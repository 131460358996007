import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MaxLength, Modules, NotificationTextMessage } from '@app/core/Enum';
import { ProductType } from '@app/core/Enum/product-type';
import { GlobalComponent, ProductModel, VatRateScheme } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  GetProductData,
  GetProductList,
  GetProductListByProductType,
  GetVatRateList,
  MenuState,
  ProductState,
} from '@app/core/Store';
import { CleanAllLinesComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  productDetailForm: FormGroup;
  formProductDetail: UntypedFormGroup;
  productText = '';
  productType = 0;

  isproductDetailValid = true;

  productTypeList: ProductModel[];
  productTypeData: ProductModel[];
  productDetailArray: any;
  linkText: string;
  maxLength = MaxLength;
  isVatIncuded: boolean = false;

  primaryContact = {
    id: 0,
  };

  @Input() triggereEditProductData: Observable<any>;
  @Input() triggerisVatIncluded: Observable<any>;
  tableDataSource: MatTableDataSource<AbstractControl>;
  notificationMessage = NotificationTextMessage;
  displayProductDetailsColumns: string[] = [
    'srNo',
    'product',
    'description',
    'qty',
    'price',
    'netAmount',
    'vatrate',
    'vatammount',
    'amount',
    'closeButton',
  ];
  vatRateList: VatRateScheme[];
  totalAmount = 0;
  vatRate: number;

  moduleEnum = Modules;

  private destroy$ = new Subject<void>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  moduleId: number;
  isVatRegistered: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private commonService: CommonService,
    private renderer: Renderer2,
    public dialog: MatDialog,
    private globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    if (!this.isVatRegistered) {
      this.displayProductDetailsColumns =
        this.displayProductDetailsColumns.filter(
          (column) =>
            column !== 'vatrate' &&
            column !== 'vatammount' &&
            column !== 'amount'
        );
    }
    this.moduleId$.subscribe((x) => {
      if (x > 0) {
        this.productText =
          x === Modules.FixedAssets ? 'Product' : 'Product / Service';
        this.moduleId = x;
        this.setProductDetailsForm(true);
      }

      this.triggerisVatIncluded?.subscribe((data) => {
        this.isVatIncuded = data;
        this.UpdateVatProduct();
      });
    });

    this.totalAmount = 0;

    this.triggereEditProductData
      .pipe(
        switchMap((data) => {
          return this.loadDropdownValues().pipe(map(() => data));
        })
      )
      .subscribe((data) => {
        this.editProduct(data);
      });

    this.formProductDetail?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formProductDetail.touched;
    });
  }

  getProductType(module: Modules): ProductType {
    switch (module) {
      case Modules.Quotation:
      case Modules.Invoices:
      case Modules.RecurringInvoice:
      case Modules.CreditNote:
        return ProductType.Sales;
      case Modules.Bills:
      case Modules.RecurringBill:
      case Modules.DebitNote:
        return ProductType.Purchase;
      case Modules.FixedAssets:
        return ProductType.Asset;
      default:
        return ProductType.Sales;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  amountChange(): void {
    const formArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;
    this.totalAmount = 0;
    formArray?.getRawValue().forEach((x) => {
      this.totalAmount = this.totalAmount + x.amount;
    });
  }

  loadDropdownValues(): Observable<any> {
    const vatRateList = this.store.dispatch(new GetVatRateList());
    const productList = this.store.dispatch(
      new GetProductListByProductType(this.getProductType(this.moduleId))
    );

    return forkJoin([vatRateList, productList]).pipe(
      tap((res) => {
        this.productTypeList = this.store.selectSnapshot(
          ProductState.getProduct
        );
        this.productTypeData = this.productTypeList;
        this.vatRateList = this.store.selectSnapshot(CommonState.getVatRate);

        if (this.productTypeList.length > 0) {
          this.productDetailArray.controls[this.productDetailArray.length - 1]
            .get('productName')
            ?.setValue('');
        }
      })
    );
  }

  resetForm(): void {
    const formArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;
    formArray.clear();
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formProductDetail.get(
            'productDetailArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('price')?.setValue(0);
            formArray.controls[i].get('account')?.setValue(0);
            formArray.controls[i].get('vatRate')?.setValue(null);
            formArray.controls[i].get('vatAmount')?.setValue(0);
            formArray.controls[i].get('netAmount')?.setValue(0);
            formArray.controls[i].get('amount')?.setValue(0);
            formArray.controls[i].get('productId')?.setValue(0);
            formArray.controls[i].get('productName')?.setValue('');

            this.setValidation(formArray, i);

            this.totalAmount = 0;
          }
        }
      });
  }

  setValidation(formArray, index): void {
    formArray.controls[index]
      .get('productName')
      ?.setValidators(Validators.required);

    formArray.controls[index].get('price')?.setValidators(Validators.required);

    formArray.controls[index].get('amount')?.setValidators(Validators.required);

    formArray.controls[index].get('price')?.updateValueAndValidity();
    formArray.controls[index].get('amount')?.updateValueAndValidity();
    formArray.controls[index].get('productName')?.updateValueAndValidity();
  }

  setProductDetailsForm(addNewRow: boolean): void {
    this.formProductDetail = new FormGroup({
      productDetailArray: new UntypedFormArray([]),
    });

    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.productDetailArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.productDetailArray.push(this.setForm());

    if (this.productDetailArray.length > 0) {
      this.loadDropdownValues().subscribe();
      this.setDisable(this.productDetailArray.length - 1);
    }

    this.setDataSource(this.productDetailArray);
  }

  setDisable(index: number): void {
    this.productDetailArray.controls[index].get('amount').disable();
    this.productDetailArray.controls[index].get('netAmount').disable();
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      description: new FormControl<string | null>(''),
      qty: new FormControl<string | null>(''),
      price: new FormControl<number | null>(0, [Validators.required]),
      vatRate: new FormControl<number | null>(null),
      netAmount: new FormControl<number | null>(0),
      vatAmount: new FormControl<number | null>(0),
      amount: new FormControl<number | null>(0, [Validators.required]),
      productName: new FormControl<string | null>('', [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
    });
  }

  onDeleteProductDetails(index: number): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;
    if (this.productDetailArray.length === 1) {
      return;
    }
    this.productDetailArray.removeAt(index);
    this.setDataSource(this.productDetailArray);
    this.amountChange();
  }

  UpdateVatProduct(): void {
    this.productDetailArray.controls.forEach((element, i) => {
      const price = this.productDetailArray.controls[i].get('price').value;
      const quantity =
        this.productDetailArray.controls[i].get('qty').value === '' ||
        this.productDetailArray.controls[i].get('qty').value === null
          ? 1
          : +this.productDetailArray.controls[i].get('qty').value;
      if (
        element.controls.vatRate.value !== undefined &&
        element.controls.vatRate.value !== null &&
        element.controls.vatRate.value !== -1
      ) {
        const data = this.vatRateList.filter(
          (x) =>
            x.id === this.productDetailArray.controls[i].get('vatRate').value
        );

        if (data.length > 0) {
          this.vatRate = data[0].rate;
        }

        let vatAmount;
        let amount;
        let vatRate;
        let totalNetAmount;

        if (this.isVatIncuded) {
          vatRate = this.vatRate === undefined ? 1 : this.vatRate;
          vatAmount = (
            (+price * +quantity * vatRate) /
            (100 + vatRate)
          ).toFixed(2);
          amount = (+quantity * +price).toFixed(2);
          totalNetAmount = (+price * +quantity - vatAmount).toFixed(2);
        } else {
          vatRate = this.vatRate === undefined ? 1 : this.vatRate;
          vatAmount = (+price * +quantity * vatRate) / 100;
          amount = +quantity * +price + vatAmount;
          totalNetAmount = quantity * +price;
        }

        this.productDetailArray.controls[i]
          .get('netAmount')
          ?.setValue(totalNetAmount);

        this.productDetailArray.controls[i]
          .get('vatAmount')
          ?.setValue(vatAmount);
        this.productDetailArray.controls[i].get('amount')?.setValue(amount);
      } else {
        const amount = quantity * price;

        this.productDetailArray.controls[i].get('vatAmount')?.setValue(0);
        this.productDetailArray.controls[i].get('amount')?.setValue(amount);
        this.productDetailArray.controls[i].get('netAmount')?.setValue(amount);

        if (amount.toString().length > 17) {
          this.commonService.onFailure(
            NotificationTextMessage.amountDigitErrorMessage
          );
        }
      }
    });
    this.amountChange();
  }
  editProduct(data: any): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.productDetailArray.clear();

    data.forEach((item, i) => {
      this.productDetailArray.push(this.buildOrderItemsForm(item));
      this.setFormValidity(true, i);
    });

    data.forEach((item, i) => {
      if (item?.isVatReturnIncluded) {
        this.productDetailArray.controls.forEach((element, i) => {
          this.productDetailArray.controls[i].get('description').disable();
          this.productDetailArray.controls[i].get('qty').disable();
          this.productDetailArray.controls[i].get('price').disable();
          this.productDetailArray.controls[i].get('vatRate').disable();
          this.productDetailArray.controls[i].get('netAmount').disable();
          this.productDetailArray.controls[i].get('vatAmount').disable();
          this.productDetailArray.controls[i].get('amount').disable();
          this.productDetailArray.controls[i].get('productName').disable();
        });
      }
    });

    this.setDataSource(this.productDetailArray);
    this.amountChange();
  }

  buildOrderItemsForm(item: any): FormGroup {
    if (
      item.productId !== (Guid.EMPTY as unknown as Guid) &&
      this.productTypeList.length > 0
    ) {
      item.productName = this.productTypeList.filter(
        (x: any) => x.id === item.productId
      )[0];
    }

    const form = this.formBuilder.group({
      id: item.id,
      productName: item.productName,
      description: item.description,
      qty: item.quantity !== 0 ? item.quantity : '',
      price: item.price.toFixed(2),
      vatRate: item.vatRateId,
      vatAmount: item.vatAmount.toFixed(2),
      amount: item.totalAmount.toFixed(2),
      netAmount:
        +item.price.toFixed(2) * +(item.quantity !== 0 ? item.quantity : 1),
    });
    form.controls.netAmount.disable();
    return form;
  }

  onProductChanged(event: any, index: number): any {
    const currentValue = event.currentTarget.value.trimEnd().toLowerCase();
    const data = this.productTypeData.filter(
      (x) => x.name.trimEnd().toLowerCase() === currentValue
    );

    if (data.length > 0 && currentValue !== '') {
      const param = {
        id: data[0].id,
      };
      this.productDetailArray.controls[index]
        .get('productName')
        ?.setValue(data[0]);
      this.onProductChange(param, index);
    } else {
      this.onProductSearch(event, index);
    }
  }

  resetAccountList(element: any): void {
    this.productTypeList = this.productTypeData;
    if (element.productName !== undefined) {
      this.scrollIntoView(element.productName.value);
    }
  }

  onProductSearch(event: any, index: number): any {
    this.productDetailArray.controls[index].get('price').reset();
    this.productDetailArray.controls[index].get('description').reset();
    this.productDetailArray.controls[index].get('vatRate').reset();
    this.productDetailArray.controls[index].get('netAmount').reset();
    this.productDetailArray.controls[index].get('vatAmount').reset();
    this.productDetailArray.controls[index].get('amount').reset();
    this.setFormValidity(false, index);
    this.productTypeList = this.productTypeData;
    const projectSearhText = event.currentTarget.value;
    const selectedArray: ProductModel[] = [];

    this.productTypeList.forEach((item) => {
      if (item.name.toLowerCase().includes(projectSearhText.toLowerCase())) {
        selectedArray.push(item);
      }
    });

    if (projectSearhText) {
      this.productTypeList = selectedArray;
    }

    if (this.productDetailArray.controls[index].get('productName').invalid) {
      this.productDetailArray.controls[index].get('productName').setValue('');
    }
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : '';
  }

  qtyChanges(index: any): void {
    const price = this.productDetailArray.controls[index].get('price').value;
    const quantity =
      this.productDetailArray.controls[index].get('qty').value === '' ||
      this.productDetailArray.controls[index].get('qty').value === null
        ? 1
        : +this.productDetailArray.controls[index].get('qty').value;

    if (
      this.productDetailArray.controls[index].get('vatRate').value !==
        undefined &&
      this.productDetailArray.controls[index].get('vatRate').value !== null &&
      this.productDetailArray.controls[index].get('vatRate').value !== -1
    ) {
      const data = this.vatRateList.filter(
        (x) =>
          x.id === this.productDetailArray.controls[index].get('vatRate').value
      );

      if (data.length > 0) {
        this.vatRate = data[0].rate;
      }

      let vatAmount;
      let amount;
      let vatRate;
      let totalNetAmount;

      if (this.isVatIncuded) {
        vatRate = this.vatRate === undefined ? 1 : this.vatRate;
        vatAmount = ((+price * +quantity * vatRate) / (100 + vatRate)).toFixed(
          2
        );
        amount = (+quantity * +price).toFixed(2);
        totalNetAmount = (+price * +quantity - vatAmount).toFixed(2);
      } else {
        vatRate = this.vatRate === undefined ? 1 : this.vatRate;
        vatAmount = (+price * +quantity * vatRate) / 100;
        amount = +quantity * +price + vatAmount;
        totalNetAmount = quantity * +price;
      }

      this.productDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(totalNetAmount);

      if (totalNetAmount.toString().length > 17) {
        this.commonService.onFailure(
          NotificationTextMessage.netAmountDigitErrorMessage
        );
      }

      this.productDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(vatAmount);

      if (vatAmount.toString().length > 17) {
        this.commonService.onFailure(
          NotificationTextMessage.vatAmountDigitErrorMessage
        );
      }
      this.productDetailArray.controls[index].get('amount')?.setValue(amount);

      if (amount.toString().length > 17) {
        this.commonService.onFailure(
          NotificationTextMessage.amountDigitErrorMessage
        );
      }

      this.amountChange();
    } else {
      const amount = quantity * price;

      this.productDetailArray.controls[index].get('vatAmount')?.setValue(0);
      this.productDetailArray.controls[index].get('amount')?.setValue(amount);
      this.productDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(amount);

      if (amount.toString().length > 17) {
        this.commonService.onFailure(
          NotificationTextMessage.amountDigitErrorMessage
        );
      }

      this.amountChange();
    }
  }

  setFormValidity(isSelect: boolean, index: number): void {
    if (!isSelect) {
      this.productDetailArray.controls[index].get('vatAmount')?.setValue(0);
      this.productDetailArray.controls[index].get('amount')?.setValue(0);
      this.productDetailArray.controls[index].get('vatRate')?.setValue();
      this.productDetailArray.controls[index].get('netAmount')?.setValue();
    }
    this.productDetailArray.controls[index].get('price').enable();
    this.productDetailArray.controls[index].get('description').enable();
    this.productDetailArray.controls[index].get('vatRate').enable();
    this.productDetailArray.controls[index].get('vatAmount').disable();
    this.productDetailArray.controls[index].get('amount').disable();
  }

  onProductChange(event: any, index: any): void {
    if (event !== '-1') {
      this.store
        .dispatch(new GetProductData(event.id))
        .pipe(
          tap((res) => {
            this.productDetailArray.controls[index]
              .get('price')
              ?.setValue(res.common.productDetails.price);
            this.productDetailArray.controls[index]
              .get('vatRate')
              ?.setValue(
                res.common.productDetails.vatRateId === null
                  ? -1
                  : res.common.productDetails.vatRateId
              );
            this.qtyChanges(index);
            this.setFormValidity(true, index);
          })
        )
        .subscribe();
    } else {
      this.clearForm();
      this.productDetailArray.controls[this.productDetailArray.length - 1]
        .get('productName')
        ?.setValue('');
      this.productDetailArray.controls[index].get('vatAmount').enable();
      this.productDetailArray.controls[index].get('amount').enable();
    }
  }

  scrollIntoView(element) {
    if (element.value.productName !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }
}
