import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationTextMessage } from '@app/core/Enum';
import { GlobalComponent, Currency } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  GetCustomBankAccountList,
  CommonState,
  GetCurrencyList,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-add-cash-coding',
  templateUrl: './add-cash-coding.component.html',
  styleUrls: ['./add-cash-coding.component.scss'],
})
export class AddCashCodingComponent implements OnInit {
  cashCodingForm: FormGroup;
  periodicDate: any;
  notificationMessage = NotificationTextMessage;

  receiptAccountList: any[] = [];
  currencyList: Currency[];

  @Input() bankId;

  defaultCurrency: Guid;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();
    this.cashCodingForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.cashCodingForm.touched;
    });
  }

  setForm(): void {
    this.cashCodingForm = new FormGroup({
      accountId: new FormControl('', [Validators.required]),
      currency: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
    });

    this.loadDropdownValues();
  }

  loadDropdownValues(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    const customBankAccount = this.store.dispatch(
      new GetCustomBankAccountList()
    );
    const currency = this.store.dispatch(new GetCurrencyList());
    forkJoin([customBankAccount, currency]).subscribe((res) => {
      this.receiptAccountList = this.store.selectSnapshot(
        CommonState.getCustomBankAccount
      );

      if (!this.commonService.isEmpty(this.bankId)) {
        this.cashCodingForm.controls.accountId.setValue(this.bankId);
        this.cashCodingForm.controls.accountId.disable();
      }

      this.currencyList = this.store.selectSnapshot(CommonState.getCurrency);
      if (this.currencyList.length > 0) {
        this.cashCodingForm.controls.currency.setValue(this.defaultCurrency);
      }
    });
  }
}
