import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { AccountModel, CustomAccountModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateCustomAccount,
  GetDataByCustomAccountId,
  GetDataByStandardAccountId,
  MenuState,
} from '@app/core/Store';
import { AddCustomAccountsComponent } from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-accounts',
  templateUrl: './custom-accounts.component.html',
  styleUrls: ['./custom-accounts.component.scss'],
})
export class CustomAccountsComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  moduleEnum = Modules;

  moduleId = Modules.AddCustomAccounts;
  customAccountData: CustomAccountModel;
  accountData: AccountModel;

  customAccountId = Guid.EMPTY as unknown as Guid;
  standardAccountId = Guid.EMPTY as unknown as Guid;

  accountGroupId: number;
  accountTypeId: number;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddCustomAccountsComponent, { static: true })
  customAccountsDetails;

  moduleName = ModuleName.AddCustomAccounts;
  customAccoutEditData: any;

  subscriptionRouting: Subscription;
  triggerEditData: Subject<any> = new Subject<any>();
  triggerEditCustomData: Subject<any> = new Subject<any>();
  triggereAddCustomAccount: Subject<any> = new Subject<any>();

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.customAccountId =
            params.get('id') === null
              ? (Guid.EMPTY as unknown as Guid)
              : (atob(params.get('id')!) as unknown as Guid);

          this.standardAccountId =
            params.get('accountId') === null
              ? (Guid.EMPTY as unknown as Guid)
              : (atob(params.get('accountId')!) as unknown as Guid);

          if (!this.commonService.isEmpty(this.customAccountId)) {
            this.editCustomAccount();
          }
          if (!this.commonService.isEmpty(this.standardAccountId)) {
            this.addNewCustomAccount();
          }
        }
      }
    );
  }

  accountGroupChange(accountGroupId: any): void {
    this.accountGroupId = accountGroupId;
  }

  accountTypeChange(accountTypeId: any): void {
    this.accountTypeId = accountTypeId;
    if (this.customAccoutEditData !== undefined) {
      this.triggerEditData.next(this.customAccoutEditData);
    }
  }

  editCustomAccount(): void {
    this.store
      .dispatch(new GetDataByCustomAccountId(this.customAccountId))
      .subscribe((res) => {
        this.customAccountsDetails.customAccountForm.markAsUntouched();
        this.customAccoutEditData = res.custom.customAccountData;
        this.triggerEditCustomData.next(res.custom.customAccountData);
      });
  }

  addNewCustomAccount(): void {
    this.store
      .dispatch(new GetDataByStandardAccountId(this.standardAccountId))
      .subscribe((res) => {
        this.customAccountsDetails.customAccountForm.markAsUntouched();
        this.triggereAddCustomAccount.next(res.custom.customAccountData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.customAccountId,
      isExit,
      Modules.ChartOfAccounts,
      RoutingPath.AddCustomAccounts
    );
  }

  onSave(isExit: boolean): void {
    if (this.customAccountsDetails.customAccountForm.invalid) {
      this.commonService.addValidation(
        this.customAccountsDetails.customAccountForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateCustomAccount(this.accountData))
          .subscribe(
            (res) => {
              this.setHighlightData(isExit);

              if (res !== undefined) {
                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const attachmentData: any[] = [];
      this.accountData = {
        id: this.customAccountId,
        name: this.customAccountsDetails.isFixedAssestsGroup
          ? this.customAccountsDetails.customAccountForm.controls
              .chartOfAccountType.value
          : this.customAccountsDetails.customAccountForm.controls.accountName
              .value,
        code: this.customAccountsDetails.customAccountForm.controls.accountCode
          .value,
        chartOfAccountGroupId:
          this.customAccountsDetails.customAccountForm.controls
            .chartOfAccountGroup.value,
        chartOfAccountTypeId: !this.customAccountsDetails.isFixedAssestsGroup
          ? this.customAccountsDetails.customAccountForm.controls
              .chartOfAccountType.value
          : 0,
        bankAccountTypeId:
          this.customAccountsDetails.customAccountForm.controls.bankAccountType
            .value === ''
            ? null
            : this.customAccountsDetails.customAccountForm.controls
                .bankAccountType.value,
        currencyId:
          this.customAccountsDetails.customAccountForm.controls.currency.value,
        bankBranchCode:
          this.customAccountsDetails.customAccountForm.controls.sortBranchCode
            .value,
        bankAccountNumber:
          this.customAccountsDetails.customAccountForm.controls.accountNo
            .value !== undefined &&
          this.customAccountsDetails.customAccountForm.controls.accountNo
            .value !== null
            ? this.customAccountsDetails.customAccountForm.controls.accountNo.value.toString()
            : '',
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.customAccountId)) {
      this.editCustomAccount();
    } else {
      this.customAccountId = Guid.EMPTY as unknown as Guid;
      this.customAccountsDetails.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
