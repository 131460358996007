import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
} from '@app/core/Enum';
import { CommonService, NotificationService } from '@app/core/Services';
import { GetDepreciationRollBackTransaction } from '@app/core/Store';
import {
  ConfirmationBoxComponent,
  ViewReceiptComponent,
} from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-rollback-popup',
  templateUrl: './rollback-popup.component.html',
  styleUrls: ['./rollback-popup.component.scss'],
})
export class RollBackPopupComponent implements OnInit {
  displayAllocationRefundColumns: string[] = [
    'refNo',
    'depreciationDate',
    'depreciationAmount',
    'actions',
  ];
  expandedCollapseDetailList: any;
  modules = Modules;
  notify: NotificationService;
  constructor(
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RollBackPopupComponent>,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    this.notify = injector.get<NotificationService>(NotificationService);
  }

  ngOnInit(): void {
    this.fetchTransactionList(atob(this.data.id));
  }

  fetchTransactionList(fixedAssetItemId): void {
    this.spinner.show();
    this.store
      .dispatch(new GetDepreciationRollBackTransaction(fixedAssetItemId))
      .subscribe((res) => {
        this.spinner.hide();
        this.expandedCollapseDetailList =
          res.fixedAssetRegister.expandedCollapseDepreciationDetailList;
      });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0) {
      if (moduleId === Modules.Journals) {
        const data = {
          moduleId,
          id,
        };

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {});
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }

  actionClick(id: Guid): void {
    let ids: any[] = [];
    ids.push(id);
    if (ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: ids,
            type: ConfirmationType.Delete,
            moduleId: Modules.FixedAssetRollBack,
            totalNumberOfRecordSelected: ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.fetchTransactionList(atob(this.data.id));
          }
        });
    }
  }
}
