import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class DummyService {
  constructor(private http: HttpClient) {}

  getLoggedInUser(): Promise<boolean> {
    return this.http
      .get<boolean>(`${environment.apiVersionUrl}Dummy/getLoggedInUser`)
      .toPromise();
  }

  getTokenUsingRefreshToken(
    refreshToken: string,
    clientId: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('scope', 'capium_myadmin_api openid offline_access')
      .set('client_id', clientId);

    return this.http.post(
      `${environment.stsServerUrl}/connect/token`,
      body.toString(),
      { headers }
    );
  }
}
