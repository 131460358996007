import { Injectable } from '@angular/core';
import { PaymentsModel, SideListModel } from '@app/core/Models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { PaymentsService } from '@app/core/Services';
import { Guid } from 'guid-typescript';
import {
  CreatePayments,
  GetDataByPaymentsId,
  GetTransactionTypeList,
} from './payments.action';

export class PaymentsStateInfo {
  paymentsData?: PaymentsModel;
  payments: Array<PaymentsModel>;
  paymentsId: Guid;
  isPaymentsAdded?: boolean;
  transactionTypeList: Array<SideListModel>;
}

@State<PaymentsStateInfo>({
  name: 'payments',
  defaults: {
    payments: [],
    paymentsId: Guid.EMPTY as unknown as Guid,
    isPaymentsAdded: false,
    transactionTypeList: [],
  },
})
@Injectable()
export class PaymentsState {
  constructor(private paymentsService: PaymentsService) {}

  @Selector()
  static getPaymentsId(state: PaymentsStateInfo) {
    return state.paymentsId;
  }
  @Selector()
  static getTransactionType(state: PaymentsStateInfo) {
    return state.transactionTypeList;
  }

  @Action(CreatePayments)
  createPayments(
    { patchState }: StateContext<PaymentsStateInfo>,
    action: CreatePayments
  ) {
    return this.paymentsService.createPayments(action.payments).pipe(
      tap((res) => {
        patchState({
          paymentsId: res,
          isPaymentsAdded: true,
        });
      })
    );
  }

  @Action(GetDataByPaymentsId)
  getDataByPaymentsId(
    { patchState }: StateContext<PaymentsStateInfo>,
    action: GetDataByPaymentsId
  ) {
    return this.paymentsService.getDataByPaymentsId(action.paymentsId).pipe(
      tap((res) => {
        patchState({
          paymentsData: res,
        });
      })
    );
  }

  @Action(GetTransactionTypeList)
  getTransactionTypeList({
    getState,
    setState,
  }: StateContext<PaymentsStateInfo>) {
    return this.paymentsService.getTransactionTypeList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          transactionTypeList: res,
        });
      })
    );
  }
}
