import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VATSettingsModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class VATSettingsService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createVatDetail(VATSettings: VATSettingsModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Company/updateVatDetail`,
      this.commonService.trimObjectSpace(JSON.stringify(VATSettings)),
      headers
    );
  }

  getVatDetail(): Observable<VATSettingsModel> {
    return this.http.get<VATSettingsModel>(
      `${environment.apiVersionUrl}Company/vatDetail`
    );
  }
}
