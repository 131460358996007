import { Component, ViewChild } from '@angular/core';
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStates,
  ApexGrid,
  ApexTitleSubtitle,
} from 'ng-apexcharts';
import { arrayData } from './data-series';

type ApexXAxis = {
  type?: 'category' | 'datetime' | 'numeric';
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

var colors = ['#7B76D3', '#F4C64D', '#F2816F'];

export type DonationByFund = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  subtitle: ApexTitleSubtitle;
  colors: string[];
  states: ApexStates;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  tooltip: any; //ApexTooltip;
};

declare global {
  interface Window {
    Apex: any;
  }
}

window.Apex = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    shared: false,
  },
  legend: {
    show: false,
  },
};

@Component({
  selector: 'app-donation-by-fund',
  templateUrl: './donation-by-fund.component.html',
  styleUrls: ['./donation-by-fund.component.scss'],
})
export class DonationByFundComponent {
  @ViewChild('chart') chart: ChartComponent;
  public DonationByFundOptions: Partial<DonationByFund>;
  public chartQuarterOptions: Partial<DonationByFund>;

  constructor() {
    this.DonationByFundOptions = {
      series: [
        {
          name: '',
          data: this.makeData(),
        },
      ],
      chart: {
        id: 'barYear',
        height: 300,
        width: '100%',
        type: 'bar',
        events: {
          mounted: (chart, opts) => {
            chart.w.globals.capturedDataPointIndex = 0;
            var quarterChartEl = document.querySelector('#chart-quarter');
            var yearChartEl = document.querySelector('#chart-year');
            if (quarterChartEl?.classList.contains('active')) {
              this.updateQuarterChart(chart, 'barQuarter');
            } else {
              yearChartEl?.classList.add('chart-quarter-activated');
              quarterChartEl?.classList.add('active');
              this.updateQuarterChart(chart, 'barQuarter');
            }
          },
          dataPointSelection: (e, chart, opts) => {
            var quarterChartEl = document.querySelector('#chart-quarter');
            var yearChartEl = document.querySelector('#chart-year');

            if (opts.selectedDataPoints[0].length === 1) {
              if (quarterChartEl?.classList.contains('active')) {
                this.updateQuarterChart(chart, 'barQuarter');
              } else {
                yearChartEl?.classList.add('chart-quarter-activated');
                quarterChartEl?.classList.add('active');
                this.updateQuarterChart(chart, 'barQuarter');
              }
            } else {
              this.updateQuarterChart(chart, 'barQuarter');
            }

            if (opts.selectedDataPoints[0].length === 0) {
              yearChartEl?.classList.remove('chart-quarter-activated');
              quarterChartEl?.classList.remove('active');
            }
          },
          updated: (chart) => {
            this.updateQuarterChart(chart, 'barQuarter');
          },
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          barHeight: '50%',
          dataLabels: {
            position: 'bottom',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },

      colors: colors,

      states: {
        normal: {
          filter: {
            type: 'desaturate',
          },
        },
        active: {
          allowMultipleDataPointsSelection: true,
          filter: {
            type: 'darken',
            value: 1,
          },
        },
      },
      tooltip: {
        marker: {
          show: false,
        },
        y: {
          formatter: (val) => {
            return '£ ' + Number(val) * 1 + '';
          },
        },
      },
      title: {
        text: 'Fund Types',
        offsetX: 15,
        style: {
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: 'Source Sans Pro, sans-serif',
          color: '#404A5F',
        },
      },
      subtitle: {
        text: '(Click on bar to see details)',
        offsetX: 460,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontFamily: 'Source Sans Pro, sans-serif',
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
      },
    };

    this.chartQuarterOptions = {
      series: [
        {
          name: 'quarter',
          data: [],
        },
      ],
      chart: {
        id: 'barQuarter',
        height: 300,
        width: '100%',
        type: 'bar',
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
          horizontal: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
      },
      title: {
        text: 'Endowment Funds Data',
        offsetX: 10,
        style: {
          fontSize: '14px',
          fontWeight: '480',
          fontFamily: 'Source Sans Pro, sans-serif',
          color: '#404A5F',
        },
      },
      tooltip: {
        marker: {
          show: false,
        },
        x: {
          formatter: function (val, opts) {
            return opts.w.globals.seriesNames[opts.seriesIndex];
          },
        },
        y: {
          title: {
            formatter: function (val, opts) {
              return '';
            },
          },
          formatter: (val) => {
            return '£ ' + Number(val) * 1 + '';
          },
        },
      },
    };
  }

  public makeData(): any {
    var dataSet = this.shuffleArray(arrayData);
    var dataYearSeries = [
      {
        x: 'Endowment Funds',
        y: dataSet[0].y,
        color: colors[0],
        quarters: dataSet[0].quarters,
      },
      {
        x: 'Restricted Funds',
        y: dataSet[1].y,
        color: colors[1],
        quarters: dataSet[1].quarters,
      },
      {
        x: 'Unrestricted Funds',
        y: dataSet[2].y,
        color: colors[2],
        quarters: dataSet[2].quarters,
      },
    ];

    return dataYearSeries;
  }

  public shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  public updateQuarterChart(sourceChart, destChartIDToUpdate) {
    var series: any[] = [];
    var colors: any[] = [];

    var pointIndex = sourceChart.w.globals.capturedDataPointIndex;

    if (pointIndex >= 0) {
      var yearSeries = sourceChart.w.config.series[0];

      series.push({
        name: yearSeries.data[pointIndex].x,
        data: yearSeries.data[pointIndex].quarters,
      });
      colors.push(yearSeries.data[pointIndex].color);

      if (series.length === 0)
        series = [
          {
            data: [],
          },
        ];

      return window.ApexCharts.exec(destChartIDToUpdate, 'updateOptions', {
        series: series,
        colors: colors,
        fill: {
          colors: colors,
        },
      });
    }
  }
}
