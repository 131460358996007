import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/Guards/auth.guard';
import { AuthResolverService } from './core/Guards/auth.resolver';
import {
  ActivitiesComponent,
  AddBankAccountComponent,
  AddFundComponent,
  BankReconciliationComponent,
  CompanyPreferenceComponent,
  CreditNoteComponent,
  CustomAccountsComponent,
  CustomerComponent,
  DashboardComponent,
  DebitNoteComponent,
  DonationComponent,
  DonorComponent,
  FixedAssetsComponent,
  FundComponent,
  FundTransferComponent,
  ImportComponent,
  IncomeInvoiceComponent,
  IncomeOverviewComponent,
  JournalsComponent,
  MainListComponent,
  NewCompanyComponent,
  NominalLedgerComponent,
  Note10Component,
  Note11Component,
  Note12Component,
  Note13Component,
  Note14Component,
  Note15Component,
  Note16Component,
  Note18Component,
  Note19Component,
  Note1Component,
  Note20Component,
  Note21Component,
  Note22Component,
  Note23Component,
  Note24Component,
  Note25Component,
  Note26Component,
  Note28Component,
  Note29Component,
  Note3Component,
  Note4Component,
  Note5Component,
  Note6Component,
  Note7Component,
  Note8Component,
  Note9Component,
  NoteComponent,
  OpeningBalanceComponent,
  BillComponent,
  QuotationComponent,
  ReceiptComponent,
  RecurringDonationComponent,
  RecurringModuleComponent,
  ReportCreditorDebtorComponent,
  ReportDashboardComponent,
  ReportListComponent,
  SponsorEventComponent,
  SupplierComponent,
  DonationInKindComponent,
  Note2Component,
  Note27Component,
  Note31Component,
  Note32Component,
  Note30Component,
  BulkEditComponent,
  AnnualReportComponent,
  ViewVatDetailComponent,
  VatSettingsComponent,
  AddVatCodesComponent,
  SubmitVatComponent,
  IncludeDataComponent,
  ReportDetailListComponent,
  BridgingVATComponent,
  DonationCodingComponent,
  CashCodingComponent,
  Note33Component,
  Note34Component,
  Note35Component,
  Note36Component,
  Note37Component,
  Note38Component,
  Note39Component,
  Note40Component,
  Note41Component,
  Note42Component,
  IntegrationsComponent,
  ReportListWithActionsComponent,
  DepreciationExpandedCollapseComponent,
  BankTransferComponent,
  DashboardComponentv1,
  EmailTemplatesComponent,
  DonationOverviewComponent,
  FixedAssetsOverviewComponent,
  ExpenditureOverviewComponent,
  FundOverviewComponent,
  BankImportComponent,
  EditAssetDetailComponent,
  TrusteesReportComponent,
  Note17Component,
  Note43Component,
  FixedAssestDisposeActiveComponent,
  AddGiftAIDsettingsComponent,
  GiftAidComponent,
  TicketComponent,
  BankOverviewComponent,
  BankFeedComponent,
  ManualImportComponent,
  AddUserComponent,
  UsersListComponent,
  AccessDeniedComponent,
} from './modules';
import { IncomeOverviewV1Component } from './modules/income/income-overview-v1/income-overview-v1.component';
import { PermissionsComponent } from './modules/permissions/permissions.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    component: DashboardComponentv1,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard-v1',
    pathMatch: 'full',
    component: DashboardComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'ticket',
    component: TicketComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'accounts/standard',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'accounts/custom',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'accounts/custom/add',
    pathMatch: 'full',
    component: CustomAccountsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/overview',
    pathMatch: 'full',
    component: IncomeOverviewV1Component,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/overview-v1',
    pathMatch: 'full',
    component: IncomeOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'expenditure/overview-v1',
    pathMatch: 'full',
    component: IncomeOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donation/overview',
    pathMatch: 'full',
    component: DonationOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donation/overview-v1',
    pathMatch: 'full',
    component: IncomeOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAsset/overview',
    pathMatch: 'full',
    component: FixedAssetsOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAsset/overview-v1',
    pathMatch: 'full',
    component: IncomeOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fund/overview',
    pathMatch: 'full',
    component: FundOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fund/overview-v1',
    pathMatch: 'full',
    component: DashboardComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'reports',
    children: [
      {
        path: 'donation',
        pathMatch: 'full',
        component: ReportCreditorDebtorComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'reportDetailsList',
        pathMatch: 'full',
        component: ReportDetailListComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'overview',
        pathMatch: 'full',
        component: ReportDashboardComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },

      {
        path: 'customer',
        children: [
          {
            path: 'advancereport',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'salesreport',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'customerlist',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'customerageinglist',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'transactionsbycustomer',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'salesproductlist1',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'customerreceipts',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'debtors',
            pathMatch: 'full',
            component: ReportCreditorDebtorComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'customerstatement',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'invoicelist',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
        ],
      },

      {
        path: 'supplier',
        children: [
          {
            path: 'advancereport',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'purchasesreport',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'supplierlist',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'supplierageinglist',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'transactionsbysupplier',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'paymenttosupplier',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'creditors',
            pathMatch: 'full',
            component: ReportCreditorDebtorComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'supplierstatement',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'billlist',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
        ],
      },

      {
        path: 'management',
        children: [
          {
            path: 'profit&loss1',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'balancesheet',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'trialbalance',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'statementoffinancialactivities',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'statementOfAssetsAndLiabilities',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'receiptAndPayment',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'comparativereport1',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'budgetreport1',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'incomeAndExpenditure',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'daybook',
            children: [
              {
                path: 'salesdaybook',
                pathMatch: 'full',
                component: ReportListComponent,
                resolve: {
                  result: AuthResolverService,
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'purchasedaybook',
                pathMatch: 'full',
                component: ReportListComponent,
                resolve: {
                  result: AuthResolverService,
                },
                canActivate: [AuthGuard],
              },
            ],
          },

          {
            path: 'cashflow1',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
        ],
      },

      {
        path: 'additional',
        children: [
          {
            path: 'accountdetails',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'nominalledger',
            pathMatch: 'full',
            component: NominalLedgerComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
        ],
      },

      {
        path: 'annualaccounts',
        children: [
          {
            path: 'statementoffinancialactivities1',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'balancesheet1',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'notes',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },

  {
    path: 'income/invoices',

    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/invoices/add',
    pathMatch: 'full',
    component: IncomeInvoiceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/recurringInvoice',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/recurringInvoice/add',
    pathMatch: 'full',
    component: RecurringModuleComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/quotation',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/quotation/add',
    pathMatch: 'full',
    component: QuotationComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/creditNote',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/creditNote/add',
    pathMatch: 'full',
    component: CreditNoteComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/receipt',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'income/receipt/add',
    pathMatch: 'full',
    component: ReceiptComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'expenditure/overview',
    pathMatch: 'full',
    component: ExpenditureOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'expenditure/bill',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'expenditure/bill/add',
    pathMatch: 'full',
    component: BillComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'expenditure/recurringBill',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'expenditure/recurringBill/add',
    pathMatch: 'full',
    component: RecurringModuleComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'expenditure/debitNote',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'expenditure/debitNote/add',
    pathMatch: 'full',
    component: DebitNoteComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'expenditure/payment',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'expenditure/payment/add',
    pathMatch: 'full',
    component: ReceiptComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/journals',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    data: { title: 'This is my title' },
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank',
    pathMatch: 'full',
    component: AddBankAccountComponent,
    resolve: {
      result: AuthResolverService,
    },
  },

  {
    path: 'bankFeed',
    pathMatch: 'full',
    component: AddBankAccountComponent,
    resolve: {
      result: AuthResolverService,
    },
  },

  {
    path: 'tasks/journals/add',
    pathMatch: 'full',
    component: JournalsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/bulkEdit',
    pathMatch: 'full',
    component: BulkEditComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/activities',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/activities/add',
    pathMatch: 'full',
    component: ActivitiesComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/sponsorEvent',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/sponsorEvent/add',
    pathMatch: 'full',
    component: SponsorEventComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/DonationCoding',
    pathMatch: 'full',
    component: DonationCodingComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/list',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/list/add',
    pathMatch: 'full',
    component: DonationComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/giftaidsetting',
    pathMatch: 'full',
    component: AddGiftAIDsettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/recurringDonations',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/recurringDonations/add',
    pathMatch: 'full',
    component: RecurringDonationComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/DonationsInKind',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/DonationsInKind/add',
    pathMatch: 'full',
    component: DonationInKindComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/giftAidClaimsSubmissiontoHMRC',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'donations/giftAidClaimsSubmissiontoHMRC/add',
    pathMatch: 'full',
    component: GiftAidComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/customers',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/customers/add',
    pathMatch: 'full',
    component: CustomerComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/suppliers',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/suppliers/add',
    pathMatch: 'full',
    component: SupplierComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/donors',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/donors/add',
    pathMatch: 'full',
    component: DonorComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Account/Login',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bankTransaction',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bankImport',
    pathMatch: 'full',
    component: BankImportComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/overview',
    pathMatch: 'full',
    component: BankOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/cashCoding',
    pathMatch: 'full',
    component: CashCodingComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'bank/bankFeed',
    pathMatch: 'full',
    component: BankFeedComponent,
    resolve: {
      result: AuthResolverService,
    },
  },
  {
    path: 'bank/bankEntry',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/bankEntry/add',
    pathMatch: 'full',
    component: ReceiptComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'bank/cashEntry',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/cashEntry/add',
    pathMatch: 'full',
    component: ReceiptComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAsset/fixedAssets',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAssets/FixedAssetsRegister',
    pathMatch: 'full',
    component: FixedAssestDisposeActiveComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAssets/FixedAssetsRegister/add',
    pathMatch: 'full',
    component: DepreciationExpandedCollapseComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'editfix',
    pathMatch: 'full',
    component: EditAssetDetailComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAsset/fixedAssets/add',
    pathMatch: 'full',
    component: FixedAssetsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fund/fundlist',
    pathMatch: 'full',
    component: ReportListWithActionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fund/fundlist/add',
    pathMatch: 'full',
    component: FundComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fund/transfer',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fund/transfer/add',
    pathMatch: 'full',
    component: FundTransferComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'logs/email',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'logs/user',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'logs/user/add',
    pathMatch: 'full',
    component: AddFundComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/charitypreference',
    pathMatch: 'full',
    component: CompanyPreferenceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/openingBalance',
    pathMatch: 'full',
    component: OpeningBalanceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'editTrialBalance',
    pathMatch: 'full',
    component: OpeningBalanceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'accountProduction/editTrialBalance',
    pathMatch: 'full',
    component: OpeningBalanceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/import',
    pathMatch: 'full',
    component: ImportComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/integrations',
    pathMatch: 'full',
    component: IntegrationsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/users',
    pathMatch: 'full',
    component: UsersListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/users/add',
    component: AddUserComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'manage/permissions',
    pathMatch: 'full',
    component: PermissionsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'report/accountDetails',
    pathMatch: 'full',
    component: ReportListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'reports/vat',
    pathMatch: 'full',
    component: ReportListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'addCharity',
    pathMatch: 'full',
    component: NewCompanyComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'editCharity',
    pathMatch: 'full',
    component: NewCompanyComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/viewVatDetails',
    pathMatch: 'full',
    component: ViewVatDetailComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/vatSettings',
    pathMatch: 'full',
    component: VatSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/vatSettings/add',
    pathMatch: 'full',
    component: AddVatCodesComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/submitvat',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/submitvat/add',
    pathMatch: 'full',
    component: SubmitVatComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/bridgingvat',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/bridgingvat/add',
    pathMatch: 'full',
    component: BridgingVATComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'mtd/submitvat/incExcDetail',
    pathMatch: 'full',
    component: IncludeDataComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'manage/templates',
    pathMatch: 'full',
    component: EmailTemplatesComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/transfer',
    pathMatch: 'full',
    component: MainListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/transfer/add',
    pathMatch: 'full',
    component: BankTransferComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manualImport',
    pathMatch: 'full',
    component: ManualImportComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'accountProduction',
    children: [
      {
        path: 'trialbalance',
        pathMatch: 'full',
        component: ReportListComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'management',
        children: [
          {
            path: 'statementoffinancialactivities',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'balancesheet',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'receiptAndPayment',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'statementOfAssetsAndLiabilities',
            pathMatch: 'full',
            component: ReportListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'independentExaminer',
        pathMatch: 'full',
        component: TrusteesReportComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'trusteeReport',
        pathMatch: 'full',
        component: TrusteesReportComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'auditReport',
        pathMatch: 'full',
        component: TrusteesReportComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },

      {
        path: 'notes',
        pathMatch: 'full',
        component: NoteComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note1',
        pathMatch: 'full',
        component: Note1Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note2',
        pathMatch: 'full',
        component: Note2Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note3',
        pathMatch: 'full',
        component: Note3Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note4',
        pathMatch: 'full',
        component: Note4Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note5',
        pathMatch: 'full',
        component: Note5Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note6',
        pathMatch: 'full',
        component: Note6Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note7',
        pathMatch: 'full',
        component: Note7Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note8',
        pathMatch: 'full',
        component: Note8Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note9',
        pathMatch: 'full',
        component: Note9Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note10',
        pathMatch: 'full',
        component: Note10Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note11',
        pathMatch: 'full',
        component: Note11Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note12',
        pathMatch: 'full',
        component: Note12Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note13',
        pathMatch: 'full',
        component: Note13Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note14',
        pathMatch: 'full',
        component: Note14Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note15',
        pathMatch: 'full',
        component: Note15Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note16',
        pathMatch: 'full',
        component: Note16Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note17',
        pathMatch: 'full',
        component: Note17Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note18',
        pathMatch: 'full',
        component: Note18Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note19',
        pathMatch: 'full',
        component: Note19Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note20',
        pathMatch: 'full',
        component: Note20Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note21',
        pathMatch: 'full',
        component: Note21Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note22',
        pathMatch: 'full',
        component: Note22Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note23',
        pathMatch: 'full',
        component: Note23Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note24',
        pathMatch: 'full',
        component: Note24Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note25',
        pathMatch: 'full',
        component: Note25Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note26',
        pathMatch: 'full',
        component: Note26Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note27',
        pathMatch: 'full',
        component: Note27Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note28',
        pathMatch: 'full',
        component: Note28Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note29',
        pathMatch: 'full',
        component: Note29Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note30',
        pathMatch: 'full',
        component: Note30Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note31',
        pathMatch: 'full',
        component: Note31Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note32',
        pathMatch: 'full',
        component: Note32Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note33',
        pathMatch: 'full',
        component: Note33Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note34',
        pathMatch: 'full',
        component: Note34Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note35',
        pathMatch: 'full',
        component: Note35Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note36',
        pathMatch: 'full',
        component: Note36Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note37',
        pathMatch: 'full',
        component: Note37Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note38',
        pathMatch: 'full',
        component: Note38Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note39',
        pathMatch: 'full',
        component: Note39Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note40',
        pathMatch: 'full',
        component: Note40Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note41',
        pathMatch: 'full',
        component: Note41Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/note42',
        pathMatch: 'full',
        component: Note42Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notes/referenceAndAdministrativeInformation',
        pathMatch: 'full',
        component: Note43Component,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'annualaccounts',
        pathMatch: 'full',
        component: AnnualReportComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
