import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataType, Modules, RoutingPath } from '@app/core/Enum';
import { SalesBillReportModel, VATReportModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  GetVatReportDetailList,
  GetVatReportList,
} from '@app/core/Store';
import { ViewReceiptComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-vat-report',
  templateUrl: './vat-report.component.html',
  styleUrls: ['./vat-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('isExpanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'isExpanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class VatReportComponent implements OnInit, OnDestroy {
  vATReportList: VATReportModel[];
  salesVATList: SalesBillReportModel[];
  billVATList: SalesBillReportModel[];

  @Output()
  readonly triggerDataFromVatReport = new EventEmitter<any>();

  displayedColumns = ['name', 'netAmount', 'vatAmount', 'totalAmount'];

  @Input()
  listParameters: any;

  vatReportDetailList: any;
  vatId: number;
  currentRowSelected: any;
  dataType = DataType;

  noDataFound = false;
  showPaginator = true;

  dataSource = new MatTableDataSource();

  @Select(CommonState.vatColumns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  @Select(CommonState.vatColumnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  triggerPaginationChange: Subject<any> = new Subject<any>();

  @ViewChild(MatSort) sort: MatSort;
  private setVatDetailEmitter;
  constructor(
    public datepipe: DatePipe,
    public store: Store,
    public dialog: MatDialog,
    public commonService: CommonService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.setVatDetailEmitter = this.commonService.setReloadVatEmitter.subscribe(
      (listParameters) => {
        if (listParameters) {
          this.listParameters = listParameters;
          this.getVatReportList();
        }
      }
    );
  }

  getVatReportList(): void {
    const queryParams = {
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };

    this.store.dispatch(new GetVatReportList(queryParams)).subscribe((res) => {
      this.vATReportList = res.common.vatReportList.columnModel;
      this.salesVATList = res.common.vatReportList.salesVAT;
      this.billVATList = res.common.vatReportList.purchaseVAT;
    });
  }

  ngOnDestroy(): void {
    if (
      this.setVatDetailEmitter !== null &&
      this.setVatDetailEmitter !== undefined
    ) {
      this.setVatDetailEmitter.unsubscribe();
    }
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0) {
      if (moduleId === Modules.AddCustomAccounts) {
        const params = { id: btoa(id), details: btoa('true') };
        this.router.navigate([RoutingPath.AccountDetails, params]);
      } else if (moduleId === Modules.Invoices) {
        const data = {
          moduleId,
          id,
        };

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {});
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList();
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  expandCollapse(row, filter): void {
    if (this.currentRowSelected && this.currentRowSelected !== row) {
      this.currentRowSelected.isExpanded = false;
    }
    this.currentRowSelected = row;

    this.vatId = row.vatId;
    if (row.isExpanded) {
      row.isExpanded = false;
    } else {
      row.isExpanded = true;
      this.listParameters.filter = filter;
      this.getList();
    }
  }

  getList(): void {
    this.store
      .dispatch(new GetVatReportDetailList(this.vatId, this.getParamter()))
      .subscribe((res) => {
        this.vatReportDetailList =
          res.common.vatReportDetailList.resultSet.data;
        this.noDataFound = this.vatReportDetailList.length > 0 ? false : true;
        this.dataSource.data = this.vatReportDetailList;
      });
  }
}
