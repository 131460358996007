import { DatePipe } from '@angular/common';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ExportType, MainListParameters } from '@app/core/Models';
import {
  GetSubContractorDetailsList,
  GiftAidDonationDetailListExport,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-sub-contractor-details',
  templateUrl: './sub-contractor-details.component.html',
  styleUrls: ['./sub-contractor-details.component.scss'],
})
export class SubContractorDetailsComponent implements OnInit {
  displayedColumns: any[] = [];
  id = Guid.EMPTY as unknown as Guid;
  subContactorList: any;
  listParameters: MainListParameters = new MainListParameters();
  exportType = ExportType;
  isAllSelected = false;
  noDataFound = false;
  isSelected = false;
  isDisable = true;
  isEdit: boolean;

  @Input() triggerEditSubContractorData: Observable<any>;
  @Input() giftAidId: Guid;
  @Input() startDate: any;
  @Input() endDate: any;
  @ViewChild(MatSort) sort: MatSort;

  private destroy$ = new Subject<void>();

  isTotalPaymentValid = true;
  datepipe: DatePipe;

  constructor(
    private store: Store,
    private injector: Injector,
    private spinner: NgxSpinnerService
  ) {
    this.datepipe = injector.get<DatePipe>(DatePipe);
  }

  ngOnInit(): void {
    this.isEdit = false;
    this.getList();

    this.triggerEditSubContractorData?.subscribe((data) => {
      this.isEdit = true;
      this.subContactorList = data;
    });
  }

  export(format: number): void {
    this.store
      .dispatch(
        new GiftAidDonationDetailListExport(
          this.getExportParamter(format),
          this.giftAidId
        )
      )
      .subscribe();
  }

  getExportParamter(format): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe.transform(this.startDate, 'yyyy-MM-dd')?.toString() ??
        null,
      endDate:
        this.datepipe.transform(this.endDate, 'yyyy-MM-dd')?.toString() ?? null,
      format: format,
    };
    return queryParams;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe.transform(this.startDate, 'yyyy-MM-dd')?.toString() ??
        null,
      endDate:
        this.datepipe.transform(this.endDate, 'yyyy-MM-dd')?.toString() ?? null,
    };
    return queryParams;
  }

  getList(): void {
    if (!this.isEdit) {
      this.spinner.show();
      this.store
        .dispatch(
          new GetSubContractorDetailsList(this.getParamter(), this.giftAidId)
        )
        .subscribe((res) => {
          this.spinner.hide();
          this.displayedColumns.push('id');
          res.giftAidDetails.subContractorData.resultSet.columns.forEach(
            (element) => {
              this.displayedColumns.push(element.name);
            }
          );
          this.subContactorList =
            res.giftAidDetails.subContractorData.resultSet.data;
        });
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList();
  }

  checkSelectAll(event: any): void {
    let isAllSelected = true;
    this.subContactorList.forEach((element) => {
      if (!element.isIncluded) {
        isAllSelected = false;
      }
    });
    this.isAllSelected = isAllSelected;
    this.isSelected = event.checked ? true : false;
  }

  selectAll(event: any): void {
    this.isAllSelected = event.checked ? true : false;
    this.subContactorList.forEach((x) => (x.isIncluded = event.checked));
  }
}
