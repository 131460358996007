import { Guid } from 'guid-typescript';
export class GetNoteParamModel {
  id?: number;
  accountingPeriodId: Guid;
  ids: Array<number>;
  isPrint?: boolean;
}

export class GetNoteModel {
  calculativeData: any;
  userInputData: NoteDataModel;
}

export class NoteDataModel {
  id?: Guid;
  accountingPeriodId: Guid;
  noteId: number;
  data: JSON;
  percentageData: JSON;
  checkBoxData: any;
}
