export enum Notes {
  Note1 = 1,
  Note2 = 2,
  Note3 = 3,
  Note4 = 4,
  Note5 = 5,
  Note6 = 6,
  Note7 = 7,
  Note8 = 8,
  Note9 = 9,
  Note10 = 10,
  Note11 = 11,
  Note12 = 12,
  Note13 = 13,
  Note14 = 14,
  Note15 = 15,
  Note16 = 16,
  Note17 = 17,
  Note18 = 18,
  Note19 = 19,
  Note20 = 20,
  Note21 = 21,
  Note22 = 22,
  Note23 = 23,
  Note24 = 24,
  Note25 = 25,
  Note26 = 26,
  Note27 = 27,
  Note28 = 28,
  Note29 = 29,
  Note30 = 30,
  Note31 = 31,
  Note32 = 32,
  Note33 = 33,
  Note34 = 34,
  Note35 = 35,
  Note36 = 36,
  Note37 = 37,
  Note38 = 38,
  Note39 = 39,
  Note40 = 40,
  Note41 = 41,
  Note42 = 42,
  Note43 = 43,
  Note44 = 44,
  Note45 = 45,
  Note46 = 46,
}
