<div class="content-body pt-0">
  <div class="form-field-title">
    <p>
      {{
        (moduleId$ | async) === moduleEnum.FixedAssets
          ? "Asset Details"
          : "Produt Details"
      }}
    </p>
  </div>
  <div class="sidenav-table sidenav-table-input wp-100">
    <div class="mat-table-wrapper product-detail-table">
      <form [formGroup]="formProductDetail">
        <ng-container formArrayName="productDetailArray">
          <table
            aria-describedby="product-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="srNo">
              <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex">
                  {{ i + 1 }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="product">
              <th mat-header-cell *matHeaderCellDef>
                {{
                  (moduleId$ | async) === moduleEnum.FixedAssets
                    ? "Asset"
                    : "Product"
                }}
                <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="fill" class="wp-100">
                  <input
                    cdkFocusInitial
                    type="text"
                    matInput
                    formControlName="productName"
                    [matAutocomplete]="auto"
                    maxlength="{{ maxLength.maxLength200 }}"
                    (keyup)="onProductSearch($event, i)"
                    (change)="onProductChanged($event, i)"
                    (mousedown)="resetAccountList(element)"
                  />
                  <mat-icon class="downarrow" matSuffix
                    >keyboard_arrow_down</mat-icon
                  >
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    role="listbox"
                    #input
                    (opened)="scrollIntoView(element)"
                    (opened)="(input.opened)"
                    (optionSelected)="onProductChange($event.option.value, i)"
                  >
                    <mat-option
                      *ngFor="let option of productTypeList"
                      [value]="option"
                      title="{{ option.name }}"
                      [class]="
                        productDetailArray.controls[i].get('productName').value
                          .id === option.id
                          ? 'mat-selected'
                          : ''
                      "
                    >
                      {{ option.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="widthemail"
              >
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    maxlength="{{ maxLength.maxLength200 }}"
                    [value]="element.description"
                    autocomplete="off"
                    formControlName="description"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="qty">
              <th mat-header-cell *matHeaderCellDef style="width: 60px">Qty</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    allow_13_2_Decimal
                    matInput
                    [value]="element.qty"
                    autocomplete="off"
                    formControlName="qty"
                    (change)="qtyChanges(i)"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef style="width: 80px">
                Price<span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field>
                  <input
                    allow_10_2_Decimal
                    zeroNotAllowed
                    matInput
                    [value]="element.price"
                    autocomplete="off"
                    formControlName="price"
                    (change)="qtyChanges(i)"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="netAmount">
              <th mat-header-cell *matHeaderCellDef>Net Amount</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    allow_14_2_Decimal
                    matInput
                    [value]="element.netAmount"
                    autocomplete="off"
                    formControlName="netAmount"
                    maxlength="10"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="vatrate" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef>VAT Rate</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field>
                  <mat-select
                    [disableOptionCentering]="true"
                    formControlName="vatRate"
                    dropdownPosition="top"
                    panelClass="mat-select-position"
                    (selectionChange)="qtyChanges(i)"
                  >
                    <mat-option
                      *ngFor="let option of vatRateList"
                      [value]="option.id"
                      title="{{ option.name }}"
                    >
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="vatammount" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef>VAT Amount</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    allow_14_2_Decimal
                    matInput
                    [value]="element.vatAmount"
                    autocomplete="off"
                    formControlName="vatAmount"
                    (change)="qtyChanges(i)"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>
                Gross Amount <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    allow_14_2_Decimal
                    matInput
                    [value]="element.amount"
                    autocomplete="off"
                    formControlName="amount"
                    maxlength="{{ maxLength.maxLength10 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-15"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  class="close-btn"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onDeleteProductDetails(i)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayProductDetailsColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayProductDetailsColumns"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>
    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
          >Add New Line</a
        >
        <a
          class="link mr-1"
          *ngIf="productDetailArray?.length > 0"
          href="javascript:void(0)"
          (click)="clearForm()"
          >Clear All Lines</a
        >
      </div>
    </div>
  </div>
</div>
