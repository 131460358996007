import { RecurringModule } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateRecurringModule {
  static readonly type = '[RECURRING] Create  Recurring  Data ';

  constructor(public recurring: RecurringModule, public moduleId: number) {}
}

export class GetDataByRecurringModuleId {
  static readonly type = '[RECURRING] Get Recurring  Data By  Recurring  Id';

  constructor(public recurringId: Guid) {}
}

export class DeleteRecurringData {
  static readonly type = '[RECURRING] Delete  Recurring Data';

  constructor(public recurringIds: Array<Guid>) {}
}

export class ArchiveAndRestoreRecurring {
  static readonly type =
    '[RECURRING] Archive and Restore Selected Recurring Data';

  constructor(public recurringIds: Array<Guid>, public isArchive: boolean) {}
}

export class GetRecurringNumber {
  static readonly type = '[RECURRING] Get Recurring Number';

  constructor(public moduleId: number) {}
}
