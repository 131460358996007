import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationTextMessage } from '@app/core/Enum';
import { VATReturnDateModel } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import { GetVatReturnDate, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-submit-vat',
  templateUrl: './add-submit-vat.component.html',
  styleUrls: ['./add-submit-vat.component.scss'],
})
export class AddSubmitVatComponent implements OnInit {
  submitVatForm: FormGroup;

  @Input() triggerEditData: Observable<any>;

  defaultCurrency: Guid;
  periodicDate: any;
  vatReturnDate: VATReturnDateModel;
  notificationMessage = NotificationTextMessage;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.getVatReturnDate();
    this.triggerEditData?.subscribe((data) => {
      this.editVatDetails(data);
    });
    this.submitVatForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.submitVatForm.touched;
    });
  }

  editVatDetails(data): void {
    this.submitVatForm.patchValue({
      fromDate: data.fromDate,
      toDate: data.toDate,
      description: data.description,
      isVat: data.isVat,
    });
  }

  setForm(): void {
    this.submitVatForm = new FormGroup({
      fromDate: new FormControl(new Date()),
      toDate: new FormControl(new Date()),
      description: new FormControl(''),
      isVat: new FormControl(''),
    });
  }

  getVatReturnDate(): void {
    this.store.dispatch(new GetVatReturnDate()).subscribe((res) => {
      this.vatReturnDate = res.submitVat.vatReturnDate;
      this.submitVatForm.controls.fromDate.setValue(
        this.vatReturnDate.fromDate
      );
      this.submitVatForm.controls.toDate.setValue(this.vatReturnDate.toDate);
    });
  }
}
