<div class="content-body content-body-scroll">
  <form [formGroup]="customAccountForm">
    <div class="d-flex" [ngClass]="!isCashInHandGroup ? 'gap-40' : 'mr-40'">
      <div class="form-field">
        <p>Account Group</p>

        <ng-select
          #chartOfAccountGroup
          (focus)="chartOfAccountGroup.open()"
          (valueChange)="chartOfAccountGroup.close()"
          [disableOptionCentering]="true"
          formControlName="chartOfAccountGroup"
          [disabled]="isReadOnly"
          (change)="onChartOfAccountGroupChange($event)"
          appAutoFocusDirective
        >
          <ng-option
            *ngFor="let cl of chartOfAccountGroupList"
            [value]="cl.id"
            title="{{ cl.name }}"
          >
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="!isCashInHandGroup">
        <p *ngIf="!isFixedAssestsGroup">Account Type</p>
        <p *ngIf="isFixedAssestsGroup">Account Name</p>
        <ng-select
          *ngIf="!isFixedAssestsGroup"
          #chartOfAccountType
          (focus)="chartOfAccountType.open()"
          (valueChange)="chartOfAccountType.close()"
          [disableOptionCentering]="true"
          formControlName="chartOfAccountType"
          [disabled]="isReadOnly"
          appAutoFocusDirective
        >
          <ng-option
            *ngFor="let clt of chartOfAccountTypeList"
            [value]="clt.id"
          >
            <div title="{{ clt.name }}">{{ clt.name }}</div>
          </ng-option>
        </ng-select>
        <mat-form-field *ngIf="isFixedAssestsGroup">
          <input
            matInput
            formControlName="chartOfAccountType"
            autocomplete="off"
            appAutoFocusDirective
          />
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!isFixedAssestsGroup" class="d-flex gap-40 align-items-start">
      <div class="form-field">
        <p>Account Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="accountName"
            maxlength="{{ maxLength.maxLength200 }}"
            autocomplete="off"
            appAutoFocusDirective
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Account Code <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            maxlength="{{ maxLength.maxLength50 }}"
            formControlName="accountCode"
            autocomplete="off"
            (blur)="validateAccountCode()"
          />
        </mat-form-field>
        <mat-error *ngIf="!isAccountCodeNotExists && suggestedCodes.length > 0">
          Suggested Codes: {{ suggestedCodes.join(", ") }}</mat-error
        >
      </div>
    </div>
    <div
      [ngClass]="[isCashInHandGroup === false ? 'hidden' : '']"
      class="d-flex gap-40 align-items-center"
    >
      <div class="form-field">
        <p>Bank Account Type <span class="text-danger-300">*</span></p>
        <ng-select
          #bankAccountType
          (focus)="bankAccountType.open()"
          (valueChange)="bankAccountType.close()"
          [disableOptionCentering]="true"
          formControlName="bankAccountType"
          panelClass="mat-select-position"
          [required]="isCashInHandGroup"
        >
          <ng-option *ngFor="let cl of accountTypeList" [value]="cl.id">
            <div title="{{ cl.name }}">
              {{ cl.name }}
            </div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Account No <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            type="text"
            maxlength="{{ maxLength.maxLength100 }}"
            formControlName="accountNo"
            autocomplete="off"
            [required]="isCashInHandGroup"
            oninput="this.value = this.value.replace(/[^0-9\+]/g, '').replace(/(\.*)\./g, '$1');"
          />
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="isCashInHandGroup" class="d-flex gap-40 align-items-center">
      <div class="form-field">
        <p>Sort/Branch Code</p>
        <mat-form-field>
          <input
            matInput
            formControlName="sortBranchCode"
            maxlength="{{ maxLength.maxLength25 }}"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Currency</p>
        <ng-select
          #currency
          (focus)="currency.open()"
          (valueChange)="currency.close()"
          [disableOptionCentering]="true"
          formControlName="currency"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
            <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
              {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
            </div>
          </ng-option>
        </ng-select>
      </div>
    </div>
  </form>
</div>
