<mat-form-field>
  <mat-select
    [disableOptionCentering]="true"
    multiple
    (selectionChange)="getSelectedValue($event.value)"
    disableRipple
  >
    <mat-select-trigger>
      <span>{{ selectedName }}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let lt of list" [value]="lt.id" title="{{ lt.name }}">{{
      lt.name
    }}</mat-option>
  </mat-select>
</mat-form-field>
