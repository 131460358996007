<div class="d-flex h-100">
  <div class="notes-side-list-wrapper">
    <div class="d-flex h-100">
      <div class="side-list">
        <div class="side-list-wrapper h-100">
          <div
            class="d-flex justify-space-between align-items-center side-list-header"
          >
            <div class="d-flex align-items-center">
              <span class="clamp side-list-header-text" title="Notes">
                Notes</span
              >
            </div>
            <div class="side-list-icon">
              <button
                mat-button
                class="search"
                disableRipple
                (click)="toggleSearchBar()"
              >
                <mat-icon [ngClass]="showSearchBar ? 'text-primary' : ''"
                  >search</mat-icon
                >
              </button>
            </div>
          </div>
          <div class="side-list-search" *ngIf="showSearchBar">
            <mat-form-field
              appearance="fill"
              class="mini-search-field search-field"
            >
              <input
                matInput
                placeholder="Search"
                autocomplete="off"
                [(ngModel)]="searchText"
                (ngModelChange)="onSearchAll()"
                (keyup.enter)="onSearch()"
              />

              <button mat-button class="search-button" (click)="onSearch()">
                <mat-icon>search</mat-icon>
              </button>
              <a
                href="javascript:void(0)"
                (click)="onCancel()"
                (click)="searchText = ''"
              >
                <mat-icon class="search-close">highlight_off</mat-icon>
              </a>
            </mat-form-field>
          </div>
          <div
            class="mat-table-wrapper"
            [ngClass]="{ 'mat-table-wrapper-full': showSearchBar }"
          >
            <table
              aria-describedby="side-list"
              mat-table
              [dataSource]="dataSource"
            >
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [ngClass]="{ highlightRecord: isActive(element) }"
                >
                  <div class="list-wrapper">
                    <div class="d-flex align-items-center">
                      <a
                        href="javascript:void(0)"
                        class="text-gray-100 list-name clamp"
                        title="{{ element.name }}"
                        (click)="noteClick(element)"
                        >{{ element.name }}</a
                      >
                    </div>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form notes">
    <div class="content-body-title">
      <p class="header-text d-flex justify-space-between align-items-center">
        {{ headerText }}
      </p>
      <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
        <mat-icon class="material-icons-outlined">error_outline</mat-icon>
        {{ commonNotificationText.ViewOnlyAccessMessage }}
      </div>
      <div class="d-flex gap-20">
        <form [formGroup]="noteForm">
          <div class="content-right-button d-flex align-items-center gap-10">
            <span class="fw-bold">Period: </span>
            <mat-form-field class="w-200">
              <mat-select
                panelClass="mat-select-position"
                [disableOptionCentering]="true"
                formControlName="accountingPeriod"
                (selectionChange)="editNotes(true)"
              >
                <mat-option
                  *ngFor="let option of periodicDateList"
                  [value]="option.id"
                  title=" {{ option.fromDate | date: 'dd-LLL-yyyy' }} -
                {{ option.toDate | date: 'dd-LLL-yyyy' }}"
                >
                  {{ option.fromDate | date: "dd-LLL-yyyy" }} -
                  {{ option.toDate | date: "dd-LLL-yyyy" }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
        <button
          *ngIf="notesModuleId === notesEnum.Note1 && thisperioicbuttonDisaply"
          class="action-button primary-button"
          mat-button
          disableRipple
          (click)="onCopyClick()"
        >
          Copy from previous year
        </button>
      </div>
    </div>
    <div class="content-body-wrapper">
      <div class="content-body">
        <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
          <app-note-1
            [hidden]="notesModuleId !== notesEnum.Note1"
            [triggerEditData]="triggerEditData"
          >
          </app-note-1>
          <app-note2
            [hidden]="notesModuleId !== notesEnum.Note2"
            [triggerEditData]="triggerEditData"
          ></app-note2>
          <app-note3
            [hidden]="notesModuleId !== notesEnum.Note3"
            [triggerEditData]="triggerEditData"
          ></app-note3>
          <app-note4
            [hidden]="notesModuleId !== notesEnum.Note4"
            [triggerEditData]="triggerEditData"
          ></app-note4>
          <app-note5
            [hidden]="notesModuleId !== notesEnum.Note5"
            [triggerEditData]="triggerEditData"
          ></app-note5>
          <app-note6
            [hidden]="notesModuleId !== notesEnum.Note6"
            [triggerEditData]="triggerEditData"
          ></app-note6>
          <app-note7
            [hidden]="notesModuleId !== notesEnum.Note7"
            [triggerEditData]="triggerEditData"
          ></app-note7>
          <app-note8
            [hidden]="notesModuleId !== notesEnum.Note8"
            [triggerEditData]="triggerEditData"
          ></app-note8>
          <app-note9
            [hidden]="notesModuleId !== notesEnum.Note9"
            [triggerEditData]="triggerEditData"
          ></app-note9>
          <app-note10
            [hidden]="notesModuleId !== notesEnum.Note10"
            [triggerEditData]="triggerEditData"
          ></app-note10>
          <app-note11
            [hidden]="notesModuleId !== notesEnum.Note11"
            [triggerEditData]="triggerEditData"
          ></app-note11>
          <app-note12
            [hidden]="notesModuleId !== notesEnum.Note12"
            [triggerEditData]="triggerEditData"
          ></app-note12>
          <app-note13
            [hidden]="notesModuleId !== notesEnum.Note13"
            [triggerEditData]="triggerEditData"
          ></app-note13>
          <app-note14
            [hidden]="notesModuleId !== notesEnum.Note14"
            [triggerEditData]="triggerEditData"
          ></app-note14>
          <app-note15
            [hidden]="notesModuleId !== notesEnum.Note15"
            [triggerEditData]="triggerEditData"
          ></app-note15>
          <app-note16
            [hidden]="notesModuleId !== notesEnum.Note16"
            [triggerEditData]="triggerEditData"
          ></app-note16>
          <app-note17
            [hidden]="notesModuleId !== notesEnum.Note17"
            [triggerEditData]="triggerEditData"
          ></app-note17>
          <app-note18
            [hidden]="notesModuleId !== notesEnum.Note18"
            [triggerEditData]="triggerEditData"
          ></app-note18>
          <app-note19
            [hidden]="notesModuleId !== notesEnum.Note19"
            [triggerEditData]="triggerEditData"
          ></app-note19>
          <app-note20
            [hidden]="notesModuleId !== notesEnum.Note20"
            [triggerEditData]="triggerEditData"
          ></app-note20>
          <app-note21
            [hidden]="notesModuleId !== notesEnum.Note21"
            [triggerEditData]="triggerEditData"
          ></app-note21>
          <app-note22
            [hidden]="notesModuleId !== notesEnum.Note22"
            [triggerEditData]="triggerEditData"
          ></app-note22>
          <app-note23
            [hidden]="notesModuleId !== notesEnum.Note23"
            [triggerEditData]="triggerEditData"
          ></app-note23>
          <app-note24
            [hidden]="notesModuleId !== notesEnum.Note24"
            [triggerEditData]="triggerEditData"
          ></app-note24>
          <app-note25
            [hidden]="notesModuleId !== notesEnum.Note25"
            [triggerEditData]="triggerEditData"
          ></app-note25>
          <app-note26
            [hidden]="notesModuleId !== notesEnum.Note26"
            [triggerEditData]="triggerEditData"
          ></app-note26>
          <app-note27
            [hidden]="notesModuleId !== notesEnum.Note27"
            [triggerEditData]="triggerEditData"
          ></app-note27>
          <app-note28
            [hidden]="notesModuleId !== notesEnum.Note28"
            [triggerEditData]="triggerEditData"
          ></app-note28>
          <app-note29
            [hidden]="notesModuleId !== notesEnum.Note29"
            [triggerEditData]="triggerEditData"
          ></app-note29>
          <app-note30
            [hidden]="notesModuleId !== notesEnum.Note30"
            [triggerEditData]="triggerEditData"
          >
          </app-note30>
          <app-note31
            [hidden]="notesModuleId !== notesEnum.Note31"
            [triggerEditData]="triggerEditData"
          >
          </app-note31>
          <app-note32
            [hidden]="notesModuleId !== notesEnum.Note32"
            [triggerEditData]="triggerEditData"
          >
          </app-note32>
          <app-note33
            [hidden]="notesModuleId !== notesEnum.Note33"
            [triggerEditData]="triggerEditData"
          >
          </app-note33>
          <app-note34
            [hidden]="notesModuleId !== notesEnum.Note34"
            [triggerEditData]="triggerEditData"
          >
          </app-note34>
          <app-note35
            [hidden]="notesModuleId !== notesEnum.Note35"
            [triggerEditData]="triggerEditData"
          >
          </app-note35>
          <app-note36
            [hidden]="notesModuleId !== notesEnum.Note36"
            [triggerEditData]="triggerEditData"
          >
          </app-note36>
          <app-note37
            [hidden]="notesModuleId !== notesEnum.Note37"
            [triggerEditData]="triggerEditData"
          >
          </app-note37>
          <app-note38
            [hidden]="notesModuleId !== notesEnum.Note38"
            [triggerEditData]="triggerEditData"
          >
          </app-note38>
          <app-note39
            [hidden]="notesModuleId !== notesEnum.Note39"
            [triggerEditData]="triggerEditData"
          >
          </app-note39>
          <app-note40
            [hidden]="notesModuleId !== notesEnum.Note40"
            [triggerEditData]="triggerEditData"
          >
          </app-note40>
          <app-note41
            [hidden]="notesModuleId !== notesEnum.Note41"
            [triggerEditData]="triggerEditData"
          >
          </app-note41>
          <app-note42
            [hidden]="notesModuleId !== notesEnum.Note42"
            [triggerEditData]="triggerEditData"
          >
          </app-note42>
          <app-note43
            [hidden]="notesModuleId !== notesEnum.Note43"
            [triggerEditData]="triggerEditData"
          >
          </app-note43>
        </div>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave()"
      (triggerOnCancelClick)="onCancel()"
    >
    </app-buttons>
  </div>
</div>
