import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NotificationHeader, NotificationTextMessage } from '@app/core/Enum';
import {
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import { GetImportTemplate, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

function endDateValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  const startDate = control.parent?.get('startDate')?.value;
  const endDate = control.value;

  if (!endDate) {
    return { required: true };
  }

  if (startDate && endDate && startDate > endDate) {
    return { endDateLessThanStartDate: true };
  }

  return null;
}

@Component({
  selector: 'app-gift-aid-details',
  templateUrl: './gift-aid-details.component.html',
  styleUrls: ['./gift-aid-details.component.scss'],
})
export class GiftAIDDetailsComponent implements OnInit {
  giftAIDDetailsForm: FormGroup;
  isEdit: boolean;
  isExpanded = true;
  selectedFileName: string;
  fileInfo: string = '';
  selectedFile: File;
  url: any;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  moduleId: number;

  @Input() triggerEditData: Observable<any>;

  private destroy$ = new Subject<void>();
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  constructor(
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private notifier: NotificationService,
    private modulePermission: ModulePermission
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
    });

    this.isEdit = false;

    this.triggerEditData.subscribe((data) => {
      this.isEdit = true;
      this.editGiftAID(data);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setForm(): void {
    this.giftAIDDetailsForm = new FormGroup({
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required, endDateValidator]),
    });
  }

  editGiftAID(data): void {
    this.giftAIDDetailsForm.patchValue({
      startDate: data.fromDate,
      endDate: data.toDate,
    });
  }

  checkSpecialChar(selectedFile: any): any {
    const format = /[!@#$%^&*():"<>?{}|]/;
    return format.test(selectedFile.name);
  }

  formatBytes(bytes: number): string {
    const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = 1024;
    let index = 0;
    while (bytes >= factor) {
      bytes /= factor;
      index++;
    }
    return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
  }

  expandPanel(): void {
    this.isExpanded = !this.isExpanded;
  }

  onDragOver(event: Event) {
    event.preventDefault();
    // Add styles to the drop area to indicate it's a valid drop target.
    // For example, you can change the background color or border.
  }

  onDragLeave(event: Event) {
    event.preventDefault();
    // Remove the styles applied in onDragOver.
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    // Prevent the default behavior of opening the dropped file in the browser.
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files !== null && files !== undefined && files.length > 0) {
      // Handle the dropped files (e.g., upload or process them).
      this.handleDroppedFiles(files);
    }
  }

  handleDroppedFiles(files: FileList) {
    let file: any;
    // Handle the dropped or selected files here, e.g., by uploading them to a server.
    for (let i = 0; i < files.length; i++) {
      file = files[i];
      // Process or upload the file as needed.
    }
    this.onProcessFileSelected(file);
  }

  onFileSelected(input: HTMLInputElement): void {
    if (!input.files || !input.files.length) {
      return;
    }
    const file = input.files![0];
    this.onProcessFileSelected(file);
  }

  onProcessFileSelected(files: any): void {
    const file = files;
    this.selectedFileName = file.name;
    if (file.size / 1024 / 1024 > 10) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSizeExceeds
      );
      return;
    }

    if (this.checkSpecialChar(file)) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.filenameWithSpecialCharacter
      );
      return;
    }

    this.fileInfo = `${file.name} (${this.formatBytes(file.size)})`;

    this.selectedFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.url = reader.result;
    };
  }

  onFileOpen(event): void {
    event.target.value = null;
  }

  downloadTemplateFile(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetImportTemplate(this.moduleId))
      .subscribe((res) => {});
  }

  onFileRemoveClick(): void {
    this.fileInfo = '';
  }
}
