import { OpeningBalance, SaveOpeningBalanceModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class SaveOpeningBalance {
  static readonly type = '[OPENINGBALANCE] save Opening balance';

  constructor(public openingBalance: SaveOpeningBalanceModel) {}
}
export class SaveTrialBalance {
  static readonly type = '[TRIALBALANCE] save Opening balance';

  constructor(public trialBalance: SaveOpeningBalanceModel) {}
}

export class SaveOpeningBalanceExplain {
  static readonly type = '[OPENINGBALANCE] save Opening balance Explain';

  constructor(
    public openingBalanceExplain: Array<OpeningBalance>,
    public accountTypeId: number
  ) {}
}

export class SaveOpeningBalanceFundExplain {
  static readonly type = '[OPENINGBALANCE] save Opening balance Fund Explain';

  constructor(public openingBalanceFundExplain: Array<any>) {}
}

export class GetOpeningBalanceList {
  static readonly type = '[OPENINGBALANCE] Get Opening Balance Based On date ';
}
export class GetOpeningBalanceBasedOnAPId {
  static readonly type =
    '[OPENINGBALANCE] Get Opening Balance Based On Accouting Period Id ';

  constructor(public accoutingPeriodId: Guid) {}
}

export class GetFundOpeningBalance {
  static readonly type = '[OPENINGBALANCE] Get Fund Opening Balance';
}

export class GetFundOpeningBalanceBasedOnAPId {
  static readonly type =
    '[OPENINGBALANCE] Get Fund Opening Balance Based on Accouting Period Id ';
  constructor(public accoutingPeriodId: Guid) {}
}

export class SaveOpeningBalanceFundExplainBasedOnAPId {
  static readonly type =
    '[OPENINGBALANCE] save Opening balance Fund Explain Based On Accouting Period Id ';

  constructor(
    public openingBalanceFundExplain: Array<any>,
    public accountingPeriodId: Guid
  ) {}
}
