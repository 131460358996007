import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Modules, MTD, RoutingPath } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-include-exclude-transaction',
  templateUrl: './include-exclude-transaction.component.html',
  styleUrls: ['./include-exclude-transaction.component.scss'],
})
export class IncludeExcludeTransactionComponent implements OnInit {
  selectedTab = 0;
  moduleId = Modules.SubmitVat;
  triggerEditData: Subject<any> = new Subject<any>();

  triggerSaveExclude: Subject<any> = new Subject<any>();
  isIncludeDetailList = false;
  id = Guid.EMPTY as unknown as Guid;
  isRecordSelected = false;
  constructor(
    public commonService: CommonService,
    public dialog: MatDialog,
    public router: Router,
    public store: Store,
    public cookieService: CookieService
  ) {}

  ngOnInit(): void {}

  onTabClick(event: any): void {
    this.selectedTab = event;
  }

  goToInclude(): void {
    this.cookieService.set('detailListId', MTD.SubmitVat.toString());
    this.router.navigate([RoutingPath.IncludeExcludeDetail]);
  }

  excludeClick(): void {
    this.triggerSaveExclude.next(false);
  }

  triggerIsRecordSelected(event) {
    this.isRecordSelected = event;
  }

  onCancel(): void {
    this.ngOnInit();
  }
}
