import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Observable, Subject, Subscription } from 'rxjs';

import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { RecurringDonationModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateRecurringDonation,
  GetDataByRecurringDonationId,
  MenuState,
} from '@app/core/Store';
import { AddDonationComponent } from '@app/modules';
import {
  AddClosePopupComponent,
  AddRecurringModuleComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-recurring-donation',
  templateUrl: './recurring-donation.component.html',
  styleUrls: ['./recurring-donation.component.scss'],
})
export class RecurringDonationComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  recurringDonationId = Guid.EMPTY as unknown as Guid;

  moduleId = Modules.RecurringDonations;
  moduleName = ModuleName.RecurringDonations;

  subscriptionRouting: Subscription;
  recurringDonationData: RecurringDonationModel;

  triggerEditData: Subject<any> = new Subject<any>();

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddDonationComponent, { static: true })
  donationDetails;

  @ViewChild(AddRecurringModuleComponent, { static: true })
  recurringDetails;

  store: Store;
  commonService: CommonService;
  datepipe: DatePipe;
  _Activatedroute: ActivatedRoute;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  location: Location;
  renderer: Renderer2;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.recurringDonationId = atob(params.get('id')!) as unknown as Guid;
          this.editRecurringDonation();
        }
      }
    );
  }

  editRecurringDonation(): void {
    this.store
      .dispatch(new GetDataByRecurringDonationId(this.recurringDonationId))
      .subscribe((res) => {
        this.donationDetails.donationForm.markAsUntouched();
        this.recurringDetails.recurringForm.markAsUntouched();
        this.triggerEditData.next(res.recurringDonation.recurringDonationData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.recurringDonationId,
      isExit,
      Modules.RecurringDonations,
      RoutingPath.AddRecurringDonations
    );
  }

  onSave(isExit: boolean): void {
    if (this.donationDetails.donationForm.invalid) {
      this.commonService.addValidation(
        this.donationDetails.donationForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        if (this.recurringDonationData) {
          this.store
            .dispatch(new CreateRecurringDonation(this.recurringDonationData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.recurringDonationData = {
        id: this.recurringDonationId,
        donorId: this.donationDetails.donationForm.controls.donorName.value,
        sponsorEventId:
          this.donationDetails.donationForm.controls.sponsoredEvent.value === ''
            ? null
            : this.donationDetails.donationForm.controls.sponsoredEvent.value,
        activityId:
          this.donationDetails.donationForm.controls.activity.value === ''
            ? null
            : this.donationDetails.donationForm.controls.activity.value,
        fundId: this.donationDetails.donationForm.controls.fundName.value,
        depositToAccountId:
          this.donationDetails.donationForm.controls.depositTo.value,
        depositAmount:
          +this.donationDetails.donationForm.controls.depositAmount.value,

        donationDate: this.datepipe
          .transform(
            this.donationDetails.donationForm.controls.donationDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        incomeAccount:
          this.donationDetails.donationForm.controls.incomeAccount.value.id,
        donationAmount:
          +this.donationDetails.donationForm.controls.donationAmount.value,
        feeExpenseAccount:
          this.donationDetails.donationForm.controls.feeExpenseAccount.value.id,
        feeAmount: +this.donationDetails.donationForm.controls.feesAmount.value,
        benefitAmountToDonor:
          this.donationDetails.donationForm.controls.benefitAmountToDonor.value,
        note: this.donationDetails.donationForm.controls.notes.value,
        dueTermId:
          this.recurringDetails.recurringForm.controls.dueTermName.value,
        startDate: this.datepipe
          .transform(
            this.recurringDetails.recurringForm.controls.startDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        endDate: this.datepipe
          .transform(
            this.recurringDetails.recurringForm.controls.endDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        maxNumOfOccurrences:
          +this.recurringDetails.recurringForm.controls.maxNumOfOccurrences
            .value,
        recurringTypeId:
          this.recurringDetails.recurringForm.controls.recurringType.value,
        dayOfWeek: this.recurringDetails.recurringForm.controls.dayOfWeek.value,
        dayOfMonth:
          this.recurringDetails.recurringForm.controls.dayOfMonth.value,
        weekOfMonth:
          this.recurringDetails.recurringForm.controls.monthOfYear.value,
        monthOfYear:
          this.recurringDetails.recurringForm.controls.monthOfYear.value,

        separationCount:
          +this.recurringDetails.recurringForm.controls.separationCount.value,

        recurringEndTypeId:
          this.recurringDetails.recurringForm.controls.scheduleType.value,
        subRecurringTypeId:
          this.recurringDetails.recurringForm.controls.scheduleSubPeriod.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (
      isCancelClick &&
      !this.commonService.isEmpty(this.recurringDonationId)
    ) {
      this.editRecurringDonation();
    } else {
      this.recurringDonationId = Guid.EMPTY as unknown as Guid;
      this.donationDetails.ngOnInit();
      this.recurringDetails.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
