<div class="content-body pt-0">
  <div class="sidenav-table sidenav-table-input wp-100">
    <div class="mat-table-wrapper product-detail-table">
      <form [formGroup]="formDonationCodingDetail">
        <ng-container formArrayName="donationCodingDetailArray">
          <table
            aria-describedby="product-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="donorName">
              <th mat-header-cell *matHeaderCellDef>
                Donor Name <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <mat-select
                    #donorName
                    (focus)="donorName.open()"
                    (valueChange)="donorName.close()"
                    [disableOptionCentering]="true"
                    formControlName="donorName"
                    panelClass="mat-select-position"
                  >
                    <mat-option
                      *ngFor="let option of donorList"
                      [value]="option.id"
                      title="{{ option.name }}"
                    >
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="depositTo">
              <th mat-header-cell *matHeaderCellDef>
                Deposit To<span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <mat-select
                    #depositTo
                    [disableOptionCentering]="true"
                    (focus)="depositTo.open()"
                    (valueChange)="depositTo.close()"
                    formControlName="depositTo"
                    panelClass="mat-select-position"
                  >
                    <mat-option
                      *ngFor="let option of depositToList"
                      [value]="option.id"
                      title="{{ option.name }}"
                    >
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="incomeAccount">
              <th mat-header-cell *matHeaderCellDef>
                Income Account<span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="fill">
                  <input
                    type="text"
                    matInput
                    formControlName="incomeAccount"
                    required
                    [matAutocomplete]="accountGroup"
                    (keyup)="onAccountSearch($event)"
                    (mousedown)="resetAccountList(element)"
                  />
                  <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                  <mat-autocomplete
                    [displayWith]="getOptionText"
                    (opened)="scrollIntoView(element)"
                    #accountGroup="matAutocomplete"
                  >
                    <mat-optgroup
                      *ngFor="let group of incomeAccountList"
                      [label]="group.groupName"
                      title="{{ group.groupName }}"
                    >
                      <mat-option
                        *ngFor="let names of group.listModels"
                        [value]="names"
                        title="{{ names.name }}"
                        [class]="
                          donationCodingDetailArray.controls[i].get(
                            'incomeAccount'
                          ).value.id === names.id
                            ? 'mat-selected'
                            : ''
                        "
                      >
                        {{ names.name }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="notes">
              <th mat-header-cell *matHeaderCellDef>Note</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    autocomplete="off"
                    maxlength="{{ maxLength.maxLength2000 }}"
                    formControlName="notes"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="donationAmount">
              <th mat-header-cell *matHeaderCellDef class="w-100">
                Donation Amount
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    formControlName="donationAmount"
                    autocomplete="off"
                    allowDecimal
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-20"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  class="close-btn"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onDeleteDonationCodingDetails(i)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="
                displayDonationCodingDetailsColumns;
                sticky: true
              "
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayDonationCodingDetailsColumns"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>

    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
          >Add New Line</a
        >
        <a
          class="link mr-1"
          *ngIf="donationCodingDetailArray.length > 0"
          href="javascript:void(0)"
          (click)="clearForm()"
          >Clear All Lines</a
        >
      </div>
    </div>
  </div>
</div>
