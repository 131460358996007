<div class="content-header">
  <div
    class="content-header-input"
    [ngClass]="customDate ? 'content-header-custom-date' : ''"
  >
    <div class="d-flex justify-space-between wp-100">
      <button
        [disabled]="isViewPermission$ | async"
        class="action-button primary-button"
        mat-button
        disableRipple
        *ngIf="
          (hasAddPermission$ | async) &&
          (moduleId$ | async) !== modules.Import &&
          (moduleId$ | async) !== modules.BulkEdit &&
          (moduleId$ | async) !== modules.CashEntry &&
          (moduleId$ | async) !== modules.Dashboard &&
          !this.isBankImport &&
          !showAccountDetails &&
          this.transactionModuleId !== modules.BankImportTransactionHistory &&
          this.transactionModuleId !== modules.CashCoding
        "
        (click)="onAddClick()"
      >
        <mat-icon>add</mat-icon>
        <span
          *ngIf="
            (moduleId$ | async) !== modules.Activities &&
            (moduleId$ | async) !== modules.FixedAssetsRegister &&
            (moduleId$ | async) !== modules.BankDashboard &&
            (moduleId$ | async) !== modules.BankFeed &&
            (moduleId$ | async) !== modules.FundList
          "
        >
          Add {{ moduleName }}
        </span>
        <span *ngIf="(moduleId$ | async) === modules.BankDashboard">
          Add Bank Account</span
        >
        <span *ngIf="(moduleId$ | async) === modules.BankFeed">
          Link Bank Account</span
        >
        <span *ngIf="(moduleId$ | async) === modules.FundList"> Add Fund </span>
        <span *ngIf="(moduleId$ | async) === modules.Activities">
          Add Activity
        </span>

        <span *ngIf="(moduleId$ | async) === modules.FixedAssetsRegister">
          Depreciation
        </span>
      </button>

      <div
        class="d-flex wp-100 header-filters  header-count header-count-{{
          (headerList$ | async).length
        }} gap-15"
        *ngIf="(moduleId$ | async) !== modules.FixedAssetsRegister"
      >
        <div
          *ngFor="let item of headerList$ | async; let i = index"
          class="{{ item.name }}"
          [ngClass]="
            item.name === 'Datetime' && customDate ? 'custom-date' : ''
          "
        >
          <mat-form-field
            *ngIf="item.controlType === controlType.TextBox"
            appearance="fill"
            class="search-field"
          >
            <input
              matInput
              placeholder="Search here..."
              autocomplete="off"
              [(ngModel)]="searchText"
              (ngModelChange)="onSearchAll()"
              (keyup.enter)="onSearch()"
            />

            <button mat-button class="search-button" (click)="onSearch()">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
          <div
            *ngIf="item.controlType === controlType.DateTimePicker"
            class="year-picker"
          >
            <div class="d-flex align-items-center">
              <span class="fw-bold mr-1">Period:</span>
              <app-custom-year-picker
                [moduleId]="moduleId"
                (triggerDateChange)="triggerDateChange($event)"
              ></app-custom-year-picker>
            </div>
          </div>
          <div
            class="content-right-button d-flex align-items-center"
            *ngIf="item.controlType === controlType.Dropdown"
          >
            <span
              class="fw-bold mr-1"
              *ngIf="
                (moduleId$ | async) === modules.BankFeed ||
                (moduleId$ | async) === modules.BulkEdit ||
                (moduleId$ | async) === modules.AccountDetails ||
                (moduleId$ | async) === modules.NominalLedger
              "
            >
              Select Account:
            </span>
            <span
              class="fw-bold mr-1"
              *ngIf="
                (moduleId$ | async) === modules.CustomerAdvanceReport ||
                (moduleId$ | async) === modules.CustomerAgeingList ||
                (moduleId$ | async) === modules.TransactionsByCustomer ||
                (moduleId$ | async) === modules.CustomerReceipts ||
                (moduleId$ | async) === modules.Debtors ||
                (item.name === HeaderFilters.Module &&
                  (moduleId$ | async) === modules.CustomerStatement) ||
                (moduleId$ | async) === modules.InvoiceList
              "
            >
              Customer :
            </span>
            <span
              class="fw-bold mr-1"
              *ngIf="
                item.name !== HeaderFilters.Module &&
                (moduleId$ | async) === modules.CustomerStatement
              "
            >
              Type:
            </span>
            <span
              class="fw-bold mr-1"
              *ngIf="
                (moduleId$ | async) === modules.SupplierAdvanceReport ||
                (moduleId$ | async) === modules.SupplierAgeingList ||
                (moduleId$ | async) === modules.TransactionsBySupplier ||
                (moduleId$ | async) === modules.PaymentToSupplier ||
                (moduleId$ | async) === modules.Creditors ||
                (item.name === HeaderFilters.Module &&
                  (moduleId$ | async) === modules.SupplierStatement) ||
                (moduleId$ | async) === modules.BillList
              "
            >
              Supplier :
            </span>
            <span
              class="fw-bold mr-1"
              *ngIf="
                item.name !== HeaderFilters.Module &&
                ((moduleId$ | async) === modules.SupplierStatement ||
                  (moduleId$ | async) === modules.BankDashboard)
              "
            >
              Type:
            </span>
            <span
              class="fw-bold mr-1"
              *ngIf="(moduleId$ | async) === modules.DonorReport"
            >
              Donor :
            </span>
            <span
              class="fw-bold mr-1"
              *ngIf="(moduleId$ | async) === modules.SalesReport"
            >
              Period:
            </span>
            <div>
              <ng-select
                panelClass="mat-select-position"
                [disableOptionCentering]="true"
                #commonSelection
                (focus)="commonSelection.open()"
                (valueChange)="commonSelection.close()"
                [(ngModel)]="
                  item.name === HeaderFilters.Module
                    ? item.value.length > 0
                      ? commonService.defaultHeaderGuidValue
                      : ''
                    : defaultValue[i].id
                "
                (change)="onDropdownChange(item.name, $event, false)"
              >
                <ng-option *ngFor="let cl of item.value" [value]="cl.id">
                  <div title="{{ cl.name }}" class="clamp">{{ cl.name }}</div>
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div
            class="content-right-button d-flex align-items-center"
            *ngIf="item.controlType === controlType.GroupDropdown"
          >
            <span
              class="fw-bold mr-1"
              *ngIf="
                (moduleId$ | async) === modules.BankFeed ||
                (moduleId$ | async) === modules.BulkEdit ||
                (moduleId$ | async) === modules.AccountDetails ||
                (moduleId$ | async) === modules.NominalLedger
              "
            >
              Select Account:
            </span>
            <div>
              <ng-select
                #accountName
                [items]="accountGroupList"
                (focus)="accountName.open()"
                (valueChange)="accountName.close()"
                class="bank-account w-250"
                [disableOptionCentering]="true"
                [(ngModel)]="
                  item.name === HeaderFilters.Module
                    ? accountGroupList.length > 0
                      ? commonService.defaultHeaderGuidValue
                      : ''
                    : defaultValue[i].id
                "
                bindLabel="name"
                bindValue="id"
                groupBy="groupName"
                (ngModelChange)="onDropdownChange(item.name, $event, false)"
                panelClass="mat-select-position"
                appendTo="body"
              >
                <ng-template ng-option-tmp let-item="item">
                  <span class="ng-option-label" [attr.title]="item.name">{{
                    item.name
                  }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div
            class="content-right-button d-flex align-items-center"
            *ngIf="item.controlType === controlType.RefreshButton"
          >
            <div>
              <button
                class="action-button primary-button"
                mat-button
                disableRipple
                (click)="onRefereshClick()"
                [disabled]="isViewPermission$ | async"
              >
                <mat-icon>download</mat-icon>
                <span> Import From Bookkeeping</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        (moduleId$ | async) !== modules.BankFeed &&
        (moduleId$ | async) !== modules.BulkEdit &&
        (moduleId$ | async) !== modules.Import &&
        this.transactionModuleId !== modules.BankImportTransactionHistory &&
        this.transactionModuleId !== modules.CashCoding
      "
      class="toolbar"
    >
      <button mat-button [matMenuTriggerFor]="menu" disableRipple>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu
        #menu="matMenu"
        class="action-menu more-menu"
        xPosition="before"
      >
        <button
          *ngIf="
            ((moduleId$ | async) === modules.TrialBalance &&
              isTrialBalanceImported) ||
            ((moduleId$ | async) === modules.AccountProductionTrialBalance &&
              isAccountProductionTrialBalance)
          "
          mat-menu-item
          (click)="onEditClick()"
          disableRipple
        >
          <mat-icon fontSet="material-icons-outlined">edit</mat-icon>Edit
        </button>
        <button
          *ngIf="
            (moduleId$ | async) === modules.Invoices ||
            (moduleId$ | async) === modules.Bills ||
            (moduleId$ | async) === modules.Donations ||
            (moduleId$ | async) === modules.CreditNote ||
            (moduleId$ | async) === modules.DebitNote ||
            (moduleId$ | async) === modules.Customers ||
            (moduleId$ | async) === modules.Suppliers ||
            (moduleId$ | async) === modules.Donors ||
            (moduleId$ | async) === modules.BankDashboard ||
            (moduleId$ | async) === modules.AccountProductionTrialBalance
          "
          mat-menu-item
          (click)="import()"
          disableRipple
          [disabled]="(isViewPermission$ | async) || !isImportChangePermission"
          [ngClass]="
            (isViewPermission$ | async) || !isImportChangePermission
              ? 'cursor-not-allowed'
              : ''
          "
        >
          <mat-icon>save_alt</mat-icon>Import
        </button>

        <button
          mat-menu-item
          (click)="export(exportType.PDF, false)"
          disableRipple
          [disabled]="isViewPermission$ | async"
          [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
        >
          <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon
          >Export As PDF
        </button>

        <button
          *ngIf="
            (moduleId$ | async) === modules.Debtors ||
            (moduleId$ | async) === modules.Creditors
          "
          mat-menu-item
          (click)="export(exportType.ExportAsPDFOutstandingBal, false)"
          disableRipple
        >
          <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon>
          Export As PDF(OutStanding Bal.)
        </button>

        <button
          mat-menu-item
          (click)="export(exportType.Excel, false)"
          disableRipple
          [disabled]="isViewPermission$ | async"
          [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
        >
          <mat-icon>border_all</mat-icon>Export to Excel
        </button>

        <button
          *ngIf="
            (moduleId$ | async) === modules.Debtors ||
            (moduleId$ | async) === modules.Creditors
          "
          mat-menu-item
          (click)="export(exportType.ExportAsExcelOutstandingBal, false)"
          disableRipple
        >
          <mat-icon>border_all</mat-icon>
          Export As Excel(OutStanding Bal.)
        </button>

        <button
          *ngIf="(moduleId$ | async) !== modules.BankDashboard"
          mat-menu-item
          (click)="export(exportType.CSV, false)"
          disableRipple
          [disabled]="isViewPermission$ | async"
          [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
        >
          <mat-icon fontSet="material-icons-outlined">description</mat-icon
          >Export to CSV
        </button>

        <button
          *ngIf="
            (moduleId$ | async) === modules.Debtors ||
            (moduleId$ | async) === modules.Creditors
          "
          mat-menu-item
          (click)="export(exportType.ExportAsCSVOutstandingBal, false)"
          disableRipple
        >
          <mat-icon fontSet="material-icons-outlined">description</mat-icon>
          Export As CSV(OutStanding Bal.)
        </button>

        <button
          *ngIf="(moduleId$ | async) === modules.BankDashboard"
          mat-menu-item
          (click)="bankEntryClick(true)"
          disableRipple
          [disabled]="(isViewPermission$ | async) || !isJournalChangePermission"
          [ngClass]="
            (isViewPermission$ | async) || !isJournalChangePermission
              ? 'cursor-not-allowed'
              : ''
          "
        >
          <mat-icon fontSet="material-icons-outlined">bolt</mat-icon>Quick
          Journal
        </button>
        <button
          *ngIf="(moduleId$ | async) === modules.BankDashboard"
          mat-menu-item
          (click)="manualImportClick()"
          disableRipple
          [disabled]="(isViewPermission$ | async) || !isImportChangePermission"
          [ngClass]="
            (isViewPermission$ | async) || !isImportChangePermission
              ? 'cursor-not-allowed'
              : ''
          "
        >
          <mat-icon fontSet="material-icons-outlined">save_alt</mat-icon>Manual
          Import
        </button>

        <button
          *ngIf="(moduleId$ | async) === modules.BankDashboard"
          mat-menu-item
          (click)="bankEntryClick(false)"
          disableRipple
          [disabled]="isViewPermission$ | async"
          [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
        >
          <mat-icon fontSet="material-icons-outlined">view_list</mat-icon>Cash
          Coding
        </button>
      </mat-menu>
    </div>
  </div>
</div>
