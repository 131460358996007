import { Injectable } from '@angular/core';
import { DataType } from '@app/core/Enum';
import {
  HeaderModel,
  MainList,
  SubmitVATModel,
  VATReturnDateModel,
} from '@app/core/Models';
import { SubmitVatService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import { CommonStateInfo } from '../../common';
import {
  CreateVat,
  GetVatDetails,
  GetVatReturnDate,
  GetVatRemainingTransaction,
  CreateVATReturnStatus,
  CheckVatNumber,
  GetvatRemainingTransactionHeader,
  SaveIncludeExclude,
  SetDefaultVatId,
  SetSubmitVatId,
} from './submit-vat.action';

export class SubmitVatStateInfo {
  vatData?: SubmitVATModel;
  vatReturnDate?: VATReturnDateModel;
  mainList?: MainList;
  totalRecord?: number;
  actions?: any;
  isLoading?: boolean;
  vatDataId? = Guid.EMPTY as unknown as Guid;
  vatReturnStatusId? = Guid.EMPTY as unknown as Guid;
  checkVatNumber?: boolean;
  headerList?: Array<HeaderModel>;
}

@State<SubmitVatStateInfo>({
  name: 'submitVat',
  defaults: {},
})
@Injectable()
export class SubmitVatState {
  constructor(private submitVatService: SubmitVatService) {}

  @Selector()
  static vatDataId(state: SubmitVatStateInfo): any {
    return state.vatDataId;
  }

  @Selector()
  static headerList(state: SubmitVatStateInfo): Array<HeaderModel> {
    if (!state.headerList) {
      return [];
    }

    return state.headerList;
  }

  @Selector()
  static totalRecord(state: SubmitVatStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Selector()
  static actions(state: SubmitVatStateInfo): any {
    if (!state.mainList) {
      return [];
    }

    return state.actions;
  }

  @Selector()
  static columns(state: SubmitVatStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.mainList) {
      return [];
    }
    return state.mainList.resultSet.columns;
  }

  @Selector()
  static columnNames(state: SubmitVatStateInfo): Array<string> {
    if (!state.mainList) {
      return [];
    }

    return state.mainList.resultSet.columns.map((x) => x.name);
  }

  @Action(SaveIncludeExclude, { cancelUncompleted: true })
  saveIncludeExclude(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: SaveIncludeExclude
  ) {
    return this.submitVatService
      .saveIncludeExclude(action.params, action.id, action.isInclude)
      .pipe(
        tap((res) => {
          patchState({});
        })
      );
  }

  @Action(GetVatDetails)
  getVatDetails(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetVatDetails
  ) {
    return this.submitVatService.getVatDetail(action.id).pipe(
      tap((res) => {
        patchState({
          vatData: res,
        });
      })
    );
  }

  @Action(SetDefaultVatId)
  setDefaultVatId({ patchState }: StateContext<SubmitVatStateInfo>) {
    return patchState({
      vatDataId: Guid.EMPTY as unknown as Guid,
    });
  }

  @Action(SetSubmitVatId)
  setSubmitVatId(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: SetSubmitVatId
  ) {
    return patchState({
      vatDataId: action.id,
    });
  }

  @Action(CreateVat)
  createVat(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: CreateVat
  ) {
    return this.submitVatService.createVat(action.vatData).pipe(
      tap((res) => {
        patchState({
          vatDataId: res,
        });
      })
    );
  }

  @Action(GetVatReturnDate)
  getVatReturnDate(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetVatReturnDate
  ) {
    return this.submitVatService.getVatReturnDate().pipe(
      tap((res) => {
        patchState({
          vatReturnDate: res,
        });
      })
    );
  }

  @Action(GetVatRemainingTransaction, { cancelUncompleted: true })
  getVatRemainingTransaction(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetVatRemainingTransaction
  ) {
    return this.submitVatService
      .getVatRemainingTransaction(
        action.queryParams,
        action.isHeaderVisible,
        action.id
      )
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(CreateVATReturnStatus)
  createVATReturnStatus(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: CreateVATReturnStatus
  ) {
    return this.submitVatService
      .createVATReturnStatus(action.vatReturnStatus, action.id)
      .pipe(
        tap((res) => {
          patchState({
            vatReturnStatusId: res,
          });
        })
      );
  }

  @Action(CheckVatNumber)
  checkVatNumber(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: CheckVatNumber
  ) {
    return this.submitVatService.checkVatNumber().pipe(
      tap((res) => {
        patchState({
          checkVatNumber: res,
        });
      })
    );
  }

  @Action(GetvatRemainingTransactionHeader, { cancelUncompleted: true })
  getvatRemainingTransactionHeader(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetvatRemainingTransactionHeader
  ) {
    return this.submitVatService.getvatRemainingTransactionHeader().pipe(
      tap({
        next: (result) => {
          patchState({
            headerList: result,
          });
        },
      })
    );
  }
}
