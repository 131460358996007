import { Guid } from 'guid-typescript';

export class FundTransferModel {
  id: Guid;
  transferDate: Date;
  amount?: number;
  fromFundId: Guid;
  toFundId: Guid;
  note: string;
}
