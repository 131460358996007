import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-user-capacity-permission',
  templateUrl: './user-capacity-permission.component.html',
  styleUrls: ['./user-capacity-permission.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserCapacityPermissionComponent implements OnInit {
  capacityPermissionForm: FormGroup;

  userId = Guid.EMPTY as unknown as Guid;
  isDirty = false;
  isAddMode = true;

  error = '';

  @Input() triggerEditCapacityPermissionData: Observable<any>;

  subscriptionRouting: Subscription;
  defaultCapacityList: any = [];
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.setForm();

    this.triggerEditCapacityPermissionData.subscribe((data) => {
      if (data) {
        this.userId = data.id;
        this.editUser(data);
        this.isAddMode = false;
      }
    });
  }

  editUser(data): void {
    this.capacityPermissionForm.patchValue({
      addToAllFutureClients: data?.addToAllFutureCompany ?? false,
      addToAllExistingClients: data?.addToAllExistingCompany,
    });
  }

  setForm(): void {
    this.capacityPermissionForm = new FormGroup({
      addToAllFutureClients: new FormControl<boolean>(true),
      addToAllExistingClients: new FormControl<boolean>(true),
    });
  }
}
