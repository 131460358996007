import { Injectable } from '@angular/core';
import { IntegrationsService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  DefaultMail,
  DeleteMail,
  GetCompanyEmailSettingsList,
  GetCompanyMailPreference,
  GetUserMailList,
  PrepareGoogleOrOffice,
  SavecompanyEmailSetting,
  UpdateEmailConfig,
} from './integration.action';
import { Guid } from 'guid-typescript';

export class IntegrationStateInfo {
  integrationData?: any;
  emailSettingData?: any;
  isUpdated?: boolean;
  isDefault?: boolean;
  isDelete?: boolean;
  emailSettingId?: Guid;
}

@State<IntegrationStateInfo>({
  name: 'integration',
  defaults: {},
})
@Injectable()
export class IntegrationState {
  constructor(private integrationService: IntegrationsService) {}

  @Selector()
  static integrationData(state: IntegrationStateInfo): any {
    return state.integrationData;
  }

  @Action(GetCompanyMailPreference)
  getCompanyMailPreference(
    { setState }: StateContext<IntegrationStateInfo>,
    action: GetCompanyMailPreference
  ) {
    return this.integrationService.getCompanyMailPreference().pipe(
      tap((res) => {
        setState({
          integrationData: res,
        });
      })
    );
  }

  @Action(UpdateEmailConfig)
  updateEmailConfig(
    { patchState }: StateContext<IntegrationStateInfo>,
    action: UpdateEmailConfig
  ) {
    return this.integrationService
      .updateEmailConfig(action.isEmailSendFromCapium)
      .pipe(
        tap((res) => {
          patchState({
            isUpdated: res,
          });
        })
      );
  }

  @Action(DefaultMail)
  defaultMail(
    { setState }: StateContext<IntegrationStateInfo>,
    action: DefaultMail
  ) {
    return this.integrationService.defaultMail(action.isDefault).pipe(
      tap((res) => {
        setState({
          isDefault: res,
        });
      })
    );
  }

  @Action(DeleteMail)
  deleteMail(
    { setState }: StateContext<IntegrationStateInfo>,
    action: DeleteMail
  ) {
    return this.integrationService.deleteMail(action.id).pipe(
      tap((res) => {
        setState({
          isDelete: res,
        });
      })
    );
  }

  @Action(GetUserMailList)
  getUserMailList(
    { setState }: StateContext<IntegrationStateInfo>,
    action: GetUserMailList
  ) {
    return this.integrationService.getUserMailList(action.providerId).pipe(
      tap((res) => {
        setState({
          integrationData: res,
        });
      })
    );
  }

  @Action(PrepareGoogleOrOffice)
  prepareGoogleOrOffice(
    { patchState }: StateContext<IntegrationStateInfo>,
    action: PrepareGoogleOrOffice
  ) {
    return this.integrationService
      .prepareGoogleOrOfficeInt(action.providerRediredUrl)
      .pipe(
        tap((res) => {
          patchState({
            integrationData: res,
          });
        })
      );
  }

  @Action(SavecompanyEmailSetting)
  savecompanyEmailSetting(
    { patchState }: StateContext<IntegrationStateInfo>,
    action: SavecompanyEmailSetting
  ) {
    return this.integrationService
      .savecompanyEmailSetting(action.emailSetting)
      .pipe(
        tap((res) => {
          patchState({
            emailSettingId: res,
          });
        })
      );
  }

  @Action(GetCompanyEmailSettingsList)
  getCompanyEmailSettingsList(
    { setState }: StateContext<IntegrationStateInfo>,
    action: GetCompanyEmailSettingsList
  ) {
    return this.integrationService.getCompanyEmailSettingsList().pipe(
      tap((res) => {
        setState({
          emailSettingData: res,
        });
      })
    );
  }
}
