import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FundModel,
  OverviewFilterModel,
  SideListModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class FundService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createFund(Fund: FundModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Fund/save`,
      this.commonService.trimObjectSpace(JSON.stringify(Fund)),
      headers
    );
  }

  getDataByFundId(fundId: Guid): Observable<FundModel> {
    return this.http.get<FundModel>(
      `${environment.apiVersionUrl}Fund/get/${fundId}`
    );
  }

  deleteFund(fundIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(fundIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Fund/delete`,
      options
    );
  }

  copyFund(fundIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Fund/copyFund`,
      this.commonService.trimObjectSpace(JSON.stringify(fundIds)),
      headers
    );
  }

  archiveAndRestoreFund(
    fundIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Fund/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(fundIds)),
      options
    );
  }

  getFundBreakdownOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fund/fundByType`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getIncomeExpensesByFundTypeOverview(
    filterData: OverviewFilterModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fund/incomeExpensesByFundType`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getIncomeVsExpensesOverview(
    filterData: OverviewFilterModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fund/incomeVsExpenses`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getEndowmentCapitalOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fund/fundDataByFundTypeId`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getFundCardData(fundCardData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fund/fundCard`,
      this.commonService.trimObjectSpace(JSON.stringify(fundCardData)),
      headers
    );
  }

  getFundListBasedOnFundAccountType(
    accountId: Guid
  ): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Fund/GetFundListBasedOnFundAccountType/${accountId}`
    );
  }
}
