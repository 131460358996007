import { Component, ViewChild } from '@angular/core';

import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexNoData,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';

export type expensesChartOption = {
  noData: ApexNoData;
  series: any;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  lines: ApexGrid;
};

@Component({
  selector: 'app-dashboard-expenses',
  templateUrl: './dashboard-expenses.component.html',
  styleUrls: ['./dashboard-expenses.component.scss'],
})
export class DashboardExpensesComponent {
  @ViewChild('chart') chart: ChartComponent;
  public expensesChartOption: Partial<expensesChartOption>;

  constructor() {
    this.expensesChartOption = {
      series: [
        {
          name: 'distibuted',
          data: [60, 80, 25, 85, 45, 20, 10],
        },
      ],
      chart: {
        height: 300,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        labels: {
          show: true,
          rotate: 0,
        },
        categories: [
          ['Raising', 'Funds'],
          ['Charitable', 'Activities'],
          ['Support', 'Costs'],
          ['Finance', 'Costs'],
          ['Governance', 'Costs'],
          ['Separate mat.', 'Item of exp.'],
          'other',
        ],
      },
      legend: {
        show: false,
        offsetX: 0,
        offsetY: 8,
      },
      fill: {
        colors: [
          '#7B76D3',
          '#F4C64D',
          '#F2816F',
          '#BA5397',
          '#7A5295',
          '#B9CE64',
          '#92BDFF',
        ],
      },
      tooltip: {
        marker: {
          show: true,
        },
        enabled: true,
        fillSeriesColor: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
}
