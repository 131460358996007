import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modules } from '@app/core/Enum';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent {
  @Input() getModuleId: number;
  @Input() isSend: boolean = false;
  @Input() saveButtonShow: boolean;

  @Output()
  readonly triggerOnSaveClick = new EventEmitter<{
    isExit: boolean;
    isSaveAndSubmit?: boolean;
    isSaveAndInvite?: boolean;
  }>();
  @Output()
  readonly triggerOnSendClick = new EventEmitter<any>();

  @Output()
  readonly triggerOnCancelClick = new EventEmitter<any>();

  modules = Modules;

  onSave(
    isExit: boolean,
    isSaveAndSubmit?: boolean,
    isSaveAndInvite?: boolean
  ): void {
    this.triggerOnSaveClick.next({ isExit, isSaveAndSubmit, isSaveAndInvite });
  }

  onCancel(): void {
    this.triggerOnCancelClick.next();
  }

  onSend(isExit: boolean): void {
    this.triggerOnSendClick.next(isExit);
  }
}
