import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AccountTypeList,
  ActionType,
  DepreciationMethodType,
  DisposalType,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  FixedAssetDisposeModel,
  GlobalComponent,
  SideListModel,
  VatRateScheme,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateFixedAssetDispose,
  GetCustomBankAndCashAccount,
  GetDepreciationChargeByDate,
  GetFixedAssetDispose,
  GetFundNameList,
  GetVatRateList,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-dispose-asset',
  templateUrl: './edit-dispose-asset.component.html',
  styleUrls: ['./edit-dispose-asset.component.scss'],
})
export class EditDisposeAssetComponent implements OnInit {
  explainId = Guid.EMPTY as unknown as Guid;
  moduleId = Modules.FixedAssetsRegister;
  showVATApplicable = false;
  methodTypeList = DepreciationMethodType;

  disposeDetailData: any;
  depreciationCharge: any;
  maxEndDate: Date;
  minStartDate: Date;
  totalProfitLoss: number = 0;
  disposeSellType: number;
  actionType = ActionType;
  accountTypeList = AccountTypeList;
  vatRateList: VatRateScheme[];
  accountsList: any[] = [];
  fundNameList: SideListModel[];
  fixedAssetDisposeData: FixedAssetDisposeModel;
  lossProfitLable: string;
  assetDisposeForm: FormGroup;
  isVatRegistered: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<EditDisposeAssetComponent>,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    private globalComponent: GlobalComponent
  ) {
    this.explainId =
      data.id === undefined
        ? (Guid.EMPTY as unknown as Guid)
        : (atob(data.id!) as unknown as Guid);
    this.maxEndDate = new Date();
    this.disposeSellType = data.actionType;
  }

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.setForm();
    this.getFixedAssetDispose();
    this.spinner.show();
  }

  setForm(): void {
    this.assetDisposeForm = new FormGroup({
      acquiredDate: new FormControl({ value: '', disabled: true }),
      price: new FormControl({ value: '', disabled: true }),
      depreciationMethod: new FormControl({ value: '', disabled: true }),
      rate: new FormControl({ value: '', disabled: true }),
      depreciationCharged: new FormControl({ value: '', disabled: true }),
      date: new FormControl('', [Validators.required]),
      depreciationCharge: new FormControl({ value: '', disabled: true }),
      account: new FormControl('', [Validators.required]),
      disposePrice: new FormControl(''),
      notes: new FormControl(''),
      fundId: new FormControl(''),
      salePrice: new FormControl('', [Validators.required]),
      isVATApplicable: new FormControl(''),
      vat: new FormControl(''),
      vatRate: new FormControl({ value: '', disabled: true }),
      netSalePrice: new FormControl({ value: '', disabled: true }),
      totalProfit: new FormControl({ value: 0, disabled: true }),
    });
    this.getFundNameList();
    this.getVatRateList();
    this.getAccountList();
  }

  getFundNameList(): void {
    this.store
      .dispatch(new GetFundNameList())
      .pipe(
        tap((res) => {
          this.fundNameList = res.account.fundNameList;
          this.assetDisposeForm.controls.fundId.setValue(
            this.fundNameList[0].id
          );
        })
      )
      .subscribe();
  }

  getVatRateList(): void {
    this.store.dispatch(new GetVatRateList()).subscribe((res) => {
      this.vatRateList = res.common.vatRate;
    });
  }

  getAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAndCashAccount())
      .pipe(
        tap((res) => {
          this.accountsList = res.common.customBankAndCashAccount;
        })
      )
      .subscribe();
  }

  getFixedAssetDispose(): void {
    this.store
      .dispatch(new GetFixedAssetDispose(this.explainId))
      .subscribe((res) => {
        this.disposeDetailData = res.fixedAssetRegister.disposeDetailData;
        this.editFixedAssetDisposeDetails(this.disposeDetailData);
      });
  }

  getOptionText(option) {
    return option.name;
  }

  editFixedAssetDisposeDetails(data): void {
    this.minStartDate = data.entryDate;
    this.assetDisposeForm.patchValue({
      acquiredDate: data.entryDate,
      price: data.totalAmount,
      depreciationMethod: data.methodTypeId,
      rate: data.rate,
      depreciationCharged: data.totalDepreciation,
      date: data.disposeDate === null ? new Date() : data.disposeDate,
      depreciationCharge: this.depreciationCharge,
      account: data.transactionAccountId,
      disposePrice: data.disposalType,
      notes: data.description,
      vat: data.vatRateId,
      vatRate: data.vatAmount,
      netSalePrice: data.amountSold,
      isVATApplicable: data.isVATApplicable,
      totalProfit: data.totalProfit,
    });
    this.getDepreciationChargeByDate();
  }

  getDepreciationChargeByDate() {
    let date: any = moment(this.assetDisposeForm.controls.date.value.valueOf())
      .format('yyyy-MM-DD')
      .toString();
    this.store
      .dispatch(
        new GetDepreciationChargeByDate(this.disposeDetailData.id, date)
      )
      .subscribe((res) => {
        this.depreciationCharge = res.fixedAssetRegister.depreciationChargeDate;
      });
    setTimeout(() => {
      this.getTotalProfitLoss();
    }, 500);
  }

  getTotalProfitLoss() {
    if (this.disposeSellType === ActionType.Sell) {
      this.calVatAmount();
    } else {
      this.assetDisposeForm.get('account')?.clearValidators();
      this.assetDisposeForm.get('salePrice')?.clearValidators();
      this.assetDisposeForm.get('account')?.updateValueAndValidity();
      this.assetDisposeForm.get('salePrice')?.updateValueAndValidity();
      const totalProfit =
        +this.disposeDetailData.totalAmount -
        +this.disposeDetailData.totalDepreciation -
        +this.depreciationCharge;
      this.assetDisposeForm.controls.totalProfit.setValue(
        totalProfit.toFixed(2)
      );
      this.lossProfitLable = totalProfit < 0 ? 'Loss' : 'Profit';
    }
  }

  onDepreciableAsset(event: any): void {
    if (event.checked) {
      this.showVATApplicable = true;
      this.getTotalProfitLoss();
    } else {
      const totalProfitLoss =
        this.totalProfitLoss - +this.assetDisposeForm.controls.vatRate.value;
      this.assetDisposeForm.controls.totalProfit.setValue(
        totalProfitLoss.toFixed(2)
      );
      this.showVATApplicable = false;
    }
  }

  onSave(): void {
    if (this.assetDisposeForm.invalid) {
      this.assetDisposeForm.markAllAsTouched();
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.fixedAssetDisposeData) {
          this.store
            .dispatch(new CreateFixedAssetDispose(this.fixedAssetDisposeData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                  this.dialogRef.close();
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.fixedAssetDisposeData = {
        id: this.disposeDetailData.id,
        fundId: this.assetDisposeForm.controls.fundId.value,
        name: this.disposeDetailData.name,
        referenceTypeId: this.disposeDetailData.referenceTypeId,
        bookAccountId: this.disposeDetailData.bookAccountId,
        referenceId: this.disposeDetailData.referenceId,
        methodTypeId: this.disposeDetailData.methodTypeId,
        rate: this.disposeDetailData.rate,
        isDepreciable: this.disposeDetailData.isDepreciable,
        totalAmount: this.disposeDetailData.totalAmount,
        totalDepreciation: this.disposeDetailData.totalDepreciation,
        currentDepreciation:
          +this.assetDisposeForm.controls.depreciationCharge.value,
        entryDate: this.disposeDetailData.entryDate,
        isDisposed: true,
        disposalType:
          this.disposeSellType === ActionType.Sell
            ? DisposalType.Sell
            : DisposalType.Disposed,
        disposeDate: this.assetDisposeForm.controls.date.value,
        amountSold:
          this.assetDisposeForm.controls.salePrice.value === ''
            ? null
            : this.assetDisposeForm.controls.salePrice.value,
        amountLoss: this.disposeDetailData.amountLoss,
        description: this.assetDisposeForm.controls.notes.value,
        transactionAccountId: this.assetDisposeForm.controls.account.value,
        disposeJournalId: this.disposeDetailData.disposeJournalId,
        transactionJournalId: this.disposeDetailData.transactionJournalId,
        isVATApplicable: this.showVATApplicable,
        vatRateId: this.assetDisposeForm.controls.vat.value,
        vatAmount: this.assetDisposeForm.controls.vatRate.value,
        netAmount: +this.assetDisposeForm.controls.totalProfit.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(): void {
    if (!this.commonService.isEmpty(this.explainId)) {
      this.getFixedAssetDispose();
    } else {
      this.explainId = Guid.EMPTY as unknown as Guid;
      this.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  calVatAmount(): void {
    var amount = 0;
    var netSalePrice = 0;
    var totalProfitLoss = 0;
    var vatRate = 0;
    if (this.vatRateList.length > 0) {
      this.vatRateList.forEach((element) => {
        if (element.id === this.assetDisposeForm.controls.vat.value) {
          vatRate = element.rate;
        }
      });

      amount =
        +this.assetDisposeForm.controls.salePrice.value -
        (+this.assetDisposeForm.controls.salePrice.value * 100) /
          (100 + vatRate);

      totalProfitLoss =
        +this.disposeDetailData.totalDepreciation +
        +this.assetDisposeForm.controls.depreciationCharge.value +
        ((+this.assetDisposeForm.controls.salePrice.value ?? 0) -
          (amount ?? 0)) -
        +this.disposeDetailData.totalAmount;

      if (amount) {
        this.assetDisposeForm.controls.vatRate.setValue(+amount.toFixed(2));
        netSalePrice = +this.assetDisposeForm.controls.salePrice.value - amount;
        this.assetDisposeForm.controls.netSalePrice.setValue(
          +netSalePrice.toFixed(2)
        );
      } else {
        netSalePrice = +this.assetDisposeForm.controls.salePrice.value ?? 0;
        this.assetDisposeForm.controls.netSalePrice.setValue(
          +netSalePrice.toFixed(2)
        );
      }

      let value = Math.abs(totalProfitLoss);
      this.totalProfitLoss = value;
      this.assetDisposeForm.controls.totalProfit.setValue(value.toFixed(2));

      this.lossProfitLable =
        this.assetDisposeForm.controls.netSalePrice.value <
        +this.disposeDetailData.totalAmount
          ? 'Loss'
          : 'Profit';
    }
  }
}
