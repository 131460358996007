import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note-1',
  templateUrl: './note-1.component.html',
  styleUrls: ['./note-1.component.scss'],
})
export class Note1Component implements OnInit {
  note1Form: FormGroup;

  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      this.setForm();

      if (
        data.noteId === Notes.Note1 &&
        data.response.userInputData !== null &&
        data.response.userInputData !== undefined &&
        data.response.userInputData.data !== null &&
        data.response.userInputData.data !== undefined
      ) {
        this.editNotes1(data.response.userInputData.data);
        this.editCheckboxNote1(data.response.userInputData.checkBoxData);
      }
    });
  }

  setForm(): void {
    this.note1Form = new FormGroup({
      textbox1: new FormControl(''),
      textbox2: new FormControl(''),
      textbox3: new FormControl(''),
      textbox4: new FormControl(''),
      textbox5: new FormControl(''),
      textbox6: new FormControl(''),
      textbox7: new FormControl(''),
      textbox8: new FormControl(''),
      textbox9: new FormControl(''),
      textbox10: new FormControl(''),
      textbox11: new FormControl(''),
      textbox12: new FormControl(''),
      textbox13: new FormControl(''),
      textbox14: new FormControl(''),
      textbox15: new FormControl(''),
      textbox16: new FormControl(''),
      textbox17: new FormControl(''),
      textbox18: new FormControl(''),
      textbox19: new FormControl(''),
      textbox20: new FormControl(''),
      textbox21: new FormControl(''),
      textbox22: new FormControl(''),
      textbox23: new FormControl(''),
      textbox24: new FormControl(''),
      textbox25: new FormControl(''),
      textbox26: new FormControl(''),
      textbox27: new FormControl(''),
      textbox28: new FormControl(''),
      textbox29: new FormControl(''),
      textbox30: new FormControl(''),
      textbox31: new FormControl(''),
      textbox32: new FormControl(''),
      textbox33: new FormControl(''),
      textbox34: new FormControl(''),
      textbox35: new FormControl(''),
      textbox36: new FormControl(''),

      checkbox1: new FormControl(0),
      checkbox2: new FormControl(0),
      checkbox3: new FormControl(0),
      checkbox4: new FormControl(0),
      checkbox5: new FormControl(0),
      checkbox6: new FormControl(0),
      checkbox7: new FormControl(0),
      checkbox8: new FormControl(0),
      checkbox9: new FormControl(0),
      checkbox10: new FormControl(0),
      checkbox11: new FormControl(0),
      checkbox12: new FormControl(0),
      checkbox13: new FormControl(0),
      checkbox14: new FormControl(0),
      checkbox15: new FormControl(0),
      checkbox16: new FormControl(0),
      checkbox17: new FormControl(0),
      checkbox18: new FormControl(0),
      checkbox19: new FormControl(0),
      checkbox20: new FormControl(0),
      checkbox21: new FormControl(0),
      checkbox22: new FormControl(0),
      checkbox23: new FormControl(0),
      checkbox24: new FormControl(0),
      checkbox25: new FormControl(0),
      checkbox26: new FormControl(0),
      checkbox27: new FormControl(0),
      checkbox28: new FormControl(0),
      checkbox29: new FormControl(0),
      checkbox30: new FormControl(0),
      checkbox31: new FormControl(0),
      checkbox32: new FormControl(0),
      checkbox33: new FormControl(0),
      checkbox34: new FormControl(0),
      checkbox35: new FormControl(0),
      checkbox36: new FormControl(0),
    });
  }

  editCheckboxNote1(res): void {
    this.note1Form.patchValue({
      checkbox1: res.checkbox1,
      checkbox2: res.checkbox2,
      checkbox3: res.checkbox3,
      checkbox4: res.checkbox4,
      checkbox5: res.checkbox5,
      checkbox6: res.checkbox6,
      checkbox7: res.checkbox7,
      checkbox8: res.checkbox8,
      checkbox9: res.checkbox9,
      checkbox10: res.checkbox10,
      checkbox11: res.checkbox11,
      checkbox12: res.checkbox12,
      checkbox13: res.checkbox13,
      checkbox14: res.checkbox14,
      checkbox15: res.checkbox15,
      checkbox16: res.checkbox16,
      checkbox17: res.checkbox17,
      checkbox18: res.checkbox18,
      checkbox19: res.checkbox19,
      checkbox20: res.checkbox20,
      checkbox21: res.checkbox21,
      checkbox22: res.checkbox22,
      checkbox23: res.checkbox23,
      checkbox24: res.checkbox24,
      checkbox25: res.checkbox25,
      checkbox26: res.checkbox26,
      checkbox27: res.checkbox27,
      checkbox28: res.checkbox28,
      checkbox29: res.checkbox29,
      checkbox30: res.checkbox30,
      checkbox31: res.checkbox31,
      checkbox32: res.checkbox32,
      checkbox33: res.checkbox33,
      checkbox34: res.checkbox34,
      checkbox35: res.checkbox35,
      checkbox36: res.checkbox36,
    });
  }

  editNotes1(res): void {
    this.note1Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : '',
      textbox2: res.textbox2 ? res.textbox2 : '',
      textbox3: res.textbox3 ? res.textbox3 : '',
      textbox4: res.textbox4 ? res.textbox4 : '',
      textbox5: res.textbox5 ? res.textbox5 : '',
      textbox6: res.textbox6 ? res.textbox6 : '',
      textbox7: res.textbox7 ? res.textbox7 : '',
      textbox8: res.textbox8 ? res.textbox8 : '',
      textbox9: res.textbox9 ? res.textbox9 : '',
      textbox10: res.textbox10 ? res.textbox10 : '',
      textbox11: res.textbox11 ? res.textbox11 : '',
      textbox12: res.textbox12 ? res.textbox12 : '',
      textbox13: res.textbox13 ? res.textbox13 : '',
      textbox14: res.textbox14 ? res.textbox14 : '',
      textbox15: res.textbox15 ? res.textbox15 : '',
      textbox16: res.textbox16 ? res.textbox16 : '',
      textbox17: res.textbox17 ? res.textbox17 : '',
      textbox18: res.textbox18 ? res.textbox18 : '',
      textbox19: res.textbox19 ? res.textbox19 : '',
      textbox20: res.textbox20 ? res.textbox20 : '',
      textbox21: res.textbox21 ? res.textbox21 : '',
      textbox22: res.textbox22 ? res.textbox22 : '',
      textbox23: res.textbox23 ? res.textbox23 : '',
      textbox24: res.textbox24 ? res.textbox24 : '',
      textbox25: res.textbox25 ? res.textbox25 : '',
      textbox26: res.textbox26 ? res.textbox26 : '',
      textbox27: res.textbox27 ? res.textbox27 : '',
      textbox28: res.textbox28 ? res.textbox28 : '',
      textbox29: res.textbox29 ? res.textbox29 : '',
      textbox30: res.textbox30 ? res.textbox30 : '',
      textbox31: res.textbox31 ? res.textbox31 : '',
      textbox32: res.textbox32 ? res.textbox32 : '',
      textbox33: res.textbox33 ? res.textbox33 : '',
      textbox34: res.textbox34 ? res.textbox34 : '',
      textbox35: res.textbox35 ? res.textbox35 : '',
      textbox36: res.textbox36 ? res.textbox36 : '',
    });
  }
}
