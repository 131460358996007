import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import { AccountState, GetBusinessTypeList } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-CIS-information',
  templateUrl: './add-CIS-information.component.html',
  styleUrls: ['./add-CIS-information.component.scss'],
})
export class AddCISInformationComponent implements OnInit {
  cisInformationForm: FormGroup;
  businessTypeList: SideListModel[];

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  @Input() triggerEditData: Observable<any>;

  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow
  ) {}

  ngOnInit(): void {
    this.getBusinessType();
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      this.editCISInfo(data);
    });

    this.cisInformationForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.cisInformationForm.touched;
    });
  }

  setForm(): void {
    this.cisInformationForm = new FormGroup({
      cisEnabled: new FormControl(false),
      businessTypeId: new FormControl(''),
      tradingName: new FormControl(''),
      subContractorUTR: new FormControl(''),
      niNumber: new FormControl(''),
      companyNumber: new FormControl(''),
      workReference: new FormControl(''),
      verify: new FormControl('1'),
      status: new FormControl(''),
      verificationNumber: new FormControl(''),
      lastVerified: new FormControl(new Date()),
      taxTreatment: new FormControl(''),
    });
  }

  editCISInfo(data): void {
    this.cisInformationForm.patchValue({
      cisEnabled: data.cisEnabled,
      businessTypeId: data.cisDetail.businessTypeId,
      tradingName: data.cisDetail.tradingName,
      subContractorUTR: data.cisDetail.subContractorUTR,
      niNumber: data.cisDetail.niNumber,
      companyNumber: data.cisDetail.companyNumber,
      workReference: data.cisDetail.workReference,
      status: data.cisDetail.status,
      verificationNumber: data.cisDetail.verificationNumber,
      lastVerified: data.cisDetail.lastVerified,
      taxTreatment: data.cisDetail.taxTreatment,
    });
  }

  getBusinessType(): void {
    this.store
      .dispatch(new GetBusinessTypeList())
      .pipe(
        tap(() => {
          this.businessTypeList = this.store.selectSnapshot(
            AccountState.getBusinesstype
          );
          this.cisInformationForm.controls.businessTypeId.setValue(
            this.businessTypeList[0].id
          );
        })
      )
      .subscribe();
  }
}
