import { Injectable } from '@angular/core';
import { EmailTemplateModel } from '@app/core/Models';
import { EmailTemplateService, IntegrationsService } from '@app/core/Services';
import { State, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetEmailTemplatesData,
  UpdateEmailTemplates,
} from './email-templates.action';

export class EmailTemplateStateInfo {
  templateData?: Array<EmailTemplateModel>;
  isUpdated?: boolean;
}

@State<EmailTemplateStateInfo>({
  name: 'emailTemplates',
  defaults: {},
})
@Injectable()
export class EmailTemplateState {
  constructor(private emailTemplateService: EmailTemplateService) {}

  @Action(UpdateEmailTemplates)
  updateEmailTemplates(
    { getState, patchState }: StateContext<EmailTemplateStateInfo>,
    action: UpdateEmailTemplates
  ) {
    return this.emailTemplateService
      .updateEmailTemplates(action.emailTemplatesData)
      .pipe(
        tap((res) => {
          patchState({
            isUpdated: !res,
          });
        })
      );
  }

  @Action(GetEmailTemplatesData)
  getEmailTemplatesData({
    getState,
    patchState,
  }: StateContext<EmailTemplateStateInfo>) {
    return this.emailTemplateService.getEmailTemplatesData().pipe(
      tap((res: Array<EmailTemplateModel>) => {
        patchState({
          templateData: res,
        });
      })
    );
  }
}
