import { BankTransferModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateBankTransfer {
  static readonly type = '[BANKTRANSFER] Create Bank Transfer';

  constructor(public bankTransfer: BankTransferModel) {}
}

export class GetDataByBankTransferId {
  static readonly type =
    '[BANKTRANSFER] Get Bank Transfer Data By Bank Transfer Id';

  constructor(public bankTransferId: Guid) {}
}

export class ArchiveAndRestoreBankTransfer {
  static readonly type =
    '[BANKTRANSFER] Archive and Restore Selected Bank Transfer';

  constructor(public bankTransferIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteBankTransfer {
  static readonly type = '[BANKTRANSFER] Delete Selected Bank Transfer';

  constructor(public bankTransferIds: Array<Guid>) {}
}

export class GetBankTransferNumber {
  static readonly type = '[BANKTRANSFER] Get Bank Transfer Number';
}
