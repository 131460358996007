import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Modules, RoutingPath } from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  BankOverviewState,
  BankReconciliationState,
  MenuState,
  SubmitVatState,
  UserState,
} from '@app/core/Store';
import { CommonState } from '@app/core/Store/common';
import { Select } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss'],
})
export class CustomPaginatorComponent implements OnInit {
  showPaginator = true;

  @Input()
  length: number;

  @Input()
  listParameters: MainListParameters;

  @Input()
  pageSizeChanged: number;

  @Input()
  isClientAssign = false;

  @Input() triggerPaginationChange: Observable<any>;

  subscriptionRouting: Subscription;

  pageSize = 50;
  pageSizestr = '50';

  math = Math;

  preventAutoPageSync = false;

  numPages: number;

  page1: number = 1;
  page2: number = 2;
  page3: number = 3;
  page4: number = 4;
  page5: number = 5;

  @Output()
  readonly pageSizeVal = new EventEmitter<number>();

  @Output()
  readonly togglePaginator = new EventEmitter<any>();

  private readonly paginationObservable = new Subject<any>();

  @Input()
  triggerOnSearch: Observable<any>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(UserState.totalRecordOfUserClient)
  totalRecordOfUserClient$: Observable<number>;

  @Select(UserState.totalRecord)
  totalUserRecord$: Observable<number>;

  @Select(BankReconciliationState.totalRecord)
  totalBankReconciliationRecord$: Observable<number>;

  @Select(SubmitVatState.totalRecord)
  totalSubmitVatRecord$: Observable<number>;

  @Select(BankOverviewState.totalRecord)
  totalBankOverviewRecord$: Observable<number>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  modules = Modules;
  moduleId = 0;

  @Output()
  readonly pageChanged = new EventEmitter<number>();
  paginatorForm: FormGroup;

  constructor(
    private commonService: CommonService,
    private _Activatedroute: ActivatedRoute
  ) {
    this.paginatorForm = new FormGroup({
      pageNumber: new FormControl<string | null>('', [Validators.required]),
    });
  }

  onKeyUp(key: any): void {
    const pageNumber = Math.ceil(this.length / this.pageSize);
    if (key.key === 'Enter') {
      if (this.paginatorForm.controls.pageNumber.value > pageNumber) {
        this.paginatorForm.controls.pageNumber.setValue(pageNumber);
      }
      if (this.paginatorForm.controls.pageNumber.value <= 0) {
        this.paginatorForm.controls.pageNumber.setValue(1);
      }
    }
    this.paginationObservable.next(key);
  }

  onEnterKey(): void {
    const pageNo = this.paginatorForm.controls.pageNumber.value;
    const pageNumber = this.paginatorForm.controls.pageNumber.value;
    this.paginatorForm.controls.pageNumber.setValue(
      pageNumber > 0 && pageNumber <= Math.ceil(this.length / this.pageSize)
        ? pageNumber
        : 1
    );

    if (pageNumber > this.page5) {
      this.page5 = pageNo;
      this.goToNextPage(pageNo, false);
    } else if (pageNumber < this.page1) {
      this.page1 = pageNo;
      this.goToPreviousPage(pageNo, false);
    } else if (pageNumber <= this.page5 && pageNumber >= this.page1) {
      this.paginatorForm.controls.pageNumber.setValue(pageNumber);
      this.pageChanged.emit(pageNumber);
    }
  }

  goToPreviousPage(pageNo: any, isPrevious: boolean): void {
    const pageNumber: number = this.paginatorForm.controls.pageNumber.value;
    if (
      this.paginatorForm.controls.pageNumber.value === '' ||
      pageNumber === 1 ||
      pageNumber > Math.ceil(this.length / this.pageSize)
    ) {
      return;
    }

    if (this.page1 === pageNumber) {
      if (pageNumber - 4 < 0) {
        this.page1 = 1;
        this.page2 = 2;
        this.page3 = 3;
        this.page4 = 4;
        this.page5 = 5;
      } else {
        this.page1 = pageNumber - 4;
        this.page2 = pageNumber - 3;
        this.page3 = pageNumber - 2;
        this.page4 = pageNumber - 1;
        this.page5 = pageNumber;
      }
    }
    let tempPageNumber = 0;
    if (isPrevious) {
      tempPageNumber = pageNumber - 1;
      this.paginatorForm.controls.pageNumber.setValue(pageNumber - 1);
    } else {
      tempPageNumber = pageNo;
      this.paginatorForm.controls.pageNumber.setValue(pageNo);
    }

    this.pageChanged.emit(tempPageNumber);
  }

  goToNextPage(pageNo: any, isNext: boolean): void {
    if (this.paginatorForm.controls.pageNumber.value === '') {
      this.paginatorForm.controls.pageNumber.setValue(1);
    }
    const pageNumber: number = this.paginatorForm.controls.pageNumber.value;

    if (pageNumber >= Math.ceil(this.length / this.pageSize)) {
      this.page5 = this.page1 + 4;
      this.paginatorForm.get('pageNumber')?.setValue(this.page1);
      return;
    }

    if (this.page5 === pageNumber) {
      this.page1 = pageNumber;
      this.page2 = pageNumber + 1;
      this.page3 = pageNumber + 2;
      this.page4 = pageNumber + 3;
      this.page5 = pageNumber + 4;
    }

    let tempPageNumber = 0;
    if (isNext) {
      tempPageNumber = pageNumber + 1;
      this.paginatorForm.controls.pageNumber.setValue(pageNumber + 1);
    } else {
      tempPageNumber = pageNo;
      this.paginatorForm.controls.pageNumber.setValue(pageNo);
    }

    this.pageChanged.emit(tempPageNumber);
  }

  goToLastPage(): void {
    const pageNumber = Math.ceil(this.length / this.pageSize);
    if (pageNumber > Math.ceil(this.length / this.pageSize)) {
      return;
    }
    if (pageNumber < Math.ceil(this.length / this.pageSize)) {
      this.page1 = pageNumber - 4;
      this.page2 = pageNumber - 3;
      this.page3 = pageNumber - 2;
      this.page4 = pageNumber - 1;
      this.page5 = pageNumber;
    }

    this.paginatorForm.controls.pageNumber.setValue(pageNumber);

    this.pageChanged.emit(pageNumber);
  }

  goToFirstPage(): void {
    const pageNumber = 1;
    if (pageNumber > Math.ceil(this.length / this.pageSize)) {
      return;
    }

    this.page1 = pageNumber;
    this.page2 = pageNumber + 1;
    this.page3 = pageNumber + 2;
    this.page4 = pageNumber + 3;
    this.page5 = pageNumber + 4;

    this.paginatorForm.controls.pageNumber.setValue(pageNumber);

    this.pageChanged.emit(pageNumber);
  }

  ngOnInit(): void {
    this.moduleId$.subscribe((x) => {
      this.moduleId = x;
    });

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        const currentUrl = this._Activatedroute.snapshot['_routerState'].url;

        if (currentUrl.includes(RoutingPath.BankTransaction)) {
          this.moduleId = Modules.BankImportTransactionHistory;
        }
      }
    );

    this.triggerPaginationChange?.subscribe((data) => {
      this.numPages = Math.ceil(data.totalRecord / data.pageSize);
      if (data.isHeaderChange) {
        this.paginatorForm.controls.pageNumber.setValue(1);
        const val = 1;
        this.page1 = val;
        this.page2 = val + 1;
        this.page3 = val + 2;
        this.page4 = val + 3;
        this.page5 = val + 4;
        this.onEnterKey();
      }
    });

    this.pageSize = this.listParameters.pageSize ?? 50;
    this.pageSizestr = this.listParameters.pageSize.toString() ?? '50';

    this.numPages = Math.ceil(this.length / this.pageSize);

    this.paginatorForm.controls.pageNumber.setValue(
      this.listParameters.pageNumber
    );
    this.onEnterKey();
  }

  onSelectionChange(val: number): void {
    this.numPages = Math.ceil(this.length / +val);
    this.paginatorForm.controls.pageNumber.setValue(1);
    this.pageSizeVal.emit(+val);
    this.pageSize = +val;
    this.pageSizestr = val.toString();
    this.goToFirstPage();
  }

  onPageNumberClick(val: number): void {
    this.paginatorForm.controls.pageNumber.setValue(
      val > 0 && val <= Math.ceil(this.length / this.pageSize) ? val : 1
    );

    this.paginatorForm.controls.pageNumber.setValue(val);

    this.pageChanged.emit(val);
  }

  onPageNumberLastClick(val: number): void {
    if (val !== Math.ceil(this.length / this.pageSize)) {
      this.page1 = val;
      this.page2 = val + 1;
      this.page3 = val + 2;
      this.page4 = val + 3;
      this.page5 = val + 4;
    }

    this.paginatorForm.controls.pageNumber.setValue(
      val > 0 && val <= Math.ceil(this.length / this.pageSize) ? val : 1
    );

    this.paginatorForm.controls.pageNumber.setValue(val);

    this.pageChanged.emit(val);
  }

  onPageNumberFirstClick(val: number): void {
    if (val !== 1) {
      if (val - 4 < 0) {
        this.page1 = 1;
        this.page2 = 2;
        this.page3 = 3;
        this.page4 = 4;
        this.page5 = 5;
      } else {
        this.page1 = val - 4;
        this.page2 = val - 3;
        this.page3 = val - 2;
        this.page4 = val - 1;
        this.page5 = val;
      }
    }

    this.paginatorForm.controls.pageNumber.setValue(
      val > 0 && val <= Math.ceil(this.length / this.pageSize) ? val : 1
    );

    this.paginatorForm.controls.pageNumber.setValue(val);

    this.pageChanged.emit(val);
  }

  togglePagination() {
    this.showPaginator = !this.showPaginator;
    this.togglePaginator.emit(this.showPaginator);
  }

  ngOnDestroy(): void {
    this.subscriptionRouting?.unsubscribe();
  }
}
