import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  DeleteGiftAidDetails,
  GetGiftAidDetails,
  GetSubContractorDetailsList,
  GiftAidImport,
  SaveGiftAidDetails,
  SaveHMRCGatewayDetails,
} from './gift-aid-details.action';
import { GiftAidDetailsService } from '@app/core/Services';

export class GiftAidDetailsStateInfo {
  giftAidData?: any;
  subContractorData?: any;
  giftAIDSettingsData?: any;
  isSubmittedToHMRC?: any;
  isDeleted?: any;
  giftAidDetails?: any;
  isSuccess?: any;
  giftAidDetailsData?: any;
  importData?: any;
}

@State<GiftAidDetailsStateInfo>({
  name: 'giftAidDetails',
  defaults: {},
})
@Injectable()
export class GiftAidDetailsState {
  constructor(private giftAidDetailsService: GiftAidDetailsService) {}

  @Selector()
  static subContractorColumnNames(
    state: GiftAidDetailsStateInfo
  ): Array<string> {
    if (!state.subContractorData) {
      return [];
    }

    let subContractorColumnNames;
    subContractorColumnNames = state.subContractorData.resultSet.columns.filter(
      (i) => i.name !== 'ModuleId'
    );

    return subContractorColumnNames.map((x) => x.name);
  }

  @Action(GetGiftAidDetails)
  getGiftAidDetails(
    { patchState }: StateContext<GiftAidDetailsStateInfo>,
    action: GetGiftAidDetails
  ) {
    return this.giftAidDetailsService.getGiftAidDetails(action.id).pipe(
      tap((res) => {
        patchState({
          giftAidDetails: res,
        });
      })
    );
  }

  @Action(GetSubContractorDetailsList)
  getSubContractorDetailsList(
    { patchState }: StateContext<GiftAidDetailsStateInfo>,
    action: GetSubContractorDetailsList
  ) {
    return this.giftAidDetailsService
      .getSubContractorDetailsList(action.queryParams, action.cisReturnId)
      .pipe(
        tap((res) => {
          patchState({
            subContractorData: res,
          });
        })
      );
  }

  @Action(SaveGiftAidDetails)
  saveGiftAidDetails(
    { patchState }: StateContext<GiftAidDetailsStateInfo>,
    action: SaveGiftAidDetails
  ) {
    return this.giftAidDetailsService
      .saveGiftAidDetails(action.giftAidDetailsData)
      .pipe(
        tap((res) => {
          patchState({
            giftAidDetailsData: res,
          });
        })
      );
  }

  @Action(SaveHMRCGatewayDetails)
  saveHMRCGatewayDetails(
    { patchState }: StateContext<GiftAidDetailsStateInfo>,
    action: SaveHMRCGatewayDetails
  ) {
    return this.giftAidDetailsService
      .saveHMRCGatewayDetails(action.saveHMRCGatewayDetailsData)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res,
          });
        })
      );
  }

  @Action(DeleteGiftAidDetails)
  deleteGiftAidDetails(
    { patchState }: StateContext<GiftAidDetailsStateInfo>,
    action: DeleteGiftAidDetails
  ) {
    return this.giftAidDetailsService
      .deleteGiftAidDetails(action.giftAddDetailsId)
      .pipe(
        tap((res) => {
          patchState({
            isDeleted: res,
          });
        })
      );
  }

  @Action(GiftAidImport)
  giftAidImport(
    { patchState }: StateContext<GiftAidDetailsStateInfo>,
    action: GiftAidImport
  ) {
    return this.giftAidDetailsService
      .giftAidImport(action.fileImportRequestModel)
      .pipe(
        tap((res) => {
          patchState({
            importData: res,
          });
        })
      );
  }
}
