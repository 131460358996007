export enum ExportType {
  UnKnown = 0,
  CSV = 1,
  Excel = 2,
  GoogleDrive = 3,
  PDF = 4,
  ExportAsExcelOutstandingBal = 5,
  ExportAsCSVOutstandingBal = 6,
  ExportAsPDFOutstandingBal = 7,
}
