import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { CommonService, UserService } from '@app/core/Services';
import {
  GetUserCompanyDetails,
  GetUserDataByUserId,
  MenuState,
  SaveUser,
  SaveUserCompanies,
  UserState,
} from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserBasicInfoComponent } from './user-basic-info/user-basic-info.component';
import { UserCapacityPermissionComponent } from './user-capacity-permission/user-capacity-permission.component';
import { UserClientsComponent } from './user-clients/user-clients.component';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit, OnDestroy {
  moduleName = ModuleName.Users;
  moduleId = Modules.Users;
  userId = Guid.EMPTY as unknown as Guid;

  subscriptionRouting: Subscription;
  headerText = '';

  triggerEditBasicInfoData: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  triggerEditCapacityPermissionData: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);

  triggerUserId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerId: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  displayedUserClientColumns: string[] = ['no', 'Name', 'isAssigned', 'close'];

  userData: any;
  isExpandAll = false;
  istoggleSideList = true;
  isShowSideListAction = false;
  isHideSideList = false;
  showAssignClients = false;
  @Select(UserState.userData)
  getUserData$: Observable<any>;

  @Select(UserState.GetUserCompanyDetails)
  userClientsData$: Observable<Array<any>>;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(UserBasicInfoComponent, { static: false })
  basicInfoDetails;

  @ViewChild(UserCapacityPermissionComponent, { static: false })
  capacityPermissionDetails;

  @ViewChild(UserClientsComponent, { static: false })
  userClientsDetails;

  defaultId = Guid.EMPTY as unknown as Guid;
  addUserSubscription: Subscription;
  saveUserSubscription: Subscription;
  cancelUserSubscription: Subscription;

  listParameters: MainListParameters = new MainListParameters();
  commonNotificationText = NotificationTextMessage;

  @Input()
  isFromTaskClient = false;

  @Input()
  triggerAddUser: Observable<any>;

  @Input()
  triggerSaveUser: Observable<any>;

  @Input()
  triggerCancelUser: Observable<any>;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  constructor(
    private _Activatedroute: ActivatedRoute,
    private store: Store,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
    public userService: UserService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (!this.isFromTaskClient) {
          localStorage.removeItem('UserData');
          setTimeout(() => {
            this.accordion.openAll();
            this.isExpandAll = false;
          }, 500);

          if (params.keys.length > 0) {
            this.userId = atob(params.get('id')!) as unknown as any;
            // this.selectedIndex = +params.get('tabId')!;
            this.triggerUserId.next({
              userId: this.userId,
              moduleId: this.moduleId,
            });
            this.showAssignClients = false;
            this.editUser();
          } else {
            this.userId = Guid.EMPTY as unknown as Guid;
            this.userData = {};
            this.headerText = 'Add New User';
          }
        }
      }
    );

    this.addUserSubscription = this.triggerAddUser?.subscribe((res) => {
      this.isHideSideList = res.isHideSideList;
      this.userId = Guid.EMPTY as unknown as Guid;
      this.userData = {};
      this.headerText = 'Add New User';
    });

    this.saveUserSubscription = this.triggerSaveUser?.subscribe((isExits) => {
      this.onSave(isExits);
    });

    this.cancelUserSubscription = this.triggerCancelUser?.subscribe(() => {
      this.onCancel(true);
    });
  }

  tabClick(tab) {
    window.dispatchEvent(new Event('resize'));
  }

  editUser(): void {
    this.getUserCompanyDetails();

    this.store
      .dispatch(new GetUserDataByUserId(this.userId))
      .subscribe((res) => {
        this.userData = res.user.userData;
        this.headerText =
          this.userData.firstName +
          (this.userData.lastName !== null &&
          this.userData.lastName !== undefined
            ? ' ' + this.userData.lastName
            : ' ') +
          '(' +
          this.userData.userCode +
          ')';
        this.triggerEditCapacityPermissionData.next(res.user.userData);
        this.triggerEditBasicInfoData.next(res.user.userData);
        this.triggerId.next(this.userId);
      });
  }

  onCancel(isCancelClick: boolean): void {
    if (isCancelClick && this.userId !== (Guid.EMPTY as unknown as Guid)) {
      this.editUser();
    } else {
      this.userId = Guid.EMPTY as unknown as Guid;
      this.basicInfoDetails.ngOnInit();
      this.capacityPermissionDetails.ngOnInit();
    }
  }

  dataSubmit(isInvitedUser?: boolean): boolean {
    this.spinner.show();
    try {
      let contactDetails = new Array<any>();
      let addressDetails = new Array<any>();

      this.basicInfoDetails.contactDetailArray?.getRawValue().forEach((x) => {
        if (x.name || x.email || x.phone) {
          contactDetails.push({
            id: x.id,
            userId: this.userId,
            title: x.title,
            name: x.name,
            email: x.email,
            countryId: +x.country,
            phone: x.phone.toString(),
          });
        }
      });

      this.basicInfoDetails.addressDetailArray?.getRawValue().forEach((x) => {
        if (x.address || x.city || x.postalCode) {
          addressDetails.push({
            id: x.id,
            userId: this.userId,
            addressType: x.addressType,
            address: x.address,
            city: x.city,
            county: x.county,
            countryId: x.country,
            postalCode: x.postalCode,
          });
        }
      });

      this.userData = {
        id: this.userId,
        title: this.basicInfoDetails.userForm.controls.title.value,
        firstName: this.basicInfoDetails.userForm.controls.firstName.value,
        middleName: this.basicInfoDetails.userForm.controls.middleName.value,
        lastName: this.basicInfoDetails.userForm.controls.lastName.value,
        email: this.basicInfoDetails.userForm.controls.email.value,
        photoUrl:
          this.basicInfoDetails.fileUploadResponseModel === undefined
            ? this.basicInfoDetails.photoUrl
            : this.basicInfoDetails.fileUploadResponseModel.fileUrl,
        roleId: this.basicInfoDetails.userForm.controls.permissions.value,
        isInvitedUser: isInvitedUser,
        addresses: addressDetails,
        contacts: contactDetails,
        addToAllFutureCompany:
          this.capacityPermissionDetails.capacityPermissionForm.controls
            .addToAllFutureClients.value,
        addToAllExistingCompany:
          this.capacityPermissionDetails.capacityPermissionForm.controls
            .addToAllExistingClients.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  addValidation(form: any): void {
    (Object as any).values(form.controls).forEach((c) => {
      c.markAsTouched();
    });
  }

  onSave(isExit: boolean, isSaveAndInvite?: boolean): void {
    if (this.basicInfoDetails.userForm.invalid) {
      this.addValidation(this.basicInfoDetails.userForm);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      if (
        this.dataSubmit(isSaveAndInvite) &&
        (this.basicInfoDetails?.userForm.dirty ||
          this.capacityPermissionDetails?.capacityPermissionForm.dirty ||
          this.basicInfoDetails?.contactDetailArray.dirty ||
          this.basicInfoDetails?.addressDetailArray.dirty)
      ) {
        // this.dialog
        //   .open(SaveAndInvitePopupComponent, {})
        //   .afterClosed()
        //   .subscribe((data) => {
        //     console.log(data);
        this.onUserSave(isExit);
        // if (!this.commonService.isEmpty(id)) {
        // }
        // });
      } else if (this.userClientsDetails.isClientUpdate) {
        this.onUserClient(isExit);
      }
    }
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.userId,
      isExit,
      Modules.Users,
      RoutingPath.AddUsers
    );
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {
      data: {
        moduleId: this.moduleId,
      },
    });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  ngOnDestroy(): void {
    this.subscriptionRouting.unsubscribe();
    this.addUserSubscription?.unsubscribe();
    this.saveUserSubscription?.unsubscribe();
    this.cancelUserSubscription?.unsubscribe();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  toggleAssignClient(): void {
    this.showAssignClients = !this.showAssignClients;
    this.spinner.show();
    this.getUserCompanyDetails();
    this.triggerData();
  }

  getUserCompanyDetails(): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      filter: this.listParameters.filter,
      userId: this.userId,
      isUserClient: !this.showAssignClients,
    };
    this.store
      .dispatch(new GetUserCompanyDetails(queryParams))
      .subscribe(() => {
        this.userClientsDetails.checkAssignedAllFee();
      });
  }

  triggerData(): void {
    setTimeout(() => {
      if (this.userId !== (Guid.EMPTY as unknown as Guid)) {
        this.getUserData$.subscribe((x) => {
          this.triggerEditBasicInfoData.next(x);
          this.triggerEditCapacityPermissionData.next(x);
        });
      }
    }, 500);
  }

  onUserSave(isExit): void {
    this.store
      .dispatch(new SaveUser(this.userData))
      .pipe()
      .subscribe(
        (res) => {
          if (res?.user.isSuccess) {
            this.basicInfoDetails.userForm.controls.permissions.reset();
            this.setHighlightData(isExit);
            if (!isExit) {
              this.userId = res.user.userId;
              if (!this.isFromTaskClient) {
                this.commonService.onEditRouting(
                  true,
                  this.moduleId,
                  this.userId,
                  1
                );
              }
              this.onCancel(true);
              this.reloadSideList.emit();
            } else {
              this.onCancel(false);
              this.router.navigate([RoutingPath.Users]);
            }
            this.commonService.onSucess(NotificationTextMessage.successMessage);
          } else {
            this.commonService.onFailure(res.user.userResponse.statusMessage);
          }
        },
        (err) => {
          this.commonService.onFailure(err.message);
        }
      );
  }

  onUserClient(isExit) {
    this.spinner.show();

    let userClientsDataList;

    this.userClientsData$.subscribe((x) => {
      userClientsDataList = x;
    });

    this.store
      .dispatch(new SaveUserCompanies(this.userId, userClientsDataList))
      .pipe()
      .subscribe(
        (res) => {
          if (res !== undefined) {
            this.onSuccessResponse(isExit);
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.spinner.hide();
          this.commonService.onFailure(err.message);
        }
      );
  }

  onSuccessResponse(isExit): void {
    if (!isExit) {
      this.setHighlightData(isExit);
      this.onCancel(true);
      this.reloadSideList.next(null);
    } else {
      this.setHighlightData(isExit);
      this.onCancel(false);
    }
    this.commonService.onSucess(NotificationTextMessage.successMessage);
  }
}
