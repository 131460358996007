import { Injectable } from '@angular/core';
import { ActivityModel, SideListModel } from '@app/core/Models';
import { ActivityService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Guid } from 'guid-typescript';
import {
  ArchiveAndRestoreActivity,
  CreateActivity,
  DeleteActivity,
  GetDataByActivityId,
} from './activity.action';

export class ActivityStateInfo {
  activityData?: ActivityModel;
  activity: Array<ActivityModel>;
  activityId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isactivityAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
}

@State<ActivityStateInfo>({
  name: 'activity',
  defaults: {
    activity: [],
    activityId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isactivityAdded: false,
  },
})
@Injectable()
export class ActivityState {
  constructor(private activityService: ActivityService) {}

  @Selector()
  static getActivityId(state: ActivityStateInfo) {
    return state.activityId;
  }

  @Selector()
  static isLastPage(state: ActivityStateInfo) {
    return state.isLastPage;
  }

  @Action(CreateActivity)
  createActivity(
    { patchState }: StateContext<ActivityStateInfo>,
    action: CreateActivity
  ) {
    return this.activityService.createActivity(action.activity).pipe(
      tap((res) => {
        patchState({
          activityId: res,
          isactivityAdded: true,
        });
      })
    );
  }

  @Action(GetDataByActivityId)
  getDataByActivityId(
    { patchState }: StateContext<ActivityStateInfo>,
    action: GetDataByActivityId
  ) {
    return this.activityService.getDataByActivityId(action.activityId).pipe(
      tap((res) => {
        patchState({
          activityData: res,
        });
      })
    );
  }

  @Action(DeleteActivity)
  deleteActivity(
    { getState, setState }: StateContext<ActivityStateInfo>,
    action: DeleteActivity
  ) {
    return this.activityService.deleteActivity(action.activityIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredactivity = state.activity.filter(
          (item) =>
            !action.activityIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.activityIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.activity,
          activity: filteredactivity,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreActivity)
  archiveAndRestoreActivity(
    { getState }: StateContext<ActivityStateInfo>,
    action: ArchiveAndRestoreActivity
  ) {
    return this.activityService.archiveAndRestoreActivity(
      action.activityIds,
      action.isArchive
    );
  }
}
