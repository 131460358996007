<div id="chart" *ngIf="chartOption">
  <apx-chart
    [series]="chartOption?.series!"
    [chart]="chartOption?.chart!"
    [labels]="chartOption?.labels!"
    [tooltip]="chartOption?.tooltip!"
    [responsive]="chartOption?.responsive!"
    [legend]="chartOption?.legend!"
    [fill]="chartOption?.fill!"
    [noData]="chartOption?.noData!"
    [colors]="[
      '#9B2FC5',
      '#FA76A1',
      '#8960C2',
      '#E57F6A',
      '#FF6498',
      '#E57F6A'
    ]"
  ></apx-chart>
</div>
