import { Injectable } from '@angular/core';
import {
  AccountGroupAndType,
  AccountModel,
  ChartOfAccountListModel,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { AccountService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreAccount,
  CreateAccount,
  DeleteAccount,
  GetAccountGroupAndType,
  GetAccountType,
  GetAccountsForBankFeed,
  GetBusinessTypeList,
  GetChartOfAccountGroupList,
  GetChartOfAccountTypeList,
  GetChartOfAllAccountsList,
  GetDataByAccountId,
  GetExplain,
  GetExplainListBasedOnAccountingPeriod,
  GetFixedAssetAdditionList,
  GetFundNameList,
  GetFundTypeList,
  GetJournalAccounts,
  GetOpeningBalanceExplainList,
  GetSupplierList,
  GetTermsList,
  CreateContact,
} from './account.action';

export class AccountStateInfo {
  accountData?: AccountModel;
  account: Array<AccountModel>;
  accountId?: Guid;
  exported?: boolean;
  sideListModel?: Array<SideListModel>;
  chartOfAccountList?: SideListModel;
  chartOfAccountGroupList?: SideListModel;
  chartOfAccountTypeList?: SideListModel;
  chartOfAllAccountList?: SideListModel;
  fundTypeList?: SideListModel;
  accounts: GroupListModel[];
  isAccountAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  businessTypeList?: Array<SideListModel>;
  termsList?: SideListModel[];
  supplier?: SideListModel[];
  accountType: ChartOfAccountListModel[];
  fundNameList?: SideListModel;
  accountGroupAndType?: AccountGroupAndType[];
  explainList?: any;
  explain?: any;
}

@State<AccountStateInfo>({
  name: 'account',
  defaults: {
    account: [],
    accountId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isAccountAdded: false,
    chartOfAccountGroupList: new SideListModel(),
    chartOfAccountTypeList: new SideListModel(),
    chartOfAllAccountList: new SideListModel(),
    fundTypeList: new SideListModel(),
    accounts: [],
    businessTypeList: [],
    termsList: [],
    supplier: [],
    accountType: [],
    fundNameList: new SideListModel(),
    accountGroupAndType: [],
  },
})
@Injectable()
export class AccountState {
  constructor(private accountService: AccountService) {}

  @Selector()
  static isLastPage(state: AccountStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getCompanyId(state: AccountStateInfo) {
    return state.accountId;
  }

  @Selector()
  static getBusinesstype(state: AccountStateInfo) {
    return state.businessTypeList;
  }

  @Selector()
  static getTerms(state: AccountStateInfo) {
    return state.termsList;
  }

  @Selector()
  static getSupplier(state: AccountStateInfo) {
    return state.supplier;
  }

  @Selector()
  static getFundNameList(state: AccountStateInfo) {
    return state.fundNameList;
  }

  @Selector()
  static getTermsList(state: AccountStateInfo) {
    return state.termsList;
  }

  @Selector()
  static getJournalAccounts(state: AccountStateInfo) {
    return state.accounts;
  }

  @Selector()
  static getFundTypeList(state: AccountStateInfo) {
    return state.fundTypeList;
  }

  @Selector()
  static getFixedAssetAdditionList(
    state: AccountStateInfo
  ): Array<GroupListModel> {
    return state.accounts;
  }

  @Action(CreateAccount)
  createAccount(
    { patchState }: StateContext<AccountStateInfo>,
    action: CreateAccount
  ) {
    return this.accountService.createAccount(action.account).pipe(
      tap((res) => {
        patchState({
          accountId: res,
          isAccountAdded: true,
        });
      })
    );
  }

  @Action(CreateContact)
  createContact(
    { patchState }: StateContext<AccountStateInfo>,
    action: CreateContact
  ) {
    return this.accountService.createContact(action.account).pipe(
      tap((res) => {
        patchState({
          accountId: res,
          isAccountAdded: true,
        });
      })
    );
  }

  @Action(GetTermsList)
  getTermsList(
    { patchState }: StateContext<AccountStateInfo>,
    action: GetTermsList
  ) {
    return this.accountService.getTermsList().pipe(
      tap((res) => {
        patchState({
          termsList: res,
        });
      })
    );
  }

  @Action(GetBusinessTypeList)
  getBusinesstypeList({ getState, setState }: StateContext<AccountStateInfo>) {
    return this.accountService.getBusinessTypeList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          businessTypeList: res,
        });
      })
    );
  }

  @Action(GetDataByAccountId)
  getDataByAccountId(
    { patchState }: StateContext<AccountStateInfo>,
    action: GetDataByAccountId
  ) {
    return this.accountService.getDataByAccountId(action.accountId).pipe(
      tap((res) => {
        patchState({
          accountData: res,
        });
      })
    );
  }

  @Action(GetChartOfAccountGroupList)
  getChartOfAccountGroupList(
    { patchState }: StateContext<AccountStateInfo>,
    action: GetChartOfAccountGroupList
  ) {
    return this.accountService.getChartOfAccountGroupList().pipe(
      tap((res) => {
        patchState({
          chartOfAccountGroupList: res,
        });
      })
    );
  }

  @Action(GetChartOfAccountTypeList)
  getChartOfAccountTypeList(
    { patchState }: StateContext<AccountStateInfo>,
    action: GetChartOfAccountTypeList
  ) {
    return this.accountService.getChartOfAccountTypeList(action.groupId).pipe(
      tap((res) => {
        patchState({
          chartOfAccountTypeList: res,
        });
      })
    );
  }

  @Action(GetChartOfAllAccountsList)
  getChartOfAccountAllAccountsList(
    { patchState }: StateContext<AccountStateInfo>,
    action: GetChartOfAllAccountsList
  ) {
    return this.accountService
      .getChartOfAllAccountsList(action.groupId, action.typeId)
      .pipe(
        tap((res) => {
          patchState({
            chartOfAllAccountList: res,
          });
        })
      );
  }

  @Action(GetFundTypeList)
  getFundTypeList(
    { patchState }: StateContext<AccountStateInfo>,
    action: GetFundTypeList
  ) {
    return this.accountService.getFundTypeList().pipe(
      tap((res) => {
        patchState({
          fundTypeList: res,
        });
      })
    );
  }

  @Action(GetJournalAccounts)
  getJournalAccounts(
    { getState, setState }: StateContext<AccountStateInfo>,
    action: any
  ) {
    return this.accountService.getJournalAccounts().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          accounts: res,
        });
      })
    );
  }

  @Action(DeleteAccount)
  deleteAccount(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: DeleteAccount
  ) {
    return this.accountService.deleteAccount(action.accountIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredAccount = state.account.filter(
          (item) =>
            !action.accountIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.accountIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          ...state.account,
          account: filteredAccount,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreAccount)
  archiveAndRestoreAccount(
    { getState }: StateContext<AccountStateInfo>,
    action: ArchiveAndRestoreAccount
  ) {
    return this.accountService.archiveAndRestoreAccount(
      action.accountIds,
      action.isArchive
    );
  }

  //#region Supplier

  @Action(GetSupplierList)
  getSupplierList(
    { getState, setState }: StateContext<AccountStateInfo>,
    action: any
  ) {
    return this.accountService.getSupplierList(action.entityId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          supplier: res,
        });
      })
    );
  }

  @Action(GetAccountType)
  getAccountTypeList(
    { getState, setState }: StateContext<AccountStateInfo>,
    action: any
  ) {
    return this.accountService.getAccountTypeList(action.accountTypeId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          accountType: res,
        });
      })
    );
  }

  @Action(GetFundNameList)
  getFundNameList(
    { patchState }: StateContext<AccountStateInfo>,
    action: GetFundNameList
  ) {
    return this.accountService.getFundNameList().pipe(
      tap((res) => {
        patchState({
          fundNameList: res,
        });
      })
    );
  }

  @Action(GetAccountGroupAndType)
  getAccountGroupAndType(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: GetAccountGroupAndType
  ) {
    return this.accountService
      .getAccountGroupAndType(action.accountGroupAndTypeId)
      .pipe(
        tap((res) => {
          patchState({
            accountGroupAndType: res,
          });
        })
      );
  }

  @Action(GetExplain)
  getExplain(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: GetExplain
  ) {
    return this.accountService.getExplain(action.id).pipe(
      tap((res) => {
        patchState({
          explain: res,
        });
      })
    );
  }

  @Action(GetOpeningBalanceExplainList)
  getOpeningBalanceExplainList(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: GetOpeningBalanceExplainList
  ) {
    return this.accountService
      .getOpeningBalanceExplainList(action.accountTypeId)
      .pipe(
        tap((res) => {
          patchState({
            explainList: res,
          });
        })
      );
  }

  @Action(GetExplainListBasedOnAccountingPeriod)
  getExplainListBasedOnAccountingPeriod(
    { getState, patchState }: StateContext<AccountStateInfo>,
    action: GetExplainListBasedOnAccountingPeriod
  ) {
    return this.accountService
      .getExplainListBasedOnAccountingPeriod(
        action.accountTypeId,
        action.accountingPeriodId
      )
      .pipe(
        tap((res) => {
          patchState({
            explainList: res,
          });
        })
      );
  }

  @Action(GetFixedAssetAdditionList)
  getFixedAssetAdditionList(
    { getState, setState }: StateContext<AccountStateInfo>,
    action: GetFixedAssetAdditionList
  ) {
    return this.accountService.getFixedAssetAdditionList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          accounts: res,
        });
      })
    );
  }

  @Action(GetAccountsForBankFeed)
  getAccountsForBankFeed(
    { patchState }: StateContext<AccountStateInfo>,
    action: GetAccountsForBankFeed
  ) {
    return this.accountService.getAccountsForBankFeed().pipe(
      tap((res) => {
        patchState({
          account: res,
        });
      })
    );
  }
}
