import { DatePipe } from '@angular/common';
import {
  Component,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  AccountingPeriodModel,
  CompanyAddressDetailModel,
  CompanyContactDetailModel,
  CompanyModel,
  CompanyRegionalDetailModel,
  CompanyVatDetailModel,
  GlobalComponent,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CompanyState,
  CreateCompany,
  GetAccountingMethod,
  GetAvailableCompanyCount,
  GetDataByCompanyId,
  GetDefaultCurrency,
  GetFinancialPeriod,
  GetMenu,
  MenuState,
} from '@app/core/Store';
import { AddCompanyComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.scss'],
})
export class NewCompanyComponent implements OnInit {
  addressDetail: CompanyAddressDetailModel;
  billingDetail?: CompanyAddressDetailModel;
  contactDetail: CompanyContactDetailModel;
  regionalDetail: CompanyRegionalDetailModel;
  companyData: CompanyModel;
  vatDetail: CompanyVatDetailModel;
  accountingPeriods: AccountingPeriodModel[] = [];

  companyId: Guid;
  routingPath = RoutingPath;
  moduleId = Modules.CharityPreference;
  moduleName = ModuleName.Charity;
  userName: string;

  triggerEditData: Subject<any> = new Subject<any>();

  subscriptionRouting: Subscription;

  @ViewChild(AddCompanyComponent, { static: true })
  companyDetail;

  @Select(MenuState.userName)
  userName$: Observable<string>;

  activeRouteLink: string;
  isloaded = false;

  @Select(CompanyState.companyCount)
  companyCount$: Observable<number>;

  store: Store;
  router: Router;
  commonService: CommonService;
  cookieService: CookieService;
  highlightRow: HighlightRow;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  _Activatedroute: ActivatedRoute;
  globalComponent: GlobalComponent;
  renderer: Renderer2;

  constructor(private injector: Injector) {
    this.store = injector.get<Store>(Store);
    this.globalComponent = injector.get<GlobalComponent>(GlobalComponent);
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = injector.get<Router>(Router);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.commonService = injector.get<CommonService>(CommonService);
    this.cookieService = injector.get<CookieService>(CookieService);
    this.renderer = injector.get<Renderer2>(Renderer2);
    this.datepipe = injector.get<DatePipe>(DatePipe);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isloaded = true;
        this.activeRouteLink = event.url.slice(1).split(';')[0];
      }
    });
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        this.isloaded = true;
        this.activeRouteLink = this.router.url.slice(1).split(';')[0];
        if (this.activeRouteLink === RoutingPath.EditCharity) {
          this.companyId =
            params.get('id') === null
              ? this.globalComponent.getCompanyId()
              : this.commonService.convertStringToGuid(atob(params.get('id')!));

          this.editCompany();
        } else {
          this.activeRouteLink = RoutingPath.AddCharity;
        }
      }
    );
  }

  editCompany(): void {
    this.store
      .dispatch(new GetDataByCompanyId(this.companyId))
      .subscribe((res) => {
        this.companyDetail.companyForm.markAsUntouched();
        this.companyDetail.financialYearDetail.formFinancialYearDetail.markAsUntouched();
        setTimeout(() => {
          this.triggerEditData.next(res.company.companyData);
        }, 1000);
      });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.accountingPeriods = [];
      this.companyDetail.financialYearDetail.financialYearArray
        ?.getRawValue()
        .forEach((element) => {
          if (element.id || (element.startDate && element.endDate)) {
            let data: AccountingPeriodModel = {
              id: element.id,
              fromDate: this.datepipe
                .transform(element.startDate, 'yyyy-MM-dd')
                ?.toString(),
              toDate: this.datepipe
                .transform(element.endDate, 'yyyy-MM-dd')
                ?.toString(),
              isLocked: element.isLocked,
            };
            this.accountingPeriods.push(data);
          }
        });
      this.addressDetail = {
        address1: this.companyDetail.companyForm.controls.address1.value,
        address2: this.companyDetail.companyForm.controls.address2.value,
        address3: this.companyDetail.companyForm.controls.address3.value,
        city: this.companyDetail.companyForm.controls.city.value,
        country: this.companyDetail.companyForm.controls.country.value,
        postalCode: this.companyDetail.companyForm.controls.postalCode.value,
      };

      if (
        this.companyDetail.companyForm.controls.keepBillingDetailSeparate.value
      ) {
        this.billingDetail = {
          address1:
            this.companyDetail.companyForm.controls.address1BillingSeparate
              .value,
          address2:
            this.companyDetail.companyForm.controls.address2BillingSeparate
              .value,
          address3:
            this.companyDetail.companyForm.controls.address3BillingSeparate
              .value,
          city: this.companyDetail.companyForm.controls.cityBillingSeparate
            .value,
          country:
            this.companyDetail.companyForm.controls.countryBillingSeparate
              .value,
          postalCode:
            this.companyDetail.companyForm.controls.postalCodeBillingSeparate
              .value,
        };
      }

      if (this.companyDetail.isRegisteredForVat) {
        this.vatDetail = {
          vatRegistrationNo:
            this.companyDetail.companyForm.controls.vatRegistrationNo.value,
          vatRegistrationDate:
            this.companyDetail.companyForm.controls.vatRegistrationDate.value,
          vatSchemeId:
            +this.companyDetail.companyForm.controls.vatSchemeId.value,
          vatReturnTypeId:
            +this.companyDetail.companyForm.controls.vatReturnTypeId.value,
        };
      }

      this.contactDetail = {
        name: this.companyDetail.companyForm.controls.name.value,
        phone1: this.companyDetail.companyForm.controls.phone1.value,
        phone2: this.companyDetail.companyForm.controls.phone2.value,
        email: this.companyDetail.companyForm.controls.email.value,
        alternateEmail:
          this.companyDetail.companyForm.controls.alternateEmail.value,
        website: this.companyDetail.companyForm.controls.website.value,
      };

      this.regionalDetail = {
        currencyId: +this.companyDetail.companyForm.controls.currency.value,
        languageId: +this.companyDetail.companyForm.controls.language.value,
        timezoneId: +this.companyDetail.companyForm.controls.timezone.value,
      };

      this.companyData = {
        id: this.companyId,
        logo:
          this.companyDetail.fileUploadResponse === undefined
            ? null
            : this.companyDetail.fileUploadResponse.fileUrl,
        name: this.companyDetail.companyForm.controls.name.value,
        charityRegulatorId:
          +this.companyDetail.companyForm.controls.charityRegulatorId.value,
        establishmentDate: this.datepipe
          .transform(
            this.companyDetail.companyForm.controls.establishDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        registrationNo:
          this.companyDetail.companyForm.controls.registrationNo.value,
        principalPurpose:
          this.companyDetail.companyForm.controls.principalPurpose.value,
        isCIO: this.companyDetail.companyForm.controls.isCIO.value,
        companyNo: this.companyDetail.companyForm.controls.companyNo.value,
        keepBillingDetailSeparate:
          this.companyDetail.companyForm.controls.keepBillingDetailSeparate
            .value,
        tradingStatus:
          this.companyDetail.companyForm.controls.tradingStatusType.value === 1
            ? true
            : false,
        addressDetail: this.addressDetail,
        billingDetail: this.billingDetail,
        contactDetail: this.contactDetail,
        regionalDetail: this.regionalDetail,
        vatDetail: this.companyDetail.isRegisteredForVat
          ? this.vatDetail
          : undefined,
        accountingMethodId:
          +this.companyDetail.companyForm.controls.accountingMethodId.value,
        accountingPeriods: this.accountingPeriods,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  getCompanyCount(): number {
    let companyCount = 0;
    this.companyCount$.subscribe((count) => {
      companyCount = count;
    });

    return companyCount;
  }

  setFinancialPeriod(): void {
    this.highlightRow.financialData = [];
    this.store
      .dispatch(new GetFinancialPeriod())
      .pipe(
        tap((res) => {
          this.highlightRow.financialData = res.common.periodicDate;
          this.commonService.setLocalStorage(
            'financialPeriod',
            JSON.stringify(res.common.periodicDate)
          );
        })
      )
      .subscribe();
  }

  setRoutingFromCompany(): void {
    this.getCompanyCount() > 0
      ? this.router.navigate([
          RoutingPath.Dashboard,
          { loadCompany: btoa('true') },
        ])
      : this.router.navigate([
          RoutingPath.OpeningBalance,
          { isNewUser: btoa('true'), loadCompany: btoa('true') },
        ]);
  }

  onSave(isGetSartedAndIsExit: boolean): void {
    if (this.companyDetail.companyForm.invalid) {
      this.scrollToFirstInvalidControl();
      this.companyDetail.companyForm.markAllAsTouched();
    } else if (
      this.companyDetail.financialYearDetail.financialYearArray.invalid
    ) {
      this.companyDetail.financialYearDetail.financialYearArray.controls.forEach(
        (x) => {
          (Object as any).values(x.controls).forEach((c) => {
            c.markAsTouched();
          });
        }
      );
    } else {
      if (this.dataSubmit()) {
        this.store.dispatch(new CreateCompany(this.companyData)).subscribe(
          (res) => {
            if (res !== undefined) {
              this.commonService.isInitialValueChange = false;
              this.companyId = res.company.companyId;
              this.setFinancialPeriod();
              this.store.dispatch(new GetDefaultCurrency()).subscribe((res) => {
                this.cookieService.set(
                  'defaultCurrency',
                  JSON.stringify(res.common.defaultCurrency)
                );
              });
              this.store
                .dispatch(new GetAccountingMethod())
                .subscribe((res) => {
                  this.cookieService.set(
                    'accountingMethod',
                    JSON.stringify(res.annualReport.accountingMethod)
                  );
                });
              this.store.dispatch(new GetMenu()).subscribe();
              if (
                isGetSartedAndIsExit &&
                (this.activeRouteLink === RoutingPath.AddCharity ||
                  this.activeRouteLink === RoutingPath.Dashboard)
              ) {
                this.setRoutingFromCompany();
                this.store.dispatch(new GetAvailableCompanyCount());
              } else if (this.activeRouteLink === RoutingPath.EditCharity) {
                if (isGetSartedAndIsExit) {
                  history.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              }
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
      }
    }
  }

  scrollToFirstInvalidControl() {
    let form = document.getElementById('companyFormId');
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }

  onCancel(isReset: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (!isReset && !this.commonService.isEmpty(this.companyId)) {
      this.editCompany();
      this.companyDetail.tooggleFlag = true;
      this.companyDetail.tooggleText = 'No';
    } else {
      this.companyId = Guid.EMPTY as unknown as Guid;
      this.companyDetail.url = '';
      this.companyDetail.companyImage = '';
      this.companyDetail.isImageSelected = false;
      this.companyDetail.companyForm.reset();
      this.companyDetail.ngOnInit();
    }
  }
}
