import { FundTransferModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateFundTransfer {
  static readonly type = '[FUNDTRANSFER] Create Fund Transfer';

  constructor(public fundTransfer: FundTransferModel) {}
}

export class GetDataByFundTransferId {
  static readonly type = '[FUNDTRANSFER] Get Data By Fund Transfer Id';

  constructor(public fundTransferId: Guid) {}
}

export class ArchiveAndRestoreFundTransfer {
  static readonly type =
    '[FUNDTRANSFER] Archive and Restore Selected Fund Transfer';

  constructor(public fundTransferIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteFundTransfer {
  static readonly type = '[FUNDTRANSFER] Delete Selected Fund Transfer';

  constructor(public fundTransferIds: Array<Guid>) {}
}

export class GetTransferToFund {
  static readonly type = '[FUNDTRANSFER] Get TransferToFund';
}
