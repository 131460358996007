import { Guid } from 'guid-typescript';

export class AnnualReportModel {
  id: Guid;
  companyId?: Guid;
  accountingPeriodId: Guid;
  examinerReportFile: any;
  trusteeReportFile: any;
  signature: any;
  name: string;
  designation: string;
  date: any;
  color: string;
  showPriorYearValue: boolean;
}
