<div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
  <mat-icon class="material-icons-outlined">error_outline</mat-icon>
  {{ commonNotificationText.ViewOnlyAccessMessage }}
</div>
<div>
  <form [formGroup]="companyForm" id="companyFormId" class="add-company-form">
    <div class="company-header-wrapper">
      <p class="header-text d-flex justify-space-between align-items-center">
        <span> Charity Profile </span>
        <button
          mat-button
          class="header-text-close d-flex align-items-center"
          disableRipple
          (click)="onCloseClick()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </p>
    </div>
    <div>
      <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
        <h3 class="text-gray-200 font-size-18 mt-1">Charity Details</h3>
        <div class="form-field attached">
          <div class="d-flex align-items-center justify-space-between">
            <p>Charity Logo</p>
            <div class="d-flex align-items-center justify-space-between">
              <button
                mat-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="menu"
                disableRipple
              >
                <mat-icon class="text-yellow-200 highlight-icon"
                  >emoji_objects</mat-icon
                >
              </button>
              <mat-menu #menu="matMenu" class="highlight" xPosition="before">
                <div class="highlight-wrapper">
                  <p class="font-size-16 fw-bold">Things to consider</p>
                  <p class="font-size-16 fw-bold">Requirements</p>
                  <ul>
                    <li>Accepted file types: JPG, JPEG, and PNG.</li>
                    <li>
                      File names cannot contain special characters, such as
                      {{ specialCar }}
                    </li>
                    <li>Maximum logo size: 300px width and 100px height.</li>
                  </ul>
                </div>
              </mat-menu>
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center gap-10">
              <div *ngIf="companyImage !== null">
                <img
                  [src]="companyImage"
                  *ngIf="companyImage"
                  width="100px"
                  alt="Logo"
                />
              </div>
              <img [src]="url" *ngIf="url" width="100px" alt="Logo" />
              <a class="link" (click)="onDeletePhoto()" *ngIf="isImageSelected"
                >Delete photo</a
              >
            </div>
            <div
              class="attach-file"
              [ngClass]="isImageSelected ? 'hidden' : ''"
            >
              <mat-icon fontSet="material-icons-outlined"
                >cloud_upload</mat-icon
              >
              <div class="attach-file-label">
                <input
                  #fileupload
                  id="selectFile"
                  type="file"
                  accept="{{ accept }}"
                  (change)="onFileSelected($event)"
                  autocomplete="off"
                  accept=".jpg, .jpeg, .png"
                  maxlength="{{ maxLength.maxLength100 }}"
                  [multiple]="false"
                />
                <label for="file">
                  <strong>Drop a photo here</strong>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex gap-40">
          <div class="form-field">
            <p>Charity Name <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                formControlName="name"
                autocomplete="off"
                maxlength="{{ maxLength.maxLength100 }}"
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>Charity Regulator <span class="text-danger-300">*</span></p>
            <ng-select
              #charityRegulatorId
              (focus)="charityRegulatorId.open()"
              (valueChange)="charityRegulatorId.close()"
              [disableOptionCentering]="true"
              formControlName="charityRegulatorId"
              panelClass="mat-select-position"
              (change)="onRegulatorChange($event, true)"
            >
              <ng-option *ngFor="let cr of charityRegulator" [value]="cr.id">
                <div class="clamp" title="{{ cr.name }}">{{ cr.name }}</div>
              </ng-option>
            </ng-select>
          </div>
          <div class="form-field">
            <p>
              Charity Registration No <span class="text-danger-300">*</span>
            </p>
            <mat-form-field>
              <input
                matInput
                formControlName="registrationNo"
                autocomplete="off"
                required
                maxlength="{{ maxLength.maxLength25 }}"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex gap-40">
          <div class="form-field">
            <p>Charity Number (If Trading)</p>
            <mat-form-field>
              <input
                matInput
                formControlName="companyNo"
                autocomplete="off"
                maxlength="{{ maxLength.maxLength25 }}"
                type="text"
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>Trading Status <span class="text-danger-300">*</span></p>
            <ng-select
              #tradingStatusType
              (focus)="tradingStatusType.open()"
              (valueChange)="tradingStatusType.close()"
              [disableOptionCentering]="true"
              formControlName="tradingStatusType"
              panelClass="mat-select-position"
            >
              <ng-option *ngFor="let ts of tradingStatus" [value]="ts.id">
                <div class="clamp" title="{{ ts.name }}">{{ ts.name }}</div>
              </ng-option>
            </ng-select>
          </div>
          <div class="form-field datepicker">
            <p>Date Of Commencement<span class="text-danger-300">*</span></p>
            <mat-form-field appearance="standard">
              <input
                matInput
                formControlName="establishDate"
                required
                [matDatepicker]="establishDatePicker"
                autocomplete="off"
                (change)="commonService.validateDate($event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="establishDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #establishDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex gap-40 align-items-center" style="width: 63%">
          <div class="form-field">
            <p>Principal Purpose<span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                formControlName="principalPurpose"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>

          <div class="form-field m-0">
            <mat-checkbox formControlName="isCIO">
              This charity is a CIO (Charitable Incorporated Organisation).
            </mat-checkbox>
          </div>
        </div>

        <div>
          <h3 class="text-gray-200 font-size-16 mb-2 pt-1">
            Registerd Office Address
          </h3>

          <div class="d-flex gap-40">
            <div class="form-field">
              <p>Address Line 1<span class="text-danger-300">*</span></p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="address1"
                  required
                  autocomplete="off"
                  maxlength="{{ maxLength.maxLength100 }}"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <p>Address Line 2</p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="address2"
                  autocomplete="off"
                  maxlength="{{ maxLength.maxLength100 }}"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <p>Address Line 3</p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="address3"
                  autocomplete="off"
                  maxlength="{{ maxLength.maxLength100 }}"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="d-flex gap-40">
            <div class="form-field">
              <p>Town/City<span class="text-danger-300">*</span></p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="city"
                  required
                  autocomplete="off"
                  maxlength="{{ maxLength.maxLength100 }}"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <p>Country<span class="text-danger-300">*</span></p>
              <ng-select
                #country
                (focus)="country.open()"
                (valueChange)="country.close()"
                [disableOptionCentering]="true"
                formControlName="country"
                panelClass="mat-select-position"
              >
                <ng-option *ngFor="let cl of countryList" [value]="cl.id">
                  <div title="{{ cl.description }}">{{ cl.description }}</div>
                </ng-option>
              </ng-select>
            </div>
            <div class="form-field">
              <div class="d-flex flex-column">
                <div>
                  <p>Post Code<span class="text-danger-300">*</span></p>
                  <mat-form-field>
                    <input
                      matInput
                      formControlName="postalCode"
                      autocomplete="off"
                      maxlength="{{ maxLength.maxLength25 }}"
                      type="text"
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon fontSet="material-icons-outlined" class="text-primary"
                >description</mat-icon
              >
              <div class="d-flex flex-column">
                <span>Add Accounting Period</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            class="content-body"
            [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
          >
            <h3 class="text-gray-200 font-size-18 pt-1">Accounting Period</h3>
            <app-add-financial-year
              [triggerEditFinancialData]="triggerEditFinancialData"
            ></app-add-financial-year>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >description</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Add Contact Info</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="content-body"
          [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
        >
          <h3 class="text-gray-200 font-size-18 mb-2 pt-1">Contact Details</h3>

          <div class="d-flex gap-40">
            <div class="form-field">
              <p>Contact Person</p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="contactPerson"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <p>Phone</p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="phone1"
                  autocomplete="off"
                  maxlength="{{ maxLength.maxLength25 }}"
                  type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <p>Mobile Number</p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="phone2"
                  autocomplete="off"
                  maxlength="{{ maxLength.maxLength25 }}"
                  type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex gap-40">
            <div class="form-field">
              <p>Email</p>
              <mat-form-field>
                <input
                  matInput
                  emailValidator
                  formControlName="email"
                  autocomplete="off"
                  maxlength="{{ maxLength.maxLength255 }}"
                  type="text"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <p>Alternative Email</p>
              <mat-form-field>
                <input
                  matInput
                  emailValidator
                  formControlName="alternateEmail"
                  autocomplete="off"
                  maxlength="{{ maxLength.maxLength255 }}"
                  type="text"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <p>Website</p>
              <mat-form-field>
                <input matInput formControlName="website" autocomplete="off" />
              </mat-form-field>
            </div>
          </div>
          <div class="form-field wp-100">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center gap-10 mt-1">
                <mat-checkbox
                  class="example-margin"
                  formControlName="keepBillingDetailSeparate"
                  (change)="showOptions($event)"
                >
                  Would you like to add a separate operating address?
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div *ngIf="keepBillingDetailSeparate">
            <h3 class="text-gray-200 font-size-18 mb-2 pt-1">
              Separate Billing Details
            </h3>
            <div class="d-flex gap-40">
              <div class="form-field">
                <p>Address Line 1</p>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="address1BillingSeparate"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
              <div class="form-field">
                <p>Address Line 2</p>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="address2BillingSeparate"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
              <div class="form-field">
                <p>Address Line 3</p>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="address3BillingSeparate"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex gap-40">
              <div class="form-field">
                <p>Town/City</p>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="cityBillingSeparate"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
              <div class="form-field">
                <p>Country</p>
                <ng-select
                  #countryBillingSeparate
                  (focus)="countryBillingSeparate.open()"
                  (valueChange)="countryBillingSeparate.close()"
                  [disableOptionCentering]="true"
                  formControlName="countryBillingSeparate"
                  panelClass="mat-select-position"
                >
                  <ng-option *ngFor="let cl of countryList" [value]="cl.id">
                    <div title="{{ cl.description }}">{{ cl.description }}</div>
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-field">
                <div class="d-flex flex-column">
                  <div>
                    <p>Post Code</p>
                    <mat-form-field>
                      <input
                        matInput
                        formControlName="postalCodeBillingSeparate"
                        autocomplete="off"
                        maxlength="{{ maxLength.maxLength25 }}"
                        type="text"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >description</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Add Accounting Details</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="content-body"
          [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
        >
          <h3 class="text-gray-200 font-size-18 mb-2 pt-1">
            Accounting Details
          </h3>
          <div class="d-flex gap-40">
            <div class="form-field">
              <p>Accounting Method<span class="text-danger-300">*</span></p>
              <ng-select
                #accountMethod
                (focus)="accountMethod.open()"
                (valueChange)="accountMethod.close()"
                [disableOptionCentering]="true"
                formControlName="accountingMethodId"
                panelClass="mat-select-position"
                (change)="onAccountingChange()"
              >
                <ng-option
                  *ngFor="let cl of accountingMethodList"
                  [value]="cl.id"
                >
                  <div title="{{ cl.name }}">{{ cl.name }}</div>
                </ng-option>
              </ng-select>
            </div>
            <div class="form-field">
              <p>Currency</p>
              <ng-select
                #currency
                (focus)="currency.open()"
                (valueChange)="currency.close()"
                [disableOptionCentering]="true"
                formControlName="currency"
                panelClass="mat-select-position"
              >
                <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
                  <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
                    {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
                  </div>
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="d-flex justify-space-between mb-2">
            <p class="font-size-16 fw-bold m-0">
              Is this charity registered for VAT?
            </p>
            <div class="radio-button d-flex align-items-center gap-10">
              <span class="fw-bold">{{ tooggleText }}</span>
              <mat-slide-toggle
                [checked]="tooggleFlag"
                (change)="registeredForVat()"
              ></mat-slide-toggle>
            </div>
          </div>
          <div [hidden]="!isRegisteredForVat">
            <h3 class="text-gray-200 font-size-16 mt-0 mb-2">VAT DETAILS</h3>
            <div class="d-flex gap-40">
              <div class="form-field">
                <p>VAT Registration No<span class="text-danger-300">*</span></p>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="vatRegistrationNo"
                    autocomplete="off"
                    maxlength="{{ maxLength.maxLength9 }}"
                    type="text"
                    [required]="isRegisteredForVat"
                    allowDecimal
                  />
                </mat-form-field>
                <div
                  *ngIf="
                    companyForm.get('vatRegistrationNo').hasError('pattern') &&
                    companyForm.get('vatRegistrationNo').touched &&
                    companyForm.get('vatRegistrationNo').dirty
                  "
                  class="text-danger-300"
                >
                  Vat Registartion number should be of 9 digit.
                </div>
              </div>

              <div class="form-field datepicker">
                <p>
                  VAT Registration Date<span class="text-danger-300">*</span>
                </p>
                <mat-form-field appearance="standard">
                  <input
                    matInput
                    formControlName="vatRegistrationDate"
                    [matDatepicker]="vatRegistrationDate"
                    autocomplete="off"
                    (change)="commonService.validateDate($event)"
                    [required]="isRegisteredForVat"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="vatRegistrationDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #vatRegistrationDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="form-field">
                <p>VAT Scheme<span class="text-danger-300">*</span></p>
                <ng-select
                  #vatSchemeId
                  (focus)="vatSchemeId.open()"
                  (valueChange)="vatSchemeId.close()"
                  [disableOptionCentering]="true"
                  formControlName="vatSchemeId"
                  dropdownPosition="top"
                  panelClass="mat-select-position"
                  (change)="onVatSchemeChange()"
                  [required]="isRegisteredForVat"
                >
                  <ng-option
                    *ngFor="let option of vatSchemeList"
                    [value]="option.id"
                  >
                    <div title="{{ option.name }}">{{ option.name }}</div>
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="d-flex gap-40" style="width: 63%">
              <div class="form-field">
                <p>VAT Submission Type<span class="text-danger-300">*</span></p>
                <ng-select
                  #vatReturnTypeId
                  (focus)="vatReturnTypeId.open()"
                  (valueChange)="vatReturnTypeId.close()"
                  [disableOptionCentering]="true"
                  formControlName="vatReturnTypeId"
                  panelClass="mat-select-position"
                  [required]="isRegisteredForVat"
                >
                  <ng-option
                    *ngFor="let cl of vatReturnTypeList"
                    [value]="cl.id"
                  >
                    <div title="{{ cl.name }}">{{ cl.name }}</div>
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div hidden="true">
      <button
        mat-button
        (click)="addRegionalInfo(isRegional)"
        class="fw-bold text-primary"
        disableRipple
      >
        <mat-icon *ngIf="!isRegional">add_circle_outline</mat-icon>
        <mat-icon *ngIf="isRegional">remove_circle_outline</mat-icon>Add
        Regional Info
      </button>
    </div>

    <div hidden="true" *ngIf="isRegional">
      <h3 class="text-gray-200 font-size-18 pt-1">REGIONAL DETAILS:</h3>
      <div class="d-flex gap-40">
        <div class="form-field">
          <p>Time Zone</p>
          <ng-select
            #timeZone
            (focus)="timeZone.open()"
            (valueChange)="timeZone.close()"
            [disableOptionCentering]="true"
            formControlName="timezone"
            panelClass="mat-select-position"
          >
            <ng-option *ngFor="let cl of timezone" [value]="cl.id">
              <div title="{{ cl.name }}">{{ cl.name }}</div>
            </ng-option>
          </ng-select>
        </div>
        <div class="form-field">
          <p>Language</p>
          <ng-select
            #language
            (focus)="language.open()"
            (valueChange)="language.close()"
            [disableOptionCentering]="true"
            formControlName="language"
            panelClass="mat-select-position"
          >
            <ng-option *ngFor="let cl of languageList" [value]="cl.id">
              <div title="{{ cl.name }}">{{ cl.name }}</div>
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </form>
</div>
