import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { BankAccount, BankFeeds } from '@app/core/Models/bank/bank-account';
import { BankDasboardService, CommonService } from '@app/core/Services';
import {
  GetAllBankAccount,
  MenuState,
  RefreshBankAccountList,
} from '@app/core/Store';
import { ConfirmationBoxComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-bank-dashboard',
  templateUrl: './bank-dashboard.component.html',
  styleUrls: ['./bank-dashboard.component.scss'],
})
export class BankDashboardComponent implements OnInit {
  bankAccountData: Array<BankAccount>;
  ids: Array<Guid>;

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  bankAccountDetailList: Array<BankFeeds>;
  subscriptionRouting: Subscription;
  isExpanded = true;
  selected;

  @Output()
  readonly triggerDataFromBank = new EventEmitter<any>();

  @Output()
  readonly triggerIsCustomFlag = new EventEmitter<any>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  moduleId: Modules;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private bankDasboardService: BankDasboardService,
    public dialog: MatDialog,
    public store: Store,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.moduleId$.subscribe((moduleId) => {
      this.moduleId = moduleId;
      this.spinner.show();
      setTimeout(() => {
        this.getAllBankAccount();
      }, 1000);
    });
  }

  getAllBankAccount(bankId?: any): void {
    this.store
      .dispatch(new GetAllBankAccount())
      .pipe(
        tap((res) => {
          this.bankAccountData = res.bankReconciliation.allBankAccount;
          if (this.bankAccountData.length > 0) {
            let isDefaultSelected;

            isDefaultSelected = this.bankAccountData.every(
              (item: any) => item.isDefault === true
            );

            if (!isDefaultSelected) {
              if (
                bankId !== undefined &&
                bankId !== null &&
                (bankId as any as Guid)
              ) {
                // Find the bank account with the matching bankId
                const selectedBankAccount = this.bankAccountData.find(
                  (item: any) => item.id === bankId
                );

                if (selectedBankAccount) {
                  selectedBankAccount.isDefault = true;

                  const param = {
                    id: selectedBankAccount.id,
                  };

                  this.triggerIsCustomFlag.emit(param);
                  this.onAccountSelected(selectedBankAccount, true);
                }
              } else {
                if (
                  Object.keys(
                    this.commonService.getLocalStorage('selectedBank')
                  ).length !== 0
                ) {
                  const id = this.commonService.getLocalStorage('selectedBank');
                  const selectedBankAccount = this.bankAccountData.find(
                    (item: any) => item.id === id
                  );

                  if (selectedBankAccount) {
                    selectedBankAccount.isDefault = true;
                  }

                  const param = {
                    id: selectedBankAccount,
                  };
                  this.triggerIsCustomFlag.emit(param);
                  this.onAccountSelected(selectedBankAccount, true);
                  this.commonService.removeItemLocalStorage('selectedBank');
                } else {
                  this.bankAccountData[0].isDefault = true;

                  const param = {
                    id: this.bankAccountData[0].id,
                  };
                  this.triggerIsCustomFlag.emit(param);
                  this.onAccountSelected(this.bankAccountData[0], true);
                }
              }
            }

            this.bankAccountData.forEach((element) => {
              element.isCardSelected = element.isDefault;
            });
          }
        })
      )
      .subscribe();
  }

  expandPanel(): void {
    this.isExpanded = !this.isExpanded;
    this.triggerData();
  }

  onAccountSelected(element: any, setDefault: boolean): void {
    this.bankAccountData.forEach((x) => {
      x.isCardSelected = x.id === element.id;
      x.isDefault = x.id === element.id;
    });
    this.triggerData();
  }

  triggerData(): void {
    const selectedRecord = this.bankAccountData.find(
      (item) => item.isDefault === true
    );

    const data = {
      id: selectedRecord?.id,
      isExpanded: this.isExpanded,
    };

    this.triggerIsCustomFlag.emit(data);
    this.triggerDataFromBank.emit(data);
  }

  onAccountDelete(data: any): void {
    this.ids = [];
    this.ids.push(data.id);

    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          ids: this.ids,
          type: ConfirmationType.Delete,
          moduleId: Modules.BankDashboard,
          headerText: NotificationHeader.deleteConfirmation,
          detailText: NotificationDetails.deleteAllDetailText,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          let isDefaultSelected;

          isDefaultSelected = this.bankAccountData.filter(
            (item: any) => item.isDefault === true && item.id !== data.id
          );
          let bankId;
          if (
            isDefaultSelected !== undefined &&
            isDefaultSelected !== null &&
            isDefaultSelected.length > 0
          ) {
            bankId = isDefaultSelected[0].id;
          }

          this.getAllBankAccount(bankId);
        }
      });
  }
}
