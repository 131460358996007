import { Guid } from 'guid-typescript';

export class CompanyPreference {
  id: Guid;
  moduleId: number;
  autoGenerate: boolean;
  prefix: string;
  suffix: string;
  repeatType?: number;
}
