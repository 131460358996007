import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  ChartOfAccountCurrentAssetStockGroupTypeName,
  ChartOfAccountExpenseGroupTypeName,
  ChartOfAccountFixedAssetInTangibleGroupTypeName,
  ChartOfAccountFixedAssetTangibleGroupTypeName,
  ChartOfAccountIncomeGroupTypeName,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { DonationInKindTypes } from '@app/core/Enum/donation-in-kind-types';
import { IncomeTypeName } from '@app/core/Enum/income-types-name';
import {
  ChartOfAccountListParam,
  GlobalComponent,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  AccountState,
  CommonState,
  CreateActivity,
  CreateDonor,
  CreateFund,
  CreateSponsor,
  DonationInKindState,
  DonationState,
  DonorState,
  GetActivityList,
  GetAllDonor,
  GetCustomBankAndCashAccount,
  GetDataByActivityId,
  GetDataByDonorId,
  GetDataByFundId,
  GetDataBySponsorId,
  GetDonationType,
  GetFundNameList,
  GetGroupAccountsBasedOnGroupId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetSponsoreList,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-donation',
  templateUrl: './add-donation.component.html',
  styleUrls: ['./add-donation.component.scss'],
})
export class AddDonationComponent implements OnInit {
  donationForm: FormGroup;
  sponsoreList: SideListModel[];
  activityList: SideListModel[];
  fundNameList: any;
  donationTypeList: SideListModel[];
  donorList: any;
  depositToList: SideListModel[];
  tempDepositToGroupList: SideListModel[];
  tempIncomeAccountGroupList: GroupListModel[];
  incomeAccountList: GroupListModel[];
  feeExpenseAccountList: GroupListModel[];
  tempFeeExpenseAccountGroupList: GroupListModel[];

  @Input() triggerEditData: Observable<any>;
  @Input() moduleId: number;

  @Input() triggerTransactionLogData: Observable<any>;

  disabledEditButton = true;
  disabledFundEditButton = true;
  disabledSponsorEditButton = true;
  isAddMode = true;
  disabledActivityEditButton = true;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  periodicDate: any;
  getModuleId: number;
  subModuleId = Modules;
  getListId: Guid;
  donationTypeId = DonationInKindTypes.DonatedFacilitiesAndServices;

  groupNames = new Array<number>();
  typeId = new Array<number>();
  groupNamesFeeExpense = new Array<number>();
  typeIdFeeExpense = new Array<number>();
  private destroy$ = new Subject<void>();

  isSponserEventChangePermission: boolean = true;
  isFundChangePermission: boolean = true;
  isActivityChangePermission: boolean = true;
  isDonorChangePermission: boolean = true;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();

    this.triggerEditData
      .pipe(
        switchMap((data) => {
          this.isAddMode = false;
          this.donationTypeId = data.donationTypeID;
          return this.loadDropdownValues().pipe(map(() => data));
        })
      )
      .subscribe((data) => {
        this.editDonation(data);
      });

    this.donationForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.donationForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSponserEventChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.SponsorEvent
      );
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
      this.isActivityChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Activities
      );
      this.isDonorChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Donors
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  donorChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  gotoAddDonor(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Donors,
          headerText: `Add ${ModuleName.Donors}`,
          saveActionName: CreateDonor,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getDonor(id);
        }
      });
  }

  getDonor(id): void {
    this.store.dispatch(new GetAllDonor()).subscribe((res) => {
      this.donorList = res.donor.sideListModel;
      if (id !== null && id !== undefined) {
        this.donationForm.controls.donorName.setValue(id);
      }
    });
  }

  gotoEditDonor(): void {
    if (this.donorList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Donors,
            headerText: `Edit ${ModuleName.Donors}`,
            id: this.donationForm.controls.donorName.value,
            saveActionName: CreateDonor,
            getActionName: GetDataByDonorId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getDonor(id);
          }
        });
    }
  }

  editDonation(data): void {
    if (data) {
      let incomeAccount;
      this.incomeAccountList.forEach((items) => {
        const accountId = items.listModels.find(
          (x: any) => x.id === data.incomeAccount
        );
        if (accountId !== null && accountId !== undefined) {
          incomeAccount = accountId;
        }
      });
      let feeExpenseAccount;
      if (this.feeExpenseAccountList.length > 0) {
        this.feeExpenseAccountList.forEach((items) => {
          const feeExpenseAccountId = items.listModels.find(
            (x: any) => x.id === data.feeExpenseAccount
          );

          if (
            feeExpenseAccountId !== null &&
            feeExpenseAccountId !== undefined
          ) {
            feeExpenseAccount = feeExpenseAccountId;
          }
        });
      }
      this.disabledEditButton = false;
      this.disabledFundEditButton = false;
      this.disabledActivityEditButton = false;
      this.donationForm.patchValue({
        donorName: data.donorId,
        donationDate: data.donationDate,
        donationType: data.donationTypeID,
        incomeAccount: incomeAccount,
        donationAmount: data.donationAmount,
        feeExpenseAccount: feeExpenseAccount ?? '',
        feesAmount: data.feeAmount,
        depositTo: data.depositToAccountId,
        depositAmount: data.depositAmount,
        sponsoredEvent: data.sponsorEventId === null ? '' : data.sponsorEventId,
        benefitAmountToDonor: data.benefitAmountToDonor,
        activity: data.activityId === null ? '' : data.activityId,
        fundName: data.fundId,
        notes: data.note,
        amount: data.amount,
      });

      this.disabledSponsorEditButton =
        this.donationForm.controls.sponsoredEvent.value === -1;
    }
  }

  setForm(): void {
    this.donationForm = new FormGroup(
      {
        donorName: new FormControl('', [
          ,
          this.commonService.whiteSpaceValidate,
        ]),
        donationDate: new FormControl(
          new Date(),
          datePickerValidator(this.periodicDate)
        ),
        incomeAccount: new FormControl(''),
        donationAmount: new FormControl(0),
        feeExpenseAccount: new FormControl(''),
        feesAmount: new FormControl(0),
        depositTo: new FormControl(''),
        depositAmount: new FormControl({ value: 0, disabled: true }),
        sponsoredEvent: new FormControl(''),
        benefitAmountToDonor: new FormControl({ value: 0, disabled: true }),
        activity: new FormControl(''),
        fundName: new FormControl('', [
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]),
        notes: new FormControl(''),
        amount: new FormControl(''),
        donationType: new FormControl(''),
      },
      {
        validators: this.commonService.compareFields(
          'donationAmount',
          'feesAmount',
          this.moduleId
        ),
      }
    );

    if (this.moduleId !== Modules.DonationsInKind) {
      this.donationForm
        .get('depositTo')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      this.donationForm.get('amount')?.clearValidators();
    } else {
      this.donationForm.get('depositTo')?.clearValidators();
      this.donationForm
        .get('amount')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
    }
    this.donationForm.controls['depositTo'].updateValueAndValidity();
    this.donationForm.controls['amount'].updateValueAndValidity();
    this.loadDropdownValues().subscribe();
  }

  loadDropdownValues(): Observable<any> {
    const getSponsoreList = this.store.dispatch(new GetSponsoreList());
    const getActivityList = this.store.dispatch(new GetActivityList());
    const getFundNameList = this.store.dispatch(new GetFundNameList());
    const getAllDonorList = this.store.dispatch(new GetAllDonor());
    const getCustomBankAndCashAccount = this.store.dispatch(
      new GetCustomBankAndCashAccount()
    );
    const getDonationType = this.store.dispatch(new GetDonationType());
    const getCreditAccount = this.getCreditAccount();
    const getDebitAccount = this.getDebitAccount();

    return forkJoin([
      getSponsoreList,
      getActivityList,
      getFundNameList,
      getAllDonorList,
      getCustomBankAndCashAccount,
      getDonationType,
      getCreditAccount,
      getDebitAccount,
    ]).pipe(
      tap((res) => {
        if (res[6].common.accountGroupList.length > 0) {
          this.incomeAccountList = res[6].common.accountGroupList;
          this.tempIncomeAccountGroupList = this.incomeAccountList;
        }

        if (res[7].common.accountGroupList.length > 0) {
          this.feeExpenseAccountList = res[7].common.accountGroupList;
          this.tempFeeExpenseAccountGroupList = this.feeExpenseAccountList;
        }

        this.sponsoreList = this.store.selectSnapshot(
          DonationState.getSponsore
        );
        this.activityList = this.store.selectSnapshot(
          DonationState.getActivity
        );
        this.fundNameList = this.store.selectSnapshot(
          AccountState.getFundNameList
        );
        this.donorList = this.store.selectSnapshot(DonorState.getAllDonor);

        this.depositToList = this.store.selectSnapshot(
          CommonState.getCustomBankAndCashAccount
        );
        this.donationTypeList = this.store.selectSnapshot(
          DonationInKindState.getDonationType
        );

        if (this.fundNameList.length > 0) {
          this.disabledFundEditButton = false;
          this.donationForm.controls.fundName.setValue(this.fundNameList[0].id);
        }

        if (this.donorList.length > 0) {
          this.disabledEditButton = false;
        }
      })
    );
  }

  onSponsorEventChange(event: any): void {
    this.disabledSponsorEditButton =
      this.donationForm.controls.sponsoredEvent.value === -1;
  }

  onDepositToSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempDepositToGroupList
    );
    this.depositToList = list;
  }

  getCreditAccount(): Observable<any> {
    if (this.moduleId !== Modules.DonationsInKind) {
      this.groupNames.push(GroupNames.Income);
      this.typeId.push(IncomeTypeName.IncomeDonationAndLegacies);
    } else {
      this.setGroupAndTypeForDonationInKindForIncome(this.donationTypeId);
    }

    const param: ChartOfAccountListParam = {
      groupIds: this.groupNames,
      typeIds: this.typeId,
    };

    return this.store.dispatch(
      new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
    );
  }

  onDonationTypeChanges(event: any): void {
    this.donationTypeId = event;
    const getCreditAccount = this.getCreditAccount()
      .pipe()
      .subscribe((res) => {
        if (res.common.accountGroupList.length > 0) {
          this.incomeAccountList = res.common.accountGroupList;
          this.tempIncomeAccountGroupList = this.incomeAccountList;
        }
      });
    const getDebitAccount = this.getDebitAccount()
      .pipe()
      .subscribe((res) => {
        if (res.common.accountGroupList.length > 0) {
          this.feeExpenseAccountList = res.common.accountGroupList;
          this.tempFeeExpenseAccountGroupList = this.feeExpenseAccountList;
        }
      });
  }

  getDebitAccount(): Observable<any> {
    const groupNames = new Array<number>();
    let dispatchAction: any;

    if (this.moduleId === Modules.Donations) {
      groupNames.push(GroupNames.Expense);
      dispatchAction = new GetGroupAccountsBasedOnGroupId(groupNames);
    } else {
      this.setGroupAndTypeForDonationInKindForIncome(this.donationTypeId);
      const param: ChartOfAccountListParam = {
        groupIds: this.groupNamesFeeExpense,
        typeIds: this.typeIdFeeExpense,
      };
      dispatchAction = new GetGroupAccountsBasedOnGroupIdAndTypeId(param);
    }

    return this.store.dispatch(dispatchAction);
  }

  setGroupAndTypeForDonationInKindForIncome(donationType): void {
    this.groupNames = [];
    this.typeId = [];
    this.groupNamesFeeExpense = [];
    this.typeIdFeeExpense = [];

    if (donationType === DonationInKindTypes.DonatedFacilitiesAndServices) {
      this.groupNames.push(GroupNames.Income);
      this.typeId.push(ChartOfAccountIncomeGroupTypeName.DonationsAndLegacies);
      this.groupNamesFeeExpense.push(GroupNames.Expense);
      this.typeIdFeeExpense.push(
        ChartOfAccountExpenseGroupTypeName.RaisingFunds,
        ChartOfAccountExpenseGroupTypeName.CharitableActivities,
        ChartOfAccountExpenseGroupTypeName.SeparateMaterialItemofExpense,
        ChartOfAccountExpenseGroupTypeName.Other,
        ChartOfAccountExpenseGroupTypeName.SupportCosts,
        ChartOfAccountExpenseGroupTypeName.GovernanceCosts,
        ChartOfAccountExpenseGroupTypeName.NetGainsLossesonInvestments,
        ChartOfAccountExpenseGroupTypeName.ExtraordinaryItems,
        ChartOfAccountExpenseGroupTypeName.GainsLossesonRevaluationofFixedAssets,
        ChartOfAccountExpenseGroupTypeName.OtherGainsLosses
      );
    }

    if (
      donationType ===
      DonationInKindTypes.DonatedGoodsAndServicesCapitalisedAsTangibleFixedAssets
    ) {
      this.groupNames.push(GroupNames.Income);
      this.typeId.push(ChartOfAccountIncomeGroupTypeName.DonationsAndLegacies);
      this.groupNamesFeeExpense.push(
        GroupNames.FixedAssets_Tangibles,
        GroupNames.FixedAssets_Intangibles
      );
      this.typeIdFeeExpense.push(
        ChartOfAccountFixedAssetTangibleGroupTypeName.FreeholdLandandBuildings,
        ChartOfAccountFixedAssetTangibleGroupTypeName.OtherLandandBuildings,
        ChartOfAccountFixedAssetTangibleGroupTypeName.PlantandMachinery,
        ChartOfAccountFixedAssetTangibleGroupTypeName.MotorVehicles,
        ChartOfAccountFixedAssetTangibleGroupTypeName.CommercialVehicles,
        ChartOfAccountFixedAssetTangibleGroupTypeName.FixturesandFittings,
        ChartOfAccountFixedAssetTangibleGroupTypeName.ComputerEquipment,
        ChartOfAccountFixedAssetInTangibleGroupTypeName.RandD,
        ChartOfAccountFixedAssetInTangibleGroupTypeName.PatentsandTrademarks,
        ChartOfAccountFixedAssetInTangibleGroupTypeName.Other
      );
    }

    if (
      donationType ===
      DonationInKindTypes.DonatedGoodsForDistributionToBeneficiaries
    ) {
      this.groupNames.push(GroupNames.Income);
      this.typeId.push(ChartOfAccountIncomeGroupTypeName.DonationsAndLegacies);
      this.groupNamesFeeExpense.push(GroupNames.CurrentAssets_Stock);
      this.typeIdFeeExpense.push(
        ChartOfAccountCurrentAssetStockGroupTypeName.Charitable_DonatedGoods_Distribution
      );
    }

    if (donationType === DonationInKindTypes.DonatedGoodsForResale) {
      this.groupNames.push(GroupNames.Income);
      this.typeId.push(
        ChartOfAccountIncomeGroupTypeName.OtherTradingActivities
      );
      this.groupNamesFeeExpense.push(GroupNames.CurrentAssets_Stock);
      this.typeIdFeeExpense.push(
        ChartOfAccountCurrentAssetStockGroupTypeName.Charitable_DonatedGoods_Resale
      );
    }
  }

  getOptionText(option) {
    return option.name;
  }

  resetAccountList(): void {
    this.incomeAccountList = this.tempIncomeAccountGroupList;
    this.scrollIntoView(this.donationForm.controls.incomeAccount.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempIncomeAccountGroupList
    );
    this.incomeAccountList = list;
  }

  resetFessExpenseAccountList(): void {
    this.feeExpenseAccountList = this.tempFeeExpenseAccountGroupList;
    this.scrollIntoView(this.donationForm.controls.feeExpenseAccount.value);
  }

  onFeeSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempFeeExpenseAccountGroupList
    );
    this.feeExpenseAccountList = list;
  }

  onDonationAmountChange(): void {
    const donationAmount = +this.donationForm.controls.donationAmount.value;
    this.donationForm.controls.depositAmount.setValue(donationAmount);
    this.donationForm.controls.benefitAmountToDonor.setValue(donationAmount);
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledFundEditButton = false;
    } else {
      this.disabledFundEditButton = true;
    }
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledFundEditButton = false;
          this.getFund(id);
        }
      });
  }

  getFund(id): void {
    this.store.dispatch(new GetFundNameList()).subscribe((res) => {
      this.fundNameList = res.account.fundNameList;
      if (id !== null && id !== undefined) {
        this.donationForm.controls.fundName.setValue(id);
      }
    });
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.donationForm.controls.fundName.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }

  gotoAddSponsor(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.SponsorEvent,
          headerText: `Add ${ModuleName.SponsorEvent}`,
          saveActionName: CreateSponsor,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledSponsorEditButton = false;
          this.getSponsor(id);
        }
      });
  }

  gotoEditSponsor(): void {
    if (this.sponsoreList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.SponsorEvent,
            headerText: `Edit ${ModuleName.SponsorEvent}`,
            id: this.donationForm.controls.sponsoredEvent.value,
            saveActionName: CreateSponsor,
            getActionName: GetDataBySponsorId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getSponsor(id);
          }
        });
    }
  }

  getSponsor(id) {
    this.store.dispatch(new GetSponsoreList()).subscribe((res) => {
      this.sponsoreList = res.donation.sponsore;
      if (id !== null && id !== undefined) {
        this.donationForm.controls.sponsoredEvent.setValue(id);
      }
    });
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  activityChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledActivityEditButton = false;
    } else {
      this.disabledActivityEditButton = true;
    }
  }

  gotoAddActivity(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Activities,
          headerText: `Add ${ModuleName.Activities}`,
          saveActionName: CreateActivity,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledActivityEditButton = false;
          this.getActivity(id);
        }
      });
  }

  gotoEditActivity(): void {
    if (this.activityList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Activities,
            headerText: `Edit ${ModuleName.Activities}`,
            id: this.donationForm.controls.activity.value,
            saveActionName: CreateActivity,
            getActionName: GetDataByActivityId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getActivity(id);
          }
        });
    }
  }

  getActivity(id): void {
    this.store.dispatch(new GetActivityList()).subscribe((res) => {
      this.activityList = res.donation.activity;

      if (id !== null && id !== undefined) {
        this.donationForm.controls.activity.setValue(id);
      }
    });
  }
}
