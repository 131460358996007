import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ComponentName,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { Notes } from '@app/core/Enum/note';
import {
  GetNoteParamModel,
  GlobalComponent,
  NoteDataModel,
} from '@app/core/Models';
import { CommonService, ModulePermission } from '@app/core/Services';
import { GetNotesData, MenuState, SaveNoteData } from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  PasteCleanupService,
  TableService,
  ToolbarService,
} from '@syncfusion/ej2-angular-richtexteditor';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-trustees-report',
  templateUrl: './trustees-report.component.html',
  styleUrls: ['./trustees-report.component.scss'],
  providers: [
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    TableService,
    PasteCleanupService,
  ],
})
export class TrusteesReportComponent implements OnInit {
  periodicDateList: any;
  jsonObject: any;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  accountingPeriod: Guid;

  noteData: NoteDataModel;
  notesModuleId: Notes;
  ids: Array<any>;
  trusteesReportForm: FormGroup;

  notesId = Guid.EMPTY as unknown as Guid;
  moduleId: number;
  headerText: string;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private globalComponent: GlobalComponent,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
    private modulePermission: ModulePermission,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;

      if (
        value.data.url === this.router.url.slice(1) &&
        value.componentName === ComponentName.TrusteesReport
      ) {
        this.notesModuleId =
          this.moduleId === Modules.TrusteesReport
            ? Notes.Note44
            : this.moduleId === Modules.IndependentExaminerReport
            ? Notes.Note45
            : Notes.Note46;

        this.getHeaderText();
        this.setPeriodDate();
      }
    });
  }

  getHeaderText(): void {
    this.headerText =
      this.moduleId === Modules.TrusteesReport
        ? ModuleName.TrusteesReport
        : this.moduleId === Modules.IndependentExaminerReport
        ? ModuleName.IndependentExaminerReport
        : ModuleName.AuditReport;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();

    this.trusteesReportForm.controls.accountingPeriod.setValue(
      this.periodicDateList[0].id
    );

    this.editNotes();
  }

  editNotes(): void {
    this.spinner.show();

    const param: GetNoteParamModel = {
      id: this.notesModuleId,
      accountingPeriodId:
        this.trusteesReportForm.controls.accountingPeriod.value,
      ids: this.ids ?? null,
    };

    this.store.dispatch(new GetNotesData(param)).subscribe((res) => {
      this.notesId =
        res.note.noteData.userInputData !== null &&
        res.note.noteData.userInputData !== undefined
          ? res.note.noteData.userInputData.id
          : (Guid.EMPTY as unknown as Guid);

      this.trusteesReportForm.patchValue({
        textbox1: res.note.noteData.userInputData.data.textbox1
          ? res.note.noteData.userInputData.data.textbox1
          : '',
      });
    });
  }

  setForm(): void {
    this.trusteesReportForm = new FormGroup({
      accountingPeriod: new FormControl(''),
      textbox1: new FormControl(''),
    });
  }

  onAccountPeriodChange(): void {}

  dataSubmit(): boolean {
    try {
      const arrayObj: any = {
        textbox1: this.trusteesReportForm.controls.textbox1.value,
      };
      this.jsonObject = <JSON>arrayObj;

      this.noteData = {
        accountingPeriodId:
          this.trusteesReportForm.controls.accountingPeriod.value,
        noteId: this.notesModuleId,
        checkBoxData: {},
        percentageData: null,
        data: this.jsonObject,
        id: this.notesId,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveNoteData(this.noteData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.notesId = res.note.noteId;
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }

  onCancel(): void {
    this.ngOnInit();
  }
}
