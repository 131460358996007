import { Injectable } from '@angular/core';
import { PaymentModel, SideListModel } from '@app/core/Models';
import { PaymentService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Guid } from 'guid-typescript';
import {
  ArchiveAndRestorePayment,
  CreatePayment,
  DeletePayment,
  GetDataByPaymentId,
} from './payment.action';

export class PaymentStateInfo {
  paymentData?: PaymentModel;
  payment: Array<PaymentModel>;
  paymentId: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isPaymentAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
}

@State<PaymentStateInfo>({
  name: 'payment',
  defaults: {
    payment: [],
    paymentId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isPaymentAdded: false,
  },
})
@Injectable()
export class PaymentState {
  constructor(private paymentService: PaymentService) {}

  @Selector()
  static isLastPage(state: PaymentStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getPaymentId(state: PaymentStateInfo) {
    return state.paymentId;
  }

  @Action(CreatePayment)
  createPayment(
    { patchState }: StateContext<PaymentStateInfo>,
    action: CreatePayment
  ) {
    return this.paymentService.createPayment(action.payment).pipe(
      tap((res) => {
        patchState({
          paymentId: res,
          isPaymentAdded: true,
        });
      })
    );
  }

  @Action(GetDataByPaymentId)
  getDataByPaymentId(
    { patchState }: StateContext<PaymentStateInfo>,
    action: GetDataByPaymentId
  ) {
    return this.paymentService.getDataByPaymentId(action.paymentId).pipe(
      tap((res) => {
        patchState({
          paymentData: res,
        });
      })
    );
  }

  @Action(DeletePayment)
  deletePayment(
    { getState, setState }: StateContext<PaymentStateInfo>,
    action: DeletePayment
  ) {
    return this.paymentService.deletePayment(action.paymentIds);
  }

  @Action(ArchiveAndRestorePayment)
  archiveAndRestorePayment(
    { getState }: StateContext<PaymentStateInfo>,
    action: ArchiveAndRestorePayment
  ) {
    return this.paymentService.archiveAndRestorePayment(
      action.paymentIds,
      action.isArchive
    );
  }
}
