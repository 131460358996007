import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Modules } from '@app/core/Enum';
import {
  SaveAnnualReportResponse,
  UploadAnnualReportResponse,
} from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import {
  DocumentEditorComponent,
  DocumentEditorContainerComponent,
  FormatType,
} from '@syncfusion/ej2-angular-documenteditor';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-annual-report-popup',
  templateUrl: './annual-report-popup.component.html',
  styleUrls: ['./annual-report-popup.component.scss'],
})
export class AnnualReportPopupComponent implements OnInit {
  hostUrl: string =
    'https://services.syncfusion.com/angular/production/api/documenteditor/';

  @ViewChild('documenteditor_default')
  public container!: DocumentEditorContainerComponent;
  saveAnnualReportResponseId = Guid.EMPTY as unknown as Guid;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AnnualReportPopupComponent>,
    private renderer: Renderer2,
    private store: Store,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.container?.documentEditor.open(
        this.data.res.annualReport.getContent
      );
      if (this.data.isEdit) {
        this.saveAnnualReportResponseId = this.data.saveAnnualReportResponseId;
      }
    }, 500);
  }

  onPrint(): void {
    (this.container.documentEditor as DocumentEditorComponent).print();
  }

  onDownloadClick(format: string = 'Docx'): void {
    this.container.documentEditor.save(
      this.container.documentEditor.documentName === ''
        ? 'AnnualAccount_' + this.data.selectedDateRange
        : this.container.documentEditor.documentName,
      format as FormatType
    );
  }

  onSaveClick(format: string = 'Docx', isConfirmSave: boolean = false): void {
    this.container.documentEditor
      .saveAsBlob(format as FormatType)
      .then((blob: Blob) => {
        let exportedDocument: Blob = blob;
        let formData: FormData = new FormData();
        formData.append(
          'files',
          exportedDocument,
          'AnnualAccount_' + this.data.selectedDateRange + '.docx'
        );
        this.spinner.show();
        this.store
          .dispatch(new UploadAnnualReportResponse(formData))
          .subscribe((res) => {
            if (res.annualReport.fileUploadResponseModel.length > 0) {
              this.saveReportResponse(
                res.annualReport.fileUploadResponseModel[0].fileUrl,
                isConfirmSave
              );
            } else {
              this.spinner.hide();
            }
          });
      });
  }

  saveReportResponse(url: any, isConfirmSave): void {
    const param = {
      id: this.saveAnnualReportResponseId,
      url: url,
    };
    this.store
      .dispatch(new SaveAnnualReportResponse(param, this.data.accountingPeriod))
      .subscribe((res) => {
        this.spinner.hide();
        this.saveAnnualReportResponseId =
          res.annualReport.saveAnnualReportResponseId;
        if (isConfirmSave) {
          this.dialogRef.close();
        }
      });
  }

  onCloseClick(): void {
    this.dialog
      .open(AddClosePopupComponent, {
        data: {
          moduleId: Modules.AnnualAccountsResponse,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onSaveClick('Docx', true);
        } else if (!result) {
          this.dialogRef.close();
        }
      });
  }
}
