<div class="mb-4">
  <form [formGroup]="giftAIDDetailsForm">
    <div class="d-flex gap-40 pl-5">
      <div class="form-field datepicker">
        <p>Start Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="startDate"
            [matDatepicker]="startDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>End Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="endDate"
            [matDatepicker]="endDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          class="mt-5"
          *ngIf="
            giftAIDDetailsForm
              .get('endDate')
              .hasError('endDateLessThanStartDate')
          "
        >
          End date should be greater than the start date.
        </mat-error>
      </div>
    </div>
    <mat-accordion>
      <mat-expansion-panel [expanded]="isExpanded">
        <mat-expansion-panel-header (click)="expandPanel()">
          <mat-panel-title>
            <div class="d-flex justify-space-between wp-100">
              <div>
                <span>Import Gift Aid Donation</span>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="content-body"
          [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
        >
          <div
            class="upload-detail"
            id="drop-area"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
          >
            <div class="import-leads mb-2">
              <div class="file-box" style="width: -webkit-fill-available">
                <div class="drop-detail">
                  <p>Drag and drop files to upload</p>
                  <h4><span>or</span></h4>
                  <button class="file-upload-button primary-button">
                    <input
                      type="file"
                      #fileInput
                      accept=".xls, .xlsx"
                      (change)="onFileSelected(fileInput)"
                      (click)="onFileOpen($event)"
                    />Browse
                  </button>
                  <div
                    class="file-info d-flex align-items-center justify-space-between mt-10 mb-10 gap-10"
                    *ngIf="fileInfo !== ''"
                  >
                    <p class="m-0">{{ fileInfo }}</p>
                    <button
                      class="file-remove-button"
                      type="button"
                      (click)="onFileRemoveClick()"
                      mat-button
                      disableRipple
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-center gap-5 cursor-pointer mt-10"
                    (click)="downloadTemplateFile()"
                  >
                    <mat-icon fontSet="material-icons-outlined"
                      >file_download</mat-icon
                    >
                    <p class="m-0 link">Download sample file</p>
                  </div>
                  <div class="file-formate">
                    <p>File Format : XLSX</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>
