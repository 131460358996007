import { DonationInKindModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateDonationInKind {
  static readonly type = '[DONATIONINKIND] Create DonationInKind';

  constructor(public donationInKind: DonationInKindModel) {}
}

export class GetDataByDonationInKindId {
  static readonly type =
    '[DONATIONINKIND] Get DonationInKind Data By DonationInKind Id';

  constructor(public donationInKindId: Guid) {}
}

export class ArchiveAndRestoreDonationInKind {
  static readonly type =
    '[DONATIONINKIND] Archive and Restore Selected DonationInKind';

  constructor(
    public donationInKindIds: Array<Guid>,
    public isArchive: boolean
  ) {}
}

export class DeleteDonationInKind {
  static readonly type = '[DONATIONINKIND] Delete Selected DonationInKind';

  constructor(public donationInKindIds: Array<Guid>) {}
}

export class GetDonationType {
  static readonly type = '[DONATIONINKIND] Get Donation Type';
}
