<div class="content client-list">
  <div class="content-body">
    <app-transactions-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-transactions-header>
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div class="mat-table-wrapper">
      <table
        aria-describedby="buld-edit-List"
        mat-table
        [dataSource]="bulkEditList"
      >
        <ng-container matColumnDef="srNo">
          <th mat-header-cell *matHeaderCellDef class="w-20">
            <mat-checkbox
              [(ngModel)]="isAllSelected"
              (change)="selectAll($event)"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <mat-checkbox
                [(ngModel)]="element.isSelected"
                (change)="onCheckBoxChanges($event, element)"
              >
              </mat-checkbox>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              {{ element.date | date: "dd-LLL-yyyy" }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef>Reference</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="element.reference"
                  autocomplete="off"
                  [disabled]="isViewPermission$ | async"
                />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="account">
          <th mat-header-cell *matHeaderCellDef class="w-200">Account</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <mat-form-field appearance="fill" class="w-170">
                <mat-select
                  panelClass="myPanelClass"
                  [disableOptionCentering]="true"
                  (selectionChange)="onAccoutChange(element)"
                  [(ngModel)]="element.accountValue"
                  [disabled]="isViewPermission$ | async"
                >
                  <mat-option
                    *ngFor="let account of accountList"
                    [value]="account.id"
                    title="{{ account.name }}"
                  >
                    {{ account.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="fund">
          <th mat-header-cell *matHeaderCellDef class="w-200">Fund</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <mat-form-field appearance="fill" class="w-170">
                <mat-select
                  panelClass="myPanelClass"
                  [disableOptionCentering]="true"
                  [(ngModel)]="element.fundId"
                  (selectionChange)="onAccoutChange(element)"
                  [disabled]="isViewPermission$ | async"
                >
                  <mat-option
                    *ngFor="let fund of fundList"
                    [value]="fund.id"
                    title=" {{ fund.name }}"
                  >
                    {{ fund.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="contactName">
          <th mat-header-cell *matHeaderCellDef>Contact Name</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              {{ element.contactName }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="referenceNo">
          <th mat-header-cell *matHeaderCellDef>Ref. No.</th>
          <td mat-cell *matCellDef="let element">
            <a
              class="link fw-bold"
              href="javascript:void()"
              (click)="onInvoiceClick(element)"
            >
              {{ element.referenceNo }}</a
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="receipt">
          <th mat-header-cell *matHeaderCellDef>Reciept</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              {{ element.receipt }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="payment">
          <th mat-header-cell *matHeaderCellDef>Payment</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              {{ element.payment }}
            </div>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayBulkEditColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayBulkEditColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayBulkEditColumns.length"
          >
            <span *ngIf="listParameters">No records found</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <app-buttons
    *ngIf="isRecordSelected && !(isViewPermission$ | async)"
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave($event.isExit)"
  ></app-buttons>
</div>
