import { Injectable } from '@angular/core';
import { CashCodingService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import { CreateCashCoding } from './cash-coding.action';

export class CashCodingStateInfo {
  cashCodingId?: Guid;
  isCashCodingAdded?: boolean;
  isLastPage?: boolean;
}

@State<CashCodingStateInfo>({
  name: 'cashCoding',
  defaults: {
    cashCodingId: Guid.EMPTY as unknown as Guid,
    isCashCodingAdded: false,
  },
})
@Injectable()
export class CashCodingState {
  constructor(private cashCodingService: CashCodingService) {}

  @Selector()
  static isLastPage(state: CashCodingStateInfo) {
    return state.isLastPage;
  }

  @Action(CreateCashCoding)
  createCashCoding(
    { patchState }: StateContext<CashCodingStateInfo>,
    action: CreateCashCoding
  ) {
    return this.cashCodingService.createCashCoding(action.cashCoding).pipe(
      tap((res) => {
        patchState({
          cashCodingId: res,
          isCashCodingAdded: true,
        });
      })
    );
  }
}
