<div class="mat-dialog-wrapper">
  <h2 mat-dialog-title>{{ headerText }}</h2>
  <mat-dialog-content>
    <div class="info-alert">
      <span>
        Please save your
        {{
          data.moduleId === modules.OpeningBalance
            ? "opening balance"
            : "trial balance"
        }}
        before explaning</span
      >
    </div>
    <div class="form-field d-flex align-items-end gap-20 pt-1">
      <button
        class="action-button primary-button"
        mat-button
        (click)="gotoAddFund()"
        disableRipple
      >
        <mat-icon>add</mat-icon>
        <span>Add Fund</span>
      </button>
    </div>
    <div class="sidenav-table sidenav-table-input wp-100">
      <div class="mat-table-wrapper mb-10">
        <form [formGroup]="formOpeningBalanceFundExplain">
          <ng-container formArrayName="openingBalanceFundExplainArray">
            <table mat-table [dataSource]="tableDataSource">
              <ng-container matColumnDef="srNo">
                <th mat-header-cell *matHeaderCellDef class="w-50">Sr No.</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <div class="d-flex">
                    {{ i + 1 }}
                  </div>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  Total Value from opening balance
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <ng-select
                    #accountName
                    [items]="accountGroupList"
                    (focus)="accountName.open()"
                    (valueChange)="accountName.close()"
                    [disableOptionCentering]="true"
                    formControlName="fund"
                    bindLabel="name"
                    bindValue="id"
                    groupBy="groupName"
                    (ngModelChange)="accountSelect(element, i)"
                    panelClass="mat-select-position"
                    appendTo="body"
                    class="bank-account"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <span class="ng-option-label" [attr.title]="item.name">{{
                        item.name
                      }}</span>
                    </ng-template>
                  </ng-select>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="opening">
                <th mat-header-cell *matHeaderCellDef>Opening</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allowDecimal
                      matInput
                      autocomplete="off"
                      formControlName="opening"
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ totalOpening | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="income">
                <th mat-header-cell *matHeaderCellDef>Income</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allowDecimal
                      matInput
                      autocomplete="off"
                      formControlName="income"
                      (change)="rowClosingCount(i)"
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ totalIncome | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="expense">
                <th mat-header-cell *matHeaderCellDef>Expense</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      type="number"
                      matInput
                      autocomplete="off"
                      formControlName="expense"
                      (input)="rowClosingCount(i)"
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ totalExpense | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="fundTransferIn">
                <th mat-header-cell *matHeaderCellDef>Fund Transfer In</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      type="number"
                      matInput
                      autocomplete="off"
                      formControlName="fundTransferIn"
                      (keyup)="fundTransferInChanges(i, $event)"
                      (keyup.backspace)="fundTransferInChanges(i, $event)"
                      maxlength="{{ maxLength.maxLength10 }}"
                      (input)="rowClosingCount(i)"
                    />
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ totalFundTransferIn | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="fundTransferOut">
                <th mat-header-cell *matHeaderCellDef>Fund Transfer Out</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      type="number"
                      matInput
                      autocomplete="off"
                      formControlName="fundTransferOut"
                      (keyup)="fundTransferOutChanges(i, $event)"
                      (keyup.backspace)="fundTransferOutChanges(i, $event)"
                      maxlength="{{ maxLength.maxLength10 }}"
                      (input)="rowClosingCount(i)"
                    />
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ totalFundTransferOut | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="gainloss">
                <th mat-header-cell *matHeaderCellDef>Gain/Loss</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      type="number"
                      matInput
                      autocomplete="off"
                      formControlName="gainloss"
                      maxlength="{{ maxLength.maxLength10 }}"
                      (input)="rowClosingCount(i)"
                    />
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ totalGainLoss | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="closing">
                <th mat-header-cell *matHeaderCellDef>Closing</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      matInput
                      autocomplete="off"
                      [disabled]="true"
                      formControlName="closing"
                    />
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ totalClosing | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="closeButton">
                <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="text-align-center"
                >
                  <div
                    class="d-flex align-items-center justify-content-end gap-10"
                  >
                    <button
                      class="close-btn"
                      type="button"
                      mat-button
                      disableRipple
                      title="Delete"
                      (click)="onDeleteOpeningBalance(i)"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container
                *ngFor="let columnName of displayedColumns1"
                [matColumnDef]="columnName"
              >
                <td mat-footer-cell *matFooterCellDef="let row; let i = index">
                  {{ countTotalOfDrCr(columnName) }}
                </td>
              </ng-container>
              <ng-container
                *ngFor="let columnName of displayedColumns2"
                [matColumnDef]="columnName"
              >
                <td mat-footer-cell *matFooterCellDef="let row; let i = index">
                  {{ countDifferenceOfDrCr(columnName) }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns1"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns2"></tr>
            </table>
          </ng-container>
        </form>
      </div>
    </div>
  </mat-dialog-content>
  <div class="mb-1 mt-1 d-flex justify-space-between">
    <div class="mt-1">
      <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
        >Add New Line</a
      >
      <a class="link mr-1" href="javascript:void(0)" (click)="clearForm()"
        >Clear All Lines</a
      >
    </div>
  </div>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>
