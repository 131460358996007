import { SideListModel } from './side-list';

export class AccountDetailViewModel {
  accountContactDetailModel: AccountContactDetailModel;
  accountStatusModel: AccountStatusModel;
  accountFlowDetailModel: AccountFlowDetailModel;
  accountTypeId: number;
}

export class AccountContactDetailModel {
  name: string;
  tradingName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country?: string;
  postalCode: string;
  phone: string;
  email: string;
  mobileNumber: string;
  fax: string;
}

export class AccountStatusModel {
  totalDue: number;
  amountPaid: number;
  balance: number;
}

export class AccountFlowDetailModel {
  series: Array<AccountFlowBarChatModel>;
  categories: Array<string>;
}
export class AccountFlowBarChatModel {
  invoice: Array<number>;
  receipt: Array<number>;
}

export class BillDashboardModel {
  accountFlowDetailModel: AccountFlowBarChatModel;
}

export class GroupListModel {
  groupName: string;
  listModels: Array<SideListModel>;
}
