import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetFundTypeList } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-fund',
  templateUrl: './add-fund.component.html',
  styleUrls: ['./add-fund.component.scss'],
})
export class AddFundComponent implements OnInit {
  fundId = Guid.EMPTY as unknown as Guid;

  fundForm: FormGroup;

  fundTypeList: SideListModel[];
  subscriptionRouting: Subscription;

  @Input()
  triggerEditData: Observable<any>;

  @Input()
  triggerOnCancel: Observable<any>;

  @Output()
  readonly triggerSaveAllTabs = new EventEmitter<any>();

  private destroy$ = new Subject<void>();

  constructor(private store: Store, private commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.getFundTypeList(false);

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getFundTypeList(true);
        this.editFund(data);
      });

    this.fundForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.fundForm.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFundTypeList(isEdit: boolean): void {
    this.store
      .dispatch(new GetFundTypeList())
      .pipe(
        tap((res) => {
          this.fundTypeList = res.account.fundTypeList;
          if (!isEdit) {
            this.fundForm.controls.fundType.setValue(this.fundTypeList[0].id);
          }
        })
      )
      .subscribe();
  }

  editFund(data): void {
    this.fundForm.patchValue({
      fundName: data.name,
      isDefault: data.isDefault,
      fundType: data.fundTypeId,
      isTradingFund: data.isTradingFund,
      openingBalance: data.openingBalance,
    });
  }

  setForm(): void {
    this.fundForm = new FormGroup({
      fundName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      isDefault: new FormControl(false),
      fundType: new FormControl('', [Validators.required]),
      isTradingFund: new FormControl(false),
      openingBalance: new FormControl(''),
    });
  }
}
