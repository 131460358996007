import { Guid } from 'guid-typescript';

export class BankTransferModel {
  id?: Guid;
  entryNumber?: string;
  entryDate?: string;
  note: string;
  transferredFromId: Guid;
  transferredToId: Guid;
  amount: number;
  fundId: Guid;
  transactionLogId?: Guid;
  isManualBank?: boolean;
}
