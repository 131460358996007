import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Modules, DashboardAgeingSummaryType } from '@app/core/Enum';
import {
  GetDashboardAgeingSummaryOverview,
  GetExpenditurePayableAgeingSummaryOverview,
  GetIncomeReceivableAgeingSummaryOverview,
} from '@app/core/Store';
import { Store } from '@ngxs/store';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type SummaryChart = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  noData: ApexNoData;
  colors: string[];
};

@Component({
  selector: 'app-overview-summary-chart-v1',
  templateUrl: './overview-summary-chart-v1.component.html',
  styleUrls: ['./overview-summary-chart-v1.component.scss'],
})
export class OverviewSummaryChartComponentv1 implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  public summaryChart: Partial<SummaryChart>;
  reportList: any;
  isNoRecord = true;
  isloadData = false;

  @Input()
  getModuleId: number;

  @Input()
  triggerHeaderFilter: Observable<any>;

  @Input() triggerAgeingSummary: Observable<any>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerAgeingSummary?.subscribe((filterData) => {
      this.getData(filterData);
    });

    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (
        this.getModuleId === Modules.IncomeOverview ||
        this.getModuleId === Modules.ExpenditureOverview
      ) {
        this.getData(filterData);
      }
    });
  }

  getData(filterData): void {
    let actionName;

    switch (this.getModuleId) {
      case Modules.IncomeOverview:
        actionName = GetIncomeReceivableAgeingSummaryOverview;
        break;
      case Modules.Dashboard:
        actionName = GetDashboardAgeingSummaryOverview;
        break;
      case Modules.ExpenditureOverview:
        actionName = GetExpenditurePayableAgeingSummaryOverview;
        break;
    }

    this.store.dispatch(new actionName(filterData)).subscribe((x) => {
      this.isloadData = true;
      switch (this.getModuleId) {
        case Modules.IncomeOverview:
          this.reportList = x.invoice.incomeReceivableAgeingSummaryOverview;
          break;
        case Modules.Dashboard:
          this.reportList = x.dashboard.dashboardAgeingSummaryOverview;
          break;
        case Modules.ExpenditureOverview:
          this.reportList = x.bill.expenditurePayableAgeingSummaryOverview;
          break;
      }

      this.getReceivableSummary(filterData);
    });
  }

  getReceivableSummary(filterData: any): void {
    if (+this.getModuleId === Modules.Dashboard) {
      this.setDashboardSummaryAgeing(filterData);
    } else if (+this.getModuleId === Modules.Invoices) {
      this.summaryChart = {
        series: [
          {
            name: '',
            data: [1500, 2200, 500, 1200],
          },
        ],
        chart: {
          type: 'bar',
          height: 300,
          toolbar: {
            show: false,
          },
        },
        noData: {
          text: 'No Data to Display',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            color: '#6d41a1',
            fontSize: '18px',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '10%',
            barHeight: '40%',
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        legend: {
          show: false,
          markers: {
            fillColors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: [
            '0 days late',
            '1-30 days late',
            '31-60 days late',
            '6+ days late',
          ],
          labels: {
            show: true,
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          enabled: true,
          fillSeriesColor: false,
          y: {
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
        },
        fill: {
          colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397', '#7A5295'],
        },
      };
    } else if (+this.getModuleId === Modules.Donors) {
      this.summaryChart = {
        series: [
          {
            name: '',
            data: [1500, 2200, 500, 1200, 2250],
          },
        ],
        chart: {
          type: 'bar',
          height: 300,
          toolbar: {
            show: false,
          },
        },
        noData: {
          text: 'No Data to Display',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            color: '#6d41a1',
            fontSize: '18px',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '10%',
            barHeight: '40%',
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        legend: {
          show: false,
          markers: {
            fillColors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397', '#7A5295'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: ['145', '123', '789', '654', '987'],
          labels: {
            show: true,
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          enabled: true,
          fillSeriesColor: false,
          y: {
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
        },
        fill: {
          colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397', '#7A5295'],
        },
      };
    } else if (+this.getModuleId === Modules.ExpenditureOverview) {
      const tempCategories: any = [];
      const tempSeries: any = [];
      let count = 0;

      for (const barchart of this.reportList[`categories`]) {
        tempCategories.push(barchart);
      }

      this.isNoRecord = this.reportList[`series`].length === 0;

      if (this.reportList[`series`].length > 0) {
        for (const barchart of this.reportList[`series`]) {
          tempSeries.push(barchart.data);
          if (barchart.data === 0) {
            count = count + 1;
          }
        }

        this.isNoRecord =
          count === this.reportList[`series`].length ? true : false;
      }

      this.summaryChart = {
        series: [
          {
            name: '',
            data: tempSeries,
          },
        ],
        chart: {
          type: 'bar',
          height: 300,
          toolbar: {
            show: false,
          },
        },
        noData: {
          text: 'No Data to Display',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            color: '#6d41a1',
            fontSize: '18px',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '10%',
            barHeight: '40%',
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        legend: {
          show: false,
          markers: {
            fillColors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: tempCategories,
          labels: {
            show: true,
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          style: {
            fontFamily: '"Source Sans Pro", sans-serif',
            fontSize: '14px',
          },
          y: {
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
          enabled: true,
          fillSeriesColor: false,
        },
        fill: {
          colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
        },
      };
    } else if (+this.getModuleId === Modules.IncomeOverview) {
      const tempCategories: any = [];
      const tempSeries: any = [];
      let count = 0;

      for (const barchart of this.reportList[`categories`]) {
        tempCategories.push(barchart);
      }

      this.isNoRecord = this.reportList[`series`].length === 0;

      if (this.reportList[`series`].length > 0) {
        for (const barchart of this.reportList[`series`]) {
          tempSeries.push(barchart.data);
          if (barchart.data === 0) {
            count = count + 1;
          }
        }

        this.isNoRecord =
          count === this.reportList[`series`].length ? true : false;
      }

      this.summaryChart = {
        series: [
          {
            name: '',
            data: tempSeries,
          },
        ],
        chart: {
          type: 'bar',
          height: 300,
          toolbar: {
            show: false,
          },
        },
        noData: {
          text: 'No Data to Display',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            color: '#6d41a1',
            fontSize: '18px',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '10%',
            barHeight: '40%',
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          //offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#404A5F'],
          },
        },
        legend: {
          show: false,
          markers: {
            fillColors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: tempCategories,
          labels: {
            show: true,
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          enabled: true,
          fillSeriesColor: false,
          y: {
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
        },
        fill: {
          colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
        },
      };
    }
  }

  getSeriesData(seriesData: any): any {
    let series: any = [];
    let count = 0;

    seriesData.series.forEach((element) => {
      series.push(element.data);
      if (element.data === 0) {
        count = count + 1;
      }
    });

    this.isNoRecord = count === seriesData.series.length ? true : false;
    return series;
  }

  setDashboardSummaryAgeing(filterData: any): void {
    const seriesList =
      +filterData.moduleId === DashboardAgeingSummaryType.Receivables
        ? this.reportList.income
        : this.reportList.expense;

    let seriesData;

    this.isNoRecord = seriesList.series.length === 0;

    if (seriesList.series.length > 0) {
      seriesData = this.getSeriesData(seriesList);
    }

    const categoriesData =
      +filterData.moduleId === DashboardAgeingSummaryType.Receivables
        ? this.reportList.income.categories
        : this.reportList.expense.categories;

    this.summaryChart = {
      series: [
        {
          name: '',
          data: seriesData,
        },
      ],
      chart: {
        type: 'bar',
        height: 300,
        toolbar: {
          show: false,
        },
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '10%',
          barHeight: '40%',
          distributed: true,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff'],
        },
      },
      legend: {
        show: false,
        markers: {
          fillColors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: categoriesData,
        labels: {
          formatter: (val) => {
            return '£ ' + Number(val) / 1000 + 'K';
          },
        },
      },
      tooltip: {
        marker: {
          show: false,
        },
        style: {
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: '14px',
        },
        y: {
          formatter: (val) => {
            return '£ ' + Number(val) * 1 + '';
          },
        },
        enabled: true,
        fillSeriesColor: false,
      },
      fill: {
        colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
      },
    };
  }
}
