import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import {
  AuthModule,
  LoggerService,
  LogLevel,
  OidcConfigService,
} from 'angular-auth-oidc-client';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { NgxDatetimeRangePickerModule } from 'ngx-datetime-range-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthGuard } from './core/Guards/auth.guard';
import { AuthResolverService } from './core/Guards/auth.resolver';
import { CustomHttpInterceptor } from './core/Interceptors/http.interceptor';
import {
  RichTextEditorAllModule,
  RichTextEditorModule,
} from '@syncfusion/ej2-angular-richtexteditor';

import {
  AccountState,
  ActivityState,
  BankDashboardState,
  BankOverviewState,
  BankReconciliationState,
  BankTransferState,
  CommonState,
  CompanyState,
  CountryState,
  CreditNoteState,
  CustomAccountState,
  DashboardState,
  DebitNoteState,
  DonationState,
  DonorState,
  FixedAssetsState,
  FundState,
  FundTransferState,
  ImportState,
  InvoiceState,
  JournalsState,
  MenuState,
  NoteState,
  OpeningBalanceState,
  OverviewState,
  PaymentsState,
  ProductState,
  BillState,
  QuotationState,
  ReceiptState,
  RecurringDonationState,
  RecurringModuleState,
  SponsorState,
  DonationInKindState,
  BulkEditState,
  AnnualReportState,
  ViewVatDetailsState,
  SubmitVatState,
  VatCodesState,
  VATSettingsState,
  BridgingVatState,
  DonationCodingState,
  FixedAssetsRegisterState,
  IntegrationState,
  EmailTemplateState,
  DonationGiftAidState,
  GiftAidDetailsState,
  SubscriptionState,
  TicketState,
  UserState,
  AccountProductionState,
} from './core/Store';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { environment } from '@environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCurrencyModule } from 'ngx-currency';
import { AppMaterialModule } from './app-material.module';
import { AppSyncfusionModule } from './app-syncfusion.module';
import {
  AutocompletePositionDirective,
  AutoFocusDirective,
  DisableInputControlDirective,
  EmailValidatorDirective,
  FourteenDigitDecimaNumberDirective,
  TenDigitDecimaNumberDirective,
  ThirteenDigitDecimaNumberDirective,
  TwoDigitDecimaNumberDirective,
  UnicodeCharacterDirective,
  ZeroNotAllowedDirective,
  NumberOnlyDirective,
} from './core/Directives';
import { LoaderInterceptor } from './core/Interceptors';
import { GlobalComponent } from './core/Models';
import { ShortNamePipe } from './core/Pipes/short-name.pipes';
import {
  ActivityService,
  BankDasboardService,
  BankOverviewService,
  BankReconciliationService,
  BankTransferService,
  CommonService,
  CompanyService,
  CustomAccountService,
  DashboardService,
  DebitNoteService,
  DonationService,
  DonorService,
  DummyService,
  FundTransferService,
  ImportService,
  JournalsService,
  MenuService,
  ModulePermission,
  NoteService,
  NotificationService,
  OpeningBalanceService,
  BillService,
  QuotationService,
  RecurringDonationService,
  RecurringModuleService,
  SponsorService,
  DonationInKindService,
  BulkEditService,
  AnnualReportService,
  SubmitVatService,
  ViewVatDeatilsService,
  VATSettingsService,
  VATCodesService,
  BridgingVatService,
  DonationCodingService,
  CashCodingService,
  FixedAssetsRegisterService,
  IntegrationsService,
  EmailTemplateService,
  DonationGiftAidService,
  GiftAidDetailsService,
  SubscriptionService,
  TicketService,
  UserService,
  AccountProductionService,
} from './core/Services';
import {
  ActivitiesComponent,
  AddActivitiesComponent,
  AddBankAccountComponent,
  AddBankTransferComponent,
  AddBulkJournalComponent,
  AddCISInformationComponent,
  AddClosePopupComponent,
  AddCommericalDetailsComponent,
  AddCompanyComponent,
  AddCompanyPreferenceComponent,
  AddContactDetailsComponent,
  AddCreditNoteComponent,
  AddCustomAccountsComponent,
  AddDebitNoteComponent,
  AddDonationComponent,
  AddFixedAssestsComponent,
  AddFundComponent,
  AddFundTransferComponent,
  AddGiftDetailsComponent,
  AddIncomeInvoiceComponent,
  AddJournalsComponent,
  AddBillComponent,
  AddQuotationComponent,
  AddReceiptComponent,
  AddRecurringDonationComponent,
  AddRecurringModuleComponent,
  AddSponsorComponent,
  AddSubscriptionDetailsComponent,
  AttachmentPopupComponent,
  BankDashboardComponent,
  BankReconciliationComponent,
  BankOverviewComponent,
  BankReconciliationDetailsComponent,
  BankTransferComponent,
  ButtonsComponent,
  CardFooterComponent,
  CompanyPreferenceComponent,
  ConfirmationBoxComponent,
  CreditNoteComponent,
  CustomAccountsComponent,
  CustomerComponent,
  CustomNotificationsComponent,
  CustomPaginatorComponent,
  DashboardCashflowChartComponent,
  DashboardComponent,
  DashboardIncomeExpensesChartComponent,
  DebitNoteComponent,
  DonationComponent,
  DonorComponent,
  ExpandCollapseComponent,
  FixedAssetsComponent,
  FooterComponent,
  FundComponent,
  FundTransferComponent,
  HeaderComponent,
  ImportComponent,
  ImportFileDataComponent,
  IncomeInvoiceComponent,
  IncomeOverviewComponent,
  JournalsComponent,
  LogsEmailLogComponent,
  LogsUserLogComponent,
  MainListComponent,
  MenuItemComponent,
  MultiSelectDropdownComponent,
  NewCompanyComponent,
  NominalLedgerComponent,
  Note10Component,
  Note11Component,
  Note12Component,
  Note13Component,
  Note14Component,
  Note15Component,
  Note16Component,
  Note17Component,
  Note18Component,
  Note19Component,
  Note1Component,
  Note20Component,
  Note21Component,
  Note22Component,
  Note23Component,
  Note24Component,
  Note25Component,
  Note26Component,
  Note28Component,
  Note29Component,
  Note2Component,
  Note3Component,
  Note4Component,
  Note5Component,
  Note6Component,
  Note7Component,
  Note8Component,
  Note9Component,
  NoteComponent,
  NoteHeaderComponent,
  OpeningBalanceComponent,
  OpeningBalanceFooterComponent,
  OverviewFlowChartComponent,
  OverviewIncomesChartComponent,
  OverviewSummaryChartComponent,
  PageNotFoundComponent,
  ProductDetailsComponent,
  ProductDetailsFooterComponent,
  BillComponent,
  QuotationComponent,
  ReceiptComponent,
  ReceiptDetailsComponent,
  RecurringDonationComponent,
  RecurringModuleComponent,
  ReportCreditorDebtorComponent,
  ReportDashboardComponent,
  ReportHeaderDetailsComponent,
  ReportListComponent,
  SideListActionsComponent,
  SideListComponent,
  SponsorEventComponent,
  SupplierComponent,
  TransactionHeaderComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
  TreeListComponent,
  ViewReceiptComponent,
  BillOutstandingChartComponent,
  DonationInKindComponent,
  Note27Component,
  Note31Component,
  Note32Component,
  Note30Component,
  BulkEditComponent,
  CreditNoteAllocationComponent,
  AnnualReportComponent,
  VATDetailsComponent,
  BridgingVATComponent,
  AddSubmitVatComponent,
  IncludeDataComponent,
  IncludeExcludeTransactionComponent,
  ReviewReturnComponent,
  SubmitVatHeaderComponent,
  SubmitVatComponent,
  AddVatCodesComponent,
  VatCodesComponent,
  AddVatSettingsComponent,
  VatSettingsComponent,
  VatReturnComponent,
  ViewVatDetailComponent,
  DetailListComponent,
  DetailHeaderComponent,
  ReportDetailListComponent,
  ReportDetailListHeaderComponent,
  DonationCodingComponent,
  AddDonationCodingComponent,
  AddBulkDonationCodingComponent,
  CashCodingComponent,
  AddCashCodingComponent,
  AddBulkCashCodingComponent,
  Note33Component,
  Note34Component,
  Note35Component,
  Note36Component,
  Note37Component,
  Note38Component,
  Note39Component,
  Note40Component,
  Note41Component,
  Note42Component,
  CleanAllLinesComponent,
  OpeningBalanceExplainContractComponent,
  OpeningBalanceExplainFundComponent,
  QuickAddComponent,
  AddFinancialYearComponent,
  ReportListWithActionsComponent,
  OverviewLineChartComponent,
  EmailPopupComponent,
  DepreciationExpandedCollapseComponent,
  DashboardIncomeClientComponent,
  DashboardDonationTypeComponent,
  DashboardExpensesComponent,
  DashboardComponentv1,
  DashboardIncomeExpensesChartComponentv1,
  DashboardIncomeClientComponentv1,
  DashboardExpensesComponentv1,
  DashboardDonationTypeComponentv1,
  DashboardCashflowChartComponentv1,
  OverviewIncomesChartComponentv1,
  EmailIntegrationsComponent,
  EmailTemplatesComponent,
  IntegrationsComponent,
  DepreciationComponent,
  DonationOverviewComponent,
  DonationByFundComponent,
  FixedAssetsOverviewComponent,
  ExpenditureOverviewComponent,
  AssetsCountChartComponent,
  FundOverviewComponent,
  FundBreakdownComponent,
  BankImportComponent,
  DonationDetailChartComponent,
  EditAssetDetailComponent,
  GiftAidDeclarationComponent,
  TrusteesReportComponent,
  Note43Component,
  EditDisposeAssetComponent,
  FixedAssestDisposeActiveComponent,
  AddGiftAIDsettingsComponent,
  GiftAIDDetailsComponent,
  HMRCGatewayDetailsComponent,
  GiftAidComponent,
  SubContractorDetailsComponent,
  AdvancePaymentComponent,
  TicketComponent,
  TicketDialogComponent,
  SupportTicketComponent,
  QuickJournalComponent,
  BankLinkComponent,
  BankFeedComponent,
  MatchJournalsComponent,
  ReconfirmPopupComponent,
  ManualImportComponent,
  AddReceiptPaymentPopupComponent,
  AddAllocateRefundPopupComponent,
  AnnualReportPopupComponent,
  AddUserComponent,
  UserClientsComponent,
  UserCapacityPermissionComponent,
  UserBasicInfoComponent,
  UserPermissionsComponent,
  AssignClientComponent,
  UsersListComponent,
  SaveAndInvitePopupComponent,
  AccessDeniedComponent,
  RollBackPopupComponent,
  ScottishRegulatorPopupComponent,
  ConvertToInvoicePopupComponent,
} from './modules';
import CustomYearPickerComponent from './modules/custom/custom-year-picker/custom-year-picker.component';
import { VatReportComponent } from './modules/reports/vat-report/vat-report.component';
import { ViewHtmlContent } from './core/Pipes/safe-html.pipes';
import { MomentUtcDateAdapter } from './core/Directives/moment-utc-date-adapter';
import { NumberPipe } from './core/Pipes/number-pipe.pipes';
import { CashCodingState } from './core/Store/bank/cash-coding';
import { MatDialogRef } from '@angular/material/dialog';
import { OverviewSummaryChartComponentv1 } from './modules/income/income-overview/overview-summary-chart-v1/overview-summary-chart-v1.component';
import { OverviewIncomesBreckdownComponent } from './modules/income/income-overview/overview-incomes-breckdown/overview-incomes-breckdown.component';
import { OverviewReceivableSummaryChartComponent } from './modules/income/income-overview/overview-receivable-summary-chart/overview-receivable-summary-chart.component';
import { OverviewIncomesBreckdownv1Component } from './modules/income/income-overview-v1/overview-incomes-breckdown -v1/overview-incomes-breckdown-v1.component';
import { IncomeOverviewV1Component } from './modules/income/income-overview-v1/income-overview-v1.component';
import { OverviewFlowChartv1Component } from './modules/income/income-overview-v1/overview-flow-chart-v1/overview-flow-chart-v1.component';
import { MentionModule } from 'angular-mentions';
import { UseEnumValuePipe } from './core/Pipes/use-enum-value.pipe';
import { GiftAidDonationsDetailsComponent } from './modules/donation/gift-aid';
import { GrantPermissionPopupComponent } from './modules/users/grant-permission-popup/grant-permission-popup.component';
import { PermissionsComponent } from './modules/permissions/permissions.component';

export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: true,
};

export function configureAuth(oidcConfigService: OidcConfigService): any {
  return () =>
    oidcConfigService.withConfig({
      stsServer: `${environment.stsServerUrl}`,
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: `${environment.identityLoginUrl}`,
      clientId: `${environment.clientId}`,
      scope: 'capium_myadmin_api openid offline_access',
      responseType: 'code',
      silentRenew: false,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      useRefreshToken: true,
      logLevel: LogLevel.Debug,
      historyCleanupOff: true,
      autoCleanStateAfterAuthentication: true,
      postLoginRoute: '/dashboard',
      unauthorizedRoute: `${environment.identityLoginUrl}`,
      autoUserinfo: false,
    });
}

export const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 60,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  exports: [],

  declarations: [
    AppComponent,
    HeaderComponent,
    SideListComponent,
    SideListActionsComponent,
    ProductDetailsComponent,
    TransactionHeaderComponent,
    CardFooterComponent,
    FooterComponent,
    TransactionsPaymentComponent,
    TransactionsAttachmentComponent,
    AddIncomeInvoiceComponent,
    ConfirmationBoxComponent,
    IncomeInvoiceComponent,
    CustomNotificationsComponent,
    QuotationComponent,
    AddQuotationComponent,
    AddCreditNoteComponent,
    CreditNoteComponent,
    CustomYearPickerComponent,
    MainListComponent,
    DebitNoteComponent,
    AddDebitNoteComponent,
    ReceiptComponent,
    AddReceiptComponent,
    ReceiptDetailsComponent,
    IncomeOverviewComponent,
    RecurringModuleComponent,
    AddRecurringModuleComponent,
    JournalsComponent,
    BulkEditComponent,
    ActivitiesComponent,
    SponsorEventComponent,
    MenuItemComponent,
    DonationComponent,
    CustomerComponent,
    SupplierComponent,
    DonorComponent,
    BankTransferComponent,
    FixedAssetsComponent,
    FundComponent,
    FundTransferComponent,
    LogsEmailLogComponent,
    LogsUserLogComponent,
    ShortNamePipe,
    NumberPipe,
    UseEnumValuePipe,
    ViewHtmlContent,
    AddJournalsComponent,
    ButtonsComponent,
    AddActivitiesComponent,
    AddFundComponent,
    AddFundTransferComponent,
    BillComponent,
    AddBillComponent,
    AddSponsorComponent,
    AddBankTransferComponent,
    AddDonationComponent,
    AddContactDetailsComponent,
    AddCommericalDetailsComponent,
    AddSubscriptionDetailsComponent,
    AddCISInformationComponent,
    AddCompanyComponent,
    AddGiftDetailsComponent,
    AddCustomAccountsComponent,
    CustomAccountsComponent,
    AddFixedAssestsComponent,
    DashboardComponent,
    DashboardIncomeExpensesChartComponent,
    DashboardCashflowChartComponent,
    CustomPaginatorComponent,
    AttachmentPopupComponent,
    OverviewIncomesChartComponent,
    OverviewSummaryChartComponent,
    OverviewFlowChartComponent,
    NewCompanyComponent,
    AddClosePopupComponent,
    ProductDetailsFooterComponent,
    BankDashboardComponent,
    AddBankAccountComponent,
    MultiSelectDropdownComponent,
    CompanyPreferenceComponent,
    AddCompanyPreferenceComponent,
    CleanAllLinesComponent,
    TwoDigitDecimaNumberDirective,
    FourteenDigitDecimaNumberDirective,
    TenDigitDecimaNumberDirective,
    ThirteenDigitDecimaNumberDirective,
    DisableInputControlDirective,
    AutocompletePositionDirective,
    ZeroNotAllowedDirective,
    NumberOnlyDirective,
    AutoFocusDirective,
    PageNotFoundComponent,
    UnicodeCharacterDirective,
    EmailValidatorDirective,
    ReportDashboardComponent,
    ReportListComponent,
    TreeListComponent,
    AddBulkJournalComponent,
    BankReconciliationComponent,
    BankOverviewComponent,
    BankReconciliationDetailsComponent,
    ViewReceiptComponent,
    OpeningBalanceComponent,
    ReportHeaderDetailsComponent,
    AddFinancialYearComponent,
    OpeningBalanceFooterComponent,
    BillOutstandingChartComponent,
    FixedAssestDisposeActiveComponent,
    Note1Component,
    Note2Component,
    Note3Component,
    Note4Component,
    Note5Component,
    Note6Component,
    Note8Component,
    Note7Component,
    Note9Component,
    Note10Component,
    Note11Component,
    Note12Component,
    Note13Component,
    Note14Component,
    Note15Component,
    Note16Component,
    Note17Component,
    Note18Component,
    Note19Component,
    Note20Component,
    Note21Component,
    Note22Component,
    Note23Component,
    Note24Component,
    Note25Component,
    Note26Component,
    Note27Component,
    Note28Component,
    Note29Component,
    Note31Component,
    Note32Component,
    Note30Component,
    NoteComponent,
    NoteHeaderComponent,
    VatReportComponent,
    ImportComponent,
    ImportFileDataComponent,
    RecurringDonationComponent,
    AddRecurringDonationComponent,
    NominalLedgerComponent,
    ExpandCollapseComponent,
    ReportCreditorDebtorComponent,
    DonationInKindComponent,
    BulkEditComponent,
    CreditNoteAllocationComponent,
    AnnualReportComponent,
    VATDetailsComponent,
    BridgingVATComponent,
    AddSubmitVatComponent,
    IncludeDataComponent,
    IncludeExcludeTransactionComponent,
    ReviewReturnComponent,
    SubmitVatHeaderComponent,
    SubmitVatComponent,
    AddVatCodesComponent,
    VatCodesComponent,
    AddVatSettingsComponent,
    VatSettingsComponent,
    VatReturnComponent,
    ViewVatDetailComponent,
    DetailListComponent,
    DetailHeaderComponent,
    ReportDetailListComponent,
    ReportDetailListHeaderComponent,
    DonationCodingComponent,
    AddDonationCodingComponent,
    AddBulkDonationCodingComponent,
    CashCodingComponent,
    AddCashCodingComponent,
    AddBulkCashCodingComponent,
    DepreciationComponent,
    Note33Component,
    Note34Component,
    Note35Component,
    Note36Component,
    Note37Component,
    Note38Component,
    Note39Component,
    Note40Component,
    Note41Component,
    Note42Component,
    OpeningBalanceExplainContractComponent,
    OpeningBalanceExplainFundComponent,
    QuickAddComponent,
    IntegrationsComponent,
    ReportListWithActionsComponent,
    OverviewLineChartComponent,
    EmailPopupComponent,
    EmailIntegrationsComponent,
    DepreciationExpandedCollapseComponent,
    DashboardIncomeClientComponent,
    DashboardDonationTypeComponent,
    DashboardExpensesComponent,
    DashboardComponentv1,
    DashboardIncomeExpensesChartComponentv1,
    DashboardIncomeClientComponentv1,
    DashboardExpensesComponentv1,
    DashboardDonationTypeComponentv1,
    DashboardCashflowChartComponentv1,
    OverviewIncomesChartComponentv1,
    OverviewSummaryChartComponentv1,
    EmailTemplatesComponent,
    DonationOverviewComponent,
    DonationByFundComponent,
    FixedAssetsOverviewComponent,
    OverviewIncomesBreckdownComponent,
    OverviewIncomesBreckdownv1Component,
    OverviewReceivableSummaryChartComponent,
    IncomeOverviewV1Component,
    OverviewFlowChartv1Component,
    ExpenditureOverviewComponent,
    AssetsCountChartComponent,
    FundOverviewComponent,
    FundBreakdownComponent,
    BankImportComponent,
    DonationDetailChartComponent,
    EditAssetDetailComponent,
    GiftAidDeclarationComponent,
    TrusteesReportComponent,
    Note43Component,
    EditDisposeAssetComponent,
    AddGiftAIDsettingsComponent,
    GiftAidComponent,
    GiftAIDDetailsComponent,
    HMRCGatewayDetailsComponent,
    SubContractorDetailsComponent,
    GiftAidDonationsDetailsComponent,
    AdvancePaymentComponent,
    TicketComponent,
    TicketDialogComponent,
    SupportTicketComponent,
    QuickJournalComponent,
    BankLinkComponent,
    BankFeedComponent,
    ReconfirmPopupComponent,
    MatchJournalsComponent,
    ManualImportComponent,
    AddReceiptPaymentPopupComponent,
    AddAllocateRefundPopupComponent,
    AnnualReportPopupComponent,
    AddUserComponent,
    UserClientsComponent,
    UserCapacityPermissionComponent,
    UserBasicInfoComponent,
    UserPermissionsComponent,
    AssignClientComponent,
    UsersListComponent,
    SaveAndInvitePopupComponent,
    GrantPermissionPopupComponent,
    PermissionsComponent,
    AccessDeniedComponent,
    RollBackPopupComponent,
    ScottishRegulatorPopupComponent,
    ConvertToInvoicePopupComponent,
  ],
  imports: [
    AuthModule.forRoot(),
    //NgShortcutModule.forRoot(shortcutConfig),
    MentionModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    NgChartsModule,
    NgSelectModule,
    NgApexchartsModule,
    NgxDatetimeRangePickerModule.forRoot(),
    NgxDropzoneModule,
    AppMaterialModule,
    AppSyncfusionModule,
    ButtonModule,
    RichTextEditorModule,
    RichTextEditorAllModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxsModule.forRoot([
      CountryState,
      AccountState,
      CompanyState,
      BankTransferState,
      CommonState,
      SponsorState,
      MenuState,
      ActivityState,
      JournalsState,
      QuotationState,
      BillState,
      CustomAccountState,
      ProductState,
      InvoiceState,
      CreditNoteState,
      DebitNoteState,
      DonationState,
      FundState,
      FundTransferState,
      PaymentsState,
      RecurringModuleState,
      ReceiptState,
      FixedAssetsState,
      OverviewState,
      DashboardState,
      BankDashboardState,
      DonationGiftAidState,
      BankOverviewState,
      BankReconciliationState,
      DonorState,
      NoteState,
      OpeningBalanceState,
      ImportState,
      RecurringDonationState,
      DonationInKindState,
      BulkEditState,
      AnnualReportState,
      ViewVatDetailsState,
      SubmitVatState,
      VatCodesState,
      VATSettingsState,
      BridgingVatState,
      DonationCodingState,
      CashCodingState,
      FixedAssetsRegisterState,
      IntegrationState,
      EmailTemplateState,
      GiftAidDetailsState,
      SubscriptionState,
      TicketState,
      UserState,
      AccountProductionState,
    ]),
    SlickCarouselModule,
    NgxSpinnerModule.forRoot(),
  ],
  providers: [
    Title,
    DatePipe,
    OidcConfigService,
    BankDasboardService,
    LoggerService,
    CookieService,
    GlobalComponent,
    DummyService,
    AuthResolverService,
    CommonService,
    AuthGuard,
    NotificationService,
    CompanyService,
    CommonService,
    BankTransferService,
    SponsorService,
    BillService,
    MenuService,
    ActivityService,
    JournalsService,
    QuotationService,
    DebitNoteService,
    DonationService,
    RecurringModuleService,
    FundTransferService,
    CustomAccountService,
    DashboardService,
    ModulePermission,
    BankOverviewService,
    BankReconciliationService,
    DonorService,
    NoteService,
    OpeningBalanceService,
    ImportService,
    RecurringDonationService,
    DonationInKindService,
    BulkEditService,
    AnnualReportService,
    VATCodesService,
    VATSettingsService,
    ViewVatDeatilsService,
    SubmitVatService,
    BridgingVatService,
    DonationCodingService,
    DonationGiftAidService,
    CashCodingService,
    FixedAssetsRegisterService,
    IntegrationsService,
    EmailTemplateService,
    GiftAidDetailsService,
    SubscriptionService,
    NumberPipe,
    TicketService,
    UserService,
    AccountProductionService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
