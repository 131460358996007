import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { JournalItemModel, JournalsModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateJournals,
  GetBankAccountTransactionData,
  GetDataByJournalsId,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import {
  AddBulkJournalComponent,
  AddClosePopupComponent,
  AddJournalsComponent,
  TransactionsAttachmentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';

import { DatePipe, Location } from '@angular/common';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-journals',
  templateUrl: './journals.component.html',
  styleUrls: ['./journals.component.scss'],
})
export class JournalsComponent implements OnInit {
  moduleId = Modules.Journals;
  moduleName = ModuleName.Journals;
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isExit = false;

  journalsId = Guid.EMPTY as unknown as Guid;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  journalsData: JournalsModel;
  journalDetailData: Array<JournalItemModel>;

  @ViewChild(AddBulkJournalComponent, { static: true })
  journalsDetails;

  @ViewChild(AddJournalsComponent, { static: true })
  journalsInfo;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  triggerEditData: Subject<any> = new Subject<any>();
  triggerTransactionLogData: Subject<any> = new Subject<any>();

  subscriptionRouting: Subscription;

  isFromBankImport = false;
  isManualBank = false;

  bankImportTransactionId = Guid.EMPTY as unknown as Guid;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    public commonService: CommonService,
    private store: Store,
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');
          this.isFromBankImport = false;
          this.isManualBank = false;
          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
          }

          const id = atob(params.get('id')!) as unknown as Guid;

          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;

            if (this.isManualBank) {
              this.getTransactionLogData();
            } else {
              this.getBankAccountTransactionData();
            }
          } else {
            this.journalsId = id;
            this.editJournals();
          }
        }
      }
    );
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.journalsInfo.journalsForm.controls.date.setValue(
          res.common.transactionLogData.entryDate
        );
        this.journalsInfo.journalsForm.controls.reference.setValue(
          res.common.transactionLogData.note
        );
        this.triggerTransactionLogData.next(res.common.transactionLogData);
      });
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.journalsInfo.journalsForm.controls.date.setValue(
          res.common.transactionLogData.transactionDate
        );
        this.journalsInfo.journalsForm.controls.reference.setValue(
          res.common.transactionLogData.description
        );
        this.triggerTransactionLogData.next(res.common.transactionLogData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.journalsId,
      isExit,
      Modules.Journals,
      RoutingPath.AddJournals
    );
  }

  onSave(isExit: boolean): void {
    if (
      this.journalsDetails.totalDebitValue !==
      this.journalsDetails.totalCreditValue
    ) {
      if (!this.isFromBankImport) {
        this.dialog
          .open(AddClosePopupComponent, {
            data: {
              moduleId: this.moduleId,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.isDraftChecked(isExit);
            }
          });
      } else {
        this.commonService.onFailure(
          NotificationTextMessage.errorDebitCreditMessage
        );
      }
    } else {
      this.isDraftChecked(isExit);
    }
  }

  isDraftChecked(isExit: boolean): void {
    if (this.journalsDetails.formJournalDetail.invalid) {
      this.journalsDetails.journalDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateJournals(this.journalsData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit && !this.isFromBankImport) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.journalsData = new JournalsModel();
      this.journalDetailData = new Array<JournalItemModel>();

      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.journalsDetails.journalDetailArray?.getRawValue().forEach((x) => {
        this.journalDetailData.push({
          id: x.id,
          accountId: x.account.id,
          customerSupplierId:
            +x.customerSupplier === -1 ? null : x.customerSupplier,
          receiptId: +x.receiptPayment === -1 ? null : x.receiptPayment,
          vatRateId: +x.vatRate === -1 ? null : x.vatRate,
          description: x.description,
          vatAmount: x.vatAmount,
          totalAmount: +x.debit > 0 ? +x.debit : +x.credit,
          isCredit: +x.credit > 0 ? true : false,
        });
      });

      this.journalsData = {
        id: this.journalsInfo.journalsForm.controls.id.value,
        entryNumber: this.journalsInfo.journalsForm.controls.journalNo.value,
        entryDate: this.datepipe
          .transform(
            this.journalsInfo.journalsForm.controls.date.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        note: this.journalsInfo.journalsForm.controls.reference.value,
        fundId: this.journalsInfo.journalsForm.controls.fundName.value,
        activityId:
          this.journalsInfo.journalsForm.controls.activity.value === ''
            ? null
            : this.journalsInfo.journalsForm.controls.activity.value,
        journalItems: this.journalDetailData,
        attachment: filedata,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.journalsId)) {
      this.editJournals();
    } else {
      this.journalsId = Guid.EMPTY as unknown as Guid;
      this.journalsInfo.ngOnInit();
      this.journalsDetails.ngOnInit();
    }
  }

  editJournals(): void {
    this.store
      .dispatch(new GetDataByJournalsId(this.journalsId))
      .subscribe((res) => {
        this.journalsInfo.journalsForm.markAsUntouched();
        this.journalsDetails.formJournalDetail.markAsUntouched();
        this.triggerEditData.next(res.journals.journalsData);
      });
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
