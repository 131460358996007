import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { DashboardModel } from '@app/core/Models';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService implements OnDestroy {
  setReloadDashboardEmitter = new EventEmitter();
  subscriptionVariable: Subscription;
  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  setDashboardReload(isCompanyChanged: boolean) {
    this.setReloadDashboardEmitter.emit(isCompanyChanged);
  }

  getDashboardData(): Observable<DashboardModel> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<DashboardModel>(
      `${environment.apiVersionUrl}Dashboard/dashboard`,
      headers
    );
  }

  getDashboardCard(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/dashboardCard
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardIncomeByCategoriesOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/incomeByCategory
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardExpenseByCategoriesOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/expenseByCategory
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardFundByTypesOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fundByTypes
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardDonationByTypesOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/DonationByTypes
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardIncomeVsExpensesOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/incomeVsExpenses
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardAgeingSummaryOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/receivablePayable
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardCashFlowOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/cashFlow
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptionVariable) {
      this.subscriptionVariable.unsubscribe();
    }
  }
}
