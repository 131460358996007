import { Injectable } from '@angular/core';
import { CreditNoteModel, SideListModel } from '@app/core/Models';
import { CreditNoteService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Guid } from 'guid-typescript';
import {
  ArchiveAndRestoreCreditNote,
  CreateCreditNote,
  DeleteCreditNote,
  GetDataByCreditNoteId,
} from './credit-note.action';

export class CreditNoteStateInfo {
  creditNoteData?: CreditNoteModel;
  creditNote: Array<CreditNoteModel>;
  creditNoteId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isCreditNoteAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  creditAllocationByCustomer?: any[] = [];
}

@State<CreditNoteStateInfo>({
  name: 'creditNote',
  defaults: {
    creditNote: [],
    creditNoteId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isCreditNoteAdded: false,
  },
})
@Injectable()
export class CreditNoteState {
  constructor(private creditNoteService: CreditNoteService) {}

  @Selector()
  static isLastPage(state: CreditNoteStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getCreditNoteId(state: CreditNoteStateInfo) {
    return state.creditNoteId;
  }

  @Action(CreateCreditNote)
  createCreditNote(
    { patchState }: StateContext<CreditNoteStateInfo>,
    action: CreateCreditNote
  ) {
    return this.creditNoteService.createCreditNote(action.creditNoteData).pipe(
      tap((res) => {
        patchState({
          creditNoteId: res,
          isCreditNoteAdded: true,
        });
      })
    );
  }

  @Action(GetDataByCreditNoteId)
  getDataByCreditNoteId(
    { patchState }: StateContext<CreditNoteStateInfo>,
    action: GetDataByCreditNoteId
  ) {
    return this.creditNoteService
      .getDataByCreditNoteId(action.creditNoteId)
      .pipe(
        tap((res) => {
          patchState({
            creditNoteData: res,
          });
        })
      );
  }

  @Action(DeleteCreditNote)
  deleteCreditNote(
    { getState, setState }: StateContext<CreditNoteStateInfo>,
    action: DeleteCreditNote
  ) {
    return this.creditNoteService.deleteCreditNote(action.creditNoteIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredCrediteNote = state.creditNote.filter(
          (item) =>
            !action.creditNoteIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.creditNoteIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.creditNote,
          creditNote: filteredCrediteNote,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreCreditNote)
  archiveAndRestoreCreditNote(
    { getState }: StateContext<CreditNoteStateInfo>,
    action: ArchiveAndRestoreCreditNote
  ) {
    return this.creditNoteService.archiveAndRestoreCreditNote(
      action.creditNoteIds,
      action.isArchive
    );
  }
}
