import { Guid } from 'guid-typescript';

export class DonationInKindModel {
  id: Guid;
  companyId?: Guid;
  donorId: Guid;
  sponsorEventId?: Guid;
  activityId?: Guid;
  fundId: Guid;
  documentNumber?: string;
  reference?: string;
  donationDate?: string;
  donationTypeID: number;
  incomeAccount: Guid;
  amount: number;
  feeExpenseAccount: Guid;
  note: string;
  attachment: Array<string>;
}
