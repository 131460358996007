<form [formGroup]="note12Form">
  <div class="notes-wrapper">
    <div class="notes-section">
      <p class="fw-bold notes-heading">Support Cost</p>
      <table class="notes-table" aria-describedby="User Task Table" scope="col">
        <tr>
          <th class="fw-bold" scope="col" rowspan="2">Analysis</th>
          <th class="fw-bold" scope="col" colspan="4">Raising Funds (%)</th>
          <th class="fw-bold" scope="col" colspan="4">
            Charitable Activities (%)
          </th>
        </tr>
        <tr>
          <th class="fw-bold" scope="col">Percentage(%)</th>
          <th class="fw-bold" scope="col">Unrestricted funds</th>
          <th class="fw-bold" scope="col">Restricted income funds</th>
          <th class="fw-bold" scope="col">Endowment funds</th>
          <th class="fw-bold" scope="col">Percentage(%)</th>
          <th class="fw-bold" scope="col">Unrestricted funds</th>
          <th class="fw-bold" scope="col">Restricted income funds</th>
          <th class="fw-bold" scope="col">Endowment funds</th>
        </tr>
        <ng-container *ngIf="calculativeData?.supportCost?.length === 0">
          <tr>
            <td colspan="9" class="text-align-center mt-1 fw-bold">
              <span>No records found</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="calculativeData?.supportCost?.length > 0">
          <tr *ngFor="let item of calculativeData.supportCost">
            <td class="fw-bold text-align-left" scope="col">
              {{ item?.name }} ( £ {{ item?.thisYearTotalFund | numberPipe }} )
            </td>
            <td>
              <div class="d-flex gap-10 align-items-center justify-content-end">
                <mat-form-field class="w-100">
                  <input
                    matInput
                    autocomplete="off"
                    allowDecimal
                    required
                    [(ngModel)]="item.raisingFund"
                    [ngModelOptions]="{ standalone: true }"
                    (blur)="onRaisingFundChange(item)"
                    pattern="^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$"
                  />
                </mat-form-field>
              </div>
            </td>
            <td>
              £
              {{
                (item?.thisYearUnrestrictedFund * (item.raisingFund ?? 50)) /
                  100 | numberPipe
              }}
            </td>
            <td>
              £
              {{
                (item?.thisYearRestrictedFund * (item.raisingFund ?? 50)) / 100
                  | numberPipe
              }}
            </td>
            <td>
              £
              {{
                (item?.thisYearEndowmentFund * (item.raisingFund ?? 50)) / 100
                  | numberPipe
              }}
            </td>
            <td>
              <div class="d-flex gap-10 align-items-center justify-content-end">
                <mat-form-field class="w-100">
                  <input
                    matInput
                    autocomplete="off"
                    allowDecimal
                    required
                    [(ngModel)]="item.charitableActivity"
                    (blur)="onCharitableActivityChange(item)"
                    [ngModelOptions]="{ standalone: true }"
                    pattern="^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$"
                  />
                </mat-form-field>
              </div>
            </td>
            <td>
              £
              {{
                (item?.thisYearUnrestrictedFund *
                  (item.charitableActivity ?? 50)) /
                  100 | numberPipe
              }}
            </td>
            <td>
              £
              {{
                (item?.thisYearRestrictedFund *
                  (item.charitableActivity ?? 50)) /
                  100 | numberPipe
              }}
            </td>
            <td>
              £
              {{
                (item?.thisYearEndowmentFund *
                  (item.charitableActivity ?? 50)) /
                  100 | numberPipe
              }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div class="notes-section">
      <p class="fw-bold notes-heading">Governance Cost</p>
      <table
        class="notes-table"
        aria-describedby="User Task Table"
        scope="col"
        [dataSource]="calculativeData?.governanceCost"
      >
        <tr>
          <th class="fw-bold" scope="col" rowspan="2">Analysis</th>
          <th class="fw-bold" scope="col" colspan="4">Raising Funds (%)</th>
          <th class="fw-bold" scope="col" colspan="4">
            Charitable Activities (%)
          </th>
        </tr>
        <tr>
          <th class="fw-bold" scope="col">Percentage(%)</th>
          <th class="fw-bold" scope="col">Unrestricted funds</th>
          <th class="fw-bold" scope="col">Restricted income funds</th>
          <th class="fw-bold" scope="col">Endowment funds</th>
          <th class="fw-bold" scope="col">Percentage(%)</th>
          <th class="fw-bold" scope="col">Unrestricted funds</th>
          <th class="fw-bold" scope="col">Restricted income funds</th>
          <th class="fw-bold" scope="col">Endowment funds</th>
        </tr>
        <ng-container *ngIf="calculativeData?.governanceCost?.length === 0">
          <tr>
            <td colspan="9" class="text-align-center mt-1 fw-bold">
              <span>No records found</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="calculativeData?.governanceCost?.length > 0">
          <tr
            *ngFor="let item of calculativeData.governanceCost; let i = index"
          >
            <th class="fw-bold text-align-left" scope="col">
              {{ item?.name }}
              ( £ {{ item?.thisYearTotalFund | numberPipe }} )
            </th>
            <td>
              <div class="d-flex gap-10 align-items-center justify-content-end">
                <mat-form-field class="w-100">
                  <input
                    matInput
                    autocomplete="off"
                    allowDecimal
                    required
                    [(ngModel)]="item.raisingFund"
                    [ngModelOptions]="{ standalone: true }"
                    (blur)="onRaisingFundChange(item)"
                    pattern="^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$"
                  />
                </mat-form-field>
              </div>
            </td>
            <td>
              £
              {{
                (item?.thisYearUnrestrictedFund * (item.raisingFund ?? 0)) / 100
                  | numberPipe
              }}
            </td>
            <td>
              £
              {{
                (item?.thisYearRestrictedFund * (item.raisingFund ?? 0)) / 100
                  | numberPipe
              }}
            </td>
            <td>
              £
              {{
                (item?.thisYearEndowmentFund * (item.raisingFund ?? 0)) / 100
                  | numberPipe
              }}
            </td>
            <td>
              <div class="d-flex gap-10 align-items-center justify-content-end">
                <mat-form-field class="w-100">
                  <input
                    matInput
                    autocomplete="off"
                    allowDecimal
                    required
                    [(ngModel)]="item.charitableActivity"
                    (blur)="onCharitableActivityChange(item)"
                    [ngModelOptions]="{ standalone: true }"
                    pattern="^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$"
                  />
                </mat-form-field>
              </div>
            </td>
            <td>
              £
              {{
                (item?.thisYearUnrestrictedFund *
                  (item.charitableActivity ?? 100)) /
                  100 | numberPipe
              }}
            </td>
            <td>
              £
              {{
                (item?.thisYearRestrictedFund *
                  (item.charitableActivity ?? 100)) /
                  100 | numberPipe
              }}
            </td>
            <td>
              £
              {{
                (item?.thisYearEndowmentFund *
                  (item.charitableActivity ?? 100)) /
                  100 | numberPipe
              }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
  <div class="notes-wrapper">
    <div class="notes-section">
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox1"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
  </div>
</form>
