<div class="content-body pt-0">
  <div class="sidenav-table sidenav-table-input wp-100">
    <div class="mat-table-wrapper product-detail-table">
      <form [formGroup]="formJournalDetail">
        <ng-container formArrayName="journalDetailArray">
          <table
            aria-describedby="product-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="account">
              <th mat-header-cell *matHeaderCellDef class="w-300">
                Account <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="fill">
                  <input
                    type="text"
                    matInput
                    formControlName="account"
                    required
                    [matAutocomplete]="accountGroup"
                    (keyup)="onAccountSearch($event)"
                    (mousedown)="resetAccountList(element)"
                  />
                  <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                  <mat-autocomplete
                    [displayWith]="getOptionText"
                    #accountGroup="matAutocomplete"
                    [disableOptionCentering]="true"
                    (opened)="scrollIntoView(element)"
                    (optionSelected)="checkModuleType($event.option.value, i)"
                  >
                    <mat-optgroup
                      *ngFor="let group of accountGroupList"
                      [label]="group.groupName"
                      title="{{ group.groupName }}"
                    >
                      <mat-option
                        *ngFor="let names of group.listModels"
                        [value]="names"
                        title="{{ names.name }}"
                        [class]="
                          journalDetailArray.controls[i].get('account').value
                            .id === names.id
                            ? 'mat-selected'
                            : ''
                        "
                      >
                        {{ names.name }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    [value]="element.description"
                    maxlength="{{ maxLength.maxLength200 }}"
                    autocomplete="off"
                    formControlName="description"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="customer/supplier">
              <th mat-header-cell *matHeaderCellDef class="w-120">
                Customer / Supplier
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <mat-select
                    #customerSupplier
                    (focus)="customerSupplier.open()"
                    (valueChange)="customerSupplier.close()"
                    [disableOptionCentering]="true"
                    formControlName="customerSupplier"
                    panelClass="mat-select-position"
                  >
                    <mat-option
                      *ngFor="let option of customerSupplierList[i]"
                      [value]="option.id"
                      title="{{ option.name }}"
                      >{{ option.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="vatRate" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef>VAT Rate</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <mat-select
                    #vatRate
                    (focus)="vatRate.open()"
                    (valueChange)="vatRate.close()"
                    [disableOptionCentering]="true"
                    formControlName="vatRate"
                    panelClass="mat-select-position"
                    (selectionChange)="calVatAmount(i)"
                  >
                    <mat-option
                      *ngFor="let option of vatRateList[i]"
                      [value]="option.id"
                      title="{{ option.name }}"
                      >{{ option.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="vatAmount" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef class="w-80">VAT Amount</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    allowDecimal
                    matInput
                    [value]="element.vatAmount"
                    autocomplete="off"
                    formControlName="vatAmount"
                    maxlength="{{ maxLength.maxLength10 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="debit">
              <th mat-header-cell *matHeaderCellDef>Debit</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    allowDecimal
                    matInput
                    [value]="element.debit"
                    autocomplete="off"
                    formControlName="debit"
                    maxlength="{{ maxLength.maxLength10 }}"
                    (keypress)="debitChanges(i, $event)"
                    (change)="calVatAmount(i)"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="credit">
              <th mat-header-cell *matHeaderCellDef>Credit</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    allowDecimal
                    matInput
                    [value]="element.credit"
                    autocomplete="off"
                    formControlName="credit"
                    maxlength="{{ maxLength.maxLength10 }}"
                    (keypress)="creditChanges(i, $event)"
                    (change)="calVatAmount(i)"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-20"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  *ngIf="
                    !this.journalDetailArray.controls[i].get('isDisable').value
                  "
                  class="close-btn"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onDeleteProductDetails(i)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayJournalDetailsColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayJournalDetailsColumns"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>

    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
          >Add New Line</a
        >
        <a
          class="link mr-1"
          *ngIf="journalDetailArray.length > 0"
          href="javascript:void(0)"
          (click)="clearForm()"
          >Clear All Lines</a
        >
      </div>
    </div>
    <div class="mt-1">
      <p class="fw-bold mr-1 mt-0 text-align-right">
        Total Credit
        <span class="fw-normal ml-1"
          >£ {{ totalCreditValue | numberPipe }}</span
        >
      </p>
      <p class="fw-bold mr-1 text-align-right">
        Total Debit
        <span class="fw-normal ml-1">£ {{ totalDebitValue | numberPipe }}</span>
      </p>
    </div>
  </div>
</div>
