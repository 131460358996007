export class SalesBillReportModel {
  vatId: number;
  vatName: string;
  netAmount: any;
  vatAmount: any;
  totalAmount: any;
  isExpanded: boolean;
}

export class VATReportModel {
  name: string;
  netAmount: string;
  vatAmount: string;
  totalAmount: string;
  isExpanded: boolean;
  salesVAT?: Array<SalesBillReportModel>;
  billVAT?: Array<SalesBillReportModel>;
}

export class ExpandedCollapseModel {
  id: number;
  name: string;
  isExpanded: boolean;
}
