import { DebitNoteModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateDebitNote {
  static readonly type = '[DEBITNOTE] Create DebitNote';

  constructor(public debitNote: DebitNoteModel) {}
}

export class GetDataByDebitNoteId {
  static readonly type = '[DEBITNOTE] Get DebitNote Data By DebitNote Id';

  constructor(public debitNoteId: Guid) {}
}

export class ArchiveAndRestoreDebitNote {
  static readonly type = '[DEBITNOTE] Archive and Restore Selected DebitNote';

  constructor(public debitNoteIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteDebitNote {
  static readonly type = '[DEBITNOTE] Delete Selected DebitNote';

  constructor(public debitNoteIds: Array<Guid>) {}
}
