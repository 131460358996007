<div class="content-body">
  <form [formGroup]="donationCodingForm">
    <div class="d-flex gap-20">
      <div class="form-field datepicker">
        <p>Donation Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="donationDate"
            [matDatepicker]="donationDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="donationDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #donationDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              donationCodingForm.get('donationDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              donationCodingForm.get('donationDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Fund Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isFundChangePermission"
            >
              <mat-icon (click)="gotoAddFund()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledFundEditButton || !isFundChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditFund()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #fundName
          (focus)="fundName.open()"
          (valueChange)="fundName.close()"
          [disableOptionCentering]="true"
          formControlName="fundName"
          panelClass="mat-select-position"
          (change)="fundChange($event)"
        >
          <ng-option *ngFor="let fn of fundNameList" [value]="fn.id">
            <div class="clamp" title="{{ fn.name }}">{{ fn.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Sponsored Event</p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isSponserEventChangePermission"
            >
              <mat-icon (click)="gotoAddSponsor()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="
                disabledSponserEditButton || !isSponserEventChangePermission
              "
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditSponsor()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>

        <ng-select
          #sponsor
          (focus)="sponsor.open()"
          (valueChange)="sponsor.close()"
          [disableOptionCentering]="true"
          formControlName="sponsoredEvent"
          panelClass="mat-select-position"
          (change)="sponserChange($event)"
        >
          <ng-option *ngFor="let cl of sponsoreList" [value]="cl.id">
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Activity</p>
        <ng-select
          #activity
          (focus)="activity.open()"
          (valueChange)="activity.close()"
          [disableOptionCentering]="true"
          formControlName="activity"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of activityList" [value]="cl.id">
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
  </form>
</div>
