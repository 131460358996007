<div class="content report-list">
  <div
    class="content-body content-body-scroll"
    [ngClass]="[
      showAccountDetails ? 'content-account-details' : '',
      (totalRecord$ | async) <= 10 && showAccountDetails
        ? 'content-account-details-full-view'
        : ''
    ]"
  >
    <app-report-header-details
      [triggerCustomerId]="triggerCustomerId"
      *ngIf="showAccountDetails"
    ></app-report-header-details>

    <app-transactions-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [showAccountDetails]="showAccountDetails"
      [listParameters]="listParameters"
      [isBankImport]="isBankImport"
    >
    </app-transactions-header>

    <p *ngIf="!showAccountDetails" class="fw-bold mb-1 font-size-20">
      {{ moduleName }}
    </p>

    <div
      class="mat-table-wrapper"
      [ngClass]="[
        moduleId === moduleEnum.CustomerList
          ? 'mat-table-wrapper-report-details'
          : '',
        totalSelectedRecords > 0 ? 'mat-table-wrapper-full' : '',
        showPaginator
          ? 'mat-table-wrapper-pagination'
          : 'mat-table-wrapper-pagination-full',
        (totalRecord$ | async) <= 10 ? 'mat-table-wrapper-full-view' : '',
        showAccountDetails ? 'mat-table-wrapper-account-details' : '',
        reportText ||
        (moduleId$ | async) === moduleEnum.TrialBalance ||
        (moduleId$ | async) === moduleEnum.BalanceSheet ||
        (moduleId$ | async) === moduleEnum.StatementofFinancialActivities ||
        (moduleId$ | async) === moduleEnum.ReceiptAndPayment ||
        (moduleId$ | async) === moduleEnum.StatementOfAssetsAndLiabilities ||
        (moduleId$ | async) === moduleEnum.AccountProductionTrialBalance ||
        (moduleId$ | async) ===
          moduleEnum.AccountProductionStatementofFinancialActivities ||
        (moduleId$ | async) === moduleEnum.AccountProductionBalanceSheet ||
        (moduleId$ | async) === moduleEnum.AccountProductionReceiptAndPayment ||
        (moduleId$ | async) ===
          moduleEnum.AccountProductionStatementOfAssetsAndLiabilities
          ? 'mat-table-wrapper-header-text'
          : ''
      ]"
    >
      <table
        aria-describedby="main-list"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="dataSource"
      >
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            [id]="col.name"
            fxFlex="50"
            [ngClass]="[
              col.dataType === dataType.String && col.alignment !== null
                ? 'text-align-right'
                : '',
              col.dataType === dataType.Int32 ||
              col.dataType === dataType.Currency
                ? 'text-align-right'
                : ''
            ]"
          >
            <div>
              <span>
                {{ col.name }}
              </span>
            </div>
          </th>

          <td
            mat-cell
            *matCellDef="let element; let rowIndex = index"
            [ngClass]="[
              col.dataType === dataType.Int32 ||
              col.dataType === dataType.Currency
                ? 'text-align-right'
                : '',
              col.dataType === dataType.String &&
              element[colIndex].alignment !== null
                ? 'text-align-right'
                : '',
              element[colIndex].backgroundColor !== null ? 'bg-gray-400' : ''
            ]"
          >
            <div class="clamp">
              <span
                *ngIf="
                  col.dataType === dataType.String ||
                  col.dataType === dataType.Bool
                "
                title="{{ element[colIndex].rowData }}"
                [ngClass]="{ 'fw-bold': element[colIndex].isBold }"
              >
                {{ element[colIndex].rowData }}
              </span>

              <span
                *ngIf="
                  col.dataType === dataType.URL &&
                  element[colIndex].id !== '' &&
                  element[colIndex].moduleId !== 0
                "
                title="{{ element[colIndex].rowData }}"
                [ngClass]="{ 'fw-bold': element[colIndex].isBold }"
              >
                <a
                  class="link maxw-100 clamp"
                  (click)="
                    redirect(element[colIndex].id, element[colIndex].moduleId)
                  "
                >
                  {{ element[colIndex].rowData }}
                </a>
              </span>

              <span
                *ngIf="
                  col.dataType === dataType.URL &&
                  element[colIndex].id !== '' &&
                  element[colIndex].moduleId === 0
                "
                title="{{ element[colIndex].rowData }}"
                [ngClass]="{ 'fw-bold': element[colIndex].isBold }"
              >
                <a
                  class="link fw-bold maxw-100 clamp"
                  (click)="
                    redirect(element[colIndex].id, element[colIndex].moduleId)
                  "
                >
                  {{ element[colIndex].rowData }}
                </a>
              </span>

              <span
                *ngIf="
                  col.dataType === dataType.URL &&
                  element[colIndex].id === '' &&
                  element[colIndex].moduleId === 0
                "
                title="{{ element[colIndex].rowData }}"
                [ngClass]="{ 'fw-bold': element[colIndex].isBold }"
              >
                {{ element[colIndex].rowData }}
              </span>

              <span
                *ngIf="col.dataType === dataType.DateTime"
                title="{{ element[colIndex].rowData }}"
                [ngClass]="{ 'fw-bold': element[colIndex].isBold }"
              >
                {{ element[colIndex].rowData | date: "dd-LLL-yyyy" }}</span
              >

              <span
                *ngIf="
                  (col.dataType === dataType.Int32 ||
                    col.dataType === dataType.Currency) &&
                  element[colIndex].rowData !== ''
                "
                title="{{ element[colIndex].rowData }}"
                [ngClass]="{ 'fw-bold': element[colIndex].isBold }"
              >
                £ {{ element[colIndex].rowData | numberPipe }}</span
              >

              <span *ngIf="col.dataType === dataType.Blob">
                <button
                  mat-button
                  (click)="downloadFile(element[colIndex].rowData)"
                  class="fw-bold text-primary"
                  disableRipple
                  [disabled]="isViewPermission$ | async"
                >
                  <mat-icon>file_download</mat-icon>
                </button></span
              >
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnsNames$ | async"></tr>
      </table>
      <div
        *ngIf="dataSource.data.length === 0 && noDataFound"
        class="text-align-center mt-1"
      >
        <div
          *ngIf="
            (moduleId$ | async) === moduleEnum.AccountProductionTrialBalance &&
              this.headerDetails.isAccountProductionTrialBalance;
            else other_content
          "
        >
          The Trial Balance is currently in a draft state. Please ensure that
          it's properly balanced to obtain accurate values
        </div>

        <ng-template #other_content>No records found</ng-template>
      </div>
      <div
        class="paginator-wrapper"
        *ngIf="
          (moduleId$ | async) !== moduleEnum.BalanceSheet &&
          (moduleId$ | async) !== moduleEnum.StatementofFinancialActivities &&
          (moduleId$ | async) !== moduleEnum.TrialBalance &&
          (moduleId$ | async) !== moduleEnum.StatementOfAssetsAndLiabilities &&
          (moduleId$ | async) !== moduleEnum.ReceiptAndPayment &&
          (moduleId$ | async) !== moduleEnum.AccountProductionTrialBalance &&
          (moduleId$ | async) !== moduleEnum.AccountProductionBalanceSheet &&
          (moduleId$ | async) !==
            moduleEnum.AccountProductionStatementofFinancialActivities &&
          (moduleId$ | async) !==
            moduleEnum.AccountProductionStatementOfAssetsAndLiabilities &&
          (moduleId$ | async) !==
            moduleEnum.AccountProductionReceiptAndPayment &&
          totalSelectedRecords <= 0 &&
          (totalRecord$ | async) > 0
        "
      >
        <app-custom-paginator
          *ngIf="(totalRecord$ | async) > 10"
          [length]="totalRecord$ | async"
          [listParameters]="listParameters"
          (pageChanged)="pageChanged($event)"
          (pageSizeVal)="pageSizeVal($event)"
          (togglePaginator)="togglePaginator($event)"
          [triggerPaginationChange]="triggerPaginationChange"
        >
        </app-custom-paginator>
      </div>

      <app-vat-report
        [listParameters]="listParameters"
        (triggerDataFromVatReport)="getDataFromVatReport($event)"
        *ngIf="moduleId === moduleEnum.VAT"
      ></app-vat-report>
    </div>
  </div>
</div>
