import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  AuthorizedRole,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { Contacts, Country, GlobalComponent } from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  CommonState,
  FileUpload,
  GetCountryList,
  GetFileAsByte,
  GetTitleList,
  GetUserPermissionsList,
  UserState,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserPermissionsComponent } from '../../user-permissions/user-permissions.component';

@Component({
  selector: 'app-user-basic-info',
  templateUrl: './user-basic-info.component.html',
  styleUrls: ['./user-basic-info.component.scss'],
})
export class UserBasicInfoComponent implements OnInit {
  @Input() triggerEditBasicInfoData: Observable<any>;
  emailRegExp =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  userId = Guid.EMPTY as unknown as Guid;
  userForm: FormGroup;
  titleList: any[];
  permissionList: any[];
  authorizedRole = AuthorizedRole;
  loggedInUserId = this.globalComponent.getLoggedInUserId();
  isFormSubmitted = false;
  defaultGuidValue = Guid.EMPTY as unknown as Guid;
  url: any;
  photoUrl = '';
  fileUploadRequestModel: any;
  fileUploadResponseModel: any;
  isImageSelected = false;
  isRemoveFiles = false;
  selectedFile: File;
  fileList: File[];
  acceptedFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];
  @ViewChild('fileupload') fileUpload: ElementRef<HTMLInputElement>;
  isUserActive: boolean = false;
  countryList: Country[];
  countryCodeList: Country[];

  //#region address details
  formAddressDetail: UntypedFormGroup;
  addressDetailArray: any;
  addressTableDataSource: MatTableDataSource<AbstractControl>;

  displayAddressDetailColumns: string[] = [
    'addressType',
    'address',
    'city',
    'county',
    'country',
    'postalCode',
  ];
  //#endregion

  //#region contact information
  formContactDetail: UntypedFormGroup;
  contactDetailArray: any;
  contactTableDataSource: MatTableDataSource<AbstractControl>;
  contactDetailIds: Array<number> = [];
  displayContactDetailColumns: string[] = ['name', 'email', 'phone'];
  contactDetailList?: Contacts[];
  //#endregion

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private notifier: NotificationService,
    public commonService: CommonService,
    public dialog: MatDialog,
    public globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.getTitles();
    this.bindPermissionList();
    this.getCountry();
    this.triggerEditBasicInfoData.subscribe((data) => {
      if (data) {
        this.isUserActive = data.identityUserId || data.isInvitedUser;
        this.setForm();
        this.editUser(data);
        setTimeout(() => {
          this.editContactDetails(data.contacts);
          this.editAddressDetails(data.addresses);
        }, 500);
      }
    });
  }

  setForm(): void {
    this.userForm = this.formBuilder.group({
      title: new FormControl('Mr'),
      firstName: new FormControl({ value: '', disabled: this.isUserActive }, [
        Validators.required,
      ]),
      middleName: new FormControl({ value: '', disabled: this.isUserActive }),
      lastName: new FormControl({ value: '', disabled: this.isUserActive }),
      email: new FormControl({ value: '', disabled: this.isUserActive }, [
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]),
      photo: new FormControl<string | null>(''),
      permissions: new FormControl(3, [Validators.required]),
    });

    this.url = '';
    this.photoUrl = '';
    this.isImageSelected = false;

    this.setAddressForm(true);
    this.setContactForm(true);
  }

  editUser(data): void {
    this.userId = data.id;
    this.userForm.patchValue({
      title: data.title,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      email: data.email,
      permissions: data.roleId,
    });
    this.photoUrl = data.photoUrl;

    if (data.photoUrl) {
      this.store.dispatch(new GetFileAsByte(data.photoUrl)).subscribe(
        (data) => {
          this.url = data.common.fileByteUrl;
          this.isImageSelected = this.url;
        },
        (error) => {
          this.url = '';
          this.isImageSelected = this.url;
        }
      );
    } else {
      this.url = '';
      this.isImageSelected = this.url;
    }
  }

  getTitles(): void {
    this.store
      .dispatch(new GetTitleList())
      .pipe(
        tap(() => {
          this.titleList = this.store.selectSnapshot(CommonState.getTitle);
        })
      )
      .subscribe();
  }

  bindPermissionList(): void {
    this.store
      .dispatch(new GetUserPermissionsList())
      .pipe(
        tap(() => {
          this.permissionList = this.store.selectSnapshot(
            UserState.getUserPermissions
          )!;
        })
      )
      .subscribe();
  }

  getCountry(): void {
    this.store
      .dispatch(new GetCountryList())

      .subscribe((res) => {
        this.countryList = res.country.country;
        this.countryCodeList = res.country.country;
      });
  }

  openDialog() {
    if (this.userForm.controls.permissions.dirty) {
      this.commonService.onFailure(
        NotificationTextMessage.permissionChangeAndNotSave
      );
    } else {
      const dialogRef = this.dialog.open(UserPermissionsComponent, {
        data: {
          permissionId: this.userForm.controls.permissions.value,
          userId: this.userId,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.commonService.onSucess(NotificationTextMessage.successMessage);
        }
      });
    }
  }

  onSelectionChange(type: number): void {
    this.contactDetailArray.controls[0]['controls'].title.setValue(type);
  }

  get getFormControls(): any {
    return this.userForm.controls;
  }

  // #region  file upload
  deletePhoto(): void {
    this.isImageSelected = false;
    this.isRemoveFiles = true;
    this.userForm.markAsDirty();
    this.fileUpload.nativeElement.value = '';
    this.url = '';
    this.photoUrl = '';
    this.userForm.controls.photo.setValue('');
    this.fileUploadResponseModel = { fileUrl: '', fileName: '', fileSize: '' };
  }

  onFileSelected(event: any): void {
    if (!event.target.files || !event.target.files.length) {
      return;
    }

    this.selectedFile = event.target.files[0];
    if (this.selectedFile.size / 1024 / 1024 < 5) {
      this.isImageSelected = true;
      this.saveFile(event.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.url = reader.result;
      };
    } else {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSize5mb
      );
    }
  }

  saveFile(file: File): void {
    this.fileList = [];
    this.fileList.push(file);

    this.fileUploadRequestModel = {
      file: this.fileList,
      attachmentType: 109,
    };

    this.store
      .dispatch(new FileUpload(this.fileUploadRequestModel))
      .pipe(
        tap((data) => {
          this.fileUploadResponseModel = data.common.fileUploadRequestModel[0];
        })
      )
      .subscribe();
  }

  get accept(): string {
    return this.acceptedFileTypes?.join(', ') ?? '';
  }
  // #endregion

  onKeyUpEvent(): void {
    this.contactDetailArray.controls[0]['controls'].name.setValue(
      this.userForm.controls.firstName.value +
        ' ' +
        this.userForm.controls.middleName.value +
        ' ' +
        this.userForm.controls.lastName.value
    );
  }

  onEmailKeyUpEvent(): void {
    this.contactDetailArray.controls[0]['controls'].email.setValue(
      this.userForm.controls.email.value
    );
  }

  //#region address details
  setAddressForm(addNewRow: boolean): void {
    this.formAddressDetail = new FormGroup({
      addressDetailArray: new UntypedFormArray([]),
    });

    this.addressDetailArray = this.formAddressDetail.get(
      'addressDetailArray'
    ) as UntypedFormArray;

    this.setAddressDataSource(this.addressDetailArray);
    if (addNewRow) this.createAddressDetailRow();
  }

  createAddressDetailRow(): void {
    this.addressDetailArray = this.formAddressDetail.get(
      'addressDetailArray'
    ) as UntypedFormArray;

    this.addressDetailArray.push(this.createAddressItem());

    this.setAddressDataSource(this.addressDetailArray);
  }

  createAddressItem(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(Guid.EMPTY as unknown as Guid),
      addressType: new FormControl<string | null>('Business'),
      address: new FormControl<string | null>(''),
      city: new FormControl<string | null>(''),
      county: new FormControl<string | null>(''),
      country: new FormControl<number | null>(206),
      postalCode: new FormControl<number | null>(null),
    });
  }

  setAddressDataSource(array: UntypedFormArray): void {
    this.addressTableDataSource = new MatTableDataSource(array.controls);
  }

  editAddressDetails(data: any): void {
    this.resetAddressForm();
    if (data && data.length > 0) {
      this.addressDetailArray = this.formAddressDetail.get(
        'addressDetailArray'
      ) as UntypedFormArray;

      data.forEach((item, i) => {
        this.addressDetailArray.push(this.buildAddressForm(item, i));
      });

      this.setAddressDataSource(this.addressDetailArray);
    } else {
      this.createAddressDetailRow();
    }
  }

  buildAddressForm(item: any, i): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      addressType: item.addressType ? item.addressType : 'Business',
      address: item.address,
      city: item.city,
      county: item.county,
      country: item.countryId,
      postalCode: item.postalCode,
    });
  }

  addNewArressDetailRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createAddressDetailRow();
    }
  }

  onDeleteAddressDetail(index: number, element: any): void {
    this.addressDetailArray = this.formAddressDetail.get(
      'addressDetailArray'
    ) as UntypedFormArray;
    this.addressDetailArray.removeAt(index);
    this.setAddressDataSource(this.addressDetailArray);
    this.addressDetailArray.markAsDirty();
  }

  resetAddressForm(): void {
    const frmArray = this.formAddressDetail.get(
      'addressDetailArray'
    ) as UntypedFormArray;
    frmArray.clear();
  }
  //#endregion

  //#region User Contact Details
  editContactDetails(data: any): void {
    this.resetContactForm();
    if (data && data.length > 0) {
      this.contactDetailArray = this.formContactDetail.get(
        'contactDetailArray'
      ) as UntypedFormArray;

      data.forEach((item, i) => {
        this.contactDetailArray.push(this.buildOrderItemsForm(item, i));
      });

      this.setDataSource(this.contactDetailArray);
    } else {
      this.createRow();
    }
  }

  resetContactForm(): void {
    const frmArray = this.formContactDetail.get(
      'contactDetailArray'
    ) as UntypedFormArray;
    frmArray.clear();
  }

  setContactForm(addNewRow: boolean): void {
    this.formContactDetail = new FormGroup({
      contactDetailArray: new UntypedFormArray([]),
    });

    this.contactDetailArray = this.formContactDetail.get(
      'contactDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.contactDetailArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.contactDetailArray = this.formContactDetail.get(
      'contactDetailArray'
    ) as UntypedFormArray;

    this.contactDetailArray.push(this.createItem());

    this.setDataSource(this.contactDetailArray);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(Guid.EMPTY as unknown as Guid),
      title: new FormControl<string | null>('Mr', Validators.required),
      name: new FormControl<string | null>(''),
      email: new FormControl<string | null>(''),
      country: new FormControl<number | null>(206),
      phone: new FormControl(''),
      contactId: new FormControl<number | null>(0, Validators.required),
    });
  }

  setDataSource(array: UntypedFormArray): void {
    this.contactTableDataSource = new MatTableDataSource(array.controls);
  }

  get getContactFormControls(): any {
    let formArray = this.formContactDetail.get(
      'contactDetailArray'
    ) as UntypedFormArray;
    return formArray.controls;
  }

  buildOrderItemsForm(item: Contacts, i): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      title: item.title ? item.title : 'Mr',
      name: new FormControl<string | null>(item.name),
      email: new FormControl<string | null>(item.email),
      country: item.countryId ? item.countryId : 206,
      phone: new FormControl<string>(item.phone),
    });
  }

  //#endregion
}
