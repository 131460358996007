import { ActionType, DataType } from '@app/core/Enum';
import { Guid } from 'guid-typescript';

export class MainList {
  paginationModel: {
    totalItemCount: number;
  };
  resultSet: {
    columns: Array<{
      name: string;
      id: Guid;
      dataType?: DataType;
      allowSortBy: boolean;
      alignment: string;
    }>;
    data: Array<{
      rowData: string;
      moduleId: number;
      isBold: boolean;
      id: Guid;
      alignment: string;
    }>;
  };
  actions: Array<{
    actionType: ActionType;
    icon: string;
    name: string;
  }>;
}
