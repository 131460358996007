import { Guid } from 'guid-typescript';

export class OpeningBalance {
  id?: Guid;
  accountId: Guid;
  unrestrictedCredit?: number;
  unrestrictedDebit?: number;
  restrictedCredit?: number;
  restrictedDebit?: number;
  endowmentCredit?: number;
  endowmentDebit?: number;
  accountingPeriodId?: Guid;
  date?: string;
}

export class SaveOpeningBalanceModel {
  accountingPeriodId: Guid;
  date?: string;
  openingBalanceData: Array<OpeningBalance>;
}
