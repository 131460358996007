import { MainListParameters } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetBankOverview {
  static readonly type = '[BANKOVERVIEW] Get Bank Overview';
}
export class GetBankTrasnactions {
  static readonly type = '[BANKOVERVIEW] Get Bank Transactions';

  constructor(public queryParams: MainListParameters) {}
}

export class DeleteBankStatementTransaction {
  static readonly type = '[BANKOVERVIEW] Delete Bank Statement Transaction';

  constructor(public ids: Array<Guid>) {}
}
