import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DashboardModel } from '@app/core/Models';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexMarkers,
  ApexNoData,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ChartComponent,
} from 'ng-apexcharts';

import { Observable } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
  stroke: ApexStroke;
  markers: ApexMarkers;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  noData: ApexNoData;
};

@Component({
  selector: 'app-dashboard-income-expenses-chart',
  templateUrl: './dashboard-income-expenses-chart.component.html',
  styleUrls: ['./dashboard-income-expenses-chart.component.scss'],
})
export class DashboardIncomeExpensesChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  reportList: DashboardModel;

  @Input()
  triggerIncomeVsExpensesData: Observable<any>;

  ngOnInit(): void {
    this.triggerIncomeVsExpensesData?.subscribe((data) => {
      this.reportList = data;
      this.getIncomeVsExpense();
    });
  }

  getIncomeVsExpense(): void {
    const tempSeries: any = [];
    const tempCategories: any = [];
    const tempSeries1: any = [];

    for (const barchart of this.reportList.income[`categories`]) {
      tempCategories.push(barchart);
    }

    for (const barchart of this.reportList.income[`series`]) {
      tempSeries.push(barchart['data']);
    }

    for (const barchart of this.reportList.expense[`series`]) {
      tempSeries1.push(barchart['data']);
    }

    this.chartOptions = {
      series: [
        {
          name: 'Income',
          data: tempSeries,
        },
        {
          name: 'Expenses',
          data: tempSeries1,
        },
      ],
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        labels: {
          colors: ['#8A61C3', '#EB7074'],
        },
        markers: {
          fillColors: ['#8A61C3', '#EB7074'],
          width: 14,
          height: 14,
          radius: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        width: 2,
        colors: ['#8A61C3', '#EB7074'],
      },
      markers: {
        colors: ['#8A61C3', '#EB7074'],
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      fill: {
        opacity: 1,
        type: 'gradient',
        colors: ['#EB7074'],
        gradient: {
          shade: 'light',
          type: 'vertical',
          gradientToColors: ['#8A61C3'],
          inverseColors: true,
          shadeIntensity: 0.25,
          opacityFrom: 0.8,
          opacityTo: 0.4,
          stops: [0, 100],
        },
      },
      xaxis: {
        categories: tempCategories,
      },
      tooltip: {
        marker: {
          fillColors: ['#8A61C3', '#EB7074'],
        },
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    };
  }
}
