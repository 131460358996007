import { Guid } from 'guid-typescript';

export class CustomEmailModel {
  fromEmail: string;
  subject: string;
  message: string;
  isSendFromCapium: boolean;
  toEmail: Array<string>;
  ccEmail: Array<string>;
  bccEmail: Array<string>;
  providerAccountId: Guid;
  attachments: Array<EmailAttachmentModel>;
  entityId: Guid;
  entityName: string;
  invoiceId: Guid;
  amount: number;
  ids: Array<Guid>;
}

export class EmailAttachmentModel {
  fileName: string;
  fileBase64String: string;
  mimeType: string;
  isUploadedFromBrowser: boolean;
}
