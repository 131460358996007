import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  PaymentDetailsModel,
  ProductDetailsModel,
  BillModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateBill,
  GetBankAccountTransactionData,
  GetDataByBillId,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import {
  AddBillComponent,
  ProductDetailsComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
} from '@app/modules';
import {
  AddClosePopupComponent,
  AdvancePaymentComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss'],
})
export class BillComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  moduleId = Modules.Bills;
  moduleName = ModuleName.Bills;

  billId = Guid.EMPTY as unknown as Guid;
  billData: BillModel;
  productItems: Array<ProductDetailsModel>;
  receiptItems: Array<PaymentDetailsModel>;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddBillComponent, { static: true })
  billDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  productDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  receiptDetails;

  @ViewChild(AdvancePaymentComponent, { static: true })
  advancePaymentDetails;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  subscriptionRouting: Subscription;
  headerText = 'Payment';

  triggerEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggereEditPaymentData: Subject<any> = new Subject<any>();
  triggerAdvancePayment: Subject<any> = new Subject<any>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();

  amount = 0;
  balanceAmount = 0;
  date: any;

  isFromBankImport = false;
  isManualBank = false;
  hasAdvanceAmount = false;

  bankImportTransactionId = Guid.EMPTY as unknown as Guid;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;
  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');

          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
          }

          const id = atob(params.get('id')!) as unknown as Guid;

          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;
            if (this.isManualBank) {
              this.getTransactionLogData();
            } else {
              this.getBankAccountTransactionData();
            }
          } else {
            this.billId = id;
            this.editBill();
          }
        }
      }
    );
  }

  isVatIncludedClick(event): void {
    this.triggerisVatIncluded.next(event);
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.billDetails.billForm.controls.billDate.setValue(
          res.common.transactionLogData.entryDate
        );
        this.billDetails.billForm.controls.notes.setValue(
          res.common.transactionLogData.note
        );
      });
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.billDetails.billForm.controls.billDate.setValue(
          res.common.transactionLogData.transactionDate
        );
        this.billDetails.billForm.controls.notes.setValue(
          res.common.transactionLogData.description
        );
      });
  }

  editBill(): void {
    this.store.dispatch(new GetDataByBillId(this.billId)).subscribe((res) => {
      this.billDetails.billForm.markAsUntouched();
      this.productDetails.formProductDetail.markAsUntouched();
      this.receiptDetails.paymentForm.markAsUntouched();
      this.triggerEditData.next(res.bill.billData);
      this.triggereEditProductData.next(res.bill.billData.items);
      this.triggereEditPaymentData.next(res.bill.billData.receiptDetails);
      this.triggerAdvancePayment.next(res.bill.billData.accountId);
      this.triggerisVatIncluded.next(res.bill.billData.isVatIncluded);
    });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.productItems = new Array<ProductDetailsModel>();
      this.receiptItems = new Array<PaymentDetailsModel>();

      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.productDetails.productDetailArray?.getRawValue().forEach((x) => {
        this.productItems.push({
          id: x.id,
          productName: x.productName.id === undefined ? x.productName : '',
          productId:
            x.productName.id === undefined
              ? (Guid.EMPTY as unknown as Guid)
              : x.productName.id,
          description: x.description,
          quantity: +x.qty,
          price: +x.price,
          vatRateId: x.vatRate === -1 ? null : x.vatRate,
          vatAmount: +x.vatAmount,
          totalAmount: +x.amount,
        });
      });

      this.receiptDetails.paymentArray?.getRawValue().forEach((x) => {
        const attachmentList: any = [];
        if (
          x.attachment !== null &&
          x.attachment !== undefined &&
          x.attachment !== '' &&
          x.attachment.length > 0
        ) {
          x.attachment.forEach((element) => {
            attachmentList.push(element);
          });
        }
        if (x.accountId !== '' && x.amount !== '') {
          this.receiptItems.push({
            id: x.id,
            receiptId: x.receiptId,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            amount: x.amount,
            postingAccountId: x.accountId,
            attachment: attachmentList,
          });
        }
      });

      this.billData = {
        id: this.billId,
        entryNumber: this.billDetails.billForm.controls.billNo.value,
        entryDate: this.datepipe
          .transform(
            this.billDetails.billForm.controls.billDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        dueDate: this.datepipe
          .transform(
            this.billDetails.billForm.controls.dueDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        currencyId: this.billDetails.billForm.controls.currency.value,
        accountId: this.billDetails.billForm.controls.supplierId.value,
        note: this.billDetails.billForm.controls.notes.value,
        bookAccountId: this.billDetails.billForm.controls.billType.value.id,
        fundId: this.billDetails.billForm.controls.fundName.value,
        activityId:
          this.billDetails.billForm.controls.activity.value === ''
            ? null
            : this.billDetails.billForm.controls.activity.value,
        items: this.productItems,
        receiptDetails: this.receiptItems,
        attachment: filedata,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
        isManualBank: this.isManualBank,
        advanceReceiptAmount:
          +this.advancePaymentDetails.advancePaymentDetailForm.controls
            .allocateAdvance.value,
        isVatIncluded: this.billDetails.billForm.controls.isVatInclude.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.billId,
      isExit,
      Modules.Bills,
      RoutingPath.AddBills
    );
  }

  onSave(isExit: boolean): void {
    if (this.billDetails.billForm.invalid) {
      this.commonService.addValidation(
        this.billDetails.billForm,
        this.renderer
      );
    } else if (
      !this.isFromBankImport &&
      this.receiptDetails.paymentArray.invalid
    ) {
      this.receiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (
      !this.isFromBankImport &&
      this.productDetails.totalAmount < this.receiptDetails.totalReciptAmount
    ) {
      this.receiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any)
          .values(x.controls)
          .forEach((c) => c.markAsTouched(false));
        x.controls.amount.status = 'INVALID';
      });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (this.productDetails.productDetailArray.invalid) {
      this.productDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else if (this.advancePaymentDetails.advancePaymentDetailForm.invalid) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      this.advancePaymentDetails.advancePaymentDetailForm.controls
        .allocateAdvance.value > this.productDetails.totalAmount
    ) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.billData) {
          this.store
            .dispatch(new CreateBill(this.billData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit && !this.isFromBankImport) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.billId)) {
      this.editBill();
    } else {
      this.billId = Guid.EMPTY as unknown as Guid;
      this.billDetails.ngOnInit();
      this.productDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
      this.receiptDetails.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  supplierClickChange(event) {
    this.triggerAdvancePayment.next(event);
  }

  onHasAdvanceAmountUpdated(updatedValue: boolean): void {
    this.hasAdvanceAmount = updatedValue;
  }
}
