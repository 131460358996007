import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ProductDetailsModel, QuotationModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateQuotation,
  GetDataByQuotationId,
  GetQuotationNumber,
  MenuState,
} from '@app/core/Store';
import { AddQuotationComponent } from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { ProductDetailsComponent } from '@app/modules/transactions';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss'],
})
export class QuotationComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  moduleId = Modules.Quotation;
  moduleName = ModuleName.Quotation;

  quotationId = Guid.EMPTY as unknown as Guid;
  quotationData: QuotationModel;
  productItems: Array<ProductDetailsModel>;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddQuotationComponent, { static: true })
  quotationDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  productDetails;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();

  amount = 0;
  balanceAmount = 0;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.quotationId = atob(params.get('id')!) as unknown as Guid;
          this.editQuotation();
        } else {
          this.getQuotationNumber();
        }
      }
    );
  }

  editQuotation(): void {
    this.store
      .dispatch(new GetDataByQuotationId(this.quotationId))
      .subscribe((res) => {
        this.quotationDetails.quotationForm.markAsUntouched();
        this.productDetails.formProductDetail.markAsUntouched();
        this.triggerEditData.next(res.quotation.quotationData);
        this.triggereEditProductData.next(
          res.quotation.quotationData.quotationItems
        );
        this.triggerisVatIncluded.next(
          res.quotation.quotationData.isVatIncluded
        );
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  reloadAutoNumberGenerator(): void {
    this.getQuotationNumber();
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  getQuotationNumber(): void {
    this.store.dispatch(new GetQuotationNumber()).subscribe((res) => {
      res.quotation.quotationNo !== null
        ? this.quotationDetails.quotationForm.controls.quotationNo.disable()
        : this.quotationDetails.quotationForm.controls.quotationNo.enable();
      this.quotationDetails.quotationForm.controls.quotationNo.setValue(
        res.quotation.quotationNo
      );
    });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.productItems = new Array<ProductDetailsModel>();

      this.productDetails.productDetailArray?.getRawValue().forEach((x) => {
        this.productItems.push({
          id: x.id,
          productName: x.productName.id === undefined ? x.productName : '',
          productId:
            x.productName.id === undefined
              ? (Guid.EMPTY as unknown as Guid)
              : x.productName.id,
          description: x.description,
          quantity: +x.qty,
          price: +x.price,
          vatRateId: x.vatRate === -1 ? null : x.vatRate,
          vatAmount: +x.vatAmount,
          totalAmount: +x.amount,
        });
      });

      this.quotationData = {
        id: this.quotationId,
        entryNumber:
          this.quotationDetails.quotationForm.controls.quotationNo.value,
        entryDate: this.datepipe
          .transform(
            this.quotationDetails.quotationForm.controls.entryDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        currencyId: this.quotationDetails.quotationForm.controls.currency.value,
        accountId:
          this.quotationDetails.quotationForm.controls.customerName.value,
        note: this.quotationDetails.quotationForm.controls.note.value,
        quotationItems: this.productItems,
        bookAccountId:
          this.quotationDetails.quotationForm.controls.invoiceType.value.id,
        fundId: this.quotationDetails.quotationForm.controls.fundName.value,
        isVatIncluded:
          this.quotationDetails.quotationForm.controls.isVatInclude.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.quotationId,
      isExit,
      Modules.Quotation,
      RoutingPath.AddQuotation
    );
  }

  onSave(isExit: boolean): void {
    if (this.quotationDetails.quotationForm.invalid) {
      this.commonService.addValidation(
        this.quotationDetails.quotationForm,
        this.renderer
      );
    } else if (this.productDetails.productDetailArray.invalid) {
      this.productDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (this.dataSubmit()) {
        if (this.quotationData) {
          this.store
            .dispatch(new CreateQuotation(this.quotationData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.quotationId)) {
      this.editQuotation();
    } else {
      this.quotationId = Guid.EMPTY as unknown as Guid;
      this.quotationDetails.ngOnInit();
      this.productDetails.ngOnInit();
      this.getQuotationNumber();
    }
  }

  isVatIncludedClick(event): void {
    this.triggerisVatIncluded.next(event);
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
