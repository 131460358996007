import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  AccountEntity,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
  OpeningBalanceExplain,
} from '@app/core/Enum';
import { OpeningBalance, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  CreateAccount,
  CreateContact,
  GetExplainListBasedOnAccountingPeriod,
  GetNonStandardAccountList,
  GetOpeningBalanceExplainList,
  SaveOpeningBalanceExplain,
} from '@app/core/Store';
import { CleanAllLinesComponent, QuickAddComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-opening-balance-explain-contract',
  templateUrl: './opening-balance-explain-contract.component.html',
  styleUrls: ['./opening-balance-explain-contract.component.scss'],
})
export class OpeningBalanceExplainContractComponent implements OnInit {
  formOpeningBalanceExplain: UntypedFormGroup;
  openingBalanceExplainArray: any;
  explainContractList: any[] = [];
  openingBalanceExplainData: OpeningBalance[] = [];
  tableDataSource: MatTableDataSource<AbstractControl>;
  totalExplainedAmount = 0;
  displayOpeningBalanceExplainColumns: string[] = [
    'srNo',
    'account',
    'unrestrictedDebit',
    'unrestrictedCredit',
    'restrictedDebit',
    'restrictedCredit',
    'endowmentDebit',
    'endowmentCredit',
    'closeButton',
  ];
  customerList: SideListModel[];

  openingBalanceExplain = OpeningBalanceExplain;
  maxLength = MaxLength;
  accountEntity = AccountEntity;

  headerText = '';

  constructor(
    public dialog: MatDialog,
    private explainContactPopup: MatDialogRef<OpeningBalanceExplainContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {
    if (this.data.accountTypeId === AccountEntity.Customer) {
      this.headerText = 'Explain Customer';
    } else {
      this.headerText = 'Explain Supplier';
    }
  }

  ngOnInit(): void {
    this.setOpeningBalanceExplainForm(true);
    this.getName();
    if (!this.data.indexData.isExplainSave) {
      this.getExplainListBasedOnAccountingPeriod();
    }
  }

  getName(): void {
    const entityId = new Array<number>();
    if (this.data.accountTypeId === AccountEntity.Customer) {
      entityId.push(AccountEntity.Customer);
    } else {
      entityId.push(AccountEntity.Supplier);
    }

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .subscribe((res) => {
        this.customerList = this.store.selectSnapshot(CommonState.accountList);
      });
  }

  setOpeningBalanceExplainForm(addNewRow: boolean): void {
    this.formOpeningBalanceExplain = new FormGroup({
      openingBalanceExplainArray: new UntypedFormArray([]),
    });

    this.openingBalanceExplainArray = this.formOpeningBalanceExplain.get(
      'openingBalanceExplainArray'
    ) as UntypedFormArray;

    this.setDataSource(this.openingBalanceExplainArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.openingBalanceExplainArray = this.formOpeningBalanceExplain.get(
      'openingBalanceExplainArray'
    ) as UntypedFormArray;

    this.openingBalanceExplainArray.push(this.setForm());

    this.setDataSource(this.openingBalanceExplainArray);
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Customers,
          headerText: `Add ${ModuleName.Customers}`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getName();
        }
      });
  }

  gotoAddSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Suppliers,
          headerText: `Add ${ModuleName.Suppliers}`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getName();
        }
      });
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      account: new FormControl('', [Validators.required]),
      unrestrictedDebit: new FormControl<number | null>(
        {
          value: null,
          disabled:
            this.data.indexData.unrestrictedDebit !== null &&
            this.data.indexData.unrestrictedDebit !== undefined
              ? false
              : true,
        },
        [Validators.required]
      ),
      unrestrictedCredit: new FormControl<number | null>(
        {
          value: null,
          disabled:
            this.data.indexData.unrestrictedCredit !== null &&
            this.data.indexData.unrestrictedCredit !== undefined
              ? false
              : true,
        },
        [Validators.required]
      ),
      restrictedDebit: new FormControl<number | null>(
        {
          value: null,
          disabled:
            this.data.indexData.restrictedDebit !== null &&
            this.data.indexData.restrictedDebit !== undefined
              ? false
              : true,
        },
        [Validators.required]
      ),
      restrictedCredit: new FormControl<number | null>(
        {
          value: null,
          disabled:
            this.data.indexData.restrictedCredit !== null &&
            this.data.indexData.restrictedCredit !== undefined
              ? false
              : true,
        },
        [Validators.required]
      ),
      endowmentDebit: new FormControl<number | null>(
        {
          value: null,
          disabled:
            this.data.indexData.endowmentDebit !== null &&
            this.data.indexData.endowmentDebit !== undefined
              ? false
              : true,
        },
        [Validators.required]
      ),
      endowmentCredit: new FormControl<number | null>(
        {
          value: null,
          disabled:
            this.data.indexData.endowmentCredit !== null &&
            this.data.indexData.endowmentCredit !== undefined
              ? false
              : true,
        },
        [Validators.required]
      ),
    });
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  onDeleteOpeningBalanceExplain(index: number): void {
    this.openingBalanceExplainArray = this.formOpeningBalanceExplain.get(
      'openingBalanceExplainArray'
    ) as UntypedFormArray;

    this.openingBalanceExplainArray.removeAt(index);
    this.setDataSource(this.openingBalanceExplainArray);

    this.totalExplainedAmount = 0;
    this.openingBalanceExplainArray?.getRawValue().forEach((x) => {
      this.totalExplainedAmount +=
        +x.unrestrictedDebit +
        +x.unrestrictedCredit +
        +x.restrictedDebit +
        +x.restrictedCredit +
        +x.endowmentDebit +
        +x.endowmentCredit;
    });
  }

  getExplainListBasedOnAccountingPeriod(): void {
    this.store
      .dispatch(new GetOpeningBalanceExplainList(this.data.accountTypeId))
      .subscribe((res) => {
        this.explainContractList = res.account.explainList;
        if (res.account.explainList.length > 0) {
          this.editOpeningBalanceExplain(res.account.explainList);
        }
      });
  }

  editOpeningBalanceExplain(data: any): void {
    this.openingBalanceExplainArray = this.formOpeningBalanceExplain.get(
      'openingBalanceExplainArray'
    ) as UntypedFormArray;

    this.openingBalanceExplainArray.clear();

    let account: any;
    data.forEach((item, i) => {
      this.openingBalanceExplainArray.push(this.buildOrderItemsForm(item));
      this.setFormValidity(i);
    });
    this.setDataSource(this.openingBalanceExplainArray);
    this.spinner.hide();
  }
  setFormValidity(index: number): void {
    this.openingBalanceExplainArray.controls[index].clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('account')
      ?.setValidators([
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]);

    this.data.indexData.unrestrictedDebit !== null &&
    this.data.indexData.unrestrictedDebit !== undefined
      ? this.openingBalanceExplainArray.controls[index]
          .get('unrestrictedDebit')
          .enable()
      : this.openingBalanceExplainArray.controls[index]
          .get('unrestrictedDebit')
          .disable();

    this.data.indexData.unrestrictedCredit !== null &&
    this.data.indexData.unrestrictedCredit !== undefined
      ? this.openingBalanceExplainArray.controls[index]
          .get('unrestrictedCredit')
          .enable()
      : this.openingBalanceExplainArray.controls[index]
          .get('unrestrictedCredit')
          .disable();

    this.data.indexData.restrictedDebit !== null &&
    this.data.indexData.restrictedDebit !== undefined
      ? this.openingBalanceExplainArray.controls[index]
          .get('restrictedDebit')
          .enable()
      : this.openingBalanceExplainArray.controls[index]
          .get('restrictedDebit')
          .disable();

    this.data.indexData.restrictedCredit !== null &&
    this.data.indexData.restrictedCredit !== undefined
      ? this.openingBalanceExplainArray.controls[index]
          .get('restrictedCredit')
          .enable()
      : this.openingBalanceExplainArray.controls[index]
          .get('restrictedCredit')
          .disable();

    this.data.indexData.endowmentDebit !== null &&
    this.data.indexData.endowmentDebit !== undefined
      ? this.openingBalanceExplainArray.controls[index]
          .get('endowmentDebit')
          .enable()
      : this.openingBalanceExplainArray.controls[index]
          .get('endowmentDebit')
          .disable();

    this.data.indexData.endowmentCredit !== null &&
    this.data.indexData.endowmentCredit !== undefined
      ? this.openingBalanceExplainArray.controls[index]
          .get('endowmentCredit')
          .enable()
      : this.openingBalanceExplainArray.controls[index]
          .get('endowmentCredit')
          .disable();

    this.openingBalanceExplainArray.controls[index].updateValueAndValidity();
  }

  calAmount(index: number, type: number): void {
    setTimeout(() => {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      this.totalExplainedAmount = 0;
      this.openingBalanceExplainArray?.getRawValue().forEach((x) => {
        this.totalExplainedAmount +=
          +x.unrestrictedDebit +
          +x.unrestrictedCredit +
          +x.restrictedDebit +
          +x.restrictedCredit +
          +x.endowmentDebit +
          +x.endowmentCredit;
      });

      if (this.totalExplainedAmount > this.data.totalOpeningBalance) {
        if (type === OpeningBalanceExplain.UnrestrictedDebit) {
          formArray.controls[index].get('unrestrictedDebit')?.setValue(null);
        } else if (type === OpeningBalanceExplain.UnrestrictedCredit) {
          formArray.controls[index].get('unrestrictedCredit')?.setValue(null);
        } else if (type === OpeningBalanceExplain.RestrictedDebit) {
          formArray.controls[index].get('restrictedDebit')?.setValue(null);
        } else if (type === OpeningBalanceExplain.RestrictedCredit) {
          formArray.controls[index].get('restrictedCredit')?.setValue(null);
        } else if (type === OpeningBalanceExplain.EndowmentDebit) {
          formArray.controls[index].get('endowmentDebit')?.setValue(null);
        } else if (type === OpeningBalanceExplain.EndowmentCredit) {
          formArray.controls[index].get('endowmentCredit')?.setValue(null);
        }
        formArray.controls[index].updateValueAndValidity();
        this.commonService.onFailure(
          NotificationTextMessage.explainOpeningBalance
        );
      }
    }, 200);
  }

  buildOrderItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      account: item.accountId,
      unrestrictedDebit:
        item.unrestrictedDebit === 0 ? null : item.unrestrictedDebit,
      unrestrictedCredit:
        item.unrestrictedCredit === 0 ? null : item.unrestrictedCredit,
      restrictedDebit: item.restrictedDebit === 0 ? null : item.restrictedDebit,
      restrictedCredit:
        item.restrictedCredit === 0 ? null : item.restrictedCredit,
      endowmentDebit: item.endowmentDebit === 0 ? null : item.endowmentDebit,
      endowmentCredit: item.endowmentCredit === 0 ? null : item.endowmentCredit,
    });
  }

  addNewRow(): void {
    if (this.totalExplainedAmount >= this.data.totalOpeningBalance) {
      this.commonService.onFailure(
        NotificationTextMessage.explainOpeningBalance
      );
      return;
    }
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formOpeningBalanceExplain.get(
            'openingBalanceExplainArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i].get('unrestrictedDebit')?.setValue(null);
            formArray.controls[i].get('unrestrictedCredit')?.setValue(null);
            formArray.controls[i].get('restrictedDebit')?.setValue(null);
            formArray.controls[i].get('restrictedCredit')?.setValue(null);
            formArray.controls[i].get('endowmentDebit')?.setValue(null);
            formArray.controls[i].get('endowmentCredit')?.setValue(null);
            formArray.controls[i].get('account')?.setValue('');
            formArray.controls[i].get('account')?.updateValueAndValidity();
            this.resetFormValidation(i);
          }
        }
      });
  }

  onCancel(): void {
    this.explainContactPopup.close();
  }

  dataSubmit(): void {
    this.openingBalanceExplainArray?.getRawValue().forEach((x) => {
      const param: any = {
        id: x.id,
        accountId: x.account,
        unrestrictedDebit: +x.unrestrictedDebit,
        unrestrictedCredit: +x.unrestrictedCredit,
        restrictedDebit: +x.restrictedDebit,
        restrictedCredit: +x.restrictedCredit,
        endowmentDebit: +x.endowmentDebit,
        endowmentCredit: +x.endowmentCredit,
        accountingPeriodId: this.data.accountingPeriodId,
        date: this.data.accountingDate,
      };
      this.openingBalanceExplainData.push(param);
    });
  }

  unrestrictedCreditChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('unrestrictedDebit')?.setValue(null);
      formArray.controls[index].get('unrestrictedDebit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);

      formArray.controls[index].updateValueAndValidity();
      this.resetFormValidation(index, OpeningBalanceExplain.UnrestrictedCredit);
    }
  }

  unrestrictedDebitChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('unrestrictedCredit')?.setValue(null);
      formArray.controls[index].get('unrestrictedCredit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      formArray.controls[index].updateValueAndValidity();
      this.resetFormValidation(index, OpeningBalanceExplain.UnrestrictedDebit);
    }
  }

  restrictedCreditChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('restrictedDebit')?.setValue(null);
      formArray.controls[index].get('restrictedDebit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      formArray.controls[index].updateValueAndValidity();
      this.resetFormValidation(index, OpeningBalanceExplain.RestrictedCredit);
    }
  }

  restrictedDebitChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('restrictedCredit')?.setValue(null);
      formArray.controls[index].get('restrictedCredit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      formArray.controls[index].updateValueAndValidity();
      this.resetFormValidation(index, OpeningBalanceExplain.RestrictedDebit);
    }
  }

  endowmentCreditChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('endowmentDebit')?.setValue(null);
      formArray.controls[index].get('endowmentDebit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      formArray.controls[index].updateValueAndValidity();
      this.resetFormValidation(index, OpeningBalanceExplain.EndowmentCredit);
    }
  }

  endowmentDebitChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('endowmentCredit')?.setValue(null);
      formArray.controls[index].get('endowmentCredit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      formArray.controls[index].updateValueAndValidity();
      this.resetFormValidation(index, OpeningBalanceExplain.EndowmentDebit);
    }
  }

  resetFormValidation(index: number, AmountTypeId?: number): void {
    this.openingBalanceExplainArray.controls[index].clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('account')
      ?.clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('unrestrictedDebit')
      ?.clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('unrestrictedCredit')
      ?.clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('restrictedDebit')
      ?.clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('restrictedCredit')
      ?.clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('endowmentDebit')
      ?.clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('endowmentCredit')
      ?.clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('account')
      ?.setValidators([
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]);

    switch (AmountTypeId) {
      case OpeningBalanceExplain.EndowmentCredit:
        this.openingBalanceExplainArray.controls[index]
          .get('endowmentCredit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.EndowmentDebit:
        this.openingBalanceExplainArray.controls[index]
          .get('endowmentDebit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.RestrictedCredit:
        this.openingBalanceExplainArray.controls[index]
          .get('restrictedCredit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.RestrictedDebit:
        this.openingBalanceExplainArray.controls[index]
          .get('restrictedDebit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.UnrestrictedCredit:
        this.openingBalanceExplainArray.controls[index]
          .get('unrestrictedCredit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.UnrestrictedDebit:
        this.openingBalanceExplainArray.controls[index]
          .get('unrestrictedDebit')
          ?.setValidators(Validators.required);
        break;
      default:
        this.openingBalanceExplainArray.controls[index]
          .get('endowmentCredit')
          ?.setValidators(Validators.required);
        this.openingBalanceExplainArray.controls[index]
          .get('endowmentDebit')
          ?.setValidators(Validators.required);
        this.openingBalanceExplainArray.controls[index]
          .get('restrictedCredit')
          ?.setValidators(Validators.required);
        this.openingBalanceExplainArray.controls[index]
          .get('restrictedDebit')
          ?.setValidators(Validators.required);
        this.openingBalanceExplainArray.controls[index]
          .get('unrestrictedCredit')
          ?.setValidators(Validators.required);
        this.openingBalanceExplainArray.controls[index]
          .get('unrestrictedDebit')
          ?.setValidators(Validators.required);
        break;
    }

    this.openingBalanceExplainArray.controls[index]
      .get('unrestrictedDebit')
      ?.updateValueAndValidity();
    this.openingBalanceExplainArray.controls[index]
      .get('unrestrictedCredit')
      ?.updateValueAndValidity();
    this.openingBalanceExplainArray.controls[index]
      .get('restrictedDebit')
      ?.updateValueAndValidity();
    this.openingBalanceExplainArray.controls[index]
      .get('restrictedCredit')
      ?.updateValueAndValidity();
    this.openingBalanceExplainArray.controls[index]
      .get('endowmentDebit')
      ?.updateValueAndValidity();
    this.openingBalanceExplainArray.controls[index]
      .get('endowmentCredit')
      ?.updateValueAndValidity();
    this.openingBalanceExplainArray.controls[index]
      .get('account')
      ?.updateValueAndValidity();
    this.openingBalanceExplainArray.controls[index].updateValueAndValidity();
  }

  onSave(): void {
    if (this.totalExplainedAmount === 0) {
      this.totalExplainedAmount = 0;
      this.openingBalanceExplainArray?.getRawValue().forEach((x) => {
        this.totalExplainedAmount +=
          +x.unrestrictedDebit +
          +x.unrestrictedCredit +
          +x.restrictedDebit +
          +x.restrictedCredit +
          +x.endowmentDebit +
          +x.endowmentCredit;
      });
    }
    if (this.formOpeningBalanceExplain.invalid) {
      this.openingBalanceExplainArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
          this.commonService.onFailure(NotificationTextMessage.blankLineError);
        });
      });
      return;
    } else if (this.totalExplainedAmount !== this.data.totalOpeningBalance) {
      this.commonService.onFailure(
        NotificationTextMessage.explainOpeningBalance
      );
      return;
    }
    this.spinner.show();
    this.dataSubmit();
    this.store
      .dispatch(
        new SaveOpeningBalanceExplain(
          this.openingBalanceExplainData,
          this.data.accountTypeId
        )
      )
      .subscribe((res) => {
        this.spinner.hide();
        if (res.openingBalance.isOpeningBalanceExplain) {
          this.commonService.onSucess(NotificationTextMessage.successMessage);
          const data = {
            sumOfAllDrCr: this.getSumOfAllDrCr(),
            isExplainSave: true,
            explainList: this.openingBalanceExplainData,
          };
          this.explainContactPopup.close(data);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  getSumOfAllDrCr(): number {
    let sumOfAllDrCr = 0;

    this.openingBalanceExplainData.forEach((element) => {
      let total =
        +element.endowmentCredit! +
        +element.endowmentDebit! +
        +element.restrictedCredit! +
        +element.restrictedDebit! +
        +element.unrestrictedCredit! +
        +element.unrestrictedDebit!;

      sumOfAllDrCr = +total;
    });

    return sumOfAllDrCr;
  }
}
