import {
  FixedAssetDisposeModel,
  FixedAssetExplainModel,
  MainListParameters,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetFixedAssetRegisterList {
  static readonly type = '[fixedAssetRegister] Get FixedAsset Register List ';
}

export class GetFixedAssetRegisterDataList {
  static readonly type =
    '[fixedAssetRegister] Get FixedAsset Register Data List';

  constructor(public queryParams: MainListParameters) {}
}

export class SaveDepreciation {
  static readonly type = '[fixedAssetRegister] Save Depreciation ';

  constructor(public fixedAssetDepriciationData: any[]) {}
}

export class GetDepreciationTransaction {
  static readonly type = '[fixedAssetRegister] Get Depreciation Transaction ';

  constructor(public queryParams: any, public id: any) {}
}

export class CreateFixedAssetExplain {
  static readonly type = '[fixedAssetRegister] Create fuFixed Asset Explain';

  constructor(public fixedAssetExplain: FixedAssetExplainModel) {}
}

export class GetDataByAssetDetailId {
  static readonly type = '[fixedAssetRegister] Get Data By Asset Detail Id';

  constructor(public assetDetailId: Guid) {}
}

export class GetFixedAssetDispose {
  static readonly type = '[fixedAssetRegister] Get FixedAsset Dispose';

  constructor(public disposeId: Guid) {}
}

export class GetDepreciationChargeByDate {
  static readonly type = '[fixedAssetRegister] Get Depreciation Charge';

  constructor(public disposeId: Guid, public date) {}
}

export class CreateFixedAssetDispose {
  static readonly type = '[fixedAssetRegister] Create FixedAsset Dispose';

  constructor(public fixedAssetDispose: FixedAssetDisposeModel) {}
}

export class ActiveDisposeId {
  static readonly type = '[fixedAssetRegister] Active Dispose Id';

  constructor(public activeDisposeId: Guid) {}
}

export class GetDepreciationRollBackTransaction {
  static readonly type =
    '[fixedAssetRegister] Get Depreciation RollBack Transaction ';

  constructor(public id: any) {}
}
