<div class="dash-row">
  <div class="dash-col">
    <apx-chart
      [series]="DonationByFundOptions.series"
      [chart]="DonationByFundOptions.chart"
      [dataLabels]="DonationByFundOptions.dataLabels"
      [plotOptions]="DonationByFundOptions.plotOptions"
      [yaxis]="DonationByFundOptions.yaxis"
      [xaxis]="DonationByFundOptions.xaxis"
      [subtitle]="DonationByFundOptions.subtitle"
      [colors]="DonationByFundOptions.colors"
      [states]="DonationByFundOptions.states"
      [title]="DonationByFundOptions.title"
      [tooltip]="DonationByFundOptions.tooltip"
    ></apx-chart>
  </div>
  <div class="dash-col">
    <apx-chart
      [series]="chartQuarterOptions.series"
      [chart]="chartQuarterOptions.chart"
      [legend]="chartQuarterOptions.legend"
      [plotOptions]="chartQuarterOptions.plotOptions"
      [yaxis]="chartQuarterOptions.yaxis"
      [xaxis]="chartQuarterOptions.xaxis"
      [grid]="chartQuarterOptions.grid"
      [title]="chartQuarterOptions.title"
      [dataLabels]="chartQuarterOptions.dataLabels"
      [tooltip]="chartQuarterOptions.tooltip"
    ></apx-chart>
  </div>
</div>
