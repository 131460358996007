<div class="dash-row d-flex justify-content-center">
  <div class="dash-col" *ngIf="!isNoRecord && isloadData">
    <apx-chart
      [series]="FundBreakdownOptions?.series!"
      [chart]="FundBreakdownOptions?.chart!"
      [dataLabels]="FundBreakdownOptions?.dataLabels!"
      [plotOptions]="FundBreakdownOptions?.plotOptions!"
      [yaxis]="FundBreakdownOptions?.yaxis!"
      [xaxis]="FundBreakdownOptions?.xaxis!"
      [subtitle]="FundBreakdownOptions?.subtitle!"
      [colors]="FundBreakdownOptions?.colors!"
      [states]="FundBreakdownOptions?.states!"
      [title]="FundBreakdownOptions?.title!"
      [tooltip]="FundBreakdownOptions?.tooltip!"
      [responsive]="FundBreakdownOptions?.responsive!"
    ></apx-chart>
  </div>
  <div class="dash-col" *ngIf="!isNoRecord && isloadData">
    <apx-chart
      [series]="chartQuarterOptions?.series!"
      [chart]="chartQuarterOptions?.chart!"
      [legend]="chartQuarterOptions?.legend!"
      [plotOptions]="chartQuarterOptions?.plotOptions!"
      [yaxis]="chartQuarterOptions?.yaxis!"
      [xaxis]="chartQuarterOptions?.xaxis!"
      [grid]="chartQuarterOptions?.grid!"
      [title]="chartQuarterOptions?.title!"
      [tooltip]="chartQuarterOptions?.tooltip!"
      [dataLabels]="chartQuarterOptions?.dataLabels!"
      [colors]="chartQuarterOptions?.colors!"
    ></apx-chart>
  </div>
</div>

<div class="norecord-chart" *ngIf="isNoRecord && isloadData">
  <img src="assets/images/column-icon.svg" alt="Bar Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
