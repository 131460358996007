<div class="content-body">
  <div class="content-body pt-0">
    <ul *ngFor="let el of emailList">
      <li>
        <mat-checkbox [(ngModel)]="el.isActive" (change)="onCheckBoxChanges()">
          <span [innerHTML]="el.name"></span>
        </mat-checkbox>
      </li>
    </ul>
  </div>
</div>
