import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  AccountingMethod,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';

import {
  ChartOfAccountListParam,
  Currency,
  GlobalComponent,
  GroupListModel,
  MenuModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  AccountState,
  CommonState,
  CreateAccount,
  CreateActivity,
  CreateContact,
  CreateFund,
  DonationState,
  GetActivityList,
  GetCurrencyList,
  GetCustomBankAccountList,
  GetDataByAccountId,
  GetDataByActivityId,
  GetDataByFundId,
  GetFundNameList,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetNonStandardAccountList,
  MenuState,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss'],
})
export class AddIncomeInvoiceComponent implements OnInit, OnDestroy {
  disabledEditButton = true;
  disabledFundEditButton = true;
  disabledActivityEditButton = true;

  invoiceForm: FormGroup;
  maxLength = MaxLength;

  currencyList: Currency[];
  customerList: SideListModel[];
  activityList: SideListModel[];
  bankList: SideListModel[];
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];

  fundNameList: any;
  advancePayment: any;
  isVatInclude = false;

  @Input() triggerEditData: Observable<any>;
  @Output() maxDate = new EventEmitter<any>();
  @Output()
  readonly customerClick = new EventEmitter<any>();

  @Output()
  readonly isVatIncludedClick = new EventEmitter<any>();

  defaultCurrency: Guid;
  periodicDate: any;
  accountingMethodId: any;
  accountingMethod = AccountingMethod;
  notificationMessage = NotificationTextMessage;

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  isCustomerChangePermission: boolean = true;
  isFundChangePermission: boolean = true;
  isActivityChangePermission: boolean = true;
  isVatRegistered: any;

  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public dialog: MatDialog,
    public datepipe: DatePipe
  ) {}

  isDataReady = false;
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.accountingMethodId = this.globalComponent.getAccountingMethod();
    this.setForm();

    this.triggerEditData
      .pipe(
        switchMap((data) => {
          return this.loadDropdownValues().pipe(map(() => data));
        })
      )
      .subscribe((data) => {
        this.editInvoice(data);
      });

    this.invoiceForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.invoiceForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCustomerChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Customers
      );
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
      this.isActivityChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Activities
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  editInvoice(data): void {
    let account;
    this.accountGroupList.forEach((items) => {
      const accountId = items.listModels.find(
        (x: any) => x.id === data.bookAccountId
      );
      if (accountId !== null && accountId !== undefined) {
        account = accountId;
      }
    });
    this.disabledEditButton = false;
    this.disabledFundEditButton = false;
    if (this.activityList.length > 0) {
      this.disabledActivityEditButton = false;
    }
    this.invoiceForm.controls.invoiceNo.disable();
    this.invoiceForm.patchValue({
      invoiceType: account,
      fundName: data.fundId,
      invoiceNo: data.entryNumber,
      invoiceDate: data.entryDate,
      dueDate: data.dueDate,
      currency: data.currencyId,
      customerName: data.accountId,
      notes: data.note,
      activity: data.activityId,
      bankId: data.bankAccountId,
      isVatInclude: data.isVatIncluded,
    });
  }

  dateChange(): void {
    let startDate: any = new Date(
      this.invoiceForm.controls['invoiceDate'].value
    );

    startDate = this.datepipe.transform(startDate, 'yyyy-MM-dd')?.toString();

    this.maxDate.emit(startDate);

    let endDate: any = new Date(this.invoiceForm.controls['invoiceDate'].value);

    this.invoiceForm.controls['dueDate'].setValue(startDate);

    endDate = this.datepipe.transform(startDate, 'yyyy-MM-dd')?.toString();
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Customers,
          headerText: `Add ${ModuleName.Customers}`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getCustomer(id);
        }
      });
  }

  gotoEditCustomer(): void {
    if (this.customerList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Customers,
            headerText: `Edit ${ModuleName.Customers}`,
            id: this.invoiceForm.controls.customerName.value,
            saveActionName: CreateContact,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getCustomer(id);
          }
        });
    }
  }

  getOptionText(option) {
    return option.name;
  }

  public noWhitespaceValidator(control: any) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  setForm(): void {
    this.invoiceForm = new FormGroup({
      customerName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      invoiceNo: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      invoiceType: new FormControl('', [Validators.required]),
      invoiceDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
      ]),
      currency: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      fundName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      dueDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
      ]),
      notes: new FormControl(''),
      activity: new FormControl(''),
      bankId: new FormControl(''),
      isVatInclude: new FormControl(false),
    });

    this.loadDropdownValues().subscribe();
  }

  loadDropdownValues(): Observable<any> {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Income);

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: this.commonService.invoiceTypeList,
    };

    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    this.defaultCurrency = this.globalComponent.getDefaultCurrency();

    const getInvoiceType = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
    );
    const getCustomer = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );
    const getCurrency = this.store.dispatch(new GetCurrencyList());
    const getActivity = this.store.dispatch(new GetActivityList());
    const getFundNameList = this.store.dispatch(new GetFundNameList());
    const getbankInvoiceList = this.store.dispatch(
      new GetCustomBankAccountList()
    );

    return forkJoin([
      getInvoiceType,
      getCustomer,
      getCurrency,
      getActivity,
      getFundNameList,
      getbankInvoiceList,
    ]).pipe(
      tap((res) => {
        this.customerList = this.store.selectSnapshot(CommonState.accountList);
        this.currencyList = this.store.selectSnapshot(CommonState.getCurrency);
        this.activityList = this.store.selectSnapshot(
          DonationState.getActivity
        );
        this.bankList = this.store.selectSnapshot(
          CommonState.getCustomBankAccount
        );
        this.fundNameList = this.store.selectSnapshot(
          AccountState.getFundNameList
        );
        const accountGroupList = this.store.selectSnapshot(
          CommonState.accountGroupList
        );
        if (accountGroupList.length > 0) {
          this.accountGroupList = accountGroupList;
          this.tempAccountGroupList = this.accountGroupList;
        }

        if (this.fundNameList.length > 0) {
          this.disabledFundEditButton = false;
          this.invoiceForm.controls.fundName.setValue(this.fundNameList[0].id);
        }

        this.invoiceForm.controls.currency.setValue(this.defaultCurrency);
      })
    );
  }

  getFund(id): void {
    this.store.dispatch(new GetFundNameList()).subscribe((res) => {
      this.fundNameList = res.account.fundNameList;
      if (id !== null && id !== undefined) {
        this.invoiceForm.controls.fundName.setValue(id);
      }
    });
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledFundEditButton = false;
    } else {
      this.disabledFundEditButton = true;
    }
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledFundEditButton = false;
          this.getFund(id);
        }
      });
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.invoiceForm.controls.fundName.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }

  getActivity(id): void {
    this.store.dispatch(new GetActivityList()).subscribe((res) => {
      this.activityList = res.donation.activity;

      if (id !== null && id !== undefined) {
        this.invoiceForm.controls.activity.setValue(id);
      }
    });
  }

  activityChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledActivityEditButton = false;
    } else {
      this.disabledActivityEditButton = true;
    }
  }

  gotoAddActivity(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Activities,
          headerText: `Add ${ModuleName.Activities}`,
          saveActionName: CreateActivity,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledActivityEditButton = false;
          this.getActivity(id);
        }
      });
  }

  gotoEditActivity(): void {
    if (this.activityList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Activities,
            headerText: `Edit ${ModuleName.Activities}`,
            id: this.invoiceForm.controls.activity.value,
            saveActionName: CreateActivity,
            getActionName: GetDataByActivityId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getActivity(id);
          }
        });
    }
  }

  resetAccountList(): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(this.invoiceForm.controls.invoiceType.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  getCustomer(id?: Guid): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap((res) => {
          this.customerList = res.common.accountList;
          if (id !== null && id !== undefined) {
            this.invoiceForm.controls.customerName.setValue(id);
          }
        })
      )
      .subscribe();
  }

  customerChange(event: any): void {
    this.customerClick.emit(event);
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  onCheckBoxSelected(event: any): void {
    this.isVatInclude = event.checked;
    this.isVatIncludedClick.emit(this.isVatInclude);
  }
}
