import { Guid } from 'guid-typescript';

export class GiftAidDetails {
  id: Guid;
  startDate: string;
  endDate: string;
}

export class HMRCGatewayDetails {
  giftAidId: Guid;
  userName: string;
  password: string;
}

export class GiftAidSubmissionModel {
  id: Guid;
  fromDate: string;
  toDate: string;
  amount?: number;
  submittedCapium: boolean;
  submittedHMRC: boolean;
  isImported: boolean;
  giftAidDonations?: Array<GiftAidDonationModel>;
  importGiftAidDonations?: Array<ImportGiftAidDonationsModel>;
  submitGiftAidDetail?: HMRCGatewayDetails;
}

export class GiftAidDonationModel {
  id: Guid;
  giftAidId: Guid;
  reference: string;
  donationDate: string;
  donorName: string;
  donationAmount: number;
  depositTo: string;
  sponsorEvent: string;
  activity: string;
  isIncluded: boolean;
}

export class ImportGiftAidDonationsModel {
  donationDate: string;
  donorName: string;
  donationAmount: number;
  houseNumber: number;
  postCode: number;
  title: string;
}
