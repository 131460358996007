<div class="mat-dialog-wrapper">
  <mat-dialog-content>
    <div>
      <div class="control-section">
        <div class="default_title_bar e-de-ctn-title">
          <button
            title="Save this document."
            (click)="onSaveClick()"
            class="e-btn"
            type="button"
          >
            <mat-icon class="e-de-padding-right e-icon-left">save</mat-icon>
          </button>
          <button
            title="Download this document."
            (click)="onDownloadClick()"
            class="e-btn"
            type="button"
          >
            <mat-icon class="e-de-padding-right e-icon-left">download</mat-icon>
          </button>
          <button
            title="Print this document (Ctrl+P)."
            class="e-btn"
            (click)="onPrint()"
          >
            <mat-icon class="e-de-padding-right e-icon-left">print</mat-icon>
          </button>
          <button class="e-btn" title="Close" (click)="onCloseClick()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <ejs-documenteditorcontainer
          #documenteditor_default
          [enableToolbar]="true"
          [enablePrint]="true"
          [enableSfdtExport]="true"
          height="calc(100% - 40px)"
          [serviceUrl]="hostUrl"
          style="display: block"
        ></ejs-documenteditorcontainer>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions> </mat-dialog-actions>
</div>
