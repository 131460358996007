export enum ConfirmationType {
  Archive = 1,
  Delete = 2,
  Copy = 3,
  Mail = 4,
  Activate = 5,
  MergeTrailBalance = 6,
  TrailBalanceErrorList = 7,
  RefreshTrialBalance = 9,
}
