import { Guid } from 'guid-typescript';

export class GetUserDetailList {
  static readonly type = '[USER] Get User';

  constructor(public queryParams: any) {}
}

export class GetUserDataByUserId {
  static readonly type = '[USER] Get User Data By User Id';

  constructor(public userId: Guid) {}
}

export class SaveUserBasicInfo {
  static readonly type = '[USER] Save Basic Info';

  constructor(public user: any) {}
}

export class SaveCapacityPermission {
  static readonly type = '[USER] Save Capacity Permission';

  constructor(public user: any) {}
}

export class ExportUser {
  static readonly type = '[USER] Export User';

  constructor(public exportParams: any) {}
}

export class DeleteSelectedUser {
  static readonly type = '[USER] Delete Selected User';

  constructor(public userIds?: Array<Guid>) {}
}

export class GetUserList {
  static readonly type = '[USER] Get User List ';

  constructor(public queryParams: any) {}
}

export class CopyUser {
  static readonly type = '[USER] Copy Selected User';

  constructor(public userIds?: Array<Guid>) {}
}

export class ArchiveAndRestoreUser {
  static readonly type = '[USER] Archive and Restore Selected User';

  constructor(public userIds?: Array<Guid>, public isArchive?: boolean) {}
}

export class SetUserId {
  static readonly type = '[USER] Set User Id ';

  constructor(public userId: Guid) {}
}

export class GetAllUsers {
  static readonly type = '[USER] Get All Users';
}

//#region Profile
export class SetNotification {
  static readonly type = '[Profile] Set Notification';

  constructor(public profileNotfication: any) {}
}

export class GetNotification {
  static readonly type = '[Profile] Get Notification';

  constructor(public userId: Guid) {}
}

//#endregion

export class SetUserDefaultState {
  static readonly type = '[USER] Set User Default State';
}

export class GetUserPermissionsList {
  static readonly type = '[USER] Get User Permissions List in User';
}

export class InviteUser {
  static readonly type = '[USER] Invite User';

  constructor(public id: Guid) {}
}

export class SaveUser {
  static readonly type = '[USER] Save User';

  constructor(public user: any) {}
}

export class GetUsersByPermission {
  static readonly type = '[USER] Get Users By Permission';
}

export class GetUserCompanyDetails {
  static readonly type = '[USER] Get User Company Details';

  constructor(public queryParams: any) {}
}

export class SaveUserCompanies {
  static readonly type = '[USER] Save User Companies';

  constructor(public userId: Guid, public userCompanies: Array<any>) {}
}

export class DeleteUserCompanyByUserId {
  static readonly type = '[USER] Delete User Company By User Id';

  constructor(public userId: Guid) {}
}
