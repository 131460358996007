import { Injectable } from '@angular/core';
import {
  ChartOfAccountListModel,
  FixedAssetsModel,
  SideListModel,
} from '@app/core/Models';
import { FixedAssetsService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Guid } from 'guid-typescript';
import {
  ArchiveAndRestoreFixedAssets,
  CreateFixedAssets,
  DeleteFixedAssets,
  GetFixAssestValueByAssetTypeOverview,
  GetDataByFixedAssetsId,
  GetFixAssestProductServiceListOverview,
  GetFixAssestAgeingSummaryOverview,
  GetAllGroupByFixAssestAgeingSummary,
} from './fixed-assests.action';

export class FixedAssetsStateInfo {
  fixedAssetsData?: FixedAssetsModel;
  fixedAssets: Array<FixedAssetsModel>;
  fixedAssetsId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isFixedAssetsAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  fixAssestAgeingSummaryOverview?: any;
  fixAssestProductServiceListOverview?: any;
  fixAssestValueByAssetTypeOverview?: any;
  accountType?: ChartOfAccountListModel[];
}

@State<FixedAssetsStateInfo>({
  name: 'fixedAssets',
  defaults: {
    fixedAssets: [],
    fixedAssetsId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isFixedAssetsAdded: false,
  },
})
@Injectable()
export class FixedAssetsState {
  constructor(private fixedAssetsService: FixedAssetsService) {}

  @Selector()
  static isLastPage(state: FixedAssetsStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getFixedAssetsId(state: FixedAssetsStateInfo) {
    return state.fixedAssetsId;
  }

  @Action(CreateFixedAssets)
  createFixedAssets(
    { patchState }: StateContext<FixedAssetsStateInfo>,
    action: CreateFixedAssets
  ) {
    return this.fixedAssetsService.createFixedAssets(action.fixedAssets).pipe(
      tap((res) => {
        patchState({
          fixedAssetsId: res,
          isFixedAssetsAdded: true,
        });
      })
    );
  }

  @Action(GetDataByFixedAssetsId)
  getDataByFixedAssetsId(
    { patchState }: StateContext<FixedAssetsStateInfo>,
    action: GetDataByFixedAssetsId
  ) {
    return this.fixedAssetsService
      .getDataByFixedAssetsId(action.fixedAssetsId)
      .pipe(
        tap((res) => {
          patchState({
            fixedAssetsData: res,
          });
        })
      );
  }

  @Action(DeleteFixedAssets)
  deleteFixedAssets(
    { getState, setState }: StateContext<FixedAssetsStateInfo>,
    action: DeleteFixedAssets
  ) {
    return this.fixedAssetsService
      .deleteFixedAssets(action.fixedAssetsIds)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredfixedAssets = state.fixedAssets.filter(
            (item) =>
              !action.fixedAssetsIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.fixedAssetsIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          setState({
            ...state.fixedAssets,
            fixedAssets: filteredfixedAssets,
            sideListModel: filteredForSideList,
          });
        })
      );
  }

  @Action(ArchiveAndRestoreFixedAssets)
  archiveAndRestoreFixedAssets(
    { getState }: StateContext<FixedAssetsStateInfo>,
    action: ArchiveAndRestoreFixedAssets
  ) {
    return this.fixedAssetsService.archiveAndRestoreFixedAssets(
      action.fixedAssetsIds,
      action.isArchive
    );
  }

  @Action(GetFixAssestAgeingSummaryOverview)
  getFixAssestAgeingSummaryOverview(
    { patchState }: StateContext<FixedAssetsStateInfo>,
    action: GetFixAssestAgeingSummaryOverview
  ) {
    return this.fixedAssetsService
      .getFixAssestAgeingSummaryOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            fixAssestAgeingSummaryOverview: res,
          });
        })
      );
  }

  @Action(GetFixAssestProductServiceListOverview)
  getFixAssestProductServiceListOverview(
    { patchState }: StateContext<FixedAssetsStateInfo>,
    action: GetFixAssestProductServiceListOverview
  ) {
    return this.fixedAssetsService
      .getFixAssestProductServiceListOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            fixAssestProductServiceListOverview: res,
          });
        })
      );
  }

  @Action(GetFixAssestValueByAssetTypeOverview)
  getFixAssestValueByAssetTypeOverview(
    { patchState }: StateContext<FixedAssetsStateInfo>,
    action: GetFixAssestValueByAssetTypeOverview
  ) {
    return this.fixedAssetsService
      .getFixAssestValueByAssetTypeOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            fixAssestValueByAssetTypeOverview: res,
          });
        })
      );
  }

  @Action(GetAllGroupByFixAssestAgeingSummary)
  getAllGroupByFixAssestAgeingSummary(
    { patchState }: StateContext<FixedAssetsStateInfo>,
    action: GetAllGroupByFixAssestAgeingSummary
  ) {
    return this.fixedAssetsService
      .getAllGroupByFixAssestAgeingSummary(action.accountTypeId)
      .pipe(
        tap((res) => {
          patchState({
            accountType: res,
          });
        })
      );
  }
}
