import { Guid } from 'guid-typescript';

export class MenuModel {
  hasAddPermission: boolean;
  hasDeletePermission: boolean;
  hasEditPermission: boolean;
  id: number;
  icon: string;
  name: string;
  url: string;
  addUrl: string;
  subMenu: Array<MenuModel>;
  permissionId: number;
}

export class ProfileInfoModel {
  id: Guid;
  fullName: string;
  email: string;
  photo: string;
  businessId: Guid;
  themeColor: string;
  AuthorizedRole: number;
}
