import { DatePipe } from '@angular/common';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Modules,
  MTD,
  NotificationTextMessage,
  VatSettings,
} from '@app/core/Enum';
import { VATSettingsModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { CreateVatDetail, GetVatDetail, MenuState } from '@app/core/Store';
import { AddVatSettingsComponent } from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-vat-settings',
  templateUrl: './vat-settings.component.html',
  styleUrls: ['./vat-settings.component.scss'],
})
export class VatSettingsComponent implements OnInit {
  selectedTab = 0;
  moduleId = Modules.VatSettings;
  vatSettingsData: VATSettingsModel;
  vatSettingsId = Guid.EMPTY as unknown as Guid;
  triggerEditData: Subject<any> = new Subject<any>();

  @ViewChild(AddVatSettingsComponent, { static: true })
  vatSettingsDetails;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    public commonService: CommonService,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public cookieService: CookieService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.editVatSettingsDetails();
    const tabNum = this.cookieService.get('selectedTab');
    this.selectedTab = +tabNum ?? 0;
  }

  editVatSettingsDetails(): void {
    this.store.dispatch(new GetVatDetail()).subscribe((res) => {
      this.vatSettingsDetails.vatSettingsForm.markAsUntouched();
      this.triggerEditData.next(res.vatSettings.vatSettingsData);
    });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.vatSettingsData = {
        vatSchemeId:
          this.vatSettingsDetails.vatSettingsForm.controls.vatScheme.value,
        vatRegistrationDate:
          this.vatSettingsDetails.vatSettingsForm.controls.registrationDate
            .value,
        vatRegistrationNo:
          this.vatSettingsDetails.vatSettingsForm.controls.registrationNo.value,
        vatReturnTypeId:
          this.vatSettingsDetails.vatSettingsForm.controls.vatSubmitType.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.vatSettingsDetails.vatSettingsForm.invalid) {
      this.commonService.addValidation(
        this.vatSettingsDetails.vatSettingsForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateVatDetail(this.vatSettingsData))
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.onCancel();
                if (isExit) {
                  history.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  onTabClick(event: any): void {
    if (event.tab.textLabel === VatSettings.VatCodes) {
      this.cookieService.set('detailListId', MTD.VATCodes.toString());
    }
    this.selectedTab = event.index;
  }

  onCancel(): void {
    this.commonService.isInitialValueChange = false;
    this.ngOnInit();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
