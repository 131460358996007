export const environment = {
  production: false,
  apiUrl: 'https://charityapi.dev.capium.co.uk/',
  uiUrl: 'https://charity.dev.capium.co.uk',
  appDomain: 'charity.dev',
  stsServerUrl: 'https://identity.dev.capium.co.uk',
  identityLoginUrl: 'https://identity.dev.capium.co.uk/Account/Login',
  clientId: 'capium_timeandfee_angular',
  cdnUrl: 'https://cdn.dev.capium.co.uk/shared/',
  apiVersionUrl: 'https://charityapi.dev.capium.co.uk/api/v1/',
  apiVersionUrl_V2: 'https://charityapi.dev.capium.co.uk/api/v2/',
  communicationFrameworkApiUrl:
    'https://communicationframworkapi.dev.capium.co.uk/api',
  // apiVersionUrl_V2: 'https://localhost:7193/api/v2/',
  // apiVersionUrl: 'https://localhost:7193/api/v1/',
  identityDomain: 'identity.dev',
  platfrom: 'dev',
  domain: 'capium.co.uk',
  helpDeskUrl: 'https://capium.freshdesk.com/support/home',
};
