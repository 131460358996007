import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableInput]',
})
export class DisableInputControlDirective {
  @Input() set disableInput(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    if (this.ngControl.control !== undefined) this.ngControl.control![action]();
  }

  constructor(private ngControl: NgControl) {}
}
