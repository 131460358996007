export enum GroupNames {
  Income = 1,
  Expense = 2,
  FixedAssets_Intangibles = 3,
  FixedAssets_Tangibles = 4,
  FixedAssets_Heritage = 5,
  FixedAssets_Investments = 6,
  CurrentAssets_Stock = 7,
  CurrentAssets_Debtors = 8,
  CurrentAssets_Investments = 9,
  CurrentAssets_CashatBankAndHand = 10,
  CurrentLiabilities = 11,
  NonCurrentLiabilities = 12,
  ProvisionsforLiabilities = 13,
  Reserves = 14,
  Funds = 16,
}
