<div class="mat-dialog-wrapper">
  <h2 mat-dialog-title>{{ headerText }}</h2>
  <mat-dialog-content>
    <div class="info-alert">
      <span> Please save your opening balance before explaning</span>
    </div>
    <div class="form-field d-flex align-items-center gap-20 pt-1">
      <p class="white-space-nowrap m-0">Opening Balance</p>
      <mat-form-field floatLabel="never" class="w-300">
        <input
          matInput
          autocomplete="off"
          [(ngModel)]="data.totalOpeningBalance"
          disabled
        />
      </mat-form-field>
      <button
        *ngIf="this.data.accountTypeId === accountEntity.Customer"
        class="action-button primary-button"
        mat-button
        (click)="gotoAddCustomer()"
        disableRipple
      >
        <mat-icon>add</mat-icon>
        <span>Add Customer</span>
      </button>
      <button
        *ngIf="this.data.accountTypeId === accountEntity.Supplier"
        class="action-button primary-button"
        mat-button
        (click)="gotoAddSupplier()"
        disableRipple
      >
        <mat-icon>add</mat-icon>
        <span>Add Supplier</span>
      </button>
    </div>
    <div class="sidenav-table sidenav-table-input wp-100">
      <div class="mat-table-wrapper mb-10">
        <form [formGroup]="formOpeningBalanceExplain">
          <ng-container formArrayName="openingBalanceExplainArray">
            <table
              aria-describedby="account-list"
              mat-table
              [dataSource]="tableDataSource"
            >
              <ng-container matColumnDef="srNo">
                <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <div class="d-flex">
                    {{ i + 1 }}
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef class="w-300">
                  Account
                  <span class="text-danger-300">*</span>
                </th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="widthemail"
                >
                  <ng-select
                    #account
                    [disableOptionCentering]="true"
                    (focus)="account.open()"
                    (valueChange)="account.close()"
                    formControlName="account"
                    panelClass="mat-select-position"
                    required
                    appendTo="body"
                  >
                    <ng-option
                      class="clamp"
                      *ngFor="let option of customerList"
                      [value]="option.id"
                    >
                      <div class="clamp" title="{{ option.name }}">
                        {{ option.name }}
                      </div>
                    </ng-option>
                  </ng-select>
                </td>
              </ng-container>
              <ng-container matColumnDef="unrestrictedDebit">
                <th mat-header-cell *matHeaderCellDef>Debit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allowDecimal
                      matInput
                      [value]="element.unrestrictedDebit"
                      autocomplete="off"
                      formControlName="unrestrictedDebit"
                      (keypress)="
                        unrestrictedDebitChanges(i, $event);
                        calAmount(i, openingBalanceExplain.UnrestrictedDebit)
                      "
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="unrestrictedCredit">
                <th mat-header-cell *matHeaderCellDef>Credit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field>
                    <input
                      allowDecimal
                      matInput
                      [value]="element.unrestrictedCredit"
                      autocomplete="off"
                      formControlName="unrestrictedCredit"
                      (keypress)="
                        unrestrictedCreditChanges(i, $event);
                        calAmount(i, openingBalanceExplain.UnrestrictedCredit)
                      "
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="restrictedDebit">
                <th mat-header-cell *matHeaderCellDef>Debit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allowDecimal
                      matInput
                      [value]="element.restrictedDebit"
                      autocomplete="off"
                      formControlName="restrictedDebit"
                      (keypress)="
                        restrictedDebitChanges(i, $event);
                        calAmount(i, openingBalanceExplain.RestrictedDebit)
                      "
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="restrictedCredit">
                <th mat-header-cell *matHeaderCellDef>Credit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field>
                    <input
                      allowDecimal
                      matInput
                      [value]="element.restrictedCredit"
                      autocomplete="off"
                      formControlName="restrictedCredit"
                      (keypress)="
                        restrictedCreditChanges(i, $event);
                        calAmount(i, openingBalanceExplain.RestrictedCredit)
                      "
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="endowmentDebit">
                <th mat-header-cell *matHeaderCellDef>Debit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allowDecimal
                      matInput
                      [value]="element.endowmentDebit"
                      autocomplete="off"
                      formControlName="endowmentDebit"
                      (keypress)="
                        endowmentDebitChanges(i, $event);
                        calAmount(i, openingBalanceExplain.EndowmentDebit)
                      "
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="endowmentCredit">
                <th mat-header-cell *matHeaderCellDef>Credit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field>
                    <input
                      allowDecimal
                      matInput
                      [value]="element.endowmentCredit"
                      autocomplete="off"
                      formControlName="endowmentCredit"
                      (keypress)="
                        endowmentCreditChanges(i, $event);
                        calAmount(i, openingBalanceExplain.EndowmentCredit)
                      "
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="closeButton">
                <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="text-align-center"
                >
                  <div
                    class="d-flex align-items-center justify-content-center gap-10"
                  >
                    <button
                      class="close-btn"
                      type="button"
                      mat-button
                      disableRipple
                      title="Delete"
                      (click)="onDeleteOpeningBalanceExplain(i)"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="header-row-first-group">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2"></th>
                <td mat-footer-cell *matFooterCellDef>Total</td>
              </ng-container>
              <ng-container matColumnDef="header-row-second-group">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [attr.colspan]="2"
                  class="text-align-center"
                >
                  Unrestricted
                </th>
              </ng-container>
              <ng-container matColumnDef="header-row-third-group">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [attr.colspan]="2"
                  class="text-align-center"
                >
                  Restricted
                </th>
              </ng-container>
              <ng-container matColumnDef="header-row-four-group">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [attr.colspan]="2"
                  class="text-align-center"
                >
                  Endowment
                </th>
              </ng-container>
              <ng-container matColumnDef="header-row-fifth-group">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"></th>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="[
                  'header-row-first-group',
                  'header-row-second-group',
                  'header-row-third-group',
                  'header-row-four-group',
                  'header-row-fifth-group'
                ]"
              ></tr>
              <tr
                mat-header-row
                class="mat-header-second-row"
                *matHeaderRowDef="
                  displayOpeningBalanceExplainColumns;
                  sticky: true
                "
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: displayOpeningBalanceExplainColumns
                "
              ></tr>
            </table>
          </ng-container>
        </form>
      </div>
    </div>
  </mat-dialog-content>
  <div class="mb-1 mt-1 d-flex justify-space-between">
    <div class="mt-1">
      <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
        >Add New Line</a
      >
      <a
        class="link mr-1"
        *ngIf="openingBalanceExplainArray.length > 0"
        href="javascript:void(0)"
        (click)="clearForm()"
        >Clear All Lines</a
      >
    </div>
  </div>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>
