import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  DataType,
  MaxLength,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  FixedAssetDepreciationModel,
  GlobalComponent,
  MainListParameters,
  SideListModel,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  FixedAssetsRegisterState,
  GetDepreciationTransaction,
  GetFixedAssetRegisterDataList,
  GetFundNameList,
  SaveDepreciation,
} from '@app/core/Store';
import { ViewReceiptComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-depreciation-expanded-collapse',
  templateUrl: './depreciation-expanded-collapse.component.html',
  styleUrls: ['./depreciation-expanded-collapse.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('isExpanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'isExpanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class DepreciationExpandedCollapseComponent implements OnInit {
  fixedAssetRegisterList: any;

  moduleId = Modules.FixedAssetsRegister;
  maxLength = MaxLength;
  dataType = DataType;
  dataSource = new MatTableDataSource();
  startDate = new Date();
  endDate = new Date();
  listParameters: MainListParameters = new MainListParameters();

  isAllSelected = false;
  noDataFound = false;
  isSelected = false;

  displayedColumns: string[] = [
    'button',
    'assestName',
    'acquiredDate',
    'lastDepreciate',
    'acquiredPrice',
    'accDepreciation',
    'rateYear',
    'method',
    'depreciation',
    'bookValue',
  ];
  fundNameList: SideListModel[];
  fixedAssetDepriciationData: Array<FixedAssetDepreciationModel>;
  periodicDateList: any;
  depreciationForm: FormGroup;

  fixedAssetItemId = '';
  @ViewChild(MatSort) sort: MatSort;
  expandedCollapseDetailList: any;

  @Select(FixedAssetsRegisterState.expandedCollapseColumnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(FixedAssetsRegisterState.expandedCollapseColumns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
      alignment: string;
    }>
  >;

  constructor(
    private store: Store,
    private globalComponent: GlobalComponent,
    public dialogRef: MatDialogRef<DepreciationExpandedCollapseComponent>,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
    private notify: NotificationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.setPeriodDate();
  }

  setForm(): void {
    this.depreciationForm = new FormGroup({
      accountingPeriod: new FormControl(''),
      fundId: new FormControl(''),
      entryDate: new FormControl(new Date()),
    });
  }

  checkSelectAll(event: any): void {
    let isAllSelected = true;
    this.fixedAssetRegisterList.forEach((element) => {
      if (!element.isSelected) {
        isAllSelected = false;
      }
    });
    this.isAllSelected = isAllSelected;
    this.isSelected = event.checked ? true : false;
  }

  selectAll(event: any): void {
    this.isSelected = event.checked ? true : false;
    this.fixedAssetRegisterList.forEach((x) => (x.isSelected = event.checked));
  }

  expandCollapse(row, filter, index: number): void {
    if (row.isExpanded) {
      row.isExpanded = false;
    } else {
      this.fixedAssetRegisterList.forEach((element, i) => {
        if (i !== index) {
          element.isExpanded = false;
        }
      });
      row.isExpanded = true;
      this.listParameters.filter = filter;
      this.fixedAssetItemId = row.fixedAssetExplainId;
      this.fetchTransactionList(this.fixedAssetItemId);
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.fetchTransactionList(this.fixedAssetItemId);
  }

  getTranactionParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate: this.datepipe
        .transform(
          this.depreciationForm.controls.accountingPeriod.value.fromDate,
          'yyyy-MM-dd'
        )
        ?.toString(),
      endDate: moment(
        this.depreciationForm.controls.entryDate.value.valueOf()
      ).format('yyyy-MM-DD'),
    };
    return queryParams;
  }

  fetchTransactionList(fixedAssetItemId): void {
    this.spinner.show();
    this.store
      .dispatch(
        new GetDepreciationTransaction(
          this.getTranactionParamter(),
          fixedAssetItemId
        )
      )
      .subscribe((res) => {
        this.spinner.hide();
        this.expandedCollapseDetailList =
          res.fixedAssetRegister.expandedCollapseDepreciationDetailList.resultSet.data;
        this.noDataFound =
          this.expandedCollapseDetailList.length === 0 ? true : false;
        this.dataSource.data = this.expandedCollapseDetailList;
      });
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    this.depreciationForm.controls.accountingPeriod.setValue(
      this.periodicDateList[0]
    );
    this.getFundNameList();
    this.onAccountPeriodChange();
  }

  getFundNameList(): void {
    this.store
      .dispatch(new GetFundNameList())
      .pipe(
        tap((res) => {
          this.fundNameList = res.account.fundNameList;
          this.depreciationForm.controls.fundId.setValue(
            this.fundNameList[0].id
          );
          this.getList();
        })
      )
      .subscribe();
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate: this.datepipe
        .transform(
          this.depreciationForm.controls.accountingPeriod.value.toDate,
          'yyyy-MM-dd'
        )
        ?.toString(),
      endDate: moment(
        this.depreciationForm.controls.entryDate.value.valueOf()
      ).format('yyyy-MM-DD'),
    };
    return queryParams;
  }

  getList(): void {
    if (this.depreciationForm.controls.entryDate.value) {
      this.spinner.show();
      this.store
        .dispatch(new GetFixedAssetRegisterDataList(this.getParamter()))
        .subscribe((res) => {
          this.spinner.hide();
          this.fixedAssetRegisterList =
            res.fixedAssetRegister.fixedAssetRegisterDataList;
          this.fixedAssetRegisterList.forEach((element) => {
            element.percentage = 1;
          });
          this.noDataFound =
            this.fixedAssetRegisterList.length === 0 ? true : false;
        });
    }
  }

  onChange(element): void {
    const checkAmount = +element.totalAmount - +element.totalDepreciation;
    if (element.percentage === 1) {
      if (+element.amount <= checkAmount && +element.amount !== 0) {
        element.bookValue =
          +element.totalAmount -
          +element.totalDepreciation -
          ((+element.totalAmount - +element.totalDepreciation) *
            +element.amount) /
            100;
      } else {
        if (+element.amount > checkAmount) {
          this.commonService.onFailure(
            NotificationTextMessage.amountErrorMessage
          );
        }
        element.amount = 0;
        element.bookValue = +checkAmount;
      }
    } else {
      if (+element.amount <= checkAmount && +element.amount !== 0) {
        element.bookValue = +checkAmount - +element.amount;
      } else {
        if (+element.amount > checkAmount) {
          this.commonService.onFailure(
            NotificationTextMessage.amountErrorMessage
          );
        }
        element.amount = 0;
        element.bookValue = +checkAmount;
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.fixedAssetDepriciationData =
        new Array<FixedAssetDepreciationModel>();
      this.fixedAssetRegisterList.forEach((element) => {
        if (element.isSelected) {
          this.fixedAssetDepriciationData.push({
            id: element.id,
            fundId: this.depreciationForm.controls.fundId.value,
            amount: +element.amount,
            name: element.name,
            acquiredDate: element.acquiredDate,
            entryDate: moment(
              this.depreciationForm.controls.entryDate.value.valueOf()
            ).format('yyyy-MM-DD'),
            totalAmount: +element.totalAmount,
            totalDepreciation: +element.totalDepreciation,
            bookValue: +element.bookValue,
            journalId: element.journalId,
            accountingPeriodId:
              this.depreciationForm.controls.accountingPeriod.value.id,
            fixedAssetExplainId: element.fixedAssetExplainId,
            rate: element.rate,
            method: element.method,
            methodTypeId: element.methodTypeId,
            lastDepreciate: element.lastDepreciate,
          });
        }
      });
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(): void {
    if (this.isSelected && this.dataSubmit()) {
      this.store
        .dispatch(new SaveDepreciation(this.fixedAssetDepriciationData))
        .subscribe((res) => {
          this.spinner.hide();
          if (res !== null && res !== undefined) {
            this.onCloseClick();
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.successMessage
            );
          } else {
            this.notify.error(
              NotificationHeader.error,
              NotificationTextMessage.errorMessage
            );
          }
        });
    } else if (!this.isSelected) {
      this.commonService.onFailure(
        NotificationTextMessage.noSelectedErrorMessage
      );
    }
  }

  onCancel(): void {
    this.fixedAssetRegisterList.forEach((x) => (x.isSelected = false));
    this.ngOnInit();
    this.expandedCollapseDetailList = [];
    this.dataSource.data = [];
    this.fixedAssetRegisterList = [];
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onAccountPeriodChange(): void {
    let accountingId = this.depreciationForm.get('accountingPeriod')?.value;
    this.periodicDateList.forEach((element) => {
      if (element.id === accountingId.id) {
        this.startDate = element.fromDate;
        this.endDate = element.toDate;
      }
    });
    this.getList();
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0) {
      if (moduleId === Modules.Journals) {
        const data = {
          moduleId,
          id,
        };

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {});
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }
}
