import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  AccountEntity,
  ChartOfAccountExpenseGroupTypeName,
  ChartOfAccountIncomeGroupTypeName,
  GroupNames,
  MaxLength,
  NotificationTextMessage,
} from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  GetAccountGroupAndType,
  GetJournalAccounts,
  GetNonStandardAccountList,
  GetVatRateList,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, Subject } from 'rxjs';
import { CleanAllLinesComponent } from '@app/modules';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GlobalComponent } from '@app/core/Models';

@Component({
  selector: 'app-add-bulk-journal',
  templateUrl: './add-bulk-journal.component.html',
  styleUrls: ['./add-bulk-journal.component.scss'],
})
export class AddBulkJournalComponent implements OnInit {
  formJournalDetail: UntypedFormGroup;
  journalDetailArray: any;

  displayJournalDetailsColumns: string[] = [
    'account',
    'description',
    'customer/supplier',
    'vatRate',
    'vatAmount',
    'debit',
    'credit',
    'closeButton',
  ];

  accountList: any[] = [];
  accountData: any[] = [];
  customerSupplierList: any[] = [];
  receiptPaymentList: any[];
  vatRateList: any[] = [];
  listModelsIds: any[];
  accountGroupList: any[] = [];
  tempAccountGroupList: any[] = [];

  accountGroupAndTypeId: any;
  totalDebitValue = 0;
  totalCreditValue = 0;
  vatRate = 0;
  primaryContact = {
    id: 0,
  };

  isAddMode = true;
  isFromBankImport = false;
  isCredit = true;
  isManualBank = false;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  defaultCurrency: Guid;
  isVatRegistered: any;

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  tableDataSource: MatTableDataSource<AbstractControl>;

  @Input() triggerTransactionLogData: Observable<any>;
  @Input() triggerEditData: Observable<any>;

  private destroy$ = new Subject<void>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    public dialog: MatDialog,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    private globalComponent: GlobalComponent
  ) {
    this.customerSupplierList = [];
    this.receiptPaymentList = [];
    this.vatRateList = [];
  }

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    if (!this.isVatRegistered) {
      this.displayJournalDetailsColumns =
        this.displayJournalDetailsColumns.filter(
          (column) => column !== 'vatRate' && column !== 'vatAmount'
        );
    }
    this.setJournalDetailsForm(true);
    this.isAddMode = true;
    this.isFromBankImport = false;

    this.triggerTransactionLogData
      ?.pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe((item) => {
        this.getAccounts(true, item);
      });

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.customerSupplierList = [];
        this.vatRateList = [];
        this.isAddMode = false;
        this.isFromBankImport = false;
        this.loadDropdownValues(false).then(() => {
          this.editJournal(data.journalItems);
        });
      });

    this.formJournalDetail?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formJournalDetail.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setAccountDataFromBankOverview(item: any): void {
    this.isFromBankImport = true;
    this.isCredit = item.isCredit ? false : true;
    let account;
    this.accountGroupList.forEach((items) => {
      const data = items.listModels.find((x: any) => x.id === item.accountId);
      if (data !== null && data !== undefined) {
        account = data;
      }
    });

    if (this.isCredit) {
      this.journalDetailArray.controls[0].get('debit').setValue(item.amount);
    } else {
      this.journalDetailArray.controls[0].get('credit').setValue(item.amount);
    }
    this.journalDetailArray.controls[0].get('isDisable').setValue(true);
    this.journalDetailArray.controls[0].get('account').setValue(account);
    this.journalDetailArray.controls[0].disable();
    this.journalDetailArray?.getRawValue().forEach((x) => {
      this.totalCreditValue += +x.credit;
      this.totalDebitValue += +x.debit;
    });
    this.checkModuleType(account, 0);
  }

  async loadDropdownValues(isAddMode: boolean): Promise<void> {
    await this.getAccounts(isAddMode);
  }

  async getAccounts(isAddMode: boolean, items?: any): Promise<void> {
    this.spinner.show();
    let res = await this.store.dispatch(new GetJournalAccounts()).toPromise();

    this.spinner.hide();
    this.accountGroupList = res.account.accounts;
    this.tempAccountGroupList = this.accountGroupList;
    if (this.accountGroupList.length > 0 && isAddMode) {
      this.accountGroupList.forEach((items) => {
        items.listModels.forEach((item) => {
          this.listModelsIds = item;
        });
      });
      this.checkModuleType(
        this.listModelsIds,
        this.journalDetailArray.length - 1
      );
    }

    if (
      this.accountGroupList.length > 0 &&
      items !== null &&
      items !== undefined
    ) {
      this.setAccountDataFromBankOverview(items);
    }
  }

  displayFn(account: any): string {
    return account && account.name ? account.name : '';
  }

  resetAccountList(element: any): void {
    this.accountGroupList = this.tempAccountGroupList;
    if (
      element.account !== null &&
      element.account !== undefined &&
      element.account.value !== null &&
      element.account.value !== undefined
    ) {
      this.scrollIntoView(element.account.value);
    }
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  getOptionText(option) {
    return option?.name;
  }

  async checkModuleType(event: any, index: number): Promise<void> {
    await this.verifyModuleType(event, index);
  }

  async validate(index: number): Promise<void> {
    await this.getVatRateList(index);
    this.setValidation(index, true);
  }

  async verifyModuleType(event: any, index: number): Promise<void> {
    let res = await this.store
      .dispatch(new GetAccountGroupAndType(event.id))
      .toPromise();

    if (res.account.accountGroupAndType) {
      this.accountGroupAndTypeId = res.account.accountGroupAndType;
      if (
        this.accountGroupAndTypeId.groupId === GroupNames.Income &&
        (this.accountGroupAndTypeId.typeId ===
          ChartOfAccountIncomeGroupTypeName.CharitableActivities ||
          this.accountGroupAndTypeId.typeId ===
            ChartOfAccountIncomeGroupTypeName.OtherTradingActivities)
      ) {
        if (this.customerSupplierList.length === 0) {
          this.customerSupplierList.push([]);
        }
        await this.validate(index);
      } else if (
        this.accountGroupAndTypeId.groupId === GroupNames.Expense &&
        (this.accountGroupAndTypeId.typeId ===
          ChartOfAccountExpenseGroupTypeName.CharitableActivities ||
          this.accountGroupAndTypeId.typeId ===
            ChartOfAccountExpenseGroupTypeName.SupportCosts ||
          this.accountGroupAndTypeId.typeId ===
            ChartOfAccountExpenseGroupTypeName.FinanceCosts ||
          this.accountGroupAndTypeId.typeId ===
            ChartOfAccountExpenseGroupTypeName.GovernanceCosts)
      ) {
        if (this.customerSupplierList.length === 0) {
          this.customerSupplierList.push([]);
        }
        await this.validate(index);
      } else if (
        this.accountGroupAndTypeId.groupId ===
          GroupNames.CurrentAssets_Debtors ||
        this.accountGroupAndTypeId.groupId === GroupNames.CurrentLiabilities ||
        this.accountGroupAndTypeId.groupId === GroupNames.NonCurrentLiabilities
      ) {
        const typeId: any = [];
        const id =
          this.accountGroupAndTypeId.groupId ===
          GroupNames.CurrentAssets_Debtors
            ? AccountEntity.Customer
            : AccountEntity.Supplier;
        typeId.push(id);
        this.forkJoinCall(typeId, event.id, index);
        this.setValidation(index, false);
        if (this.vatRateList.length === 0) {
          this.vatRateList.push([]);
        }
      } else {
        if (this.vatRateList.length === 0) {
          this.vatRateList.push([]);
        }
        if (this.customerSupplierList.length === 0) {
          this.customerSupplierList.push([]);
        }

        this.journalDetailArray.controls[index].get('vatRate').disable();
        this.journalDetailArray.controls[index]
          .get('customerSupplier')
          .disable();
        this.journalDetailArray.controls[index]
          .get('customerSupplier')
          .setValue(null);
        this.journalDetailArray.controls[index].get('vatRate').setValue(null);
      }
    }
  }

  setValidation(index: number, isVatScheme): void {
    if (isVatScheme) {
      this.journalDetailArray.controls[index]?.clearValidators();
      this.journalDetailArray.controls[index].get('vatRate').enable();
      this.journalDetailArray.controls[index]
        .get('customerSupplier')
        .setValue(null);
      this.journalDetailArray.controls[index].get('customerSupplier').disable();
      this.journalDetailArray.controls[index]?.updateValueAndValidity();
    } else {
      this.journalDetailArray.controls[index]?.clearValidators();
      this.journalDetailArray.controls[index].get('vatRate').disable();
      this.journalDetailArray.controls[index].get('vatRate').setValue(null);
      this.journalDetailArray.controls[index].get('customerSupplier').enable();
      this.journalDetailArray.controls[index]
        .get('customerSupplier')
        ?.updateValueAndValidity();
      this.journalDetailArray.controls[index]?.updateValueAndValidity();
    }
  }

  forkJoinCall(entityId: any, accountId: any, i: number): void {
    this.spinner.show();
    const customerSupplier = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    forkJoin([customerSupplier]).subscribe((res) => {
      this.spinner.hide();
      const customerSupplierInfo = this.store.selectSnapshot(
        CommonState.accountList
      );

      this.setCustomerSupplierList(customerSupplierInfo, i);
    });
  }

  setReciptPaymentList(receiptPaymentInfo: any, i: number): void {
    if (
      this.receiptPaymentList.length > 0 &&
      this.receiptPaymentList[i] !== null &&
      this.receiptPaymentList[i] !== undefined
    ) {
      this.receiptPaymentList[i] = receiptPaymentInfo;
      this.vatRateList[i] = [];
    } else {
      this.receiptPaymentList.push(receiptPaymentInfo);
      this.vatRateList.push([]);
    }
  }

  setCustomerSupplierList(customerSupplierInfo: any, i: number): void {
    if (
      this.customerSupplierList.length > 0 &&
      this.customerSupplierList[i] !== null &&
      this.customerSupplierList[i] !== undefined
    ) {
      this.customerSupplierList[i] = customerSupplierInfo;
      this.vatRateList[i] = [];
    } else {
      this.customerSupplierList.push(customerSupplierInfo);
      this.vatRateList.push([]);
    }
  }

  async getVatRateList(i: number): Promise<void> {
    this.spinner.show();
    let res = await this.store.dispatch(new GetVatRateList()).toPromise();
    this.spinner.hide();
    if (
      this.vatRateList.length > 0 &&
      this.vatRateList[i] !== null &&
      this.vatRateList[i] !== undefined
    ) {
      this.vatRateList[i] = res.common.vatRate;
      this.receiptPaymentList[i] = [];
      this.customerSupplierList[i] = [];
    } else {
      this.vatRateList.push(res.common.vatRate);
      this.receiptPaymentList.push([]);
      this.customerSupplierList.push([]);
    }
  }

  creditChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formJournalDetail.get(
        'journalDetailArray'
      ) as UntypedFormArray;

      formArray.controls[index].get('debit')?.setValue(null);
      formArray.controls[index].get('debit')?.clearValidators();
      formArray.controls[index]
        .get('credit')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
          Validators.pattern(/^(?=.*[1-9])\d+(?:\.*\d\d?)?$/),
        ]);

      formArray.controls[index].get('credit')?.updateValueAndValidity();
      formArray.controls[index].get('debit')?.updateValueAndValidity();
    }
  }

  debitChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formJournalDetail.get(
        'journalDetailArray'
      ) as UntypedFormArray;

      formArray.controls[index].get('credit')?.setValue(null);
      formArray.controls[index].get('credit')?.clearValidators();
      formArray.controls[index]
        .get('debit')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
          Validators.pattern(/^(?=.*[1-9])\d+(?:\.*\d\d?)?$/),
        ]);
      formArray.controls[index].get('credit')?.updateValueAndValidity();
      formArray.controls[index].get('debit')?.updateValueAndValidity();
    }
  }

  calVatAmount(index: number): void {
    if (this.vatRateList.length > 0) {
      let data = this.vatRateList[index]?.filter((x) => {
        return (
          x.id === this.journalDetailArray.controls[index].get('vatRate').value
        );
      });

      if (data?.length > 0) {
        this.vatRate = data[0].rate;
      } else {
        this.vatRate = 0;
      }
    }

    if (+this.journalDetailArray.controls[index].get('credit').value !== 0) {
      const credit =
        +this.journalDetailArray.controls[index].get('credit').value === 0
          ? 0
          : +this.journalDetailArray.controls[index].get('credit').value;

      const vatAmount =
        (credit * (this.vatRate / 100)) / (1 + this.vatRate / 100);

      this.journalDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(vatAmount.toFixed(2));
    } else {
      const debit =
        +this.journalDetailArray.controls[index].get('debit').value === 0
          ? 0
          : +this.journalDetailArray.controls[index].get('debit').value;

      const vatAmount =
        (debit * (this.vatRate / 100)) / (1 + this.vatRate / 100);

      this.journalDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(vatAmount.toFixed(2));
    }

    this.totalCreditValue = 0;
    this.totalDebitValue = 0;
    this.journalDetailArray?.getRawValue().forEach((x) => {
      this.totalCreditValue += +x.credit;
      this.totalDebitValue += +x.debit;
    });
  }

  resetForm(): void {
    const formArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;
    formArray.clear();
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formJournalDetail.get(
            'journalDetailArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            if (!formArray.controls[0].value.isDisable) {
              formArray.controls[i].reset();
            }
          }
        }
      });
  }

  setJournalDetailsForm(addNewRow: boolean): void {
    this.formJournalDetail = new FormGroup({
      journalDetailArray: new UntypedFormArray([]),
    });

    this.journalDetailArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.journalDetailArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.journalDetailArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;

    this.journalDetailArray.push(this.setForm());

    if (this.journalDetailArray.length > 0) {
      this.loadDropdownValues(true);
    }

    this.setDataSource(this.journalDetailArray);
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    const form = this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      account: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      customerSupplier: new FormControl(''),
      vatRate: new FormControl(''),
      vatAmount: new FormControl({ value: 0.0, disabled: true }),
      debit: new FormControl(null),
      credit: new FormControl(null),
      isDisable: new FormControl(false),
    });

    if (this.isFromBankImport) {
      if (this.isCredit) {
        form.controls.debit.disable();
      } else {
        form.controls.credit.disable();
      }
    }
    return form;
  }

  onDeleteProductDetails(index: number): void {
    this.journalDetailArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;
    if (this.journalDetailArray.length === 1) {
      return;
    }

    this.journalDetailArray.removeAt(index);

    this.customerSupplierList.splice(index, 1);
    this.receiptPaymentList.splice(index, 1);
    this.vatRateList.splice(index, 1);

    this.setDataSource(this.journalDetailArray);
  }

  editJournal(data: any): void {
    this.totalCreditValue = 0;
    this.totalDebitValue = 0;

    this.journalDetailArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;

    this.journalDetailArray.clear();

    data?.forEach((item, i) => {
      if (this.vatRateList.length === 0) {
        this.vatRateList.push([]);
      } else {
        this.vatRateList[i] = [];
      }
      if (this.customerSupplierList.length === 0) {
        this.customerSupplierList.push([]);
      } else {
        this.customerSupplierList[i] = [];
      }
      this.journalDetailArray.push(this.buildOrderItemsForm(item));
      let account;
      this.accountGroupList.forEach((items) => {
        const data = items.listModels.find((x: any) => x.id === item.accountId);
        if (data !== null && data !== undefined) {
          account = data;
        }
      });

      this.journalDetailArray.controls[i].get('account').setValue(account);
      this.journalDetailArray.controls[i].get('vatAmount').disable();
      this.checkModuleType(account, i);
      this.journalDetailArray.controls[i]
        .get('vatRate')
        .setValue(item.vatRateId);
    });

    this.journalDetailArray?.getRawValue().forEach((x) => {
      this.totalCreditValue += +x.credit;
      this.totalDebitValue += +x.debit;
    });
    this.setDataSource(this.journalDetailArray);
  }

  buildOrderItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      account: item.accountId,
      description: item.description,
      customerSupplier: item.customerSupplierId,
      vatRate: '',
      vatAmount: item.vatAmount,
      debit: item.isCredit ? null : item.totalAmount,
      credit: item.isCredit ? item.totalAmount : null,
      isDisable: false,
    });
  }

  scrollIntoView(element) {
    if (element.value.account !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }
}
