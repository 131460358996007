<form [formGroup]="note29Form">
  <div class="notes-section">
    <div class="notes-content">
      <table class="notes-table" aria-describedby="User Task Table">
        <tr>
          <th>Analysis of debtors</th>
          <th>
            This year<br />
            £
          </th>
          <th>
            Last year<br />
            £
          </th>
        </tr>
        <ng-container *ngIf="calculativeData?.length === 0">
          <tr>
            <td colspan="3" class="text-align-center mt-1 fw-bold">
              <span>No records found</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="calculativeData?.length > 0">
          <tr *ngFor="let item of calculativeData">
            <td class="fw-bold text-align-left">{{ item.name }}</td>
            <td>{{ item.thisYear | numberPipe }}</td>
            <td>{{ item.lastYear | numberPipe }}</td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
  <div class="notes-wrapper">
    <div class="notes-section">
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox1"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
  </div>
</form>
