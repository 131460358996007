<div class="ticket-wrapper">
  <div class="content-body">
    <mat-tab-group
      mat-align-tabs="start"
      disableRipple
      animationDuration="0ms"
      animationDuration="0ms "
    >
      <mat-tab label="Support Ticket">
        <app-support-ticket></app-support-ticket>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
