import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  GroupNames,
  MaxLength,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { Currency, GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  CommonState,
  CustomAccountState,
  GetAccountTypeList,
  GetChartOfAccountGroupList,
  GetChartOfAccountTypeList,
  GetCurrencyList,
  GetFundTypeList,
  MenuState,
  ValidateAccountCodeExists,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.scss'],
})
export class QuickAddComponent implements OnInit {
  moduleEnum = Modules;
  form: FormGroup;

  id = Guid.EMPTY as unknown as Guid;

  quickSaveData: any;
  editData: any;
  periodicDate: any;
  quickData: any;

  fundTypeList: SideListModel[];
  chartOfAccountGroupList: SideListModel[];
  maxLength = MaxLength;
  notificationMessage = NotificationTextMessage;
  defaultCurrency: Guid;
  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;
  accountTypeList: SideListModel[];
  currencyList: Currency[];
  isCashInHandGroup = false;
  isFixedAssestsGroup = false;
  isAddMode = true;
  chartOfAccountTypeList: SideListModel[];
  isReadOnly = false;
  accountType: number;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  moduleId: Number;
  @Output()
  readonly accountGroupChange = new EventEmitter<any>();

  isAccountCodeNotExists: boolean = true;
  suggestedCodes: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<QuickAddComponent>,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private store: Store,
    private globalComponent: GlobalComponent,
    public notifier: NotificationService
  ) {
    this.quickData = data;
    this.id = data.id === undefined ? (Guid.EMPTY as unknown as Guid) : data.id;
  }

  ngOnInit(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.periodicDate = this.globalComponent.getFinancialPeriod();

    this.setForm();

    if (this.quickData.moduleId === Modules.FundList) {
      this.getFundTypeList(false);
    }

    if (this.quickData.moduleId === Modules.AddCustomAccounts) {
      this.getChartOfAccountGroupList();
      this.getCurrency();
      this.getAccountTypeList();
    }

    if (!this.commonService.isEmpty(this.id)) {
      this.edit();
    }
  }

  getCurrency(): void {
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.currencyList.length > 0) {
            this.form.controls.currency.setValue(this.defaultCurrency);
          }
        })
      )
      .subscribe();
  }

  getAccountTypeList(): void {
    this.store
      .dispatch(new GetAccountTypeList())
      .pipe(
        tap(() => {
          this.accountTypeList = this.store.selectSnapshot(
            CustomAccountState.getAccountType
          );
        })
      )
      .subscribe();
  }

  getChartOfAccountGroupList(): void {
    this.moduleId$.subscribe((moduleId) => {
      this.moduleId = moduleId;
    });
    this.store
      .dispatch(new GetChartOfAccountGroupList())
      .pipe(
        tap((res) => {
          const dataList = res.account.chartOfAccountGroupList;
          this.chartOfAccountGroupList = [];
          if (this.moduleId === this.moduleEnum.BankDashboard) {
            dataList.forEach((element) => {
              if (element.id !== GroupNames.CurrentAssets_Investments) {
                this.chartOfAccountGroupList.push(element);
              }
            });
            this.onChartOfAccountGroupChange(
              this.chartOfAccountGroupList[8].id
            );
            this.form.controls.chartOfAccountGroup.setValue(
              this.chartOfAccountGroupList[8].id
            );
          } else {
            dataList.forEach((element) => {
              if (
                element.id !== GroupNames.CurrentAssets_Investments &&
                element.id !== GroupNames.NonCurrentLiabilities
              ) {
                this.chartOfAccountGroupList.push(element);
              }
            });
            this.onChartOfAccountGroupChange(
              this.chartOfAccountGroupList[0].id
            );
            this.form.controls.chartOfAccountGroup.setValue(
              this.chartOfAccountGroupList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  onCloseClick(): void {
    this.dialogRef.close(Guid.EMPTY as unknown as Guid);
  }

  getFundTypeList(isEdit: boolean): void {
    this.store
      .dispatch(new GetFundTypeList())
      .pipe(
        tap((res) => {
          this.fundTypeList = res.account.fundTypeList;
          if (!isEdit) {
            this.form.controls.fundType.setValue(this.fundTypeList[0].id);
          }
        })
      )
      .subscribe();
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      if (this.quickData.moduleId === Modules.AddCustomAccounts) {
        this.quickSaveData = {
          id: this.id,
          name: this.isFixedAssestsGroup
            ? this.form.controls.chartOfAccountType.value
            : this.form.controls.accountName.value,
          code: this.form.controls.accountCode.value,
          chartOfAccountGroupId: this.form.controls.chartOfAccountGroup.value,
          chartOfAccountTypeId: !this.isFixedAssestsGroup
            ? this.form.controls.chartOfAccountType.value
            : 0,
          bankAccountTypeId:
            this.form.controls.bankAccountType.value === ''
              ? null
              : this.form.controls.bankAccountType.value,
          currencyId: this.form.controls.currency.value,
          bankBranchCode: this.form.controls.sortBranchCode.value,
          bankAccountNumber:
            this.form.controls.accountNo.value !== undefined &&
            this.form.controls.accountNo.value !== null
              ? this.form.controls.accountNo.value.toString()
              : '',
        };
      } else {
        this.quickSaveData = {
          id: this.id,
          name: this.form.controls.name.value,
          email: this.form.controls.email.value,
          accountTypeId: this.commonService.getEntityTypeId(
            this.quickData.moduleId
          ),
          fundTypeId: +this.form.controls.fundType.value,
          isQuickEdit: true,
          eventDate: this.form.controls.eventDate.value,
          code: this.form.controls.code.value,
        };
      }
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  getEditData(res): void {
    switch (this.quickData.moduleId) {
      case Modules.Customers:
      case Modules.Suppliers:
        this.editData = res.account.accountData;
        break;
      case Modules.Donors:
        this.editData = res.donor.donorData;
        break;
      case Modules.SponsorEvent:
        this.editData = res.sponsor.sponsorData;
        break;
      case Modules.FundList:
        this.editData = res.fund.fundData;
        break;
      case Modules.Activities:
        this.editData = res.activity.activityData;
        break;
    }
  }

  edit(): void {
    this.store
      .dispatch(new this.quickData.getActionName(this.id))
      .subscribe((res) => {
        this.getEditData(res);
        this.form.controls.code.disable();
        this.form.patchValue({
          name: this.editData.name,
          email: this.editData.email,
          fundType: this.editData.fundTypeId,
          eventDate: this.editData.eventDate,
          code: this.editData.code,
        });
      });
  }

  setId(res): void {
    switch (this.quickData.moduleId) {
      case Modules.Customers:
      case Modules.Suppliers:
        this.id = res.account.accountId;
        break;
      case Modules.Donors:
        this.id = res.donor.donorId;
        break;
      case Modules.SponsorEvent:
        this.id = res.sponsor.sponsorId;
        break;
      case Modules.FundList:
        this.id = res.fund.fundId;
        break;
      case Modules.Activities:
        this.id = res.activity.activityId;
        break;
      case Modules.AddCustomAccounts:
        this.id = res.custom.customAccountId;
        break;
    }
  }

  findInvalidControls() {
    const invalid: any = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onSave(): void {
    const invalid = this.findInvalidControls();
    if (invalid.length > 0) {
      this.commonService.addValidation(this.form, this.renderer);
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new this.quickData.saveActionName(this.quickSaveData))
          .pipe()
          .subscribe((res) => {
            if (res !== undefined) {
              this.spinner.hide();
              this.setId(res);
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
              this.dialogRef.close(this.id);
            }
          });
      }
    }
  }

  onCancel(): void {
    if (!this.commonService.isEmpty(this.id)) {
      this.edit();
    } else {
      this.id = Guid.EMPTY as unknown as Guid;
      this.ngOnInit();
    }
  }

  setForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      email: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
        Validators.email,
      ]),
      fundType: new FormControl(''),
      eventDate: new FormControl(new Date()),
      code: new FormControl(''),
      chartOfAccountGroup: new FormControl(''),
      chartOfAccountType: new FormControl(''),
      accountName: new FormControl(''),
      accountCode: new FormControl(''),
      accountNo: new FormControl(''),
      currency: new FormControl({ value: '', disabled: true }),
      bankAccountType: new FormControl(''),
      sortBranchCode: new FormControl(''),
    });

    if (
      this.quickData.moduleId === Modules.FundList ||
      this.quickData.moduleId === Modules.SponsorEvent
    ) {
      if (this.quickData.moduleId === Modules.FundList) {
        this.form.controls.fundType.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);

        this.form.controls.fundType.updateValueAndValidity();
      }

      if (this.quickData.moduleId === Modules.SponsorEvent) {
        this.form.controls.eventDate.setValidators(
          datePickerValidator(this.periodicDate)
        );
        this.form.controls.eventDate.updateValueAndValidity();
      }

      this.form.controls.email.clearValidators();
      this.form.controls.email.updateValueAndValidity();
    }
    if (this.quickData.moduleId === Modules.Activities) {
      this.form.controls.email.clearValidators();
      this.form.controls.email.updateValueAndValidity();
    }
  }

  onChartOfAccountGroupChange(accountGroupId?: number): void {
    this.accountGroupChange.emit(accountGroupId);
    this.form.controls.chartOfAccountGroup.setValue(accountGroupId);
    if (accountGroupId === GroupNames.CurrentAssets_CashatBankAndHand) {
      this.isCashInHandGroup = true;
    } else {
      this.isCashInHandGroup = false;
    }
    if (
      accountGroupId === GroupNames.FixedAssets_Intangibles ||
      accountGroupId === GroupNames.FixedAssets_Tangibles ||
      accountGroupId === GroupNames.FixedAssets_Heritage ||
      accountGroupId === GroupNames.FixedAssets_Investments
    ) {
      this.setValidation(true);
    } else {
      this.getChartOfAccountTypeList(accountGroupId);
      this.setValidation(false);
    }
  }

  setValidation(isFixesAssest: boolean): void {
    if (isFixesAssest) {
      this.isFixedAssestsGroup = true;
      this.form.clearValidators();
      this.form.controls.chartOfAccountType.setValue(
        this.form.controls.accountName.value
      );
      if (!this.isAddMode) {
        this.form.controls.chartOfAccountGroup.disable();
      }
      this.form.get('accountName')?.clearValidators();
      this.form.get('accountCode')?.clearValidators();
      this.form.get('accountName')?.updateValueAndValidity();
      this.form.get('accountCode')?.updateValueAndValidity();
      this.form.updateValueAndValidity();
    } else {
      this.isFixedAssestsGroup = false;
      this.form.clearValidators();
      this.form.get('accountName')?.setValidators(Validators.required);
      this.form
        .get('accountCode')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      this.form.controls.chartOfAccountGroup.enable();
      this.form.get('accountName')?.clearValidators();
      this.form.get('email')?.clearValidators();
      this.form.get('name')?.clearValidators();
      this.form.get('accountName')?.updateValueAndValidity();

      this.form.get('email')?.updateValueAndValidity();
      this.form.get('name')?.updateValueAndValidity();
      this.form.updateValueAndValidity();
    }
  }

  getChartOfAccountTypeList(accountGroupId?: number): void {
    if (accountGroupId !== undefined) {
      this.store
        .dispatch(new GetChartOfAccountTypeList(accountGroupId))
        .pipe(
          tap((res) => {
            this.chartOfAccountTypeList = res.account.chartOfAccountTypeList;
            if (this.chartOfAccountTypeList.length > 0) {
              this.form.controls.chartOfAccountType.setValue(
                this.isReadOnly
                  ? this.accountType
                  : this.chartOfAccountTypeList[0]?.id
              );

              if (accountGroupId === 10) {
                this.chartOfAccountTypeList.splice(0, 1);
              }
            }
          })
        )
        .subscribe();
    }
  }

  validateAccountCode(): void {
    if (this.form.controls.accountCode.value !== '') {
      this.store
        .dispatch(
          new ValidateAccountCodeExists(this.form.controls.accountCode.value)
        )
        .subscribe((res) => {
          this.isAccountCodeNotExists = res.custom.accountCodeExist.isSuccess;
          if (!this.isAccountCodeNotExists) {
            this.suggestedCodes = res.custom.accountCodeExist.suggestedCodes;
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.accountCodeExist
            );
            this.form.controls.accountCode.setErrors({
              accountCodeExists: true,
            });
          } else {
            this.suggestedCodes = [];
            this.form.controls.accountCode.setErrors(null);
          }
        });
    } else {
      this.isAccountCodeNotExists = true;
      this.suggestedCodes = [];
      this.form.controls.accountCode.setErrors(null);
    }
  }
}
