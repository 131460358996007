import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AddClosePopupComponent } from '@app/modules/common';
import { Observable, Subject, Subscription } from 'rxjs';

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { FundModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { CreateFund, GetDataByFundId, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddFundComponent } from './add-fund/add-fund.component';

@Component({
  selector: 'app-fund',
  templateUrl: './fund.component.html',
  styleUrls: ['./fund.component.scss'],
})
export class FundComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isExit = false;

  fundId = Guid.EMPTY as unknown as Guid;

  fundData: FundModel;

  moduleId = Modules.FundList;
  moduleName = ModuleName.Fund;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<boolean> = new Subject<boolean>();
  triggerOnCancel: Subject<any> = new Subject<any>();

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddFundComponent, { static: true })
  fundDetails;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private store: Store,
    public commonService: CommonService,
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<FundComponent>
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.fundId = atob(params.get('id')!) as unknown as Guid;
          this.editFund();
        }
      }
    );
  }

  editFund(): void {
    this.store.dispatch(new GetDataByFundId(this.fundId)).subscribe((res) => {
      this.fundDetails.fundForm.markAsUntouched();
      this.triggerEditData.next(res.fund.fundData);
    });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.fundId)) {
      this.editFund();
    } else {
      this.fundId = Guid.EMPTY as unknown as Guid;
      this.fundDetails.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.fundData = {
        id: this.fundId,
        name: this.fundDetails.fundForm.controls.fundName.value,
        isDefault: this.fundDetails.fundForm.controls.isDefault.value,
        fundTypeId: this.fundDetails.fundForm.controls.fundType.value,
        isTradingFund: this.fundDetails.fundForm.controls.isTradingFund.value,
        openingBalance:
          +this.fundDetails.fundForm.controls.openingBalance.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.fundId,
      isExit,
      Modules.FundList,
      RoutingPath.AddFund
    );
  }

  onSave(isExit: boolean): void {
    if (this.fundDetails.fundForm.invalid) {
      this.commonService.addValidation(
        this.fundDetails.fundForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        if (this.fundData) {
          this.store
            .dispatch(new CreateFund(this.fundData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }
}
