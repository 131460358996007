import { MainListParameters } from '@app/core/Models';
import { DonorModel } from '@app/core/Models/contact/donor-model';
import { Guid } from 'guid-typescript';

export class GetDataByDonorId {
  static readonly type = '[DONOR] Get Donor Data By Donor Id';

  constructor(public donorId: Guid) {}
}

export class GetAllDonor {
  static readonly type = '[DONOR] Get All Donor';
}

export class GetGiftAidDonorSpreadSheets {
  static readonly type = '[DONOR] Get Gift Aid Donor Spread Sheets';
}

export class GetGiftAidDonorDeclarations {
  static readonly type = '[DONOR] Get Gift Aid Donor Declarations';
}

export class CreateDonor {
  static readonly type = '[DONOR] Create Donor';

  constructor(public donor: DonorModel) {}
}

export class ArchiveAndRestoreDonor {
  static readonly type = '[DONOR] Archive and Restore Selected Donor';

  constructor(public donorIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteDonor {
  static readonly type = '[DONOR] Delete Selected Donor';

  constructor(public donorIds: Array<Guid>) {}
}

export class DonorTransactionExport {
  static readonly type = '[DONOR] Donor Transaction Export ';

  constructor(public queryParams: MainListParameters, public id: Guid) {}
}
