import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Modules, NotificationTextMessage } from '@app/core/Enum';
import { EmailTemplateModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetEmailTemplatesData,
  MenuState,
  UpdateEmailTemplates,
} from '@app/core/Store';

import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
})
export class EmailTemplatesComponent implements OnInit {
  emailTemplate: Array<EmailTemplateModel> = [];
  suggestedLabel: Array<any>;
  signatureAddress = '';
  isExpandAll = false;
  moduleId = Modules.EmailLogs;
  triggerGetTextFieldData: Subject<any> = new Subject<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;
  highlightTexts: any;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isPermission: boolean = true;
  constructor(private store: Store, private commonService: CommonService) {}

  ngOnInit(): void {
    this.getTemplates();
    this.isViewPermission$.subscribe((res) => {
      this.isPermission = res;
    });
  }

  getTemplates(): void {
    this.store
      .dispatch(new GetEmailTemplatesData())
      .pipe(
        tap((res) => {
          if (res.emailTemplates.templateData.length > 0) {
            this.emailTemplate = res.emailTemplates.templateData;

            this.addSuggestedLabel(
              this.emailTemplate[0].templateDetails[0].suggestedLabels
            );
          }
        })
      )
      .subscribe();
  }

  addSuggestedLabel(suggestedLabels: any): void {
    this.suggestedLabel = Object.entries(suggestedLabels).map(([k, v]) => v);
  }

  removeHighlight(): void {
    const removemark = ['<mark>', '</mark>'];

    this.emailTemplate.forEach((x) => {
      x.templateDetails.forEach((y) => {
        removemark.forEach((element) => {
          y.subject = y.subject?.replaceAll(element, ' ');
          y.templateHTML = y.templateHTML?.replaceAll(element, ' ');
        });
      });
    });
  }

  onSave(isExits: boolean): void {
    this.removeHighlight();

    this.store
      .dispatch(new UpdateEmailTemplates(this.emailTemplate))
      .subscribe((res: any) => {
        if (res) {
          this.commonService.onSucess(NotificationTextMessage.successMessage);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }

        if (isExits) {
          history.back();
        }
      });
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCancel(): void {
    this.getTemplates();
  }

  highlightValue(messageValue: any, suggestedLabel): any {
    let suggestedLabels = Object.entries(suggestedLabel).map(([k, v]) => v);

    suggestedLabels.forEach((element) => {
      messageValue = messageValue.replaceAll(
        element,
        `<mark>${element}</mark>`
      );
    });

    return messageValue;
  }

  onMessageChange(value, text, isSubject): any {
    let messageValue = value.target.innerHTML;

    messageValue = messageValue.replace(/\s+/g, ' ').trim();
    const removemark = ['<mark>', '</mark>'];
    removemark.forEach((element) => {
      if (messageValue.includes(element)) {
        messageValue = messageValue.replaceAll(element, ' ');
      }
    });

    if (isSubject) {
      text.subject = this.highlightValue(messageValue, text.suggestedLabels);
    } else {
      text.templateHTML = this.highlightValue(
        messageValue,
        text.suggestedLabels
      );
    }
  }
}
