import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { ExtendConnectionTokenUserDataPayload } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { ReconfirmBankAccount } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-reconfirm-popup',
  templateUrl: './reconfirm-popup.component.html',
  styleUrls: ['./reconfirm-popup.component.scss'],
})
export class ReconfirmPopupComponent implements OnInit {
  reconfirmForm: FormGroup;
  maxLength = MaxLength;
  userData: ExtendConnectionTokenUserDataPayload;

  @Input() bankId;

  isContentShow = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    public dialogRef: MatDialogRef<ReconfirmPopupComponent>,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.bankId = atob(this.data.customId) as unknown as any;
  }

  setForm(): void {
    this.reconfirmForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      postCode: new FormControl(''),
      email: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
        Validators.email,
      ]),
      mobileNumber: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
    });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
  onBackClick(): void {
    this.isContentShow = false;
  }
  onAllowClick(): void {
    this.isContentShow = true;
    this.setForm();
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  onProceedClick(): void {
    this.userData = {
      name: this.reconfirmForm.controls.name.value,
      email: this.reconfirmForm.controls.email.value,
      phone: this.reconfirmForm.controls.mobileNumber.value,
    };

    this.store
      .dispatch(new ReconfirmBankAccount(this.bankId, this.userData))
      .pipe(
        tap((res) => {
          if (res.bankDashboard.response.status) {
            if (res.bankDashboard.response.msg === 'no_action_needed') {
              this.commonService.onSucess(
                'The connection has been extended and is able to be used until consent needs to be re-confirmed in the future.'
              );
            } else {
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
            }
            this.onCloseClick();
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        })
      )
      .subscribe();
  }
}
