<div class="mt-1">
  <p class="fw-bold mr-1 text-align-right">
    Total Unrestricted Debit
    <span class="fw-normal ml-1"
      >£ {{ totalUnrestrictedDebit | numberPipe }}</span
    >
  </p>
  <p class="fw-bold mr-1 mt-0 text-align-right">
    Total Unrestricted Credit
    <span class="fw-normal ml-1"
      >£ {{ totalUnrestrictedCredit | numberPipe }}</span
    >
  </p>
  <p class="fw-bold mr-1 text-align-right">
    Total Restricted Debit
    <span class="fw-normal ml-1"
      >£ {{ totalRestrictedDebit | numberPipe }}</span
    >
  </p>
  <p class="fw-bold mr-1 mt-0 text-align-right">
    Total Restricted Credit
    <span class="fw-normal ml-1"
      >£ {{ totalRestrictedCredit | numberPipe }}</span
    >
  </p>
  <p class="fw-bold mr-1 text-align-right">
    Total Endowment Debit
    <span class="fw-normal ml-1">£ {{ totalEndowmentDebit | numberPipe }}</span>
  </p>
  <p class="fw-bold mr-1 mt-0 text-align-right">
    Total Endowment Credit
    <span class="fw-normal ml-1"
      >£ {{ totalEndowmentCredit | numberPipe }}</span
    >
  </p>
</div>
