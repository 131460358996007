import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailIntegration, Modules } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-email-integrations',
  templateUrl: './email-integrations.component.html',
  styleUrls: ['./email-integrations.component.scss'],
})
export class EmailIntegrationsComponent implements OnInit {
  emailList = [
    {
      id: Guid.EMPTY as unknown as Guid,
      moduleId: Modules.Invoices,
      name: this.getName(Modules.Invoices),
      isActive: false,
    },
    {
      id: Guid.EMPTY as unknown as Guid,
      moduleId: Modules.Quotation,
      name: this.getName(Modules.Quotation),
      isActive: false,
    },
    {
      id: Guid.EMPTY as unknown as Guid,
      moduleId: Modules.CreditNote,
      name: this.getName(Modules.CreditNote),
      isActive: false,
    },
    {
      id: Guid.EMPTY as unknown as Guid,
      moduleId: Modules.DebitNote,
      name: this.getName(Modules.DebitNote),
      isActive: false,
    },
    {
      id: Guid.EMPTY as unknown as Guid,
      moduleId: Modules.Receipt,
      name: this.getName(Modules.Receipt),
      isActive: false,
    },
  ];

  isCheckboxChecked = false;

  @Input() triggerEditData: Observable<any>;

  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.triggerEditData.subscribe((data) => {
      if (data.length > 0) {
        this.emailList = [];
        data.forEach((element) => {
          this.emailList.push({
            id: element.id,
            moduleId: element.moduleId,
            name: this.getName(element.moduleId),
            isActive: element.isActive,
          });
        });
      }
    });
  }

  getName(moduleId): any {
    switch (moduleId) {
      case Modules.Invoices:
        return EmailIntegration.Invoices;
      case Modules.Quotation:
        return EmailIntegration.Quotation;
      case Modules.CreditNote:
        return EmailIntegration.CreditNote;
      case Modules.DebitNote:
        return EmailIntegration.DebitNote;
      case Modules.Receipt:
        return EmailIntegration.Receipt;
    }
  }

  onCheckBoxChanges(): void {
    this.isCheckboxChecked = true;
    this.commonService.isInitialValueChange = this.isCheckboxChecked;
  }
}
