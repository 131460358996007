<div class="content">
  <div class="content-body">
    <app-transactions-header [listParameters]="listParameters">
    </app-transactions-header>
    <div class="mat-table-wrapper">
      <table
        aria-describedby="Bank-Reconcillation-List"
        mat-table
        [dataSource]="reconciliationList"
        class="grid-table"
      >
        <ng-container matColumnDef="bankProvider">
          <th mat-header-cell *matHeaderCellDef>Bank / Provider</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              {{ element.providerName }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="daysToReconfirm">
          <th mat-header-cell *matHeaderCellDef>Days on to Reconfirm</th>
          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.nextRefreshScheduledDate | date: "dd-LLL-yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="from">
          <th mat-header-cell *matHeaderCellDef>From</th>

          <td mat-cell *matCellDef="let element" class="widthemail">
            True Layer
          </td>
        </ng-container>
        <ng-container matColumnDef="accountNumber">
          <th mat-header-cell *matHeaderCellDef>A/c No.</th>
          <td mat-cell *matCellDef="let element">
            {{ element.accountNo }}
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element.accountType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="feedsStartDate">
          <th mat-header-cell *matHeaderCellDef>Feeds Start Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdOn | date: "dd-LLL-yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="bankAccountName">
          <th mat-header-cell *matHeaderCellDef>Capium A/c</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="d-flex align-items-center">
              <div class="clamp">
                {{ element.bankAccountName }}
              </div>
              <div
                class="grid-actions"
                [ngClass]="selectedRowIndex === i ? 'show-button' : ''"
              >
                <div
                  class="d-flex align-items-center"
                  *ngFor="let item of actions; let permissionsIndex = index"
                >
                  <button
                    type=" button"
                    mat-button
                    disableRipple
                    title="{{ item.name }}"
                    (click)="onButtonClick(element, item.actionType)"
                    [disabled]="isViewPermission$ | async"
                  >
                    <mat-icon>{{ item.icon }}</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayReconciliationColumns_v1; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayReconciliationColumns_v1"
          [ngClass]="{ 'bg-danger-400 expired': row.isExpired }"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayReconciliationColumns_v1.length"
          >
            <span *ngIf="listParameters">No Records Found</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
