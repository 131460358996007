import { Component, OnInit, ViewChild } from '@angular/core';
import { Modules } from '@app/core/Enum';
import {
  MainListComponent,
  ReportListWithActionsComponent,
} from '@app/modules/common';

@Component({
  selector: 'app-fixed-assest-dispose-active',
  templateUrl: './fixed-assest-dispose-active.component.html',
  styleUrls: ['./fixed-assest-dispose-active.component.scss'],
})
export class FixedAssestDisposeActiveComponent implements OnInit {
  selectedTab = 0;
  modulesEnum = Modules;
  @ViewChild(ReportListWithActionsComponent, { static: true })
  reportListWithActions;

  ngOnInit(): void {
    this.onTabClick(this.selectedTab);
  }

  onTabClick(event: any): void {
    this.selectedTab = event;
    if (this.selectedTab === 0) {
      this.reportListWithActions.moduleId = Modules.FixedAssetsRegister;
      this.reportListWithActions.getList(false);
    }
  }
}
