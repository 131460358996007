import { RecurringDonationModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateRecurringDonation {
  static readonly type = '[RECURRINGDONATION] Create RecurringDonation';

  constructor(public recurringDonation: RecurringDonationModel) {}
}

export class GetDataByRecurringDonationId {
  static readonly type =
    '[RECURRINGDONATION] Get RecurringDonation Data By RecurringDonation Id';

  constructor(public recurringDonationId: Guid) {}
}

export class ArchiveAndRestoreRecurringDonation {
  static readonly type =
    '[RECURRINGDONATION] Archive and Restore Selected RecurringDonation';

  constructor(
    public recurringDonationIds: Array<Guid>,
    public isArchive: boolean
  ) {}
}

export class DeleteRecurringDonation {
  static readonly type =
    '[RECURRINGDONATION] Delete Selected RecurringDonation';

  constructor(public recurringDonationIds: Array<Guid>) {}
}
