import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { AccountModel, SideListModel } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import { CommonState, CreateAccount, CreateContact, GetDataByAccountId, MenuState } from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddCommericalDetailsComponent,
  AddContactDetailsComponent,
  AddSubscriptionDetailsComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  customerId = Guid.EMPTY as unknown as Guid;

  selectedIndex = 0;
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  hidden = false;

  moduleName = ModuleName.Customers;
  moduleId = Modules.Customers;
  enumModulesID = Modules;
  getModuleName?: string;
  accountData: AccountModel;
  customerList: SideListModel[];
  headerText = '';
  showPaginator = true;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @Output()
  readonly triggerAccountDetails = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddContactDetailsComponent, { static: true })
  contactDetails;

  @ViewChild(AddCommericalDetailsComponent, { static: true })
  commericalDetails;

  @ViewChild(AddSubscriptionDetailsComponent, { static: true })
  subscriptionDetails;

  triggerEditData: Subject<any> = new Subject<any>();
  subscriptionRouting: Subscription;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;
  highlightRow: HighlightRow;
  dialogRef: MatDialogRef<CustomerComponent>;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.dialogRef = injector.get<MatDialogRef<CustomerComponent>>(MatDialogRef<CustomerComponent>);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.customerId = atob(params.get('id')!) as unknown as Guid;
          this.editCustomer();

        }
        else {
          this.customerId = Guid.EMPTY as unknown as Guid;
          this.headerText = 'Add New Customer';

        }
      }
    );

  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }


  tabClick(tab) {
    this.selectedIndex = tab.index;
    window.dispatchEvent(new Event('resize'));
  }

  editCustomer(): void {
   
    this.store
      .dispatch(new GetDataByAccountId(this.customerId))
      .subscribe((res) => {
        this.headerText = res.account.accountData.name;
        this.contactDetails.contactDetailForm.markAsUntouched();
        this.commericalDetails.commericalDetailsForm.markAsUntouched();
        this.subscriptionDetails.subscriptionForm.markAsUntouched();
        this.triggerEditData.next(res.account.accountData);
      });

    setTimeout(() => {
      const data = {
        id: this.customerId,
        moduleId: this.moduleId
      }
      this.triggerAccountDetails.emit(data);
    }, 0);

  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.customerId,
      isExit,
      Modules.Customers,
      RoutingPath.AddCustomers
    );
  }

  onSave(isExit: boolean): void {
    if (this.contactDetails.contactDetailForm.invalid) {
      this.commonService.addValidation(
        this.contactDetails.contactDetailForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateContact(this.accountData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);
                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.highlightRow.mainListHighlighted.moduleId =
                    this.moduleId;
                  this.location.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.accountData = {
        id: this.customerId,
        name: this.contactDetails.contactDetailForm.controls.name.value,
        tradingName:
          this.contactDetails.contactDetailForm.controls.tradingName.value,
        address1:
          this.contactDetails.contactDetailForm.controls.addressLine1.value,
        address2:
          this.contactDetails.contactDetailForm.controls.addressLine2.value,
        city: this.contactDetails.contactDetailForm.controls.city.value,
        address3:
          this.contactDetails.contactDetailForm.controls.addressLine3.value,
        countryId: this.contactDetails.contactDetailForm.controls.country.value,
        postalCode:
          this.contactDetails.contactDetailForm.controls.postCode.value,
        phone: this.contactDetails.contactDetailForm.controls.phone.value,
        email: this.contactDetails.contactDetailForm.controls.email.value,
        mobileNumber:
          this.contactDetails.contactDetailForm.controls.mobileNumber.value,
        fax: this.contactDetails.contactDetailForm.controls.fax.value,
        vatRegistrationNumber:
          this.commericalDetails.commericalDetailsForm.controls
            .vatRegistrationNumber.value,
        bankAccountNumber:
          this.commericalDetails.commericalDetailsForm.controls.accountNumber
            .value,
        bankAccountName:
          this.commericalDetails.commericalDetailsForm.controls.accountName
            .value,
        bankBranchCode:
          this.commericalDetails.commericalDetailsForm.controls.branchCode
            .value,
        bankAccountIBANCode:
          this.commericalDetails.commericalDetailsForm.controls.accountIBANCode
            .value,
        termId:
          this.commericalDetails.commericalDetailsForm.controls.terms.value,

        membershipStatus:
          +this.subscriptionDetails.subscriptionForm.controls.isActive.value ===
            0
            ? 'Inactive'
            : 'Active',
        membershipStartDate: this.datepipe
          .transform(
            this.subscriptionDetails.subscriptionForm.controls.startDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        membershipEndDate: this.datepipe
          .transform(
            this.subscriptionDetails.subscriptionForm.controls.endDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        membershipNote:
          this.subscriptionDetails.subscriptionForm.controls.note.value,
        accountTypeId: this.commonService.getEntityTypeId(this.moduleId),
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.customerId)) {
      this.editCustomer();
    } else {
      this.customerId = Guid.EMPTY as unknown as Guid;
      this.contactDetails.ngOnInit();
      this.commericalDetails.ngOnInit();
      this.subscriptionDetails.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
