import { Guid } from 'guid-typescript';

export class BankAccount {
  id: Guid;
  name: string;
  bankAccountNumber: string;
  bankAccountIBANCode: string;
  bankBranchCode: string;
  bankAccountType: string;
  companyId: Guid;
  unExplained: number;
  explained: number;
  balance: number;
  isDefault?: boolean;
  isCardSelected?: boolean;
}

export class BankFeeds {
  id?: Guid;
  accountId?: string;
  accountName?: string;
  accountType?: string;
  currency?: string;
  companyId?: string;
  accountNo?: string;
  branchCode?: string;
  accountCode?: string;
  providerId?: string;
  providerName?: string;
  logoUrl?: string;
  amount?: number;
  isExpired?: boolean;
  isDefault?: boolean;
  isCardSelected?: boolean;
  credited?: number;
  debited?: number;
  currentBalance?: number;
  createdOn?: Date;
  isSelected?: boolean;
  stateId?: Guid;
}

export class Account {
  updateTimestamp: Date;
  accountId: string;
  accountType: string;
  displayName: string;
  currency: string;
  accountNumber: AccountNumber;
  provider: Provider;
  isSelected: boolean;
}

export class AccountNumber {
  iban: string;
  swiftBic: string;
  number: number;
  sortCode: string;
}

export class Provider {
  displayName: string;
  providerId: string;
  logoUri: string;
}

export interface ExtendConnectionTokenUserDataPayload {
  id?: string;
  name: string;
  email: string;
  phone: string;
}

export class ManualImportItemModel {
  transactionDate?: string;
  notes: string;
  receivedAmount?: number;
  paymentAmount?: number;
}
