import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentName } from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { ModulePermission } from '@app/core/Services';
import { Export, GetCreditorDebtorReport } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-report-creditor-debitor',
  templateUrl: './report-creditor-debitor.component.html',
  styleUrls: ['./report-creditor-debitor.component.scss'],
})
export class ReportCreditorDebtorComponent implements OnInit {
  listParameters: MainListParameters = new MainListParameters();

  ids: Array<Guid>;
  moduleId: number;

  triggerExpandedCollapseList: Subject<any> = new Subject<any>();

  constructor(
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe,
    private modulePermission: ModulePermission,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
      if (
        value.data.url === this.router.url.slice(1) &&
        value.componentName === ComponentName.ReportCreditorDebtorComponent
      ) {
        this.getList();
      }
    });
  }

  getDataFromHeader(data: any): void {
    data.ids = this.ids;
    if (data.search === '') {
      this.spinner.show();
    }
    this.listParameters = data;
    !this.listParameters.format
      ? this.getList()
      : this.export(this.listParameters.format, this.listParameters.isPrint);
  }

  getList(): void {
    const queryParams: any = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    this.store
      .dispatch(new GetCreditorDebtorReport(queryParams, this.moduleId))
      .subscribe((res) => {
        const param = {
          data: res.common.mainList.resultSet.data,
          columns: res.common.mainList.resultSet.columns,
        };
        this.triggerExpandedCollapseList.next(param);
      });
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();

    const queryParams: any = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId,
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.ids,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      format: format,
      isPrint: isPrint,
    };

    this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
  }
}
