import { Guid } from 'guid-typescript';

export class GiftAid {
  accountingPeriodId: Guid;
  fileURL: any;
}

export class GiftAIDsettings {
  referenceNo: string;
  firstName: string;
  lastName: string;
  postCode: string;
  phone: string;
  senderType?: string;
  registrationNo?: string;
}
