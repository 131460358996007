<div class="content-header">
  <div class="toolbar">
    <button mat-button [matMenuTriggerFor]="menu" disableRipple>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="action-menu more-menu" xPosition="before">
      <button mat-menu-item (click)="export(exportType.PDF)" disableRipple>
        <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon
        >Export As PDF
      </button>

      <button mat-menu-item (click)="export(exportType.Excel)" disableRipple>
        <mat-icon>border_all</mat-icon>Export to excel
      </button>

      <button mat-menu-item (click)="export(exportType.CSV)" disableRipple>
        <mat-icon fontSet="material-icons-outlined">description</mat-icon>Export
        to CSV
      </button>
    </mat-menu>
  </div>
</div>
<table
  mat-table
  matSort
  aria-describedby="Import Success List"
  [dataSource]="subContactorList"
>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef class="w-50">
      <mat-checkbox
        [(ngModel)]="isAllSelected"
        (change)="selectAll($event)"
        disableRipple
      ></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let element; let i = index">
      <div class="d-flex align-items-center gap-10">
        <mat-checkbox
          [(ngModel)]="element.isIncluded"
          (change)="checkSelectAll($event)"
        >
        </mat-checkbox>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Donation No">
    <th mat-header-cell *matHeaderCellDef>Donation No</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.reference }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Donation Date">
    <th mat-header-cell *matHeaderCellDef>Donation Date</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.donationDate | date: "dd-LLL-yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="First Name">
    <th mat-header-cell *matHeaderCellDef>First Name</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.firstName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Last Name">
    <th mat-header-cell *matHeaderCellDef>Last Name</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.lastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Address">
    <th mat-header-cell *matHeaderCellDef>Address</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.address }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Post Code">
    <th mat-header-cell *matHeaderCellDef>Post Code</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.postCode }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Amount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element; let i = index">
      £ {{ element.donationAmount | numberPipe }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Deposit To">
    <th mat-header-cell *matHeaderCellDef>Deposit To</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.depositTo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Activity">
    <th mat-header-cell *matHeaderCellDef>Activity</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.activity }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Sponsored Event">
    <th mat-header-cell *matHeaderCellDef>Sponsored Event</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.sponsorEvent }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
