import { ReceiptModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateReceipt {
  static readonly type = '[RECEIPT] Create Receipt';

  constructor(public receipt: ReceiptModel) {}
}

export class GetDataByReceiptId {
  static readonly type = '[RECEIPT] Get Receipt Data By Receipt Id';

  constructor(public receiptId: Guid, public moduleId: number) {}
}

export class ArchiveAndRestoreReceipt {
  static readonly type = '[RECEIPT] Archive and Restore Selected Receipt';

  constructor(public receiptIds: Array<Guid>, public isArchive: boolean) {}
}

export class ArchiveAndRestoreCashEntry {
  static readonly type = '[CASHENTRY] Archive and Restore Selected Cash Entry';

  constructor(public cashEntryIds: Array<Guid>, public isArchive: boolean) {}
}

export class ArchiveAndRestoreBankEntry {
  static readonly type = '[BANKENTRY] Archive and Restore Selected Bank Entry';

  constructor(public bankEntryIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteReceipt {
  static readonly type = '[RECEIPT] Delete Selected Receipt';

  constructor(public receiptIds: Array<Guid>, public moduleId: number) {}
}

export class GetInvoiceBasedOnCustomer {
  static readonly type = '[RECEIPT] Get Invoice based on Customer';

  constructor(public customerId: Guid) {}
}

export class GetBillBasedOnCustomer {
  static readonly type = '[RECEIPT] Get Bill based on Customer';

  constructor(public supplierId: Guid) {}
}

export class GetInvoiceReceipt {
  static readonly type = '[RECEIPT] Get Invoice Receipt';

  constructor(public invoiceId: Guid) {}
}

export class GetBillReceipt {
  static readonly type = '[RECEIPT] Get Bill Receipt';

  constructor(public billId: Guid) {}
}

export class GetReceiptPayment {
  static readonly type = '[RECEIPT] Get Receipt/Bill List';

  constructor(public accountId: Guid) {}
}

export class GetBillBasedOnSupplier {
  static readonly type = '[RECEIPT] Get Bill based on Supplier';

  constructor(public supplierId: Guid) {}
}

export class GetBillDetail {
  static readonly type = '[RECEIPT] Get Bill Detail';

  constructor(public billId: Guid) {}
}

export class GetBankAccountListForBankEntryAndCashEntry {
  static readonly type =
    '[ACCOUNT] Get Bank Account List For Bank Entry And Cash Entry';

  constructor(public entityId: any) {}
}

export class QuickReceiptAdd {
  static readonly type = '[ACCOUNT] Quick Receipt Add';

  constructor(public quickReceipt: any) {}
}
