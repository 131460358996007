import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note11',
  templateUrl: './note11.component.html',
  styleUrls: ['./note11.component.scss'],
})
export class Note11Component implements OnInit {
  note11Form: FormGroup;
  calculativeDate: any;

  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      if (data.noteId === Notes.Note11) {
        this.calculativeDate = data.response.calculativeData;
        if (
          data.response.userInputData !== null &&
          data.response.userInputData !== undefined &&
          data.response.userInputData.data !== null &&
          data.response.userInputData.data !== undefined
        ) {
          this.editNotes11(data.response.userInputData.data);
        }
      }
    });
  }
  editNotes11(res): void {
    this.note11Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : '',
    });
  }

  setForm(): void {
    this.note11Form = new FormGroup({
      textbox1: new FormControl(''),
    });
  }
}
