import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-details-footer',
  templateUrl: './product-details-footer.component.html',
  styleUrls: ['./product-details-footer.component.scss'],
})
export class ProductDetailsFooterComponent {
  @Input()
  showBalanceDue: boolean = true;

  @Input()
  total: number;

  @Input()
  balanceAmount: number;
}
