import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FundOverview, Modules, OverviewYear } from '@app/core/Enum';
import { FilterDateRange, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetFundCardData, GetFundTypeList } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-fund-overview',
  templateUrl: './fund-overview.component.html',
  styleUrls: ['./fund-overview.component.scss'],
})
export class FundOverviewComponent implements OnInit {
  fundIdForBreakdown: number;
  fundIdForIncomeVsExpenses: number;
  moduleId = Modules.FundOverview;
  rangeFormGroup: FormGroup;
  selectYear = OverviewYear;
  fundTypeList: SideListModel[];

  selectedYearValue = OverviewYear['Last 30 days'];

  fundCardData: any;
  dateRange: any;
  totalForward = 0;
  totalIncome = 0;
  totalExpense = 0;
  totalTransfer = 0;
  totalBalance = 0;

  showCalender = false;

  @Output()
  readonly triggerHeaderFilter = new EventEmitter<any>();

  @Output()
  readonly triggerHeaderFilterForFundBreakdown = new EventEmitter<any>();

  @Output()
  readonly triggerHeaderFilterForIncomeVsExpenses = new EventEmitter<any>();

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    public commonService: CommonService,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );

    this.getFundCardData();
    this.setForm();
  }

  setForm(): void {
    this.getFundTypeList();

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  triggerWidgetClick(data: any): void {
    this.fundIdForBreakdown = data.id;
    this.onFundTypeChangeForBreakDown();
  }

  setFundBreakDown(fundTypeId: any): void {
    this.fundIdForBreakdown = fundTypeId;
    this.onFundTypeChangeForBreakDown();
  }

  getFundTypeList(): void {
    this.store
      .dispatch(new GetFundTypeList())
      .pipe(
        tap((res) => {
          this.fundTypeList = res.account.fundTypeList;
          this.fundIdForBreakdown = this.fundTypeList[0].id;
          this.fundIdForIncomeVsExpenses = this.fundTypeList[0].id;
          this.getData();
        })
      )
      .subscribe();
  }

  onSelectionChange(val: any): void {
    this.showCalender = +val === 7;
    this.dateRange = this.commonService.generateDateSeries(val);

    if (
      this.showCalender &&
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.dateRange = new FilterDateRange();
      this.dateRange.startDate = new Date(
        this.rangeFormGroup.controls.start.value
      );
      this.dateRange.endDate = new Date(this.rangeFormGroup.controls.end.value);
      this.getData();
    } else if (!this.showCalender) {
      this.getData();
    }
    this.getFundCardData();
    this.onFundTypeChangeForBreakDown();
  }

  onFundTypeChangeForBreakDown(): void {
    const data = this.getFilter();
    data.moduleId = this.fundIdForBreakdown;
    this.triggerHeaderFilterForFundBreakdown.emit(data);
  }

  onFundTypeChangeForIncomeVsExpenses(): void {
    const data = this.getFilter();
    data.moduleId = this.fundIdForIncomeVsExpenses;
    this.triggerHeaderFilterForIncomeVsExpenses.emit(data);
  }

  getFilter(): any {
    const filterData = {
      moduleId: 0,
      dateFilter: {
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(
                  this.rangeFormGroup.controls.start.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),

        endDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(this.rangeFormGroup.controls.end.value, 'yyyy-MM-dd')
                ?.toString(),
      },
    };

    return filterData;
  }

  getData(): void {
    const data = this.getFilter();

    setTimeout(() => {
      this.triggerHeaderFilter.emit(data);

      data.moduleId = this.fundIdForIncomeVsExpenses;
      this.triggerHeaderFilterForIncomeVsExpenses.emit(data);
    }, 500);
  }

  getFundCardData(): void {
    const filterData = {
      moduleId: 0,
      dateFilter: {
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? undefined
            : this.rangeFormGroup.controls.start.value,
        endDate:
          this.selectedYearValue < 7
            ? undefined
            : this.rangeFormGroup.controls.end.value,
      },
    };

    this.store
      .dispatch(new GetFundCardData(filterData))
      .pipe(
        tap((res) => {
          this.fundCardData = res.fund.fundCardData;
          this.totalForward = this.fundCardData.card.totalAmount;
          this.totalIncome = this.fundCardData.card1.totalAmount;
          this.totalExpense = this.fundCardData.card2.totalAmount;
          this.totalTransfer = this.fundCardData.card3.totalAmount;
          this.totalBalance = this.fundCardData.card4.totalAmount;
        })
      )
      .subscribe();
  }
}
