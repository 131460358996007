import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {
  GroupNames,
  MaxLength,
  Modules,
  NotificationTextMessage,
  TransactionStatus,
} from '@app/core/Enum';
import { SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetInvoiceBasedOnCustomer,
  GetInvoiceReceipt,
  GetBillBasedOnSupplier,
  GetBillDetail,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { CleanAllLinesComponent } from '@app/modules/common';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

export function scrollFactory1(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
@Component({
  selector: 'app-receipt-details',
  templateUrl: './receipt-details.component.html',
  styleUrls: ['./receipt-details.component.scss'],
})
export class ReceiptDetailsComponent implements OnInit {
  receiptDetailForm: FormGroup;
  formReceiptDetail: UntypedFormGroup;

  invoiceList: any[] = [];
  tempInvoiceList: SideListModel[] = [];
  resetInvoiceList: any[] = [];
  selectedInvoiceIds: any[] = [];
  tempSelectedInvoiceIds: any[] = [];
  receiptData: any[];
  maxLength = MaxLength;

  receiptDetailArray: any;

  @Input() triggerCustomer: Observable<any>;

  @Input() triggerReceiptDetail: Observable<any>;
  @Input() getModuleId: number;
  @Input() amountReceivedValue: number;
  @Input() triggerTransactionLogData: Observable<any>;

  @Output() amountReceived = new EventEmitter<any>();

  totalAmount = 0;
  customerId = Guid.EMPTY as unknown as Guid;

  moduleId = Modules;
  invoiceText: string;
  amountText: string;
  headerText: string;
  isCredit = true;
  isReceiptDetailValid = true;

  //#endregion

  tableDataSource: MatTableDataSource<AbstractControl>;
  displayProductDetailsColumns: string[] = [
    'invoiceNo',
    'invoiceReceipt',
    'note',
    'invoiceAmount',
    'dueAmount',
    'amount',
    'closeButton',
  ];
  isEdit = false;
  accountGroupId: number;
  isFromBankImport = false;
  transActionAmount: number;

  private destroy$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    public commonService: CommonService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.setReceiptDetailsForm(true);
    this.triggerCustomer?.subscribe((data) => {
      const receiptLength = this.receiptDetailArray.length;
      this.customerId = data.controls.customerName.value;
      for (let i = 0; i < receiptLength; i++) {
        this.receiptDetailArray.removeAt(i);
        this.setDataSource(this.receiptDetailArray);
      }
      this.setReceiptDetailsForm(true);

      if (
        data.controls.transactionType.value !== null &&
        data.controls.transactionType.value !== undefined
      ) {
        this.setIsCredit(data.controls.transactionType.value);
      } else {
        this.accountGroupId = data.controls.accountGroupId.value;
        this.setLabelText();
      }
    });

    this.triggerReceiptDetail
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isEdit = true;
        this.customerId = data.accountId;
        this.setIsCredit(data.isCredit);
        this.spinner.show();
        setTimeout(() => {
          this.editReceiptDetail(data.receiptItems);
        }, 1500);
      });

    this.triggerTransactionLogData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((item) => {
        this.isFromBankImport = true;
        this.transActionAmount = item.amount;
      });
    this.formReceiptDetail?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formReceiptDetail.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setIsCredit(data?: any): void {
    if (
      this.getModuleId === Modules.CashEntry ||
      this.getModuleId === Modules.BankEntry
    ) {
      this.isCredit = data === 0 ? false : true;
    } else {
      this.isCredit = this.getModuleId === Modules.Payment ? false : true;
    }

    this.setLabelText(
      this.isCredit ? TransactionStatus.Receipt : TransactionStatus.Payment
    );
  }

  setLabelText(transactionType?: number): void {
    if (
      this.getModuleId === Modules.Payment ||
      transactionType === TransactionStatus.Payment ||
      this.accountGroupId === GroupNames.CurrentLiabilities ||
      this.accountGroupId === GroupNames.NonCurrentLiabilities
    ) {
      this.invoiceText = 'Bill No';
      this.headerText = 'Bill Details';
      this.amountText = 'Payment Amount';
      this.getBillbySupplier();
    } else if (
      this.getModuleId === Modules.Receipt ||
      transactionType === TransactionStatus.Receipt ||
      this.accountGroupId === GroupNames.CurrentAssets_Debtors
    ) {
      this.invoiceText = 'Invoice No';
      this.headerText = 'Invoice Details';
      this.amountText = 'Invoice Amount';
      this.getInvoicebyCustomer();
    }
  }

  editReceiptDetail(data: any): void {
    this.receiptData = [];

    this.receiptDetailArray = this.formReceiptDetail.get(
      'receiptDetailArray'
    ) as UntypedFormArray;

    this.receiptDetailArray.clear();

    data.forEach((element) => {
      this.receiptData?.push(element);
    });

    this.receiptData.forEach((item, i) => {
      this.receiptDetailArray.push(this.buildOrderItemsForm(item));
      this.onInvoiceChange(item.invoiceId, i);
      this.setDropDown();
    });

    if (this.getModuleId === Modules.Receipt) {
      this.resetDropdown();
    }

    this.onAmountChange();
    this.setDataSource(this.receiptDetailArray);
    this.spinner.hide();
  }

  buildOrderItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      invoiceReceipt: item.invoiceId,
      note: '',
      invoiceAmount: 0,
      dueAmount: 0,
      amount: item.amount,
      invoiceTypeId: item.invoiceTypeId,
    });
  }

  getBillbySupplier(): void {
    const emptyCheck: any = this.customerId;
    if (
      emptyCheck !== '' &&
      this.customerId !== undefined &&
      this.customerId !== null
    ) {
      this.store
        .dispatch(new GetBillBasedOnSupplier(this.customerId))
        .subscribe((res) => {
          this.invoiceList[0] = res.receipt.billByCustomer;
          this.tempInvoiceList = res.receipt.billByCustomer;
          this.setDefaultData();
        });
    }
  }

  setDefaultData(): void {
    if (this.invoiceList.length > 0) {
      this.receiptDetailArray.controls[this.receiptDetailArray.length - 1]
        .get('invoiceReceipt')
        ?.setValue(this.invoiceList[0][0]?.id);
      this.onInvoiceChange(
        this.invoiceList[0][0]?.id,
        this.receiptDetailArray.length - 1
      );
    }
  }

  getInvoicebyCustomer(): void {
    const emptyCheck: any = this.customerId;
    if (
      emptyCheck !== '' &&
      this.customerId !== undefined &&
      this.customerId !== null
    ) {
      this.store
        .dispatch(new GetInvoiceBasedOnCustomer(this.customerId))
        .subscribe((res) => {
          this.invoiceList[0] = res.receipt.invoiceByCustomer;
          this.tempInvoiceList = res.receipt.invoiceByCustomer;
          this.setDefaultData();
        });
    }
  }

  onDeleteReceiptDetails(index: number): void {
    this.receiptDetailArray = this.formReceiptDetail.get(
      'receiptDetailArray'
    ) as UntypedFormArray;
    if (this.receiptDetailArray.length === 1) {
      return;
    }
    this.receiptDetailArray.removeAt(index);
    this.setDataSource(this.receiptDetailArray);
  }

  onAmountChange(): void {
    const formArray = this.formReceiptDetail.get(
      'receiptDetailArray'
    ) as UntypedFormArray;

    this.totalAmount = 0;

    formArray?.getRawValue().forEach((x) => {
      this.totalAmount = this.totalAmount + Number(x.amount);
    });

    this.amountReceived.emit(+this.totalAmount);
  }

  onInvoiceChange(event: any, index: number): void {
    if (this.selectedInvoiceIds.length > index) {
      this.selectedInvoiceIds[index] = event;
      this.tempSelectedInvoiceIds[index] = event;
    } else {
      this.selectedInvoiceIds.push(event);
      this.tempSelectedInvoiceIds.push(event);
    }

    if (event !== null && event !== undefined) {
      if (
        this.getModuleId === Modules.Payment ||
        !this.isCredit ||
        this.accountGroupId === GroupNames.CurrentLiabilities ||
        this.accountGroupId === GroupNames.NonCurrentLiabilities
      ) {
        this.store.dispatch(new GetBillDetail(event)).subscribe((res) => {
          this.setDataDisable(res.receipt.billReceiptData, index);
        });
      } else if (
        this.getModuleId === Modules.Receipt ||
        this.isCredit ||
        this.accountGroupId === GroupNames.CurrentAssets_Debtors
      ) {
        this.store.dispatch(new GetInvoiceReceipt(event)).subscribe((res) => {
          this.setDataDisable(res.receipt.invoiceReceiptData, index);
        });
      }
    }
  }

  setDataDisable(data: any, index: number): void {
    this.receiptDetailArray.controls[index]
      .get('invoiceTypeId')
      ?.setValue(data.invoiceTypeId);
    this.receiptDetailArray.controls[index].get('note')?.setValue(data.note);
    this.receiptDetailArray.controls[index]
      .get('invoiceAmount')
      ?.setValue(data.totalAmount);
    this.receiptDetailArray.controls[index]
      .get('dueAmount')
      ?.setValue(data.dueAmount);
    this.receiptDetailArray.controls[index].get('note').disable();
    this.receiptDetailArray.controls[index].get('invoiceAmount').disable();
    this.receiptDetailArray.controls[index].get('dueAmount').disable();
  }

  resetForm(): void {
    const frmArray = this.formReceiptDetail.get(
      'receiptDetailArray'
    ) as UntypedFormArray;
    frmArray.clear();
  }

  setReceiptDetailsForm(addNewRow: boolean): void {
    this.formReceiptDetail = new FormGroup({
      receiptDetailArray: new UntypedFormArray([]),
    });

    this.receiptDetailArray = this.formReceiptDetail.get(
      'receiptDetailArray'
    ) as UntypedFormArray;
    this.setDataSource(this.receiptDetailArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.receiptDetailArray = this.formReceiptDetail.get(
      'receiptDetailArray'
    ) as UntypedFormArray;
    this.receiptDetailArray.push(this.createItem());
    if (
      this.receiptDetailArray.length > 0 &&
      this.receiptDetailArray.length !== 1 &&
      !this.commonService.isEmpty(this.customerId)
    ) {
      this.setDropDown();
    }

    this.setDataSource(this.receiptDetailArray);
  }

  setDropDown(): void {
    if (Modules.Receipt === this.getModuleId) {
      this.resetInvoiceList = [];
      this.tempInvoiceList.forEach((element) => {
        const isAlreadySelected = this.selectedInvoiceIds.filter(
          (x) => x === element.id
        );
        if (isAlreadySelected.length === 0) {
          this.resetInvoiceList.push(element);
        }
      });
      this.invoiceList.push(this.resetInvoiceList);
      this.resetDropdown();
    } else {
      this.invoiceList.push(this.tempInvoiceList);
    }
  }
  resetDropdown(): void {
    this.invoiceList.forEach((res, i) => {
      this.resetInvoiceList = [];
      this.selectedInvoiceIds = [...this.tempSelectedInvoiceIds];
      const selectId = this.selectedInvoiceIds;
      selectId.splice(i, 1);
      res.forEach((element) => {
        const isAlreadySelected = selectId.filter((x) => x === element.id);
        if (isAlreadySelected.length === 0) {
          this.resetInvoiceList.push(element);
        }
      });
      this.invoiceList[i] = this.resetInvoiceList;
    });
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formReceiptDetail.get(
            'receiptDetailArray'
          ) as UntypedFormArray;

          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i].clearValidators();
            this.receiptDetailArray.controls[this.receiptDetailArray.length - 1]
              .get('invoiceReceipt')
              ?.setValue('');
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('invoiceAmount')?.setValue(0);
            formArray.controls[i].get('dueAmount')?.setValue(0);
            formArray.controls[i].get('amount')?.setValue(0);
            formArray.controls[i].updateValueAndValidity();
          }
          this.totalAmount = 0;
        }
      });
  }

  addNewRow(): void {
    if (
      this.getModuleId !== Modules.Receipt &&
      this.getModuleId !== Modules.Payment &&
      this.amountReceivedValue <= this.totalAmount
    ) {
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    }

    if (this.invoiceList.length > 0 && Modules.Receipt === this.getModuleId) {
      if (this.invoiceList[this.invoiceList.length - 1].length === 1) {
        this.commonService.onFailure(
          NotificationTextMessage.receiptSelectError
        );
        return;
      }
    }
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid>(Guid.EMPTY as unknown as Guid),
      invoiceNo: new FormControl<string | null>(''),
      invoiceReceipt: new FormControl('', [Validators.required]),
      note: new FormControl<string | null>(''),
      invoiceAmount: new FormControl<number | null>(0, [Validators.required]),
      dueAmount: new FormControl<number | null>(0, [Validators.required]),
      amount: new FormControl<number | null>(0, [Validators.required]),
      invoiceTypeId: new FormControl<number | null>(0),
    });
  }

  checkAmount(index: any): void {
    setTimeout(() => {
      const frmArray = this.formReceiptDetail.get(
        'receiptDetailArray'
      ) as UntypedFormArray;
      this.receiptDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            !this.isFromBankImport &&
            x.controls.amount.value > x.controls.dueAmount.value
          ) {
            this.receiptDetailArray.controls[index]
              .get('amount')
              ?.setValue(x.controls.dueAmount.value);
            this.onAmountChange();
            x.controls.amount.status = 'VALID';
          } else if (
            this.isFromBankImport &&
            x.controls.amount.value > this.transActionAmount
          ) {
            this.receiptDetailArray.controls[index].get('amount')?.setValue(0);
            this.onAmountChange();
          }

          c.markAsTouched();
        });
      });
    }, 500);
  }
}
