<div class="content-body">
  <form [formGroup]="commericalDetailsForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Terms</p>
        <ng-select
          #terms
          (focus)="terms.open()"
          (valueChange)="terms.close()"
          [disableOptionCentering]="true"
          formControlName="terms"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let tl of termsList" [value]="tl.id">
            <div title="{{ tl.name }}">{{ tl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>VAT Registration Number</p>
        <mat-form-field>
          <input
            matInput
            formControlName="vatRegistrationNumber"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Bank Account Name</p>
        <mat-form-field>
          <input
            matInput
            formControlName="accountName"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Bank Account Number</p>
        <mat-form-field>
          <input
            matInput
            formControlName="accountNumber"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Sort / Branch Code</p>
        <mat-form-field>
          <input
            matInput
            formControlName="branchCode"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Account IBAN Code</p>
        <mat-form-field>
          <input
            matInput
            formControlName="accountIBANCode"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
