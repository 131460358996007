import { EmailTemplateModel } from '@app/core/Models';

export class GetEmailTemplatesData {
  static readonly type = '[EMAILTEMPLATES] Get Email Template';
}

export class UpdateEmailTemplates {
  static readonly type = '[EMAILTEMPLATES] Update Template';
  constructor(public emailTemplatesData: Array<EmailTemplateModel>) {}
}
