import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { AddClosePopupComponent } from '@app/modules/common';
import { Observable, Subject, Subscription } from 'rxjs';

import { DatePipe, Location } from '@angular/common';
import {
  DebitNoteModel,
  PaymentDetailsModel,
  ProductDetailsModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateDebitNote,
  GetBankAccountTransactionData,
  GetDataByDebitNoteId,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import {
  AddDebitNoteComponent,
  CreditNoteAllocationComponent,
} from '@app/modules';
import {
  ProductDetailsComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
} from '@app/modules/transactions';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-debit-note',
  templateUrl: './debit-note.component.html',
  styleUrls: ['./debit-note.component.scss'],
})
export class DebitNoteComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  moduleId = Modules.DebitNote;
  moduleName = ModuleName.DebitNote;

  debitNoteId = Guid.EMPTY as unknown as Guid;
  amount = 0;
  balanceAmount = 0;
  totalAmount = 0;
  vatAmount = 0;
  amountReceivedTotal = 0;
  headerText = 'Receipt';
  date: any;

  debitNoteData: DebitNoteModel;
  productItems: Array<ProductDetailsModel>;
  receiptItems: Array<PaymentDetailsModel>;
  creditAllocationItems = new Array<any>();

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddDebitNoteComponent, { static: true })
  addDebitNoteDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  productDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  receiptInfo;

  @ViewChild(CreditNoteAllocationComponent, { static: true })
  creditNoteAllocationInfo;

  subscriptionRouting: Subscription;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  triggerEditData: Subject<any> = new Subject<any>();
  triggereDebitNoteEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggerCustomer: Subject<any> = new Subject<any>();
  triggereEditPaymentData: Subject<any> = new Subject<any>();
  triggerReceiptDetail: Subject<any> = new Subject<any>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();

  isFromBankImport = false;
  isManualBank = false;

  bankImportTransactionId = Guid.EMPTY as unknown as Guid;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');

          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
          }

          const id = atob(params.get('id')!) as unknown as Guid;

          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;
            if (this.isManualBank) {
              this.getTransactionLogData();
            } else {
              this.getBankAccountTransactionData();
            }
          } else {
            this.debitNoteId = id;
            this.editDebitNote();
          }
        }
      }
    );
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.addDebitNoteDetails.debitNoteForm.controls.entryDate.setValue(
          res.common.transactionLogData.entryDate
        );
        this.addDebitNoteDetails.debitNoteForm.controls.note.setValue(
          res.common.transactionLogData.note
        );
        this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.setValue(
          res.common.transactionLogData.amount
        );
        this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.disable();
      });
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.addDebitNoteDetails.debitNoteForm.controls.entryDate.setValue(
          res.common.transactionLogData.transactionDate
        );
        this.addDebitNoteDetails.debitNoteForm.controls.note.setValue(
          res.common.transactionLogData.description
        );
        this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.setValue(
          res.common.transactionLogData.amount
        );
        this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.disable();
      });
  }

  onSupplierChange(): void {
    this.creditNoteAllocationInfo.clearForm();
    for (
      let i = 0;
      i < this.creditNoteAllocationInfo.creditDetailArray.length;
      i++
    ) {
      this.creditNoteAllocationInfo.creditDetailArray.controls[i]
        .get('invoiceReceipt')
        .setValue(0);
      this.creditNoteAllocationInfo.creditDetailArray.controls[i]
        .get('id')
        .setValue(Guid.EMPTY as unknown as Guid);
    }

    this.triggerCustomer.next(this.addDebitNoteDetails.debitNoteForm);
  }

  editDebitNote(): void {
    this.store
      .dispatch(new GetDataByDebitNoteId(this.debitNoteId))
      .subscribe((res) => {
        this.addDebitNoteDetails.debitNoteForm.markAsUntouched();
        this.productDetails.formProductDetail.markAsUntouched();
        this.receiptInfo.paymentForm.markAsUntouched();
        this.creditNoteAllocationInfo.formCreditDetail.markAsUntouched();

        this.triggereDebitNoteEditData.next(res.debitNote.debitNoteData);
        this.triggereEditProductData.next(
          res.debitNote.debitNoteData.debitNoteItems
        );
        this.triggerEditData.next(res.debitNote.debitNoteData);
        this.triggereEditPaymentData.next(
          res.debitNote.debitNoteData.receiptDetails
        );
        this.triggerReceiptDetail.next(
          res.debitNote.debitNoteData.invoiceAllocations
        );
        this.triggerisVatIncluded.next(
          res.debitNote.debitNoteData.isVatIncluded
        );
      });
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.debitNoteId,
      isExit,
      Modules.DebitNote,
      RoutingPath.AddDebitNote
    );
  }

  onSave(isExit: boolean): void {
    if (this.addDebitNoteDetails.debitNoteForm.invalid) {
      this.commonService.addValidation(
        this.addDebitNoteDetails.debitNoteForm,
        this.renderer
      );
    } else if (
      !this.isFromBankImport &&
      this.receiptInfo.paymentArray.invalid
    ) {
      this.receiptInfo.paymentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (
      !this.isFromBankImport &&
      this.productDetails.totalAmount < this.receiptInfo.totalReciptAmount
    ) {
      this.receiptInfo.paymentArray.controls.forEach((x) => {
        (Object as any)
          .values(x.controls)
          .forEach((c) => c.markAsTouched(false));
        x.controls.amount.status = 'INVALID';
      });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (
      !this.isFromBankImport &&
      this.creditNoteAllocationInfo.creditDetailArray.invalid
    ) {
      this.creditNoteAllocationInfo.creditDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (
      !this.isFromBankImport &&
      this.productDetails.totalAmount <
        this.creditNoteAllocationInfo.totalAmount
    ) {
      this.creditNoteAllocationInfo.creditDetailArray.controls.forEach((x) => {
        (Object as any)
          .values(x.controls)
          .forEach((c) => c.markAsTouched(false));
        x.controls.amount.status = 'INVALID';
      });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (
      this.isFromBankImport &&
      this.productDetails.totalAmount <
        +this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.value
    ) {
      this.commonService.onFailure(NotificationTextMessage.amountTotalError);
    } else if (this.productDetails.productDetailArray.invalid) {
      this.productDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateDebitNote(this.debitNoteData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit && !this.isFromBankImport) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }
      this.productItems = new Array<ProductDetailsModel>();

      this.productDetails.productDetailArray?.getRawValue().forEach((x) => {
        const data = this.productDetails.productTypeData.filter(
          (a) => a.name === x.productName
        );
        if (data.length > 0) {
          x.productId = data[0].id;
        }

        this.productItems.push({
          id: x.id,
          productName: x.productName.id === undefined ? x.productName : '',
          productId:
            x.productName.id === undefined
              ? (Guid.EMPTY as unknown as Guid)
              : x.productName.id,
          description: x.description,
          quantity: +x.qty,
          price: +x.price,
          vatRateId: x.vatRate === -1 ? null : x.vatRate,
          vatAmount: +x.vatAmount,
          totalAmount: +x.amount,
        });
      });

      this.receiptItems = new Array<PaymentDetailsModel>();

      this.receiptInfo.paymentArray?.getRawValue().forEach((x) => {
        const attachmentList: any = [];
        if (
          x.attachment !== null &&
          x.attachment !== undefined &&
          x.attachment !== '' &&
          x.attachment.length > 0
        ) {
          x.attachment.forEach((element) => {
            attachmentList.push(element);
          });
        }

        if (x.accountId !== '' && x.amount !== '') {
          this.receiptItems.push({
            id: x.id,
            receiptId: x.receiptId,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            amount: x.amount,
            postingAccountId: x.accountId,
            attachment: attachmentList,
          });
        }
      });

      this.creditAllocationItems = new Array<any>();
      this.creditNoteAllocationInfo.creditDetailArray
        ?.getRawValue()
        .forEach((x) => {
          this.creditAllocationItems.push({
            id: x.id,
            toInvoiceId: x.invoiceReceipt,
            amount: +x.amount,
            toInvoiceTypeID: x.invoiceTypeId,
          });
        });

      this.debitNoteData = {
        id: this.debitNoteId,
        entryNumber:
          this.addDebitNoteDetails.debitNoteForm.controls.debitNoteNo.value,
        accountId:
          this.addDebitNoteDetails.debitNoteForm.controls.accountId.value,
        bookAccountId:
          this.addDebitNoteDetails.debitNoteForm.controls.debitNoteType.value
            .id,
        fundId: this.addDebitNoteDetails.debitNoteForm.controls.fundName.value,
        entryDate: this.datepipe
          .transform(
            this.addDebitNoteDetails.debitNoteForm.controls.entryDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        currencyId:
          this.addDebitNoteDetails.debitNoteForm.controls.currency.value,
        note: this.addDebitNoteDetails.debitNoteForm.controls.note.value,
        voucherNumber:
          this.addDebitNoteDetails.debitNoteForm.controls.billRefNo.value,
        attachment: filedata,
        debitNoteItems: this.productItems,
        receiptDetails: this.receiptItems,
        invoiceAllocations: this.creditAllocationItems,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
        isManualBank: this.isManualBank,
        isVatIncluded:
          this.addDebitNoteDetails.debitNoteForm.controls.isVatInclude.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.debitNoteId)) {
      this.editDebitNote();
    } else {
      this.debitNoteId = Guid.EMPTY as unknown as Guid;
      this.addDebitNoteDetails.ngOnInit();
      this.productDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
      this.receiptInfo.ngOnInit();
      this.creditNoteAllocationInfo.ngOnInit();
    }
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onAmountReceivedChange(): void {
    this.amountReceivedTotal = this.creditNoteAllocationInfo.totalAmount;
  }

  isVatIncludedClick(event): void {
    this.triggerisVatIncluded.next(event);
  }
}
