import { Injectable } from '@angular/core';
import { VATSettingsModel } from '@app/core/Models';
import { VATSettingsService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CreateVatDetail, GetVatDetail } from './vat-settings.action';

export class VATSettingsStateInfo {
  vatSettingsData?: VATSettingsModel;
}

@State<VATSettingsStateInfo>({
  name: 'vatSettings',
  defaults: {},
})
@Injectable()
export class VATSettingsState {
  constructor(private vatSettingsService: VATSettingsService) {}

  @Action(GetVatDetail)
  getVatDetail(
    { patchState }: StateContext<VATSettingsStateInfo>,
    action: GetVatDetail
  ) {
    return this.vatSettingsService.getVatDetail().pipe(
      tap((res) => {
        patchState({
          vatSettingsData: res,
        });
      })
    );
  }

  @Action(CreateVatDetail)
  createVatDetail(
    { patchState }: StateContext<VATSettingsStateInfo>,
    action: CreateVatDetail
  ) {
    return this.vatSettingsService.createVatDetail(action.vatSettings).pipe(
      tap((res) => {
        patchState({
          vatSettingsData: res,
        });
      })
    );
  }
}
