import { Injectable } from '@angular/core';
import { DonationModel, SideListModel } from '@app/core/Models';
import { DonationService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreDonation,
  CreateDonation,
  DeleteDonation,
  GetActivityList,
  GetDataByDonationId,
  GetDonationByDonationAndDonorOverview,
  GetDonationEndowmentFundDataOverview,
  GetDonationFundTypesOverview,
  GetDonationDonorsAndDonationOverview,
  GetDonationsFlowOverview,
  GetDonationsInKindOverview,
  GetSponsoreList,
} from './donation.action';
import { FundStateInfo } from '../../fund/fund';

export class DonationStateInfo {
  donationData?: DonationModel;
  donation: Array<DonationModel>;
  donationId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isDonationAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  sponsore: SideListModel[];
  activity: SideListModel[];
  donationFundTypesOverview?: any;
  donationDonorsAndDonationOverview?: any;
  donationsInKindOverview?: any;
  donationEndowmentFundDataOverview?: any;
  donationsFlowOverview?: any;
  donationsDonorOverview?: any;
}

@State<DonationStateInfo>({
  name: 'donation',
  defaults: {
    donation: [],
    sponsore: [],
    activity: [],
    donationId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isDonationAdded: false,
  },
})
@Injectable()
export class DonationState {
  constructor(private donationService: DonationService) {}

  @Selector()
  static isLastPage(state: DonationStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getDonationId(state: DonationStateInfo) {
    return state.donationId;
  }

  @Selector()
  static getSponsore(state: DonationStateInfo) {
    return state.sponsore;
  }

  @Selector()
  static getActivity(state: DonationStateInfo) {
    return state.activity;
  }

  @Action(CreateDonation)
  createDonation(
    { patchState }: StateContext<DonationStateInfo>,
    action: CreateDonation
  ) {
    return this.donationService.createDonation(action.donation).pipe(
      tap((res) => {
        patchState({
          donationId: res,
          isDonationAdded: true,
        });
      })
    );
  }

  @Action(GetDataByDonationId)
  getDataByDonationId(
    { patchState }: StateContext<DonationStateInfo>,
    action: GetDataByDonationId
  ) {
    return this.donationService.getDataByDonationId(action.donationId).pipe(
      tap((res) => {
        patchState({
          donationData: res,
        });
      })
    );
  }

  @Action(DeleteDonation)
  deleteDonation(
    { getState, patchState }: StateContext<DonationStateInfo>,
    action: DeleteDonation
  ) {
    return this.donationService.deleteDonation(action.donationIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredQuatation = state.donation.filter(
          (item) =>
            !action.donationIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.donationIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          ...state.donation,
          donation: filteredQuatation,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreDonation)
  archiveAndRestoreDonation(
    { getState }: StateContext<DonationStateInfo>,
    action: ArchiveAndRestoreDonation
  ) {
    return this.donationService.archiveAndRestoreDonation(
      action.donationIds,
      action.isArchive
    );
  }

  @Action(GetSponsoreList)
  getSponsoreList(
    { getState, patchState }: StateContext<DonationStateInfo>,
    action: any
  ) {
    return this.donationService.getSponsoreList().pipe(
      tap((res) => {
        patchState({
          sponsore: res,
        });
      })
    );
  }

  @Action(GetActivityList)
  getActivityList(
    { patchState }: StateContext<DonationStateInfo>,
    action: any
  ) {
    return this.donationService.getActivityList().pipe(
      tap((res) => {
        patchState({
          activity: res,
        });
      })
    );
  }

  @Action(GetDonationFundTypesOverview)
  getDonationFundTypesOverview(
    { patchState }: StateContext<DonationStateInfo>,
    action: GetDonationFundTypesOverview
  ) {
    return this.donationService
      .getDonationFundTypesOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            donationFundTypesOverview: res,
          });
        })
      );
  }

  @Action(GetDonationDonorsAndDonationOverview)
  getDonationDonorsAndDonationOverview(
    { patchState }: StateContext<DonationStateInfo>,
    action: GetDonationDonorsAndDonationOverview
  ) {
    return this.donationService
      .getDonationDonorsAndDonationOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            donationDonorsAndDonationOverview: res,
          });
        })
      );
  }

  @Action(GetDonationsInKindOverview)
  getDonationsInKindOverview(
    { patchState }: StateContext<DonationStateInfo>,
    action: GetDonationsInKindOverview
  ) {
    return this.donationService
      .getDonationsInKindOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            donationsInKindOverview: res,
          });
        })
      );
  }

  @Action(GetDonationEndowmentFundDataOverview)
  getDonationEndowmentFundDataOverview(
    { patchState }: StateContext<DonationStateInfo>,
    action: GetDonationEndowmentFundDataOverview
  ) {
    return this.donationService
      .getDonationEndowmentFundDataOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            donationEndowmentFundDataOverview: res,
          });
        })
      );
  }

  @Action(GetDonationsFlowOverview)
  getDonationsFlowOverview(
    { patchState }: StateContext<DonationStateInfo>,
    action: GetDonationsFlowOverview
  ) {
    return this.donationService
      .getDonationsFlowOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            donationsFlowOverview: res,
          });
        })
      );
  }

  @Action(GetDonationByDonationAndDonorOverview)
  getDonationByDonationAndDonorOverview(
    { patchState }: StateContext<DonationStateInfo>,
    action: GetDonationByDonationAndDonorOverview
  ) {
    return this.donationService
      .getDonationByDonationAndDonorOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            donationsDonorOverview: res,
          });
        })
      );
  }
}
