<div class="content-body mb-4">
  <p class="header-text d-flex justify-space-between align-items-center">
    {{ headerText }}
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </p>
  <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
    <mat-icon class="material-icons-outlined">error_outline</mat-icon>
    {{ commonNotificationText.ViewOnlyAccessMessage }}
  </div>

  <form [formGroup]="trusteesReportForm" class="p-0">
    <div class="content-right-button d-flex align-items-center gap-10 mb-1">
      <span class="fw-bold">Period: </span>
      <mat-form-field class="w-200">
        <mat-select
          panelClass="mat-select-position"
          [disableOptionCentering]="true"
          formControlName="accountingPeriod"
          (selectionChange)="editNotes()"
        >
          <mat-option
            *ngFor="let option of periodicDateList"
            [value]="option.id"
            title=" {{ option.fromDate | date: 'dd-LLL-yyyy' }} -
            {{ option.toDate | date: 'dd-LLL-yyyy' }}"
          >
            {{ option.fromDate | date: "dd-LLL-yyyy" }} -
            {{ option.toDate | date: "dd-LLL-yyyy" }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
      <p class="fw-bold">{{ headerText }}</p>
      <div>
        <ejs-richtexteditor
          [toolbarSettings]="
            commonService.syncfusionRichTextEditorToolbarSettings
          "
          (actionBegin)="
            commonService.syncfusionRichTextEditorOnActionBegin($event)
          "
          [enableXhtml]="true"
          [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
          formControlName="textbox1"
          [enableHtmlSanitizer]="true"
          [pasteCleanupSettings]="
            commonService.syncfusionRichTextEditorPasteCleanupSettings
          "
        ></ejs-richtexteditor>
      </div>
    </div>
  </form>
</div>
<app-buttons
  *ngIf="!(isViewPermission$ | async)"
  [getModuleId]="moduleId"
  (triggerOnSaveClick)="onSave()"
  (triggerOnCancelClick)="onCancel()"
  [saveButtonShow]="true"
>
</app-buttons>
