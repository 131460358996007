import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  AccountingMethod,
  ModuleName,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { Modules } from '@app/core/Enum/module';
import {
  FinancialData,
  GlobalComponent,
  InvoiceModel,
  PaymentDetailsModel,
  ProductDetailsModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  CreateInvoice,
  GetBankAccountTransactionData,
  GetDataByInvoiceId,
  GetInvoiceNumber,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddIncomeInvoiceComponent,
  AdvancePaymentComponent,
  ProductDetailsComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-income-invoice',
  templateUrl: './income-invoice.component.html',
  styleUrls: ['./income-invoice.component.scss'],
})
export class IncomeInvoiceComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  moduleId = Modules.Invoices;
  moduleName = ModuleName.Invoices;

  invoiceId = Guid.EMPTY as unknown as Guid;
  invoiceData: InvoiceModel;
  productItems: Array<ProductDetailsModel>;
  receiptItems: Array<PaymentDetailsModel>;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddIncomeInvoiceComponent, { static: true })
  incomeInvoiceDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  invoiceProductDetails;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  invoiceReceiptDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(AdvancePaymentComponent, { static: true })
  advancePaymentDetails;

  subscriptionRouting: Subscription;
  headerText = 'Receipt';

  triggerEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggereEditPaymentData: Subject<any> = new Subject<any>();
  triggerAdvancePayment: Subject<any> = new Subject<any>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();
  date: any;

  isFromBankImport = false;
  isManualBank = false;
  hasAdvanceAmount = false;
  accountingMethod: any;

  bankImportTransactionId = Guid.EMPTY as unknown as Guid;
  @Select(CommonState.periodicDate)
  periodicDate$: Observable<Array<FinancialData>>;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  isReceiptChangePermission: boolean = true;
  constructor(
    private injector: Injector,
    private globalComponent: GlobalComponent
  ) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.accountingMethod = this.globalComponent.getAccountingMethod();
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');
          const customId = atob(params.get('customId')!) as unknown as any;

          this.isFromBankImport = false;
          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
          }

          const id = atob(params.get('id')!) as unknown as Guid;

          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;

            setTimeout(() => {
              this.incomeInvoiceDetails.invoiceForm.controls.bankId.setValue(
                customId
              );
              this.incomeInvoiceDetails.invoiceForm.controls.bankId.disable();
            }, 1000);

            this.getInvoiceNumber();

            if (this.isManualBank) {
              this.getTransactionLogData();
            } else {
              this.getBankAccountTransactionData();
            }
          } else {
            this.invoiceId = id;
            this.editInvoice();
          }
        } else {
          this.invoiceId = Guid.EMPTY as unknown as Guid;
          this.getInvoiceNumber();
        }
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isReceiptChangePermission = this.commonService.checkPermission(
        Modules.Income,
        Modules.Receipt
      );
    }, 3000);
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.incomeInvoiceDetails.invoiceForm.controls.invoiceDate.setValue(
          res.common.transactionLogData.entryDate
        );
        this.incomeInvoiceDetails.invoiceForm.controls.notes.setValue(
          res.common.transactionLogData.note
        );
      });
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.incomeInvoiceDetails.invoiceForm.controls.invoiceDate.setValue(
          res.common.transactionLogData.transactionDate
        );
        this.incomeInvoiceDetails.invoiceForm.controls.notes.setValue(
          res.common.transactionLogData.description
        );
      });
  }

  getInvoiceNumber(): void {
    this.store.dispatch(new GetInvoiceNumber()).subscribe((res) => {
      res.invoice.invoiceNo !== null
        ? this.incomeInvoiceDetails.invoiceForm.controls.invoiceNo.disable()
        : this.incomeInvoiceDetails.invoiceForm.controls.invoiceNo.enable();
      this.incomeInvoiceDetails.invoiceForm.controls.invoiceNo.setValue(
        res.invoice.invoiceNo
      );
    });
  }

  editInvoice(): void {
    this.store
      .dispatch(new GetDataByInvoiceId(this.invoiceId))
      .subscribe((res) => {
        this.incomeInvoiceDetails.invoiceForm.markAsUntouched();
        this.invoiceProductDetails.formProductDetail.markAsUntouched();
        this.invoiceReceiptDetails.paymentForm.markAsUntouched();
        this.incomeInvoiceDetails.invoiceForm.controls.bankId.enable();
        this.triggerEditData.next(res.invoice.invoiceData);
        this.triggereEditProductData.next(res.invoice.invoiceData.items);
        this.triggereEditPaymentData.next(
          res.invoice.invoiceData.receiptDetails
        );
        this.triggerAdvancePayment.next(res.invoice.invoiceData.accountId);
        this.triggerisVatIncluded.next(res.invoice.invoiceData.isVatIncluded);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  reloadAutoNumberGenerator(): void {
    this.getInvoiceNumber();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.invoiceId,
      isExit,
      Modules.Invoices,
      RoutingPath.AddInvoice
    );
  }

  onSave(isExit: boolean): void {
    if (this.incomeInvoiceDetails.invoiceForm.invalid) {
      this.commonService.addValidation(
        this.incomeInvoiceDetails.invoiceForm,
        this.renderer
      );
    } else if (
      !this.isFromBankImport &&
      this.invoiceReceiptDetails.paymentArray.invalid
    ) {
      this.invoiceReceiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (
      !this.isFromBankImport &&
      this.invoiceProductDetails.totalAmount <
        this.invoiceReceiptDetails.totalReciptAmount
    ) {
      this.invoiceReceiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any)
          .values(x.controls)
          .forEach((c) => c.markAsTouched(false));
        x.controls.amount.status = 'INVALID';
      });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (this.invoiceProductDetails.productDetailArray.invalid) {
      this.invoiceProductDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else if (this.advancePaymentDetails.advancePaymentDetailForm.invalid) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      this.advancePaymentDetails.advancePaymentDetailForm.controls
        .allocateAdvance.value > this.invoiceProductDetails.totalAmount
    ) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      this.accountingMethod === AccountingMethod.CashBasis &&
      this.invoiceReceiptDetails.totalReciptAmount === 0 &&
      +this.advancePaymentDetails.allocatedAdvanceAmount === 0
    ) {
      this.commonService.onFailure(
        NotificationTextMessage.invoiceReceiptAllocationMissing
      );
      return;
    } else if (
      this.accountingMethod === AccountingMethod.CashBasis &&
      this.invoiceProductDetails.totalAmount !==
        +this.invoiceReceiptDetails.totalReciptAmount +
          +this.advancePaymentDetails.allocatedAdvanceAmount
    ) {
      this.commonService.onFailure(
        NotificationTextMessage.invoiceAndReceiptAmountAllocationMissMatch
      );
      return;
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateInvoice(this.invoiceData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit && !this.isFromBankImport) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.productItems = new Array<ProductDetailsModel>();
      this.receiptItems = new Array<PaymentDetailsModel>();

      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.invoiceProductDetails.productDetailArray
        ?.getRawValue()
        .forEach((x) => {
          this.productItems.push({
            id: x.id,
            productName: x.productName.id === undefined ? x.productName : '',
            productId:
              x.productName.id === undefined
                ? (Guid.EMPTY as unknown as Guid)
                : x.productName.id,
            description: x.description,
            quantity: +x.qty,
            price: +x.price,
            vatRateId: x.vatRate === -1 ? null : x.vatRate,
            vatAmount: +x.vatAmount,
            totalAmount: +x.amount,
          });
        });

      this.invoiceReceiptDetails.paymentArray?.getRawValue().forEach((x) => {
        const attachmentList: any = [];
        if (
          x.attachment !== null &&
          x.attachment !== undefined &&
          x.attachment !== '' &&
          x.attachment.length > 0
        ) {
          x.attachment.forEach((element) => {
            attachmentList.push(element);
          });
        }

        if (x.accountId !== '' && x.amount !== '') {
          this.receiptItems.push({
            id: x.id,
            receiptId: x.receiptId,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            amount: x.amount,
            postingAccountId: x.accountId,
            attachment: attachmentList,
          });
        }
      });

      this.invoiceData = {
        id: this.invoiceId,
        entryNumber:
          this.incomeInvoiceDetails.invoiceForm.controls.invoiceNo.value,
        entryDate: this.datepipe
          .transform(
            this.incomeInvoiceDetails.invoiceForm.controls.invoiceDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        dueDate: this.datepipe
          .transform(
            this.incomeInvoiceDetails.invoiceForm.controls.dueDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        currencyId:
          this.incomeInvoiceDetails.invoiceForm.controls.currency.value,
        accountId:
          this.incomeInvoiceDetails.invoiceForm.controls.customerName.value,
        note: this.incomeInvoiceDetails.invoiceForm.controls.notes.value,
        items: this.productItems,
        receiptDetails: this.receiptItems,
        bookAccountId:
          this.incomeInvoiceDetails.invoiceForm.controls.invoiceType.value.id,
        fundId: this.incomeInvoiceDetails.invoiceForm.controls.fundName.value,
        activityId:
          this.incomeInvoiceDetails.invoiceForm.controls.activity.value === ''
            ? null
            : this.incomeInvoiceDetails.invoiceForm.controls.activity.value,
        bankAccountId:
          this.incomeInvoiceDetails.invoiceForm.controls.bankId.value === ''
            ? null
            : this.incomeInvoiceDetails.invoiceForm.controls.bankId.value,
        attachment: filedata,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
        isManualBank: this.isManualBank,
        advanceReceiptAmount:
          +this.advancePaymentDetails.advancePaymentDetailForm.controls
            .allocateAdvance.value,
        isVatIncluded:
          this.incomeInvoiceDetails.invoiceForm.controls.isVatInclude.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.invoiceId)) {
      this.editInvoice();
    } else {
      this.invoiceId = Guid.EMPTY as unknown as Guid;
      this.incomeInvoiceDetails.ngOnInit();
      this.invoiceProductDetails.ngOnInit();
      this.invoiceReceiptDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
      this.getInvoiceNumber();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  customerClickChange(event) {
    this.triggerAdvancePayment.next(event);
  }

  isVatIncludedClick(event): void {
    this.triggerisVatIncluded.next(event);
  }

  onHasAdvanceAmountUpdated(updatedValue: boolean): void {
    this.hasAdvanceAmount = updatedValue;
  }
}
