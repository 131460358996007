import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note37',
  templateUrl: './note37.component.html',
  styleUrls: ['./note37.component.scss'],
})
export class Note37Component implements OnInit {
  note37Form: FormGroup;
  calculativeData: any;

  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      if (data.noteId === Notes.Note37) {
        this.calculativeData = data.response.calculativeData;
        if (
          data.response.userInputData !== null &&
          data.response.userInputData !== undefined &&
          data.response.userInputData.data !== null &&
          data.response.userInputData.data !== undefined
        ) {
          this.editNotes37(data.response.userInputData.data);
        }
      }
    });
  }
  editNotes37(res): void {
    this.note37Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : '',
    });
  }

  setForm(): void {
    this.note37Form = new FormGroup({
      textbox1: new FormControl(''),
    });
  }
}
