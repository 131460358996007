import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DonationModel,
  OverviewFilterModel,
  SideListModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class DonationService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createDonation(donation: DonationModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Donation/Create`,
      this.commonService.trimObjectSpace(JSON.stringify(donation)),
      headers
    );
  }

  getDataByDonationId(donationId: Guid): Observable<DonationModel> {
    return this.http.get<DonationModel>(
      `${environment.apiVersionUrl}Donation/get/${donationId}`
    );
  }

  deleteDonation(donationIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(donationIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Donation/delete`,
      options
    );
  }

  copyDonation(donationIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Donation/copyDonations`,
      this.commonService.trimObjectSpace(JSON.stringify(donationIds)),
      headers
    );
  }

  archiveAndRestoreDonation(
    donationIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Donation/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(donationIds)),
      options
    );
  }

  getSponsoreList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}SponsorEvent/all`
    );
  }

  getActivityList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Activity/all`
    );
  }

  getDonationFundTypesOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/donation/donationByFundType`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDonationDonorsAndDonationOverview(
    filterData: OverviewFilterModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/donation/donationFundDataFundType`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDonationsInKindOverview(filterData: OverviewFilterModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/donation/donationInKind`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDonationEndowmentFundDataOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fund/fundDataByFundTypeId`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDonationsFlowOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/donation/donationFlow`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDonationByDonationAndDonorOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/donation/donationByDonationAndDonor
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }
}
