<div class="import-setting-wrapper bank-import">
  <div
    class="content-body content-body-scroll import-setting"
    [ngClass]="{
      'import-setting-with-pagination':
        (totalRecord$ | async) > 10 && showPaginator,
      'import-setting-without-pagination': !showPaginator
    }"
  >
    <p class="header-text d-flex justify-space-between align-items-center">
      Bank Import
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>

    <div>
      <ng-select
        class="w-250"
        #bankName
        (focus)="bankName.open()"
        (valueChange)="bankName.close()"
        [disableOptionCentering]="true"
        [(ngModel)]="customId"
        panelClass="mat-select-position"
        (change)="onbankNameChange($event)"
      >
        <ng-option *ngFor="let ba of bankAccountList" [value]="ba.id">
          <div title="{{ ba.name }}">{{ ba.name }}</div>
        </ng-option>
      </ng-select>

      <div
        class="upload-detail"
        id="drop-area"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
      >
        <div class="import-leads mb-2">
          <div class="file-box" style="width: -webkit-fill-available">
            <div class="drop-detail">
              <p>Drag and drop files to upload</p>
              <h4><span>or</span></h4>
              <button class="file-upload-button primary-button">
                <input
                  type="file"
                  #fileInput
                  accept=".xls, .xlsx"
                  (change)="onFileSelected(fileInput)"
                  (click)="onFileOpen($event)"
                />Browse
              </button>
              <div
                class="file-info d-flex align-items-center justify-space-between mt-10 mb-10 gap-10"
                *ngIf="fileInfo !== ''"
              >
                <p class="m-0">{{ fileInfo }}</p>
                <button
                  class="file-remove-button"
                  type="button"
                  (click)="onFileRemoveClick()"
                  mat-button
                  disableRipple
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <div
                class="d-flex align-items-center justify-content-center gap-5 cursor-pointer mt-10"
                (click)="downloadTemplateFile()"
              >
                <mat-icon fontSet="material-icons-outlined"
                  >file_download</mat-icon
                >
                <p class="m-0 link">Download sample file</p>
              </div>
              <div class="file-formate">
                <p>File Format : XLSX</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fw-bold mb-1">
      <span>Import History</span>
      <app-report-list
        [isBankImport]="true"
        [triggerBankAccountId]="triggerBankAccountId"
        (toggleShowPaginator)="togglePaginator($event)"
      ></app-report-list>
    </div>
  </div>
  <app-buttons
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave()"
    (triggerOnCancelClick)="onCancel()"
  >
  </app-buttons>
</div>
