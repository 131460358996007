import { Injectable } from '@angular/core';
import { RecurringModule, SideListModel } from '@app/core/Models';
import { RecurringModuleService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreRecurring,
  CreateRecurringModule,
  DeleteRecurringData,
  GetDataByRecurringModuleId,
  GetRecurringNumber,
} from './recurring-module.action';

export class RecurringModuleStateInfo {
  recurringData?: RecurringModule;
  recurring: Array<RecurringModule>;
  recurringId: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  totalRecord?: number;
  isLastPage?: boolean;
  recurringNo: string;
}

@State<RecurringModuleStateInfo>({
  name: 'recurring',
  defaults: {
    recurring: [],
    recurringNo: '',
    recurringId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
  },
})
@Injectable()
export class RecurringModuleState {
  constructor(private recurringService: RecurringModuleService) {}

  @Selector()
  static isLastPage(state: RecurringModuleStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getInvoiceId(state: RecurringModuleStateInfo) {
    return state.recurringId;
  }

  @Action(CreateRecurringModule)
  createRecurringModule(
    { patchState }: StateContext<RecurringModuleStateInfo>,
    action: CreateRecurringModule
  ) {
    return this.recurringService.createRecurringModule(action.recurring).pipe(
      tap((res) => {
        patchState({
          recurringId: res,
        });
      })
    );
  }

  @Action(GetDataByRecurringModuleId)
  getDataByRecurringModuleId(
    { patchState }: StateContext<RecurringModuleStateInfo>,
    action: GetDataByRecurringModuleId
  ) {
    return this.recurringService
      .getDataByRecurringModuleId(action.recurringId)
      .pipe(
        tap((res) => {
          patchState({
            recurringData: res,
          });
        })
      );
  }

  @Action(DeleteRecurringData)
  deleteRecurringData(
    { getState, patchState }: StateContext<RecurringModuleStateInfo>,
    action: DeleteRecurringData
  ) {
    return this.recurringService.deleteRecurringData(action.recurringIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredQuatation = state.recurring.filter(
          (item) =>
            !action.recurringIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.recurringIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          ...state.recurring,
          recurring: filteredQuatation,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreRecurring)
  archiveAndRestoreRecurring(
    { getState }: StateContext<RecurringModuleStateInfo>,
    action: ArchiveAndRestoreRecurring
  ) {
    return this.recurringService.archiveAndRestoreRecurring(
      action.recurringIds,
      action.isArchive
    );
  }

  @Action(GetRecurringNumber)
  getRecurringNumber(
    { patchState }: StateContext<RecurringModuleStateInfo>,
    action: GetRecurringNumber
  ) {
    return this.recurringService.getRecurringNumber(action.moduleId).pipe(
      tap((res) => {
        patchState({
          recurringNo: res,
        });
      })
    );
  }
}
