export enum ChartOfAccountIncomeGroupTypeName {
  DonationsAndLegacies = 1,
  CharitableActivities = 2,
  OtherTradingActivities = 3,
  Investments = 4,
  SeparateMaterialItemofIncome = 5,
  Other = 6,
  NetGainsLossesonInvestments = 7,
  GainsLossesonRevaluationofFixedAssets = 8,
  OtherGainsLosses = 9,
}

export enum ChartOfAccountExpenseGroupTypeName {
  RaisingFunds = 1,
  CharitableActivities = 2,
  SeparateMaterialItemofExpense = 3,
  Other = 4,
  SupportCosts = 5,
  FinanceCosts = 6,
  GovernanceCosts = 7,
  NetGainsLossesonInvestments = 8,
  ExtraordinaryItems = 9,
  GainsLossesonRevaluationofFixedAssets = 10,
  OtherGainsLosses = 11,
}

export enum ChartOfAccountFixedAssetTangibleGroupTypeName {
  FreeholdLandandBuildings = 1,
  OtherLandandBuildings = 2,
  PlantandMachinery = 3,
  MotorVehicles = 4,
  CommercialVehicles = 5,
  FixturesandFittings = 6,
  ComputerEquipment = 7,
}

export enum ChartOfAccountFixedAssetInTangibleGroupTypeName {
  RandD = 1,
  PatentsandTrademarks = 2,
  Other = 3,
}

export enum ChartOfAccountCurrentAssetStockGroupTypeName {
  Charitable_Stock_Resale = 1,
  Charitable_Stock_Distribution = 2,
  Charitable_DonatedGoods_Resale = 3,
  Charitable_DonatedGoods_Distribution = 4,
  Charitable_Stock_WorkInProgress = 5,
  OtherTrading_Stock_Resale = 6,
  OtherTrading_Stock_Distribution = 7,
  OtherTrading_DonatedGoods_Resale = 8,
  OtherTrading_DonatedGoods_Distribution = 9,
  OtherTrading_Stock_WorkInProgress = 10,
  Other_Stock_Resale = 11,
  Other_Stock_Distribution = 12,
  Other_DonatedGoods_Resale = 13,
  Other_DonatedGoods_Distribution = 14,
  Other_Stock_WorkInProgress = 15,
}
