<div id="chart">
  <apx-chart
    [series]="incomeClientChartOptions.series!"
    [chart]="incomeClientChartOptions.chart!"
    [labels]="incomeClientChartOptions.labels!"
    [responsive]="incomeClientChartOptions.responsive!"
    [fill]="incomeClientChartOptions.fill!"
    [dataLabels]="incomeClientChartOptions.dataLabels!"
    [legend]="incomeClientChartOptions.legend!"
  ></apx-chart>
</div>
