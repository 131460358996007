<div class="alert-message" *ngIf="isViewPermission$ | async">
  <mat-icon class="material-icons-outlined">error_outline</mat-icon>
  {{ commonNotificationText.ViewOnlyAccessMessage }}
</div>
<div
  class="content-body content-body-scroll"
  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
>
  <form [formGroup]="vatSettingsForm" class="p-0">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>VAT Scheme <span class="text-danger-300">*</span></p>
        <ng-select
          #activity
          (focus)="activity.open()"
          (valueChange)="activity.close()"
          [disableOptionCentering]="true"
          formControlName="vatScheme"
          panelClass="mat-select-position"
          required
        >
          <ng-option *ngFor="let sl of vatSchemeList" [value]="sl.id">
            <div title="{{ sl.name }}">{{ sl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field datepicker">
        <p>Registration Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="registrationDate"
            [matDatepicker]="registrationDateDatePicker"
            autocomplete="off"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="registrationDateDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #registrationDateDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Registration No <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="registrationNo"
            autocomplete="off"
            allowDecimal
            maxlength="{{ maxLength.maxLength9 }}"
            required
          />
        </mat-form-field>
        <div
          *ngIf="
            vatSettingsForm.get('registrationNo').hasError('pattern') &&
            vatSettingsForm.get('registrationNo').touched &&
            vatSettingsForm.get('registrationNo').dirty
          "
          class="text-danger-300 mt-10"
        >
          Registartion number should be of 9 digit.
        </div>
      </div>
      <div class="form-field">
        <p>VAT Submit Type <span class="text-danger-300">*</span></p>
        <ng-select
          #VatSubmitType
          (focus)="VatSubmitType.open()"
          (valueChange)="VatSubmitType.close()"
          [disableOptionCentering]="true"
          formControlName="vatSubmitType"
          panelClass="mat-select-position"
          required
        >
          <ng-option *ngFor="let rl of vatReturnTypeList" [value]="rl.id">
            <div title="{{ rl.name }}">{{ rl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field datepicker" *ngIf="showFromTo">
        <p>From</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="from"
            [matDatepicker]="fromDatePicker"
            autocomplete="off"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fromDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-field datepicker" *ngIf="showFromTo">
        <p>To</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="to"
            [matDatepicker]="toDatePicker"
            autocomplete="off"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="toDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex mr-40">
      <div class="form-field" *ngIf="showFromTo">
        <p>Discounted Rate</p>
        <mat-form-field>
          <input matInput formControlName="discountedRate" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
