import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AccountEntity,
  AccountTypeImport,
  DetailListModules,
  MTD,
  ModuleName,
  Modules,
  NotificationHeader,
  OverviewYear,
  RoutingPath,
  SortOrdering,
} from '@app/core/Enum';
import {
  ChartOfAccountListParam,
  Country,
  Currency,
  CustomEmailModel,
  DefaultCurrency,
  FileUploadRequestModel,
  FilterDateRange,
  FinancialData,
  FontFamilies,
  GenerateSaasURLModel,
  GlobalStandardPermission,
  HeaderModel,
  MainListParameters,
  MenuModel,
  MultipleFileDownloadModel,
  ProductDetailsModel,
  ProductModel,
  QueryParams,
  SideListModel,
  StandardPermission,
  TshqRequestOptions,
  UserStandardPermission,
  VATReportModel,
  VatRateScheme,
  ViewDetails,
  ViewParamModel,
} from '@app/core/Models';
import {
  AccountDetailViewModel,
  GroupListModel,
} from '@app/core/Models/common/account-detail-view';
import { MainList } from '@app/core/Models/common/main-list';
import { NotificationService } from '@app/core/Services';

import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IncomeTypeName } from '@app/core/Enum/income-types-name';
import { PurchaseTypeName } from '@app/core/Enum/purchase-type-name';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { HighlightRow } from './highlighted-texts.service';
import { MenuState } from '@app/core/Store';
import { Select } from '@ngxs/store';
import { PermissionType } from '@app/core/Enum/settings';

@Injectable()
export class CommonService implements OnDestroy {
  constructor(
    private http: HttpClient,
    private notifier: NotificationService,
    private router: Router,
    private highlightRow: HighlightRow,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  setReloadVatEmitter = new EventEmitter();
  subscriptionVariable: Subscription;
  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  checkPermission(
    mainMenu: number,
    childMenu: number,
    isCheckViewOnly?: boolean
  ): boolean {
    let isChangePermission = true;
    this.menuList$.pipe(take(1)).subscribe((data) => {
      for (const menu of data) {
        if (menu.id === mainMenu && menu.subMenu) {
          const isSubMenuPresent = menu.subMenu.some(
            (submenu) => submenu.id === childMenu
          );

          if (!isSubMenuPresent) {
            isChangePermission = false;
            return;
          }

          for (const submenu of menu.subMenu) {
            if (isCheckViewOnly) {
              if (
                submenu.id === childMenu &&
                submenu.permissionId === PermissionType.None
              ) {
                isChangePermission = false;
                return;
              }
            } else {
              if (
                submenu.id === childMenu &&
                submenu.permissionId !== PermissionType.Full
              ) {
                isChangePermission = false;
                return;
              }
            }
          }
        }
      }
    });

    return isChangePermission;
  }

  setButtonPermissions(buttons, gridActions) {
    buttons.forEach((button: any) => {
      const buttonElement = button._elementRef
        .nativeElement as HTMLButtonElement;
      const buttonName = buttonElement.name;
      gridActions.forEach((element) => {
        if (element.name === buttonName && element.isDisabled) {
          buttonElement.disabled = element.isDisabled;
          buttonElement.classList.add('mat-button-disabled');
        }
      });
    });
  }
  setVatReload(listParameters: any) {
    this.setReloadVatEmitter.emit(listParameters);
  }

  ngOnDestroy(): void {
    if (this.subscriptionVariable) {
      this.subscriptionVariable.unsubscribe();
    }
  }

  private tenantName = 'charity';
  private selectedSubject = new Subject<any>();
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  public defaultHeaderGuidValue = Guid.EMPTY as unknown as Guid;
  public setParamId = null;
  public isInitialValueChange = false;
  public toggleMenu = true;
  public isEmailValid = true;

  public receiptList: any[] = [
    {
      id: 1,
      name: ModuleName.MatchJournal,
      moduleId: Modules.MatchJournal,
    },
    {
      id: 2,
      name: ModuleName.Journals,
      moduleId: Modules.Journals,
    },
    {
      id: 3,
      name: ModuleName.BankTransfer,
      moduleId: Modules.BankTransfer,
    },
    {
      id: 4,
      name: ModuleName.Invoices,
      moduleId: Modules.Invoices,
    },
    {
      id: 5,
      name: ModuleName.Donations,
      moduleId: Modules.Donations,
    },
    {
      id: 6,
      name: ModuleName.Receipt,
      moduleId: Modules.Receipt,
    },

    {
      id: 7,
      name: ModuleName.DebitNote,
      moduleId: Modules.DebitNote,
    },
  ];

  public paymentList: any[] = [
    {
      id: 1,
      name: ModuleName.Journals,
      moduleId: Modules.Journals,
    },
    {
      id: 2,
      name: ModuleName.MatchJournal,
      moduleId: Modules.MatchJournal,
    },
    {
      id: 3,
      name: ModuleName.Payment,
      moduleId: Modules.Payment,
    },
    {
      id: 4,
      name: ModuleName.BankTransfer,
      moduleId: Modules.BankTransfer,
    },
    {
      id: 5,
      name: ModuleName.Bills,
      moduleId: Modules.Bills,
    },
    {
      id: 6,
      name: ModuleName.CreditNote,
      moduleId: Modules.CreditNote,
    },
  ];

  public invoiceTypeList: number[] = [
    IncomeTypeName.CharitableActivities,
    IncomeTypeName.Othertradingactivities,
    IncomeTypeName.SeparateMaterialItemofIncome,
  ];

  public billTypeList: number[] = [
    PurchaseTypeName.CharitableActivities,
    PurchaseTypeName.RaisingFunds,
    PurchaseTypeName.SupportCosts,
    PurchaseTypeName.GovernanceCosts,
    //PurchaseTypeName.SeparateMaterialItemofExpense,
  ];

  setTenantName(name: string): void {
    this.tenantName = name;
  }

  getTenantName(): any {
    return this.tenantName;
  }

  isEmpty(id: any): boolean {
    return id === this.defaultGuidValue;
  }

  getCountryList(): Observable<Array<Country>> {
    return this.http.get<Array<Country>>(
      `${environment.apiVersionUrl}Country/all`
    );
  }

  prepareUrl(): Observable<any> {
    const payload = {
      returnUrl: window.location.origin.toString() + this.router.url,
    };

    const url = `${environment.apiVersionUrl}MTD/prepareUrl`;
    return this.http
      .post(url, payload, { responseType: 'text' })
      .pipe(map((body) => body));
  }

  postExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.get(endpoint, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }

  print(body: Blob): void {
    const file = new Blob([body], {
      type: 'application/pdf',
    });
    const blobUrl = URL.createObjectURL(file);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow?.print();
  }

  download(response: HttpResponse<Blob>): void {
    let fileName = response.headers
      .get('Content-Disposition')
      ?.split(';')
      .map((x) => (x ?? '').trimLeft().split('='))
      .find((x) => x[0] === 'filename')
      ?.pop();

    const a = document.createElement('a');
    let navigator: any;
    navigator = window.navigator;

    if (fileName !== undefined && fileName !== null) {
      fileName = fileName.replace(/"/g, '');
    }

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(response, fileName);
    } else {
      a.href = URL.createObjectURL(response.body || new Blob());
      a.download = fileName ?? '';
      a.click();
    }
  }

  fileUpload(fileUploadRequestModel: FileUploadRequestModel): Observable<any> {
    const formData = new FormData();

    fileUploadRequestModel.file.forEach((x) =>
      formData.append('files', x, x.name)
    );

    formData.append(
      'attachmentType',
      fileUploadRequestModel.attachmentType.toString()
    );

    return this.http.post<any>(
      `${environment.apiVersionUrl}FileUpload/multipleUpload`,
      formData
    );
  }

  getSideList(
    queryParams: QueryParams,
    moduleId: Modules
  ): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}${this.getSideListUrl(moduleId)}`,
      queryParams,
      httpOptions
    );
  }

  getSideListUrl(moduleId): string {
    let url;

    if (moduleId === Modules.Users) {
      url = `User/getUsersList`;
    } else {
      url = `Common/allList?moduleId=${moduleId}`;
    }

    return url;
  }

  getNominalLedgerReport(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}common/nominalLedgerReport`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getMainList(
    queryParams: MainListParameters,
    moduleId: Modules
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Common/list?moduleId=${moduleId}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getTransactionLogData(id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(
      `${environment.apiVersionUrl}TransactionLog/get/${id}`,
      headers
    );
  }

  getAccountTransactionList(
    queryParams: MainListParameters,
    id: Guid,
    moduleId: number
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let url = '';
    if (moduleId !== Modules.Donors) {
      url = 'Account/accountTransactions';
    } else {
      url = 'Donor/donorDetailList';
    }

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}${url}/${id}`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getReportList(
    queryParams: MainListParameters,
    moduleId: Modules
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Common/report?moduleId=${moduleId}`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getVatReportList(
    queryParams: MainListParameters
  ): Observable<VATReportModel> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<VATReportModel>(
      `${environment.apiVersionUrl}Common/vatReportDetail`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getNominalLedgerTransaction(queryParams: any): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Common/nominalLedgerTransaction`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getVatReportDetailList(
    vatId: Modules,
    queryParams: any
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Common/vatReportTransaction?vatRateId=${vatId}`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getHeaderList(moduleId: Modules): Observable<Array<HeaderModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<HeaderModel>>(
      `${environment.apiVersionUrl}Common/listHeader?moduleId=${moduleId}`,
      headers
    );
  }

  reportDetailView(id: Guid, moduleId: number) {
    let url = '';
    if (moduleId !== Modules.Donors) {
      url = 'Account/detailView';
    } else {
      url = 'Donor/detailView';
    }
    return this.http.get<AccountDetailViewModel>(
      `${environment.apiVersionUrl}${url}/${id}`
    );
  }

  export(queryParams: MainListParameters, moduleId: Modules): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let url;

    if (moduleId === Modules.FundDetailTransaciton) {
      url = 'Fund/transaction/export';
    } else {
      url = `Common/export?moduleId=${moduleId}`;
    }

    return this.postExportRequest(
      `${environment.apiVersionUrl}${url}`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (queryParams.isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }

  reportExport(queryParams: MainListParameters, id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}Account/accountTransactions/export/${id}`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (queryParams.isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }

  giftAidDonationDetailListExport(queryParams: any, id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}GiftAid/giftAidDonationDetailList/export/${id}`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        this.download(response);
        return of(true);
      })
    );
  }

  editReceipt(ids: Array<Guid>, moduleId: any): Observable<any> {
    let url;

    switch (moduleId) {
      case Modules.Invoices:
        url = `Invoice/canEditInvoice/${ids}`;
        break;

      case Modules.CreditNote:
        url = `creditNote/canEditCreditNote/${ids}`;
        break;

      case Modules.DebitNote:
        url = `debitNote/canEditDebitNote/${ids}`;
        break;

      case Modules.Bills:
        url = `purchase/canEditPurchase/${ids}`;
        break;

      case Modules.FixedAssets:
        url = `fixedAsset/canEditFixedAsset/${ids}`;
        break;

      case Modules.Donations:
        url = `donation/canEditDonation/${ids}`;
        break;

      case Modules.Journals:
        url = `journal/canEditJournal/${ids}`;
        break;
    }

    return this.http.get<Array<Guid>>(`${environment.apiVersionUrl}${url}`);
  }

  exportReceipt(
    ids: Array<Guid>,
    moduleId: Modules,
    isPrint: boolean
  ): Observable<any> {
    let url;

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    switch (moduleId) {
      case Modules.Invoices:
        url = 'Invoice/export';
        break;

      case Modules.Bills:
        url = 'Purchase/export';
        break;

      case Modules.CreditNote:
        url = 'CreditNote/export';
        break;

      case Modules.DebitNote:
        url = 'DebitNote/export';
        break;

      case Modules.Quotation:
        url = 'Quotation/export';
        break;

      case Modules.Journals:
        url = 'Journal/export';
        break;

      case Modules.BridgingVAT:
        url = 'MTD/bridgingVat/export';
        break;

      case Modules.Receipt:
      case Modules.Payment:
        url = 'Receipt/export';
        break;

      case Modules.FixedAssets:
        url = 'FixedAsset/export';
        break;

      case Modules.Donations:
        url = 'Donation/export';
        break;

      case Modules.FixedAssetDetail:
      case Modules.FixedAssetsRegister:
        url = 'FixedAssetRegister/detailView/export';
        break;
    }

    return this.postExportRequest(
      `${environment.apiVersionUrl}${url}`,
      JSON.stringify(ids),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }

  getCurrencyList(): Observable<Array<Currency>> {
    return this.http.get<Array<Currency>>(
      `${environment.apiVersionUrl}Currency/all`
    );
  }

  getProductData(productId: Guid): Observable<ProductDetailsModel> {
    return this.http.get<ProductDetailsModel>(
      `${environment.apiVersionUrl}Product/id/${productId}`
    );
  }

  customEmail(
    moduleId: Modules,
    emailData: any
  ): Observable<Array<CustomEmailModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<CustomEmailModel>>(
      `${environment.apiVersionUrl}Common/customEmail/${moduleId}`,
      this.trimObjectSpace(JSON.stringify(emailData)),
      headers
    );
  }

  getProductList(): Observable<Array<ProductModel>> {
    return this.http.get<Array<ProductModel>>(
      `${environment.apiVersionUrl}Product/all`
    );
  }

  getProductListByProductType(
    productType: number
  ): Observable<Array<ProductModel>> {
    return this.http.get<Array<ProductModel>>(
      `${environment.apiVersionUrl}Product/allBasedOnProductType/${productType}`
    );
  }

  addValidation(form: any, renderer: any): void {
    (Object as any).values(form.controls).forEach((c) => c.markAsTouched());
    this.autoScrollToInvalidControl(renderer);
  }

  whiteSpaceValidate: any = (control: FormControl) => {
    return (control.value?.name || control.value || '').trim().length
      ? null
      : { whitespace: true };
  };

  onSucess(message: any): void {
    this.notifier.success(NotificationHeader.success, message);
  }

  checkCustomerSupplierDuplicate(
    name: string,
    email: string,
    moduleId: number
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}${this.getImportModuleServiceName(
        moduleId
      )}/checkDuplicate?name=${name}&email=${email}`
    );
  }

  checkEntryNumber(entryNumber: string, moduleId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}${this.getImportModuleServiceName(
        moduleId
      )}/checkEntryNumber/${entryNumber}`
    );
  }

  getImportModuleServiceName(moduleId: number): string {
    let serviceName;

    switch (moduleId) {
      case Modules.Invoices:
        serviceName = 'invoice';
        break;

      case Modules.Bills:
        serviceName = 'purchase';
        break;

      case Modules.Donations:
        serviceName = 'donation';
        break;

      case Modules.CreditNote:
        serviceName = 'creditNote';
        break;

      case Modules.DebitNote:
        serviceName = 'debitNote';
        break;

      case Modules.Customers:
        serviceName = 'account/' + AccountTypeImport.Customer;
        break;

      case Modules.Suppliers:
        serviceName = 'account/' + AccountTypeImport.Supplier;
        break;

      case Modules.TrialBalance:
        serviceName = 'openingBalance';
        break;

      case Modules.Donors:
        serviceName = 'donor';
        break;
    }

    return serviceName;
  }

  convertStringToGuid(guid: string): Guid {
    return guid as unknown as Guid; // maybe add validation that the parameter is an actual guid ?
  }

  setHighlightData(
    id: Guid,
    isExit: boolean,
    moduleId: number,
    routingPath: RoutingPath
  ): void {
    if (!isExit) {
      if (id === this.defaultGuidValue) {
        this.highlightRow.sideListHighlighted.isHighlighted = true;
        this.highlightRow.sideListHighlighted.sortBy = SortOrdering.createdOn;
      } else {
        this.highlightRow.sideListHighlighted.isHighlighted = false;
        this.highlightRow.sideListHighlighted.sortBy = '';

        this.router.navigate([routingPath], {
          queryParamsHandling: 'preserve',
        });
      }
    } else {
      this.highlightRow.mainListHighlighted.isHighlighted = true;
      this.highlightRow.mainListHighlighted.sortBy =
        id === this.defaultGuidValue
          ? SortOrdering.createdOn
          : SortOrdering.updatedOn;
      this.highlightRow.mainListHighlighted.moduleId = moduleId;
    }
  }

  onFailure(errorMessage: any): void {
    this.spinner.hide();
    this.notifier.error(NotificationHeader.error, errorMessage);
  }

  downloadFile(fileData: MultipleFileDownloadModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.postExportRequest(
      `${environment.apiVersionUrl}FileUpload/multipledownload`,
      fileData,
      headers
    ).pipe(
      switchMap((response) => {
        this.download(response);
        return of();
      })
    );
  }

  getEntityTypeId(moduleId?: number): any {
    if (moduleId === Modules.Customers) {
      return AccountEntity.Customer;
    }

    if (moduleId === Modules.Suppliers) {
      return AccountEntity.Supplier;
    }
  }

  //#region transactions
  getVatRateList(): Observable<Array<VatRateScheme>> {
    return this.http.get<Array<VatRateScheme>>(
      `${environment.apiVersionUrl}Vat/all/rate`
    );
  }

  getAccountList(entityId: Array<number>): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/all`,
      this.trimObjectSpace(JSON.stringify(entityId)),
      headers
    );
  }

  getChartOfAccountsBasedOnGroupId(
    groupIds: Array<number>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAccounts`,
      this.trimObjectSpace(JSON.stringify(groupIds)),
      headers
    );
  }

  getChartOfAccountsBasedOnGroupIdAndTypeId(
    param: ChartOfAccountListParam
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAccountsBasedOnGroupIdAndTypeId`,
      this.trimObjectSpace(JSON.stringify(param)),
      headers
    );
  }

  getAccountsBasedOnGroupId(
    groupIds: Array<number>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getAccounts`,
      this.trimObjectSpace(JSON.stringify(groupIds)),
      headers
    );
  }

  getAccountsBasedOnGroupIdAndTypeId(
    param: ChartOfAccountListParam
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getAccountsBasedOnGroupIdAndTypeId`,
      this.trimObjectSpace(JSON.stringify(param)),
      headers
    );
  }

  checkDateValidation(formControl: any): any {
    let validations = {
      invalidFinancialYear: formControl.hasError('invalidFinancialYear'),
      lockedFinancialYear: formControl.hasError('lockedFinancialYear'),
    };
    return validations;
  }
  autoScrollMatAutoComplete(renderer): void {
    const fragment = 'mat-selected';

    const element = renderer.selectRootElement(`.${fragment}`, true);
    element.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  }

  getProductUnit(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}ProductUnit/all`
    );
  }

  getVatObligations(
    queryParams: MainListParameters,
    detailListId: any
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let url = '';

    switch (detailListId) {
      case MTD.Obligations:
        url = `MTD/vatObligations`;
        break;
      case MTD.RetrieveVATLiabilities:
        url = `MTD/vatLiabilities`;
        break;
      case MTD.RetrieveVATPayments:
        url = `MTD/vatPayments`;
        break;
      default:
        break;
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}${url}`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getReportDetailList(queryParams: MainListParameters): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Fund/transaction`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getFixedAssetRegisterList(queryParams?: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetRegister/list`,
      headers
    );
  }

  getReportDetailHeaderList(): Observable<Array<HeaderModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<HeaderModel>>(
      `${environment.apiVersionUrl}Fund/transaction/listHeader`,
      headers
    );
  }

  getDetailHeaderList(mtdId: MTD): Observable<Array<HeaderModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<HeaderModel>>(
      `${environment.apiVersionUrl}MTD/listHeader?mtdId=${mtdId}`,
      headers
    );
  }

  getDetailList(
    queryParams: MainListParameters,
    dataParam: any
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let url = '';

    if (dataParam.detailListModulesId === DetailListModules.MTD) {
      url = `MTD/list?moduleId=${dataParam.detailListId}`;
    }

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}${url}`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  exportMTD(
    queryParams: MainListParameters,
    moduleId: Modules
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}MTD/export?moduleId=${moduleId}`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (queryParams.isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }
  //#endregion

  onEditRouting(
    isEdit: boolean,
    moduleId: number,
    id: any,
    details?: any,
    parentModuleId?: any,
    isManualBank?: any,
    customId?: any
  ): any {
    let params: any;
    if (isEdit) {
      params = {
        id: btoa(id),
        details: btoa(details),
        moduleId: btoa(parentModuleId),
      };
    } else {
      params = {
        id: btoa(id),
        isFromBankImport: btoa(details),
        isManualBank: btoa(isManualBank),
        customId: btoa(customId),
      };
    }

    switch (moduleId) {
      case Modules.Customers:
        this.router.navigate([RoutingPath.AddCustomers, params]);
        break;
      case Modules.Suppliers:
        this.router.navigate([RoutingPath.AddSuppliers, params]);
        break;
      case Modules.BankTransfer:
        this.router.navigate([RoutingPath.AddBankTransfer, params]);
        break;
      case Modules.Donors:
        this.router.navigate([RoutingPath.AddDonors, params]);
        break;
      case Modules.Activities:
        this.router.navigate([RoutingPath.AddActivity, params]);
        break;
      case Modules.SponsorEvent:
        this.router.navigate([RoutingPath.AddSponsorEvent, params]);
        break;
      case Modules.Journals:
        this.router.navigate([RoutingPath.AddJournals, params]);
        break;
      case Modules.Quotation:
        this.router.navigate([RoutingPath.AddQuotation, params]);
        break;
      case Modules.Invoices:
        this.router.navigate([RoutingPath.AddInvoice, params]);
        break;
      case Modules.DebitNote:
        this.router.navigate([RoutingPath.AddDebitNote, params]);
        break;
      case Modules.AddCustomAccounts:
        this.router.navigate([RoutingPath.AddCustomAccounts, params]);
        break;
      case Modules.Bills:
        this.router.navigate([RoutingPath.AddBills, params]);
        break;
      case Modules.CreditNote:
        this.router.navigate([RoutingPath.AddCreditNote, params]);
        break;
      case Modules.Donations:
        this.router.navigate([RoutingPath.AddDonations, params]);
        break;
      case Modules.FundList:
        this.router.navigate([RoutingPath.AddFund, params]);
        break;
      case Modules.FundTransfers:
        this.router.navigate([RoutingPath.AddFundTransfer, params]);
        break;
      case Modules.RecurringInvoice:
        this.router.navigate([RoutingPath.AddRecurringInvoice, params]);
        break;
      case Modules.RecurringBill:
        this.router.navigate([RoutingPath.AddRecurringBill, params]);
        break;
      case Modules.Receipt:
        this.router.navigate([RoutingPath.AddReceipt, params]);
        break;
      case Modules.Payment:
        this.router.navigate([RoutingPath.AddPayment, params]);
        break;
      case Modules.CashEntry:
        this.router.navigate([RoutingPath.AddCashEntry, params]);
        break;
      case Modules.BankEntry:
        this.router.navigate([RoutingPath.AddBankEntry, params]);
        break;
      case Modules.FixedAssets:
        this.router.navigate([RoutingPath.AddFixedAssets, params]);
        break;
      case Modules.AccountDetails:
        this.router.navigate([RoutingPath.AccountDetails, params]);
        break;
      case Modules.RecurringDonations:
        this.router.navigate([RoutingPath.AddRecurringDonations, params]);
        break;
      case Modules.DonationsInKind:
        this.router.navigate([RoutingPath.AddDonationInKinds, params]);
        break;
      case Modules.VatSettings:
        this.router.navigate([RoutingPath.AddVatCodes, params]);
        break;
      case Modules.SubmitVat:
        this.router.navigate([RoutingPath.AddSubmitVAT, params]);
        break;
      case Modules.BridgingVAT:
        this.router.navigate([RoutingPath.AddBridgingVAT, params]);
        break;
      case Modules.ReportDetailsList:
        this.router.navigate([RoutingPath.ReportDetailsList, params]);
        break;
      case Modules.ReportAccountDetail:
        this.router.navigate([RoutingPath.ReportAccountDetails, params]);
        break;
      case Modules.BankImportTransactionHistory:
        this.router.navigate([RoutingPath.BankTransaction, params]);
        break;
      case Modules.DonationGiftAid:
        this.router.navigate([RoutingPath.AddGiftAidDeclaration, params]);
        break;
      case Modules.Users:
        this.router.navigate([RoutingPath.AddUsers, params]);
        break;
      default:
        this.router.navigate([RoutingPath.Dashboard]);
        break;
    }
    return true;
  }

  onAddRoutingFromSideList(moduleId: number): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.onAddRouting(moduleId);
  }

  onAddRouting(moduleId: number): void {
    switch (moduleId) {
      case Modules.Customers:
        this.router.navigate([RoutingPath.AddCustomers]);
        break;
      case Modules.Suppliers:
        this.router.navigate([RoutingPath.AddSuppliers]);
        break;
      case Modules.BankTransfer:
        this.router.navigate([RoutingPath.AddBankTransfer]);
        break;
      case Modules.Donors:
        this.router.navigate([RoutingPath.AddDonors]);
        break;
      case Modules.Activities:
        this.router.navigate([RoutingPath.AddActivity]);
        break;
      case Modules.SponsorEvent:
        this.router.navigate([RoutingPath.AddSponsorEvent]);
        break;
      case Modules.Journals:
        this.router.navigate([RoutingPath.AddJournals]);
        break;
      case Modules.Quotation:
        this.router.navigate([RoutingPath.AddQuotation]);
        break;
      case Modules.Invoices:
        this.router.navigate([RoutingPath.AddInvoice]);
        break;
      case Modules.DebitNote:
        this.router.navigate([RoutingPath.AddDebitNote]);
        break;
      case Modules.AddCustomAccounts:
        this.router.navigate([RoutingPath.AddCustomAccounts]);
        break;
      case Modules.Bills:
        this.router.navigate([RoutingPath.AddBills]);
        break;
      case Modules.CreditNote:
        this.router.navigate([RoutingPath.AddCreditNote]);
        break;
      case Modules.Donations:
        this.router.navigate([RoutingPath.AddDonations]);
        break;
      case Modules.FundList:
        this.router.navigate([RoutingPath.AddFund]);
        break;
      case Modules.FundTransfers:
        this.router.navigate([RoutingPath.AddFundTransfer]);
        break;
      case Modules.RecurringInvoice:
        this.router.navigate([RoutingPath.AddRecurringInvoice]);
        break;
      case Modules.RecurringBill:
        this.router.navigate([RoutingPath.AddRecurringBill]);
        break;
      case Modules.Receipt:
        this.router.navigate([RoutingPath.AddReceipt]);
        break;
      case Modules.Payment:
        this.router.navigate([RoutingPath.AddPayment]);
        break;
      case Modules.CashEntry:
        this.router.navigate([RoutingPath.AddCashEntry]);
        break;
      case Modules.BankEntry:
        this.router.navigate([RoutingPath.AddBankEntry]);
        break;
      case Modules.FixedAssets:
        this.router.navigate([RoutingPath.AddFixedAssets]);
        break;
      case Modules.BankDashboard:
        this.router.navigate([RoutingPath.BankDashboard]);
        break;
      case Modules.RecurringDonations:
        this.router.navigate([RoutingPath.AddRecurringDonations]);
        break;
      case Modules.DonationsInKind:
        this.router.navigate([RoutingPath.AddDonationInKinds]);
        break;
      case Modules.VatSettings:
        this.router.navigate([RoutingPath.AddVatCodes]);
        break;
      case Modules.SubmitVat:
        this.router.navigate([RoutingPath.AddSubmitVAT]);
        break;
      case Modules.BridgingVAT:
        this.router.navigate([RoutingPath.AddBridgingVAT]);
        break;
      case Modules.DonationGiftAid:
        this.router.navigate([RoutingPath.AddGiftAidDeclaration]);
        break;
      case Modules.Users:
        this.router.navigate([RoutingPath.AddUsers]);
        break;
      default:
        this.router.navigate([RoutingPath.Dashboard]);
        break;
    }
  }

  getDefaultCurrency(): Observable<Array<DefaultCurrency>> {
    return this.http.get<Array<DefaultCurrency>>(
      `${environment.apiVersionUrl}company/currency`
    );
  }

  getFinancialPeriod(): Observable<Array<FinancialData>> {
    return this.http.get<Array<FinancialData>>(
      `${environment.apiVersionUrl}accountingPeriod/all`
    );
  }

  lockFinancialPeriod(financialData: any): Observable<Array<FinancialData>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}accountingPeriod/all`,
      this.trimObjectSpace(JSON.stringify(financialData)),
      headers
    );
  }

  getModuleDetailView(param: ViewParamModel): Observable<ViewDetails> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<ViewDetails>(
      `${environment.apiVersionUrl}Common/view`,
      this.trimObjectSpace(JSON.stringify(param)),
      headers
    );
  }

  getStandardAccountList(
    entityId: Array<number>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/allStandard`,
      this.trimObjectSpace(JSON.stringify(entityId)),
      headers
    );
  }

  getNonStandardAccountList(
    entityId: Array<number>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/allNonStandard`,
      this.trimObjectSpace(JSON.stringify(entityId)),
      headers
    );
  }

  getGroupNonStandardAccountList(
    entityId: Array<number>
  ): Observable<Array<GroupListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/groupAllNonStandard`,
      this.trimObjectSpace(JSON.stringify(entityId)),
      headers
    );
  }

  getCustomBankAccountList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getCustomBankAccount`
    );
  }

  getCashAccountList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getCashAccount`
    );
  }

  getGroupList(): Observable<Array<GroupListModel>> {
    return this.http.get<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/getGroupAllList`
    );
  }

  getGroupAccountsBasedOnGroupId(
    groupIds: Array<number>
  ): Observable<Array<GroupListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/getGroupAccounts`,
      this.trimObjectSpace(JSON.stringify(groupIds)),
      headers
    );
  }

  getGroupAccountsBasedOnGroupIdAndTypeId(
    param: ChartOfAccountListParam
  ): Observable<Array<GroupListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/getGroupAccountsBasedOnGroupIdAndTypeId`,
      this.trimObjectSpace(JSON.stringify(param)),
      headers
    );
  }

  getGroupAccountList(
    entityId: Array<number>
  ): Observable<Array<GroupListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/allGroup`,
      this.trimObjectSpace(JSON.stringify(entityId)),
      headers
    );
  }

  getCustomBankAndCashAccount(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getCustomBankAndCashAccount`
    );
  }

  getGroupCustomBankAndCashAccount(): Observable<Array<GroupListModel>> {
    return this.http.get<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/getGroupCustomBankAndCashAccount`
    );
  }

  selectedDateValidator(periodicDate: any, selectedDate: any): any {
    let isInvalidDate = false;
    let invalidFinancialYear = false;
    let isLockedFinancialYear = false;

    if (selectedDate) {
      const dateValue = Date.parse(selectedDate);

      let periodicDateList = periodicDate.find((element) => {
        const start = Date.parse(element.fromDate);
        const end = Date.parse(element.toDate);

        return (
          moment(dateValue.valueOf()).format('YYYY-MM-DD') >=
            moment(start.valueOf()).format('YYYY-MM-DD') &&
          moment(dateValue.valueOf()).format('YYYY-MM-DD') <=
            moment(end.valueOf()).format('YYYY-MM-DD')
        );
      });

      if (periodicDateList === undefined) {
        isInvalidDate = true;
        invalidFinancialYear = true;
      } else if (periodicDateList.isLocked) {
        isInvalidDate = true;
        isLockedFinancialYear = true;
      }
    }
    return isInvalidDate
      ? {
          invalidFinancialYear: invalidFinancialYear,
          lockedFinancialYear: isLockedFinancialYear,
        }
      : null;
  }

  validateDate(event): void {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46, 109, 111, 191, 189];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  getCreditorDebtorReport(
    queryParams: MainListParameters,
    moduleId: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}${this.getReportModuleURL(moduleId)}`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getReportModuleURL(moduleId: number): string {
    let serviceName;

    switch (moduleId) {
      case Modules.Creditors:
        serviceName = 'report/creditorsReport';
        break;

      case Modules.Debtors:
        serviceName = 'report/debtorsReport';
        break;

      case Modules.DonorReport:
        serviceName = 'report/donorReport';
        break;
    }

    return serviceName;
  }

  getCreditorDebtorTransaction(
    queryParams: any,
    moduleId: number,
    id: any
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}${this.getReportTransactionModuleURL(
        moduleId
      )}${id}`,
      this.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getReportTransactionModuleURL(moduleId: number): string {
    let serviceName;

    switch (moduleId) {
      case Modules.Creditors:
        serviceName = 'report/creditorTransaction/';
        break;

      case Modules.Debtors:
        serviceName = 'report/debtorTransaction/';
        break;

      case Modules.DonorReport:
        serviceName = 'Donor/donorDetailList/';
        break;
    }

    return serviceName;
  }

  onAccountSearch(event: any, tempAccountGroupList: any): any {
    let accountGroupList = tempAccountGroupList;
    const projectSearhText = event.currentTarget.value;
    const trimProjectSearhText = projectSearhText.trimStart();
    const selectedArray: GroupListModel[] = [];
    let listModel: any;
    accountGroupList.filter((item) => {
      let groupName = item.groupName;
      if (
        item.groupName
          .toLowerCase()
          .includes(trimProjectSearhText.toLowerCase())
      ) {
        selectedArray.push(item);
      }
      listModel = [];
      item.listModels.filter((items) => {
        if (
          items.name.toLowerCase().includes(trimProjectSearhText.toLowerCase())
        ) {
          let groupNameExist = selectedArray.filter(
            (x) => x.groupName === groupName
          );
          let tempGroupNameExist = tempAccountGroupList.filter(
            (x) => x.groupName === groupName
          );
          if (groupNameExist.length > 0) {
            if (
              tempGroupNameExist[0].listModels.length !==
              groupNameExist[0].listModels.length
            ) {
              groupNameExist[0].listModels.push(items);
            }
          } else {
            listModel.push(items);
            const data = {
              groupName: groupName,
              listModels: listModel,
            };
            selectedArray.push(data);
          }
        }
      });
    });
    if (trimProjectSearhText) {
      accountGroupList = selectedArray;
    }
    return accountGroupList;
  }
  updateThemeColor(themecolor: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}User/updateThemeColor`,
      JSON.stringify(themecolor),
      headers
    );
  }

  onThemeMessage(): Observable<any> {
    return this.selectedSubject.asObservable();
  }

  clearThemeMessages() {
    this.selectedSubject.next();
  }

  isDataInLockedPeriod(
    ids: Array<Guid>,
    moduleId: Modules
  ): Observable<boolean> {
    let isValidPeriod = new Subject<boolean>();

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    this.http
      .post<any>(
        `${environment.apiVersionUrl}common/validateAccountingPeriod?moduleId=${moduleId}`,
        this.trimObjectSpace(JSON.stringify(ids)),
        headers
      )
      .subscribe((res) => {
        isValidPeriod.next(!res);
      });

    return isValidPeriod.asObservable();
  }

  sendEmail(id: Guid, moduleId: Modules): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let url;

    switch (moduleId) {
      case Modules.Invoices:
        url = 'Invoice';
        break;

      case Modules.CreditNote:
        url = 'CreditNote';
        break;

      case Modules.DebitNote:
        url = 'DebitNote';
        break;

      case Modules.Quotation:
        url = 'Quotation';
        break;

      case Modules.Receipt:
        url = 'Receipt';
        break;
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}${url}/sendEmail/${id}`,
      headers
    );
  }

  autoScrollToInvalidControl(renderer: any): void {
    const fragment = 'ng-invalid';
    const element = renderer.selectRootElement(`.${fragment}`, true);
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  trimObjectSpace(obj) {
    let data = JSON.parse(obj);

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    });

    return JSON.stringify(data);
  }

  getModuleName(moduleId): string {
    let moduleName;

    switch (moduleId) {
      case Modules.Donors:
        moduleName = ModuleName.Donors;
        break;

      case Modules.Customers:
        moduleName = ModuleName.Customers;
        break;

      case Modules.Suppliers:
        moduleName = ModuleName.Suppliers;
        break;

      case Modules.SponsorEvent:
        moduleName = ModuleName.SponsorEvent;
        break;

      case Modules.Invoices:
        moduleName = ModuleName.Invoices;
        break;

      case Modules.Donations:
        moduleName = ModuleName.Donations;
        break;

      case Modules.BankTransfer:
        moduleName = ModuleName.BankTransfer;
        break;

      case Modules.FixedAssets:
        moduleName = ModuleName.FixedAssets;
        break;

      case Modules.FundList:
        moduleName = ModuleName.Fund;
        break;

      case Modules.RecurringInvoice:
        moduleName = ModuleName.RecurringInvoice;
        break;

      case Modules.Activities:
        moduleName = ModuleName.Activities;
        break;

      case Modules.RecurringBill:
        moduleName = ModuleName.RecurringBill;
        break;

      case Modules.Quotation:
        moduleName = ModuleName.Quotation;
        break;

      case Modules.CreditNote:
        moduleName = ModuleName.CreditNote;
        break;

      case Modules.Receipt:
        moduleName = ModuleName.Receipt;
        break;

      case Modules.Bills:
        moduleName = ModuleName.Bills;
        break;

      case Modules.DebitNote:
        moduleName = ModuleName.DebitNote;
        break;

      case Modules.Payment:
        moduleName = ModuleName.Payment;
        break;

      case Modules.Journals:
        moduleName = ModuleName.Journals;
        break;

      case Modules.Bills:
        moduleName = ModuleName.Bills;
        break;

      case Modules.FundTransfers:
        moduleName = ModuleName.FundTransfers;
        break;

      case Modules.CashEntry:
        moduleName = ModuleName.CashEntry;
        break;

      case Modules.BankEntry:
        moduleName = ModuleName.BankEntry;
        break;

      case Modules.RecurringDonations:
        moduleName = ModuleName.RecurringDonations;
        break;

      case Modules.DonationsInKind:
        moduleName = ModuleName.DonationsInKind;
        break;

      case Modules.BankImportTransactionHistory:
        moduleName = ModuleName.BankImportTransactionHistory;
        break;

      case Modules.Users:
        moduleName = ModuleName.Users;
        break;
    }

    return moduleName;
  }

  compareFields(field1, field2, moduleId): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      if (moduleId === Modules.Donations) {
        if (+control.get(field1)!.value < +control.get(field2)!.value) {
          return { noMatch: true };
        }
        return null;
      } else if (moduleId === Modules.FundTransfers) {
        if (!control.get(field1)?.value || !control.get(field2)?.value)
          return null;

        if (control.get(field1)?.value === control.get(field2)?.value) {
          control.get(field1).status = 'INVALID';
          control.get(field2).status = 'INVALID';
          return { matchingFields: true };
        } else {
          control.get(field1).status = 'VALID';
          control.get(field2).status = 'VALID';
          return null;
        }
      } else if (moduleId === Modules.BankEntry) {
        if (!control.get(field1)?.value || !control.get(field2)?.value.id)
          return null;

        if (control.get(field1)?.value === control.get(field2)?.value.id) {
          control.get(field1).status = 'INVALID';
          control.get(field2).status = 'INVALID';
          return { matchingFields: true };
        } else {
          control.get(field1).status = 'VALID';
          control.get(field2).status = 'VALID';
          return null;
        }
      } else {
        return null;
      }
    };
  }

  getSaasURL(url: GenerateSaasURLModel): Observable<GenerateSaasURLModel> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<GenerateSaasURLModel>(
      `${environment.apiVersionUrl}FileUpload/generateSasUrl`,
      this.trimObjectSpace(JSON.stringify(url)),
      headers
    );
  }

  deleteBankImportTransactionHistory(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.trimObjectSpace(JSON.stringify(ids)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}TransactionLog/delete`,
      options
    );
  }

  generateDateSeries(filter: OverviewYear): any {
    let dateRange: FilterDateRange = new FilterDateRange();

    switch (filter) {
      case OverviewYear['This month']:
        dateRange.startDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        );
        dateRange.endDate = new Date(
          dateRange.startDate.getFullYear(),
          dateRange.startDate.getMonth(),
          new Date(
            dateRange.startDate.getFullYear(),
            dateRange.startDate.getMonth() + 1,
            0
          ).getDate()
        );
        break;

      case OverviewYear['This quarter']:
        let thisQuarter = Math.floor(new Date().getMonth() / 3);
        dateRange.startDate = new Date(
          new Date().getFullYear(),
          thisQuarter * 3,
          1
        );
        dateRange.endDate = new Date(
          dateRange.startDate.getFullYear(),
          dateRange.startDate.getMonth() + 3,
          0
        );
        break;
      case OverviewYear['This year']:
        dateRange.startDate = new Date(new Date().getFullYear(), 0, 1);
        dateRange.endDate = new Date(new Date().getFullYear(), 11, 31);
        break;

      case OverviewYear['Last month']:
        let date = new Date();
        dateRange.startDate = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        dateRange.endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        break;

      case OverviewYear['Last quarter']:
        let lastQuarter = Math.floor(new Date().getMonth() / 3) - 1;
        dateRange.startDate = new Date(
          new Date().getFullYear(),
          lastQuarter * 3,
          1
        );
        dateRange.endDate = new Date(
          dateRange.startDate.getFullYear(),
          dateRange.startDate.getMonth() + 3,
          0
        );
        break;

      case OverviewYear['Last year']:
        let date1 = new Date();
        dateRange.startDate = new Date(date1.getFullYear() - 1, 0, 1);
        dateRange.endDate = new Date(date1.getFullYear() - 1, 11, 31);

        break;

      case OverviewYear.Custom:
      case OverviewYear['Last 30 days']:
        dateRange.endDate = new Date();
        dateRange.startDate = new Date(
          new Date().setDate(new Date().getDate() - 30)
        );
        break;

      default:
        dateRange.endDate = new Date();
        dateRange.startDate = new Date(dateRange.endDate.setDate(-30));
        break;
    }

    return dateRange;
  }

  getFontFamilies(): Observable<Array<FontFamilies>> {
    return this.http.get<Array<FontFamilies>>(
      `${environment.apiVersionUrl}common/fontFamilies`
    );
  }

  checkDonorDuplicate(
    name: string,
    lastName: string,
    email: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}donor/checkDuplicate?name=${name}&lastName=${lastName}&email=${email}`
    );
  }

  getBankAccountTransactionData(id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(
      `${environment.apiVersionUrl}BankAccount/getBankAccountTransaction/${id}`,
      headers
    );
  }

  getAdvancePaymentList(accountId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(
      `${environment.apiVersionUrl}receipt/advanceAmount/${accountId}`,
      headers
    );
  }

  //#region Local Storage
  setLocalStorage(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getLocalStorage(key: string): any {
    return JSON.parse(localStorage.getItem(key) || '{}');
  }

  removeItemLocalStorage(key: string): any {
    return localStorage.removeItem(key);
  }

  //#endregion

  getAllocationData(id: Guid, moduleId: number): Observable<any> {
    let url;

    switch (moduleId) {
      case Modules.CreditNote:
        url = `creditNote/getAllocationData`;
        break;

      case Modules.DebitNote:
        url = `debitNote/getAllocationData`;
        break;
    }
    return this.http.get<any>(`${environment.apiVersionUrl}${url}/${id}`);
  }

  saveAllocation(allocationData: any, moduleId: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let url;

    switch (moduleId) {
      case Modules.CreditNote:
        url = `CreditNote/saveCreditNoteAllocation`;
        break;

      case Modules.DebitNote:
        url = `DebitNote/saveDebitNoteAllocation`;
        break;
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}${url}`,
      this.trimObjectSpace(JSON.stringify(allocationData)),
      headers
    );
  }

  getTitleList(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}Salutation/all`
    );
  }

  getUserBusinessPermission(
    userId: Guid
  ): Observable<Array<StandardPermission>> {
    return this.http.get<Array<StandardPermission>>(
      `${environment.apiVersionUrl}ModuleRolePermission/getUserBusinessPermission/${userId}`
    );
  }

  getPermissions(
    searchText?: string
  ): Observable<Array<GlobalStandardPermission>> {
    return this.http.get<Array<GlobalStandardPermission>>(
      `${environment.apiVersionUrl}ModuleRolePermission/getBusinessPermission?search=${searchText}`
    );
  }

  saveUserPermission(
    standardPermission: Array<UserStandardPermission>,
    userId: Guid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}ModuleRolePermission/saveUserPermission/${userId}`,
      JSON.stringify(standardPermission),
      headers
    );
  }

  saveBusinessPermission(
    standardPermission: Array<GlobalStandardPermission>,
    userId: Guid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}ModuleRolePermission/saveBusinessPermission?userId=${userId}`,
      JSON.stringify(standardPermission),
      headers
    );
  }

  getFileAsByte(fileUrl: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FileUpload/getFileAsByte`,
      JSON.stringify(fileUrl),
      headers
    );
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }

  getIsVatRegistered(): Observable<any> {
    return this.http.get<boolean>(
      `${environment.apiVersionUrl}Company/companyIsVatRegistered`
    );
  }

  public syncfusionRichTextEditorTools: object = {
    type: 'MultiRow',
    items: [
      'Undo',
      'Redo',
      '|',
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      '|',
      'FontName',
      'FontSize',
      'FontColor',
      '|',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateTable',
    ],
  };

  public syncfusionRichTextEditorPasteCleanupSettings: object = {
    listConversion: true,
    prompt: false,
    plainText: false,
    keepFormat: true,
    deniedTags: ['a', 'img'],
    deniedAttrs: ['title', 'id', 'lang'],
  };

  public syncfusionRichTextEditorToolbarSettings: object = {
    enableFloating: false,
  };

  syncfusionRichTextEditorOnActionBegin(e) {
    if (e.type === 'drop' || e.type === 'dragstart') {
      e.cancel = true;
    }
  }
}
