import { Injectable } from '@angular/core';
import { SideListModel, VATCodesModel } from '@app/core/Models';
import { VATCodesService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  SaveVATCodes,
  GetVATCodes,
  DeleteVATCodes,
  ArchiveAndRestoreVATCodes,
} from './vat-codes.action';

export class VatCodesStateInfo {
  vatCodeData?: VATCodesModel;
  sideListModel: Array<SideListModel>;
  vatCodesModel: Array<VATCodesModel>;
}

@State<VatCodesStateInfo>({
  name: 'vatCodes',
  defaults: {
    sideListModel: [],
    vatCodesModel: [],
  },
})
@Injectable()
export class VatCodesState {
  constructor(private vatCodesService: VATCodesService) {}

  @Action(SaveVATCodes)
  saveVATCodes(
    { patchState }: StateContext<VatCodesStateInfo>,
    action: SaveVATCodes
  ) {
    return this.vatCodesService.saveVATCodes(action.params).pipe(
      tap((res) => {
        patchState({
          vatCodeData: res,
        });
      })
    );
  }

  @Action(GetVATCodes, { cancelUncompleted: true })
  getvatCodes(
    { patchState }: StateContext<VatCodesStateInfo>,
    action: GetVATCodes
  ) {
    return this.vatCodesService.getVATCodes(action.vatCodeId).pipe(
      tap({
        next: (result) => {
          patchState({
            vatCodeData: result,
          });
        },
      })
    );
  }

  @Action(DeleteVATCodes)
  deleteVATCodes(
    { getState, patchState }: StateContext<VatCodesStateInfo>,
    action: DeleteVATCodes
  ) {
    return this.vatCodesService.deleteVATCodes(action.vatCodsIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredMinutesOfMeetingIds = state.vatCodesModel.filter(
          (item) =>
            !action.vatCodsIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.vatCodsIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          vatCodesModel: filteredMinutesOfMeetingIds,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreVATCodes)
  archiveAndRestoreVATCodes(
    { getState }: StateContext<VatCodesStateInfo>,
    action: ArchiveAndRestoreVATCodes
  ) {
    return this.vatCodesService.archiveAndRestoreVATCodes(
      action.vatCodsIds,
      action.isArchive
    );
  }
}
