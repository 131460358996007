<form [formGroup]="note1Form">
  <div class="notes-wrapper">
    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox formControlName="checkbox1" disableRipple></mat-checkbox
        >Accounting Policies
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox1"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox formControlName="checkbox2" disableRipple></mat-checkbox
        >Basis of preparation
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox2"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox formControlName="checkbox3" disableRipple></mat-checkbox
        >Going concern
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox3"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox4"></mat-checkbox
        >Change of accounting policy
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox4"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox5"></mat-checkbox
        >Changes to accounting estimates
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox5"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox6"></mat-checkbox
        >Material prior year errors
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox6"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <!-- with table -->
    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox7"></mat-checkbox
        >Reconciliation with previous Generally accepted accounting practice
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox7"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox8"></mat-checkbox
        >Income from donations or grants
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox8"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox9"></mat-checkbox
        >Legacies
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox9"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox10"></mat-checkbox
        >Government grants
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox10"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox11"></mat-checkbox
        >Tax reclaims on donations and gifts
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox11"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox12"></mat-checkbox
        >Contractual income and performance related grants
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox12"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox13"></mat-checkbox
        >Donated goods
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox13"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox14"></mat-checkbox
        >Donated services and facilities
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox14"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox15"></mat-checkbox
        >Income from charitable activities
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox15"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox16"></mat-checkbox
        >Investment income
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox16"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox17"></mat-checkbox
        >Income from membership subscriptions
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox17"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox18"></mat-checkbox
        >Other trading income
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox18"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox19"></mat-checkbox
        >Settlement of insurance claims
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox19"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox20"></mat-checkbox
        >Investment gains and losses
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox20"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox21"></mat-checkbox
        >Expenditure
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox21"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox22"></mat-checkbox
        >Pensions
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox22"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox23"></mat-checkbox
        >Taxation
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox23"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox24"></mat-checkbox
        >Creditors
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox24"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox25"></mat-checkbox
        >Provisions for liabilities
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox25"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox26"></mat-checkbox
        >Financial instruments
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox26"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox27"></mat-checkbox
        >Fund accounting
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox27"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox28"></mat-checkbox
        >Critical accounting estimates and judgements
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox28"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox29"></mat-checkbox
        >Intangible fixed assets
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox29"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox30"></mat-checkbox
        >Tangible fixed assets
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox30"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox31"></mat-checkbox>
        Heritage assets
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox31"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox32"></mat-checkbox>
        Investments
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox32"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox33"></mat-checkbox>
        Investment properties
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox33"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox34"></mat-checkbox
        >Stocks and work in progress
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox34"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox35"></mat-checkbox
        >Debtors
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox35"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>

    <div class="notes-section">
      <p class="fw-bold notes-heading">
        <mat-checkbox disableRipple formControlName="checkbox36"></mat-checkbox
        >Current asset investments
      </p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox36"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
  </div>
</form>
