export enum ModuleName {
  Dashboard = 'Dashboard',
  ChartOfAccounts = 'Chart Of Accounts',
  Income = 'Income',
  Expenditure = 'Expenditure',
  Contacts = 'Contacts',
  Bank = 'Bank',
  Fund = 'Fund',
  Reports = 'Reports',
  Manage = 'Manage',
  Logs = 'Logs',
  AddStandardAccounts = 'Standard Accounts',
  AddCustomAccounts = 'Custom Accounts',
  Invoices = 'Invoices',
  RecurringInvoice = 'Recurring Invoice',
  Quotation = 'Quotation',
  CreditNote = 'Credit Note',
  Receipt = 'Receipt',
  Bills = 'Bill',
  RecurringBill = 'Recurring Bill',
  DebitNote = 'Debit Note',
  Payment = 'Payment',
  Capiscan = 'Capiscan',
  Journals = 'Journals',
  BulkEdit = 'BulkEdit',
  Activities = 'Activities',
  SponsorEvent = 'Sponsor Event',
  Donations = 'Donations',
  Report = 'Report',
  Customers = 'Customer',
  Suppliers = 'Suppliers',
  Donors = 'Donors',
  BankDashboard = 'Bank Dashboard',
  BankImport = 'Bank Import',
  BankTransfer = 'Bank Transfer',
  BankFeed = 'Bank Feed',
  CashEntry = 'Cash Entry',
  FixedAssets = 'Fixed Assets',
  Funds = 'Funds',
  FundTransfers = 'Fund Transfers',
  EmailLog = 'EmailLog',
  UserLog = 'UserLog',
  Charity = 'Charity',
  StatementOfFinancialActivities = 'Statement Of Financial Activities',
  BalanceSheet = 'Balance Sheet',
  ManagementNotes = 'ManagementNotes',
  BankEntry = 'Bank Entry',
  Import = 'Import',
  RecurringDonations = 'Recurring Donations',
  NominalLedger = 'Nominal Ledger',
  Creditors = 'Creditors',
  Debtors = 'Debtors',
  DonationsInKind = 'Donations In Kind',
  ManagementTrialBalance = 'Trial Balance',
  SubmitVat = 'SubmitVat',
  BridgingVAT = 'BridgingVAT',
  TrialBalance = 'Trial Balance',
  OpeningBalance = 'Opening Balance',
  DonationCoding = 'Donation Coding',
  CashCoding = 'Cash Coding',
  MailIntegrations = 'MailIntegrations',
  BankImportTransactionHistory = 'Bank Import Transaction History',
  DonorReport = 'Donor Report',
  TrusteesReport = "Trustees' Report",
  IndependentExaminerReport = "Independent Examiner's Report",
  AuditReport = 'Audit Report',
  MatchJournal = 'Match Journal',
  FixedAssetDetail = 'Fixed Asset Detail',
  Users = 'Users',
}

export enum HeaderTextForSideList {
  Invoice = 'Invoices',
  Donations = 'Donations',
  BankTransfer = 'Bank Transfer',
  FixedAssets = 'Fixed Assets',
  Fund = 'Funds',
  Suppliers = 'Suppliers',
  Customers = 'Customers',
  Donors = 'Donors',
  Quotation = 'Quotations',
  CreditNote = 'Credit Notes',
  RecurringInvoice = 'Recurring Invoices',
  RecurringBill = 'Recurring Bill',
  Receipt = 'Receipts',
  Bills = 'Bill',
  DebitNote = 'Debit Notes',
  Payment = 'Payments',
  Activities = 'Activities',
  Journals = 'Journals',
  SponsorEvent = 'Sponsor Events',
  CustomAccount = 'Accounts',
  FundTransfers = 'Fund Transfer',
  CashEntry = 'Cash Entry',
  BankEntry = 'Bank Entry',
  RecurringDonations = 'Recurring Donations',
  DonationsInKind = 'Donations In Kind',
  DonationCoding = 'Donation Coding',
  CashCoding = 'Cash Coding',
  Users = 'User',
}
