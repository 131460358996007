import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength, Modules } from '@app/core/Enum';
import { Country, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { CountryState, GetCountryList, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-contact-details',
  templateUrl: './add-contact-details.component.html',
  styleUrls: ['./add-contact-details.component.scss'],
})
export class AddContactDetailsComponent implements OnInit {
  contactDetailForm: FormGroup;
  moduleId = Modules;
  maxLength = MaxLength;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  countryList: Country[];
  chartOfAllAccountList: SideListModel[];
  fundTypeList: SideListModel[];

  isEdit = false;
  isSelected = false;

  @Input() triggerEditData: Observable<any>;

  @Input()
  getModuleName?: string;

  @Input()
  getModuleId: number;

  @Output() isGiftAidSelected = new EventEmitter<boolean>();
  private destroy$ = new Subject<void>();

  constructor(private store: Store, private commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.getCountry().subscribe();

    this.isEdit = false;

    this.triggerEditData
      .pipe(
        switchMap((data) => {
          return this.getCountry().pipe(map(() => data));
        })
      )
      .subscribe((data) => {
        this.isEdit = true;
        this.editContactDetail(data);
      });

    this.contactDetailForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.contactDetailForm.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  editContactDetail(data): void {
    this.contactDetailForm.patchValue({
      name: data.name,
      lastName: data.lastName,
      code: data.code,
      tradingName: data.tradingName,
      fundTypeName: data.fundTypeId,
      country: data.countryId,
      addressLine1: data.address1,
      addressLine2: data.address2,
      addressLine3: data.address3,
      city: data.city,
      postCode: data.postalCode,
      email: data.email,
      mobileNumber: data.mobileNumber,
      phone: data.phone,
      fax: data.fax,
      isGiftAidDonor: data.isGiftAidDonor,
    });
  }

  getCountry(): Observable<any> {
    return this.store.dispatch(new GetCountryList()).pipe(
      tap(() => {
        this.countryList = this.store.selectSnapshot(CountryState.getCountry);
        this.contactDetailForm.controls.country.setValue(
          this.countryList[205]?.id
        );
      })
    );
  }

  setForm(): void {
    this.contactDetailForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      tradingName: new FormControl(''),
      lastName: new FormControl(''),
      country: new FormControl(''),
      addressLine1: new FormControl(''),
      addressLine2: new FormControl(''),
      addressLine3: new FormControl(''),
      city: new FormControl(''),
      postCode: new FormControl(''),
      email: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
        Validators.email,
      ]),
      mobileNumber: new FormControl(''),
      phone: new FormControl(''),
      fax: new FormControl(''),
      isGiftAidDonor: new FormControl(false),
    });
  }

  onCheckBoxSelected(event: any): void {
    this.isSelected = event.checked;
    const lastNameControl = this.contactDetailForm.get('lastName');
    const postCodeControl = this.contactDetailForm.get('postCode');
    const addressLine1Control = this.contactDetailForm.get('addressLine1');

    if (this.isSelected) {
      lastNameControl?.setValidators([
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]);

      postCodeControl?.setValidators([
        Validators.required,
        Validators.pattern(
          /^[A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2}$/
        ),
      ]);

      addressLine1Control?.setValidators([
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]);

      lastNameControl?.updateValueAndValidity();
      postCodeControl?.updateValueAndValidity();
      addressLine1Control?.updateValueAndValidity();
    } else {
      lastNameControl?.clearValidators();
      lastNameControl?.updateValueAndValidity();
      postCodeControl?.clearValidators();
      postCodeControl?.updateValueAndValidity();
      addressLine1Control?.clearValidators();
      addressLine1Control?.updateValueAndValidity();
    }
    this.isGiftAidSelected.emit(this.isSelected);
  }
}
