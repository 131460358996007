export enum Modules {
  Dashboard = 1,
  Income = 2,
  Expenditure = 3,
  Tasks = 4,
  Donation = 5,
  Contacts = 6,
  Bank = 7,
  Funds = 9,
  MTD = 10,
  Report = 11,
  ChartOfAccounts = 12,
  Manage = 13,
  Logs = 14,
  IncomeOverview = 15,
  Invoices = 16,
  RecurringInvoice = 17,
  Quotation = 18,
  CreditNote = 19,
  Receipt = 20,
  ExpenditureOverview = 21,
  Bills = 22,
  RecurringBill = 23,
  DebitNote = 24,
  Payment = 25,
  Capiscan = 26,
  Journals = 27,
  BulkEdit = 28,
  Activities = 29,
  SponsorEvent = 30,
  DonationOverview = 31,
  Donations = 32,
  RecurringDonations = 33,
  DonationsInKind = 34,
  DonationCoding = 35,
  Customers = 36,
  Suppliers = 37,
  Donors = 38,
  BankDashboard = 39,
  BankTransfer = 40,
  BankFeed = 41,
  CashEntry = 42,
  BankEntry = 43,
  CashCoding = 44,
  FixedAssetOverview = 45,
  FixedAssets = 46,
  FixedAssetsRegister = 47,
  FundOverview = 48,
  FundList = 49,
  FundTransfers = 50,
  SubmitVat = 51,
  BridgingVAT = 52,
  ViewVATDetails = 53,
  VatSettings = 54,
  AddStandardAccounts = 55,
  AddCustomAccounts = 56,
  CharityProfile = 57,
  CharityPreference = 58,
  OpeningBalance = 59,
  Import = 60,
  EmailLogs = 61,
  UserLogs = 62,
  ReportDashboard = 63,
  Management = 64,
  AnnualAccounts = 65,
  Additional = 66,
  Customer = 67,
  Supplier = 68,
  DonorReport = 69,
  VAT = 70,
  TrialBalance = 71,
  StatementofFinancialActivities = 72,
  BalanceSheet = 73,
  ReceiptAndPayment = 74,
  StatementOfAssetsAndLiabilities = 75,
  Notes = 76,
  TrusteesReport = 77,
  IndependentExaminerReport = 78,
  IncomeAndExpenditure = 79,
  DayBook = 80,
  AccountDetails = 81,
  NominalLedger = 82,
  CustomerAdvanceReport = 83,
  SalesReport = 84,
  CustomerList = 85,
  CustomerAgeingList = 86,
  TransactionsByCustomer = 87,
  CustomerReceipts = 88,
  Debtors = 89,
  CustomerStatement = 90,
  InvoiceList = 91,
  SupplierAdvanceReport = 92,
  PurchasesReport = 93,
  SupplierList = 94,
  SupplierAgeingList = 95,
  TransactionsBySupplier = 96,
  PaymentToSupplier = 97,
  Creditors = 98,
  SupplierStatement = 99,
  BillList = 100,
  SalesDayBook = 101,
  PurchaseDayBook = 102,
  Integration = 103,
  Templates = 104,
  DonationGiftAid = 105,
  GiftAidSetting = 106,
  EditTrialBalance = 107,
  AuditReport = 108,
  Users = 109,
  AccountProduction = 110,
  AccountProductionTrialBalance = 111,
  AccountProductionStatementofFinancialActivities = 112,
  AccountProductionBalanceSheet = 113,
  AccountProductionReceiptAndPayment = 114,
  AccountProductionStatementOfAssetsAndLiabilities = 115,
  Permission = 117,
  //----Don't Changes module id +200
  ReportDetailsList = 200,
  FinancialYear = 201,
  ReportAccountDetail = 202,
  CustomerDetailHeader = 203,
  SupplierDetailHeader = 204,
  DonorDetailHeader = 205,
  BankImportHistory = 206,
  BankImportTransactionHistory = 207,
  FixedAssetsDispose = 208,
  FundDetailTransaciton = 209,
  SupportTicket = 210,
  ClientTicket = 211,
  MatchJournal = 212,
  BankFeedTrasnaction = 213,
  FixedAssetDetail = 214,
  AnnualAccountsResponse = 215,
  UserClients = 216,
  EditAccountProductionTrialBalance = 217,
  Note1 = 218,
  FixedAssetRollBack = 219,
}

export enum ReportEnum {
  TrialBalance = 71,
  StatementofFinancialActivities = 72,
  BalanceSheet = 73,
  ReceiptAndPayment = 74,
  StatementOfAssetsAndLiabilities = 75,
  Notes = 76,
  TrusteesReport = 77,
  IndependentExaminerReport = 78,
  CustomerList = 85,
  SupplierList = 94,
  AccountProductionTrialBalance = 111,
  AccountProductionStatementofFinancialActivities = 112,
  AccountProductionBalanceSheet = 113,
  AccountProductionReceiptAndPayment = 114,
  AccountProductionStatementOfAssetsAndLiabilities = 115,
}

export enum ProjectName {
  bookkeeping = 'bookkeeping',
  diyboox = 'diyboox',
}

export enum ComponentName {
  MainListComponent = 'app-main-list',
  ReportListComponent = 'app-report-list',
  BankReconciliationComponent = 'app-bank-reconciliation',
  NominalLedgerComponent = 'app-nominal-ledger',
  ImportListComponent = 'app-import',
  ReportCreditorDebtorComponent = 'app-report-creditor-debitor',
  BulkEdit = 'app-bulk-edit',
  VatSettings = 'app-vat-settings',
  ReportListWithActionsComponent = 'app-report-list-with-actions',
  Dashboard = 'app-dashboard',
  Dashboardv1 = 'app-dashboard-v1',
  TrusteesReport = 'app-trustees-report',
  fixedAssetRegister = 'app-fixed-assest-dispose-active',
  BankOverviewComponent = 'app-bank-overview',
  CashCodingComponent = 'app-cash-coding',
  BankFeedComponent = 'app-bank-feed',
}

export enum MTD {
  Obligations = 1,
  ViewVATReturn = 2,
  RetrieveVATLiabilities = 3,
  RetrieveVATPayments = 4,
  VATCodes = 5,
  SubmitVat = 6,
}

export enum DetailListModules {
  Inventory = 1,
  MTD = 2,
}

export enum VatSettings {
  VatSettings = 'VAT Settings',
  VatCodes = 'VAT Codes',
}

export enum DashboardAgeingSummaryType {
  Receivables = 1,
  Payable = 2,
}

export enum AccountTypeImport {
  Supplier = 1,
  Customer = 2,
}

export enum OpeningBalanceExplain {
  UnrestrictedDebit = 1,
  UnrestrictedCredit = 2,
  RestrictedDebit = 3,
  RestrictedCredit = 4,
  EndowmentDebit = 5,
  EndowmentCredit = 6,
}

export enum ImportDateName {
  invoiceDate = 1,
  dueDate = 2,
  billDate = 3,
  creditNoteDate = 4,
  debitNoteDate = 5,
  fixedAssetDate = 6,
  donationDate = 7,
}
