import { Injectable } from '@angular/core';
import { SideListModel, SponsorModel } from '@app/core/Models';
import { SponsorService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Guid } from 'guid-typescript';
import {
  ArchiveAndRestoreSponsor,
  CreateSponsor,
  DeleteSponsor,
  GetDataBySponsorId,
} from './sponsor.action';

export class SponsorStateInfo {
  sponsorData?: SponsorModel;
  sponsor: Array<SponsorModel>;
  sponsorId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isSponsorAdd?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
}

@State<SponsorStateInfo>({
  name: 'sponsor',
  defaults: {
    sponsor: [],
    sponsorId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isSponsorAdd: false,
  },
})
@Injectable()
export class SponsorState {
  constructor(private sponsorService: SponsorService) {}

  @Selector()
  static isLastPage(state: SponsorStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getSponsorId(state: SponsorStateInfo) {
    return state.sponsorId;
  }

  @Selector()
  static getSponsorData(state: SponsorStateInfo) {
    return state.sponsorData;
  }

  @Action(CreateSponsor)
  createSponsor(
    { patchState }: StateContext<SponsorStateInfo>,
    action: CreateSponsor
  ) {
    return this.sponsorService.createSponsor(action.sponsor).pipe(
      tap((res) => {
        patchState({
          sponsorId: res,
          isSponsorAdd: true,
        });
      })
    );
  }

  @Action(GetDataBySponsorId)
  getDataBySponsorId(
    { patchState }: StateContext<SponsorStateInfo>,
    action: GetDataBySponsorId
  ) {
    return this.sponsorService.getDataBySponsorId(action.sponsorId).pipe(
      tap((res) => {
        patchState({
          sponsorData: res,
        });
      })
    );
  }

  @Action(DeleteSponsor)
  deleteSponsor(
    { getState, setState }: StateContext<SponsorStateInfo>,
    action: DeleteSponsor
  ) {
    return this.sponsorService.deleteSponsor(action.sponsorIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredSponsor = state.sponsor.filter(
          (item) =>
            !action.sponsorIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.sponsorIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.sponsor,
          sponsor: filteredSponsor,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreSponsor)
  archiveAndRestoreSponsor(
    { getState }: StateContext<SponsorStateInfo>,
    action: ArchiveAndRestoreSponsor
  ) {
    return this.sponsorService.archiveAndRestoreSponsor(
      action.sponsorIds,
      action.isArchive
    );
  }
}
