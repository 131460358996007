<div id="chart" *ngIf="!isNoRecord && isloadData">
  <apx-chart
    [series]="donationChartOptionsv1?.series!"
    [chart]="donationChartOptionsv1?.chart!"
    [labels]="donationChartOptionsv1?.labels!"
    [responsive]="donationChartOptionsv1?.responsive!"
    [fill]="donationChartOptionsv1?.fill!"
    [dataLabels]="donationChartOptionsv1?.dataLabels!"
    [legend]="donationChartOptionsv1?.legend!"
    [tooltip]="donationChartOptionsv1?.tooltip!"
  ></apx-chart>
</div>
<div class="norecord-chart" *ngIf="isNoRecord && isloadData">
  <img src=" assets/images/pia-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
