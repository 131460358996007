<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList()"
    [reloadSideList]="reloadSideList"
    [ngClass]="{ 'wp-100': isShowSideListAction }"
  ></app-side-list>

  <div
    class="form"
    [ngClass]="[
      istoggleSideList ? 'hide-sidelist' : '',
      selectedIndex === 0 && supplierId === commonService.defaultGuidValue
        ? 'mb-4'
        : '',
      (totalRecord$ | async) > 10 && showPaginator ? 'mb-4' : '',
      selectedIndex === 1 && supplierId !== commonService.defaultGuidValue
        ? 'mb-4'
        : ''
    ]"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <p class="header-text d-flex justify-space-between align-items-center">
          <span>
            {{ headerText }}
          </span>
          <button
            mat-button
            class="header-text-close d-flex align-items-center"
            disableRipple
            (click)="onCloseClick()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </p>
        <mat-tab-group
          mat-align-tabs="start"
          disableRipple
          animationDuration="0ms"
          [selectedIndex]="selectedIndex"
          (selectedTabChange)="tabClick($event)"
        >
          <mat-tab
            label="Overview"
            *ngIf="supplierId !== commonService.defaultGuidValue"
          >
            <div>
              <app-report-list
                #reportList
                [triggerAccountDetails]="triggerAccountDetails"
                (toggleShowPaginator)="togglePaginator($event)"
              ></app-report-list>
            </div>
          </mat-tab>
          <mat-tab label="Supplier Details">
            <div>
              <div class="alert-message" *ngIf="isViewPermission$ | async">
                <mat-icon class="material-icons-outlined"
                  >error_outline</mat-icon
                >
                {{ commonNotificationText.ViewOnlyAccessMessage }}
              </div>
              <div class="accordion-toggle">
                <mat-icon
                  (click)="toggleAccordion(); accordion.openAll()"
                  *ngIf="isExpandAll"
                  >keyboard_double_arrow_down
                </mat-icon>
                <mat-icon
                  (click)="toggleAccordion(); accordion.closeAll()"
                  *ngIf="!isExpandAll"
                  >keyboard_double_arrow_up
                </mat-icon>
              </div>
              <mat-accordion [multi]="true">
                <mat-expansion-panel
                  [expanded]="true"
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon
                        fontSet="material-icons-outlined"
                        class="text-primary"
                        >description</mat-icon
                      >
                      <div class="d-flex flex-column">
                        <span>Contact Details</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-add-contact-details
                    [getModuleId]="moduleId"
                    [getModuleName]="moduleName"
                    [triggerEditData]="triggerEditData"
                  ></app-add-contact-details>
                </mat-expansion-panel>
                <mat-expansion-panel
                  *ngIf="false"
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon
                        fontSet="material-icons-outlined"
                        class="text-primary"
                      >
                        verified_user
                      </mat-icon>
                      <div class="d-flex flex-column">
                        <span>CIS Information</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-add-CIS-information
                    [triggerEditData]="triggerEditData"
                  ></app-add-CIS-information>
                </mat-expansion-panel>
                <div>
                  <mat-expansion-panel
                    [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon
                          fontSet="material-icons-outlined"
                          class="text-primary"
                          >receipt</mat-icon
                        >
                        <div class="d-flex flex-column">
                          <span>Commercial Details</span>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-add-commerical-details
                      [triggerEditData]="triggerEditData"
                    ></app-add-commerical-details>
                  </mat-expansion-panel>
                </div>
              </mat-accordion>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <app-buttons
        *ngIf="
          (supplierId === commonService.defaultGuidValue ||
            selectedIndex === 1) &&
          !(isViewPermission$ | async)
        "
        [getModuleId]="moduleId"
        (triggerOnSaveClick)="onSave($event.isExit)"
        (triggerOnCancelClick)="onCancel(true)"
      >
      </app-buttons>
    </div>
  </div>
</div>
