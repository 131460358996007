<div class="d-flex year-picker-content">
  <div>
    <mat-form-field>
      <mat-select
        [value]="selectedOption"
        (selectionChange)="onDropdownChange($event)"
        panelClass="mat-select-position"
        [disableOptionCentering]="true"
      >
        <mat-option
          *ngIf="
            this.moduleId !== moduleEnum.TrialBalance &&
            this.moduleId !== moduleEnum.EditTrialBalance &&
            this.moduleId !== moduleEnum.EditAccountProductionTrialBalance &&
            this.moduleId !== moduleEnum.StatementofFinancialActivities &&
            this.moduleId !== moduleEnum.BalanceSheet &&
            this.moduleId !== moduleEnum.IncomeAndExpenditure &&
            this.moduleId !== moduleEnum.StatementOfAssetsAndLiabilities &&
            this.moduleId !== moduleEnum.ReceiptAndPayment &&
            this.moduleId !== moduleEnum.AccountProductionTrialBalance &&
            this.moduleId !== moduleEnum.AccountProductionBalanceSheet &&
            this.moduleId !==
              moduleEnum.AccountProductionStatementofFinancialActivities &&
            this.moduleId !==
              moduleEnum.AccountProductionStatementOfAssetsAndLiabilities &&
            this.moduleId !== moduleEnum.AccountProductionReceiptAndPayment &&
            this.moduleId !== moduleEnum.VAT &&
            this.moduleId !== moduleEnum.AccountDetails
          "
          value="-1"
          >All Years</mat-option
        >

        <mat-option
          *ngFor="let year of yearsListPair"
          [value]="year"
          title="{{ year.name }}"
        >
          {{ year.name }}
        </mat-option>
        <mat-option
          *ngIf="
            this.moduleId !== moduleEnum.TrialBalance &&
            this.moduleId !== moduleEnum.EditTrialBalance &&
            this.moduleId !== moduleEnum.EditAccountProductionTrialBalance &&
            this.moduleId !== moduleEnum.StatementofFinancialActivities &&
            this.moduleId !== moduleEnum.BalanceSheet &&
            this.moduleId !== moduleEnum.IncomeAndExpenditure &&
            this.moduleId !== moduleEnum.StatementOfAssetsAndLiabilities &&
            this.moduleId !== moduleEnum.ReceiptAndPayment &&
            this.moduleId !== moduleEnum.AccountProductionTrialBalance &&
            this.moduleId !==
              moduleEnum.AccountProductionStatementofFinancialActivities &&
            this.moduleId !== moduleEnum.AccountProductionBalanceSheet &&
            this.moduleId !==
              moduleEnum.AccountProductionStatementOfAssetsAndLiabilities &&
            this.moduleId !== moduleEnum.AccountProductionReceiptAndPayment &&
            this.moduleId !== moduleEnum.VAT &&
            this.moduleId !== moduleEnum.CashCoding
          "
          value="1"
          title="Custom dates"
          >Custom dates</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <form *ngIf="selectedOption === '1'" [formGroup]="rangeFormGroup">
    <mat-form-field appearance="standard">
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          matInput
          #start
          (dateChange)="onCustomDate()"
          formControlName="start"
          [required]="this.moduleId === moduleEnum.AccountDetails"
        />
        <input
          matEndDate
          matInput
          #end
          formControlName="end"
          (dateChange)="onCustomDate()"
          (change)="commonService.validateDate($event)"
          [required]="this.moduleId === moduleEnum.AccountDetails"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</div>
