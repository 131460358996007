import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  AccountingMethod,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
  TransactionStatus,
} from '@app/core/Enum';
import {
  Currency,
  GlobalComponent,
  GroupListModel,
  ReceiptModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  GetBankAccountListForBankEntryAndCashEntry,
  GetCurrencyList,
  GetCustomBankAccountList,
  GetCashAccountList,
  GetNonStandardAccountList,
  GetTransactionTypeList,
  MenuState,
  PaymentsState,
  CreateAccount,
  GetDataByAccountId,
  CreateContact,
} from '@app/core/Store';
import { QuickAddComponent, ReceiptDetailsComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-receipt',
  templateUrl: './add-receipt.component.html',
  styleUrls: ['./add-receipt.component.scss'],
})
export class AddReceiptComponent implements OnInit {
  receiptForm: FormGroup;
  receiptId = Guid.EMPTY as unknown as Guid;

  receiptData: ReceiptModel;

  customerList: SideListModel[];
  accountTypeList: SideListModel[];
  accountList: any[] = [];
  currencyList: Currency[];
  receiptAccountList: any[] = [];
  tempReceiptAccountList: any[];
  getListId: Guid;

  @Input() bankId;

  accountGroupList: GroupListModel[];
  tempAccountGroupList: any[];

  subscriptionRouting: Subscription;
  moduleName = ModuleName;
  moduleId = Modules;

  amountReceivedValue = 0;
  amountReceivedTotal = 0;
  amountReceived = 0;
  isAdvance = false;

  @Input()
  getModuleName?: string;

  @Input()
  getModuleId: number;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  @ViewChild(ReceiptDetailsComponent, { static: true })
  receiptInfo;

  triggerCustomer: Subject<any> = new Subject<any>();
  triggerReceiptDetail: Subject<any> = new Subject<any>();

  @Input() triggerTransactionLogData: Observable<any>;
  @Input() triggerEditData: Observable<any>;

  transactionType = [
    {
      id: TransactionStatus.Receipt,
      name: TransactionStatus[TransactionStatus.Receipt],
    },
    {
      id: TransactionStatus.Payment,
      name: TransactionStatus[TransactionStatus.Payment],
    },
  ];

  customerLabelText: string;
  accountLabelText: string;

  defaultCurrency: Guid;
  periodicDate: any;

  isEdit = false;
  disabledEditButton = true;
  showReceiptDetails = false;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  accountingMehtodId: any;
  accountingMethod = AccountingMethod;
  isFromBankImport = false;

  private destroy$ = new Subject<void>();
  isCustomerSupplierChangePermission: boolean = true;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.accountingMehtodId = this.globalComponent.getAccountingMethod();
    this.isEdit = false;
    this.setForm();
    this.loadDropdownValues();

    this.triggerTransactionLogData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isFromBankImport = true;
        this.bindCurrency();
        this.onAccountTypeChange(1);
        this.receiptForm.controls.accountTypeId.setValue(1);
        this.receiptForm.controls.accountId.setValue(data.accountId);
        this.receiptForm.controls.amountReceived.setValue(data.amount);
        this.receiptForm.controls.receiptDate.setValue(data.entryDate);
        this.receiptForm.controls.note.setValue(data.note);
        this.receiptForm.controls.receiptDate.disable();
        this.receiptForm.controls.amountReceived.disable();
        this.receiptForm.controls.accountId.disable();
        this.receiptForm.controls.accountTypeId.disable();
      });

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isEdit = true;
        this.loadDropdownValues();
        if (
          data.transactionTypeId > 0 &&
          (this.getModuleId === Modules.Receipt ||
            this.getModuleId === Modules.Payment)
        ) {
          this.onAccountTypeChange(data.transactionTypeId);
        }
        this.spinner.show();
        setTimeout(() => {
          this.editReceipt(data);
          this.spinner.hide();
        }, 2000);
      });

    this.receiptForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.receiptForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (
        this.getModuleId === Modules.Payment ||
        this.receiptForm.controls.transactionType.value ===
          TransactionStatus.Payment
      ) {
        this.isCustomerSupplierChangePermission =
          this.commonService.checkPermission(
            Modules.Contacts,
            Modules.Supplier
          );
      } else if (
        this.getModuleId === Modules.Receipt ||
        this.receiptForm.controls.transactionType.value ===
          TransactionStatus.Receipt
      ) {
        this.isCustomerSupplierChangePermission =
          this.commonService.checkPermission(
            Modules.Contacts,
            Modules.Customers
          );
      }
    }, 3000);
  }

  resetAccountList(): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(this.receiptForm.controls.accoutListBank.value);
  }

  onAccountSearch2(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setLabelText(): void {
    if (
      this.getModuleId === Modules.Payment ||
      this.receiptForm.controls.transactionType.value ===
        TransactionStatus.Payment
    ) {
      this.customerLabelText = 'Supplier Name';
    } else if (
      this.getModuleId === Modules.Receipt ||
      this.receiptForm.controls.transactionType.value ===
        TransactionStatus.Receipt
    ) {
      this.customerLabelText = 'Customer Name';
    }
  }

  bindAccountType(): void {
    if (
      this.getModuleId === Modules.Payment ||
      this.getModuleId === Modules.Receipt
    ) {
      this.store
        .dispatch(new GetTransactionTypeList())
        .pipe(
          tap(() => {
            this.accountTypeList = this.store.selectSnapshot(
              PaymentsState.getTransactionType
            );

            if (this.accountTypeList.length > 0 && !this.isEdit) {
              this.receiptForm.controls.accountTypeId.setValue(
                this.accountTypeList[0].id
              );
              this.onAccountTypeChange(this.accountTypeList[0].id);
            }
          })
        )
        .subscribe();
    } else if (this.getModuleId === Modules.BankEntry) {
      this.getBankAccountList();
    } else {
      this.getCashAccountList();
    }
  }

  bindCustomerSupplier(id?: Guid): void {
    const entityId = new Array<number>();

    if (
      this.getModuleId === Modules.Payment ||
      this.receiptForm.controls.transactionType.value ===
        TransactionStatus.Payment
    ) {
      entityId.push(AccountEntity.Supplier);
    } else if (
      this.getModuleId === Modules.Receipt ||
      this.receiptForm.controls.transactionType.value ===
        TransactionStatus.Receipt
    ) {
      entityId.push(AccountEntity.Customer);
    }

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap(() => {
          this.customerList = this.store.selectSnapshot(
            CommonState.accountList
          );

          if (this.customerList.length > 0 && !this.isEdit) {
            this.disabledEditButton = false;
            this.receiptForm.controls.customerName.setValue(
              this.customerList[0].id
            );
            this.triggerCustomer.next(this.receiptForm);
          }
          if (id !== null && id !== undefined) {
            this.receiptForm.controls.customerName.setValue(id);
          }
          this.triggerCustomer.next(this.receiptForm);
        })
      )
      .subscribe();
  }

  bindCurrency(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.currencyList.length > 0) {
            this.receiptForm.controls.currency.setValue(this.defaultCurrency);
          }
        })
      )
      .subscribe();
  }

  loadDropdownValues(): void {
    this.bindAccountType();

    this.bindCurrency();

    if (
      this.getModuleId === Modules.BankEntry ||
      this.getModuleId === Modules.CashEntry
    ) {
      this.getBankAccountListForBankEntryAndCashEntry();
    } else {
      this.showReceiptDetails = true;
      this.bindCustomerSupplier();
    }
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId:
            this.getModuleId === Modules.Receipt
              ? Modules.Customers
              : Modules.Suppliers,
          headerText: `Add ${
            this.getModuleId === Modules.Receipt
              ? ModuleName.Customers
              : ModuleName.Suppliers
          }`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.bindCustomerSupplier(id);
        }
      });
  }

  gotoEditCustomer(): void {
    if (this.customerList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId:
              this.getModuleId === Modules.Receipt
                ? Modules.Customers
                : Modules.Suppliers,
            headerText: `Edit ${
              this.getModuleId === Modules.Receipt
                ? ModuleName.Customers
                : ModuleName.Suppliers
            }`,
            id: this.receiptForm.controls.currency.value,
            saveActionName: CreateContact,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.bindCustomerSupplier(id);
            if (id !== null && id !== undefined) {
              this.receiptForm.controls.currency.setValue(id);
            }
          }
        });
    }
  }

  editReceipt(data: any): void {
    let accoutListBank: any = '';
    if (
      this.getModuleId === Modules.BankEntry ||
      this.getModuleId === Modules.CashEntry
    ) {
      this.accountGroupList.forEach((items) => {
        const accoutList = items.listModels.find(
          (x: any) => x.id === data.accountId
        );

        if (accoutList !== null && accoutList !== undefined) {
          accoutListBank = accoutList;
        }
      });
      this.accountGroupSelected(accoutListBank, true);
      this.receiptForm.controls.accountId.disable();
      this.receiptForm.controls.accoutListBank.disable();
    } else {
      this.receiptForm.controls.accountTypeId.disable();
      this.receiptForm.controls.accountId.disable();
      this.receiptForm.controls.customerName.disable();
      this.showReceiptDetails = true;
    }

    this.receiptForm.controls.code.disable();
    this.disabledEditButton = false;
    this.receiptForm.patchValue({
      accountTypeId: data.transactionTypeId,
      code: data.code,
      accountId: data.postingAccountId,
      accoutListBank: accoutListBank,
      customerName: data.accountId,
      receiptDate: data.receiptDate,
      note: data.note,
      amountReceived: data.totalAmount,
      currency: data.currencyId,
      transactionType: data.isCredit ? 1 : 0,
      isAdvance: data.isAdvance,
    });
    this.setLabelText();
    this.triggerReceiptDetail.next(data);
    this.showReceiptDetails = !data.isAdvance;
  }

  onAmountReceivedChange(): void {
    if (this.showReceiptDetails && !this.isFromBankImport) {
      this.amountReceivedValue = this.receiptForm.controls.amountReceived.value;
      this.amountReceivedTotal = this.receiptInfo.totalAmount;

      if (
        this.getModuleId === Modules.Receipt ||
        this.getModuleId === Modules.Payment
      ) {
        this.receiptForm.controls.amountReceived.setValue(
          +this.amountReceivedTotal
        );
      }
    }
  }

  onTransactionTypeChange(): void {
    if (
      this.getModuleId === Modules.BankEntry ||
      this.getModuleId === Modules.CashEntry
    ) {
      this.getBankAccountListForBankEntryAndCashEntry();
    } else {
      this.bindCustomerSupplier();
    }
    this.setLabelText();
    if (this.showReceiptDetails) {
      this.triggerCustomer.next(this.receiptForm);
    }
  }

  onAccountTypeChange(event: any): void {
    this.receiptForm.controls.accountId.setValue('');
    const data = this.accountTypeList.find((x) => x.id === event);

    if (data?.id === 1) {
      this.getBankAccountList();
    } else if (data?.id === 2) {
      this.getCashAccountList();
    }
  }

  onAccountChange(event: any): void {
    if (this.getModuleId === Modules.BankEntry) {
      this.tempAccountGroupList =
        this.getBankAccountListForBankEntryAndCashEntry();
      this.tempAccountGroupList.forEach((items) => {
        items.listModels = items.listModels.filter((i) => i.id !== event);
      });

      this.accountGroupList = this.tempAccountGroupList;
    }
  }

  getBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.receiptAccountList = res.common.customBankAccount;
          this.tempReceiptAccountList = this.receiptAccountList;
          if (!this.commonService.isEmpty(this.bankId)) {
            this.receiptForm.controls.accountId.setValue(this.bankId);
            this.receiptForm.controls.accountId.disable();
          }
        })
      )
      .subscribe();
  }

  getBankAccountListForBankEntryAndCashEntry(): any {
    const entityId = new Array<number>();

    if (
      this.receiptForm.controls.transactionType.value ===
      TransactionStatus.Payment
    ) {
      entityId.push(AccountEntity.Supplier);
    } else if (
      this.receiptForm.controls.transactionType.value ===
      TransactionStatus.Receipt
    ) {
      entityId.push(AccountEntity.Customer);
    }

    this.store
      .dispatch(new GetBankAccountListForBankEntryAndCashEntry(entityId))
      .pipe(
        tap((res) => {
          this.accountGroupList = res.receipt.acctListForBankEntryAndCashEntry;
          this.tempAccountGroupList = this.accountGroupList;
        })
      )
      .subscribe();

    return this.tempAccountGroupList;
  }

  getCashAccountList(): void {
    this.store
      .dispatch(new GetCashAccountList())
      .pipe(
        tap((res) => {
          this.receiptAccountList = res.common.accountList;
          this.tempReceiptAccountList = this.receiptAccountList;
        })
      )
      .subscribe();
  }

  getOptionText(option) {
    return option.name;
  }

  onAccountSearch(event: any): void {
    this.receiptAccountList = this.tempReceiptAccountList;
    const projectSearhText = event.currentTarget.value;
    const trimProjectSearhText = projectSearhText.trimStart();
    let listModel: any;
    this.receiptAccountList.filter((item) => {
      if (
        item.name.toLowerCase().includes(trimProjectSearhText.toLowerCase())
      ) {
        listModel.push(item);
      }
    });

    if (trimProjectSearhText) {
      this.receiptAccountList = listModel;
    }
  }

  setForm(): void {
    this.receiptForm = new FormGroup(
      {
        accountTypeId: new FormControl(0),
        accountId: new FormControl('', [Validators.required]),
        code: new FormControl(''),
        customerName: new FormControl('', [Validators.required]),
        transactionType: new FormControl(TransactionStatus.Receipt),
        receiptDate: new FormControl(
          new Date(),
          datePickerValidator(this.periodicDate)
        ),
        currency: new FormControl({ value: '', disabled: true }, [
          Validators.required,
        ]),
        amountReceived: new FormControl('', [Validators.required]),
        isAdvance: new FormControl(false),
        note: new FormControl(''),
        accoutListBank: new FormControl('', [Validators.required]),
      },
      {
        validators: this.commonService.compareFields(
          'accountId',
          'accoutListBank',
          this.getModuleId
        ),
      }
    );

    if (
      this.getModuleId === Modules.Receipt ||
      this.getModuleId === Modules.Payment
    ) {
      this.receiptForm.controls.amountReceived.disable();
    }

    if (
      this.getModuleId === Modules.BankEntry ||
      this.getModuleId === Modules.CashEntry
    ) {
      this.receiptForm.controls.customerName.clearValidators();
      this.receiptForm.controls.customerName.updateValueAndValidity();
    } else {
      this.receiptForm.controls.accoutListBank.clearValidators();
      this.receiptForm.controls.accoutListBank.updateValueAndValidity();
    }
    this.accountLabelText =
      this.getModuleId === Modules.BankEntry ? 'Bank' : 'Account';

    this.setLabelText();
  }

  accountGroupSelected(event: any, isEdit): void {
    const entityType = [AccountEntity.Customer, AccountEntity.Supplier];

    this.showReceiptDetails = entityType.includes(
      isEdit ? event.accountTypeId : +event.option.value.accountTypeId
    );

    if (!isEdit && this.showReceiptDetails) {
      this.receiptForm.controls.customerName.setValue(event.option.value.id);
      this.onCustomerChange('');
    }

    if (this.getModuleId === Modules.BankEntry) {
      this.receiptAccountList = this.tempReceiptAccountList.filter(
        (i) => i.id !== event.id
      );
    }
  }

  onCustomerChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }

    if (this.showReceiptDetails) {
      for (let i = 0; i < this.receiptInfo.receiptDetailArray.length; i++) {
        this.receiptInfo.receiptDetailArray.controls[i]
          .get('invoiceReceipt')
          .setValue(0);
        this.receiptInfo.receiptDetailArray.controls[i]
          .get('id')
          .setValue(Guid.EMPTY as unknown as Guid);
      }

      this.triggerCustomer.next(this.receiptForm);
    }
  }

  getCustomBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.accountList = res.common.accountList;

          if (this.accountList.length > 0) {
            this.receiptForm.controls.accountId.setValue(
              this.accountList[0].id
            );
          } else {
            this.receiptForm.controls.accountId.reset();
          }
        })
      )
      .subscribe();
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onCheckBoxSelected(event: any): void {
    this.isAdvance = event.checked;
    this.showReceiptDetails = !event.checked;

    if (event.checked) {
      this.receiptForm.controls.amountReceived.setValue(0);
      this.receiptForm.controls.amountReceived.enable();
    } else {
      this.receiptForm.controls.amountReceived.setValue(
        this.amountReceivedTotal
      );
      this.receiptForm.controls.amountReceived.disable();
    }
  }
}
