import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnnualReportModel, GetNoteModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class AnnualReportService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  saveAnnualReport(annualReport: AnnualReportModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AnnualReport/save`,
      this.commonService.trimObjectSpace(JSON.stringify(annualReport)),
      headers
    );
  }

  getByAccountPeriod(accountPeriodId: Guid): Observable<any> {
    return this.http.get<GetNoteModel>(
      `${environment.apiVersionUrl}AnnualReport/getByAccountPeriod/${accountPeriodId}`
    );
  }

  getListSavedFile(accountPeriodId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}AnnualReport/listSavedFile/${accountPeriodId}`,
      headers
    );
  }

  export(accountPeriodId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}AnnualReport/export`,
        JSON.stringify(accountPeriodId),
        headers
      )
      .pipe(
        switchMap((response: any) => {
          const body: Blob = response.body || new Blob();
          return of(body);
        })
      );
  }

  print(body: Blob): void {
    const file = new Blob([body], {
      type: 'application/pdf',
    });
    const blobUrl = URL.createObjectURL(file);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow?.print();
  }

  getTrusteesReport(accountPeriodId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}AnnualReport/defaultTrusteeReport`,
        JSON.stringify(accountPeriodId),
        headers
      )
      .pipe(
        switchMap((response) => {
          this.commonService.download(response);

          return of(true);
        })
      );
  }

  getIdependentExaminerReport(accountPeriodId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}AnnualReport/defaultIndependentExaminerReport`,
        JSON.stringify(accountPeriodId),
        headers
      )
      .pipe(
        switchMap((response) => {
          this.commonService.download(response);

          return of(true);
        })
      );
  }

  getAnnualReportContent(accountPeriodId: Guid): Observable<any> {
    return this.http.get(
      `${environment.apiVersionUrl}AnnualReport/annualReportWord/${accountPeriodId}`,
      {
        responseType: 'text',
      }
    );
  }

  getAccountingMethod(): Observable<any> {
    return this.http.get<GetNoteModel>(
      `${environment.apiVersionUrl}Company/getAccountingMethod`
    );
  }

  getSavedAnnualReportResponse(
    accountPeriodId: Guid,
    Id: any
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}AnnualReport/openSavedFile/${accountPeriodId}/${Id}`
    );
  }

  getByAccountPeriodReponse(accountPeriodId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}AnnualReport/annualReportResponse/${accountPeriodId}`
    );
  }

  saveAnnualReportResponse(param: any, accountPeriodId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AnnualReport/saveAnnualReportFile/${accountPeriodId}`,
      JSON.stringify(param),
      headers
    );
  }

  uploadAnnualReportResponse(formData: any): Observable<any> {
    return this.http.post<any>(
      `${environment.apiVersionUrl}FileUpload/multipleUpload`,
      formData
    );
  }

  deleteSavedFile(Ids: Array<Guid>, accountPeriodId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(Ids)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}AnnualReport/deleteSavedFile/${accountPeriodId}`,
      options
    );
  }

  downloadSavedAnnualReport(
    accountingPeriodId: Guid,
    id: Guid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}AnnualReport/downloadSavedFile/${accountingPeriodId}/${id}`,
        headers
      )
      .pipe(
        switchMap((response: any) => {
          const body: Blob = response.body || new Blob();

          this.commonService.download(response);

          return of(true);
        })
      );
  }
}
