import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Modules } from '@app/core/Enum';
import { ExportFormat } from '@app/core/Enum/export-type';
import { MainListParameters } from '@app/core/Models';
import { MenuState } from '@app/core/Store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-side-list-actions',
  templateUrl: './side-list-actions.component.html',
  styleUrls: ['./side-list-actions.component.scss'],
})
export class SideListActionsComponent implements OnInit {
  numberOfRecords = 0;
  moduleId;
  listParameters: MainListParameters = new MainListParameters();
  istoggleSideList = false;

  @Input() getModuleId: number;

  @Input() toggleSideList: Observable<boolean>;

  @Input() triggerNumberOfRecords: Observable<any>;

  @Input() triggerSelectedRowData: Observable<any>;

  @Output()
  readonly deleteClick = new EventEmitter<any>();

  @Output()
  readonly exportClick = new EventEmitter<any>();

  @Output()
  readonly copyClick = new EventEmitter<any>();

  @Output()
  readonly cancelSelectionClick = new EventEmitter<any>();

  @Output()
  readonly printClick = new EventEmitter<any>();

  @Output()
  readonly archiveAndRestoreClick = new EventEmitter<any>();

  @Output()
  readonly emailClick = new EventEmitter<any>();

  exportFormat = ExportFormat;
  moduleEnum = Modules;

  disableActiveAction: boolean;
  disableInActiveAction: boolean;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  constructor(public datepipe: DatePipe, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.triggerNumberOfRecords.subscribe((data: any) => {
      this.numberOfRecords = data && data > 0 ? data : 0;
    });

    this.toggleSideList.subscribe((data: boolean) => {
      this.istoggleSideList = !this.istoggleSideList;
    });

    this.triggerSelectedRowData?.subscribe((data) => {
      if (data.length > 0) {
        this.disableActiveAction = data.includes(false) && !data.includes(true);
        this.disableInActiveAction =
          data.includes(true) && !data.includes(false);
      }
    });
  }

  export(format: number): void {
    this.exportClick.emit(format);
  }

  delete(): void {
    this.deleteClick.emit();
  }

  copy(): void {
    this.copyClick.emit();
  }

  cancelSelection(): void {
    this.cancelSelectionClick.emit();
  }

  print(): void {
    this.printClick.emit();
  }

  archiveAndRestore(isArchive: boolean): void {
    this.archiveAndRestoreClick.emit(isArchive);
  }

  email(): void {
    this.emailClick.emit();
  }
}
