<table
  mat-table
  matSort
  aria-describedby="Import Success List"
  [dataSource]="dataSource"
>
  <ng-container matColumnDef="donationDate">
    <th mat-header-cell *matHeaderCellDef>Donation Date</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.donationDate | date: "dd-LLL-yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.title }}
    </td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef>First Name</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.firstName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef>Last Name</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.lastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="houseNumber">
    <th mat-header-cell *matHeaderCellDef>House Number</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.houseNumber }}
    </td>
  </ng-container>

  <ng-container matColumnDef="postCode">
    <th mat-header-cell *matHeaderCellDef>Post Code</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ element.postCode }}
    </td>
  </ng-container>

  <ng-container matColumnDef="donationAmount">
    <th mat-header-cell *matHeaderCellDef>Donation Amount</th>
    <td mat-cell *matCellDef="let element; let i = index">
      £ {{ element.donationAmount | numberPipe }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
