import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Modules, OverviewYear } from '@app/core/Enum';
import { FilterDateRange, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetFundTypeList } from '@app/core/Store';
import { Store } from '@ngxs/store';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexLegend,
  ApexGrid,
  ApexFill,
  ApexStroke,
} from 'ng-apexcharts';
import { tap } from 'rxjs/operators';

export type donationOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  grid: ApexGrid;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
};

export type donorOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  grid: ApexGrid;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'app-donation-overview',
  templateUrl: './donation-overview.component.html',
  styleUrls: ['./donation-overview.component.scss'],
})
export class DonationOverviewComponent {
  @ViewChild('chart') chart: ChartComponent;

  moduleId = Modules.DonationOverview;
  selectedYearValue = OverviewYear['Last 30 days'];

  fundIdForBreakdown: number;
  fundIdForIncomeVsExpenses: number;
  fundTypeList: SideListModel[];
  showCalender = false;
  dateRange: any;

  @Output()
  readonly triggerHeaderFilter = new EventEmitter<any>();

  @Output()
  readonly triggerHeaderFilterForFundBreakdown = new EventEmitter<any>();

  selectYear = OverviewYear;

  constructor(
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private store: Store,
    public commonService: CommonService
  ) {}

  rangeFormGroup: FormGroup;

  ngOnInit(): void {
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );
    this.store.dispatch(new GetFundTypeList()).subscribe();
    this.setForm();
  }

  setForm(): void {
    this.getFundTypeList();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  getFundTypeList(): void {
    this.store
      .dispatch(new GetFundTypeList())
      .pipe(
        tap((res) => {
          this.fundTypeList = res.account.fundTypeList;
          this.fundIdForBreakdown = this.fundTypeList[0].id;
          this.fundIdForIncomeVsExpenses = this.fundTypeList[0].id;
          this.getData();
        })
      )
      .subscribe();
  }

  onFundTypeChangeForBreakDown(): void {
    const data = this.getFilter();
    data.moduleId = this.fundIdForBreakdown;
    this.triggerHeaderFilterForFundBreakdown.emit(data);
  }

  onSelectionChange(val: any): void {
    this.showCalender = +val === 7;
    this.dateRange = this.commonService.generateDateSeries(val);

    if (
      this.showCalender &&
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.dateRange = new FilterDateRange();
      this.dateRange.startDate = new Date(
        this.rangeFormGroup.controls.start.value
      );
      this.dateRange.endDate = new Date(this.rangeFormGroup.controls.end.value);
      this.getData();
    } else if (!this.showCalender) {
      this.getData();
    }
    this.onFundTypeChangeForBreakDown();
  }

  triggerWidgetClick(data: any): void {
    this.fundIdForBreakdown = data.id;
    this.onFundTypeChangeForBreakDown();
  }

  setDonationBreakDown(fundTypeId: any): void {
    this.fundIdForBreakdown = fundTypeId;
    this.onFundTypeChangeForBreakDown();
  }

  getFilter(): any {
    const filterData = {
      moduleId: this.fundIdForBreakdown,
      dateFilter: {
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(
                  this.rangeFormGroup.controls.start.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),

        endDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(this.rangeFormGroup.controls.end.value, 'yyyy-MM-dd')
                ?.toString(),
      },
    };

    return filterData;
  }

  getData(): void {
    const data = this.getFilter();

    setTimeout(() => {
      this.triggerHeaderFilter.emit(data);
    }, 500);
  }
}
