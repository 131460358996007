import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note36',
  templateUrl: './note36.component.html',
  styleUrls: ['./note36.component.scss'],
})
export class Note36Component implements OnInit {
  note36Form: FormGroup;

  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      this.setForm();

      if (
        data.noteId === Notes.Note36 &&
        data.response.userInputData !== null &&
        data.response.userInputData !== undefined &&
        data.response.userInputData.data !== null &&
        data.response.userInputData.data !== undefined
      ) {
        this.editNotes36(data.response.userInputData.data);
      }
    });
  }

  setForm(): void {
    this.note36Form = new FormGroup({
      textbox1: new FormControl(''),
      textbox2: new FormControl(''),
      textbox3: new FormControl(''),
    });
  }

  editNotes36(res): void {
    this.note36Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : '',
      textbox2: res.textbox2 ? res.textbox2 : '',
      textbox3: res.textbox3 ? res.textbox3 : '',
    });
  }
}
