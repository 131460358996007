import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-note-header',
  templateUrl: './note-header.component.html',
  styleUrls: ['./note-header.component.scss'],
})
export class NoteHeaderComponent {
  triggerDateChange(data: any): void {}
}
