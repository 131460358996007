import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RoutingPath } from '@app/core/Enum';

@Component({
  selector: 'app-convert-to-invoice-popup',
  templateUrl: './convert-to-invoice-popup.html',
  styleUrls: ['./convert-to-invoice-popup.scss'],
})
export class ConvertToInvoicePopupComponent implements OnInit {
  invoiceNumber: string;
  quotationNumber: string;
  invoiceId: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConvertToInvoicePopupComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.invoiceNumber = this.data.invoiceNumber;
    this.quotationNumber = this.data.quotationNumber;
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onEditInvoiceClick(): void {
    let params: any;
    params = {
      id: btoa(this.data.invoiceId),
      isFromQuotation: btoa('true'),
    };
    this.router.navigate([RoutingPath.AddInvoice, params]);
    this.dialogRef.close(false);
  }
}
