import { FileImportRequestModel } from '@app/core/Enum/import';
import {
  GiftAidSubmissionModel,
  HMRCGatewayDetails,
  MainListParameters,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetGiftAidDetails {
  static readonly type = '[GIFTAIDDETAILS]  Get GiftAid Details';
  constructor(public id: Guid) {}
}

export class SaveHMRCGatewayDetails {
  static readonly type = '[GIFTAIDDETAILS]  Save HMRC Gateway Details';

  constructor(public saveHMRCGatewayDetailsData: HMRCGatewayDetails) {}
}

export class GetSubContractorDetailsList {
  static readonly type = '[GIFTAIDDETAILS]  Get Sub Contractor Details List';
  constructor(
    public queryParams: MainListParameters,
    public cisReturnId: Guid
  ) {}
}

export class SaveGiftAidDetails {
  static readonly type = '[GIFTAIDDETAILS] Save Gift Aid Details';

  constructor(public giftAidDetailsData: GiftAidSubmissionModel) {}
}

export class DeleteGiftAidDetails {
  static readonly type = '[GIFTAIDDETAILS] Delete Gift Aid Details';

  constructor(public giftAddDetailsId: Guid) {}
}

export class GiftAidImport {
  static readonly type = '[GIFTAIDIMPORT] Gift Aid Import';
  constructor(public fileImportRequestModel: FileImportRequestModel) {}
}
