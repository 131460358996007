import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  GroupNames,
  Modules,
  NotificationTextMessage,
  TransactionStatus,
} from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { ReceiptDetailsComponent } from '@app/modules/income';
import { Guid } from 'guid-typescript';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bank-reconciliation-details',
  templateUrl: './bank-reconciliation-details.component.html',
  styleUrls: ['./bank-reconciliation-details.component.scss'],
})
export class BankReconciliationDetailsComponent implements OnInit {
  bankReconciliationForm: FormGroup;

  receiptItems = new Array<any>();

  triggerCustomer: Subject<any> = new Subject<any>();
  triggerReceiptDetail: Subject<any> = new Subject<any>();

  getModuleId = 0;
  amountReceivedValue = 0;

  @ViewChild(ReceiptDetailsComponent, { static: true })
  receiptDetails;
  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BankReconciliationDetailsComponent>
  ) {
    this.bankReconciliationForm = new FormGroup({
      customerName: new FormControl(Guid.EMPTY as unknown as Guid),
      transactionType: new FormControl(TransactionStatus.Receipt),
      accountGroupId: new FormControl(GroupNames.CurrentAssets_Debtors),
    });
  }

  ngOnInit(): void {
    if (
      this.data.transactionType !== null &&
      this.data.transactionType !== undefined
    ) {
      this.getModuleId =
        this.data.transactionType === TransactionStatus.Receipt
          ? Modules.Receipt
          : Modules.Payment;
    }

    this.amountReceivedValue = this.data.amountReceivedValue;

    setTimeout(() => {
      this.bankReconciliationForm
        .get('transactionType')
        ?.setValue(this.data.transactionType);
      this.bankReconciliationForm
        .get('customerName')
        ?.setValue(this.data.customerName);
      this.bankReconciliationForm
        .get('accountGroupId')
        ?.setValue(this.data.accountGroupId);
      this.triggerCustomer.next(this.bankReconciliationForm);

      if (
        this.data.receiptItems !== null &&
        this.data.receiptItems !== undefined
      ) {
        const param = {
          accountId: this.data.customerName,
          accountGroupId: this.data.accountGroupId,
          receiptItems: this.data.receiptItems,
        };

        this.triggerReceiptDetail.next(param);
      }
    }, 500);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSave(): void {
    if (this.data.isBankReconciliation) {
      if (
        Math.abs(this.amountReceivedValue) < this.receiptDetails.totalAmount
      ) {
        this.receiptDetails.receiptDetailArray.controls.forEach((x) => {
          (Object as any)
            .values(x.controls)
            .forEach((c) => c.markAsTouched(false));
          x.controls.amount.status = 'INVALID';
        });
        this.commonService.onFailure(
          NotificationTextMessage.amountErrorMessage
        );
        return;
      }
    }

    this.receiptItems = new Array<any>();

    this.receiptDetails.receiptDetailArray?.getRawValue().forEach((x) => {
      if (x.invoiceReceipt !== 0) {
        this.receiptItems.push({
          invoiceId: x.invoiceReceipt,
          amount: +x.amount,
        });
      }
    });

    this.dialogRef.close(this.receiptItems);
  }

  onAmountChange(amount: number): void {
    this.bankReconciliationForm.get('amountReceived')?.setValue(amount);
  }
}
