<div id="chart" *ngIf="!isNoRecord && isloadData">
  <apx-chart
    [series]="chartOptionsv1?.series!"
    [chart]="chartOptionsv1?.chart!"
    [xaxis]="chartOptionsv1?.xaxis!"
    [legend]="chartOptionsv1?.legend!"
    [stroke]="chartOptionsv1?.stroke!"
    [fill]="chartOptionsv1?.fill!"
    [markers]="chartOptionsv1?.markers!"
    [tooltip]="chartOptionsv1?.tooltip!"
    [dataLabels]="chartOptionsv1?.dataLabels!"
    [noData]="chartOptionsv1?.noData!"
    [colors]="chartOptionsv1?.colors!"
  ></apx-chart>
</div>

<div class="norecord-chart" *ngIf="isNoRecord && isloadData">
  <img src="assets/images/line-icon.svg" alt="Line Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
