import { Injectable } from '@angular/core';
import { DebitNoteModel, SideListModel } from '@app/core/Models';
import { DebitNoteService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Guid } from 'guid-typescript';
import {
  ArchiveAndRestoreDebitNote,
  CreateDebitNote,
  DeleteDebitNote,
  GetDataByDebitNoteId,
} from './debit-note.action';

export class DebitNoteStateInfo {
  debitNoteData?: DebitNoteModel;
  debitNote: Array<DebitNoteModel>;
  debitNoteId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isDebitNoteAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
}

@State<DebitNoteStateInfo>({
  name: 'debitNote',
  defaults: {
    debitNote: [],
    debitNoteId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isDebitNoteAdded: false,
  },
})
@Injectable()
export class DebitNoteState {
  constructor(private debitNoteService: DebitNoteService) {}

  @Selector()
  static isLastPage(state: DebitNoteStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getDebitNoteId(state: DebitNoteStateInfo) {
    return state.debitNoteId;
  }

  @Action(CreateDebitNote)
  createDebitNote(
    { patchState }: StateContext<DebitNoteStateInfo>,
    action: CreateDebitNote
  ) {
    return this.debitNoteService.createDebitNote(action.debitNote).pipe(
      tap((res) => {
        patchState({
          debitNoteId: res,
          isDebitNoteAdded: true,
        });
      })
    );
  }

  @Action(GetDataByDebitNoteId)
  getDataByDebitNoteId(
    { patchState }: StateContext<DebitNoteStateInfo>,
    action: GetDataByDebitNoteId
  ) {
    return this.debitNoteService.getDataByDebitNoteId(action.debitNoteId).pipe(
      tap((res) => {
        patchState({
          debitNoteData: res,
        });
      })
    );
  }

  @Action(DeleteDebitNote)
  deleteDebitNote(
    { getState, setState }: StateContext<DebitNoteStateInfo>,
    action: DeleteDebitNote
  ) {
    return this.debitNoteService.deleteDebitNote(action.debitNoteIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredCrediteNote = state.debitNote.filter(
          (item) =>
            !action.debitNoteIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.debitNoteIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.debitNote,
          debitNote: filteredCrediteNote,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreDebitNote)
  archiveAndRestoreDebitNote(
    { getState }: StateContext<DebitNoteStateInfo>,
    action: ArchiveAndRestoreDebitNote
  ) {
    return this.debitNoteService.archiveAndRestoreDebitNote(
      action.debitNoteIds,
      action.isArchive
    );
  }
}
