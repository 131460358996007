import { Component, OnInit, Renderer2 } from '@angular/core';
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AccountEntity, GroupNames, MaxLength } from '@app/core/Enum';
import { GroupListModel, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetAllDonor,
  GetCustomBankAndCashAccount,
  DonorState,
  CommonState,
  GetGroupAccountsBasedOnGroupId,
  GetGroupNonStandardAccountList,
} from '@app/core/Store';
import { CleanAllLinesComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-bulk-donation-coding',
  templateUrl: './add-bulk-donation-coding.component.html',
  styleUrls: ['./add-bulk-donation-coding.component.scss'],
})
export class AddBulkDonationCodingComponent implements OnInit {
  formDonationCodingDetail: UntypedFormGroup;
  donationCodingDetailArray: any;
  tableDataSource: MatTableDataSource<AbstractControl>;

  displayDonationCodingDetailsColumns: string[] = [
    'donorName',
    'depositTo',
    'incomeAccount',
    'notes',
    'donationAmount',
    'closeButton',
  ];

  donorList: any;
  depositToList: SideListModel[];
  tempIncomeAccountGroupList: GroupListModel[];
  incomeAccountList: GroupListModel[];
  maxLength = MaxLength;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private commonService: CommonService,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setDonationCodingDetailsForm(true);
    this.loadDropdownValues(true);
    this.getInvoiceType();
    this.formDonationCodingDetail.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange =
        this.formDonationCodingDetail.touched;
    });
  }

  getOptionText(option: any) {
    return option.name;
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setDonationCodingDetailsForm(addNewRow: boolean): void {
    this.formDonationCodingDetail = new FormGroup({
      donationCodingDetailArray: new UntypedFormArray([]),
    });

    this.donationCodingDetailArray = this.formDonationCodingDetail.get(
      'donationCodingDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.donationCodingDetailArray);
    if (addNewRow) this.createRow(false);
  }

  createRow(isNewRow: boolean): void {
    this.donationCodingDetailArray = this.formDonationCodingDetail.get(
      'donationCodingDetailArray'
    ) as UntypedFormArray;

    this.donationCodingDetailArray.push(this.setForm());

    this.setDataSource(this.donationCodingDetailArray);
  }

  loadDropdownValues(isAddMode: boolean): void {
    const getAllDonorList = this.store.dispatch(new GetAllDonor());
    const getCustomBankAndCashAccount = this.store.dispatch(
      new GetCustomBankAndCashAccount()
    );
    forkJoin([getAllDonorList, getCustomBankAndCashAccount]).subscribe(
      (res) => {
        this.donorList = this.store.selectSnapshot(DonorState.getAllDonor);
        this.depositToList = this.store.selectSnapshot(
          CommonState.getCustomBankAndCashAccount
        );
      }
    );
  }

  getInvoiceType(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Income);

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames))
      .pipe(
        tap((res) => {
          this.incomeAccountList = res.common.accountGroupList;
          this.tempIncomeAccountGroupList = this.incomeAccountList;
        })
      )
      .subscribe();

    const accountTypes = new Array<number>();
    accountTypes.push(AccountEntity.Customer);

    this.store
      .dispatch(new GetGroupNonStandardAccountList(accountTypes))
      .pipe(
        tap((res) => {
          this.incomeAccountList = this.incomeAccountList.concat(
            res.common.accountGroupList
          );
          this.tempIncomeAccountGroupList =
            this.tempIncomeAccountGroupList.concat(this.incomeAccountList);
        })
      )
      .subscribe();
  }

  resetAccountList(element: any): void {
    this.incomeAccountList = this.tempIncomeAccountGroupList;
    this.scrollIntoView(element.incomeAccount.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempIncomeAccountGroupList
    );
    this.incomeAccountList = list;
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      donorName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      depositTo: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      incomeAccount: new FormControl('', [Validators.required]),
      donationAmount: new FormControl(0),
      notes: new FormControl(''),
    });
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow(true);
    }
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formDonationCodingDetail.get(
            'donationCodingDetailArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
          }
        }
      });
  }

  onDeleteDonationCodingDetails(index: number): void {
    this.donationCodingDetailArray = this.formDonationCodingDetail.get(
      'donationCodingDetailArray'
    ) as UntypedFormArray;
    if (this.donationCodingDetailArray.length === 1) {
      return;
    }

    this.donationCodingDetailArray.removeAt(index);

    this.setDataSource(this.donationCodingDetailArray);
  }

  scrollIntoView(element) {
    if (element.value.incomeAccount !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }
}
