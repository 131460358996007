import { Guid } from 'guid-typescript';

export class ConfirmationList {
  type: number;
  isArchive: boolean;
  moduleId: number;
  ids: Array<Guid>;
  startDate?: Date;
  endDate?: Date;
  headerText: string;
  detailText?: string;
  multipleModulesIds: any;
  accountingPeriod?: any;
}
