import { Component, Injector, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Modules, NotificationTextMessage } from '@app/core/Enum';
import { SideListModel } from '@app/core/Models';
import { BankDasboardService, CommonService } from '@app/core/Services';
import { GetAccountsForBankFeed } from '@app/core/Store';
import { ConfirmationBoxComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-bank-link',
  templateUrl: './bank-link.component.html',
  styleUrls: ['./bank-link.component.scss'],
})
export class BankLinkComponent implements OnInit {
  linkBankForm: FormGroup;
  bankDasboardService: BankDasboardService;
  bankAccountList: SideListModel[];
  isEditable = true;

  renderer: Renderer2;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    public commonService: CommonService,
    private store: Store,
    private injector: Injector
  ) {
    this.bankDasboardService =
      injector.get<BankDasboardService>(BankDasboardService);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.setForm();
    this.getAccountsForBankFeed();
  }

  getAccountsForBankFeed(): void {
    this.store
      .dispatch(new GetAccountsForBankFeed())
      .pipe(
        tap((res) => {
          this.bankAccountList = res.account.account;
          if (this.bankAccountList.length > 0) {
            this.linkBankForm.controls.fundName.setValue(
              this.bankAccountList[0].id
            );
          } else {
            this.commonService.onFailure(
              'No Bank Account has been added yet, you may add a Bank Account first under Charity->Bank->Overview and setup the Bank Feeds'
            );
            this.onCloseClick();
          }
        })
      )
      .subscribe();
  }

  setForm(): void {
    this.linkBankForm = new FormGroup({
      bankAccountId: new FormControl('', [Validators.required]),
      checkAgree: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onAcceptClick(): void {
    if (this.linkBankForm.invalid) {
      this.commonService.addValidation(this.linkBankForm, this.renderer);
    } else {
      localStorage.setItem('ignoreAccountModal', 'true');
      const returnUrl = window.location.origin.toString() + '/bankFeed';

      this.bankDasboardService
        .prepareUrl(returnUrl, this.linkBankForm.controls.bankAccountId.value)
        .subscribe((res: any) => {
          window.open(res, '_self');
        });
    }
  }
}
