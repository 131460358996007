import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DonationInKindModel, SideListModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class DonationInKindService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createDonationInKind(
    donationInKindInKind: DonationInKindModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}DonationInKind/create`,
      this.commonService.trimObjectSpace(JSON.stringify(donationInKindInKind)),
      headers
    );
  }

  getDataByDonationInKindId(
    donationInKindInKindId: Guid
  ): Observable<DonationInKindModel> {
    return this.http.get<DonationInKindModel>(
      `${environment.apiVersionUrl}DonationInKind/get/${donationInKindInKindId}`
    );
  }

  deleteDonationInKind(donationInKindInKindIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(
        JSON.stringify(donationInKindInKindIds)
      ),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}DonationInKind/delete`,
      options
    );
  }

  copyDonationInKind(donationInKindInKindIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}DonationInKind/copyDonations`,
      this.commonService.trimObjectSpace(
        JSON.stringify(donationInKindInKindIds)
      ),
      headers
    );
  }

  archiveAndRestoreDonationInKind(
    donationInKindInKindIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}DonationInKind/archive/${isArchive}`,
      this.commonService.trimObjectSpace(
        JSON.stringify(donationInKindInKindIds)
      ),
      options
    );
  }

  getDonationType(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}DonationType/all`
    );
  }
}
