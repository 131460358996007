import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotificationTextMessage } from '@app/core/Enum';
import { GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import { GetAdvancePaymentList } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-advance-payment',
  templateUrl: './advance-payment.component.html',
  styleUrls: ['./advance-payment.component.scss'],
})
export class AdvancePaymentComponent implements OnInit {
  @Input()
  headerText: string;

  @Input()
  totalReceiptAmount: any;

  advancePaymentDetailForm: FormGroup;
  advancePayment: any;
  allocatedAdvanceAmount: number = 0;
  paymentId = Guid.EMPTY as unknown as Guid;

  paymentForm: FormGroup;

  notificationMessage = NotificationTextMessage;

  @Input() triggereEditPaymentData: Observable<any>;
  private destroy$ = new Subject<void>();

  @Input() triggerAdvancePayment: Observable<any>;
  @Input() triggereEditAdvancePaymentData: Observable<any>;
  @Output()
  readonly hasAdvanceAmountUpdated = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerAdvancePayment?.subscribe((data) => {
      this.store
        .dispatch(new GetAdvancePaymentList(data))
        .pipe(
          tap((res) => {
            this.advancePayment = res.common.advancePayment;
            this.hasAdvanceAmountUpdated.emit(
              this.advancePayment > 0 ? true : false
            );
          })
        )
        .subscribe();
    });

    this.triggereEditAdvancePaymentData
      ?.pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.editAdvancePayment(data);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setForm(): void {
    this.advancePaymentDetailForm = new FormGroup({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      advanceReceived: new FormControl(''),
      allocateAdvance: new FormControl(''),
    });
  }

  resetForm(): void {
    this.paymentForm.reset();
  }

  editAdvancePayment(data: any): void {
    this.advancePaymentDetailForm.patchValue({
      allocateAdvance: data.advanceReceiptAmount,
    });
  }

  scrollIntoView() {
    this.commonService.autoScrollMatAutoComplete(this.renderer);
  }

  onAllocateAdvanceChange(): void {
    if (
      this.advancePaymentDetailForm.controls.allocateAdvance.value >
      this.advancePaymentDetailForm.controls.advanceReceived.value
    ) {
      this.commonService.onFailure(
        'Advance allocation cannot be grater than advance received'
      );
      this.advancePaymentDetailForm.controls['allocateAdvance'].setErrors({
        incorrect: true,
      });

      return;
    }
    if (
      this.totalReceiptAmount <
      this.advancePaymentDetailForm.controls.allocateAdvance.value
    ) {
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      this.advancePaymentDetailForm.controls['allocateAdvance'].setErrors({
        incorrect: true,
      });
      return;
    }
    this.allocatedAdvanceAmount =
      this.advancePaymentDetailForm.controls.allocateAdvance.value;
  }
}
