import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Observable, Subject, Subscription } from 'rxjs';

import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { DonationModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateDonation,
  GetBankAccountTransactionData,
  GetDataByDonationId,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import {
  AddDonationComponent,
  TransactionsAttachmentComponent,
} from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.scss'],
})
export class DonationComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isFromBankImport = false;
  isManualBank = false;

  bankImportTransactionId = Guid.EMPTY as unknown as Guid;

  donationId = Guid.EMPTY as unknown as Guid;

  moduleId = Modules.Donations;
  moduleName = ModuleName.Donations;

  subscriptionRouting: Subscription;
  donationData: DonationModel;

  triggerEditData: Subject<any> = new Subject<any>();

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddDonationComponent, { static: true })
  donationDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');

          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
          }

          const id = atob(params.get('id')!) as unknown as Guid;
          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;
            if (this.isManualBank) {
              this.getTransactionLogData();
            } else {
              this.getBankAccountTransactionData();
            }
          } else {
            this.donationId = id;
            this.editDonation();
          }
        }
      }
    );
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.donationDetails.donationForm.controls.donationDate.setValue(
          res.common.transactionLogData.transactionDate
        );
        this.donationDetails.donationForm.controls.notes.setValue(
          res.common.transactionLogData.description
        );

        this.donationDetails.donationForm.controls.donationAmount.setValue(
          res.common.transactionLogData.amount
        );

        this.donationDetails.donationForm.controls.depositTo.setValue(
          res.common.transactionLogData.accountId
        );
        this.donationDetails.donationForm.controls.donationAmount.disable();
        this.donationDetails.donationForm.controls.depositTo.disable();
        this.donationDetails.donationForm.controls.donationDate.disable();
      });
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.donationDetails.donationForm.controls.donationDate.setValue(
          res.common.transactionLogData.entryDate
        );
        this.donationDetails.donationForm.controls.notes.setValue(
          res.common.transactionLogData.note
        );

        this.donationDetails.donationForm.controls.donationAmount.setValue(
          res.common.transactionLogData.amount
        );

        this.donationDetails.donationForm.controls.depositTo.setValue(
          res.common.transactionLogData.accountId
        );

        this.donationDetails.donationForm.controls.donationAmount.disable();
        this.donationDetails.donationForm.controls.depositTo.disable();
        this.donationDetails.donationForm.controls.donationDate.disable();
      });
  }

  editDonation(): void {
    this.store
      .dispatch(new GetDataByDonationId(this.donationId))
      .subscribe((res) => {
        this.donationDetails.donationForm.markAsUntouched();
        this.triggerEditData.next(res.donation.donationData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.donationId,
      isExit,
      Modules.Donations,
      RoutingPath.AddDonations
    );
  }

  onSave(isExit: boolean): void {
    if (this.donationDetails.donationForm.invalid) {
      this.commonService.addValidation(
        this.donationDetails.donationForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        if (this.donationData) {
          this.store
            .dispatch(new CreateDonation(this.donationData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit && !this.isFromBankImport) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.donationData = {
        id: this.donationId,
        donorId: this.donationDetails.donationForm.controls.donorName.value,
        sponsorEventId:
          this.donationDetails.donationForm.controls.sponsoredEvent.value === ''
            ? null
            : this.donationDetails.donationForm.controls.sponsoredEvent.value,
        activityId:
          this.donationDetails.donationForm.controls.activity.value === ''
            ? null
            : this.donationDetails.donationForm.controls.activity.value,
        fundId: this.donationDetails.donationForm.controls.fundName.value,
        depositToAccountId:
          this.donationDetails.donationForm.controls.depositTo.value,
        depositAmount:
          +this.donationDetails.donationForm.controls.depositAmount.value,
        donationDate: this.datepipe
          .transform(
            this.donationDetails.donationForm.controls.donationDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        incomeAccount:
          this.donationDetails.donationForm.controls.incomeAccount.value.id,
        donationAmount:
          +this.donationDetails.donationForm.controls.donationAmount.value,
        benefitAmountToDonor:
          this.donationDetails.donationForm.controls.benefitAmountToDonor.value,
        note: this.donationDetails.donationForm.controls.notes.value,
        attachment: filedata,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
        isManualBank: this.isManualBank,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.donationId)) {
      this.editDonation();
    } else {
      this.donationId = Guid.EMPTY as unknown as Guid;
      this.attachmentDetail.showTable = false;
      this.attachmentDetail.fileUploadResponse = [];
      this.donationDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
