export enum RoutingPath {
  Dashboard = 'dashboard',
  AddStandardAccounts = 'accounts/standard',
  AddCustomAccounts = 'accounts/custom/add',
  AddInvoice = 'income/invoices/add',
  AddRecurringInvoice = 'income/recurringInvoice/add',
  AddQuotation = 'income/quotation/add',
  AddCreditNote = 'income/creditNote/add',
  AddReceipt = 'income/receipt/add',
  AddBills = 'expenditure/bill/add',
  AddRecurringBill = 'expenditure/recurringBill/add',
  AddDebitNote = 'expenditure/debitNote/add',
  AddPayment = 'expenditure/payment/add',
  AddCapiscan = 'expenditure/capiscan/add',
  AddJournals = 'tasks/journals/add',
  AddBulkEdit = 'tasks/bulkEdit/add',
  AddActivity = 'tasks/activities/add',
  AddSponsorEvent = 'tasks/sponsorEvent/add',
  AddDonations = 'donations/list/add',
  AddCustomers = 'contacts/customers/add',
  AddSuppliers = 'contacts/suppliers/add',
  AddDonors = 'contacts/donors/add',
  AddCashEntry = 'bank/cashEntry/add',
  AddFixedAssets = 'fixedAsset/fixedAssets/add',
  AddFixedAssetsRegister = 'fixedAssets/FixedAssetsRegister/add',
  FixedAssetsRegister = 'fixedAssets/FixedAssetsRegister',
  AddBankEntry = 'bank/bankEntry/add',
  AddCharity = 'addCharity',
  AddFund = 'fund/fundlist/add',
  AddFundTransfer = 'fund/transfer/add',
  CharityPreference = 'manage/charitypreference',
  EditCharity = 'editCharity',
  AddEmailLog = 'logs/email/add',
  AddUserLog = 'logs/user/add',
  AddBankTransfer = 'bank/transfer/add',
  AddCPI = 'contact/CPI',
  Manage = 'manage',
  DonationReport = 'donations/reports',
  IncomeOverview = 'income/overview',
  ExpenditureOverview = 'expenditure/overview',
  BankDashboard = 'bank/overview',
  ReceiptList = 'income/receipt',
  GetInvoiceList = 'income/list',
  QuotationList = 'income/quotation',
  BillList = 'expenditure/bills',
  RecurringBillList = 'expenditure/recurringBill',
  PaymentList = 'expenditure/payment',
  CashEntryList = 'bank/cashEntry',
  MainList = 'accounts/standard',
  InoviceMainList = 'income/invoices',
  FundList = 'fund/fundlist',
  CustomerList = 'contacts/customers',
  SupplierList = 'contacts/suppliers',
  DonorList = 'contacts/donors',
  RecurringInvoiceList = 'income/recurringInvoice',
  CreditNoteList = 'income/creditNote',
  DebitNoteList = 'expenditure/debitNote',
  JournalsList = 'tasks/journals',
  ActivityList = 'tasks/activities',
  SponsorEventList = 'tasks/sponsorEvent',
  BankTransferList = 'bank/transfer',
  FixedAssetsList = 'fixedAssets/fixedAssets',
  FundTransferList = 'fund/transfer',
  OpeningBalance = 'manage/openingBalance',
  AccountDetails = 'report/accountDetails',
  ManagementNote1 = 'reports/management/notes/note1',
  ManagementNote2 = 'reports/management/notes/note2',
  ManagementNote2_2 = 'reports/management/notes/note2-2',
  ManagementNote3 = 'reports/management/notes/note3',
  ManagementNote4 = 'reports/management/notes/note4',
  ManagementNote5 = 'reports/management/notes/note5',
  ManagementNote6 = 'reports/management/notes/note6',
  ManagementNote7 = 'reports/management/notes/note7',
  ManagementNote8 = 'reports/management/notes/note8',
  ManagementNote9 = 'reports/management/notes/note9',
  ManagementNote10 = 'reports/management/notes/note10',
  ManagementNote11 = 'reports/management/notes/note11',
  ManagementNote12 = 'reports/management/notes/note12',
  ManagementNote13 = 'reports/management/notes/note13',
  ManagementNote14 = 'reports/management/notes/note14',
  ManagementNote15 = 'reports/management/notes/note15',
  ManagementNote16 = 'reports/management/notes/note16',
  ManagementNote17 = 'reports/management/notes/note17',
  ManagementNote18 = 'reports/management/notes/note18',
  ManagementNote19 = 'reports/management/notes/note19',
  ManagementNote20 = 'reports/management/notes/note20',
  ManagementNote21 = 'reports/management/notes/note21',
  ManagementNote22 = 'reports/management/notes/note22',
  ManagementNote23 = 'reports/management/notes/note23',
  ManagementNote24 = 'reports/management/notes/note24',
  ManagementNote25 = 'reports/management/notes/note25',
  ManagementNote26 = 'reports/management/notes/note26',
  ManagementNote27_1 = 'reports/management/notes/note27-1',
  ManagementNote27_2 = 'reports/management/notes/note27-2',
  ManagementNote27_3 = 'reports/management/notes/note27-3',
  ManagementNote28 = 'reports/management/notes/note28',
  ManagementNote29 = 'reports/management/notes/note29',
  Import = 'manage/import',
  AddRecurringDonations = 'donations/recurringDonations/add',
  RecurringDonations = 'donations/recurringDonations',
  NominalLedger = 'reports/additional/nominalledger',
  AddDonationInKinds = 'donations/DonationsInKind/add',
  ViewVatDetails = 'mtd/viewVatDetails',
  VatSettings = 'mtd/vatSettings',
  AddVatCodes = 'mtd/vatSettings/add',
  SubmitVAT = 'mtd/submitvat',
  AddSubmitVAT = 'mtd/submitvat/add',
  AddBridgingVAT = 'mtd/bridgingvat/add',
  IncludeExcludeDetail = 'mtd/submitvat/incExcDetail',
  ReportDetailsList = 'reports/reportDetailsList',
  ReportAccountDetails = 'reports/additional/accountdetails',
  DonationCoding = 'donations/DonationCoding',
  ManagementNote33 = 'reports/management/notes/note33',
  ManagementNote34 = 'reports/management/notes/note34',
  ManagementNote35 = 'reports/management/notes/note35',
  ManagementNote36 = 'reports/management/notes/note36',
  ManagementNote37 = 'reports/management/notes/note37',
  ManagementNote38 = 'reports/management/notes/note38',
  ManagementNote39 = 'reports/management/notes/note39',
  ManagementNote40 = 'reports/management/notes/note40',
  ManagementNote41 = 'reports/management/notes/note41',
  ManagementNote42 = 'reports/management/notes/note42',
  BridgingVAT = 'mtd/bridgingvat',
  ReportAccountDetailsPermission = '/reports/additional/accountdetails',
  DonationOverview = 'donation/overview',
  FundOverview = 'fund/overview',
  FixedAssetOverview = 'fixedAsset/overview',
  BankImport = 'bankImport',
  BankTransaction = 'bankTransaction',
  ManagementNote43 = 'reports/management/notes/referenceAndAdministrativeInformation',
  CashCoding = 'bank/cashCoding',
  BankFeedBank = '/bankFeed',
  BankFeed = 'bank/bankFeed',
  GiftAidDeclaration = 'donations/giftAidClaimsSubmissiontoHMRC',
  AddGiftAidDeclaration = 'donations/giftAidClaimsSubmissiontoHMRC/add',
  EditTrialBalance = 'editTrialBalance',
  EditAccountProductionTrialBalance = 'accountProduction/editTrialBalance',
  BankManualImport = 'manualImport',
  AddUsers = 'manage/users/add',
  Users = 'manage/users',
  AccessDenied = 'access-denied',
  StatementOfFinancialActivities = 'accountProduction/management/statementoffinancialactivities',
  BalanceSheet = 'accountProduction/management/balancesheet',
  SupportTicket = 'ticket',
}
