import { DatePipe } from '@angular/common';
import {
  Component,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import {
  ActionType,
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { ExportType, MainListParameters } from '@app/core/Models';
import { BankTransactionModel } from '@app/core/Models/bank/bank-overview/bank-overview';
import {
  CommonService,
  HighlightRow,
  NotificationService,
} from '@app/core/Services';
import {
  BankOverviewState,
  Export,
  GetBankTrasnactions,
  MenuState,
} from '@app/core/Store';
import {
  BankDashboardComponent,
  ConfirmationBoxComponent,
  MatchJournalsComponent,
  ViewReceiptComponent,
} from '@app/modules';
import { TransactionHeaderComponent } from '@app/modules/transactions';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService, Spinner } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-bank-overview',
  templateUrl: './bank-overview.component.html',
  styleUrls: ['./bank-overview.component.scss'],
})
export class BankOverviewComponent implements OnInit {
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  isExpanded = true;
  customId: any;
  showPaginator = true;
  totalSelectedRecords = 0;
  listParameters: MainListParameters = new MainListParameters();
  bankTransactions: BankTransactionModel[];
  isRowHighlighted = false;
  noDataFound = false;
  moduleEnum = Modules;
  isHeaderChange = false;
  selectedRowIndex = -1;
  moreActionCount = 4;
  ids: Array<Guid>;
  notify: NotificationService;

  actions: any = [];

  receiptList: any[] = [];
  paymentList: any[] = [];
  tempReceiptList: any[] = [];
  tempPaymentList: any[] = [];
  isAllSelected: any;
  isCheckBoxDisabled: boolean = true;

  @Select(BankOverviewState.totalRecord)
  totalRecord$: Observable<number>;

  @ViewChild(TransactionHeaderComponent, { static: true })
  headerDetails;

  @ViewChild(BankDashboardComponent, { static: true })
  dashboard;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  triggerPaginationChange: Subject<any> = new Subject<any>();

  displayedColumns: string[] = [
    'srNo',
    'date',
    'refNo',
    'contact',
    'reference',
    'receipts',
    'payments',
    'Balance',
    'transactionID',
    'status',
    'explain',
    'action',
  ];
  datepipe: DatePipe;

  @ViewChild(MatSort) sort: MatSort;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  actionTypeEnum = ActionType;
  isBankTranferChangePermission: boolean = true;
  isJournalChangePermission: boolean = true;
  isImportChangePermission: boolean = true;
  isRecieptChangePermission: boolean = true;
  isPaymentChangePermission: boolean = true;
  isDonationChangePermission: boolean = true;
  constructor(
    private injector: Injector,
    private store: Store,
    private highlightRow: HighlightRow,
    public commonService: CommonService,
    private renderer: Renderer2
  ) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.receiptList = this.commonService.receiptList;
    this.paymentList = this.commonService.paymentList;
  }

  ngOnInit(): void {
    this.actions = [
      {
        name: 'Edit',
        actionType: ActionType.Update,
        icon: 'edit',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
    ];
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isBankTranferChangePermission = this.commonService.checkPermission(
        Modules.Bank,
        Modules.BankTransfer
      );
      this.isJournalChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Journals
      );
      this.isRecieptChangePermission = this.commonService.checkPermission(
        Modules.Income,
        Modules.Receipt
      );
      this.isPaymentChangePermission = this.commonService.checkPermission(
        Modules.Expenditure,
        Modules.Payment
      );
      this.isDonationChangePermission = this.commonService.checkPermission(
        Modules.Donation,
        Modules.Donations
      );
    }, 3000);
  }

  getDataFromBank(data: any): void {
    this.isHeaderChange = true;
    this.isExpanded = data.isExpanded;
    this.listParameters.pageNumber = 1;
    this.listParameters.moduleId = data.id;
    this.headerDetails.headerData.moduleId = data.id;

    this.getList();
  }

  getList(): void {
    if (
      this.listParameters.moduleId !== undefined &&
      this.listParameters.moduleId !== null &&
      this.listParameters.moduleId !== (Guid.EMPTY as unknown as Guid)
    ) {
      this.store
        .dispatch(new GetBankTrasnactions(this.getParamter()))
        .pipe()
        .subscribe((res) => {
          this.bankTransactions = res.bankOverview.getBankTransactions;
          this.noDataFound = this.bankTransactions.length > 0 ? false : true;

          const param = {
            pageSize: this.listParameters.pageSize,
            totalRecord: res.bankOverview.totalRecord,
            isHeaderChange: this.isHeaderChange,
          };
          this.triggerPaginationChange.next(param);
        });
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.isHeaderChange = false;
    this.getList();
  }

  getParamter(format?: number, isPrint?: boolean): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      format: format,
      isPrint: isPrint,
    };
    return queryParams;
  }

  export(format: number, isPrint?: boolean): void {
    let totalRecordOfRecords = 0;

    this.totalRecord$.subscribe((x) => {
      totalRecordOfRecords = x;
    });

    this.spinner.show();
    if (totalRecordOfRecords > 0) {
      this.store
        .dispatch(
          new Export(
            this.getParamter(format, isPrint),
            this.moduleEnum.BankDashboard
          )
        )
        .subscribe();
    } else {
      this.spinner.hide();
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noDataToExport
      );
    }
  }

  checkIsCustom(event: any): void {
    this.customId = event.id;
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.isHeaderChange = false;

      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.isHeaderChange = false;

    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  scrollIntoView(element) {
    if (element.accountValue !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  receiptPaymentChange(event: any, data: any): void {
    if (event.moduleId !== Modules.MatchJournal) {
      this.commonService.onEditRouting(
        false,
        event.moduleId,
        data.id,
        true,
        undefined,
        !data.isBankFeed,
        this.customId
      );
    } else {
      const params = {
        transactionId: btoa(data.id),
      };
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = params;
      this.dialog
        .open(MatchJournalsComponent, dialogConfig)
        .afterClosed()
        .subscribe((result) => {
          this.getList();
          this.cancelSelectionClick();
        });
    }
  }

  getDataFromHeader(data: any): void {
    this.getFilterData(data);
  }

  getFilterData(data: any): void {
    if (data.search === '') {
      this.spinner.show();
    }

    if (this.listParameters.filter > -1) {
      this.isCheckBoxDisabled = false;
    } else {
      this.isCheckBoxDisabled = true;
    }

    this.isHeaderChange = true;
    this.listParameters = data;
    !this.listParameters.format
      ? this.getList()
      : this.export(this.listParameters.format, this.listParameters.isPrint);

    this.cancelSelectionClick();
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  onButtonClick(element: any, actionType: ActionType): void {
    let id = element.id;

    this.ids = [];
    this.ids.push(id);

    this.commonService
      .isDataInLockedPeriod(this.ids, element.moduleId)
      .subscribe((isValidPeriod) => {
        if (
          isValidPeriod ||
          actionType === ActionType.Email ||
          actionType === ActionType.Export ||
          actionType === ActionType.View
        ) {
          switch (actionType) {
            case ActionType.Update:
              this.commonService.toggleMenu = false;
              const test = this.commonService.onEditRouting(
                true,
                element.moduleId,
                id
              );
              break;

            case ActionType.Delete:
              this.onDeleteClick(id, element.moduleId);
              break;
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  onDeleteClick(id: any, moduleId: number): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.ids = [];
            this.isHeaderChange = false;
            const params = {
              customId: this.listParameters.moduleId,
            };
            this.isQuickAdd(params);
          }
        });
    }
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0) {
      if (moduleId === Modules.FundList) {
        this.commonService.onEditRouting(true, Modules.ReportDetailsList, id);
      } else if (
        moduleId === Modules.Customers ||
        moduleId === Modules.Donors ||
        moduleId === Modules.Suppliers
      ) {
        this.commonService.onEditRouting(true, moduleId, id);
      } else if (
        moduleId === Modules.Invoices ||
        moduleId === Modules.Bills ||
        moduleId === Modules.CreditNote ||
        moduleId === Modules.DebitNote ||
        moduleId === Modules.FixedAssets ||
        moduleId === Modules.Quotation ||
        moduleId === Modules.Journals ||
        moduleId === Modules.Receipt ||
        moduleId === Modules.Payment ||
        moduleId === Modules.Donations
      ) {
        const data = {
          moduleId,
          id,
        };

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {});
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }

  isQuickAdd(event): void {
    if (event) {
      this.dashboard.getAllBankAccount(event.customId);
    }
  }

  selectAll(event: any): void {
    this.bankTransactions.forEach((x) => {
      x.isSelected = event.checked;
    });

    this.showFooter();
  }

  showFooter(): void {
    this.selectedIds();
    this.totalSelectedRecords = this.ids.length;
  }

  selectedIds(): void {
    this.ids = [];

    this.bankTransactions.forEach((element) => {
      if (element.isSelected) {
        this.ids.push(element.id);
      }
    });
  }
  deleteClick(): void {
    this.selectedIds();
    this.commonService
      .isDataInLockedPeriod(
        this.ids,
        this.listParameters.filter as unknown as Modules
      )
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                type: ConfirmationType.Delete,
                moduleId: this.listParameters.filter as unknown as Modules,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: NotificationHeader.deleteConfirmation,
                detailText: NotificationDetails.deleteAllDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.getList();
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    this.isAllSelected = this.bankTransactions.every(
      (item: any) => item.isSelected === true
    );
    this.showFooter();
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.bankTransactions?.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
    this.ids = [];
  }
}
