import { Injectable, Injector } from '@angular/core';
import { DataType, Modules } from '@app/core/Enum';
import {
  AccountDetailViewModel,
  Currency,
  DefaultCurrency,
  FileUploadRequestModel,
  FinancialData,
  FontFamilies,
  GenerateSaasURLModel,
  GlobalStandardPermission,
  GroupListModel,
  HeaderModel,
  ProductDetailsModel,
  SideListModel,
  UserStandardPermission,
  VATReportModel,
  VatRateScheme,
} from '@app/core/Models';
import { MainList } from '@app/core/Models/common/main-list';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { ViewDetails } from '@app/core/Models/common/viewModel';
import {
  AccountService,
  ActivityService,
  BankTransferService,
  BillService,
  CommonService,
  CreditNoteService,
  CustomAccountService,
  DebitNoteService,
  DonationInKindService,
  DonationService,
  DonorService,
  FixedAssetsService,
  FundService,
  FundTransferService,
  InvoiceService,
  JournalsService,
  QuotationService,
  RecurringDonationService,
  RecurringModuleService,
  SponsorService,
} from '@app/core/Services';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CheckCustomerSupplierDuplicate,
  CheckDonorDuplicate,
  CheckEntryNumber,
  Copy,
  CustomEmail,
  DeleteBankImportTransactionHistory,
  EditReceipt,
  Export,
  ExportMTD,
  ExportReceipt,
  FileDownload,
  FileUpload,
  GetAccountTransactionList,
  GetAccountsBasedOnGroupId,
  GetAccountsBasedOnGroupIdAndTypeId,
  GetAdvancePaymentList,
  GetAllocationData,
  GetBankAccountTransactionData,
  GetCashAccountList,
  GetChartOfAccountsBasedOnGroupId,
  GetChartOfAccountsBasedOnGroupIdAndTypeId,
  GetCreditorDebtorReport,
  GetCreditorDebtorTransaction,
  GetCurrencyList,
  GetCustomBankAccountList,
  GetCustomBankAndCashAccount,
  GetDefaultCurrency,
  GetDetailHeaderList,
  GetDetailList,
  GetFileAsByte,
  GetFinancialPeriod,
  GetFontFamilies,
  GetGroupAccountList,
  GetGroupAccountsBasedOnGroupId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetGroupCustomBankAndCashAccount,
  GetGroupList,
  GetGroupNonStandardAccountList,
  GetHeaderList,
  GetMainList,
  GetModuleDetailView,
  GetNominalLedgerReport,
  GetNominalLedgerTransaction,
  GetNonStandardAccountList,
  GetPermissions,
  GetProductData,
  GetProductUnit,
  GetReportDetailHeaderList,
  GetReportDetailList,
  GetReportList,
  GetSaasURL,
  GetSideList,
  GetStandardAccountList,
  GetTitleList,
  GetTransactionLogData,
  GetUserBusinessPermission,
  GetVatObligations,
  GetVatRateList,
  GetVatReportDetailList,
  GetVatReportList,
  GiftAidDonationDetailListExport,
  LockFinancialPeriod,
  ReportDetailView,
  ReportExport,
  SaveAllocation,
  SaveBusinessPermission,
  SaveUserPermission,
  SendEmail,
  UpdateThemeColor,
  GetIsVatRegistered,
} from './common.action';

export class CommonStateInfo {
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isCommonAdded?: boolean;
  isLastPage?: boolean;
  fileUploadRequestModel: Array<FileUploadRequestModel>;
  mainList?: MainList;
  isLoading?: boolean;
  currency: Array<Currency>;
  defaultCurrency: Array<DefaultCurrency>;
  totalRecord?: number;
  isCopied: boolean;
  accountList: Array<SideListModel>;
  chartOfAccountList: Array<SideListModel>;
  vatRate: Array<VatRateScheme>;
  productunit: Array<SideListModel>;
  productDetails?: ProductDetailsModel;
  emailData?: any;
  isExport: boolean;
  headerList?: Array<HeaderModel>;
  actions?: any;
  moduleViewDetails?: ViewDetails;
  detailView?: AccountDetailViewModel;
  periodicDate: Array<FinancialData>;
  financialData: any;
  vatReportList?: VATReportModel;
  vatReportDetailList?: any;
  accountGroupList: Array<GroupListModel>;
  customBankAndCashAccount: Array<SideListModel>;
  customBankAccount: Array<SideListModel>;
  cashAccount: Array<SideListModel>;
  expandedCollapseDetailList?: any;
  vatObligations?: any;
  primaryColor: string;
  isMailSent: boolean;
  isValidEntryNo?: boolean;
  isValidCustomerSupplier?: boolean;
  customBankAndCashAccountGroupList?: Array<GroupListModel>;
  generateSaasURLModel?: GenerateSaasURLModel;
  transactionLogData?: any;
  isBankImportTransactionHistoryDeleted: boolean;
  fontFamilies: Array<FontFamilies>;
  advancePayment?: any;
  isEdit: boolean;
  allocationData?: any;
  isAllocationAdded?: boolean;
  title: Array<any>;
  userPermission?: Array<UserStandardPermission>;
  isUserPermissionUpdated?: boolean;
  fileByteUrl?: string;
  isVatRegistered: boolean;
  standardPermission?: Array<GlobalStandardPermission>;
  isBusinessPermissionUpdated?: boolean;
}

@State<CommonStateInfo>({
  name: 'common',
  defaults: {
    exported: false,
    title: [],
    sideListModel: [],
    currency: [],
    defaultCurrency: [],
    isCommonAdded: false,
    isCopied: false,
    fileUploadRequestModel: [],
    accountList: [],
    vatRate: [],
    productunit: [],
    chartOfAccountList: [],
    isExport: false,
    periodicDate: [],
    financialData: [],
    accountGroupList: [],
    customBankAndCashAccount: [],
    customBankAccount: [],
    primaryColor: '',
    cashAccount: [],
    isMailSent: false,
    isValidEntryNo: false,
    isValidCustomerSupplier: false,
    isBankImportTransactionHistoryDeleted: false,
    fontFamilies: [],
    isEdit: false,
    isVatRegistered: false,
  },
})
@Injectable()
export class CommonState {
  commonService: CommonService;
  accountService: AccountService;
  sponsorService: SponsorService;
  activityService: ActivityService;
  journalsService: JournalsService;
  quotationService: QuotationService;
  invoiceService: InvoiceService;
  billService: BillService;
  creditNoteService: CreditNoteService;
  debitNoteService: DebitNoteService;
  donationService: DonationService;
  recurringService: RecurringModuleService;
  customAccountService: CustomAccountService;
  fixedAssetsService: FixedAssetsService;
  fundService: FundService;
  fundTransferService: FundTransferService;
  ngxSpinnerService: NgxSpinnerService;
  donorService: DonorService;
  recurringDonationService: RecurringDonationService;
  donationInKindService: DonationInKindService;
  bankTransferService: BankTransferService;

  constructor(private injector: Injector) {
    this.commonService = injector.get<CommonService>(CommonService);
    this.accountService = injector.get<AccountService>(AccountService);
    this.sponsorService = injector.get<SponsorService>(SponsorService);
    this.activityService = injector.get<ActivityService>(ActivityService);
    this.journalsService = injector.get<JournalsService>(JournalsService);
    this.quotationService = injector.get<QuotationService>(QuotationService);
    this.invoiceService = injector.get<InvoiceService>(InvoiceService);
    this.billService = injector.get<BillService>(BillService);
    this.creditNoteService = injector.get<CreditNoteService>(CreditNoteService);
    this.debitNoteService = injector.get<DebitNoteService>(DebitNoteService);
    this.quotationService = injector.get<QuotationService>(QuotationService);
    this.recurringService = injector.get<RecurringModuleService>(
      RecurringModuleService
    );
    this.customAccountService =
      injector.get<CustomAccountService>(CustomAccountService);
    this.fixedAssetsService =
      injector.get<FixedAssetsService>(FixedAssetsService);
    this.fundService = injector.get<FundService>(FundService);
    this.fundTransferService =
      injector.get<FundTransferService>(FundTransferService);
    this.ngxSpinnerService = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.donationService = injector.get<DonationService>(DonationService);
    this.donorService = injector.get<DonorService>(DonorService);
    this.recurringDonationService = injector.get<RecurringDonationService>(
      RecurringDonationService
    );
    this.donationInKindService = injector.get<DonationInKindService>(
      DonationInKindService
    );

    this.bankTransferService =
      injector.get<BankTransferService>(BankTransferService);
  }

  @Selector()
  static getSideList(state: CommonStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static getCustomBankAccount(state: CommonStateInfo) {
    return state.customBankAccount;
  }

  @Selector()
  static getCashAccount(state: CommonStateInfo) {
    return state.cashAccount;
  }

  @Selector()
  static isLoading(state: CommonStateInfo): boolean {
    return state.isLoading ?? false;
  }

  @Selector()
  static totalRecord(state: CommonStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Selector()
  static getPermissions(state: CommonStateInfo) {
    return state.standardPermission;
  }

  @Selector()
  static getUserBusinessPermission(state: CommonStateInfo) {
    return state.userPermission;
  }

  @Selector()
  static getTitle(state: CommonStateInfo) {
    return state.title;
  }

  @Selector()
  static accountList(state: CommonStateInfo): Array<SideListModel> {
    return state.accountList;
  }

  @Selector()
  static accountGroupList(state: CommonStateInfo): Array<GroupListModel> {
    return state.accountGroupList;
  }

  @Selector()
  static getCustomBankAndCashAccount(
    state: CommonStateInfo
  ): Array<SideListModel> {
    return state.customBankAndCashAccount;
  }

  @Selector()
  static getGroupAccountsBasedOnGroupId(
    state: CommonStateInfo
  ): Array<GroupListModel> {
    return state.accountGroupList;
  }

  @Selector()
  static getGroupNonStandardAccountList(
    state: CommonStateInfo
  ): Array<GroupListModel> {
    return state.accountGroupList;
  }

  @Selector()
  static columns(state: CommonStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.mainList) {
      return [];
    }

    let columns;
    columns = state.mainList.resultSet.columns.filter(
      (i) => i.name !== 'ModuleId'
    );

    return columns;
  }

  @Selector()
  static expandedCollapseColumns(state: CommonStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.expandedCollapseDetailList) {
      return [];
    }
    return state.expandedCollapseDetailList.resultSet.columns;
  }

  @Selector()
  static vatColumns(state: CommonStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.vatReportDetailList) {
      return [];
    }
    return state.vatReportDetailList.resultSet.columns;
  }

  @Selector()
  static columnNames(state: CommonStateInfo): Array<string> {
    if (!state.mainList) {
      return [];
    }

    let columnnames;
    columnnames = state.mainList.resultSet.columns.filter(
      (i) => i.name !== 'ModuleId'
    );

    return columnnames.map((x) => x.name);
  }

  @Selector()
  static vatColumnNames(state: CommonStateInfo): Array<string> {
    if (!state.vatReportDetailList) {
      return [];
    }

    return state.vatReportDetailList.resultSet.columns.map((x) => x.name);
  }

  @Selector()
  static expandedCollapseColumnNames(state: CommonStateInfo): Array<string> {
    if (!state.expandedCollapseDetailList) {
      return [];
    }

    return state.expandedCollapseDetailList.resultSet.columns.map(
      (x) => x.name
    );
  }

  @Selector()
  static headerList(state: CommonStateInfo): Array<HeaderModel> {
    if (!state.headerList) {
      return [];
    }

    return state.headerList;
  }

  @Selector()
  static actions(state: CommonStateInfo): any {
    if (!state.mainList) {
      return [];
    }

    return state.actions;
  }

  @Selector()
  static isLastPage(state: CommonStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static reportActions(state: CommonStateInfo): any {
    if (!state.mainList) {
      return [];
    }

    return state.actions;
  }

  @Selector()
  static getCurrency(state: CommonStateInfo) {
    return state.currency;
  }

  @Selector()
  static getVatRate(state: CommonStateInfo) {
    return state.vatRate;
  }

  @Selector()
  static getProductUnit(state: CommonStateInfo) {
    return state.productunit;
  }

  @Selector()
  static defaultCurrency(state: CommonStateInfo) {
    return state.defaultCurrency;
  }

  @Selector()
  static periodicDate(state: CommonStateInfo) {
    return state.periodicDate;
  }

  //region transaction

  @Action(CheckEntryNumber)
  checkEntryNumber(
    { patchState }: StateContext<CommonStateInfo>,
    action: CheckEntryNumber
  ) {
    return this.commonService
      .checkEntryNumber(action.entryNumber, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            isValidEntryNo: res,
          });
        })
      );
  }

  @Action(CheckCustomerSupplierDuplicate)
  checkCustomerSupplierDuplicate(
    { patchState }: StateContext<CommonStateInfo>,
    action: CheckCustomerSupplierDuplicate
  ) {
    return this.commonService
      .checkCustomerSupplierDuplicate(
        action.name,
        action.email,
        action.moduleId
      )
      .pipe(
        tap((res) => {
          patchState({
            isValidCustomerSupplier: res,
          });
        })
      );
  }

  @Action(GetReportDetailList, { cancelUncompleted: true })
  getReportDetailList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetReportDetailList
  ) {
    return this.commonService.getReportDetailList(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            mainList: result,
            totalRecord: result.paginationModel.totalItemCount,
            actions: result.actions,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetReportDetailHeaderList, { cancelUncompleted: true })
  getReportDetailHeaderList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetReportDetailHeaderList
  ) {
    return this.commonService.getReportDetailHeaderList().pipe(
      tap({
        next: (result) => {
          patchState({
            headerList: result,
          });
        },
      })
    );
  }

  @Action(GetDetailHeaderList, { cancelUncompleted: true })
  getDetailHeaderList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetDetailHeaderList
  ) {
    return this.commonService.getDetailHeaderList(action.mtdId).pipe(
      tap({
        next: (result) => {
          patchState({
            headerList: result,
          });
        },
      })
    );
  }

  @Action(ExportMTD, { cancelUncompleted: true })
  exportMTD({ patchState }: StateContext<CommonStateInfo>, action: ExportMTD) {
    let isExport = false;
    return this.commonService
      .exportMTD(action.queryParams, action.moduleId)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  @Action(GetDetailList, { cancelUncompleted: true })
  getDetailList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetDetailList
  ) {
    return this.commonService
      .getDetailList(action.queryParams, action.dataParam)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetVatObligations, { cancelUncompleted: true })
  getVatObligations(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetVatObligations
  ) {
    return this.commonService
      .getVatObligations(action.queryParams, action.detailListId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              vatObligations: result,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetVatRateList)
  getVatRateList({ getState, setState }: StateContext<CommonStateInfo>) {
    return this.commonService.getVatRateList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          vatRate: res,
        });
      })
    );
  }

  @Action(GetChartOfAccountsBasedOnGroupId)
  getChartOfAccountsBasedOnGroupId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetChartOfAccountsBasedOnGroupId
  ) {
    return this.commonService
      .getChartOfAccountsBasedOnGroupId(action.groupIds)
      .pipe(
        tap((res) => {
          patchState({
            chartOfAccountList: res,
          });
        })
      );
  }

  @Action(GetChartOfAccountsBasedOnGroupIdAndTypeId)
  getChartOfAccountsBasedOnGroupIdAndTypeId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetChartOfAccountsBasedOnGroupIdAndTypeId
  ) {
    return this.commonService
      .getChartOfAccountsBasedOnGroupIdAndTypeId(action.param)
      .pipe(
        tap((res) => {
          patchState({
            chartOfAccountList: res,
          });
        })
      );
  }

  //#endregion

  @Action(GetMainList, { cancelUncompleted: true })
  getMainList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetMainList
  ) {
    return this.commonService
      .getMainList(action.queryParams, action.moduleId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetNominalLedgerReport, { cancelUncompleted: true })
  getNominalLedgerReport(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetNominalLedgerReport
  ) {
    return this.commonService.getNominalLedgerReport(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            mainList: result,
            totalRecord: result.paginationModel.totalItemCount,
            actions: result.actions,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetAccountTransactionList, { cancelUncompleted: true })
  getAccountTransactionList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAccountTransactionList
  ) {
    return this.commonService
      .getAccountTransactionList(action.queryParams, action.id, action.moduleId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetTransactionLogData, { cancelUncompleted: true })
  getTransactionLogData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetTransactionLogData
  ) {
    return this.commonService.getTransactionLogData(action.id).pipe(
      tap({
        next: (result) => {
          patchState({
            transactionLogData: result,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetBankAccountTransactionData, { cancelUncompleted: true })
  getBankAccountTransactionData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetTransactionLogData
  ) {
    return this.commonService.getBankAccountTransactionData(action.id).pipe(
      tap({
        next: (result) => {
          patchState({
            transactionLogData: result,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetReportList, { cancelUncompleted: true })
  getReportList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetReportList
  ) {
    return this.commonService
      .getReportList(action.queryParams, action.moduleId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetVatReportDetailList, { cancelUncompleted: true })
  getVatReportDetailList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetVatReportDetailList
  ) {
    return this.commonService
      .getVatReportDetailList(action.vatId, action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              vatReportDetailList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetNominalLedgerTransaction, { cancelUncompleted: true })
  getNominalLedgerTransaction(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetNominalLedgerTransaction
  ) {
    return this.commonService
      .getNominalLedgerTransaction(action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              expandedCollapseDetailList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetVatReportList, { cancelUncompleted: true })
  getVatReportList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetVatReportList
  ) {
    return this.commonService.getVatReportList(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            vatReportList: result,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetHeaderList, { cancelUncompleted: true })
  getHeaderList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetHeaderList
  ) {
    return this.commonService.getHeaderList(action.moduleId).pipe(
      tap({
        next: (result) => {
          patchState({
            headerList: result,
          });
        },
      })
    );
  }

  @Action(Export, { cancelUncompleted: true })
  export({ patchState }: StateContext<CommonStateInfo>, action: Export) {
    let isExport = false;
    return this.commonService.export(action.queryParams, action.moduleId).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }

  @Action(ReportExport, { cancelUncompleted: true })
  reportExport(
    { patchState }: StateContext<CommonStateInfo>,
    action: ReportExport
  ) {
    let isExport = false;
    return this.commonService.reportExport(action.queryParams, action.id).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }

  @Action(GiftAidDonationDetailListExport, { cancelUncompleted: true })
  giftAidDonationDetailListExport(
    { patchState }: StateContext<CommonStateInfo>,
    action: GiftAidDonationDetailListExport
  ) {
    let isExport = false;
    return this.commonService
      .giftAidDonationDetailListExport(action.queryParams, action.id)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  @Action(ReportDetailView, { cancelUncompleted: true })
  reportDetailView(
    { patchState }: StateContext<CommonStateInfo>,
    action: ReportDetailView
  ) {
    return this.commonService.reportDetailView(action.id, action.moduleId).pipe(
      tap((res) => {
        patchState({
          detailView: res,
        });
      })
    );
  }

  @Action(ExportReceipt, { cancelUncompleted: true })
  exportReceipt(
    { patchState }: StateContext<CommonStateInfo>,
    action: ExportReceipt
  ) {
    let isExport = false;
    return this.commonService
      .exportReceipt(action.ids, action.moduleId, action.isPrint)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  @Action(EditReceipt, { cancelUncompleted: true })
  editReceipt(
    { patchState }: StateContext<CommonStateInfo>,
    action: EditReceipt
  ) {
    let isEdit = false;
    return this.commonService.editReceipt(action.ids, action.moduleId).pipe(
      tap((res) => {
        patchState({
          isEdit: res,
        });
      })
    );
  }

  @Action(GetSideList, { cancelUncompleted: true })
  getSideList(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetSideList
  ) {
    return this.commonService
      .getSideList(action.queryParams, action.moduleId)
      .pipe(
        tap((res) => {
          const state = getState();
          let common: SideListModel[] | null = [];
          const headers = JSON.parse(res.headers.get('Pagination')!);
          const isLastPage = headers.IsLastPage;
          if (
            action.queryParams.pageNumber &&
            action.queryParams.pageNumber > 1
          ) {
            common = state.sideListModel;
            common = common.concat(
              action.moduleId === Modules.Users ? res.body!.data : res.body!
            );
          } else {
            common =
              action.moduleId === Modules.Users ? res.body!.data : res.body!;
          }

          setState({
            ...state,
            sideListModel: common!,
            isLastPage: isLastPage,
          });
        })
      );
  }

  @Action(FileUpload)
  fileUpload(
    { patchState }: StateContext<CommonStateInfo>,
    action: FileUpload
  ) {
    return this.commonService.fileUpload(action.fileUploadRequestModel).pipe(
      tap((res) => {
        patchState({
          fileUploadRequestModel: res,
        });
      })
    );
  }

  @Action(GetCurrencyList)
  getCurrencyList({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getCurrencyList().pipe(
      tap((res) => {
        patchState({
          currency: res,
        });
      })
    );
  }

  @Action(GetProductData)
  getProductData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetProductData
  ) {
    return this.commonService.getProductData(action.productId).pipe(
      tap((res) => {
        patchState({
          productDetails: res,
        });
      })
    );
  }

  @Action(Copy)
  copy({ patchState }: StateContext<CommonStateInfo>, action: Copy) {
    let isCopied = false;

    switch (action.moduleId) {
      case Modules.Customers:
      case Modules.Suppliers:
        return this.accountService.copyAccount(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Donors:
        return this.donorService.copyDonor(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.SponsorEvent:
        return this.sponsorService.copySponsor(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Activities:
        return this.activityService.copyActivity(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Journals:
        return this.journalsService.copyJournals(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Quotation:
        return this.quotationService.copyQuotation(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Invoices:
        return this.invoiceService.copyInvoice(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Bills:
        return this.billService.copyBill(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.DebitNote:
        return this.debitNoteService.copyDebitNote(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.CreditNote:
        return this.creditNoteService.copyCreditNote(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Donations:
        return this.donationService.copyDonation(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.FundList:
        return this.fundService.copyFund(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.FundTransfers:
        return this.fundTransferService.copyFundTransfer(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.RecurringBill:
      case Modules.RecurringInvoice:
        return this.recurringService.copyRecurringData(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.AddCustomAccounts:
        return this.customAccountService.copyCustomAccount(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.FixedAssets:
        return this.fixedAssetsService.copyFixedAssets(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.RecurringDonations:
        return this.recurringDonationService
          .copyRecurringDonation(action.ids)
          .pipe(
            tap((res) => {
              isCopied = true;
              patchState({
                isCopied,
              });
            })
          );

      case Modules.DonationsInKind:
        return this.donationInKindService.copyDonationInKind(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.BankTransfer:
        return this.bankTransferService.copyBankTransfer(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );
    }
    this.ngxSpinnerService.hide();
    return isCopied;
  }

  @Action(CustomEmail)
  customEmail(
    { patchState }: StateContext<CommonStateInfo>,
    action: CustomEmail
  ) {
    return this.commonService
      .customEmail(action.moduleId, action.emailData)
      .pipe(
        tap((res) => {
          patchState({
            emailData: res,
          });
        })
      );
  }

  @Action(FileDownload)
  downloadFile(
    { patchState }: StateContext<CommonStateInfo>,
    action: FileDownload
  ) {
    return this.commonService.downloadFile(action.fileData);
  }

  @Action(GetProductUnit)
  getProductUnit({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getProductUnit().pipe(
      tap((res) => {
        patchState({
          productunit: res,
        });
      })
    );
  }
  //#endregion

  @Action(GetDefaultCurrency)
  getDefaultCurrency({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getDefaultCurrency().pipe(
      tap((res) => {
        patchState({
          defaultCurrency: res,
        });
      })
    );
  }

  @Action(GetFinancialPeriod)
  getFinancialPeriod(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetFinancialPeriod
  ) {
    return this.commonService.getFinancialPeriod().pipe(
      tap((res) => {
        patchState({
          periodicDate: res,
        });
      })
    );
  }

  @Action(LockFinancialPeriod)
  lockFinancialPeriod(
    { patchState }: StateContext<CommonStateInfo>,
    action: LockFinancialPeriod
  ) {
    return this.commonService.lockFinancialPeriod(action.financialData).pipe(
      tap((res) => {
        patchState({
          financialData: res,
        });
      })
    );
  }

  @Action(GetModuleDetailView)
  getModuleDetailView(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetModuleDetailView
  ) {
    return this.commonService.getModuleDetailView(action.param).pipe(
      tap((res) => {
        patchState({
          moduleViewDetails: res,
        });
      })
    );
  }

  @Action(GetStandardAccountList)
  getStandardAccountList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetStandardAccountList
  ) {
    return this.commonService.getStandardAccountList(action.entityId).pipe(
      tap((res) => {
        patchState({
          accountList: res,
        });
      })
    );
  }

  @Action(GetNonStandardAccountList)
  getNonStandardAccountList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetNonStandardAccountList
  ) {
    return this.commonService.getNonStandardAccountList(action.entityId).pipe(
      tap((res) => {
        patchState({
          accountList: res,
        });
      })
    );
  }

  @Action(GetGroupNonStandardAccountList)
  getGroupNonStandardAccountList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupNonStandardAccountList
  ) {
    return this.commonService
      .getGroupNonStandardAccountList(action.entityId)
      .pipe(
        tap((res) => {
          patchState({
            accountGroupList: res,
          });
        })
      );
  }

  @Action(GetCustomBankAccountList)
  getCustomBankAccountList({
    getState,
    setState,
  }: StateContext<CommonStateInfo>) {
    return this.commonService.getCustomBankAccountList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          customBankAccount: res,
        });
      })
    );
  }

  @Action(GetCashAccountList)
  getCashAccountList({ getState, setState }: StateContext<CommonStateInfo>) {
    return this.commonService.getCashAccountList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          accountList: res,
        });
      })
    );
  }

  @Action(GetGroupList)
  getGroupList({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getGroupList().pipe(
      tap((res) => {
        patchState({
          accountGroupList: res,
        });
      })
    );
  }

  @Action(GetGroupAccountsBasedOnGroupId)
  getGroupAccountsBasedOnGroupId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupAccountsBasedOnGroupId
  ) {
    return this.commonService
      .getGroupAccountsBasedOnGroupId(action.groupIds)
      .pipe(
        tap((res) => {
          patchState({
            accountGroupList: res,
          });
        })
      );
  }

  @Action(GetAccountsBasedOnGroupId)
  getAccountsBasedOnGroupId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAccountsBasedOnGroupId
  ) {
    return this.commonService.getAccountsBasedOnGroupId(action.groupIds).pipe(
      tap((res) => {
        patchState({
          accountList: res,
        });
      })
    );
  }

  @Action(GetGroupAccountsBasedOnGroupIdAndTypeId)
  getGroupAccountsBasedOnGroupIdAndTypeId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupAccountsBasedOnGroupIdAndTypeId
  ) {
    return this.commonService
      .getGroupAccountsBasedOnGroupIdAndTypeId(action.param)
      .pipe(
        tap((res) => {
          patchState({
            accountGroupList: res,
          });
        })
      );
  }

  @Action(GetAccountsBasedOnGroupIdAndTypeId)
  getAccountsBasedOnGroupIdAndTypeId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAccountsBasedOnGroupIdAndTypeId
  ) {
    return this.commonService
      .getAccountsBasedOnGroupIdAndTypeId(action.param)
      .pipe(
        tap((res) => {
          patchState({
            accountList: res,
          });
        })
      );
  }

  @Action(GetGroupAccountList)
  getGroupAccountList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupAccountList
  ) {
    return this.commonService.getGroupAccountList(action.entityId).pipe(
      tap((res) => {
        patchState({
          accountGroupList: res,
        });
      })
    );
  }

  @Action(GetCustomBankAndCashAccount)
  getCustomBankAndCashAccount(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetCustomBankAndCashAccount
  ) {
    return this.commonService.getCustomBankAndCashAccount().pipe(
      tap((res) => {
        patchState({
          customBankAndCashAccount: res,
        });
      })
    );
  }

  @Action(GetGroupCustomBankAndCashAccount)
  getGroupCustomBankAndCashAccount(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupCustomBankAndCashAccount
  ) {
    return this.commonService.getGroupCustomBankAndCashAccount().pipe(
      tap((res) => {
        patchState({
          customBankAndCashAccountGroupList: res,
        });
      })
    );
  }

  @Action(GetCreditorDebtorReport, { cancelUncompleted: true })
  getCreditorDebtorReport(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetCreditorDebtorReport
  ) {
    return this.commonService
      .getCreditorDebtorReport(action.queryParams, action.moduleId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetCreditorDebtorTransaction, { cancelUncompleted: true })
  getCreditorDebtorTransaction(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetCreditorDebtorTransaction
  ) {
    return this.commonService
      .getCreditorDebtorTransaction(
        action.queryParams,
        action.moduleId,
        action.id
      )
      .pipe(
        tap({
          next: (result) => {
            patchState({
              expandedCollapseDetailList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(UpdateThemeColor)
  updateThemeColor(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: UpdateThemeColor
  ) {
    return this.commonService.updateThemeColor(action.colorCode).pipe(
      tap((res) => {
        patchState({
          primaryColor: res,
        });
      })
    );
  }

  @Action(SendEmail, { cancelUncompleted: true })
  sendEmail({ patchState }: StateContext<CommonStateInfo>, action: SendEmail) {
    return this.commonService.sendEmail(action.id, action.moduleId).pipe(
      tap((res) => {
        patchState({
          isMailSent: res,
        });
      })
    );
  }

  @Action(DeleteBankImportTransactionHistory)
  deleteBankImportTransactionHistory(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: DeleteBankImportTransactionHistory
  ) {
    return this.commonService
      .deleteBankImportTransactionHistory(action.ids)
      .pipe(
        tap((res) => {
          patchState({
            isBankImportTransactionHistoryDeleted: res,
          });
        })
      );
  }

  @Action(GetFontFamilies)
  getFontFamilies({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getFontFamilies().pipe(
      tap((res) => {
        patchState({
          fontFamilies: res,
        });
      })
    );
  }

  @Action(GetSaasURL)
  getSaasURL(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetSaasURL
  ) {
    return this.commonService.getSaasURL(action.genrateurl).pipe(
      tap((res) => {
        patchState({
          generateSaasURLModel: res,
        });
      })
    );
  }

  @Action(CheckDonorDuplicate)
  checkDonorDuplicate(
    { patchState }: StateContext<CommonStateInfo>,
    action: CheckDonorDuplicate
  ) {
    return this.commonService
      .checkDonorDuplicate(action.name, action.lastName, action.email)
      .pipe(
        tap((res) => {
          patchState({
            isValidCustomerSupplier: res,
          });
        })
      );
  }

  @Action(GetAdvancePaymentList)
  getAdvancePaymentList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAdvancePaymentList
  ) {
    return this.commonService.getAdvancePaymentList(action.entityId).pipe(
      tap((res) => {
        patchState({
          advancePayment: res,
        });
      })
    );
  }

  @Action(GetAllocationData)
  getAllocationData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAllocationData
  ) {
    return this.commonService
      .getAllocationData(action.id, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            allocationData: res,
          });
        })
      );
  }

  @Action(SaveAllocation)
  saveCreditNoteAllocation(
    { patchState }: StateContext<CommonStateInfo>,
    action: SaveAllocation
  ) {
    return this.commonService
      .saveAllocation(action.allocationData, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            isAllocationAdded: true,
          });
        })
      );
  }

  @Action(GetTitleList)
  getTitleList(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetTitleList
  ) {
    return this.commonService.getTitleList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          title: res,
        });
      })
    );
  }

  @Action(GetUserBusinessPermission, { cancelUncompleted: true })
  getUserBusinessPermission(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetUserBusinessPermission
  ) {
    return this.commonService.getUserBusinessPermission(action.userId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          userPermission: res,
        });
      })
    );
  }

  @Action(GetPermissions, { cancelUncompleted: true })
  getPermissions(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetPermissions
  ) {
    return this.commonService.getPermissions(action.searchText).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          standardPermission: res,
        });
      })
    );
  }

  @Action(SaveUserPermission)
  saveUserPermission(
    { patchState }: StateContext<CommonStateInfo>,
    action: SaveUserPermission
  ) {
    return this.commonService
      .saveUserPermission(action.standardPermission, action.userId)
      .pipe(
        tap((res) => {
          patchState({
            isUserPermissionUpdated: res,
          });
        })
      );
  }

  @Action(SaveBusinessPermission)
  saveBusinessPermission(
    { patchState }: StateContext<CommonStateInfo>,
    action: SaveBusinessPermission
  ) {
    return this.commonService
      .saveBusinessPermission(action.standardPermission, action.userId)
      .pipe(
        tap((res) => {
          patchState({
            isBusinessPermissionUpdated: res,
          });
        })
      );
  }

  @Action(GetFileAsByte)
  getFileAsByte(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetFileAsByte
  ) {
    return this.commonService.getFileAsByte(action.fileUrl).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          fileByteUrl: res.data,
        });
      })
    );
  }

  @Action(GetIsVatRegistered)
  getIsVatRegistered(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetIsVatRegistered
  ) {
    return this.commonService.getIsVatRegistered().pipe(
      tap((res) => {
        patchState({
          isVatRegistered: res,
        });
      })
    );
  }
}
