import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-sponsor-event',
  templateUrl: './add-sponsor-event.component.html',
  styleUrls: ['./add-sponsor-event.component.scss'],
})
export class AddSponsorComponent implements OnInit {
  sponsorForm: FormGroup;

  @Input() triggerEditData: Observable<any>;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  periodicDate: any;

  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      this.editSponsorForm(data);
    });

    this.sponsorForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.sponsorForm.touched;
    });
  }

  editSponsorForm(data): void {
    this.sponsorForm.patchValue({
      name: data.name,
      eventDate: data.eventDate,
      isActive: data.isActive,
    });
  }

  setForm(): void {
    this.sponsorForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      eventDate: new FormControl(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      isActive: new FormControl(false),
    });
  }
}
