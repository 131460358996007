import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewVatDeatilsService {
  constructor(private http: HttpClient) {}

  getVatReturn(periodKey: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}MTD/vatReturn/${periodKey}`
    );
  }

  getVatRetunDetails(periodKey: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}MTD/vatReturnDetail/${periodKey}`
    );
  }

  getAuthorized(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiVersionUrl}MTD/authorized`);
  }
}
