import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverviewModel } from '@app/core/Models';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type SummaryChart = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  noData: ApexNoData;
};

@Component({
  selector: 'app-overview-summary-chart',
  templateUrl: './overview-summary-chart.component.html',
  styleUrls: ['./overview-summary-chart.component.scss'],
})
export class OverviewSummaryChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  public summaryChart: Partial<SummaryChart>;
  reportList: OverviewModel;

  @Input()
  triggerSummaryIncomeData: Observable<any>;

  @Input()
  triggerSummaryExpensesData: Observable<any>;

  @Input()
  triggerOverviewData: Observable<any>;

  ngOnInit(): void {
    this.triggerOverviewData?.subscribe((data) => {
      this.reportList = data.barChart;
      this.getOverviewSummary();
    });

    this.triggerSummaryIncomeData?.subscribe((data) => {
      this.reportList = data;
      this.getOverviewSummary();
    });

    this.triggerSummaryExpensesData?.subscribe((data) => {
      this.reportList = data;
      this.getOverviewSummary();
    });
  }

  getOverviewSummary(): void {
    const tempCategories: any = [];

    for (const barchartData of this.reportList['categories']) {
      tempCategories.push(barchartData);
    }

    const arraydata: any = [];
    for (const barchartData of this.reportList[`series`]) {
      arraydata.push(barchartData.data);
    }

    this.summaryChart = {
      series: [
        {
          name: 'Net Profit',
          data: arraydata,
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 10,
          columnWidth: '10  %',
          barHeight: '40',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return '£ ' + val;
        },
        offsetX: 25,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: tempCategories,
      },
      tooltip: {
        marker: {
          show: false,
        },
        enabled: true,
        fillSeriesColor: false,
      },
      fill: {
        opacity: 1,
        type: 'gradient',
        colors: ['#F7CFDD'],
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#EF89AD'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
    };
  }
}
