import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AccountEntity,
  FixedAssetsOverview,
  Modules,
  OverviewYear,
} from '@app/core/Enum';
import { ReportFilterTypes } from '@app/core/Enum/report-filter-type';
import { FilterDateRange, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';

import {
  GetAllGroupByFixAssestAgeingSummary,
  GetFixAssestProductServiceListOverview,
} from '@app/core/Store';
import { Store } from '@ngxs/store';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexLegend,
  ApexGrid,
  ApexFill,
  ApexStroke,
} from 'ng-apexcharts';
import { tap } from 'rxjs/operators';

export type FixAssetsOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  grid: ApexGrid;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'app-fixed-assets-overview',
  templateUrl: './fixed-assets-overview.component.html',
  styleUrls: ['./fixed-assets-overview.component.scss'],
})
export class FixedAssetsOverviewComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public FixAssetsOptions: Partial<FixAssetsOptions>;

  moduleId = Modules.FixedAssetOverview;
  productServiecData: any;
  filter1 = ReportFilterTypes.Last30Days;

  valueByAssetTypeList: SideListModel[];
  valueByAssetType: number;
  productService: number;

  @Output()
  readonly triggerHeaderFilter = new EventEmitter<any>();

  @Output()
  readonly triggerHeaderFilterForValueByAssetType = new EventEmitter<any>();

  selectYear = OverviewYear;
  selectedYearValue = OverviewYear['Last 30 days'];

  showCalender = false;
  dateRange: any;

  rangeFormGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    public datepipe: DatePipe,
    public commonService: CommonService
  ) {
    this.FixAssetsOptions = {
      series: [
        {
          name: 'series1',
          data: [500, 100, 305, 25],
        },
      ],
      chart: {
        height: 70,
        width: '80%',
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      fill: {
        colors: ['#00E396'],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      yaxis: {
        show: false,
      },
      stroke: {
        show: true,
        colors: ['#00E396'],
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        tooltip: {
          enabled: false,
        },
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    };
  }

  ngOnInit(): void {
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );
    this.setForm();
  }

  setForm(): void {
    this.getFixAssetTypeList();

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  onSelectionChange(val: any): void {
    this.showCalender = +val === 7;
    this.dateRange = this.commonService.generateDateSeries(val);

    if (
      this.showCalender &&
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.dateRange = new FilterDateRange();
      this.dateRange.startDate = new Date(
        this.rangeFormGroup.controls.start.value
      );
      this.dateRange.endDate = new Date(this.rangeFormGroup.controls.end.value);
      this.getData();
      this.getFixAssestProductServiceListOverview();
    } else if (!this.showCalender) {
      this.getData();
      this.getFixAssestProductServiceListOverview();
    }
  }

  getData(): void {
    const data = this.getFilter();

    setTimeout(() => {
      this.triggerHeaderFilter.emit(data);

      data.moduleId = this.valueByAssetType;
      this.triggerHeaderFilterForValueByAssetType.emit(data);
    }, 500);
  }

  getFixAssetTypeList(): void {
    this.store
      .dispatch(
        new GetAllGroupByFixAssestAgeingSummary(AccountEntity.FixedAssets)
      )
      .pipe(
        tap((res) => {
          this.valueByAssetTypeList = res.fixedAssets.accountType;
          this.valueByAssetType = this.valueByAssetTypeList[0].id;
          this.productService = this.valueByAssetTypeList[0].id;
          this.getData();
          this.getFixAssestProductServiceListOverview();
        })
      )
      .subscribe();
  }

  getFilter(): any {
    const filterData = {
      moduleId: 0,
      dateFilter: {
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(
                  this.rangeFormGroup.controls.start.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),

        endDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(this.rangeFormGroup.controls.end.value, 'yyyy-MM-dd')
                ?.toString(),
      },
    };

    return filterData;
  }

  onValueByAssetType(): void {
    const data = this.getFilter();
    data.moduleId = this.valueByAssetType;
    this.triggerHeaderFilterForValueByAssetType.emit(data);
  }

  getFixAssestProductServiceListOverview(): void {
    const data = this.getFilter();
    data.moduleId = this.productService;

    this.store
      .dispatch(new GetFixAssestProductServiceListOverview(data))
      .pipe(
        tap((res) => {
          this.productServiecData =
            res.fixedAssets.fixAssestProductServiceListOverview;
        })
      )
      .subscribe();
  }
}
