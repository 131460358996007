import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import { NotificationTextMessage, Modules, ModuleName } from '@app/core/Enum';
import { SideListModel, GlobalComponent } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  GetSponsoreList,
  GetActivityList,
  GetFundNameList,
  DonationState,
  AccountState,
  CreateFund,
  CreateDonor,
  CreateSponsor,
  GetDataBySponsorId,
  GetDataByFundId,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-add-donation-coding',
  templateUrl: './add-donation-coding.component.html',
  styleUrls: ['./add-donation-coding.component.scss'],
})
export class AddDonationCodingComponent implements OnInit, AfterViewInit {
  donationCodingForm: FormGroup;
  sponsoreList: SideListModel[];
  activityList: SideListModel[];
  fundNameList: any;
  donorList: any;
  disabledFundEditButton = true;
  disabledSponserEditButton = true;
  periodicDate: any;
  getListId: Guid;
  notificationMessage = NotificationTextMessage;
  isSponserEventChangePermission: boolean = true;
  isFundChangePermission: boolean = true;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();
    this.donationCodingForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.donationCodingForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSponserEventChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.SponsorEvent
      );
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
    }, 3000);
  }

  editDonation(data): void {
    this.disabledFundEditButton = false;
    this.disabledSponserEditButton = false;
    this.donationCodingForm.patchValue({
      donationDate: data.donationDate,
      sponsoredEvent: data.sponsorEventId === null ? '' : data.sponsorEventId,
      activity: data.activityId === null ? '' : data.activityId,
      fundName: data.fundId,
    });
  }

  setForm(): void {
    this.donationCodingForm = new FormGroup({
      donationDate: new FormControl(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      fundName: new FormControl('', [Validators.required]),
      sponsoredEvent: new FormControl(''),
      activity: new FormControl(''),
    });

    this.loadDropdownValues();
  }

  loadDropdownValues(): void {
    const getSponsoreList = this.store.dispatch(new GetSponsoreList());
    const getActivityList = this.store.dispatch(new GetActivityList());
    const getFundNameList = this.store.dispatch(new GetFundNameList());
    forkJoin([getSponsoreList, getActivityList, getFundNameList]).subscribe(
      (res) => {
        this.sponsoreList = this.store.selectSnapshot(
          DonationState.getSponsore
        );
        this.activityList = this.store.selectSnapshot(
          DonationState.getActivity
        );
        this.fundNameList = this.store.selectSnapshot(
          AccountState.getFundNameList
        );

        if (this.sponsoreList.length > 0) {
          this.donationCodingForm.controls.sponsoredEvent.setValue('');
        }
        this.donationCodingForm.controls.activity.setValue('');
        if (this.fundNameList.length > 0) {
          this.disabledFundEditButton = false;
          this.donationCodingForm.controls.fundName.setValue(
            this.fundNameList[0].id
          );
        }
      }
    );
  }

  sponserChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledSponserEditButton = false;
    } else {
      this.disabledSponserEditButton = true;
    }
  }

  gotoAddSponsor(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.SponsorEvent,
          headerText: `Add ${ModuleName.SponsorEvent}`,
          saveActionName: CreateSponsor,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledSponserEditButton = false;
          this.getSponsor(id);
        }
      });
  }

  getSponsor(id) {
    this.store.dispatch(new GetSponsoreList()).subscribe((res) => {
      this.sponsoreList = res.donation.sponsore;
      if (id !== null && id !== undefined) {
        this.donationCodingForm.controls.sponsoredEvent.setValue(id);
      }
    });
  }

  gotoEditSponsor(): void {
    if (this.sponsoreList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.SponsorEvent,
            headerText: `Edit ${ModuleName.SponsorEvent}`,
            id: this.donationCodingForm.controls.sponsoredEvent.value,
            saveActionName: CreateSponsor,
            getActionName: GetDataBySponsorId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getSponsor(id);
          }
        });
    }
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledFundEditButton = false;
    } else {
      this.disabledFundEditButton = true;
    }
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledFundEditButton = false;
          this.getFund(id);
        }
      });
  }
  getFund(id): void {
    this.store.dispatch(new GetFundNameList()).subscribe((res) => {
      this.fundNameList = res.account.fundNameList;
      if (id !== null && id !== undefined) {
        this.donationCodingForm.controls.fundName.setValue(id);
      }
    });
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.donationCodingForm.controls.fundName.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }
}
