<div class="mat-dialog-wrapper">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">{{ quickData.headerText }}</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div>
      <form [formGroup]="form">
        <div>
          <div
            *ngIf="quickData.moduleId !== moduleEnum.AddCustomAccounts"
            class="form-field"
          >
            <p *ngIf="quickData.moduleId !== moduleEnum.Donors">
              Name <span class="text-danger-300">*</span>
            </p>
            <p *ngIf="quickData.moduleId === moduleEnum.Donors">
              First Name <span class="text-danger-300">*</span>
            </p>
            <mat-form-field>
              <input
                matInput
                formControlName="name"
                autocomplete="off"
                appAutoFocusDirective
                required
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="
              quickData.moduleId !== moduleEnum.FundList &&
              quickData.moduleId !== moduleEnum.SponsorEvent &&
              quickData.moduleId !== moduleEnum.Activities &&
              quickData.moduleId !== moduleEnum.AddCustomAccounts
            "
            class="form-field"
          >
            <p>Email <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                emailValidator
                formControlName="email"
                autocomplete="off"
                required
                type="text"
              />
            </mat-form-field>
          </div>

          <div
            *ngIf="quickData.moduleId === moduleEnum.FundList"
            class="form-field"
          >
            <p>Fund Type <span class="text-danger-300">*</span></p>
            <ng-select
              #fundType
              (focus)="fundType.open()"
              (valueChange)="fundType.close()"
              [disableOptionCentering]="true"
              formControlName="fundType"
              panelClass="mat-select-position"
              appendTo="body"
            >
              <ng-option *ngFor="let ft of fundTypeList" [value]="ft.id">
                <div title="{{ ft.name }}">{{ ft.name }}</div>
              </ng-option>
            </ng-select>
          </div>

          <div
            *ngIf="quickData.moduleId === moduleEnum.SponsorEvent"
            class="form-field datepicker"
          >
            <p>Event Date <span class="text-danger-300">*</span></p>
            <mat-form-field appearance="standard">
              <input
                matInput
                [matDatepicker]="eventDatePicker"
                autocomplete="off"
                formControlName="eventDate"
                (change)="commonService.validateDate($event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="eventDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #eventDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-error
              *ngIf="
                commonService.checkDateValidation(form.get('eventDate'))
                  .invalidFinancialYear
              "
            >
              {{ notificationMessage.validPeriodDateMessage }}
            </mat-error>

            <mat-error
              *ngIf="
                commonService.checkDateValidation(form.get('eventDate'))
                  .lockedFinancialYear
              "
            >
              {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
            </mat-error>
          </div>

          <div
            *ngIf="quickData.moduleId === moduleEnum.Activities"
            class="form-field"
          >
            <p>Code <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                formControlName="code"
                autocomplete="off"
                required
                type="text"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="quickData.moduleId === moduleEnum.AddCustomAccounts"
            class="form-field"
          >
            <p>Account Group <span class="text-danger-300">*</span></p>
            <ng-select
              #chartOfAccountGroup
              (focus)="chartOfAccountGroup.open()"
              (valueChange)="chartOfAccountGroup.close()"
              [disableOptionCentering]="true"
              formControlName="chartOfAccountGroup"
              (change)="onChartOfAccountGroupChange($event)"
              appAutoFocusDirective
              [readonly]="(moduleId$ | async) === moduleEnum.BankDashboard"
            >
              <ng-option
                *ngFor="let cl of chartOfAccountGroupList"
                [value]="cl.id"
                title="{{ cl.name }}"
              >
                <div title="{{ cl.name }}">{{ cl.name }}</div>
              </ng-option>
            </ng-select>
          </div>
          <div
            class="form-field"
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              !isCashInHandGroup
            "
          >
            <p *ngIf="!isFixedAssestsGroup">Account Type</p>
            <p *ngIf="isFixedAssestsGroup">Account Name</p>
            <ng-select
              *ngIf="!isFixedAssestsGroup"
              #chartOfAccountType
              [disabled]="isReadOnly"
              (focus)="chartOfAccountType.open()"
              (valueChange)="chartOfAccountType.close()"
              [disableOptionCentering]="true"
              formControlName="chartOfAccountType"
              appAutoFocusDirective
            >
              <ng-option
                *ngFor="let clt of chartOfAccountTypeList"
                [value]="clt.id"
              >
                <div title="{{ clt.name }}">{{ clt.name }}</div>
              </ng-option>
            </ng-select>
            <mat-form-field *ngIf="isFixedAssestsGroup">
              <input
                matInput
                formControlName="chartOfAccountType"
                autocomplete="off"
                appAutoFocusDirective
              />
            </mat-form-field>
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              !isFixedAssestsGroup
            "
            class="form-field"
          >
            <p>Account Name <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                formControlName="accountName"
                maxlength="{{ maxLength.maxLength200 }}"
                autocomplete="off"
                appAutoFocusDirective
              />
            </mat-form-field>
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              !isFixedAssestsGroup
            "
            class="form-field"
          >
            <p>Account Code <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                maxlength="{{ maxLength.maxLength50 }}"
                formControlName="accountCode"
                autocomplete="off"
                (blur)="validateAccountCode()"
              />
            </mat-form-field>
            <mat-error
              *ngIf="!isAccountCodeNotExists && suggestedCodes.length > 0"
            >
              Suggested Codes: {{ suggestedCodes.join(", ") }}</mat-error
            >
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              isCashInHandGroup
            "
            class="form-field"
          >
            <p>Bank Account Type <span class="text-danger-300">*</span></p>
            <ng-select
              #bankAccountType
              (focus)="bankAccountType.open()"
              (valueChange)="bankAccountType.close()"
              [disableOptionCentering]="true"
              formControlName="bankAccountType"
              panelClass="mat-select-position"
              [required]="isCashInHandGroup"
            >
              <ng-option *ngFor="let cl of accountTypeList" [value]="cl.id">
                <div title="{{ cl.name }}">
                  {{ cl.name }}
                </div>
              </ng-option>
            </ng-select>
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              isCashInHandGroup
            "
            class="form-field"
          >
            <p>Account No <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                type="number"
                maxlength="{{ maxLength.maxLength50 }}"
                formControlName="accountNo"
                autocomplete="off"
                [required]="isCashInHandGroup"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              isCashInHandGroup
            "
            class="form-field"
          >
            <div class="form-field">
              <p>Sort/Branch Code</p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="sortBranchCode"
                  maxlength="{{ maxLength.maxLength50 }}"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              isCashInHandGroup
            "
            class="form-field"
          >
            <p>Currency</p>
            <ng-select
              #currency
              (focus)="currency.open()"
              (valueChange)="currency.close()"
              [disableOptionCentering]="true"
              formControlName="currency"
              panelClass="mat-select-position"
            >
              <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
                <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
                  {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
                </div>
              </ng-option>
            </ng-select>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="m-0">
    <app-buttons
      [getModuleId]="quickData.moduleId"
      (triggerOnSaveClick)="onSave()"
      (triggerOnCancelClick)="onCancel()"
      [saveButtonShow]="true"
    >
    </app-buttons>
  </mat-dialog-actions>
</div>
