<form [formGroup]="note27Form">
  <div class="notes-content">
    <table class="notes-table" aria-describedby="User Task Table">
      <ng-container *ngIf="calculativeData?.length === 0">
        <tr>
          <td class="text-align-center mt-1 fw-bold">
            <span>No records found</span>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="calculativeData?.length > 0">
        <tr>
          <th scope="col"></th>
          <th *ngFor="let item of calculativeData">
            {{ item?.name }}
          </th>
        </tr>

        <tr>
          <th class="fw-bold text-align-left" scope="col">
            Carrying (fair) value at beginning of period
          </th>
          <td *ngFor="let item of calculativeData">
            {{ item?.openingBalance }}
          </td>
        </tr>

        <tr>
          <th class="text-align-left" scope="col">
            <span class="fw-bold text-align-left">Add:</span> additions to
            investments during period*
          </th>
          <td *ngFor="let item of calculativeData">
            {{ item?.addition | numberPipe }}
          </td>
        </tr>

        <tr>
          <th class="text-align-left" scope="col">
            <span class="fw-bold text-align-left"> Less:</span> disposals at
            carrying value
          </th>
          <td *ngFor="let item of calculativeData">
            {{ item?.expense | numberPipe }}
          </td>
        </tr>

        <tr>
          <th class="text-align-left" scope="col">
            <span class="fw-bold text-align-left"> Less: impairments</span>
          </th>
          <td *ngFor="let item of calculativeData">
            {{ item?.impairments | numberPipe }}
          </td>
        </tr>

        <tr>
          <th class="text-align-left" scope="col">
            <span class="fw-bold text-align-left"
              >Add: Reversal of impairments</span
            >
          </th>
          <td *ngFor="let item of calculativeData">
            {{ item?.reversalImpairments | numberPipe }}
          </td>
        </tr>

        <tr>
          <th class="text-align-left" scope="col">
            <span class="fw-bold text-align-left"> Add/(deduct):</span>
            transfer in/(out) in the period
          </th>
          <td *ngFor="let item of calculativeData">
            {{ item?.transfer | numberPipe }}
          </td>
        </tr>

        <tr>
          <th class="text-align-left" scope="col">
            <span class="fw-bold text-align-left"> Add/(deduct):</span> net
            gain/(loss) on revaluation
          </th>
          <td *ngFor="let item of calculativeData">
            {{ item?.netGainLoss | numberPipe }}
          </td>
        </tr>

        <tr>
          <th class="fw-bold text-align-left" scope="col">
            Carrying (fair) value at end of year
          </th>
          <td *ngFor="let item of calculativeData">
            {{ item?.closingBalance | numberPipe }}
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
  <div class="notes-wrapper">
    <div class="notes-section">
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox1"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
  </div>
</form>
