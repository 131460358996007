export enum viewVatReturnBox {
  Box1 = 'Box 1',
  Box2 = 'Box 2',
  Box3 = 'Box 3',
  Box4 = 'Box 4',
  Box5 = 'Box 5',
  Box6 = 'Box 6',
  Box7 = 'Box 7',
  Box8 = 'Box 8',
  Box9 = 'Box 9',
}

export enum Stepper {
  FirstStep = 1,
  SecondStep = 2,
  ThirdStep = 3,
}

export enum VATReturnStatus {
  SubmitToCapium = 1,
  SubmitToHMRC = 2,
}
