import { Injectable } from '@angular/core';
import { FundTransferModel, SideListModel } from '@app/core/Models';
import { FundTransferService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreFundTransfer,
  CreateFundTransfer,
  DeleteFundTransfer,
  GetDataByFundTransferId,
  GetTransferToFund,
} from './fund-transfer.action';

export class FundTransferStateInfo {
  fundTransferData?: FundTransferModel;
  fundTransfer: Array<FundTransferModel>;
  fundTransferId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isFundTransferIdAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  fundToTrasfer?: SideListModel[];
}

@State<FundTransferStateInfo>({
  name: 'fundTransfer',
  defaults: {
    fundTransfer: [],
    fundTransferId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isFundTransferIdAdded: false,
  },
})
@Injectable()
export class FundTransferState {
  constructor(private fundTransferService: FundTransferService) {}

  @Selector()
  static isLastPage(state: FundTransferStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getFundTransferIdId(state: FundTransferStateInfo) {
    return state.fundTransferId;
  }

  @Selector()
  static getTransferToFund(state: FundTransferStateInfo) {
    return state.fundToTrasfer;
  }

  @Action(CreateFundTransfer)
  createFundTransfer(
    { patchState }: StateContext<FundTransferStateInfo>,
    action: CreateFundTransfer
  ) {
    return this.fundTransferService
      .createFundTransfer(action.fundTransfer)
      .pipe(
        tap((res) => {
          patchState({
            fundTransferId: res,
            isFundTransferIdAdded: true,
          });
        })
      );
  }

  @Action(GetDataByFundTransferId)
  getDataByFundTransferId(
    { patchState }: StateContext<FundTransferStateInfo>,
    action: GetDataByFundTransferId
  ) {
    return this.fundTransferService
      .getDataByFundTransferId(action.fundTransferId)
      .pipe(
        tap((res) => {
          patchState({
            fundTransferData: res,
          });
        })
      );
  }

  @Action(DeleteFundTransfer)
  deleteFundTransfer(
    { getState, setState }: StateContext<FundTransferStateInfo>,
    action: DeleteFundTransfer
  ) {
    return this.fundTransferService
      .deleteFundTransfer(action.fundTransferIds)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredQuatation = state.fundTransfer.filter(
            (item) =>
              !action.fundTransferIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.fundTransferIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          setState({
            ...state.fundTransfer,
            fundTransfer: filteredQuatation,
            sideListModel: filteredForSideList,
          });
        })
      );
  }

  @Action(ArchiveAndRestoreFundTransfer)
  archiveAndRestoreFundTransfer(
    { getState }: StateContext<FundTransferStateInfo>,
    action: ArchiveAndRestoreFundTransfer
  ) {
    return this.fundTransferService.archiveAndRestoreFundTransfer(
      action.fundTransferIds,
      action.isArchive
    );
  }

  @Action(GetTransferToFund)
  getTransferToFund(
    { getState, setState }: StateContext<FundTransferStateInfo>,
    action: any
  ) {
    return this.fundTransferService.getTransferToFund().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          fundToTrasfer: res,
        });
      })
    );
  }
}
