import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note21',
  templateUrl: './note21.component.html',
  styleUrls: ['./note21.component.scss'],
})
export class Note21Component implements OnInit {
  note21Form: FormGroup;
  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}
  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      if (
        data.noteId === Notes.Note21 &&
        data.response.userInputData !== null &&
        data.response.userInputData !== undefined &&
        data.response.userInputData.data !== null &&
        data.response.userInputData.data !== undefined
      ) {
        this.editNotes21(data.response.userInputData.data);
      }
    });
  }

  editNotes21(res): void {
    this.note21Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : ' ',
      textbox2: res.textbox2 ? res.textbox2 : ' ',
      textbox3: res.textbox3 ? res.textbox3 : ' ',
      textbox4: res.textbox4 ? res.textbox4 : ' ',
    });
  }

  setForm(): void {
    this.note21Form = new FormGroup({
      textbox1: new FormControl(''),
      textbox2: new FormControl(''),
      textbox3: new FormControl(''),
      textbox4: new FormControl(''),
    });
  }
}
