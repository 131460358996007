<div id="chart" *ngIf="summaryChart">
  <apx-chart
    [series]="summaryChart?.series!"
    [chart]="summaryChart?.chart!"
    [dataLabels]="summaryChart?.dataLabels!"
    [plotOptions]="summaryChart?.plotOptions!"
    [yaxis]="summaryChart?.yaxis!"
    [legend]="summaryChart?.legend!"
    [fill]="summaryChart?.fill!"
    [stroke]="summaryChart?.stroke!"
    [tooltip]="summaryChart?.tooltip!"
    [xaxis]="summaryChart?.xaxis!"
    [noData]="summaryChart?.noData!"
  ></apx-chart>
</div>
