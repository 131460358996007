import { Guid } from 'guid-typescript';

export class BulkEditModel {
  id: Guid;
  date: string;
  reference: string;
  account: string;
  fundId: Guid;
  contactNumber: string;
  referenceNo: number;
  receipt: string;
  payment: string;
  isSelected: boolean;
  invoiceTypeId: number;
  accountValue: any;
}

export class SaveBulkEditModel {
  id?: Guid;
  invoiceTypeId?: number;
  bookAccountId?: Guid;
  fundId?: Guid;
  reference?: string;
  companyId?: string;
}
