import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DashboardOverview, FundOverview, Modules } from '@app/core/Enum';
import { DashboardModel } from '@app/core/Models';
import {
  GetDashboardCashFlowOverview,
  GetIncomeExpensesByFundTypeOverview,
} from '@app/core/Store';
import { Store } from '@ngxs/store';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';

import { Observable } from 'rxjs';

export type CashFlowChartOptionsv1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  noData: ApexNoData;
  labels: any;
};

@Component({
  selector: 'app-dashboard-cashflow-chart-v1',
  templateUrl: './dashboard-cashflow-chart-v1.component.html',
  styleUrls: ['./dashboard-cashflow-chart-v1.component.scss'],
})
export class DashboardCashflowChartComponentv1 implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public cashFlowChartOptionsv1: Partial<CashFlowChartOptionsv1>;
  reportList: any;
  isNoRecord = true;
  isloadData = false;

  @Input()
  getModuleId: number;

  @Input()
  triggerHeaderFilter: Observable<any>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (
        +this.getModuleId === Modules.FundOverview ||
        +this.getModuleId === Modules.Dashboard
      ) {
        let actionName;
        switch (this.getModuleId) {
          case Modules.FundOverview:
            actionName = GetIncomeExpensesByFundTypeOverview;
            break;
          case Modules.Dashboard:
            actionName = GetDashboardCashFlowOverview;
            break;
        }
        this.store.dispatch(new actionName(filterData)).subscribe((x) => {
          this.isloadData = true;
          switch (this.getModuleId) {
            case Modules.FundOverview:
              this.reportList = x.fund.incomeExpensesByFundTypeOverview;
              break;
            case Modules.Dashboard:
              this.reportList = x.dashboard.dashboardCashFlowOverview;
              break;
          }

          this.getData();
        });
      }
    });
  }

  getData(): void {
    if (+this.getModuleId === Modules.Dashboard) {
      this.isNoRecord = this.reportList.series.length === 0;

      this.cashFlowChartOptionsv1 = {
        series: this.reportList.series,
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          markers: {
            fillColors: ['#7B76D3', '#F2816F'],
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: false,
            columnWidth: '50%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return '£ ' + Number(val) / 1000 + 'K';
            },
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          enabled: true,
          fillSeriesColor: false,
          y: {
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
        },
        labels: [
          'Estimates Created - 60%  </br>  42',
          'Estimates Accepted - 30%  </br>  22',
          'Estimates Rejected - 10% </br>  12',
        ],
        xaxis: {
          categories: this.reportList.categories,
        },
        noData: {
          text: 'No Data to Display',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#6d41a1',
            fontSize: '18px',
          },
        },
        fill: {
          opacity: 1,
          colors: ['#7B76D3', '#F2816F'],
        },
      };
    } else if (
      +this.getModuleId === Modules.DonationOverview ||
      +this.getModuleId === Modules.FundOverview
    ) {
      const tempCategories: any = [];
      const tempSeries: any = [];
      let count = 0;

      for (const barchart of this.reportList[`categories`]) {
        let name: any;
        name = barchart.split(' ');
        tempCategories.push(name);
      }

      this.isNoRecord = this.reportList[`series`].length === 0;

      if (this.reportList[`series`].length > 0) {
        for (const barchart of this.reportList[`series`]) {
          tempSeries.push(barchart.data);
          if (barchart.data === 0) {
            count = count + 1;
          }
        }

        this.isNoRecord =
          count === this.reportList[`series`].length ? true : false;
      }

      this.cashFlowChartOptionsv1 = {
        series: [
          {
            name: 'Income',
            data: tempSeries[0],
          },
          {
            name: 'Expence',
            data: tempSeries[1],
          },
        ],
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          //customLegendItems: ['Balance as on 01/04/2022', 'Incoming', 'Outgoing' ,'Balance as on 31/03/2023'],
          markers: {
            fillColors: ['#7B76D3', '#F2816F'],
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: false,
            columnWidth: '50%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return '£ ' + Number(val) / 1000 + 'K';
            },
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          enabled: true,
          fillSeriesColor: false,
          y: {
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
        },
        labels: [
          'Estimates Created - 60%  </br>  42',
          'Estimates Accepted - 30%  </br>  22',
          'Estimates Rejected - 10% </br>  12',
        ],
        xaxis: {
          labels: {
            show: true,
            rotate: 0,
          },
          categories: tempCategories,
        },
        noData: {
          text: 'No Data to Display',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#6d41a1',
            fontSize: '18px',
          },
        },
        fill: {
          opacity: 1,
          colors: ['#7B76D3', '#F2816F'],
        },
      };
    }
  }
}
