import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { RetrieveSubscription } from './subscription.action';
import { SubscriptionService } from '@app/core/Services';

export class SubscriptionStateInfo {
  retrieveSubscriptionList: Array<any>;
}

@State<SubscriptionStateInfo>({
  name: 'subscription',
  defaults: {
    retrieveSubscriptionList: [],
  },
})
@Injectable()
export class SubscriptionState {
  constructor(private subscriptionService: SubscriptionService) {}

  @Action(RetrieveSubscription, { cancelUncompleted: true })
  retrieveSubscription(
    { getState, setState }: StateContext<SubscriptionStateInfo>,
    action: RetrieveSubscription
  ) {
    return this.subscriptionService.retrieveSubscription().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          retrieveSubscriptionList: res,
        });
      })
    );
  }
}
