<div class="mat-dialog-wrapper convert-inv" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">Invoice</h2>

    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="d-flex justify-space-between align-items-center mb-2">
      <div>
        <span
          >Invoice <strong>{{invoiceNumber}}</strong> generated successfully
          from quotation <strong>{{quotationNumber}}</strong>.</span
        >
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onEditInvoiceClick()"
    >
      Edit Invoice
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
