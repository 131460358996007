<form [formGroup]="note13Form">
  <div class="notes-section">
    <table class="notes-table" aria-describedby="User Task Table">
      <tr>
        <th class="fw-bold" scope="col">Analysis</th>
        <th class="fw-bold" scope="col">Unrestricted funds</th>
        <th class="fw-bold" scope="col">Restricted income funds</th>
        <th class="fw-bold" scope="col">Endowment funds</th>
        <th class="fw-bold" scope="col">
          This Year
          <p>£</p>
        </th>
        <th class="fw-bold" scope="col">
          Prior Year
          <p>£</p>
        </th>
      </tr>
      <ng-container *ngIf="calculativeData?.length === 0">
        <tr>
          <td colspan="9" class="text-align-center mt-1 fw-bold">
            <span>No records found</span>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="calculativeData?.length > 0">
        <tr *ngFor="let item of calculativeData">
          <th class="fw-bold text-align-left" scope="col">{{ item?.name }}</th>
          <td>{{ item?.thisYearUnrestrictedFund | numberPipe }}</td>
          <td>{{ item?.thisYearRestrictedFund | numberPipe }}</td>
          <td>{{ item?.thisYearEndowmentFund | numberPipe }}</td>
          <td>{{ item?.thisYearTotalFund | numberPipe }}</td>
          <td>{{ item?.lastYearTotalFund | numberPipe }}</td>
        </tr>
      </ng-container>
    </table>
  </div>
  <div class="notes-wrapper">
    <div class="notes-section">
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox1"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
  </div>
</form>
