import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MaxLength, Modules, NotificationTextMessage } from '@app/core/Enum';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
  GiftAid,
  GlobalComponent,
  MultipleFileDownloadModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  FileDownload,
  FileUpload,
  GetGiftAidDonorSpreadSheets,
  SaveGiftAid,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  ReportListWithActionsComponent,
} from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-gift-aid-declaration',
  templateUrl: './gift-aid-declaration.component.html',
  styleUrls: ['./gift-aid-declaration.component.scss'],
})
export class GiftAidDeclarationComponent implements OnInit {
  displayedColumns: string[] = [
    'fileName',
    'importedby',
    'importedon',
    'Download',
    'action',
  ];

  periodicDateList: any;
  accountingPeriod: Guid;
  moduleId = Modules.DonationGiftAid;

  multipleFileDownloadModel: MultipleFileDownloadModel;
  files: FileUploadResponseModel[] = [];
  acceptedFileTypes = ['.xlsx'];
  isImageSelected = false;
  selectedFile: File;
  url: any;
  fileName: any;
  fileList: any[] = [];
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: FileUploadResponseModel;
  fileData: GiftAid;
  startDate = new Date();
  endDate = new Date();
  id = Guid.EMPTY as unknown as Guid;

  @Output()
  readonly triggerDataFromGiftAid = new EventEmitter<any>();

  @ViewChild(ReportListWithActionsComponent, { static: true })
  reportList;

  maxLength = MaxLength;
  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent,
    public store: Store,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setPeriodDate();
    this.getGiftAidDonorSpreadSheets();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onAccountPeriodChange(element: any): void {
    this.accountingPeriod = element.value;
    this.periodicDateList.forEach((element) => {
      if (element.id === this.accountingPeriod) {
        this.startDate = element.fromDate;
        this.endDate = element.toDate;
      }
    });
    var dateList = [{ startDate: this.startDate, endDate: this.endDate }];
    this.triggerDataFromGiftAid.emit(dateList);
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    this.accountingPeriod = this.periodicDateList[0].id;
    this.triggerDataFromGiftAid.emit(this.accountingPeriod);
  }

  dowloadFile(fileUrl: any): void {
    const filedata: any[] = [];

    filedata.push(fileUrl);

    this.multipleFileDownloadModel = {
      fileURLs: filedata,
    };

    this.store
      .dispatch(new FileDownload(this.multipleFileDownloadModel))
      .subscribe();
  }

  getGiftAidDonorSpreadSheets(): void {
    this.store.dispatch(new GetGiftAidDonorSpreadSheets()).subscribe((res) => {
      this.files = res.donor.giftAidDonorSpreadSheetsList;
    });
  }

  get accept(): string {
    return this.acceptedFileTypes?.join(', ') ?? '';
  }

  onFileSelected(event: any): void {
    if (!event.target.files || !event.target.files.length) {
      return;
    }
    this.isImageSelected = true;
    this.selectedFile = event.target.files[0];
    this.saveFile(event.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.url = reader.result;
    };
  }

  saveFile(file: File): void {
    this.fileList = [];
    this.fileList.push(file);

    this.fileUploadRequest = {
      file: this.fileList,
      attachmentType: 1,
    };

    this.store
      .dispatch(new FileUpload(this.fileUploadRequest))
      .pipe(
        tap((data) => {
          this.fileUploadResponse = data.common.fileUploadRequestModel[0];
          this.fileName = this.fileUploadResponse.fileName;
          this.onSave();
        })
      )
      .subscribe();
  }

  dataSubmit() {
    this.spinner.show();
    try {
      this.fileData = {
        accountingPeriodId: this.accountingPeriod,
        fileURL:
          this.fileUploadResponse === undefined
            ? null
            : this.fileUploadResponse.fileUrl,
      };
    } catch (error) {
      this.spinner.hide();

      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(): void {
    if (this.dataSubmit()) {
      if (this.fileData) {
        this.store
          .dispatch(new SaveGiftAid(this.fileData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );

                this.ngOnInit();
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }
}
