<div id="chart">
  <apx-chart
    [series]="donationChartOptions.series!"
    [chart]="donationChartOptions.chart!"
    [labels]="donationChartOptions.labels!"
    [responsive]="donationChartOptions.responsive!"
    [fill]="donationChartOptions.fill!"
    [dataLabels]="donationChartOptions.dataLabels!"
    [legend]="donationChartOptions.legend!"
  ></apx-chart>
</div>
