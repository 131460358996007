export enum TicketModules {
  Unknown = 0,
  Bookkeeping = 1,
  Accounts_Production = 2,
  Payroll = 3,
  Corporation_Tax = 4,
  Personal_Tax = 5,
  Accountant = 6,
  PracticeManagement = 7,
  PayrollAdvanced = 8,
  CompanySecretary = 9,
  P11D = 10,
  TrustTax = 11,
  Partnership_Tax = 12,
  BookKeepingMTD = 13,
  Capisign = 18,
  TimeandFeeIndividual = 25,
  CharityIndividual = 26,
  TimeandFees = 35,
  Charity = 36,
  diyboox = 38,
  storage = 39,
  CapiumHub = 40,
  CompanySecretarial = 41,
  SMS = 42,
  CapiSign = 43,
  BankFeeds = 44,
}

export enum TicketStatus {
  Open = 2,
  Pending = 3,
  Resolved = 4,
  Closed = 5,
}
