<div>
  <div class="d-flex align-items-center justify-space-between gap-10 pt-2">
    <div>
      <p class="font-size-14 mb-5">Total Assets</p>
      <p class="font-size-18 mb-0 fw-bold">120</p>
    </div>
    <div>
      <p class="font-size-14 mb-5">Tangible</p>
      <p class="font-size-18 mb-0 fw-bold">100</p>
    </div>
    <div>
      <p class="font-size-14 mb-5">Intangible</p>
      <p class="font-size-18 mb-0 fw-bold">20</p>
    </div>
  </div>
  <div class="mt-1 mb-1 progress-bar">
    <mat-progress-bar mode="determinate" value="60"></mat-progress-bar>
  </div>
  <div class="mt-10">
    <div class="d-flex align-items-center justify-space-between gap-10 mb-1">
      <div class="font-size-14 text-gray d-flex align-items-center gap-5">
        <span class="fill-box"></span>Tangible
      </div>
      <div class="font-size-12 text-gray fw-bold">80%</div>
    </div>
    <div class="d-flex align-items-center justify-space-between gap-10">
      <div class="font-size-14 text-gray d-flex align-items-center gap-5">
        <span class="unfill-box"></span>Intangible
      </div>
      <div class="font-size-12 text-gray fw-bold">20%</div>
    </div>
  </div>
</div>
