<div class="content-body content-body-scroll">
  <div class="content-header">
    <div class="content-header-input header-text">
      <div class="d-flex align-items-center gap-20">
        <p class="text-primary font-size-20 fw-bold m-0">
          {{ dateRange.startDate | date: "dd-LLL-yyyy" }} to
          {{ dateRange.endDate | date: "dd-LLL-yyyy" }}
        </p>
      </div>
      <div>
        <div class="content-header m-0">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div>
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    [(ngModel)]="selectedYearValue"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear | useEnumValues"
                      [value]="year.key"
                      title="{{ year.value }}"
                    >
                      {{ year.value }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form *ngIf="showCalender" [formGroup]="rangeFormGroup">
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-wrapper">
    <div class="d-flex align-items-center justify-space-between gap-20">
      <mat-card>
        <mat-card-title>
          <div *ngIf="false" class="text-success font-size-14 amount-growth">
            <span class="d-flex align-items-center justify-content-end">
              <mat-icon>north_east</mat-icon>
              32.2%
            </span>
          </div>
          <div class="d-flex gap-20">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >currency_pound</mat-icon
              >
            </div>
            <div>
              <p class="title">Total Income</p>
              <p class="amount">
                £ {{ dashboardCard?.card?.totalAmount | numberPipe }}
              </p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">Receivables</div>
            <div class="action-amount">
              £ {{ dashboardCard?.card?.amount | numberPipe }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div *ngIf="false" class="text-danger-300 font-size-14 amount-growth">
            <span class="d-flex align-items-center justify-content-end">
              <mat-icon class="text-danger-300">south_east</mat-icon>
              8.4%
            </span>
          </div>
          <div class="d-flex gap-20">
            <div class="icon">
              <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
            </div>
            <div>
              <p class="title">Total Expenditure</p>
              <p class="amount">
                £ {{ dashboardCard?.card1?.totalAmount | numberPipe }}
              </p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">Payables</div>
            <div class="action-amount">
              £ {{ dashboardCard?.card1?.amount | numberPipe }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div *ngIf="false" class="text-success font-size-14 amount-growth">
            <span class="d-flex align-items-center justify-content-end">
              <mat-icon>north_east</mat-icon>
              2.2%
            </span>
          </div>
          <div class="d-flex gap-20">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >account_balance</mat-icon
              >
            </div>
            <div>
              <p class="title">Bank Balance</p>
              <p class="amount">
                £ {{ dashboardCard?.card2?.totalAmount | numberPipe }}
              </p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">
              <a
                href="javscript:void(0)"
                (click)="isBankOverviewChangePermission ? goToBankBook() : ''"
                [ngClass]="
                  !isBankOverviewChangePermission ? 'cursor-not-allowed' : ''
                "
                class="link"
                >View Bank Book</a
              >
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div *ngIf="false" class="text-success font-size-14 amount-growth">
            <span class="d-flex align-items-center justify-content-end">
              <mat-icon>north_east</mat-icon>
              6.4%
            </span>
          </div>
          <div class="d-flex gap-20">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >account_balance_wallet</mat-icon
              >
            </div>
            <div>
              <p class="title">Cash in Hand</p>
              <p class="amount">
                £ {{ dashboardCard?.card3?.totalAmount | numberPipe }}
              </p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">
              <a href="javscript:void(0)" (click)="goToCashBook()" class="link"
                >View Cash Book</a
              >
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">
          Income by Categories
        </h3>
      </div>
      <app-overview-incomes-chart-v1
        [getModuleId]="moduleId"
        [triggerHeaderFilter]="triggerHeaderFilter"
      >
      </app-overview-incomes-chart-v1>
    </div>
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">
          Expense by Categories
        </h3>
      </div>
      <app-dashboard-expenses-v1
        [triggerHeaderFilter]="triggerHeaderFilter"
        [getModuleId]="moduleId"
      >
      </app-dashboard-expenses-v1>
    </div>
  </div>
  <div class="chart-wrapper mt-2 mb-2">
    <div class="dash-row">
      <div class="dash-col">
        <div class="card">
          <div
            class="d-flex align-items-center hr-bottom justify-space-between pb-10"
          >
            <h3 class="card-title card-title-without-dropdown">
              Fund by Types
            </h3>
          </div>
          <app-dashboard-income-client-v1
            [getModuleId]="moduleId"
            [triggerHeaderFilter]="triggerHeaderFilter"
          ></app-dashboard-income-client-v1>
        </div>
      </div>
      <div class="dash-col">
        <div class="card">
          <div
            class="d-flex align-items-center hr-bottom justify-space-between pb-10"
          >
            <h3 class="card-title card-title-without-dropdown">
              Donation by Types
            </h3>
          </div>
          <app-dashboard-donation-type-v1
            [triggerHeaderFilter]="triggerHeaderFilter"
            [getModuleId]="moduleId"
          ></app-dashboard-donation-type-v1>
        </div>
      </div>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2 mb-2">
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">
          Income vs Expenses
        </h3>
      </div>
      <app-dashboard-income-expenses-chart-v1
        [triggerHeaderFilter]="triggerHeaderFilter"
        [getModuleId]="moduleId"
      >
      </app-dashboard-income-expenses-chart-v1>
    </div>
    <div class="card" *ngIf="this.moduleId === module.Dashboard">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title">Ageing Summary</h3>
        <div class="d-flex gap-20">
          <mat-form-field appearance="fill" class="w-150">
            <mat-select
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              [(ngModel)]="ageingSummaryValue"
              (selectionChange)="ageingSummaryChange()"
            >
              <mat-option value="1"> Receivables</mat-option>
              <mat-option value="2"> Payable</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <app-overview-summary-chart-v1
        [triggerAgeingSummary]="triggerAgeingSummary"
        [getModuleId]="moduleId"
      ></app-overview-summary-chart-v1>
    </div>
  </div>
  <div
    class="chart-wrapper d-flex gap-20"
    *ngIf="this.moduleId === module.Dashboard"
  >
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">Cash Flow</h3>
      </div>
      <app-dashboard-cashflow-chart-v1
        [triggerHeaderFilter]="triggerHeaderFilter"
        [getModuleId]="moduleId"
      ></app-dashboard-cashflow-chart-v1>
      <div
        class="flow-box-wrapper d-flex align-items-center justify-space-between hidden"
      >
        <div class="flow-box">
          <p class="flow-title">Balance as on 01/04/2022</p>
          <p class="flow-amount">£ 4500.00</p>
        </div>
        <div class="flow-box">
          <p class="flow-title">Incoming</p>
          <p class="flow-amount">£ 2400.00</p>
        </div>
        <div class="flow-box">
          <p class="flow-title">Outgoing</p>
          <p class="flow-amount">£ 2100.00</p>
        </div>
        <div class="flow-box">
          <p class="flow-title">Balance as on 31/03/2023</p>
          <p class="flow-amount">£ 4500.00</p>
        </div>
      </div>
    </div>
  </div>
</div>
