<div class="company-preference content-body-wrapper">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      Charity Preference
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <div class="alert-message" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div>
      <div class="company-preference-info">
        <mat-tab-group
          mat-align-tabs="start"
          disableRipple
          animationDuration="0ms"
          animationDuration="0ms "
          [(selectedIndex)]="selectedTab"
          (selectedTabChange)="onTabClick($event.index)"
        >
          <mat-tab label="Invoice">
            <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
              <app-add-company-preference
                *ngIf="selectedTab === 0"
                [moduleId]="moduleId"
                [triggerEditData]="triggerEditData"
              >
              </app-add-company-preference>
            </div>
          </mat-tab>
          <mat-tab label="Quotation">
            <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
              <app-add-company-preference
                *ngIf="selectedTab === 1"
                [moduleId]="moduleId"
                [triggerEditData]="triggerEditData"
              >
              </app-add-company-preference>
            </div>
          </mat-tab>
          <mat-tab label="Recurring Invoice">
            <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
              <app-add-company-preference
                *ngIf="selectedTab === 2"
                [moduleId]="moduleId"
                [triggerEditData]="triggerEditData"
              >
              </app-add-company-preference>
            </div>
          </mat-tab>
          <mat-tab label="Recurring Bill">
            <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
              <app-add-company-preference
                *ngIf="selectedTab === 3"
                [moduleId]="moduleId"
                [triggerEditData]="triggerEditData"
              >
              </app-add-company-preference>
            </div>
          </mat-tab>
          <mat-tab label="Account Production">
            <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
              <app-add-company-preference
                *ngIf="selectedTab === 4"
                [moduleId]="moduleId"
                [triggerEditData]="triggerEditData"
              >
              </app-add-company-preference>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleEnum.CharityPreference"
      (triggerOnSaveClick)="onSave($event.isExit)"
      (triggerOnCancelClick)="onCancel()"
    >
    </app-buttons>
  </div>
</div>
