export enum AnnualReportUploadType {
  trusteesReport = 1,
  independentExaminerReport = 2,
  signature = 3,
}

export enum AccountingMethod {
  AccuralBasis = 1,
  CashBasis = 2,
}
