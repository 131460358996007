<div class="content-body-wrapper confirmation-popup">
  <h2 mat-dialog-title>{{ confirmationList.headerText }}</h2>
  <mat-dialog-content>
    <p class="font-size-14" [innerHTML]="confirmationList.detailText"></p>

    <ng-container
      *ngIf="
        confirmationList.type === confirmationType.Delete &&
        confirmationList.moduleId === modules.Users
      "
    >
      <p class="mt-1 text-red d-flex align-items-center">
        <span *ngIf="confirmationList.moduleId === modules.Users">
          This user will be marked as inactive in 'Charity' module.
        </span>
      </p>
    </ng-container>

    <ng-container
      *ngIf="
        confirmationList.type === confirmationType.Archive &&
        confirmationList.isArchive === true &&
        confirmationList.moduleId === modules.Users
      "
    >
      <p class="mt-10 text-red d-flex align-items-center">
        <span *ngIf="confirmationList.moduleId === modules.Users">
          This user not be able to access this module until you grant permission
          again.
        </span>
      </p>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onYesClick()"
      >
        Yes
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        (click)="onNoClick()"
      >
        No
      </button>
    </div>
  </mat-dialog-actions>
</div>
