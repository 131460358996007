<div id="chart" *ngIf="flowChartOptions">
  <apx-chart
    [series]="flowChartOptions?.series!"
    [chart]="flowChartOptions?.chart!"
    [dataLabels]="flowChartOptions?.dataLabels!"
    [plotOptions]="flowChartOptions?.plotOptions!"
    [yaxis]="flowChartOptions?.yaxis!"
    [legend]="flowChartOptions?.legend!"
    [fill]="flowChartOptions?.fill!"
    [stroke]="flowChartOptions?.stroke!"
    [tooltip]="flowChartOptions?.tooltip!"
    [xaxis]="flowChartOptions?.xaxis!"
    [noData]="flowChartOptions?.noData!"
  ></apx-chart>
</div>
