<div
  class="alert alert-{{ cssClass(item) }}"
  *ngFor="let item of notifications"
>
  <div class="d-flex align-items-center">
    <div>
      <mat-icon class="check-icon text-{{ cssClass(item) }}-100">{{
        icon
      }}</mat-icon>
    </div>
    <div>
      <p class="text-{{ cssClass(item) }} mb-0">{{ item.message }}</p>
    </div>
  </div>
</div>
