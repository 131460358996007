<div class="content-body content-body-scroll">
  <div class="chart-header">
    <div
      class="header-text d-flex align-items-center justify-space-between mb-1"
    >
      <div class="d-flex align-items-center gap-20">
        <p class="text-primary font-size-20 fw-bold m-0">
          {{ dateRange.startDate | date: "dd-LLL-yyyy" }} to
          {{ dateRange.endDate | date: "dd-LLL-yyyy" }}
        </p>
      </div>
      <div>
        <div class="content-header m-0">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div>
                <mat-form-field appearance="fill" class="w-150 m-0">
                  <mat-select
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    [(ngModel)]="selectedYearValue"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear | useEnumValues"
                      [value]="year.key"
                      title="{{ year.value }}"
                    >
                      {{ year.value }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form *ngIf="showCalender" [formGroup]="rangeFormGroup">
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title">Expense Breakdown</h3>
        <ng-select
          #expenseBreakdown
          (focus)="expenseBreakdown.open()"
          (valueChange)="expenseBreakdown.close()"
          [disableOptionCentering]="true"
          [(ngModel)]="expenseBreakdownId"
          (change)="onExpenseBreakdownChangeForExpensesBreakDown()"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let ft of expenseBreakdownList" [value]="ft.id">
            <div class="clamp" title="{{ ft.name }}">{{ ft.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <app-dashboard-expenses-v1
        [triggerHeaderFilterForExpenseBreakdown]="
          triggerHeaderFilterForExpenseBreakdown
        "
        [getModuleId]="moduleId"
      >
      </app-dashboard-expenses-v1>
    </div>
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">
          Payable Ageing Summary
        </h3>
      </div>
      <app-overview-summary-chart-v1
        [triggerHeaderFilter]="triggerHeaderFilter"
        [getModuleId]="moduleId"
      ></app-overview-summary-chart-v1>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">Expenditure Flow</h3>
      </div>
      <app-overview-flow-chart-v1
        [triggerHeaderFilter]="triggerHeaderFilter"
        [getModuleId]="moduleId"
      >
      </app-overview-flow-chart-v1>
    </div>
  </div>
</div>
