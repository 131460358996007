<div class="mat-dialog-wrapper">
  <h2 mat-dialog-title>{{ headerText }}</h2>
  <mat-dialog-content>
    <p>{{ bodyText }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onYesClick()"
    >
      {{ yeslbl }}
    </button>
    <button
      class="action-button secondary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onNoClick()"
    >
      {{ nolbl }}
    </button>
  </mat-dialog-actions>
</div>
