import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStates,
  ApexGrid,
  ApexTitleSubtitle,
  ApexResponsive,
} from 'ng-apexcharts';
import { Select, Store } from '@ngxs/store';
import {
  GetDonationFundTypesOverview,
  GetDonationDonorsAndDonationOverview,
  GetEndowmentCapitalOverview,
  GetFundBreakdownOverview,
  AccountState,
} from '@app/core/Store';
import { Observable } from 'rxjs';
import { DonationOverview, FundOverview, Modules } from '@app/core/Enum';

interface ApexXAxis {
  type?: 'category' | 'datetime' | 'numeric';
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
}

const colors = [
  '#7B76D3',
  '#F4C64D',
  '#F2816F',
  '#BA5397',
  '#7A5295',
  '#B9CE64',
];

export interface FundBreakdown {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  subtitle: ApexTitleSubtitle;
  colors: string[];
  states: ApexStates;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  tooltip: any;
  responsive: ApexResponsive[];
}

declare global {
  interface Window {
    Apex: any;
  }
}

window.Apex = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    shared: false,
  },
  legend: {
    show: false,
  },
};
@Component({
  selector: 'app-fund-breakdown',
  templateUrl: './fund-breakdown.component.html',
  styleUrls: ['./fund-breakdown.component.scss'],
})
export class FundBreakdownComponent {
  @ViewChild('chart') chart: ChartComponent;
  public FundBreakdownOptions: Partial<FundBreakdown>;
  public chartQuarterOptions: Partial<FundBreakdown>;

  isNoRecord = true;
  isloadData = false;

  @Input()
  triggerHeaderFilter: Observable<any>;

  @Input()
  triggerHeaderFilterForFundBreakdown: Observable<any>;

  @Output()
  readonly triggerWidgetClick = new EventEmitter<any>();

  @Output()
  readonly triggerModuleId = new EventEmitter<any>();

  @Input() getModuleId: number;

  @Select(AccountState.getFundTypeList)
  fundTypeList$: Observable<any>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (
        this.getModuleId === Modules.DonationOverview ||
        this.getModuleId === Modules.FundOverview
      ) {
        this.getData(filterData);
      }
    });

    this.triggerHeaderFilterForFundBreakdown?.subscribe((filterData) => {
      this.getDataByFundType(filterData);
    });
  }

  getSelectedFundName(fundId: number): any {
    let fundName;

    this.fundTypeList$.subscribe((x) => {
      fundName = x.find((x) => +x.id === +fundId)?.name;
    });

    return fundName;
  }

  getActionName(): any {
    let actionName;

    switch (this.getModuleId) {
      case Modules.FundOverview:
        actionName = GetFundBreakdownOverview;
        break;
      case Modules.DonationOverview:
        actionName = GetDonationFundTypesOverview;
        break;
    }

    return actionName;
  }

  onWidgetClick(fundBreakdownData): any {
    let data = {
      id: fundBreakdownData[0].id,
    };

    this.triggerWidgetClick.emit(data);
  }

  getData(filterData: any): any {
    const actionName = this.getActionName();

    this.store.dispatch(new actionName(filterData)).subscribe((data) => {
      let overviewData;

      switch (this.getModuleId) {
        case Modules.FundOverview:
          this.isloadData = true;
          overviewData = data.fund.fundBreakdownOverview;
          break;
        case Modules.DonationOverview:
          this.isloadData = true;
          overviewData = data.donation.donationFundTypesOverview;
          break;
      }
      this.isNoRecord = overviewData.series.length === 0;
      let fundBreakdownData = this.makeDataForFund(overviewData);

      this.FundBreakdownOptions = {
        series: [
          {
            name: 'year',
            data: fundBreakdownData,
          },
        ],
        chart: {
          id: 'barYear',
          height: 300,
          width: '100%',
          type: 'bar',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const fundBreakdownData: any = [];
              fundBreakdownData.push(
                config.w.config.series[0].data[config.dataPointIndex]
              );
              this.onWidgetClick(fundBreakdownData);
            },
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
            barHeight: '50%',
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },

        colors: colors,

        states: {
          normal: {
            filter: {
              type: 'desaturate',
            },
          },
          active: {
            allowMultipleDataPointsSelection: true,
            filter: {
              type: 'darken',
              value: 1,
            },
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          y: {
            title: {
              formatter: function (val, opts) {
                return '';
              },
            },
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
        },
        title: {
          text: 'Fund Types',
          offsetX: 15,
          style: {
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'Source Sans Pro, sans-serif',
            color: '#404A5F',
          },
        },
        subtitle: {
          text: '(Click on bar to see details)',
          offsetX: 460,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Source Sans Pro, sans-serif',
          },
        },
        responsive: [
          {
            breakpoint: 1532,
            options: {
              subtitle: {
                offsetX: 350,
              },
            },
          },
        ],
        yaxis: {
          labels: {
            show: true,
          },
        },
      };

      if (fundBreakdownData.length > 0) {
        filterData.moduleId = fundBreakdownData[0].id;
      }
      this.triggerModuleId.emit(filterData.moduleId);
    });
  }

  makeDataForFund(overviewData: any): any {
    const categories: any = [];
    let count = 0;

    overviewData.categories.forEach((element, i) => {
      categories.push({
        x: element.name,
        y: overviewData.series[i].data,
        color: colors[i],
        id: element.id,
      });
      if (overviewData.series[i].data === 0) {
        count = count + 1;
      }
    });
    this.isNoRecord = count === overviewData.series.length ? true : false;

    return categories;
  }

  getDataByFundType(filter: any): void {
    const filterData = {
      moduleId: filter.moduleId,
      dateFilter: {
        filter: filter.dateFilter.filter,
        startDate: filter.dateFilter.startDate,
        endDate: filter.dateFilter.endDate,
      },
    };

    let actionName;

    switch (this.getModuleId) {
      case Modules.FundOverview:
        actionName = GetEndowmentCapitalOverview;
        break;
      case Modules.DonationOverview:
        actionName = GetDonationDonorsAndDonationOverview;
        break;
    }

    this.store.dispatch(new actionName(filterData)).subscribe((data) => {
      let overviewData;

      switch (this.getModuleId) {
        case Modules.FundOverview:
          overviewData = data.fund.fundEndowmentCapitalOverview;
          break;
        case Modules.DonationOverview:
          overviewData = data.donation.donationDonorsAndDonationOverview;
          break;
      }

      const categories: any = [];
      const setColour: any[] = [];

      let colourSelected;

      const overViewTypeData: any = this.FundBreakdownOptions?.series;
      if (overViewTypeData.length > 0) {
        const getColour = overViewTypeData[0].data.filter(
          (x) => x.id === filterData.moduleId
        );
        if (getColour.length > 0) {
          colourSelected = getColour[0].color;
        }
      }

      if (overviewData.categories.length > 0) {
        overviewData.categories.forEach((element, i) => {
          categories.push({
            x: element.name,
            y: overviewData.series[i].data,
            color: colors[filterData.moduleId],
            id: element.id,
          });
          setColour.push(colourSelected);
        });
      }
      this.chartQuarterOptions = {
        series: [
          {
            name: 'quarter',
            data: categories,
          },
        ],
        colors: setColour,
        chart: {
          id: 'barQuarter',
          height: 300,
          width: '100%',
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        title: {
          text: this.getSelectedFundName(filter.moduleId),
          offsetX: 10,
          style: {
            fontSize: '14px',
            fontWeight: '480',
            fontFamily: 'Source Sans Pro, sans-serif',
            color: '#404A5F',
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          x: {
            formatter: function (val, opts) {
              return opts.w.globals.seriesNames[opts.seriesIndex];
            },
          },
          y: {
            title: {
              formatter: function (val, opts) {
                return '';
              },
            },
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
        },
      };
    });
  }
}
