import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { CommonService } from '../Services';

@Directive({
  selector: '[emailValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true,
    },
  ],
})
export class EmailValidatorDirective implements Validator {
  constructor(private commonService: CommonService) {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value !== '') {
      const form = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/.test(control.value)
        ? null
        : { invalid: true };

      this.commonService.isEmailValid = true;
      if (form !== null) {
        this.commonService.isEmailValid = false;
      }
      return form;
    }
    return null;
  }
}
