import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-scottish-regulator-popup',
  templateUrl: './scottish-regulator-popup.component.html',
  styleUrls: ['./scottish-regulator-popup.component.scss'],
})
export class ScottishRegulatorPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<ScottishRegulatorPopupComponent>
  ) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
