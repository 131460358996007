import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  AccountingMethod,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { PurchaseTypeName } from '@app/core/Enum/purchase-type-name';
import {
  ChartOfAccountListParam,
  Currency,
  GlobalComponent,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  CreateFund,
  CreateAccount,
  DonationState,
  GetActivityList,
  GetCurrencyList,
  GetDataByAccountId,
  GetFundNameList,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetNonStandardAccountList,
  GetDataByFundId,
  CreateActivity,
  GetDataByActivityId,
  CreateContact,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-bill',
  templateUrl: './add-bill.component.html',
  styleUrls: ['./add-bill.component.scss'],
})
export class AddBillComponent implements OnInit {
  billForm: FormGroup;
  currencyList: Currency[];
  supplierList: SideListModel[];
  billTypeList: SideListModel[];
  fundNameList: SideListModel[];
  activityList: SideListModel[];
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];

  defaultCurrency: Guid;
  getListId: Guid;
  accountingMethodId: any;
  accountingMethod = AccountingMethod;

  @Input() triggerEditData: Observable<any>;
  @Output() maxDate = new EventEmitter<any>();
  @Output()
  readonly supplierClick = new EventEmitter<any>();

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  @Output()
  readonly isVatIncludedClick = new EventEmitter<any>();

  private destroy$ = new Subject<void>();

  disabledEditButton = true;
  disabledFundEditButton = true;
  disabledActivityEditButton = true;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  periodicDate: any;
  isVatInclude = false;
  isVatRegistered: any;

  isSupplierChangePermission: boolean = true;
  isFundChangePermission: boolean = true;
  isActivityChangePermission: boolean = true;

  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public dialog: MatDialog,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.accountingMethodId = this.globalComponent.getAccountingMethod();
    this.setForm();

    this.triggerEditData
      .pipe(
        switchMap((data) => {
          return this.loadDropdownValues().pipe(map(() => data));
        })
      )
      .subscribe((data) => {
        this.editBill(data);
        this.billForm.controls.billNo.disable();
      });

    this.billForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.billForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSupplierChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Suppliers
      );
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
      this.isActivityChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Activities
      );
    }, 3000);
  }

  loadDropdownValues(): Observable<any> {
    return forkJoin([
      this.getSupplier(),
      this.getCurrency(),
      this.getInvoiceType(),
      this.getFundNameList(),
      this.getActivity(),
    ]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  dateChange(): void {
    let startDate: any = new Date(this.billForm.controls['billDate'].value);

    startDate = this.datepipe.transform(startDate, 'yyyy-MM-dd')?.toString();
    this.maxDate.emit(startDate);

    let endDate: any = new Date(this.billForm.controls['billDate'].value);

    this.billForm.controls['dueDate'].setValue(startDate);

    endDate = this.datepipe.transform(startDate, 'yyyy-MM-dd')?.toString();
  }

  getInvoiceType(): Observable<any> {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Expense);

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: this.commonService.billTypeList,
    };

    return this.store
      .dispatch(new GetGroupAccountsBasedOnGroupIdAndTypeId(param))
      .pipe(
        tap((res) => {
          if (res.common.accountGroupList.length > 0) {
            this.accountGroupList = res.common.accountGroupList;
            this.tempAccountGroupList = this.accountGroupList;
          }
        })
      );
  }

  getCurrency(): Observable<any> {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    return this.store.dispatch(new GetCurrencyList()).pipe(
      tap(() => {
        this.currencyList = this.store.selectSnapshot(CommonState.getCurrency);
        this.billForm.controls.currency.setValue(this.defaultCurrency);
      })
    );
  }

  getSupplier(id?: Guid): Observable<any> {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    return this.store.dispatch(new GetNonStandardAccountList(entityId)).pipe(
      tap((res) => {
        this.supplierList = res.common.accountList;

        if (id !== null && id !== undefined) {
          this.billForm.controls.supplierId.setValue(id);
        }
      })
    );
  }

  gotoAddSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Suppliers,
          headerText: `Add ${ModuleName.Suppliers}`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getSupplier(id).subscribe();
        }
      });
  }

  gotoEditSupplier(): void {
    if (this.supplierList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Suppliers,
            headerText: `Edit ${ModuleName.Suppliers}`,
            id: this.billForm.controls.supplierId.value,
            saveActionName: CreateContact,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getSupplier(id).subscribe();
          }
        });
    }
  }

  activityChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledActivityEditButton = false;
    } else {
      this.disabledActivityEditButton = true;
    }
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledFundEditButton = false;
    } else {
      this.disabledFundEditButton = true;
    }
  }

  supplierChange(event: any): void {
    this.supplierClick.emit(event);
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  editBill(data): void {
    let account;
    this.accountGroupList.forEach((items) => {
      const accountId = items.listModels.find(
        (x: any) => x.id === data.bookAccountId
      );
      if (accountId !== null && accountId !== undefined) {
        account = accountId;
      }
    });
    this.disabledEditButton = false;
    this.disabledFundEditButton = false;
    this.disabledActivityEditButton = false;
    this.billForm.patchValue({
      billNo: data.entryNumber,
      billDate: data.entryDate,
      currency: data.currencyId,
      supplierId: data.accountId,
      dueDate: data.dueDate,
      notes: data.note,
      billType: account,
      fundName: data.fundId,
      activity: data.activityId === null ? '' : data.activityId,
      isVatInclude: data.isVatIncluded,
    });
  }

  setForm(): void {
    this.billForm = new FormGroup({
      billNo: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      supplierId: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      billDate: new FormControl(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      currency: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      dueDate: new FormControl(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      notes: new FormControl(''),
      billType: new FormControl('', [Validators.required]),
      fundName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      activity: new FormControl(''),
      isVatInclude: new FormControl(false),
    });

    this.loadDropdownValues().subscribe();
  }

  onCheckBoxSelected(event: any): void {
    this.isVatInclude = event.checked;
    this.isVatIncludedClick.emit(this.isVatInclude);
  }

  getActivity(id?: Guid): Observable<any> {
    return this.store.dispatch(new GetActivityList()).pipe(
      tap(() => {
        this.activityList = this.store.selectSnapshot(
          DonationState.getActivity
        );

        if (id !== null && id !== undefined) {
          this.billForm.controls.activity.setValue(id);
        }
      })
    );
  }

  getFundNameList(): Observable<any> {
    return this.store.dispatch(new GetFundNameList()).pipe(
      tap((res) => {
        this.fundNameList = res.account.fundNameList;
        if (this.fundNameList.length > 0) {
          this.disabledFundEditButton = false;
          this.billForm.controls.fundName.setValue(this.fundNameList[0].id);
        }
      })
    );
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledFundEditButton = false;
          this.getFund(id).subscribe();
        }
      });
  }

  getFund(id): Observable<any> {
    return this.store.dispatch(new GetFundNameList()).pipe(
      tap((res) => {
        this.fundNameList = res.account.fundNameList;
        if (id !== null && id !== undefined) {
          this.billForm.controls.fundName.setValue(id);
        }
      })
    );
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.billForm.controls.fundName.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id).subscribe();
          }
        });
    }
  }

  resetAccountList(): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(this.billForm.controls.billType.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  getOptionText(option) {
    return option.name;
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  gotoEditActivity(): void {
    if (this.activityList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Activities,
            headerText: `Edit ${ModuleName.Activities}`,
            id: this.billForm.controls.activity.value,
            saveActionName: CreateActivity,
            getActionName: GetDataByActivityId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getActivity(id).subscribe();
          }
        });
    }
  }

  gotoAddActivity(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Activities,
          headerText: `Add ${ModuleName.Activities}`,
          saveActionName: CreateActivity,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledActivityEditButton = false;
          this.getActivity(id).subscribe();
        }
      });
  }
}
