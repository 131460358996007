import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { GlobalComponent, GroupListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CreateFund,
  GetDataByFundId,
  GetFundNameList,
  GetGroupCustomBankAndCashAccount,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-bank-transfer',
  templateUrl: './add-bank-transfer.component.html',
  styleUrls: ['./add-bank-transfer.component.scss'],
})
export class AddBankTransferComponent implements OnInit {
  bankTransferForm: FormGroup;
  maxLength = MaxLength;

  fundNameList: any;
  transferToGroupList: any;
  transferFromGroupList: any;
  periodicDate: any;

  @Input() triggerTransactionLogData: Observable<any>;
  @Input() triggerEditData: Observable<any>;
  notificationMessage = NotificationTextMessage;
  tempGroupList: GroupListModel[];

  disabledFundEditButton = true;

  private destroy$ = new Subject<void>();

  isBankImport = false;
  isCredit = false;
  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private store: Store,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();

    this.triggerTransactionLogData
      .pipe(
        switchMap((data) => {
          return this.getTransferList().pipe(map(() => data));
        })
      )
      .subscribe((data) => {
        this.isBankImport = true;
        this.isCredit = data.isCredit;

        if (!data.isCredit) {
          const transferTo = this.findTransferItem(
            data.accountId,
            this.transferToGroupList
          );
          this.bankTransferForm.controls.transferTo.setValue(transferTo);
          this.bankTransferForm.controls.transferTo.disable();
        } else {
          const transferFrom = this.findTransferItem(
            data.accountId,
            this.transferFromGroupList
          );
          this.bankTransferForm.controls.transferFrom.setValue(transferFrom);
          this.bankTransferForm.controls.transferFrom.disable();
        }
      });

    this.triggerEditData
      .pipe(
        switchMap((data) => {
          return this.getTransferList().pipe(map(() => data));
        })
      )
      .subscribe((data) => {
        this.editBankTransfer(data);
      });

    this.bankTransferForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.bankTransferForm.touched;
    });
  }

  findTransferItem(accountId: any, transferGroupList: any[]): any {
    for (const items of transferGroupList) {
      const transferItem = items.listModels.find(
        (x: any) => x.id === accountId
      );
      if (transferItem) {
        return transferItem;
      }
    }
    return null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  editBankTransfer(data): void {
    let transferFrom;
    this.transferFromGroupList.forEach((items) => {
      const transferFromId = items.listModels.find(
        (x: any) => x.id === data.transferredFromId
      );
      if (transferFromId !== null && transferFromId !== undefined) {
        transferFrom = transferFromId;
      }
    });

    let transferTo;
    this.transferToGroupList.forEach((items) => {
      const transferToId = items.listModels.find(
        (x: any) => x.id === data.transferredToId
      );
      if (transferToId !== null && transferToId !== undefined) {
        transferTo = transferToId;
      }
    });

    this.bankTransferForm.patchValue({
      bankTransferNumber: data.entryNumber,
      transferDate: data.entryDate,
      transferFrom: transferFrom,
      transferTo: transferTo,
      reference: data.note,
      amount: data.amount,
      fundId: data.fundId,
    });
  }

  resetAccountList(isFromTransfer: boolean): void {
    let data;
    if (isFromTransfer) {
      data = this.bankTransferForm.controls.transferFrom.value;
      this.transferFromGroupList = this.tempGroupList;
    } else {
      data = this.bankTransferForm.controls.transferTo.value;
      this.transferToGroupList = this.tempGroupList;
    }
    this.scrollIntoView(data);
  }

  onAccountSearch(event: any, isFromTransfer: boolean): void {
    const list = this.commonService.onAccountSearch(event, this.tempGroupList);
    if (isFromTransfer) {
      this.transferFromGroupList = list;
    } else {
      this.transferToGroupList = list;
    }
  }

  getOptionText(option) {
    return option.name;
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledFundEditButton = false;
          this.getFund(id);
        }
      });
  }

  setForm(): void {
    this.bankTransferForm = new FormGroup(
      {
        bankTransferNumber: new FormControl('', Validators.required),
        transferDate: new FormControl(
          new Date(),
          datePickerValidator(this.periodicDate)
        ),
        transferFrom: new FormControl('', [Validators.required]),
        transferTo: new FormControl('', [Validators.required]),
        amount: new FormControl(''),
        reference: new FormControl('', [Validators.required]),
        fundId: new FormControl('', [Validators.required]),
      },
      { validators: this.compareValidator('transferFrom', 'transferTo') }
    );

    this.bankTransferForm.controls.bankTransferNumber.disable();
    this.getTransferList().subscribe();
    this.getFund();
  }

  compareValidator(transferFrom, transferTo): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      if (!control.get(transferFrom)?.value || !control.get(transferTo)?.value)
        return null;

      if (control.get(transferFrom)?.value === control.get(transferTo)?.value) {
        if (this.isBankImport) {
          if (!this.isCredit) {
            control.controls.transferFrom.status = 'INVALID';
            control.controls.transferTo.status = 'DISABLED';
          } else {
            control.controls.transferTo.status = 'INVALID';
            control.controls.transferFrom.status = 'DISABLED';
          }
        } else {
          control.controls.transferFrom.status = 'INVALID';
          control.controls.transferTo.status = 'INVALID';
        }
        return { matchingFields: true };
      } else {
        if (this.isBankImport) {
          if (!this.isCredit) {
            control.controls.transferTo.status = 'DISABLED';
          } else {
            control.controls.transferFrom.status = 'DISABLED';
          }
        } else {
          control.controls.transferFrom.status = 'VALID';
          control.controls.transferTo.status = 'VALID';
        }
        return null;
      }
    };
  }

  getFund(id?: Guid): void {
    this.store
      .dispatch(new GetFundNameList())
      .pipe(
        tap((res) => {
          this.fundNameList = res.account.fundNameList;

          if (id !== null && id !== undefined) {
            this.bankTransferForm.controls.fundId.setValue(id);
          }
        })
      )
      .subscribe();
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.bankTransferForm.controls.fundId.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledFundEditButton = false;
    } else {
      this.disabledFundEditButton = true;
    }
  }

  getTransferList(): Observable<any> {
    return this.store.dispatch(new GetGroupCustomBankAndCashAccount()).pipe(
      tap((res) => {
        this.transferFromGroupList =
          res.common.customBankAndCashAccountGroupList;
        this.transferToGroupList = res.common.customBankAndCashAccountGroupList;

        this.tempGroupList = this.transferFromGroupList;
      })
    );
  }
}
