import { PaymentsModel } from '@app/core/Models/transactions/payments';
import { Guid } from 'guid-typescript';
export class CreatePayments {
  static readonly type = '[PAYMENT] Create Payment';

  constructor(public payments: PaymentsModel) {}
}

export class GetDataByPaymentsId {
  static readonly type = '[PAYMENT] Get Payment Data By Payment Id';

  constructor(public paymentsId: Guid) {}
}

export class GetTransactionTypeList {
  static readonly type = '[PAYMENT] Get Transaction Type List';
}
