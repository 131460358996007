import { Component, Input } from '@angular/core';
import { GiftAidImport } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-gift-aid-donations-details',
  templateUrl: './gift-aid-donations-details.component.html',
  styleUrls: ['./gift-aid-donations-details.component.scss'],
})
export class GiftAidDonationsDetailsComponent {
  displayedColumns: any[] = [];
  dataSource: any[] = [];

  @Input() giftAidImportData: [];

  constructor(private store: Store, private spinner: NgxSpinnerService) {}

  ngOnChanges() {
    this.spinner.show();
    this.getList();
  }

  getList(): void {
    this.dataSource = this.giftAidImportData;
    if (this.dataSource.length > 0) {
      this.displayedColumns = Object.keys(this.dataSource[0]);
    }
    this.spinner.hide();
  }
}
