import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { FileImportRequestModel, ImportStep } from '@app/core/Enum/import';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  CommonState,
  GetBankImportTemplate,
  GetCustomBankAccountList,
  ImportBank,
  ImportState,
} from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { ReportListComponent } from '@app/modules/reports';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-bank-import',
  templateUrl: './bank-import.component.html',
  styleUrls: ['./bank-import.component.scss'],
})
export class BankImportComponent implements OnInit {
  selectedFileName: string;
  fileInfo: string = '';
  isExpandAll = false;
  selectedFile: any;
  url: any;
  bankAccountList: any;
  moduleId = Modules.BankImportHistory;
  customId = Guid.EMPTY as unknown as Guid;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(ReportListComponent, { static: true })
  reportList;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  fileImportRequestModel: FileImportRequestModel;

  subscriptionRouting: Subscription;
  showPaginator = true;

  @Output()
  readonly triggerBankAccountId = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private notifier: NotificationService,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
    private _Activatedroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.commonService.toggleMenu = false;
    this.spinner.hide();
    this.getBankAccountList();

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.customId = atob(params.get('customId')!) as unknown as any;
        }
      }
    );
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onSave(): void {
    this.fileImportRequestModel = {
      file: this.selectedFile,
      step: ImportStep.Upload,
    };

    this.spinner.show();
    this.store
      .dispatch(new ImportBank(this.fileImportRequestModel, this.customId))
      .subscribe(() => {
        const details = this.store.selectSnapshot(ImportState.getImportData);
        this.spinner.hide();
        if (details.StatusCode === 0 || details.StatusCode !== undefined) {
          this.commonService.onFailure(details.Message);
        } else {
          this.commonService.onSucess(NotificationTextMessage.fileUploaded);
          this.triggerBankAccountId.emit(this.customId);
          this.onCancel();
        }
        (error) => {
          this.commonService.onFailure(error.message);
          this.spinner.hide();
        };
      });
  }

  onCancel(): void {
    this.selectedFile = '';
    this.url = '';
    this.fileInfo = '';
    this.selectedFileName = '';
  }

  downloadTemplateFile(): void {
    this.spinner.show();
    this.store.dispatch(new GetBankImportTemplate()).subscribe((res) => {});
  }

  onFileOpen(event): void {
    event.target.value = null;
  }

  checkSpecialChar(selectedFile: any): any {
    const format = /[!@#$%^&*():"<>?{}|]/;
    return format.test(selectedFile.name);
  }

  formatBytes(bytes: number): string {
    const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = 1024;
    let index = 0;
    while (bytes >= factor) {
      bytes /= factor;
      index++;
    }
    return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
  }

  onDragOver(event: Event) {
    event.preventDefault();
    // Add styles to the drop area to indicate it's a valid drop target.
    // For example, you can change the background color or border.
  }

  onDragLeave(event: Event) {
    event.preventDefault();
    // Remove the styles applied in onDragOver.
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    // Prevent the default behavior of opening the dropped file in the browser.
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files !== null && files !== undefined && files.length > 0) {
      // Handle the dropped files (e.g., upload or process them).
      this.handleDroppedFiles(files);
    }
  }

  handleDroppedFiles(files: FileList) {
    let file: any;
    // Handle the dropped or selected files here, e.g., by uploading them to a server.
    for (let i = 0; i < files.length; i++) {
      file = files[i];
      // Process or upload the file as needed.
    }
    this.onProcessFileSelected(file);
  }

  onFileSelected(input: HTMLInputElement): void {
    if (!input.files || !input.files.length) {
      return;
    }
    const file = input.files![0];
    this.onProcessFileSelected(file);
  }

  onProcessFileSelected(files: any): void {
    const file = files;
    this.selectedFileName = file.name;
    if (file.size / 1024 / 1024 > 10) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSizeExceeds
      );
      return;
    }

    if (this.checkSpecialChar(file)) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.filenameWithSpecialCharacter
      );
      return;
    }

    this.fileInfo = `${file.name} (${this.formatBytes(file.size)})`;

    this.selectedFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.url = reader.result;
    };
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog
      .open(AddClosePopupComponent, {})
      .afterClosed()
      .subscribe((result) => {
        this.commonService.setLocalStorage('selectedBank', this.customId);
      });
  }

  onbankNameChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.triggerBankAccountId.emit(this.customId);
    }
  }

  getBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.bankAccountList = res.common.customBankAccount;
          if (this.commonService.isEmpty(this.customId)) {
            this.customId = this.bankAccountList[0].id;
          }

          this.triggerBankAccountId.emit(this.customId);
        })
      )
      .subscribe();
  }

  onFileRemoveClick(): void {
    this.fileInfo = '';
  }
}
