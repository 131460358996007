import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  ConfirmationType,
  FilteredStatus,
  HeaderTextForSideList,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { ExportFormat } from '@app/core/Enum/export-type';
import { ExportType, MainListParameters } from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  Copy,
  Export,
  ExportReceipt,
  GetSideList,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmationBoxComponent, EmailPopupComponent } from '..';

@Component({
  selector: 'app-side-list',
  templateUrl: './side-list.component.html',
  styleUrls: ['./side-list.component.scss'],
})
export class SideListComponent implements OnInit {
  @Input()
  getModuleId: number;

  dataSourceSelectedLength = 0;
  selectedId = Guid.EMPTY as unknown as Guid;
  closeMenu = false;
  ids: Array<Guid>;
  selectedRowData: Array<any> = [];
  dataSource: any;
  headerText: string;

  isAllSelected = false;
  showSearchBar = false;
  isAddMode = true;
  isRowHighlighted = false;
  allSelectVisible = true;
  canScroll = true;

  displayedColumns: string[] = ['name'];

  @Input()
  reloadSideList: Observable<any>;

  @ViewChild('searchText') searchField: ElementRef;
  listParameters: MainListParameters = new MainListParameters();

  @Output()
  readonly toggleSideList = new EventEmitter<boolean>();

  @Output()
  readonly showSideListAction = new EventEmitter<any>();

  triggerNumberOfRecords: Subject<any> = new Subject<any>();
  triggerSelectedRowData: Subject<any> = new Subject<any>();

  @Output()
  readonly reloadAutoNumberGenerator = new EventEmitter<any>();

  moduleId: number;
  moduleName: string;
  searchText = '';

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  store: Store;
  notify: NotificationService;
  dialog: MatDialog;
  highlightRow: HighlightRow;
  commonService: CommonService;
  activatedRoute: ActivatedRoute;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;

  constructor(private injector: Injector, private renderer: Renderer2) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.commonService = injector.get<CommonService>(CommonService);
    this.activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
  }

  autoScroll(): void {
    const fragment = 'highlightRecord';
    const element = this.renderer.selectRootElement(`.${fragment}`, true);
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params && params['id']) {
        this.isAddMode = false;
        this.selectedId = atob(params['id']!) as unknown as Guid;
      }
    });

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.reloadSideList?.subscribe(() => {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: FilteredStatus.All,
        sortOrder: false,
        sortBy: this.highlightRow.sideListHighlighted.sortBy,
        search: this.listParameters.search,
        isRowHighlighted: this.highlightRow.sideListHighlighted.isHighlighted,
      };
      this.selectedId = this.highlightRow.sideListHighlighted.isHighlighted
        ? (Guid.EMPTY as unknown as Guid)
        : this.selectedId;
      this.bindList(queryParams);
    });

    this.bindList(queryParams);
  }

  changeHeaderText(): void {
    switch (this.getModuleId) {
      case Modules.Donors:
        this.headerText = HeaderTextForSideList.Donors;
        break;
      case Modules.Customers:
        this.headerText = HeaderTextForSideList.Customers;
        break;
      case Modules.Suppliers:
        this.headerText = HeaderTextForSideList.Suppliers;
        break;

      case Modules.SponsorEvent:
        this.headerText = HeaderTextForSideList.SponsorEvent;
        break;

      case Modules.Invoices:
        this.headerText = HeaderTextForSideList.Invoice;
        break;

      case Modules.Donations:
        this.headerText = HeaderTextForSideList.Donations;
        break;

      case Modules.BankTransfer:
        this.headerText = HeaderTextForSideList.BankTransfer;
        break;

      case Modules.FixedAssets:
        this.headerText = HeaderTextForSideList.FixedAssets;
        break;

      case Modules.FundList:
        this.headerText = HeaderTextForSideList.Fund;
        break;

      case Modules.RecurringInvoice:
        this.headerText = HeaderTextForSideList.RecurringInvoice;
        break;

      case Modules.Activities:
        this.headerText = HeaderTextForSideList.Activities;
        break;

      case Modules.RecurringBill:
        this.headerText = HeaderTextForSideList.RecurringBill;
        break;
      case Modules.Quotation:
        this.headerText = HeaderTextForSideList.Quotation;
        break;
      case Modules.CreditNote:
        this.headerText = HeaderTextForSideList.CreditNote;
        break;
      case Modules.Receipt:
        this.headerText = HeaderTextForSideList.Receipt;
        break;

      case Modules.Bills:
        this.headerText = HeaderTextForSideList.Bills;
        break;
      case Modules.DebitNote:
        this.headerText = HeaderTextForSideList.DebitNote;
        break;
      case Modules.Payment:
        this.headerText = HeaderTextForSideList.Payment;
        break;
      case Modules.Journals:
        this.headerText = HeaderTextForSideList.Journals;
        break;
      case Modules.AddCustomAccounts:
        this.headerText = HeaderTextForSideList.CustomAccount;
        break;
      case Modules.Bills:
        this.headerText = HeaderTextForSideList.Bills;
        break;
      case Modules.FundTransfers:
        this.headerText = HeaderTextForSideList.FundTransfers;
        break;
      case Modules.CashEntry:
        this.headerText = HeaderTextForSideList.CashEntry;
        break;
      case Modules.BankEntry:
        this.headerText = HeaderTextForSideList.BankEntry;
        break;
      case Modules.RecurringDonations:
        this.headerText = HeaderTextForSideList.RecurringDonations;
        break;
      case Modules.DonationsInKind:
        this.headerText = HeaderTextForSideList.DonationsInKind;
        break;
      case Modules.Users:
        this.headerText = HeaderTextForSideList.Users;
        break;
    }
  }

  bindList(queryParams: any): void {
    this.changeHeaderText();
    this.moduleName = this.commonService.getModuleName(this.getModuleId);

    this.store
      .dispatch(new GetSideList(queryParams, this.getModuleId))
      .pipe(
        tap(() => {
          this.isRowHighlighted = queryParams.isRowHighlighted ?? false;
          this.dataSource = this.store.selectSnapshot(CommonState.getSideList);
          if (this.dataSource.length > 0) {
            this.canScroll = true;
            this.allSelectVisible = true;
          } else {
            this.allSelectVisible = false;
          }
          if (this.selectedId !== (Guid.EMPTY as unknown as Guid)) {
            setTimeout(() => {
              this.autoScroll();
            }, 200);
          }
        })
      )
      .subscribe();
  }

  selectAll(event: any): void {
    this.dataSource.forEach((x) => (x.isSelected = event.checked));

    this.isActionVisible(false);
  }

  onReloadSideList(): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: true,
    };

    this.cancelSelectionClick();
    this.bindList(queryParams);
  }

  addClick(): void {
    this.spinner.show();

    this.selectedId = Guid.EMPTY as unknown as Guid;
    this.isRowHighlighted = false;
    this.commonService.onAddRoutingFromSideList(this.getModuleId);
    this.spinner.hide();
  }

  editClick(id: any): void {
    this.ids = [];
    this.ids.push(id);

    this.commonService
      .isDataInLockedPeriod(this.ids, this.getModuleId)
      .subscribe((isValidPeriod) => {
        if (isValidPeriod) {
          if (this.selectedId !== id) {
            this.spinner.show();
          }

          this.selectedId = id;
          this.isRowHighlighted = false;

          this.commonService.onEditRouting(true, this.getModuleId, id);
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  deleteClick(): void {
    this.selectedIds();

    if (
      this.getModuleId === Modules.Users
        ? this.checkCanDeleteArchive(false)
        : true
    ) {
      this.commonService
        .isDataInLockedPeriod(this.ids, this.getModuleId)
        .subscribe((isValidPeriod) => {
          if (this.ids.length > 0 && isValidPeriod) {
            this.dialog
              .open(ConfirmationBoxComponent, {
                data: {
                  ids: this.ids,
                  type: ConfirmationType.Delete,
                  moduleId: this.getModuleId,
                  totalNumberOfRecordSelected: this.ids.length,
                  headerText: NotificationHeader.deleteConfirmation,
                  detailText: NotificationDetails.deleteAllDetailText,
                },
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                  if (this.ids.includes(this.selectedId)) {
                    const locationPath = window.location.href
                      .split(';')[0]
                      .toString();
                    window.location.replace(locationPath);
                  }

                  this.cancelSelectionClick();
                }
              });
          } else {
            this.commonService.onFailure(
              NotificationTextMessage.dataInLockedPeriod
            );
          }
        });
    }
  }

  selectedIds(): void {
    this.ids = [];

    this.dataSource.forEach((x) =>
      x.isSelected ? this.ids.push(x.id ?? 0) : ''
    );
  }

  toggleSearchBar(): void {
    this.showSearchBar = !this.showSearchBar;

    if (this.showSearchBar) {
      setTimeout(() => {
        this.searchField.nativeElement.focus();
      }, 0);
    }
  }

  copyClick(): void {
    this.spinner.show();
    this.selectedIds();

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: false,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.commonService
      .isDataInLockedPeriod(this.ids, this.getModuleId)
      .subscribe((isValidPeriod) => {
        if (isValidPeriod) {
          this.store
            .dispatch(new Copy(this.getModuleId, this.ids))
            .subscribe((res) => {
              if (res.common.isCopied) {
                this.notify.success(
                  NotificationHeader.success,
                  NotificationTextMessage.recordCopySuccessfully
                );
                this.bindList(queryParams);
                this.cancelSelectionClick();
                if (
                  (this.getModuleId === Modules.Invoices ||
                    this.getModuleId === Modules.RecurringInvoice ||
                    this.getModuleId === Modules.Quotation ||
                    this.getModuleId === Modules.RecurringBill ||
                    this.getModuleId === Modules.Journals ||
                    this.getModuleId === Modules.BankTransfer) &&
                  this.selectedId === (Guid.EMPTY as unknown as Guid)
                ) {
                  this.reloadAutoNumberGenerator.emit();
                }
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  exportClick(format: number, isPrint: boolean): void {
    this.spinner.show();
    this.selectedIds();

    if (
      (this.getModuleId === Modules.Invoices ||
        this.getModuleId === Modules.Bills ||
        this.getModuleId === Modules.Quotation ||
        this.getModuleId === Modules.CreditNote ||
        this.getModuleId === Modules.DebitNote ||
        this.getModuleId === Modules.FixedAssets ||
        this.getModuleId === Modules.Journals ||
        this.getModuleId === Modules.Receipt ||
        this.getModuleId === Modules.Payment ||
        this.getModuleId === Modules.Donations) &&
      format === ExportFormat.PDF
    ) {
      this.store
        .dispatch(new ExportReceipt(this.getModuleId, this.ids, isPrint))
        .subscribe();
      this.cancelSelectionClick();
    } else {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId:
          this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
        subModuleId: this.listParameters.subModuleId ?? -1,
        ids: this.ids,
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store
        .dispatch(new Export(queryParams, this.getModuleId))
        .subscribe((res) => {
          this.dataSource = res.common.sideListModel;
        });
    }
  }

  archiveAndRestoreClick(isArchive: boolean): void {
    this.selectedIds();

    if (
      this.getModuleId === Modules.Users
        ? this.checkCanDeleteArchive(true)
        : true
    ) {
      this.commonService
        .isDataInLockedPeriod(this.ids, this.getModuleId)
        .subscribe((isValidPeriod) => {
          if (this.ids.length > 0 && isValidPeriod) {
            this.dialog
              .open(ConfirmationBoxComponent, {
                data: {
                  ids: this.ids,
                  isArchive,
                  type: ConfirmationType.Archive,
                  moduleId: this.getModuleId,
                  totalNumberOfRecordSelected: this.ids.length,
                  headerText: isArchive
                    ? NotificationHeader.archiveConfirmation
                    : NotificationHeader.restoreConfirmation,
                  detailText: isArchive
                    ? NotificationDetails.archiveDetailText
                    : NotificationDetails.restoreDetailText,
                },
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                  this.cancelSelectionClick();
                  this.addClick();
                }
              });
          } else {
            this.commonService.onFailure(
              NotificationTextMessage.dataInLockedPeriod
            );
          }
        });
    }
  }

  onSearch(): void {
    this.listParameters.search = this.searchText;
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.bindList(queryParams);
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.onSearch();
    }
  }

  onCancel(): void {
    this.listParameters.search = '';
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };
    this.bindList(queryParams);
  }

  onTableScroll(event): void {
    let isLastPage: boolean | undefined = false;

    isLastPage = this.store.selectSnapshot(CommonState.isLastPage);

    if (isLastPage === false) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight
      ) {
        if (this.canScroll) {
          this.canScroll = false;
          const pageIndex = this.listParameters.pageNumber + 1;
          this.pageChanged(pageIndex);
        }
      }
    }
  }

  pageChanged(pageIndex: number): void {
    this.listParameters.pageNumber = pageIndex;
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.bindList(queryParams);
  }

  onCheckBoxSelected(element: any): void {
    this.isAllSelected = this.dataSource.every(
      (item: any) => item.isSelected === true
    );
    this.isActionVisible(false);
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
    this.isActionVisible(true);
  }

  deSelectAll(): void {
    this.dataSource.forEach((x) => (x.isSelected = false));
    this.triggerNumberOfRecords.next(0);
    this.isAllSelected = false;
  }

  printClick(): void {
    this.selectedIds();
    this.exportClick(ExportType.PDF, true);
  }

  isActionVisible(reloadList: boolean): void {
    this.dataSourceSelectedLength = 0;

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus.All,
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.selectedRowData = [];
    this.dataSource.forEach((x) => {
      if (x.isSelected) {
        this.dataSourceSelectedLength++;
        this.selectedRowData.push(x.isArchived);
      }
    });

    this.showSideListAction.emit(
      this.dataSourceSelectedLength > 0 ? true : false
    );

    this.triggerNumberOfRecords.next(this.dataSourceSelectedLength);

    if (this.dataSourceSelectedLength > 0) {
      setTimeout(() => {
        this.triggerSelectedRowData.next(this.selectedRowData);
      }, 100);
    }

    if (reloadList || this.dataSourceSelectedLength === 0) {
      this.bindList(queryParams);
    }
  }

  isActive(element: any): boolean {
    return this.selectedId === element.id;
  }

  menuToggle(): void {
    this.closeMenu = !this.closeMenu;

    this.toggleSideList.emit(this.closeMenu);
  }

  emailClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(EmailPopupComponent, {
          data: {
            ids: this.ids,
            moduleId: this.getModuleId,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.mailSentSuccessfully
            );
            this.cancelSelectionClick();
          }
        });
    }
  }

  checkCanDeleteArchive(isArchiveClicked: boolean): boolean {
    let returnValue = true;

    this.dataSource?.forEach((data) => {
      if (this.ids?.includes(data.id!) && !data.canDelete) {
        this.notify.error(
          NotificationHeader.error,
          isArchiveClicked
            ? NotificationTextMessage.canNotInactive
            : NotificationTextMessage.canNotDelete
        );
        returnValue = false;
      }
    });

    return returnValue;
  }
}
