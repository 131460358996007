import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverviewModel } from '@app/core/Models';

import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNoData,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexTooltip,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type ChartOption = {
  noData: ApexNoData;
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
};

@Component({
  selector: 'app-overview-incomes-chart',
  templateUrl: './overview-incomes-chart.component.html',
  styleUrls: ['./overview-incomes-chart.component.scss'],
})
export class OverviewIncomesChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  public chartOption: Partial<ChartOption>;
  reportList: OverviewModel;

  @Input()
  triggerIncomeData: Observable<any>;

  @Input()
  triggerExpensesData: Observable<any>;

  @Input()
  triggerOverviewData: Observable<any>;

  @Input()
  triggerFundTypeData: Observable<any>;

  ngOnInit(): void {
    this.triggerOverviewData?.subscribe((data) => {
      this.reportList = data.pieChart;
      if (Object.keys(this.reportList).length > 0) {
        this.getData();
      }
    });

    this.triggerIncomeData?.subscribe((data) => {
      this.reportList = data;
      this.getData();
    });

    this.triggerExpensesData?.subscribe((data) => {
      this.reportList = data;
      this.getData();
    });

    this.triggerFundTypeData?.subscribe((data) => {
      this.reportList = data;
      this.getData();
    });
  }

  getData(): void {
    const tempSeries: any = [];
    const tempCategories: any = [];

    for (const piechartData of this.reportList[`category`]) {
      tempCategories.push(piechartData);
    }

    for (const piechartData of this.reportList[`series`]) {
      tempSeries.push(piechartData);
    }

    this.chartOption = {
      series: tempSeries,
      chart: {
        width: 380,
        type: 'donut',
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      labels: tempCategories,
      legend: {
        show: false,
        position: 'bottom',
      },
      fill: {
        type: 'gradient',
        colors: [
          '#9B2FC5',
          '#FA76A1',
          '#8960C2',
          '#E57F6A',
          '#FF6498',
          '#E57F6A',
        ],
      },
      tooltip: {
        marker: {
          show: false,
        },
        enabled: true,
        fillSeriesColor: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
              markers: {
                fillColors: [
                  '#9B2FC5',
                  '#FA76A1',
                  '#8960C2',
                  '#E57F6A',
                  '#FF6498',
                  '#E57F6A',
                ],
              },
            },
          },
        },
      ],
    };
  }
}
