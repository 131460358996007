import { GiftAIDsettings, GiftAid } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class SaveGiftAid {
  static readonly type = '[GIFTAID] Save Gift Aid';

  constructor(public giftAidData: GiftAid) {}
}

export class SubmitToHMRC {
  static readonly type = '[GIFTAID] Submit To HMRC';

  constructor(public giftAddId: Guid) {}
}

export class DeleteGIF {
  static readonly type = '[GIFTAID] Delete  GIF';

  constructor(public giftAddId: Guid) {}
}

export class SaveGiftAIDsettings {
  static readonly type = '[GIFTAID]  Save Gift AID settings';
  constructor(public giftAIDSettingsData: GiftAIDsettings) {}
}

export class GetGiftAIDsettings {
  static readonly type = '[GIFTAID]  Get Gift AID settings';
}
