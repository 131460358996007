import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionType,
  ComponentName,
  ConfirmationType,
  DataType,
  FilteredStatus,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ExportFormat } from '@app/core/Enum/export-type';
import {
  ExportType,
  GlobalComponent,
  MainListParameters,
  ViewParamModel,
} from '@app/core/Models';
import {
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  Copy,
  Export,
  ExportReceipt,
  GetMainList,
  MenuState,
  SendEmail,
} from '@app/core/Store';
import {
  BankDashboardComponent,
  ConfirmationBoxComponent,
  EditAssetDetailComponent,
  EditDisposeAssetComponent,
  EmailPopupComponent,
  TransactionHeaderComponent,
  ViewReceiptComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
@Component({
  selector: 'app-main-list',
  templateUrl: './main-list.component.html',
  styleUrls: ['./main-list.component.scss'],
})
export class MainListComponent implements OnInit {
  @Select(CommonState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  isRowHighlighted = false;
  customId: any;
  showPaginator = true;
  checkBoxValue = 0;
  filteredStatus = FilteredStatus;

  @Select(CommonState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.actions)
  actions$: Observable<any>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;
  listParameters: MainListParameters = new MainListParameters();
  totalSelectedRecords = 0;
  isAllSelected = false;
  isHeaderChange = false;
  isExpanded = true;
  ids: Array<Guid>;
  selectedRowData: Array<any> = [];
  mainList: any;
  dataSource = new MatTableDataSource();
  dataType = DataType;
  moduleId: number;
  moduleEnum = Modules;
  selectedRowIndex = -1;
  moreActionCount = 4;
  moduleName: string;

  triggerPaginationChange: Subject<any> = new Subject<any>();
  triggerCard: Subject<any> = new Subject<any>();
  triggerSelectedRowData: Subject<any> = new Subject<any>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.hasEditPermission)
  hasEditPermission$: Observable<boolean>;

  @Select(MenuState.hasDeletePermission)
  hasDeletePermission$: Observable<boolean>;

  @ViewChild(MatSort) sort: MatSort;

  dialog: MatDialog;
  store: Store;
  notify: NotificationService;
  commonService: CommonService;
  router: Router;
  globalComponent: GlobalComponent;
  highlightRow: HighlightRow;
  datepipe: DatePipe;
  _Activatedroute: ActivatedRoute;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;
  noDataFound = false;
  multipleModulesIds: any;
  @ViewChild(TransactionHeaderComponent, { static: true })
  headerDetails;

  @ViewChild(BankDashboardComponent, { static: true })
  dashboard;

  subscriptionRouting: Subscription;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  actionTypeEnum = ActionType;

  constructor(private injector: Injector) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.globalComponent = injector.get<GlobalComponent>(GlobalComponent);
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
  }

  ngOnInit(): void {
    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
      this.moduleName = this.commonService.getModuleName(value.data.id);
      if (this.moduleId !== Modules.CharityProfile) {
        if (
          value.data.url === this.router.url.slice(1) &&
          (value.componentName === ComponentName.MainListComponent ||
            value.componentName === ComponentName.VatSettings)
        ) {
        }
      }
    });

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        const currentUrl = this._Activatedroute.snapshot['_routerState'].url;
        if (currentUrl.includes('bank/cashEntry')) {
          this.moduleId = Modules.CashEntry;
        }
      }
    );
  }

  highlightRecord(): void {
    if (this.highlightRow.mainListHighlighted.moduleId! > 0) {
      this.listParameters.sortBy = this.highlightRow.mainListHighlighted.sortBy;
      this.listParameters.sortOrder = false;
      this.isHeaderChange = false;
    }
  }

  selectAll(event: any): void {
    this.mainList.forEach((x) => {
      x.isSelected = event.checked;
    });

    this.showFooter();
  }

  showFooter(): void {
    this.selectedIds();
    this.totalSelectedRecords = this.ids.length;

    setTimeout(() => {
      this.triggerSelectedRowData.next(this.selectedRowData);
    }, 100);
  }

  deleteClick(): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                multipleModulesIds: this.multipleModulesIds,
                type: ConfirmationType.Delete,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: NotificationHeader.deleteConfirmation,
                detailText: NotificationDetails.deleteAllDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.isHeaderChange = false;
                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();
    this.selectedIds();
    if (
      (this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.Bills ||
        this.moduleId === Modules.Quotation ||
        this.moduleId === Modules.CreditNote ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.FixedAssets ||
        this.moduleId === Modules.Journals ||
        this.moduleId === Modules.Receipt ||
        this.moduleId === Modules.Payment ||
        this.moduleId === Modules.BridgingVAT ||
        this.moduleId === Modules.Donations ||
        this.moduleId === Modules.FixedAssetDetail) &&
      format === ExportFormat.PDF &&
      this.ids.length > 0
    ) {
      this.store
        .dispatch(new ExportReceipt(this.moduleId, this.ids, isPrint))
        .subscribe();
      this.cancelSelectionClick();
    } else {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId: this.listParameters.moduleId,
        subModuleId: this.listParameters.subModuleId ?? -1,
        ids: this.ids,
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
      this.cancelSelectionClick();
    }
  }

  copyClick(): void {
    this.spinner.show();
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (isValidPeriod) {
          if (Object.keys(this.multipleModulesIds).length > 0) {
            Object.keys(this.multipleModulesIds).forEach((element) => {
              this.store
                .dispatch(new Copy(+element, this.multipleModulesIds[+element]))
                .subscribe((res) => {
                  if (res.common.isCopied) {
                    this.notify.success(
                      NotificationHeader.success,
                      NotificationTextMessage.recordCopySuccessfully
                    );
                    this.isHeaderChange = false;
                    this.getList(false);
                    this.cancelSelectionClick();
                  }
                });
            });
          } else {
            this.store
              .dispatch(new Copy(this.moduleId, this.ids))
              .subscribe((res) => {
                if (res.common.isCopied) {
                  this.notify.success(
                    NotificationHeader.success,
                    NotificationTextMessage.recordCopySuccessfully
                  );
                  this.isHeaderChange = false;
                  this.getList(false);
                  this.cancelSelectionClick();
                }
              });
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  emailClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(EmailPopupComponent, {
          data: {
            ids: this.ids,
            moduleId: this.moduleId,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.mailSentSuccessfully
            );
            this.cancelSelectionClick();
          }
        });
    }
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.mainList?.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
    this.ids = [];
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.isHeaderChange = false;
    this.getList(false);
  }

  onAddClick(element: any): void {
    this.router.navigate([
      RoutingPath.AddCustomAccounts,
      { accountId: btoa(element[0]) },
    ]);
  }

  archiveAndRestoreClick(isArchive: any): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                isArchive,
                multipleModulesIds: this.multipleModulesIds,
                type: ConfirmationType.Archive,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: isArchive
                  ? NotificationHeader.archiveConfirmation
                  : NotificationHeader.restoreConfirmation,
                detailText: isArchive
                  ? NotificationDetails.archiveDetailText
                  : NotificationDetails.restoreDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.ids = [];
                this.isHeaderChange = false;
                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  getMultipleModuleId(element): void {
    let moduleId = +element[element.length - 1];
    if (!this.multipleModulesIds[moduleId]) {
      this.multipleModulesIds[moduleId] = [];
    }

    this.multipleModulesIds[moduleId].push(element[0]);
  }

  selectedIds(): void {
    this.ids = [];
    this.multipleModulesIds = {};
    this.selectedRowData = [];

    this.mainList.forEach((element) => {
      if (element.isSelected) {
        if (
          this.moduleId === Modules.CashEntry ||
          this.moduleId === Modules.BankEntry
        ) {
          this.getMultipleModuleId(element);
        }
        this.ids.push(element[0]);

        if (
          this.moduleId === Modules.CashEntry ||
          this.moduleId === Modules.BankEntry ||
          this.moduleId === Modules.VatSettings
        ) {
          //This is done because in  CashEntry & BankEntry last row is for moduleId not status
          this.selectedRowData.push(element[this.mainList[0].length - 2]);
        } else {
          this.selectedRowData.push(element[this.mainList[0].length - 1]);
        }
      }
    });
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.isHeaderChange = false;
      this.getList(false);
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.isHeaderChange = false;
    this.getList(false);
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    this.isAllSelected = this.mainList.every(
      (item: any) => item.isSelected === true
    );
    this.showFooter();
  }

  onDeleteClick(id: any, moduleId: number): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isHeaderChange = false;
            this.getList(false);
          }
        });
    }
  }

  setDynamicModuleId(element: any): number {
    let moduleId = this.moduleId;
    if (
      this.moduleId === Modules.CashEntry ||
      this.moduleId === Modules.BankEntry ||
      this.moduleId === Modules.BankDashboard
    ) {
      moduleId = +element[element.length - 1];
    }

    return moduleId;
  }

  onButtonClick(element: any, actionType: ActionType): void {
    let id = element[0];
    let moduleId = this.setDynamicModuleId(element);

    this.ids = [];
    this.ids.push(id);

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (
          isValidPeriod ||
          actionType === ActionType.Email ||
          actionType === ActionType.Export ||
          actionType === ActionType.View
        ) {
          switch (actionType) {
            case ActionType.Update:
              this.commonService.toggleMenu = false;
              const test = this.commonService.onEditRouting(true, moduleId, id);
              break;

            case ActionType.Delete:
              this.onDeleteClick(id, moduleId);
              break;

            case ActionType.Export:
              this.ids = [];
              this.ids.push(id);
              this.store
                .dispatch(new ExportReceipt(moduleId, this.ids, false))
                .subscribe();
              break;

            case ActionType.View:
              const data: ViewParamModel = {
                moduleId: moduleId,
                id,
              };

              this.dialog
                .open(ViewReceiptComponent, {
                  data,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result) => {});
              break;

            case ActionType.Email:
              this.store
                .dispatch(new SendEmail(this.moduleId, id))
                .subscribe((x) => {
                  if (x.common.isMailSent) {
                    this.notify.success(
                      NotificationHeader.success,
                      NotificationTextMessage.emailMessage
                    );
                  } else {
                    this.notify.error(
                      NotificationHeader.error,
                      NotificationTextMessage.emailFailedMessage
                    );
                  }
                });
              break;
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  onTOCButtonClick(element: any, actionType: ActionType): void {
    let id = element[0];

    switch (actionType) {
      case ActionType.Explain:
        this.dialog
          .open(EditAssetDetailComponent, {
            data: {
              id: btoa(id),
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;

      case ActionType.Dispose:
      case ActionType.Sell:
        this.dialog
          .open(EditDisposeAssetComponent, {
            data: {
              id: btoa(id),
              actionType: actionType,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;
    }
  }

  getList(isRowHighlighted: boolean): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };

    this.spinner.show();
    this.isRowHighlighted = isRowHighlighted;
    this.headerDetails.isRowHighlighted = isRowHighlighted;
    if (
      this.moduleId !== Modules.Dashboard &&
      this.moduleId !== Modules.IncomeOverview &&
      this.moduleId !== Modules.ExpenditureOverview &&
      this.moduleId !== Modules.DonationOverview &&
      this.moduleId !== Modules.FundOverview &&
      this.moduleId !== Modules.FixedAssetOverview
    ) {
      if (this.moduleId !== null && this.moduleId !== undefined) {
        this.store
          .dispatch(new GetMainList(queryParams, this.moduleId))
          .subscribe((res) => {
            this.highlightRow.mainListHighlighted.moduleId = 0;
            this.spinner.hide();
            this.mainList = res.common.mainList.resultSet.data;
            this.noDataFound = this.mainList.length > 0 ? false : true;
            this.dataSource.data = this.mainList;
            this.checkBoxValue = this.dataSource.data.length;

            this.actions$.subscribe((x) => {
              if (x !== null && x !== undefined && x.length > 4) {
                this.moreActionCount = 3;
              }
            });

            const param = {
              pageSize: this.listParameters.pageSize,
              totalRecord: res.common.totalRecord,
              isHeaderChange: this.isHeaderChange,
            };
            this.triggerPaginationChange.next(param);
          });
      }
    } else {
      this.spinner.hide();
    }
  }

  isQuickAdd(event): void {
    if (event) {
      this.dashboard.getBankAccountList();
    }
  }

  getDataFromHeader(data: any): void {
    this.highlightRecord();

    if (data.search !== '') {
      this.ids = [];
    }
    data.ids = this.ids;
    if (data.search === '') {
      this.spinner.show();
    }
    this.isHeaderChange = true;
    this.listParameters = data;
    !this.listParameters.format
      ? this.getList(this.headerDetails.isRowHighlighted)
      : this.export(this.listParameters.format, this.listParameters.isPrint);
    this.cancelSelectionClick();
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }
}
