import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DataType, ModuleName, Modules, RoutingPath } from '@app/core/Enum';
import { ExportType, MainListParameters } from '@app/core/Models';
import { CommonService, ModulePermission } from '@app/core/Services';
import {
  CommonState,
  Export,
  GetReportDetailList,
  GetVatReportDetailList,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  ViewReceiptComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-report-detail-list',
  templateUrl: './report-detail-list.component.html',
  styleUrls: ['./report-detail-list.component.scss'],
})
export class ReportDetailListComponent implements OnInit {
  @Select(CommonState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  showPaginator = true;
  moduleslist = Modules;

  @Select(CommonState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.hasEditPermission)
  hasEditPermission$: Observable<boolean>;

  @Select(MenuState.hasDeletePermission)
  hasDeletePermission$: Observable<boolean>;

  @ViewChild(MatSort) sort: MatSort;

  listParameters: MainListParameters = new MainListParameters();

  totalSelectedRecords = 0;
  ids: Array<Guid>;

  mainList: any;
  moduleId: number;
  reportText: string;

  dataSource = new MatTableDataSource();
  dataType = DataType;
  store: Store;
  commonService: CommonService;
  router: Router;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;
  moduleEnum = Modules;
  noDataFound = false;

  accountId: Guid;
  triggerCustomerId: Subject<any> = new Subject<any>();

  subscriptionRouting: Subscription;
  triggerPaginationChange: Subject<any> = new Subject<any>();

  constructor(
    private injector: Injector,
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
    this.dialog = injector.get<MatDialog>(MatDialog);
  }

  ngOnInit(): void {
    this.setReportHeader();
    this.getList();

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.accountId = atob(params.get('id')!) as unknown as any;
          const moduleId = +atob(params.get('moduleId')!) as unknown as any;
          const param = {
            id: this.accountId,
            moduleId: moduleId,
          };
          setTimeout(() => {
            this.triggerCustomerId.next(param);
          }, 1000);

          this.getList();
        }
      }
    );
  }

  setReportHeader(): void {
    switch (this.moduleId) {
      case Modules.BalanceSheet:
        this.reportText = ModuleName.BalanceSheet;
        break;
      case Modules.StatementofFinancialActivities:
        this.reportText = ModuleName.StatementOfFinancialActivities;
        break;
    }
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0) {
      if (moduleId === Modules.AddCustomAccounts) {
        const params = { id: btoa(id), details: btoa('true') };
        this.router.navigate([RoutingPath.AccountDetails, params]);
      } else if (
        moduleId === Modules.Invoices ||
        moduleId === Modules.Bills ||
        moduleId === Modules.CreditNote ||
        moduleId === Modules.DebitNote ||
        moduleId === Modules.FixedAssets ||
        moduleId === Modules.Quotation ||
        moduleId === Modules.Journals ||
        moduleId === Modules.Receipt ||
        moduleId === Modules.Payment ||
        moduleId === Modules.Donations ||
        moduleId === Modules.FixedAssetDetail
      ) {
        const data = {
          moduleId,
          id,
        };

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {});
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }

  export(format: number, isPrint?: boolean): void {
    this.spinner.show();
    this.selectedIds();

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId,
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.ids,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      format: format,
      isPrint: isPrint,
    };

    this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList();
  }

  selectedIds(): void {
    this.ids = [];
    this.mainList.forEach((value) =>
      value.isSelected ? this.ids.push(value[0] ?? 0) : ''
    );
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  getParameter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  getList(): void {
    this.bindGrid(this.getParameter());
  }

  bindGrid(queryParams: any): void {
    this.store
      .dispatch(new GetReportDetailList(queryParams))
      .subscribe((res) => {
        this.mainList = res.common.mainList.resultSet.data;
        this.noDataFound = this.mainList.length > 0 ? false : true;
        this.dataSource.data = this.mainList;
        const param = {
          pageSize: this.listParameters.pageSize,
          totalRecord: res.common.totalRecord,
        };
        this.triggerPaginationChange.next(param);
      });
  }

  getDataFromHeader(data: any): void {
    if (data.search === '') {
      this.spinner.show();
    }

    this.listParameters = data;
    !this.listParameters.format
      ? this.getList()
      : this.export(this.listParameters.format, this.listParameters.isPrint);
  }

  getDataFromVatReport(data: any): void {
    this.listParameters.filter = data.filter;
    this.store
      .dispatch(new GetVatReportDetailList(data.id, this.getParameter()))
      .subscribe((res) => {
        this.mainList = res.common.mainList.resultSet.data;
        this.noDataFound = this.mainList.length > 0 ? false : true;
        this.dataSource.data = this.mainList;
      });
  }

  onCancel(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  downloadFile(event: any): void {
    const list: any[] = [];
    list.push(event);
    const param = {
      fileURLs: list,
    };
    this.commonService.downloadFile(param).subscribe();
  }
}
