export enum IncomeTypeName {
  IncomeDonationAndLegacies = 1,
  CharitableActivities = 2,
  Othertradingactivities = 3,
  Investments = 4,
  SeparateMaterialItemofIncome = 5,
  Other = 6,
  NetGainsLossesonInvestments = 7,
  GainsLossesonRevaluationofFixedAssets = 8,
  OtherGainsLosses = 9,
}
