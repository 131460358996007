export enum NotificationHeader {
  deleteConfirmation = 'Confirm Delete',
  disposedConfirmation = 'Confirm re-Activate Disposed',
  archiveConfirmation = 'Confirm Inactive',
  restoreConfirmation = 'Confirm Active',
  copyConfirmation = 'Confirm Copy',
  success = 'Success!',
  error = 'Error!',
  clearAllLinesHeaderText = 'Clear',
  navigateMeToGmail = 'Navigate me to gmail',
  navigateMeToOutlook = 'Navigate me to outlook',
  deleteYourAccount = 'Delete your account',
  reauthenticateYourAccount = 'Reauthenticate Your Account',
  confirmation = 'Confirmation',
  trialBalanceReferesh = 'Once refreshed, the changes cannot be reverted back.',
}

export enum NotificationDetails {
  archiveDetailText = 'Are you sure you want to inactive the data? ',
  restoreDetailText = 'Are you sure you want to active the data? ',
  deleteAllDetailText = 'Please confirm you want to delete these things. Deleting cannot be undone.',
  disposedDetailText = 'Are you sure to re-activate the disposed asset?',
  navigateMeToGmailText = 'Are you sure you want to navigate to your gmail account.',
  navigateMeToOutlookText = 'Are you sure you want to navigate to your outlook account.',
  deleteYourAccountText = 'Are you sure you want to delete your account?',
  mergeTrailBalanceText = 'Are you sure you want to merge the values in the same account?',
  trialBalanceErrorText = 'Following accounts does not have any values so we can not import in the <b> Trial Balance </b>.',
  deleteRow = 'Are you sure to delete row?',
  copyDetail = 'Are you sure you want to copy data from pervious year.',
  trialBalanceReferesh = 'Are you sure you want to refresh this Trial Balance?',
}

export enum NotificationAddClosePopUpMessage {
  defaultHeaderText = 'Terminate process',
  defaultBodyText = 'Sure, you want to terminate process.',
  defaultAnnualReportHeaderText = 'Confirmation',
  defaultAnnualReportBodyText = 'Do you want to save the report?',
  saveOpeningBalanceHeaderText = 'Confirmation',
  informHeaderText = 'Information',
  saveOpeningBalanceBodyText = 'This Opening Balance will get saved as draft as the amount in Debit and Credit Columns are not equal.',
  saveJournalBodyText = 'This journal will be saved as a draft because the amounts in the debit and credit columns are not equal. You must balance this journal for its proper impact on the final accounts.',
  informBodyText = 'All Explained value will be marked as a draft.',
  importBodyText = 'Sure, you want to ignore and continue.',
  saveWarningBodyText = 'Are you sure you want to navigate without saving the changes?',
}

export enum NotificationTextMessage {
  qtyErrorMessage = 'Quantity should be greater than 0',
  amtErrorMessage = 'Amount should be greater than 0',
  validPeriodDateMessage = 'Date should be between accounting period.',
  lockedAccoutingPeriodDateMessage = 'Accounting period is locked.',
  successMessage = 'The operation completed Successfully!',
  emailMessage = 'Email sent Successfully!',
  emailFailedMessage = 'Unable to send email. Please try again later.',
  errorMessage = 'There might be some error occured!',
  errorWrongMessage = 'Something went wrong. Please try again later.',
  bankAccountAlreadyExits = 'Bank account already exits!',
  recordCopySuccessfully = 'Record copied successfully',
  recordArchivedSuccessfully = 'Record inactivated successfully',
  recordRestoredSuccessfully = 'Record activated successfully',
  recordDeletedSuccessfully = 'Record deleted successfully',
  recordActivateSuccessfully = 'Record re-Activate successfully',
  noRecordFoundText = 'No record found.',
  amountErrorMessage = 'Entered Amount Exceed Limit',
  fileSizeExceeds = 'File should be less than 10 MB',
  filenameWithSpecialCharacter = 'File name should not contain special character',
  fileUploaded = 'File uploaded successfully',
  capiumSaveMessage = 'VAT return has been submitted to Capium successfully',
  vatTransactionImported = 'Vat transaction imported successfully',
  exceedDateMessage = 'End Date should not be more than one year',
  clearAllLinesBodyText = 'Sure, you want to clear all the lines.',
  explainOpeningBalance = 'Amount cannot be greater than total opening balance',
  mailDeleteSucessfully = 'Mail deleted successfully',
  moveToSuccessList = 'Record move to success list',
  invoiceNoIsExist = 'Invoice number is exists',
  accountCodeExist = 'The account code you entered already exists. Please choose a different account code to proceed.',
  succesList = 'No records are updated',
  mailSentSuccessfully = 'Mail Sent successfully',
  duplicateMail = 'to email, cc email or bcc email can not be same',
  invoiceNoValidateSuccessfully = 'Invoice number validated successfully',
  blankLineError = 'Please remove the blank line to save the data',
  noDataToExport = 'Sorry, There is no data to export.',
  checkAmountErrorMessage = 'Amount has to be greater than 0 to generate a Receipt.',
  checkAdvancePaymentErrorMessage = 'Entered total advance payment amount should be greater then Receipt.',
  transferErrorMessage = 'Sorry, cannot be transferred to the same account',
  noSelectedErrorMessage = 'Please select atleast one checkbox to depriciate assets!',
  dataInLockedPeriod = 'Sorry, Accounting period is locked for the selected date.',
  noSelectedBankErrorMessage = 'Please select atleast one checkbox to save changes.',
  accountErrorMessage = 'Please select account to save changes.',
  fileExtensionValidatonForImage = 'Uploaded file should be .png or .jpg or .jpeg extension',
  fileExtensionValidatonForPDF = 'File should be .pdf extension',
  pleaseAddSignature = 'Please add signature.',
  errorDebitCreditMessage = 'Debit amount and credit amount should be same to save changes.',
  amountTotalError = 'Entered total amount should be greater then import bank payment',
  receiptSelectError = 'No invoice available for receipt generation.',
  submittedToHMRC = 'Successfully submitted To HMRC.',
  netAmountDigitErrorMessage = 'Net Amount should not be than 14 digit before decimal dot',
  vatAmountDigitErrorMessage = 'Vat Amount should not be than 14 digit before decimal dot',
  amountDigitErrorMessage = 'Amount should not be than 14 digit before decimal dot',
  emptyBody = 'Empty email body is not allowed!',
  trailBalanceZeroValueValidation = 'Sorry you cannot merge this account as the amount gets zero after merging.',
  donorIsExist = 'Donor already exists',
  checkVatNumber = 'Please check your VAT Details.',
  invoiceReceiptAllocationMissing = 'Receipt is required for saving the invoice',
  invoiceAndReceiptAmountAllocationMissMatch = 'Receipt amount does not match the invoice amount',
  allocateFullReceipt = 'Please allocate full amount to save',
  fileSize5mb = 'File should be less than 5 MB',
  fileSize2mb = 'File should be less than 2 MB',
  fileJpgPng = 'File should be .png or .jpg extension',
  validationMessage = 'Please fill all the mandatory fields.',
  canNotEdit = 'This record can not be edited!',
  ViewOnlyAccessMessage = 'You have view only access for this section. Please contact your manager for edit access.',
  NoImportAccesssMessage = 'You do not have access for import this module. Please contact your manager for edit access.',
  NoRecordsFound = 'No records found',
  NoRecordsFoundForFilter = 'No records found for the given search/filter values',
  permissionChangeAndNotSave = 'Please save your permission changes to edit user permissions.',
  canNotDelete = 'You do not have permission to delete selected records',
  canNotInactive = 'You do not have permission to inactive selected records',
  alreadyInactive = 'Selected record has already inactive record',
  alreadyRestore = 'Selected record has already active record',
  trialBalanceRefereshSuccessfully = 'Trial Balance has been imported and saved successfully',
  totalIncomeErrorMessage = 'Total Income is not matching',
  totalExpenseErrorMessage = 'Total Expense is not matching',
  totalOpeningErrorMessage = 'Total Opening is not matching',
  totalGainLossErrorMessage = 'Total Gain/Loss is not matching',
  totalFundTransferErrorMessage = 'The sum of Fund Transfer In must be equal to Fund Transfer Out.',
}
