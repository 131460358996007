export enum PurchaseTypeName {
  RaisingFunds = 1,
  CharitableActivities = 2,
  //SeparateMaterialItemofExpense = 3,
  Other = 4,
  SupportCosts = 5,
  //FinanceCosts = 6,
  GovernanceCosts = 7,
  NetGainsLossesonInvestments = 8,
  ExtraordinaryItems = 9,
  GainsLossesonRevaluationofFixedAssets = 10,
  OtherGainsLosses = 11,
}
