import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  ControlType,
  HeaderFilter,
  Modules,
  NotificationTextMessage,
  ReportEnum,
  VatSettings,
} from '@app/core/Enum';
import {
  ExportType,
  GlobalComponent,
  HeaderModel,
  MainListParameters,
} from '@app/core/Models';
import { BankDasboardService, CommonService } from '@app/core/Services';
import {
  CheckVatNumber,
  CommonState,
  Export,
  GetReportDetailHeaderList,
  MenuState,
  SetDefaultVatId,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-report-detail-list-header',
  templateUrl: './report-detail-list-header.component.html',
  styleUrls: ['./report-detail-list-header.component.scss'],
})
export class ReportDetailListHeaderComponent implements OnInit {
  defaultValue: any[] = [
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
  ];

  @Output()
  readonly triggerDataFromHeader = new EventEmitter<any>();

  @Input() listParameters: any;

  @Select(MenuState.hasAddPermission)
  hasAddPermission$: Observable<boolean>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.menu)
  menu$: Observable<any>;

  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;

  controlType = ControlType;
  periodicDateList: any;

  @Select(CommonState.headerList)
  headerList$: Observable<Array<HeaderModel>>;

  customDate = false;

  HeaderFilters = HeaderFilter;
  moduleEnum = Modules;
  moduleId: number;
  headerData: MainListParameters = new MainListParameters();
  exportType = ExportType;
  modules = Modules;
  periodicId = '-1';
  moduleName = '';
  searchText = '';
  reportlist: any[] = [];
  private _moduleIdSubscription;
  isRowHighlighted: boolean = false;
  subscriptionRouting: Subscription;
  reportListId = Guid.EMPTY as unknown as Guid;

  constructor(
    public commonService: CommonService,
    private store: Store,
    private bankDasboardService: BankDasboardService,
    public dialog: MatDialog,
    private globalComponent: GlobalComponent,
    private _Activatedroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.reportListId = atob(params.get('id')!) as unknown as Guid;
        }
      }
    );

    for (let key in ReportEnum) {
      this.reportlist.push({ number: key, word: ReportEnum[key] });
    }

    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    this.store.dispatch(new GetReportDetailHeaderList());

    this.headerList$.subscribe((data) => {
      data.forEach((item) => {
        if (item.value?.length > 0 && item.name === HeaderFilter.Module) {
          this.commonService.defaultHeaderGuidValue = this.reportListId;
          this.onDropdownChange(item.name, this.reportListId, false);
        }
      });
    });

    this.customDate = false;
    this.moduleName$.subscribe((x) => {
      this.moduleName =
        x.toLocaleLowerCase() === VatSettings.VatSettings.toLocaleLowerCase()
          ? VatSettings.VatCodes[VatSettings.VatCodes.length - 1] === 's'
            ? VatSettings.VatCodes.substring(0, VatSettings.VatCodes.length - 1)
            : VatSettings.VatCodes
          : x[x.length - 1] === 's'
          ? x.substring(0, x.length - 1)
          : x;
    });
  }

  onAddClick(): void {
    this._moduleIdSubscription = this.moduleId$.subscribe((data: any) => {
      if (data !== Modules.BankDashboard && data !== Modules.SubmitVat) {
        this.commonService.onAddRouting(data);
      } else if (data === Modules.SubmitVat) {
        this.store.dispatch(new SetDefaultVatId());
        this.checkVatNumber(data);
      }
    });
    this._moduleIdSubscription.unsubscribe();
  }

  checkVatNumber(data: any): void {
    this.store.dispatch(new CheckVatNumber()).subscribe((res) => {
      if (res.submitVat.checkVatNumber) {
        this.commonService.onAddRouting(data);
      } else {
        this.commonService.onFailure(NotificationTextMessage.checkVatNumber);
      }
    });
  }

  dataChangeFromHeader(onLoad: boolean): void {
    const params = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      search: this.headerData.search,
      moduleId: this.headerData.moduleId,
      subModuleId: this.headerData.subModuleId,
      format: this.headerData.format,
      isPrint: this.headerData.isPrint,
      filter: this.headerData.filter,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: onLoad ? this.listParameters.sortBy : '',
      sortOrder: onLoad ? this.listParameters.sortOrder : true,
    };
    this.isRowHighlighted = onLoad;
    this.triggerDataFromHeader.emit(params);
  }

  onSearch(): void {
    this.headerData.search = this.searchText;
    this.dataChangeFromHeader(false);
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.onSearch();
    }
  }

  triggerDateChange(data: any): void {
    if (data.value === '-1') {
      this.headerData.startDate = '';
      this.headerData.endDate = '';
    } else {
      this.headerData.startDate = data.startDate;
      this.headerData.endDate = data.endDate;
    }

    this.dataChangeFromHeader(false);
  }

  export(format: number, isPrint: boolean): void {
    const params = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      filter: this.listParameters.filter,
      search: this.headerData.search,
      moduleId: this.headerData.moduleId,
      subModuleId: this.headerData.subModuleId,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      format: format,
      isPrint: isPrint,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      ids: [],
    };

    this.moduleId$.subscribe((data) => {
      this.moduleId = data;
    });

    if (this.moduleId === Modules.SalesDayBook) {
      this.store.dispatch(new Export(params, this.moduleId)).subscribe();
    } else {
      this.triggerDataFromHeader.emit(params);
    }
  }

  onDropdownChange(headerName: any, value: any, onLoad: boolean): void {
    if (headerName === HeaderFilter.Filter) {
      this.headerData.filter = value;
    }
    if (headerName === HeaderFilter.Module) {
      this.headerData.moduleId = value;
    }
    if (headerName === HeaderFilter.SubModule) {
      this.headerData.subModuleId = value;
    }

    this.listParameters.pageNumber = 1;
    this.dataChangeFromHeader(onLoad);
  }
}
