<form [formGroup]="note40Form">
  <div class="notes-wrapper">
    <div class="notes-section">
      <p class="fw-bold notes-heading">
        Details of material funds held and movements during the CURRENT
        reporting period
      </p>
      <table class="notes-table" aria-describedby="User Task Table">
        <tr>
          <th rowspan="2" scope="col">Fund names</th>
          <th scope="col">Fund balances brought forward</th>
          <th scope="col">Income</th>
          <th scope="col">Expenditure</th>
          <th scope="col">Transfers</th>
          <th scope="col">Gains and losses</th>
          <th scope="col">Fund balances carried forward</th>
        </tr>
        <tr>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
        </tr>
        <ng-container
          *ngIf="calculativeData?.fundDataCurrentPeriod?.length === 0"
        >
          <tr>
            <td colspan="9" class="text-align-center mt-1 fw-bold">
              <span>No records found</span>
            </td>
          </tr>
        </ng-container>
        <ng-container
          *ngIf="calculativeData?.fundDataCurrentPeriod?.length > 0"
        >
          <ng-container
            *ngIf="
              filterItems(
                calculativeData?.fundDataCurrentPeriod,
                'unrestricted'
              ).length > 0
            "
          >
            <tr>
              <td class="fw-bold text-align-left" colspan="7">
                Unrestricted funds
              </td>
            </tr>
            <tr
              *ngFor="
                let item of filterItems(
                  calculativeData?.fundDataCurrentPeriod,
                  'unrestricted'
                )
              "
            >
              <td class="text-align-left">{{ item.name }}</td>
              <td class="fw-bold">
                {{ item.balanceBroughtForward | numberPipe }}
              </td>
              <td class="fw-bold">{{ item.income | numberPipe }}</td>
              <td class="fw-bold">{{ item.expenditure | numberPipe }}</td>
              <td class="fw-bold">{{ item.transfers | numberPipe }}</td>
              <td class="fw-bold">{{ item.gainAndLoss | numberPipe }}</td>
              <td class="fw-bold">
                {{ item.carriedForward | numberPipe }}
              </td>
            </tr>
          </ng-container>
          <ng-container
            *ngIf="
              filterItems(calculativeData?.fundDataCurrentPeriod, 'restricted')
                .length > 0
            "
          >
            <tr>
              <td class="fw-bold text-align-left" colspan="7">
                Restricted Income funds
              </td>
            </tr>
            <tr
              *ngFor="
                let item of filterItems(
                  calculativeData?.fundDataCurrentPeriod,
                  'restricted'
                )
              "
            >
              <td class="text-align-left">{{ item.name }}</td>
              <td class="fw-bold">
                {{ item.balanceBroughtForward | numberPipe }}
              </td>
              <td class="fw-bold">{{ item.income | numberPipe }}</td>
              <td class="fw-bold">{{ item.expenditure | numberPipe }}</td>
              <td class="fw-bold">{{ item.transfers | numberPipe }}</td>
              <td class="fw-bold">{{ item.gainAndLoss | numberPipe }}</td>
              <td class="fw-bold">
                {{ item.carriedForward | numberPipe }}
              </td>
            </tr>
          </ng-container>
          <ng-container
            *ngIf="
              filterItems(calculativeData?.fundDataCurrentPeriod, 'endowment')
                .length > 0
            "
          >
            <tr>
              <td class="fw-bold text-align-left" colspan="7">
                Endowment funds
              </td>
            </tr>
            <tr
              *ngFor="
                let item of filterItems(
                  calculativeData?.fundDataCurrentPeriod,
                  'endowment'
                )
              "
            >
              <td class="text-align-left">{{ item.name }}</td>
              <td class="fw-bold">
                {{ item.balanceBroughtForward | numberPipe }}
              </td>
              <td class="fw-bold">{{ item.income | numberPipe }}</td>
              <td class="fw-bold">{{ item.expenditure | numberPipe }}</td>
              <td class="fw-bold">{{ item.transfers | numberPipe }}</td>
              <td class="fw-bold">{{ item.gainAndLoss | numberPipe }}</td>
              <td class="fw-bold">
                {{ item.carriedForward | numberPipe }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </div>
    <div class="notes-section">
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox1"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
    <div class="notes-section">
      <p class="fw-bold notes-heading">
        Details of material funds held and movements during the PREVIOUS
        reporting period
      </p>
      <table class="notes-table" aria-describedby="Note Table">
        <tr>
          <th rowspan="2" scope="col">Fund names</th>
          <th scope="col">Fund balances brought forward</th>
          <th scope="col">Income</th>
          <th scope="col">Expenditure</th>
          <th scope="col">Transfers</th>
          <th scope="col">Gains and losses</th>
          <th scope="col">Fund balances carried forward</th>
        </tr>
        <tr>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
          <th scope="col" class="fw-bold">£</th>
        </tr>
        <ng-container
          *ngIf="calculativeData?.fundDataPreviousPeriod?.length === 0"
        >
          <tr>
            <td colspan="9" class="text-align-center mt-1 fw-bold">
              <span>No records found</span>
            </td>
          </tr>
        </ng-container>
        <ng-container
          *ngIf="calculativeData?.fundDataPreviousPeriod?.length > 0"
        >
          <ng-container
            *ngIf="
              filterItems(
                calculativeData?.fundDataPreviousPeriod,
                'unrestricted'
              ).length > 0
            "
          >
            <tr>
              <td class="fw-bold text-align-left" colspan="7">
                Unrestricted funds
              </td>
            </tr>
            <tr
              *ngFor="
                let item of filterItems(
                  calculativeData?.fundDataPreviousPeriod,
                  'unrestricted'
                )
              "
            >
              <td class="text-align-left">{{ item.name }}</td>
              <td class="fw-bold">
                {{ item.balanceBroughtForward | numberPipe }}
              </td>
              <td class="fw-bold">{{ item.income | numberPipe }}</td>
              <td class="fw-bold">{{ item.expenditure | numberPipe }}</td>
              <td class="fw-bold">{{ item.transfers | numberPipe }}</td>
              <td class="fw-bold">{{ item.gainAndLoss | numberPipe }}</td>
              <td class="fw-bold">
                {{ item.carriedForward | numberPipe }}
              </td>
            </tr>
          </ng-container>
          <ng-container
            *ngIf="
              filterItems(calculativeData?.fundDataPreviousPeriod, 'restricted')
                .length > 0
            "
          >
            <tr>
              <td class="fw-bold text-align-left" colspan="7">
                Restricted Income funds
              </td>
            </tr>
            <tr
              *ngFor="
                let item of filterItems(
                  calculativeData?.fundDataPreviousPeriod,
                  'restricted'
                )
              "
            >
              <td class="text-align-left">{{ item.name }}</td>
              <td class="fw-bold">
                {{ item.balanceBroughtForward | numberPipe }}
              </td>
              <td class="fw-bold">{{ item.income | numberPipe }}</td>
              <td class="fw-bold">{{ item.expenditure | numberPipe }}</td>
              <td class="fw-bold">{{ item.transfers | numberPipe }}</td>
              <td class="fw-bold">{{ item.gainAndLoss | numberPipe }}</td>
              <td class="fw-bold">
                {{ item.carriedForward | numberPipe }}
              </td>
            </tr>
          </ng-container>
          <ng-container
            *ngIf="
              filterItems(calculativeData?.fundDataPreviousPeriod, 'endowment')
                .length > 0
            "
          >
            <tr>
              <td class="fw-bold text-align-left" colspan="7">
                Endowment funds
              </td>
            </tr>
            <tr
              *ngFor="
                let item of filterItems(
                  calculativeData?.fundDataPreviousPeriod,
                  'endowment'
                )
              "
            >
              <td class="text-align-left">{{ item.name }}</td>
              <td class="fw-bold">
                {{ item.balanceBroughtForward | numberPipe }}
              </td>
              <td class="fw-bold">{{ item.income | numberPipe }}</td>
              <td class="fw-bold">{{ item.expenditure | numberPipe }}</td>
              <td class="fw-bold">{{ item.transfers | numberPipe }}</td>
              <td class="fw-bold">{{ item.gainAndLoss | numberPipe }}</td>
              <td class="fw-bold">
                {{ item.carriedForward | numberPipe }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </div>
    <div class="notes-section">
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox2"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
    <div class="notes-section">
      <p class="fw-bold notes-heading">Transfers between funds</p>
      <p class="fw-bold">This year</p>
      <table class="notes-table" aria-describedby="Note Table">
        <tr>
          <th scope="col"></th>
          <th scope="col">Amount</th>
        </tr>
        <tr>
          <td class="text-align-left">
            Between unrestricted and restricted funds
          </td>
          <td>
            {{
              calculativeData?.fundTransferDataCurrentPeriod?.amount1
                | numberPipe
            }}
          </td>
        </tr>
        <tr>
          <td class="text-align-left">
            Between endowment and restricted funds
          </td>
          <td>
            {{
              calculativeData?.fundTransferDataCurrentPeriod?.amount2
                | numberPipe
            }}
          </td>
        </tr>
        <tr>
          <td class="text-align-left">
            Between endowment and unrestricted funds
          </td>
          <td>
            {{
              calculativeData?.fundTransferDataCurrentPeriod?.amount3
                | numberPipe
            }}
          </td>
        </tr>
      </table>
      <br />
      <p class="fw-bold">Last year</p>
      <table class="notes-table" aria-describedby="Note Table">
        <tr>
          <th scope="col"></th>
          <th scope="col">Amount</th>
        </tr>
        <tr>
          <td class="text-align-left">
            Between unrestricted and restricted funds
          </td>
          <td>
            {{
              calculativeData?.fundTransferDataPreviousPeriod?.amount1
                | numberPipe
            }}
          </td>
        </tr>
        <tr>
          <td class="text-align-left">
            Between endowment and restricted funds
          </td>
          <td>
            {{
              calculativeData?.fundTransferDataPreviousPeriod?.amount2
                | numberPipe
            }}
          </td>
        </tr>
        <tr>
          <td class="text-align-left">
            Between endowment and unrestricted funds
          </td>
          <td>
            {{
              calculativeData?.fundTransferDataPreviousPeriod?.amount3
                | numberPipe
            }}
          </td>
        </tr>
      </table>
    </div>
    <div class="notes-section">
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox3"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
    <div class="notes-section">
      <p class="fw-bold notes-heading">Designated funds</p>
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox4"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
  </div>
</form>
