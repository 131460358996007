import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AddClosePopupComponent } from '@app/modules/common';

import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { FundTransferModel } from '@app/core/Models';

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@app/core/Services';
import {
  CreateFundTransfer,
  GetDataByFundTransferId,
  MenuState,
} from '@app/core/Store';
import { AddFundTransferComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-fund-transfer',
  templateUrl: './fund-transfer.component.html',
  styleUrls: ['./fund-transfer.component.scss'],
})
export class FundTransferComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  fundTransferId = Guid.EMPTY as unknown as Guid;
  subscriptionRouting: Subscription;

  moduleId = Modules.FundTransfers;
  moduleName = ModuleName.FundTransfers;

  fundTransferData: FundTransferModel;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  triggerEditData: Subject<any> = new Subject<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddFundTransferComponent, { static: true })
  fundTransferDetails;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private store: Store,
    public commonService: CommonService,
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.fundTransferId = atob(params.get('id')!) as unknown as Guid;
          this.editFundTransfer();
        }
      }
    );
  }

  editFundTransfer(): void {
    this.store
      .dispatch(new GetDataByFundTransferId(this.fundTransferId))
      .subscribe((res) => {
        this.fundTransferDetails.fundTransferForm.markAsUntouched();
        this.triggerEditData.next(res.fundTransfer.fundTransferData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.fundTransferId,
      isExit,
      Modules.FundTransfers,
      RoutingPath.AddFundTransfer
    );
  }

  onSave(isExit: boolean): void {
    if (
      this.fundTransferDetails.fundTransferForm.controls.transferFrom.value !==
      this.fundTransferDetails.fundTransferForm.controls.transferTo.value
    ) {
      if (this.fundTransferDetails.fundTransferForm.invalid) {
        this.commonService.addValidation(
          this.fundTransferDetails.fundTransferForm,
          this.renderer
        );
      } else {
        if (this.dataSubmit()) {
          if (this.fundTransferData) {
            this.store
              .dispatch(new CreateFundTransfer(this.fundTransferData))
              .pipe()
              .subscribe(
                (res) => {
                  if (res !== undefined) {
                    this.setHighlightData(isExit);

                    if (!isExit) {
                      this.onCancel(false);
                      this.reloadSideList.emit();
                    } else {
                      this.onCancel(false);
                      this.location.back();
                    }
                    this.commonService.onSucess(
                      NotificationTextMessage.successMessage
                    );
                  } else {
                    this.commonService.onFailure(
                      NotificationTextMessage.errorMessage
                    );
                  }
                },
                (err) => {
                  this.commonService.onFailure(err.error.Message);
                }
              );
          }
        }
      }
    } else {
      this.fundTransferDetails.fundTransferForm.controls.transferFrom.markAsTouched();
      this.fundTransferDetails.fundTransferForm.controls.transferTo.markAsTouched();
      this.commonService.onFailure(
        NotificationTextMessage.transferErrorMessage
      );
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.fundTransferData = {
        id: this.fundTransferId,
        transferDate:
          this.fundTransferDetails.fundTransferForm.controls.transferDate.value,
        amount: this.fundTransferDetails.fundTransferForm.controls.amount.value,
        fromFundId:
          this.fundTransferDetails.fundTransferForm.controls.transferFrom.value,
        toFundId:
          this.fundTransferDetails.fundTransferForm.controls.transferTo.value,
        note: this.fundTransferDetails.fundTransferForm.controls.note.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.fundTransferId)) {
      this.editFundTransfer();
    } else {
      this.fundTransferId = Guid.EMPTY as unknown as Guid;
      this.fundTransferDetails.ngOnInit();
    }
  }

  triggerSaveAllTabs(): void {
    this.reloadSideList.emit();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
