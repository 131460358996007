<div id="chart" *ngIf="!isNoRecord && isloadData">
  <apx-chart
    [series]="flowChartOptionsv1?.series!"
    [chart]="flowChartOptionsv1?.chart!"
    [dataLabels]="flowChartOptionsv1?.dataLabels!"
    [plotOptions]="flowChartOptionsv1?.plotOptions!"
    [yaxis]="flowChartOptionsv1?.yaxis!"
    [legend]="flowChartOptionsv1?.legend!"
    [fill]="flowChartOptionsv1?.fill!"
    [stroke]="flowChartOptionsv1?.stroke!"
    [tooltip]="flowChartOptionsv1?.tooltip!"
    [xaxis]="flowChartOptionsv1?.xaxis!"
    [noData]="flowChartOptionsv1?.noData!"
    [grid]="flowChartOptionsv1.grid!"
    [labels]="flowChartOptionsv1?.labels!"
  ></apx-chart>
</div>
<div class="norecord-chart" *ngIf="isNoRecord && isloadData">
  <img src="assets/images/column-icon.svg" alt="Bar Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
