import { Guid } from 'guid-typescript';

export class RecurringDonationModel {
  id: Guid;
  companyId?: Guid;
  donorId: Guid;
  sponsorEventId?: Guid;
  activityId?: Guid;
  fundId: Guid;
  depositToAccountId: Guid;
  depositAmount: number;
  documentNumber?: string;
  reference?: string;
  donationDate?: string;
  incomeAccount: Guid;
  donationAmount: number;
  feeExpenseAccount: Guid;
  feeAmount: number;
  benefitAmountToDonor: number;
  note: string;
  attachment?: Array<string>;
  dueTermId: Guid;
  startDate?: string;
  endDate?: string;
  maxNumOfOccurrences?: number;
  recurringTypeId: number;
  dayOfWeek?: number;
  dayOfMonth?: number;
  weekOfMonth?: number;
  monthOfYear?: number;
  separationCount?: number;
  lastRecurrenceDate?: Date;
  nextRecurrenceDate?: Date;
  noOfRecurrence?: number;
  recurringEndTypeId?: number;
  subRecurringTypeId?: number;
}
