import { PaymentDetailsModel, ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class FixedAssetsModel {
  id: Guid;
  accountId: Guid;
  bookAccountId: Guid;
  fundId: Guid;
  referenceId?: Guid;
  entryNumber: string;
  entryDate?: string;
  dueDate?: string;
  currencyId: number;
  note: string;
  attachment: Array<string>;
  items: Array<ProductDetailsModel>;
  receiptDetails: Array<PaymentDetailsModel>;
  advanceReceiptAmount?: number;
  isVatIncluded: boolean;
}
