<div id="chart" *ngIf="!isNoRecord && isloadData">
  <apx-chart
    [series]="chartOptionv1?.series!"
    [chart]="chartOptionv1?.chart!"
    [labels]="chartOptionv1?.labels!"
    [tooltip]="chartOptionv1?.tooltip!"
    [responsive]="chartOptionv1?.responsive!"
    [legend]="chartOptionv1?.legend!"
    [plotOptions]="chartOptionv1.plotOptions!"
    [fill]="chartOptionv1?.fill!"
    [noData]="chartOptionv1?.noData!"
    [dataLabels]="chartOptionv1.dataLabels!"
    [xaxis]="chartOptionv1.xaxis!"
    [yaxis]="chartOptionv1.yaxis!"
    [grid]="chartOptionv1.grid!"
    [lines]="chartOptionv1.lines!"
  ></apx-chart>
</div>

<div class="norecord-chart" *ngIf="isNoRecord && isloadData">
  <img src="assets/images/column-icon.svg" alt="Bar Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
