import { DatePipe } from '@angular/common';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionType,
  ComponentName,
  ConfirmationType,
  DataType,
  FilteredStatus,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ExportFormat } from '@app/core/Enum/export-type';
import {
  ExportType,
  MainListParameters,
  MenuModel,
  PermissionModel,
  ViewParamModel,
} from '@app/core/Models';
import {
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  ConvertToInvoice,
  Copy,
  Export,
  ExportReceipt,
  GetReportList,
  MenuState,
  SendEmail,
  SetModulePermission,
  SubmitToHMRC,
} from '@app/core/Store';
import {
  AddAllocateRefundPopupComponent,
  AddReceiptPaymentPopupComponent,
  ConfirmationBoxComponent,
  ConvertToInvoicePopupComponent,
  EditAssetDetailComponent,
  EditDisposeAssetComponent,
  EmailPopupComponent,
  MatchJournalsComponent,
  RollBackPopupComponent,
  TransactionHeaderComponent,
  ViewReceiptComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-report-list-with-actions',
  templateUrl: './report-list-with-actions.component.html',
  styleUrls: ['./report-list-with-actions.component.scss'],
})
export class ReportListWithActionsComponent implements OnInit {
  @Select(CommonState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
      alignment: string;
    }>
  >;

  isRowHighlighted = false;

  showPaginator = true;
  checkBoxValue = 0;
  filteredStatus = FilteredStatus;

  @Select(CommonState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.reportActions)
  actions$: Observable<any>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  listParameters: MainListParameters = new MainListParameters();
  totalSelectedRecords = 0;
  isAllSelected = false;
  isExpanded = true;
  noDataFound = false;
  ids: Array<Guid>;
  selectedRowData: Array<any> = [];
  mainList: any;
  dataSource = new MatTableDataSource();
  dataType = DataType;
  moduleId: number;
  moduleEnum = Modules;
  actionTypeEnum = ActionType;
  selectedRowIndex = -1;
  moreActionCount = 4;
  moduleName: string;

  triggerPaginationChange: Subject<any> = new Subject<any>();
  triggerSelectedRowData: Subject<any> = new Subject<any>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.hasEditPermission)
  hasEditPermission$: Observable<boolean>;

  @Select(MenuState.hasDeletePermission)
  hasDeletePermission$: Observable<boolean>;

  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @ViewChild(MatSort) sort: MatSort;

  dialog: MatDialog;
  store: Store;
  notify: NotificationService;
  commonService: CommonService;
  router: Router;
  highlightRow: HighlightRow;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;
  _Activatedroute: ActivatedRoute;
  showTransactionDetails = false;
  @ViewChild(TransactionHeaderComponent, { static: true })
  headerDetails;

  subscriptionRouting: Subscription;

  receiptList: any[] = [];
  paymentList: any[] = [];

  @Input() triggerGiftAidData: Observable<any>;
  statusColIndex = 0;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isReceiptChangePermission: boolean = true;
  isPaymentChangePermission: boolean = true;
  constructor(private injector: Injector) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.paymentList = this.commonService.paymentList;
    this.receiptList = this.commonService.receiptList;
  }

  ngOnInit(): void {
    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
      if (
        (value.data.url === this.router.url.slice(1) &&
          value.componentName ===
            ComponentName.ReportListWithActionsComponent) ||
        value.componentName === ComponentName.VatSettings ||
        value.componentName === ComponentName.fixedAssetRegister
      ) {
        this.moduleName = this.commonService.getModuleName(this.moduleId);
      }
    });

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.showTransactionDetails = JSON.parse(
            atob(params.get('details')!)
          );
          if (this.showTransactionDetails) {
            const id = atob(params.get('id')!) as unknown as any;
            this.listParameters.moduleId = id;
            this.moduleId = +atob(params.get('moduleId')!) as unknown as any;
            this.moduleName = this.commonService.getModuleName(this.moduleId);
            this.headerDetails.ngOnInit();
          }
        }
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isReceiptChangePermission = this.commonService.checkPermission(
        Modules.Income,
        Modules.Receipt
      );
      this.isPaymentChangePermission = this.commonService.checkPermission(
        Modules.Expenditure,
        Modules.Payment
      );
    }, 3000);
  }

  highlightRecord(): void {
    if (this.highlightRow.mainListHighlighted.moduleId! > 0) {
      this.listParameters.sortBy = this.highlightRow.mainListHighlighted.sortBy;
      this.listParameters.sortOrder = false;
    }
  }

  selectAll(event: any): void {
    this.mainList.forEach(
      (x) =>
        (x.isSelected = !this.isCheckboxDisabled(x) ? event.checked : false)
    );
    this.showFooter();
  }

  showFooter(): void {
    this.selectedIds();
    this.totalSelectedRecords = this.ids.length;

    setTimeout(() => {
      this.triggerSelectedRowData.next(this.selectedRowData);
    }, 100);
  }

  deleteClick(): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                type: ConfirmationType.Delete,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: NotificationHeader.deleteConfirmation,
                detailText: NotificationDetails.deleteAllDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();
    this.selectedIds();
    if (
      (this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.Bills ||
        this.moduleId === Modules.Quotation ||
        this.moduleId === Modules.CreditNote ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.FixedAssets ||
        this.moduleId === Modules.Journals ||
        this.moduleId === Modules.Receipt ||
        this.moduleId === Modules.Payment ||
        this.moduleId === Modules.BridgingVAT ||
        this.moduleId === Modules.Donations ||
        this.moduleId === Modules.FixedAssetsRegister) &&
      format === ExportFormat.PDF &&
      this.ids.length > 0
    ) {
      this.store
        .dispatch(new ExportReceipt(this.moduleId, this.ids, isPrint))
        .subscribe();
      this.cancelSelectionClick();
    } else {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId: this.listParameters.moduleId,
        subModuleId: this.listParameters.subModuleId ?? -1,
        ids: this.ids,
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
      this.cancelSelectionClick();
    }
  }

  copyClick(): void {
    this.spinner.show();

    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (isValidPeriod) {
          this.store
            .dispatch(new Copy(this.moduleId, this.ids))
            .subscribe((res) => {
              if (res.common.isCopied) {
                this.notify.success(
                  NotificationHeader.success,
                  NotificationTextMessage.recordCopySuccessfully
                );

                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.mainList?.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;

    this.getList(false);
  }

  onAddClick(element: any): void {
    this.router.navigate([
      RoutingPath.AddCustomAccounts,
      { accountId: btoa(element[0]) },
    ]);
  }

  archiveAndRestoreClick(isArchive: any): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                isArchive,
                type: ConfirmationType.Archive,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: isArchive
                  ? NotificationHeader.archiveConfirmation
                  : NotificationHeader.restoreConfirmation,
                detailText: isArchive
                  ? NotificationDetails.archiveDetailText
                  : NotificationDetails.restoreDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.ids = [];

                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  selectedIds(): void {
    this.ids = [];
    this.selectedRowData = [];

    this.mainList.forEach((value) => {
      if (value.isSelected) {
        if (this.moduleId !== Modules.FixedAssetsRegister) {
          this.ids.push(value[0].rowData);
          this.selectedRowData.push(value[this.mainList[0].length - 1].rowData);
        } else {
          this.ids.push(value[6].rowData);
          this.selectedRowData.push(value[this.mainList[6].length - 1].rowData);
        }
      }
    });
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;

      this.getList(false);
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;

    this.getList(false);
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    this.isAllSelected = this.mainList.every(
      (item: any) => item.isSelected === true
    );
    this.showFooter();
  }

  onDeleteClick(id: any): void {
    this.ids = [];
    this.ids.push(id);
    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList(false);
          }
        });
    }
  }

  onButtonClick(id: any, actionType: ActionType): void {
    this.ids = [];
    this.ids.push(id.rowData);

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (
          isValidPeriod ||
          actionType === ActionType.Email ||
          actionType === ActionType.Export ||
          actionType === ActionType.View
        ) {
          switch (actionType) {
            case ActionType.Update:
              this.commonService.onEditRouting(true, this.moduleId, id.rowData);
              break;

            case ActionType.Delete:
              this.onDeleteClick(id.rowData);
              break;

            case ActionType.Export:
              this.ids = [];
              this.ids.push(id.rowData);
              this.store
                .dispatch(new ExportReceipt(this.moduleId, this.ids, false))
                .subscribe();
              break;

            case ActionType.View:
              const data: ViewParamModel = {
                moduleId: this.moduleId,
                id: id.rowData,
              };

              this.dialog
                .open(ViewReceiptComponent, {
                  data,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result) => {});
              break;

            case ActionType.Email:
              this.store
                .dispatch(new SendEmail(this.moduleId, id.rowData))
                .subscribe((x) => {
                  if (x.common.isMailSent) {
                    this.notify.success(
                      NotificationHeader.success,
                      NotificationTextMessage.emailMessage
                    );
                  } else {
                    this.notify.error(
                      NotificationHeader.error,
                      NotificationTextMessage.emailFailedMessage
                    );
                  }
                });
              break;

            case ActionType.AddReceipt:
              const params = {
                isReceipt: true,
                id: id.rowData,
              };
              this.dialog
                .open(AddReceiptPaymentPopupComponent, {
                  data: params,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList(false);
                  }
                });
              break;

            case ActionType.AddPayment:
              const param = {
                isReceipt: false,
                id: id.rowData,
              };
              this.dialog
                .open(AddReceiptPaymentPopupComponent, {
                  data: param,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList(false);
                  }
                });
              break;

            case ActionType.AllocateRefund:
              const parameter = {
                moduleId: this.moduleId,
                id: id.rowData,
              };
              this.dialog
                .open(AddAllocateRefundPopupComponent, {
                  data: parameter,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList(false);
                  }
                });
              break;

            case ActionType.CreateInvoice:
              this.spinner.show();
              this.convertToInvoice(id.rowData);
              break;
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  getList(isRowHighlighted: boolean): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    this.spinner.show();
    this.isRowHighlighted = isRowHighlighted;
    this.headerDetails.isRowHighlighted = isRowHighlighted;

    if (
      this.moduleId !== null &&
      this.moduleId !== undefined &&
      this.moduleId !== Modules.Dashboard
    ) {
      this.store
        .dispatch(new GetReportList(queryParams, this.moduleId))
        .subscribe((res) => {
          this.highlightRow.mainListHighlighted.moduleId = 0;
          this.spinner.hide();

          if (this.actions$ !== null || this.actions$ !== undefined) {
            this.actions$.subscribe((x) => {
              if (x.length > 4) {
                this.moreActionCount = 3;
              }
            });
          }

          if (
            this.moduleId === this.moduleEnum.Invoices ||
            this.moduleId === this.moduleEnum.Bills ||
            this.moduleId === this.moduleEnum.CreditNote ||
            this.moduleId === this.moduleEnum.DebitNote
          ) {
            const columnName =
              this.moduleId === this.moduleEnum.Invoices
                ? 'Invoice Status'
                : this.moduleId === this.moduleEnum.Bills
                ? 'Bill Status'
                : 'Allocation Status';
            this.statusColIndex =
              res.common.mainList.resultSet.columns.findIndex(
                (x) => x.name === columnName
              );
          }

          this.mainList = res.common.mainList.resultSet.data;
          this.noDataFound = this.mainList.length > 0 ? false : true;
          this.dataSource.data = this.mainList;
          this.checkBoxValue = this.dataSource.data.length;
          const param = {
            pageSize: this.listParameters.pageSize,
            totalRecord: res.common.totalRecord,
          };
          this.triggerPaginationChange.next(param);
        });
    } else {
      this.spinner.hide();
    }
  }

  getDataFromHeader(data: any): void {
    this.highlightRecord();

    if (data.search !== '') {
      this.ids = [];
    }
    data.ids = this.ids;
    if (data.search === '') {
      this.spinner.show();
    }
    if (this.moduleId === Modules.BankImportTransactionHistory) {
      data.moduleId = this.listParameters.moduleId;
    }

    this.listParameters = data;
    !this.listParameters.format
      ? this.getList(this.headerDetails.isRowHighlighted)
      : this.export(this.listParameters.format, this.listParameters.isPrint);
    this.cancelSelectionClick();
  }

  getDataFromDonationGiftAid(data: any): void {
    this.isExpanded = data.isExpanded;
    this.listParameters.moduleId = data.id;
    this.listParameters.startDate = data[0].startDate;
    this.listParameters.endDate = data[0].endDate;

    this.getList(false);
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  receiptPaymentChange(event: any, data: any): void {
    if (event.moduleId !== Modules.MatchJournal) {
      this.commonService.onEditRouting(
        false,
        event.moduleId,
        data[0].rowData,
        true,
        undefined,
        true
      );
    } else {
      const params = {
        transactionId: btoa(data[0].rowData),
      };
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = params;
      this.dialog
        .open(MatchJournalsComponent, dialogConfig)
        .afterClosed()
        .subscribe((result) => {
          this.getList(false);
        });
    }
  }

  downloadFile(event: any): void {
    const list: any[] = [];
    list.push(event);
    const param = {
      fileURLs: list,
    };
    this.commonService.downloadFile(param).subscribe();
  }

  onSubmitToHMRC(id: any): void {
    this.store.dispatch(new SubmitToHMRC(id)).subscribe((res) => {
      if (res.giftAid.isSubmittedToHMRC) {
        this.commonService.onSucess(NotificationTextMessage.submittedToHMRC);
        this.getList(false);
      }
    });
  }

  setPermission(url: any, id: any): void {
    setTimeout(() => {
      this.menuList$.subscribe((data) => {
        this.setData(url, data, ComponentName.ReportListComponent, id);
      });
    }, 500);
  }

  emitPermissionData(data: any, componentName: any, id: any): void {
    const permissionData = new PermissionModel();
    permissionData.data = data;
    permissionData.componentName = componentName;

    this.modulePermission.permissionData.next(permissionData);
  }

  setData(event: any, data: any, componentName: any, id: any): void {
    data.forEach((x) => {
      if (x.url === event.slice(1) || x.addUrl === event.slice(1)) {
        this.store.dispatch(new SetModulePermission(x)).subscribe();
        this.emitPermissionData(x, componentName, id);
      } else {
        x.subMenu.map((y) => {
          if (
            y.url === event.slice(1) ||
            y.addUrl === event.slice(1).split(';')[0]
          ) {
            this.store.dispatch(new SetModulePermission(y)).subscribe();
            this.emitPermissionData(y, componentName, id);
          } else {
            y.subMenu.map((z) => {
              if (
                z.url === event.slice(1) ||
                z.addUrl === event.slice(1).split(';')[0]
              ) {
                this.store.dispatch(new SetModulePermission(z)).subscribe();
                this.emitPermissionData(z, componentName, id);
              } else {
                z.subMenu.map((z1) => {
                  if (
                    z1.url === event.slice(1) ||
                    z1.addUrl === event.slice(1).split(';')[0]
                  ) {
                    this.store
                      .dispatch(new SetModulePermission(z1))
                      .subscribe();
                    this.emitPermissionData(z1, componentName, id);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0) {
      if (moduleId === Modules.FundList) {
        this.commonService.onEditRouting(true, Modules.ReportDetailsList, id);
      } else if (
        moduleId === Modules.Customers ||
        moduleId === Modules.Suppliers ||
        moduleId === Modules.Donors
      ) {
        this.commonService.onEditRouting(true, moduleId, id);
      } else if (moduleId === Modules.AddCustomAccounts) {
        this.commonService.onEditRouting(
          true,
          Modules.ReportAccountDetail,
          id,
          false
        );
        this.setPermission(RoutingPath.ReportAccountDetailsPermission, id);
      } else if (
        moduleId === Modules.Invoices ||
        moduleId === Modules.Bills ||
        moduleId === Modules.CreditNote ||
        moduleId === Modules.DebitNote ||
        moduleId === Modules.FixedAssets ||
        moduleId === Modules.Quotation ||
        moduleId === Modules.Journals ||
        moduleId === Modules.Receipt ||
        moduleId === Modules.Payment ||
        moduleId === Modules.Donations ||
        moduleId === Modules.FixedAssetDetail
      ) {
        const data = {
          moduleId,
          id,
        };

        let panelClass: string;

        if (moduleId === Modules.FixedAssetDetail) {
          panelClass = 'fixed-assest-detail';
        } else {
          panelClass = 'view-receipt';
        }

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
            panelClass: panelClass,
          })
          .afterClosed()
          .subscribe((result) => {});
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }

  onTOCButtonClick(element: any, actionType: ActionType): void {
    let id = element[0].rowData;

    switch (actionType) {
      case ActionType.Explain:
        this.dialog
          .open(EditAssetDetailComponent, {
            data: {
              id: btoa(id),
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;

      case ActionType.Dispose:
      case ActionType.Sell:
        this.dialog
          .open(EditDisposeAssetComponent, {
            data: {
              id: btoa(id),
              actionType: actionType,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;

      case ActionType.RollBack:
        this.dialog
          .open(RollBackPopupComponent, {
            panelClass: 'roll-back-popup',
            data: {
              id: btoa(element[6].rowData),
              actionType: actionType,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;
    }
  }

  isCheckboxDisabled(element: any): boolean {
    if (
      this.moduleId === this.moduleEnum.Invoices ||
      this.moduleId === this.moduleEnum.CreditNote ||
      this.moduleId === this.moduleEnum.Bills ||
      this.moduleId === this.moduleEnum.DebitNote ||
      this.moduleId === this.moduleEnum.Journals
    ) {
      return element[element.length - 1].rowData === 'True';
    }

    return false;
  }

  convertToInvoice(id: any): void {
    this.store.dispatch(new ConvertToInvoice(id)).subscribe((res) => {
      const parameters = {
        id: id,
        invoiceNumber: res.quotation.convertedInvoice.invoiceNumber,
        invoiceId: res.quotation.convertedInvoice.invoiceId,
        quotationNumber: res.quotation.convertedInvoice.quotationNumber,
      };
      this.dialog
        .open(ConvertToInvoicePopupComponent, {
          data: parameters,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.getList(false);
            this.spinner.hide();
          }
        });
    });
  }

  emailClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(EmailPopupComponent, {
          data: {
            ids: this.ids,
            moduleId: this.moduleId,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.mailSentSuccessfully
            );
            this.cancelSelectionClick();
          }
        });
    }
  }
}
