<div id="chart" *ngIf="billOutstandingChartOptions">
  <apx-chart
    [series]="billOutstandingChartOptions?.series!"
    [chart]="billOutstandingChartOptions?.chart!"
    [dataLabels]="billOutstandingChartOptions?.dataLabels!"
    [plotOptions]="billOutstandingChartOptions?.plotOptions!"
    [yaxis]="billOutstandingChartOptions?.yaxis!"
    [legend]="billOutstandingChartOptions?.legend!"
    [fill]="billOutstandingChartOptions?.fill!"
    [stroke]="billOutstandingChartOptions?.stroke!"
    [tooltip]="billOutstandingChartOptions?.tooltip!"
    [xaxis]="billOutstandingChartOptions?.xaxis!"
    [noData]="billOutstandingChartOptions?.noData!"
  ></apx-chart>
</div>
