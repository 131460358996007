import { Injectable } from '@angular/core';
import { FundModel, SideListModel } from '@app/core/Models';
import { FundService } from '@app/core/Services/fund/fund/fund.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreFund,
  CreateFund,
  DeleteFund,
  GetDataByFundId,
  GetEndowmentCapitalOverview,
  GetFundBreakdownOverview,
  GetFundCardData,
  GetFundListBasedOnFundAccountType,
  GetIncomeExpensesByFundTypeOverview,
  GetIncomeVsExpensesOverview,
} from './fund.action';

export class FundStateInfo {
  fundData?: FundModel;
  fund: Array<FundModel>;
  fundId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isFundIdAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  fundBreakdownOverview?: any;
  incomeExpensesByFundTypeOverview?: any;
  incomeVsExpensesOverview?: any;
  fundEndowmentCapitalOverview?: any;
  fundCardData?: any;
}

@State<FundStateInfo>({
  name: 'fund',
  defaults: {
    fund: [],
    fundId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isFundIdAdded: false,
  },
})
@Injectable()
export class FundState {
  constructor(private fundService: FundService) {}

  @Selector()
  static isLastPage(state: FundStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getFundId(state: FundStateInfo) {
    return state.fundId;
  }

  @Action(CreateFund)
  createFund({ patchState }: StateContext<FundStateInfo>, action: CreateFund) {
    return this.fundService.createFund(action.fund).pipe(
      tap((res) => {
        patchState({
          fundId: res,
          isFundIdAdded: true,
        });
      })
    );
  }

  @Action(GetDataByFundId)
  getDataByFundId(
    { patchState }: StateContext<FundStateInfo>,
    action: GetDataByFundId
  ) {
    return this.fundService.getDataByFundId(action.fundId).pipe(
      tap((res) => {
        patchState({
          fundData: res,
        });
      })
    );
  }

  @Action(DeleteFund)
  deleteFund(
    { getState, setState }: StateContext<FundStateInfo>,
    action: DeleteFund
  ) {
    return this.fundService.deleteFund(action.fundIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredFund = state.fund.filter(
          (item) =>
            !action.fundIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.fundIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.fund,
          fund: filteredFund,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreFund)
  archiveAndRestoreFund(
    { getState }: StateContext<FundStateInfo>,
    action: ArchiveAndRestoreFund
  ) {
    return this.fundService.archiveAndRestoreFund(
      action.fundIds,
      action.isArchive
    );
  }

  @Action(GetFundBreakdownOverview)
  getFundBreakdownOverview(
    { patchState }: StateContext<FundStateInfo>,
    action: GetFundBreakdownOverview
  ) {
    return this.fundService.getFundBreakdownOverview(action.filterData).pipe(
      tap((res) => {
        patchState({
          fundBreakdownOverview: res,
        });
      })
    );
  }

  @Action(GetIncomeExpensesByFundTypeOverview)
  getIncomeExpensesByFundTypeOverview(
    { patchState }: StateContext<FundStateInfo>,
    action: GetIncomeExpensesByFundTypeOverview
  ) {
    return this.fundService
      .getIncomeExpensesByFundTypeOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            incomeExpensesByFundTypeOverview: res,
          });
        })
      );
  }

  @Action(GetIncomeVsExpensesOverview)
  getIncomeVsExpensesOverview(
    { patchState }: StateContext<FundStateInfo>,
    action: GetIncomeVsExpensesOverview
  ) {
    return this.fundService.getIncomeVsExpensesOverview(action.filterData).pipe(
      tap((res) => {
        patchState({
          incomeVsExpensesOverview: res,
        });
      })
    );
  }

  @Action(GetEndowmentCapitalOverview)
  getEndowmentCapitalOverview(
    { patchState }: StateContext<FundStateInfo>,
    action: GetEndowmentCapitalOverview
  ) {
    return this.fundService.getEndowmentCapitalOverview(action.filterData).pipe(
      tap((res) => {
        patchState({
          fundEndowmentCapitalOverview: res,
        });
      })
    );
  }

  @Action(GetFundCardData)
  getFundCardData(
    { patchState }: StateContext<FundStateInfo>,
    action: GetFundCardData
  ) {
    return this.fundService.getFundCardData(action.fundCardData).pipe(
      tap((res) => {
        patchState({
          fundCardData: res,
        });
      })
    );
  }

  @Action(GetFundListBasedOnFundAccountType)
  getFundListBasedOnFundAccountTypeAsync(
    { patchState }: StateContext<FundStateInfo>,
    action: GetFundListBasedOnFundAccountType
  ) {
    return this.fundService
      .getFundListBasedOnFundAccountType(action.accountId)
      .pipe(
        tap((res) => {
          patchState({
            sideListModel: res,
          });
        })
      );
  }
}
