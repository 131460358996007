<div class="d-flex h-100">
  <div
    class="form mb-4 wp-100"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <p class="header-text d-flex justify-space-between align-items-center">
          <span> Gift Aid Setting </span>
          <button
            mat-button
            class="header-text-close d-flex align-items-center"
            disableRipple
            (click)="onCloseClick()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </p>
        <div>
          <div class="alert-message" *ngIf="isViewPermission$ | async">
            <mat-icon class="material-icons-outlined">error_outline</mat-icon>
            {{ commonNotificationText.ViewOnlyAccessMessage }}
          </div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Authorised Official</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content-body">
                <form [formGroup]="giftAIDsettingsForm">
                  <div class="d-flex gap-40">
                    <div class="form-field">
                      <p>First Name <span class="text-danger-300">*</span></p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="firstName"
                          autocomplete="off"
                        />
                      </mat-form-field>
                    </div>
                    <div class="form-field">
                      <p>Charity ID <span class="text-danger-300">*</span></p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="charityId"
                          autocomplete="off"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="d-flex gap-40">
                    <div class="form-field">
                      <p>Last Name <span class="text-danger-300">*</span></p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="lastName"
                          autocomplete="off"
                        />
                      </mat-form-field>
                    </div>
                    <div class="form-field">
                      <p>Charity Commision Reference</p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="charityCommisionReference"
                          autocomplete="off"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="d-flex gap-40">
                    <div class="form-field">
                      <p>Phone <span class="text-danger-300">*</span></p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="phone"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength25 }}"
                          type="text"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        />
                      </mat-form-field>
                    </div>
                    <div class="form-field">
                      <p>Regulator <span class="text-danger-300">*</span></p>
                      <ng-select
                        #regulator
                        (focus)="regulator.open()"
                        (valueChange)="regulator.close()"
                        [disableOptionCentering]="true"
                        formControlName="regulator"
                        panelClass="mat-select-position"
                      >
                        <ng-option
                          *ngFor="let cr of charityRegulator"
                          [value]="cr.id"
                        >
                          <div class="clamp" title="{{ cr.name }}">
                            {{ cr.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                  <div class="d-flex gap-40">
                    <div class="form-field">
                      <p>Post Code <span class="text-danger-300">*</span></p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="postCode"
                          autocomplete="off"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave($event.isExit)"
      (triggerOnCancelClick)="onCancel(true)"
    >
    </app-buttons>
  </div>
</div>
