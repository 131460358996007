import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpeningBalance, SaveOpeningBalanceModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../..';
import { Guid } from 'guid-typescript';

@Injectable()
export class OpeningBalanceService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  saveOpeningBalanceFundExplain(
    openingBalanceFundExplain: Array<any>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Fund/saveFundOpeningBalance`,
      this.commonService.trimObjectSpace(
        JSON.stringify(openingBalanceFundExplain)
      ),
      headers
    );
  }

  saveOpeningBalanceExplain(
    openingBalanceExplain: Array<OpeningBalance>,
    accountTypeId: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}OpeningBalance/saveExplain/${accountTypeId}`,
      this.commonService.trimObjectSpace(JSON.stringify(openingBalanceExplain)),
      headers
    );
  }

  saveOpeningBalance(openingBalance: SaveOpeningBalanceModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}OpeningBalance/save`,
      this.commonService.trimObjectSpace(JSON.stringify(openingBalance)),
      headers
    );
  }

  saveTrialBalance(trialBalance: SaveOpeningBalanceModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}OpeningBalance/saveTrialBalance`,
      this.commonService.trimObjectSpace(JSON.stringify(trialBalance)),
      headers
    );
  }

  getOpeningBalanceList(): Observable<Array<OpeningBalance>> {
    return this.http.get<Array<OpeningBalance>>(
      `${environment.apiVersionUrl}OpeningBalance/filteredList`
    );
  }

  getOpeningBalanceBasedOnAPId(
    accoutingPeriodId: Guid
  ): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}OpeningBalance/getBasedOnAccountingPeriod/${accoutingPeriodId}`
    );
  }

  getFundOpeningBalance(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}Fund/getFundOpeningBalance`
    );
  }

  getFundOpeningBalanceBasedOnAPId(
    accoutingPeriodId: Guid
  ): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}AccountProduction/getFundOpeningBalance/${accoutingPeriodId}`
    );
  }
  saveOpeningBalanceFundExplainBasedOnAPId(
    openingBalanceFundExplain: Array<any>,
    accountingPeriodId: Guid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountProduction/saveFundOpeningBalance/${accountingPeriodId}`,
      this.commonService.trimObjectSpace(
        JSON.stringify(openingBalanceFundExplain)
      ),
      headers
    );
  }
}
