<div class="company-add-form" [style.display]="isloaded ? 'block' : 'none'">
  <div class="company-logo text-align-center pt-2 pb-2">
    <a href="javascript:void(0)">
      <img src="assets/images/logo.svg" alt="Logo" />
    </a>
  </div>
  <div class="company-form mb-4">
    <div class="company-form-fields content-body">
      <div class="company-header text-align-center">
        <h2 class="text-primary fw-normal mt-0">
          Welcome to Capium's Charity module. To get started, please add a
          Charity.
        </h2>
        <p class="text-gray-200">
          Fill the boxes below to complete the charity creation process.
        </p>
      </div>
      <app-add-company
        [getModuleId]="moduleId"
        [getModuleName]="moduleName"
        [triggerEditData]="triggerEditData"
      >
      </app-add-company>
    </div>
    <div class="button-wrapper mt-1">
      <div class="d-flex align-items-center justify-space-between">
        <div
          *ngIf="
            activeRouteLink === routingPath.AddCharity ||
            activeRouteLink === routingPath.Dashboard
          "
        >
          <button
            class="action-button primary-button mr-1"
            type="button"
            mat-button
            disableRipple
            (click)="onSave(true)"
          >
            Get Started
          </button>
          <button
            class="action-button secondary-button"
            type="button"
            mat-button
            disableRipple
            (click)="onCancel(true)"
          >
            Reset
          </button>
        </div>

        <div *ngIf="activeRouteLink === routingPath.EditCharity">
          <app-buttons
            [getModuleId]="moduleId"
            (triggerOnSaveClick)="onSave($event.isExit)"
            (triggerOnCancelClick)="onCancel(false)"
          ></app-buttons>
        </div>
      </div>
    </div>
  </div>
</div>
