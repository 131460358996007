import { Directive, HostListener, ElementRef } from '@angular/core';
@Directive({
  selector: '[unicodeCharacter]',
})
export class UnicodeCharacterDirective {
  regexStr = `^[a-zA-Z\xC0-\uFFFF .'-]`;

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedValue = event.clipboardData?.getData('text');
    if (pastedValue && !String(pastedValue).match(this.regexStr)) {
      event.preventDefault();
    }
  }
}
