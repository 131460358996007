import { Injectable } from '@angular/core';
import { RecurringDonationModel, SideListModel } from '@app/core/Models';
import { RecurringDonationService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreRecurringDonation,
  CreateRecurringDonation,
  DeleteRecurringDonation,
  GetDataByRecurringDonationId,
} from './recurring-donation.action';

export class RecurringDonationStateInfo {
  recurringDonationData?: RecurringDonationModel;
  recurringDonation: Array<RecurringDonationModel>;
  recurringDonationId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isRecurringDonationAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
}

@State<RecurringDonationStateInfo>({
  name: 'recurringDonation',
  defaults: {
    recurringDonation: [],
    recurringDonationId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isRecurringDonationAdded: false,
  },
})
@Injectable()
export class RecurringDonationState {
  constructor(private recurringDonationService: RecurringDonationService) {}

  @Selector()
  static isLastPage(state: RecurringDonationStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getRecurringDonationId(state: RecurringDonationStateInfo) {
    return state.recurringDonationId;
  }

  @Action(CreateRecurringDonation)
  createRecurringDonation(
    { patchState }: StateContext<RecurringDonationStateInfo>,
    action: CreateRecurringDonation
  ) {
    return this.recurringDonationService
      .createRecurringDonation(action.recurringDonation)
      .pipe(
        tap((res) => {
          patchState({
            recurringDonationId: res,
            isRecurringDonationAdded: true,
          });
        })
      );
  }

  @Action(GetDataByRecurringDonationId)
  getDataByRecurringDonationId(
    { patchState }: StateContext<RecurringDonationStateInfo>,
    action: GetDataByRecurringDonationId
  ) {
    return this.recurringDonationService
      .getDataByRecurringDonationId(action.recurringDonationId)
      .pipe(
        tap((res) => {
          patchState({
            recurringDonationData: res,
          });
        })
      );
  }

  @Action(DeleteRecurringDonation)
  deleteRecurringDonation(
    { getState, patchState }: StateContext<RecurringDonationStateInfo>,
    action: DeleteRecurringDonation
  ) {
    return this.recurringDonationService
      .deleteRecurringDonation(action.recurringDonationIds)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredQuatation = state.recurringDonation.filter(
            (item) =>
              !action.recurringDonationIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.recurringDonationIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          patchState({
            ...state.recurringDonation,
            recurringDonation: filteredQuatation,
            sideListModel: filteredForSideList,
          });
        })
      );
  }

  @Action(ArchiveAndRestoreRecurringDonation)
  archiveAndRestoreRecurringDonation(
    { getState }: StateContext<RecurringDonationStateInfo>,
    action: ArchiveAndRestoreRecurringDonation
  ) {
    return this.recurringDonationService.archiveAndRestoreRecurringDonation(
      action.recurringDonationIds,
      action.isArchive
    );
  }
}
