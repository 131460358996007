import { Guid } from 'guid-typescript';

export class SponsorModel {
  id: Guid;
  name: string;
  eventDate?: string;
  amount?: number;
  attachment?: Array<string>;
  isActive: boolean;
}
