import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';

import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { SponsorModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateSponsor,
  GetDataBySponsorId,
  MenuState,
  SponsorState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddSponsorComponent,
  TransactionsAttachmentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-sponsor-event',
  templateUrl: './sponsor-event.component.html',
  styleUrls: ['./sponsor-event.component.scss'],
})
export class SponsorEventComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isExit = false;

  moduleId = Modules.SponsorEvent;
  moduleName = ModuleName.SponsorEvent;

  sponsorData: SponsorModel;

  sponsorId = Guid.EMPTY as unknown as Guid;

  subscriptionRouting: Subscription;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddSponsorComponent, { static: true })
  sponsorDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  triggerEditData: Subject<any> = new Subject<any>();

  commonService: CommonService;
  spinner: NgxSpinnerService;
  store: Store;
  _Activatedroute: ActivatedRoute;
  datepipe: DatePipe;
  dialog: MatDialog;
  location: Location;
  renderer: Renderer2;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.sponsorId = atob(params.get('id')!) as unknown as Guid;
          this.editSponsorEvent();
        }
      }
    );
  }

  editSponsorEvent(): void {
    this.store
      .dispatch(new GetDataBySponsorId(this.sponsorId))
      .subscribe(() => {
        this.sponsorDetails.sponsorForm.markAsUntouched();
        this.sponsorData = this.store.selectSnapshot(
          SponsorState.getSponsorData
        )!;
        this.triggerEditData.next(this.sponsorData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.sponsorData = {
        id: this.sponsorId,
        name: this.sponsorDetails.sponsorForm.controls.name.value,
        eventDate: this.datepipe
          .transform(
            this.sponsorDetails.sponsorForm.controls.eventDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        attachment: filedata,
        isActive: this.sponsorDetails.sponsorForm.controls.isActive.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.sponsorId,
      isExit,
      Modules.SponsorEvent,
      RoutingPath.AddSponsorEvent
    );
  }

  onSave(isExit: boolean): void {
    if (this.sponsorDetails.sponsorForm.invalid) {
      this.commonService.addValidation(
        this.sponsorDetails.sponsorForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        if (this.sponsorData) {
          this.store
            .dispatch(new CreateSponsor(this.sponsorData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.sponsorId)) {
      this.editSponsorEvent();
    } else {
      this.sponsorId = Guid.EMPTY as unknown as Guid;
      this.sponsorDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
