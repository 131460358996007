<div class="content-body content-body-scroll">
  <p class="header-text d-flex justify-space-between align-items-center">
    Annual Accounts
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </p>
  <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
    <mat-icon class="material-icons-outlined">error_outline</mat-icon>
    {{ commonNotificationText.ViewOnlyAccessMessage }}
  </div>

  <div class="content-right-button d-flex align-items-center gap-30 mb-1">
    <div class="d-flex align-items-center gap-10">
      <span class="fw-bold">Period: </span>
      <mat-form-field class="w-200">
        <mat-select
          panelClass="mat-select-position"
          [disableOptionCentering]="true"
          [(ngModel)]="accountingPeriod"
          (selectionChange)="onAccountPeriodChange()"
        >
          <mat-option
            *ngFor="let option of periodicDateList"
            [value]="option.id"
            title="{{ option.fromDate | date: 'dd-LLL-yyyy' }} - {{
              option.toDate | date: 'dd-LLL-yyyy'
            }}"
          >
            {{ option.fromDate | date: "dd-LLL-yyyy" }} -
            {{ option.toDate | date: "dd-LLL-yyyy" }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <button
        class="action-button primary-button"
        (click)="generateReport()"
        mat-button
        disableRipple
      >
        Generate Report
      </button>
    </div>
  </div>
  <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
    <p class="fw-bold font-size-18 mb-2">
      {{
        accountingMethodId === accountingMethod.CashBasis
          ? "Statement Of Assets And Liabilities"
          : "Balance Sheet"
      }}
      Signatory
    </p>
    <div class="d-flex gap-40 justify-space-between">
      <div class="flex-1">
        <form [formGroup]="annualForm">
          <div class="d-flex gap-40">
            <div class="form-field">
              <p>Name <span class="text-danger-300">*</span></p>
              <mat-form-field>
                <input
                  matInput
                  maxlength="{{ maxLength.maxLength100 }}"
                  formControlName="name"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>

            <div class="form-field">
              <p>Designation <span class="text-danger-300">*</span></p>
              <mat-form-field>
                <input
                  matInput
                  maxlength="{{ maxLength.maxLength100 }}"
                  formControlName="designation"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>

            <div class="form-field datepicker">
              <p>Date <span class="text-danger-300">*</span></p>
              <mat-form-field appearance="standard">
                <input
                  matInput
                  formControlName="date"
                  [matDatepicker]="datePicker"
                  autocomplete="off"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="datePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex gap-40 align-items-center">
            <div class="form-field mb-0">
              <p>Color <span class="text-danger-300">*</span></p>
              <input
                type="color"
                [(ngModel)]="fontColors"
                formControlName="fontColor"
                class="color-picker"
              />
            </div>
            <div class="form-field m-0">
              <mat-checkbox formControlName="showPriorYearValue">
                Show Prior Year Values
              </mat-checkbox>
            </div>
          </div>
        </form>
      </div>
      <div class="form-field attached">
        <p>Signature</p>
        <div class="d-flex align-items-center gap-10">
          <img
            class="signature-img"
            [src]="signatureFileName"
            *ngIf="isSignatureSelected"
            alt="Logo"
          />

          <a
            class="link fw-bold"
            (click)="onSignatureDeletePDF()"
            *ngIf="isSignatureSelected"
          >
            <mat-icon>delete_outline</mat-icon>
          </a>
          <div
            class="attach-file align-items-center"
            [ngClass]="isSignatureSelected ? 'hidden' : ''"
          >
            <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
            <div class="attach-file-label">
              <input
                #examinerReport
                id="examinerReport"
                type="file"
                accept="{{ acceptSignature }}"
                (change)="onSignatureSelection($event)"
                autocomplete="off"
                maxlength="{{ maxLength.maxLength100 }}"
                [multiple]="false"
              />
              <label for="file" class="mb-5">
                <div>
                  <span>Drag and drop jpeg/png/jpg files to upload</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="control-section mt-2">
      <div class="sidenav-table sidenav-table-input wp-100">
        <div
          class="mat-table-wrapper"
          [ngClass]="{ 'mat-table-wrapper-noData': noDataFound }"
        >
          <table
            mat-table
            #table
            [dataSource]="annualReportListSavedFiles"
            aria-describedby="SavedFile_List"
          >
            <ng-container matColumnDef="srno">
              <th mat-header-cell *matHeaderCellDef>Sr No</th>
              <td mat-cell *matCellDef="let element">{{ element.srno }}</td>
            </ng-container>

            <ng-container matColumnDef="period">
              <th mat-header-cell *matHeaderCellDef>Period</th>
              <td mat-cell *matCellDef="let element">{{ element.period }}</td>
            </ng-container>

            <ng-container matColumnDef="saveDate">
              <th mat-header-cell *matHeaderCellDef>Save Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.saveDate | date: "dd-LLL-yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="saveTime">
              <th mat-header-cell *matHeaderCellDef>Save Time</th>
              <td mat-cell *matCellDef="let element">
                {{ element.saveTime }}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div
                  class="grid-actions justify-content-end"
                  [ngClass]="{
                    'show-button': selectedRowIndex === i,
                    'hide-edit-button': !element.isManual
                  }"
                >
                  <div
                    class="d-flex align-items-center"
                    *ngFor="let item of actions; let permissionsIndex = index"
                  >
                    <span>
                      <button
                        *ngIf="permissionsIndex < moreActionCount"
                        mat-button
                        disableRipple
                        title="{{ item.name }}"
                        (click)="onButtonClick(element, item.actionType)"
                      >
                        <mat-icon fontSet="material-icons-outlined">{{
                          item.icon
                        }}</mat-icon>
                      </button>
                    </span>
                    <button
                      mat-button
                      class="more-button"
                      (click)="onToggleMatMenu(i)"
                      [matMenuTriggerFor]="menu"
                      (menuClosed)="allowCloseOnClickOut()"
                      disableRipple
                    >
                      <mat-icon *ngIf="permissionsIndex === moreActionCount"
                        >more_vert</mat-icon
                      >
                    </button>
                  </div>

                  <mat-menu
                    #menu="matMenu"
                    xPosition="before"
                    class="more-button-menu"
                  >
                    <ng-container
                      *ngFor="let item of actions; let permissionsIndex = index"
                    >
                      <div *ngIf="permissionsIndex > 2">
                        <button
                          mat-menu-item
                          disableRipple
                          title="{{ item.name }}"
                          (click)="onButtonClick(element, item.actionType)"
                        >
                          <mat-icon fontSet="material-icons-outlined">{{
                            item.icon
                          }}</mat-icon>
                          <span>{{ item.name }} </span>
                        </button>
                      </div>
                    </ng-container>
                  </mat-menu>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <div *ngIf="noDataFound" class="text-align-center mt-1">
            No records found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-buttons
  *ngIf="!(isViewPermission$ | async)"
  [getModuleId]="moduleId"
  (triggerOnSaveClick)="onSave()"
  (triggerOnCancelClick)="onCancel()"
>
</app-buttons>
