import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
  Stepper,
} from '@app/core/Enum';
import { FileImportRequestModel } from '@app/core/Enum/import';
import {
  GiftAidDonationModel,
  GiftAidSubmissionModel,
  HMRCGatewayDetails,
  ImportGiftAidDonationsModel,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  GetGiftAidDetails,
  GiftAidImport,
  MenuState,
  SaveGiftAidDetails,
  SaveHMRCGatewayDetails,
} from '@app/core/Store';

import {
  AddClosePopupComponent,
  GiftAIDDetailsComponent,
  HMRCGatewayDetailsComponent,
  SubContractorDetailsComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { GiftAidDonationsDetailsComponent } from './gift-aid-donations-details/gift-aid-donations-details.component';

@Component({
  selector: 'app-gift-aid',
  templateUrl: './gift-aid.component.html',
  styleUrls: ['./gift-aid.component.scss'],
})
export class GiftAidComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isFirstStepDone = false;
  isSecondStepDone = false;
  isThirdStepDone = false;
  capiumButtonShow = false;
  hmrcButtonShow = false;
  isSubmittedCapium = false;
  isSubmittedHMRC = false;
  isContinueForHMRCSubmission = false;
  isEdit = false;
  isGiftAIDDetailsComponent = false;
  stepperEnum = Stepper;

  startDate: any;
  endDate: any;
  importFile: any;
  giftAidDetailsData: GiftAidSubmissionModel;
  giftAidDonationModel: Array<GiftAidDonationModel>;
  importGiftAidDonationsModel: Array<ImportGiftAidDonationsModel>;
  giftAidId = Guid.EMPTY as unknown as Guid;
  hmrcGatewayDetails: HMRCGatewayDetails;
  submitGiftAidId: Guid;
  fileImportRequestModel: FileImportRequestModel;
  giftAidImportData: [] = [];

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(GiftAIDDetailsComponent, { static: false })
  giftAIDDetailsComponent;

  @ViewChild(HMRCGatewayDetailsComponent, { static: false })
  hmrcGatewayDetailsComponent;

  @ViewChild(SubContractorDetailsComponent, { static: false })
  SubContractorDetailsComponent;

  @ViewChild(GiftAidDonationsDetailsComponent, { static: false })
  GiftAidDonationsDetailsComponent;

  @ViewChild('horizontalStepper') stepper: MatStepper;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    public commonService: CommonService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private store: Store,
    private spinner: NgxSpinnerService,
    private router: Router,
    private notifier: NotificationService,
    private _Activatedroute: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.spinner.hide();
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.giftAidId = atob(params.get('id')!) as unknown as Guid;
          this.editGiftAid();
        }
      }
    );
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  editGiftAid(): void {
    this.store
      .dispatch(new GetGiftAidDetails(this.giftAidId))
      .subscribe((res) => {
        this.triggerEditData.next(res.giftAidDetails.giftAidDetails);
      });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      if (this.importFile !== undefined) {
        this.importGiftAidDonationsModel =
          new Array<ImportGiftAidDonationsModel>();
        this.GiftAidDonationsDetailsComponent.giftAidImportData.forEach(
          (element) => {
            this.importGiftAidDonationsModel.push({
              title: element.title,
              donationDate: element.donationDate,
              donorName: element.donorName,
              donationAmount: element.donationAmount,
              houseNumber: element.houseNumber,
              postCode: element.postCode,
            });
          }
        );
      } else {
        this.giftAidDonationModel = new Array<GiftAidDonationModel>();
        this.SubContractorDetailsComponent.subContactorList.forEach(
          (element) => {
            if (element.isIncluded) {
              this.giftAidDonationModel.push({
                id: element.id,
                giftAidId: element.giftAidId,
                reference: element.reference,
                donationDate: this.datepipe
                  .transform(element.donationDate, 'yyyy-MM-dd')
                  ?.toString(),
                donorName: element.donorName,
                donationAmount: element.donationAmount,
                depositTo: element.depositTo,
                sponsorEvent: element.sponsorEven,
                activity: element.activity,
                isIncluded: element.isIncluded,
              });
            }
          }
        );
      }

      this.giftAidDetailsData = {
        id:
          this.isSubmittedHMRC === true ? this.submitGiftAidId : this.giftAidId,
        fromDate: this.datepipe
          .transform(
            this.giftAIDDetailsComponent.giftAIDDetailsForm.controls.startDate
              .value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        toDate: this.datepipe
          .transform(
            this.giftAIDDetailsComponent.giftAIDDetailsForm.controls.endDate
              .value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        submittedCapium: this.isSubmittedCapium,
        submittedHMRC: this.isSubmittedHMRC,
        isImported: this.importFile !== undefined,
        giftAidDonations: this.giftAidDonationModel,
        importGiftAidDonations: this.importGiftAidDonationsModel,
        submitGiftAidDetail: this.hmrcGatewayDetails,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    this.spinner.hide();
    return true;
  }

  onNextClick(isExit: boolean, selectedIndex) {
    if (this.stepper.selectedIndex === 0) {
      this.startDate =
        this.giftAIDDetailsComponent.giftAIDDetailsForm.controls.startDate.value;
      this.endDate =
        this.giftAIDDetailsComponent.giftAIDDetailsForm.controls.endDate.value;

      this.importFile = this.giftAIDDetailsComponent.selectedFile;

      if (this.giftAIDDetailsComponent.fileInfo != '') {
        this.saveImport();
      }
      if (this.giftAIDDetailsComponent.giftAIDDetailsForm.invalid) {
        this.commonService.addValidation(
          this.giftAIDDetailsComponent.giftAIDDetailsForm,
          this.renderer
        );
      } else {
        this.isFirstStepDone = true;
        this.isGiftAIDDetailsComponent = true;
        this.stepper.next();
        this.capiumButtonShow = true;
      }
    } else if (this.stepper.selectedIndex === 1) {
      this.isSecondStepDone = true;
      this.capiumButtonShow = true;
      this.stepper.linear = false;
      this.stepper.selectedIndex = 2;
      setTimeout(() => {
        this.stepper.linear = true;
      });
    }
  }

  saveImport(): void {
    this.fileImportRequestModel = {
      file: this.importFile,
      step: 0,
    };
    this.spinner.show();
    this.store
      .dispatch(new GiftAidImport(this.fileImportRequestModel))
      .pipe()
      .subscribe((res) => {
        this.giftAidImportData = res.giftAidDetails.importData;
        this.capiumButtonShow = false;
        this.hmrcButtonShow = true;
        this.spinner.hide();
      });
  }

  submitToCapium(capiumHMRC: boolean): void {
    if (capiumHMRC) {
      this.isSubmittedCapium = true;
      this.isSubmittedHMRC = false;
      if (this.dataSubmit()) {
        this.spinner.show();
        this.store
          .dispatch(new SaveGiftAidDetails(this.giftAidDetailsData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                if (capiumHMRC) {
                  this.capiumButtonShow = false;
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                  this.hmrcButtonShow = true;
                  this.submitGiftAidId = res.giftAidDetails.giftAidDetailsData;
                  this.giftAidId = this.submitGiftAidId;
                } else {
                  this.isSecondStepDone = true;
                  this.stepper.next();
                }
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    } else {
      this.isSubmittedHMRC = true;
      this.isSubmittedCapium = true;
      this.isSecondStepDone = true;
      this.stepper.next();
      this.isContinueForHMRCSubmission = true;
    }
  }

  continueForHMRCSubmission(): void {
    if (this.hmrcGatewayDetailsComponent.hmrcGatewayDetailsForm.invalid) {
      return;
    } else {
      if (this.importFile !== undefined) {
        this.dataSubmitForHMRC();
        this.dataSubmit();
        this.spinner.show();
        this.store
          .dispatch(new SaveGiftAidDetails(this.giftAidDetailsData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
                this.router.navigate([RoutingPath.GiftAidDeclaration]);
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      } else {
        this.dataSubmitForHMRC();
        this.store
          .dispatch(new SaveHMRCGatewayDetails(this.hmrcGatewayDetails))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
                this.router.navigate([RoutingPath.GiftAidDeclaration]);
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  dataSubmitForHMRC(): boolean {
    this.spinner.show();
    try {
      this.hmrcGatewayDetails = {
        giftAidId: this.submitGiftAidId,
        userName:
          this.hmrcGatewayDetailsComponent.hmrcGatewayDetailsForm.controls
            .userName.value,
        password:
          this.hmrcGatewayDetailsComponent.hmrcGatewayDetailsForm.controls
            .password.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    this.spinner.hide();
    return true;
  }

  cancelClick(isCancelClick: boolean) {
    if (!isCancelClick) {
      this.router.navigate([RoutingPath.GiftAidDeclaration]);
    }
  }

  onPrevClick(stepper) {
    this.capiumButtonShow = true;
    if (this.giftAidId) {
      this.editGiftAid();
    }
    this.isSecondStepDone = false;
    this.isGiftAIDDetailsComponent = false;
    stepper.previous();
  }

  onCapiuamSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.capiumSaveMessage
    );
  }
}
