import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {
  AuthorizedRole,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { PermissionType } from '@app/core/Enum/settings';
import {
  GlobalComponent,
  MainListParameters,
  GlobalStandardPermission,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  GetPermissions,
  MenuState,
  SaveBusinessPermission,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

export class StandardPermissionHeader extends GlobalStandardPermission {
  subMenu: Array<StandardPermissionHeader>;
}
export class StandardPermissionFlatNode extends GlobalStandardPermission {
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit {
  displayedColumns: string[] = [
    'permission',
    'admin',
    'manager',
    'regularUser',
  ];

  constructor(
    private store: Store,
    // private settingService: SettingService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private globalComponent: GlobalComponent
  ) {}

  moduleId = Modules.Permission;
  private transformer = (node: GlobalStandardPermission, level: number) => {
    return {
      expandable: !!node.subMenu && node.subMenu.length > 0,
      name: node.name,
      ordinal: node.ordinal,
      parentModuleId: node.parentModuleId,
      id: node.id,
      isOwner: node.isOwner,
      isAdministrator: node.isAdministrator,
      isManager: node.isManager,
      isRegularUser: node.isRegularUser,
      subMenu: node.subMenu,
      permissionId: node.permissionId,
      isDisabled: node.isDisabled,
      level,
    };
  };

  // tslint:disable-next-line:member-ordering
  treeControl = new FlatTreeControl<StandardPermissionFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  // tslint:disable-next-line:member-ordering
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.subMenu
  );
  loggedInUserId = this.globalComponent.getLoggedInUserId();
  authorizedRoleId = this.globalComponent.getLoggedInAuthorizedRole();
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  toggleAll = false;

  isAllAdminFullSelected: boolean = false;
  isAllAdminViewSelected: boolean = false;
  isAllAdminNoneSelected: boolean = false;

  isAllManagerFullSelected: boolean = false;
  isAllManagerViewSelected: boolean = false;
  isAllManagerNoneSelected: boolean = false;

  isAllRegularUserFullSelected: boolean = false;
  isAllRegularUserViewSelected: boolean = false;
  isAllRegularUserNoneSelected: boolean = false;

  permissionData: Array<GlobalStandardPermission>;
  searchText = '';
  isDirty: boolean = false;
  @Input()
  triggerLoadBusinessData: Observable<any>;

  @Input()
  triggerOnSavePermission: Observable<any>;

  @Input()
  triggerOnCancelPermission: Observable<any>;

  listParameters: MainListParameters = new MainListParameters();
  permissionType = PermissionType;
  authorizedRole = AuthorizedRole;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  ngOnInit(): void {
    this.getList();
  }
  onCancel(): void {
    this.getList();
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.getList();
    }
  }

  onSearch(event: any): void {
    this.searchText = event.target.value;
    this.listParameters.pageNumber = 1;
    this.getList();
  }

  getList(): void {
    this.spinner.show();

    this.store.dispatch(new GetPermissions(this.searchText)).subscribe(() => {
      const permissions = this.store.selectSnapshot(CommonState.getPermissions);
      this.dataSource.data = permissions ?? [];
      this.setAllSelected();
    });
  }

  toggleAllClick(): void {
    this.toggleAll = !this.toggleAll;
    if (this.toggleAll) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  showButtons(): void {
    this.isDirty = true;
  }

  onOwnerSelected(data: any): void {
    const parent: StandardPermissionFlatNode | null = this.getParentNode(data);
    const descendants = this.treeControl.getDescendants(data);

    if (data.isOwner) {
      descendants.forEach((ele) => {
        ele.isOwner = true;
      });
    } else {
      descendants.forEach((ele) => {
        ele.isOwner = false;
      });
    }

    if (!data.fullName) {
      if (data.isOwner) {
        data.isOwner = true;
      }
      const descendants = this.treeControl.getDescendants(data);
      descendants.forEach((ele) => {
        ele.isOwner = data.isOwner;
      });
    }

    // updating golbal check box based on grid check box
    if (parent) {
      let setSelected = false;
      const descendants = this.treeControl.getDescendants(parent);
      setSelected = descendants.some((ele) => ele.isOwner);
      parent.isOwner = setSelected;
    }

    this.setAllSelected();
    this.showButtons();
  }

  onAdminSelected(data: any, permissionType): void {
    const parent: StandardPermissionFlatNode | null = this.getParentNode(data);
    const descendants = this.treeControl.getDescendants(data);

    descendants.forEach((ele) => {
      if (permissionType === PermissionType.Full) {
        ele.isAdministrator = PermissionType.Full;
      }
      if (permissionType === PermissionType.View) {
        ele.isAdministrator = PermissionType.View;
      }
      if (permissionType === PermissionType.None) {
        ele.isAdministrator = PermissionType.None;
      }
    });

    if (parent) {
      let tempChild: any = [];
      this.treeControl.dataNodes.forEach((element) => {
        if (element.parentModuleId! > 0) tempChild.push(element);
      });

      let mainTemp: any = [];
      mainTemp = tempChild.filter((x) => x.parentModuleId === parent.id);
      let result = mainTemp.every((e) => {
        return e.isAdministrator === permissionType;
      });

      if (result) {
        parent.isAdministrator = permissionType;
      }

      if (parent.isAdministrator !== permissionType) {
        parent.isAdministrator = PermissionType.Full;
      }
    }
    this.setAllSelected();
    this.showButtons();
  }

  onManagerSelected(data: any, permissionType): void {
    const parent: StandardPermissionFlatNode | null = this.getParentNode(data);
    const descendants = this.treeControl.getDescendants(data);

    descendants.forEach((ele) => {
      if (permissionType === PermissionType.Full) {
        ele.isManager = PermissionType.Full;
      }
      if (permissionType === PermissionType.View) {
        ele.isManager = PermissionType.View;
      }
      if (permissionType === PermissionType.None) {
        ele.isManager = PermissionType.None;
      }
    });

    if (parent) {
      let tempChild: any = [];
      this.treeControl.dataNodes.forEach((element) => {
        if (element.parentModuleId! > 0) tempChild.push(element);
      });

      let mainTemp: any = [];
      mainTemp = tempChild.filter((x) => x.parentModuleId === parent.id);
      let result = mainTemp.every((e) => {
        return e.isManager === permissionType;
      });

      if (result) {
        parent.isManager = permissionType;
      }

      if (parent.isManager !== permissionType) {
        parent.isManager = PermissionType.Full;
      }
    }

    this.setAllSelected();
    this.showButtons();
  }

  onRegularUserSelected(data: any, permissionType): void {
    const parent: StandardPermissionFlatNode | null = this.getParentNode(data);
    const descendants = this.treeControl.getDescendants(data);

    descendants.forEach((ele) => {
      if (permissionType === PermissionType.Full) {
        ele.isRegularUser = PermissionType.Full;
      }
      if (permissionType === PermissionType.View) {
        ele.isRegularUser = PermissionType.View;
      }
      if (permissionType === PermissionType.None) {
        ele.isRegularUser = PermissionType.None;
      }
    });

    if (parent) {
      let tempChild: any = [];
      this.treeControl.dataNodes.forEach((element) => {
        if (element.parentModuleId! > 0) tempChild.push(element);
      });

      let mainTemp: any = [];
      mainTemp = tempChild.filter((x) => x.parentModuleId === parent.id);
      let result = mainTemp.every((e) => {
        return e.isRegularUser === permissionType;
      });

      if (result) {
        parent.isRegularUser = permissionType;
      }

      if (parent.isRegularUser !== permissionType) {
        parent.isRegularUser = PermissionType.Full;
      }
    }

    this.setAllSelected();
    this.showButtons();
  }

  dataSubmit(): void {
    this.spinner.show();
    this.permissionData = new Array<GlobalStandardPermission>();

    this.treeControl.dataNodes?.forEach((node) => {
      if (node.parentModuleId) {
        const parentIndex = this.permissionData.findIndex(
          (parent) => parent.id === node.parentModuleId
        );
        if (parentIndex === -1) {
          this.permissionData.forEach((parent) => {
            if (parent.subMenu) {
              const childIndex = parent.subMenu.findIndex(
                (child) => child?.id === node.parentModuleId
              );

              if (childIndex !== -1) {
                parent.subMenu[childIndex]?.subMenu.push(node);
              }
            }
          });
        } else {
          node.subMenu = [];
          this.permissionData[parentIndex].subMenu.push(node);
        }
      } else {
        node.subMenu = [];
        this.permissionData.push(node);
      }
    });
  }

  onSave(isExits: boolean): void {
    this.dataSubmit();

    if (this.permissionData?.length > 0) {
      this.store
        .dispatch(
          new SaveBusinessPermission(
            this.permissionData,
            this.globalComponent.getLoggedInUserId()
          )
        )
        .subscribe((res: any) => {
          if (res.common.isBusinessPermissionUpdated) {
            this.commonService.onSucess(NotificationTextMessage.successMessage);
            this.getList();
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        });
    }
  }

  selectedAllAdmin(type): void {
    for (let dataNode of this.treeControl.dataNodes) {
      if (!dataNode.isDisabled) {
        if (type === PermissionType.Full) {
          dataNode.isAdministrator = PermissionType.Full;
          this.isAllAdminFullSelected = true;
          this.isAllAdminViewSelected = false;
          this.isAllAdminNoneSelected = false;
        }

        if (type === PermissionType.View) {
          dataNode.isAdministrator = PermissionType.View;
          this.isAllAdminFullSelected = false;
          this.isAllAdminViewSelected = true;
          this.isAllAdminNoneSelected = false;
        }

        if (type === PermissionType.None) {
          dataNode.isAdministrator = PermissionType.None;
          this.isAllAdminFullSelected = false;
          this.isAllAdminViewSelected = false;
          this.isAllAdminNoneSelected = true;
        }
      }
      this.showButtons();
    }
  }

  selectedAllManager(type): void {
    for (let dataNode of this.treeControl.dataNodes) {
      if (!dataNode.isDisabled) {
        if (type === PermissionType.Full) {
          dataNode.isManager = PermissionType.Full;
          this.isAllManagerFullSelected = true;
          this.isAllManagerViewSelected = false;
          this.isAllManagerNoneSelected = false;
        }

        if (type === PermissionType.View) {
          dataNode.isManager = PermissionType.View;
          this.isAllManagerFullSelected = false;
          this.isAllManagerViewSelected = true;
          this.isAllManagerNoneSelected = false;
        }

        if (type === PermissionType.None) {
          dataNode.isManager = PermissionType.None;
          this.isAllManagerFullSelected = false;
          this.isAllManagerViewSelected = false;
          this.isAllManagerNoneSelected = true;
        }
      }
      this.showButtons();
    }
  }

  selectedAllRegularUser(type): void {
    for (let dataNode of this.treeControl.dataNodes) {
      if (!dataNode.isDisabled) {
        if (type === PermissionType.Full) {
          dataNode.isRegularUser = PermissionType.Full;
          this.isAllRegularUserFullSelected = true;
          this.isAllRegularUserViewSelected = false;
          this.isAllRegularUserNoneSelected = false;
        }

        if (type === PermissionType.View) {
          dataNode.isRegularUser = PermissionType.View;
          this.isAllRegularUserFullSelected = false;
          this.isAllRegularUserViewSelected = true;
          this.isAllRegularUserNoneSelected = false;
        }

        if (type === PermissionType.None) {
          dataNode.isRegularUser = PermissionType.None;
          this.isAllRegularUserFullSelected = false;
          this.isAllRegularUserViewSelected = false;
          this.isAllRegularUserNoneSelected = true;
        }
      }
      this.showButtons();
    }
  }

  getLevel = (node: StandardPermissionFlatNode) => node.level;

  getParentNode(
    node: StandardPermissionFlatNode
  ): StandardPermissionFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  setAllSelected(): void {
    this.isAllAdminFullSelected = this.treeControl.dataNodes.every(
      (item: any) => item.isAdministrator === PermissionType.Full
    );

    this.isAllAdminViewSelected = this.treeControl.dataNodes.every(
      (item: any) => item.isAdministrator === PermissionType.View
    );

    this.isAllAdminNoneSelected = this.treeControl.dataNodes.every(
      (item: any) => item.isAdministrator === PermissionType.None
    );

    this.isAllManagerFullSelected = this.treeControl.dataNodes.every(
      (item: any) => item.isManager === PermissionType.Full
    );

    this.isAllManagerViewSelected = this.treeControl.dataNodes.every(
      (item: any) => item.isManager === PermissionType.View
    );

    this.isAllManagerNoneSelected = this.treeControl.dataNodes.every(
      (item: any) => item.isManager === PermissionType.None
    );

    this.isAllRegularUserFullSelected = this.treeControl.dataNodes.every(
      (item: any) => item.isRegularUser === PermissionType.Full
    );

    this.isAllRegularUserViewSelected = this.treeControl.dataNodes.every(
      (item: any) => item.isRegularUser === PermissionType.View
    );

    this.isAllRegularUserNoneSelected = this.treeControl.dataNodes.every(
      (item: any) => item.isRegularUser === PermissionType.None
    );
  }

  ngDestroy(): void {
    // this.settingService.reloadMenus.unsubscribe()
  }
}
