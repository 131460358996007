import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { IncomeTypeName } from '@app/core/Enum/income-types-name';
import {
  ChartOfAccountListParam,
  Currency,
  GlobalComponent,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  CreateAccount,
  CreateContact,
  CreateFund,
  GetCurrencyList,
  GetDataByAccountId,
  GetDataByFundId,
  GetFundNameList,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetNonStandardAccountList,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-quotation',
  templateUrl: './add-quotation.component.html',
  styleUrls: ['./add-quotation.component.scss'],
})
export class AddQuotationComponent implements OnInit {
  disabledEditButton = true;
  disabledFundEditButton = true;

  quotationForm: FormGroup;
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  currencyList: Currency[];
  customerList: SideListModel[];
  fundNameList: SideListModel[];
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];

  subscriptionRouting: Subscription;

  @Input() triggerEditData: Observable<any>;
  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  defaultCurrency: Guid;
  getListId: Guid;
  periodicDate: any;
  isCustomerChangePermission: boolean = true;
  isFundChangePermission: boolean = true;
  isVatInclude = false;
  isVatRegistered: any;

  private destroy$ = new Subject<void>();

  @Output()
  readonly isVatIncludedClick = new EventEmitter<any>();

  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();
    this.getCustomer();
    this.getCurrency();
    this.getFundNameList();

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.editQuotation(data);
      });

    this.quotationForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.quotationForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCustomerChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Customers
      );
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetAccountList(): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(this.quotationForm.controls.invoiceType.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  getOptionText(option) {
    return option.name;
  }

  getCurrency(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          this.quotationForm.controls.currency.setValue(this.defaultCurrency);
        })
      )
      .subscribe();
  }

  getInvoiceType(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Income);

    const typeId = new Array<number>();
    typeId.push(
      IncomeTypeName.CharitableActivities,
      IncomeTypeName.Othertradingactivities
    );

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: typeId,
    };

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupIdAndTypeId(param))
      .pipe(
        tap((res) => {
          if (res.common.accountGroupList.length > 0) {
            this.accountGroupList = res.common.accountGroupList;
            this.tempAccountGroupList = this.accountGroupList;
          }
        })
      )
      .subscribe();
  }

  getFundNameList(): void {
    this.store
      .dispatch(new GetFundNameList())
      .pipe(
        tap((res) => {
          this.fundNameList = res.account.fundNameList;
          if (this.fundNameList.length > 0) {
            this.disabledFundEditButton = false;
            this.quotationForm.controls.fundName.setValue(
              this.fundNameList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Customers,
          headerText: `Add ${ModuleName.Customers}`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getCustomer(id);
        }
      });
  }

  gotoEditCustomer(): void {
    if (this.customerList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Customers,
            headerText: `Edit ${ModuleName.Customers}`,
            id: this.quotationForm.controls.customerName.value,
            saveActionName: CreateContact,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getCustomer(id);
          }
        });
    }
  }

  customerChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  editQuotation(data): void {
    let account;
    this.accountGroupList.forEach((items) => {
      const accountId = items.listModels.find(
        (x: any) => x.id === data.bookAccountId
      );
      if (accountId !== null && accountId !== undefined) {
        account = accountId;
      }
    });
    this.quotationForm.controls.quotationNo.disable();
    this.disabledEditButton = false;
    this.disabledFundEditButton = false;
    this.quotationForm.patchValue({
      invoiceType: account,
      fundName: data.fundId,
      entryDate: data.entryDate,
      quotationNo: data.entryNumber,
      currency: data.currencyId,
      customerName: data.accountId,
      note: data.note,
      isVatInclude: data.isVatIncluded,
    });
  }

  setForm(): void {
    this.quotationForm = new FormGroup({
      customerName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      quotationNo: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      currency: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      entryDate: new FormControl(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      note: new FormControl(''),
      invoiceType: new FormControl('', [Validators.required]),
      fundName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      isVatInclude: new FormControl(false),
    });

    this.getInvoiceType();
  }

  getCustomer(id?: Guid): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))

      .pipe(
        tap((res) => {
          this.customerList = res.common.accountList;
          if (this.customerList.length > 0) {
            if (id !== null && id !== undefined) {
              this.quotationForm.controls.customerName.setValue(id);
            }
          }
        })
      )
      .subscribe();
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledFundEditButton = false;
    } else {
      this.disabledFundEditButton = true;
    }
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledFundEditButton = false;
          this.getFund(id);
        }
      });
  }

  getFund(id): void {
    this.store.dispatch(new GetFundNameList()).subscribe((res) => {
      this.fundNameList = res.account.fundNameList;
      if (id !== null && id !== undefined) {
        this.quotationForm.controls.fundName.setValue(id);
      }
    });
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.quotationForm.controls.fundName.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onCheckBoxSelected(event: any): void {
    this.isVatInclude = event.checked;
    this.isVatIncludedClick.emit(this.isVatInclude);
  }
}
