import { FileImportRequestModel } from '@app/core/Enum/import';
import { SubmitVATModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class ImportVatBridging {
  static readonly type = '[IMPORT] Import Vat Bridging';

  constructor(public fileImportRequestModel: FileImportRequestModel) {}
}

export class SaveBridgingVAT {
  static readonly type = '[BRIDINGVAT] Save Bridging VAT';

  constructor(public data: SubmitVATModel) {}
}

export class SetBridgingVatId {
  static readonly type = '[BRIDINGVAT] Set Bridging Vat Id';

  constructor(public id: Guid) {}
}
