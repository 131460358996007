<div class="content-body">
  <form [formGroup]="fixedAssetsForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Bill No. <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="billNo"
            autocomplete="off"
            required
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
            appAutoFocusDirective
          />
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Bill Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="invoiceDate"
            [matDatepicker]="invoiceDatePicker"
            autocomplete="off"
            (dateChange)="dateChange()"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="invoiceDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #invoiceDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              fixedAssetsForm.get('invoiceDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              fixedAssetsForm.get('invoiceDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Fixed Asset Type <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="invoiceType"
            required
            [matAutocomplete]="accountGroup"
            (keyup)="onAccountSearch($event)"
            (mousedown)="resetAccountList()"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            #accountGroup="matAutocomplete"
            (opened)="
              scrollIntoView(fixedAssetsForm.controls.invoiceType.value)
            "
          >
            <mat-optgroup
              *ngFor="let group of fixedAssetsTypeList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.fixedAssetsForm.controls.invoiceType.value.id ===
                  names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Fund Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isFundChangePermission"
            >
              <mat-icon (click)="gotoAddFund()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledFundEditButton || !isFundChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditFund()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #fundName
          (focus)="fundName.open()"
          (valueChange)="fundName.close()"
          [disableOptionCentering]="true"
          formControlName="fundName"
          (change)="fundChange($event)"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let fn of fundNameList" [value]="fn.id">
            <div class="clamp" title="{{ fn.name }}">{{ fn.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Supplier Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isFundChangePermission"
            >
              <mat-icon (click)="gotoAddSupplier()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledEditButton || !isSupplierChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditSupplier()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #supplierName
          (focus)="supplierName.open()"
          (valueChange)="supplierName.close()"
          [disableOptionCentering]="true"
          formControlName="accountId"
          panelClass="mat-select-position"
          (change)="supplierChange($event)"
        >
          <ng-option *ngFor="let cl of supplierList" [value]="cl.id">
            <div class="clamp" title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Currency</p>
        <ng-select
          #currency
          (focus)="currency.open()"
          (valueChange)="currency.close()"
          [disableOptionCentering]="true"
          formControlName="currencyId"
          dropdownPosition="top"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
            <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
              {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
            </div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40 align-items-center">
      <div
        class="form-field datepicker"
        *ngIf="accountingMethodId !== accountingMethod.CashBasis"
      >
        <p>Due Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="dueDate"
            [matDatepicker]="dueDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dueDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dueDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(fixedAssetsForm.get('dueDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(fixedAssetsForm.get('dueDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field m-0" *ngIf="isVatRegistered">
        <mat-checkbox
          formControlName="isVatInclude"
          (change)="onCheckBoxSelected($event)"
        >
          Amount Including VAT
        </mat-checkbox>
      </div>
    </div>
    <div class="form-field wp-100 textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          maxlength="{{ maxLength.maxLength2000 }}"
          matInput
          formControlName="note"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
