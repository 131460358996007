import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note29',
  templateUrl: './note29.component.html',
  styleUrls: ['./note29.component.scss'],
})
export class Note29Component implements OnInit {
  note29Form: FormGroup;
  calculativeData: any;

  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      if (data.noteId === Notes.Note29) {
        this.calculativeData = data.response.calculativeData;

        if (
          data.response.userInputData !== null &&
          data.response.userInputData !== undefined &&
          data.response.userInputData.data !== null &&
          data.response.userInputData.data !== undefined
        ) {
          this.editNotes29(data.response.userInputData.data);
        }
      }
    });
  }

  editNotes29(res): void {
    this.note29Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : ' ',
    });
  }

  setForm(): void {
    this.note29Form = new FormGroup({
      textbox1: new FormControl(''),
    });
  }
}
