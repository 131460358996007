<div class="content-body">
  <form [formGroup]="bankTransferForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Transaction No. <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="bankTransferNumber"
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <div class="form-field datepicker">
        <p>Transfer Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="transferDate"
            [matDatepicker]="transferDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="transferDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #transferDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              bankTransferForm.get('transferDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              bankTransferForm.get('transferDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Transferred From <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="transferFrom"
            required
            [matAutocomplete]="transferFromGroup"
            (keyup)="onAccountSearch($event, true)"
            (mousedown)="resetAccountList(true)"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            (opened)="
              scrollIntoView(bankTransferForm.controls.transferFrom.value)
            "
            #transferFromGroup="matAutocomplete"
          >
            <mat-optgroup
              *ngFor="let group of transferFromGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.bankTransferForm.controls.transferFrom.value.id ===
                  names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error
          *ngIf="
            bankTransferForm.get('transferFrom')?.value !== '' &&
            bankTransferForm.get('transferFrom')?.status === 'INVALID'
          "
        >
          Same account selection is not allowed
        </mat-error>
      </div>

      <div class="form-field">
        <p>Transferred To <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="transferTo"
            required
            [matAutocomplete]="transferToGroup"
            (keyup)="onAccountSearch($event, false)"
            (mousedown)="resetAccountList(false)"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            (opened)="
              scrollIntoView(bankTransferForm.controls.transferTo.value)
            "
            #transferToGroup="matAutocomplete"
          >
            <mat-optgroup
              *ngFor="let group of transferToGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.bankTransferForm.controls.transferTo.value.id ===
                  names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error
          *ngIf="
            bankTransferForm.get('transferTo')?.value !== '' &&
            bankTransferForm.get('transferTo')?.status === 'INVALID'
          "
        >
          Same account selection is not allowed
        </mat-error>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Amount <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            allow_14_2_Decimal
            matInput
            zeroNotAllowed
            formControlName="amount"
            autocomplete="off"
            required
            allowDecimal
          />
        </mat-form-field>
      </div>

      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Fund Name <span class="text-danger-300">*</span></p>
          <span>
            <button mat-button disableRipple>
              <mat-icon (click)="gotoAddFund()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledFundEditButton"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditFund()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #fundId
          (focus)="fundId.open()"
          (valueChange)="fundId.close()"
          [disableOptionCentering]="true"
          formControlName="fundId"
          panelClass="mat-select-position"
          (change)="fundChange($event)"
        >
          <ng-option *ngFor="let fn of fundNameList" [value]="fn.id">
            <div class="clamp" title="{{ fn.name }}">{{ fn.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="d-flex mr-40">
      <div class="form-field">
        <p>Reference <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            maxlength="{{ maxLength.maxLength2000 }}"
            matInput
            formControlName="reference"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
