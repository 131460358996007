import { ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class QuotationModel {
  id: Guid;
  entryNumber?: number;
  entryDate?: string;
  currencyId: number;
  accountId: Guid;
  note: string;
  bookAccountId?: Guid;
  fundId?: Guid;
  isVatIncluded: boolean;
  quotationItems: Array<ProductDetailsModel>;
}
