<div
  class="mat-dialog-wrapper form-list-content p-0 mb-4 task-details"
  *ngIf="!showAddClient"
>
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      <span> Assign Charity </span>
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>

    <div class="content-header form-list-content sidenav-search">
      <div class="content-header-input">
        <button
          class="primary-button"
          type="button"
          mat-button
          disableRipple
          (click)="addClient()"
        >
          <mat-icon>add</mat-icon>
          <span>Add Charity</span>
        </button>
        <mat-form-field appearance="fill" class="search-field w-270">
          <input
            matInput
            placeholder="Search"
            autocomplete="off"
            (keyup)="onSearch($event)"
          />
          <mat-icon matSuffix class="font-size-16">search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <app-user-clients
      *ngIf="getModuleId === moduleIdEnum.Users"
      [displayedUserClientColumns]="displayedUserClientColumns"
      [triggerDataFromHeader]="triggerDataFromHeader"
      [triggerReloadList]="triggerReloadList"
      [showAssignClients]="true"
    ></app-user-clients>
  </div>
</div>

<div class="action-wrapper" *ngIf="!showAddClient">
  <div class="content-body">
    <div class="d-flex">
      <button
        class="action-button primary-button mr-1"
        (click)="onSaveClick()"
        type="button"
        mat-button
        disableRipple
      >
        Save
      </button>
      <button
        class="action-button secondary-button"
        (click)="onCloseClick()"
        type="button"
        mat-button
        disableRipple
      >
        Cancel
      </button>
    </div>
  </div>
</div>

<div
  class="mat-dialog-wrapper form-list-content mb-4 p-0 task-details"
  *ngIf="showAddClient"
>
  <div>
    <div class="back-button-wrapper sidenav-body-content pb-0">
      <button
        class="back-button"
        mat-button
        disableRipple
        (click)="onBackLinkClick()"
      >
        <mat-icon fontSet="material-icons-outlined">arrow_circle_left</mat-icon>
        <span class="hyperlink" *ngIf="this.getModuleId === moduleIdEnum.Users"
          >Back to User</span
        >
      </button>
    </div>
    <div class="mb-1">
      <app-add-company
        [isFromAssignClient]="true"
        [triggerAddClient]="triggerAddClient"
        [triggerSaveClient]="triggerSaveClient"
        [triggerCancelClient]="triggerCancelClient"
      >
      </app-add-company>
    </div>
    <div class="action-wrapper">
      <div class="sidenav-body-content">
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="onSaveClient()"
        >
          Save
        </button>
        <button
          class="action-button secondary-button"
          type="button"
          mat-button
          disableRipple
          (click)="onCancelClient()"
        >
          Cancel Changes
        </button>
      </div>
    </div>
  </div>
</div>
