import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  GetDashboardExpenseByCategoriesOverview,
  GetDonationsInKindOverview,
  GetExpenditureExpenseBreakdownOverview,
  GetFixAssestAgeingSummaryOverview,
} from '@app/core/Store';
import { Modules } from '@app/core/Enum';
import { Store } from '@ngxs/store';

import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexResponsive,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type expensesChartOptionv1 = {
  noData: ApexNoData;
  series: any;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  lines: ApexGrid;
};

@Component({
  selector: 'app-dashboard-expenses-v1',
  templateUrl: './dashboard-expenses-v1.component.html',
  styleUrls: ['./dashboard-expenses-v1.component.scss'],
})
export class DashboardExpensesComponentv1 implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public expensesChartOptionv1: Partial<expensesChartOptionv1>;

  @Input() triggerHeaderFilter: Observable<any>;

  @Input() triggerHeaderFilterForExpenseBreakdown: Observable<any>;

  @Input() triggerHeaderFilterForValueByAssetType: Observable<any>;

  @Input()
  getModuleId: number;

  isNoRecord = true;
  isloadData = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (
        this.getModuleId === Modules.Dashboard ||
        this.getModuleId === Modules.DonationOverview
      ) {
        this.getData(filterData);
      }
    });

    this.triggerHeaderFilterForExpenseBreakdown?.subscribe((filterData) => {
      if (this.getModuleId === Modules.ExpenditureOverview) {
        this.getData(filterData);
      }
    });

    this.triggerHeaderFilterForValueByAssetType?.subscribe((filterData) => {
      if (this.getModuleId === Modules.FixedAssetOverview) {
        this.getData(filterData);
      }
    });
  }

  makeData(overviewData: any): any {
    const categories: any = [];

    overviewData.series.forEach((element, i) => {
      let param: any;
      param = element.name.split(' ');
      categories.push(param);
    });
    return categories;
  }

  makeSeriesData(overviewData: any): any {
    const series: any = [];
    let count = 0;

    overviewData.series.forEach((element, i) => {
      series.push(element.data);
      if (element.data === 0) {
        count = count + 1;
      }
    });

    this.isNoRecord = count === overviewData.series.length ? true : false;
    return series;
  }

  setSeriesData(data: any): any {
    let series: any[] = [];
    let count = 0;

    data.forEach((element) => {
      series.push(element.data);
      if (element.data === 0) {
        count = count + 1;
      }
    });

    this.isNoRecord = count === data.length ? true : false;
    return series;
  }

  setCategoryData(data: any): any {
    let categories: any[] = [];
    data.forEach((element) => {
      let param: any;
      param = element.name.split(' ');
      categories.push(param);
    });
    return categories;
  }

  getData(filterData?: any): void {
    if (+this.getModuleId === Modules.Dashboard) {
      this.store
        .dispatch(new GetDashboardExpenseByCategoriesOverview(filterData))
        .subscribe((res) => {
          this.isloadData = true;
          let overViewData = res.dashboard.dashboardExpenseByCategoriesOverview;
          let seriesData;

          this.isNoRecord = overViewData.series.length === 0;

          if (overViewData.series.length > 0) {
            seriesData = this.setSeriesData(overViewData.series);
          }

          let categories = this.setCategoryData(overViewData.categories);

          this.expensesChartOptionv1 = {
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
            chart: {
              height: 300,
              type: 'bar',
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            noData: {
              text: 'No Data to Display',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: '#6d41a1',
                fontSize: '18px',
              },
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              labels: {
                show: true,
                rotate: 0,
              },
              categories: categories,
            },
            yaxis: {
              labels: {
                formatter: (val) => {
                  return '£ ' + Number(val) / 1000 + 'K';
                },
              },
            },
            legend: {
              show: false,
              offsetX: 0,
              offsetY: 8,
            },
            fill: {
              colors: [
                '#7B76D3',
                '#F4C64D',
                '#F2816F',
                '#BA5397',
                '#7A5295',
                '#B9CE64',
                '#92BDFF',
              ],
            },
            tooltip: {
              marker: {
                show: false,
              },
              style: {
                fontFamily: '"Source Sans Pro", sans-serif',
                fontSize: '14px',
              },
              y: {
                formatter: (val) => {
                  return '£ ' + Number(val) * 1 + '';
                },
              },
              enabled: true,
              fillSeriesColor: false,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          };
        });
    } else if (+this.getModuleId === Modules.ExpenditureOverview) {
      this.store
        .dispatch(new GetExpenditureExpenseBreakdownOverview(filterData))
        .subscribe((res) => {
          this.isloadData = true;
          let expensesChart = res.bill.expenditureExpenseBreakdownOverview;
          let expensesSeriesData;

          this.isNoRecord = expensesChart.series.length === 0;

          if (expensesChart.series.length > 0) {
            expensesSeriesData = this.makeSeriesData(expensesChart);
          }

          let expensesCategoryData = this.makeData(expensesChart);

          this.expensesChartOptionv1 = {
            series: [
              {
                name: '',
                data: expensesSeriesData,
              },
            ],
            chart: {
              height: 300,
              type: 'bar',
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            noData: {
              text: 'No Data to Display',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: '#6d41a1',
                fontSize: '18px',
              },
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            yaxis: {
              labels: {
                formatter: (val) => {
                  return '£ ' + Number(val) / 1000 + 'K';
                },
              },
            },
            xaxis: {
              labels: {
                show: true,
                rotate: 0,
                trim: true,
              },
              categories: expensesCategoryData,
            },
            legend: {
              show: false,
              offsetX: 0,
              offsetY: 8,
            },
            fill: {
              colors: [
                '#7B76D3',
                '#F4C64D',
                '#F2816F',
                '#BA5397',
                '#7A5295',
                '#B9CE64',
                '#92BDFF',
              ],
            },
            tooltip: {
              marker: {
                show: false,
              },
              style: {
                fontFamily: '"Source Sans Pro", sans-serif',
                fontSize: '14px',
              },
              y: {
                formatter: (val) => {
                  return '£ ' + Number(val) * 1 + '';
                },
              },
              enabled: true,
              fillSeriesColor: false,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          };
        });
    } else if (+this.getModuleId === Modules.FixedAssetOverview) {
      this.store
        .dispatch(new GetFixAssestAgeingSummaryOverview(filterData))
        .subscribe((res) => {
          this.isloadData = true;
          let fixedAssetChart = res.fixedAssets.fixAssestAgeingSummaryOverview;
          let fixedAssetSeriesData;

          this.isNoRecord = fixedAssetChart.series.length === 0;

          if (fixedAssetChart.series.length > 0) {
            fixedAssetSeriesData = this.makeSeriesData(fixedAssetChart);
          }

          let fixedAssetCategoryData = this.makeData(fixedAssetChart);
          this.expensesChartOptionv1 = {
            series: [
              {
                name: '',
                data: fixedAssetSeriesData,
              },
            ],
            chart: {
              height: 300,
              type: 'bar',
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                columnWidth: '35%',
                distributed: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            noData: {
              text: 'No Data to Display',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: '#6d41a1',
                fontSize: '18px',
              },
            },
            yaxis: {
              labels: {
                formatter: (val) => {
                  return '£ ' + Number(val) / 1000 + 'K';
                },
              },
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              labels: {
                show: true,
                rotate: 0,
              },
              categories: fixedAssetCategoryData,
            },
            legend: {
              show: false,
              offsetX: 0,
              offsetY: 8,
            },
            fill: {
              colors: [
                '#7B76D3',
                '#F4C64D',
                '#F2816F',
                '#BA5397',
                '#7A5295',
                '#92BDFF',
              ],
            },
            tooltip: {
              marker: {
                show: false,
              },
              style: {
                fontFamily: '"Source Sans Pro", sans-serif',
                fontSize: '14px',
              },
              y: {
                formatter: (val) => {
                  return '£ ' + Number(val) * 1 + '';
                },
              },
              enabled: true,
              fillSeriesColor: false,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          };
        });
    } else if (+this.getModuleId === Modules.DonationOverview) {
      this.store
        .dispatch(new GetDonationsInKindOverview(filterData))
        .subscribe((res) => {
          this.isloadData = true;
          let expensesChart = res.donation.donationsInKindOverview;
          let expensesSeriesData;

          this.isNoRecord = expensesChart.series.length === 0;

          if (expensesChart.series.length > 0) {
            expensesSeriesData = this.makeSeriesData(expensesChart);
          }

          let expensesCategoryData = this.makeData(expensesChart);

          this.expensesChartOptionv1 = {
            series: [
              {
                name: '',
                data: expensesSeriesData,
              },
            ],
            chart: {
              height: 300,
              type: 'bar',
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                columnWidth: '35%',
                distributed: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            noData: {
              text: 'No Data to Display',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: '#6d41a1',
                fontSize: '18px',
              },
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              labels: {
                show: true,
                rotate: 0,
              },
              categories: expensesCategoryData,
            },
            yaxis: {
              labels: {
                formatter: (val) => {
                  return '£ ' + Number(val) / 1000 + 'K';
                },
              },
            },
            legend: {
              show: false,
              offsetX: 0,
              offsetY: 8,
            },
            fill: {
              colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
            },
            tooltip: {
              marker: {
                show: false,
              },
              style: {
                fontFamily: '"Source Sans Pro", sans-serif',
                fontSize: '14px',
              },
              y: {
                formatter: (val) => {
                  return '£ ' + Number(val) * 1 + '';
                },
              },
              enabled: true,
              fillSeriesColor: false,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          };
        });
    }
  }
}
