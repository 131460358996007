<div class="header">
  <div class="d-flex align-items-center justify-space-between wp-100">
    <div class="d-flex align-items-center">
      <div class="logo-container">
        <a [routerLink]="'/'">
          <img src="assets/images/logo.png" alt="Logo" />
        </a>
      </div>
      <div class="nav-container">
        <div class="navigation-menu" *ngIf="(companyCount$ | async) > 0">
          <a [matMenuTriggerFor]="company" (click)="getCompanyList()">
            {{ companyName }}
            <mat-icon>expand_more</mat-icon>
          </a>
          <mat-menu
            #company="matMenu"
            class="action-menu company-list-menu main-wrapper"
            xPosition="after"
          >
            <div>
              <div class="company-list-header">
                <div class="d-flex align-items-center justify-space-between">
                  <mat-form-field
                    appearance="fill"
                    class="search-field w-300"
                    (click)="$event.stopPropagation()"
                  >
                    <input
                      matInput
                      placeholder="Search by Charity"
                      autocomplete="off"
                      [(ngModel)]="searchText"
                      (keyup.enter)="onSearch()"
                      (ngModelChange)="onSearchAll()"
                    />
                    <button
                      mat-button
                      class="search-button"
                      (click)="onSearch()"
                    >
                      <mat-icon>search</mat-icon>
                    </button>
                  </mat-form-field>
                  <button
                    class="action-button primary-button"
                    mat-button
                    disableRipple
                    (click)="onNewCompanyAdded()"
                  >
                    <mat-icon>add</mat-icon>
                    <span>Add New</span>
                  </button>
                </div>
              </div>
              <div class="company-list-body">
                <div
                  class="mat-table-wrapper"
                  (click)="$event.stopPropagation()"
                >
                  <mat-radio-group>
                    <table
                      aria-describedby="company-list"
                      mat-table
                      matSort
                      (matSortChange)="sorting(sort.active, sort.direction)"
                      [dataSource]="companyList"
                    >
                      <ng-container matColumnDef="default">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          class="w-100 text-align-center"
                        >
                          Default
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element; let i = index"
                          class="text-align-center"
                        >
                          <mat-radio-button
                            [value]="i"
                            [checked]="element.isDefault"
                            disableRipple
                            (click)="onChangeCompany(element)"
                          >
                          </mat-radio-button>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="companyname">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                          Charity Name
                        </th>
                        <td mat-cell *matCellDef="let companyList; let element">
                          <div class="clamp">
                            <a
                              class="link fw-bold"
                              (click)="onChangeCompany(element)"
                              title="{{ companyList.companyName }}"
                              ><span>{{ companyList.companyName }}</span></a
                            >
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="charityRegulator">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                          Charity Regulator
                        </th>
                        <td mat-cell *matCellDef="let companyList">
                          <div class="clamp">
                            <span title="{{ companyList.charityRegulator }}">{{
                              companyList.charityRegulator
                            }}</span>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="vatnumber">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                          VAT Number
                        </th>
                        <td mat-cell *matCellDef="let companyList">
                          <div
                            class="d-flex align-items-center justify-space-between"
                          >
                            <div class="clamp">
                              <span>{{ companyList.vatNumber }}</span>
                            </div>
                            <div class="d-flex align-items-center grid-actions">
                              <div class="d-flex align-items-center">
                                <button
                                  mat-button
                                  (click)="onEditClick(companyList.id)"
                                  disableRipple
                                >
                                  <mat-icon fontSet="material-icons-outlined"
                                    >edit</mat-icon
                                  >
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                      ></tr>
                    </table>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>
    </div>
    <form [formGroup]="headerForm" class="p-0">
      <div class="account-container">
        <div class="account-menu">
          <ul class="d-flex align-items-center">
            <li *ngIf="(companyCount$ | async) > 0">
              <div class="d-flex align-items-center header-search-wrapper">
                <a (click)="onSearchClick()" *ngIf="!isSearchboxShow">
                  <mat-icon title="search">search</mat-icon>
                </a>
                <div class="header-search" *ngIf="isSearchboxShow">
                  <mat-form-field appearance="fill" class="header-search-field">
                    <input
                      matInput
                      placeholder="Search for anything"
                      [matAutocomplete]="auto"
                      formControlName="searchText"
                      (keyup)="onSearchModule($event)"
                      (keydown.Tab)="onSearchName($event)"
                      (change)="onSearchName($event)"
                      #trigger="matAutocompleteTrigger"
                      appAutocompletePosition="trigger"
                    />
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      (opened)="scrollIntoView()"
                      (optionSelected)="onSearchModuleSelection($event)"
                      #input
                    >
                      <mat-option
                        *ngFor="let list of menuData"
                        [value]="list.url"
                        title="{{ list.name }}"
                      >
                        <span title="{{ list.name }}">{{ list.name }}</span>
                      </mat-option>
                    </mat-autocomplete>
                    <mat-icon title="search" matPrefix class="text-white"
                      >search</mat-icon
                    >
                    <a (click)="onCloseClick()" matSuffix>
                      <mat-icon title="close">close</mat-icon>
                    </a>
                  </mat-form-field>
                </div>
              </div>
            </li>
            <li *ngIf="(companyCount$ | async) > 0">
              <a title="Quick Add">
                <mat-icon
                  class="mat-settings-icon"
                  fontSet="material-icons-outlined"
                  [matMenuTriggerFor]="addSettings"
                >
                  add_circle_outline</mat-icon
                >
              </a>
              <mat-menu
                #addSettings="matMenu"
                class="action-menu add-setting-menu"
                xPosition="before"
              >
                <div class="main-menu-list d-flex gap-20">
                  <div
                    *ngFor="let menuItem of addMenuList"
                    class="menu-list-item"
                  >
                    <div
                      class="main-menu-header d-flex gap-5 align-items-center"
                    >
                      <mat-icon
                        class="mat-settings-icon"
                        fontSet="material-icons-outlined"
                        >{{ menuItem.icon }}
                      </mat-icon>
                      <span>{{ menuItem.name }}</span>
                    </div>
                    <div class="sub-menu-list">
                      <div
                        *ngFor="let subMenu of menuItem.subMenu"
                        class="sub-menu-list-item"
                      >
                        <a
                          routerLink="{{ subMenu.addUrl }}"
                          class="d-flex align-items-center gap-5"
                        >
                          <mat-icon class="add-icon">add </mat-icon>
                          {{ subMenu.name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </li>
            <li class="module-list separation pl-1">
              <a
                href="javascript:void(0)"
                [matMenuTriggerFor]="capiumMenu"
                title="Modules"
              >
                <div class="m-0">
                  <img
                    src="assets/images/module-icon.png"
                    width="18"
                    height="18"
                    class="capium-logo"
                    alt="Capium Logo"
                  />
                </div>
              </a>
              <mat-menu
                #capiumMenu="matMenu"
                class="action-menu dropdown-module-list"
                xPosition="after"
                [overlayPanelClass]="'custom-overlay-panel'"
              >
                <div class="justify-content-between">
                  <ul>
                    <ng-container
                      *ngFor="let subscriptionModule of subscriptionModuleList"
                    >
                      <li
                        [ngClass]="
                          subscriptionModule.isDisabled ? 'disable-box' : ''
                        "
                        *ngIf="subscriptionModule.isDisplay"
                      >
                        <a
                          [href]="appUrl ? appUrl : '#'"
                          (click)="redirectToModule(subscriptionModule, $event)"
                        >
                          <div class="top-card d-flex gap-10">
                            <img
                              [src]="subscriptionModule.iconSrc"
                              alt="logo"
                            />
                            <div>
                              <h6>{{ subscriptionModule.externalName }}</h6>
                              <p>{{ subscriptionModule.content }}</p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                  <div class="top-switch-button text-align-right">
                    <button
                      type="button"
                      class="mat-focus-indicator action-button primary-button mat-button mat-button-base"
                      (click)="redirectToHome()"
                    >
                      <span class="mat-button-wrapper">
                        <img
                          src="assets/images/capihome.svg"
                          alt="Icon"
                          class="pr-5"
                        />
                        <span _ngcontent-krt-c167="">Home </span>
                      </span>
                      <span class="mat-ripple mat-button-ripple"> </span>
                      <span class="mat-button-focus-overlay"></span>
                    </button>
                    <button
                      type="button"
                      class="mat-focus-indicator action-button secondary-button ml-1 mat-button mat-button-base"
                    >
                      <span class="mat-button-wrapper">
                        <a (click)="myAdminClick()">
                          <img
                            src="assets/images/myadmin.svg"
                            alt="Icon"
                            class="pr-5"
                          />
                          <span>My Admin</span>
                        </a>
                      </span>
                      <span
                        matripple=""
                        class="mat-ripple mat-button-ripple"
                      ></span>
                      <span class="mat-button-focus-overlay"></span>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </li>
            <li>
              <a [routerLink]="'/ticket'" title="Ticket">
                <mat-icon class="rotate" fontSet="material-icons-outlined"
                  >confirmation_number</mat-icon
                >
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                class="profile-setting-icon p-0"
                title="Help"
                (click)="onHelpClick()"
              >
                <mat-icon>help_outline</mat-icon>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                [matMenuTriggerFor]="profileInfo"
                title="User Profile"
              >
                <div
                  *ngIf="
                    userData?.picture !== undefined &&
                    userData?.picture !== null
                  "
                >
                  <img
                    class="profile-image-thumb"
                    [src]="userData?.picture"
                    width="35"
                    height="35"
                    alt="User photo"
                  />
                </div>
                <div
                  *ngIf="
                    userData?.picture === undefined ||
                    userData?.picture === null
                  "
                  class="rounded-border m-0"
                  title="User Image"
                >
                  {{ userName | shortName: 2 }}
                </div>
              </a>
              <mat-menu
                #profileInfo="matMenu"
                class="action-menu profile-setting-menu"
                xPosition="after"
              >
                <div class="text-align-center profile-settings">
                  <div
                    class="mb-1"
                    *ngIf="
                      userData?.picture !== undefined &&
                      userData?.picture !== null
                    "
                  >
                    <img
                      class="profile-image"
                      [src]="userData?.picture"
                      alt="User photo"
                    />
                  </div>
                  <div
                    *ngIf="
                      userData?.picture === undefined ||
                      userData?.picture === null
                    "
                    class="rounded-border"
                  >
                    {{ userName | shortName: 2 }}
                  </div>

                  <div>
                    <p
                      class="fw-bold text-transform-uppercase text-gray-100 font-size-16 mb-10 m-0"
                    >
                      {{ userName }}
                    </p>
                  </div>
                  <div>
                    <p class="text-gray-200 mb-10 m-0">User ID: {{ userId }}</p>
                  </div>
                  <div>
                    <p class="text-gray-200 mb-2 m-0">{{ userEmail }}</p>
                  </div>
                  <div class="hr pt-1">
                    <a href="javascript:void(0)" (click)="myAdminClick()">
                      <span class="text-gray-100 font-size-16">My Account</span>
                    </a>
                    <span class="pl-2 pr-2 text-gray-100">|</span>
                    <a href="javascript:void(0)" (click)="signOutUser()">
                      <span class="text-danger-100 font-size-16">Sign Out</span>
                    </a>
                  </div>
                  <div class="pt-1 pb-5" *ngIf="isSubscriptionPortalEnabled">
                    <a
                      href="javascript:void(0)"
                      (click)="produdctSubscriptionClick()"
                      class="link"
                    >
                      <span class="text-primary font-size-16"
                        >Products & Subcriptions</span
                      >
                    </a>
                  </div>
                  <div class="theme-colors pt-1">
                    <mat-radio-group
                      formControlName="colorSelected"
                      class="mt-10"
                    >
                      <mat-radio-button
                        *ngFor="let color of colors"
                        [value]="color"
                        ngDefaultControl
                        (change)="onSelectionChange($event.value, true)"
                        [ngClass]="{
                          'mat-radio-checked': colorSelected === color
                        }"
                      >
                        <button
                          class="theme-selector"
                          [ngStyle]="{ 'background-color': color }"
                          type="button"
                          mat-button
                          disableRipple
                        >
                          <mat-icon>check</mat-icon>
                        </button>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </mat-menu>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</div>
<app-custom-notifications></app-custom-notifications>
