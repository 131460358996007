import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  CharityRegulator,
  MaxLength,
  NotificationTextMessage,
} from '@app/core/Enum';
import { TradingStatus } from '@app/core/Enum/trading-status';
import {
  AccountingPeriodModel,
  CompanyAddressDetailModel,
  CompanyContactDetailModel,
  CompanyModel,
  CompanyRegionalDetailModel,
  Country,
  Currency,
  FileUploadRequestModel,
  FileUploadResponseModel,
  SideListModel,
  VatRateScheme,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  CountryState,
  CreateCompany,
  FileUpload,
  GetAccountingMethodList,
  GetCharityRegulator,
  GetCountryList,
  GetCurrencyList,
  GetLanguage,
  GetTimeZone,
  GetVatReturnTypeList,
  GetVatSchemeList,
  MenuState,
} from '@app/core/Store';
import { ScottishRegulatorPopupComponent } from '@app/modules';
import {
  AddClosePopupComponent,
  AddFinancialYearComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
})
export class AddCompanyComponent implements OnInit, OnDestroy {
  isRegisteredForVat = false;
  keepBillingDetailSeparate = false;
  isCompanyAddress = false;
  isRegional = false;
  isImageSelected = false;
  isAddMode = true;
  tooggleFlag: boolean;
  companyImage: string;
  tooggleText = 'No';
  GUID: any;
  url: any;
  selectedFile: File;
  specialCar = '"!@#$%^&*():"<>?{}|[]"';

  companyForm: FormGroup;
  addressDetail: CompanyAddressDetailModel;
  billingDetail: CompanyAddressDetailModel;
  contactDetail: CompanyContactDetailModel;
  regionalDetail: CompanyRegionalDetailModel;
  fileUploadResponse: FileUploadResponseModel;
  fileUploadRequest: FileUploadRequestModel;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  countryList: Country[];
  currencyList: Currency[];
  vatReturnTypeList: SideListModel[];
  charityRegulator: SideListModel[];
  timezone: SideListModel[];
  languageList: SideListModel[];
  accountingMethodList: SideListModel[];
  vatSchemeList: VatRateScheme[];
  fileList: any[] = [];

  acceptedFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];
  assigncompanyData: CompanyModel;
  tradingStatus = [
    {
      id: TradingStatus.Active,
      name: TradingStatus[TradingStatus.Active],
    },
    {
      id: TradingStatus.Dissolved,
      name: TradingStatus[TradingStatus.Dissolved],
    },
  ];

  @Input() triggerEditData: Observable<any>;

  @ViewChild(AddFinancialYearComponent, { static: false })
  financialYearDetail;

  triggerEditFinancialData: Subject<any> = new Subject<any>();

  @Input()
  triggerAddClient: Observable<any>;

  @Input()
  triggerSaveClient: Observable<any>;

  @Input()
  triggerCancelClient: Observable<any>;

  @Input()
  isFromAssignClient = false;
  saveClientSubscription: Subscription;
  cancelClientSubscription: Subscription;
  addClientSubscription: Subscription;
  id = Guid.EMPTY as unknown as Guid;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private store: Store,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public commonService: CommonService,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      this.isAddMode = false;
      this.companyImage = '';
      this.editCompanyDetails(data);
      this.triggerEditFinancialData.next(data.accountingPeriods);
    });

    this.saveClientSubscription = this.triggerSaveClient?.subscribe(
      (isExits) => {
        this.onSave();
      }
    );

    this.cancelClientSubscription = this.triggerCancelClient?.subscribe(() => {
      this.onCancel(true);
    });

    this.addClientSubscription = this.triggerAddClient?.subscribe((res) => {
      this.id = Guid.EMPTY as unknown as Guid;
    });

    this.companyForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.companyForm.touched;
    });
  }

  onCancel(isReset?: boolean): void {
    this.commonService.isInitialValueChange = false;
    this.id = Guid.EMPTY as unknown as Guid;
    this.url = '';
    this.companyImage = '';
    this.isImageSelected = false;
    this.companyForm.reset();
    this.ngOnInit();
    // }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      let accountingPeriods: any[] = [];
      this.financialYearDetail.financialYearArray
        ?.getRawValue()
        .forEach((element) => {
          if (element.id || (element.startDate && element.endDate)) {
            let data: AccountingPeriodModel = {
              id: element.id,
              fromDate: this.datepipe
                .transform(element.startDate, 'yyyy-MM-dd')
                ?.toString(),
              toDate: this.datepipe
                .transform(element.endDate, 'yyyy-MM-dd')
                ?.toString(),
              isLocked: element.isLocked,
            };
            accountingPeriods.push(data);
          }
        });
      this.addressDetail = {
        address1: this.companyForm.controls.address1.value,
        address2: this.companyForm.controls.address2.value,
        address3: this.companyForm.controls.address3.value,
        city: this.companyForm.controls.city.value,
        country: this.companyForm.controls.country.value,
        postalCode: this.companyForm.controls.postalCode.value,
      };

      if (this.companyForm.controls.keepBillingDetailSeparate.value) {
        this.billingDetail = {
          address1: this.companyForm.controls.address1BillingSeparate.value,
          address2: this.companyForm.controls.address2BillingSeparate.value,
          address3: this.companyForm.controls.address3BillingSeparate.value,
          city: this.companyForm.controls.cityBillingSeparate.value,
          country: this.companyForm.controls.countryBillingSeparate.value,
          postalCode: this.companyForm.controls.postalCodeBillingSeparate.value,
        };
      }
      let vatDetail;
      if (this.isRegisteredForVat) {
        vatDetail = {
          vatRegistrationNo: this.companyForm.controls.vatRegistrationNo.value,
          vatRegistrationDate:
            this.companyForm.controls.vatRegistrationDate.value,
          vatSchemeId: +this.companyForm.controls.vatSchemeId.value,
          vatReturnTypeId: +this.companyForm.controls.vatReturnTypeId.value,
        };
      }

      this.contactDetail = {
        name: this.companyForm.controls.name.value,
        phone1: this.companyForm.controls.phone1.value,
        phone2: this.companyForm.controls.phone2.value,
        email: this.companyForm.controls.email.value,
        alternateEmail: this.companyForm.controls.alternateEmail.value,
        website: this.companyForm.controls.website.value,
      };

      this.regionalDetail = {
        currencyId: +this.companyForm.controls.currency.value,
        languageId: +this.companyForm.controls.language.value,
        timezoneId: +this.companyForm.controls.timezone.value,
      };

      this.assigncompanyData = {
        id: this.id,
        logo:
          this.fileUploadResponse === undefined
            ? null
            : this.fileUploadResponse.fileUrl,
        name: this.companyForm.controls.name.value,
        charityRegulatorId: +this.companyForm.controls.charityRegulatorId.value,
        establishmentDate: this.datepipe
          .transform(
            this.companyForm.controls.establishDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        registrationNo: this.companyForm.controls.registrationNo.value,
        principalPurpose: this.companyForm.controls.principalPurpose.value,
        companyNo: this.companyForm.controls.companyNo.value,
        keepBillingDetailSeparate:
          this.companyForm.controls.keepBillingDetailSeparate.value,
        isCIO: this.companyForm.controls.isCIO.value,
        tradingStatus:
          this.companyForm.controls.tradingStatusType.value === 1
            ? true
            : false,
        addressDetail: this.addressDetail,
        billingDetail: this.billingDetail,
        contactDetail: this.contactDetail,
        regionalDetail: this.regionalDetail,
        vatDetail: this.isRegisteredForVat ? vatDetail : undefined,
        accountingMethodId: +this.companyForm.controls.accountingMethodId.value,
        accountingPeriods: accountingPeriods,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  scrollToFirstInvalidControl() {
    let form = document.getElementById('companyFormId');
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }

  onSave(): void {
    if (this.companyForm.invalid) {
      this.scrollToFirstInvalidControl();
      this.companyForm.markAllAsTouched();
    } else if (this.financialYearDetail.financialYearArray.invalid) {
      this.financialYearDetail.financialYearArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateCompany(this.assigncompanyData))
          .subscribe(
            (res) => {
              if (res !== undefined) {
                res.company.companyId;
                this.commonService.isInitialValueChange = false;
                this.onCancel();
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  ngOnDestroy(): void {
    this.saveClientSubscription?.unsubscribe();
    this.cancelClientSubscription?.unsubscribe();
    this.addClientSubscription?.unsubscribe();
  }

  editCompanyDetails(data: any): void {
    if (data.logo) {
      this.isImageSelected = true;
      this.companyImage = data.logo;
    }

    this.keepBillingDetailSeparate = data.keepBillingDetailSeparate;

    this.isRegisteredForVat = !(
      data.vatDetail === null || data.vatDetail === undefined
    );

    this.tooggleText = this.isRegisteredForVat ? 'Yes' : 'No';
    this.tooggleFlag = this.isRegisteredForVat ? true : false;

    this.companyForm.patchValue({
      name: data.name,
      charityRegulatorId: data.charityRegulatorId,
      tradingStatusType: data.tradingStatus === true ? 1 : 0,
      establishDate: data.establishmentDate,
      registrationNo: data.registrationNo,
      companyNo: data.companyNo,
      principalPurpose: data.principalPurpose,
      country: data.addressDetail.country,
      address1: data.addressDetail.address1,
      address2: data.addressDetail.address2,
      address3: data.addressDetail.address3,
      city: data.addressDetail.city,
      postalCode: data.addressDetail.postalCode,
      keepBillingDetailSeparate: data.keepBillingDetailSeparate,
      contactPerson: data.contactDetail.name,
      phone1: data.contactDetail.phone1,
      phone2: data.contactDetail.phone2,
      email: data.contactDetail.email,
      alternateEmail: data.contactDetail.alternateEmail,
      website: data.contactDetail.website,
      currency: data.regionalDetail.currencyId,
      language: data.regionalDetail.languageId,
      timezone: data.regionalDetail.timezoneId,
      state: data.addressDetail.state,
      address1BillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.address1,
      address2BillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.address2,
      cityBillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.city,
      address3BillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.address3,
      countryBillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.country,
      postalCodeBillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.postalCode,
      vatRegistrationNo:
        data.vatDetail === null || data.vatDetail === undefined
          ? null
          : data.vatDetail.vatRegistrationNo,
      vatRegistrationDate:
        data.vatDetail === null || data.vatDetail === undefined
          ? null
          : data.vatDetail.vatRegistrationDate,
      vatSchemeId:
        data.vatDetail === null || data.vatDetail === undefined
          ? null
          : data.vatDetail.vatSchemeId,
      vatReturnTypeId:
        data.vatDetail === null || data.vatDetail === undefined
          ? null
          : data.vatDetail.vatReturnTypeId,
      accountingMethodId: data.accountingMethodId,
      startDate: data.startDate,
      endDate: data.endDate,
      isCIO: data.isCIO,
    });

    this.ValidationForVatRegistration();
  }

  ValidationForVatRegistration(): void {
    const vatRegistrationNoControl = this.companyForm.get('vatRegistrationNo');
    if (this.isRegisteredForVat) {
      if (vatRegistrationNoControl) {
        vatRegistrationNoControl.setValidators([
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
          Validators.pattern(/^\d{9}$/),
        ]);

        vatRegistrationNoControl.updateValueAndValidity();
      }
    } else {
      if (vatRegistrationNoControl) {
        vatRegistrationNoControl.clearValidators();
        vatRegistrationNoControl.reset();
        vatRegistrationNoControl.updateValueAndValidity();
      }
    }
  }

  addCompanyAddress(isCompanyAddress: boolean): void {
    this.isCompanyAddress = !isCompanyAddress;
  }

  addRegionalInfo(isRegional: boolean): void {
    this.isRegional = !isRegional;
  }

  getCountry(): void {
    this.store
      .dispatch(new GetCountryList())
      .pipe(
        tap(() => {
          this.countryList = this.store.selectSnapshot(CountryState.getCountry);
          if (this.isAddMode && this.countryList.length > 0) {
            this.companyForm.controls.country.setValue(
              this.countryList[205].id
            );
            this.companyForm.controls.countryBillingSeparate.setValue(
              this.countryList[205].id
            );
          }
        })
      )
      .subscribe();
  }

  getAccountingMethod(): void {
    this.store
      .dispatch(new GetAccountingMethodList())
      .pipe(
        tap((res) => {
          this.accountingMethodList = res.company.accountingMethod;

          if (this.isAddMode && this.accountingMethodList.length > 0) {
            this.companyForm.controls.accountingMethodId.setValue(
              this.accountingMethodList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  getCurrency(): void {
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );

          if (this.isAddMode && this.currencyList.length > 0) {
            this.companyForm.controls.currency.setValue(
              this.currencyList[122].id
            );
          }
        })
      )
      .subscribe();
  }

  getVatReturnType(): void {
    this.store
      .dispatch(new GetVatReturnTypeList())
      .pipe(
        tap((res) => {
          this.vatReturnTypeList = res.company.vatReturnTypeList;
          if (this.isAddMode && this.vatReturnTypeList.length > 0) {
            this.companyForm.controls.vatReturnTypeId.setValue(
              this.vatReturnTypeList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  registeredForVat(): void {
    this.isRegisteredForVat = !this.isRegisteredForVat;
    this.tooggleText = this.isRegisteredForVat ? 'Yes' : 'No';
    this.ValidationForVatRegistration();
  }

  onFileSelected(event: any): void {
    if (!event.target.files || !event.target.files.length) {
      return;
    }
    const file = event.target.files[0];
    const validTypes = ['image/jpeg', 'image/png'];

    if (!validTypes.includes(file.type)) {
      this.commonService.onFailure('Only JPG and PNG files are allowed.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > 300 || img.height > 100) {
          this.commonService.onFailure(
            'Logo dimensions should not exceed 300px width and 100px height.'
          );
          return;
        }

        this.isImageSelected = true;
        this.selectedFile = event.target.files[0];
        this.saveFile(event.target.files[0]);
        this.url = reader.result;
      };
      img.onerror = () => {
        this.commonService.onFailure('Invalid image file.');
        return;
      };
      img.src = e.target.result;
    };
    reader.onerror = () => {
      this.commonService.onFailure('Error reading file.');
      return;
    };
    reader.readAsDataURL(file);
  }

  saveFile(file: File): void {
    this.fileList = [];
    this.fileList.push(file);

    this.fileUploadRequest = {
      file: this.fileList,
      attachmentType: 1,
    };

    this.store
      .dispatch(new FileUpload(this.fileUploadRequest))
      .pipe(
        tap((data) => {
          this.fileUploadResponse = data.common.fileUploadRequestModel[0];
        })
      )
      .subscribe();
  }

  showOptions(event: any): void {
    this.companyForm.controls.keepBillingDetailSeparate.setValue(event.checked);
    this.keepBillingDetailSeparate = !this.keepBillingDetailSeparate;
    this.companyForm.controls.countryBillingSeparate.setValue(
      this.countryList[205].id
    );
  }

  getVatSchemeList(): void {
    this.store
      .dispatch(new GetVatSchemeList())
      .pipe(
        tap((res) => {
          this.vatSchemeList = res.company.vatSchemeList;

          if (this.isAddMode && this.vatSchemeList.length > 0) {
            this.companyForm.controls.vatSchemeId.setValue(
              this.vatSchemeList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  setForm(): void {
    this.companyForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      charityRegulatorId: new FormControl('', [Validators.required]),
      vatReturnTypeId: new FormControl(),
      vatSchemeId: new FormControl(),
      establishDate: new FormControl(new Date()),
      registrationNo: new FormControl(''),
      companyNo: new FormControl(''),
      principalPurpose: new FormControl(''),
      country: new FormControl('', [Validators.required]),
      address1: new FormControl(''),
      address2: new FormControl(''),
      address3: new FormControl(''),
      city: new FormControl(''),
      postalCode: new FormControl(''),
      keepBillingDetailSeparate: new FormControl(false),
      countryBillingSeparate: new FormControl(''),
      address1BillingSeparate: new FormControl(''),
      address2BillingSeparate: new FormControl(''),
      address3BillingSeparate: new FormControl(''),
      cityBillingSeparate: new FormControl(''),
      postalCodeBillingSeparate: new FormControl(''),
      contactPerson: new FormControl(''),
      phone1: new FormControl(''),
      phone2: new FormControl(''),
      email: new FormControl('', [Validators.email]),
      alternateEmail: new FormControl('', [Validators.email]),
      website: new FormControl(''),
      tradingStatusType: new FormControl(TradingStatus.Active),
      currency: new FormControl({ value: '', disabled: true }),
      language: new FormControl(),
      timezone: new FormControl(),
      vatRegistrationNo: new FormControl(''),
      vatRegistrationDate: new FormControl(new Date()),
      state: new FormControl(''),
      accountingMethodId: new FormControl('', [Validators.required]),
      isCIO: new FormControl(false),
    });

    this.getCountry();
    this.getCurrency();
    this.getCharityRegulator();
    this.getTimeZone();
    this.getLanguage();
    this.getVatSchemeList();
    this.getVatReturnType();
    this.getAccountingMethod();
  }

  getCharityRegulator(): void {
    this.store
      .dispatch(new GetCharityRegulator())
      .pipe(
        tap((res) => {
          this.charityRegulator = res.company.charityRegulator;
          this.companyForm.controls.charityRegulatorId.setValue(
            this.charityRegulator[0].id
          );
        })
      )
      .subscribe();
  }

  getTimeZone(): void {
    this.store
      .dispatch(new GetTimeZone())
      .pipe(
        tap((res) => {
          this.timezone = res.company.timeZone;
          this.companyForm.controls.timezone.setValue(this.timezone[0].id);
        })
      )
      .subscribe();
  }

  getLanguage(): void {
    this.store
      .dispatch(new GetLanguage())
      .pipe(
        tap((res) => {
          this.languageList = res.company.language;
          if (this.isAddMode && this.languageList.length > 0) {
            this.companyForm.controls.language.setValue(
              this.languageList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  get accept(): string {
    return this.acceptedFileTypes?.join(', ') ?? '';
  }

  onDeletePhoto(): void {
    this.isImageSelected = false;
    this.companyImage = '';
    this.url = '';
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onAccountingChange(): void {
    this.companyForm.controls.vatSchemeId.setValue(
      this.companyForm.controls.accountingMethodId.value
    );
  }

  onVatSchemeChange(): void {
    this.companyForm.controls.accountingMethodId.setValue(
      this.companyForm.controls.vatSchemeId.value
    );
  }

  onRegulatorChange(event, showPopup: boolean = false): void {
    if (
      this.companyForm.controls.charityRegulatorId.value !==
      CharityRegulator.CharityCommissionForEnglandAndWales
    ) {
      if (showPopup) {
        this.dialog
          .open(ScottishRegulatorPopupComponent)
          .afterClosed()
          .subscribe();
      }
    }
  }
}
