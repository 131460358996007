<div id="chart" *ngIf="cashFlowChartOptions">
  <apx-chart
    [series]="cashFlowChartOptions?.series!"
    [chart]="cashFlowChartOptions?.chart!"
    [dataLabels]="cashFlowChartOptions?.dataLabels!"
    [plotOptions]="cashFlowChartOptions?.plotOptions!"
    [yaxis]="cashFlowChartOptions?.yaxis!"
    [legend]="cashFlowChartOptions?.legend!"
    [fill]="cashFlowChartOptions?.fill!"
    [stroke]="cashFlowChartOptions?.stroke!"
    [tooltip]="cashFlowChartOptions?.tooltip!"
    [xaxis]="cashFlowChartOptions?.xaxis!"
    [noData]="cashFlowChartOptions?.noData!"
  ></apx-chart>
</div>
