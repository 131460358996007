<div class="content-body">
  <form [formGroup]="donationForm">
    <div class="d-flex gap-40">
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Donor Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isDonorChangePermission"
            >
              <mat-icon (click)="gotoAddDonor()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledEditButton || !isDonorChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditDonor()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #donorName
          [disableOptionCentering]="true"
          (focus)="donorName.open()"
          (valueChange)="donorName.close()"
          formControlName="donorName"
          panelClass="mat-select-position"
          (change)="donorChange($event)"
        >
          <ng-option *ngFor="let option of donorList" [value]="option.id">
            <div class="clamp" title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field datepicker">
        <p>Donation Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="donationDate"
            [matDatepicker]="donationDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="donationDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #donationDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(donationForm.get('donationDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(donationForm.get('donationDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field" *ngIf="moduleId !== subModuleId.DonationsInKind">
        <p>Deposited To<span class="text-danger-300">*</span></p>
        <ng-select
          #depositTo
          [disableOptionCentering]="true"
          (focus)="depositTo.open()"
          (valueChange)="depositTo.close()"
          formControlName="depositTo"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let option of depositToList" [value]="option.id">
            <div class="clamp" title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="moduleId === subModuleId.DonationsInKind">
        <p>Donation Type <span class="text-danger-300">*</span></p>
        <ng-select
          #donationType
          (focus)="donationType.open()"
          (valueChange)="donationType.close()"
          [disableOptionCentering]="true"
          formControlName="donationType"
          panelClass="mat-select-position"
          (change)="onDonationTypeChanges($event)"
          required
        >
          <ng-option *ngFor="let cl of donationTypeList" [value]="cl.id">
            <div class="clamp" title=" {{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Fund Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isFundChangePermission"
            >
              <mat-icon (click)="gotoAddFund()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledFundEditButton || !isFundChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditFund()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #fundName
          (focus)="fundName.open()"
          (valueChange)="fundName.close()"
          [disableOptionCentering]="true"
          formControlName="fundName"
          panelClass="mat-select-position"
          (change)="fundChange($event)"
        >
          <ng-option *ngFor="let fn of fundNameList" [value]="fn.id">
            <div class="clamp" title="{{ fn.name }}">{{ fn.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field">
        <p>
          {{
            moduleId === subModuleId.DonationsInKind
              ? "Credit Account"
              : "Income Account"
          }}
          <span class="text-danger-300">*</span>
        </p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="incomeAccount"
            required
            [matAutocomplete]="accountGroup"
            (keyup)="onAccountSearch($event)"
            (mousedown)="resetAccountList()"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            #accountGroup="matAutocomplete"
            (opened)="scrollIntoView(donationForm.controls.incomeAccount.value)"
          >
            <mat-optgroup
              *ngFor="let group of incomeAccountList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  donationForm.controls.incomeAccount.value.id === names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-field" *ngIf="moduleId === subModuleId.DonationsInKind">
        <p>Amount <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            zeroNotAllowed
            formControlName="amount"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            allowDecimal
          />
        </mat-form-field>
      </div>
      <div class="form-field" *ngIf="moduleId !== subModuleId.DonationsInKind">
        <p>Donation Amount <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            zeroNotAllowed
            formControlName="donationAmount"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            allowDecimal
            (change)="onDonationAmountChange()"
          />
        </mat-form-field>
      </div>
    </div>

    <div
      class="d-flex"
      [ngClass]="moduleId === subModuleId.DonationsInKind ? 'mr-40' : 'gap-40'"
    >
      <div class="form-field" *ngIf="moduleId === subModuleId.DonationsInKind">
        <p>Debit Account<span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="feeExpenseAccount"
            required
            [matAutocomplete]="feeAccountGroup"
            (keyup)="onFeeSearch($event)"
            (mousedown)="resetFessExpenseAccountList()"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            #feeAccountGroup="matAutocomplete"
            (opened)="
              scrollIntoView(donationForm.controls.feeExpenseAccount.value)
            "
          >
            <mat-optgroup
              *ngFor="let group of feeExpenseAccountList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  donationForm.controls.feeExpenseAccount.value.id === names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div
      class="d-flex"
      [ngClass]="moduleId === subModuleId.DonationsInKind ? 'mr-40' : 'gap-40'"
    >
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Sponsored Event</p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isSponserEventChangePermission"
            >
              <mat-icon (click)="gotoAddSponsor()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="
                disabledSponsorEditButton || !isSponserEventChangePermission
              "
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditSponsor()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>

        <ng-select
          #sponsor
          (focus)="sponsor.open()"
          (valueChange)="sponsor.close()"
          [disableOptionCentering]="true"
          formControlName="sponsoredEvent"
          panelClass="mat-select-position"
          (change)="onSponsorEventChange($event)"
        >
          <ng-option *ngFor="let cl of sponsoreList" [value]="cl.id">
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="moduleId !== subModuleId.DonationsInKind">
        <p>Deposit Amount</p>
        <mat-form-field>
          <input
            matInput
            formControlName="depositAmount"
            autocomplete="off"
            required
            maxlength="{{ maxLength.maxLength25 }}"
            allowDecimal
          />
        </mat-form-field>
      </div>
    </div>

    <div
      class="d-flex"
      [ngClass]="moduleId === subModuleId.DonationsInKind ? 'mr-40' : 'gap-40'"
    >
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Activity</p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isActivityChangePermission"
            >
              <mat-icon (click)="gotoAddActivity()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="
                disabledActivityEditButton || !isActivityChangePermission
              "
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditActivity()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #activity
          (focus)="activity.open()"
          (valueChange)="activity.close()"
          [disableOptionCentering]="true"
          formControlName="activity"
          panelClass="mat-select-position"
          (change)="activityChange($event)"
        >
          <ng-option *ngFor="let cl of activityList" [value]="cl.id">
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="moduleId !== subModuleId.DonationsInKind">
        <p>Benefit Amount To Donor</p>
        <mat-form-field>
          <input
            matInput
            formControlName="benefitAmountToDonor"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="form-field wp-100 textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          maxlength="{{ maxLength.maxLength2000 }}"
          matInput
          formControlName="notes"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
