import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccountModel,
  CustomAccountModel,
  SideListModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class CustomAccountService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createCustomAccount(customAccount: AccountModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Account/save`,
      this.commonService.trimObjectSpace(JSON.stringify(customAccount)),
      headers
    );
  }

  getDataByCustomAccountId(
    customAccountId: Guid
  ): Observable<CustomAccountModel> {
    return this.http.get<CustomAccountModel>(
      `${environment.apiVersionUrl}Account/get/${customAccountId}`
    );
  }

  getDataByStandardAccountId(
    standardAccountId: Guid
  ): Observable<CustomAccountModel> {
    return this.http.get<CustomAccountModel>(
      `${environment.apiVersionUrl}ChartOfAccount/get/${standardAccountId}`
    );
  }

  deleteCustomAccount(customAccountIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(
        JSON.stringify(customAccountIds)
      ),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Account/delete`,
      options
    );
  }

  copyCustomAccount(customAccountIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Account/copy`,
      this.commonService.trimObjectSpace(JSON.stringify(customAccountIds)),
      headers
    );
  }

  archiveAndRestoreCustomAccount(
    customAccountIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Account/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(customAccountIds)),
      options
    );
  }

  getAccountTypeList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}BankAccountType/all`
    );
  }

  validateAccountCodeExists(code: any): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}Account/validateAccountCodeExist/${code}`
    );
  }
}
