export const arrayData = [
  {
    y: 400,
    quarters: [
      {
        x: 'Term Endowment',
        y: 120,
      },
      {
        x: 'Restricted Endowment',
        y: 90,
      },
      {
        x: 'Unrestricted Endowment',
        y: 100,
      },
      {
        x: 'Quasi Endowment',
        y: 90,
      },
    ],
  },
  {
    y: 430,
    quarters: [
      {
        x: 'Term Endowment',
        y: 120,
      },
      {
        x: 'Restricted Endowment',
        y: 110,
      },
      {
        x: 'Unrestricted Endowment',
        y: 90,
      },
      {
        x: 'Quasi Endowment',
        y: 110,
      },
    ],
  },
  {
    y: 448,
    quarters: [
      {
        x: 'Term Endowment',
        y: 70,
      },
      {
        x: 'Restricted Endowment',
        y: 100,
      },
      {
        x: 'Unrestricted Endowment',
        y: 140,
      },
      {
        x: 'Quasi Endowment',
        y: 138,
      },
    ],
  },
  {
    y: 470,
    quarters: [
      {
        x: 'Term Endowment',
        y: 150,
      },
      {
        x: 'Restricted Endowment',
        y: 60,
      },
      {
        x: 'Unrestricted Endowment',
        y: 190,
      },
      {
        x: 'Quasi Endowment',
        y: 70,
      },
    ],
  },
  {
    y: 540,
    quarters: [
      {
        x: 'Q1',
        y: 120,
      },
      {
        x: 'Q2',
        y: 120,
      },
      {
        x: 'Q3',
        y: 130,
      },
      {
        x: 'Q4',
        y: 170,
      },
    ],
  },
];
