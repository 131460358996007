import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ComponentName,
  ConfirmationType,
  ControlType,
  HeaderFilter,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
  VatSettings,
} from '@app/core/Enum';
import {
  ExportType,
  GlobalComponent,
  HeaderModel,
  MainListParameters,
} from '@app/core/Models';
import {
  BankDasboardService,
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import {
  BankOverviewState,
  CheckVatNumber,
  CommonState,
  CreateCustomAccount,
  GetHeaderList,
  MenuState,
  RefreshAccountProductionTrialBalance,
  SetDefaultVatId,
} from '@app/core/Store';
import { DepreciationExpandedCollapseComponent } from '@app/modules';
import { BankLinkComponent } from '@app/modules/bank';
import { QuickJournalComponent } from '@app/modules/bank/quick-journal/quick-journal.component';
import {
  ConfirmationBoxComponent,
  QuickAddComponent,
} from '@app/modules/common';
import CustomYearPickerComponent from '@app/modules/custom/custom-year-picker/custom-year-picker.component';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transactions-header',
  templateUrl: './transactions-header.component.html',
  styleUrls: ['./transactions-header.component.scss'],
})
export class TransactionHeaderComponent implements OnInit, OnDestroy {
  defaultValue: any[] = [
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
  ];

  isTrialBalanceImported = false;
  isAccountProductionTrialBalance = false;
  accoutingPeriodId = Guid.EMPTY as unknown as Guid;

  @Output()
  readonly triggerDataFromHeader = new EventEmitter<any>();

  @Output()
  readonly triggerQuickAdd = new EventEmitter<any>();

  @Input() listParameters: any;

  @Input() showAccountDetails: boolean = false;

  @Input() isBankImport: boolean = false;

  @Input() customId: any;

  @Input() transactionModuleId: number;

  @Select(MenuState.hasAddPermission)
  hasAddPermission$: Observable<boolean>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.menu)
  menu$: Observable<any>;

  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(BankOverviewState.totalRecord)
  totalBankOverviewRecord$: Observable<number>;

  controlType = ControlType;
  periodicDateList: any;

  @Select(CommonState.headerList)
  headerList$: Observable<Array<HeaderModel>>;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  customDate = false;
  headerList: any;

  HeaderFilters = HeaderFilter;
  moduleEnum = Modules;
  moduleId: number;
  headerData: MainListParameters = new MainListParameters();
  exportType = ExportType;
  modules = Modules;
  periodicId = '-1';
  moduleName = '';
  searchText = '';
  accountId: any = null;
  isRowHighlighted: boolean = false;
  accountGroupList: any[] = [];

  private _moduleIdSubscription;

  subscriptionRouting: Subscription;

  commonService: CommonService;
  store: Store;
  modulePermission: ModulePermission;
  router: Router;
  bankDasboardService: BankDasboardService;
  dialog: MatDialog;
  globalComponent: GlobalComponent;
  _Activatedroute: ActivatedRoute;
  notify: NotificationService;

  @ViewChild(CustomYearPickerComponent, { static: true })
  customYearPickerComponent;

  private unsubscribe$ = new Subject<void>();

  isSponserEventChangePermission: boolean = true;
  isFundChangePermission: boolean = true;
  isActivityChangePermission: boolean = true;
  isDonorChangePermission: boolean = true;
  isJournalChangePermission: boolean = true;
  isImportChangePermission: boolean = true;
  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
    this.router = injector.get<Router>(Router);
    this.bankDasboardService =
      injector.get<BankDasboardService>(BankDasboardService);
    this.globalComponent = injector.get<GlobalComponent>(GlobalComponent);
    this.notify = injector.get<NotificationService>(NotificationService);
  }

  ngOnInit(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();

    if (
      !this.isBankImport &&
      this.transactionModuleId !== Modules.BankImportTransactionHistory
    ) {
      this.modulePermission.permissionData
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => {
          this.moduleId = value.data.id;

          if (
            this.moduleId !== Modules.CharityProfile &&
            this.moduleId !== Modules.Dashboard
          ) {
            if (
              (value.data.url === this.router.url.slice(1) ||
                value.data.url === this.router.url.slice(1).split(';')[0]) &&
              (value.componentName === ComponentName.MainListComponent ||
                value.componentName ===
                  ComponentName.BankReconciliationComponent ||
                value.componentName === ComponentName.ImportListComponent ||
                value.componentName === ComponentName.ReportListComponent ||
                value.componentName === ComponentName.NominalLedgerComponent ||
                value.componentName === ComponentName.BulkEdit ||
                value.componentName === ComponentName.VatSettings ||
                value.componentName === ComponentName.BankOverviewComponent ||
                value.componentName === ComponentName.BankFeedComponent ||
                value.componentName === ComponentName.CashCodingComponent ||
                value.componentName ===
                  ComponentName.ReportCreditorDebtorComponent ||
                value.componentName ===
                  ComponentName.ReportListWithActionsComponent)
            ) {
              this.store
                .dispatch(new GetHeaderList(value.data.id))
                .subscribe((data) => {
                  this.headerList = data.common.headerList;
                  this.headerList.forEach((item) => {
                    if (item.value.length > 0) {
                      this.commonService.defaultHeaderGuidValue =
                        this.commonService.setParamId !== null &&
                        this.commonService.setParamId !== undefined
                          ? this.commonService.setParamId
                          : item.value[0].id;

                      this.setDataFortheHeaderParamters(
                        item.name,
                        this.commonService.defaultHeaderGuidValue
                      );

                      if (item.name === HeaderFilter.Module) {
                        this.headerData.moduleId =
                          this.commonService.defaultHeaderGuidValue;
                      }
                    }

                    if (item.groupValue.length > 0) {
                      this.accountGroupList = this.addGroupNameToListModels(
                        item.groupValue
                      );

                      this.commonService.defaultHeaderGuidValue =
                        this.commonService.setParamId !== null &&
                        this.commonService.setParamId !== undefined
                          ? this.commonService.setParamId
                          : this.accountGroupList[0].id;

                      this.onDropdownChange(
                        item.name,
                        this.commonService.defaultHeaderGuidValue,
                        false
                      );
                    }
                  });

                  if (
                    this.moduleId !== Modules.TrialBalance &&
                    this.moduleId !== Modules.BalanceSheet &&
                    this.moduleId !== Modules.StatementofFinancialActivities &&
                    this.moduleId !== Modules.IncomeAndExpenditure &&
                    this.moduleId !== Modules.PurchaseDayBook &&
                    this.moduleId !== Modules.SalesDayBook &&
                    this.moduleId !== Modules.AccountDetails
                  ) {
                    this.dataChangeFromHeader(false);
                  }
                });
            }
          }
        });

      this.customDate = false;
      this.moduleName$.subscribe((x) => {
        this.moduleName =
          x.toLocaleLowerCase() === VatSettings.VatSettings.toLocaleLowerCase()
            ? VatSettings.VatCodes[VatSettings.VatCodes.length - 1] === 's'
              ? VatSettings.VatCodes.substring(
                  0,
                  VatSettings.VatCodes.length - 1
                )
              : VatSettings.VatCodes
            : x[x.length - 1] === 's'
            ? x.substring(0, x.length - 1)
            : x;
      });

      this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
        (params) => {
          const currentUrl = this._Activatedroute.snapshot['_routerState'].url;
          if (currentUrl.includes('bank/cashEntry')) {
            this.moduleId = Modules.CashEntry;
            this.getHeader();
          }

          if (params.keys.length > 0) {
            this.commonService.setParamId = atob(
              params.get('id')!
            ) as unknown as any;
          } else {
            this.commonService.setParamId = null;
          }
        }
      );
    } else if (this.isBankImport) {
      this.moduleId = Modules.BankImportHistory;
      this.getHeader();
    } else if (
      (this.transactionModuleId = Modules.BankImportTransactionHistory)
    ) {
      this.moduleId = Modules.BankImportTransactionHistory;
      this.getHeader();
      this.dataChangeFromHeader(false);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSponserEventChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.SponsorEvent
      );
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
      this.isActivityChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Activities
      );
      this.isDonorChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Donors
      );
      this.isJournalChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Journals
      );
      this.isImportChangePermission = this.commonService.checkPermission(
        Modules.Manage,
        Modules.Import
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getHeader(): void {
    if (
      this.moduleId !== Modules.Dashboard &&
      this.moduleId !== Modules.IncomeOverview &&
      this.moduleId !== Modules.ExpenditureOverview &&
      this.moduleId !== Modules.FixedAssetOverview
    ) {
      this.store.dispatch(new GetHeaderList(this.moduleId));
    }
  }

  onAddClick(): void {
    this.commonService.toggleMenu = false;
    this.commonService.isInitialValueChange = false;
    this._moduleIdSubscription = this.moduleId$.subscribe((data: any) => {
      if (
        data !== Modules.BankDashboard &&
        data !== Modules.BankFeed &&
        data !== Modules.FixedAssetsRegister
      ) {
        this.commonService.onAddRouting(data);
      } else if (data === Modules.BankDashboard) {
        this.gotoAddCustomAccount();
      } else if (data === Modules.FixedAssetsRegister) {
        this.dialog
          .open(DepreciationExpandedCollapseComponent, {
            panelClass: 'depreciation-list-dialog',
          })
          .afterClosed()
          .subscribe(() => {
            this.dataChangeFromHeader(false);
          });
      } else {
        this.dialog
          .open(BankLinkComponent)
          .afterClosed()
          .subscribe(() => {});
      }
    });
    this._moduleIdSubscription.unsubscribe();
  }

  onRefereshClick(): void {
    let totalRecordOfRecords = 0;
    let response;
    this.commonService.toggleMenu = false;
    this.commonService.isInitialValueChange = false;
    this.totalRecord$.subscribe((x) => {
      totalRecordOfRecords = x;
    });
    if (totalRecordOfRecords > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            type: ConfirmationType.RefreshTrialBalance,
            moduleId: Modules.AccountProductionTrialBalance,
            headerText: NotificationHeader.trialBalanceReferesh,
            detailText: NotificationDetails.trialBalanceReferesh,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (
            result &&
            this.accoutingPeriodId !== undefined &&
            this.accoutingPeriodId !== null &&
            this.accoutingPeriodId !== (Guid.EMPTY as unknown as Guid)
          ) {
            this.store
              .dispatch(
                new RefreshAccountProductionTrialBalance(this.accoutingPeriodId)
              )
              .subscribe((res: any) => {
                response = res.accountProduction.refreshTrialBalance;
                if (response.isSuccess) {
                  this.notify.success(
                    NotificationHeader.success,
                    NotificationTextMessage.trialBalanceRefereshSuccessfully
                  );

                  this.dataChangeFromHeader(false);
                } else {
                  this.notify.error(
                    NotificationHeader.error,
                    NotificationTextMessage.noDataToExport
                  );
                }
              });
          }
        });
    } else {
      if (
        this.accoutingPeriodId !== undefined &&
        this.accoutingPeriodId !== null &&
        this.accoutingPeriodId !== (Guid.EMPTY as unknown as Guid)
      ) {
        this.store
          .dispatch(
            new RefreshAccountProductionTrialBalance(this.accoutingPeriodId)
          )
          .subscribe((res: any) => {
            response = res.accountProduction.refreshTrialBalance;

            if (response.isSuccess) {
              this.notify.success(
                NotificationHeader.success,
                NotificationTextMessage.trialBalanceRefereshSuccessfully
              );

              this.dataChangeFromHeader(false);
            } else {
              this.notify.error(
                NotificationHeader.error,
                NotificationTextMessage.noDataToExport
              );
            }
          });
      }
    }
  }

  gotoAddCustomAccount(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.AddCustomAccounts,
          headerText: `Add ${ModuleName.AddCustomAccounts}`,
          saveActionName: CreateCustomAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.triggerQuickAdd.emit(true);
        }
      });
  }

  checkVatNumber(data: any): void {
    this.store.dispatch(new CheckVatNumber()).subscribe((res) => {
      if (res.submitVat.checkVatNumber) {
        this.commonService.onAddRouting(data);
      } else {
        this.commonService.onFailure(NotificationTextMessage.checkVatNumber);
      }
    });
  }

  dataChangeFromHeader(onLoad: boolean): void {
    const params = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      search: this.headerData.search,
      moduleId: this.headerData.moduleId,
      subModuleId: this.headerData.subModuleId,
      format: this.headerData.format,
      isPrint: this.headerData.isPrint,
      filter: this.headerData.filter,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: onLoad ? this.listParameters.sortBy : '',
      sortOrder: onLoad ? this.listParameters.sortOrder : true,
    };
    this.isRowHighlighted = onLoad;

    this.triggerDataFromHeader.emit(params);
  }

  onSearch(): void {
    this.headerData.search = this.searchText;

    this.dataChangeFromHeader(false);
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.onSearch();
    }
  }

  triggerDateChange(data: any): void {
    if (data.value === '-1') {
      this.headerData.startDate = '';
      this.headerData.endDate = '';
      this.isTrialBalanceImported = false;
      this.isAccountProductionTrialBalance = false;
      this.accoutingPeriodId = Guid.EMPTY as unknown as Guid;
    } else {
      this.headerData.startDate = data.startDate;
      this.headerData.endDate = data.endDate;
      this.isTrialBalanceImported = data.isTrialBalanceImported;
      this.isAccountProductionTrialBalance =
        data.isAccountProductionTrialBalance;
      this.accoutingPeriodId = data.accountingPeriodId;
    }

    this.dataChangeFromHeader(false);
  }

  import(): void {
    if (this.moduleId !== Modules.BankDashboard) {
      const params = { moduleId: btoa(this.moduleId.toString()) };
      this.router.navigate([RoutingPath.Import, params]);
    } else {
      const params = { customId: btoa(this.customId.toString()) };
      this.router.navigate([RoutingPath.BankImport, params]);
    }
  }

  bankEntryClick(isBankEntry): void {
    const params = {
      customId: btoa(this.customId),
    };

    if (isBankEntry) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = params;
      this.dialog
        .open(QuickJournalComponent, dialogConfig)
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            const params = {
              customId: this.customId,
            };
            this.triggerQuickAdd.emit(params);
          }
        });
    } else {
      this.router.navigate([RoutingPath.CashCoding, params]);
    }
  }

  export(format: number, isPrint: boolean): void {
    let totalRecordOfRecords = 0;
    if (this.moduleId === Modules.BankDashboard) {
      this.totalBankOverviewRecord$.subscribe((x) => {
        totalRecordOfRecords = x;
      });
    } else {
      this.totalRecord$.subscribe((x) => {
        totalRecordOfRecords = x;
      });
    }

    if (
      format === ExportType.ExportAsExcelOutstandingBal ||
      format === ExportType.ExportAsCSVOutstandingBal ||
      format === ExportType.ExportAsPDFOutstandingBal
    ) {
      this.listParameters.filter = 0;
    } else if (
      (format === ExportType.Excel ||
        format === ExportType.CSV ||
        format === ExportType.PDF) &&
      (this.moduleId === Modules.Debtors || this.moduleId === Modules.Creditors)
    ) {
      this.listParameters.filter = -1;
    }

    if (format === ExportType.ExportAsExcelOutstandingBal) {
      format = ExportType.Excel;
    } else if (format === ExportType.ExportAsCSVOutstandingBal) {
      format = ExportType.CSV;
    } else if (format === ExportType.ExportAsPDFOutstandingBal) {
      format = ExportType.PDF;
    }

    if (totalRecordOfRecords > 0) {
      const params = {
        startDate: this.headerData.startDate,
        endDate: this.headerData.endDate,
        filter: this.listParameters.filter,
        search: this.headerData.search,
        moduleId: this.headerData.moduleId,
        subModuleId: this.headerData.subModuleId,
        pageNumber: this.listParameters.pageNumber,
        pageSize: totalRecordOfRecords,
        format: format,
        isPrint: isPrint,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
      };
      this.triggerDataFromHeader.emit(params);
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noDataToExport
      );
    }
  }

  setDataFortheHeaderParamters(headerName: any, value: any): void {
    if (headerName === HeaderFilter.Filter) {
      this.headerData.filter = value;
    }
    if (headerName === HeaderFilter.Module) {
      this.headerData.moduleId = value;
    }
    if (headerName === HeaderFilter.SubModule) {
      this.headerData.subModuleId = value;
    }
  }

  onDropdownChange(headerName: any, value: any, onLoad: boolean): void {
    this.setDataFortheHeaderParamters(headerName, value);

    this.listParameters.pageNumber = 1;
    this.dataChangeFromHeader(onLoad);
  }

  onEditClick(): void {
    if (this.moduleId === Modules.TrialBalance) {
      this.router.navigate([RoutingPath.EditTrialBalance]);
    }
    if (this.moduleId === Modules.AccountProductionTrialBalance) {
      this.router.navigate([RoutingPath.EditAccountProductionTrialBalance]);
    }
  }

  manualImportClick(): void {
    if (this.moduleId === Modules.BankDashboard) {
      const params = { customId: btoa(this.customId.toString()) };
      this.router.navigate([RoutingPath.BankManualImport, params]);
    }
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }
}
