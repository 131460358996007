import { DatePipe } from '@angular/common';
import { Component, Injector, Input, OnInit, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  ActionType,
  MaxLength,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { GlobalComponent, ManualImportItemModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetCustomBankAccountList,
  ManualImportBank,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  CleanAllLinesComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface PeriodicElement2 {
  date: string;
  Type: string;
  Description: string;
  paidout: number;
  paidin: number;
}

@Component({
  selector: 'app-manual-import',
  templateUrl: './manual-import.component.html',
  styleUrls: ['./manual-import.component.scss'],
})
export class ManualImportComponent implements OnInit {
  displayedColumns: string[] = [
    'date',
    'Description',
    'paidout',
    'paidin',
    'closeButton',
  ];

  @Input()
  getModuleId: number;
  selectedRowIndex = -1;
  moreActionCount = 4;
  periodicDate: any;
  formManualImport: UntypedFormGroup;
  manualImportArray: any;
  tableDataSource: MatTableDataSource<AbstractControl>;
  maxLength = MaxLength;
  notificationMessage = NotificationTextMessage;

  actions: any = [];
  receiptAccountList: any[] = [];
  tempReceiptAccountList: any[];
  selectedBank: any;
  manualImportItems = new Array<ManualImportItemModel>();

  subscriptionRouting: Subscription;
  router: Router;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent,
    private store: Store,
    public commonService: CommonService,
    private renderer: Renderer2,
    private _Activatedroute: ActivatedRoute,
    private injector: Injector
  ) {
    this.router = injector.get<Router>(Router);
  }

  ngOnInit(): void {
    this.spinner.hide();

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.selectedBank = atob(params.get('customId')!) as unknown as any;
        }
      }
    );
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.getBankAccountList();
    this.setProductDetailsForm(true);
    this.actions = [
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
    ];
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      date: new FormControl(new Date(), datePickerValidator(this.periodicDate)),
      description: new FormControl<string | null>('', [Validators.required]),
      paidIn: new FormControl<number | null>(0),
      paidOut: new FormControl<number | null>(0),
    });
  }

  setProductDetailsForm(addNewRow: boolean): void {
    this.formManualImport = new FormGroup({
      manualImportArray: new UntypedFormArray([]),
    });

    this.manualImportArray = this.formManualImport.get(
      'manualImportArray'
    ) as UntypedFormArray;

    this.setDataSource(this.manualImportArray);
    if (addNewRow) this.createRow();
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.addDropdown();
    }
  }

  addDropdown(): void {
    this.createRow();
  }

  createRow(): void {
    this.manualImportArray = this.formManualImport.get(
      'manualImportArray'
    ) as UntypedFormArray;

    this.manualImportArray.push(this.setForm());

    this.setDataSource(this.manualImportArray);
  }
  onCloseClick(): void {
    this.dialog
      .open(AddClosePopupComponent, {})
      .afterClosed()
      .subscribe((result) => {
        this.router.navigate([RoutingPath.BankDashboard]);
        this.commonService.setLocalStorage('selectedBank', this.selectedBank);
      });
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formManualImport.get(
            'manualImportArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
          }
        }
      });
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    this.ngOnInit();
  }

  validateImport(): boolean {
    const manualImportItems = this.manualImportArray?.getRawValue();

    for (const x of manualImportItems) {
      const transactionDate = this.datepipe
        .transform(x.date, 'yyyy-MM-dd')
        ?.toString();
      const notes = x.description;
      const paymentAmount = x.paidOut;
      const receivedAmount = x.paidIn;

      if (!transactionDate || isNaN(Date.parse(transactionDate))) {
        return false;
      }

      if (!notes || notes.trim() === '') {
        return false;
      }

      if (!(paymentAmount > 0) && !(receivedAmount > 0)) {
        return false;
      }
    }

    return true;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.manualImportArray?.getRawValue().forEach((x) => {
        this.manualImportItems.push({
          transactionDate: this.datepipe
            .transform(x.date, 'yyyy-MM-dd')
            ?.toString(),
          notes: x.description,
          paymentAmount: x.paidOut,
          receivedAmount: x.paidIn,
        });
      });
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.selectedBank === undefined || this.selectedBank === null) {
      this.commonService.onFailure('Please select bank Account');
    } else if (this.formManualImport.invalid || !this.validateImport()) {
      this.commonService.onFailure(
        'Please select values for: Date, Description and Paid In/Out'
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(
            new ManualImportBank(this.manualImportItems, this.selectedBank)
          )
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.onCancel(true);
                if (isExit) {
                  history.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  onButtonClick(element: any, actionType: ActionType): void {}

  getBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.receiptAccountList = res.common.customBankAccount;
          this.tempReceiptAccountList = this.receiptAccountList;
          if (this.receiptAccountList.length > 0) {
            if (this.selectedBank === undefined || this.selectedBank === null) {
              this.selectedBank = this.receiptAccountList[0].id;
            }
          }
        })
      )
      .subscribe();
  }

  paidOutChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formManualImport.get(
        'manualImportArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('paidIn')?.setValue(0);
      formArray.controls[index].get('paidIn')?.clearValidators();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  paidInChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formManualImport.get(
        'manualImportArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('paidOut')?.setValue(0);
      formArray.controls[index].get('paidOut')?.clearValidators();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  onDeleteCashCodingDetails(index: number): void {
    this.manualImportArray = this.formManualImport.get(
      'manualImportArray'
    ) as UntypedFormArray;
    if (this.manualImportArray.length === 1) {
      return;
    }

    this.manualImportArray.removeAt(index);

    this.setDataSource(this.manualImportArray);
  }
}
