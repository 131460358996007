<div class="content-body-wrapper">
  <div class="content-body content-body-scroll">
    <div>
      <div class="alert-message" *ngIf="isViewPermission$ | async">
        <mat-icon class="material-icons-outlined">error_outline</mat-icon>
        {{ commonNotificationText.ViewOnlyAccessMessage }}
      </div>
      <div class="accordion-toggle">
        <mat-icon
          (click)="toggleAccordion(); accordion.openAll()"
          *ngIf="isExpandAll"
          >keyboard_double_arrow_down
        </mat-icon>
        <mat-icon
          (click)="toggleAccordion(); accordion.closeAll()"
          *ngIf="!isExpandAll"
          >keyboard_double_arrow_up
        </mat-icon>
      </div>
      <mat-accordion [multi]="true">
        <mat-expansion-panel
          [expanded]="true"
          [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon fontSet="material-icons-outlined" class="text-primary"
                >description</mat-icon
              >
              <div class="d-flex flex-column">
                <span>Bulk Donation</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-add-donation-coding></app-add-donation-coding>
        </mat-expansion-panel>
        <mat-expansion-panel
          [expanded]="true"
          [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon fontSet="material-icons-outlined" class="text-primary"
                >description</mat-icon
              >
              <div class="d-flex flex-column">
                <span>Bulk Donation Details</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-add-bulk-donation-coding></app-add-bulk-donation-coding>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <app-buttons
    *ngIf="!(isViewPermission$ | async)"
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave($event.isExit)"
    (triggerOnCancelClick)="onCancel(true)"
  ></app-buttons>
</div>
