import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MaxLength, Modules, NotificationTextMessage } from '@app/core/Enum';
import { GiftAIDsettings, SideListModel } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  GetCharityRegulator,
  GetGiftAIDsettings,
  MenuState,
  SaveGiftAIDsettings,
} from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-gift-aid-settings',
  templateUrl: './add-gift-aid-settings.component.html',
  styleUrls: ['./add-gift-aid-settings.component.scss'],
})
export class AddGiftAIDsettingsComponent implements OnInit {
  giftAIDsettingsForm: FormGroup;
  giftAIDsettingsData: GiftAIDsettings;
  maxLength = MaxLength;
  charityRegulator: SideListModel[];

  isExpandAll = false;
  istoggleSideList = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  moduleId = Modules.GiftAidSetting;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.getCharityRegulator();
  }

  setForm(): void {
    this.giftAIDsettingsForm = new FormGroup({
      charityId: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      charityCommisionReference: new FormControl(''),
      lastName: new FormControl('', [Validators.required]),
      regulator: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      phone: new FormControl('', [Validators.required]),
      postCode: new FormControl('', [Validators.required]),
    });

    this.editGiftAIDsettings();
  }

  getCharityRegulator(): void {
    this.store
      .dispatch(new GetCharityRegulator())
      .pipe(
        tap((res) => {
          this.charityRegulator = res.company.charityRegulator;
          this.giftAIDsettingsForm.controls.regulator.setValue(
            this.charityRegulator[0].id
          );
        })
      )
      .subscribe();
  }

  editGiftAIDsettings(): void {
    this.store
      .dispatch(new GetGiftAIDsettings())
      .pipe(
        tap((res) => {
          this.giftAIDsettingsForm.patchValue({
            charityId: res.giftAid.giftAIDSettingsData.referenceNo,
            firstName: res.giftAid.giftAIDSettingsData.firstName,
            charityCommisionReference:
              res.giftAid.giftAIDSettingsData.registrationNo,
            lastName: res.giftAid.giftAIDSettingsData.lastName,
            phone: res.giftAid.giftAIDSettingsData.phone,
            postCode: res.giftAid.giftAIDSettingsData.postCode,
          });
        })
      )
      .subscribe();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  dataSubmit(): boolean {
    this.spinner.show();

    this.giftAIDsettingsData = {
      firstName: this.giftAIDsettingsForm.controls.firstName.value,
      referenceNo:
        this.giftAIDsettingsForm.controls.charityCommisionReference.value,
      lastName: this.giftAIDsettingsForm.controls.lastName.value,
      phone: this.giftAIDsettingsForm.controls.phone.value,
      postCode: this.giftAIDsettingsForm.controls.postCode.value,
      registrationNo: this.giftAIDsettingsForm.controls.charityId.value,
    };
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.giftAIDsettingsForm.invalid) {
      this.commonService.addValidation(this.giftAIDsettingsForm, this.renderer);
    } else if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveGiftAIDsettings(this.giftAIDsettingsData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.onCancel(isExit);
              if (isExit) {
                history.back();
              }
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
    }
  }

  onCancel(isCancelClick: boolean): void {
    if (isCancelClick) {
      this.editGiftAIDsettings();
    } else {
      this.ngOnInit();
    }
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }
}
