<form [formGroup]="note28Form">
  <div class="notes-section">
    <table class="notes-table" aria-describedby="User Task Table">
      <tr class="text-align-center">
        <th></th>
        <th colspan="2" scope="col">Stock</th>
        <th colspan="2" scope="col">Donated goods</th>
        <th rowspan="2" scope="col">Work in progress</th>
      </tr>
      <tr class="text-align-center">
        <th scope="col"></th>
        <th scope="col">For distribution</th>
        <th scope="col">For resale</th>
        <th scope="col">For distribution</th>
        <th scope="col">For resale</th>
      </tr>
      <tr class="text-align-center">
        <th scope="col"></th>
        <th class="fw-bold" scope="col">£</th>
        <th class="fw-bold" scope="col">£</th>
        <th class="fw-bold" scope="col">£</th>
        <th class="fw-bold" scope="col">£</th>
        <th class="fw-bold" scope="col">£</th>
      </tr>
      <tr>
        <th class="fw-bold text-align-left" scope="col">
          Charitable activities:
        </th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col"><em>Opening</em></th>
        <td>
          {{
            calculativeData?.charitableActivities?.stockForDistribution?.opening
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.stockForResale?.opening
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.donatedForDistribution
              ?.opening | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.donatedForResale?.opening
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.workInProgress?.opening
              | numberPipe
          }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col">
          <em>Added in period</em>
        </th>
        <td>
          {{
            calculativeData?.charitableActivities?.stockForDistribution?.added
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.stockForResale?.added
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.donatedForDistribution?.added
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.donatedForResale?.added
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.workInProgress?.added
              | numberPipe
          }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col">
          <em>Expensed in period</em>
        </th>
        <td>
          {{
            calculativeData?.charitableActivities?.stockForDistribution?.expense
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.stockForResale?.expense
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.donatedForDistribution
              ?.expense | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.donatedForResale?.expense
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.workInProgress?.expense
              | numberPipe
          }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col"><em>Impaired</em></th>
        <td>
          {{
            calculativeData?.charitableActivities?.stockForDistribution
              ?.impaired | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.stockForResale?.impaired
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.donatedForDistribution
              ?.impaired | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.donatedForResale?.impaired
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.charitableActivities?.workInProgress?.impaired
              | numberPipe
          }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col"><em>Closing</em></th>
        <td class="fw-bold">
          {{
            calculativeData?.charitableActivities?.stockForDistribution?.closing
              | numberPipe
          }}
        </td>
        <td class="fw-bold">
          {{ calculativeData?.charitableActivities?.stockForResale?.closing }}
        </td>
        <td class="fw-bold">
          {{
            calculativeData?.charitableActivities?.donatedForDistribution
              ?.closing | numberPipe
          }}
        </td>
        <td class="fw-bold">
          {{
            calculativeData?.charitableActivities?.donatedForResale?.closing
              | numberPipe
          }}
        </td>
        <td class="fw-bold">
          {{
            calculativeData?.charitableActivities?.workInProgress?.closing
              | numberPipe
          }}
        </td>
      </tr>

      <tr>
        <th class="fw-bold text-align-left" scope="col">
          Other trading activities:
        </th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col"><em>Opening</em></th>
        <td>
          {{
            calculativeData?.otherTradingActivities?.stockForDistribution
              ?.opening | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.stockForResale?.opening
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.donatedForDistribution
              ?.opening | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.donatedForResale?.opening
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.workInProgress?.opening
              | numberPipe
          }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col">
          <em>Added in period</em>
        </th>
        <td>
          {{
            calculativeData?.otherTradingActivities?.stockForDistribution?.added
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.stockForResale?.added
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.donatedForDistribution
              ?.added | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.donatedForResale?.added
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.workInProgress?.added
              | numberPipe
          }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col">
          <em>Expensed in period</em>
        </th>
        <td>
          {{
            calculativeData?.otherTradingActivities?.stockForDistribution
              ?.expense | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.stockForResale?.expense
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.donatedForDistribution
              ?.expense | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.donatedForResale?.expense
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.workInProgress?.expense
              | numberPipe
          }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col"><em>Impaired</em></th>
        <td>
          {{
            calculativeData?.otherTradingActivities?.stockForDistribution
              ?.impaired | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.stockForResale?.impaired
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.donatedForDistribution
              ?.impaired | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.donatedForResale?.impaired
              | numberPipe
          }}
        </td>
        <td>
          {{
            calculativeData?.otherTradingActivities?.workInProgress?.impaired
              | numberPipe
          }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col"><em>Closing</em></th>
        <td class="fw-bold">
          {{
            calculativeData?.otherTradingActivities?.stockForDistribution
              ?.closing | numberPipe
          }}
        </td>
        <td class="fw-bold">
          {{
            calculativeData?.otherTradingActivities?.stockForResale?.closing
              | numberPipe
          }}
        </td>
        <td class="fw-bold">
          {{
            calculativeData?.otherTradingActivities?.donatedForDistribution
              ?.closing | numberPipe
          }}
        </td>
        <td class="fw-bold">
          {{
            calculativeData?.otherTradingActivities?.donatedForResale?.closing
              | numberPipe
          }}
        </td>
        <td class="fw-bold">
          {{
            calculativeData?.otherTradingActivities?.workInProgress?.closing
              | numberPipe
          }}
        </td>
      </tr>

      <tr>
        <th class="text-align-left" scope="col">Other:</th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col"><em>Opening</em></th>
        <td>
          {{
            calculativeData?.other?.stockForDistribution?.opening | numberPipe
          }}
        </td>
        <td>
          {{ calculativeData?.other?.stockForResale?.opening | numberPipe }}
        </td>
        <td>
          {{
            calculativeData?.other?.donatedForDistribution?.opening | numberPipe
          }}
        </td>
        <td>
          {{ calculativeData?.other?.donatedForResale?.opening | numberPipe }}
        </td>
        <td>
          {{ calculativeData?.other?.workInProgress?.opening | numberPipe }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col">
          <em>Added in period</em>
        </th>
        <td>
          {{ calculativeData?.other?.stockForDistribution?.added | numberPipe }}
        </td>
        <td>
          {{ calculativeData?.other?.stockForResale?.added | numberPipe }}
        </td>
        <td>
          {{
            calculativeData?.other?.donatedForDistribution?.added | numberPipe
          }}
        </td>
        <td>
          {{ calculativeData?.other?.donatedForResale?.added | numberPipe }}
        </td>
        <td>
          {{ calculativeData?.other?.workInProgress?.added | numberPipe }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col">
          <em>Expensed in period</em>
        </th>
        <td>
          {{
            calculativeData?.other?.stockForDistribution?.expense | numberPipe
          }}
        </td>
        <td>
          {{ calculativeData?.other?.stockForResale?.expense | numberPipe }}
        </td>
        <td>
          {{
            calculativeData?.other?.donatedForDistribution?.expense | numberPipe
          }}
        </td>
        <td>
          {{ calculativeData?.other?.donatedForResale?.expense | numberPipe }}
        </td>
        <td>
          {{ calculativeData?.other?.workInProgress?.expense | numberPipe }}
        </td>
      </tr>
      <tr>
        <td class="text-align-left"><em>Impaired</em></td>
        <td>
          {{
            calculativeData?.other?.stockForDistribution?.impaired | numberPipe
          }}
        </td>
        <td>
          {{ calculativeData?.other?.stockForResale?.impaired | numberPipe }}
        </td>
        <td>
          {{
            calculativeData?.other?.donatedForDistribution?.impaired
              | numberPipe
          }}
        </td>
        <td>
          {{ calculativeData?.other?.donatedForResale?.impaired | numberPipe }}
        </td>
        <td>
          {{ calculativeData?.other?.workInProgress?.impaired | numberPipe }}
        </td>
      </tr>
      <tr>
        <th class="text-align-left" scope="col"><em>Closing</em></th>
        <td class="fw-bold">
          {{
            calculativeData?.other?.stockForDistribution?.closing | numberPipe
          }}
        </td>
        <td class="fw-bold">
          {{ calculativeData?.other?.stockForResale?.closing | numberPipe }}
        </td>
        <td class="fw-bold">
          {{
            calculativeData?.other?.donatedForDistribution?.closing | numberPipe
          }}
        </td>
        <td class="fw-bold">
          {{ calculativeData?.other?.donatedForResale?.closing | numberPipe }}
        </td>
        <td class="fw-bold">
          {{ calculativeData?.other?.workInProgress?.closing | numberPipe }}
        </td>
      </tr>
      <tr>
        <th class="fw-bold text-align-left" scope="col">
          <em>Total this year</em>
        </th>
        <td>
          {{ calculativeData?.thisYear?.stockForDistribution | numberPipe }}
        </td>
        <td>{{ calculativeData?.thisYear?.stockForResale | numberPipe }}</td>
        <td>
          {{ calculativeData?.thisYear?.donatedForDistribution | numberPipe }}
        </td>
        <td>
          {{ calculativeData?.thisYear?.donatedForResale | numberPipe }}
        </td>
        <td>{{ calculativeData?.thisYear?.workInProgress | numberPipe }}</td>
      </tr>
      <tr>
        <th class="fw-bold text-align-left" scope="col">
          <em>Total previous year</em>
        </th>
        <td>
          {{ calculativeData?.lastYear?.stockForDistribution | numberPipe }}
        </td>
        <td>{{ calculativeData?.lastYear?.stockForResale | numberPipe }}</td>
        <td>
          {{ calculativeData?.lastYear?.donatedForDistribution | numberPipe }}
        </td>
        <td>
          {{ calculativeData?.lastYear?.donatedForResale | numberPipe }}
        </td>
        <td>{{ calculativeData?.lastYear?.workInProgress | numberPipe }}</td>
      </tr>
    </table>
  </div>
  <div class="notes-wrapper">
    <div class="notes-section">
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox1"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
  </div>
</form>
