import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AccountingMethod,
  ActionType,
  AnnualReportUploadType,
  ConfirmationType,
  FontSize,
  MaxLength,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  AnnualReportModel,
  FileUploadRequestModel,
  FileUploadResponseModel,
  GlobalComponent,
  TshqRequestOptions,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  FileUpload,
  GetSaasURL,
  GetByAccountPeriod,
  SaveAnnualReport,
  GetAccountingMethod,
  GetAnnualReportResponse,
  GetListSavedFile,
  GetSavedAnnualReportResponse,
  DownloadSavedAnnualReport,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  ConfirmationBoxComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';

import {
  EditorHistoryService,
  EditorService,
  PrintService,
  SelectionService,
  SfdtExportService,
  ToolbarService,
} from '@syncfusion/ej2-angular-documenteditor';
import { HttpClient } from '@angular/common/http';
import { AnnualReportPopupComponent } from './annual-report-popup/annual-report-popup.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-annual-report',
  templateUrl: './annual-report.component.html',
  styleUrls: ['./annual-report.component.scss'],
  providers: [
    ToolbarService,
    PrintService,
    SelectionService,
    EditorService,
    EditorHistoryService,
    SfdtExportService,
  ],
})
export class AnnualReportComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;
  annualForm: FormGroup;
  fontColors: any;

  src = '';
  annualReportContent = '';
  periodicDateList: any;
  accountingId: any;
  accountingPeriod: Guid;
  provideTrusteeFileName: string;
  examinerReportFileName: string;
  signatureFileName: string;
  moreActionCount = 4;
  selectedRowIndex = -1;

  actions: any = [];

  moduleId = Modules.AnnualAccounts;

  isProvideTrusteePDFSelected = false;
  isExaminerReportPDFSelected = false;
  isSignatureSelected = false;
  showDocument = false;
  isLocked = false;
  noDataFound = false;

  url: any[] = [];
  showPath: any[] = [];
  fileList: any[] = [];

  id = Guid.EMPTY as unknown as Guid;
  annualData: AnnualReportModel;

  acceptedFileTypes = ['.pdf'];
  acceptedSignatureFileTypes = ['.jpg', '.jpeg', '.png'];

  subscriptionRouting: Subscription;
  fileUploadResponse: FileUploadResponseModel;
  fileUploadRequest: FileUploadRequestModel;
  uploadType: AnnualReportUploadType;

  provideNamefileUploadResponse: FileUploadResponseModel;
  examinerNamefileUploadResponse: FileUploadResponseModel;
  signaturefileUploadResponse: FileUploadResponseModel;

  fileURl: string;
  isIframeHidden = true;

  srcDocContent: string;

  maxLength = MaxLength;
  fontSizeList = FontSize;
  fontFamilies = [
    'Arial',
    'sans-serif',
    'Times New Roman',
    'Verdana',
    'Courier New',
  ];
  annualReportListSavedFiles: any[] = [];

  displayedColumns = ['srno', 'period', 'saveDate', 'saveTime', 'action'];
  dataSource = new MatTableDataSource<any>();
  accountingMethodId: any;
  accountingMethod = AccountingMethod;

  public culture: string = 'en-US';
  generatedReport: any;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private store: Store,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog,
    private renderer: Renderer2,
    public datepipe: DatePipe,
    public location: Location,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.accountingMethodId = this.globalComponent.getAccountingMethod();
    this.actions = [
      {
        name: 'View / Edit',
        actionType: ActionType.View,
        icon: 'visibility',
      },
      {
        name: 'Download',
        actionType: ActionType.Export,
        icon: 'download',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
    ];
    this.setForm();
    this.setPeriodDate();
  }

  onCreate(): void {
    this.store
      .dispatch(new GetAnnualReportResponse(this.accountingId))
      .pipe()
      .subscribe(
        (response: any) => {
          this.openAnnualReportPopup(response, false);
        },
        (error: any) => {
          console.error('Failed:', error);
        }
      );
  }

  viewSavedReport(element): void {
    this.spinner.show();
    this.store
      .dispatch(new GetSavedAnnualReportResponse(this.accountingId, element.id))
      .pipe()
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.openAnnualReportPopup(response, true, element.id);
        },
        (error: any) => {
          this.spinner.hide();
          console.error('Failed:', error);
        }
      );
  }

  openAnnualReportPopup(
    response: any,
    isEdit: boolean,
    saveAnnualReportResponseId?: any
  ): void {
    let selectedPeriodDetail = this.periodicDateList.filter(
      (x) => x.id === this.accountingPeriod
    );
    let selectedDateRange = '';
    if (selectedPeriodDetail.length > 0) {
      selectedDateRange = selectedPeriodDetail[0].name.replace(/\s/g, '');
    }
    this.dialog
      .open(AnnualReportPopupComponent, {
        panelClass: 'annual-report-dialog',
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: {
          res: response,
          selectedDateRange: selectedDateRange,
          accountingPeriod: this.accountingPeriod,
          isEdit: isEdit,
          saveAnnualReportResponseId: isEdit
            ? saveAnnualReportResponseId
            : (Guid.EMPTY as unknown as Guid),
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        this.getListSavedFiles();
      });
  }
  setForm(): void {
    this.annualForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      designation: new FormControl('', [Validators.required]),
      date: new FormControl(new Date(), [Validators.required]),
      fontColor: new FormControl(''),
      showPriorYearValue: new FormControl(true),
    });
  }

  onSignatureDeletePDF(): void {
    this.isSignatureSelected = false;
    this.signatureFileName = '';
    this.signaturefileUploadResponse = new FileUploadResponseModel();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onAccountPeriodChange(): void {
    this.spinner.show();
    this.getByAccountPeriod();
    this.getListSavedFiles();
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    this.accountingPeriod = this.periodicDateList[0].id;

    this.getByAccountPeriod();
    this.getListSavedFiles();
  }

  getSaasURL(url: any, type: any): void {
    const data = {
      fileUrl: url,
    };

    this.store.dispatch(new GetSaasURL(data)).subscribe((res) => {
      if (type === AnnualReportUploadType.signature) {
        this.signatureFileName = res.common.generateSaasURLModel;
      }
    });
  }

  onSignatureSelection(event: any): void {
    if (!event.target.files || !event.target.files.length) {
      return;
    }

    if (
      event.target.files[0].type === 'image/jpeg' ||
      event.target.files[0].type === 'image/png' ||
      event.target.files[0].type === 'image/jpg'
    ) {
      this.isSignatureSelected = true;
      this.signatureFileName = event.target.files[0].name;
      this.saveFile(event.target.files[0], AnnualReportUploadType.signature);

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.url.push(reader.result);
      };
    } else {
      this.commonService.onFailure(
        NotificationTextMessage.fileExtensionValidatonForImage
      );
    }
  }

  saveFile(file: File, type: Number): void {
    this.fileList = [];
    this.fileList.push(file);

    this.fileUploadRequest = {
      file: this.fileList,
      attachmentType: 1,
    };

    this.store
      .dispatch(new FileUpload(this.fileUploadRequest))
      .pipe(
        tap((data) => {
          if (type === AnnualReportUploadType.signature) {
            this.signaturefileUploadResponse =
              data.common.fileUploadRequestModel[0];
          }

          this.getSaasURL(data.common.fileUploadRequestModel[0].fileUrl, type);
        })
      )
      .subscribe();
  }

  get accept(): string {
    return this.acceptedFileTypes?.join(', ') ?? '';
  }

  get acceptSignature(): string {
    return this.acceptedSignatureFileTypes?.join(', ') ?? '';
  }

  dataSubmit() {
    this.spinner.show();
    try {
      this.annualData = {
        id: this.id,
        accountingPeriodId: this.accountingPeriod,
        name: this.annualForm.controls.name.value,
        designation: this.annualForm.controls.designation.value,
        showPriorYearValue: this.annualForm.controls.showPriorYearValue.value,
        date: this.datepipe
          .transform(this.annualForm.controls.date.value, 'yyyy-MM-dd')
          ?.toString()!,
        trusteeReportFile:
          this.provideNamefileUploadResponse === undefined
            ? null
            : this.provideNamefileUploadResponse.fileUrl,
        examinerReportFile:
          this.examinerNamefileUploadResponse === undefined
            ? null
            : this.examinerNamefileUploadResponse.fileUrl,
        signature:
          this.signaturefileUploadResponse === undefined
            ? null
            : this.signaturefileUploadResponse.fileUrl,
        color: this.annualForm.controls.fontColor.value,
      };
    } catch (error) {
      this.spinner.hide();

      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(): void {
    if (this.annualForm.invalid) {
      this.commonService.addValidation(this.annualForm, this.renderer);
    } else {
      if (this.dataSubmit()) {
        if (this.annualData) {
          this.store
            .dispatch(new SaveAnnualReport(this.annualData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  getListSavedFiles(): void {
    this.store
      .dispatch(new GetListSavedFile(this.accountingId))
      .subscribe((res) => {
        if (res.annualReport.annualReportFileList.length > 0) {
          this.annualReportListSavedFiles = [];
          let selectedPeriodDetail = this.periodicDateList.filter(
            (x) => x.id === this.accountingPeriod
          );
          let selectedDateRange = '';
          if (selectedPeriodDetail.length > 0) {
            selectedDateRange = selectedPeriodDetail[0].name.replace(/\s/g, '');
          }
          res.annualReport.annualReportFileList.forEach((element, i) => {
            const dataDisplay = {
              id: element.id,
              srno: i + 1,
              period: selectedDateRange,
              saveDate: element.savedDate,
              saveTime: element.savedTime,
            };
            this.annualReportListSavedFiles.push(dataDisplay);
            this.noDataFound =
              this.annualReportListSavedFiles.length > 0 ? false : true;
          });
          this.dataSource.data = this.annualReportListSavedFiles;
        } else {
          this.dataSource.data = [];
          this.annualReportListSavedFiles = [];
          this.noDataFound = true;
        }
      });
  }

  getByAccountPeriod() {
    this.accountingId = this.accountingPeriod;

    this.store
      .dispatch(new GetByAccountPeriod(this.accountingId))
      .subscribe((res) => {
        if (res) {
          this.annualData = res.annualReport.annualReportData;
          if (this.annualData !== null) {
            this.id = this.annualData.id;

            this.signatureFileName = '';
            this.signaturefileUploadResponse = new FileUploadResponseModel();
            this.isSignatureSelected = false;

            if (
              this.annualData.signature !== null &&
              this.annualData.signature !== undefined
            ) {
              this.isSignatureSelected = true;
              this.signaturefileUploadResponse.fileUrl =
                this.annualData.signature;
              this.signatureFileName = this.annualData.signature.replace(
                /^.*[\\\/]/,
                ''
              );

              this.getSaasURL(
                this.signaturefileUploadResponse.fileUrl,
                AnnualReportUploadType.signature
              );
            }

            this.accountingPeriod = this.annualData.accountingPeriodId;

            this.annualForm.patchValue({
              date: this.annualData.date,
              name: this.annualData.name,
              designation: this.annualData.designation,
              fontColor: this.annualData.color,
              showPriorYearValue: this.annualData.showPriorYearValue,
            });
          } else {
            this.signatureFileName = '';
            this.isSignatureSelected = false;
            this.id = Guid.EMPTY as unknown as Guid;
            this.annualForm.patchValue({
              date: new Date(),
              name: '',
              designation: '',
              fontColor: '',
            });
          }
        }
        this.src = '';
      });
  }

  onCancel(): void {
    this.accountingId = Guid.EMPTY as unknown as Guid;
    this.ngOnInit();
  }

  generateReport(): void {
    if (this.annualForm.invalid) {
      this.commonService.addValidation(this.annualForm, this.renderer);
    } else {
      this.showDocument = true;
      this.accountingId = this.accountingPeriod;
      this.spinner.show();
      this.onCreate();
    }
  }

  onDelete(element): void {
    let ids: any[] = [];
    ids.push(element.id);

    if (ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: ids,
            type: ConfirmationType.Delete,
            moduleId: Modules.AnnualAccounts,
            totalNumberOfRecordSelected: ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
            accountingPeriod: this.accountingPeriod,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getListSavedFiles();
          }
        });
    }
  }

  onButtonClick(element: any, actionType: ActionType): void {
    if (
      actionType === ActionType.Delete ||
      actionType === ActionType.Export ||
      actionType === ActionType.View
    ) {
      switch (actionType) {
        case ActionType.Delete:
          this.onDelete(element);
          break;
        case ActionType.View:
          this.viewSavedReport(element);
          break;
        case ActionType.Export:
          this.downloadSavedFile(element);
          break;
      }
    } else {
      this.commonService.onFailure(NotificationTextMessage.dataInLockedPeriod);
    }
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  downloadSavedFile(element): void {
    this.spinner.show();
    this.store
      .dispatch(
        new DownloadSavedAnnualReport(this.accountingPeriod, element.id)
      )
      .subscribe();
  }

  // onPrint(): void {
  //   (this.container.documentEditor as DocumentEditorComponent).print();
  // }

  // onDownloadClick(format: string = 'Docx'): void {
  //   this.container.documentEditor.save(
  //     this.container.documentEditor.documentName === ''
  //       ? 'sample'
  //       : this.container.documentEditor.documentName,
  //     format as FormatType
  //   );
  // }
}
