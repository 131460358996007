<div class="content-body">
  <form [formGroup]="cashCodingForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Bank Account <span class="text-danger-300">*</span></p>
        <ng-select
          #accountId
          (focus)="accountId.open()"
          (valueChange)="accountId.close()"
          [disableOptionCentering]="true"
          formControlName="accountId"
          appAutoFocusDirective
        >
          <ng-option *ngFor="let clt of receiptAccountList" [value]="clt.id">
            <div title="{{ clt.name }}">{{ clt.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Currency <span class="text-danger-300">*</span></p>
        <ng-select
          #currency
          (focus)="currency.open()"
          (valueChange)="currency.close()"
          [disableOptionCentering]="true"
          formControlName="currency"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
            <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
              {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
            </div>
          </ng-option>
        </ng-select>
      </div>
    </div>
  </form>
</div>
