import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaxLength } from '@app/core/Enum';
import { SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { AccountState, GetTermsList } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-commerical-details',
  templateUrl: './add-commerical-details.component.html',
  styleUrls: ['./add-commerical-details.component.scss'],
})
export class AddCommericalDetailsComponent implements OnInit {
  termsList: SideListModel[];
  commericalDetailsForm: FormGroup;
  maxLength = MaxLength;

  @Input() triggerEditData: Observable<any>;

  constructor(private store: Store, private commonService: CommonService) {}

  ngOnInit(): void {
    this.getTermsList();
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      this.editCommericalData(data);
    });

    this.commericalDetailsForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange =
        this.commericalDetailsForm.touched;
    });
  }

  getTermsList(): void {
    this.store
      .dispatch(new GetTermsList())
      .pipe(
        tap(() => {
          this.termsList = this.store.selectSnapshot(AccountState.getTerms);
          this.commericalDetailsForm.controls.terms.setValue(
            this.termsList[0].id
          );
        })
      )
      .subscribe();
  }

  editCommericalData(data): void {
    this.commericalDetailsForm.patchValue({
      terms: data.termId,
      vatRegistrationNumber: data.vatRegistrationNumber,
      branchCode: data.bankBranchCode,
      accountNumber: data.bankAccountNumber,
      accountName: data.bankAccountName,
      accountIBANCode: data.bankAccountIBANCode,
    });
  }

  setForm(): void {
    this.commericalDetailsForm = new FormGroup({
      terms: new FormControl(),
      vatRegistrationNumber: new FormControl(''),
      branchCode: new FormControl(''),
      accountNumber: new FormControl(''),
      accountName: new FormControl(''),
      accountIBANCode: new FormControl(''),
    });
  }
}
