import { AnnualReportModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class SaveAnnualReport {
  static readonly type = '[AnnualReport] Save Annual Report';

  constructor(public annualReportData: AnnualReportModel) {}
}

export class GetByAccountPeriod {
  static readonly type = '[AnnualReport] Get By Account Period';

  constructor(public accountPeriodId: Guid) {}
}

export class GetListSavedFile {
  static readonly type = '[AnnualReport] Get List  SavedFile';

  constructor(public accountPeriodId: Guid) {}
}

export class ExportAnnualReport {
  static readonly type = '[AnnualReport] Export Annual Report';

  constructor(public accountPeriodId: Guid) {}
}

export class GetTrusteesReport {
  static readonly type = '[AnnualReport] Get Trustees Report';

  constructor(public accountPeriodId: Guid) {}
}

export class GetIndependentExaminerReport {
  static readonly type = '[AnnualReport] Get Independent Examiner Report';

  constructor(public accountPeriodId: Guid) {}
}

export class GetAnnualReportContent {
  static readonly type = '[AnnualReport] Get Annual Report Content';

  constructor(public accountPeriodId: Guid) {}
}

export class GetAccountingMethod {
  static readonly type = '[AnnualReport] Get Accounting Method';
}

export class GetAnnualReportResponse {
  static readonly type = '[AnnualReport] Get Annual Report Response';

  constructor(public accountPeriodId: Guid) {}
}

export class GetSavedAnnualReportResponse {
  static readonly type = '[AnnualReport] Get Save Annual Report Response';

  constructor(public accountPeriodId: Guid, public Id: any) {}
}

export class SaveAnnualReportResponse {
  static readonly type = '[AnnualReport] Save Annual Report Response';

  constructor(public param: any, public accountPeriodId: Guid) {}
}

export class UploadAnnualReportResponse {
  static readonly type = '[AnnualReport] Upload Annual Report Response';

  constructor(public file: any) {}
}

export class DeleteSavedFile {
  static readonly type = '[AnnualReport] Delete Saved File';

  constructor(public Ids: Array<Guid>, public accountPeriodId: Guid) {}
}

export class DownloadSavedAnnualReport {
  static readonly type = '[AnnualReport] Donwload Save Annual Report';

  constructor(public accountPeriodId: Guid, public Id: any) {}
}
