<div
  id="chart"
  *ngIf="
    incomeClientChartOptionsv1 &&
    incomeClientChartOptionsv1?.series?.length !== 0 &&
    isloadData
  "
>
  <apx-chart
    [series]="incomeClientChartOptionsv1.series!"
    [chart]="incomeClientChartOptionsv1.chart!"
    [labels]="incomeClientChartOptionsv1.labels!"
    [responsive]="incomeClientChartOptionsv1.responsive!"
    [fill]="incomeClientChartOptionsv1.fill!"
    [dataLabels]="incomeClientChartOptionsv1.dataLabels!"
    [legend]="incomeClientChartOptionsv1.legend!"
    [tooltip]="incomeClientChartOptionsv1.tooltip!"
  ></apx-chart>
</div>

<div
  class="norecord-chart"
  *ngIf="incomeClientChartOptionsv1?.series?.length === 0 && isloadData"
>
  <img src="assets/images/pia-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
