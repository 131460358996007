import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { DonorModel } from '@app/core/Models/contact/donor-model';
import { CommonService } from '@app/core/Services';
import { CommonState, MenuState } from '@app/core/Store';
import { CreateDonor, GetDataByDonorId } from '@app/core/Store/contact/donor';
import {
  AddContactDetailsComponent,
  AddGiftDetailsComponent,
  AddSubscriptionDetailsComponent,
} from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-donor',
  templateUrl: './donor.component.html',
  styleUrls: ['./donor.component.scss'],
})
export class DonorComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isShowGiftAid = false;
  headerText = '';

  donorId = Guid.EMPTY as unknown as Guid;
  selectedIndex = 0;
  showPaginator = true;

  donorData: DonorModel;

  moduleName = ModuleName.Donors;
  moduleId = Modules.Donors;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @Output()
  readonly triggerAccountDetails = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddContactDetailsComponent, { static: true })
  contactDetails;

  @ViewChild(AddGiftDetailsComponent, { static: false })
  giftDetails;

  @ViewChild(AddSubscriptionDetailsComponent, { static: true })
  subscriptionDetails;

  triggerEditData: Subject<any> = new Subject<any>();

  subscriptionRouting: Subscription;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;
  dialogRef: MatDialogRef<DonorComponent>;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
    this.dialogRef = injector.get<MatDialogRef<DonorComponent>>(MatDialogRef<DonorComponent>);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.donorId = atob(params.get('id')!) as unknown as Guid;
          this.editDonor();
        }
        else {
          this.donorId = Guid.EMPTY as unknown as Guid;
          this.headerText = 'Add New Donor';
        }
      }
    );
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  tabClick(tab) {
    this.selectedIndex = tab.index;
    window.dispatchEvent(new Event('resize'));
  }

  editDonor(): void {
    this.store.dispatch(new GetDataByDonorId(this.donorId)).subscribe((res) => {
      this.headerText = res.donor.donorData.name;
      this.contactDetails.contactDetailForm.markAsUntouched();
      this.giftDetails.giftForm.markAsUntouched();
      this.subscriptionDetails.subscriptionForm.markAsUntouched();
      this.triggerEditData.next(res.donor.donorData);
    });
    setTimeout(() => {
      const data = {
        id: this.donorId,
        moduleId: this.moduleId
      }
      this.triggerAccountDetails.emit(data);
    }, 0);
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.donorId,
      isExit,
      Modules.Donors,
      RoutingPath.AddDonors
    );
  }

  onSave(isExit: boolean): void {
    if (this.contactDetails.contactDetailForm.invalid) {
      this.commonService.addValidation(
        this.contactDetails.contactDetailForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateDonor(this.donorData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];
      if (
        this.giftDetails.fileData !== undefined &&
        this.giftDetails.fileData !== null &&
        this.giftDetails.fileData.length > 0
      ) {
        this.giftDetails.fileData.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.donorData = {
        id: this.donorId,
        moduleId: this.moduleId,
        name: this.contactDetails.contactDetailForm.controls.name.value,
        lastName: this.contactDetails.contactDetailForm.controls.lastName.value,
        address1:
          this.contactDetails.contactDetailForm.controls.addressLine1.value,
        address2:
          this.contactDetails.contactDetailForm.controls.addressLine2.value,
        city: this.contactDetails.contactDetailForm.controls.city.value,
        address3:
          this.contactDetails.contactDetailForm.controls.addressLine3.value,
        countryId: this.contactDetails.contactDetailForm.controls.country.value,
        postalCode:
          this.contactDetails.contactDetailForm.controls.postCode.value,
        phone: this.contactDetails.contactDetailForm.controls.phone.value,
        email: this.contactDetails.contactDetailForm.controls.email.value,
        mobileNumber:
          this.contactDetails.contactDetailForm.controls.mobileNumber.value,
        fax: this.contactDetails.contactDetailForm.controls.fax.value,
        taxPayerStartDate: this.datepipe
          .transform(
            this.giftDetails.giftForm.controls.eligibaleStartDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        taxPayerEndDate: this.datepipe
          .transform(
            this.giftDetails.giftForm.controls.taxPayerStatusDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        membershipStatus:
          this.subscriptionDetails.subscriptionForm.controls.isActive.value ===
            '0'
            ? 'Inactive'
            : 'Active',
        membershipStartDate: this.datepipe
          .transform(
            this.subscriptionDetails.subscriptionForm.controls.startDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        membershipEndDate: this.datepipe
          .transform(
            this.subscriptionDetails.subscriptionForm.controls.endDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        membershipNote:
          this.subscriptionDetails.subscriptionForm.controls.note.value,
        giftAidAttachment: filedata,
        isGiftAidDonor:
          this.contactDetails.contactDetailForm.controls.isGiftAidDonor.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.donorId)) {
      this.editDonor();
    } else {
      this.donorId = Guid.EMPTY as unknown as Guid;
      this.giftDetails.fileData = [];
      this.contactDetails.ngOnInit();
      this.giftDetails.ngOnInit();
      this.subscriptionDetails.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  isGiftAidSelected(event: boolean) {
    this.isShowGiftAid = event;
    if (!this.isShowGiftAid) {
      this.giftDetails.fileData = [];
      this.giftDetails.ngOnInit();
    }
  }
}
