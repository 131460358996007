import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HeaderModel,
  IncluceExcludeTransactionModel,
  MainList,
  MainListParameters,
  SubmitVATModel,
  VATReturnDateModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class SubmitVatService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createVat(vatData: SubmitVATModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/vatreturn/saveperiod`,
      this.commonService.trimObjectSpace(JSON.stringify(vatData)),
      headers
    );
  }

  getVatDetail(id: Guid): Observable<SubmitVATModel> {
    return this.http.get<SubmitVATModel>(
      `${environment.apiVersionUrl}MTD/vatreturnbyId/${id}`
    );
  }

  getVatReturnDate(): Observable<VATReturnDateModel> {
    return this.http.get<VATReturnDateModel>(
      `${environment.apiVersionUrl}MTD/nextVatReturnDate`
    );
  }

  getVatRemainingTransaction(
    queryParams: MainListParameters,
    isHeaderVisible: boolean,
    id: Guid
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let url = '';
    if (isHeaderVisible) {
      url = 'MTD/vatRemainingTransaction';
    } else {
      url = `MTD/vatReturnTransaction/${id}`;
    }

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}${url}`,
      this.commonService.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  createVATReturnStatus(vatReturnStatus: number, id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/vatReturnStatus/${id}/status/${vatReturnStatus}`,
      this.commonService.trimObjectSpace(JSON.stringify(vatReturnStatus)),
      headers
    );
  }

  checkVatNumber(): Observable<any> {
    return this.http.get<any>(`${environment.apiVersionUrl}MTD/checkVatNumber`);
  }

  getvatRemainingTransactionHeader(): Observable<Array<HeaderModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<HeaderModel>>(
      `${environment.apiVersionUrl}MTD/vatRemainingTransactionHeader`,
      headers
    );
  }

  saveIncludeExclude(
    params: IncluceExcludeTransactionModel,
    id: Guid,
    isInclude: boolean
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/vatreturn/${id}/saveTransaction/${isInclude}`,
      this.commonService.trimObjectSpace(JSON.stringify(params)),
      headers
    );
  }
}
