<div class="d-flex align-items-center hr-bottom justify-space-between pb-10">
  <h3 class="card-title card-title-without-dropdown">Donation</h3>
</div>
<div
  class="main-contain justify-space-between d-flex align-items-center gap-10"
>
  <div class="d-flex align-items-center gap-10">
    <p class="font-size-14 text-gray mb-0 w-50">Donors</p>
    <div id="chart" class="w-150">
      <apx-chart
        [series]="donorOptions?.series!"
        [chart]="donorOptions?.chart!"
        [xaxis]="donorOptions?.xaxis!"
        [yaxis]="donorOptions?.yaxis!"
        [stroke]="donorOptions?.stroke!"
        [tooltip]="donorOptions?.tooltip!"
        [legend]="donorOptions?.legend!"
        [grid]="donorOptions?.grid!"
        [fill]="donorOptions?.fill!"
        [dataLabels]="donorOptions?.dataLabels!"
      ></apx-chart>
    </div>
    <div class="font-size-18 fw-bold text-primary pr-1">
      <span>{{ this.donorData?.total }}</span>
    </div>
  </div>
  <div class="d-flex align-items-center gap-10">
    <div
      class="view-list d-flex align-items-center gap-5"
      [ngClass]="this.donorData?.isProfit ? 'text-success' : 'text-danger-300'"
    >
      <mat-icon *ngIf="!this.donorData?.isProfit" class="font-size-16"
        >south</mat-icon
      >
      <mat-icon *ngIf="this.donorData?.isProfit" class="font-size-16"
        >north</mat-icon
      >
      <span class="font-size-14">{{ this.donorData?.profitOrLoss }}%</span>
      <mat-icon *ngIf="!this.donorData?.isProfit">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="this.donorData?.isProfit">arrow_drop_up</mat-icon>
    </div>
    <div *ngIf="!this.donorData?.isProfit">Less than last year</div>
    <div *ngIf="this.donorData?.isProfit">More than last year</div>
  </div>
</div>
<hr />
<div
  class="main-contain justify-space-between d-flex align-items-center gap-10"
>
  <div class="d-flex align-items-center gap-10">
    <p class="font-size-14 text-gray mb-0 w-50">Donations</p>
    <div id="chart" class="w-150">
      <apx-chart
        [series]="donationOptions?.series!"
        [chart]="donationOptions?.chart!"
        [xaxis]="donationOptions?.xaxis!"
        [yaxis]="donationOptions?.yaxis!"
        [stroke]="donationOptions?.stroke!"
        [tooltip]="donationOptions?.tooltip!"
        [legend]="donationOptions?.legend!"
        [grid]="donationOptions?.grid!"
        [fill]="donationOptions?.fill!"
        [dataLabels]="donationOptions?.dataLabels!"
      ></apx-chart>
    </div>
    <div class="font-size-18 fw-bold text-primary pr-1">
      <span>£</span><span>&nbsp;{{ this.donationData?.total }}</span>
    </div>
  </div>
  <div class="d-flex align-items-center gap-10">
    <div
      class="view-list d-flex align-items-center gap-5"
      [ngClass]="
        this.donationData?.isProfit ? 'text-success' : 'text-danger-300'
      "
    >
      <mat-icon *ngIf="!this.donationData?.isProfit" class="font-size-16"
        >south</mat-icon
      >
      <mat-icon *ngIf="this.donationData?.isProfit" class="font-size-16"
        >north</mat-icon
      >
      <span class="font-size-14">{{ this.donationData?.profitOrLoss }}%</span>
      <mat-icon *ngIf="!this.donationData?.isProfit">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="this.donationData?.isProfit">arrow_drop_up</mat-icon>
    </div>
    <div *ngIf="!this.donationData?.isProfit">Less than last year</div>
    <div *ngIf="this.donationData?.isProfit">More than last year</div>
  </div>
</div>
