import { Injectable } from '@angular/core';
import { ImportService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetBankImportTemplate,
  GetImportTemplate,
  ImportBank,
  ImportModules,
  SaveImport,
} from './import.action';

export class ImportStateInfo {
  exported?: boolean;
  importData?: any;
  saveImportData?: any;
}

@State<ImportStateInfo>({
  name: 'import',
})
@Injectable()
export class ImportState {
  constructor(private importService: ImportService) {}

  @Selector()
  static getImportData(state: ImportStateInfo) {
    return state.importData;
  }

  @Action(ImportModules)
  importModules(
    { patchState }: StateContext<ImportStateInfo>,
    action: ImportModules
  ) {
    return this.importService
      .importModules(action.fileImportRequestModel, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            importData: res,
          });
        })
      );
  }

  @Action(ImportBank)
  importBank(
    { patchState }: StateContext<ImportStateInfo>,
    action: ImportBank
  ) {
    return this.importService
      .importBank(action.fileImportRequestModel, action.customId)
      .pipe(
        tap((res) => {
          patchState({
            importData: res,
          });
        })
      );
  }

  @Action(SaveImport)
  saveImport(
    { patchState }: StateContext<ImportStateInfo>,
    action: SaveImport
  ) {
    return this.importService.saveImport(action.param, action.moduleId).pipe(
      tap((res) => {
        patchState({
          saveImportData: res,
        });
      })
    );
  }

  @Action(GetImportTemplate)
  getImportTemplate(
    { patchState }: StateContext<ImportStateInfo>,
    action: GetImportTemplate
  ) {
    return this.importService.getImportTemplate(action.moduleId).pipe(
      tap((res) => {
        patchState({
          exported: res,
        });
      })
    );
  }

  @Action(GetBankImportTemplate)
  getBankImportTemplate(
    { patchState }: StateContext<ImportStateInfo>,
    action: GetBankImportTemplate
  ) {
    return this.importService.getBankImportTemplate().pipe(
      tap((res) => {
        patchState({
          exported: res,
        });
      })
    );
  }
}
