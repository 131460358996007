import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailTemplateModel } from '@app/core/Models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateService {
  constructor(private http: HttpClient) {}

  getEmailTemplatesData(): Observable<Array<EmailTemplateModel>> {
    return this.http.get<Array<EmailTemplateModel>>(
      `${environment.apiVersionUrl}Company/getEmailTemplates`
    );
  }

  updateEmailTemplates(
    emailTemplates: Array<EmailTemplateModel>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Company/SaveEmailTemplates`,
      emailTemplates,
      headers
    );
  }
}
