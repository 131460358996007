import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  AccountingMethod,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  Currency,
  GlobalComponent,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  AccountState,
  CommonState,
  CreateFund,
  CreateAccount,
  GetCurrencyList,
  GetDataByAccountId,
  GetFundNameList,
  GetNonStandardAccountList,
  GetDataByFundId,
  GetFixedAssetAdditionList,
  CreateContact,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-fixed-assests',
  templateUrl: './add-fixed-assests.component.html',
  styleUrls: ['./add-fixed-assests.component.scss'],
})
export class AddFixedAssestsComponent implements OnInit {
  fixedAssetsForm: FormGroup;

  fixedAssetsTypeList: GroupListModel[];
  currencyList: Currency[];
  supplierList: SideListModel[];
  subscriptionRouting: Subscription;

  tempfixedAssetsGroupList: GroupListModel[];

  defaultCurrency: Guid;
  getListId: Guid;
  periodicDate: any;
  fundNameList: any;

  disabledEditButton = true;
  disabledFundEditButton = true;
  accountingMethodId: any;
  accountingMethod = AccountingMethod;
  isVatInclude = false;
  isVatRegistered: any;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  @Input() triggerEditData: Observable<any>;
  @Output() maxDate = new EventEmitter<any>();
  @Output()
  readonly supplierClick = new EventEmitter<any>();

  @Output()
  readonly isVatIncludedClick = new EventEmitter<any>();

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  private destroy$ = new Subject<void>();

  isSupplierChangePermission: boolean = true;
  isFundChangePermission: boolean = true;

  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public dialog: MatDialog,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.accountingMethodId = this.globalComponent.getAccountingMethod();
    this.setForm();

    this.triggerEditData
      ?.pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.editfixedAssets(data);
      });

    this.fixedAssetsForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.fixedAssetsForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSupplierChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Supplier
      );
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  dateChange(): void {
    let startDate: any = new Date(
      this.fixedAssetsForm.controls['invoiceDate'].value
    );

    startDate = this.datepipe.transform(startDate, 'yyyy-MM-dd')?.toString();
    this.maxDate.emit(startDate);

    let endDate: any = new Date(
      this.fixedAssetsForm.controls['invoiceDate'].value
    );

    this.fixedAssetsForm.controls['dueDate'].setValue(startDate);

    endDate = this.datepipe.transform(startDate, 'yyyy-MM-dd')?.toString();
  }

  supplierChange(event: any): void {
    this.supplierClick.emit(event);
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  gotoAddSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Suppliers,
          headerText: `Add ${ModuleName.Suppliers}`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getSupplier(id);
        }
      });
  }

  gotoEditSupplier(): void {
    if (this.supplierList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Suppliers,
            headerText: `Edit ${ModuleName.Suppliers}`,
            id: this.fixedAssetsForm.controls.accountId.value,
            saveActionName: CreateContact,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getSupplier(id);
          }
        });
    }
  }

  editfixedAssets(data): void {
    let account;
    this.fixedAssetsTypeList.forEach((items) => {
      const accountId = items.listModels.find(
        (x: any) => x.id === data.bookAccountId
      );
      if (accountId !== null && accountId !== undefined) {
        account = accountId;
      }
    });
    this.fixedAssetsForm.controls.billNo.disable();
    this.disabledEditButton = false;
    this.disabledFundEditButton = false;
    this.fixedAssetsForm.patchValue({
      billNo: data.entryNumber,
      invoiceDate: data.entryDate,
      currencyId: data.currencyId,
      accountId: data.accountId,
      dueDate: data.dueDate,
      note: data.note,
      invoiceType: account,
      fundName: data.fundId,
      isVatInclude: data.isVatIncluded,
    });
  }

  getOptionText(option) {
    return option.name;
  }

  resetAccountList(): void {
    this.fixedAssetsTypeList = this.tempfixedAssetsGroupList;
    this.scrollIntoView(this.fixedAssetsForm.controls.invoiceType.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempfixedAssetsGroupList
    );
    this.fixedAssetsTypeList = list;
  }

  setForm(): void {
    this.fixedAssetsForm = new FormGroup({
      invoiceType: new FormControl('', [Validators.required]),
      fundName: new FormControl('', [Validators.required]),
      billNo: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      accountId: new FormControl('', [Validators.required]),
      invoiceDate: new FormControl(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      currencyId: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      dueDate: new FormControl(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      note: new FormControl(''),
      isVatInclude: new FormControl(false),
    });

    this.loadDropdownValues();
  }

  loadDropdownValues(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();

    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    const getCurrency = this.store.dispatch(new GetCurrencyList());
    const getFundNameList = this.store.dispatch(new GetFundNameList());
    const getSupplier = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );
    const getFixedAssetsType = this.store.dispatch(
      new GetFixedAssetAdditionList()
    );

    forkJoin([
      getFixedAssetsType,
      getSupplier,
      getCurrency,
      getFundNameList,
    ]).subscribe((res) => {
      this.supplierList = this.store.selectSnapshot(CommonState.accountList);
      this.currencyList = this.store.selectSnapshot(CommonState.getCurrency);
      this.fundNameList = this.store.selectSnapshot(
        AccountState.getFundNameList
      );
      const accountGroupList = this.store.selectSnapshot(
        AccountState.getFixedAssetAdditionList
      );

      if (accountGroupList.length > 0) {
        this.fixedAssetsTypeList = accountGroupList;
        this.tempfixedAssetsGroupList = this.fixedAssetsTypeList;
      }

      this.fixedAssetsForm.controls.currencyId.setValue(this.defaultCurrency);

      if (this.fundNameList.length > 0) {
        this.disabledFundEditButton = false;
        this.fixedAssetsForm.controls.fundName.setValue(
          this.fundNameList[0].id
        );
      }
    });
  }

  getSupplier(id?: Guid): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap((res) => {
          this.supplierList = res.common.accountList;

          if (id !== null && id !== undefined) {
            this.fixedAssetsForm.controls.accountId.setValue(id);
          }
        })
      )
      .subscribe();
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledFundEditButton = false;
    } else {
      this.disabledFundEditButton = true;
    }
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledFundEditButton = false;
          this.getFund(id);
        }
      });
  }
  getFund(id): void {
    this.store.dispatch(new GetFundNameList()).subscribe((res) => {
      this.fundNameList = res.account.fundNameList;
      if (id !== null && id !== undefined) {
        this.fixedAssetsForm.controls.fundName.setValue(id);
      }
    });
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.fixedAssetsForm.controls.fundName.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onCheckBoxSelected(event: any): void {
    this.isVatInclude = event.checked;
    this.isVatIncludedClick.emit(this.isVatInclude);
  }
}
