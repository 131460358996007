import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company, CompanyModel, SideListModel } from '@app/core/Models';
import { CompanyPreference } from '@app/core/Models/company/company-preference';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '..';
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createCompany(company: CompanyModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Company/save`,
      this.commonService.trimObjectSpace(JSON.stringify(company)),
      headers
    );
  }

  getCompanyList(params: any): Observable<Array<Company>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<Company>>(
      `${environment.apiVersionUrl}Company/list`,
      this.commonService.trimObjectSpace(JSON.stringify(params)),
      headers
    );
  }

  getAvailableCompanyCount(): Observable<number> {
    return this.http.get<number>(`${environment.apiVersionUrl}Company/count`);
  }

  getVatReturnTypeList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Vat/all/returnType`
    );
  }

  setAsDefaultCompany(companyId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Company/${companyId}/setAsDefault`,
      JSON.stringify(companyId),
      headers
    );
  }

  getDataByCompanyId(companyId: Guid): Observable<CompanyModel> {
    return this.http.get<CompanyModel>(
      `${environment.apiVersionUrl}Company/get/${companyId}`
    );
  }

  getCharityRegulator(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}CharityRegulator/all`
    );
  }

  getIndustry(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}IndustryType/all`
    );
  }

  getTimeZone(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Timezone/all`
    );
  }

  getLanguage(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Language/all`
    );
  }

  createCompanyPreference(company: CompanyPreference): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Company/saveCompanyPreference`,
      this.commonService.trimObjectSpace(JSON.stringify(company)),
      headers
    );
  }

  getCompanyPreferenceData(moduleId: number): Observable<CompanyPreference> {
    return this.http.get<CompanyPreference>(
      `${environment.apiVersionUrl}Company/getCompanyPreference/${moduleId}`
    );
  }

  getAccountingMethodList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}AccountingMethod/all`
    );
  }

  getVatSchemeList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Vat/all/scheme`
    );
  }

  deleteFinancialYear(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(ids)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}AccountingPeriod/delete`,
      options
    );
  }

  dateBetweenAccountingPeriod(date?: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/dateBetweenAccountingPeriod`,
      JSON.stringify(date),
      options
    );
  }

  getCompanyRoundingPreference(): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiVersionUrl}Company/getCompanyRoundingPreference`
    );
  }

  updateCompanyRoundingPreference(roundPreference: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}company/UpdateCompanyRoundingPreference/${roundPreference}`,
      null,
      headers
    );
  }
}
