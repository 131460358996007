import { PaymentModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreatePayment {
  static readonly type = '[INVOICE] Create Payment';

  constructor(public payment: PaymentModel) {}
}

export class GetDataByPaymentId {
  static readonly type = '[INVOICE] Get Payment Data By Payment Id';

  constructor(public paymentId: Guid) {}
}

export class ArchiveAndRestorePayment {
  static readonly type = '[INVOICE] Archive and Restore Selected Payment';

  constructor(public paymentIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeletePayment {
  static readonly type = '[INVOICE] Delete Selected Payment';

  constructor(public paymentIds: Array<Guid>) {}
}
