<div class="content-body error-wrapper">
  <div
    class="error-content d-flex justify-content-center align-items-center flex-column gap-10"
  >
    <div class="d-flex justify-content-center align-items-center">
      <h1 class="error-text first-four">4</h1>
      <div class="cog-wheel1">
        <mat-icon>settings</mat-icon>
      </div>
      <h1 class="error-text second-four">4</h1>
    </div>
    <h2 class="error-para m-0">
      Oops! <strong class="text-uppercase">404 - Page not found.</strong>
    </h2>
    <button
      class="action-button primary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onButtonClick()"
    >
      Go To Dashboard
    </button>
  </div>
</div>
