<div id="chart" *ngIf="!isNoRecord && isloadData">
  <apx-chart
    [series]="summaryChart?.series!"
    [chart]="summaryChart?.chart!"
    [dataLabels]="summaryChart?.dataLabels!"
    [plotOptions]="summaryChart?.plotOptions!"
    [yaxis]="summaryChart?.yaxis!"
    [legend]="summaryChart?.legend!"
    [fill]="summaryChart?.fill!"
    [stroke]="summaryChart?.stroke!"
    [tooltip]="summaryChart?.tooltip!"
    [xaxis]="summaryChart?.xaxis!"
    [noData]="summaryChart?.noData!"
    [colors]="summaryChart?.colors!"
  ></apx-chart>
</div>
<div class="norecord-chart" *ngIf="isNoRecord && isloadData">
  <img src="assets/images/column-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
