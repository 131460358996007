<div class="content vat-list">
  <div class="content-body">
    <app-detail-header
      *ngIf="
        (moduleId$ | async) !== moduleEnum.BridgingVAT &&
        (moduleId$ | async) !== moduleEnum.SubmitVat
      "
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-detail-header>
    <div class="mat-table-wrapper">
      <table
        mat-table
        #table
        [dataSource]="dataSource"
        aria-describedby="Vat-Return"
      >
        <ng-container matColumnDef="boxNo">
          <th mat-header-cell class="w-200" *matHeaderCellDef>Box No.</th>
          <td mat-cell *matCellDef="let element">{{ element.boxNo }}</td>
        </ng-container>
        <ng-container matColumnDef="vatDesc">
          <th mat-header-cell *matHeaderCellDef>Vat Desc</th>
          <td mat-cell *matCellDef="let element">{{ element.vatDesc }}</td>
        </ng-container>
        <ng-container matColumnDef="vatAmount">
          <th mat-header-cell *matHeaderCellDef>Vat Amount</th>
          <td mat-cell *matCellDef="let element">
            £ {{ element.vatAmount | numberPipe }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div *ngIf="noDataFound" class="text-align-center mt-1 fw-bold">
        No Data Found
      </div>
    </div>
  </div>
</div>
