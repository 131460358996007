import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';

@Component({
  selector: 'app-add-financial-year',
  templateUrl: './add-financial-year.component.html',
  styleUrls: ['./add-financial-year.component.scss'],
})
export class AddFinancialYearComponent implements OnInit {
  formFinancialYearDetail: UntypedFormGroup;
  financialYearArray: any;
  notificationMessage = NotificationTextMessage;
  displayColumns: string[] = [
    'srNo',
    'startDate',
    'endDate',
    'deleteButton',
    'lockButton',
  ];
  financialYearData: any[] = [];
  tableDataSource: MatTableDataSource<AbstractControl>;
  @Input() triggerEditData: Observable<any>;
  @Input() triggerEditFinancialData: Observable<any>;
  isNewRow = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private highlightRow: HighlightRow,
    public datepipe: DatePipe,
    public commonService: CommonService,
    public store: Store,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setJournalDetailsForm(true);
    this.triggerEditFinancialData?.subscribe((data) => {
      this.editFinancialYear(data);
    });

    this.formFinancialYearDetail.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange =
        this.formFinancialYearDetail.touched;
    });
  }

  editFinancialYear(data: any): void {
    this.financialYearData = [];
    this.highlightRow.financialData = [];
    this.financialYearArray = this.formFinancialYearDetail.get(
      'financialYearArray'
    ) as UntypedFormArray;

    this.financialYearArray.clear();

    data.forEach((element) => {
      this.financialYearData?.push(element);
    });

    this.financialYearData.forEach((item, i) => {
      this.financialYearArray.push(this.buildOrderItemsForm(item));
      if (item.isLocked) {
        this.financialYearArray.controls[i].get('startDate').disable();
        this.financialYearArray.controls[i].get('endDate').disable();
      }
    });

    this.setDataSource(this.financialYearArray);
  }

  buildOrderItemsForm(item: any): FormGroup {
    this.highlightRow.financialData.push({
      id: item.id,
      startDate: this.datepipe.transform(item.fromDate, 'yyyy-MM-dd'),
      endDate: this.datepipe.transform(item.toDate, 'yyyy-MM-dd'),
      isLocked: item.isLocked,
    });

    return this.formBuilder.group({
      id: item.id,
      startDate: item.fromDate,
      endDate: item.toDate,
      attachment: '',
      minDate: item.fromDate,
      maxDate: item.toDate,
      isLocked: item.isLocked,
    });
  }

  setJournalDetailsForm(addNewRow: boolean): void {
    this.formFinancialYearDetail = new FormGroup({
      financialYearArray: new UntypedFormArray([]),
    });

    this.financialYearArray = this.formFinancialYearDetail.get(
      'financialYearArray'
    ) as UntypedFormArray;

    this.setDataSource(this.financialYearArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.financialYearArray = this.formFinancialYearDetail.get(
      'financialYearArray'
    ) as UntypedFormArray;
    this.financialYearArray.push(this.setForm());
    this.highlightRow.financialData = [];
    this.financialYearArray?.getRawValue().forEach((x) => {
      this.highlightRow.financialData.push({
        id: x.id,
        startDate: this.datepipe.transform(x.startDate, 'yyyy-MM-dd'),
        endDate: this.datepipe.transform(x.endDate, 'yyyy-MM-dd'),
        isLocked: x.isLocked,
      });
    });

    this.setDataSource(this.financialYearArray);
  }

  addNewRow(): void {
    this.isNewRow = true;
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  dateChange(isStartDate: boolean, index: number): void {
    let endDate;
    if (isStartDate) {
      this.financialYearArray.controls[index]
        .get('minDate')
        ?.setValue(
          this.financialYearArray.controls[index].get('startDate')?.value
        );

      endDate = new Date(
        this.financialYearArray.controls[index].get('startDate')?.value
      );
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(endDate.getDate() - 1);
      this.financialYearArray.controls[index].get('endDate')?.setValue(endDate);
    }
    this.highlightRow.financialData = [];

    this.financialYearArray?.getRawValue().forEach((x) => {
      this.highlightRow.financialData.push({
        id: x.id,
        startDate: this.datepipe.transform(x.startDate, 'yyyy-MM-dd'),
        endDate: this.datepipe.transform(x.endDate, 'yyyy-MM-dd'),
        isLocked: x.isLocked,
      });
    });
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    let startDate;
    let endDate;
    if (this.isNewRow) {
      if (this.financialYearArray.length > 0) {
        startDate = new Date(
          this.financialYearArray.controls[
            this.financialYearArray.length - 1
          ].get('startDate').value
        );
        startDate.setFullYear(startDate.getFullYear() + 1);
      } else {
        startDate = new Date();
      }
    } else {
      startDate = new Date();
    }

    endDate = new Date(startDate);
    endDate.setFullYear(endDate.getFullYear() + 1);
    endDate.setDate(endDate.getDate() - 1);

    return this.formBuilder.group({
      id: new FormControl(Guid.EMPTY as unknown as Guid),
      startDate: new FormControl(startDate, Validators.required),
      endDate: new FormControl(endDate, Validators.required),
      attachment: new FormControl(),
      minDate: new FormControl(new Date()),
      maxDate: new FormControl(new Date()),
      isLocked: new FormControl(false),
    });
  }

  onDelete(index: number, element): void {
    if (this.financialYearArray.length > 1) {
      if (
        element.controls.id.value !== undefined &&
        element.controls.id.value !== null &&
        element.controls.id.value !== Guid.EMPTY
      ) {
        let ids: any = [];
        ids.push(element.controls.id.value);

        this.dialog
          .open(ConfirmationBoxComponent, {
            data: {
              ids: ids,
              type: ConfirmationType.Delete,
              moduleId: Modules.FinancialYear,
              headerText: NotificationHeader.confirmation,
              detailText: NotificationDetails.deleteAllDetailText,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.financialYearArray = this.formFinancialYearDetail.get(
                'financialYearArray'
              ) as UntypedFormArray;
              if (this.financialYearArray.length === 1) {
                return;
              }
              this.financialYearArray.removeAt(index);
              this.highlightRow.financialData.splice(index, 1);
              this.setDataSource(this.financialYearArray);
            }
          });
      } else {
        this.financialYearArray = this.formFinancialYearDetail.get(
          'financialYearArray'
        ) as UntypedFormArray;
        if (this.financialYearArray.length === 1) {
          return;
        }
        this.financialYearArray.removeAt(index);
        this.highlightRow.financialData.splice(index, 1);
        this.setDataSource(this.financialYearArray);
      }
    } else {
      this.commonService.onFailure(
        'Minimum one accounting period is required!'
      );
    }
  }

  onLockClick(index: number, item: any): void {
    item.value.isLocked =
      item.controls.startDate.value !== null
        ? !item.value.isLocked
        : item.value.isLocked;
    this.financialYearArray.controls[index]
      .get('isLocked')
      .setValue(item.value.isLocked);
    this.financialYearArray = this.formFinancialYearDetail.get(
      'financialYearArray'
    ) as UntypedFormArray;

    this.financialYearArray.controls.forEach((element, i) => {
      if (i === index) {
        if (!this.financialYearArray.controls[i].get('isLocked').value) {
          this.financialYearArray.controls[i].get('startDate').enable();
          this.financialYearArray.controls[i].get('endDate').enable();
        } else {
          this.financialYearArray.controls[i].get('startDate').disable();
          this.financialYearArray.controls[i].get('endDate').disable();
        }
      }
    });
  }
}
