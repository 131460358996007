<div class="content-body">
  <form [formGroup]="quotationForm">
    <div class="d-flex gap-40">
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Customer Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isCustomerChangePermission"
            >
              <mat-icon (click)="gotoAddCustomer()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledEditButton || !isCustomerChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditCustomer()"
                title="edit"
                fontSet="material-icons-outlined"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #customerName
          (focus)="customerName.open()"
          (valueChange)="customerName.close()"
          [disableOptionCentering]="true"
          formControlName="customerName"
          panelClass="mat-select-position"
          appAutoFocusDirective
          (change)="customerChange($event)"
        >
          <ng-option *ngFor="let option of customerList" [value]="option.id">
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Quotation No. <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            maxlength="{{ maxLength.maxLength100 }}"
            matInput
            formControlName="quotationNo"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Account Name <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="invoiceType"
            required
            [matAutocomplete]="accountGroup"
            (keyup)="onAccountSearch($event)"
            (mousedown)="resetAccountList()"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            #accountGroup="matAutocomplete"
            (opened)="scrollIntoView(quotationForm.controls.invoiceType.value)"
          >
            <mat-optgroup
              *ngFor="let group of accountGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.quotationForm.controls.invoiceType.value.id === names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Fund Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isFundChangePermission"
            >
              <mat-icon (click)="gotoAddFund()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledFundEditButton || !isFundChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditFund()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #fundName
          (focus)="fundName.open()"
          (valueChange)="fundName.close()"
          [disableOptionCentering]="true"
          formControlName="fundName"
          panelClass="mat-select-position"
          (change)="fundChange($event)"
        >
          <ng-option *ngFor="let fn of fundNameList" [value]="fn.id">
            <div class="clamp" title="{{ fn.name }}">{{ fn.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>Currency <span class="text-danger-300">*</span></p>
        <ng-select
          #selectCurrency
          (focus)="selectCurrency.open()"
          (valueChange)="selectCurrency.close()"
          [disableOptionCentering]="true"
          formControlName="currency"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
            <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
              {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
            </div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="entryDate"
            [matDatepicker]="entryDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="entryDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #entryDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(quotationForm.get('entryDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(quotationForm.get('entryDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field m-0" *ngIf="isVatRegistered">
        <mat-checkbox
          formControlName="isVatInclude"
          (change)="onCheckBoxSelected($event)"
        >
          Amount Including VAT
        </mat-checkbox>
      </div>
    </div>
    <div class="form-field wp-100 textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="note"
          autocomplete="off"
          maxlength="{{ maxLength.maxLength2000 }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
