import { Guid } from 'guid-typescript';

export class DonationModel {
  id: Guid;
  donorId: Guid;
  sponsorEventId: Guid | null;
  activityId: Guid | null;
  fundId: Guid;
  depositToAccountId: number;
  depositAmount: number;
  documentNumber?: string;
  reference?: string;
  donationDate?: string;
  incomeAccount: number;
  donationAmount: number;
  benefitAmountToDonor: number;
  note: string;
  attachment: Array<string>;
  transactionLogId?: Guid;
  isManualBank?: boolean;
}

export class DonationCodingModel {
  donationDate?: string;
  sponsorEventId: Guid | null;
  activityId: Guid | null;
  fundId: Guid;
  donationCodingItems: Array<DonationCodingItemModel>;
}

export class DonationCodingItemModel {
  id?: Guid;
  donorId: Guid;
  depositToAccountId: number;
  incomeAccount: number;
  donationAmount: number;
  note: string;
}
