<div class="import-stepper mt-2">
  <mat-stepper [linear]="true" #stepper>
    <mat-step disableRipple>
      <ng-template matStepLabel>Upload</ng-template>
      <form [formGroup]="uploadFormGroup" class="p-0">
        <div
          class="upload-detail"
          id="drop-area"
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event)"
        >
          <div class="import-leads mb-2">
            <div class="file-box" style="width: -webkit-fill-available">
              <div class="drop-detail">
                <p>Drag and drop files to upload</p>
                <h4><span>or</span></h4>
                <button class="file-upload-button primary-button">
                  <input
                    type="file"
                    #fileInput
                    accept=".xls, .xlsx"
                    (change)="onFileSelected(fileInput)"
                    (click)="onFileOpen($event)"
                  />Browse
                </button>
                <div
                  class="file-info d-flex align-items-center justify-space-between mt-10 mb-10 gap-10"
                  *ngIf="fileInfo !== ''"
                >
                  <p class="m-0">{{ fileInfo }}</p>
                  <button
                    class="file-remove-button"
                    type="button"
                    (click)="onCloseClick()"
                    mat-button
                    disableRipple
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <div
                  class="d-flex align-items-center justify-content-center gap-5 cursor-pointer mt-10"
                  (click)="downloadTemplateFile()"
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >file_download</mat-icon
                  >
                  <p class="m-0 link">Download sample file</p>
                </div>
                <div class="file-formate">
                  <p>File Format : XLSX</p>
                </div>
                <div
                  style="text-align: left !important"
                  *ngIf="importDetailList.length > 0 && fileInfo === ''"
                >
                  <div
                    class="fw-bold mb-1"
                    *ngFor="let element of importDetailList"
                  >
                    {{ element.message }}({{ element.count }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step disableRipple>
      <ng-template matStepLabel>Verified Data</ng-template>
      <div class="content-body mt-2">
        <form [formGroup]="importForm" class="p-0">
          <div class="form-field d-flex gap-40">
            <div *ngIf="showAccountName">
              <p>Account Name<span class="text-danger-300">*</span></p>
              <mat-form-field appearance="fill" class="w-200">
                <input
                  type="text"
                  matInput
                  formControlName="accountObj"
                  [matAutocomplete]="accountGroup"
                  (keyup)="onAccountSearch($event)"
                  (mousedown)="resetAccountList()"
                  [required]="showAccountName"
                />
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete
                  [displayWith]="getOptionText"
                  (opened)="
                    scrollIntoView(this.importForm.controls.accountObj.value)
                  "
                  #accountGroup="matAutocomplete"
                >
                  <mat-optgroup
                    *ngFor="let group of accountGroupList"
                    [label]="group.groupName"
                    title="{{ group.groupName }}"
                  >
                    <mat-option
                      *ngFor="let names of group.listModels"
                      [value]="names"
                      title="{{ names.name }}"
                      [class]="
                        this.importForm.controls.accountObj.value?.id ===
                        names.id
                          ? 'mat-selected'
                          : ''
                      "
                    >
                      {{ names.name }}
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="form-field" *ngIf="showIncomeAccount">
              <p>Income Account<span class="text-danger-300">*</span></p>
              <mat-form-field appearance="fill" class="w-200">
                <input
                  type="text"
                  matInput
                  formControlName="incomeAccountObj"
                  [matAutocomplete]="accountGroup"
                  (keyup)="onAccountSearch($event)"
                  (mousedown)="resetAccountList()"
                  [required]="showIncomeAccount"
                />
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete
                  [displayWith]="getOptionText"
                  (opened)="
                    scrollIntoView(
                      this.importForm.controls.incomeAccountObj.value
                    )
                  "
                  #accountGroup="matAutocomplete"
                >
                  <mat-optgroup
                    *ngFor="let group of accountGroupList"
                    [label]="group.groupName"
                    title="{{ group.groupName }}"
                  >
                    <mat-option
                      *ngFor="let names of group.listModels"
                      [value]="names"
                      title="{{ names.name }}"
                      [class]="
                        this.importForm.controls.incomeAccountObj.value?.id ===
                        names.id
                          ? 'mat-selected'
                          : ''
                      "
                    >
                      {{ names.name }}
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="form-field" *ngIf="showDepositAccount">
              <p>Deposit Account<span class="text-danger-300">*</span></p>
              <ng-select
                #depsoitAccount
                (focus)="depsoitAccount.open()"
                (valueChange)="depsoitAccount.close()"
                [disableOptionCentering]="true"
                formControlName="depositAccountObj"
                panelClass="mat-select-position"
                [required]="showDepositAccount"
                class="w-200"
              >
                <ng-option *ngFor="let fn of depositToList" [value]="fn">
                  <div title="{{ fn.name }}">{{ fn.name }}</div>
                </ng-option>
              </ng-select>
            </div>

            <div class="form-field quick-add-buttons" *ngIf="showFundName">
              <div class="d-flex justify-space-between">
                <p>Fund Name<span class="text-danger-300">*</span></p>
                <span>
                  <button mat-button disableRipple>
                    <mat-icon (click)="gotoAddFund()" title="add"
                      >add_circle_outline</mat-icon
                    >
                  </button>
                  <button
                    mat-button
                    disableRipple
                    [disabled]="
                      this.importForm.controls.fundObj.value === null ||
                      this.importForm.controls.fundObj.value === undefined
                    "
                  >
                    <mat-icon
                      (click)="gotoEditFund()"
                      fontSet="material-icons-outlined"
                      title="edit"
                      >edit</mat-icon
                    >
                  </button>
                </span>
              </div>
              <ng-select
                #fundName
                (focus)="fundName.open()"
                (valueChange)="fundName.close()"
                [disableOptionCentering]="true"
                formControlName="fundObj"
                panelClass="mat-select-position"
                [required]="showFundName"
                class="w-200"
              >
                <ng-option *ngFor="let fn of fundNameList" [value]="fn">
                  <div class="clamp" title="{{ fn.name }}">{{ fn.name }}</div>
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div
            class="d-flex gap-20 align-items-center mb-2"
            *ngIf="
              selectedModuleId === modules.TrialBalance ||
              selectedModuleId === modules.AccountProductionTrialBalance
            "
          >
            <p class="fw-bold white-space-nowrap m-0">
              Period: <span class="text-danger-300">*</span>
            </p>
            <mat-form-field class="w-200">
              <mat-select
                panelClass="mat-select-position"
                [disableOptionCentering]="true"
                formControlName="accountingPeriod"
                [(ngModel)]="selectedValue"
                (selectionChange)="onAccountPeriodChange()"
              >
                <mat-option
                  *ngFor="let option of periodicDateList"
                  [value]="option.id"
                  title="{{ option.fromDate | date: 'dd-LLL-yyyy' }} - {{
                    option.toDate | date: 'dd-LLL-yyyy'
                  }}"
                >
                  {{ option.fromDate | date: "dd-LLL-yyyy" }} -
                  {{ option.toDate | date: "dd-LLL-yyyy" }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="d-flex gap-20 align-items-center mb-2"
            *ngIf="selectedModuleId === modules.OpeningBalance"
          >
            <p class="fw-bold white-space-nowrap m-0">
              Date: <span class="text-danger-300">*</span>
            </p>
            <mat-form-field appearance="standard" class="w-200">
              <input
                matInput
                formControlName="accountingDate"
                [matDatepicker]="accountingDatePicker"
                autocomplete="off"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="accountingDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #accountingDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </form>
        <mat-accordion [multi]="true">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon fontSet="material-icons-outlined" class="text-primary"
                  >description</mat-icon
                >
                <div class="d-flex flex-column">
                  <span
                    >Success List ({{ importSuccessList.data.length }})</span
                  >
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content-body">
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  aria-describedby="Import Success List"
                  [dataSource]="importSuccessList"
                >
                  <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Customer Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddCustomer()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #customerName
                        [disableOptionCentering]="true"
                        (focus)="customerName.open()"
                        (valueChange)="customerName.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        disabled
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of customerList"
                          [value]="option.id"
                        >
                          <div class="clamp" title="{{ option.name }}">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="accountName">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      title="{{
                        this.importForm.controls.accountObj.value?.name
                      }}"
                    >
                      <div
                        class="clamp"
                        title="{{
                          this.importForm.controls.accountObj.value?.name
                        }}"
                      >
                        {{ this.importForm.controls.accountObj.value?.name }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fundName">
                    <th mat-header-cell *matHeaderCellDef>Fund Name</th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      title="{{ this.importForm.controls.fundObj.value?.name }}"
                    >
                      <div
                        class="clamp"
                        title="{{
                          this.importForm.controls.fundObj.value?.name
                        }}"
                      >
                        {{ this.importForm.controls.fundObj.value?.name }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceNo">
                    <th mat-header-cell *matHeaderCellDef>Invoice No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceDate">
                    <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="invoiceSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef>Due Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.dueDateError">
                        {{ element.dueDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.dueDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.dueDate"
                          [matDatepicker]="dueDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="dueDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #dueDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="productName">
                    <th mat-header-cell *matHeaderCellDef>Product Name</th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      title="{{ element.productName }}"
                    >
                      <div class="clamp" title="{{ element.productName }}">
                        {{ element.productName }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef class="w-50">Qty</th>
                    <td mat-cell *matCellDef="let element">
                      <ng-container *ngIf="+element.quantity !== 0">{{
                        element.quantity
                      }}</ng-container>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Price
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.price }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="vatRate">
                    <th mat-header-cell *matHeaderCellDef>Vat Rate</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field class="w-150">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.vatRateId"
                          dropdownPosition="top"
                          disabled
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of vatRateList"
                            [value]="option.id"
                            title="{{ option.name }}"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="vatAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Vat Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatAmount | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="totalAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Total Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.totalAmount | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="supplierName">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Supplier Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddSupplier()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #suppliername
                        (focus)="suppliername.open()"
                        (valueChange)="suppliername.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        disabled
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of supplierList"
                          [value]="option.id"
                        >
                          <div class="clamp" title="{{ option.name }}">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="billNo">
                    <th mat-header-cell *matHeaderCellDef>Bill No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="billDate">
                    <th mat-header-cell *matHeaderCellDef>Bill Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="billDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="billDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #billDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Credit Note No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Credit Note Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="creditNoteDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="creditNoteDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #creditNoteDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Debit Note No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Debit Note Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="debitNoteDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="debitNoteDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #debitNoteDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetNo">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetDate">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="fixedAssetDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="fixedAssetDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #fixedAssetDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="accountIBANCode">
                    <th mat-header-cell *matHeaderCellDef>AccountIBAN Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.accountIBANCode }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine1">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 1</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine1 }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine2">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 2</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine2 }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountName">
                    <th mat-header-cell *matHeaderCellDef>Bank Account Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    >
                      Ttoal
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Bank Account Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="branchCode">
                    <th mat-header-cell *matHeaderCellDef>Branch Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.branchCode }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.city }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef>Contact Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.contactName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Country
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #country
                        (focus)="country.open()"
                        (valueChange)="country.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.countryId"
                        panelClass="mat-select-position"
                        disabled
                        required
                      >
                        <ng-option
                          *ngFor="let cl of countryList"
                          [value]="cl.id"
                        >
                          <div title="{{ cl.description }}">
                            {{ cl.description }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Email
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.email }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.phone }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="postCode">
                    <th mat-header-cell *matHeaderCellDef>Post Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.postCode }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="tradingName">
                    <th mat-header-cell *matHeaderCellDef>Trading Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.tradingName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="vatRegistrationNo">
                    <th mat-header-cell *matHeaderCellDef>
                      Vat RegistrationNo
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatRegistrationNo }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container
                    matColumnDef="donorName"
                    *ngIf="selectedModuleId === modules.Donors"
                  >
                    <th mat-header-cell *matHeaderCellDef>Donor Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.donorName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container
                    matColumnDef="firstName"
                    *ngIf="selectedModuleId === modules.Donors"
                  >
                    <th mat-header-cell *matHeaderCellDef>First Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.firstName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container
                    matColumnDef="lastName"
                    *ngIf="selectedModuleId === modules.Donors"
                  >
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.lastName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container
                    matColumnDef="donorName"
                    *ngIf="selectedModuleId === modules.Donations"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      Donor Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddDonor()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #donorName
                        [disableOptionCentering]="true"
                        (focus)="donorName.open()"
                        (valueChange)="donorName.close()"
                        [(ngModel)]="element.donorId"
                        panelClass="mat-select-position"
                        [disabled]="!element.donorError"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of donorList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}">{{ option.name }}</div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="depositAccount">
                    <th mat-header-cell *matHeaderCellDef>Deposit Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.depositAccountObj.value?.name
                      }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="donationDate">
                    <th mat-header-cell *matHeaderCellDef>Donation Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.donationDateError">
                        {{ element.donationDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.donationDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.donationDate"
                          [matDatepicker]="donationDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="donationDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #donationDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="donationAmount">
                    <th mat-header-cell *matHeaderCellDef>Donation Amount</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.donationAmount | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="incomeAccount">
                    <th mat-header-cell *matHeaderCellDef>Income Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.incomeAccountObj.value?.name
                      }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="sponsorEvent">
                    <th mat-header-cell *matHeaderCellDef>Sponsor Event</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #sponsorEvent
                        (focus)="sponsorEvent.open()"
                        (valueChange)="sponsorEvent.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.sponsorEventId"
                        panelClass="mat-select-position"
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let fn of sponsorEventList"
                          [value]="fn.id"
                        >
                          <div class="clamp" title="{{ fn.name }}">
                            {{ fn.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="activity">
                    <th mat-header-cell *matHeaderCellDef>Activity</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #activity
                        (focus)="activity.open()"
                        (valueChange)="activity.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.activityId"
                        panelClass="mat-select-position"
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let fn of activityList"
                          [value]="fn.id"
                        >
                          {{ fn.name }}
                          <div class="clamp" title="{{ fn.name }}">
                            {{ fn.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="endowmentCredit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Credit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.endowmentCredit | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    >
                      {{ totalEndowmentCredit | numberPipe }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="endowmentDebit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Debit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.endowmentDebit | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    >
                      {{ totalEndowmentDebit | numberPipe }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="restrictedCredit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Credit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.restrictedCredit | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    >
                      {{ totalRestrictedCredit | numberPipe }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="restrictedDebit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Debit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.restrictedDebit | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    >
                      {{ totalRestrictedDebit | numberPipe }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="unrestrictedCredit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Credit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.unrestrictedCredit | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    >
                      {{ totalUnrestrictedCredit | numberPipe }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="unrestrictedDebit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Debit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.unrestrictedDebit | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                      class="text-align-center"
                    >
                      {{ totalUnrestrictedDebit | numberPipe }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="account">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #accountName
                        [items]="accountGroupList"
                        required
                        (focus)="accountName.open()"
                        (valueChange)="accountName.close()"
                        class="bank-account"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.accountId"
                        bindLabel="name"
                        bindValue="id"
                        groupBy="groupName"
                        (ngModelChange)="checkValidElement(element, i, true)"
                        panelClass="mat-select-position"
                        appendTo="body"
                      >
                        <ng-template ng-option-tmp let-item="item">
                          <span
                            class="ng-option-label"
                            [attr.title]="item.name"
                            >{{ item.name }}</span
                          >
                        </ng-template>
                      </ng-select>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                  </ng-container>
                  <ng-container matColumnDef="header-row-first-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="1"
                    ></th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-second-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="2"
                      class="text-align-center"
                    >
                      Unrestricted
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-third-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="2"
                      class="text-align-center"
                    >
                      Restricted
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-four-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="2"
                      class="text-align-center"
                    >
                      Endowment
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-fifth-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="1"
                    ></th>
                  </ng-container>
                  <div *ngIf="selectedModuleId === modules.TrialBalance">
                    <ng-container
                      *ngFor="let columnName of displayedColumns2"
                      [matColumnDef]="columnName"
                    >
                      <td
                        mat-footer-cell
                        *matFooterCellDef="let row; let i = index"
                        [ngClass]="
                          countDifferenceOfDrCr(columnName) === 'Difference'
                            ? 'text-align-left'
                            : 'text-align-center'
                        "
                      >
                        {{
                          countDifferenceOfDrCr(columnName) === "Difference"
                            ? countDifferenceOfDrCr(columnName)
                            : (countDifferenceOfDrCr(columnName) | numberPipe)
                        }}
                      </td>
                    </ng-container>
                  </div>
                  <ng-container
                    *ngIf="selectedModuleId === modules.TrialBalance"
                  >
                    <tr
                      mat-header-row
                      *matHeaderRowDef="[
                        'header-row-first-group',
                        'header-row-second-group',
                        'header-row-third-group',
                        'header-row-four-group'
                      ]"
                    ></tr>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                  <div *ngIf="panelHeaderTitle === 'Trial Balance'">
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                    <tr
                      mat-footer-row
                      *matFooterRowDef="displayedColumns2"
                    ></tr>
                  </div>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="importErrorList.data.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon fontSet="material-icons-outlined" class="text-primary"
                  >error_outline</mat-icon
                >
                <div class="d-flex align-items-center gap-40">
                  <span>Error List ({{ importErrorList.data.length }})</span>
                  <button
                    class="action-button primary-button"
                    mat-button
                    disableRipple
                    *ngIf="
                      selectedModuleId === modules.TrialBalance ||
                      selectedModuleId === modules.OpeningBalance ||
                      selectedModuleId === modules.AccountProductionTrialBalance
                    "
                    (click)="gotoAddCustomAccount()"
                  >
                    <mat-icon class="p-0 m-0 no-border button-icon"
                      >add</mat-icon
                    >
                    Add Custom Account
                  </button>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content-body">
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  aria-describedby="Import Error List"
                  [dataSource]="importErrorList"
                >
                  <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Customer Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddCustomer()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #customerName
                        [disableOptionCentering]="true"
                        (focus)="customerName.open()"
                        (valueChange)="customerName.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        [disabled]="!element.accountError"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of customerList"
                          [value]="option.id"
                        >
                          <div class="clamp" title="{{ option.name }}">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accountName">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      title="{{
                        this.importForm.controls.accountObj.value?.name
                      }}"
                    >
                      <div
                        class="clamp"
                        title="{{
                          this.importForm.controls.accountObj.value?.name
                        }}"
                      >
                        {{ this.importForm.controls.accountObj.value?.name }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fundName">
                    <th mat-header-cell *matHeaderCellDef>Fund Name</th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      title="{{ this.importForm.controls.fundObj.value?.name }}"
                    >
                      <div
                        class="clamp"
                        title="{{
                          this.importForm.controls.fundObj.value?.name
                        }}"
                      >
                        {{ this.importForm.controls.fundObj.value?.name }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceNo">
                    <th mat-header-cell *matHeaderCellDef>Invoice No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceDate">
                    <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>

                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="invoiceErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.invoiceDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef>Due Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.dueDateError">
                        {{ element.dueDate | date: "dd-LLL-yyyy" }}
                      </span>

                      <mat-form-field
                        *ngIf="element.dueDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.dueDate"
                          [matDatepicker]="dueDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.dueDate,
                              importDateName.dueDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="dueDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #dueDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="productName">
                    <th mat-header-cell *matHeaderCellDef>Product Name</th>
                    <td
                      mat-cell
                      *matCellDef="let element; let i = index"
                      title="{{ element.productName }}"
                    >
                      <div
                        *ngIf="!element.productNameError"
                        class="clamp"
                        title="{{ element.productName }}"
                      >
                        {{ element.productName }}
                      </div>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.productNameError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.productName"
                          (focusout)="checkErrorProductName(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef class="w-50">Qty</th>
                    <td mat-cell *matCellDef="let element">
                      <ng-container *ngIf="+element.quantity !== 0">{{
                        element.quantity
                      }}</ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Price
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.priceError">{{
                        element.price | numberPipe
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.priceError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.price"
                          (focusout)="checkErrorProductPrice(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatRate">
                    <th mat-header-cell *matHeaderCellDef>Vat Rate</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field class="w-150">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.vatRateId"
                          dropdownPosition="top"
                          [disabled]="!element.vatRateError"
                          panelClass="mat-select-position"
                          (ngModelChange)="checkValidElement(element, i)"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of vatRateList"
                            [value]="option.id"
                            title="{{ option.name }}"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Vat Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatAmount | numberPipe }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Total Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.totalAmount | numberPipe }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="supplierName">
                    <th mat-header-cell *matHeaderCellDef>
                      Supplier Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddSupplier()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #suppliername
                        [disableOptionCentering]="true"
                        (focus)="suppliername.open()"
                        (valueChange)="suppliername.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        [disabled]="!element.accountError"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of supplierList"
                          [value]="option.id"
                        >
                          <div class="clamp" title="{{ option.name }}">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billNo">
                    <th mat-header-cell *matHeaderCellDef>Bill No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billDate">
                    <th mat-header-cell *matHeaderCellDef>Bill Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>

                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="billDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.billDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="billDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #billDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Credit Note No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Credit Note Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>

                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="creditNoteDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.creditNoteDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="creditNoteDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #creditNoteDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Debit Note No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Debit Note Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>

                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="debitNoteDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.debitNoteDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="debitNoteDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #debitNoteDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetNo">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                          maxlength="{{ maxLength.maxLength25 }}"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetDate">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>

                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="fixedAssestDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.fixedAssetDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="fixedAssestDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #fixedAssestDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accountIBANCode">
                    <th mat-header-cell *matHeaderCellDef>AccountIBAN Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.accountIBANCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine1">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 1</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine1 }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine2">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 2</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine2 }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountName">
                    <th mat-header-cell *matHeaderCellDef>Bank Account Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Bank Account Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="branchCode">
                    <th mat-header-cell *matHeaderCellDef>Branch Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.branchCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.city }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Contact Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.contactName"
                          (focusout)="checkCustomerValidElement(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.contactNameError"
                          unicodeCharacter
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Country
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #country
                        (focus)="country.open()"
                        (valueChange)="country.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.countryId"
                        (ngModelChange)="
                          selectedModuleId === modules.Donors
                            ? checkDonorValidElement(element, i)
                            : checkCustomerValidElement(element, i)
                        "
                        [disabled]="!element.countryError"
                        panelClass="mat-select-position"
                        required
                      >
                        <ng-option
                          *ngFor="let cl of countryList"
                          [value]="cl.id"
                        >
                          <div title="{{ cl.description }}">
                            {{ cl.description }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Email
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          emailValidator
                          [(ngModel)]="element.email"
                          (focusout)="
                            selectedModuleId === modules.Donors
                              ? checkDonorValidElement(element, i)
                              : checkCustomerValidElement(element, i)
                          "
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="email"
                          [disabled]="!element.emailError"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.phoneError">{{
                        element.phone
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.phoneError"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.phone"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength10 }}"
                          type="text"
                          oninput="this.value = this.value.replace(/[^0-9\+]/g, '').replace(/(\.*)\./g, '$1');"
                          [disabled]="!element.phoneError"
                          (focusout)="checkErrorPhoneNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="postCode">
                    <th mat-header-cell *matHeaderCellDef>Post Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.postCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="tradingName">
                    <th mat-header-cell *matHeaderCellDef>Trading Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.tradingName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatRegistrationNo">
                    <th mat-header-cell *matHeaderCellDef>
                      Vat RegistrationNo
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatRegistrationNo }}
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="donorName"
                    *ngIf="selectedModuleId === modules.Donors"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Donor Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.donorName"
                          (focusout)="checkDonorValidElement(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.donorNameError"
                          unicodeCharacter
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="firstName"
                    *ngIf="selectedModuleId === modules.Donors"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      First Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.firstName"
                          (focusout)="checkDonorValidElement(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.donorNameError"
                          unicodeCharacter
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="lastName"
                    *ngIf="selectedModuleId === modules.Donors"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Last Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.lastName"
                          (focusout)="checkDonorValidElement(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.lastNameError"
                          unicodeCharacter
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="donorName"
                    *ngIf="selectedModuleId === modules.Donations"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      Donor Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddDonor()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #donorName
                        [disableOptionCentering]="true"
                        (focus)="donorName.open()"
                        (valueChange)="donorName.close()"
                        [(ngModel)]="element.donorId"
                        panelClass="mat-select-position"
                        [disabled]="!element.donorError"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of donorList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}">{{ option.name }}</div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="depositAccount">
                    <th mat-header-cell *matHeaderCellDef>Deposit Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.depositAccountObj.value?.name
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="donationDate">
                    <th mat-header-cell *matHeaderCellDef>Donation Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.donationDateError">
                        {{ element.donationDate | date: "dd-LLL-yyyy" }}
                      </span>

                      <mat-form-field
                        *ngIf="element.donationDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.donationDate"
                          [matDatepicker]="donationDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.donationDate,
                              importDateName.donationDate
                            )
                          "
                          [max]="donationMaxDate"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="donationDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #donationDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="donationAmount">
                    <th mat-header-cell *matHeaderCellDef>Donation Amount</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.donationAmount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="incomeAccount">
                    <th mat-header-cell *matHeaderCellDef>Income Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.incomeAccountObj.value?.name
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="sponsorEvent">
                    <th mat-header-cell *matHeaderCellDef>Sponsor Event</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #sponsorEvent
                        (focus)="sponsorEvent.open()"
                        (valueChange)="sponsorEvent.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.sponsorEventId"
                        panelClass="mat-select-position"
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let fn of sponsorEventList"
                          [value]="fn.id"
                        >
                          <div class="clamp" title="{{ fn.name }}">
                            {{ fn.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="activity">
                    <th mat-header-cell *matHeaderCellDef>Activity</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #activity
                        (focus)="activity.open()"
                        (valueChange)="activity.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.activityId"
                        panelClass="mat-select-position"
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let fn of activityList"
                          [value]="fn.id"
                        >
                          <div class="clamp" title="{{ fn.name }}">
                            {{ fn.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="endowmentCredit">
                    <th mat-header-cell *matHeaderCellDef>Credit</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span>{{ element.endowmentCredit | numberPipe }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="endowmentDebit">
                    <th mat-header-cell *matHeaderCellDef>Debit</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span>{{ element.endowmentDebit | numberPipe }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="restrictedCredit">
                    <th mat-header-cell *matHeaderCellDef>Credit</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span>{{ element.restrictedCredit | numberPipe }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="restrictedDebit">
                    <th mat-header-cell *matHeaderCellDef>Debit</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span>{{ element.restrictedDebit | numberPipe }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="unrestrictedCredit">
                    <th mat-header-cell *matHeaderCellDef>Credit</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span>{{ element.unrestrictedCredit | numberPipe }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="unrestrictedDebit">
                    <th mat-header-cell *matHeaderCellDef>Debit</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span>{{ element.unrestrictedDebit | numberPipe }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="account">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <div
                        class="d-flex align-items-center gap-20"
                        [ngClass]="element.accountError ? 'pt-10 pb-10' : ''"
                      >
                        <ng-select
                          required
                          #accountName
                          [items]="accountGroupList"
                          (focus)="accountName.open()"
                          (valueChange)="accountName.close()"
                          class="bank-account"
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.accountId"
                          bindLabel="name"
                          bindValue="id"
                          groupBy="groupName"
                          (ngModelChange)="checkValidElement(element, i)"
                          panelClass="mat-select-position"
                          appendTo="body"
                        >
                          <ng-template ng-option-tmp let-item="item">
                            <span
                              class="ng-option-label"
                              [attr.title]="item.name"
                              >{{ item.name }}</span
                            >
                          </ng-template>
                        </ng-select>
                        <span *ngIf="element.accountError">{{
                          element.accountErrorValue
                        }}</span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="header-row-first-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="1"
                    ></th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-second-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="2"
                      class="text-align-center"
                    >
                      Unrestricted
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-third-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="2"
                      class="text-align-center"
                    >
                      Restricted
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-four-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="2"
                      class="text-align-center"
                    >
                      Endowment
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-fifth-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="1"
                    ></th>
                  </ng-container>
                  <ng-container *ngIf="panelHeaderTitle === 'Trial Balance'">
                    <tr
                      mat-header-row
                      *matHeaderRowDef="[
                        'header-row-first-group',
                        'header-row-second-group',
                        'header-row-third-group',
                        'header-row-four-group'
                      ]"
                    ></tr>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="importDuplicateList.data.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon fontSet="material-icons-outlined" class="text-primary"
                  >content_copy</mat-icon
                >
                <div class="d-flex flex-column">
                  <span
                    >Duplicate List ({{
                      importDuplicateList.data.length
                    }})</span
                  >
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content-body">
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  aria-describedby="Duplicate List"
                  [dataSource]="importDuplicateList"
                >
                  <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Customer Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddCustomer()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #customerName
                        [disableOptionCentering]="true"
                        (focus)="customerName.open()"
                        (valueChange)="customerName.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        [disabled]="!element.accountError"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of customerList"
                          [value]="option.id"
                        >
                          <div class="clamp" title="{{ option.name }}">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accountName">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      title="{{
                        this.importForm.controls.accountObj.value?.name
                      }}"
                    >
                      <div
                        class="clamp"
                        title="{{
                          this.importForm.controls.accountObj.value?.name
                        }}"
                      >
                        {{ this.importForm.controls.accountObj.value?.name }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fundName">
                    <th mat-header-cell *matHeaderCellDef>Fund Name</th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      title="{{ this.importForm.controls.fundObj.value?.name }}"
                    >
                      <div
                        class="clamp"
                        title="{{
                          this.importForm.controls.fundObj.value?.name
                        }}"
                      >
                        {{ this.importForm.controls.fundObj.value?.name }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceNo">
                    <th mat-header-cell *matHeaderCellDef>Invoice No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceDate">
                    <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="invoiceDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.invoiceDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef>Due Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.dueDateError">
                        {{ element.dueDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.dueDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.dueDate"
                          [matDatepicker]="dueDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.dueDate,
                              importDateName.dueDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="dueDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #dueDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="productName">
                    <th mat-header-cell *matHeaderCellDef>Product Name</th>
                    <td
                      mat-cell
                      *matCellDef="let element; let i = index"
                      title="{{ element.productName }}"
                    >
                      <div
                        *ngIf="!element.productNameError"
                        class="clamp"
                        title="{{ element.productName }}"
                      >
                        {{ element.productName }}
                      </div>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.productNameError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.productName"
                          (focusout)="checkProductName(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef class="w-80">Qty</th>
                    <td mat-cell *matCellDef="let element">
                      <ng-container *ngIf="+element.quantity !== 0">{{
                        element.quantity
                      }}</ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Price
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.price }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatRate">
                    <th mat-header-cell *matHeaderCellDef>Vat Rate</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field class="w-150">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.vatRateId"
                          dropdownPosition="top"
                          [disabled]="!element.vatRateError"
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of vatRateList"
                            [value]="option.id"
                            title="{{ option.name }}"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Vat Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatAmount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Total Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.totalAmount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="supplierName">
                    <th mat-header-cell *matHeaderCellDef>
                      Supplier Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddSupplier()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #suppliername
                        [disableOptionCentering]="true"
                        (focus)="suppliername.open()"
                        (valueChange)="suppliername.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        [disabled]="!element.accountError"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of supplierList"
                          [value]="option.id"
                        >
                          <div class="clamp" title="{{ option.name }}">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billNo">
                    <th mat-header-cell *matHeaderCellDef>Bill No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billDate">
                    <th mat-header-cell *matHeaderCellDef>Bill Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="billDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.billDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="billDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #billDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Credit Note No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Credit Note Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="creditNoteDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.creditNoteDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="creditNoteDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #creditNoteDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Debit Note No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Debit Note Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="debitNoteDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.debitNoteDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="debitNoteDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #debitNoteDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetNo">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetDate">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="debitNoteDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.fixedAssetDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="debitNoteDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #debitNoteDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accountIBANCode">
                    <th mat-header-cell *matHeaderCellDef>AccountIBAN Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.accountIBANCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine1">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 1</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine1 }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine2">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 2</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine2 }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountName">
                    <th mat-header-cell *matHeaderCellDef>Bank Account Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Bank Account Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="branchCode">
                    <th mat-header-cell *matHeaderCellDef>Branch Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.branchCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.city }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Contact Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.contactName"
                          (focusout)="checkCustomerDuplicate(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.contactNameError"
                          unicodeCharacter
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Country
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #country
                        (focus)="country.open()"
                        (valueChange)="country.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.countryId"
                        [disabled]="!element.countryError"
                        panelClass="mat-select-position"
                        disabled
                        required
                      >
                        <ng-option
                          *ngFor="let cl of countryList"
                          [value]="cl.id"
                        >
                          <div title="{{ cl.description }}">
                            {{ cl.description }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Email
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          emailValidator
                          [(ngModel)]="element.email"
                          (focusout)="
                            selectedModuleId === modules.Donors
                              ? checkDonorDuplicate(element, i)
                              : checkCustomerDuplicate(element, i)
                          "
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="email"
                          [disabled]="
                            selectedModuleId === modules.Donors
                              ? !element.duplicateDonorError
                              : !element.emailError
                          "
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.phone }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="postCode">
                    <th mat-header-cell *matHeaderCellDef>Post Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.postCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="tradingName">
                    <th mat-header-cell *matHeaderCellDef>Trading Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.tradingName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatRegistrationNo">
                    <th mat-header-cell *matHeaderCellDef>
                      Vat RegistrationNo
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatRegistrationNo }}
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="donorName"
                    *ngIf="selectedModuleId === modules.Donors"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Donor Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.donorName"
                          (focusout)="checkDonorDuplicate(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.donorNameError"
                          unicodeCharacter
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="firstName"
                    *ngIf="selectedModuleId === modules.Donors"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      First Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.firstName"
                          (focusout)="checkDonorDuplicate(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.duplicateDonorError"
                          unicodeCharacter
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="lastName"
                    *ngIf="selectedModuleId === modules.Donors"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Last Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.lastName"
                          (focusout)="checkDonorDuplicate(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.duplicateDonorError"
                          unicodeCharacter
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="donorName"
                    *ngIf="selectedModuleId === modules.Donations"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      Donor Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddDonor()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #donorName
                        [disableOptionCentering]="true"
                        (focus)="donorName.open()"
                        (valueChange)="donorName.close()"
                        [(ngModel)]="element.donorId"
                        panelClass="mat-select-position"
                        [disabled]="!element.donorError"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of donorList"
                          [value]="option.id"
                        >
                          <div class="clamp" title="{{ option.name }}">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="depositAccount">
                    <th mat-header-cell *matHeaderCellDef>Deposit Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.depositAccountObj.value?.name
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="donationDate">
                    <th mat-header-cell *matHeaderCellDef>Donation Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.donationDateError">
                        {{ element.donationDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.donationDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.donationDate"
                          [matDatepicker]="donationDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.donationDate,
                              importDateName.donationDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="donationDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #donationDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="donationAmount">
                    <th mat-header-cell *matHeaderCellDef>Donation Amount</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.donationAmount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="incomeAccount">
                    <th mat-header-cell *matHeaderCellDef>Income Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.incomeAccountObj.value?.name
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="sponsorEvent">
                    <th mat-header-cell *matHeaderCellDef>Sponsor Event</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #sponsorEvent
                        (focus)="sponsorEvent.open()"
                        (valueChange)="sponsorEvent.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.sponsorEventId"
                        panelClass="mat-select-position"
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let fn of sponsorEventList"
                          [value]="fn.id"
                        >
                          <div class="clamp" title="{{ fn.name }}">
                            {{ fn.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="activity">
                    <th mat-header-cell *matHeaderCellDef>Activity</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #activity
                        (focus)="activity.open()"
                        (valueChange)="activity.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.activityId"
                        panelClass="mat-select-position"
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let fn of activityList"
                          [value]="fn.id"
                        >
                          <div class="clamp" title="{{ fn.name }}">
                            {{ fn.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="endowmentCredit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Credit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.endowmentCredit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="endowmentDebit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Debit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.endowmentDebit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="restrictedCredit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Credit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.restrictedCredit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="restrictedDebit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Debit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.restrictedDebit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="unrestrictedCredit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Credit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.unrestrictedCredit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="unrestrictedDebit">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="text-align-center"
                    >
                      Debit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="text-align-center"
                    >
                      {{ element.unrestrictedDebit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="account">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #accountName
                        [items]="accountGroupList"
                        (focus)="accountName.open()"
                        (valueChange)="accountName.close()"
                        class="bank-account"
                        required
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.accountId"
                        bindLabel="name"
                        bindValue="id"
                        groupBy="groupName"
                        panelClass="mat-select-position"
                        appendTo="body"
                      >
                        <ng-template ng-option-tmp let-item="item">
                          <span
                            class="ng-option-label"
                            [attr.title]="item.name"
                            >{{ item.name }}</span
                          >
                        </ng-template>
                      </ng-select>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="header-row-first-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="1"
                    ></th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-second-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="2"
                      class="text-align-center"
                    >
                      Unrestricted
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-third-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="2"
                      class="text-align-center"
                    >
                      Restricted
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-four-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="2"
                      class="text-align-center"
                    >
                      Endowment
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="header-row-fifth-group">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      [attr.colspan]="1"
                    ></th>
                  </ng-container>
                  <ng-container *ngIf="panelHeaderTitle === 'Trial Balance'">
                    <tr
                      mat-header-row
                      *matHeaderRowDef="[
                        'header-row-first-group',
                        'header-row-second-group',
                        'header-row-third-group',
                        'header-row-four-group'
                      ]"
                    ></tr>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-step>
    <mat-step disableRipple>
      <ng-template matStepLabel>Details</ng-template>
      <div class="end-details">
        <div class="list-detail">
          <p>
            {{ panelHeaderTitle }} Imported ({{ finalResponse?.imported ?? 0 }})
          </p>
          <p>No. of records duplicate ({{ finalResponse?.skipped ?? 0 }})</p>
          <p>No. of error records ({{ finalResponse?.error ?? 0 }})</p>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<app-custom-notifications></app-custom-notifications>
