<div class="content-body">
  <form [formGroup]="capacityPermissionForm">
    <div class="hr-top pt-1">
      <div class="form-field">
        <p>Assign to Charities</p>
        <mat-checkbox
          class="example-margin"
          formControlName="addToAllFutureClients"
          >Add to all future Charities
        </mat-checkbox>
        <br />
        <mat-checkbox
          class="example-margin"
          formControlName="addToAllExistingClients"
          >Add to all existing Charities
        </mat-checkbox>
      </div>
    </div>
  </form>
</div>
