<div class="d-flex justify-content-center gap-40">
  <div class="import-leads">
    <div class="file-box">
      <div class="upload-detail">
        <div class="d-flex align-items-center gap-10">
          <p>Step 1. Download our template file.</p>
        </div>
        <div class="drop-detail text-align-left">
          <p>
            You can download our Excel template file.<br />
            The file has got accurate prescribed headings which are to be
            imported.
          </p>
        </div>
        <div
          class="d-flex align-items-center justify-content-center gap-5 cursor-pointer"
          (click)="downloadTemplateFile()"
        >
          <mat-icon fontSet="material-icons-outlined">file_download</mat-icon>
          <p class="m-0 link">Download sample file</p>
        </div>
      </div>
    </div>
  </div>
  <div class="import-leads">
    <div class="file-box">
      <div class="upload-detail">
        <div class="d-flex align-items-center gap-10">
          <p>Step 2. Copy Data into template file.</p>
        </div>
        <div class="drop-detail text-align-left">
          <p class="mb-10">
            You can copy your data into template file using excel or any other
            spreadsheet editor.
          </p>
          <p class="mb-10">
            Make sure the data you copied, matches the column headings provided
            in the template.
          </p>
          <p class="m-0">
            Do not change the column headings in the template file.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="import-leads justify-content-center">
    <div class="file-box">
      <form id="FrmImport_Sales" class="p-0">
        <div
          class="upload-detail"
          id="drop-area"
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event)"
        >
          <div class="d-flex align-items-center gap-10">
            <p>Step 3. Import the filled template file.</p>
          </div>
          <div class="drop-detail">
            <p>Drag and drop files to upload</p>
            <h4><span>or</span></h4>
            <button class="file-upload-button primary-button">
              <input
                type="file"
                #fileInput
                class="cursor-pointer"
                accept=".xls, .xlsx"
                (change)="onFileSelected(fileInput)"
                [multiple]="false"
              />Browse
            </button>
            <p class="file-info">{{ fileInfo }}</p>
          </div>
          <div
            class="d-flex gap-10 justify-content-center button-container mt-1"
          >
            <button
              class="action-button primary-button"
              type="button"
              mat-button
              (click)="onImport()"
              [disabled]="!isFileSelected"
            >
              Import
            </button>
            <button
              class="action-button secondary-button"
              type="button"
              mat-button
              disableRipple
              (click)="onCancel()"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-vat-return *ngIf="isImportSuccess" [submitVatId]="moduleId">
</app-vat-return>
