import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { AccountDetailViewModel } from '@app/core/Models';
import { MenuState, ReportDetailView } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-report-header-details',
  templateUrl: './report-header-details.component.html',
  styleUrls: ['./report-header-details.component.scss'],
})
export class ReportHeaderDetailsComponent implements OnInit {
  triggerBillOutstanding: EventEmitter<any> = new EventEmitter<any>();

  detailViewData: AccountDetailViewModel;

  @Input()
  triggerCustomerId: Observable<any>;

  @Output()
  readonly triggerOnCancel = new EventEmitter<any>();

  customerId: Guid;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  moduleEnum = Modules;
  moduleId = 0;
  constructor(private store: Store, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.triggerCustomerId.subscribe((data) => {
      this.customerId = data.id;
      this.moduleId = data.moduleId;
      this.getDetailView(data.id, data.moduleId);
    });
  }

  getDetailView(id: any, moduleId: number): void {
    this.spinner.show();
    setTimeout(() => {
      this.store
        .dispatch(new ReportDetailView(id, moduleId))
        .subscribe((res) => {
          this.spinner.hide();
          this.detailViewData = res.common.detailView;
          this.triggerBillOutstanding.emit(
            this.detailViewData.accountFlowDetailModel
          );
        });
    }, 1000);
  }

  cancelClick(): void {
    this.triggerOnCancel.emit(true);
  }
}
