<div class="bank-details">
  <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
    <mat-icon class="material-icons-outlined">error_outline</mat-icon>
    {{ commonNotificationText.ViewOnlyAccessMessage }}
  </div>

  <mat-accordion>
    <mat-expansion-panel [expanded]="isExpanded">
      <mat-expansion-panel-header (click)="expandPanel()">
        <mat-panel-title>
          <div class="d-flex justify-space-between wp-100">
            <div>
              <span>Bank Details</span>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="content-body">
        <ngx-slick-carousel
          class="carousel"
          #slickModal="slick-carousel"
          [config]="slideConfig"
        >
          <div
            ngxSlickItem
            class="slide"
            *ngFor="let account of bankAccountData"
          >
            <mat-card [ngClass]="account.isCardSelected ? 'active' : ''">
              <div class="card-header">
                <div
                  class="d-flex justify-space-between align-items-center gap-10"
                >
                  <div class="clamp">
                    <p
                      class="text-uppercase clamp m-0"
                      title="{{ account.name }}"
                    >
                      {{ account.name }}
                    </p>
                  </div>
                  <div
                    class="d-flex align-items-center gap-10 mb-5 bank-action-buttons"
                  >
                    <div>
                      <button
                        mat-button
                        disableRipple
                        [disabled]="isViewPermission$ | async"
                      >
                        <mat-icon
                          (click)="onAccountDelete(account)"
                          title="delete"
                          >delete_outline</mat-icon
                        >
                      </button>
                    </div>
                    <div class="card-status text-align-right">
                      <mat-radio-button
                        [checked]="account.isDefault"
                        (change)="onAccountSelected(account, true)"
                      ></mat-radio-button>
                    </div>
                  </div>
                </div>
                <mat-card-title-group>
                  <mat-card-title
                    class="clamp font-size-14 text-gray-200"
                    title="{{ account.bankAccountType }}"
                  >
                    {{ account.bankAccountType }}</mat-card-title
                  >
                  <mat-card-subtitle title="{{ account.bankAccountNumber }}">
                    {{ account.bankAccountNumber }}
                  </mat-card-subtitle>
                </mat-card-title-group>
              </div>
              <mat-card-content>
                <div>
                  <div
                    class="d-flex justify-space-between align-items-center mb-10"
                  >
                    <div>
                      <div class="d-flex gap-10 align-items-center">
                        <mat-icon
                          fontSet="material-icons-outlined"
                          class="text-primary"
                          >bar_chart</mat-icon
                        >
                        <p title="{{ account.explained | numberPipe }}">
                          Explained Balance
                        </p>
                      </div>
                    </div>
                    <div>
                      <p class="ml-1 text-align-right text-primary">
                        £ {{ account.explained | numberPipe }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-space-between align-items-center mb-10"
                  >
                    <div class="d-flex gap-10 align-items-center">
                      <mat-icon
                        fontSet="material-icons-outlined"
                        class="text-primary"
                        >bar_chart</mat-icon
                      >
                      <p title="{{ account.balance | numberPipe }}">
                        Net Balance
                      </p>
                    </div>
                    <div>
                      <p class="ml-1 text-align-right text-primary">
                        £ {{ account.balance | numberPipe }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between align-items-center">
                    <div>
                      <div class="d-flex gap-10 align-items-center">
                        <mat-icon
                          fontSet="material-icons-outlined"
                          class="text-primary"
                          >bar_chart</mat-icon
                        >
                        <p title="{{ account.unExplained | numberPipe }}">
                          Unexplained Balance
                        </p>
                      </div>
                    </div>
                    <div>
                      <p class="ml-1 text-align-right text-danger-300">
                        £ {{ account.unExplained | numberPipe }}
                      </p>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ngx-slick-carousel>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
