import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  ChartOfAccountListParam,
  Currency,
  GlobalComponent,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  CreateAccount,
  CreateContact,
  CreateFund,
  GetCurrencyList,
  GetDataByAccountId,
  GetDataByFundId,
  GetFundNameList,
  GetGroupAccountsBasedOnGroupId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetInvoiceNoList,
  GetNonStandardAccountList,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-credit-note',
  templateUrl: './add-credit-note.component.html',
  styleUrls: ['./add-credit-note.component.scss'],
})
export class AddCreditNoteComponent implements OnInit {
  creditNoteForm: FormGroup;

  currencyList: Currency[];
  referenceIdList: SideListModel[];
  customerList: SideListModel[];
  creditNoteTypeList: GroupListModel[];
  fundNameList: SideListModel[];
  tempCreditNoteGroupList: GroupListModel[];

  @Input() isFromBankImport: boolean;
  @Input() triggerEditData: Observable<any>;
  @Output() maxDate = new EventEmitter<any>();

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  defaultCurrency: Guid;
  getListId: Guid;

  periodicDate: any;

  disabledEditButton = true;
  disabledFundEditButton = true;
  isAddMode = true;
  isVatInclude = false;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  isVatRegistered: any;

  @Output()
  readonly triggerCustomerChange = new EventEmitter<any>();

  @Output()
  readonly isVatIncludedClick = new EventEmitter<any>();
  private destroy$ = new Subject<void>();
  isCustomerChangePermission: boolean = true;
  isFundChangePermission: boolean = true;

  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public dialog: MatDialog,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.getCurrency();
    this.getCustomer();
    this.getCreditNoteType();
    this.getFundNameList();
    this.setForm();

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.editCreditNote(data);
        this.isAddMode = false;
      });

    this.creditNoteForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.creditNoteForm.touched;
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCustomerChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Customers
      );
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSelectedData(item: any[]): void {
    this.creditNoteForm.controls.customerName.setValue(item);
  }

  getCreditNoteType(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Income);

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: this.commonService.invoiceTypeList,
    };

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupIdAndTypeId(param))
      .pipe(
        tap((res) => {
          this.creditNoteTypeList = res.common.accountGroupList;
          this.tempCreditNoteGroupList = this.creditNoteTypeList;
        })
      )
      .subscribe();
  }

  getCustomer(id?: Guid): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))

      .pipe(
        tap((res) => {
          this.customerList = res.common.accountList;

          if (this.customerList.length > 0 && this.isAddMode) {
            this.onCustomerChange();
            if (id !== null && id !== undefined) {
              this.creditNoteForm.controls.customerName.setValue(id);
            }
          }
        })
      )
      .subscribe();
    if (id !== null && id !== undefined) {
      this.creditNoteForm.controls.customerName.setValue(id);
    }
  }

  getInvoiceNoList(): void {
    this.store
      .dispatch(new GetInvoiceNoList())
      .pipe(
        tap((res) => {
          this.referenceIdList = res.invoice.invoiceNoList;
          this.creditNoteForm.controls.customerName.setValue(
            this.referenceIdList[0].id
          );
        })
      )
      .subscribe();
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Customers,
          headerText: `Add ${ModuleName.Customers}`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getCustomer(id);
        }
      });
  }

  gotoEditCustomer(): void {
    if (this.customerList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Customers,
            headerText: `Edit ${ModuleName.Customers}`,
            id: this.creditNoteForm.controls.customerName.value,
            saveActionName: CreateContact,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getCustomer(id);
          }
        });
    }
  }

  editCreditNote(data): void {
    let account;
    this.creditNoteTypeList.forEach((items) => {
      const accountId = items.listModels.find(
        (x: any) => x.id === data.bookAccountId
      );
      if (accountId !== null && accountId !== undefined) {
        account = accountId;
      }
    });
    this.creditNoteForm.controls.creditNoteNo.disable();
    this.disabledEditButton = false;
    this.disabledFundEditButton = false;
    this.creditNoteForm.patchValue({
      customerName: data.accountId,
      creditNoteNo: data.entryNumber,
      postingAccountId: data.postingAccountId,
      fundName: data.fundId,
      invoiceRefNo: data.voucherNumber,
      entryDate: data.entryDate,
      currency: data.currencyId,
      note: data.note,
      creditNoteType: account,
      isVatInclude: data.isVatIncluded,
    });
    this.onCustomerChange();
  }

  dateChange(): void {
    let startDate: any = new Date(
      this.creditNoteForm.controls['entryDate'].value
    );

    startDate = this.datepipe.transform(startDate, 'yyyy-MM-dd')?.toString();
    this.maxDate.emit(startDate);
  }

  getOptionText(option) {
    return option.name;
  }

  resetAccountList(): void {
    this.creditNoteTypeList = this.tempCreditNoteGroupList;
    this.scrollIntoView(this.creditNoteForm.controls.creditNoteType.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempCreditNoteGroupList
    );
    this.creditNoteTypeList = list;
  }

  setForm(): void {
    this.creditNoteForm = new FormGroup({
      customerName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      creditNoteNo: new FormControl('', [Validators.required]),
      postingAccountId: new FormControl(''),
      fundName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      entryDate: new FormControl(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      currency: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      note: new FormControl(''),
      invoiceRefNo: new FormControl(''),
      creditNoteType: new FormControl(''),
      amountReceived: new FormControl(),
      isVatInclude: new FormControl(false),
    });
  }

  getCurrency(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          this.creditNoteForm.controls.currency.setValue(this.defaultCurrency);
        })
      )
      .subscribe();
  }

  getFundNameList(): void {
    this.store
      .dispatch(new GetFundNameList())
      .pipe(
        tap((res) => {
          this.fundNameList = res.account.fundNameList;
          if (this.fundNameList.length > 0) {
            this.disabledFundEditButton = false;
            this.creditNoteForm.controls.fundName.setValue(
              this.fundNameList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledFundEditButton = false;
    } else {
      this.disabledFundEditButton = true;
    }
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledFundEditButton = false;
          this.getFund(id);
        }
      });
  }

  getFund(id): void {
    this.store.dispatch(new GetFundNameList()).subscribe((res) => {
      this.fundNameList = res.account.fundNameList;
      if (id !== null && id !== undefined) {
        this.creditNoteForm.controls.fundName.setValue(id);
      }
    });
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.creditNoteForm.controls.fundName.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }

  customerChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  onCustomerChange(): void {
    this.triggerCustomerChange.emit();
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onCheckBoxSelected(event: any): void {
    this.isVatInclude = event.checked;
    this.isVatIncludedClick.emit(this.isVatInclude);
  }
}
