import { Injectable } from '@angular/core';
import { InvoiceModel, SideListModel } from '@app/core/Models';
import { InvoiceService } from '@app/core/Services/income/invoice/invoice.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreInvoice,
  CreateInvoice,
  DeleteInvoice,
  GetDataByInvoiceId,
  GetDueAmountByInvoiceId,
  GetIncomeBreakdownOverview,
  GetIncomeFlowOverview,
  GetIncomeReceivableAgeingSummaryOverview,
  GetInvoiceNoList,
  GetInvoiceNumber,
} from './invoice.action';

export class InvoiceStateInfo {
  invoiceData?: InvoiceModel;
  invoice: Array<InvoiceModel>;
  invoiceId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  invoiceNoList?: Array<SideListModel>;
  isInvoiceAdded?: boolean;
  totalRecord?: number;
  invoiceNo: string;
  isLastPage?: boolean;
  incomeReceivableAgeingSummaryOverview?: any;
  incomeBreakdownOverview?: any;
  incomeFlowOverview?: any;
  dueAmount: any;
}

@State<InvoiceStateInfo>({
  name: 'invoice',
  defaults: {
    invoice: [],
    invoiceId: Guid.EMPTY as unknown as Guid,
    invoiceNo: '',
    exported: false,
    sideListModel: [],
    isInvoiceAdded: false,
    dueAmount: 0,
  },
})
@Injectable()
export class InvoiceState {
  constructor(private invoiceService: InvoiceService) {}

  @Selector()
  static isLastPage(state: InvoiceStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getInvoiceId(state: InvoiceStateInfo) {
    return state.invoiceId;
  }

  @Selector()
  static getInvoiceData(state: InvoiceStateInfo) {
    return state.invoiceData;
  }

  @Action(CreateInvoice)
  createInvoice(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: CreateInvoice
  ) {
    return this.invoiceService.createInvoice(action.invoice).pipe(
      tap((res) => {
        patchState({
          invoiceId: res,
          isInvoiceAdded: true,
        });
      })
    );
  }

  @Action(GetDataByInvoiceId)
  getDataByInvoiceId(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetDataByInvoiceId
  ) {
    return this.invoiceService.getDataByInvoiceId(action.invoiceId).pipe(
      tap((res) => {
        patchState({
          invoiceData: res,
        });
      })
    );
  }

  @Action(GetDueAmountByInvoiceId)
  getDueAmountByInvoiceId(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetDueAmountByInvoiceId
  ) {
    return this.invoiceService.getDueAmountByInvoiceId(action.invoiceId).pipe(
      tap((res) => {
        patchState({
          dueAmount: res,
        });
      })
    );
  }

  @Action(DeleteInvoice)
  deleteInvoice(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: DeleteInvoice
  ) {
    return this.invoiceService.deleteInvoice(action.invoiceIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredInvoice = state.invoice.filter(
          (item) =>
            !action.invoiceIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.invoiceIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          invoice: filteredInvoice,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreInvoice)
  archiveAndRestoreAccount(
    { getState }: StateContext<InvoiceStateInfo>,
    action: ArchiveAndRestoreInvoice
  ) {
    return this.invoiceService.archiveAndRestoreInvoice(
      action.invoiceIds,
      action.isArchive
    );
  }

  @Action(GetInvoiceNoList)
  getInvoiceNoList(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoiceNoList
  ) {
    return this.invoiceService.getInvoiceNoList().pipe(
      tap((res) => {
        patchState({
          invoiceNoList: res,
        });
      })
    );
  }

  @Action(GetInvoiceNumber)
  getInvoiceNumber({ patchState }: StateContext<InvoiceStateInfo>) {
    return this.invoiceService.getInvoiceNumber().pipe(
      tap((res) => {
        patchState({
          invoiceNo: res,
        });
      })
    );
  }

  @Action(GetIncomeReceivableAgeingSummaryOverview)
  getIncomeReceivableAgeingSummaryOverview(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetIncomeReceivableAgeingSummaryOverview
  ) {
    return this.invoiceService
      .getIncomeReceivableAgeingSummaryOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            incomeReceivableAgeingSummaryOverview: res,
          });
        })
      );
  }

  @Action(GetIncomeBreakdownOverview)
  getIncomeBreakdownOverview(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetIncomeBreakdownOverview
  ) {
    return this.invoiceService
      .getIncomeBreakdownOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            incomeBreakdownOverview: res,
          });
        })
      );
  }

  @Action(GetIncomeFlowOverview)
  getIncomeFlowOverview(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetIncomeFlowOverview
  ) {
    return this.invoiceService.getIncomeFlowOverview(action.filterData).pipe(
      tap((res) => {
        patchState({
          incomeFlowOverview: res,
        });
      })
    );
  }
}
