import { Guid } from 'guid-typescript';

export class PaymentModel {
  id: Guid;
  account: string;
  paymentNo: string;
  supplierName: string;
  paymentDate: string;
  invoiceCurrency: string;
  amountPaid: string;
  notes: string;
}

export class PaymentItemModel {
  paymentId?: Guid;
  invoiceNo: string;
  date: string;
  note: string;
  amount: number;
  dueAmount: number;
  amounts: number;
}
