<form
  [formGroup]="companyPreferenceForm"
  *ngIf="moduleId !== modules.AccountProduction"
>
  <div>
    <div class="form-field">
      <mat-radio-group
        aria-label="Select an option"
        formControlName="autoGenerate"
        (change)="autoGenerateChange($event)"
      >
        <mat-radio-button [value]="true" class="mr-3"
          >Auto Generate</mat-radio-button
        >
        <mat-radio-button [value]="false">Add Manually</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="form-wrapper d-flex gap-20">
      <div class="form-field">
        <p>Prefix</p>
        <mat-form-field class="w-150">
          <input
            matInput
            formControlName="prefix"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength5 }}"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Suffix</p>
        <mat-form-field class="w-150">
          <input
            matInput
            formControlName="suffix"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength5 }}"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="form-field wp-100">
      <mat-radio-group
        aria-label="Select an option"
        formControlName="repeatType"
      >
        <mat-radio-button [value]="repeteType.Daily" class="mr-1"
          >Daily</mat-radio-button
        >
        <mat-radio-button [value]="repeteType.Weekly" class="mr-1"
          >Weekly</mat-radio-button
        >
        <mat-radio-button [value]="repeteType.Monthly" class="mr-1"
          >Monthly</mat-radio-button
        >
        <mat-radio-button [value]="repeteType.Yearly">Yearly</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
<form
  [formGroup]="accountProductionPreferenceForm"
  *ngIf="moduleId === modules.AccountProduction"
>
  <div>
    <p class="form-field-title">Rounding Preference</p>
    <div class="d-flex gap-40">
      <mat-radio-group
        aria-label="Select an option"
        formControlName="roundUpTo"
      >
        <mat-radio-button [value]="true" class="mr-3"
          >Round Up to Nearest Amount</mat-radio-button
        >
        <mat-radio-button [value]="false"> Exact Amount</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
