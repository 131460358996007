import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { GlobalComponent, MenuModel } from '@app/core/Models';
import {
  BankDasboardService,
  CommonService,
  ModulePermission,
} from '@app/core/Services';
import {
  CheckVatNumber,
  CommonState,
  CreateCustomAccount,
  GetUserBusinessPermission,
  MenuState,
  SetDefaultVatId,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  BankLinkComponent,
  QuickAddComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
})
export class MenuItemComponent implements OnInit {
  treeControl = new NestedTreeControl<MenuModel>((node) => node.subMenu);
  dataSource = new MatTreeNestedDataSource<MenuModel>();
  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @Select(MenuState.treeNode)
  treeNode$: Observable<any>;

  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;
  activeNode;

  @Input() activeMenuLink = '';

  parentName: string;
  subparentName: string;

  constructor(
    private router: Router,
    private modulePermission: ModulePermission,
    private store: Store,
    public cookieService: CookieService,
    public commonService: CommonService,
    public dialog: MatDialog,
    public bankDasboardService: BankDasboardService,
    private globalComponent: GlobalComponent
  ) {
    this.menuList$.subscribe((menuList) => {
      localStorage.setItem('userPermission', JSON.stringify(menuList));
      this.modulePermission.permissionData.subscribe((value) => {
        this.activeNode = value.data;
        this.activeMenuLink = value.data.url;
        this.toExpandMenu(menuList, value.data.name);
      });
    });
  }

  ngOnInit(): void {}

  getUserBusinessPermission(): void {
    this.store
      .dispatch(
        new GetUserBusinessPermission(this.globalComponent.getLoggedInUserId())
      )
      .subscribe((res) => {
        const permissions = this.store.selectSnapshot(
          CommonState.getUserBusinessPermission
        );
        localStorage.setItem('userPermission', JSON.stringify(permissions));
      });
  }

  toExpandMenu(data: MenuModel[], name: string): any {
    if (data !== undefined) {
      data.forEach((node) => {
        if (
          node.subMenu &&
          node.subMenu.find(
            (c) => c.name === name || c.subMenu.find((x) => x.name)
          )
        ) {
          this.onLeafNodeClick(name);
          this.toExpandMenu(this.treeControl.dataNodes, node.name);
        }
      });
    }
  }

  onAddClick(node: any): void {
    if (this.commonService.isInitialValueChange) {
      this.dialog
        .open(AddClosePopupComponent, {
          data: {
            isWarning: true,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.addRedirect(node);
          }
        });
    } else {
      this.addRedirect(node);
    }
  }

  addRedirect(node: any): void {
    let addURL;
    addURL = node.id === Modules.CharityProfile ? node.url : node.addUrl;
    if (node.id === Modules.SubmitVat) {
      this.checkVatNumber(addURL);
      this.store.dispatch(new SetDefaultVatId());
    } else if (node.id === Modules.BankDashboard) {
      this.gotoAddCustomAccount();
    } else if (node.id === Modules.BankFeed) {
      this.dialog
        .open(BankLinkComponent)
        .afterClosed()
        .subscribe(() => {});
    } else {
      addURL === this.router.url.slice(1)
        ? location.reload()
        : this.redirectTo(addURL);
    }
  }

  gotoAddCustomAccount(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.AddCustomAccounts,
          headerText: `Add ${ModuleName.AddCustomAccounts}`,
          saveActionName: CreateCustomAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
        }
      });
  }

  redirectTo(addURL: string) {
    this.router.navigate([addURL]);
  }

  checkVatNumber(addURL: any): void {
    this.store.dispatch(new CheckVatNumber()).subscribe((res) => {
      if (res.submitVat.checkVatNumber) {
        addURL === this.router.url.slice(1)
          ? location.reload()
          : this.router.navigate([addURL]);
      } else {
        this.commonService.onFailure(NotificationTextMessage.checkVatNumber);
      }
    });
  }

  hasChild = (_: number, node: MenuModel) =>
    !!node.subMenu && node.subMenu.length > 0;

  getAncestors(array, name): any {
    if (typeof array !== 'undefined') {
      for (const element of array) {
        if (element.name === name && element.url === this.activeMenuLink) {
          return [element];
        }
        const a = this.getAncestors(element.subMenu, name);
        if (a !== null) {
          a.unshift(element);
          return a;
        }
      }
    }
    return null;
  }

  childClick(node: any): void {
    this.commonService.isInitialValueChange = false;
    this.router.navigate([node.url]);

    if (node.id === Modules.VatSettings) {
      this.cookieService.delete('selectedTab', RoutingPath.VatSettings);
    } else if (node.id === Modules.SubmitVat) {
      this.store.dispatch(new SetDefaultVatId());
    }
  }

  onChildClick(node): void {
    if (this.commonService.isInitialValueChange) {
      this.dialog
        .open(AddClosePopupComponent, {
          data: {
            isWarning: true,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.childClick(node);
          }
        });
    } else {
      this.childClick(node);
    }
  }

  onLeafNodeClick(name): void {
    let array;
    this.menuList$.subscribe((x) => {
      array = x;
    });

    const ancestors = this.getAncestors(array, name);
    if (ancestors !== null && ancestors !== undefined) {
      ancestors.forEach((element) => {
        this.treeControl.expand(element);
      });
      this.parentName = ancestors[0].name;
      this.subparentName = ancestors[1] !== undefined ? ancestors[1].name : '';
    }
  }
}
