<div class="vat-Settings content-body-wrapper">
  <div class="content-body">
    <div class="vat-Settings-info">
      <mat-tab-group
        *ngIf="!isIncludeDetailList"
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
        [(selectedIndex)]="selectedTab"
        (selectedTabChange)="onTabClick($event.index)"
      >
        <mat-tab label="Sales VAT">
          <div class="mt-1">
            <button
              class="action-button primary-button mr-1"
              type="button"
              (click)="goToInclude()"
              mat-button
              disableRipple
            >
              Include
            </button>
            <button
              class="action-button secondary-button"
              type="button"
              (click)="excludeClick()"
              [disabled]="!isRecordSelected"
              mat-button
              disableRipple
            >
              Exclude
            </button>
          </div>
          <app-include-data
            *ngIf="selectedTab === 0"
            [isHeaderVisible]="false"
            [triggerSaveExclude]="triggerSaveExclude"
            [tabSalesPurchase]="selectedTab"
            (triggerIsRecordSelected)="triggerIsRecordSelected($event)"
          >
          </app-include-data>
        </mat-tab>
        <mat-tab label="Purchase VAT">
          <div class="mt-1">
            <button
              class="action-button primary-button mr-1"
              type="button"
              (click)="goToInclude()"
              mat-button
              disableRipple
            >
              Include
            </button>
            <button
              class="action-button secondary-button"
              type="button"
              (click)="excludeClick()"
              [disabled]="!isRecordSelected"
              mat-button
              disableRipple
            >
              Exclude
            </button>
          </div>
          <app-include-data
            *ngIf="selectedTab === 1"
            [isHeaderVisible]="false"
            [triggerSaveExclude]="triggerSaveExclude"
            [tabSalesPurchase]="selectedTab"
            (triggerIsRecordSelected)="triggerIsRecordSelected($event)"
          >
          </app-include-data>
        </mat-tab>
      </mat-tab-group>
      <div *ngIf="isIncludeDetailList">
        <app-include-data></app-include-data>
      </div>
    </div>
  </div>
</div>
