import { Component, Input, ViewChild } from '@angular/core';
import { IncomeOverview, Modules } from '@app/core/Enum';
import { GetIncomeBreakdownOverview } from '@app/core/Store';
import { Store } from '@ngxs/store';

import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexResponsive,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type ChartOptionvBreckdownv1 = {
  noData: ApexNoData;
  series: any;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  lines: ApexGrid;
};

@Component({
  selector: 'app-overview-incomes-breckdown-v1',
  templateUrl: './overview-incomes-breckdown-v1.component.html',
  styleUrls: ['./overview-incomes-breckdown-v1.component.scss'],
})
export class OverviewIncomesBreckdownv1Component {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptionsvBreckdownv1: Partial<ChartOptionvBreckdownv1>;

  //@Input() triggerHeaderFilter: Observable<any>;
  @Input() triggerHeaderFilterForIncomeBreakdown: Observable<any>;

  @Input() getModuleId: number;
  reportList: any;
  isNoRecord = true;
  isloadData = false;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerHeaderFilterForIncomeBreakdown?.subscribe((filterData) => {
      if (this.getModuleId === Modules.IncomeOverview) {
        this.getData(filterData);
      }
    });
  }

  getData(filterData): void {
    this.store
      .dispatch(new GetIncomeBreakdownOverview(filterData))
      .subscribe((x) => {
        this.isloadData = true;
        this.reportList = x.invoice.incomeBreakdownOverview;
        this.getIncomeBreakDownSummary();
      });
  }

  getIncomeBreakDownSummary(): void {
    const tempCategories: any = [];
    const tempSeries: any = [];
    let count = 0;

    for (const barchart of this.reportList[`categories`]) {
      tempCategories.push(barchart.name);
    }

    this.isNoRecord = this.reportList[`series`].length === 0;

    if (this.reportList[`series`].length > 0) {
      for (const barchart of this.reportList[`series`]) {
        tempSeries.push(barchart.data);
        if (barchart.data === 0) {
          count = count + 1;
        }
      }

      this.isNoRecord =
        count === this.reportList[`series`].length ? true : false;
    }

    this.chartOptionsvBreckdownv1 = {
      series: [
        {
          name: '',
          data: tempSeries,
        },
      ],
      chart: {
        height: 300,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        labels: {
          show: true,
          rotate: 0,
        },
        categories: tempCategories,
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return Number(val) / 1000 + 'K';
          },
        },
      },
      legend: {
        show: false,
        offsetX: 0,
        offsetY: 8,
      },
      fill: {
        colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
      },
      tooltip: {
        marker: {
          show: false,
        },
        enabled: true,
        fillSeriesColor: false,
        y: {
          formatter: (val) => {
            return '£ ' + Number(val) * 1 + '';
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
}
