import { Component, Input, ViewChild } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { GetDashboardFundByTypesOverview } from '@app/core/Store';
import { Store } from '@ngxs/store';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexFill,
  ApexDataLabels,
  ApexLegend,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type incomeClientChartOptionsv1 = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  tooltip: any;
};

@Component({
  selector: 'app-dashboard-income-client-v1',
  templateUrl: './dashboard-income-client-v1.component.html',
  styleUrls: ['./dashboard-income-client-v1.component.scss'],
})
export class DashboardIncomeClientComponentv1 {
  @ViewChild('chart') chart: ChartComponent;
  public incomeClientChartOptionsv1: Partial<incomeClientChartOptionsv1>;

  @Input()
  triggerHeaderFilter: Observable<any>;
  @Input() getModuleId: number;
  isloadData = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (this.getModuleId === Modules.Dashboard) {
        this.getFundByTypesData(filterData);
      }
    });
  }

  setlabel(data): any {
    let label: any[] = [];
    data.forEach((element) => {
      label.push(
        element.name + '- ' + element.percentage + '% ' + '£ ' + element.amount
      );
    });
    return label;
  }

  getFundByTypesData(filterData): void {
    this.store
      .dispatch(new GetDashboardFundByTypesOverview(filterData))
      .subscribe((res) => {
        this.isloadData = true;
        let overViewData = res.dashboard.dashboardFundByTypesOverview;
        const labels = this.setlabel(overViewData.data);
        this.incomeClientChartOptionsv1 = {
          series: overViewData.series,
          chart: {
            width: 560,
            height: 400,
            type: 'pie',
            offsetX: -50,
            offsetY: 0,
          },
          fill: {
            colors: ['#7B76D3', '#BA5397', '#F4C64D', '#00E396', '#FF0000'],
          },
          legend: {
            show: true,
            position: 'right',
            horizontalAlign: 'right',
            offsetX: -40,
            offsetY: 30,
            fontSize: '14px',
            fontFamily: 'Source Sans Pro, sans-serif',
            markers: {
              fillColors: [
                '#7B76D3',
                '#BA5397',
                '#F4C64D',
                '#00E396',
                '#FF0000',
              ],
              offsetY: 3,
              offsetX: -5,
            },
            itemMargin: {
              vertical: 2,
            },
          },
          tooltip: {
            theme: 'light',
            fillSeriesColor: false,
            style: {
              fontFamily: '"Source Sans Pro", sans-serif',
              fontSize: '14px',
            },
            marker: {
              show: false,
            },
            fixed: {
              position: 'topLeft',
            },
            y: {
              formatter: function (val) {
                return '';
              },
              title: {
                formatter: function (seriesName) {
                  return seriesName;
                },
              },
            },
          },

          dataLabels: {
            enabled: false,
          },
          labels: labels,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        };
      });
  }
}
