<div id="chart" *ngIf="chartOptionsvBreckdown">
  <apx-chart
    [series]="chartOptionsvBreckdown?.series!"
    [chart]="chartOptionsvBreckdown?.chart!"
    [labels]="chartOptionsvBreckdown?.labels!"
    [tooltip]="chartOptionsvBreckdown?.tooltip!"
    [responsive]="chartOptionsvBreckdown?.responsive!"
    [legend]="chartOptionsvBreckdown?.legend!"
    [plotOptions]="chartOptionsvBreckdown.plotOptions!"
    [fill]="chartOptionsvBreckdown?.fill!"
    [noData]="chartOptionsvBreckdown?.noData!"
    [dataLabels]="chartOptionsvBreckdown.dataLabels!"
    [xaxis]="chartOptionsvBreckdown.xaxis!"
    [yaxis]="chartOptionsvBreckdown.yaxis!"
    [grid]="chartOptionsvBreckdown.grid!"
    [lines]="chartOptionsvBreckdown.lines!"
  ></apx-chart>
</div>
