import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-activities',
  templateUrl: './add-activities.component.html',
  styleUrls: ['./add-activities.component.scss'],
})
export class AddActivitiesComponent implements OnInit {
  activitiesForm: FormGroup;
  maxLength = MaxLength;

  @Input() triggerEditData: Observable<any>;

  constructor(private commonService: CommonService) {}
  ngOnInit(): void {
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      this.editActivities(data);
    });

    this.activitiesForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.activitiesForm.touched;
    });
  }

  editActivities(data): void {
    this.activitiesForm.controls.code.disable();
    this.activitiesForm.patchValue({
      name: data.name,
      description: data.description,
      code: data.code,
      attachment: data.attachment,
      isDefault: data.isDefault,
    });
  }

  setForm(): void {
    this.activitiesForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      description: new FormControl(''),
      code: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      attachment: new FormControl(''),
      isDefault: new FormControl(false),
    });
  }
}
