<div class="company-preference content-body-wrapper">
  <div class="content-body">
    <div class="fixed-assets-dispose">
      <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
        [(selectedIndex)]="selectedTab"
        (selectedTabChange)="onTabClick($event.index)"
      >
        <mat-tab label="Active Assets">
          <div [ngClass]="selectedTab === 0 ? '' : 'hidden'">
            <app-report-list-with-actions></app-report-list-with-actions>
          </div>
        </mat-tab>
        <mat-tab label="Disposed">
          <app-detail-list
            [moduleId]="modulesEnum.FixedAssetsDispose"
            *ngIf="selectedTab === 1"
          >
          </app-detail-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
