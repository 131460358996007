import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GiftAIDsettings, GiftAid } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { CommonService } from '../../common/common.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class DonationGiftAidService {
  constructor(private http: HttpClient, private commonService: CommonService) {}
  saveGiftAid(giftAidData: GiftAid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}GiftAid/save`,
      this.commonService.trimObjectSpace(JSON.stringify(giftAidData)),
      headers
    );
  }

  submitToHMRC(giftAddId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}GiftAid/submit`,
      JSON.stringify(giftAddId),
      headers
    );
  }

  deleteGIF(giftAddId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(giftAddId),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}GiftAid/delete`,
      options
    );
  }

  saveGiftAIDsettings(giftAIDSettingsData: GiftAIDsettings): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Company/updateGiftAidDetail`,
      this.commonService.trimObjectSpace(JSON.stringify(giftAIDSettingsData)),
      headers
    );
  }

  getGiftAIDsettings(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Company/giftAidDetail`
    );
  }
}
