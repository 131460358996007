import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  AccountingMethod,
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
  TransactionStatus,
} from '@app/core/Enum';
import { GlobalComponent, ReceiptModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateReceipt,
  GetBankAccountTransactionData,
  GetDataByReceiptId,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import {
  AddReceiptComponent,
  TransactionsAttachmentComponent,
} from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {
  receiptData: ReceiptModel;

  moduleId = 0;
  receiptId = Guid.EMPTY as unknown as Guid;
  amount = 0;
  balanceAmount = 0;
  moduleName = '';

  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isFromBankImport = false;
  isManualBank = false;
  isCredit: boolean;

  receiptItems = new Array<any>();

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddReceiptComponent, { static: true })
  receiptDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  triggerEditData: Subject<any> = new Subject<any>();
  triggerTransactionLogData: Subject<any> = new Subject<any>();

  subscriptionRouting: Subscription;
  accountingMehtodId: any;

  bankImportTransactionId = Guid.EMPTY as unknown as Guid;
  bankId = Guid.EMPTY as unknown as Guid;
  account;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;
  moduleEnum = Modules;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private injector: Injector,
    private globalComponent: GlobalComponent
  ) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.accountingMehtodId = this.globalComponent.getAccountingMethod();
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        const currentUrl = this._Activatedroute.snapshot['_routerState'].url;

        if (currentUrl.includes('income/receipt/add')) {
          this.moduleId = Modules.Receipt;
          this.moduleName = ModuleName.Receipt;
        } else if (currentUrl.includes('expenditure/payment/add')) {
          this.moduleId = Modules.Payment;
          this.moduleName = ModuleName.Payment;
        } else if (currentUrl.includes('bank/cashEntry/add')) {
          this.moduleId = Modules.CashEntry;
          this.moduleName = ModuleName.CashEntry;
        } else {
          this.moduleId = Modules.BankEntry;
          this.moduleName = ModuleName.BankEntry;
        }

        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');

          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
          }

          const id = atob(params.get('id')!) as unknown as Guid;
          const customId = params.get('customId')!;

          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;

            if (this.isManualBank) {
              this.getTransactionLogData();
            } else {
              this.getBankAccountTransactionData();
            }
          } else if (
            params.get('id') !== undefined &&
            params.get('id') !== null
          ) {
            this.receiptId = id;
            this.editReceiptData();
          } else if (customId !== undefined && customId !== null) {
            this.bankId = atob(customId) as unknown as Guid;
          }
        }
      }
    );
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.triggerTransactionLogData.next(res.common.transactionLogData);
      });
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.triggerTransactionLogData.next(res.common.transactionLogData);
      });
  }

  editReceiptData(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetDataByReceiptId(this.receiptId, this.moduleId))
      .subscribe((res) => {
        this.receiptDetails.receiptForm.markAsUntouched();
        this.receiptDetails.receiptInfo.formReceiptDetail.markAsUntouched();
        this.spinner.hide();
        this.receiptId = res.receipt.receiptData.id;
        this.triggerEditData.next(res.receipt.receiptData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  routingPath(): RoutingPath {
    if (this.moduleId === Modules.Receipt) {
      return RoutingPath.AddReceipt;
    } else if (this.moduleId === Modules.Payment) {
      return RoutingPath.AddPayment;
    } else if (this.moduleId === Modules.CashEntry) {
      return RoutingPath.AddCashEntry;
    } else {
      return RoutingPath.AddBankEntry;
    }
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.receiptId,
      isExit,
      this.moduleId,
      this.routingPath()
    );
  }

  onSave(isExit: boolean): void {
    if (this.receiptDetails.receiptForm.invalid) {
      this.commonService.addValidation(
        this.receiptDetails.receiptForm,
        this.renderer
      );
    } else if (
      this.receiptDetails.receiptForm.controls.accountId.value ===
        this.receiptDetails.receiptForm.controls.accoutListBank.value.id &&
      this.receiptDetails.getModuleId === Modules.BankEntry
    ) {
      this.receiptDetails.receiptForm.controls.accountId.markAsTouched();
      this.receiptDetails.receiptForm.controls.accoutListBank.markAsTouched();
      this.commonService.onFailure(
        NotificationTextMessage.transferErrorMessage
      );
      return;
    } else if (this.receiptDetails.showReceiptDetails) {
      if (this.receiptDetails.receiptInfo.receiptDetailArray.invalid) {
        this.receiptDetails.receiptInfo.receiptDetailArray.controls.forEach(
          (x) => {
            (Object as any)
              .values(x.controls)
              .forEach((c) => c.markAsTouched());
          }
        );
      } else if (
        +this.receiptDetails.receiptForm.controls.amountReceived.value <
        this.receiptDetails.receiptInfo.totalAmount
      ) {
        this.receiptDetails.receiptInfo.receiptDetailArray.controls.forEach(
          (x) => {
            (Object as any)
              .values(x.controls)
              .forEach((c) => c.markAsTouched(false));
            x.controls.amount.status = 'INVALID';
          }
        );
        this.commonService.onFailure(
          NotificationTextMessage.amountErrorMessage
        );
      } else if (
        this.accountingMehtodId === AccountingMethod.CashBasis &&
        +this.receiptDetails.receiptForm.controls.amountReceived.value !==
          this.receiptDetails.receiptInfo.totalAmount
      ) {
        this.commonService.onFailure(
          NotificationTextMessage.allocateFullReceipt
        );
        return;
      } else {
        this.saveData(isExit);
      }
    } else {
      this.saveData(isExit);
    }
  }

  saveData(isExit): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new CreateReceipt(this.receiptData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.setHighlightData(isExit);

              if (!isExit && !this.isFromBankImport) {
                this.onCancel(false);
                this.reloadSideList.emit();
              } else {
                this.onCancel(false);
                this.location.back();
              }
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.receiptItems = new Array<any>();
      if (this.receiptDetails.showReceiptDetails) {
        this.receiptDetails.receiptInfo.receiptDetailArray
          ?.getRawValue()
          .forEach((x) => {
            this.receiptItems.push({
              id: x.id,
              invoiceId: x.invoiceReceipt,
              amount: +x.amount,
              invoiceTypeId: x.invoiceTypeId,
              note: x.note,
            });
          });
      }

      this.isCredit =
        this.receiptDetails.receiptForm.controls.transactionType.value ===
          TransactionStatus.Receipt &&
        (this.moduleId === Modules.Receipt ||
          this.moduleId === Modules.CashEntry ||
          this.moduleId === Modules.BankEntry);

      this.receiptData = {
        moduleId: this.moduleId,
        id: this.receiptId,
        code: this.receiptDetails.receiptForm.controls.code.value,
        isCredit: this.isCredit ?? false,
        receiptDate: this.datepipe
          .transform(
            this.receiptDetails.receiptForm.controls.receiptDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        note: this.receiptDetails.receiptForm.controls.note.value,
        transactionTypeId:
          this.receiptDetails.receiptForm.controls.accountTypeId.value,
        accountId:
          this.moduleId === Modules.BankEntry ||
          this.moduleId === Modules.CashEntry
            ? this.receiptDetails.receiptForm.controls.accoutListBank.value.id
            : this.receiptDetails.receiptForm.controls.customerName.value,
        postingAccountId:
          this.receiptDetails.receiptForm.controls.accountId.value,
        totalAmount:
          this.receiptDetails.receiptForm.controls.amountReceived.value === ''
            ? 0
            : this.receiptDetails.receiptForm.controls.amountReceived.value,
        isAdvance: this.receiptDetails.receiptForm.controls.isAdvance.value,
        currencyId: this.receiptDetails.receiptForm.controls.currency.value,
        attachment: filedata,
        receiptItems: this.receiptItems,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
        isManualBank: this.isManualBank,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.receiptId)) {
      this.editReceiptData();
    } else {
      this.receiptId = Guid.EMPTY as unknown as Guid;
      this.receiptDetails.ngOnInit();
      if (this.receiptDetails.showReceiptDetails) {
        this.receiptDetails.receiptInfo.ngOnInit();
      }
      this.attachmentDetail.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
