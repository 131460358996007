<div class="content-body content-body-scroll">
  <div class="alert-message" *ngIf="isViewPermission$ | async">
    <mat-icon class="material-icons-outlined">error_outline</mat-icon>
    {{ commonNotificationText.ViewOnlyAccessMessage }}
  </div>
  <div class="stepper-wrapper bridging-vat">
    <mat-horizontal-stepper linear #horizontalStepper>
      <mat-step label="1">
        <ng-template matStepLabel>Gift Aid Details</ng-template>
        <app-gift-aid-details
          #submitVatDetails
          [triggerEditData]="triggerEditData"
        ></app-gift-aid-details>
      </mat-step>
      <mat-step label="2" label=" VAT Details ">
        <ng-template matStepLabel>Donations Details</ng-template>
        <app-gift-aid-donations-details
          *ngIf="isFirstStepDone && importFile !== undefined"
          [giftAidImportData]="giftAidImportData"
          [startDate]="startDate"
          [endDate]="endDate"
        ></app-gift-aid-donations-details>
        <app-sub-contractor-details
          *ngIf="
            isFirstStepDone &&
            isGiftAIDDetailsComponent &&
            importFile === undefined
          "
          [giftAidId]="giftAidId"
          [startDate]="startDate"
          [endDate]="endDate"
          #vatDetails
        ></app-sub-contractor-details>
      </mat-step>
      <mat-step label="3">
        <ng-template matStepLabel>HMRC Gateway Details</ng-template>
        <app-hmrc-gateway-details
          #HMRCGatewayDetails
        ></app-hmrc-gateway-details>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
<div class="action-wrapper">
  <div class="content-body">
    <div class="button-container">
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onPrevClick(horizontalStepper)"
        [disabled]="stepper?.selectedIndex === 0"
      >
        Prev
      </button>
      <button
        *ngIf="stepper?.selectedIndex === 0"
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onNextClick(true, stepper?.selectedIndex)"
        [disabled]="isViewPermission$ | async"
      >
        Save & Next
      </button>
      <button
        *ngIf="
          isFirstStepDone && capiumButtonShow && stepper.selectedIndex === 1
        "
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="submitToCapium(true)"
      >
        Submit To Capium
      </button>
      <button
        *ngIf="
          hmrcButtonShow &&
          !capiumButtonShow &&
          stepper.selectedIndex === 1 &&
          !isContinueForHMRCSubmission
        "
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        (click)="submitToCapium(false)"
      >
        Submit To HMRC
      </button>
      <button
        *ngIf="isContinueForHMRCSubmission && stepper.selectedIndex === 2"
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="continueForHMRCSubmission()"
      >
        Continue for HMRC Submission
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        (click)="cancelClick(false)"
      >
        Close
      </button>
    </div>
  </div>
</div>
