import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ModuleName, Modules, NotificationTextMessage } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { CreateDonationCoding, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { DatePipe, Location } from '@angular/common';
import { AddClosePopupComponent } from '../common';
import { AddBulkDonationCodingComponent } from './add-bulk-donation-coding/add-bulk-donation-coding.component';
import { AddDonationCodingComponent } from './add-donation-coding/add-donation-coding.component';
import { DonationCodingItemModel, DonationCodingModel } from '@app/core/Models';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-donation-coding',
  templateUrl: './donation-coding.component.html',
  styleUrls: ['./donation-coding.component.scss'],
})
export class DonationCodingComponent implements OnInit {
  istoggleSideList = false;
  isShowSideListAction = false;
  isExpandAll = false;
  donationCodingData: DonationCodingModel;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddBulkDonationCodingComponent, { static: true })
  donationCodingDetails;

  @ViewChild(AddDonationCodingComponent, { static: true })
  donationCodingInfo;

  moduleId = Modules.DonationCoding;
  moduleName = ModuleName.DonationCoding;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private store: Store,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.donationCodingDetails.formDonationCodingDetail.markAsUntouched();
    this.donationCodingInfo.donationCodingForm.markAsUntouched();
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onSave(isExit: boolean): void {
    if (this.donationCodingInfo.donationCodingForm.invalid) {
      this.commonService.addValidation(
        this.donationCodingInfo.donationCodingForm,
        this.renderer
      );
    } else if (this.donationCodingDetails.formDonationCodingDetail.invalid) {
      this.donationCodingDetails.donationCodingDetailArray.controls.forEach(
        (x) => {
          (Object as any).values(x.controls).forEach((c) => {
            c.markAsTouched();
          });
        }
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateDonationCoding(this.donationCodingData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.onCancel(true);
                if (isExit) {
                  history.back();
                }
                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const donationCodingItems = new Array<DonationCodingItemModel>();

      this.donationCodingDetails.donationCodingDetailArray
        ?.getRawValue()
        .forEach((x) => {
          donationCodingItems.push({
            id: x.id,
            donorId: x.donorName,
            depositToAccountId: x.depositTo,
            incomeAccount: x.incomeAccount.id,
            donationAmount: x.donationAmount,
            note: x.notes,
          });
        });

      this.donationCodingData = {
        donationDate: this.datepipe
          .transform(
            this.donationCodingInfo.donationCodingForm.controls.donationDate
              .value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        sponsorEventId:
          this.donationCodingInfo.donationCodingForm.controls.sponsoredEvent
            .value === ''
            ? null
            : this.donationCodingInfo.donationCodingForm.controls.sponsoredEvent
                .value,
        activityId:
          this.donationCodingInfo.donationCodingForm.controls.activity.value ===
          ''
            ? null
            : this.donationCodingInfo.donationCodingForm.controls.activity
                .value,
        fundId:
          this.donationCodingInfo.donationCodingForm.controls.fundName.value,
        donationCodingItems: donationCodingItems,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    this.donationCodingDetails.ngOnInit();
    this.donationCodingInfo.ngOnInit();
  }
}
