import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyEmailSetting } from '@app/core/Models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  updateEmailConfig(IsFromCapium: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}company/updateEmailConfig/${IsFromCapium}`,
      null,
      headers
    );
  }

  getCompanyMailPreference(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}company/companyMailPreference`
    );
  }

  prepareGoogleOrOfficeInt(payload): Observable<any> {
    let provider =
      payload.ProviderType === 'google' ? 'prepareGoogle' : 'prepareOffice';
    let url = `${environment.apiVersionUrl}ProviderAccount/${provider}`;
    return this.http
      .post(url, payload, { responseType: 'text' })
      .pipe(map((body) => body));
  }

  getUserMailList(providerId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}ProviderAccount/getAllMail/${providerId}`
    );
  }

  deleteMail(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiVersionUrl}ProviderAccount/deleteMail/${id}`
    );
  }

  defaultMail(providerAccount): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}ProviderAccount/defaultMail`,
      providerAccount,
      headers
    );
  }

  savecompanyEmailSetting(
    companyEmailSetting: Array<CompanyEmailSetting>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Company/saveCompanyEmailSetting`,
      this.commonService.trimObjectSpace(JSON.stringify(companyEmailSetting)),
      headers
    );
  }

  getCompanyEmailSettingsList(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Company/companyEmailSettingList`
    );
  }
}
