import { Guid } from 'guid-typescript';

export class CommericalDetailModel {
  id: Guid;
  openingBalance: string;
  terms: string;
  vatRegistrationNumber: string;
  branchCode: string;
  accountNumber: string;
  accountIBANCode: string;
}
