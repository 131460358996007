import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GroupNames, Modules, OverviewYear } from '@app/core/Enum';
import { ReportFilterTypes } from '@app/core/Enum/report-filter-type';
import { FilterDateRange, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetChartOfAccountTypeList } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-income-overview-v1',
  templateUrl: './income-overview-v1.component.html',
  styleUrls: ['./income-overview-v1.component.scss'],
})
export class IncomeOverviewV1Component implements OnInit {
  @Output()
  readonly triggerSummaryIncomeData = new EventEmitter<any>();

  filter1 = ReportFilterTypes.Last30Days;

  @Output()
  readonly triggerHeaderFilter = new EventEmitter<any>();

  @Output()
  readonly triggerHeaderFilterForIncomeBreakdown = new EventEmitter<any>();

  rangeFormGroup: FormGroup;
  incomeBreakdownId: number;
  selectYear = OverviewYear;
  selectedYearValue = OverviewYear['Last 30 days'];
  moduleId = Modules.IncomeOverview;
  incomeBreakdownList: SideListModel[];
  showCalender = false;
  dateRange: any;

  constructor(
    public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private store: Store,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );
    this.setForm();
  }

  getChartOfAccountTypeList(): void {
    this.store
      .dispatch(new GetChartOfAccountTypeList(GroupNames.Income))
      .pipe(
        tap((res) => {
          this.incomeBreakdownList = res.account.chartOfAccountTypeList;
          this.incomeBreakdownId = this.incomeBreakdownList[0].id;
          this.getData();
        })
      )
      .subscribe();
  }

  setForm(): void {
    this.getChartOfAccountTypeList();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  onSelectionChange(val: any): void {
    this.showCalender = +val === 7;
    this.dateRange = this.commonService.generateDateSeries(val);

    if (
      this.showCalender &&
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.dateRange = new FilterDateRange();
      this.dateRange.startDate = new Date(
        this.rangeFormGroup.controls.start.value
      );
      this.dateRange.endDate = new Date(this.rangeFormGroup.controls.end.value);
      this.getData();
    } else if (!this.showCalender) {
      this.getData();
    }
  }

  onIncomeBreakdownTypeChange(): void {
    const data = this.getFilter();
    data.moduleId = this.incomeBreakdownId;
    this.triggerHeaderFilterForIncomeBreakdown.emit(data);
  }

  getFilter(): any {
    const filterData = {
      moduleId: 0,
      dateFilter: {
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(
                  this.rangeFormGroup.controls.start.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),

        endDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(this.rangeFormGroup.controls.end.value, 'yyyy-MM-dd')
                ?.toString(),
      },
    };

    return filterData;
  }

  getData(): void {
    const data = this.getFilter();

    setTimeout(() => {
      this.triggerHeaderFilter.emit(data);
      data.moduleId = this.incomeBreakdownId;
      this.triggerHeaderFilterForIncomeBreakdown.emit(data);
    }, 500);
  }
}
