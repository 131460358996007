<div class="content">
  <div class="content-body content-body-scroll">
    <app-transactions-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-transactions-header>
    <app-expand-collapse
      [triggerExpandedCollapseList]="triggerExpandedCollapseList"
      [listParameters]="listParameters"
      [moduleId]="moduleId"
    >
    </app-expand-collapse>
  </div>
</div>
