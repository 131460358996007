import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-subscription-details',
  templateUrl: './add-subscription-details.component.html',
  styleUrls: ['./add-subscription-details.component.scss'],
})
export class AddSubscriptionDetailsComponent implements OnInit {
  subscriptionForm: FormGroup;
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  @Input() triggerEditData: Observable<any>;

  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      this.editSubscription(data);
    });

    this.subscriptionForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.subscriptionForm.touched;
    });
  }

  editSubscription(data: any): void {
    data.isActive = data.membershipStatus;
    this.subscriptionForm.patchValue({
      startDate: data.membershipStartDate,
      endDate: data.membershipEndDate,
      note: data.membershipNote,
      isActive: data.membershipStatus === 'Active' ? '1' : '0',
    });
  }

  setForm(): void {
    this.subscriptionForm = new FormGroup({
      isActive: new FormControl('1'),
      startDate: new FormControl(new Date()),
      endDate: new FormControl(new Date()),
      note: new FormControl(''),
    });
  }
}
