import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CommonService } from '@app/core/Services';
import { environment } from '@environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PermissionType } from '../Enum/settings';
import { Guid } from 'guid-typescript';
import { RoutingPath } from '../Enum';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  isLogin = false;
  appDomain = environment.appDomain;
  uiUrl = environment.uiUrl;
  domain = 'capium.co.uk';
  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    @Inject(DOCUMENT) private document: Document,
    private commonService: CommonService,
    private cookieService: CookieService,
    private _Activatedroute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | boolean {
    return this.canActivate(next);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const userId = this.cookieService.get('userId') as unknown as Guid;
    if (
      userId === ('' as unknown as Guid) ||
      this.document.location.href.includes('code')
    ) {
      this.spinner.show();
    }

    return this.oidcSecurityService.checkAuth().pipe(
      switchMap((isAuthenticated) => {
        const userId = this.cookieService.get('userid') as unknown as Guid;

        if (isAuthenticated && userId !== ('' as unknown as Guid)) {
          if (state?.url === '/') {
            return this.router.navigate([RoutingPath.Dashboard]);
          }
          return this.hasPermissionToAccess(state?.url).pipe(
            map((hasAccess) => {
              if (hasAccess) {
                this.isLogin = isAuthenticated;
                return this.handleTokenRedirection();
              } else {
                this.router.navigate([RoutingPath.AccessDenied]);
                return false;
              }
            })
          );
        } else {
          this.isLogin = isAuthenticated;
          return of(this.handleTokenRedirection());
        }
      }),
      catchError((err) => {
        this.router.navigate(['/']);

        return of(false);
      })
    );
  }

  notLoggedIn(): void {
    const url = window.location.href;

    localStorage.clear();
    sessionStorage.clear();

    this.deleteAllCookies();

    if (url.split('/')[url.split('/').length - 1] === 'register') {
      this.isLogin = false;
    } else {
      this.login();
    }
  }

  login(): void {
    this.oidcSecurityService.authorize();
  }

  logout(): void {
    this.oidcSecurityService.logoff();
  }

  deleteAllCookies(): void {
    const cookies = document.cookie.split(';');

    for (const element of cookies) {
      const cookie = element;
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  getAppUrl(tenantName: string): string {
    const restUrl = `${window.location.pathname}${window.location.search}`;
    return `https://${this.appDomain}.${this.domain}${restUrl}`;
  }

  private handleTokenRedirection(): boolean {
    const token = this.oidcSecurityService.getToken();

    const origin = window.location.origin;
    const originParts = origin.split('.');
    let urlTenantName = '';

    this.isLogin = false;

    if (!token) {
      this.notLoggedIn();

      return false;
    }

    const decoded: any = jwt_decode(token);
    this.cookieService.set('externalUserGUID', decoded.ExternalUserGUID);
    this.cookieService.set('name', decoded.name);
    this.cookieService.set('email', decoded.email);
    if (!decoded.tenantName) {
      return false;
    }

    if (originParts.length > 3) {
      urlTenantName = originParts[0].split('//')[1];
    }

    if (originParts.length === 3) {
      urlTenantName = decoded.tenantName;
    }

    this.commonService.setTenantName(decoded.tenantName);

    this.navigateToDashboard();

    return true;
  }

  navigateToDashboard(): void {
    if (this.document.location.href.includes('code')) {
      this.document.location.href =
        this.document.location.origin + '/dashboard';
    } else {
      this.router.navigate['/dashboard'];
    }
  }

  setUrlIndex(url) {
    let urlIndex = '';
    if (url.indexOf(';') > 0) {
      urlIndex = ';';
    }
    if (url.indexOf('?') > 0) {
      urlIndex = '?';
    }
    return urlIndex;
  }

  hasPermissionToAccess(url): Observable<boolean> {
    let urlIndex = this.setUrlIndex(url);

    const currentUrl = url.indexOf(urlIndex) > 0 ? url.split(urlIndex)[0] : url;
    const currUrl = currentUrl.substring(1);
    const userPermission = localStorage.getItem('userPermission')!;
    const element = JSON.parse(userPermission);
    if (!element) {
      return of(true);
    }
    if (
      currUrl === RoutingPath.CashCoding ||
      currUrl === RoutingPath.BankManualImport ||
      currUrl === RoutingPath.ReportDetailsList ||
      currUrl === RoutingPath.CashEntryList ||
      currUrl === RoutingPath.SupportTicket ||
      currUrl === RoutingPath.AddCharity ||
      currUrl === RoutingPath.BankImport ||
      currUrl === RoutingPath.BankTransaction ||
      currUrl === RoutingPath.EditAccountProductionTrialBalance
    ) {
      return of(true);
    }

    const isMenuExist = element.some(
      (ele) => ele.url === currUrl || ele.addUrl === currUrl
    );

    if (isMenuExist || this.checkSubMenuPresent(element, currUrl)) {
      for (const ele of element) {
        if (
          (ele.url === currUrl || ele.addUrl === currUrl) &&
          ele.permissionId === PermissionType.None
        ) {
          return of(false);
        }

        for (const childElement of ele.subMenu) {
          if (
            (childElement.url === currUrl || childElement.addUrl === currUrl) &&
            childElement.permissionId === PermissionType.None
          ) {
            return of(false);
          }
        }
      }
    } else {
      return of(false);
    }

    return of(true);
  }

  checkSubMenuPresent(element, currUrl) {
    const checkSubMenu = (subMenuArray) => {
      return subMenuArray.some((submenu) => {
        if (submenu.subMenu.length) {
          return checkSubMenu(submenu.subMenu);
        }
        return submenu.url === currUrl || submenu.addUrl === currUrl;
      });
    };

    for (const ele of element) {
      if (ele.subMenu.length && checkSubMenu(ele.subMenu)) {
        return true;
      }
    }

    return false;
  }
}
