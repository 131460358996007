import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GiftAid,
  GiftAidSubmissionModel,
  HMRCGatewayDetails,
  MainListParameters,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { CommonService } from '../../common/common.service';
import { Guid } from 'guid-typescript';
import { FileImportRequestModel } from '@app/core/Enum/import';

@Injectable({
  providedIn: 'root',
})
export class GiftAidDetailsService {
  constructor(private http: HttpClient, private commonService: CommonService) {}
  saveGiftAid(giftAidData: GiftAid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}GiftAid/save`,
      this.commonService.trimObjectSpace(JSON.stringify(giftAidData)),
      headers
    );
  }

  getGiftAidDetails(id: Guid): Observable<any> {
    return this.http.get<any>(`${environment.apiVersionUrl}GiftAid/get/${id}`);
  }

  getSubContractorDetailsList(
    queryParams: MainListParameters,
    directorShareholderId: Guid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}GiftAid/giftAidDonationDetailList/${directorShareholderId}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  saveGiftAidDetails(
    giftAidDetailsData: GiftAidSubmissionModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}GiftAid/save`,
      JSON.stringify(giftAidDetailsData),
      headers
    );
  }

  saveHMRCGatewayDetails(
    saveHMRCGatewayDetailsData: HMRCGatewayDetails
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}GiftAid/submit`,
      JSON.stringify(saveHMRCGatewayDetailsData),
      headers
    );
  }

  deleteGiftAidDetails(giftAddDetailsId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(giftAddDetailsId),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}GiftAid/delete`,
      options
    );
  }

  giftAidImport(
    fileImportRequestModel: FileImportRequestModel
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}GiftAid/import`,
      formData
    );
  }
}
