<div class="content-body">
  <form [formGroup]="cisInformationForm">
    <div class="form-field d-flex align-items-center">
      <mat-checkbox disableRipple formControlName="cisEnabled"></mat-checkbox>
      <span class="clamp">CIS?</span>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Business Type <span class="text-danger-300">*</span></p>
        <ng-select
          #businessType
          (focus)="businessType.open()"
          (valueChange)="businessType.close()"
          [disableOptionCentering]="true"
          formControlName="businessTypeId"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of businessTypeList" [value]="cl.id">
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Trading Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input matInput formControlName="tradingName" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Subcontractor UTR</p>
        <mat-form-field>
          <input
            matInput
            formControlName="subContractorUTR"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>NI Number</p>
        <mat-form-field>
          <input
            matInput
            formControlName="niNumber"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Charity Number</p>
        <mat-form-field>
          <input
            matInput
            formControlName="companyNumber"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Work Reference</p>
        <mat-form-field>
          <input
            matInput
            formControlName="workReference"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <mat-panel-title>
      <div class="d-flex flex-column">
        <p>Verification Status</p>
      </div>
    </mat-panel-title>
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field">
        <p>Verify</p>
        <ng-select
          #verify
          (focus)="verify.open()"
          (valueChange)="verify.close()"
          [disableOptionCentering]="true"
          formControlName="verify"
          panelClass="mat-select-position"
        >
          <ng-option value="3">
            <div title="verify 3">Verify 3</div>
          </ng-option>
          <ng-option value="2">
            <div title="verify 2">Verify 2</div>
          </ng-option>
          <ng-option value="1">
            <div title="verify 1">Verify 1</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field m-0">
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
        >
          Verify
        </button>
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
        >
          Tax Treatment
        </button>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Status</p>
        <mat-form-field>
          <input
            matInput
            formControlName="status"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Verification Number</p>
        <mat-form-field>
          <input
            matInput
            formControlName="verificationNumber"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>Last Verified</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="lastVerified"
            [matDatepicker]="lastVerifiedDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="lastVerifiedDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #lastVerifiedDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Tax Treatment</p>
        <mat-form-field>
          <input
            matInput
            formControlName="taxTreatment"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
