<div class="mb-4">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      Manual Import
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>

    <div class="mt-1 mb-1 d-flex align-items-center gap-10">
      <p class="fw-bold m-0">Select Bank:</p>
      <ng-select
        class="w-200"
        #accountList
        (focus)="accountList.open()"
        (valueChange)="accountList.close()"
        [disableOptionCentering]="true"
        [(ngModel)]="selectedBank"
        required
        panelClass="mat-select-position"
      >
        <ng-option
          *ngFor="let option of receiptAccountList"
          [value]="option.id"
        >
          <div title="{{ option.name }}">{{ option.name }}</div>
        </ng-option>
      </ng-select>
    </div>

    <div class="sidenav-table sidenav-table-input wp-100">
      <div class="mat-table-wrapper">
        <form [formGroup]="formManualImport">
          <ng-container formArrayName="manualImportArray">
            <table
              mat-table
              [dataSource]="tableDataSource"
              aria-describedby="Manual-Import"
            >
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="w-150">Date</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field appearance="standard">
                    <input
                      matInput
                      formControlName="date"
                      [matDatepicker]="DatePicker"
                      autocomplete="off"
                      required
                      [value]="element.date"
                      (change)="commonService.validateDate($event)"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="DatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #DatePicker></mat-datepicker>
                  </mat-form-field>
                  <mat-error
                    *ngIf="
                      commonService.checkDateValidation(
                        manualImportArray.controls[i].get('date')
                      ).invalidFinancialYear
                    "
                  >
                    {{ notificationMessage.validPeriodDateMessage }}
                  </mat-error>

                  <mat-error
                    *ngIf="
                      commonService.checkDateValidation(
                        manualImportArray.controls[i].get('date')
                      ).lockedFinancialYear
                    "
                  >
                    {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
                  </mat-error>
                </td>
              </ng-container>
              <ng-container matColumnDef="Description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="w-387"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      matInput
                      maxlength="{{ maxLength.maxLength100 }}"
                      [value]="element.description"
                      autocomplete="off"
                      formControlName="description"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="paidout">
                <th mat-header-cell *matHeaderCellDef class="w-100">
                  Paid Out
                </th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allow_14_2_Decimal
                      matInput
                      [value]="element.paidOut"
                      autocomplete="off"
                      formControlName="paidOut"
                      (keypress)="paidOutChanges(i, $event)"
                      (keyup.backspace)="paidOutChanges(i, $event)"
                      maxlength="10"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="paidin">
                <th mat-header-cell *matHeaderCellDef class="w-100">Paid In</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allow_14_2_Decimal
                      matInput
                      [value]="element.paidIn"
                      autocomplete="off"
                      formControlName="paidIn"
                      (keypress)="paidInChanges(i, $event)"
                      (keyup.backspace)="paidInChanges(i, $event)"
                      maxlength="10"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="closeButton">
                <th mat-header-cell *matHeaderCellDef class="w-20"></th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="text-align-center"
                >
                  <button
                    class="close-btn"
                    type="button"
                    mat-button
                    disableRipple
                    (click)="onDeleteCashCodingDetails(i)"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </ng-container>
        </form>
      </div>
      <div class="mb-1 mt-1 d-flex justify-space-between">
        <div class="mt-1">
          <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
            >Add New Line</a
          >
          <a class="link mr-1" href="javascript:void(0)" (click)="clearForm()"
            >Clear All Lines</a
          >
        </div>
      </div>
    </div>
  </div>
  <app-buttons
    (triggerOnSaveClick)="onSave($event.isExit)"
    (triggerOnCancelClick)="onCancel(true)"
  ></app-buttons>
</div>
