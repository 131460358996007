import { SaveOpeningBalanceModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class RefreshAccountProductionTrialBalance {
  static readonly type =
    '[ACCOUNTPRODUCTION] Refresh Account Production Trial Balance ';

  constructor(public accountingPeriodId: Guid) {}
}

export class GetAccountProductionTrialBalance {
  static readonly type =
    '[ACCOUNTPRODUCTION] Get Account Production Trial Balance ';

  constructor(public accoutingPeriodId: Guid) {}
}

export class SaveAccountProductionTrialBalance {
  static readonly type = '[ACCOUNTPRODUCTION] save Trial Balance';

  constructor(public trialBalance: SaveOpeningBalanceModel) {}
}
