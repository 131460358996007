<div class="mat-dialog-wrapper">
  <div class="d-flex justify-space-between align-items-center mb-1 header-text">
    <h2 mat-dialog-title class="m-0">Confirm Invite</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="form-field-title">
      <p>Select modules to grant access to this user :</p>
    </div>
    <div class="d-flex gap-40">
      <ng-container *ngFor="let subscriptionModule of subscriptionModuleList">
        <div
          class="form-field m-0"
          [ngClass]="subscriptionModule.isDisabled ? 'disable-box' : ''"
          *ngIf="subscriptionModule.isDisplay"
        >
          <mat-checkbox>{{ subscriptionModule.externalName }}</mat-checkbox>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mt-2 m-0 d-flex gap-15">
    <button
      class="mat-focus-indicator action-button primary-button mat-button mat-button-base"
    >
      Invite
    </button>
    <button
      class="action-button secondary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onCancelClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>
