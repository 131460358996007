import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainListParameters, SideListModel } from '@app/core/Models';
import { DonorModel } from '@app/core/Models/contact/donor-model';
import { CommonService } from '@app/core/Services';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DonorService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getDataByDonorId(donorId: Guid): Observable<DonorModel> {
    return this.http.get<DonorModel>(
      `${environment.apiVersionUrl}Donor/get/${donorId}`
    );
  }

  getAllDonor(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Donor/all`
    );
  }

  getGiftAidDonorSpreadSheets(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}Donor/getGiftAidDonorSpreadsheets`
    );
  }

  getGiftAidDonorDeclarations(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}Donor/getGiftAidDeclarations`
    );
  }

  createDonor(donor: DonorModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Donor/save`,
      this.commonService.trimObjectSpace(JSON.stringify(donor)),
      headers
    );
  }

  deleteDonor(donorIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(donorIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Donor/delete`,
      options
    );
  }

  copyDonor(donorIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Donor/copy`,
      this.commonService.trimObjectSpace(JSON.stringify(donorIds)),
      headers
    );
  }

  archiveAndRestoreDonor(
    donorIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Donor/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(donorIds)),
      options
    );
  }

  donorTransactionExport(
    queryParams: MainListParameters,
    id: Guid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}Donor/donorDetailList/export/${id}`,
        JSON.stringify(queryParams),
        headers
      )
      .pipe(
        switchMap((response: any) => {
          const body: Blob = response.body || new Blob();
          if (queryParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }
}
