import { ActivityModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateActivity {
  static readonly type = '[ACTIVITY] Create Activity';

  constructor(public activity: ActivityModel) {}
}

export class GetDataByActivityId {
  static readonly type = '[ACTIVITY] Get Activity Data By Activity Id';

  constructor(public activityId: Guid) {}
}

export class ArchiveAndRestoreActivity {
  static readonly type = '[ACTIVITY] Archive and Restore Selected Activity';

  constructor(public activityIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteActivity {
  static readonly type = '[ACTIVITY] Delete Selected Activity';

  constructor(public activityIds: Array<Guid>) {}
}
