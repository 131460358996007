import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainListParameters, SaveBulkEditModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';
@Injectable({
  providedIn: 'root',
})
export class BulkEditService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getBulkEditList(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}BulkEdit/list`,
      this.commonService.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  saveBulkEdit(params?: Array<SaveBulkEditModel>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}BulkEdit/save`,
      this.commonService.trimObjectSpace(JSON.stringify(params)),
      headers
    );
  }

  getInvoiceTypeBasedOfAccount(invoiceTypeId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Account/getInvoiceTypeBasedOfAccount/${invoiceTypeId}`
    );
  }
}
