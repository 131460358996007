import { Injectable } from '@angular/core';
import { OverviewModel } from '@app/core/Models';
import { OverviewService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { GetOverviewData } from './overview.action';

export class OverviewStateInfo {
  overviewData: OverviewModel;
}

@State<OverviewStateInfo>({
  name: 'overview',
  defaults: {
    overviewData: new OverviewModel(),
  },
})
@Injectable()
export class OverviewState {
  constructor(private overviewService: OverviewService) {}

  @Selector()
  static overviewData(state: OverviewStateInfo): any {
    return state.overviewData;
  }

  @Action(GetOverviewData)
  getOverviewData(
    { setState }: StateContext<OverviewStateInfo>,
    action: GetOverviewData
  ) {
    return this.overviewService
      .getOverviewData(action.body, action.moduleId)
      .pipe(
        tap((res) => {
          setState({
            overviewData: res,
          });
        })
      );
  }
}
