import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note40',
  templateUrl: './note40.component.html',
  styleUrls: ['./note40.component.scss'],
})
export class Note40Component implements OnInit {
  note40Form: FormGroup;
  calculativeData: any;

  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      if (data.noteId === Notes.Note40) {
        this.calculativeData = data.response.calculativeData;
        if (
          data.response.userInputData !== null &&
          data.response.userInputData !== undefined &&
          data.response.userInputData.data !== null &&
          data.response.userInputData.data !== undefined
        ) {
          this.editNotes40(data.response.userInputData.data);
        }
      }
    });
  }
  editNotes40(res): void {
    this.note40Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : '',
      textbox2: res.textbox2 ? res.textbox2 : '',
      textbox3: res.textbox3 ? res.textbox3 : '',
      textbox4: res.textbox4 ? res.textbox4 : '',
    });
  }

  setForm(): void {
    this.note40Form = new FormGroup({
      textbox1: new FormControl(''),
      textbox2: new FormControl(''),
      textbox3: new FormControl(''),
      textbox4: new FormControl(''),
    });
  }

  filterItems(calculativeData: any, fundType: string): any {
    return calculativeData.filter((x) =>
      x.type.toLowerCase().startsWith(fundType)
    );
  }
}
