import { PaymentDetailsModel, ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class BillModel {
  id?: Guid;
  entryNumber?: string;
  entryDate?: string;
  dueDate?: string;
  currencyId: number;
  accountId: Guid;
  note: string;
  bookAccountId: Guid;
  fundId: Guid;
  activityId: Guid | null;
  items: Array<ProductDetailsModel>;
  receiptDetails: Array<PaymentDetailsModel>;
  attachment?: Array<string>;
  transactionLogId?: Guid;
  isManualBank?: boolean;
  advanceReceiptAmount?: number;
  isVatIncluded: boolean;
}
