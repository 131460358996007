<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
    [ngClass]="{ 'wp-100': isShowSideListAction }"
  ></app-side-list>

  <div
    class="form mb-4"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <p class="header-text d-flex justify-space-between align-items-center">
          <span>
            {{
              customAccountId === commonService.defaultGuidValue
                ? "Add Custom Account"
                : "Edit Custom Account"
            }}
          </span>
          <button
            mat-button
            class="header-text-close d-flex align-items-center"
            disableRipple
            (click)="onCloseClick()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </p>
        <div class="mb-1">
          <div class="alert-message" *ngIf="isViewPermission$ | async">
            <mat-icon class="material-icons-outlined">error_outline</mat-icon>
            {{ commonNotificationText.ViewOnlyAccessMessage }}
          </div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Account Setup</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-add-custom-accounts
                [triggerEditCustomData]="triggerEditCustomData"
                [triggereAddCustomAccount]="triggereAddCustomAccount"
                (accountGroupChange)="accountGroupChange($event)"
                (accountTypeChange)="accountTypeChange($event)"
              ></app-add-custom-accounts>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave($event.isExit)"
      (triggerOnCancelClick)="onCancel(true)"
    >
    </app-buttons>
  </div>
</div>
