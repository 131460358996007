<div class="mat-dialog-wrapper">
  <div class="d-flex justify-space-between align-items-center mb-1 header-text">
    <h2 mat-dialog-title class="m-0">Grant Permission</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="form-field-title">
      <p>Select modules to grant access to this user :</p>
    </div>
    <div class="d-flex gap-40">
      <div>
        <div class="form-field m-0">
          <mat-checkbox>Time and Fees</mat-checkbox>
        </div>
        <div class="form-field m-0">
          <mat-checkbox>Account Production</mat-checkbox>
        </div>
        <div class="form-field m-0">
          <mat-checkbox>Practice Management</mat-checkbox>
        </div>
        <div class="form-field m-0">
          <mat-checkbox>Capium Hub</mat-checkbox>
        </div>
      </div>
      <div>
        <div class="form-field m-0">
          <mat-checkbox>Charity</mat-checkbox>
        </div>
        <div class="form-field m-0">
          <mat-checkbox>Corporation Tax</mat-checkbox>
        </div>
        <div class="form-field m-0">
          <mat-checkbox>Payroll</mat-checkbox>
        </div>
        <div class="form-field m-0">
          <mat-checkbox>My Admmin</mat-checkbox>
        </div>
      </div>
      <div>
        <div class="form-field m-0">
          <mat-checkbox>Bookkeeping</mat-checkbox>
        </div>
        <div class="form-field m-0">
          <mat-checkbox>Self Assessment</mat-checkbox>
        </div>
        <div class="form-field m-0">
          <mat-checkbox>Capisign</mat-checkbox>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mt-2 m-0 d-flex gap-15">
    <button
      class="mat-focus-indicator action-button primary-button mat-button mat-button-base"
    >
      Grant Access
    </button>
    <button
      class="action-button secondary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onCancelClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>
