import { DatePipe, Location } from '@angular/common';
import {
  Component,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AccountEntity,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
  OpeningBalanceExplain,
  RoutingPath,
} from '@app/core/Enum';
import {
  GlobalComponent,
  OpeningBalance,
  SaveOpeningBalanceModel,
} from '@app/core/Models';
import { CommonService, ModulePermission } from '@app/core/Services';
import {
  AccountProductionState,
  AccountState,
  GetAccountProductionTrialBalance,
  GetExplain,
  GetJournalAccounts,
  MenuState,
  SaveAccountProductionTrialBalance,
} from '@app/core/Store';
import {
  GetOpeningBalanceBasedOnAPId,
  GetOpeningBalanceList,
  OpeningBalanceState,
  SaveOpeningBalance,
  SaveTrialBalance,
} from '@app/core/Store/opening-balance/opening-balance';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AddClosePopupComponent, CleanAllLinesComponent } from '../common';
import * as moment from 'moment';
import CustomYearPickerComponent from '../custom/custom-year-picker/custom-year-picker.component';
import {
  OpeningBalanceExplainContractComponent,
  OpeningBalanceExplainFundComponent,
} from '..';
import { NumberPipe } from '@app/core/Pipes/number-pipe.pipes';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-opening-balance',
  templateUrl: './opening-balance.component.html',
  styleUrls: ['./opening-balance.component.scss'],
})
export class OpeningBalanceComponent implements OnInit {
  @Input()
  getModuleId: number;

  openingBalanceForm: FormGroup;
  formOpeningBalance: UntypedFormGroup;

  isNewUser = false;
  isEdit = false;

  openingBalanceData: OpeningBalance[] = [];

  openingBalanceAccountingDateData: SaveOpeningBalanceModel;
  trialBalanceAccountingDateData: SaveOpeningBalanceModel;

  totalUnrestrictedCredit: number = 0;
  totalUnrestrictedDebit: number = 0;
  totalRestrictedCredit: number = 0;
  totalRestrictedDebit: number = 0;
  totalEndowmentCredit: number = 0;
  totalEndowmentDebit: number = 0;
  sumOfTotalCrDrExplain: number = 0;
  unrestrictedTotalDifference: number = 0;
  restrictedTotalDifference: number = 0;
  endowmenTotalDifference: number = 0;

  moduleId = Modules.EditTrialBalance;
  moduleEnum = Modules;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  tableDataSource: MatTableDataSource<AbstractControl>;
  subscriptionRouting: Subscription;
  maxLength = MaxLength;

  startDate: any;
  endDate: any;
  statusValue: any;
  accountingDate: any;
  periodicDateList: any;
  accountingDateId: Guid;
  openingBalanceArray: any;
  selectedIds: any[] = [];
  accountGroupConstList: any[] = [];
  accountGroupList: any[] = [];
  tempAccountGroupList: any[] = [];
  accountList: any[] = [];
  openingBalanceBasedOnAPIdList: any[] = [];
  displayOpeningBalanceColumns: string[] = [
    'srNo',
    'account',
    'unrestrictedDebit',
    'unrestrictedCredit',
    'restrictedDebit',
    'restrictedCredit',
    'endowmentDebit',
    'endowmentCredit',
    'closeButton',
  ];

  displayOpeningBalanceColumns2: string[] =
    this.displayOpeningBalanceColumns.map((x) => `f2_${x}`);

  triggerResetDetailList: Subject<any> = new Subject<any>();

  @ViewChild(CustomYearPickerComponent, { static: true })
  customYearPickerDetails;

  store: Store;
  formBuilder: UntypedFormBuilder;
  commonService: CommonService;
  dialog: MatDialog;
  location: Location;
  _Activatedroute: ActivatedRoute;
  spinner: NgxSpinnerService;
  renderer: Renderer2;
  datepipe: DatePipe;
  globalComponent: GlobalComponent;
  numberPipe: NumberPipe;

  headerText: string;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private injector: Injector,
    private modulePermission: ModulePermission,
    private router: Router
  ) {
    (this.store = injector.get<Store>(Store)),
      (this.formBuilder = injector.get<UntypedFormBuilder>(UntypedFormBuilder)),
      (this.commonService = injector.get<CommonService>(CommonService)),
      (this.dialog = injector.get<MatDialog>(MatDialog)),
      (this.location = injector.get<Location>(Location)),
      (this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute)),
      (this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService)),
      (this.renderer = injector.get<Renderer2>(Renderer2)),
      (this.datepipe = injector.get<DatePipe>(DatePipe)),
      (this.globalComponent = injector.get<GlobalComponent>(GlobalComponent)),
      (this.numberPipe = injector.get<NumberPipe>(NumberPipe));

    this.formOpeningBalance?.markAsUntouched();
  }

  ngOnInit(): void {
    if (
      this.router.url.replace(/\?.*$/g, '').slice(1) ===
      RoutingPath.EditAccountProductionTrialBalance
    ) {
      this.moduleId = Modules.EditAccountProductionTrialBalance;
    }
    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
      if (this.moduleId === Modules.OpeningBalance) {
        this.getHeaderText();
        this.getGroupAccountList();
      }
    });
    this.getHeaderText();
    this.setOpeningBalanceForm(true);
    this.setPeriodDate();
    this.accountingDate = new Date();
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.isNewUser = JSON.parse(atob(params.get('isNewUser')!));
          this.spinner.hide();
        }
      }
    );

    this.formOpeningBalance.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formOpeningBalance.touched;
    });
  }

  getHeaderText(): void {
    this.headerText =
      this.moduleId === Modules.OpeningBalance
        ? ModuleName.OpeningBalance
        : 'Trial Balance';
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.addDropdown();
    }
  }

  addDropdown(): void {
    const i = this.openingBalanceArray.length + 1;

    let tempArray: any[] = [];
    for (let data of this.accountGroupConstList) {
      const response: any = this.checkIdsExist(
        data.listModels,
        this.selectedIds
      );
      if (response !== null && response !== undefined) {
        let list: any[] = [];
        data.listModels.forEach((element) => {
          const isExist = response.filter((x) => x === element.id);
          if (isExist.length === 0) {
            list.push(element);
          }
        });
        const param = {
          groupName: data.groupName,
          listModels: list,
        };
        if (param.listModels.length > 0) {
          tempArray.push(param);
        }
      } else {
        tempArray.push(data);
      }
    }
    this.accountGroupList.push(tempArray);
    this.tempAccountGroupList.push(tempArray);

    this.createRow();
  }

  setDropDown(dataDisplay: any, isReset: boolean): void {
    this.accountGroupList = [];
    this.tempAccountGroupList = [];
    this.selectedIds = [];
    dataDisplay.forEach((item) => {
      this.selectedIds.push(isReset ? item.account.id : item.accountId);
    });

    dataDisplay.forEach((item, i) => {
      let selectedIds: any[] = [];
      this.selectedIds.forEach((element, index) => {
        if (i !== index) {
          selectedIds.push(element);
        }
      });

      let tempArray: any[] = [];

      for (let data of this.accountGroupConstList) {
        const response: any = this.checkIdsExist(data.listModels, selectedIds);
        if (
          response !== null &&
          response !== undefined &&
          response.length > 0
        ) {
          let list: any[] = [];
          data.listModels.forEach((element) => {
            const removedata = response.find((x) => x === element.id);

            if (removedata === null || removedata === undefined) {
              list.push(element);
            }
          });
          const param = {
            groupName: data.groupName,
            listModels: list,
          };
          if (param.listModels.length > 0) {
            tempArray.push(param);
          }
        } else {
          tempArray.push(data);
        }
      }
      this.accountGroupList.push(tempArray);
      this.tempAccountGroupList.push(tempArray);
    });

    if (!isReset) {
      this.editOpeningBalance(dataDisplay);
    }
  }

  checkIdsExist(listModels, selectedIds): any {
    let dataReponse: any[] = [];
    listModels.forEach((element) => {
      const data = selectedIds.find((x) => x === element.id);
      if (data !== null && data !== undefined) {
        dataReponse.push(data);
      }
    });
    return dataReponse;
  }

  resetForm(): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;
    formArray.clear();
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formOpeningBalance.get(
            'openingBalanceArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('unrestrictedDebit')?.setValue(null);
            formArray.controls[i].get('unrestrictedCredit')?.setValue(null);
            formArray.controls[i].get('restrictedDebit')?.setValue(null);
            formArray.controls[i].get('restrictedCredit')?.setValue(null);
            formArray.controls[i].get('endowmentDebit')?.setValue(null);
            formArray.controls[i].get('endowmentCredit')?.setValue(null);
            formArray.controls[i].get('account')?.setValue('');
            formArray.controls[i].get('account')?.updateValueAndValidity();
            this.resetFormValidation(i);
          }
          this.totalUnrestrictedCredit = 0;
          this.totalUnrestrictedDebit = 0;
          this.totalRestrictedCredit = 0;
          this.totalRestrictedDebit = 0;
          this.totalEndowmentCredit = 0;
          this.totalEndowmentDebit = 0;
          this.sumOfTotalCrDrExplain = 0;
          this.unrestrictedTotalDifference = 0;
          this.restrictedTotalDifference = 0;
          this.endowmenTotalDifference = 0;
        }
        this.calAmount();
      });
  }

  setOpeningBalanceForm(addNewRow: boolean): void {
    this.formOpeningBalance = new FormGroup({
      openingBalanceArray: new UntypedFormArray([]),
    });

    this.openingBalanceArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    this.setDataSource(this.openingBalanceArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.openingBalanceArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    this.openingBalanceArray.push(this.setForm());

    this.setDataSource(this.openingBalanceArray);
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      unrestrictedDebit: new FormControl<number | null>(null, [
        Validators.required,
      ]),
      unrestrictedCredit: new FormControl<number | null>(null, [
        Validators.required,
      ]),
      restrictedDebit: new FormControl<number | null>(null, [
        Validators.required,
      ]),
      restrictedCredit: new FormControl<number | null>(null, [
        Validators.required,
      ]),
      endowmentDebit: new FormControl<number | null>(null, [
        Validators.required,
      ]),
      endowmentCredit: new FormControl<number | null>(null, [
        Validators.required,
      ]),
      account: new FormControl<string | null>('', [Validators.required]),
      isExplain: new FormControl(false),
      isFundExplain: new FormControl(false),
      isUpdated: new FormControl(false),
      isExplainSave: new FormControl(false),
      explainList: new FormControl(),
      accountTypeId: new FormControl(0),
    });
  }

  accountSelect(event, element, index: number): void {
    this.resetDropDown();
    this.getAccountGroupAndType(event.option.value, element, index);
  }

  getAccountGroupAndType(event, element, index): void {
    this.store.dispatch(new GetExplain(event.id)).subscribe((res) => {
      element.isExplain =
        res.account.explain === AccountEntity.Supplier ||
        res.account.explain === AccountEntity.Customer;

      element.isFundExplain = res.account.explain === AccountEntity.Fund;

      this.openingBalanceArray.controls[index]
        .get('accountTypeId')
        .setValue(
          res.account.explain === AccountEntity.Supplier
            ? AccountEntity.Supplier
            : res.account.explain === AccountEntity.Customer
            ? AccountEntity.Customer
            : AccountEntity.Fund
        );
      this.openingBalanceArray.controls[index]
        .get('isExplain')
        .setValue(element.isExplain);
      this.openingBalanceArray.controls[index]
        .get('isFundExplain')
        .setValue(element.isFundExplain);
    });
  }

  onExplainContacts(index: number, element: any): void {
    const openingBalance =
      +element.value.endowmentCredit +
      +element.value.endowmentDebit +
      +element.value.restrictedCredit +
      +element.value.restrictedDebit +
      +element.value.unrestrictedCredit +
      +element.value.unrestrictedDebit;
    const param = {
      moduleId: this.moduleId,
      totalOpeningBalance: openingBalance,
      accountTypeId:
        this.openingBalanceArray.controls[index].get('accountTypeId').value,
      accountingPeriodId: this.accountingDateId,
      accountingDate: this.datepipe
        .transform(this.accountingDate, 'yyyy-MM-dd')
        ?.toString(),
      indexData: this.openingBalanceArray.controls[index].value,
      openingBalanceArray: this.openingBalanceArray,
      account: this.openingBalanceArray.controls[index].get('account').value,
    };

    if (this.openingBalanceArray.controls[index].get('isFundExplain').value) {
      this.dialog
        .open(OpeningBalanceExplainFundComponent, {
          data: param,
          disableClose: true,
          autoFocus: false,
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            this.openingBalanceArray.controls[index]
              .get('isExplainSave')
              ?.setValue(res.isExplainSave);

            this.store
              .dispatch(new GetJournalAccounts())
              .pipe(
                tap((res) => {
                  if (res.account.accounts.length > 0) {
                    this.accountList = res.account.accounts;
                    this.accountGroupConstList = this.accountList;
                  }
                })
              )
              .subscribe();
          }
        });
    } else if (
      this.openingBalanceArray.controls[index].get('isExplain').value
    ) {
      this.dialog
        .open(OpeningBalanceExplainContractComponent, {
          data: param,
          disableClose: true,
          autoFocus: false,
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            this.sumOfTotalCrDrExplain = +res.sumOfAllDrCr;
            this.openingBalanceArray.controls[index]
              .get('explainList')
              ?.setValue(res.explainList);

            this.openingBalanceArray.controls[index]
              .get('isExplainSave')
              ?.setValue(res.isExplainSave);
          }
        });
    }
  }

  onDeleteOpeningBalance(index: number): void {
    this.openingBalanceArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    this.accountGroupList.splice(index, 1);
    this.tempAccountGroupList.splice(index, 1);
    this.selectedIds.splice(index, 1);
    this.openingBalanceArray.removeAt(index);
    this.totalUnrestrictedCredit = 0;
    this.totalUnrestrictedDebit = 0;
    this.totalRestrictedCredit = 0;
    this.totalRestrictedDebit = 0;
    this.totalEndowmentCredit = 0;
    this.totalEndowmentDebit = 0;
    this.openingBalanceArray?.getRawValue().forEach((x) => {
      this.totalUnrestrictedDebit += +x.unrestrictedDebit;
      this.totalUnrestrictedCredit += +x.unrestrictedCredit;
      this.totalRestrictedDebit += +x.restrictedDebit;
      this.totalRestrictedCredit += +x.restrictedCredit;
      this.totalEndowmentDebit += +x.endowmentDebit;
      this.totalEndowmentCredit += +x.endowmentCredit;
    });
    this.setDataSource(this.openingBalanceArray);
    this.resetDropDown();
  }

  resetDropDown(): void {
    this.accountGroupList = [];
    this.tempAccountGroupList = [];
    this.selectedIds = [];
    this.setDropDown(this.openingBalanceArray.value, true);
  }

  editOpeningBalance(data: any): void {
    this.openingBalanceArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    this.openingBalanceArray.clear();
    this.sumOfTotalCrDrExplain = 0;
    let account: any;
    data.forEach((item, i) => {
      this.openingBalanceArray.push(this.buildOrderItemsForm(item));
      this.accountGroupList[i].forEach((items) => {
        const data = items.listModels.find((x: any) => x.id === item.accountId);
        if (data !== null && data !== undefined) {
          account = data;
        }
      });
      this.openingBalanceArray.controls[i].get('account').setValue(account);
      this.openingBalanceArray.controls[i]
        .get('account')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      this.openingBalanceArray.controls[i]
        .get('account')
        ?.updateValueAndValidity();
      if (item.isExplain && item.explainList.length > 0) {
        item.explainList.forEach((element) => {
          this.sumOfTotalCrDrExplain =
            +this.sumOfTotalCrDrExplain +
            +element.endowmentCredit! +
            +element.endowmentDebit! +
            +element.restrictedCredit! +
            +element.restrictedDebit! +
            +element.unrestrictedCredit! +
            +element.unrestrictedDebit!;
        });
      }
    });
    this.openingBalanceArray?.getRawValue().forEach((x) => {
      this.totalUnrestrictedCredit += +x.unrestrictedCredit;
      this.totalUnrestrictedDebit += +x.unrestrictedDebit;
      this.totalRestrictedCredit += +x.restrictedCredit;
      this.totalRestrictedDebit += +x.restrictedDebit;
      this.totalEndowmentCredit += +x.endowmentCredit;
      this.totalEndowmentDebit += +x.endowmentDebit;
    });
    this.setDataSource(this.openingBalanceArray);
    this.spinner.hide();
  }

  buildOrderItemsForm(item: any): FormGroup {
    const data = this.formBuilder.array(
      item.explainList.map((x) => this.formBuilder.control(x))
    );
    return this.formBuilder.group({
      id: item.id,
      account: item.accountId,
      unrestrictedDebit:
        item.unrestrictedDebit === 0 ? null : item.unrestrictedDebit,
      unrestrictedCredit:
        item.unrestrictedCredit === 0 ? null : item.unrestrictedCredit,
      restrictedDebit: item.restrictedDebit === 0 ? null : item.restrictedDebit,
      restrictedCredit:
        item.restrictedCredit === 0 ? null : item.restrictedCredit,
      endowmentDebit: item.endowmentDebit === 0 ? null : item.endowmentDebit,
      endowmentCredit: item.endowmentCredit === 0 ? null : item.endowmentCredit,
      isExplain: item.isExplain,
      accountTypeId: item.accountTypeId,
      isUpdated: false,
      isExplainSave: false,
      isFundExplain: item.isFundExplain,
      explainList: this.formBuilder.control(data.value),
    });
  }

  displayFn(account: any): string {
    return account && account.name ? account.name : '';
  }

  resetFormValidation(index: number, AmountTypeId?: number): void {
    this.openingBalanceArray.controls[index].clearValidators();
    this.openingBalanceArray.controls[index].get('account')?.clearValidators();
    this.openingBalanceArray.controls[index]
      .get('unrestrictedDebit')
      ?.clearValidators();
    this.openingBalanceArray.controls[index]
      .get('unrestrictedCredit')
      ?.clearValidators();
    this.openingBalanceArray.controls[index]
      .get('restrictedDebit')
      ?.clearValidators();
    this.openingBalanceArray.controls[index]
      .get('restrictedCredit')
      ?.clearValidators();
    this.openingBalanceArray.controls[index]
      .get('endowmentDebit')
      ?.clearValidators();
    this.openingBalanceArray.controls[index]
      .get('endowmentCredit')
      ?.clearValidators();
    this.openingBalanceArray.controls[index]
      .get('account')
      ?.setValidators([
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]);

    switch (AmountTypeId) {
      case OpeningBalanceExplain.EndowmentCredit:
        this.openingBalanceArray.controls[index]
          .get('endowmentCredit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.EndowmentDebit:
        this.openingBalanceArray.controls[index]
          .get('endowmentDebit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.RestrictedCredit:
        this.openingBalanceArray.controls[index]
          .get('restrictedCredit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.RestrictedDebit:
        this.openingBalanceArray.controls[index]
          .get('restrictedDebit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.UnrestrictedCredit:
        this.openingBalanceArray.controls[index]
          .get('unrestrictedCredit')
          ?.setValidators(Validators.required);
        break;
      case OpeningBalanceExplain.UnrestrictedDebit:
        this.openingBalanceArray.controls[index]
          .get('unrestrictedDebit')
          ?.setValidators(Validators.required);
        break;
      default:
        this.openingBalanceArray.controls[index]
          .get('endowmentCredit')
          ?.setValidators(Validators.required);
        this.openingBalanceArray.controls[index]
          .get('endowmentDebit')
          ?.setValidators(Validators.required);
        this.openingBalanceArray.controls[index]
          .get('restrictedCredit')
          ?.setValidators(Validators.required);
        this.openingBalanceArray.controls[index]
          .get('restrictedDebit')
          ?.setValidators(Validators.required);
        this.openingBalanceArray.controls[index]
          .get('unrestrictedCredit')
          ?.setValidators(Validators.required);
        this.openingBalanceArray.controls[index]
          .get('unrestrictedDebit')
          ?.setValidators(Validators.required);
        break;
    }

    this.openingBalanceArray.controls[index]
      .get('unrestrictedDebit')
      ?.updateValueAndValidity();
    this.openingBalanceArray.controls[index]
      .get('unrestrictedCredit')
      ?.updateValueAndValidity();
    this.openingBalanceArray.controls[index]
      .get('restrictedDebit')
      ?.updateValueAndValidity();
    this.openingBalanceArray.controls[index]
      .get('restrictedCredit')
      ?.updateValueAndValidity();
    this.openingBalanceArray.controls[index]
      .get('endowmentDebit')
      ?.updateValueAndValidity();
    this.openingBalanceArray.controls[index]
      .get('endowmentCredit')
      ?.updateValueAndValidity();
    this.openingBalanceArray.controls[index]
      .get('account')
      ?.updateValueAndValidity();
    this.openingBalanceArray.controls[index].updateValueAndValidity();
  }

  unrestrictedCreditChanges(index: any, event: any): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;
    if (event.target.value !== '') {
      if (event.keyCode !== 9) {
        this.totalUnrestrictedCredit = 0;

        formArray.controls[index].get('unrestrictedDebit')?.setValue(null);
        formArray.controls[index].get('unrestrictedDebit')?.clearValidators();
        formArray.controls[index]
          .get('account')
          ?.setValidators([Validators.required]);
        if (this.isEdit) {
          formArray.controls[index].get('isUpdated')?.setValue(true);
          formArray.controls[index].get('isExplainSave')?.setValue(true);
        }
        formArray.controls[index].updateValueAndValidity();
        this.resetFormValidation(
          index,
          OpeningBalanceExplain.UnrestrictedCredit
        );
      }
    } else {
      formArray.controls[index].get('unrestrictedCredit')?.setValue(null);
      formArray.controls[index].get('unrestrictedCredit')?.clearValidators();
      formArray.controls[index].get('unrestrictedDebit')?.clearValidators();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  unrestrictedDebitChanges(index: any, event: any): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;
    if (event.target.value !== '') {
      if (event.keyCode !== 9) {
        this.totalUnrestrictedDebit = 0;

        formArray.controls[index].get('unrestrictedCredit')?.setValue(null);
        formArray.controls[index].get('unrestrictedCredit')?.clearValidators();
        formArray.controls[index]
          .get('account')
          ?.setValidators([Validators.required]);
        if (this.isEdit) {
          formArray.controls[index].get('isUpdated')?.setValue(true);
          formArray.controls[index].get('isExplainSave')?.setValue(true);
        }
        formArray.controls[index].updateValueAndValidity();
        this.resetFormValidation(
          index,
          OpeningBalanceExplain.UnrestrictedDebit
        );
      }
    } else {
      formArray.controls[index].get('unrestrictedDebit')?.setValue(null);
      formArray.controls[index].get('unrestrictedCredit')?.clearValidators();
      formArray.controls[index].get('unrestrictedDebit')?.clearValidators();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  restrictedCreditChanges(index: any, event: any): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;
    if (event.target.value !== '') {
      if (event.keyCode !== 9) {
        this.totalRestrictedCredit = 0;

        formArray.controls[index].get('restrictedDebit')?.setValue(null);
        formArray.controls[index].get('restrictedDebit')?.clearValidators();
        formArray.controls[index]
          .get('account')
          ?.setValidators([Validators.required]);
        if (this.isEdit) {
          formArray.controls[index].get('isUpdated')?.setValue(true);
          formArray.controls[index].get('isExplainSave')?.setValue(true);
        }
        formArray.controls[index].updateValueAndValidity();
        this.resetFormValidation(index, OpeningBalanceExplain.RestrictedCredit);
      }
    } else {
      formArray.controls[index].get('restrictedCredit')?.setValue(null);
      formArray.controls[index].get('restrictedCredit')?.clearValidators();
      formArray.controls[index].get('restrictedDebit')?.clearValidators();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  restrictedDebitChanges(index: any, event: any): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    if (event.target.value !== '') {
      if (event.keyCode !== 9) {
        this.totalRestrictedDebit = 0;

        formArray.controls[index].get('restrictedCredit')?.setValue(null);
        formArray.controls[index].get('restrictedCredit')?.clearValidators();
        formArray.controls[index]
          .get('account')
          ?.setValidators([Validators.required]);
        if (this.isEdit) {
          formArray.controls[index].get('isUpdated')?.setValue(true);
          formArray.controls[index].get('isExplainSave')?.setValue(true);
        }
        formArray.controls[index].updateValueAndValidity();
        this.resetFormValidation(index, OpeningBalanceExplain.RestrictedDebit);
      }
    } else {
      formArray.controls[index].get('restrictedDebit')?.setValue(null);
      formArray.controls[index].get('restrictedCredit')?.clearValidators();
      formArray.controls[index].get('restrictedDebit')?.clearValidators();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  endowmentCreditChanges(index: any, event: any): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    if (event.target.value !== '') {
      if (event.keyCode !== 9) {
        this.totalEndowmentCredit = 0;

        formArray.controls[index].get('endowmentDebit')?.setValue(null);
        formArray.controls[index].get('endowmentDebit')?.clearValidators();
        formArray.controls[index]
          .get('account')
          ?.setValidators([Validators.required]);
        if (this.isEdit) {
          formArray.controls[index].get('isUpdated')?.setValue(true);
          formArray.controls[index].get('isExplainSave')?.setValue(true);
        }
        formArray.controls[index].updateValueAndValidity();
        this.resetFormValidation(index, OpeningBalanceExplain.EndowmentCredit);
      }
    } else {
      formArray.controls[index].get('endowmentCredit')?.setValue(null);
      formArray.controls[index].get('endowmentCredit')?.clearValidators();
      formArray.controls[index].get('endowmentDebit')?.clearValidators();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  endowmentDebitChanges(index: any, event: any): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;
    if (event.target.value !== '') {
      if (event.keyCode !== 9) {
        this.totalEndowmentDebit = 0;
        formArray.controls[index].get('endowmentCredit')?.setValue(null);
        formArray.controls[index].get('endowmentCredit')?.clearValidators();
        formArray.controls[index]
          .get('account')
          ?.setValidators([Validators.required]);
        if (this.isEdit) {
          formArray.controls[index].get('isUpdated')?.setValue(true);
          formArray.controls[index].get('isExplainSave')?.setValue(true);
        }
        formArray.controls[index].updateValueAndValidity();
        this.resetFormValidation(index, OpeningBalanceExplain.EndowmentDebit);
      }
    } else {
      formArray.controls[index].get('endowmentDebit')?.setValue(null);
      formArray.controls[index].get('endowmentCredit')?.clearValidators();
      formArray.controls[index].get('endowmentDebit')?.clearValidators();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  calAmount(): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;
    this.totalUnrestrictedCredit = 0;
    this.totalUnrestrictedDebit = 0;
    this.totalRestrictedCredit = 0;
    this.totalRestrictedDebit = 0;
    this.totalEndowmentCredit = 0;
    this.totalEndowmentDebit = 0;
    this.openingBalanceArray?.getRawValue().forEach((x) => {
      this.totalUnrestrictedDebit += +x.unrestrictedDebit;
      this.totalUnrestrictedCredit += +x.unrestrictedCredit;
      this.totalRestrictedDebit += +x.restrictedDebit;
      this.totalRestrictedCredit += +x.restrictedCredit;
      this.totalEndowmentDebit += +x.endowmentDebit;
      this.totalEndowmentCredit += +x.endowmentCredit;
    });
  }

  calDifferenceUnrestrictedDebitAmount(): any {
    this.unrestrictedTotalDifference =
      this.totalUnrestrictedDebit - this.totalUnrestrictedCredit;
    if (this.unrestrictedTotalDifference < 0) {
      this.unrestrictedTotalDifference = +this.unrestrictedTotalDifference
        .toString()
        .replace('-', '');
    }
    return this.unrestrictedTotalDifference;
  }

  calDifferenceRestrictedDebitAmount(): any {
    this.restrictedTotalDifference =
      this.totalRestrictedDebit - this.totalRestrictedCredit;
    if (this.restrictedTotalDifference < 0) {
      this.restrictedTotalDifference = +this.restrictedTotalDifference
        .toString()
        .replace('-', '');
    }
    return this.restrictedTotalDifference;
  }

  calDifferenceEndowmentDebitAmount(): any {
    this.endowmenTotalDifference =
      this.totalEndowmentDebit - this.totalEndowmentCredit;
    if (this.endowmenTotalDifference < 0) {
      this.endowmenTotalDifference = +this.endowmenTotalDifference
        .toString()
        .replace('-', '');
    }
    return this.endowmenTotalDifference;
  }

  setFormValidity(index: number): void {
    this.openingBalanceArray.controls[index].clearValidators();
    this.openingBalanceArray.controls[index]
      .get('account')
      ?.setValidators([Validators.required]);
    this.openingBalanceArray.controls[index].updateValueAndValidity();
  }

  getGroupAccountList(accountingDateId?: any): void {
    const journalAccounts = this.store.dispatch(new GetJournalAccounts());
    let openingBalanceBasedOnAPId: any;

    if (this.moduleId === Modules.OpeningBalance) {
      openingBalanceBasedOnAPId = this.store.dispatch(
        new GetOpeningBalanceList()
      );
    } else if (this.moduleId === Modules.EditTrialBalance) {
      openingBalanceBasedOnAPId = this.store.dispatch(
        new GetOpeningBalanceBasedOnAPId(accountingDateId)
      );
    } else if (this.moduleId === Modules.EditAccountProductionTrialBalance) {
      openingBalanceBasedOnAPId = this.store.dispatch(
        new GetAccountProductionTrialBalance(accountingDateId)
      );
    }

    forkJoin([journalAccounts, openingBalanceBasedOnAPId]).subscribe((res) => {
      this.accountList = this.store.selectSnapshot(
        AccountState.getJournalAccounts
      );
      this.accountGroupConstList = this.accountList;
      if (this.moduleId === Modules.EditAccountProductionTrialBalance) {
        this.openingBalanceBasedOnAPIdList = this.store.selectSnapshot(
          AccountProductionState.getJournalAccounts
        );
      } else {
        this.openingBalanceBasedOnAPIdList = this.store.selectSnapshot(
          OpeningBalanceState.getJournalAccounts
        );
      }

      if (this.moduleId === Modules.OpeningBalance) {
        this.getOpeningBalanceBasedOnDate(this.openingBalanceBasedOnAPIdList);
      } else {
        this.getOpeningBalanceBasedOnAPId(this.openingBalanceBasedOnAPIdList);
      }
    });
  }

  getOptionText(option) {
    return option?.name;
  }

  dataSubmit(): void {
    this.openingBalanceData.splice(0, this.openingBalanceData.length);

    if (this.openingBalanceArray.length > 0) {
      this.openingBalanceArray?.getRawValue().forEach((x) => {
        const param: OpeningBalance = {
          id: x.id,
          accountId: x.account.id,
          unrestrictedDebit: +x.unrestrictedDebit,
          unrestrictedCredit: +x.unrestrictedCredit,
          restrictedDebit: +x.restrictedDebit,
          restrictedCredit: +x.restrictedCredit,
          endowmentDebit: +x.endowmentDebit,
          endowmentCredit: +x.endowmentCredit,
        };

        this.openingBalanceData.push(param);

        this.openingBalanceAccountingDateData = {
          accountingPeriodId: this.accountingDateId,
          date: this.datepipe
            .transform(this.accountingDate, 'yyyy-MM-dd')
            ?.toString(),

          openingBalanceData: this.openingBalanceData,
        };
      });
    } else {
      this.openingBalanceAccountingDateData = {
        accountingPeriodId: this.accountingDateId,
        date: this.datepipe
          .transform(this.accountingDate, 'yyyy-MM-dd')
          ?.toString(),

        openingBalanceData: this.openingBalanceData,
      };
    }
  }

  isUpdated(isExit: boolean): void {
    if (this.formOpeningBalance.invalid) {
      this.openingBalanceArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
          this.commonService.onFailure(NotificationTextMessage.blankLineError);
        });
      });
      return;
    }
    if (this.isEdit) {
      const isExplain = this.openingBalanceArray
        ?.getRawValue()
        .filter((x) => x.isExplain === true);
      const isExplainSave = this.openingBalanceArray
        ?.getRawValue()
        .filter((x) => x.isExplainSave === true);

      let sumOfAllDrCr = 0;
      let sumOfTotalCrDrExplain = 0;
      this.openingBalanceArray?.getRawValue().filter((element, i) => {
        if (
          isExplain.length > 0 &&
          element.explainList !== null &&
          element.explainList !== undefined &&
          element.explainList.length > 0 &&
          element.isExplain === true
        ) {
          let total =
            +element.endowmentCredit! +
            +element.endowmentDebit! +
            +element.restrictedCredit! +
            +element.restrictedDebit! +
            +element.unrestrictedCredit! +
            +element.unrestrictedDebit!;

          sumOfAllDrCr = +sumOfAllDrCr + +total;

          element.explainList.forEach((element) => {
            let totalExplain =
              +element.endowmentCredit! +
              +element.endowmentDebit! +
              +element.restrictedCredit! +
              +element.restrictedDebit! +
              +element.unrestrictedCredit! +
              +element.unrestrictedDebit!;

            sumOfTotalCrDrExplain = +sumOfTotalCrDrExplain + +totalExplain;
          });
        }
      });

      if (isExplain.length > 0 && sumOfAllDrCr !== sumOfTotalCrDrExplain) {
        this.dialog
          .open(AddClosePopupComponent, {
            data: {
              moduleId: this.moduleId,
              isEdit: false,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.isDraftCheck(isExit);
            }
          });
      } else {
        this.isDraftCheck(isExit);
      }
    } else {
      this.isDraftCheck(isExit);
    }
  }

  isDraftCheck(isExit: boolean): void {
    if (
      this.totalUnrestrictedCredit.toFixed(2) !==
        this.totalUnrestrictedDebit.toFixed(2) ||
      this.totalRestrictedCredit.toFixed(2) !==
        this.totalRestrictedDebit.toFixed(2) ||
      this.totalEndowmentCredit.toFixed(2) !==
        this.totalEndowmentDebit.toFixed(2)
    ) {
      this.dialog
        .open(AddClosePopupComponent, {
          data: {
            moduleId: this.moduleId,
            isEdit: true,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.onSave(isExit);
          }
        });
    } else {
      this.onSave(isExit);
    }
  }

  onSave(isExit: boolean): void {
    if (this.customYearPickerDetails.rangeFormGroup.invalid) {
      return;
    } else {
      this.dataSubmit();
      if (this.moduleId === Modules.EditTrialBalance) {
        this.store
          .dispatch(new SaveTrialBalance(this.openingBalanceAccountingDateData))
          .subscribe((res) => {
            if (res.openingBalance.isTrialBalance) {
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
            this.commonService.isInitialValueChange = false;
            if (isExit && !this.isNewUser) {
              this.location.back();
            }
          });
      } else if (this.moduleId === Modules.EditAccountProductionTrialBalance) {
        this.store
          .dispatch(
            new SaveAccountProductionTrialBalance(
              this.openingBalanceAccountingDateData
            )
          )
          .subscribe((res) => {
            if (res.accountProduction.isTrialBalance) {
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
            this.commonService.isInitialValueChange = false;
            if (isExit && !this.isNewUser) {
              this.location.back();
            }
          });
      } else if (this.moduleId === Modules.OpeningBalance) {
        this.store
          .dispatch(
            new SaveOpeningBalance(this.openingBalanceAccountingDateData)
          )
          .subscribe((res) => {
            if (res.openingBalance.isOpeningBalance) {
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
            this.commonService.isInitialValueChange = false;
            if (isExit && !this.isNewUser) {
              this.location.back();
            }
          });
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    this.ngOnInit();
    this.calAmount();
    this.getGroupAccountList(this.accountingDateId);
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  resetAccountList(element: any, index: any): void {
    this.accountGroupList[index] = this.tempAccountGroupList[index];
    this.scrollIntoView(element);
  }

  onAccountSearch(event: any, index: number): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList[index]
    );
    this.accountGroupList[index] = list;
  }

  scrollIntoView(element) {
    if (element.value.account !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  countDifferenceOfDrCr(columnName: string) {
    columnName = columnName.replace('f2_', '').replace('f3_', '');
    let returnValue;

    if (columnName === this.displayOpeningBalanceColumns[0]) {
      returnValue = 'Difference';
    } else if (columnName === this.displayOpeningBalanceColumns[1]) {
      returnValue = '';
    } else if (columnName === this.displayOpeningBalanceColumns[2]) {
      if (this.totalUnrestrictedDebit < this.totalUnrestrictedCredit) {
        returnValue = this.numberPipe.transform(
          this.calDifferenceUnrestrictedDebitAmount()
        );
      } else {
        returnValue = this.numberPipe.transform('0');
      }
    } else if (columnName === this.displayOpeningBalanceColumns[3]) {
      if (this.totalUnrestrictedDebit > this.totalUnrestrictedCredit) {
        returnValue = this.numberPipe.transform(
          this.calDifferenceUnrestrictedDebitAmount()
        );
      } else {
        returnValue = this.numberPipe.transform('0');
      }
    } else if (columnName === this.displayOpeningBalanceColumns[4]) {
      if (this.totalRestrictedDebit < this.totalRestrictedCredit) {
        returnValue = this.numberPipe.transform(
          this.calDifferenceRestrictedDebitAmount()
        );
      } else {
        returnValue = this.numberPipe.transform('0');
      }
    } else if (columnName === this.displayOpeningBalanceColumns[5]) {
      if (this.totalRestrictedDebit > this.totalRestrictedCredit) {
        returnValue = this.numberPipe.transform(
          this.calDifferenceRestrictedDebitAmount()
        );
      } else {
        returnValue = this.numberPipe.transform('0');
      }
    } else if (columnName === this.displayOpeningBalanceColumns[6]) {
      if (this.totalEndowmentDebit < this.totalEndowmentCredit) {
        returnValue = this.numberPipe.transform(
          this.calDifferenceEndowmentDebitAmount()
        );
      } else {
        returnValue = this.numberPipe.transform('0');
      }
    } else if (columnName === this.displayOpeningBalanceColumns[7]) {
      if (this.totalEndowmentDebit > this.totalEndowmentCredit) {
        returnValue = this.numberPipe.transform(
          this.calDifferenceEndowmentDebitAmount()
        );
      } else {
        returnValue = this.numberPipe.transform('0');
      }
    } else if (columnName === this.displayOpeningBalanceColumns[8]) {
      returnValue = '';
    }
    return returnValue;
  }

  selectCustomDate(): void {
    this.triggerResetDetailList.next(true);
  }

  triggerDateChange(data: any): void {
    this.startDate = this.datepipe
      .transform(data.startDate, 'yyyy-MM-dd')
      ?.toString();
    this.endDate = this.datepipe
      .transform(data.endDate, 'yyyy-MM-dd')
      ?.toString();
    this.periodicDateList.forEach((element) => {
      if (
        this.datepipe.transform(element.fromDate, 'yyyy-MM-dd') ===
          this.startDate &&
        this.datepipe.transform(element.toDate, 'yyyy-MM-dd') === this.endDate
      ) {
        this.accountingDateId = element.id;
      }
    });
    if (
      this.moduleId === Modules.EditTrialBalance ||
      this.moduleId === Modules.EditAccountProductionTrialBalance
    ) {
      this.getGroupAccountList(this.accountingDateId);
    }
  }

  dateChange(data): void {
    this.accountingDate = moment(data.value.valueOf()).format('YYYY-MM-DD');
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
  }

  getOpeningBalanceList(): void {
    this.store.dispatch(new GetOpeningBalanceList()).subscribe((res) => {
      if (res) {
      }
    });
  }

  getOpeningBalanceBasedOnAPId(openingBalanceBasedOnAPIdList): void {
    this.accountingDateId = openingBalanceBasedOnAPIdList.accountingPeriodId;
    if (openingBalanceBasedOnAPIdList.openingBalanceData.length > 0) {
      this.totalUnrestrictedCredit = 0;
      this.totalUnrestrictedDebit = 0;
      this.totalRestrictedCredit = 0;
      this.totalRestrictedDebit = 0;
      this.totalEndowmentCredit = 0;
      this.totalEndowmentDebit = 0;
      this.sumOfTotalCrDrExplain = 0;
      this.isEdit = true;
      this.accountGroupList.push(this.accountGroupConstList);
      this.tempAccountGroupList.push(this.accountGroupConstList);
      this.setDropDown(openingBalanceBasedOnAPIdList.openingBalanceData, false);
    } else {
      this.accountGroupList = [];
      this.tempAccountGroupList = [];
      this.selectedIds = [];
      this.accountGroupList.push(this.accountGroupConstList);
      this.tempAccountGroupList.push(this.accountGroupConstList);
      this.totalUnrestrictedCredit = 0;
      this.totalUnrestrictedDebit = 0;
      this.totalRestrictedCredit = 0;
      this.totalRestrictedDebit = 0;
      this.totalEndowmentCredit = 0;
      this.totalEndowmentDebit = 0;
      this.sumOfTotalCrDrExplain = 0;
      this.openingBalanceArray = this.formOpeningBalance.get(
        'openingBalanceArray'
      ) as UntypedFormArray;

      this.openingBalanceArray.clear();
      this.setOpeningBalanceForm(true);
    }
  }

  getOpeningBalanceBasedOnDate(openingBalanceBasedOnAPIdList): void {
    this.accountingDate = openingBalanceBasedOnAPIdList.date;
    if (openingBalanceBasedOnAPIdList.openingBalanceData.length > 0) {
      this.totalUnrestrictedCredit = 0;
      this.totalUnrestrictedDebit = 0;
      this.totalRestrictedCredit = 0;
      this.totalRestrictedDebit = 0;
      this.totalEndowmentCredit = 0;
      this.totalEndowmentDebit = 0;
      this.sumOfTotalCrDrExplain = 0;
      this.isEdit = true;
      this.accountGroupList.push(this.accountGroupConstList);
      this.tempAccountGroupList.push(this.accountGroupConstList);
      this.setDropDown(openingBalanceBasedOnAPIdList.openingBalanceData, false);
    } else {
      this.accountGroupList = [];
      this.tempAccountGroupList = [];
      this.selectedIds = [];
      this.accountGroupList.push(this.accountGroupConstList);
      this.tempAccountGroupList.push(this.accountGroupConstList);
      this.totalUnrestrictedCredit = 0;
      this.totalUnrestrictedDebit = 0;
      this.totalRestrictedCredit = 0;
      this.totalRestrictedDebit = 0;
      this.totalEndowmentCredit = 0;
      this.totalEndowmentDebit = 0;
      this.sumOfTotalCrDrExplain = 0;
      this.openingBalanceArray = this.formOpeningBalance.get(
        'openingBalanceArray'
      ) as UntypedFormArray;

      this.openingBalanceArray.clear();
      this.setOpeningBalanceForm(true);
    }
  }
}
