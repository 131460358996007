<div class="mat-dialog-wrapper bank-reconcillation">
  <mat-dialog-content>
    <app-receipt-details
      (amountReceived)="onAmountChange($event)"
      [triggerCustomer]="triggerCustomer"
      [triggerReceiptDetail]="triggerReceiptDetail"
      [getModuleId]="getModuleId"
      [amountReceivedValue]="amountReceivedValue"
    ></app-receipt-details>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-buttons
      [getModuleId]="getModuleId"
      (triggerOnSaveClick)="onSave()"
      (triggerOnCancelClick)="onCancel()"
      [saveButtonShow]="true"
    >
    </app-buttons>
  </mat-dialog-actions>
</div>
