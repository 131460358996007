import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankReconciliationModel, MainListParameters } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class BankReconciliationService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getBankReconciliation(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BankReconcillation/list`,
      this.commonService.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getAllBankAccount(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}BankAccount/GetAllBankAccount`
    );
  }

  saveBankReconciliation(
    params: Array<BankReconciliationModel>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BankReconcillation/save`,
      this.commonService.trimObjectSpace(JSON.stringify(params)),
      headers
    );
  }

  getBankDetail(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}BankAccount/getBankDetail`
    );
  }
}
