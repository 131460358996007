import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import { FundTransferState, GetTransferToFund } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-fund-transfer',
  templateUrl: './add-fund-transfer.component.html',
  styleUrls: ['./add-fund-transfer.component.scss'],
})
export class AddFundTransferComponent implements OnInit {
  fundToTransList: SideListModel[];
  tempfundToTransList: SideListModel[];

  fundfromTransList: SideListModel[];
  tempfundfromTransList: SideListModel[];

  fundTransferForm: FormGroup;

  @Input() triggerEditData: Observable<any>;
  @Input() moduleId: number;
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  periodicDate: any;

  constructor(
    private store: Store,
    private globalComponent: GlobalComponent,
    public commonService: CommonService,
    public highlightRow: HighlightRow
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();
    this.getTransferToFund();

    this.triggerEditData?.subscribe((data) => {
      this.editFundTransfer(data);
    });

    this.fundTransferForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.fundTransferForm.touched;
    });
  }

  editFundTransfer(data): void {
    this.fundTransferForm.patchValue({
      transferFrom: data.fromFundId,
      transferDate: data.transferDate,
      transferTo: data.toFundId,
      amount: data.amount,
      note: data.note,
    });
  }

  setForm(): void {
    this.fundTransferForm = new FormGroup(
      {
        transferFrom: new FormControl('', [
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]),
        transferDate: new FormControl(
          new Date(),
          datePickerValidator(this.periodicDate)
        ),
        transferTo: new FormControl('', [
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]),
        amount: new FormControl(''),
        note: new FormControl(''),
      },
      {
        validators: this.commonService.compareFields(
          'transferFrom',
          'transferTo',
          this.moduleId
        ),
      }
    );
  }

  getTransferToFund(): void {
    this.store
      .dispatch(new GetTransferToFund())
      .pipe(
        tap(() => {
          this.fundToTransList = this.tempfundToTransList =
            this.store.selectSnapshot(FundTransferState.getTransferToFund)!;

          this.fundfromTransList = this.tempfundfromTransList =
            this.store.selectSnapshot(FundTransferState.getTransferToFund)!;

          this.fundTransferForm.controls.transferFrom.setValue(
            this.fundfromTransList[0].id
          );

          this.fundTransferForm.controls.transferTo.setValue(
            this.fundToTransList[0].id
          );
        })
      )
      .subscribe();
  }
}
