import { Component, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexFill,
  ApexDataLabels,
  ApexLegend,
} from 'ng-apexcharts';

export type donationChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
};
@Component({
  selector: 'app-dashboard-donation-type',
  templateUrl: './dashboard-donation-type.component.html',
  styleUrls: ['./dashboard-donation-type.component.scss'],
})
export class DashboardDonationTypeComponent {
  @ViewChild('chart') chart: ChartComponent;
  public donationChartOptions: Partial<donationChartOptions>;

  constructor() {
    this.donationChartOptions = {
      series: [70, 30],
      chart: {
        width: 400,
        height: 400,
        type: 'pie',
        offsetX: -100,
        offsetY: 0,
      },
      fill: {
        colors: ['#7B76D3', '#F4C64D'],
      },
      legend: {
        position: 'right',
        horizontalAlign: 'right',
        offsetX: -30,
        offsetY: 20,
        markers: {
          fillColors: ['#7B76D3', '#F4C64D'],
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: [
        'Donation in Cash - 60%  </br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2,400.00',
        'Donation in kind - 20%  </br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 240.00',
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
}
