<div class="content">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      Fund Details
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCancel()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <app-report-detail-list-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-report-detail-list-header>

    <p class="fw-bold mb-1 font-size-20">{{ reportText }}</p>

    <div
      class="mat-table-wrapper"
      [ngClass]="[
        moduleId === moduleEnum.CustomerList
          ? 'mat-table-wrapper-report-details'
          : '',
        reportText ? 'mat-table-wrapper-header-text' : '',
        showPaginator
          ? 'mat-table-wrapper-pagination'
          : 'mat-table-wrapper-pagination-full',
        (totalRecord$ | async) <= 10 ? 'mat-table-wrapper-full-view' : ''
      ]"
    >
      <table
        aria-describedby="main-list"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="dataSource"
      >
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
            <div class="d-flex align-items-center">
              <span mat-sort-header>
                {{ col.name }}
              </span>
            </div>
          </th>

          <td mat-cell *matCellDef="let element; let rowIndex = index">
            <div
              class="d-flex align-items-center"
              [ngClass]="
                colIndex === (columns$ | async).length - 1
                  ? 'justify-space-between'
                  : ''
              "
            >
              <div class="clamp">
                <span
                  *ngIf="
                    col.dataType === dataType.String ||
                    col.dataType === dataType.Bool
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  {{ element[colIndex].rowData }}
                </span>

                <span
                  *ngIf="
                    col.dataType === dataType.URL &&
                    element[colIndex].id !== '' &&
                    element[colIndex].moduleId !== 0
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  <a
                    *ngIf="element[colIndex].moduleId > 0"
                    class="link fw-bold"
                    (click)="
                      redirect(element[colIndex].id, element[colIndex].moduleId)
                    "
                  >
                    {{ element[colIndex].rowData }}
                  </a>
                  <span
                    *ngIf="
                      element[colIndex].moduleId <= 0 ||
                      element[colIndex].moduleId === undefined
                    "
                  >
                    {{ element[colIndex].rowData }}
                  </span>
                </span>

                <span
                  *ngIf="
                    col.dataType === dataType.URL &&
                    element[colIndex].id === '' &&
                    element[colIndex].moduleId === 0
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  {{ element[colIndex].rowData }}
                </span>

                <span
                  *ngIf="col.dataType === dataType.DateTime"
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  {{ element[colIndex].rowData | date: "dd-LLL-yyyy" }}</span
                >

                <span
                  *ngIf="
                    (col.dataType === dataType.Int32 ||
                      col.dataType === dataType.Currency) &&
                    element[colIndex].rowData !== ''
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  £ {{ element[colIndex].rowData | numberPipe }}</span
                >

                <span *ngIf="col.dataType === dataType.Blob">
                  <button
                    mat-button
                    (click)="downloadFile(element[colIndex].rowData)"
                    class="fw-bold text-primary"
                    disableRipple
                  >
                    <mat-icon>file_download</mat-icon>
                  </button></span
                >
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnsNames$ | async"></tr>
      </table>
      <div
        *ngIf="dataSource.data.length === 0 && noDataFound"
        class="text-align-center mt-1"
      >
        No records found
      </div>
      <div
        class="paginator-wrapper"
        *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async) > 0"
      >
        <app-custom-paginator
          *ngIf="(totalRecord$ | async) > 10"
          [length]="totalRecord$ | async"
          [listParameters]="listParameters"
          (pageChanged)="pageChanged($event)"
          (pageSizeVal)="pageSizeVal($event)"
          (togglePaginator)="togglePaginator($event)"
          [triggerPaginationChange]="triggerPaginationChange"
        >
        </app-custom-paginator>
      </div>

      <app-vat-report
        [listParameters]="listParameters"
        (triggerDataFromVatReport)="getDataFromVatReport($event)"
        *ngIf="moduleId === moduleEnum.VAT"
      ></app-vat-report>
    </div>
  </div>
</div>
