<div class="content-body content-body-scroll">
  <div class="alert-message" *ngIf="isViewPermission$ | async">
    <mat-icon class="material-icons-outlined">error_outline</mat-icon>
    {{ commonNotificationText.ViewOnlyAccessMessage }}
  </div>
  <div class="stepper-wrapper">
    <mat-horizontal-stepper linear #horizontalStepper>
      <mat-step label="1" [completed]="isFirstStepDone">
        <ng-template matStepLabel>Set-up VAT Period</ng-template>
        <app-add-submit-vat
          #submitVatDetails
          [triggerEditData]="triggerEditData"
        ></app-add-submit-vat>
        <div class="action-wrapper">
          <div class="content-body"></div>
        </div>
      </mat-step>
      <mat-step
        label="2"
        label="Include/Exclude Transactions "
        [completed]="isSecondStepDone"
      >
        <ng-template matStepLabel>Include/Exclude Transactions</ng-template>
        <app-include-exclude-transaction
          *ngIf="isFirstStepDone"
          #includeExcludeTransactionDetails
        ></app-include-exclude-transaction>
        <div class="action-wrapper">
          <div class="content-body"></div>
        </div>
      </mat-step>
      <mat-step label="3" [completed]="isThirdStepDone">
        <ng-template matStepLabel>Review Return</ng-template>
        <app-review-return
          #mainListDetails
          [submitVatId]="moduleId"
          *ngIf="isSecondStepDone"
        ></app-review-return>
        <div class="action-wrapper">
          <div class="content-body"></div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
    <div class="content-body pt-0" *ngIf="isSecondStepDone">
      <mat-checkbox (change)="onCheckBoxChanges($event)"> </mat-checkbox>
      A legal declaration that the information is true and complete. A false
      declaration can result in prosecution.
    </div>
  </div>
  <div class="action-wrapper">
    <div class="content-body">
      <div class="button-container">
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="onPrevClick(horizontalStepper)"
        >
          Prev
        </button>
        <button
          *ngIf="stepper?.selectedIndex === 0 || !isFirstStepDone"
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          [disabled]="isViewPermission$ | async"
          (click)="onNextClick(false)"
        >
          Save & Close
        </button>
        <button
          *ngIf="stepper?.selectedIndex !== 2 || !isSecondStepDone"
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          [disabled]="isViewPermission$ | async"
          (click)="onNextClick(true)"
        >
          Save & Next
        </button>
        <button
          *ngIf="
            isSecondStepDone && capiumButtonShow && stepper.selectedIndex === 2
          "
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          [disabled]="isDisable"
          (click)="onSave(vatReturnStatus.SubmitToCapium)"
        >
          Submit To Capium
        </button>
        <button
          *ngIf="hmrcButtonShow && stepper.selectedIndex === 2"
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="onSave(vatReturnStatus.SubmitToHMRC)"
        >
          Submit To HMRC
        </button>
        <button
          class="action-button secondary-button"
          type="button"
          mat-button
          disableRipple
          (click)="cancelClick(false)"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
