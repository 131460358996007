<div class="vat-Settings content-body-wrapper">
  <div class="content-body">
    <p
      class="header-text d-flex justify-space-between align-items-center"
      *ngIf="isHeaderVisible"
    >
      <span> Include Data </span>
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <app-submit-vat-header
      *ngIf="isHeaderVisible"
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-submit-vat-header>
    <div
      class="mat-table-wrapper"
      [ngClass]="[
        !isHeaderVisible ? 'mat-table-wrapper-full' : '',
        showPaginator && isHeaderVisible
          ? 'mat-table-wrapper-pagination'
          : 'mat-table-wrapper-pagination-full'
      ]"
    >
      <table
        aria-describedby="main-list"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="dataSource"
      >
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
            <div class="d-flex align-items-center">
              <span *ngIf="checkBoxValue !== 0">
                <mat-checkbox
                  [(ngModel)]="isAllSelected"
                  (change)="selectAll($event)"
                  *ngIf="colIndex === 1"
                >
                </mat-checkbox>
              </span>
              <span mat-sort-header [disabled]="!col.name">
                {{ col.name }}
              </span>
            </div>
          </th>

          <td mat-cell *matCellDef="let element; let i = index">
            <div
              class="d-flex align-items-center button-wrapper"
              [ngClass]="
                colIndex === (columns$ | async).length - 1
                  ? 'justify-space-between'
                  : ''
              "
            >
              <span
                class="fw-bold"
                *ngIf="element[element.length - 1] === 'True' && colIndex === 1"
              >
                {{ element[0] }}
              </span>
              <span
                *ngIf="element[element.length - 1] === 'False'"
                class="d-flex align-items-center"
              >
                <mat-checkbox
                  *ngIf="colIndex === 1"
                  [(ngModel)]="element.isSelected"
                  (change)="onCheckBoxSelected(element, $event)"
                >
                </mat-checkbox>
                <div class="clamp">
                  <span
                    *ngIf="col.dataType === dataType.String"
                    title="{{ element[colIndex] }}"
                  >
                    {{ element[colIndex] }}</span
                  >

                  <span
                    *ngIf="col.dataType === dataType.Bool"
                    title="{{ element[colIndex] }}"
                    [ngClass]="
                      element[colIndex] === 'Active'
                        ? 'status-active'
                        : 'status-archived'
                    "
                  >
                    {{ element[colIndex] }}</span
                  >

                  <span
                    *ngIf="col.dataType === dataType.DateTime"
                    title="{{ element[colIndex] }}"
                  >
                    {{ element[colIndex] | date: "dd-LLL-yyyy" }}</span
                  >

                  <span
                    *ngIf="
                      col.dataType === dataType.Int32 ||
                      col.dataType === dataType.Currency
                    "
                    title="{{ element[colIndex] | numberPipe }}"
                  >
                    £ {{ element[colIndex] | numberPipe }}</span
                  >

                  <span *ngIf="col.dataType === dataType.Blob">
                    <button
                      mat-button
                      (click)="onAddClick(element)"
                      class="fw-bold text-primary"
                      disableRipple
                    >
                      <mat-icon>add_circle_outline</mat-icon>
                    </button></span
                  >
                </div>
              </span>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnsNames$ | async"></tr>
      </table>
    </div>
  </div>
  <div
    class="paginator-wrapper"
    *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async) > 0"
  >
    <app-custom-paginator
      *ngIf="(totalRecord$ | async) > 10"
      [length]="totalRecord$ | async"
      [listParameters]="listParameters"
      (pageChanged)="pageChanged($event)"
      (pageSizeVal)="pageSizeVal($event)"
      (togglePaginator)="togglePaginator($event)"
      [triggerPaginationChange]="triggerPaginationChange"
    >
    </app-custom-paginator>
  </div>
  <div class="action-wrapper" *ngIf="isRecordSelected">
    <div class="content-body">
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onSave()"
      >
        Save
      </button>
    </div>
  </div>
</div>
