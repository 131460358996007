import { Guid } from 'guid-typescript';
import { SideListModel } from '../common/side-list';

export class AccountModel {
  moduleId?: number;
  id?: Guid;
  chartOfAccountGroupId?: number;
  chartOfAccountTypeId?: number;
  fundTypeId?: number;
  name?: string;
  code?: string;
  tradingName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  address3?: string;
  countryId?: Guid;
  postalCode?: string;
  phone?: string;
  email?: string;
  mobileNumber?: string;
  fax?: string;
  openingBalance?: number;
  totalDebit?: number;
  totalCredit?: number;
  closingBalance?: number;
  vatRegistrationNumber?: string;
  bankAccountNumber?: string;
  bankAccountName?: string;
  bankBranchCode?: string;
  bankAccountIBANCode?: string;
  taxPayerStartDate?: string;
  taxPayerEndDate?: string;
  membershipStatus?: string;
  membershipStartDate?: string;
  membershipEndDate?: string;
  membershipNote?: string;
  termId?: Guid;
  cISEnabled?: boolean;
  cISDetail?: CISDetailModel;
  giftAidAttachment?: Array<string>;
  accountTypeId?: number;
  bankAccountTypeId?: number;
  currencyId?: number;
}

export class CISDetailModel {
  businessTypeId: number;
  tradingName: string;
  subContractorUTR: string;
  nINumber?: string;
  companyNumber: string;
  workReference: string;
  status: boolean;
  verificationNumber: string;
  taxTreatment: string;
  lastVerified?: string;
}

export class ChartOfAccountListModel {
  chartOFAccountGroup: SideListModel;
  chartOFAccountType: SideListModel;
  chartOFAccounts: Array<SideListModel>;
}
