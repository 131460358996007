import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FundTransferModel, SideListModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class FundTransferService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createFundTransfer(FundTransfer: FundTransferModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FundTransfer/save`,
      this.commonService.trimObjectSpace(JSON.stringify(FundTransfer)),
      headers
    );
  }

  getDataByFundTransferId(invoiceId: Guid): Observable<FundTransferModel> {
    return this.http.get<FundTransferModel>(
      `${environment.apiVersionUrl}FundTransfer/get/${invoiceId}`
    );
  }

  deleteFundTransfer(fundTrasferIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(fundTrasferIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}FundTransfer/delete`,
      options
    );
  }

  copyFundTransfer(fundTransferIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}FundTransfer/copyFundTransfer`,
      this.commonService.trimObjectSpace(JSON.stringify(fundTransferIds)),
      headers
    );
  }

  archiveAndRestoreFundTransfer(
    fundTransferIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}FundTransfer/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(fundTransferIds)),
      options
    );
  }

  getTransferToFund(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Fund/all`
    );
  }
}
