import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { Notes } from '@app/core/Enum/note';
import {
  GetNoteParamModel,
  GlobalComponent,
  NoteDataModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  GetAllNote,
  GetNotesData,
  MenuState,
  SaveNoteData,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { Note1Component } from '../note-1/note-1.component';
import { Note10Component } from '../note10/note10.component';
import { Note11Component } from '../note11/note11.component';
import { Note12Component } from '../note12/note12.component';
import { Note13Component } from '../note13/note13.component';
import { Note15Component } from '../note15/note15.component';
import { Note14Component } from '../note14/note14.component';
import { Note17Component } from '../note17/note17.component';
import { Note16Component } from '../note16/note16.component';
import { Note18Component } from '../note18/note18.component';
import { Note19Component } from '../note19/note19.component';
import { Note2Component } from '../note2/note2.component';
import { Note20Component } from '../note20/note20.component';
import { Note21Component } from '../note21/note21.component';
import { Note22Component } from '../note22/note22.component';
import { Note23Component } from '../note23/note23.component';
import { Note24Component } from '../note24/note24.component';
import { Note25Component } from '../note25/note25.component';
import { Note26Component } from '../note26/note26.component';
import { Note27Component } from '../note27/note27.component';
import { Note28Component } from '../note28/note28.component';
import { Note29Component } from '../note29/note29.component';
import { Note30Component } from '../note30/note30.component';
import { Note3Component } from '../note3/note3.component';
import { Note4Component } from '../note4/note4.component';
import { Note5Component } from '../note5/note5.component';
import { Note7Component } from '../note7/note7.component';
import { Note6Component } from '../note6/note6.component';
import { Note9Component } from '../note9/note9.component';
import { Note8Component } from '../note8/note8.component';
import { Note31Component } from '../note31/note31.component';
import { Note32Component } from '../note32/note32.component';
import { Note33Component } from '../note33/note33.component';
import { Note34Component } from '../note34/note34.component';
import { Note35Component } from '../note35/note35.component';
import { Note36Component } from '../note36/note36.component';
import { Note37Component } from '../note37/note37.component';
import { Note38Component } from '../note38/note38.component';
import { Note39Component } from '../note39/note39.component';
import { Note40Component } from '../note40/note40.component';
import { Note41Component } from '../note41/note41.component';
import { Note42Component } from '../note42/note42.component';
import { ConfirmationBoxComponent, Note43Component } from '@app/modules';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {
  dataSource: any[] = [];
  tempDataSource: any[] = [];
  periodicDateList: any;

  headerText = 'Note 1';
  searchText = '';
  dataSourceSelectedLength = 0;

  notesModuleId = Notes.Note1;
  moduleId = Modules.Notes;
  thisperioicbuttonDisaply = false;

  ids: Array<any>;
  jsonObject: any;
  jsonObjectForCheckbox: any;
  jsonPercentageData: any;
  noteForm: FormGroup;
  displayedColumns: string[] = ['name'];

  @Select(CommonState.periodicDate)
  periodicDate$: Observable<Guid>;

  routingPath = RoutingPath;
  noteData: NoteDataModel;
  notesEnum = Notes;

  isAllSelected = false;

  notesId = Guid.EMPTY as unknown as Guid;
  periodicId = Guid.EMPTY as unknown as Guid;

  triggerSave: Subject<any> = new Subject<any>();

  triggerEditData: Subject<any> = new Subject<any>();

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  @ViewChild(Note1Component, { static: true })
  note1Details;

  @ViewChild(Note2Component, { static: true })
  note2Details;

  @ViewChild(Note3Component, { static: true })
  note3Details;

  @ViewChild(Note4Component, { static: true })
  note4Details;

  @ViewChild(Note5Component, { static: true })
  note5Details;

  @ViewChild(Note6Component, { static: true })
  note6Details;

  @ViewChild(Note7Component, { static: true })
  note7Details;

  @ViewChild(Note8Component, { static: true })
  note8Details;

  @ViewChild(Note9Component, { static: true })
  note9Details;

  @ViewChild(Note10Component, { static: true })
  note10Details;

  @ViewChild(Note11Component, { static: true })
  note11Details;

  @ViewChild(Note12Component, { static: true })
  note12Details;

  @ViewChild(Note13Component, { static: true })
  note13Details;

  @ViewChild(Note14Component, { static: true })
  note14Details;

  @ViewChild(Note15Component, { static: true })
  note15Details;

  @ViewChild(Note16Component, { static: true })
  note16Details;

  @ViewChild(Note17Component, { static: true })
  note17Details;

  @ViewChild(Note18Component, { static: true })
  note18Details;

  @ViewChild(Note19Component, { static: true })
  note19Details;

  @ViewChild(Note20Component, { static: true })
  note20Details;

  @ViewChild(Note21Component, { static: true })
  note21Details;

  @ViewChild(Note22Component, { static: true })
  note22Details;

  @ViewChild(Note23Component, { static: true })
  note23Details;

  @ViewChild(Note24Component, { static: true })
  note24Details;

  @ViewChild(Note25Component, { static: true })
  note25Details;

  @ViewChild(Note26Component, { static: true })
  note26Details;

  @ViewChild(Note27Component, { static: true })
  note27Details;

  @ViewChild(Note28Component, { static: true })
  note28Details;

  @ViewChild(Note29Component, { static: true })
  note29Details;

  @ViewChild(Note30Component, { static: true })
  note30Details;

  @ViewChild(Note31Component, { static: true })
  note31Details;

  @ViewChild(Note32Component, { static: true })
  note32Details;

  @ViewChild(Note33Component, { static: true })
  note33Details;

  @ViewChild(Note34Component, { static: true })
  note34Details;

  @ViewChild(Note35Component, { static: true })
  note35Details;

  @ViewChild(Note36Component, { static: true })
  note36Details;

  @ViewChild(Note37Component, { static: true })
  note37Details;

  @ViewChild(Note38Component, { static: true })
  note38Details;

  @ViewChild(Note39Component, { static: true })
  note39Details;

  @ViewChild(Note40Component, { static: true })
  note40Details;

  @ViewChild(Note41Component, { static: true })
  note41Details;

  @ViewChild(Note42Component, { static: true })
  note42Details;

  @ViewChild(Note43Component, { static: true })
  note43Details;

  showSearchBar = false;
  shouldHideButton = false;
  @ViewChild('searchText') searchField: ElementRef;
  constructor(
    private store: Store,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.bindSideList();
    this.setPeriodDate();
  }

  toggleSearchBar(): void {
    this.showSearchBar = !this.showSearchBar;

    if (this.showSearchBar) {
      setTimeout(() => {
        this.searchField.nativeElement.focus();
      }, 0);
    }
  }

  onSearch(): void {
    this.dataSource = [];
    this.tempDataSource.forEach((item) => {
      if (
        item.name
          .trim()
          .toLowerCase()
          .includes(this.searchText.trim().toLowerCase())
      ) {
        this.dataSource.push(item);
      }
    });
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.onSearch();
    }
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    this.noteForm.controls.accountingPeriod.setValue(
      this.periodicDateList[0].id
    );
    this.editNotes();
  }

  setForm(): void {
    this.noteForm = new FormGroup({
      accountingPeriod: new FormControl(''),
    });
  }

  bindSideList(): void {
    this.spinner.show();
    this.store.dispatch(new GetAllNote()).subscribe((res) => {
      this.spinner.hide();
      this.dataSource = res.note.noteSideList.filter(
        (i) =>
          i.id !== +Notes.Note44 &&
          i.id !== +Notes.Note45 &&
          i.id !== +Notes.Note46
      );

      this.tempDataSource = this.dataSource;
    });
  }

  noteClick(element: any): void {
    this.notesModuleId = element.id;
    this.getHeaderName();
    this.editNotes();
  }

  getHeaderName(): void {
    switch (this.notesModuleId) {
      case Notes.Note1:
        this.headerText = 'Basis of preparation';
        break;
      case Notes.Note2:
        this.headerText = 'Income from Donations and Legacies';
        break;
      case Notes.Note3:
        this.headerText = 'Income from Charitable Activities';
        break;
      case Notes.Note4:
        this.headerText = 'Income from Other Trading Activities';
        break;
      case Notes.Note5:
        this.headerText = 'Income from Investments';
        break;
      case Notes.Note6:
        this.headerText = 'Separate Material Item of Income';
        break;
      case Notes.Note7:
        this.headerText = 'Other Income';
        break;
      case Notes.Note8:
        this.headerText = 'Analysis of Receipts of Government Grants';
        break;
      case Notes.Note9:
        this.headerText = 'Donated Goods, Facilities and Services';
        break;
      case Notes.Note10:
        this.headerText = 'Expenditure on Raising Funds';
        break;
      case Notes.Note11:
        this.headerText = 'Expenditure on Charitable Activities';
        break;
      case Notes.Note12:
        this.headerText = 'Support Costs';
        break;
      case Notes.Note13:
        this.headerText = 'Finance Costs';
        break;
      case Notes.Note14:
        this.headerText = 'Governance Costs';
        break;
      case Notes.Note15:
        this.headerText = 'Separate Material Item of Expense';
        break;
      case Notes.Note16:
        this.headerText = 'Other Expenditure';
        break;
      case Notes.Note17:
        this.headerText = 'Extraordinary Items';
        break;
      case Notes.Note18:
        this.headerText = 'Funds Received as Agent';
        break;
      case Notes.Note19:
        this.headerText = 'Analysis of Support Costs';
        break;
      case Notes.Note20:
        this.headerText = 'Details of certain Items of Expenditure';
        break;
      case Notes.Note21:
        this.headerText = 'Employee’s Emoluments';
        break;
      case Notes.Note22:
        this.headerText = 'Defined contribution Pension Scheme';
        break;
      case Notes.Note23:
        this.headerText = 'Analysis of Grants Paid';
        break;
      case Notes.Note24:
        this.headerText = 'Intangible Fixed Assets';
        break;
      case Notes.Note25:
        this.headerText = 'Tangible Fixed Assets';
        break;
      case Notes.Note26:
        this.headerText = 'Heritage Assets';
        break;
      case Notes.Note27:
        this.headerText = 'Investments';
        break;
      case Notes.Note28:
        this.headerText = 'Stocks';
        break;
      case Notes.Note29:
        this.headerText = 'Debtors: Amounts falling due within one year';
        break;
      case Notes.Note30:
        this.headerText = 'Debtors: Amounts falling due after one year';
        break;
      case Notes.Note31:
        this.headerText = 'Creditors: Amounts falling due within one year';
        break;
      case Notes.Note32:
        this.headerText = 'Creditors: Amounts falling due after one year';
        break;
      case Notes.Note33:
        this.headerText = 'Deferred income';
        break;
      case Notes.Note34:
        this.headerText = 'Provisions for liabilities and charges';
        break;
      case Notes.Note35:
        this.headerText =
          'Other disclosures for debtors, creditors and other basic financial instruments';
        break;
      case Notes.Note36:
        this.headerText = 'Contingent liabilities and contingent assets';
        break;
      case Notes.Note37:
        this.headerText = 'Cash at bank and in hand';
        break;
      case Notes.Note38:
        this.headerText = 'Fair value of assets and liabilities';
        break;
      case Notes.Note39:
        this.headerText = 'Events after the end of the reporting period';
        break;
      case Notes.Note40:
        this.headerText = 'Charity funds';
        break;
      case Notes.Note41:
        this.headerText = 'Transactions with trustees and related parties';
        break;
      case Notes.Note42:
        this.headerText = 'Additional Disclosures';
        break;
      case Notes.Note43:
        this.headerText = 'Reference and Administrative Information';
        break;
    }
  }

  setNotes1Data(): void {
    const arrayObj: any = {
      textbox1: this.note1Details.note1Form.controls.textbox1.value,
      textbox2: this.note1Details.note1Form.controls.textbox2.value,
      textbox3: this.note1Details.note1Form.controls.textbox3.value,
      textbox4: this.note1Details.note1Form.controls.textbox4.value,
      textbox5: this.note1Details.note1Form.controls.textbox5.value,
      textbox6: this.note1Details.note1Form.controls.textbox6.value,
      textbox7: this.note1Details.note1Form.controls.textbox7.value,
      textbox8: this.note1Details.note1Form.controls.textbox8.value,
      textbox9: this.note1Details.note1Form.controls.textbox9.value,
      textbox10: this.note1Details.note1Form.controls.textbox10.value,
      textbox11: this.note1Details.note1Form.controls.textbox11.value,
      textbox12: this.note1Details.note1Form.controls.textbox12.value,
      textbox13: this.note1Details.note1Form.controls.textbox13.value,
      textbox14: this.note1Details.note1Form.controls.textbox14.value,
      textbox15: this.note1Details.note1Form.controls.textbox15.value,
      textbox16: this.note1Details.note1Form.controls.textbox16.value,
      textbox17: this.note1Details.note1Form.controls.textbox17.value,
      textbox18: this.note1Details.note1Form.controls.textbox18.value,
      textbox19: this.note1Details.note1Form.controls.textbox19.value,
      textbox20: this.note1Details.note1Form.controls.textbox20.value,
      textbox21: this.note1Details.note1Form.controls.textbox21.value,
      textbox22: this.note1Details.note1Form.controls.textbox22.value,
      textbox23: this.note1Details.note1Form.controls.textbox23.value,
      textbox24: this.note1Details.note1Form.controls.textbox24.value,
      textbox25: this.note1Details.note1Form.controls.textbox25.value,
      textbox26: this.note1Details.note1Form.controls.textbox26.value,
      textbox27: this.note1Details.note1Form.controls.textbox27.value,
      textbox28: this.note1Details.note1Form.controls.textbox28.value,
      textbox29: this.note1Details.note1Form.controls.textbox29.value,
      textbox30: this.note1Details.note1Form.controls.textbox30.value,
      textbox31: this.note1Details.note1Form.controls.textbox31.value,
      textbox32: this.note1Details.note1Form.controls.textbox32.value,
      textbox33: this.note1Details.note1Form.controls.textbox33.value,
      textbox34: this.note1Details.note1Form.controls.textbox34.value,
      textbox35: this.note1Details.note1Form.controls.textbox35.value,
      textbox36: this.note1Details.note1Form.controls.textbox36.value,
    };
    this.jsonObject = <JSON>arrayObj;

    const ObjForCheckbox: any = {
      checkbox1: this.note1Details.note1Form.controls.checkbox1.value ? 1 : 0,
      checkbox2: this.note1Details.note1Form.controls.checkbox2.value ? 1 : 0,
      checkbox3: this.note1Details.note1Form.controls.checkbox3.value ? 1 : 0,
      checkbox4: this.note1Details.note1Form.controls.checkbox4.value ? 1 : 0,
      checkbox5: this.note1Details.note1Form.controls.checkbox5.value ? 1 : 0,
      checkbox6: this.note1Details.note1Form.controls.checkbox6.value ? 1 : 0,
      checkbox7: this.note1Details.note1Form.controls.checkbox7.value ? 1 : 0,
      checkbox8: this.note1Details.note1Form.controls.checkbox8.value ? 1 : 0,
      checkbox9: this.note1Details.note1Form.controls.checkbox9.value ? 1 : 0,
      checkbox10: this.note1Details.note1Form.controls.checkbox10.value ? 1 : 0,
      checkbox11: this.note1Details.note1Form.controls.checkbox11.value ? 1 : 0,
      checkbox12: this.note1Details.note1Form.controls.checkbox12.value ? 1 : 0,
      checkbox13: this.note1Details.note1Form.controls.checkbox13.value ? 1 : 0,
      checkbox14: this.note1Details.note1Form.controls.checkbox14.value ? 1 : 0,
      checkbox15: this.note1Details.note1Form.controls.checkbox15.value ? 1 : 0,
      checkbox16: this.note1Details.note1Form.controls.checkbox16.value ? 1 : 0,
      checkbox17: this.note1Details.note1Form.controls.checkbox17.value ? 1 : 0,
      checkbox18: this.note1Details.note1Form.controls.checkbox18.value ? 1 : 0,
      checkbox19: this.note1Details.note1Form.controls.checkbox19.value ? 1 : 0,
      checkbox20: this.note1Details.note1Form.controls.checkbox20.value ? 1 : 0,
      checkbox21: this.note1Details.note1Form.controls.checkbox21.value ? 1 : 0,
      checkbox22: this.note1Details.note1Form.controls.checkbox22.value ? 1 : 0,
      checkbox23: this.note1Details.note1Form.controls.checkbox23.value ? 1 : 0,
      checkbox24: this.note1Details.note1Form.controls.checkbox24.value ? 1 : 0,
      checkbox25: this.note1Details.note1Form.controls.checkbox25.value ? 1 : 0,
      checkbox26: this.note1Details.note1Form.controls.checkbox26.value ? 1 : 0,
      checkbox27: this.note1Details.note1Form.controls.checkbox27.value ? 1 : 0,
      checkbox28: this.note1Details.note1Form.controls.checkbox28.value ? 1 : 0,
      checkbox29: this.note1Details.note1Form.controls.checkbox29.value ? 1 : 0,
      checkbox30: this.note1Details.note1Form.controls.checkbox30.value ? 1 : 0,
      checkbox31: this.note1Details.note1Form.controls.checkbox31.value ? 1 : 0,
      checkbox32: this.note1Details.note1Form.controls.checkbox32.value ? 1 : 0,
      checkbox33: this.note1Details.note1Form.controls.checkbox33.value ? 1 : 0,
      checkbox34: this.note1Details.note1Form.controls.checkbox34.value ? 1 : 0,
      checkbox35: this.note1Details.note1Form.controls.checkbox35.value ? 1 : 0,
      checkbox36: this.note1Details.note1Form.controls.checkbox36.value ? 1 : 0,
    };
    this.jsonObjectForCheckbox = <JSON>ObjForCheckbox;
  }

  setNotes2Data(): void {
    const arrayObj: any = {
      textbox1: this.note2Details.note2Form.controls.textbox1.value,
    };

    this.jsonObject = <JSON>arrayObj;
  }

  setNotes3Data(): void {
    const arrayObj: any = {
      textbox1: this.note3Details.note3Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes4Data(): void {
    const arrayObj: any = {
      textbox1: this.note4Details.note4Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes5Data(): void {
    const arrayObj: any = {
      textbox1: this.note5Details.note5Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes6Data(): void {
    const arrayObj: any = {
      textbox1: this.note6Details.note6Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes7Data(): void {
    const arrayObj: any = {
      textbox1: this.note7Details.note7Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes8Data(): void {
    const arrayObj: any = {
      textbox1: this.note8Details.note8Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes9Data(): void {
    const arrayObj: any = {
      textbox1: this.note9Details.note9Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes10Data(): void {
    const arrayObj: any = {
      textbox1: this.note10Details.note10Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes11Data(): void {
    const arrayObj: any = {
      textbox1: this.note11Details.note11Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes12Data(): void {
    const arrayObj: any = {
      textbox1: this.note12Details.note12Form.controls.textbox1.value,
    };
    const percentageData: any = {
      supportCost: this.note12Details.calculativeData.supportCost.map((x) => ({
        id: x.id,
        groupId: x.groupId,
        typeId: x.typeId,
        charitableActivity: x.charitableActivity,
        raisingFund: x.raisingFund,
      })),
      governanceCost: this.note12Details.calculativeData.governanceCost.map(
        (x) => ({
          id: x.id,
          groupId: x.groupId,
          typeId: x.typeId,
          charitableActivity: x.charitableActivity,
          raisingFund: x.raisingFund,
        })
      ),
    };
    this.jsonObject = <JSON>arrayObj;
    this.jsonPercentageData = <JSON>percentageData;
  }

  setNotes13Data(): void {
    const arrayObj: any = {
      textbox1: this.note13Details.note13Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes14Data(): void {
    const arrayObj: any = {
      textbox1: this.note14Details.note14Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes15Data(): void {
    const arrayObj: any = {
      textbox1: this.note15Details.note15Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes16Data(): void {
    const arrayObj: any = {
      textbox1: this.note16Details.note16Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes17Data(): void {
    const arrayObj: any = {
      textbox1: this.note17Details.note17Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes18Data(): void {
    const arrayObj: any = {
      textbox1: this.note18Details.note18Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes19Data(): void {
    const arrayObj: any = {
      textbox1: this.note19Details.note19Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes20Data(): void {
    const arrayObj: any = {
      textbox1: this.note20Details.note20Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes21Data(): void {
    const arrayObj: any = {
      textbox1: this.note21Details.note21Form.controls.textbox1.value,
      textbox2: this.note21Details.note21Form.controls.textbox2.value,
      textbox3: this.note21Details.note21Form.controls.textbox3.value,
      textbox4: this.note21Details.note21Form.controls.textbox4.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes22Data(): void {
    const arrayObj: any = {
      textbox1: this.note22Details.note22Form.controls.textbox1.value,
      textbox2: this.note22Details.note22Form.controls.textbox2.value,
      textbox3: this.note22Details.note22Form.controls.textbox3.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes23Data(): void {
    const arrayObj: any = {
      textbox1: this.note23Details.note23Form.controls.textbox1.value,
      textbox2: this.note23Details.note23Form.controls.textbox2.value,
      textbox3: this.note23Details.note23Form.controls.textbox3.value,
      textbox4: this.note23Details.note23Form.controls.textbox4.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes24Data(): void {
    const arrayObj: any = {
      textbox1: this.note24Details.note24Form.controls.textbox1.value,
      textbox2: this.note24Details.note24Form.controls.textbox2.value,
      textbox3: this.note24Details.note24Form.controls.textbox3.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes25Data(): void {
    const arrayObj: any = {
      textbox1: this.note25Details.note25Form.controls.textbox1.value,
      textbox2: this.note25Details.note25Form.controls.textbox2.value,
      textbox3: this.note25Details.note25Form.controls.textbox3.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes26Data(): void {
    const arrayObj: any = {
      textbox1: this.note26Details.note26Form.controls.textbox1.value,
      textbox2: this.note26Details.note26Form.controls.textbox2.value,
      textbox3: this.note26Details.note26Form.controls.textbox3.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes27Data(): void {
    const arrayObj: any = {
      textbox1: this.note27Details.note27Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes28Data(): void {
    const arrayObj: any = {
      textbox1: this.note28Details.note28Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes29Data(): void {
    const arrayObj: any = {
      textbox1: this.note29Details.note29Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes30Data(): void {
    const arrayObj: any = {
      textbox1: this.note30Details.note30Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes31Data(): void {
    const arrayObj: any = {
      textbox1: this.note31Details.note31Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes32Data(): void {
    const arrayObj: any = {
      textbox1: this.note32Details.note32Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes33Data(): void {
    const arrayObj: any = {
      textbox1: this.note33Details.note33Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes34Data(): void {
    const arrayObj: any = {
      textbox1: this.note34Details.note34Form.controls.textbox1.value,
      textbox2: this.note34Details.note34Form.controls.textbox2.value,
      textbox3: this.note34Details.note34Form.controls.textbox3.value,
      textbox4: this.note34Details.note34Form.controls.textbox4.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes35Data(): void {
    const arrayObj: any = {
      textbox1: this.note35Details.note35Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes36Data(): void {
    const arrayObj: any = {
      textbox1: this.note36Details.note36Form.controls.textbox1.value,
      textbox2: this.note36Details.note36Form.controls.textbox2.value,
      textbox3: this.note36Details.note36Form.controls.textbox3.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes37Data(): void {
    const arrayObj: any = {
      textbox1: this.note37Details.note37Form.controls.textbox1.value,
    };

    this.jsonObject = <JSON>arrayObj;
  }

  setNotes38Data(): void {
    const arrayObj: any = {
      textbox1: this.note38Details.note38Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes39Data(): void {
    const arrayObj: any = {
      textbox1: this.note39Details.note39Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes40Data(): void {
    const arrayObj: any = {
      textbox1: this.note40Details.note40Form.controls.textbox1.value,
      textbox2: this.note40Details.note40Form.controls.textbox2.value,
      textbox3: this.note40Details.note40Form.controls.textbox3.value,
      textbox4: this.note40Details.note40Form.controls.textbox4.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes41Data(): void {
    const arrayObj: any = {
      textbox1: this.note41Details.note41Form.controls.textbox1.value,
      textbox2: this.note41Details.note41Form.controls.textbox2.value,
      textbox3: this.note41Details.note41Form.controls.textbox3.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes42Data(): void {
    const arrayObj: any = {
      textbox1: this.note42Details.note42Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  setNotes43Data(): void {
    const arrayObj: any = {
      textbox1: this.note43Details.note43Form.controls.textbox1.value,
    };
    this.jsonObject = <JSON>arrayObj;
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      switch (this.notesModuleId) {
        case Notes.Note1:
          this.setNotes1Data();
          break;
        case Notes.Note2:
          this.setNotes2Data();
          break;
        case Notes.Note3:
          this.setNotes3Data();
          break;
        case Notes.Note4:
          this.setNotes4Data();
          break;
        case Notes.Note5:
          this.setNotes5Data();
          break;
        case Notes.Note6:
          this.setNotes6Data();
          break;
        case Notes.Note7:
          this.setNotes7Data();
          break;
        case Notes.Note8:
          this.setNotes8Data();
          break;
        case Notes.Note9:
          this.setNotes9Data();
          break;
        case Notes.Note10:
          this.setNotes10Data();
          break;
        case Notes.Note11:
          this.setNotes11Data();
          break;
        case Notes.Note12:
          this.setNotes12Data();
          break;
        case Notes.Note13:
          this.setNotes13Data();
          break;
        case Notes.Note14:
          this.setNotes14Data();
          break;
        case Notes.Note15:
          this.setNotes15Data();
          break;
        case Notes.Note16:
          this.setNotes16Data();
          break;
        case Notes.Note17:
          this.setNotes17Data();
          break;
        case Notes.Note18:
          this.setNotes18Data();
          break;
        case Notes.Note19:
          this.setNotes19Data();
          break;
        case Notes.Note20:
          this.setNotes20Data();
          break;
        case Notes.Note21:
          this.setNotes21Data();
          break;
        case Notes.Note22:
          this.setNotes22Data();
          break;
        case Notes.Note23:
          this.setNotes23Data();
          break;
        case Notes.Note24:
          this.setNotes24Data();
          break;
        case Notes.Note25:
          this.setNotes25Data();
          break;
        case Notes.Note26:
          this.setNotes26Data();
          break;
        case Notes.Note27:
          this.setNotes27Data();
          break;
        case Notes.Note28:
          this.setNotes28Data();
          break;
        case Notes.Note29:
          this.setNotes29Data();
          break;
        case Notes.Note30:
          this.setNotes30Data();
          break;
        case Notes.Note31:
          this.setNotes31Data();
          break;
        case Notes.Note32:
          this.setNotes32Data();
          break;
        case Notes.Note33:
          this.setNotes33Data();
          break;
        case Notes.Note35:
          this.setNotes35Data();
          break;
        case Notes.Note36:
          this.setNotes36Data();
          break;
        case Notes.Note37:
          this.setNotes37Data();
          break;
        case Notes.Note38:
          this.setNotes38Data();
          break;
        case Notes.Note39:
          this.setNotes39Data();
          break;
        case Notes.Note40:
          this.setNotes40Data();
          break;
        case Notes.Note41:
          this.setNotes41Data();
          break;
        case Notes.Note42:
          this.setNotes42Data();
          break;
        case Notes.Note43:
          this.setNotes43Data();
          break;
      }

      this.noteData = {
        accountingPeriodId: this.noteForm.controls.accountingPeriod.value,
        noteId: this.notesModuleId,
        checkBoxData:
          this.jsonObjectForCheckbox === undefined
            ? {}
            : this.jsonObjectForCheckbox,
        data: this.jsonObject,
        percentageData:
          this.jsonPercentageData === undefined
            ? null
            : this.jsonPercentageData,
        id: this.notesId,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  editNotes(isOnLoad: boolean = false): void {
    let index = this.periodicDateList.findIndex(
      (x) => x.id === this.noteForm.controls.accountingPeriod.value
    );
    if (index !== 0) {
      this.thisperioicbuttonDisaply = true;
    } else {
      this.thisperioicbuttonDisaply = false;
    }

    this.spinner.show();
    this.getHeaderName();

    const param: GetNoteParamModel = {
      id: this.notesModuleId,
      accountingPeriodId: this.noteForm.controls.accountingPeriod.value,
      ids: this.ids ?? null,
    };

    this.store.dispatch(new GetNotesData(param)).subscribe((res) => {
      this.notesId =
        res.note.noteData.userInputData !== null &&
        res.note.noteData.userInputData !== undefined
          ? res.note.noteData.userInputData.id
          : (Guid.EMPTY as unknown as Guid);

      const param = {
        response: res.note.noteData,
        noteId: this.notesModuleId,
      };

      this.triggerEditData.next(param);
    });
  }

  onCancel(): void {
    this.ngOnInit();
  }

  onSave(): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveNoteData(this.noteData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.notesId = res.note.noteId;
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }
  isActive(element: any): boolean {
    return this.notesModuleId === element.id;
  }

  onCopyClick(): void {
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          id: this.notesModuleId,
          type: ConfirmationType.Copy,
          moduleId: Modules.Note1,
          headerText: NotificationHeader.copyConfirmation,
          detailText: NotificationDetails.copyDetail,
          accountingPeriod: this.noteForm.controls.accountingPeriod.value,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          const param = {
            response: res,
            noteId: this.notesModuleId,
          };

          this.triggerEditData.next(param);
        }
      });
  }
}
