import { Guid } from 'guid-typescript';

export class LetsConnectRequestModel {
  name: string;
  emailId: string;
  phoneNumber: string;
  clientName: string;
  supportTopic: string;
  modules: string;
  subject: string;
  message: string;
  externalUserGuid: Guid;
  file: Array<File>;
}
