import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VATCodesModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class VATCodesService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getVATCodes(vatCodeId: Guid): Observable<VATCodesModel> {
    return this.http.get<VATCodesModel>(
      `${environment.apiVersionUrl}Vat/get/${vatCodeId}`
    );
  }

  saveVATCodes(params: VATCodesModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Vat/save`,
      this.commonService.trimObjectSpace(JSON.stringify(params)),
      headers
    );
  }

  deleteVATCodes(vatCodsIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(vatCodsIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Vat/delete`,
      options
    );
  }

  archiveAndRestoreVATCodes(
    vatCodsIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Vat/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(vatCodsIds)),
      options
    );
  }
}
