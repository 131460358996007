import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Modules, NotificationTextMessage } from '@app/core/Enum';
import { CompanyPreference } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateCompanyPreference,
  GetCompanyPreferenceData,
  GetCompanyRoundingPreference,
  MenuState,
  UpdateCompanyRoundingPreference,
} from '@app/core/Store';
import { AddCompanyPreferenceComponent } from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-company-preference',
  templateUrl: './company-preference.component.html',
  styleUrls: ['./company-preference.component.scss'],
})
export class CompanyPreferenceComponent implements OnInit {
  companyPreferenceData: CompanyPreference;

  companyPreferenceId = Guid.EMPTY as unknown as Guid;
  selectedTab = 0;
  moduleEnum = Modules;
  moduleId = Modules.Invoices;

  @ViewChild(AddCompanyPreferenceComponent)
  companyPreferenceDetail;

  triggerEditData: Subject<any> = new Subject<any>();

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private store: Store,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.editCompanyPreferences();
  }

  editCompanyPreferences(): void {
    let moduleId = this.selectedTabModuleId();
    if (this.moduleId !== Modules.AccountProduction) {
      this.store
        .dispatch(new GetCompanyPreferenceData(moduleId))
        .subscribe((res) => {
          this.companyPreferenceDetail.companyPreferenceForm.markAsUntouched();
          this.companyPreferenceId = res.company.getCompanyPreferenceData.id;
          this.triggerEditData.next(res.company.getCompanyPreferenceData);
        });
    } else {
      this.store
        .dispatch(new GetCompanyRoundingPreference())
        .subscribe((res) => {
          this.companyPreferenceDetail.accountProductionPreferenceForm.markAsUntouched();
          this.triggerEditData.next(res.company.companyRoundingPreference);
        });
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      if (this.moduleId !== Modules.AccountProduction) {
        this.companyPreferenceData = {
          id: this.companyPreferenceId,
          moduleId: this.selectedTabModuleId(),
          autoGenerate:
            this.companyPreferenceDetail.companyPreferenceForm.value
              .autoGenerate,
          prefix:
            this.companyPreferenceDetail.companyPreferenceForm.value.prefix,
          suffix:
            this.companyPreferenceDetail.companyPreferenceForm.value.suffix,
          repeatType:
            +this.companyPreferenceDetail.companyPreferenceForm.value
              .repeatType,
        };
      }
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.moduleId !== Modules.AccountProduction) {
      if (this.companyPreferenceDetail.companyPreferenceForm.invalid) {
        this.commonService.addValidation(
          this.companyPreferenceDetail.companyPreferenceForm,
          this.renderer
        );
      } else {
        if (this.dataSubmit()) {
          this.store
            .dispatch(new CreateCompanyPreference(this.companyPreferenceData))
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.onCancel();
                  if (isExit) {
                    history.back();
                  }
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    } else {
      this.store
        .dispatch(
          new UpdateCompanyRoundingPreference(
            this.companyPreferenceDetail.accountProductionPreferenceForm.value.roundUpTo
          )
        )
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.onCancel();
              if (isExit) {
                history.back();
              }
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }

  onTabClick(event: any): void {
    this.selectedTab = event;
    this.editCompanyPreferences();
  }

  onCancel(): void {
    this.commonService.isInitialValueChange = false;
    this.companyPreferenceId = Guid.EMPTY as unknown as Guid;
    this.ngOnInit();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  selectedTabModuleId(): number {
    let selectedModuleId;

    switch (this.selectedTab) {
      case 0:
        selectedModuleId = Modules.Invoices;
        this.moduleId = Modules.Invoices;
        break;

      case 1:
        selectedModuleId = Modules.Quotation;
        this.moduleId = Modules.Quotation;
        break;

      case 2:
        selectedModuleId = Modules.RecurringInvoice;
        this.moduleId = Modules.RecurringInvoice;
        break;

      case 3:
        selectedModuleId = Modules.RecurringBill;
        this.moduleId = Modules.RecurringBill;
        break;

      case 4:
        selectedModuleId = Modules.AccountProduction;
        this.moduleId = Modules.AccountProduction;
        break;
    }

    return selectedModuleId;
  }
}
