import { Injectable } from '@angular/core';
import { DonationCodingService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import { CreateDonationCoding } from './donation-coding.action';

export class DonationCodingStateInfo {
  donationCodingId?: Guid;
  isDonationCodingAdded?: boolean;
  isLastPage?: boolean;
}

@State<DonationCodingStateInfo>({
  name: 'donationCoding',
  defaults: {
    donationCodingId: Guid.EMPTY as unknown as Guid,
    isDonationCodingAdded: false,
  },
})
@Injectable()
export class DonationCodingState {
  constructor(private donationCodingService: DonationCodingService) {}

  @Selector()
  static isLastPage(state: DonationCodingStateInfo) {
    return state.isLastPage;
  }

  @Action(CreateDonationCoding)
  createDonationCoding(
    { patchState }: StateContext<DonationCodingStateInfo>,
    action: CreateDonationCoding
  ) {
    return this.donationCodingService
      .createDonationCoding(action.donationCoding)
      .pipe(
        tap((res) => {
          patchState({
            donationCodingId: res,
            isDonationCodingAdded: true,
          });
        })
      );
  }
}
