<div class="content-body">
  <form [formGroup]="fundTransferForm">
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field datepicker">
        <p>Transfer Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="transferDate"
            [matDatepicker]="transferDate"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="transferDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #transferDate></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              fundTransferForm.get('transferDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              fundTransferForm.get('transferDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field">
        <p>Amount<span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            zeroNotAllowed
            formControlName="amount"
            autocomplete="off"
            required
            maxlength="{{ maxLength.maxLength25 }}"
            allowDecimal
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field">
        <p>Transfer From Fund</p>
        <ng-select
          #fundFrom
          (focus)="fundFrom.open()"
          (valueChange)="fundFrom.close()"
          [disableOptionCentering]="true"
          formControlName="transferFrom"
          panelClass="mat-select-position"
        >
          <ng-option
            *ngFor="let option of fundfromTransList"
            [value]="option.id"
          >
            <div class="clamp" title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Transfer To Fund</p>
        <ng-select
          #transferTo
          (focus)="transferTo.open()"
          (valueChange)="transferTo.close()"
          [disableOptionCentering]="true"
          formControlName="transferTo"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let option of fundToTransList" [value]="option.id">
            <div class="clamp" title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="form-field wp-100 textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          maxlength="{{ maxLength.maxLength2000 }}"
          matInput
          formControlName="note"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
