import { Injectable } from '@angular/core';
import { DonationInKindModel, SideListModel } from '@app/core/Models';
import { DonationInKindService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreDonationInKind,
  CreateDonationInKind,
  DeleteDonationInKind,
  GetDataByDonationInKindId,
  GetDonationType,
} from './donation-in-kind.action';

export class DonationInKindStateInfo {
  donationInKindData?: DonationInKindModel;
  donationInKind: Array<DonationInKindModel>;
  donationInKindId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isDonationInKindAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  donationInKindTypeList: SideListModel[];
}

@State<DonationInKindStateInfo>({
  name: 'donationInKind',
  defaults: {
    donationInKind: [],
    donationInKindId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    donationInKindTypeList: [],
    isDonationInKindAdded: false,
  },
})
@Injectable()
export class DonationInKindState {
  constructor(private donationInKindService: DonationInKindService) {}

  @Selector()
  static isLastPage(state: DonationInKindStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getDonationInKindId(state: DonationInKindStateInfo) {
    return state.donationInKindId;
  }

  @Selector()
  static getDonationType(state: DonationInKindStateInfo) {
    return state.donationInKindTypeList;
  }

  @Action(CreateDonationInKind)
  createDonationInKind(
    { patchState }: StateContext<DonationInKindStateInfo>,
    action: CreateDonationInKind
  ) {
    return this.donationInKindService
      .createDonationInKind(action.donationInKind)
      .pipe(
        tap((res) => {
          patchState({
            donationInKindId: res,
            isDonationInKindAdded: true,
          });
        })
      );
  }

  @Action(GetDataByDonationInKindId)
  getDataByDonationInKindId(
    { patchState }: StateContext<DonationInKindStateInfo>,
    action: GetDataByDonationInKindId
  ) {
    return this.donationInKindService
      .getDataByDonationInKindId(action.donationInKindId)
      .pipe(
        tap((res) => {
          patchState({
            donationInKindData: res,
          });
        })
      );
  }

  @Action(DeleteDonationInKind)
  deleteDonationInKind(
    { getState, patchState }: StateContext<DonationInKindStateInfo>,
    action: DeleteDonationInKind
  ) {
    return this.donationInKindService
      .deleteDonationInKind(action.donationInKindIds)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredQuatation = state.donationInKind.filter(
            (item) =>
              !action.donationInKindIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.donationInKindIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          patchState({
            ...state.donationInKind,
            donationInKind: filteredQuatation,
            sideListModel: filteredForSideList,
          });
        })
      );
  }

  @Action(ArchiveAndRestoreDonationInKind)
  archiveAndRestoreDonationInKind(
    { getState }: StateContext<DonationInKindStateInfo>,
    action: ArchiveAndRestoreDonationInKind
  ) {
    return this.donationInKindService.archiveAndRestoreDonationInKind(
      action.donationInKindIds,
      action.isArchive
    );
  }

  @Action(GetDonationType)
  getDonationType(
    { patchState }: StateContext<DonationInKindStateInfo>,
    action: any
  ) {
    return this.donationInKindService.getDonationType().pipe(
      tap((res) => {
        patchState({
          donationInKindTypeList: res,
        });
      })
    );
  }
}
