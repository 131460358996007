import { CompanyModel } from '@app/core/Models';
import { CompanyPreference } from '@app/core/Models/company/company-preference';
import { Guid } from 'guid-typescript';

export class CreateCompany {
  static readonly type = '[COMPANY] Create Company';

  constructor(public company: CompanyModel) {}
}

export class GetCompanyList {
  static readonly type = '[COMPANY] Get Company';
  constructor(public params: any) {}
}

export class GetAvailableCompanyCount {
  static readonly type = '[COMPANY] Get Available Company Count';
}

export class GetVatReturnTypeList {
  static readonly type = '[COMPANY] Get Vat Return Type List';
}

export class SetAsDefaultCompany {
  static readonly type = '[COMPANY] Get Default Company';

  constructor(public companyId: Guid) {}
}

export class GetDataByCompanyId {
  static readonly type = '[COMPANY] Get Company Data By Company Id';

  constructor(public companyId: Guid) {}
}

export class GetCharityRegulator {
  static readonly type = '[COMPANY] Get Charity Regulator';
}

export class GetTimeZone {
  static readonly type = '[COMPANY] Get TimeZone';
}

export class GetLanguage {
  static readonly type = '[COMPANY] Get language';
}

export class CreateCompanyPreference {
  static readonly type = '[COMPANY] Create Company Preference';

  constructor(public company: CompanyPreference) {}
}

export class GetCompanyPreferenceData {
  static readonly type = '[COMPANY] Get Company Data By Module Id';

  constructor(public moduleId: number) {}
}

export class GetAccountingMethodList {
  static readonly type = '[COMPANY] Get Accounting Method';
}

export class GetVatSchemeList {
  static readonly type = '[COMPANY] Get Vat Scheme List';
}

export class DeleteFinancialYear {
  static readonly type = '[COMPANY] Delete Financial Year';

  constructor(public ids: Array<Guid>) {}
}
export class DateBetweenAccountingPeriod {
  static readonly type = '[COMPANY] Date Between Accounting Period';

  constructor(public date: string) {}
}
export class GetCompanyRoundingPreference {
  static readonly type = '[COMPANY] Get Company Rounding Preference';
}

export class UpdateCompanyRoundingPreference {
  static readonly type = '[COMPANY] Update Company Rounding Preference';

  constructor(public roundPreference: boolean) {}
}
