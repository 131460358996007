import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DonationOverview,
  ExpenseOverview,
  IncomeOverview,
  Modules,
} from '@app/core/Enum';
import {
  GetDonationsFlowOverview,
  GetExpenditureFlowOverview,
  GetIncomeFlowOverview,
} from '@app/core/Store';
import { Store } from '@ngxs/store';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type FlowChartOptionsv1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  noData: ApexNoData;
  grid: ApexGrid;
  labels: any;
};

@Component({
  selector: 'app-overview-flow-chart-v1',
  templateUrl: './overview-flow-chart-v1.component.html',
  styleUrls: ['./overview-flow-chart-v1.component.scss'],
})
export class OverviewFlowChartv1Component implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public flowChartOptionsv1: Partial<any>;

  @Input() triggerHeaderFilter: Observable<any>;

  @Input()
  triggerExpenditureBarData: Observable<any>;

  @Input()
  getModuleId: number;

  isNoRecord = true;
  isloadData = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (
        this.getModuleId === Modules.DonationOverview ||
        this.getModuleId === Modules.ExpenditureOverview ||
        this.getModuleId === Modules.IncomeOverview
      ) {
        this.getData(filterData);
      }
    });
  }

  makeSeriesData(overviewData: any): any {
    const series: any = [];
    let count = 0;

    overviewData.series.forEach((element, i) => {
      series.push(element.data);
      if (element.data === 0) {
        count = count + 1;
      }
    });

    this.isNoRecord = count === overviewData.series.length ? true : false;
    return series;
  }

  getData(filterData?: any): void {
    let seriesData;
    let categoryData;

    if (+this.getModuleId === Modules.IncomeOverview) {
      this.store
        .dispatch(new GetIncomeFlowOverview(filterData))
        .subscribe((res) => {
          this.isloadData = true;
          let incomeChart = res.invoice.incomeFlowOverview;

          this.isNoRecord = incomeChart.series.length === 0;

          if (incomeChart.series.length > 0) {
            seriesData = this.makeSeriesData(incomeChart);
          }

          categoryData = incomeChart.categories;
          this.setChartData(seriesData, categoryData);
        });
    } else if (+this.getModuleId === Modules.ExpenditureOverview) {
      this.store
        .dispatch(new GetExpenditureFlowOverview(filterData))
        .subscribe((res) => {
          this.isloadData = true;
          let expensesChart = res.bill.expenditureFlowOverview;

          this.isNoRecord = expensesChart.series.length === 0;

          if (expensesChart.series.length > 0) {
            seriesData = this.makeSeriesData(expensesChart);
          }

          categoryData = expensesChart.categories;
          this.setChartData(seriesData, categoryData);
        });
    } else if (+this.getModuleId === Modules.DonationOverview) {
      this.store
        .dispatch(new GetDonationsFlowOverview(filterData))
        .subscribe((res) => {
          this.isloadData = true;
          let donationChart = res.donation.donationsFlowOverview;

          this.isNoRecord = donationChart.series.length === 0;

          if (donationChart.series.length > 0) {
            seriesData = this.makeSeriesData(donationChart);
          }

          categoryData = donationChart.categories;
          this.setChartData(seriesData, categoryData);
        });
    }
  }

  setChartData(seriesData: any, categoryData: any): void {
    this.flowChartOptionsv1 = {
      series: [
        {
          name: '',
          data: seriesData,
        },
      ],
      chart: {
        height: 300,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '35%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        labels: {
          show: true,
          rotate: 0,
        },
        categories: categoryData,
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return '£ ' + Number(val) / 1000 + 'K';
          },
        },
      },
      legend: {
        show: false,
        offsetX: 0,
        offsetY: 8,
      },
      fill: {
        colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
      },
      tooltip: {
        marker: {
          show: false,
        },
        style: {
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: '14px',
        },
        y: {
          formatter: (val) => {
            return '£ ' + Number(val) * 1 + '';
          },
        },
        enabled: true,
        fillSeriesColor: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
}
