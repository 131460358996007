import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
  TransactionStatus,
} from '@app/core/Enum';
import { Currency, GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  CreateActivity,
  CreateFund,
  DonationState,
  GetActivityList,
  GetDataByActivityId,
  GetDataByFundId,
  GetFundNameList,
  GetJournalNo,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-journals',
  templateUrl: './add-journals.component.html',
  styleUrls: ['./add-journals.component.scss'],
})
export class AddJournalsComponent implements OnInit {
  journalsForm: FormGroup;
  subscriptionRouting: Subscription;
  maxLength = MaxLength;
  @Input() triggerEditData: Observable<any>;

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  defaultCurrency: Guid;
  periodicDate: any;
  fundId: Guid;

  customerList: SideListModel[];
  postedToList: SideListModel[];
  currencyList: Currency[];
  fundTypeList: SideListModel[];
  fundNameList: SideListModel[];
  activityList: SideListModel[];
  disabledEditButton = true;
  disabledActivityEditButton = true;

  statusFilter: any[] = [
    {
      value: TransactionStatus.Receipt,
      name: TransactionStatus[TransactionStatus.Receipt],
    },
    {
      value: TransactionStatus.Payment,
      name: TransactionStatus[TransactionStatus.Payment],
    },
  ];

  notificationMessage = NotificationTextMessage;
  private destroy$ = new Subject<void>();
  isFundChangePermission: boolean = true;
  isActivityChangePermission: boolean = true;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();
    this.getJournalNo();
    this.getActivity();
    this.getFund();

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.editJournals(data);
      });

    this.journalsForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.journalsForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
      this.isActivityChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Activities
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  editJournals(data): void {
    this.disabledEditButton = false;
    this.disabledActivityEditButton = false;
    this.journalsForm.patchValue({
      id: data.id,
      journalNo: data.entryNumber,
      date: data.entryDate,
      reference: data.note,
      fundName: data.fundId,
      activity: data.activityId === null ? '' : data.activityId,
    });
  }

  setForm(): void {
    this.journalsForm = new FormGroup({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      journalNo: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      date: new FormControl(new Date(), datePickerValidator(this.periodicDate)),
      reference: new FormControl(''),
      activity: new FormControl(''),
      fundName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
    });
  }

  getJournalNo(): void {
    this.spinner.show();
    this.store.dispatch(new GetJournalNo()).subscribe((res) => {
      this.spinner.hide();
      this.journalsForm.get('journalNo')?.setValue(res.journals.journalNo);
      this.journalsForm.get('journalNo')?.disable();
    });
  }

  getActivity(id?: Guid): void {
    this.store
      .dispatch(new GetActivityList())
      .pipe(
        tap(() => {
          this.activityList = this.store.selectSnapshot(
            DonationState.getActivity
          );

          if (id !== null && id !== undefined) {
            this.journalsForm.controls.activity.setValue(id);
          }
        })
      )
      .subscribe();
  }

  getFund(id?: Guid): void {
    this.store
      .dispatch(new GetFundNameList())
      .pipe(
        tap((res) => {
          this.fundNameList = res.account.fundNameList;

          if (this.fundNameList.length > 0) {
            this.disabledEditButton = false;
            this.journalsForm.controls.fundName.setValue(
              this.fundNameList[0].id
            );

            if (id !== null && id !== undefined) {
              this.journalsForm.controls.fundName.setValue(id);
            }
          }
        })
      )
      .subscribe();
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getFund(id);
        }
      });
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.journalsForm.controls.fundName.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }

  activityChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledActivityEditButton = false;
    } else {
      this.disabledActivityEditButton = true;
    }
  }

  gotoAddActivity(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Activities,
          headerText: `Add ${ModuleName.Activities}`,
          saveActionName: CreateActivity,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledActivityEditButton = false;
          this.getActivity(id);
        }
      });
  }

  gotoEditActivity(): void {
    if (this.activityList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Activities,
            headerText: `Edit ${ModuleName.Activities}`,
            id: this.journalsForm.controls.activity.value,
            saveActionName: CreateActivity,
            getActionName: GetDataByActivityId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getActivity(id);
          }
        });
    }
  }
}
