<div class="content-body">
  <form [formGroup]="giftForm">
    <div class="d-flex wp-100">
      <div class="form-field wp-100">
        <p class="fw-normal">
          When you have confirmed tax payer eligibility enter the date from
          which you wish to recover Gift Aid on donations made in line with
          current HMRC guidance for backdating of claims.
        </p>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>Tax Payer Eligible Start Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="eligibaleStartDate"
            [matDatepicker]="startDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Date Tax Payer End Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="taxPayerStatusDate"
            [matDatepicker]="endDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              giftForm.get('taxPayerStatusDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              giftForm.get('taxPayerStatusDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="form-field">
      <div *ngFor="let file of files">
        <a
          (click)="dowloadFile(file.fileUrl)"
          class="text-primary d-flex align-items-center gap-10"
        >
          <mat-icon>file_download</mat-icon>
          <span class="link">{{ file.fileName }}</span></a
        >
      </div>
    </div>
    <div class="form-field wp-100">
      <p>Gift Aid Declaration to HMRC</p>
      <button
        type="button"
        mat-button
        disableRipple
        (click)="clickAttachment()"
      >
        <mat-icon>attach_file</mat-icon>
        <span>
          <strong><u>choose a file</u></strong>
        </span>
      </button>
    </div>
  </form>
</div>
