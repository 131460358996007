<div class="content-header">
  <div
    class="content-header-input"
    [ngClass]="[
      customDate ? 'content-header-custom-date' : '',
      (moduleId$ | async) === moduleEnum.FixedAssetsDispose
        ? 'justify-content-end'
        : ''
    ]"
  >
    <div
      *ngIf="moduleId !== moduleEnum.FixedAssetsDispose"
      class="toolbar mr-10"
    >
      <button matTooltip="Refresh" mat-button (click)="selectCustomDate()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div
      *ngIf="moduleId !== moduleEnum.FixedAssetsDispose"
      class="d-flex justify-space-between wp-100"
    >
      <button
        class="action-button primary-button"
        mat-button
        disableRipple
        (click)="onAddClick()"
        *ngIf="
          detailListId !== mtd.Obligations &&
          detailListId !== mtd.ViewVATReturn &&
          detailListId !== mtd.RetrieveVATLiabilities &&
          detailListId !== mtd.RetrieveVATPayments
        "
      >
        <mat-icon>add</mat-icon>
        <span>Add {{ moduleName }}</span>
      </button>
      <div
        class="d-flex wp-100 header-filters  header-count header-count-{{
          (headerList$ | async).length
        }}"
      >
        <div
          *ngFor="let item of headerList$ | async; let i = index"
          class="{{ item.name }}"
          [ngClass]="
            item.name === 'Datetime' && customDate ? 'custom-date' : ''
          "
        >
          <mat-form-field
            *ngIf="item.controlType === controlType.TextBox"
            appearance="fill"
            class="search-field"
          >
            <input
              matInput
              placeholder="Search here..."
              autocomplete="off"
              [(ngModel)]="searchText"
              (keyup.enter)="onSearch()"
              (ngModelChange)="onSearchAll()"
            />
            <button mat-button class="search-button" (click)="onSearch()">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
          <div
            *ngIf="item.controlType === controlType.DateTimePicker"
            class="year-picker"
          >
            <app-custom-year-picker
              [moduleId]="moduleId"
              [triggerResetDetailList]="triggerResetDetailList"
              (triggerDateChange)="triggerDateChange($event)"
            ></app-custom-year-picker>
          </div>
          <div
            class="content-right-button d-flex align-items-center"
            *ngIf="item.controlType === controlType.Dropdown"
          >
            <span class="fw-bold"> Select Periodic: </span>
            <mat-form-field
              [ngClass]="[
                (moduleId$ | async) === modules.BankFeed ? 'w-200' : '',
                (moduleId$ | async) === modules.AddStandardAccounts
                  ? 'w-200'
                  : ''
              ]"
            >
              <mat-select
                panelClass="mat-select-position"
                [disableOptionCentering]="true"
                [(value)]="
                  item.name === HeaderFilters.Module
                    ? commonService.defaultGuidValue
                    : defaultValue[i].id
                "
                (selectionChange)="onDropdownChange(item.name, $event.value)"
              >
                <mat-option
                  *ngFor="let cl of item.value"
                  [value]="cl.id"
                  title="{{ cl.name }}"
                >
                  {{ cl.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="toolbar">
      <button mat-button [matMenuTriggerFor]="menu" disableRipple>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu
        #menu="matMenu"
        class="action-menu more-menu"
        xPosition="before"
      >
        <button
          mat-menu-item
          *ngIf="(moduleId$ | async) === modules.FixedAssetsRegister"
          (click)="export(exportType.PDF, false)"
          disableRipple
          [disabled]="isViewPermission$ | async"
          [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
        >
          <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon
          >Export As PDF
        </button>
        <button
          mat-menu-item
          (click)="export(exportType.Excel, false)"
          disableRipple
          [disabled]="isViewPermission$ | async"
          [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
        >
          <mat-icon>border_all</mat-icon>Export to excel
          {{ showAccountDetails }}
        </button>
        <button
          mat-menu-item
          (click)="export(exportType.CSV, false)"
          disableRipple
          [disabled]="isViewPermission$ | async"
          [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
        >
          <mat-icon fontSet="material-icons-outlined">description</mat-icon
          >Export to CSV
        </button>
      </mat-menu>
    </div>
  </div>
</div>
