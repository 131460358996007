import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createPayment(payment: PaymentModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Payment/create`,
      this.commonService.trimObjectSpace(JSON.stringify(payment)),
      headers
    );
  }

  getDataByPaymentId(paymentId: Guid): Observable<PaymentModel> {
    return this.http.get<PaymentModel>(
      `${environment.apiVersionUrl}Payment/get/${paymentId}`
    );
  }

  deletePayment(paymentIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(paymentIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Payment/deletePayment`,
      options
    );
  }

  copyPayment(paymentIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Payment/copyPayment`,
      this.commonService.trimObjectSpace(JSON.stringify(paymentIds)),
      headers
    );
  }

  archiveAndRestorePayment(
    paymentIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const statusModel = {
      ids: paymentIds,
      status: isArchive,
    };

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(statusModel)),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Payment/status`,
      this.commonService.trimObjectSpace(JSON.stringify(statusModel)),
      options
    );
  }
}
