import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ActivityModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateActivity,
  GetDataByActivityId,
  MenuState,
} from '@app/core/Store';
import {
  AddActivitiesComponent,
  AddClosePopupComponent,
  TransactionsAttachmentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isExit = false;

  fileAttachments: any[] = [];
  activityId = Guid.EMPTY as unknown as Guid;

  moduleId = Modules.Activities;
  moduleName = ModuleName.Activities;
  activityData: ActivityModel;

  triggerEditData: Subject<any> = new Subject<any>();
  subscriptionRouting: Subscription;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddActivitiesComponent, { static: true })
  activitiesDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetails;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private store: Store,
    public commonService: CommonService,
    private _Activatedroute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private location: Location,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.activityId = atob(params.get('id')!) as unknown as Guid;
          this.editActivity();
        }
      }
    );
  }

  editActivity(): void {
    this.store
      .dispatch(new GetDataByActivityId(this.activityId))
      .subscribe((res) => {
        this.activitiesDetails.activitiesForm.markAsUntouched();
        this.triggerEditData.next(res.activity.activityData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.activityId,
      isExit,
      Modules.Activities,
      RoutingPath.AddActivity
    );
  }

  onSave(isExit: boolean): void {
    if (this.activitiesDetails.activitiesForm.invalid) {
      this.commonService.addValidation(
        this.activitiesDetails.activitiesForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateActivity(this.activityData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }

                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      const filedata: any[] = [];

      if (this.attachmentDetails.fileUploadResponse.length > 0) {
        this.attachmentDetails.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.activityData = {
        id: this.activityId,
        name: this.activitiesDetails.activitiesForm.controls.name.value,
        code: this.activitiesDetails.activitiesForm.controls.code.value,
        description:
          this.activitiesDetails.activitiesForm.controls.description.value,
        attachment: filedata,
        isDefault:
          this.activitiesDetails.activitiesForm.controls.isDefault.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.activityId)) {
      this.editActivity();
    } else {
      this.activityId = Guid.EMPTY as unknown as Guid;
      this.activitiesDetails.ngOnInit();
      this.attachmentDetails.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
