import { ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class ViewParamModel {
  id: Guid;
  moduleId: number;
}

export class ViewDetails {
  htmlContent: string;
}

export class ViewModel {
  id?: Guid;
  entryDate?: string;
  entryNumber: string;
  dueDate?: string;
  currencyId: number;
  accountId?: Guid;
  bookAccountId?: Guid;
  fundId?: Guid;
  activityId: Guid | null;
  note: string;
  baseAmount?: number;
  totalAmount?: number;
  viewItems: Array<ProductDetailsModel>;
}
