import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ChartOfAccountIncomeGroupTypeName,
  GroupNames,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  ChartOfAccountListParam,
  MainListParameters,
  SideListModel,
  ViewParamModel,
  BulkEditModel,
  SaveBulkEditModel,
} from '@app/core/Models';
import { NotificationService } from '@app/core/Services';
import {
  GetAccountsBasedOnGroupId,
  GetAccountsBasedOnGroupIdAndTypeId,
  GetBulkEditList,
  GetFundNameList,
  GetInvoiceTypeBasedOfAccount,
  MenuState,
  SaveBulkEdit,
} from '@app/core/Store';
import { ViewReceiptComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-edit',
  templateUrl: './bulk-edit.component.html',
  styleUrls: ['./bulk-edit.component.scss'],
})
export class BulkEditComponent implements OnInit {
  listParameters: MainListParameters = new MainListParameters();
  displayBulkEditColumns: string[] = [
    'srNo',
    'date',
    'reference',
    'account',
    'fund',
    'contactName',
    'referenceNo',
    'receipt',
    'payment',
  ];
  bulkEditList: BulkEditModel[] = [];
  isAllSelected = false;
  moduleId = Modules.BulkEdit;
  groupIds = new Array<number>();
  typeIds = new Array<number>();
  accountList: SideListModel[];
  tempAccountList: SideListModel[];
  fundList: SideListModel[];
  accountValue = '-1';
  isRecordSelected = false;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private notifier: NotificationService,
    private store: Store,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.spinner.hide();
    this.getList();
    this.getFund();
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  onInvoiceClick(element): void {
    const data: ViewParamModel = {
      moduleId: element.moduleId,
      id: element.id,
    };

    this.dialog
      .open(ViewReceiptComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {});
  }

  getInvoiceTypeBasedOfAccount(element): void {
    if (element !== undefined) {
      this.store
        .dispatch(new GetInvoiceTypeBasedOfAccount(element))
        .subscribe((res) => {
          const invoiceTypeId = res.bulkEdit.invoiceType;
          this.groupIds = [];
          this.typeIds = [];

          if (invoiceTypeId === GroupNames.Income) {
            this.groupIds.push(GroupNames.Income);
            this.typeIds.push(
              ChartOfAccountIncomeGroupTypeName.CharitableActivities,
              ChartOfAccountIncomeGroupTypeName.OtherTradingActivities
            );
            this.getAccountsBasedOnGroupIdAndTypeId();
          }

          if (invoiceTypeId === GroupNames.Expense) {
            this.groupIds.push(GroupNames.Expense);
            this.typeIds.push(
              ChartOfAccountIncomeGroupTypeName.DonationsAndLegacies,
              ChartOfAccountIncomeGroupTypeName.CharitableActivities
            );
            this.getAccountsBasedOnGroupIdAndTypeId();
          }

          if (invoiceTypeId === GroupNames.FixedAssets_Intangibles) {
            this.groupIds.push(GroupNames.Income);
            this.getInvoiceType();
          }

          if (invoiceTypeId === GroupNames.FixedAssets_Tangibles) {
            this.groupIds.push(GroupNames.Expense);
            this.getInvoiceType();
          }

          if (invoiceTypeId === GroupNames.FixedAssets_Heritage) {
            this.groupIds.push(
              ChartOfAccountIncomeGroupTypeName.OtherTradingActivities,
              ChartOfAccountIncomeGroupTypeName.Investments
            );
            this.getInvoiceType();
          }
        });
    }
  }

  getInvoiceType(): void {
    this.store
      .dispatch(new GetAccountsBasedOnGroupId(this.groupIds))
      .pipe(
        tap((res) => {
          this.accountList = res.common.accountList;
          this.tempAccountList = this.accountList;
        })
      )
      .subscribe();
  }

  getFund(): void {
    this.store
      .dispatch(new GetFundNameList())
      .pipe(
        tap((res) => {
          this.fundList = res.account.fundNameList;
        })
      )
      .subscribe();
  }

  getAccountsBasedOnGroupIdAndTypeId(): void {
    const param: ChartOfAccountListParam = {
      groupIds: this.groupIds,
      typeIds: this.typeIds,
    };

    this.store
      .dispatch(new GetAccountsBasedOnGroupIdAndTypeId(param))
      .pipe(
        tap((res) => {
          if (res.common.accountList.length > 0) {
            this.accountList = res.common.accountList;
            this.tempAccountList = this.accountList;
          }
        })
      )
      .subscribe();
  }

  onSave(event): void {
    const param: SaveBulkEditModel[] = [];
    for (let bulkEdit of this.bulkEditList) {
      if (bulkEdit.isSelected) {
        param.push({
          id: bulkEdit.id,
          bookAccountId:
            bulkEdit.accountValue && bulkEdit.accountValue !== '-1'
              ? bulkEdit.accountValue
              : this.listParameters.moduleId,
          reference: bulkEdit.reference,
          fundId: bulkEdit.fundId,
          invoiceTypeId: bulkEdit.invoiceTypeId,
        });
      }
    }
    if (param.length > 0) {
      this.store.dispatch(new SaveBulkEdit(param)).subscribe((res) => {
        this.spinner.hide();
        this.isRecordSelected = false;
        if (res) {
          this.notifier.success(
            NotificationHeader.success,
            NotificationTextMessage.successMessage
          );
          this.getList();
        }
      });
    }
  }

  selectAll(event: any): void {
    this.bulkEditList.forEach((x) => (x.isSelected = event.checked));
    this.isRecordSelected = event.checked;
  }

  onAccoutChange(element: any): void {
    this.bulkEditList.forEach((y) => {
      if (y.isSelected && element.isSelected) {
        y.accountValue = element.accountValue;
        y.fundId = element.fundId;
      }
    });
  }

  onCheckBoxChanges(event: any, element: any): void {
    element.isSelected = event.checked;
    let temp = this.bulkEditList.find((x) => {
      x.isSelected === true;
    });

    if (temp) {
      this.bulkEditList.forEach((y) => {
        if (y.isSelected) {
          y.accountValue = element.accountValue;
          y.referenceNo = element.referenceNo;
        }
      });
    }

    let isCheckedList = this.bulkEditList.filter(
      (x: any) => x.isSelected === true
    );
    this.isRecordSelected = isCheckedList.length > 0 ? true : false;
    this.isAllSelected =
      isCheckedList.length === this.bulkEditList.length ? true : false;
  }

  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetBulkEditList(this.getParamter()))
      .subscribe((res) => {
        this.bulkEditList = res.bulkEdit.getBulkEdit;
      });
  }

  getDataFromHeader(data: any): void {
    this.listParameters = data;
    this.getList();
    this.getInvoiceTypeBasedOfAccount(this.listParameters.moduleId);
  }

  getOptionText(option) {
    return option?.name;
  }
}
