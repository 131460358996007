import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetNoteModel,
  GetNoteParamModel,
  NoteDataModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getNotesData(note: GetNoteParamModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<GetNoteModel>(
      `${environment.apiVersionUrl}Note/get`,
      this.commonService.trimObjectSpace(JSON.stringify(note)),
      headers
    );
  }

  saveNoteData(note: NoteDataModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Note/saveNoteData`,
      this.commonService.trimObjectSpace(JSON.stringify(note)),
      headers
    );
  }

  getAllNote(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.apiVersionUrl}Note/all`);
  }

  export(queryParams: GetNoteParamModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}Note/export`,
        JSON.stringify(queryParams),
        headers
      )
      .pipe(
        switchMap((response: any) => {
          const body: Blob = response.body || new Blob();
          if (queryParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  copyPreviousYearNote(queryParams: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Note/copyPreviousYearNote`,
      JSON.stringify(queryParams),
      headers
    );
  }
}
