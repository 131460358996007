import { Injectable } from '@angular/core';
import { BulkEditModel } from '@app/core/Models';
import { BulkEditService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetBulkEditList,
  GetInvoiceTypeBasedOfAccount,
  SaveBulkEdit,
} from './bulk-edit.action';

export class BulkEditStateInfo {
  getBulkEdit?: Array<BulkEditModel>;
  totalRecord?: number;
  actions?: any;
  invoiceType?: any;
}

@State<BulkEditStateInfo>({
  name: 'bulkEdit',
  defaults: {},
})
@Injectable()
export class BulkEditState {
  constructor(private bulkEditService: BulkEditService) {}

  @Selector()
  static totalRecord(state: BulkEditStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Action(SaveBulkEdit, { cancelUncompleted: true })
  saveBulkEdit(
    { patchState }: StateContext<BulkEditStateInfo>,
    action: SaveBulkEdit
  ) {
    return this.bulkEditService.saveBulkEdit(action.params).pipe(
      tap((res) => {
        patchState({});
      })
    );
  }

  @Action(GetBulkEditList, { cancelUncompleted: true })
  getBulkEditList(
    { patchState }: StateContext<BulkEditStateInfo>,
    action: GetBulkEditList
  ) {
    return this.bulkEditService.getBulkEditList(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            getBulkEdit: result.resultSet.data,
            totalRecord: result.paginationModel.totalItemCount,
            actions: result.actions,
          });
        },
      })
    );
  }

  @Action(GetInvoiceTypeBasedOfAccount)
  getInvoiceTypeBasedOfAccount(
    { getState, patchState }: StateContext<BulkEditStateInfo>,
    action: GetInvoiceTypeBasedOfAccount
  ) {
    return this.bulkEditService
      .getInvoiceTypeBasedOfAccount(action.invoiceTypeId)
      .pipe(
        tap((res) => {
          patchState({
            invoiceType: res,
          });
        })
      );
  }
}
