<div class="content">
  <div
    [ngClass]="[
      moduleId === moduleEnum.FixedAssetsRegister
        ? 'pt-1'
        : 'content-body content-body-scroll'
    ]"
  >
    <app-gift-aid-declaration
      *ngIf="moduleId === moduleEnum.DonationGiftAid"
      (triggerDataFromGiftAid)="getDataFromDonationGiftAid($event)"
    ></app-gift-aid-declaration>

    <app-transactions-header
      [hidden]="moduleId === moduleEnum.VAT"
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
      [transactionModuleId]="moduleId"
    >
    </app-transactions-header>

    <div
      class="mat-table-wrapper"
      [ngClass]="[
        totalSelectedRecords > 0 ? 'mat-table-wrapper-full' : '',
        showPaginator
          ? 'mat-table-wrapper-pagination'
          : 'mat-table-wrapper-pagination-full',
        (totalRecord$ | async) <= 10 ? 'mat-table-wrapper-full-view' : '',
        moduleId === moduleEnum.BankDashboard && isExpanded
          ? 'mt-1 mat-table-wrapper-accordian'
          : '',
        moduleId === moduleEnum.BankDashboard && !isExpanded
          ? 'mat-table-wrapper-accordian-full'
          : '',
        moduleId === moduleEnum.BankDashboard && !showPaginator
          ? 'mat-table-wrapper-accordian-pagination'
          : '',
        moduleId === moduleEnum.BankDashboard && !isExpanded && !showPaginator
          ? 'mat-table-wrapper-accordian-full-view'
          : '',
        moduleId === moduleEnum.FixedAssetsRegister
          ? 'mat-table-wrapper-depreciation'
          : ''
      ]"
    >
      <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
        <mat-icon class="material-icons-outlined">error_outline</mat-icon>
        {{ commonNotificationText.ViewOnlyAccessMessage }}
      </div>
      <table
        aria-describedby="main-list"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="dataSource"
        class="grid-table"
      >
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
            <div class="d-flex align-items-center">
              <span
                *ngIf="
                  moduleId !== moduleEnum.BankImportTransactionHistory &&
                  moduleId !== moduleEnum.DonationGiftAid &&
                  checkBoxValue !== 0
                "
              >
                <mat-checkbox
                  [(ngModel)]="isAllSelected"
                  (change)="selectAll($event)"
                  *ngIf="colIndex === 1"
                >
                </mat-checkbox>
              </span>
              <span mat-sort-header [disabled]="!col.name">
                {{ col.name }}
              </span>
            </div>
          </th>

          <td mat-cell *matCellDef="let element; let i = index">
            <div
              class="d-flex align-items-center button-wrapper"
              [ngClass]="
                colIndex === (columns$ | async).length - 1
                  ? 'justify-space-between'
                  : ''
              "
            >
              <mat-checkbox
                *ngIf="
                  moduleId !== moduleEnum.BankImportTransactionHistory &&
                  moduleId !== moduleEnum.DonationGiftAid &&
                  colIndex === 1
                "
                [(ngModel)]="element.isSelected"
                (change)="onCheckBoxSelected(element, $event)"
                [disabled]="isCheckboxDisabled(element)"
              >
              </mat-checkbox>
              <div [ngClass]="{ clamp: col.dataType !== dataType.DropDown }">
                <span
                  *ngIf="col.dataType === dataType.String"
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="{
                    'text-danger-100':
                      col.name === 'Account' &&
                      moduleId === moduleEnum.FixedAssetsRegister &&
                      element[element.length - 2].rowData === 'False',
                    'status-active':
                      element[colIndex].rowData === 'Paid' ||
                      element[colIndex].rowData === 'Allocated',
                    'status-archived':
                      element[colIndex].rowData === 'Due' ||
                      element[colIndex].rowData === 'OverDue' ||
                      element[colIndex].rowData === 'Partial allocated' ||
                      element[colIndex].rowData === 'Unallocated'
                  }"
                >
                  {{ element[colIndex].rowData }}</span
                >

                <span
                  *ngIf="col.dataType === dataType.Bool"
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="
                    element[colIndex].rowData === 'Active'
                      ? 'status-active'
                      : 'status-archived'
                  "
                >
                  {{ element[colIndex].rowData }}
                </span>

                <span
                  *ngIf="
                    col.dataType === dataType.URL &&
                    element[colIndex].id !== '' &&
                    element[colIndex].moduleId !== 0
                  "
                  title="{{ element[colIndex].rowData }}"
                >
                  <a
                    class="link clamp wp-100"
                    (click)="
                      redirect(element[colIndex].id, element[colIndex].moduleId)
                    "
                  >
                    {{ element[colIndex].rowData }}
                  </a>
                </span>

                <span
                  *ngIf="
                    col.dataType === dataType.URL &&
                    element[colIndex].id === '' &&
                    element[colIndex].moduleId === 0
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  {{ element[colIndex].rowData }}
                </span>

                <span
                  *ngIf="col.dataType === dataType.DateTime"
                  title="{{ element[colIndex].rowData }}"
                >
                  {{ element[colIndex].rowData | date: "dd-LLL-yyyy" }}</span
                >

                <span
                  *ngIf="
                    (col.dataType === dataType.Int32 ||
                      col.dataType === dataType.Currency) &&
                    element[colIndex].rowData !== ''
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  £ {{ element[colIndex].rowData | numberPipe }}</span
                >

                <span *ngIf="col.dataType === dataType.DropDown">
                  <ng-select
                    class="w-150"
                    *ngIf="+element[colIndex].rowData === 0"
                    #receipt
                    (focus)="receipt.open()"
                    (valueChange)="receipt.close()"
                    [disableOptionCentering]="true"
                    panelClass="mat-select-position"
                    (change)="receiptPaymentChange($event, element)"
                  >
                    <ng-option *ngFor="let fn of receiptList" [value]="fn">
                      <div title="{{ fn.name }}">{{ fn.name }}</div>
                    </ng-option>
                  </ng-select>

                  <ng-select
                    class="w-150"
                    *ngIf="+element[colIndex].rowData === 1"
                    #payment
                    (focus)="payment.open()"
                    (valueChange)="payment.close()"
                    [disableOptionCentering]="true"
                    panelClass="mat-select-position"
                    (change)="receiptPaymentChange($event, element)"
                  >
                    <ng-option *ngFor="let fn of paymentList" [value]="fn">
                      <div title="{{ fn.name }}">{{ fn.name }}</div>
                    </ng-option>
                  </ng-select>
                </span>

                <span
                  *ngIf="
                    col.dataType === dataType.Button &&
                    moduleId === moduleEnum.DonationGiftAid
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                  class="clamp"
                >
                  <div *ngIf="element[colIndex].rowData === '0'">
                    <button
                      class="action-button primary-button"
                      type="button"
                      mat-button
                      disableRipple
                      (click)="onSubmitToHMRC(element[0].rowData)"
                    >
                      Submit to HMRC
                    </button>
                  </div>
                  <div *ngIf="element[colIndex].rowData !== '0'">
                    <p class="text-primary pl-1 m-0">
                      {{ element[colIndex].rowData }}
                    </p>
                  </div>
                </span>

                <span *ngIf="col.dataType === dataType.Blob">
                  <button
                    mat-button
                    (click)="downloadFile(element[colIndex].rowData)"
                    class="fw-bold text-primary"
                    disableRipple
                  >
                    <mat-icon>file_download</mat-icon>
                  </button></span
                >
              </div>
              <div
                *ngIf="colIndex === (columns$ | async).length - 1"
                class="grid-actions"
                [ngClass]="selectedRowIndex === i ? 'show-button' : ''"
              >
                <div
                  class="d-flex align-items-center"
                  *ngFor="
                    let item of actions$ | async;
                    let permissionsIndex = index
                  "
                >
                  <button
                    *ngIf="
                      permissionsIndex < moreActionCount &&
                      moduleId !== moduleEnum.FixedAssetsRegister
                    "
                    mat-button
                    disableRipple
                    title="{{ item.name }}"
                    [ngClass]="[
                      ((this.moduleId === this.moduleEnum.Invoices ||
                        this.moduleId === this.moduleEnum.Bills) &&
                      (item.actionType === actionTypeEnum.AddReceipt ||
                        item.actionType === actionTypeEnum.AddPayment) &&
                      element[statusColIndex].rowData === 'Paid'
                        ? 'hidden'
                        : '') ||
                        ((this.moduleId === this.moduleEnum.CreditNote ||
                          this.moduleId === this.moduleEnum.DebitNote) &&
                        item.actionType === actionTypeEnum.AllocateRefund &&
                        element[statusColIndex].rowData === 'Allocated'
                          ? 'hidden'
                          : ''),
                      (isViewPermission$ | async) &&
                      (this.moduleId === this.moduleEnum.Invoices ||
                        this.moduleId === this.moduleEnum.RecurringInvoice) &&
                      (item.actionType === actionTypeEnum.Export ||
                        item.actionType === actionTypeEnum.Delete)
                        ? 'cursor-not-allowed'
                        : ''
                    ]"
                    [disabled]="
                      ((isViewPermission$ | async) &&
                        (this.moduleId === this.moduleEnum.Invoices ||
                          this.moduleId === this.moduleEnum.RecurringInvoice ||
                          this.moduleId === this.moduleEnum.Quotation ||
                          this.moduleId === this.moduleEnum.CreditNote ||
                          this.moduleId === this.moduleEnum.Receipt ||
                          this.moduleId === this.moduleEnum.Bills ||
                          this.moduleId === this.moduleEnum.RecurringBill ||
                          this.moduleId === this.moduleEnum.DebitNote ||
                          this.moduleId === this.moduleEnum.Payment ||
                          this.moduleId === this.moduleEnum.Donations ||
                          this.moduleId ===
                            this.moduleEnum.RecurringDonations ||
                          this.moduleId === this.moduleEnum.DonationsInKind ||
                          this.moduleId === this.moduleEnum.Customers ||
                          this.moduleId === this.moduleEnum.Suppliers ||
                          this.moduleId === this.moduleEnum.Donors ||
                          this.moduleId ===
                            this.moduleEnum.FixedAssetsRegister ||
                          this.moduleId === this.moduleEnum.FundList) &&
                        (item.actionType === actionTypeEnum.Export ||
                          item.actionType === actionTypeEnum.Delete)) ||
                      (item.actionType === actionTypeEnum.Update &&
                        element[colIndex + 1]?.rowData === 'True')
                    "
                    (click)="onButtonClick(element[0], item.actionType)"
                  >
                    <mat-icon fontSet="material-icons-outlined"
                      >{{ item.icon }}
                    </mat-icon>
                  </button>
                  <button
                    *ngIf="moduleId === moduleEnum.FixedAssetsRegister"
                    type="button"
                    mat-button
                    disableRipple
                    title="{{ item.name }}"
                    [disabled]="
                      (item.name === 'Sell' &&
                        element[element.length - 2].rowData !== 'True') ||
                      (item.name === 'Dispose' &&
                        element[element.length - 2].rowData !== 'True') ||
                      (item.name === 'RollBack' &&
                        element[element.length - 2].rowData !== 'True') ||
                      ((isViewPermission$ | async) &&
                        (item.actionType === actionTypeEnum.Explain ||
                          item.actionType === actionTypeEnum.RollBack))
                    "
                    (click)="onTOCButtonClick(element, item.actionType)"
                  >
                    <mat-icon>{{ item.icon }}</mat-icon>
                  </button>

                  <button
                    mat-button
                    class="more-button"
                    (click)="onToggleMatMenu(i)"
                    [matMenuTriggerFor]="menu"
                    (menuClosed)="allowCloseOnClickOut()"
                    disableRipple
                  >
                    <mat-icon *ngIf="permissionsIndex === moreActionCount"
                      >more_vert</mat-icon
                    >
                  </button>
                </div>

                <mat-menu
                  #menu="matMenu"
                  xPosition="before"
                  class="more-button-menu"
                >
                  <ng-container
                    *ngFor="
                      let item of actions$ | async;
                      let permissionsIndex = index
                    "
                  >
                    <div *ngIf="permissionsIndex > 2">
                      <button
                        mat-menu-item
                        disableRipple
                        title="{{ item.name }}"
                        [ngClass]="[
                          (item.actionType === actionTypeEnum.Update &&
                          element[colIndex + 1]?.rowData === 'True'
                            ? 'hidden'
                            : '') ||
                            ((this.moduleId === this.moduleEnum.Invoices ||
                              this.moduleId === this.moduleEnum.Bills) &&
                            (item.actionType === actionTypeEnum.AddReceipt ||
                              item.actionType === actionTypeEnum.AddPayment) &&
                            element[statusColIndex].rowData === 'Paid'
                              ? 'hidden'
                              : '') ||
                            ((this.moduleId === this.moduleEnum.CreditNote ||
                              this.moduleId === this.moduleEnum.DebitNote) &&
                            item.actionType === actionTypeEnum.AllocateRefund &&
                            element[statusColIndex].rowData === 'Allocated'
                              ? 'hidden'
                              : ''),
                          ((isViewPermission$ | async) &&
                            (item.actionType === actionTypeEnum.Delete ||
                              item.actionType === actionTypeEnum.Email)) ||
                          (item.actionType === actionTypeEnum.AddPayment &&
                            !isPaymentChangePermission) ||
                          (item.actionType === actionTypeEnum.AddReceipt &&
                            !isReceiptChangePermission)
                            ? 'cursor-not-allowed'
                            : ''
                        ]"
                        [disabled]="
                          ((isViewPermission$ | async) &&
                            (item.actionType === actionTypeEnum.Delete ||
                              item.actionType === actionTypeEnum.Email)) ||
                          (item.actionType === actionTypeEnum.AddPayment &&
                            !isPaymentChangePermission) ||
                          (item.actionType === actionTypeEnum.AddReceipt &&
                            !isReceiptChangePermission)
                        "
                        (click)="onButtonClick(element[0], item.actionType)"
                      >
                        <mat-icon fontSet="material-icons-outlined">{{
                          item.icon
                        }}</mat-icon>
                        <span>{{ item.name }}</span>
                      </button>
                    </div>
                  </ng-container>
                </mat-menu>
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsNames$ | async"
          [ngClass]="isRowHighlighted ? 'highlightRow' : ''"
        ></tr>
      </table>
      <div
        *ngIf="dataSource.data.length === 0 && noDataFound"
        class="text-align-center mt-1"
      >
        No records found
      </div>
    </div>
  </div>
  <div
    class="paginator-wrapper"
    *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async) > 0"
  >
    <app-custom-paginator
      *ngIf="(totalRecord$ | async) > 10"
      [length]="totalRecord$ | async"
      [listParameters]="listParameters"
      (pageChanged)="pageChanged($event)"
      (pageSizeVal)="pageSizeVal($event)"
      (togglePaginator)="togglePaginator($event)"
      [triggerPaginationChange]="triggerPaginationChange"
    >
    </app-custom-paginator>
  </div>
</div>

<app-card-footer
  *ngIf="totalSelectedRecords > 0"
  [totalSelectedRecords]="totalSelectedRecords"
  (deleteClick)="deleteClick()"
  (exportClick)="export($event, false)"
  (copyClick)="copyClick()"
  (cancelSelectionClick)="cancelSelectionClick()"
  (printClick)="printClick()"
  (emailClick)="emailClick()"
  (archiveAndRestoreClick)="archiveAndRestoreClick($event)"
  [ngClass]="
    this.listParameters.filter === filteredStatus.Archive
      ? 'archive-records'
      : ''
  "
  [getModuleId]="moduleId"
  [triggerSelectedRowData]="triggerSelectedRowData"
></app-card-footer>
<ngx-spinner
  template="<img class='loader-image mr-1' src='assets/images/loader.svg' />
  <span class='loader'></span>"
></ngx-spinner>
