import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModuleName, Modules, NotificationTextMessage } from '@app/core/Enum';
import { ViewDetails, ViewParamModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  EditReceipt,
  ExportReceipt,
  GetModuleDetailView,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-view-receipt',
  templateUrl: './view-receipt.component.html',
  styleUrls: ['./view-receipt.component.scss'],
})
export class ViewReceiptComponent implements OnInit {
  moduleViewDetails: ViewDetails;
  headerText: string;
  ids: Array<Guid>;
  editId: Array<Guid>;
  moduleId: any;
  modules = Modules;
  isRender = false;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  isJournalChangePermission: boolean = true;
  isRecieptChangePermission: boolean = true;
  isPaymentChangePermission: boolean = true;
  isJournalChangePermissionEdit: boolean = true;
  isRecieptChangePermissionEdit: boolean = true;
  isPaymentChangePermissionEdit: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ViewReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setHeader();
    this.spinner.show();

    const param: ViewParamModel = {
      id: this.data.id,
      moduleId: this.data.moduleId,
    };
    this.moduleId = this.data.moduleId;

    this.store.dispatch(new GetModuleDetailView(param)).subscribe((res) => {
      this.moduleViewDetails = res.common.moduleViewDetails.htmlContent;
      this.isRender = true;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isJournalChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Journals
      );
      this.isRecieptChangePermission = this.commonService.checkPermission(
        Modules.Income,
        Modules.Receipt
      );
      this.isPaymentChangePermission = this.commonService.checkPermission(
        Modules.Expenditure,
        Modules.Payment
      );

      this.isJournalChangePermissionEdit = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Journals,
        true
      );

      this.isRecieptChangePermissionEdit = this.commonService.checkPermission(
        Modules.Income,
        Modules.Receipt,
        true
      );

      this.isPaymentChangePermissionEdit = this.commonService.checkPermission(
        Modules.Expenditure,
        Modules.Payment,
        true
      );
    }, 500);
  }

  setHeader(): void {
    switch (this.data.moduleId) {
      case Modules.Invoices:
        this.headerText = ModuleName.Invoices;
        break;

      case Modules.Quotation:
        this.headerText = ModuleName.Quotation;
        break;

      case Modules.DebitNote:
        this.headerText = ModuleName.DebitNote;
        break;

      case Modules.CreditNote:
        this.headerText = ModuleName.CreditNote;
        break;

      case Modules.Bills:
        this.headerText = ModuleName.Bills;
        break;

      case Modules.FixedAssets:
        this.headerText = ModuleName.FixedAssets;
        break;

      case Modules.Journals:
        this.headerText = ModuleName.Journals;
        break;

      case Modules.Donations:
        this.headerText = ModuleName.Donations;
        break;

      case Modules.FixedAssetDetail:
        this.headerText = ModuleName.FixedAssetDetail;
        break;
    }
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onDownloadClick(): void {
    this.ids = [];
    this.ids.push(this.data.id);
    this.spinner.show();

    this.store
      .dispatch(new ExportReceipt(this.data.moduleId, this.ids, false))
      .subscribe();
  }

  onEditClick(): void {
    if (
      this.data.moduleId === Modules.Invoices ||
      this.data.moduleId === Modules.CreditNote ||
      this.data.moduleId === Modules.DebitNote ||
      this.data.moduleId === Modules.Bills ||
      this.data.moduleId === Modules.FixedAssets ||
      this.data.moduleId === Modules.Journals ||
      this.data.moduleId === Modules.Donations
    ) {
      this.ids = [];
      this.ids.push(this.data.id);
      switch (this.data.moduleId) {
        case Modules.Invoices:
        case Modules.CreditNote:
        case Modules.DebitNote:
        case Modules.Bills:
        case Modules.FixedAssets:
        case Modules.Journals:
        case Modules.Donations:
          this.editId = this.data.id;
          break;
      }

      this.store
        .dispatch(new EditReceipt(this.editId, this.data.moduleId))
        .subscribe((res) => {
          if (!res.common.isEdit) {
            this.commonService.onFailure(NotificationTextMessage.canNotEdit);
          } else {
            this.dialogRef.close(false);
            this.commonService.onEditRouting(
              true,
              this.data.moduleId,
              this.data.id
            );
          }
        });
    } else {
      this.dialogRef.close(false);
      this.commonService.onEditRouting(true, this.data.moduleId, this.data.id);
    }
  }
}
