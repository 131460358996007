import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit, Renderer2 } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  DepreciationMethodType,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { FixedAssetExplainModel, GroupListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateFixedAssetExplain,
  GetDataByAssetDetailId,
  GetFixedAssetAdditionList,
  GetJournalAccounts,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-asset-detail',
  templateUrl: './edit-asset-detail.component.html',
  styleUrls: ['./edit-asset-detail.component.scss'],
})
export class EditAssetDetailComponent implements OnInit {
  fixedAssetExplainData: FixedAssetExplainModel;
  referenceId = Guid.EMPTY as unknown as Guid;
  assetDetailForm: FormGroup;
  moduleId = Modules.FixedAssetsRegister;
  showDepreciationDetail = false;
  isOpeningBalanceAccount = false;
  maxAccumulatedDepreciationAmount = 0;
  datepipe: DatePipe;

  isRate = true;
  assetDetailData: any;
  methodTypeList = DepreciationMethodType;

  fixedAssetsTypeList: GroupListModel[];

  constructor(
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<EditAssetDetailComponent>,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.referenceId =
      data.id === undefined
        ? (Guid.EMPTY as unknown as Guid)
        : (atob(data.id!) as unknown as Guid);
    this.datepipe = injector.get<DatePipe>(DatePipe);
  }

  ngOnInit(): void {
    this.setForm();
    this.getFixedAssetAdditionList();
    this.spinner.show();
  }

  getFixedAssetAdditionList(): void {
    this.store.dispatch(new GetJournalAccounts()).subscribe((res) => {
      this.fixedAssetsTypeList = res.account.accounts;
      this.editAssetDetail();
    });
  }

  editAssetDetail(): void {
    this.store
      .dispatch(new GetDataByAssetDetailId(this.referenceId))
      .subscribe((res) => {
        this.assetDetailData = res.fixedAssetRegister.assetDetailData;
        this.editFixedAssetDetails(this.assetDetailData);
      });
  }

  getOptionText(option) {
    return option.name;
  }

  editFixedAssetDetails(data): void {
    let account;

    this.fixedAssetsTypeList.forEach((items) => {
      const accountId = items.listModels.find(
        (x: any) => x.id === data.bookAccountId
      );
      if (accountId !== null && accountId !== undefined) {
        account = accountId;
      }
    });
    this.assetDetailForm.patchValue({
      assetDetailName: data.name,
      account: account,
      acquiredDate: data.entryDate,
      price: data.totalAmount,
      isDepreciableAsset: data.isDepreciable,
      rate: data.rate,
      method: data.methodTypeId,
      isAutoDepreciate: data.isAutoDepreciate,
      accumulatedDepreciationAmount: data.accumulatedDepreciationAmount,
      depreciationUptoDate: data.depreciationUptoDate,
      depriciationStartDate: data.depriciationStartDate,
    });
    if (data.invoiceTypeId === 13) {
      this.isOpeningBalanceAccount = true;
      this.assetDetailForm.get('acquiredDate').enable();
      this.maxAccumulatedDepreciationAmount =
        data.maxAccumulatedDepreciationAmount;
      this.assetDetailForm.controls.accumulatedDepreciationAmount.clearValidators();
      this.assetDetailForm.controls.accumulatedDepreciationAmount.setValidators(
        [
          Validators.pattern(/^(?=.*[0-9])\d+(?:\.*\d\d?)?$/),
          Validators.max(+this.maxAccumulatedDepreciationAmount),
        ]
      );
      this.assetDetailForm.controls.accumulatedDepreciationAmount.updateValueAndValidity();
    }
  }

  setForm(): void {
    this.assetDetailForm = new FormGroup(
      {
        assetDetailName: new FormControl(''),
        account: new FormControl({ value: '', disabled: true }),
        acquiredDate: new FormControl({ value: '', disabled: true }),
        price: new FormControl({ value: '', disabled: true }),
        isDepreciableAsset: new FormControl(''),
        depreciationStartDate: new FormControl(''),
        rate: new FormControl('', [
          Validators.required,
          Validators.pattern(/^(?=.*[1-9])\d+(?:\.*\d\d?)?$/),
        ]),
        accumulatedDepreciationAmount: new FormControl(0, [
          Validators.pattern(/^(?=.*[0-9])\d+(?:\.*\d\d?)?$/),
        ]),
        isAutoDepreciate: new FormControl(''),
        method: new FormControl('', [Validators.required]),
        depreciationUptoDate: new FormControl(''),
        depriciationStartDate: new FormControl(''),
      },
      { validators: [this.rateValidator('rate')] }
    );
  }

  rateValidator(rate): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      if (!control.get(rate)?.value) return null;
      if (control.get(rate)?.value) {
        if (control.get(rate)?.value > 100) {
          control.controls.rate.status = 'INVALID';
          return null;
        } else {
          control.controls.rate.status = 'VALID';
          return { matchingFields: true };
        }
      } else {
        return null;
      }
    };
  }

  onDepreciableAsset(event: any): void {
    if (event.checked) {
      this.showDepreciationDetail = true;
    } else {
      this.showDepreciationDetail = false;
    }
  }

  onSave(): void {
    const invalid: any[] = [];
    const controls = this.assetDetailForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    if (invalid.length > 0) {
      this.assetDetailForm.markAllAsTouched();
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.fixedAssetExplainData) {
          this.store
            .dispatch(new CreateFixedAssetExplain(this.fixedAssetExplainData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                  this.dialogRef.close();
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.fixedAssetExplainData = {
        id: this.assetDetailData.id,
        name: this.assetDetailForm.controls.assetDetailName.value,
        bookAccountId: this.assetDetailData.bookAccountId,
        referenceId: this.assetDetailData.referenceId,
        methodTypeId: this.assetDetailForm.controls.method.value,
        rate: this.assetDetailForm.controls.rate.value,
        isDepreciable: this.assetDetailForm.controls.isDepreciableAsset.value,
        referenceTypeId: this.assetDetailData.referenceTypeId,
        accumulatedDepreciationAmount:
          this.assetDetailForm.controls.accumulatedDepreciationAmount.value ===
            null ||
          this.assetDetailForm.controls.accumulatedDepreciationAmount.value ===
            ''
            ? 0
            : +this.assetDetailForm.controls.accumulatedDepreciationAmount
                .value,
        isAutoDepreciate: this.assetDetailForm.controls.isAutoDepreciate.value,
        entryDate: this.datepipe
          .transform(
            this.assetDetailForm.controls.acquiredDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        depriciationStartDate: this.datepipe
          .transform(
            this.assetDetailForm.controls.depriciationStartDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        depreciationUptoDate:
          this.assetDetailForm.controls.depreciationUptoDate.value === null ||
          this.assetDetailForm.controls.depreciationUptoDate.value === ''
            ? null
            : this.datepipe
                .transform(
                  this.assetDetailForm.controls.depreciationUptoDate.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(): void {
    if (!this.commonService.isEmpty(this.referenceId)) {
      this.editAssetDetail();
    } else {
      this.referenceId = Guid.EMPTY as unknown as Guid;
      this.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  methodChange(event: any): void {
    if (event === 2) {
      this.isRate = false;
    } else if (event === 1) {
      this.isRate = true;
    } else {
      this.isRate = false;
    }
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  accumulatedDepreciationAmountChange(): void {
    const accumulatedDepreciationAmount =
      +this.assetDetailForm.controls.accumulatedDepreciationAmount.value;

    if (accumulatedDepreciationAmount > this.maxAccumulatedDepreciationAmount) {
      this.commonService.onFailure(
        'Accumulated Depreciation must be less than maximum allowed value or Asset value.'
      );
      this.assetDetailForm.controls.accumulatedDepreciationAmount.markAsDirty();
    }
  }
}
