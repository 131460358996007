import { Component, OnInit } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { MenuModel } from '@app/core/Models';
import { MenuState } from '@app/core/Store';
import { Select } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.scss'],
})
export class ReportDashboardComponent implements OnInit {
  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;
  reportlistitems: any[] = [];
  modules = Modules;

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.menuList$.subscribe((menuList) => {
      this.spinner.show();
      menuList.forEach((x) => {
        if (x.id === Modules.Report) {
          this.reportlistitems.push(x);
        }
      });
      this.spinner.hide();
    });
  }
}
