import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Observable, Subject, Subscription } from 'rxjs';

import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { DonationInKindModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateDonationInKind,
  GetDataByDonationInKindId,
  MenuState,
} from '@app/core/Store';
import {
  AddDonationComponent,
  TransactionsAttachmentComponent,
} from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-donation-in-kind',
  templateUrl: './donation-in-kind.component.html',
  styleUrls: ['./donation-in-kind.component.scss'],
})
export class DonationInKindComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  donationInKindId = Guid.EMPTY as unknown as Guid;

  moduleId = Modules.DonationsInKind;
  moduleName = ModuleName.DonationsInKind;

  subscriptionRouting: Subscription;
  donationInKindData: DonationInKindModel;

  triggerEditData: Subject<any> = new Subject<any>();

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddDonationComponent, { static: true })
  donationDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.donationInKindId = atob(params.get('id')!) as unknown as Guid;
          this.editDonationInKind();
        }
      }
    );
  }

  editDonationInKind(): void {
    this.store
      .dispatch(new GetDataByDonationInKindId(this.donationInKindId))
      .subscribe((res) => {
        this.donationDetails.donationForm.markAsUntouched();
        this.triggerEditData.next(res.donationInKind.donationInKindData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.donationInKindId,
      isExit,
      Modules.DonationsInKind,
      RoutingPath.AddDonationInKinds
    );
  }

  onSave(isExit: boolean): void {
    if (this.donationDetails.donationForm.invalid) {
      this.commonService.addValidation(
        this.donationDetails.donationForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        if (this.donationInKindData) {
          this.store
            .dispatch(new CreateDonationInKind(this.donationInKindData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.donationInKindData = {
        id: this.donationInKindId,
        donorId: this.donationDetails.donationForm.controls.donorName.value,
        sponsorEventId:
          this.donationDetails.donationForm.controls.sponsoredEvent.value === ''
            ? null
            : this.donationDetails.donationForm.controls.sponsoredEvent.value,
        activityId:
          this.donationDetails.donationForm.controls.activity.value === ''
            ? null
            : this.donationDetails.donationForm.controls.activity.value,
        fundId: this.donationDetails.donationForm.controls.fundName.value,
        donationDate: this.datepipe
          .transform(
            this.donationDetails.donationForm.controls.donationDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        donationTypeID:
          this.donationDetails.donationForm.controls.donationType.value,
        amount: this.donationDetails.donationForm.controls.amount.value,
        incomeAccount:
          this.donationDetails.donationForm.controls.incomeAccount.value.id,
        feeExpenseAccount:
          this.donationDetails.donationForm.controls.feeExpenseAccount.value.id,
        note: this.donationDetails.donationForm.controls.notes.value,
        attachment: filedata,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.donationInKindId)) {
      this.editDonationInKind();
    } else {
      this.donationInKindId = Guid.EMPTY as unknown as Guid;
      this.donationDetails.ngOnInit();
      this.attachmentDetail.showTable = false;
      this.attachmentDetail.fileUploadResponse = [];
      this.attachmentDetail.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
