<div class="mat-dialog-wrapper depreciation-list">
  <h2 mat-dialog-title>Edit Asset Detail</h2>
  <mat-dialog-content>
    <form [formGroup]="assetDetailForm">
      <p class="text-primary fw-bold">Asset Detail</p>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Name</p>
          <mat-form-field>
            <input
              matInput
              formControlName="assetDetailName"
              autocomplete="off"
              appAutoFocusDirective
              unicodeCharacter
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Account</p>
          <mat-form-field appearance="fill">
            <input
              type="text"
              matInput
              formControlName="account"
              required
              [matAutocomplete]="accountGroup"
            />
            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
            <mat-autocomplete
              [displayWith]="getOptionText"
              #accountGroup="matAutocomplete"
              (opened)="scrollIntoView(assetDetailForm.controls.account.value)"
            >
              <mat-optgroup
                *ngFor="let group of fixedAssetsTypeList"
                [label]="group.groupName"
                title="{{ group.groupName }}"
              >
                <mat-option
                  *ngFor="let names of group.listModels"
                  [value]="names"
                  title="{{ names.name }}"
                  [class]="
                    this.assetDetailForm.controls.account.value.id === names.id
                      ? 'mat-selected'
                      : ''
                  "
                >
                  {{ names.name }}
                </mat-option>
              </mat-optgroup>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field datepicker">
          <p>Acquired Date</p>
          <mat-form-field appearance="standard">
            <input
              matInput
              formControlName="acquiredDate"
              [matDatepicker]="acquiredDatePicker"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="acquiredDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #acquiredDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Price</p>
          <mat-form-field>
            <input
              matInput
              formControlName="price"
              autocomplete="off"
              appAutoFocusDirective
              unicodeCharacter
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="d-flex gap-40 align-items-center">
          <div class="checkbox mb-0">
            <ul>
              <li>
                <mat-checkbox
                  formControlName="isDepreciableAsset"
                  [(ngModel)]="showDepreciationDetail"
                  (change)="onDepreciableAsset($event)"
                >
                  Depreciable Asset
                </mat-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="showDepreciationDetail" class="mt-1">
        <p class="text-primary fw-bold">Depreciation Detail</p>
      </div>
      <div
        class="d-flex gap-40 align-items-center"
        *ngIf="showDepreciationDetail"
      >
        <div class="form-field">
          <p>Depreciation Start Date</p>
          <mat-form-field appearance="standard">
            <input
              matInput
              formControlName="depriciationStartDate"
              [matDatepicker]="depriciationStartDatePicker"
              autocomplete="off"
              [min]="assetDetailForm.controls.acquiredDate.value"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="depriciationStartDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #depriciationStartDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div
        class="d-flex gap-40 align-items-center"
        *ngIf="showDepreciationDetail"
      >
        <div class="form-field">
          <p>Method <span class="text-danger-300">*</span></p>
          <ng-select
            #method
            (focus)="method.open()"
            (valueChange)="method.close()"
            [disableOptionCentering]="true"
            formControlName="method"
            panelClass="mat-select-position"
            (change)="methodChange($event)"
          >
            <ng-option
              *ngFor="let font of methodTypeList | useEnumValues"
              [value]="font.key"
              title="{{ font.value }}"
            >
              <div title="{{ font.value }}">{{ font.value }}</div>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div
        class="d-flex gap-40 align-items-center"
        *ngIf="showDepreciationDetail"
      >
        <div class="form-field">
          <p *ngIf="isRate">Rate (%)</p>
          <p *ngIf="!isRate">Year</p>
          <mat-form-field>
            <input
              matInput
              formControlName="rate"
              autocomplete="off"
              appAutoFocusDirective
              allowDecimal
            />
          </mat-form-field>
        </div>
      </div>
      <div
        class="d-flex gap-40 align-items-center"
        *ngIf="showDepreciationDetail"
      >
        <div class="d-flex gap-40 align-items-center">
          <div class="checkbox mb-0">
            <ul>
              <li>
                <mat-checkbox formControlName="isAutoDepreciate">
                  Auto Depreciation
                </mat-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="d-flex gap-40 align-items-center"
        *ngIf="showDepreciationDetail && isOpeningBalanceAccount"
      >
        <div class="form-field datepicker">
          <p>Depreciation Upto</p>
          <mat-form-field appearance="standard">
            <input
              matInput
              formControlName="depreciationUptoDate"
              [matDatepicker]="depreciationUptoDatePicker"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="depreciationUptoDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #depreciationUptoDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div
        class="d-flex gap-40 align-items-center"
        *ngIf="showDepreciationDetail && isOpeningBalanceAccount"
      >
        <div class="form-field">
          <p>Accumulated Depreciation</p>
          <mat-form-field>
            <input
              matInput
              formControlName="accumulatedDepreciationAmount"
              autocomplete="off"
              appAutoFocusDirective
              (change)="accumulatedDepreciationAmountChange()"
              allow_10_2_Decimal
            />
          </mat-form-field>
          <p>
            <small>[ Max amount {{ maxAccumulatedDepreciationAmount }}]</small>
          </p>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <app-buttons
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave()"
    (triggerOnCancelClick)="onCancel()"
  >
  </app-buttons>
  <mat-dialog-actions>
    <button
      class="close-button"
      type="button"
      (click)="onCloseClick()"
      mat-button
      disableRipple
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
