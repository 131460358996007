<div
  class="import-setting-wrapper module-import"
  [ngClass]="(isViewPermission$ | async) ? 'is-pagination' : ''"
>
  <div
    class="content-body import-setting"
    [ngClass]="!(isViewPermission$ | async) ? 'top-pagination' : ''"
  >
    <p class="header-text d-flex justify-space-between align-items-center">
      Import {{ panelHeaderTitle }}
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div class="alert-message mb-10" *ngIf="!isModuleChangePermission">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.NoImportAccesssMessage }}
    </div>

    <div>
      <div [hidden]="showImport">
        <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
          <p class="fw-bold mb-1">Select Category</p>
          <div class="import-wrapper mb-1">
            <mat-radio-group
              [(ngModel)]="selectedModuleId"
              [ngModelOptions]="{ standalone: true }"
              (change)="radioChange($event.value)"
              aria-label="Select an option"
            >
              <div class="import-card" *ngFor="let element of selectCategory">
                <div class="import-check text-align-right">
                  <mat-radio-button
                    [value]="element.moduleId"
                  ></mat-radio-button>
                </div>
                <div class="import-body d-flex align-items-center gap-10">
                  <div class="import-img">
                    <mat-icon>{{ element.icon }}</mat-icon>
                  </div>
                  <div class="import-text">
                    <h2>{{ element.name }}</h2>
                  </div>
                </div>
              </div>
            </mat-radio-group>
          </div>
        </div>
        <div class="mb-1">
          <span>Import History</span>
          <app-report-list [isDirectImport]="!isDirectImport"></app-report-list>
        </div>
      </div>
      <app-import-file-data
        [hidden]="!showImport"
        [triggerImportDetail]="triggerImportDetail"
        [triggerImport]="triggerImport"
        [panelHeaderTitle]="panelHeaderTitle"
        [selectedModuleId]="selectedModuleId"
        [importDetailList]="importDetailList"
      ></app-import-file-data>
    </div>
    <div class="action-wrapper" *ngIf="!(isViewPermission$ | async)">
      <div class="content-body">
        <div class="button-container">
          <button
            *ngIf="
              this.importFileData.myStepper?.selectedIndex !== 1 &&
              this.importFileData.myStepper?.selectedIndex !== 2
            "
            class="action-button primary-button mr-1"
            type="button"
            mat-button
            disableRipple
            [disabled]="!isModuleChangePermission"
            (click)="onNextClick()"
          >
            Next
          </button>
          <button
            *ngIf="
              this.importFileData.myStepper?.selectedIndex === 1 &&
              this.importFileData?.importSuccessList &&
              (this.importFileData.importErrorList ||
                this.importFileData.importDuplicateList)
            "
            class="action-button primary-button mr-1"
            type="button"
            mat-button
            disableRipple
            (click)="onPrevClick()"
          >
            Back
          </button>
          <button
            *ngIf="
              this.importFileData.myStepper?.selectedIndex === 1 &&
              this.importFileData?.importSuccessList?.data?.length > 0 &&
              this.importFileData?.importErrorList?.data?.length === 0 &&
              this.importFileData?.importDuplicateList?.data?.length === 0
            "
            class="action-button primary-button mr-1"
            type="button"
            mat-button
            disableRipple
            (click)="onSaveNextClick()"
          >
            Save And Next
          </button>
          <button
            *ngIf="
              this.importFileData.myStepper?.selectedIndex === 1 &&
              (this.importFileData.importErrorList?.data?.length !== 0 ||
                this.importFileData.importDuplicateList?.data?.length !== 0)
            "
            class="action-button primary-button mr-1"
            type="button"
            mat-button
            disableRipple
            (click)="onIgnoreClick()"
          >
            Ignore And Continue
          </button>
        </div>
        <button
          *ngIf="this.importFileData.myStepper?.selectedIndex !== 2"
          class="action-button secondary-button"
          type="button"
          mat-button
          disableRipple
          (click)="onCancelClick()"
        >
          Cancel
        </button>
        <button
          *ngIf="this.importFileData.myStepper?.selectedIndex === 2"
          class="action-button secondary-button"
          type="button"
          mat-button
          disableRipple
          (click)="onCloseImportClick()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
