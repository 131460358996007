import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SponsorModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class SponsorService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createSponsor(sponsor: SponsorModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}SponsorEvent/save`,
      this.commonService.trimObjectSpace(JSON.stringify(sponsor)),
      headers
    );
  }

  getDataBySponsorId(sponsorId: Guid): Observable<SponsorModel> {
    return this.http.get<SponsorModel>(
      `${environment.apiVersionUrl}SponsorEvent/get/${sponsorId}`
    );
  }

  deleteSponsor(sponsorIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(sponsorIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}SponsorEvent/delete`,
      options
    );
  }

  copySponsor(sponsorIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}SponsorEvent/copySponsorEvents`,
      this.commonService.trimObjectSpace(JSON.stringify(sponsorIds)),
      headers
    );
  }

  archiveAndRestoreSponsor(
    sponsorIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}SponsorEvent/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(sponsorIds)),
      options
    );
  }
}
