<div class="content">
  <div
    class="content-body content-body-scroll"
    [ngClass]="{ 'mb-4': (totalRecord$ | async) > 10 && showPaginator }"
  >
    <app-transactions-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
      (triggerQuickAdd)="isQuickAdd($event)"
      [transactionModuleId]="moduleEnum.BankDashboard"
      [customId]="customId"
    >
    </app-transactions-header>
    <div>
      <app-bank-dashboard
        (triggerDataFromBank)="getDataFromBank($event)"
        (triggerIsCustomFlag)="checkIsCustom($event)"
      ></app-bank-dashboard>
    </div>
    <div
      class="mat-table-wrapper"
      [ngClass]="{ 'mat-table-wrapper-noData': noDataFound }"
    >
      <table
        mat-table
        [dataSource]="bankTransactions"
        aria-describedby="Bank-Transactions"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
      >
        <ng-container matColumnDef="srNo">
          <th mat-header-cell *matHeaderCellDef fxFlex="15" class="w-15">
            <mat-checkbox
              [(ngModel)]="isAllSelected"
              (change)="selectAll($event)"
              [disabled]="isCheckBoxDisabled"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="d-flex">
              <mat-checkbox
                [(ngModel)]="element.isSelected"
                [disabled]="isCheckBoxDisabled"
                (change)="onCheckBoxSelected(element, $event)"
              >
              </mat-checkbox>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date: "dd-LLL-yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="refNo">
          <th mat-header-cell *matHeaderCellDef>Ref No</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.refNo }}">
              <a
                *ngIf="
                  element.id !== undefined &&
                  element.id !== null &&
                  element.id !== '' &&
                  element.id !== commonService.defaultGuidValue
                "
                class="link maxw-100 clamp"
                (click)="redirect(element.id, element.moduleId)"
              >
                {{ element.refNo }}
              </a>
              <span
                *ngIf="
                  element.id === undefined ||
                  element.id === null ||
                  element.id === '' ||
                  element.id === commonService.defaultGuidValue
                "
              >
                {{ element.refNo }}
              </span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="contact">
          <th mat-header-cell *matHeaderCellDef>Contact</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.customerSupplierName }}">
              <a
                class="link maxw-100 clamp"
                (click)="
                  redirect(element.accountId, element.accountTypeModuleId)
                "
              >
                {{ element.customerSupplierName }}
              </a>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef>Reference</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="clamp"
            title="{{ element.notes }}"
          >
            {{ element.notes }}
          </td>
        </ng-container>

        <ng-container matColumnDef="receipts">
          <th mat-header-cell *matHeaderCellDef>Receipts</th>
          <td mat-cell *matCellDef="let element">
            {{ element.reciepts | numberPipe }}
          </td>
        </ng-container>

        <ng-container matColumnDef="payments">
          <th mat-header-cell *matHeaderCellDef>Payments</th>
          <td mat-cell *matCellDef="let element">
            {{ element.payments | numberPipe }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Balance">
          <th mat-header-cell *matHeaderCellDef>Balance</th>
          <td mat-cell *matCellDef="let element">
            {{ element.balance | numberPipe }}
          </td>
        </ng-container>

        <ng-container matColumnDef="transactionID">
          <th mat-header-cell *matHeaderCellDef>TransactionID</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="clamp"
            title="{{ element.transactionId }}"
          >
            {{ element.transactionId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span
              *ngIf="
                element.id !== undefined &&
                element.id !== null &&
                element.id !== '' &&
                element.id !== commonService.defaultGuidValue
              "
            >
              <span
                *ngIf="!element.isManual"
                class="text-danger-300"
                title="Unexplained"
                ><mat-icon>question_mark</mat-icon></span
              >
              <span
                *ngIf="element.isManual && !element.isImported"
                class="user"
                title="Manual"
                ><mat-icon>person</mat-icon></span
              >
              <span
                *ngIf="element.isManual && element.isImported"
                class="sucess"
                title="Imported"
                ><mat-icon>done</mat-icon></span
              >
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="explain">
          <th mat-header-cell *matHeaderCellDef class="w-120">Explain</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <ng-select
              class="w-120"
              [ngClass]="(isViewPermission$ | async) ? 'disable-drop' : ''"
              *ngIf="!element.isCredit && !element.isManual"
              #receipt
              (focus)="receipt.open()"
              (valueChange)="receipt.close()"
              [disableOptionCentering]="true"
              panelClass="mat-select-position"
              (change)="receiptPaymentChange($event, element)"
              [disabled]="isViewPermission$ | async"
            >
              <ng-option *ngFor="let fn of receiptList" [value]="fn">
                <div title="{{ fn.name }}">{{ fn.name }}</div>
              </ng-option>
            </ng-select>

            <ng-select
              class="w-120"
              [ngClass]="(isViewPermission$ | async) ? 'disable-drop' : ''"
              *ngIf="element.isCredit && !element.isManual"
              #payment
              (focus)="payment.open()"
              (valueChange)="payment.close()"
              [disableOptionCentering]="true"
              panelClass="mat-select-position"
              (change)="receiptPaymentChange($event, element)"
              [disabled]="isViewPermission$ | async"
            >
              <ng-option
                *ngFor="let fn of paymentList"
                [value]="fn"
                [disabled]="
                  (!isBankTranferChangePermission &&
                    fn.moduleId === moduleEnum.BankTransfer) ||
                  (!isJournalChangePermission &&
                    fn.moduleId === moduleEnum.Journals)
                "
              >
                <div title="{{ fn.name }}">{{ fn.name }}</div>
              </ng-option>
            </ng-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="w-50"></th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div
              class="grid-actions"
              *ngIf="
                element.id !== undefined &&
                element.id !== null &&
                element.id !== '' &&
                element.id !== commonService.defaultGuidValue
              "
              [ngClass]="{
                'show-button': selectedRowIndex === i,
                'hide-edit-button': !element.isManual
              }"
            >
              <div
                class="d-flex align-items-center"
                *ngFor="let item of actions; let permissionsIndex = index"
              >
                <span>
                  <button
                    *ngIf="permissionsIndex < moreActionCount"
                    mat-button
                    disableRipple
                    title="{{ item.name }}"
                    (click)="onButtonClick(element, item.actionType)"
                    [disabled]="
                      ((isViewPermission$ | async) &&
                        item.actionType === actionTypeEnum.Delete) ||
                      (element.moduleId === moduleEnum.Journals &&
                        !isJournalChangePermission &&
                        item.actionType === actionTypeEnum.Delete) ||
                      (element.moduleId === moduleEnum.Payment &&
                        !isPaymentChangePermission &&
                        item.actionType === actionTypeEnum.Delete) ||
                      (element.moduleId === moduleEnum.Receipt &&
                        !isRecieptChangePermission &&
                        item.actionType === actionTypeEnum.Delete) ||
                      (element.moduleId === moduleEnum.Donations &&
                        !isDonationChangePermission &&
                        item.actionType === actionTypeEnum.Delete)
                    "
                  >
                    <mat-icon fontSet="material-icons-outlined">{{
                      item.icon
                    }}</mat-icon>
                  </button>
                </span>
                <button
                  mat-button
                  class="more-button"
                  (click)="onToggleMatMenu(i)"
                  [matMenuTriggerFor]="menu"
                  (menuClosed)="allowCloseOnClickOut()"
                  disableRipple
                >
                  <mat-icon *ngIf="permissionsIndex === moreActionCount"
                    >more_vert</mat-icon
                  >
                </button>
              </div>

              <mat-menu
                #menu="matMenu"
                xPosition="before"
                class="more-button-menu"
              >
                <ng-container
                  *ngFor="let item of actions; let permissionsIndex = index"
                >
                  <div *ngIf="permissionsIndex > 2">
                    <button
                      mat-menu-item
                      disableRipple
                      title="{{ item.name }}"
                      (click)="onButtonClick(element, item.actionType)"
                    >
                      <mat-icon fontSet="material-icons-outlined">{{
                        item.icon
                      }}</mat-icon>
                      <span>{{ item.name }} </span>
                    </button>
                  </div>
                </ng-container>
              </mat-menu>
            </div>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="isRowHighlighted ? 'highlightRow' : ''"
        ></tr>
      </table>
      <div *ngIf="noDataFound" class="text-align-center mt-1">
        No records found
      </div>
    </div>
  </div>
  <div
    class="paginator-wrapper"
    *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async) > 0"
  >
    <app-custom-paginator
      *ngIf="(totalRecord$ | async) > 10"
      [length]="totalRecord$ | async"
      [listParameters]="listParameters"
      (pageChanged)="pageChanged($event)"
      (pageSizeVal)="pageSizeVal($event)"
      (togglePaginator)="togglePaginator($event)"
      [triggerPaginationChange]="triggerPaginationChange"
    >
    </app-custom-paginator>
  </div>
</div>

<app-card-footer
  #cardFooter
  *ngIf="totalSelectedRecords > 0"
  [totalSelectedRecords]="totalSelectedRecords"
  (deleteClick)="deleteClick()"
  (exportClick)="export($event, false)"
  (printClick)="printClick()"
  (cancelSelectionClick)="cancelSelectionClick()"
  [getModuleId]="moduleId$ | async"
></app-card-footer>
