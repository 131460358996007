import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createActivity(activity: ActivityModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Activity/save`,
      this.commonService.trimObjectSpace(JSON.stringify(activity)),
      headers
    );
  }

  getDataByActivityId(activityId: Guid): Observable<ActivityModel> {
    return this.http.get<ActivityModel>(
      `${environment.apiVersionUrl}Activity/get/${activityId}`
    );
  }

  deleteActivity(activityIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(activityIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Activity/delete`,
      options
    );
  }

  copyActivity(activityIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Activity/copyActivity`,
      this.commonService.trimObjectSpace(JSON.stringify(activityIds)),
      headers
    );
  }

  archiveAndRestoreActivity(
    activityIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Activity/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(activityIds)),
      options
    );
  }
}
