<div class="content-body">
  <form [formGroup]="receiptForm">
    <div class="d-flex gap-40">
      <div
        class="form-field"
        *ngIf="
          getModuleName === moduleName.Receipt ||
          getModuleId === moduleId.Payment
        "
      >
        <p>Type <span class="text-danger-300">*</span></p>
        <ng-select
          #selectType
          (focus)="selectType.open()"
          (valueChange)="selectType.close()"
          [disableOptionCentering]="true"
          formControlName="accountTypeId"
          dropdownPosition="top"
          panelClass="mat-select-position"
          (change)="onAccountTypeChange($event)"
          appAutoFocusDirective
        >
          <ng-option *ngFor="let tt of accountTypeList" [value]="tt.id">
            <div class="clamp" title="{{ tt.name }}">{{ tt.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field wap-40">
        <p>{{ accountLabelText }} <span class="text-danger-300">*</span></p>
        <ng-select
          #accountList
          (focus)="accountList.open()"
          (valueChange)="accountList.close()"
          [disableOptionCentering]="true"
          formControlName="accountId"
          panelClass="mat-select-position"
        >
          <ng-option
            *ngFor="let option of receiptAccountList"
            [value]="option.id"
          >
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div
        class="form-field"
        *ngIf="
          getModuleName === moduleName.CashEntry ||
          getModuleName === moduleName.BankEntry
        "
      >
        <p>Transaction Type</p>
        <ng-select
          #accountType
          (focus)="accountType.open()"
          (valueChange)="accountType.close()"
          [disableOptionCentering]="true"
          (change)="onTransactionTypeChange()"
          formControlName="transactionType"
          dropdownPosition="top"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let al of transactionType" [value]="al.id">
            <div class="clamp" title="{{ al.name }}">{{ al.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div
        *ngIf="
          getModuleId !== moduleId.BankEntry &&
          getModuleId !== moduleId.CashEntry
        "
        class="form-field quick-add-buttons"
      >
        <div class="d-flex justify-space-between">
          <p>{{ customerLabelText }} <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isCustomerSupplierChangePermission"
            >
              <mat-icon (click)="gotoAddCustomer()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="
                disabledEditButton || !isCustomerSupplierChangePermission
              "
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditCustomer()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          *ngIf="
            getModuleId !== moduleId.BankEntry &&
            getModuleId !== moduleId.CashEntry
          "
          #customerName
          [disableOptionCentering]="true"
          (focus)="customerName.open()"
          (valueChange)="customerName.close()"
          formControlName="customerName"
          (change)="onCustomerChange($event)"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let option of customerList" [value]="option.id">
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>

      <div
        *ngIf="
          getModuleId === moduleId.BankEntry ||
          getModuleId === moduleId.CashEntry
        "
        class="form-field quick-add-buttons"
      >
        <p>Account Name <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="accoutListBank"
            required
            [matAutocomplete]="accountGroup2"
            (keyup)="onAccountSearch2($event)"
            (mousedown)="resetAccountList()"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            (opened)="scrollIntoView(receiptForm.controls.accoutListBank.value)"
            #accountGroup2="matAutocomplete"
            (optionSelected)="accountGroupSelected($event, false)"
          >
            <mat-optgroup
              *ngFor="let group of accountGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.receiptForm.controls.accoutListBank.value.id === names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="form-field">
        <p>{{ getModuleName }} No</p>
        <mat-form-field>
          <input
            matInput
            formControlName="code"
            maxlength="{{ maxLength.maxLength50 }}"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>
          {{
            (moduleId$ | async) === moduleId.Receipt
              ? "Receipt Date"
              : "Payment Date"
          }}
        </p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="receiptDate"
            [matDatepicker]="receiptDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="receiptDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #receiptDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(receiptForm.get('receiptDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(receiptForm.get('receiptDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field">
        <p>Currency <span class="text-danger-300">*</span></p>
        <ng-select
          #currency
          (focus)="currency.open()"
          (valueChange)="currency.close()"
          [disableOptionCentering]="true"
          formControlName="currency"
          dropdownPosition="top"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
            <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
              {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
            </div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p *ngIf="getModuleName !== moduleName.Receipt">
          Amount Received <span class="text-danger-300">*</span>
        </p>
        <p *ngIf="getModuleName === moduleName.Receipt">
          Amount Paid <span class="text-danger-300">*</span>
        </p>
        <mat-form-field>
          <input
            allow_14_2_Decimal
            matInput
            zeroNotAllowed
            formControlName="amountReceived"
            autocomplete="off"
            allowDecimal
            (change)="onAmountReceivedChange()"
          />
        </mat-form-field>
      </div>
      <div
        class="d-flex align-items-center"
        *ngIf="
          getModuleId !== moduleId.BankEntry &&
          getModuleId !== moduleId.CashEntry &&
          accountingMehtodId !== accountingMethod.CashBasis
        "
      >
        <mat-checkbox
          formControlName="isAdvance"
          (change)="onCheckBoxSelected($event)"
        >
          {{
            (moduleId$ | async) === moduleId.Receipt
              ? "Make Advance Receipt"
              : "Make Advance Payment"
          }}
        </mat-checkbox>
      </div>
    </div>
    <div class="form-field wp-100 textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          maxlength="{{ maxLength.maxLength2000 }}"
          formControlName="note"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div [hidden]="!showReceiptDetails">
  <app-receipt-details
    (amountReceived)="onAmountReceivedChange()"
    [triggerCustomer]="triggerCustomer"
    [triggerReceiptDetail]="triggerReceiptDetail"
    [getModuleId]="getModuleId"
    [amountReceivedValue]="amountReceivedValue"
    [triggerTransactionLogData]="triggerTransactionLogData"
  ></app-receipt-details>
</div>
