import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DashboardOverview,
  Modules,
  OverviewYear,
  RoutingPath,
  DashboardAgeingSummaryType,
} from '@app/core/Enum';
import { FilterDateRange } from '@app/core/Models';
import { CommonService, DashboardService } from '@app/core/Services';
import { GetDashboardCard, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-v1',
  templateUrl: './dashboard-v1.component.html',
  styleUrls: ['./dashboard-v1.component.scss'],
})
export class DashboardComponentv1 implements OnInit, OnDestroy {
  moduleId = Modules.Dashboard;
  module = Modules;
  @Output()
  readonly triggerHeaderFilter = new EventEmitter<any>();

  @Output()
  readonly triggerAgeingSummary = new EventEmitter<any>();

  selectedYearValue = OverviewYear['Last 30 days'];
  selectYear = OverviewYear;
  rangeFormGroup: FormGroup;
  ageingSummaryValue = DashboardAgeingSummaryType.Receivables.toString();
  dashboardCard: any;

  showCalender = false;
  dateRange: any;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  private setDashboardDetailsEmitter;
  isBankOverviewChangePermission: boolean = true;
  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    public commonService: CommonService,
    public dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );
    this.moduleId$.subscribe((moduleId) => {
      if (moduleId === Modules.Dashboard) {
        this.setForm();
      }
    });

    this.setDashboardDetailsEmitter =
      this.dashboardService.setReloadDashboardEmitter.subscribe((isReload) => {
        this.setForm();
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isBankOverviewChangePermission = this.commonService.checkPermission(
        Modules.Bank,
        Modules.BankDashboard
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    if (
      this.setDashboardDetailsEmitter !== null &&
      this.setDashboardDetailsEmitter !== undefined
    ) {
      this.setDashboardDetailsEmitter.unsubscribe();
    }
  }

  setForm(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });

    this.getData();
  }

  ageingSummaryChange(): void {
    const data = this.getFilter();
    data.moduleId = this.ageingSummaryValue;
    this.triggerAgeingSummary.emit(data);
  }

  goToBankBook(): void {
    this.router.navigate([RoutingPath.BankDashboard]);
  }

  goToCashBook(): void {
    this.router.navigate([RoutingPath.CashEntryList]);
  }

  onSelectionChange(val: any): void {
    this.showCalender = +val === 7;
    this.dateRange = this.commonService.generateDateSeries(val);
    if (
      this.showCalender &&
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.dateRange = new FilterDateRange();
      this.dateRange.startDate = new Date(
        this.rangeFormGroup.controls.start.value
      );
      this.dateRange.endDate = new Date(this.rangeFormGroup.controls.end.value);
      this.getData();
    } else if (!this.showCalender) {
      this.getData();
    }
    this.getCardData();
  }

  getFilter(): any {
    const filterData = {
      moduleId: 0,
      dateFilter: {
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(
                  this.rangeFormGroup.controls.start.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),

        endDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(this.rangeFormGroup.controls.end.value, 'yyyy-MM-dd')
                ?.toString(),
      },
    };

    return filterData;
  }

  getData(): void {
    this.getCardData();
    const data = this.getFilter();

    setTimeout(() => {
      this.triggerHeaderFilter.emit(data);
      data.moduleId = this.ageingSummaryValue;
      this.triggerAgeingSummary.emit(data);
    }, 500);
  }

  getCardData(): void {
    const data = this.getFilter();
    this.store.dispatch(new GetDashboardCard(data)).subscribe((res) => {
      this.dashboardCard = res.dashboard.dashboardCard;
    });
  }
}
