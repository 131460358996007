<div class="content-body">
  <form [formGroup]="activitiesForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Activity Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="name"
            maxlength="{{ maxLength.maxLength200 }}"
            autocomplete="off"
            appAutoFocusDirective
            unicodeCharacter
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Activity Code <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="code"
            autocomplete="off"
            required
            maxlength="{{ maxLength.maxLength50 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Description</p>
        <mat-form-field>
          <input
            matInput
            maxlength="{{ maxLength.maxLength200 }}"
            formControlName="description"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-checkbox formControlName="isDefault"> Default </mat-checkbox>
      </div>
    </div>
  </form>
</div>
