import { Injectable } from '@angular/core';
import { ProductModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { GetProductList, GetProductListByProductType } from './product.action';

export class ProductStateInfo {
  product: Array<ProductModel>;
}

@State<ProductStateInfo>({
  name: 'product',
  defaults: {
    product: [],
  },
})
@Injectable()
export class ProductState {
  constructor(private commonService: CommonService) {}

  @Selector()
  static getProduct(state: ProductStateInfo) {
    return state.product;
  }

  @Action(GetProductList, { cancelUncompleted: true })
  getProductList(
    { getState, setState }: StateContext<ProductStateInfo>,
    action: GetProductList
  ) {
    return this.commonService.getProductList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          product: res,
        });
      })
    );
  }

  @Action(GetProductListByProductType)
  getProductListByProductType(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetProductListByProductType
  ) {
    return this.commonService
      .getProductListByProductType(action.productType)
      .pipe(
        tap((res) => {
          patchState({
            product: res,
          });
        })
      );
  }
}
