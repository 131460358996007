<div id="chart" *ngIf="!isNoRecord && isloadData">
  <apx-chart
    [series]="expensesChartOptionv1?.series!"
    [chart]="expensesChartOptionv1?.chart!"
    [labels]="expensesChartOptionv1?.labels!"
    [tooltip]="expensesChartOptionv1?.tooltip!"
    [responsive]="expensesChartOptionv1?.responsive!"
    [legend]="expensesChartOptionv1?.legend!"
    [plotOptions]="expensesChartOptionv1.plotOptions!"
    [fill]="expensesChartOptionv1?.fill!"
    [noData]="expensesChartOptionv1?.noData!"
    [dataLabels]="expensesChartOptionv1.dataLabels!"
    [xaxis]="expensesChartOptionv1.xaxis!"
    [yaxis]="expensesChartOptionv1.yaxis!"
    [grid]="expensesChartOptionv1.grid!"
    [lines]="expensesChartOptionv1.lines!"
  ></apx-chart>
</div>

<div class="norecord-chart" *ngIf="isNoRecord && isloadData">
  <img src="assets/images/column-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
