export enum AccountEntity {
  Supplier = 1,
  Customer = 2,
  Donor = 3,
  FixedAssets = 4,
  Bank = 5,
  Cash = 6,
  JV = 7,
  Fund = 8,
}
