import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SaveOpeningBalanceModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '..';
@Injectable({
  providedIn: 'root',
})
export class AccountProductionService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  refreshAccountProductionTrialBalance(
    accountingPeriodId: Guid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountProduction/refreshTrialBalance/${accountingPeriodId}`,
      headers
    );
  }

  getAccountProductionTrialBalance(
    accoutingPeriodId: Guid
  ): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}AccountProduction/getBasedOnAccountingPeriod/${accoutingPeriodId}`
    );
  }

  saveTrialBalance(trialBalance: SaveOpeningBalanceModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountProduction/saveTrialBalance`,
      this.commonService.trimObjectSpace(JSON.stringify(trialBalance)),
      headers
    );
  }
}
