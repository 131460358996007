import { SponsorModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateSponsor {
  static readonly type = '[SPONSOR] Create Sponsor';

  constructor(public sponsor: SponsorModel) {}
}

export class GetDataBySponsorId {
  static readonly type = '[SPONSOR] Get Sponsor Data By Sponsor Id';

  constructor(public sponsorId: Guid) {}
}

export class ArchiveAndRestoreSponsor {
  static readonly type = '[SPONSOR] Archive and Restore Selected Sponsor';

  constructor(public sponsorIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteSponsor {
  static readonly type = '[SPONSOR] Delete Selected Sponsor';

  constructor(public sponsorIds: Array<Guid>) {}
}
