import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ComponentName,
  ModuleName,
  Modules,
  RoutingPath,
} from '@app/core/Enum';
import { ReportFilterTypes } from '@app/core/Enum/report-filter-type';
import { ModulePermission } from '@app/core/Services';
import { GetDashboardData, GetOverviewData, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  filter1 = ReportFilterTypes.Last30Days;
  filter2 = ReportFilterTypes.Last30Days;
  filter3 = ReportFilterTypes.Last30Days;

  selectYear = [
    {
      id: 0,
      name: 'Last 30 days',
    },
    {
      id: 1,
      name: 'This month',
    },
    {
      id: 2,
      name: 'This quarter',
    },
    {
      id: 3,
      name: 'This year',
    },
    {
      id: 4,
      name: 'Last month',
    },
    {
      id: 5,
      name: 'Last quarter',
    },
    {
      id: 6,
      name: 'Last year',
    },
    {
      id: 7,
      name: 'Custom',
    },
  ];

  @Output()
  readonly triggerIncomeData = new EventEmitter<any>();

  @Output()
  readonly triggerExpensesData = new EventEmitter<any>();

  @Output()
  readonly triggerSummaryIncomeData = new EventEmitter<any>();

  @Output()
  readonly triggerSummaryExpensesData = new EventEmitter<any>();

  @Output()
  readonly triggerIncomeVsExpensesData = new EventEmitter<any>();

  @Output()
  readonly triggerCashFlow = new EventEmitter<any>();

  selectedTab = 0;
  dashboardData: any;
  fundDashboardData: any;
  incomeAmount = 0;
  totalIncomeAmount = 0;
  expenseAmount = 0;
  totalExpenseAmount = 0;

  totalBroughtForward = 0;
  totalIncome = 0;
  totalExpense = 0;
  theTotalBalance = 0;

  bank = 0;
  cashInHand = 0;

  showCalender = false;
  moduleId = Modules.Dashboard;
  module = Modules;
  moduleName = ModuleName;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Output()
  readonly triggerOverviewData = new EventEmitter<any>();

  isloaded = false;
  rangeFormGroup: FormGroup;

  constructor(
    private store: Store,
    private router: Router,
    private modulePermission: ModulePermission,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
      if (value.componentName === ComponentName.Dashboard) {
        this.dashboardOverviewData();
        if (this.moduleId === Modules.FundOverview) {
          this.fundOverviewData();
        }
      }
    });
  }
  setForm(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }
  fundOverviewData(): void {
    const filterData = {
      filter1: {
        filter: this.filter1,
        startDate:
          this.filter1 < 7
            ? undefined
            : this.rangeFormGroup.controls.start.value,
        endDate:
          this.filter1 < 7 ? undefined : this.rangeFormGroup.controls.end.value,
      },

      filter2: {
        filter: this.filter2,
        startDate:
          this.filter2 < 7
            ? undefined
            : this.rangeFormGroup.controls.start.value,
        endDate:
          this.filter2 < 7 ? undefined : this.rangeFormGroup.controls.end.value,
      },

      filter3: {
        filter: this.filter3,
        startDate:
          this.filter3 < 7
            ? undefined
            : this.rangeFormGroup.controls.start.value,
        endDate:
          this.filter3 < 7 ? undefined : this.rangeFormGroup.controls.end.value,
      },
    };

    this.store
      .dispatch(new GetOverviewData(filterData, this.moduleId))
      .subscribe((x) => {
        this.isloaded = true;
        setTimeout(() => {
          this.fundDashboardData = x.overview.overviewData;
          this.totalBroughtForward = this.fundDashboardData.card.totalAmount;
          this.totalIncome = this.fundDashboardData.card1.totalAmount;
          this.totalExpense = this.fundDashboardData.card2.totalAmount;
          this.theTotalBalance = this.fundDashboardData.card3.totalAmount;
          this.triggerIncomeData.emit(this.fundDashboardData.pieChart);
          this.triggerExpensesData.emit(this.fundDashboardData.pieChart1);
          this.triggerIncomeVsExpensesData.emit(
            this.fundDashboardData.incomeExpense
          );
        }, 1000);
      });
  }

  dashboardOverviewData(): void {
    this.store.dispatch(new GetDashboardData()).subscribe((x) => {
      this.isloaded = true;
      setTimeout(() => {
        this.dashboardData = x.dashboard.dashboradData;
        this.incomeAmount = this.dashboardData.dashboard.income.amount;
        this.totalIncomeAmount =
          this.dashboardData.dashboard.income.totalAmount;
        this.expenseAmount = this.dashboardData.dashboard.expense.amount;
        this.totalExpenseAmount =
          this.dashboardData.dashboard.expense.totalAmount;
        this.bank = this.dashboardData.dashboard.bank.totalAmount;
        this.cashInHand = this.dashboardData.dashboard.cashInHand.totalAmount;

        this.triggerIncomeData.emit(x.dashboard.dashboradData.income);
        this.triggerExpensesData.emit(x.dashboard.dashboradData.expense);
        this.triggerSummaryIncomeData.emit(
          x.dashboard.dashboradData.receivable
        );
        this.triggerIncomeVsExpensesData.emit(
          x.dashboard.dashboradData.incomeExpense
        );
        this.triggerCashFlow.emit(x.dashboard.dashboradData.cashFlow);
      }, 100);
    });
  }

  tabClick(event: any): void {
    this.selectedTab = event;
    window.dispatchEvent(new Event('resize'));
    this.selectedTab === 1
      ? this.triggerSummaryExpensesData.emit(this.dashboardData.payable)
      : this.triggerSummaryIncomeData.emit(this.dashboardData.receivable);
  }

  goToBankBook(): void {
    this.router.navigate([RoutingPath.BankDashboard]);
  }

  goToCashBook(): void {
    this.router.navigate([RoutingPath.CashEntryList]);
  }

  onSelectionChange(val: any): void {
    this.showCalender = val === 7;
    if (
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.fundOverviewData();
    }
  }
}
