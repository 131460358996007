<div class="vat-Settings content-body-wrapper">
  <div class="content-body">
    <div class="vat-Settings-info">
      <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
        [(selectedIndex)]="selectedTab"
        (selectedTabChange)="onTabClick($event)"
      >
        <mat-tab label="Vat Settings">
          <app-add-vat-settings
            [hidden]="selectedTab !== 0"
            [triggerEditData]="triggerEditData"
          >
          </app-add-vat-settings>
        </mat-tab>
        <mat-tab label="Vat Codes">
          <div
            [ngClass]="selectedTab === 1 ? '' : 'hidden'"
            class="vat-settings-list"
          >
            <app-main-list></app-main-list>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <app-buttons
    *ngIf="selectedTab !== 1 && !(isViewPermission$ | async)"
    [getModuleId]="moduleId"
    [saveButtonShow]="true"
    (triggerOnSaveClick)="onSave($event.isExit)"
    (triggerOnCancelClick)="onCancel()"
  >
  </app-buttons>
</div>
