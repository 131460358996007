import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
  RecurringType,
  ScheduleSubPeriod,
  ScheduleType,
} from '@app/core/Enum';
import {
  ChartOfAccountListParam,
  Currency,
  GlobalComponent,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  AccountState,
  CommonState,
  CreateAccount,
  CreateContact,
  CreateFund,
  GetCurrencyList,
  GetDataByAccountId,
  GetDataByFundId,
  GetFundNameList,
  GetGroupAccountsBasedOnGroupId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetNonStandardAccountList,
  GetTermsList,
  MenuState,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-recurring-module',
  templateUrl: './add-recurring-module.component.html',
  styleUrls: ['./add-recurring-module.component.scss'],
})
export class AddRecurringModuleComponent implements OnInit {
  recurringForm: FormGroup;

  currencyList: Currency[];
  accountList: SideListModel[];
  weekList: string[] = [];
  monthList: string[] = [];
  dayList: any[] = [];
  fundNameList: any;
  dueTermList: any;
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];
  getListId: Guid;

  @Input() triggerEditData: Observable<any>;
  @Input() moduleId: number;
  subModuleId = Modules;
  @Input()
  getModuleId: number;
  subscriptionRouting: Subscription;
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  minEndDate: Date;
  minStartDate: Date;
  periodicDate: any;

  recurringType = RecurringType;
  scheduleSubPeriod = ScheduleSubPeriod;
  scheduleType = ScheduleType;
  moduleEnum = Modules;
  defaultCurrency: Guid;
  disabledFundEditButton = true;

  recurringTypeList: any[] = [
    {
      value: RecurringType.Daily,
      name: RecurringType[RecurringType.Daily],
    },
    {
      value: RecurringType.Weekly,
      name: RecurringType[RecurringType.Weekly],
    },
    {
      value: RecurringType.Monthly,
      name: RecurringType[RecurringType.Monthly],
    },
    {
      value: RecurringType.Yearly,
      name: RecurringType[RecurringType.Yearly],
    },
    {
      value: RecurringType.Custom,
      name: RecurringType[RecurringType.Custom],
    },
  ];

  scheduleSubPeriodList: any[] = [
    {
      value: ScheduleSubPeriod.Day,
      name: 'Day(s)',
    },
    {
      value: ScheduleSubPeriod.Week,
      name: 'Week(s)',
    },
    {
      value: ScheduleSubPeriod.Month,
      name: 'Month(s)',
    },
    {
      value: ScheduleSubPeriod.Year,
      name: 'Year(s)',
    },
  ];

  scheduleTypeList: any[] = [
    {
      value: ScheduleType.After,
      name: ScheduleType[ScheduleType.After],
    },
    {
      value: ScheduleType.On,
      name: ScheduleType[ScheduleType.On],
    },
    {
      value: ScheduleType.Never,
      name: ScheduleType[ScheduleType.Never],
    },
  ];

  isReadOnly = false;
  disabledEditButton = true;
  private destroy$ = new Subject<void>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  isCustomerChangePermission: boolean = true;
  isFundChangePermission: boolean = true;

  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {
    this.minStartDate = new Date();
    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minEndDate.getDate() + 1);
  }

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();

    this.triggerEditData
      .pipe(
        switchMap((data) => {
          this.isReadOnly = true;
          return this.loadDropdownValues().pipe(map(() => data));
        })
      )
      .subscribe((data) => {
        this.editRecurringData(data);
      });

    this.recurringForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.recurringForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCustomerChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Customers
      );
      this.isFundChangePermission = this.commonService.checkPermission(
        Modules.Funds,
        Modules.FundList
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChangesCustomerName(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  loadDropdownValues(): Observable<any> {
    const groupNames = new Array<number>();
    this.moduleId$.subscribe((x) => {
      groupNames.push(
        x === Modules.RecurringInvoice ? GroupNames.Income : GroupNames.Expense
      );
    });
    let entityId = new Array<number>();

    entityId.push(
      this.moduleId === Modules.RecurringInvoice
        ? AccountEntity.Customer
        : AccountEntity.Supplier
    );

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: this.commonService.invoiceTypeList,
    };

    this.defaultCurrency = this.globalComponent.getDefaultCurrency();

    let invoiceType;
    if (this.moduleId === Modules.RecurringInvoice) {
      invoiceType = this.store.dispatch(
        new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
      );
    } else {
      invoiceType = this.store.dispatch(
        new GetGroupAccountsBasedOnGroupId(groupNames)
      );
    }

    const fundNameList = this.store.dispatch(new GetFundNameList());
    const accountType = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );
    const getCurrency = this.store.dispatch(new GetCurrencyList());
    const dueTermList = this.store.dispatch(new GetTermsList());

    return forkJoin([
      invoiceType,
      fundNameList,
      accountType,
      getCurrency,
      dueTermList,
    ]).pipe(
      tap((res) => {
        const accountGroupList = this.store.selectSnapshot(
          CommonState.accountGroupList
        );
        this.fundNameList = this.store.selectSnapshot(
          AccountState.getFundNameList
        );
        this.accountList = this.store.selectSnapshot(CommonState.accountList);
        this.currencyList = this.store.selectSnapshot(CommonState.getCurrency);
        this.dueTermList = this.store.selectSnapshot(AccountState.getTermsList);

        if (accountGroupList.length > 0) {
          this.accountGroupList = accountGroupList;
          this.tempAccountGroupList = this.accountGroupList;
        }

        this.recurringForm.controls.currency.setValue(this.defaultCurrency);
        this.recurringForm.controls.dueTermName.setValue(
          this.dueTermList[0].id
        );

        if (this.fundNameList.length > 0) {
          this.disabledFundEditButton = false;
          this.recurringForm.controls.fundName.setValue(
            this.fundNameList[0].id
          );
        }
      })
    );
  }

  getOptionText(option) {
    return option.name;
  }

  resetAccountList(): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(this.recurringForm.controls.invoiceType.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  isCustomer(): boolean {
    let isCustomer = false;
    this.moduleId$.subscribe((x) => {
      isCustomer = x === Modules.RecurringInvoice;
    });

    return isCustomer;
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: this.isCustomer() ? Modules.Customers : Modules.Suppliers,
          headerText: `Add ${
            this.isCustomer() ? ModuleName.Customers : ModuleName.Suppliers
          }`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getCustomerOrSupplier(id);
        }
      });
  }

  getCustomerOrSupplier(id?: Guid): void {
    const entityId = new Array<number>();
    this.moduleId$.subscribe((x) => {
      entityId.push(
        x === Modules.RecurringInvoice
          ? AccountEntity.Customer
          : AccountEntity.Supplier
      );
    });

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap((res) => {
          this.accountList = res.common.accountList;
          if (this.accountList.length > 0) {
            if (id !== null && id !== undefined) {
              this.recurringForm.controls.accountId.setValue(id);
            }
          }
        })
      )
      .subscribe();
  }

  gotoEditCustomer(): void {
    if (this.accountList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: this.isCustomer() ? Modules.Customers : Modules.Suppliers,
            headerText: `Edit ${
              this.isCustomer() ? ModuleName.Customers : ModuleName.Suppliers
            }`,
            id: this.recurringForm.controls.accountId.value,
            saveActionName: CreateContact,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getCustomerOrSupplier(id);
          }
        });
    }
  }

  editRecurringData(data): void {
    let account;
    this.accountGroupList.forEach((items) => {
      const accountId = items.listModels.find(
        (x: any) => x.id === data.bookAccountId
      );
      if (accountId !== null && accountId !== undefined) {
        account = accountId;
      }
    });
    this.recurringForm.controls.recurringNo.disable();
    this.recurringForm.patchValue({
      recurringNo: data.entryNumber,
      accountId: data.accountId,
      fundName: data.fundId,
      dueTermName: data.dueTermId,
      currency: data.currencyId,
      notes: data.note,
      recurringType: data.recurringTypeId,
      scheduleSubPeriod: data.subRecurringTypeId,
      dayOfWeek: data.dayOfWeek,
      dayOfMonth: data.dayOfMonth,
      separationCount: data.separationCount,
      maxNumOfOccurrences: data.maxNumOfOccurrences,
      monthOfYear: data.monthOfYear,
      startDate: data.startDate,
      endDate: data.endDate,
      scheduleType: data.recurringEndTypeId,
      invoiceType: account,
    });

    this.disabledEditButton =
      this.recurringForm.controls.accountId.value === '';
  }

  bindMonthList(): void {
    this.monthList = Array.from({ length: 12 }, (e, i) => {
      return new Date(0, i + 1, 0).toLocaleDateString('en', {
        month: 'long',
      });
    });
  }

  setMinEndDate(): void {
    if (this.recurringForm.controls.scheduleType.value === ScheduleType.On) {
      this.minEndDate = new Date(this.recurringForm.controls.startDate.value);
      this.minEndDate.setDate(this.minEndDate.getDate() + 1);
      this.recurringForm.controls.endDate.setValue(this.minEndDate);
    }
    if (
      this.recurringForm.controls.scheduleType.value === ScheduleType.After ||
      this.recurringForm.controls.scheduleType.value === ScheduleType.Never
    ) {
      this.recurringForm.get('endDate')?.clearValidators();
      this.recurringForm.get('endDate')?.updateValueAndValidity();
      this.recurringForm
        .get('endDate')
        ?.setValidators([datePickerValidator(this.periodicDate)]);
    }
  }

  bindWeekList(): void {
    this.weekList = Array.from({ length: 7 }, (e, i) => {
      return new Date(0, 0, i).toLocaleDateString('en', {
        weekday: 'long',
      });
    });
  }

  bindDayList(): void {
    for (let i = 1; i <= 32; i++) {
      let label = '';
      if (i === 1) {
        label = 'First';
      } else if (i === 2) {
        label = 'Last';
      } else if (i === 3 || i === 23) {
        label = i - 2 + 'st';
      } else if (i === 4 || i === 24) {
        label = i - 2 + 'nd';
      } else if (i === 5 || i === 25) {
        label = i - 2 + 'rd';
      } else if (i >= 6) {
        label = i - 2 + 'th';
      }

      const obj = {
        value: i,
        lable: label,
      };
      this.dayList.push(obj);
    }
  }

  setForm(): void {
    this.recurringForm = new FormGroup({
      recurringNo: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      accountId: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      fundName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      dueTermName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      currency: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      notes: new FormControl(''),
      recurringType: new FormControl<number | null>(1),
      scheduleSubPeriod: new FormControl<number | null>(1),
      dayOfWeek: new FormControl<number | null>(1),
      dayOfMonth: new FormControl<number | null>(1),
      separationCount: new FormControl<number | null>(1),
      maxNumOfOccurrences: new FormControl<number | null>(1),
      monthOfYear: new FormControl<number | null>(1),
      startDate: new FormControl<Date | null>(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      endDate: new FormControl<Date | null>(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      scheduleType: new FormControl<number | null>(1),
      invoiceType: new FormControl('', [Validators.required]),
    });

    this.bindMonthList();
    this.bindWeekList();
    this.bindDayList();
    this.setMinEndDate();

    this.loadDropdownValues().subscribe();
  }

  fundChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledFundEditButton = false;
    } else {
      this.disabledFundEditButton = true;
    }
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledFundEditButton = false;
          this.getFund(id);
        }
      });
  }

  getFund(id): void {
    this.store.dispatch(new GetFundNameList()).subscribe((res) => {
      this.fundNameList = res.account.fundNameList;
      if (this.fundNameList.length > 0) {
        if (id !== null && id !== undefined) {
          this.recurringForm.controls.fundName.setValue(id);
        }
      }
    });
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.recurringForm.controls.fundName.value,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }
}
