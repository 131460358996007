import { BillModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class RecurringBillModel {
  id: Guid;
  startDate: Date;
  endDate: Date;
  maxNumOfOccurrences: number;
  recurringTypeId: Guid;
  dayOfWeek: number;
  dayOfMonth: number;
  weekOfMonth: number;
  monthOfYear: number;
  invoice: BillModel;
}
