import { Injectable } from '@angular/core';
import { Company, CompanyModel, SideListModel } from '@app/core/Models';
import { CompanyPreference } from '@app/core/Models/company/company-preference';
import { CompanyService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  CreateCompany,
  CreateCompanyPreference,
  DateBetweenAccountingPeriod,
  DeleteFinancialYear,
  GetAccountingMethodList,
  GetAvailableCompanyCount,
  GetCharityRegulator,
  GetCompanyList,
  GetCompanyPreferenceData,
  GetCompanyRoundingPreference,
  GetDataByCompanyId,
  GetLanguage,
  GetTimeZone,
  GetVatReturnTypeList,
  GetVatSchemeList,
  SetAsDefaultCompany,
  UpdateCompanyRoundingPreference,
} from './company.action';

export class CompanyStateInfo {
  companyId: Guid;
  companyCount: number;
  isCompanyAdded: boolean;
  companyRoundingPreference: boolean;
  isFinancialYearDeleted: boolean;
  isDateBetweenAccountingPeriod: boolean;
  company: Array<Company>;
  companyData?: CompanyModel;
  getCompanyPreferenceData?: CompanyPreference;
  charityRegulator: Array<SideListModel>;
  timeZone: Array<SideListModel>;
  language: Array<SideListModel>;
  financialPeriod: Array<SideListModel>;
  vatReturnTypeList: Array<SideListModel>;
  vatSchemeList: Array<SideListModel>;
  accountingMethod: Array<SideListModel>;
  isUpdated: boolean;
}

@State<CompanyStateInfo>({
  name: 'company',
  defaults: {
    companyId: Guid.EMPTY as unknown as Guid,
    companyCount: -1,
    isCompanyAdded: false,
    isFinancialYearDeleted: false,
    isDateBetweenAccountingPeriod: false,
    company: [],
    charityRegulator: [],
    timeZone: [],
    language: [],
    financialPeriod: [],
    vatReturnTypeList: [],
    accountingMethod: [],
    vatSchemeList: [],
    companyRoundingPreference: false,
    isUpdated: false,
  },
})
@Injectable()
export class CompanyState {
  constructor(private companyService: CompanyService) {}

  @Selector()
  static companyCount(state: CompanyStateInfo): number {
    return state.companyCount;
  }

  @Action(CreateCompany)
  createCompany(
    { patchState }: StateContext<CompanyStateInfo>,
    action: CreateCompany
  ) {
    return this.companyService.createCompany(action.company).pipe(
      tap((res) => {
        patchState({
          companyId: res,
          isCompanyAdded: true,
        });
      })
    );
  }

  @Action(GetCompanyList)
  getCompanyList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetCompanyList
  ) {
    return this.companyService.getCompanyList(action.params).pipe(
      tap((res) => {
        patchState({
          company: res,
        });
      })
    );
  }

  @Action(GetAvailableCompanyCount)
  getAvailableCompanyCount({ patchState }: StateContext<CompanyStateInfo>) {
    return this.companyService.getAvailableCompanyCount().pipe(
      tap((res) => {
        patchState({
          companyCount: res,
        });
      })
    );
  }

  @Action(GetVatReturnTypeList)
  getVatReturnTypeList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetVatReturnTypeList
  ) {
    return this.companyService.getVatReturnTypeList().pipe(
      tap((res) => {
        patchState({
          vatReturnTypeList: res,
        });
      })
    );
  }

  @Action(SetAsDefaultCompany, { cancelUncompleted: true })
  setAsDefaultCompany(
    { patchState }: StateContext<CompanyStateInfo>,
    action: SetAsDefaultCompany
  ) {
    return this.companyService.setAsDefaultCompany(action.companyId).pipe(
      tap((res) => {
        patchState({
          companyId: res,
          isCompanyAdded: true,
        });
      })
    );
  }

  @Action(GetDataByCompanyId)
  getDataByCompanyId(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetDataByCompanyId
  ) {
    return this.companyService.getDataByCompanyId(action.companyId).pipe(
      tap((res) => {
        patchState({
          companyData: res,
        });
      })
    );
  }

  @Action(GetCharityRegulator)
  getCharityRegulator({
    getState,
    patchState,
  }: StateContext<CompanyStateInfo>) {
    return this.companyService.getCharityRegulator().pipe(
      tap((res) => {
        patchState({
          charityRegulator: res,
        });
      })
    );
  }

  @Action(GetTimeZone)
  getTimeZone({ getState, patchState }: StateContext<CompanyStateInfo>) {
    return this.companyService.getTimeZone().pipe(
      tap((res) => {
        patchState({
          timeZone: res,
        });
      })
    );
  }

  @Action(GetLanguage)
  getLanguage({ getState, patchState }: StateContext<CompanyStateInfo>) {
    return this.companyService.getLanguage().pipe(
      tap((res) => {
        patchState({
          language: res,
        });
      })
    );
  }

  @Action(CreateCompanyPreference)
  createCompanyPreference(
    { patchState }: StateContext<CompanyStateInfo>,
    action: CreateCompanyPreference
  ) {
    return this.companyService.createCompanyPreference(action.company).pipe(
      tap((res) => {
        patchState({
          companyId: res,
          isCompanyAdded: true,
        });
      })
    );
  }

  @Action(GetCompanyPreferenceData)
  getCompanyPreferenceData(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetCompanyPreferenceData
  ) {
    return this.companyService.getCompanyPreferenceData(action.moduleId).pipe(
      tap((res) => {
        patchState({
          getCompanyPreferenceData: res,
        });
      })
    );
  }

  @Action(GetAccountingMethodList)
  getAccountingMethodList({ patchState }: StateContext<CompanyStateInfo>) {
    return this.companyService.getAccountingMethodList().pipe(
      tap((res) => {
        patchState({
          accountingMethod: res,
        });
      })
    );
  }

  @Action(GetVatSchemeList)
  getVatSchemeList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetVatSchemeList
  ) {
    return this.companyService.getVatSchemeList().pipe(
      tap((res) => {
        patchState({
          vatSchemeList: res,
        });
      })
    );
  }

  @Action(DeleteFinancialYear)
  deleteFinancialYear(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: DeleteFinancialYear
  ) {
    return this.companyService.deleteFinancialYear(action.ids).pipe(
      tap((res) => {
        patchState({
          isFinancialYearDeleted: res,
        });
      })
    );
  }

  @Action(DateBetweenAccountingPeriod)
  dateBetweenAccountingPeriod(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: DateBetweenAccountingPeriod
  ) {
    return this.companyService.dateBetweenAccountingPeriod(action.date).pipe(
      tap((res) => {
        patchState({
          isDateBetweenAccountingPeriod: res,
        });
      })
    );
  }

  @Action(GetCompanyRoundingPreference)
  getCompanyRoundingPreference(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetCompanyRoundingPreference
  ) {
    return this.companyService.getCompanyRoundingPreference().pipe(
      tap((res) => {
        patchState({
          companyRoundingPreference: res,
        });
      })
    );
  }

  @Action(UpdateCompanyRoundingPreference)
  updateCompanyRoundingPreference(
    { patchState }: StateContext<CompanyStateInfo>,
    action: UpdateCompanyRoundingPreference
  ) {
    return this.companyService
      .updateCompanyRoundingPreference(action.roundPreference)
      .pipe(
        tap((res) => {
          patchState({
            isUpdated: res,
          });
        })
      );
  }
}
