import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note12',
  templateUrl: './note12.component.html',
  styleUrls: ['./note12.component.scss'],
})
export class Note12Component implements OnInit {
  note12Form: FormGroup;
  calculativeData: any;

  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      if (data.noteId === Notes.Note12) {
        this.calculativeData = data.response.calculativeData;
        if (
          data.response.userInputData !== null &&
          data.response.userInputData !== undefined &&
          data.response.userInputData.data !== null &&
          data.response.userInputData.data !== undefined
        ) {
          this.editNotes12(data.response.userInputData.data);
        }
      }
    });
  }
  editNotes12(res): void {
    this.note12Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : '',
    });
  }

  setForm(): void {
    this.note12Form = new FormGroup({
      textbox1: new FormControl(''),
      supportCosts: new UntypedFormArray([]),
    });
  }

  onRaisingFundChange(element: any) {
    element.charitableActivity = 100 - +element.raisingFund;
  }

  onCharitableActivityChange(element: any) {
    element.raisingFund = 100 - +element.charitableActivity;
  }
}
