import { Guid } from 'guid-typescript';

export class GetVatRetun {
  static readonly type = '[VATRETURN] Get VAT Return';

  constructor(public periodKey: Guid) {}
}

export class GetVatRetunDetails {
  static readonly type = '[VATRETURN] Get VAT Return Details';

  constructor(public periodKey: Guid) {}
}

export class GetAuthorized {
  static readonly type = '[VATRETURN] Get Authorized';
}
