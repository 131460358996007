import { SortOrdering } from '@app/core/Enum';
import { Guid } from 'guid-typescript';

export class SideListModel {
  name: string;
  id: any;
  isSelected = false;
  url: string;
  entityId?: any;
  isArchived?: boolean;
}

export class HighLightModel {
  sortBy: string = SortOrdering.createdOn;
  isHighlighted = false;
  moduleId?: number;
}

export class FinancialData {
  id?: Guid;
  startDate?: string | null;
  endDate?: string | null;
  isLocked?: boolean;
}
