import { Injectable } from '@angular/core';
import { QuotationModel, SideListModel } from '@app/core/Models';
import { QuotationService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Guid } from 'guid-typescript';
import {
  ArchiveAndRestoreQuotation,
  ConvertToInvoice,
  CreateQuotation,
  DeleteQuotation,
  GetDataByQuotationId,
  GetQuotationNumber,
} from './quotation.action';

export class QuotationStateInfo {
  quotationData?: QuotationModel;
  quotation: Array<QuotationModel>;
  quotationId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isQuotationAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  quotationNo?: number;
  convertedInvoice?: any;
}

@State<QuotationStateInfo>({
  name: 'quotation',
  defaults: {
    quotation: [],
    quotationId: Guid.EMPTY as unknown as Guid,
    quotationNo: 0,
    exported: false,
    sideListModel: [],
    isQuotationAdded: false,
  },
})
@Injectable()
export class QuotationState {
  constructor(private quotationService: QuotationService) {}

  @Selector()
  static isLastPage(state: QuotationStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getQuotationId(state: QuotationStateInfo) {
    return state.quotationId;
  }

  @Selector()
  static getQuotationData(state: QuotationStateInfo) {
    return state.quotationData;
  }

  @Action(CreateQuotation)
  createQuotation(
    { patchState }: StateContext<QuotationStateInfo>,
    action: CreateQuotation
  ) {
    return this.quotationService.createQuotation(action.quotation).pipe(
      tap((res) => {
        patchState({
          quotationId: res,
          isQuotationAdded: true,
        });
      })
    );
  }

  @Action(GetDataByQuotationId)
  getDataByQuotationId(
    { patchState }: StateContext<QuotationStateInfo>,
    action: GetDataByQuotationId
  ) {
    return this.quotationService.getDataByQuotationId(action.quotationId).pipe(
      tap((res) => {
        patchState({
          quotationData: res,
        });
      })
    );
  }

  @Action(DeleteQuotation)
  deleteQuotation(
    { getState, setState }: StateContext<QuotationStateInfo>,
    action: DeleteQuotation
  ) {
    return this.quotationService.deleteQuotation(action.quotationIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredQuatation = state.quotation.filter(
          (item) =>
            !action.quotationIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.quotationIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.quotation,
          quotation: filteredQuatation,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreQuotation)
  archiveAndRestoreQuotation(
    { getState }: StateContext<QuotationStateInfo>,
    action: ArchiveAndRestoreQuotation
  ) {
    return this.quotationService.archiveAndRestoreQuotation(
      action.quotationIds,
      action.isArchive
    );
  }

  @Action(GetQuotationNumber)
  getQuotationNumber({ patchState }: StateContext<QuotationStateInfo>) {
    return this.quotationService.getQuotationNumber().pipe(
      tap((res) => {
        patchState({
          quotationNo: res,
        });
      })
    );
  }

  @Action(ConvertToInvoice)
  convertToInvoice(
    { patchState }: StateContext<QuotationStateInfo>,
    action: ConvertToInvoice
  ) {
    return this.quotationService.convertToInvoice(action.id).pipe(
      tap((res) => {
        patchState({
          convertedInvoice: res,
        });
      })
    );
  }
}
