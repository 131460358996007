import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  AccountEntity,
  ChartOfAccountExpenseGroupTypeName,
  ChartOfAccountIncomeGroupTypeName,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { SideListModel } from '@app/core/Models';
import { NumberPipe } from '@app/core/Pipes/number-pipe.pipes';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  CreateAccount,
  CreateFund,
  GetFundListBasedOnFundAccountType,
  GetFundOpeningBalance,
  GetFundOpeningBalanceBasedOnAPId,
  GetGroupAccountsBasedOnGroupId,
  GetNonStandardAccountList,
  SaveOpeningBalanceFundExplain,
  SaveOpeningBalanceFundExplainBasedOnAPId,
} from '@app/core/Store';
import { CleanAllLinesComponent, QuickAddComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

export interface PeriodicElement5 {
  name: string;
  balance: number;
}

const ELEMENT_DATA: PeriodicElement5[] = [{ name: 'Fund', balance: 1000 }];

@Component({
  selector: 'app-opening-balance-explain-fund',
  templateUrl: './opening-balance-explain-fund.component.html',
  styleUrls: ['./opening-balance-explain-fund.component.scss'],
})
export class OpeningBalanceExplainFundComponent implements OnInit {
  headerText = 'Fund Explain';
  openingBalanceFundExplainData: any[] = [];
  displayedColumns: string[] = [
    'srNo',
    'name',
    'opening',
    'income',
    'expense',
    'fundTransferIn',
    'fundTransferOut',
    'gainloss',
    'closing',
    'closeButton',
  ];
  displayedColumns1: string[] = this.displayedColumns.map((x) => `s2_${x}`);
  displayedColumns2: string[] = this.displayedColumns.map((x) => `f2_${x}`);
  maxLength = MaxLength;
  totalExplainedAmount = 0;

  totalOpening: number = 0;
  totalIncome: number = 0;
  totalExpense: number = 0;
  totalFundTransferIn: number = 0;
  totalFundTransferOut: number = 0;
  totalGainLoss: number = 0;
  totalClosing: number = 0;

  accountGroupList: any[] = [];
  tempAccountGroupList: any[] = [];
  formOpeningBalanceFundExplain: UntypedFormGroup;
  openingBalanceFundExplainArray: any;
  tableDataSource: MatTableDataSource<AbstractControl>;
  modules = Modules;

  constructor(
    public dialog: MatDialog,
    private fundExplainContactPopup: MatDialogRef<OpeningBalanceExplainFundComponent>,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2,
    private numberPipe: NumberPipe,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.calValueFromOpeningBalanceOpening();
    this.setOpeningBalanceExplainForm(true);
    this.getName(true);
  }

  getFundOpeningBalance(): void {
    this.spinner.show();
    let actionName;
    if (this.data.moduleId === Modules.EditAccountProductionTrialBalance) {
      actionName = GetFundOpeningBalanceBasedOnAPId;
    } else {
      actionName = GetFundOpeningBalance;
    }
    this.store
      .dispatch(new actionName(this.data.accountingPeriodId))
      .subscribe((res) => {
        this.spinner.hide();
        if (res.openingBalance.openingBalanceFundExplainData.length > 0) {
          this.editOpeningBalanceExplain(
            res.openingBalance.openingBalanceFundExplainData
          );
        } else {
          this.defaultOpeningBalanceFundExplain(
            this.data.openingBalanceArray?.getRawValue()
          );
        }
      });
  }

  editOpeningBalanceExplain(data: any): void {
    this.openingBalanceFundExplainArray =
      this.formOpeningBalanceFundExplain.get(
        'openingBalanceFundExplainArray'
      ) as UntypedFormArray;

    this.openingBalanceFundExplainArray.clear();
    data.forEach((item, i) => {
      this.openingBalanceFundExplainArray.push(
        this.buildEditOrderItemsForm(item)
      );
      this.rowClosingCount(i);
      this.setFormValidity(i);
    });
    this.setDataSource(this.openingBalanceFundExplainArray);
    this.spinner.hide();
  }

  buildEditOrderItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      fund: item.accountId,
      opening: item.openingBalance === 0 ? null : item.openingBalance,
      income: item.income === 0 ? null : item.income,
      expense: item.expenditure === 0 ? null : item.expenditure,
      fundTransferIn: item.fundTransferIn === 0 ? null : item.fundTransferIn,
      fundTransferOut: item.fundTransferOut === 0 ? null : item.fundTransferOut,
      gainloss: item.netGainLoss === 0 ? null : item.netGainLoss,
      closing: null,
    });
  }

  setFormValidity(index: number): void {
    this.openingBalanceFundExplainArray.controls[index].clearValidators();
    this.openingBalanceFundExplainArray.controls[index]
      .get('fund')
      ?.setValidators([
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]);
    this.openingBalanceFundExplainArray.controls[index]
      .get('opening')
      ?.disable();
    this.openingBalanceFundExplainArray.controls[index]
      .get('closing')
      ?.disable();

    this.openingBalanceFundExplainArray.controls[
      index
    ].updateValueAndValidity();
  }

  calValueFromOpeningBalanceOpening(): void {
    this.totalOpening = 0;
    let totalCredit = 0;
    let totalDebit = 0;
    this.data.openingBalanceArray?.getRawValue().forEach((x) => {
      if (x.account.groupId === GroupNames.Funds) {
        totalCredit =
          +totalCredit +
          +x.endowmentCredit +
          +x.restrictedCredit +
          +x.unrestrictedCredit;
        totalDebit =
          +totalDebit +
          +x.endowmentDebit +
          +x.restrictedDebit +
          +x.unrestrictedDebit;
      }
    });
    this.totalOpening = totalCredit - totalDebit;
    this.totalOpening = +this.totalOpening.toFixed(2);
    this.calValueFromOpeningBalanceIncome();
  }

  calValueFromOpeningBalanceIncome(): void {
    this.totalIncome = 0;
    let totalCredit = 0;
    let totalDebit = 0;
    this.data.openingBalanceArray?.getRawValue().forEach((x) => {
      if (
        x.account.groupId === GroupNames.Income &&
        (x.account.typeId ===
          ChartOfAccountIncomeGroupTypeName.DonationsAndLegacies ||
          x.account.typeId ===
            ChartOfAccountIncomeGroupTypeName.CharitableActivities ||
          x.account.typeId ===
            ChartOfAccountIncomeGroupTypeName.OtherTradingActivities ||
          x.account.typeId === ChartOfAccountIncomeGroupTypeName.Investments ||
          x.account.typeId ===
            ChartOfAccountIncomeGroupTypeName.SeparateMaterialItemofIncome ||
          x.account.typeId === ChartOfAccountIncomeGroupTypeName.Other)
      ) {
        totalCredit =
          +totalCredit +
          +x.endowmentCredit +
          +x.restrictedCredit +
          +x.unrestrictedCredit;
        totalDebit =
          +totalDebit +
          +x.endowmentDebit +
          +x.restrictedDebit +
          +x.unrestrictedDebit;
      }
    });
    this.totalIncome = totalCredit - totalDebit;
    this.totalIncome = +this.totalIncome.toFixed(2);
    this.calValueFromOpeningBalanceExpense();
  }

  calValueFromOpeningBalanceExpense(): void {
    this.totalExpense = 0;
    let totalCredit = 0;
    let totalDebit = 0;
    this.data.openingBalanceArray?.getRawValue().forEach((x) => {
      if (
        x.account.groupId === GroupNames.Expense &&
        (x.account.typeId === ChartOfAccountExpenseGroupTypeName.RaisingFunds ||
          x.account.typeId ===
            ChartOfAccountExpenseGroupTypeName.CharitableActivities ||
          x.account.typeId ===
            ChartOfAccountExpenseGroupTypeName.SupportCosts ||
          x.account.typeId ===
            ChartOfAccountExpenseGroupTypeName.FinanceCosts ||
          x.account.typeId ===
            ChartOfAccountExpenseGroupTypeName.GovernanceCosts ||
          x.account.typeId === ChartOfAccountExpenseGroupTypeName.Other)
      ) {
        totalCredit =
          +totalCredit +
          +x.endowmentCredit +
          +x.restrictedCredit +
          +x.unrestrictedCredit;
        totalDebit =
          +totalDebit +
          +x.endowmentDebit +
          +x.restrictedDebit +
          +x.unrestrictedDebit;
      }
    });
    this.totalExpense = totalDebit - totalCredit;
    this.totalExpense = +this.totalExpense.toFixed(2);
    this.calValueFromOpeningBalanceGainLoss();
  }

  calValueFromOpeningBalanceGainLoss(): void {
    this.totalGainLoss = 0;
    let totalNetGainCredit = 0;
    let totalNetGainDebit = 0;
    let totalNetLossCredit = 0;
    let totalNetLossDebit = 0;
    let netGain = 0;
    let netLoss = 0;
    this.data.openingBalanceArray?.getRawValue().forEach((x) => {
      if (
        x.account.groupId === GroupNames.Income &&
        (x.account.typeId ===
          ChartOfAccountIncomeGroupTypeName.GainsLossesonRevaluationofFixedAssets ||
          x.account.typeId ===
            ChartOfAccountIncomeGroupTypeName.OtherGainsLosses)
      ) {
        totalNetGainCredit =
          +totalNetGainCredit +
          +x.endowmentCredit +
          +x.restrictedCredit +
          +x.unrestrictedCredit;
        totalNetGainDebit =
          +totalNetGainDebit +
          +x.endowmentDebit +
          +x.restrictedDebit +
          +x.unrestrictedDebit;
      }

      if (
        x.account.groupId === GroupNames.Expense &&
        (x.account.typeId ===
          ChartOfAccountExpenseGroupTypeName.GainsLossesonRevaluationofFixedAssets ||
          x.account.typeId ===
            ChartOfAccountExpenseGroupTypeName.OtherGainsLosses)
      ) {
        totalNetLossCredit =
          +totalNetLossCredit +
          +x.endowmentCredit +
          +x.restrictedCredit +
          +x.unrestrictedCredit;
        totalNetLossDebit =
          +totalNetLossDebit +
          +x.endowmentDebit +
          +x.restrictedDebit +
          +x.unrestrictedDebit;
      }
    });
    netGain = totalNetGainCredit - totalNetGainDebit;
    netLoss = totalNetLossDebit - totalNetLossCredit;
    this.totalGainLoss = netGain - netLoss;
    this.totalGainLoss = +this.totalGainLoss.toFixed(2);
    this.calValueFromOpeningBalanceClosing();
  }

  calValueFromOpeningBalanceClosing(): void {
    this.totalClosing =
      this.totalOpening +
      this.totalIncome -
      this.totalExpense +
      this.totalFundTransferIn -
      this.totalFundTransferOut +
      this.totalGainLoss;
    this.totalClosing = +this.totalClosing.toFixed(2);
  }

  getOptionText(option: any) {
    return option?.name;
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getName(false);
        }
      });
  }

  calTotalOpening(): any {
    let totalOpening = 0;
    if (this.openingBalanceFundExplainArray.length > 0) {
      this.openingBalanceFundExplainArray?.getRawValue().forEach((x) => {
        totalOpening = +totalOpening + +x.opening;
      });
    } else {
      totalOpening = this.numberPipe.transform('0');
    }
    return totalOpening;
  }

  calTotalIncome(): any {
    let totalIncome = 0;
    if (this.openingBalanceFundExplainArray.length > 0) {
      this.openingBalanceFundExplainArray?.getRawValue().forEach((x) => {
        totalIncome = +totalIncome + +x.income;
      });
    } else {
      totalIncome = this.numberPipe.transform('0');
    }
    return totalIncome;
  }

  calTotalExpense(): any {
    let totalExpense = 0;
    if (this.openingBalanceFundExplainArray.length > 0) {
      this.openingBalanceFundExplainArray?.getRawValue().forEach((x) => {
        totalExpense = +totalExpense + +x.expense;
      });
    } else {
      totalExpense = this.numberPipe.transform('0');
    }
    return totalExpense;
  }

  calTotalFundTransferIn(): any {
    let totalFundTransferIn = 0;
    if (this.openingBalanceFundExplainArray.length > 0) {
      this.openingBalanceFundExplainArray?.getRawValue().forEach((x) => {
        totalFundTransferIn = +totalFundTransferIn + +x.fundTransferIn;
      });
    } else {
      totalFundTransferIn = this.numberPipe.transform('0');
    }
    return totalFundTransferIn;
  }

  calTotalFundTransferOut(): any {
    let totalFundTransferOut = 0;
    if (this.openingBalanceFundExplainArray.length > 0) {
      this.openingBalanceFundExplainArray?.getRawValue().forEach((x) => {
        totalFundTransferOut = +totalFundTransferOut + +x.fundTransferOut;
      });
    } else {
      totalFundTransferOut = this.numberPipe.transform('0');
    }
    return totalFundTransferOut;
  }

  calTotalGainLoss(): any {
    let totalGainLoss = 0;
    if (this.openingBalanceFundExplainArray.length > 0) {
      this.openingBalanceFundExplainArray?.getRawValue().forEach((x) => {
        totalGainLoss = +totalGainLoss + +x.gainloss;
      });
    } else {
      totalGainLoss = this.numberPipe.transform('0');
    }
    return totalGainLoss;
  }

  calTotalClosing(): any {
    let totalClosing = 0;
    if (this.openingBalanceFundExplainArray.length > 0) {
      this.openingBalanceFundExplainArray?.getRawValue().forEach((x) => {
        totalClosing = +totalClosing + +x.closing;
      });
    } else {
      totalClosing = this.numberPipe.transform('0');
    }
    return totalClosing;
  }

  countTotalOfDrCr(columnName: string) {
    columnName = columnName.replace('s2_', '').replace('s3_', '');
    let returnValue;

    if (columnName === this.displayedColumns[0]) {
      returnValue = 'Total';
    } else if (columnName === this.displayedColumns[1]) {
      returnValue = '';
    } else if (columnName === this.displayedColumns[2]) {
      returnValue = this.numberPipe.transform(this.calTotalOpening());
    } else if (columnName === this.displayedColumns[3]) {
      returnValue = this.numberPipe.transform(this.calTotalIncome());
    } else if (columnName === this.displayedColumns[4]) {
      returnValue = this.numberPipe.transform(this.calTotalExpense());
    } else if (columnName === this.displayedColumns[5]) {
      returnValue = this.numberPipe.transform(this.calTotalFundTransferIn());
    } else if (columnName === this.displayedColumns[6]) {
      returnValue = this.numberPipe.transform(this.calTotalFundTransferOut());
    } else if (columnName === this.displayedColumns[7]) {
      returnValue = this.numberPipe.transform(this.calTotalGainLoss());
    } else if (columnName === this.displayedColumns[8]) {
      returnValue = this.numberPipe.transform(this.calTotalClosing());
    } else if (columnName === this.displayedColumns[9]) {
      returnValue = '';
    } else {
      returnValue = this.numberPipe.transform('0');
    }
    return returnValue;
  }

  countDifferenceOfDrCr(columnName: string) {
    columnName = columnName.replace('f2_', '').replace('f3_', '');
    let returnValue;

    if (columnName === this.displayedColumns[0]) {
      returnValue = 'Difference';
    } else if (columnName === this.displayedColumns[1]) {
      returnValue = '';
    } else if (columnName === this.displayedColumns[2]) {
      returnValue = this.numberPipe.transform(
        this.totalOpening - this.calTotalOpening()
      );
    } else if (columnName === this.displayedColumns[3]) {
      returnValue = this.numberPipe.transform(
        this.totalIncome - this.calTotalIncome()
      );
    } else if (columnName === this.displayedColumns[4]) {
      returnValue = this.numberPipe.transform(
        this.totalExpense - this.calTotalExpense()
      );
    } else if (columnName === this.displayedColumns[7]) {
      returnValue = this.numberPipe.transform(
        this.totalGainLoss - this.calTotalGainLoss()
      );
    } else if (columnName === this.displayedColumns[8]) {
      returnValue = this.numberPipe.transform(
        this.totalClosing - this.calTotalClosing()
      );
    } else if (columnName === this.displayedColumns[9]) {
      returnValue = '';
    } else {
      returnValue = this.numberPipe.transform('0');
    }
    return returnValue;
  }

  getName(isOnLoad: boolean): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Funds);

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames))
      .subscribe((res) => {
        if (res.common.accountGroupList.length > 0) {
          this.accountGroupList = this.commonService.addGroupNameToListModels(
            res.common.accountGroupList
          );
          this.tempAccountGroupList = this.accountGroupList;
          if (isOnLoad) {
            this.getFundOpeningBalance();
          }
        }
      });
  }

  accountSelect(element: any, index: number): void {
    const filteredData = this.data.openingBalanceArray
      ?.getRawValue()
      .filter((x) => x.account.id === element.value.fund.id);
    if (filteredData.length > 0) {
      const totalDebit =
        +filteredData[0].endowmentDebit +
        +filteredData[0].restrictedDebit +
        +filteredData[0].unrestrictedDebit;
      const totalCredit =
        +filteredData[0].endowmentCredit +
        +filteredData[0].restrictedCredit +
        +filteredData[0].unrestrictedCredit;
      const totalOpeningBalance = totalCredit - totalDebit;
      this.openingBalanceFundExplainArray.controls[index]
        .get('opening')
        .setValue(totalOpeningBalance);
    } else {
      this.openingBalanceFundExplainArray.controls[index]
        .get('opening')
        .setValue(0);
    }
    this.rowClosingCount(index);
  }

  rowClosingCount(index: number): void {
    const closingTotal =
      +this.openingBalanceFundExplainArray.controls[index].get('opening')
        .value +
      +this.openingBalanceFundExplainArray.controls[index].get('income').value -
      +this.openingBalanceFundExplainArray.controls[index].get('expense')
        .value -
      +this.openingBalanceFundExplainArray.controls[index].get(
        'fundTransferOut'
      ).value +
      +this.openingBalanceFundExplainArray.controls[index].get('fundTransferIn')
        .value +
      +this.openingBalanceFundExplainArray.controls[index].get('gainloss')
        .value;
    this.openingBalanceFundExplainArray.controls[index]
      .get('closing')
      .setValue(closingTotal.toFixed(2));
  }

  onDeleteOpeningBalance(index: number): void {
    this.openingBalanceFundExplainArray =
      this.formOpeningBalanceFundExplain.get(
        'openingBalanceFundExplainArray'
      ) as UntypedFormArray;

    this.openingBalanceFundExplainArray.removeAt(index);
    this.setDataSource(this.openingBalanceFundExplainArray);
  }

  createRow(): void {
    this.openingBalanceFundExplainArray =
      this.formOpeningBalanceFundExplain.get(
        'openingBalanceFundExplainArray'
      ) as UntypedFormArray;
    this.openingBalanceFundExplainArray.push(this.setForm());
    this.setDataSource(this.openingBalanceFundExplainArray);
  }

  defaultOpeningBalanceFundExplain(data: any): void {
    let dataList: any[] = [];
    data.forEach((element) => {
      if (element.account.groupId === GroupNames.Funds) {
        dataList.push(element);
      }
    });
    this.openingBalanceFundExplainArray =
      this.formOpeningBalanceFundExplain.get(
        'openingBalanceFundExplainArray'
      ) as UntypedFormArray;

    this.openingBalanceFundExplainArray.clear();
    dataList.forEach((item, i) => {
      this.openingBalanceFundExplainArray.push(this.buildOrderItemsForm(item));
      this.rowClosingCount(i);
      this.setFormValidity(i);
    });
    this.setDataSource(this.openingBalanceFundExplainArray);
    this.spinner.hide();
  }

  buildOrderItemsForm(item: any): FormGroup {
    const totalDebit =
      +item.endowmentDebit + +item.restrictedDebit + +item.unrestrictedDebit;
    const totalCredit =
      +item.endowmentCredit + +item.restrictedCredit + +item.unrestrictedCredit;
    const totalOpeningBalance = totalCredit - totalDebit;
    return this.formBuilder.group({
      id: Guid.EMPTY as unknown as Guid,
      fund: item.account.id,
      opening: totalOpeningBalance,
      income: null,
      expense: null,
      fundTransferIn: null,
      fundTransferOut: null,
      gainloss: null,
      closing: null,
    });
  }

  setOpeningBalanceExplainForm(addNewRow: boolean): void {
    this.formOpeningBalanceFundExplain = new FormGroup({
      openingBalanceFundExplainArray: new UntypedFormArray([]),
    });

    this.openingBalanceFundExplainArray =
      this.formOpeningBalanceFundExplain.get(
        'openingBalanceFundExplainArray'
      ) as UntypedFormArray;

    this.setDataSource(this.openingBalanceFundExplainArray);
    if (addNewRow) this.createRow();
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formOpeningBalanceFundExplain.get(
            'openingBalanceFundExplainArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i].get('income')?.setValue(null);
            formArray.controls[i].get('expense')?.setValue(null);
            formArray.controls[i].get('opening')?.setValue(null);
            formArray.controls[i].get('gainloss')?.setValue(null);
            formArray.controls[i].get('fundTransferOut')?.setValue(null);
            formArray.controls[i].get('fundTransferIn')?.setValue(null);
            formArray.controls[i].get('closing')?.setValue(null);
            formArray.controls[i].get('fund')?.setValue('');
            formArray.controls[i].get('fund')?.updateValueAndValidity();
          }
        }
      });
  }

  dataSubmit(): void {
    this.openingBalanceFundExplainData = [];
    this.openingBalanceFundExplainArray?.getRawValue().forEach((x) => {
      const param: any = {
        id: x.id,
        accountId: x.fund,
        openingBalance: +x.opening,
        income: +x.income,
        fundTransferOut: +x.fundTransferOut,
        fundTransferIn: +x.fundTransferIn,
        expenditure: +x.expense,
        netGainLoss: +x.gainloss,
      };
      this.openingBalanceFundExplainData.push(param);
    });
  }

  onSave(): void {
    if (this.formOpeningBalanceFundExplain.invalid) {
      this.openingBalanceFundExplainArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
          this.commonService.onFailure(NotificationTextMessage.blankLineError);
        });
      });
      return;
    }

    if (this.totalOpening !== this.calTotalOpening()) {
      this.commonService.onFailure(
        NotificationTextMessage.totalOpeningErrorMessage
      );
      return;
    } else if (this.totalIncome !== this.calTotalIncome()) {
      this.commonService.onFailure(
        NotificationTextMessage.totalIncomeErrorMessage
      );
      return;
    } else if (+this.totalExpense.toFixed(2) !== this.calTotalExpense()) {
      this.commonService.onFailure(
        NotificationTextMessage.totalExpenseErrorMessage
      );
      return;
    } else if (this.totalGainLoss !== this.calTotalGainLoss()) {
      this.commonService.onFailure(
        NotificationTextMessage.totalGainLossErrorMessage
      );
      return;
    } else if (
      this.calTotalFundTransferIn() !== this.calTotalFundTransferOut()
    ) {
      this.commonService.onFailure(
        NotificationTextMessage.totalFundTransferErrorMessage
      );
      return;
    }
    this.dataSubmit();
    let actionName;

    if (this.data.moduleId === Modules.EditAccountProductionTrialBalance) {
      actionName = SaveOpeningBalanceFundExplainBasedOnAPId;
    } else {
      actionName = SaveOpeningBalanceFundExplain;
    }
    this.store
      .dispatch(
        new actionName(
          this.openingBalanceFundExplainData,
          this.data.accountingPeriodId
        )
      )
      .subscribe((res) => {
        this.spinner.hide();
        if (res.openingBalance.isOpeningBalanceExplain) {
          this.commonService.onSucess(NotificationTextMessage.successMessage);
          const data = {
            sumOfAllDrCr: 0,
            isExplainSave: true,
          };
          this.fundExplainContactPopup.close(data);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  onCancel(): void {
    this.fundExplainContactPopup.close();
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      fund: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      opening: new FormControl<number | null>({ value: null, disabled: true }),
      income: new FormControl<number | null>(null),
      expense: new FormControl<number | null>(null),
      fundTransferIn: new FormControl<number | null>(null),
      fundTransferOut: new FormControl<number | null>(null),
      gainloss: new FormControl<number | null>(null),
      closing: new FormControl<number | null>({ value: null, disabled: true }),
    });
  }

  fundTransferInChanges(index: any, event: any): void {
    const formArray = this.formOpeningBalanceFundExplain.get(
      'openingBalanceFundExplainArray'
    ) as UntypedFormArray;
    if (event.target.value !== '') {
      if (event.keyCode !== 9) {
        formArray.controls[index].get('fundTransferOut')?.setValue(null);
        formArray.controls[index].get('fundTransferOut')?.clearValidators();
        formArray.controls[index].updateValueAndValidity();
      }
    } else {
      formArray.controls[index].get('fundTransferIn')?.setValue(null);
      formArray.controls[index].updateValueAndValidity();
    }
  }

  fundTransferOutChanges(index: any, event: any): void {
    const formArray = this.formOpeningBalanceFundExplain.get(
      'openingBalanceFundExplainArray'
    ) as UntypedFormArray;
    if (event.target.value !== '') {
      if (event.keyCode !== 9) {
        formArray.controls[index].get('fundTransferIn')?.setValue(null);
        formArray.controls[index].get('fundTransferIn')?.clearValidators();
        formArray.controls[index].updateValueAndValidity();
      }
    } else {
      formArray.controls[index].get('fundTransferOut')?.setValue(null);
      formArray.controls[index].updateValueAndValidity();
    }
  }
}
