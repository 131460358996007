import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotificationTextMessage } from '@app/core/Enum';
import {
  FileUploadResponseModel,
  MultipleFileDownloadModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  FileDownload,
  GetGiftAidDonorDeclarations,
  GetGiftAidDonorSpreadSheets,
} from '@app/core/Store';
import { AttachmentPopupComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

class FileUploadResponse {
  fileUrl: string;
  fileName: string;
}

@Component({
  selector: 'app-add-gift-details',
  templateUrl: './add-gift-details.component.html',
  styleUrls: ['./add-gift-details.component.scss'],
})
export class AddGiftDetailsComponent implements OnInit {
  giftForm: FormGroup;
  subscriptionRouting: Subscription;
  fileData: Array<FileUploadResponseModel>;
  notificationMessage = NotificationTextMessage;

  @Input() triggerEditData: Observable<any>;
  files: FileUploadResponse[] = [];
  multipleFileDownloadModel: MultipleFileDownloadModel;

  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.getGiftAidDonorDeclarations();

    this.triggerEditData?.subscribe((data) => {
      this.getGiftAidDonorDeclarations();
      this.editGiftDetails(data);
    });

    this.giftForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.giftForm.touched;
    });
  }

  getGiftAidDonorSpreadSheets(): void {
    this.store.dispatch(new GetGiftAidDonorSpreadSheets()).subscribe((res) => {
      this.files = res.donor.giftAidDonorSpreadSheetsList;
    });
  }

  getGiftAidDonorDeclarations(): void {
    this.store.dispatch(new GetGiftAidDonorDeclarations()).subscribe((res) => {
      this.files = res.donor.giftAidDonorSpreadSheetsList;
    });
  }

  editGiftDetails(data: any): void {
    this.giftForm.patchValue({
      eligibaleStartDate: data.taxPayerStartDate,
      taxPayerStatusDate: data.taxPayerEndDate,
      giftAidAttachment: data.giftAidAttachment,
    });

    this.fileData = [];
    if (data.giftAidAttachment.length > 0) {
      data.giftAidAttachment.forEach((element) => {
        this.fileData.push({
          isSuccess: true,
          fileName: element.replace(/^.*[\\\/]/, ''),
          fileUrl: element,
        });
      });
    }
  }

  dowloadFile(fileUrl: any): void {
    const filedata: any[] = [];

    filedata.push(fileUrl);

    this.multipleFileDownloadModel = {
      fileURLs: filedata,
    };

    this.store
      .dispatch(new FileDownload(this.multipleFileDownloadModel))
      .subscribe();
  }

  clickAttachment(): void {
    this.dialog
      .open(AttachmentPopupComponent, {
        data: this.giftForm.controls.giftAidAttachment.value,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.fileData = result;

          const attachment: any = [];
          result.forEach((element) => {
            attachment.push(element.fileUrl);
          });
          this.giftForm.controls.giftAidAttachment.setValue(attachment);
        }
      });
  }

  setForm(): void {
    this.giftForm = new FormGroup({
      eligibaleStartDate: new FormControl(new Date()),
      taxPayerStatusDate: new FormControl(new Date()),
      giftAidAttachment: new FormControl(),
    });
  }
}
