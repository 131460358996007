export enum RecurringType {
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Yearly = 4,
  Custom = 5,
}

export enum ScheduleSubPeriod {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

export enum ScheduleType {
  After = 0,
  On = 1,
  Never = 2,
}
