<div class="financial-year">
  <div class="sidenav-table sidenav-table-input">
    <div class="mat-table-wrapper product-detail-table">
      <form [formGroup]="formFinancialYearDetail">
        <ng-container formArrayName="financialYearArray">
          <table
            aria-describedby="product-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="srNo">
              <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex">
                  {{ i + 1 }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef>
                Start Date <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="standard">
                  <input
                    matInput
                    formControlName="startDate"
                    [matDatepicker]="startDatePicker"
                    autocomplete="off"
                    (dateChange)="dateChange(true, i)"
                    (change)="commonService.validateDate($event)"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="startDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef>
                End Date <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="standard">
                  <input
                    matInput
                    formControlName="endDate"
                    [matDatepicker]="endDatePicker"
                    autocomplete="off"
                    (dateChange)="dateChange(false, i)"
                    (change)="commonService.validateDate($event)"
                    [min]="
                      financialYearArray.controls[i].get('startDate').value
                    "
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="endDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="deleteButton">
              <th mat-header-cell *matHeaderCellDef class="w-50"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  *ngIf="
                    !this.financialYearArray.controls[i].get('isLocked').value
                  "
                  class="close-btn"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onDelete(i, element)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="lockButton">
              <th mat-header-cell *matHeaderCellDef class="w-50"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  class="close-btn"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onLockClick(i, element)"
                >
                  <mat-icon
                    *ngIf="
                      this.financialYearArray.controls[i].get('isLocked').value
                    "
                    >lock</mat-icon
                  >
                  <mat-icon
                    *ngIf="
                      !this.financialYearArray.controls[i].get('isLocked').value
                    "
                    >lock_open</mat-icon
                  >
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
          </table>
        </ng-container>
      </form>
    </div>
    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
          >Add New Period</a
        >
      </div>
    </div>
  </div>
</div>
