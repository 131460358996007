<div class="content-body-wrapper">
  <div class="content-body">
    <div>
      <p class="header-text d-flex justify-space-between align-items-center">
        <span>
          {{
            vatCodeId === commonService.defaultGuidValue
              ? "Add Vat Code"
              : "Edit Vat Code"
          }}
        </span>
        <button
          mat-button
          class="header-text-close d-flex align-items-center"
          disableRipple
          (click)="onCloseClick()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </p>
    </div>
    <form [formGroup]="vatCodesForm">
      <div class="d-flex gap-40">
        <div class="form-field">
          <p>VAT Name <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input matInput formControlName="vatName" autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Abbreviation <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input matInput formControlName="abbreviation" autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Rate (%) <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input
              matInput
              formControlName="rate"
              autocomplete="off"
              allowDecimal
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
<app-buttons
  [getModuleId]="moduleId"
  (triggerOnSaveClick)="onSave($event.isExit)"
  (triggerOnCancelClick)="onCancel(true)"
>
</app-buttons>
