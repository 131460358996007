import { Injectable } from '@angular/core';
import { Country } from '@app/core/Models/country/country';
import { CommonService } from '@app/core/Services/common/common.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { GetCountryList } from './country.action';

export class CountryStateInfo {
  country: Array<Country>;
}

@State<CountryStateInfo>({
  name: 'country',
  defaults: {
    country: [],
  },
})
@Injectable()
export class CountryState {
  constructor(private commonService: CommonService) {}

  @Selector()
  static getCountry(state: CountryStateInfo) {
    return state.country;
  }

  @Action(GetCountryList)
  getCountryList({ getState, setState }: StateContext<CountryStateInfo>) {
    return this.commonService.getCountryList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          country: res,
        });
      })
    );
  }
}
