import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { BankTransferModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateBankTransfer,
  GetBankAccountTransactionData,
  GetBankTransferNumber,
  GetDataByBankTransferId,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import { AddBankTransferComponent } from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.scss'],
})
export class BankTransferComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isExit = false;

  moduleId = Modules.BankTransfer;
  moduleName = ModuleName.BankTransfer;

  bankTransferData: BankTransferModel;

  triggerEditData: Subject<any> = new Subject<any>();

  triggerTransactionLogData: Subject<any> = new Subject<any>();

  bankTransferId = Guid.EMPTY as unknown as Guid;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddBankTransferComponent, { static: true })
  bankTransferDetails;

  subscriptionRouting: Subscription;

  isFromBankImport = false;
  isManualBank = false;

  bankImportTransactionId = Guid.EMPTY as unknown as Guid;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    public commonService: CommonService,
    private store: Store,
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private location: Location,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');

          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
          }

          const id = atob(params.get('id')!) as unknown as Guid;

          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;
            this.getBankTransferNumber();
            if (this.isManualBank) {
              this.getTransactionLogData();
            } else {
              this.getBankAccountTransactionData();
            }
          } else {
            this.bankTransferId = id;
            this.editBankTransfer();
          }
        } else {
          this.getBankTransferNumber();
        }
      }
    );
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.bankTransferDetails.bankTransferForm.controls.transferDate.setValue(
          res.common.transactionLogData.entryDate
        );
        this.bankTransferDetails.bankTransferForm.controls.amount.setValue(
          res.common.transactionLogData.amount
        );
        this.bankTransferDetails.bankTransferForm.controls.reference.setValue(
          res.common.transactionLogData.note
        );

        this.triggerTransactionLogData.next(res.common.transactionLogData);
        this.bankTransferDetails.bankTransferForm.controls.transferDate.disable();
        this.bankTransferDetails.bankTransferForm.controls.amount.disable();
      });
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.bankTransferDetails.bankTransferForm.controls.transferDate.setValue(
          res.common.transactionLogData.transactionDate
        );
        this.bankTransferDetails.bankTransferForm.controls.amount.setValue(
          res.common.transactionLogData.amount
        );
        this.bankTransferDetails.bankTransferForm.controls.reference.setValue(
          res.common.transactionLogData.description
        );

        this.triggerTransactionLogData.next(res.common.transactionLogData);
        this.bankTransferDetails.bankTransferForm.controls.transferDate.disable();
        this.bankTransferDetails.bankTransferForm.controls.amount.disable();
      });
  }

  getBankTransferNumber(): void {
    this.store.dispatch(new GetBankTransferNumber()).subscribe((res) => {
      this.bankTransferDetails.bankTransferForm.controls.bankTransferNumber.setValue(
        res.bankTransfer.bankTransferNo
      );
    });
  }

  editBankTransfer(): void {
    this.store
      .dispatch(new GetDataByBankTransferId(this.bankTransferId))

      .subscribe((res) => {
        this.triggerEditData.next(res.bankTransfer.bankTransferData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.bankTransferId,
      isExit,
      Modules.BankTransfer,
      RoutingPath.AddBankTransfer
    );
  }

  onSave(isExit: boolean): void {
    if (this.bankTransferDetails.bankTransferForm.invalid) {
      this.bankTransferDetails.bankTransferForm.markAllAsTouched();
      return;
    } else if (
      this.bankTransferDetails.bankTransferForm.controls.transferFrom.value
        .id ===
      this.bankTransferDetails.bankTransferForm.controls.transferTo.value.id
    ) {
      this.bankTransferDetails.bankTransferForm.controls.transferFrom.markAsTouched();
      this.bankTransferDetails.bankTransferForm.controls.transferTo.markAsTouched();
      this.commonService.onFailure(
        NotificationTextMessage.transferErrorMessage
      );
      return;
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateBankTransfer(this.bankTransferData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);
                if (!isExit && !this.isFromBankImport) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }

                this.commonService.onSucess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      this.bankTransferData = {
        id: this.bankTransferId,
        entryNumber:
          this.bankTransferDetails.bankTransferForm.controls.bankTransferNumber
            .value,
        entryDate: this.datepipe
          .transform(
            this.bankTransferDetails.bankTransferForm.controls.transferDate
              .value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        transferredFromId:
          this.bankTransferDetails.bankTransferForm.controls.transferFrom.value
            .id,

        transferredToId:
          this.bankTransferDetails.bankTransferForm.controls.transferTo.value
            .id,
        note: this.bankTransferDetails.bankTransferForm.controls.reference
          .value,

        amount:
          +this.bankTransferDetails.bankTransferForm.controls.amount.value,
        fundId: this.bankTransferDetails.bankTransferForm.controls.fundId.value,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
        isManualBank: this.isManualBank,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    if (isCancelClick && !this.commonService.isEmpty(this.bankTransferId)) {
      this.editBankTransfer();
    } else {
      this.bankTransferId = Guid.EMPTY as unknown as Guid;
      this.bankTransferDetails.ngOnInit();
      this.getBankTransferNumber();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
