<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
    [ngClass]="{ 'wp-100': isShowSideListAction }"
  ></app-side-list>

  <div
    class="form mb-4"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <p class="header-text d-flex justify-space-between align-items-center">
          <span>
            {{
              billId === commonService.defaultGuidValue
                ? "Add New Bill"
                : "Edit Bill"
            }}
          </span>
          <button
            mat-button
            class="header-text-close d-flex align-items-center"
            disableRipple
            (click)="onCloseClick()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </p>
        <div>
          <div class="alert-message" *ngIf="isViewPermission$ | async">
            <mat-icon class="material-icons-outlined">error_outline</mat-icon>
            {{ commonNotificationText.ViewOnlyAccessMessage }}
          </div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Bill Details</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-add-bill
                [triggerEditData]="triggerEditData"
                (supplierClick)="supplierClickChange($event)"
                (isVatIncludedClick)="isVatIncludedClick($event)"
              >
              </app-add-bill>
              <app-product-details
                [triggerisVatIncluded]="triggerisVatIncluded"
                [triggereEditProductData]="triggereEditProductData"
              ></app-product-details>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="[
                isFromBankImport ? 'hidden' : !hasAdvanceAmount ? 'hidden' : '',
                (isViewPermission$ | async) ? 'is-readonly' : ''
              ]"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >payment</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Advance Payment</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-advance-payment
                [triggerAdvancePayment]="triggerAdvancePayment"
                [triggereEditAdvancePaymentData]="triggerEditData"
                [totalReceiptAmount]="
                  this.productDetails.totalAmount -
                  this.receiptDetails.totalReciptAmount
                "
                (hasAdvanceAmountUpdated)="onHasAdvanceAmountUpdated($event)"
              ></app-advance-payment>
            </mat-expansion-panel>

            <mat-expansion-panel
              [ngClass]="[
                isFromBankImport ? 'hidden' : '',
                (isViewPermission$ | async) ? 'is-readonly' : ''
              ]"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >payment</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Payments</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-transactions-payment
                [moduleId]="moduleId"
                [triggereEditPaymentData]="triggereEditPaymentData"
                [headerText]="headerText"
                [totalReceiptAmount]="
                  this.productDetails.totalAmount -
                  this.advancePaymentDetails.allocatedAdvanceAmount
                "
              ></app-transactions-payment>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >attachment</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Attachment</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-transactions-attachment
                [triggerEditData]="triggerEditData"
              ></app-transactions-attachment>
            </mat-expansion-panel>
          </mat-accordion>
          <app-product-details-footer
            [total]="this.productDetails.totalAmount"
            [balanceAmount]="
              this.productDetails.totalAmount -
              this.receiptDetails.totalReciptAmount -
              this.advancePaymentDetails.allocatedAdvanceAmount
            "
          ></app-product-details-footer>
        </div>
      </div>
    </div>
    <app-buttons
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave($event.isExit)"
      (triggerOnCancelClick)="onCancel(true)"
    ></app-buttons>
  </div>
</div>
