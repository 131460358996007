import { Injectable } from '@angular/core';
import { ViewVatDeatilsService } from '@app/core/Services';

import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetAuthorized,
  GetVatRetun,
  GetVatRetunDetails,
} from './view-vat-details.action';

export class ViewVatDetailsStateInfo {
  vatReturnData?: Array<any>;
  vatReturnDetailsData?: Array<any>;
  totalRecord?: number;
  actions?: any;
  isAuthorized?: boolean;
}

@State<ViewVatDetailsStateInfo>({
  name: 'viewVatDetails',
  defaults: {},
})
@Injectable()
export class ViewVatDetailsState {
  constructor(private viewVatDeatilsService: ViewVatDeatilsService) {}

  @Action(GetVatRetun, { cancelUncompleted: true })
  getVatRetun(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetVatRetun
  ) {
    return this.viewVatDeatilsService.getVatReturn(action.periodKey).pipe(
      tap({
        next: (res) => {
          patchState({
            vatReturnData: res,
          });
        },
      })
    );
  }

  @Action(GetVatRetunDetails, { cancelUncompleted: true })
  getVatRetunDetails(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetVatRetunDetails
  ) {
    return this.viewVatDeatilsService.getVatRetunDetails(action.periodKey).pipe(
      tap({
        next: (res) => {
          patchState({
            vatReturnDetailsData: res,
          });
        },
      })
    );
  }

  @Action(GetAuthorized)
  getAuthorized(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetAuthorized
  ) {
    return this.viewVatDeatilsService.getAuthorized().pipe(
      tap((res) => {
        patchState({
          isAuthorized: res,
        });
      })
    );
  }
}
