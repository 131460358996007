<div class="company-preference content-body-wrapper">
  <div class="content-body">
    <div class="alert-message" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div class="company-preference-info">
      <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
        (selectedTabChange)="onTabClick($event.index)"
      >
        <mat-tab label="Authorise Software">
          <div class="content-body" *ngIf="isAuthorizedSection">
            <div class="bg-yellow-300 warning" *ngIf="!isAuthorized">
              <p class="d-flex align-items-center gap-5 mb-5">
                <mat-icon fontSet="material-icons-outlined"
                  >report_problem</mat-icon
                >
                <span class="fw-bold">Info!</span> Please authorise Capium to
                access HMRC's data.
              </p>
              <p class="d-flex align-items-center gap-5 m-0">
                <a
                  href="javascript:void(0)"
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                  (click)="redirectOnUnAuthorized()"
                  class="link"
                  >Click here</a
                >
                to authorise Charity.
              </p>
            </div>
            <div class="sucess" *ngIf="isAuthorized">
              <p class="d-flex align-items-center gap-5 m-0">
                <mat-icon
                  fontSet="material-icons-outlined"
                  class="text-success-100"
                  >check_circle</mat-icon
                >
                Charity is authorised.
              </p>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="!isAuthorized" label="Obligations">
          <app-detail-list *ngIf="selectedTab === 1"></app-detail-list>
        </mat-tab>
        <mat-tab [disabled]="!isAuthorized" label="View VAT Return">
          <app-vat-return *ngIf="selectedTab === 2"></app-vat-return>
        </mat-tab>
        <mat-tab [disabled]="!isAuthorized" label="Retrieve VAT Liabilities">
          <app-detail-list *ngIf="selectedTab === 3"></app-detail-list>
        </mat-tab>
        <mat-tab [disabled]="!isAuthorized" label="Retrieve VAT Payments">
          <app-detail-list *ngIf="selectedTab === 4"></app-detail-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
