import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MTD, NotificationTextMessage } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { GetAuthorized, MenuState } from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-view-vat-detail',
  templateUrl: './view-vat-detail.component.html',
  styleUrls: ['./view-vat-detail.component.scss'],
})
export class ViewVatDetailComponent implements OnInit {
  selectedTab = 0;
  isAuthorized = false;
  isAuthorizedSection = false;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public cookieService: CookieService,
    private store: Store,
    public commonService: CommonService
  ) {}
  ngOnInit(): void {
    this.spinner.hide();
    this.checkIsAuthorized();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  checkIsAuthorized(): void {
    this.spinner.show();
    this.store.dispatch(new GetAuthorized()).subscribe(
      (res) => {
        this.spinner.hide();
        this.isAuthorized = res.viewVatDetails.isAuthorized;
        this.isAuthorizedSection = true;
      },
      (error) => {
        this.isAuthorized = false;
        this.isAuthorizedSection = true;
        this.spinner.hide();
      }
    );
  }

  onTabClick(event: any): void {
    switch (event) {
      case MTD.Obligations:
        this.cookieService.set('detailListId', MTD.Obligations.toString());
        break;
      case MTD.ViewVATReturn:
        this.cookieService.set('detailListId', MTD.ViewVATReturn.toString());
        break;
      case MTD.RetrieveVATLiabilities:
        this.cookieService.set(
          'detailListId',
          MTD.RetrieveVATLiabilities.toString()
        );
        break;
      case MTD.RetrieveVATPayments:
        this.cookieService.set(
          'detailListId',
          MTD.RetrieveVATPayments.toString()
        );
        break;

      default:
        break;
    }
    this.selectedTab = event;
  }

  redirectOnUnAuthorized(): void {
    localStorage.setItem('ignoreAccountModal', 'true');
    this.commonService.prepareUrl().subscribe((res: any) => {
      window.open(res, '_self');
    });
  }
}
