<div class="content-body">
  <form [formGroup]="fundForm">
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field">
        <p>Fund Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="fundName"
            autocomplete="off"
            appAutoFocusDirective
            unicodeCharacter
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Fund Type <span class="text-danger-300">*</span></p>
        <ng-select
          #fundType
          (focus)="fundType.open()"
          (valueChange)="fundType.close()"
          [disableOptionCentering]="true"
          formControlName="fundType"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let ft of fundTypeList" [value]="ft.id">
            <div title="{{ ft.name }}">{{ ft.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="d-flex gap-40 align-items-center">
      <div class="form-field">
        <p>Opening Balance</p>
        <mat-form-field>
          <input
            matInput
            formControlName="openingBalance"
            autocomplete="off"
            allowDecimal
          />
        </mat-form-field>
      </div>
      <div class="form-field m-0">
        <div class="d-flex gap-40 align-items-center">
          <div class="checkbox mb-0">
            <ul>
              <li>
                <mat-checkbox formControlName="isDefault"
                  >Default
                </mat-checkbox>
              </li>
            </ul>
          </div>
          <div class="checkbox mb-0">
            <ul>
              <li>
                <mat-checkbox formControlName="isTradingFund"
                  >Trading Fund
                </mat-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
