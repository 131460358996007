<div class="content-body content-body-scroll" *ngIf="isloaded">
  <div class="card-wrapper" *ngIf="this.moduleId === module.Dashboard">
    <div class="d-flex align-items-center justify-space-between gap-20">
      <mat-card>
        <mat-card-title>
          <div class="d-flex gap-20">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >currency_pound</mat-icon
              >
            </div>
            <div>
              <p class="title">Total Income</p>
              <p class="amount">£ {{ totalIncomeAmount | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">Receivable</div>
            <div class="action-amount">£ {{ incomeAmount | numberPipe }}</div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div class="d-flex gap-20">
            <div class="icon">
              <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
            </div>
            <div>
              <p class="title">Total Expenditure</p>
              <p class="amount">£ {{ totalExpenseAmount | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">Payable</div>
            <div class="action-amount">£ {{ expenseAmount | numberPipe }}</div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div class="d-flex gap-20">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >account_balance</mat-icon
              >
            </div>
            <div>
              <p class="title">Bank Balance</p>
              <p class="amount">£ {{ bank | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">
              <a href="javscript:void(0)" (click)="goToBankBook()" class="link"
                >View Bank Book</a
              >
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div class="d-flex gap-20">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >account_balance_wallet</mat-icon
              >
            </div>
            <div>
              <p class="title">Cash in Hand</p>
              <p class="amount">£ {{ cashInHand | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">
              <a href="javscript:void(0)" (click)="goToCashBook()" class="link"
                >View Cash Book</a
              >
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="card-wrapper" *ngIf="this.moduleId === module.FundOverview">
    <div class="d-flex align-items-center justify-space-between gap-20">
      <mat-card>
        <mat-card-title>
          <div class="d-flex justify-space-between">
            <div class="icon">
              <mat-icon class="material-icons-outlined">forward</mat-icon>
            </div>
            <div>
              <p class="title">Total Brought Forward</p>
              <p class="amount">£ {{ totalBroughtForward | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div class="d-flex justify-space-between">
            <div class="icon">
              <mat-icon class="material-icons-outlined">local_offer</mat-icon>
            </div>
            <div>
              <p class="title">Total Income</p>
              <p class="amount">£ {{ totalIncome | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div class="d-flex justify-space-between">
            <div class="icon">
              <mat-icon class="material-icons-outlined">shopping_bag </mat-icon>
            </div>
            <div>
              <p class="title">Total Expense</p>
              <p class="amount">£ {{ totalExpense | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div class="d-flex justify-space-between">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >account_balance_wallet</mat-icon
              >
            </div>
            <div>
              <p class="title">Total Balance</p>
              <p class="amount">£ {{ theTotalBalance | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
      </mat-card>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title mb-1">Incomes</h3>
        <div class="content-header">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div *ngIf="this.moduleId === module.FundOverview">
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    [(ngModel)]="filter1"
                    placeholder="This Accounting Period"
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear"
                      [value]="year.id"
                      title="{{ year.name }}"
                    >
                      {{ year.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <form
                *ngIf="showCalender && filter1 === 7"
                [formGroup]="rangeFormGroup"
              >
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <app-overview-incomes-chart [triggerIncomeData]="triggerIncomeData">
      </app-overview-incomes-chart>
    </div>
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title">Expenses</h3>
        <div class="content-header">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div *ngIf="this.moduleId === module.FundOverview">
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    [(ngModel)]="filter2"
                    placeholder="This Accounting Period"
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear"
                      [value]="year.id"
                      title="{{ year.name }}"
                    >
                      {{ year.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form
                *ngIf="showCalender && filter2 === 7"
                [formGroup]="rangeFormGroup"
              >
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <app-overview-incomes-chart [triggerExpensesData]="triggerExpensesData">
      </app-overview-incomes-chart>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2 mb-2">
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title mb-1">Income v/s Expenses</h3>
        <div class="content-header">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div *ngIf="this.moduleId === module.FundOverview">
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    [(ngModel)]="filter3"
                    placeholder="This Accounting Period"
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear"
                      [value]="year.id"
                      title="{{ year.name }}"
                    >
                      {{ year.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form
                *ngIf="showCalender && filter3 === 7"
                [formGroup]="rangeFormGroup"
              >
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <app-dashboard-income-expenses-chart
        [triggerIncomeVsExpensesData]="triggerIncomeVsExpensesData"
      >
      </app-dashboard-income-expenses-chart>
    </div>
    <div class="card" *ngIf="this.moduleId === module.Dashboard">
      <h3 class="card-title mb-1">Ageing Summary</h3>
      <div class="chart-tab">
        <mat-tab-group
          mat-align-tabs="start"
          [(selectedIndex)]="selectedTab"
          (selectedTabChange)="tabClick($event.index)"
          disableRipple
        >
          <mat-tab label="Receivable">
            <app-overview-summary-chart
              [triggerSummaryIncomeData]="triggerSummaryIncomeData"
            ></app-overview-summary-chart>
          </mat-tab>
          <mat-tab label="Payable">
            <app-overview-summary-chart
              [triggerSummaryExpensesData]="triggerSummaryExpensesData"
            ></app-overview-summary-chart>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div
    class="chart-wrapper d-flex gap-20"
    *ngIf="this.moduleId === module.Dashboard"
  >
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title mb-1">Cash Flow</h3>
      </div>
      <app-dashboard-cashflow-chart
        [triggerCashFlow]="triggerCashFlow"
      ></app-dashboard-cashflow-chart>
      <div class="d-flex align-items-center justify-space-between hidden">
        <div class="flow-box">
          <p class="flow-title">Income as on 01/01/2021</p>
          <p class="flow-amount">£ 4500.00</p>
        </div>
        <div class="flow-box">
          <p class="flow-title success">Incoming</p>
          <p class="flow-amount">£ 2400.00</p>
        </div>
        <div class="flow-box">
          <p class="flow-title danger">Outgoing</p>
          <p class="flow-amount">£ 2100.00</p>
        </div>
        <div class="flow-box">
          <p class="flow-title sky">Income as on 31/12/2022</p>
          <p class="flow-amount">£ 4500.00</p>
        </div>
      </div>
    </div>
  </div>
</div>
