import { Component, OnInit, Renderer2 } from '@angular/core';
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  GroupNames,
  MaxLength,
  NotificationTextMessage,
  TransactionStatus,
} from '@app/core/Enum';
import { GlobalComponent, GroupListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  GetAccountGroupAndType,
  GetGroupList,
} from '@app/core/Store';
import { CleanAllLinesComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin } from 'rxjs';
import { BankReconciliationDetailsComponent } from '../../bank-reconciliation/bank-reconciliation-details/bank-reconciliation-details.component';

@Component({
  selector: 'app-add-bulk-cash-coding',
  templateUrl: './add-bulk-cash-coding.component.html',
  styleUrls: ['./add-bulk-cash-coding.component.scss'],
})
export class AddBulkCashCodingComponent implements OnInit {
  formCashCodingDetail: UntypedFormGroup;
  cashCodingDetailArray: any;
  tableDataSource: MatTableDataSource<AbstractControl>;
  displayCashCodingDetailsColumns: string[] = [
    'date',
    'account',
    'notes',
    'transactionType',
    'amount',
    'closeButton',
  ];
  periodicDate: any;
  maxLength = MaxLength;
  notificationMessage = NotificationTextMessage;
  accountList: any[] = [];
  tempAccountGroupList: any[] = [];
  transactionType = [
    {
      id: TransactionStatus.Receipt,
      name: TransactionStatus[TransactionStatus.Receipt],
    },
    {
      id: TransactionStatus.Payment,
      name: TransactionStatus[TransactionStatus.Payment],
    },
  ];
  accountGroupAndTypeId: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    public commonService: CommonService,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setCashCodingDetailsForm(true);
    this.loadDropdownValues(true);
    this.formCashCodingDetail.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange =
        this.formCashCodingDetail.touched;
    });
  }

  getOptionText(option: any) {
    return option.name;
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setCashCodingDetailsForm(addNewRow: boolean): void {
    this.formCashCodingDetail = new FormGroup({
      cashCodingDetailArray: new UntypedFormArray([]),
    });

    this.cashCodingDetailArray = this.formCashCodingDetail.get(
      'cashCodingDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.cashCodingDetailArray);
    if (addNewRow) this.createRow(false);
  }

  createRow(isNewRow: boolean): void {
    this.cashCodingDetailArray = this.formCashCodingDetail.get(
      'cashCodingDetailArray'
    ) as UntypedFormArray;

    this.cashCodingDetailArray.push(this.setForm());
    this.setDataSource(this.cashCodingDetailArray);
  }

  loadDropdownValues(isAddMode: boolean): void {
    const account = this.store.dispatch(new GetGroupList());
    forkJoin([account]).subscribe((res) => {
      this.accountList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );
      this.tempAccountGroupList = this.accountList;
    });
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  resetAccountList(element: any): void {
    this.accountList = this.tempAccountGroupList;
    this.scrollIntoView(element.accountId.value);
  }

  onAccountSearch(event: any): void {
    this.accountList = this.tempAccountGroupList;
    const projectSearhText = event.currentTarget.value;
    const trimProjectSearhText = projectSearhText.trimStart();
    const selectedArray: GroupListModel[] = [];
    let listModel: any;
    this.accountList.filter((item) => {
      let groupName = item.groupName;
      if (
        item.groupName
          .toLowerCase()
          .includes(trimProjectSearhText.toLowerCase())
      ) {
        selectedArray.push(item);
      }
      listModel = [];
      item.listModels.filter((items) => {
        if (
          items.name.toLowerCase().includes(trimProjectSearhText.toLowerCase())
        ) {
          let groupNameExist = selectedArray.filter(
            (x) => x.groupName === groupName
          );
          let tempGroupNameExist = this.tempAccountGroupList.filter(
            (x) => x.groupName === groupName
          );
          if (groupNameExist.length > 0) {
            if (
              tempGroupNameExist[0].listModels.length !==
              groupNameExist[0].listModels.length
            ) {
              groupNameExist[0].listModels.push(items);
            }
          } else {
            listModel.push(items);
            const data = {
              groupName: groupName,
              listModels: listModel,
            };
            selectedArray.push(data);
          }
          return true;
        }
        return false;
      });
    });

    if (trimProjectSearhText) {
      this.accountList = selectedArray;
    }
  }

  getAccountGroupAndType(element, index): void {
    this.store
      .dispatch(new GetAccountGroupAndType(element.id))
      .subscribe((res) => {
        this.accountGroupAndTypeId = res.account.accountGroupAndType;
        const isAdd =
          this.accountGroupAndTypeId.groupId ===
            GroupNames.CurrentAssets_Debtors ||
          this.accountGroupAndTypeId.groupId ===
            GroupNames.CurrentLiabilities ||
          this.accountGroupAndTypeId.groupId ===
            GroupNames.NonCurrentLiabilities;

        this.cashCodingDetailArray.controls[index].get('isAdd').setValue(isAdd);
      });
  }

  accountSelect(event, element, index): void {
    if (element.value.accountId.id) {
      element.accountId = event.option.value;
      if (element.accountId.id != '-1') {
        this.getAccountGroupAndType(event.option.value, index);
      }
    } else {
      element.value.isAdd = false;
    }
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      isAdd: new FormControl(false),
      date: new FormControl(new Date(), datePickerValidator(this.periodicDate)),
      accountId: new FormControl('', [Validators.required]),
      receiptItems: new FormControl(),
      notes: new FormControl(''),
      transactionType: new FormControl(TransactionStatus.Receipt),
      amount: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?=.*[1-9])\d+(?:\.*\d\d?)?$/),
      ]),
    });
  }

  clickReceiptDetails(element: any, index: number): void {
    const obj = {
      customerName: element.accountId.id,
      accountGroupId: this.accountGroupAndTypeId.groupId,
      amountReceivedValue: element.amount,
      isBankReconciliation: false,
      receiptItems: element.value.receiptItems,
      transactionType: element.value.transactionType,
    };
    this.dialog
      .open(BankReconciliationDetailsComponent, {
        data: obj,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          element.value.receiptItems = result;
          this.cashCodingDetailArray.controls[index].controls.receiptItems =
            this.formBuilder.control(result);
        }
      });
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.accountList = this.tempAccountGroupList;
      this.createRow(true);
    }
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formCashCodingDetail.get(
            'cashCodingDetailArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
          }
        }
      });
  }

  onDeleteCashCodingDetails(index: number): void {
    this.cashCodingDetailArray = this.formCashCodingDetail.get(
      'cashCodingDetailArray'
    ) as UntypedFormArray;
    if (this.cashCodingDetailArray.length === 1) {
      return;
    }

    this.cashCodingDetailArray.removeAt(index);

    this.setDataSource(this.cashCodingDetailArray);
  }
}
