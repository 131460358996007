import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { donationOptions } from '../donation-overview.component';
import { DonationOverview, Modules } from '@app/core/Enum';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { GetDonationByDonationAndDonorOverview } from '@app/core/Store';

@Component({
  selector: 'app-donation-detail-chart',
  templateUrl: './donation-detail-chart.component.html',
  styleUrls: ['./donation-detail-chart.component.scss'],
})
export class DonationDetailChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public donationOptions: Partial<donationOptions>;
  public donorOptions: Partial<donationOptions>;

  donationData: any;
  donorData: any;

  @Input() triggerHeaderFilter: Observable<any>;
  @Input() getModuleId: number;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (+this.getModuleId === Modules.DonationOverview) {
        this.getData(filterData);
      }
    });
  }

  getData(filter?: any): void {
    const filterData = {
      moduleId: this.getModuleId,
      dateFilter: {
        filter: filter.dateFilter.filter,
        startDate: filter.dateFilter.startDate,
        endDate: filter.dateFilter.endDate,
      },
    };
    this.store
      .dispatch(new GetDonationByDonationAndDonorOverview(filterData))
      .subscribe((res) => {
        let summaryData = res.donation.donationsDonorOverview;
        this.donationData = summaryData.donations;
        this.donorData = summaryData.donors;

        const donationSeries: any[] = [];
        const donorSeries: any[] = [];

        donationSeries.push(this.donationData.series);
        donorSeries.push(this.donorData.series);

        this.donationOptions = {
          series: donationSeries,
          chart: {
            height: 70,
            width: '80%',
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          fill: {
            colors: [this.donationData.isProfit ? '#00E396' : '#FF0000'],
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          grid: {
            show: false,
          },
          yaxis: {
            show: false,
          },
          stroke: {
            show: true,
            colors: [this.donationData.isProfit ? '#00E396' : '#FF0000'],
            curve: 'smooth',
          },
          xaxis: {
            type: 'category',
            tooltip: {
              enabled: false,
            },
            labels: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          tooltip: {
            enabled: false,
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        };
        this.donorOptions = {
          series: donorSeries,
          chart: {
            height: 70,
            width: '80%',
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          fill: {
            colors: [this.donorData.isProfit ? '#00E396' : '#FF0000'], //#00E396- green //#FF0000- red
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          grid: {
            show: false,
          },
          yaxis: {
            show: false,
          },
          stroke: {
            show: true,
            colors: [this.donorData.isProfit ? '#00E396' : '#FF0000'], //#00E396- green //#FF0000- red
            curve: 'smooth',
          },
          xaxis: {
            type: 'category',
            tooltip: {
              enabled: false,
            },
            labels: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          tooltip: {
            enabled: false,
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        };
      });
  }
}
