<div
  class="content-body"
  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
>
  <form [formGroup]="submitVatForm">
    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>From</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="fromDate"
            [matDatepicker]="fromDatePicker"
            autocomplete="off"
            (keydown)="commonService.validateDate($event)"
            tabindex="-1"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fromDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>To</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="toDate"
            [matDatepicker]="toDatePicker"
            autocomplete="off"
            (keydown)="commonService.validateDate($event)"
            tabindex="-1"
            [min]="this.submitVatForm.controls.fromDate.value"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="toDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field wp-100 textarea">
      <p>Description</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="description"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="checkbox">
      <mat-checkbox formControlName="isVat">De-registered for VAT</mat-checkbox>
      <p>
        Please tick this box if this is your last VAT submission. This is only
        needed when you have already cancelled you VAT registration at the
        HMRC's end.
      </p>
    </div>
  </form>
</div>
