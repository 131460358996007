import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberPipe',
})
export class NumberPipe implements PipeTransform {
  transform(value: any): any {
    value = parseFloat(value === '' ? 0 : value).toFixed(2);

    if (value < 0) {
      // adding the desired parenthesis and removing the '-' sign
      value = '(' + value + ')';
      value = value.replace('-', '');
    }

    const displayValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return displayValue.toLowerCase() === 'nan' ? 0 : displayValue;
  }
}
