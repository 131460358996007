<div
  class="pagination-toggle"
  (click)="togglePagination()"
  [ngClass]="[showPaginator ? '' : 'pagination-toggle-stick-bottom']"
>
  <mat-icon>{{
    showPaginator ? "keyboard_arrow_down" : "keyboard_arrow_up"
  }}</mat-icon>
</div>
<div
  class="paginator-container"
  [ngClass]="[showPaginator ? 'show-pagination' : 'hide-pagination']"
>
  <div class="paginator-size">
    <span class="mr-1">
      Total Records :<span
        *ngIf="
          moduleId !== modules.BankFeed &&
          moduleId !== modules.BankDashboard &&
          moduleId !== modules.Users
        "
        class="fw-bold"
      >
        {{ totalRecord$ | async }}</span
      >
      <span
        *ngIf="moduleId === modules.Users || isClientAssign"
        class="fw-bold"
      >
        {{
          isClientAssign
            ? (totalRecordOfUserClient$ | async)
            : (totalUserRecord$ | async)
        }}</span
      >
      <span *ngIf="moduleId === modules.BankFeed" class="fw-bold">
        {{ totalBankReconciliationRecord$ | async }}</span
      >
      <span *ngIf="moduleId === modules.BankDashboard" class="fw-bold">
        {{ totalBankOverviewRecord$ | async }}</span
      >
    </span>
    <span class="mr-1 text-black">Show</span>
    <mat-form-field appearance="fill">
      <mat-select
        (selectionChange)="onSelectionChange($event.value)"
        [value]="pageSizestr"
        panelClass="pagination"
        [disableOptionCentering]="true"
      >
        <mat-option value="10">10</mat-option>
        <mat-option value="20">20</mat-option>
        <mat-option value="50">50</mat-option>
        <mat-option value="100">100</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="paginator-pages-count">
    <ng-container [formGroup]="paginatorForm" novalidate>
      <button
        mat-button
        class="paginator-arrow paginator-previous"
        [class]="
          listParameters.pageNumber === 1 ||
          listParameters.pageNumber > math.ceil(this.length / this.pageSize)
            ? 'previous-disabled'
            : 'previous'
        "
        (click)="
          goToPreviousPage(paginatorForm.controls.pageNumber.value, true)
        "
        disableRipple
      >
        <mat-icon>chevron_left</mat-icon>
        <span>Previous</span>
      </button>

      <button
        *ngIf="numPages >= page1"
        mat-button
        (click)="onPageNumberFirstClick(page1)"
        disableRipple
        [ngClass]="listParameters.pageNumber === page1 ? 'active' : ''"
      >
        {{ page1 }}
      </button>
      <button
        *ngIf="numPages >= page2"
        mat-button
        (click)="onPageNumberClick(page2)"
        disableRipple
        [ngClass]="listParameters.pageNumber === page2 ? 'active' : ''"
      >
        {{ page2 }}
      </button>
      <button
        *ngIf="numPages >= page3"
        mat-button
        (click)="onPageNumberClick(page3)"
        disableRipple
        [ngClass]="listParameters.pageNumber === page3 ? 'active' : ''"
      >
        {{ page3 }}
      </button>
      <button
        *ngIf="numPages >= page4"
        mat-button
        (click)="onPageNumberClick(page4)"
        disableRipple
        [ngClass]="listParameters.pageNumber === page4 ? 'active' : ''"
      >
        {{ page4 }}
      </button>
      <button
        *ngIf="numPages >= page5"
        mat-button
        (click)="onPageNumberLastClick(page5)"
        disableRipple
        [ngClass]="listParameters.pageNumber === page5 ? 'active' : ''"
      >
        {{ page5 }}
      </button>
      <button
        mat-button
        class="paginator-arrow paginator-next"
        [class]="
          listParameters.pageNumber >= math.ceil(length / pageSize)
            ? 'next-disabled'
            : 'next'
        "
        (click)="goToNextPage(paginatorForm.controls.pageNumber.value, true)"
        disableRipple
      >
        <span>Next</span>
        <mat-icon>chevron_right</mat-icon>
      </button>

      <div class="mat-paginator-input">
        <span class="mr-1 go-to-text">Go to</span>
        <mat-form-field appearance="fill" class="text-align-center">
          <input
            matInput
            formControlName="pageNumber"
            type="number"
            class="paginator-number"
            tshqNumbersOnly
            max="numPages"
            autocomplete="off"
            (keyup)="onKeyUp($event)"
            (keyup.enter)="onEnterKey()"
          />
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</div>
