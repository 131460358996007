import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Observable, Subject, Subscription } from 'rxjs';

import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  FixedAssetsModel,
  PaymentDetailsModel,
  ProductDetailsModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateFixedAssets,
  GetDataByFixedAssetsId,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddFixedAssestsComponent,
  AdvancePaymentComponent,
  ProductDetailsComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-fixed-assets',
  templateUrl: './fixed-assets.component.html',
  styleUrls: ['./fixed-assets.component.scss'],
})
export class FixedAssetsComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isExit = false;

  fixedAssetsId = Guid.EMPTY as unknown as Guid;

  moduleId = Modules.FixedAssets;
  moduleName = ModuleName.FixedAssets;

  fixedAssetsData: FixedAssetsModel;
  productItems: Array<ProductDetailsModel>;
  receiptItems: Array<PaymentDetailsModel>;

  @ViewChild(AddFixedAssestsComponent, { static: true })
  fixedAssetsDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  productDetails;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  receiptDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(AdvancePaymentComponent, { static: true })
  advancePaymentDetails;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggereEditPaymentData: Subject<any> = new Subject<any>();
  triggerAdvancePayment: Subject<any> = new Subject<any>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();

  headerText = 'Payment';
  date: any;
  hasAdvanceAmount = false;

  _Activatedroute: ActivatedRoute;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  location: Location;
  renderer: Renderer2;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(private injector: Injector) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.location = injector.get<Location>(Location);
    this.renderer = injector.get<Renderer2>(Renderer2);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.fixedAssetsId = atob(params.get('id')!) as unknown as Guid;
          this.editFixAssets();
        }
      }
    );
  }

  editFixAssets(): void {
    this.store
      .dispatch(new GetDataByFixedAssetsId(this.fixedAssetsId))
      .subscribe((res) => {
        this.fixedAssetsDetails.fixedAssetsForm.markAsUntouched();
        this.productDetails.formProductDetail.markAsUntouched();
        this.receiptDetails.paymentForm.markAsUntouched();
        this.triggerEditData.next(res.fixedAssets.fixedAssetsData);
        this.triggereEditProductData.next(
          res.fixedAssets.fixedAssetsData.items
        );
        this.triggereEditPaymentData.next(
          res.fixedAssets.fixedAssetsData.receiptDetails
        );
        this.triggerAdvancePayment.next(
          res.fixedAssets.fixedAssetsData.accountId
        );
        this.triggerisVatIncluded.next(
          res.fixedAssets.fixedAssetsData.isVatIncluded
        );
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.productItems = new Array<ProductDetailsModel>();
      this.receiptItems = new Array<PaymentDetailsModel>();

      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.productDetails.productDetailArray?.getRawValue().forEach((x) => {
        this.productItems.push({
          id: x.id,
          productName: x.productName.id === undefined ? x.productName : '',
          productId:
            x.productName.id === undefined
              ? (Guid.EMPTY as unknown as Guid)
              : x.productName.id,
          description: x.description,
          quantity: +x.qty,
          price: +x.price,
          vatAmount: x.vatAmount,
          vatRateId: x.vatRate === -1 ? null : x.vatRate,
          totalAmount: +x.amount,
        });
      });

      this.receiptDetails.paymentArray?.getRawValue().forEach((x) => {
        const attachmentList: any = [];
        if (
          x.attachment !== null &&
          x.attachment !== undefined &&
          x.attachment !== '' &&
          x.attachment.length > 0
        ) {
          x.attachment.forEach((element) => {
            attachmentList.push(element);
          });
        }
        if (x.accountId !== '' && x.amount !== '') {
          this.receiptItems.push({
            id: x.id,
            receiptId: x.receiptId,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            amount: +x.amount,
            postingAccountId: x.accountId,
            attachment: attachmentList,
          });
        }
      });

      this.fixedAssetsData = {
        id: this.fixedAssetsId,
        accountId:
          this.fixedAssetsDetails.fixedAssetsForm.controls.accountId.value,
        bookAccountId:
          this.fixedAssetsDetails.fixedAssetsForm.controls.invoiceType.value.id,
        fundId: this.fixedAssetsDetails.fixedAssetsForm.controls.fundName.value,
        entryNumber:
          this.fixedAssetsDetails.fixedAssetsForm.controls.billNo.value,
        entryDate: this.datepipe
          .transform(
            this.fixedAssetsDetails.fixedAssetsForm.controls.invoiceDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        currencyId:
          this.fixedAssetsDetails.fixedAssetsForm.controls.currencyId.value,
        note: this.fixedAssetsDetails.fixedAssetsForm.controls.note.value,
        dueDate: this.datepipe
          .transform(
            this.fixedAssetsDetails.fixedAssetsForm.controls.dueDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        items: this.productItems,
        receiptDetails: this.receiptItems,
        attachment: filedata,
        advanceReceiptAmount:
          +this.advancePaymentDetails.advancePaymentDetailForm.controls
            .allocateAdvance.value,
        isVatIncluded:
          this.fixedAssetsDetails.fixedAssetsForm.controls.isVatInclude.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.fixedAssetsId,
      isExit,
      Modules.FixedAssets,
      RoutingPath.AddFixedAssets
    );
  }

  onSave(isExit: boolean): void {
    if (this.fixedAssetsDetails.fixedAssetsForm.invalid) {
      this.commonService.addValidation(
        this.fixedAssetsDetails.fixedAssetsForm,
        this.renderer
      );
    } else if (
      this.productDetails.totalAmount < this.receiptDetails.totalReciptAmount
    ) {
      this.receiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any)
          .values(x.controls)
          .forEach((c) => c.markAsTouched(false));
        x.controls.amount.status = 'INVALID';
      });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (this.productDetails.productDetailArray.invalid) {
      this.productDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else if (this.receiptDetails.paymentArray.invalid) {
      this.receiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (this.advancePaymentDetails.advancePaymentDetailForm.invalid) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      this.advancePaymentDetails.advancePaymentDetailForm.controls
        .allocateAdvance.value > this.productDetails.totalAmount
    ) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.fixedAssetsData) {
          this.store
            .dispatch(new CreateFixedAssets(this.fixedAssetsData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.fixedAssetsId)) {
      this.editFixAssets();
    } else {
      this.fixedAssetsId = Guid.EMPTY as unknown as Guid;
      this.fixedAssetsDetails.ngOnInit();
      this.productDetails.ngOnInit();
      this.receiptDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  supplierClickChange(event) {
    this.triggerAdvancePayment.next(event);
  }

  onHasAdvanceAmountUpdated(updatedValue: boolean): void {
    this.hasAdvanceAmount = updatedValue;
  }

  isVatIncludedClick(event): void {
    this.triggerisVatIncluded.next(event);
  }
}
