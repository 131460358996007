import { Component, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexFill,
  ApexDataLabels,
  ApexLegend,
} from 'ng-apexcharts';

export type incomeClientChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
};

@Component({
  selector: 'app-dashboard-income-client',
  templateUrl: './dashboard-income-client.component.html',
  styleUrls: ['./dashboard-income-client.component.scss'],
})
export class DashboardIncomeClientComponent {
  @ViewChild('chart') chart: ChartComponent;
  public incomeClientChartOptions: Partial<incomeClientChartOptions>;

  constructor() {
    this.incomeClientChartOptions = {
      series: [30, 30, 30],
      chart: {
        width: 400,
        height: 400,
        type: 'pie',
        offsetX: -100,
        offsetY: 0,
      },
      fill: {
        colors: ['#7B76D3', '#BA5397', '#F4C64D'],
      },
      legend: {
        position: 'right',
        horizontalAlign: 'right',
        offsetX: -30,
        offsetY: 20,
        markers: {
          fillColors: ['#7B76D3', '#BA5397', '#F4C64D'],
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: [
        'Restricted funds - 50%  </br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2,400.00',
        'Unrestricted - 20%  </br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 240.00',
        'Endowment funds - 30%  </br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 140.00',
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
}
