import { CompanyEmailSetting } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetCompanyMailPreference {
  static readonly type = '[Integration] GET Company Mail Preference';
}

export class UpdateEmailConfig {
  static readonly type = '[Integration] Update Email Config';
  constructor(public isEmailSendFromCapium: boolean) {}
}

export class PrepareGoogleOrOffice {
  static readonly type = '[Integration] Prepare Google Or Office';
  constructor(public providerRediredUrl: any) {}
}

export class DeleteMail {
  static readonly type = '[Integration] Delete Mail';
  constructor(public id: Guid) {}
}

export class GetUserMailList {
  static readonly type = '[Integration] GetUserMailList';
  constructor(public providerId: number) {}
}

export class DefaultMail {
  static readonly type = '[Integration] Default Mail';
  constructor(public isDefault: any) {}
}

export class SavecompanyEmailSetting {
  static readonly type = '[Integration] Create EmailSetting';

  constructor(public emailSetting: Array<CompanyEmailSetting>) {}
}

export class GetCompanyEmailSettingsList {
  static readonly type = '[Integration] GET Company Email Settings';
}
