import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import { MaxLength, Modules, NotificationTextMessage } from '@app/core/Enum';
import { GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetBillBasedOnSupplier,
  GetBillDetail,
  GetInvoiceBasedOnCustomer,
  GetInvoiceReceipt,
} from '@app/core/Store';
import { CleanAllLinesComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export function scrollFactory12(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
@Component({
  selector: 'app-credit-note-allocation',
  templateUrl: './credit-note-allocation.component.html',
  styleUrls: ['./credit-note-allocation.component.scss'],
})
export class CreditNoteAllocationComponent implements OnInit {
  creditDetailForm: FormGroup;
  formCreditDetail: UntypedFormGroup;

  invoiceList: SideListModel[] = [];
  creditData: any[];

  creditDetailArray: any;

  @Input() triggerCreditCustomer: Observable<any>;
  @Input() triggerDebitCustomer: Observable<any>;

  @Input() getModuleId: number;
  @Input() triggerReceiptDetail: Observable<any>;
  @Input() amountReceivedValue: number;

  @Output() amountReceived = new EventEmitter<any>();

  totalAmount = 0;
  customerId = Guid.EMPTY as unknown as Guid;

  moduleId = Modules;
  invoiceText: string;
  amountText: string;
  headerText: string;
  isCredit = true;
  isReceiptDetailValid = true;
  periodicDate: any;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  //#endregion

  tableDataSource: MatTableDataSource<AbstractControl>;
  displayProductDetailsColumns: string[] = [
    'invoiceNo',
    'invoice',
    'amount',
    'due',
    'allocationAmount',
    'closeButton',
  ];
  isEdit = false;
  accountGroupId: number;

  private destroy$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    public commonService: CommonService,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setReceiptDetailsForm(false);

    this.triggerCreditCustomer?.subscribe((data: any) => {
      this.setData(data);
    });

    this.triggerDebitCustomer?.subscribe((data: any) => {
      this.setData(data);
    });

    this.triggerReceiptDetail
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isEdit = true;
        this.editCreditDebitNoteDetail(data);
      });

    this.formCreditDetail?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formCreditDetail.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setData(data: any): void {
    const receiptLength = this.creditDetailArray.length;
    if (this.getModuleId === Modules.DebitNote) {
      this.customerId =
        data.controls.accountId.value !== this.commonService.defaultGuidValue
          ? data.controls.accountId.value === ''
            ? null
            : data.controls.accountId.value
          : this.commonService.defaultGuidValue;
    } else {
      this.customerId =
        data.controls.customerName.value !== this.commonService.defaultGuidValue
          ? data.controls.customerName.value === ''
            ? null
            : data.controls.customerName.value
          : this.commonService.defaultGuidValue;
    }
    for (let i = 0; i < receiptLength; i++) {
      this.creditDetailArray.removeAt(i);
      this.setDataSource(this.creditDetailArray);
    }
    this.setReceiptDetailsForm(false);
    this.setLabelText();
  }

  setLabelText(): void {
    if (this.getModuleId === Modules.DebitNote) {
      this.invoiceText = 'Bill No';
      if (this.customerId !== null && this.customerId !== undefined) {
        this.getBillbySupplier();
      }
    } else if (this.getModuleId === Modules.CreditNote) {
      this.invoiceText = 'Invoice No';
      if (this.customerId !== null && this.customerId !== undefined) {
        this.getInvoicebyCustomer();
      }
    }
  }

  editCreditDebitNoteDetail(data: any): void {
    this.creditData = [];

    this.creditDetailArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;

    this.creditDetailArray.clear();

    data.forEach((element) => {
      this.creditData?.push(element);
    });

    this.creditData.forEach((item, i) => {
      this.creditDetailArray.push(this.buildOrderItemsForm(item));
      this.onInvoiceChange(item.toInvoiceId, i);
    });

    this.onAmountChange();
    this.setDataSource(this.creditDetailArray);
  }

  buildOrderItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      invoiceReceipt: item.toInvoiceId,
      invoiceAmount: 0,
      dueAmount: 0,
      amount: item.amount,
      invoiceTypeId: item.toInvoiceTypeID,
    });
  }

  setDefaultData(): void {
    if (this.invoiceList.length > 0) {
      this.creditDetailArray.controls[this.creditDetailArray.length - 1]
        .get('invoiceReceipt')
        ?.setValue(this.invoiceList[0].id);
      this.onInvoiceChange(
        this.invoiceList[0].id,
        this.creditDetailArray.length - 1
      );
    }
  }

  getBillbySupplier(): void {
    this.store
      .dispatch(new GetBillBasedOnSupplier(this.customerId))
      .subscribe((res) => {
        this.invoiceList = res.receipt.billByCustomer;
      });
  }

  getInvoicebyCustomer(): void {
    this.store
      .dispatch(new GetInvoiceBasedOnCustomer(this.customerId))
      .subscribe((res) => {
        this.invoiceList = res.receipt.invoiceByCustomer;
      });
  }

  onDeleteReceiptDetails(index: number): void {
    this.creditDetailArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;
    this.creditDetailArray.removeAt(index);
    this.setDataSource(this.creditDetailArray);
  }

  onAmountChange(): void {
    const formArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;

    this.totalAmount = 0;

    formArray?.getRawValue().forEach((x) => {
      this.totalAmount = this.totalAmount + Number(x.amount);
    });

    this.amountReceived.emit(+this.totalAmount);
  }

  onInvoiceChange(event: any, index: number): void {
    if (event !== null && event !== undefined) {
      if (this.getModuleId === Modules.DebitNote) {
        this.store.dispatch(new GetBillDetail(event)).subscribe((res) => {
          this.setDataDisable(res.receipt.billReceiptData, index);
        });
      } else if (this.getModuleId === Modules.CreditNote) {
        this.store.dispatch(new GetInvoiceReceipt(event)).subscribe((res) => {
          this.setDataDisable(res.receipt.invoiceReceiptData, index);
        });
      }
    }
  }

  setDataDisable(data: any, index: number): void {
    this.creditDetailArray.controls[index]
      .get('invoiceTypeId')
      ?.setValue(data.invoiceTypeId);
    this.creditDetailArray.controls[index]
      .get('invoiceAmount')
      ?.setValue(data.totalAmount);
    this.creditDetailArray.controls[index]
      .get('dueAmount')
      ?.setValue(data.dueAmount);

    this.creditDetailArray.controls[index].get('invoiceAmount').disable();
    this.creditDetailArray.controls[index].get('dueAmount').disable();
  }

  resetForm(): void {
    const frmArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;
    frmArray.clear();
  }

  setReceiptDetailsForm(addNewRow: boolean): void {
    this.formCreditDetail = new FormGroup({
      creditDetailArray: new UntypedFormArray([]),
    });

    this.creditDetailArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;
    this.setDataSource(this.creditDetailArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.creditDetailArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;
    this.creditDetailArray.push(this.createItem());

    if (
      this.creditDetailArray.length > 0 &&
      !this.commonService.isEmpty(this.customerId)
    ) {
      if (this.getModuleId === Modules.CreditNote) {
        if (this.customerId !== null && this.customerId !== undefined) {
          this.getInvoicebyCustomer();
        }
      } else {
        if (this.customerId !== null && this.customerId !== undefined) {
          this.getBillbySupplier();
        }
      }
    }

    this.setDataSource(this.creditDetailArray);
  }

  clearAllLines(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.clearForm();
        }
      });
  }

  clearForm(): void {
    const formArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;

    for (let i = 0; i < formArray.length; i++) {
      formArray.controls[i].reset();
      formArray.controls[i].clearValidators();
      formArray.controls[i].get('invoiceReceipt')?.setValue(0);
      formArray.controls[i].get('id')?.setValue(Guid.EMPTY as unknown as Guid);
      formArray.controls[i].get('invoiceAmount')?.setValue(0);
      formArray.controls[i].get('dueAmount')?.setValue(0);
      formArray.controls[i].get('amount')?.setValue(0);
      formArray.controls[i].updateValueAndValidity();
    }
    this.totalAmount = 0;
  }

  addNewRow(): void {
    if (this.amountReceivedValue <= this.totalAmount) {
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    }
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid>(Guid.EMPTY as unknown as Guid),
      invoiceNo: new FormControl<string | null>(''),
      invoiceReceipt: new FormControl('', [Validators.required]),
      invoiceAmount: new FormControl<number | null>(0, [Validators.required]),
      dueAmount: new FormControl<number | null>(0, [Validators.required]),
      amount: new FormControl<string | null>('', [Validators.required]),
      invoiceTypeId: new FormControl<number | null>(0),
    });
  }
}
