export class EmailTemplateModel {
  id: number;
  templateTypeName: string;
  templateDetails: Array<TemplateDetail>;
}

export class TemplateDetail {
  id?: number;
  businessTemplateId?: number;
  templateId?: number;
  displayName?: string;
  subject?: string;
  templateHTML?: string;
  templateParams?: string;
  suggestedLabels?: any;
}
