import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent {
  @Input() list: any[];
  @Output() GetSelectedData = new EventEmitter();
  selectedData: any[];
  selectedName: any[];

  constructor() {
    this.selectedData = [];
    this.selectedName = [];
  }

  getSelectedValue(data: any): void {
    this.selectedData = [];
    this.selectedName = [];

    data.forEach((x) => {
      this.selectedName.push(this.list[x].name);
      this.selectedData.push(x);
    });

    this.GetSelectedData.emit(this.selectedData);
  }
}
