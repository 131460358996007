import {
  IncluceExcludeTransactionModel,
  MainListParameters,
  SubmitVATModel,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class GetVatDetails {
  static readonly type = '[VATSETTINGS] Get VAT deatils';
  constructor(public id: Guid) {}
}

export class SetDefaultVatId {
  static readonly type = '[VATSETTINGS] Set Default VatId';
}

export class CreateVat {
  static readonly type = '[VATSETTINGS] Create VAT';

  constructor(public vatData: SubmitVATModel) {}
}

export class SetSubmitVatId {
  static readonly type = '[VATSETTINGS] Set Submit Vat Id';

  constructor(public id: Guid) {}
}

export class CreateVATReturnStatus {
  static readonly type = '[VATSETTINGS] Create VATReturnStatus';

  constructor(public vatReturnStatus: number, public id: Guid) {}
}

export class GetVatReturnDate {
  static readonly type = '[VATSETTINGS] Get Vat Return Date';
}

export class GetVatRemainingTransaction {
  static readonly type = '[VATSETTINGS] Get Vat Remaining Transaction List ';

  constructor(
    public queryParams: MainListParameters,
    public isHeaderVisible: boolean,
    public id: Guid
  ) {}
}

export class CheckVatNumber {
  static readonly type = '[VATSETTINGS] Check Vat Number ';
}

export class GetvatRemainingTransactionHeader {
  static readonly type = '[VATHEADERLIST] Get Vat Header List ';
}

export class SaveIncludeExclude {
  static readonly type = '[VATSUBMIT] Save Include Exclude submitlist';

  constructor(
    public params: IncluceExcludeTransactionModel,
    public id: Guid,
    public isInclude: boolean
  ) {}
}
