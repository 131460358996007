<form [formGroup]="note25Form">
  <div class="notes-wrapper">
    <div class="notes-section">
      <p class="fw-bold notes-heading">Cost or valuation</p>
      <table class="notes-table" aria-describedby="User Task Table">
        <ng-container *ngIf="calculativeData?.costAndValuation?.length === 0">
          <tr>
            <td class="text-align-center mt-1 fw-bold">
              <span>No records found</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="calculativeData?.costAndValuation?.length > 0">
          <tr>
            <th scope="col"></th>
            <th
              scope="col"
              *ngFor="let item of calculativeData?.costAndValuation"
            >
              {{ item?.name }}
            </th>
          </tr>

          <tr>
            <th class="text-align-left" scope="col">
              At the beginning of the year
            </th>
            <td *ngFor="let item of calculativeData?.costAndValuation">
              {{ item?.beginning | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">Additions</th>
            <td *ngFor="let item of calculativeData?.costAndValuation">
              {{ item?.addition | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">Revaluations</th>
            <td *ngFor="let item of calculativeData?.costAndValuation">
              {{ item?.revaluation | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">Disposals</th>
            <td *ngFor="let item of calculativeData?.costAndValuation">
              {{ item?.disposal | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">Transfers *</th>
            <td *ngFor="let item of calculativeData?.costAndValuation">
              {{ item?.transfer | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">At end of the year</th>
            <td *ngFor="let item of calculativeData?.costAndValuation">
              {{ item?.atEnd | numberPipe }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div class="notes-wrapper">
      <div class="notes-section">
        <ejs-richtexteditor
          [toolbarSettings]="
            commonService.syncfusionRichTextEditorToolbarSettings
          "
          (actionBegin)="
            commonService.syncfusionRichTextEditorOnActionBegin($event)
          "
          [enableXhtml]="true"
          [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
          formControlName="textbox1"
          [enableHtmlSanitizer]="true"
          [pasteCleanupSettings]="
            commonService.syncfusionRichTextEditorPasteCleanupSettings
          "
        ></ejs-richtexteditor>
      </div>
    </div>
    <div class="notes-section">
      <p class="fw-bold notes-heading">Depreciation and impairments</p>
    </div>
    <div class="notes-section">
      <table class="notes-table" aria-describedby="User Task Table">
        <ng-container *ngIf="calculativeData?.depriciations?.length === 0">
          <tr>
            <td class="text-align-center mt-1 fw-bold">
              <span>No records found</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="calculativeData?.depriciations?.length > 0">
          <tr>
            <th scope="col"></th>
            <th scope="col" *ngFor="let item of calculativeData?.depriciations">
              {{ item?.name }}
            </th>
          </tr>

          <tr>
            <th class="text-align-left" scope="col">
              At beginning of the year
            </th>
            <td *ngFor="let item of calculativeData?.depriciations">
              {{ item?.beginning | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">Additions</th>
            <td *ngFor="let item of calculativeData?.depriciations">
              {{ item?.addition | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">Disposals</th>
            <td *ngFor="let item of calculativeData?.depriciations">
              {{ item?.disposal | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">Revaluations</th>
            <td *ngFor="let item of calculativeData?.depriciations">
              {{ item?.revaluation | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">Transfers *</th>
            <td *ngFor="let item of calculativeData?.depriciations">
              {{ item?.transfer | numberPipe }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" scope="col">At end of the year</th>
            <td *ngFor="let item of calculativeData?.depriciations">
              {{ item?.atEnd | numberPipe }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div class="notes-wrapper">
      <div class="notes-section">
        <ejs-richtexteditor
          [toolbarSettings]="
            commonService.syncfusionRichTextEditorToolbarSettings
          "
          (actionBegin)="
            commonService.syncfusionRichTextEditorOnActionBegin($event)
          "
          [enableXhtml]="true"
          [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
          formControlName="textbox2"
          [enableHtmlSanitizer]="true"
          [pasteCleanupSettings]="
            commonService.syncfusionRichTextEditorPasteCleanupSettings
          "
        ></ejs-richtexteditor>
      </div>
    </div>
    <div class="notes-section">
      <p class="fw-bold notes-heading">Net book value</p>
      <table class="notes-table" aria-describedby="User Task Table">
        <ng-container
          *ngIf="
            calculativeData?.costAndValuation?.length === 0 ||
            calculativeData?.depriciations?.length === 0
          "
        >
          <tr>
            <td class="text-align-center mt-1 fw-bold">
              <span>No records found</span>
            </td>
          </tr>
        </ng-container>
        <ng-container
          *ngIf="
            calculativeData?.costAndValuation?.length > 0 ||
            calculativeData?.depriciations?.length > 0
          "
        >
          <tr>
            <th scope="col"></th>
            <th
              scope="col"
              *ngFor="let item of calculativeData?.costAndValuation"
            >
              {{ item?.name }}
            </th>
          </tr>
          <tr>
            <th class="text-align-left" style="width: 20%" scope="col">
              Net book value at the beginning of the year
            </th>
            <td
              *ngFor="
                let item of calculativeData?.costAndValuation;
                let i = index
              "
            >
              {{
                calculativeData?.costAndValuation[i]?.beginning -
                  calculativeData?.depriciations[i]?.beginning | numberPipe
              }}
            </td>
          </tr>
          <tr>
            <th class="text-align-left" style="width: 20%" scope="col">
              Net book value at the end of the year
            </th>
            <td
              *ngFor="
                let item of calculativeData?.costAndValuation;
                let i = index
              "
            >
              {{
                calculativeData?.costAndValuation[i]?.atEnd -
                  calculativeData?.depriciations[i]?.atEnd | numberPipe
              }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div class="notes-wrapper">
      <div class="notes-section">
        <ejs-richtexteditor
          [toolbarSettings]="
            commonService.syncfusionRichTextEditorToolbarSettings
          "
          (actionBegin)="
            commonService.syncfusionRichTextEditorOnActionBegin($event)
          "
          [enableXhtml]="true"
          [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
          formControlName="textbox3"
          [enableHtmlSanitizer]="true"
          [pasteCleanupSettings]="
            commonService.syncfusionRichTextEditorPasteCleanupSettings
          "
        ></ejs-richtexteditor>
      </div>
    </div>
  </div>
</form>
