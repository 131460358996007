<div id="chart" *ngIf="!isNoRecord && isloadData">
  <apx-chart
    [series]="cashFlowChartOptionsv1?.series!"
    [chart]="cashFlowChartOptionsv1?.chart!"
    [dataLabels]="cashFlowChartOptionsv1?.dataLabels!"
    [plotOptions]="cashFlowChartOptionsv1?.plotOptions!"
    [yaxis]="cashFlowChartOptionsv1?.yaxis!"
    [legend]="cashFlowChartOptionsv1?.legend!"
    [fill]="cashFlowChartOptionsv1?.fill!"
    [stroke]="cashFlowChartOptionsv1?.stroke!"
    [tooltip]="cashFlowChartOptionsv1?.tooltip!"
    [xaxis]="cashFlowChartOptionsv1?.xaxis!"
    [noData]="cashFlowChartOptionsv1?.noData!"
  ></apx-chart>
</div>
<div class="norecord-chart" *ngIf="isNoRecord && isloadData">
  <img src="assets/images/pia-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
