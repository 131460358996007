import { DonationModel, OverviewFilterModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateDonation {
  static readonly type = '[DONATION] Create Donation';

  constructor(public donation: DonationModel) {}
}

export class GetDataByDonationId {
  static readonly type = '[DONATION] Get Donation Data By Donation Id';

  constructor(public donationId: Guid) {}
}

export class ArchiveAndRestoreDonation {
  static readonly type = '[DONATION] Archive and Restore Selected Donation';

  constructor(public donationIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteDonation {
  static readonly type = '[DONATION] Delete Selected Donation';

  constructor(public donationIds: Array<Guid>) {}
}

export class GetSponsoreList {
  static readonly type = '[DONATION] Get Sponsore';
}

export class GetActivityList {
  static readonly type = '[DONATION] Get Activity';
}

export class GetDonationFundTypesOverview {
  static readonly type = '[DONATION] Get Donation Breakdown Overview';
  constructor(public filterData: any) {}
}

export class GetDonationEndowmentFundDataOverview {
  static readonly type = '[DONATION] Get Donation Endowment Capital Overview';
  constructor(public filterData: any) {}
}

export class GetDonationDonorsAndDonationOverview {
  static readonly type = '[DONATION] Get Income Expenses By Donation Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDonationsInKindOverview {
  static readonly type = '[DONATION] Get Income Vs Expenses Donation Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDonationsFlowOverview {
  static readonly type = '[DONATION] Get Donations Flow Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDonationByDonationAndDonorOverview {
  static readonly type =
    '[DONATION] Get Donation ByD onation And Donor Overview';
  constructor(public filterData: any) {}
}
