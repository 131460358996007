import { MainListParameters, SaveBulkEditModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetBulkEditList {
  static readonly type = '[BULKEDIT] Get Bulk List';

  constructor(public queryParams: MainListParameters) {}
}

export class SaveBulkEdit {
  static readonly type = '[BULKEDIT] Save Bulk List';

  constructor(public params: Array<SaveBulkEditModel>) {}
}

export class GetInvoiceTypeBasedOfAccount {
  static readonly type = '[BULKEDIT] Get Account Group And Type';

  constructor(public invoiceTypeId: Guid) {}
}
