import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GroupNames, ModuleName, Modules, OverviewYear } from '@app/core/Enum';
import { ReportFilterTypes } from '@app/core/Enum/report-filter-type';
import { FilterDateRange, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetChartOfAccountTypeList } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-expenditure-overview',
  templateUrl: './expenditure-overview.component.html',
  styleUrls: ['./expenditure-overview.component.scss'],
})
export class ExpenditureOverviewComponent implements OnInit {
  moduleId = Modules.ExpenditureOverview;

  @Output()
  readonly triggerHeaderFilterForExpenseBreakdown = new EventEmitter<any>();

  @Output()
  readonly triggerHeaderFilter = new EventEmitter<any>();

  filter1 = ReportFilterTypes.Last30Days;
  rangeFormGroup: FormGroup;
  selectYear = OverviewYear;
  selectedYearValue = OverviewYear['Last 30 days'];

  expenseBreakdownList: SideListModel[];
  expenseBreakdownId: number;

  showCalender = false;
  dateRange: any;
  module = Modules;
  moduleName = ModuleName;

  constructor(
    public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private store: Store,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );
    this.setForm();
  }

  setForm(): void {
    this.getChartOfAccountTypeList();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  onSelectionChange(val: any): void {
    this.showCalender = +val === 7;
    this.dateRange = this.commonService.generateDateSeries(val);

    if (
      this.showCalender &&
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.dateRange = new FilterDateRange();
      this.dateRange.startDate = new Date(
        this.rangeFormGroup.controls.start.value
      );
      this.dateRange.endDate = new Date(this.rangeFormGroup.controls.end.value);
      this.getData();
    } else if (!this.showCalender) {
      this.getData();
    }
  }

  getChartOfAccountTypeList(): void {
    this.store
      .dispatch(new GetChartOfAccountTypeList(GroupNames.Expense))
      .pipe(
        tap((res) => {
          this.expenseBreakdownList = res.account.chartOfAccountTypeList;
          this.expenseBreakdownId = this.expenseBreakdownList[0].id;
          this.getData();
        })
      )
      .subscribe();
  }

  onExpenseBreakdownChangeForExpensesBreakDown(): void {
    const data = this.getFilter();
    data.moduleId = this.expenseBreakdownId;
    this.triggerHeaderFilterForExpenseBreakdown.emit(data);
  }

  getFilter(): any {
    const filterData = {
      moduleId: 0,
      dateFilter: {
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(
                  this.rangeFormGroup.controls.start.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),

        endDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(this.rangeFormGroup.controls.end.value, 'yyyy-MM-dd')
                ?.toString(),
      },
    };

    return filterData;
  }

  getData(): void {
    const data = this.getFilter();

    setTimeout(() => {
      this.triggerHeaderFilter.emit(data);
      data.moduleId = this.expenseBreakdownId;
      this.triggerHeaderFilterForExpenseBreakdown.emit(data);
    }, 500);
  }
}
