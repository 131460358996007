import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  InvoiceModel,
  OverviewFilterModel,
  SideListModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createInvoice(invoice: InvoiceModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Invoice/save`,
      this.commonService.trimObjectSpace(JSON.stringify(invoice)),
      headers
    );
  }

  getDataByInvoiceId(invoiceId: Guid): Observable<InvoiceModel> {
    return this.http.get<InvoiceModel>(
      `${environment.apiVersionUrl}Invoice/get/${invoiceId}`
    );
  }

  getDueAmountByInvoiceId(invoiceId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Invoice/getInvoiceDueAmount/${invoiceId}`
    );
  }

  deleteInvoice(invoiceIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(invoiceIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Invoice/delete`,
      options
    );
  }

  copyInvoice(invoiceIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Invoice/copyInvoice`,
      this.commonService.trimObjectSpace(JSON.stringify(invoiceIds)),
      headers
    );
  }

  archiveAndRestoreInvoice(
    invoiceIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Invoice/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(invoiceIds)),
      options
    );
  }

  getInvoiceNoList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Invoice/all`
    );
  }

  getInvoiceNumber(): Observable<any> {
    return this.http.get(`${environment.apiVersionUrl}Invoice/getInvoiceNo`, {
      responseType: 'text',
    });
  }

  getIncomeReceivableAgeingSummaryOverview(
    filterData: OverviewFilterModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/income/receivable`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getIncomeBreakdownOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/income/breakdown`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getIncomeFlowOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/income/flow`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }
}
