import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { SideListModel, VatRateScheme } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetVatReturnTypeList,
  GetVatSchemeList,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-vat-settings',
  templateUrl: './add-vat-settings.component.html',
  styleUrls: ['./add-vat-settings.component.scss'],
})
export class AddVatSettingsComponent implements OnInit {
  vatSettingsForm: FormGroup;
  showFromTo: boolean = false;
  vatSchemeList: VatRateScheme[];
  vatReturnTypeList: SideListModel[];
  isAddMode = true;
  maxLength = MaxLength;

  @Input() triggerEditData: Observable<any>;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(private store: Store, private commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      this.editVatSettingsDetails(data);
    });

    this.vatSettingsForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.vatSettingsForm.touched;
    });
  }

  editVatSettingsDetails(data): void {
    this.vatSettingsForm.patchValue({
      registrationDate: data.vatRegistrationDate,
      registrationNo: data.vatRegistrationNo,
      vatScheme: data.vatSchemeId === 0 ? '' : data.vatSchemeId,
      vatSubmitType: data.vatReturnTypeId === 0 ? '' : data.vatReturnTypeId,
    });
    this.ValidationForRegistrationNo();
  }

  setForm(): void {
    this.vatSettingsForm = new FormGroup({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      registrationDate: new FormControl('', [Validators.required]),
      registrationNo: new FormControl('', [Validators.required]),
      vatScheme: new FormControl('', [Validators.required]),
      vatSubmitType: new FormControl('', [Validators.required]),
      flatRate: new FormControl(''),
      discountPeriod: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(''),
      discountedRate: new FormControl(''),
    });

    this.getVatSchemeList();
    this.getVatReturnType();
    this.ValidationForRegistrationNo();
  }

  checkValue(ischeck) {
    if (ischeck === 'check') {
      this.showFromTo = true;
    } else {
      this.showFromTo = false;
    }
  }

  getVatSchemeList(): void {
    this.store
      .dispatch(new GetVatSchemeList())
      .pipe(
        tap((res) => {
          this.vatSchemeList = res.company.vatSchemeList;
        })
      )
      .subscribe();
  }

  getVatReturnType(): void {
    this.store
      .dispatch(new GetVatReturnTypeList())
      .pipe(
        tap((res) => {
          this.vatReturnTypeList = res.company.vatReturnTypeList;
        })
      )
      .subscribe();
  }

  ValidationForRegistrationNo(): void {
    const registrationNoControl = this.vatSettingsForm.get('registrationNo');
    if (registrationNoControl) {
      registrationNoControl.setValidators([
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
        Validators.pattern(/^\d{9}$/),
      ]);
      registrationNoControl.updateValueAndValidity();
    }
  }
}
