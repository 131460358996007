import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note24',
  templateUrl: './note24.component.html',
  styleUrls: ['./note24.component.scss'],
})
export class Note24Component implements OnInit {
  note24Form: FormGroup;
  calculativeData: any;
  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      if (data.noteId === Notes.Note24) {
        this.calculativeData = data.response.calculativeData;
        if (
          data.response.userInputData !== null &&
          data.response.userInputData !== undefined &&
          data.response.userInputData.data !== null &&
          data.response.userInputData.data !== undefined
        ) {
          this.editNotes24(data.response.userInputData.data);
        }
      }
    });
  }

  editNotes24(res): void {
    this.note24Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : ' ',
      textbox2: res.textbox2 ? res.textbox2 : ' ',
      textbox3: res.textbox3 ? res.textbox3 : ' ',
    });
  }

  setForm(): void {
    this.note24Form = new FormGroup({
      textbox1: new FormControl(''),
      textbox2: new FormControl(''),
      textbox3: new FormControl(''),
    });
  }
}
