<div class="action-wrapper button-action-wrapper" *ngIf="!isSend">
  <div class="content-body">
    <button
      *ngIf="
        getModuleId !== modules.Notes &&
        getModuleId !== modules.Import &&
        getModuleId !== modules.BulkEdit &&
        getModuleId !== modules.FixedAssetsRegister &&
        getModuleId !== modules.Integration &&
        getModuleId !== modules.BankFeed &&
        getModuleId !== modules.AnnualAccounts &&
        getModuleId !== modules.CharityPreference &&
        getModuleId !== modules.AccountDetails &&
        getModuleId !== modules.OpeningBalance &&
        getModuleId !== modules.EditTrialBalance &&
        getModuleId !== modules.EditAccountProductionTrialBalance &&
        getModuleId !== modules.BankImportHistory &&
        getModuleId !== modules.TrusteesReport &&
        getModuleId !== modules.GiftAidSetting &&
        getModuleId !== modules.Users &&
        !saveButtonShow
      "
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(false)"
    >
      Save and New
    </button>
    <button
      *ngIf="
        getModuleId === modules.Notes ||
        getModuleId === modules.Import ||
        getModuleId === modules.BulkEdit ||
        getModuleId === modules.FixedAssetsRegister ||
        getModuleId === modules.Integration ||
        getModuleId === modules.BankFeed ||
        getModuleId === modules.CharityPreference ||
        getModuleId === modules.AccountDetails ||
        getModuleId === modules.OpeningBalance ||
        getModuleId === modules.EditTrialBalance ||
        getModuleId === modules.EditAccountProductionTrialBalance ||
        getModuleId === modules.AnnualAccounts ||
        getModuleId === modules.BankImportHistory ||
        getModuleId === modules.TrusteesReport ||
        getModuleId === modules.GiftAidSetting ||
        getModuleId === modules.Users ||
        saveButtonShow
      "
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(false)"
    >
      Save
    </button>
    <button
      *ngIf="
        getModuleId !== modules.Notes &&
        getModuleId !== modules.Import &&
        getModuleId !== modules.BulkEdit &&
        getModuleId !== modules.FixedAssetsRegister &&
        getModuleId !== modules.BankFeed &&
        getModuleId !== modules.BankImportHistory &&
        getModuleId !== modules.TrusteesReport &&
        getModuleId !== modules.AnnualAccounts &&
        !saveButtonShow
      "
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(true)"
    >
      Save and Exit
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@saveAndInvite'"
      (click)="onSave(true, false, true)"
      *ngIf="getModuleId === modules.Users"
    >
      Save and Invite
    </button>
    <button
      *ngIf="
        getModuleId !== modules.BulkEdit &&
        getModuleId !== modules.BankDashboard
      "
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Clear Form
    </button>

    <button
      *ngIf="getModuleId === modules.BankDashboard"
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</div>
<div class="action-wrapper button-action-wrapper" *ngIf="isSend">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@save'"
      (click)="onSend(false)"
    >
      Send
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@cancel'"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</div>
