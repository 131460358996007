import { GetNoteParamModel, NoteDataModel } from '@app/core/Models';

export class GetNotesData {
  static readonly type = '[NOTE] Get Note Data';

  constructor(public param: GetNoteParamModel) {}
}

export class SaveNoteData {
  static readonly type = '[NOTE] Save Note Data';

  constructor(public noteData: NoteDataModel) {}
}
export class GetAllNote {
  static readonly type = '[NOTE] Get All Note';
}

export class ExportNotes {
  static readonly type = '[NOTE] Export Note';

  constructor(public param: GetNoteParamModel) {}
}

export class CopyPreviousYearNote {
  static readonly type = '[NOTE] Copy Previous Year Note';

  constructor(public noteData: any) {}
}
