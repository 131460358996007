import { Guid } from 'guid-typescript';

export class FundModel {
  id: Guid;
  name: string;
  isDefault: boolean;
  fundTypeId: number;
  isTradingFund: boolean;
  openingBalance?: number;
}
