import { FileImportRequestModel } from '@app/core/Enum/import';

export class GetImportTemplate {
  static readonly type = '[IMPORT] Get Import Template By Module Id';

  constructor(public moduleId: number) {}
}
export class ImportModules {
  static readonly type = '[IMPORT] Import Invoice';

  constructor(
    public fileImportRequestModel: FileImportRequestModel,
    public moduleId: number
  ) {}
}

export class ImportBank {
  static readonly type = '[IMPORT] Import Bank';

  constructor(
    public fileImportRequestModel: FileImportRequestModel,
    public customId: any
  ) {}
}

export class SaveImport {
  static readonly type = '[IMPORT] save Import';

  constructor(public param: any, public moduleId: number) {}
}

export class GetBankImportTemplate {
  static readonly type = '[IMPORT] Get Bank Import Template';
}
