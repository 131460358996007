<div class="content-body-wrapper depreciation-list text-align-left">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      Depreciation
      <button
        class="close-button"
        type="button"
        (click)="onCloseClick()"
        mat-button
        disableRipple
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>

    <form [formGroup]="depreciationForm">
      <div class="d-flex mt-1 mb-1 gap-15">
        <div>
          <p class="fw-bold">Period</p>
          <mat-form-field class="w-200">
            <mat-select
              panelClass="mat-select-position"
              [disableOptionCentering]="true"
              formControlName="accountingPeriod"
              (selectionChange)="onAccountPeriodChange()"
            >
              <mat-option
                *ngFor="let option of periodicDateList"
                [value]="option"
                title=" {{ option.fromDate | date: 'dd-LLL-yyyy' }} -
                {{ option.toDate | date: 'dd-LLL-yyyy' }}"
              >
                {{ option.fromDate | date: "dd-LLL-yyyy" }} -
                {{ option.toDate | date: "dd-LLL-yyyy" }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <p class="fw-bold">Depreciate Till</p>
          <mat-form-field appearance="standard" class="w-150">
            <input
              matInput
              formControlName="entryDate"
              [matDatepicker]="entryDatePicker"
              autocomplete="off"
              (change)="commonService.validateDate($event)"
              [min]="startDate"
              [max]="endDate"
              (dateChange)="getList()"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="entryDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #entryDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <p class="fw-bold">Fund</p>
          <mat-form-field class="w-200">
            <mat-select
              panelClass="mat-select-position"
              [disableOptionCentering]="true"
              formControlName="fundId"
            >
              <mat-option
                *ngFor="let fn of fundNameList"
                [value]="fn.id"
                title="{{ fn.name }}"
              >
                {{ fn.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div>
      <table
        mat-table
        [dataSource]="fixedAssetRegisterList"
        multiTemplateDataRows="true"
        aria-describedby="User Task Table"
      >
        <th></th>
        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              [(ngModel)]="isAllSelected"
              (change)="selectAll($event)"
              disableRipple
            ></mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <div class="d-flex align-items-center gap-10">
              <mat-checkbox
                [(ngModel)]="element.isSelected"
                (change)="checkSelectAll($event)"
              >
              </mat-checkbox>
              <div (click)="expandCollapse(element, 0, i)">
                <a
                  href="javascript:void(0)"
                  *ngIf="!element.isExpanded"
                  class="text-primary"
                >
                  <mat-icon>add</mat-icon>
                </a>
                <a
                  href="javascript:void(0)"
                  *ngIf="element.isExpanded"
                  class="text-primary"
                >
                  <mat-icon>remove</mat-icon>
                </a>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assestName">
          <mat-header-cell *matHeaderCellDef>Assest Name</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <div class="d-flex">
              {{ element.name }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="acquiredDate">
          <mat-header-cell *matHeaderCellDef>Acquired Date</mat-header-cell>
          <mat-cell *matCellDef="let element" class="widthemail">
            {{ element.acquiredDate | date: "dd-LLL-yyyy" }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastDepreciate">
          <mat-header-cell *matHeaderCellDef>Last Depreciate</mat-header-cell>
          <mat-cell *matCellDef="let element" class="widthemail">
            {{ element.lastDepreciate | date: "dd-LLL-yyyy" }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="acquiredPrice">
          <mat-header-cell *matHeaderCellDef>Acquired Price</mat-header-cell>
          <mat-cell *matCellDef="let element" class="widthemail">
            £ {{ element.totalAmount | numberPipe }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="accDepreciation">
          <mat-header-cell *matHeaderCellDef>Acc. Depreciation</mat-header-cell>
          <mat-cell *matCellDef="let element" class="widthemail">
            £ {{ element.totalDepreciation | numberPipe }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rateYear">
          <mat-header-cell *matHeaderCellDef>Rate/Year</mat-header-cell>
          <mat-cell *matCellDef="let element" class="widthemail">
            {{ element.rate }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="method">
          <mat-header-cell *matHeaderCellDef>Method</mat-header-cell>
          <mat-cell *matCellDef="let element" class="widthemail">
            {{ element.method }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="depreciation">
          <mat-header-cell *matHeaderCellDef class="w-150"
            >Depreciation</mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="widthemail">
            <mat-form-field>
              <input
                matInput
                [ngModel]="element.amount | numberPipe"
                autocomplete="off"
                maxlength="{{ maxLength.maxLength10 }}"
                readonly
                disabled
                (change)="onChange(element)"
              />
              <span class="pl-10" matPrefix>£</span>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="bookValue">
          <mat-header-cell *matHeaderCellDef>Book Value</mat-header-cell>
          <mat-cell *matCellDef="let element" class="widthemail">
            £ {{ element.bookValue | numberPipe }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let element" class="flex-column">
            <table
              aria-describedby="main-list"
              mat-table
              matSort
              (matSortChange)="sorting(sort.active, sort.direction)"
              [dataSource]="dataSource"
            >
              <ng-container
                *ngFor="let col of columns$ | async; let colIndex = index"
                matColumnDef="{{ col.name }}"
              >
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [id]="col.name"
                  fxFlex="50"
                  [ngClass]="[
                    (col.dataType === dataType.String ||
                      col.dataType === dataType.Currency ||
                      col.dataType === dataType.Int32) &&
                    col.alignment !== null
                      ? 'text-align-right'
                      : ''
                  ]"
                >
                  <div>
                    <span>
                      {{ col.name }}
                    </span>
                  </div>
                </th>

                <td
                  mat-cell
                  *matCellDef="let element; let rowIndex = index"
                  [ngClass]="[
                    col.dataType === dataType.Int32 ||
                    col.dataType === dataType.Currency
                      ? 'text-align-right'
                      : '',
                    col.dataType === dataType.String &&
                    element[colIndex].alignment !== null
                      ? 'text-align-right'
                      : ''
                  ]"
                >
                  <div>
                    <div class="clamp">
                      <span
                        *ngIf="
                          col.dataType === dataType.String ||
                          col.dataType === dataType.Bool
                        "
                        title="{{ element[colIndex].rowData }}"
                        [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                      >
                        {{ element[colIndex].rowData }}
                      </span>

                      <span
                        *ngIf="
                          col.dataType === dataType.URL &&
                          element[colIndex].id !== '' &&
                          element[colIndex].moduleId !== 0
                        "
                        title="{{ element[colIndex].rowData }}"
                        [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                        (click)="
                          redirect(
                            element[colIndex].id,
                            element[colIndex].moduleId
                          )
                        "
                      >
                        <a class="link fw-bold">
                          {{ element[colIndex].rowData }}
                        </a>
                      </span>

                      <span
                        *ngIf="
                          col.dataType === dataType.URL &&
                          element[colIndex].id === '' &&
                          element[colIndex].moduleId === 0
                        "
                        title="{{ element[colIndex].rowData }}"
                        [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                      >
                        {{ element[colIndex].rowData }}
                      </span>

                      <span
                        *ngIf="col.dataType === dataType.DateTime"
                        title="{{ element[colIndex].rowData }}"
                        [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                      >
                        {{
                          element[colIndex].rowData | date: "dd-LLL-yyyy"
                        }}</span
                      >

                      <span
                        *ngIf="
                          (col.dataType === dataType.Int32 ||
                            col.dataType === dataType.Currency) &&
                          element[colIndex].rowData !== ''
                        "
                        title="{{ element[colIndex].rowData }}"
                        [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                      >
                        £ {{ element[colIndex].rowData | numberPipe }}</span
                      >
                    </div>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="columnsNames$ | async; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: columnsNames$ | async"
              ></tr>
            </table>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          matRipple
          class="element-row"
          [class.expanded]="row.isExpanded"
        ></mat-row>

        <mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          [@detailExpand]="row.isExpanded === true ? 'expanded' : 'collapsed'"
          style="overflow: hidden"
        >
        </mat-row>
      </table>
      <div
        *ngIf="fixedAssetRegisterList?.length === 0 && noDataFound"
        class="text-align-center mt-1 mb-1"
      >
        No records found
      </div>
    </div>
  </div>
  <div>
    <app-buttons
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave()"
      (triggerOnCancelClick)="onCancel()"
    >
    </app-buttons>
  </div>
</div>
