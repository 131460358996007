<div class="dragndrop-box d-flex justify-space-between pb-3 pt-3">
  <form [formGroup]="attachmentForm">
    <ngx-dropzone
      #fileupload
      (change)="onFileSelected($event)"
      [multiple]="true"
    >
      <ngx-dropzone-label>
        <div class="icon">
          <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
        </div>
        <span>
          Drag a file or <strong><u>choose a file</u></strong> to attach
        </span>
      </ngx-dropzone-label>
    </ngx-dropzone>

    <div *ngIf="showTable" class="note-table mt-1">
      <table aria-describedby="attachment-list" class="table-border">
        <tr>
          <th></th>
        </tr>
        <tr
          class="clickable"
          *ngFor="let file of fileUploadResponse; let i = index"
        >
          <td>
            <span class="clamp" title="{{ file.fileName }}">{{
              file.fileName
            }}</span>
          </td>
          <td class="text-align-right">
            <button
              class="mr-5"
              type="button"
              mat-button
              disableRipple
              (click)="dowloadFile(file.fileUrl)"
            >
              <mat-icon class="text-gray-100">get_app</mat-icon>
            </button>
            <button
              type="button"
              mat-button
              disableRipple
              (click)="removeFile(i)"
            >
              <mat-icon class="text-danger-300">highlight_off</mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </form>
</div>
