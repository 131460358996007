<div class="content-body">
  <div class="mb-2">
    <div class="d-flex">
      <div>
        <h2 class="m-0 font-size-18 fw-bold">
          Let’s Get a Picture of your Profits
        </h2>
      </div>
      <div></div>
    </div>
  </div>

  <div>
    <div class="bank-logo-wrapper">
      <ul class="bank-logo">
        <li
          *ngFor="let element of bankAccountList; let index = index"
          [ngClass]="selectedAccountIndex === index ? 'active' : ''"
        >
          <mat-radio-button
            [ngModel]="selectedIndex === index"
            (change)="onAccountSelection(element, $event, index)"
          ></mat-radio-button>
          <img [src]="element.provider.logoUri" alt="Bank logo" />
          <h2 class="font-size-14 fw-bold m-0">
            {{ element.displayName }}
          </h2>
        </li>
      </ul>
    </div>
  </div>
  <app-buttons
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave($event.isExit)"
    (triggerOnCancelClick)="onCancel()"
    [saveButtonShow]="true"
  >
  </app-buttons>
</div>
