<div>
  <p class="header-text d-flex justify-space-between align-items-center">
    Gift Aid Claims Submission to HMRC
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </p>
  <div class="content-right-button d-flex align-items-center gap-30 mb-1">
    <div class="d-flex align-items-center gap-10">
      <span class="fw-bold">Period: </span>
      <mat-form-field class="w-200">
        <mat-select
          panelClass="mat-select-position"
          [disableOptionCentering]="true"
          [(ngModel)]="accountingPeriod"
          (selectionChange)="onAccountPeriodChange($event)"
        >
          <mat-option
            *ngFor="let option of periodicDateList"
            [value]="option.id"
            title="{{ option.fromDate | date: 'dd-LLL-yyyy' }} - {{
              option.toDate | date: 'dd-LLL-yyyy'
            }}"
          >
            {{ option.fromDate | date: "dd-LLL-yyyy" }} -
            {{ option.toDate | date: "dd-LLL-yyyy" }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex align-items-center gap-20 mt-2 mb-2">
    <div *ngFor="let file of files" class="files-wrapper">
      <a
        (click)="dowloadFile(file.fileUrl)"
        class="text-primary d-flex align-items-center gap-10"
      >
        <mat-icon>file_download</mat-icon>
        <span class="link">{{ file.fileName }}</span></a
      >
    </div>
  </div>
  <div class="form-field form-field attached">
    <p>Upload File</p>
    <div class="d-flex align-items-center gap-10">
      <div class="attach-file align-items-center">
        <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
        <div class="attach-file-label">
          <input
            #provideTrustee
            id="provideTrustee"
            type="file"
            accept="{{ accept }}"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength100 }}"
            [multiple]="false"
            (change)="onFileSelected($event)"
          />
          <label for="file" class="mb-5">
            <div>
              <span>Drag and drop <strong>excel</strong> files to upload</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
