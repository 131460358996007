import { Injectable } from '@angular/core';
import { GetNoteModel, SideListModel } from '@app/core/Models';
import { NoteService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  CopyPreviousYearNote,
  ExportNotes,
  GetAllNote,
  GetNotesData,
  SaveNoteData,
} from './note.action';

export class NoteStateInfo {
  noteData?: GetNoteModel;
  noteId?: Guid;
  noteSideList?: Array<SideListModel>;
  isExport: boolean;
}

@State<NoteStateInfo>({
  name: 'note',
  defaults: {
    noteId: Guid.EMPTY as unknown as Guid,
    isExport: false,
  },
})
@Injectable()
export class NoteState {
  constructor(private noteService: NoteService) {}

  @Action(GetNotesData)
  getNotesData(
    { patchState }: StateContext<NoteStateInfo>,
    action: GetNotesData
  ) {
    return this.noteService.getNotesData(action.param).pipe(
      tap((res) => {
        patchState({
          noteData: res,
        });
      })
    );
  }

  @Action(SaveNoteData)
  saveNoteData(
    { patchState }: StateContext<NoteStateInfo>,
    action: SaveNoteData
  ) {
    return this.noteService.saveNoteData(action.noteData).pipe(
      tap((res) => {
        patchState({
          noteId: res,
        });
      })
    );
  }

  @Action(CopyPreviousYearNote)
  copyPreviousYearNote(
    { patchState }: StateContext<NoteStateInfo>,
    action: CopyPreviousYearNote
  ) {
    return this.noteService.copyPreviousYearNote(action.noteData).pipe(
      tap((res) => {
        patchState({
          noteData: res,
        });
      })
    );
  }

  @Action(GetAllNote)
  getAllNote({ patchState }: StateContext<NoteStateInfo>, action: GetAllNote) {
    return this.noteService.getAllNote().pipe(
      tap((res) => {
        patchState({
          noteSideList: res,
        });
      })
    );
  }

  @Action(ExportNotes, { cancelUncompleted: true })
  exportNotes(
    { patchState }: StateContext<NoteStateInfo>,
    action: ExportNotes
  ) {
    let isExport = false;
    return this.noteService.export(action.param).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }
}
