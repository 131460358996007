import { Injectable } from '@angular/core';
import { AnnualReportModel, FileUploadRequestModel } from '@app/core/Models';
import { AnnualReportService, CommonService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  DeleteSavedFile,
  DownloadSavedAnnualReport,
  ExportAnnualReport,
  GetAccountingMethod,
  GetAnnualReportContent,
  GetAnnualReportResponse,
  GetByAccountPeriod,
  GetIndependentExaminerReport,
  GetListSavedFile,
  GetSavedAnnualReportResponse,
  GetTrusteesReport,
  SaveAnnualReport,
  SaveAnnualReportResponse,
  UploadAnnualReportResponse,
} from './annual-report.action';

export class AnnualReportStateInfo {
  annualReportData?: AnnualReportModel;
  accountingMethod?: any;
  annualReportId? = Guid.EMPTY as unknown as Guid;
  isExport: boolean;
  blobFile?: any;
  exported?: boolean;
  getContent?: any;
  fileUploadResponseModel: Array<FileUploadRequestModel>;
  saveAnnualReportResponseId? = Guid.EMPTY as unknown as Guid;
  annualReportFileList?: [];
  isSavedFileDeleted?: boolean;
}

@State<AnnualReportStateInfo>({
  name: 'annualReport',
  defaults: {
    isExport: false,
    fileUploadResponseModel: [],
  },
})
@Injectable()
export class AnnualReportState {
  constructor(
    private annualReportService: AnnualReportService,
    private commonService: CommonService
  ) {}

  @Action(SaveAnnualReport)
  saveAnnualReport(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: SaveAnnualReport
  ) {
    return this.annualReportService
      .saveAnnualReport(action.annualReportData)
      .pipe(
        tap((res) => {
          patchState({
            annualReportId: res,
          });
        })
      );
  }

  @Action(GetListSavedFile)
  getListSavedFile(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: GetListSavedFile
  ) {
    return this.annualReportService
      .getListSavedFile(action.accountPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            annualReportFileList: res,
          });
        })
      );
  }

  @Action(GetByAccountPeriod)
  getByAccountPeriod(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: GetByAccountPeriod
  ) {
    return this.annualReportService
      .getByAccountPeriod(action.accountPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            annualReportData: res,
          });
        })
      );
  }

  @Action(ExportAnnualReport, { cancelUncompleted: true })
  exportAnnualReport(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: ExportAnnualReport
  ) {
    let isExport = false;
    return this.annualReportService.export(action.accountPeriodId).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          blobFile: res,
          isExport,
        });
      })
    );
  }

  @Action(GetTrusteesReport)
  getTrusteesReport(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: GetTrusteesReport
  ) {
    return this.annualReportService
      .getTrusteesReport(action.accountPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            exported: res,
          });
        })
      );
  }

  @Action(GetIndependentExaminerReport)
  getIdependentExaminerReport(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: GetIndependentExaminerReport
  ) {
    return this.annualReportService
      .getIdependentExaminerReport(action.accountPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            exported: res,
          });
        })
      );
  }

  @Action(GetAnnualReportContent)
  getAnnualReportContent(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: GetAnnualReportContent
  ) {
    return this.annualReportService
      .getAnnualReportContent(action.accountPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            getContent: res,
          });
        })
      );
  }

  @Action(GetAccountingMethod)
  getAccountingMethod(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: GetAccountingMethod
  ) {
    return this.annualReportService.getAccountingMethod().pipe(
      tap((res) => {
        patchState({
          accountingMethod: res,
        });
      })
    );
  }

  @Action(GetAnnualReportResponse)
  getAnnualReportResponse(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: GetAnnualReportResponse
  ) {
    return this.annualReportService
      .getByAccountPeriodReponse(action.accountPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            getContent: res,
          });
        })
      );
  }

  @Action(GetSavedAnnualReportResponse)
  getSavedAnnualReportResponse(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: GetSavedAnnualReportResponse
  ) {
    return this.annualReportService
      .getSavedAnnualReportResponse(action.accountPeriodId, action.Id)
      .pipe(
        tap((res) => {
          patchState({
            getContent: res,
          });
        })
      );
  }

  @Action(SaveAnnualReportResponse)
  saveAnnualReportResponse(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: SaveAnnualReportResponse
  ) {
    return this.annualReportService
      .saveAnnualReportResponse(action.param, action.accountPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            saveAnnualReportResponseId: res,
          });
        })
      );
  }

  @Action(UploadAnnualReportResponse)
  uploadAnnualReportResponse(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: UploadAnnualReportResponse
  ) {
    return this.annualReportService
      .uploadAnnualReportResponse(action.file)
      .pipe(
        tap((res) => {
          patchState({
            fileUploadResponseModel: res,
          });
        })
      );
  }

  @Action(DeleteSavedFile)
  deleteSavedFile(
    { getState, patchState }: StateContext<AnnualReportStateInfo>,
    action: DeleteSavedFile
  ) {
    return this.annualReportService
      .deleteSavedFile(action.Ids, action.accountPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            isSavedFileDeleted: res,
          });
        })
      );
  }

  @Action(DownloadSavedAnnualReport, { cancelUncompleted: true })
  downloadSavedAnnualReport(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: DownloadSavedAnnualReport
  ) {
    let isExport = false;
    return this.annualReportService
      .downloadSavedAnnualReport(action.accountPeriodId, action.Id)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }
}
