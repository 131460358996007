import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  JournalsModel,
  LinkJournalModel,
  MatchJournalModel,
  QuickJournalModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class JournalsService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createJournals(journals: JournalsModel): Observable<Guid> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Guid>(
      `${environment.apiVersionUrl}Journal/save`,
      this.commonService.trimObjectSpace(JSON.stringify(journals)),
      headers
    );
  }

  getDataByJournalsId(journalsId: Guid): Observable<JournalsModel> {
    return this.http.get<JournalsModel>(
      `${environment.apiVersionUrl}Journal/get/${journalsId}`
    );
  }

  deleteJournals(journalsIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(journalsIds)),
    };

    return this.http.delete<any>(
      `${environment.apiVersionUrl}Journal/delete`,
      options
    );
  }

  copyJournals(journalsIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Journal/copyJournals`,
      this.commonService.trimObjectSpace(JSON.stringify(journalsIds)),
      headers
    );
  }

  archiveAndRestoreJournals(
    journalsIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Journal/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(journalsIds)),
      options
    );
  }

  getJournalNo(): Observable<any> {
    return this.http.get(`${environment.apiVersionUrl}Journal/getJournalNo`, {
      responseType: 'text',
    });
  }

  quickJournal(quickJournals: QuickJournalModel): Observable<Guid> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Guid>(
      `${environment.apiVersionUrl}Journal/quickSave`,
      this.commonService.trimObjectSpace(JSON.stringify(quickJournals)),
      headers
    );
  }

  getMatchingJournals(transactionId: Guid): Observable<MatchJournalModel> {
    return this.http.get<MatchJournalModel>(
      `${environment.apiVersionUrl}Journal/getMatchingJournals/${transactionId}`
    );
  }

  linkJournal(linkJournal: LinkJournalModel): Observable<Guid> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Guid>(
      `${environment.apiVersionUrl}Journal/linkJournal`,
      this.commonService.trimObjectSpace(JSON.stringify(linkJournal)),
      headers
    );
  }
}
