<div id="chart" *ngIf="lineChartOptions">
  <apx-chart
    [series]="lineChartOptions?.series!"
    [chart]="lineChartOptions?.chart!"
    [dataLabels]="lineChartOptions?.dataLabels!"
    [plotOptions]="lineChartOptions?.plotOptions!"
    [yaxis]="lineChartOptions?.yaxis!"
    [legend]="lineChartOptions?.legend!"
    [fill]="lineChartOptions?.fill!"
    [stroke]="lineChartOptions?.stroke!"
    [tooltip]="lineChartOptions?.tooltip!"
    [xaxis]="lineChartOptions?.xaxis!"
    [noData]="lineChartOptions?.noData!"
  ></apx-chart>
</div>
