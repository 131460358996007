import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Notes } from '@app/core/Enum/note';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-note19',
  templateUrl: './note19.component.html',
  styleUrls: ['./note19.component.scss'],
})
export class Note19Component implements OnInit {
  note19Form: FormGroup;
  @Input() triggerEditData: Observable<any>;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      if (
        data.noteId === Notes.Note19 &&
        data.response.userInputData !== null &&
        data.response.userInputData !== undefined &&
        data.response.userInputData.data !== null &&
        data.response.userInputData.data !== undefined
      ) {
        this.editNotes19(data.response.userInputData.data);
      }
    });
  }
  editNotes19(res): void {
    this.note19Form.patchValue({
      textbox1: res.textbox1 ? res.textbox1 : '',
    });
  }

  setForm(): void {
    this.note19Form = new FormGroup({
      textbox1: new FormControl(''),
    });
  }
}
