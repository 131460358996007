import { Injectable } from '@angular/core';
import { SideListModel } from '@app/core/Models';
import { DonorModel } from '@app/core/Models/contact/donor-model';
import { DonorService } from '@app/core/Services/contact/donor/donor.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreDonor,
  CreateDonor,
  DeleteDonor,
  DonorTransactionExport,
  GetAllDonor,
  GetDataByDonorId,
  GetGiftAidDonorDeclarations,
  GetGiftAidDonorSpreadSheets,
} from './donor.action';

export class DonorStateInfo {
  donorData?: DonorModel;
  donor: Array<DonorModel>;
  donorId?: Guid;
  exported?: boolean;
  sideListModel?: Array<SideListModel>;
  giftAidDonorSpreadSheetsList?: Array<any>;
  isAccountAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  isExport: boolean;
}

@State<DonorStateInfo>({
  name: 'donor',
  defaults: {
    donor: [],
    donorId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isAccountAdded: false,
    isExport: false,
  },
})
@Injectable()
export class DonorState {
  constructor(private donorService: DonorService) {}

  @Selector()
  static isLastPage(state: DonorStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getAllDonor(state: DonorStateInfo) {
    return state.sideListModel;
  }

  @Action(GetDataByDonorId)
  getDataByDonorId(
    { patchState }: StateContext<DonorStateInfo>,
    action: GetDataByDonorId
  ) {
    return this.donorService.getDataByDonorId(action.donorId).pipe(
      tap((res) => {
        patchState({
          donorData: res,
        });
      })
    );
  }

  @Action(GetAllDonor)
  getAllDonor(
    { patchState }: StateContext<DonorStateInfo>,
    action: GetAllDonor
  ) {
    return this.donorService.getAllDonor().pipe(
      tap((res) => {
        patchState({
          sideListModel: res,
        });
      })
    );
  }

  @Action(GetGiftAidDonorSpreadSheets)
  getGiftAidDonorSpreadSheets(
    { patchState }: StateContext<DonorStateInfo>,
    action: GetGiftAidDonorSpreadSheets
  ) {
    return this.donorService.getGiftAidDonorSpreadSheets().pipe(
      tap((res) => {
        patchState({
          giftAidDonorSpreadSheetsList: res,
        });
      })
    );
  }

  @Action(GetGiftAidDonorDeclarations)
  getGiftAidDonorDeclaration(
    { patchState }: StateContext<DonorStateInfo>,
    action: GetGiftAidDonorSpreadSheets
  ) {
    return this.donorService.getGiftAidDonorDeclarations().pipe(
      tap((res) => {
        patchState({
          giftAidDonorSpreadSheetsList: res,
        });
      })
    );
  }

  @Action(CreateDonor)
  createDonor(
    { patchState }: StateContext<DonorStateInfo>,
    action: CreateDonor
  ) {
    return this.donorService.createDonor(action.donor).pipe(
      tap((res) => {
        patchState({
          donorId: res,
          isAccountAdded: true,
        });
      })
    );
  }

  @Action(DeleteDonor)
  deleteDonor(
    { getState, patchState }: StateContext<DonorStateInfo>,
    action: DeleteDonor
  ) {
    return this.donorService.deleteDonor(action.donorIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredDonor = state.donor.filter(
          (item) =>
            !action.donorIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.donorIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          ...state.donor,
          donor: filteredDonor,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreDonor)
  archiveAndRestoreDonor(
    { getState }: StateContext<DonorStateInfo>,
    action: ArchiveAndRestoreDonor
  ) {
    return this.donorService.archiveAndRestoreDonor(
      action.donorIds,
      action.isArchive
    );
  }

  @Action(DonorTransactionExport, { cancelUncompleted: true })
  donorTransactionExport(
    { patchState }: StateContext<DonorStateInfo>,
    action: DonorTransactionExport
  ) {
    let isExport = false;
    return this.donorService
      .donorTransactionExport(action.queryParams, action.id)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }
}
