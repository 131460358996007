import {
  Component,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import {
  AccountEntity,
  GroupNames,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { Select, Store } from '@ngxs/store';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

import { Location } from '@angular/common';

import {
  CashCodingItemModel,
  CashCodingModel,
  ChartOfAccountListParam,
  GlobalComponent,
  GroupListModel,
  MainListParameters,
  SaveBulkEditModel,
  SideListModel,
} from '@app/core/Models';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import {
  BankOverviewState,
  GetAllDonor,
  GetBankTrasnactions,
  GetCustomBankAccountList,
  GetFundNameList,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetJournalAccounts,
  GetNonStandardAccountList,
  GetVatRateList,
} from '@app/core/Store';
import { tap } from 'rxjs/operators';
import {
  FormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
  AbstractControl,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BankTransactionModel } from '@app/core/Models/bank/bank-overview/bank-overview';
import { Observable, Subject, Subscription } from 'rxjs';
import { IncomeTypeName } from '@app/core/Enum/income-types-name';
import { PurchaseTypeName } from '@app/core/Enum/purchase-type-name';
import { CreateCashCoding } from '@app/core/Store/bank/cash-coding';
import { AddClosePopupComponent } from '@app/modules/common';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-cash-coding',
  templateUrl: './cash-coding.component.html',
  styleUrls: ['./cash-coding.component.scss'],
})
export class CashCodingComponent implements OnInit {
  formCashCoding: UntypedFormGroup;
  cashCodingArray: any;
  listParameters: MainListParameters = new MainListParameters();
  vatRate = 0;
  moduleEnum = Modules;
  isRowHighlighted = false;
  noDataFound = false;
  fundNameList: SideListModel[];
  accountGroupList: any[] = [];
  tempAccountGroupList: any[] = [];
  invoiceGroupList: GroupListModel[];
  donationGroupList: GroupListModel[];
  purchaseGroupList: GroupListModel[];
  vatRateList: any[];
  customerList: any[];
  supplierList: any[];
  donorList: any[];
  bankTransactions: BankTransactionModel[] = [];
  isAllSelected = false;
  isRecordSelected = false;
  isCustomerAccount = false;
  isSupplierAccount = false;
  isDonorAccount = false;
  receiptAccountList: any[] = [];
  tempReceiptAccountList: any[];
  isVatRegistered: any;

  searchText = '';
  tableDataSource: MatTableDataSource<AbstractControl>;
  subscriptionRouting: Subscription;
  router: Router;

  customId: any;
  isHeaderChange = false;
  customDate = false;

  displayedColumns: string[] = [
    'srNo',
    'date',
    'reference',
    'account',
    'fundName',
    'vatRate',
    'vatAmount',
    'contactName',
    'receipt',
    'payment',
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private _Activatedroute: ActivatedRoute,
    private commonService: CommonService,
    private store: Store,
    private renderer: Renderer2,
    private location: Location,
    private injector: Injector,
    private globalComponent: GlobalComponent
  ) {
    this.router = injector.get<Router>(Router);
    this.vatRateList = [];
  }

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    if (!this.isVatRegistered) {
      this.displayedColumns = this.displayedColumns.filter(
        (column) => column !== 'vatRate' && column !== 'vatAmount'
      );
    }
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.customId = atob(params.get('customId')!) as unknown as any;
          this.listParameters.pageNumber = 1;
          this.listParameters.pageSize = 2147483647;
          this.listParameters.moduleId = this.customId;

          if (this.customId !== null && this.customId !== undefined) {
            this.getList();
            this.loadDropdownValues();
          } else {
            this.location.back();
            this.commonService.onFailure('Please select bank account');
          }
        }
      }
    );
  }

  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetBankTrasnactions(this.getParamter()))
      .pipe()
      .subscribe((res) => {
        this.spinner.hide();
        this.bankTransactions = res.bankOverview.getBankTransactions;
        this.bankTransactions = this.bankTransactions.filter(
          (transaction) => !transaction.isManual
        );

        this.noDataFound = this.bankTransactions.length > 0 ? false : true;
      });
  }

  getBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.receiptAccountList = res.common.customBankAccount;
          this.tempReceiptAccountList = this.receiptAccountList;
          if (this.receiptAccountList.length > 0) {
            if (this.customId === undefined || this.customId === null) {
              this.customId = this.receiptAccountList[0].id;
            }
          }
        })
      )
      .subscribe();
  }

  setForm(): FormGroup {
    const form = this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      account: new FormControl('', [Validators.required]),
      customerSupplier: new FormControl(''),
      fundName: new FormControl(''),
      vatRate: new FormControl(''),
      vatAmount: new FormControl({ value: 0.0, disabled: true }),
    });

    return form;
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  loadDropdownValues(): void {
    this.getBankAccountList();
    this.getFundNameList();
    this.getAccounts();
    this.getVatRateList();
    this.getSupplierList();
    this.getCustomerList();
    this.getDonorList();
    this.getInvoiceAccountList();
    this.getDonationAccountList();
    this.getPurchaseAccountList();
  }

  getSupplierList(): void {
    this.spinner.show();
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap((res) => {
          this.spinner.hide();
          this.supplierList = res.common.accountList;
        })
      )
      .subscribe();
  }

  getCustomerList(): void {
    this.spinner.show();
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap((res) => {
          this.spinner.hide();
          this.customerList = res.common.accountList;
        })
      )
      .subscribe();
  }

  getDonorList(): void {
    this.spinner.show();
    this.store.dispatch(new GetAllDonor()).subscribe((res) => {
      this.spinner.hide();
      this.donorList = res.donor.sideListModel;
    });
  }

  getFundNameList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetFundNameList())
      .pipe(
        tap((res) => {
          this.spinner.hide();
          this.fundNameList = res.account.fundNameList;
        })
      )
      .subscribe();
  }

  getVatRateList(): void {
    this.spinner.show();
    this.store.dispatch(new GetVatRateList()).subscribe((res) => {
      this.spinner.hide();
      this.vatRateList = res.common.vatRate;
    });
  }

  getAccounts(): void {
    this.spinner.show();
    this.store.dispatch(new GetJournalAccounts()).subscribe((res) => {
      this.spinner.hide();
      this.accountGroupList = this.addGroupNameToListModels(
        res.account.accounts
      );
      this.accountGroupList = this.accountGroupList.filter(
        (x) => x.id !== this.customId
      );
    });
  }

  getInvoiceAccountList(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Income);

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: this.commonService.invoiceTypeList,
    };
    this.spinner.show();
    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupIdAndTypeId(param))
      .subscribe((res) => {
        this.spinner.hide();
        this.invoiceGroupList = res.common.accountGroupList;
      });
  }

  getDonationAccountList(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Income);

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: [IncomeTypeName.IncomeDonationAndLegacies],
    };
    this.spinner.show();
    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupIdAndTypeId(param))
      .subscribe((res) => {
        this.spinner.hide();
        this.donationGroupList = res.common.accountGroupList;
      });
  }

  getPurchaseAccountList(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Expense);

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: this.commonService.billTypeList,
    };

    this.spinner.show();
    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupIdAndTypeId(param))
      .subscribe((res) => {
        this.spinner.hide();
        this.purchaseGroupList = res.common.accountGroupList;
      });
  }

  onAccountChange(element: any): void {
    let customerAccount;
    this.invoiceGroupList.forEach((item) => {
      item.listModels.forEach((x) => {
        if (x.id === element.accountName) {
          customerAccount = x;
          return;
        }
      });
    });

    let supplierAccount;
    this.invoiceGroupList.concat(this.purchaseGroupList).forEach((item) => {
      item.listModels.forEach((x) => {
        if (x.id === element.accountName) {
          supplierAccount = x;
          return;
        }
      });
    });

    let donorAccount;
    this.donationGroupList.forEach((item) => {
      item.listModels.forEach((x) => {
        if (x.id === element.accountName) {
          donorAccount = x;
          return;
        }
      });
    });

    if (customerAccount !== undefined && customerAccount !== null) {
      this.isCustomerAccount = true;
      this.isSupplierAccount = false;
      this.isDonorAccount = false;
    } else if (supplierAccount !== undefined && supplierAccount !== null) {
      this.isSupplierAccount = true;
      this.isCustomerAccount = false;
      this.isDonorAccount = false;
    } else if (donorAccount !== undefined && donorAccount !== null) {
      this.isDonorAccount = true;
      this.isSupplierAccount = false;
      this.isCustomerAccount = false;
    } else {
      this.isCustomerAccount = false;
      this.isSupplierAccount = false;
      this.isDonorAccount = false;
    }

    this.bankTransactions.forEach((y) => {
      if (y.isSelected && element.isSelected) {
        y.accountName = element.accountName;
        if (
          y.reciepts !== 0 &&
          ((customerAccount !== undefined && customerAccount !== null) ||
            (donorAccount !== undefined && donorAccount !== null))
        ) {
          y.contactEnable = true;
          if (donorAccount !== undefined && donorAccount !== null) {
            y.isDonation = true;
          } else {
            y.isDonation = false;
          }
        } else if (
          y.reciepts === 0 &&
          supplierAccount !== undefined &&
          supplierAccount !== null
        ) {
          y.contactEnable = true;
        } else {
          y.contact = null;
          y.contactEnable = false;
          y.isDonation = false;
        }
      }
    });
  }

  onFundChange(element: any): void {
    this.bankTransactions.forEach((y) => {
      if (y.isSelected && element.isSelected) {
        y.fundId = element.fundId;
      }
    });
  }

  onContactChange(element: any): void {
    this.bankTransactions.forEach((y) => {
      if (y.isSelected && element.isSelected) {
        if (
          +element.reciepts !== 0 &&
          +y.reciepts !== 0 &&
          (this.isCustomerAccount || this.isDonorAccount)
        ) {
          element.contactEnable = true;
          y.contactEnable = true;
          y.contact = element.contact;
          if (this.isDonorAccount) {
            element.isDonation = true;
            y.isDonation = true;
          }
        } else if (
          +element.reciepts === 0 &&
          +y.reciepts === 0 &&
          this.isSupplierAccount
        ) {
          element.contactEnable = true;
          y.contactEnable = true;
          y.contact = element.contact;
        } else {
          element.contactEnable = false;
          element.contact = null;
          element.isDonation = false;
        }
      }
    });
  }

  onVatChange(element: any): void {
    this.calVatAmount(element);
    this.bankTransactions.forEach((y) => {
      if (y.isSelected && element.isSelected) {
        y.vatRateId = element.vatRateId;
        y.vatAmount = element.vatAmount;
      }
    });
  }

  calVatAmount(element: any): void {
    if (this.vatRateList.length > 0) {
      const data = this.vatRateList.filter((x) => x.id === element.vatRateId);
      if (data.length > 0) {
        this.vatRate = data[0].rate;
      } else {
        this.vatRate = 0;
      }
    }

    if (+element.reciepts !== 0) {
      const vatAmount =
        (element.reciepts * (this.vatRate / 100)) / (1 + this.vatRate / 100);

      element.vatAmount = vatAmount.toFixed(2);
    } else {
      const vatAmount =
        (element.payments * (this.vatRate / 100)) / (1 + this.vatRate / 100);

      element.vatAmount = vatAmount.toFixed(2);
    }
  }

  selectAll(event: any): void {
    this.bankTransactions.forEach((x) => this.onCheckBoxChanges(event, x));
    this.isRecordSelected = event.checked;
  }

  onCheckBoxChanges(event: any, element: any): void {
    element.isSelected = event.checked;
    if (event.checked) {
      this.bankTransactions.forEach((y) => {
        if (y.isSelected && element.isSelected) {
          element.fundId = y.fundId;
          element.accountName = y.accountName;
          element.vatRateId = y.vatRateId;
          element.vatAmount = y.vatAmount;
          if (
            +element.reciepts !== 0 &&
            (this.isCustomerAccount || this.isDonorAccount)
          ) {
            element.contactEnable = true;
            element.contact = y.contact;
            if (this.isDonorAccount) {
              element.isDonation = true;
            }
          } else if (+element.reciepts === 0 && this.isSupplierAccount) {
            element.contactEnable = true;
            element.contact = y.contact;
          } else {
            element.contactEnable = false;
            element.contact = null;
            element.isDonation = false;
          }
        }
      });
    } else {
      element.fundId = '';
      element.accountName = '';
      element.vatRateId = '';
      element.contact = '';
      element.vatAmount = 0;
      element.contactEnable = false;
      element.isDonation = false;
    }
  }

  onSave(): void {
    const param: CashCodingModel[] = [];
    for (let bulkEdit of this.bankTransactions) {
      if (bulkEdit.isSelected) {
        param.push({
          transactionId: bulkEdit.id,
          fundId: bulkEdit.fundId,
          accountId: bulkEdit.accountName,
          bankAccountId: this.customId,
          isBankFeed: bulkEdit.isBankFeed,
          contactId: bulkEdit.contact,
          isCredit: bulkEdit.reciepts !== 0 ? true : false,
          vatRateId:
            bulkEdit.vatRateId === undefined ||
            bulkEdit.vatRateId === null ||
            bulkEdit.vatRateId === this.commonService.defaultGuidValue
              ? null
              : bulkEdit.vatRateId,
          vatAmount: bulkEdit.vatAmount,
          isDonation: bulkEdit.isDonation,
        });
      }
    }

    if (param.length > 0) {
      this.spinner.show();
      this.store
        .dispatch(new CreateCashCoding(param))
        .pipe()
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.cashCoding.cashCodingId?.isSuccess) {
              this.commonService.onSucess(
                NotificationTextMessage.successMessage
              );
              history.back();
            } else {
              this.commonService.onFailure(
                res.cashCoding.cashCodingId?.statusMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }

  onCancel(): void {
    this.ngOnInit();
  }

  onCloseClick(): void {
    this.dialog
      .open(AddClosePopupComponent, {})
      .afterClosed()
      .subscribe((result) => {
        this.router.navigate([RoutingPath.BankDashboard]);
        this.commonService.setLocalStorage('selectedBank', this.customId);
      });
  }

  triggerDateChange(data: any): void {
    if (data.value === '-1') {
      this.listParameters.startDate = '';
      this.listParameters.endDate = '';
    } else {
      this.listParameters.startDate = data.startDate;
      this.listParameters.endDate = data.endDate;
    }

    this.getList();
  }

  onSearch(): void {
    this.listParameters.search = this.searchText;
    this.getList();
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.onSearch();
    }
  }

  onBankAccountChange(): void {
    this.listParameters.moduleId = this.customId;
    this.getList();
  }
}
