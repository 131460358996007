<div class="content-body">
  <form [formGroup]="sponsorForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Event Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="name"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength100 }}"
            appAutoFocusDirective
            unicodeCharacter
          />
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Event Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            [matDatepicker]="eventDatePicker"
            autocomplete="off"
            formControlName="eventDate"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(sponsorForm.get('eventDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(sponsorForm.get('eventDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field checkbox mb-0">
        <mat-checkbox formControlName="isActive"> Active </mat-checkbox>
      </div>
    </div>
  </form>
</div>
