import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Modules } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-review-return',
  templateUrl: './review-return.component.html',
  styleUrls: ['./review-return.component.scss'],
})
export class ReviewReturnComponent implements OnInit {
  selectedTab = 0;
  submitVatId = Modules.BridgingVAT;
  triggerEditData: Subject<any> = new Subject<any>();

  constructor(public commonService: CommonService, public dialog: MatDialog) {}

  ngOnInit(): void {}

  onTabClick(event: any): void {
    this.selectedTab = event;
  }

  onCancel(): void {
    this.ngOnInit();
  }
}
