<div id="chart" *ngIf="chartOptions">
  <apx-chart
    [series]="chartOptions?.series!"
    [chart]="chartOptions?.chart!"
    [xaxis]="chartOptions?.xaxis!"
    [legend]="chartOptions?.legend!"
    [stroke]="chartOptions?.stroke!"
    [fill]="chartOptions?.fill!"
    [markers]="chartOptions?.markers!"
    [tooltip]="chartOptions?.tooltip!"
    [dataLabels]="chartOptions?.dataLabels!"
    [noData]="chartOptions?.noData!"
  ></apx-chart>
</div>
