import { Guid } from 'guid-typescript';

export class ActivityModel {
  id: Guid;
  name: string;
  code: string;
  description: string;
  attachment: Array<string>;
  isDefault: boolean;
}
