import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChartOfAccountListModel,
  FixedAssetsModel,
  OverviewFilterModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class FixedAssetsService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createFixedAssets(fixedAssets: FixedAssetsModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAsset/save`,
      this.commonService.trimObjectSpace(JSON.stringify(fixedAssets)),
      headers
    );
  }

  getDataByFixedAssetsId(fixedAssetsId: Guid): Observable<FixedAssetsModel> {
    return this.http.get<FixedAssetsModel>(
      `${environment.apiVersionUrl}FixedAsset/get/${fixedAssetsId}`
    );
  }

  deleteFixedAssets(fixedAssetsIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(fixedAssetsIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}FixedAsset/delete`,
      options
    );
  }

  copyFixedAssets(fixedAssetsIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}FixedAsset/copyFixedAsset`,
      this.commonService.trimObjectSpace(JSON.stringify(fixedAssetsIds)),
      headers
    );
  }

  archiveAndRestoreFixedAssets(
    fixedAssetsIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}FixedAsset/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(fixedAssetsIds)),
      options
    );
  }

  getFixAssestAgeingSummaryOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fixedAsset/valueByAssetType`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getFixAssestProductServiceListOverview(
    filterData: OverviewFilterModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fixedAsset/productList`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getFixAssestValueByAssetTypeOverview(
    filterData: OverviewFilterModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/fixedAsset/productList`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getAllGroupByFixAssestAgeingSummary(
    accountTypeId: number
  ): Observable<Array<ChartOfAccountListModel>> {
    return this.http.get<Array<ChartOfAccountListModel>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAllGroup/accountType/${accountTypeId}`
    );
  }
}
