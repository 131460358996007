import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize, retry } from 'rxjs/operators';
import { GlobalComponent, MenuModel } from '../Models';
import { CommonService } from '../Services';
import { Observable, throwError } from 'rxjs';
import { Select } from '@ngxs/store';
import { MenuState } from '../Store';
import { NotificationTextMessage } from '../Enum';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  service_count = 0;
  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;
  constructor(
    private spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent,
    private commonService: CommonService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.service_count++;

    return next.handle(request).pipe(
      finalize(() => {
        this.service_count--;
        if (
          this.service_count === 0 &&
          this.globalComponent.getLoggedInUserId() !==
            (Guid.EMPTY as unknown as Guid)
        ) {
          this.spinner.hide();
        }
      }),

      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        this.spinner.hide();

        errorMessage = `Error: ${error.error.Message}`;
        if (error.error.Message === undefined) {
          errorMessage = NotificationTextMessage.errorWrongMessage;
        }

        this.commonService.onFailure(errorMessage);

        const errorDisplay: any = new Error(errorMessage);
        return throwError(errorDisplay);
      })
    );
  }
}
