import { FilteredStatus } from '@app/core/Enum';
import { ExportType } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class QueryParams {
  pageNumber?: number;
  pageSize?: number;
  filter?: number;
  sortBy?: string;
  sortByNewlyAdded?: string;
  maxPageSize?: number;
  sortOrder?: boolean;
  search?: string;
  startDate?: string;
  endDate?: string;
  isRecurring?: boolean;
  moduleId?: number;
}

export class ExportParams extends QueryParams {
  format?: ExportType;
  fileName?: string;
  isPrint? = false;
  ids?: Array<Guid>;
}

export class MainListParameters {
  pageNumber = 1;
  pageSize = 20;
  filter = FilteredStatus.All;
  sortBy = '';
  sortOrder = true;
  search = '';
  moduleId?: Guid;
  subModuleId: number;
  ids: Array<Guid>;
  startDate?: string;
  endDate?: string;
  format?: ExportType;
  isPrint?: boolean;
  isRowHighlighted?: false;
  isCustomDate?: false;
}
