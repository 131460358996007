<p class="fw-bold font-size-20 mb-1">{{ reportText }}</p>
<table
  mat-table
  [dataSource]="expandedCollapseList"
  multiTemplateDataRows="true"
  aria-describedby="User Task Table"
>
  <th></th>
  <ng-container
    *ngFor="let disCol of displayedColumns; let colIndex = index"
    matColumnDef="{{ disCol.name }}"
  >
    <mat-header-cell
      *matHeaderCellDef
      [id]="disCol.name"
      [ngClass]="[
        colIndex === 0 ? 'hidden' : '',
        (disCol.dataType === dataType.String ||
          disCol.dataType === dataType.Currency ||
          disCol.dataType === dataType.Int32) &&
        disCol.alignment !== null
          ? 'justify-content-end'
          : ''
      ]"
    >
      <span [ngClass]="colIndex === 1 ? 'report-name' : ''">{{
        disCol.name
      }}</span>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      [ngClass]="[
        colIndex === 0 ? 'hidden' : '',
        (disCol.dataType === dataType.String ||
          disCol.dataType === dataType.Currency ||
          disCol.dataType === dataType.Int32) &&
        disCol.alignment !== null
          ? 'justify-content-end'
          : ''
      ]"
    >
      <div class="d-flex align-items-center gap-20">
        <div *ngIf="colIndex === 1">
          <a
            href="javascript:void(0)"
            *ngIf="!element.isExpanded"
            class="text-primary"
          >
            <mat-icon>add</mat-icon>
          </a>
          <a
            href="javascript:void(0)"
            *ngIf="element.isExpanded"
            class="text-primary"
          >
            <mat-icon>remove</mat-icon>
          </a>
        </div>
        <div *ngIf="colIndex !== 0 && colIndex !== 2">
          {{ element[colIndex] }}
        </div>
        <div *ngIf="colIndex === 2">
          {{ element[colIndex] | numberPipe }}
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let element" class="flex-column">
      <table
        aria-describedby="main-list"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="dataSource"
      >
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            [id]="col.name"
            fxFlex="50"
            [ngClass]="[
              (col.dataType === dataType.String ||
                col.dataType === dataType.Currency ||
                col.dataType === dataType.Int32) &&
              col.alignment !== null
                ? 'text-align-right'
                : ''
            ]"
          >
            <div>
              <span>
                {{ col.name }}
              </span>
            </div>
          </th>

          <td
            mat-cell
            *matCellDef="let element; let rowIndex = index"
            [ngClass]="[
              col.dataType === dataType.Int32 ||
              col.dataType === dataType.Currency
                ? 'text-align-right'
                : '',
              col.dataType === dataType.String &&
              element[colIndex].alignment !== null
                ? 'text-align-right'
                : ''
            ]"
          >
            <div>
              <div class="clamp">
                <span
                  *ngIf="
                    col.dataType === dataType.String ||
                    col.dataType === dataType.Bool
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  {{ element[colIndex].rowData }}
                </span>

                <span
                  *ngIf="
                    col.dataType === dataType.URL &&
                    element[colIndex].id !== '' &&
                    element[colIndex].moduleId !== 0
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  <a
                    class="link fw-bold"
                    (click)="
                      redirect(element[colIndex].id, element[colIndex].moduleId)
                    "
                  >
                    {{ element[colIndex].rowData }}
                  </a>
                </span>

                <span
                  *ngIf="
                    col.dataType === dataType.URL &&
                    element[colIndex].id === '' &&
                    element[colIndex].moduleId === 0
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  {{ element[colIndex].rowData }}
                </span>

                <span
                  *ngIf="col.dataType === dataType.DateTime"
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  {{ element[colIndex].rowData | date: "dd-LLL-yyyy" }}</span
                >

                <span
                  *ngIf="
                    (col.dataType === dataType.Int32 ||
                      col.dataType === dataType.Currency) &&
                    element[colIndex].rowData !== ''
                  "
                  title="{{ element[colIndex].rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                >
                  £ {{ element[colIndex].rowData | numberPipe }}</span
                >
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnsNames$ | async"></tr>
      </table>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumnsName"></mat-header-row>

  <mat-row
    *matRowDef="let row; columns: displayedColumnsName"
    matRipple
    class="element-row"
    [class.expanded]="row.isExpanded"
    (click)="expandCollapse(row, 0)"
  ></mat-row>

  <mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    [@detailExpand]="row.isExpanded === true ? 'expanded' : 'collapsed'"
    style="overflow: hidden"
  >
  </mat-row>
</table>
<div
  *ngIf="expandedCollapseList.length === 0 && noDataFound"
  class="text-align-center mt-1"
>
  No records found
</div>
