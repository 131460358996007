import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ComponentName,
  DataType,
  ModuleName,
  Modules,
  RoutingPath,
} from '@app/core/Enum';
import {
  ExportType,
  MainListParameters,
  MenuModel,
  PermissionModel,
} from '@app/core/Models';
import { CommonService, ModulePermission } from '@app/core/Services';
import {
  CommonState,
  DonorTransactionExport,
  Export,
  GetAccountTransactionList,
  GetHeaderList,
  GetReportList,
  GetVatReportDetailList,
  MenuState,
  ReportExport,
  SetModulePermission,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { ViewReceiptComponent } from '../common';
import { AddClosePopupComponent } from '../common/add-close-popup/add-close-popup.component';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { TransactionHeaderComponent } from '../transactions';
@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit, OnDestroy {
  @Select(CommonState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
      alignment: string;
    }>
  >;

  showPaginator = true;
  isHeaderChange = false;
  moduleslist = Modules;

  @Select(CommonState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.hasEditPermission)
  hasEditPermission$: Observable<boolean>;

  @Select(MenuState.hasDeletePermission)
  hasDeletePermission$: Observable<boolean>;

  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @Input() isDirectImport: boolean = false;

  @Input() isBankImport: boolean = false;

  @ViewChild(MatSort) sort: MatSort;

  @Output()
  readonly toggleShowPaginator = new EventEmitter<any>();

  listParameters: MainListParameters = new MainListParameters();
  triggerPaginationChange: Subject<any> = new Subject<any>();
  totalSelectedRecords = 0;
  ids: Array<Guid>;

  mainList: any;
  moduleId: number;
  reportText: string;

  dataSource = new MatTableDataSource();
  dataType = DataType;
  store: Store;
  commonService: CommonService;
  router: Router;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;
  moduleEnum = Modules;
  moduleName: string;
  noDataFound = false;

  showAccountDetails = false;
  accountId: Guid;
  triggerCustomerId: Subject<any> = new Subject<any>();
  @Input()
  triggerAccountDetails: Observable<any>;

  subscriptionRouting: Subscription;
  isExpanded = true;

  @Input()
  triggerBankAccountId: Observable<any>;

  private unsubscribe$ = new Subject<void>();
  @ViewChild(TransactionHeaderComponent, { static: true })
  headerDetails;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  constructor(
    private injector: Injector,
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
    this.dialog = injector.get<MatDialog>(MatDialog);
  }

  ngOnInit(): void {
    if (!this.isBankImport) {
      this.modulePermission.permissionData
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => {
          this.moduleId = value.data.id;
          if (
            value.data.url === this.router.url.slice(1) &&
            this.moduleId !== Modules.IncomeAndExpenditure &&
            this.moduleId !== Modules.StatementOfAssetsAndLiabilities &&
            this.moduleId !== Modules.StatementofFinancialActivities &&
            this.moduleId !== Modules.BalanceSheet &&
            this.moduleId !== Modules.TrialBalance &&
            (value.componentName === ComponentName.ReportListComponent ||
              value.componentName === ComponentName.ImportListComponent)
          ) {
            this.isHeaderChange = false;
            this.setReportHeader();
          }
          this.moduleName = value.data.name;
        });
    } else if (this.isBankImport) {
      this.triggerBankAccountId?.subscribe((customId) => {
        this.moduleId = Modules.BankImportHistory;
        this.listParameters.moduleId = customId;
        this.getList();
      });
    }

    this.triggerAccountDetails?.subscribe((data) => {
      this.showAccountDetails = true;
      this.isHeaderChange = false;
      this.accountId = data.id;
      this.moduleId = data.moduleId;
      this.moduleName = this.commonService.getModuleName(this.moduleId);

      setTimeout(() => {
        this.triggerCustomerId.next(data);
      }, 0);

      if (this.moduleId !== undefined && this.moduleId !== null) {
        this.getHeaderDataForContacts()
          .pipe(take(1))
          .subscribe(() => {
            this.listParameters.filter = -1;
            this.listParameters.subModuleId = -1;
            this.getList();
          });
      }
    });

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.showAccountDetails = JSON.parse(atob(params.get('details')!));
          if (this.showAccountDetails) {
            this.accountId = atob(params.get('id')!) as unknown as any;
            const moduleId = +atob(params.get('moduleId')!) as unknown as any;
            this.moduleId = moduleId;
            const param = {
              id: this.accountId,
              moduleId: moduleId,
            };
            setTimeout(() => {
              this.triggerCustomerId.next(param);
            }, 1000);
            this.isHeaderChange = false;
            if (this.moduleId !== undefined && this.moduleId !== null) {
              this.getHeaderData();
            }
            this.moduleName = this.commonService.getModuleName(this.moduleId);

            this.getList();
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getHeaderDataForContacts(): Observable<any> {
    let param;

    switch (this.moduleId) {
      case Modules.Customers:
        param = Modules.CustomerDetailHeader;
        break;
      case Modules.Suppliers:
        param = Modules.SupplierDetailHeader;
        break;
      case Modules.Donors:
        param = Modules.DonorDetailHeader;
        break;
    }

    return this.store.dispatch(new GetHeaderList(param));
  }

  getHeaderData(): void {
    let param;

    switch (this.moduleId) {
      case Modules.Customers:
        param = Modules.CustomerDetailHeader;
        break;
      case Modules.Suppliers:
        param = Modules.SupplierDetailHeader;
        break;
      case Modules.Donors:
        param = Modules.DonorDetailHeader;
        break;
    }

    this.store.dispatch(new GetHeaderList(param));
  }

  expandPanel(): void {
    this.isExpanded = !this.isExpanded;
  }

  setReportHeader(): void {
    switch (this.moduleId) {
      case Modules.BalanceSheet:
        this.reportText = ModuleName.BalanceSheet;
        break;
      case Modules.StatementofFinancialActivities:
        this.reportText = ModuleName.StatementOfFinancialActivities;
        break;
    }
  }

  emitPermissionData(data: any, componentName: any, id: any): void {
    const permissionData = new PermissionModel();
    permissionData.data = data;
    permissionData.componentName = componentName;

    this.modulePermission.permissionData.next(permissionData);
  }

  setPermission(url: any, id: any): void {
    setTimeout(() => {
      this.menuList$.subscribe((data) => {
        this.setData(url, data, ComponentName.ReportListComponent, id);
      });
    }, 500);
  }

  setData(event: any, data: any, componentName: any, id: any): void {
    data.forEach((x) => {
      if (x.url === event.slice(1) || x.addUrl === event.slice(1)) {
        this.store.dispatch(new SetModulePermission(x)).subscribe();
        this.emitPermissionData(x, componentName, id);
      } else {
        x.subMenu.map((y) => {
          if (
            y.url === event.slice(1) ||
            y.addUrl === event.slice(1).split(';')[0]
          ) {
            this.store.dispatch(new SetModulePermission(y)).subscribe();
            this.emitPermissionData(y, componentName, id);
          } else {
            y.subMenu.map((z) => {
              if (
                z.url === event.slice(1) ||
                z.addUrl === event.slice(1).split(';')[0]
              ) {
                this.store.dispatch(new SetModulePermission(z)).subscribe();
                this.emitPermissionData(z, componentName, id);
              } else {
                z.subMenu.map((z1) => {
                  if (
                    z1.url === event.slice(1) ||
                    z1.addUrl === event.slice(1).split(';')[0]
                  ) {
                    this.store
                      .dispatch(new SetModulePermission(z1))
                      .subscribe();
                    this.emitPermissionData(z1, componentName, id);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0 && !this.isBankImport) {
      if (moduleId === Modules.FundList) {
        this.commonService.onEditRouting(true, Modules.ReportDetailsList, id);
      } else if (
        moduleId === Modules.Customers ||
        moduleId === Modules.Suppliers
      ) {
        this.commonService.onEditRouting(true, moduleId, id);
      } else if (moduleId === Modules.AddCustomAccounts) {
        this.commonService.onEditRouting(
          true,
          Modules.ReportAccountDetail,
          id,
          false
        );
      } else if (
        moduleId === Modules.Invoices ||
        moduleId === Modules.Bills ||
        moduleId === Modules.CreditNote ||
        moduleId === Modules.DebitNote ||
        moduleId === Modules.FixedAssets ||
        moduleId === Modules.Quotation ||
        moduleId === Modules.Journals ||
        moduleId === Modules.Receipt ||
        moduleId === Modules.Payment ||
        moduleId === Modules.Donations ||
        moduleId === Modules.FixedAssetDetail
      ) {
        const data = {
          moduleId,
          id,
        };

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {});
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    } else if (moduleId > 0 && this.isBankImport) {
      this.commonService.onEditRouting(
        true,
        Modules.BankImportTransactionHistory,
        id,
        true,
        Modules.BankImportTransactionHistory
      );
    }
  }

  export(format: number, isPrint?: boolean): void {
    this.spinner.show();
    this.selectedIds();

    let actionName;
    let param;

    switch (this.showAccountDetails) {
      case true:
        if (this.moduleId !== Modules.Donors) {
          actionName = ReportExport;
        } else {
          actionName = DonorTransactionExport;
        }
        param = this.accountId;
        break;

      case false:
        actionName = Export;
        param = this.moduleId;
        break;
    }

    if (actionName !== undefined) {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId: this.listParameters.moduleId,
        subModuleId: this.listParameters.subModuleId ?? -1,
        ids: this.ids,
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new actionName(queryParams, param)).subscribe();
    }
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.isHeaderChange = false;

    this.getList();
  }

  selectedIds(): void {
    this.ids = [];
    this.mainList.forEach((value) =>
      value.isSelected ? this.ids.push(value[0] ?? 0) : ''
    );
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.isHeaderChange = false;

      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.isHeaderChange = false;

    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
    this.toggleShowPaginator.emit(this.showPaginator);
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  getList(): void {
    const queryParams = this.getParamter();
    if (queryParams !== undefined) {
      this.bindGrid(queryParams);
    }
  }

  bindGrid(queryParams: any): void {
    let actionName;
    let param;
    switch (this.showAccountDetails) {
      case true:
        actionName = GetAccountTransactionList;
        param = this.accountId;
        break;
      case false:
        actionName = GetReportList;
        param = this.moduleId;
        break;
    }
    if (
      actionName !== undefined &&
      !this.isDirectImport &&
      ((this.moduleId !== null &&
        this.moduleId !== undefined &&
        this.moduleId !== Modules.Dashboard &&
        !this.showAccountDetails) ||
        this.showAccountDetails)
    ) {
      this.store
        .dispatch(new actionName(queryParams, param, this.moduleId))
        .subscribe((res) => {
          this.spinner.hide();
          this.mainList = res.common.mainList.resultSet.data;
          this.noDataFound = this.mainList.length > 0 ? false : true;
          this.dataSource.data = this.mainList;
          const param = {
            pageSize: this.listParameters.pageSize,
            totalRecord: res.common.totalRecord,
            isHeaderChange: this.isHeaderChange,
          };
          this.triggerPaginationChange.next(param);
        });
    } else {
      this.spinner.hide();
    }
  }

  getDataFromHeader(data: any): void {
    this.getFilterData(data);
  }

  getFilterData(data: any): void {
    if (data.search === '') {
      this.spinner.show();
    }
    if (this.isBankImport) {
      data.moduleId = this.listParameters.moduleId;
    }
    this.isHeaderChange = true;
    this.listParameters = data;

    !this.listParameters.format
      ? this.getList()
      : this.export(this.listParameters.format, this.listParameters.isPrint);

    if (this.moduleId === this.moduleEnum.VAT) {
      this.commonService.setVatReload(this.listParameters);
    }
  }

  getDataFromVatReport(data: any): void {
    this.listParameters.filter = data.filter;
    this.store
      .dispatch(new GetVatReportDetailList(data.id, this.getParamter()))
      .subscribe((res) => {
        this.mainList = res.common.mainList.resultSet.data;
        this.noDataFound = this.mainList.length > 0 ? false : true;
        this.dataSource.data = this.mainList;
      });
  }

  onCancel(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  downloadFile(event: any): void {
    const list: any[] = [];
    list.push(event);
    const param = {
      fileURLs: list,
    };
    this.commonService.downloadFile(param).subscribe();
  }
}
