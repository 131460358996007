import { Guid } from 'guid-typescript';

export class PaymentsModel {
  id: Guid;
  receiptNo: string;
  receiptDate: string;
  accountId: string;
  amount: string;
}

export class VatRateScheme {
  id: number;
  name: string;
  rate: number;
}
