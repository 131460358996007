import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmationType,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { AccountProvider } from '@app/core/Enum/account-provider';
import { ConfirmationList } from '@app/core/Models';
import {
  CommonService,
  NoteService,
  NotificationService,
} from '@app/core/Services';
import {
  ActiveDisposeId,
  ArchiveAndRestoreAccount,
  ArchiveAndRestoreActivity,
  ArchiveAndRestoreBankEntry,
  ArchiveAndRestoreBankTransfer,
  ArchiveAndRestoreBill,
  ArchiveAndRestoreCashEntry,
  ArchiveAndRestoreCreditNote,
  ArchiveAndRestoreCustomAccount,
  ArchiveAndRestoreDebitNote,
  ArchiveAndRestoreDonation,
  ArchiveAndRestoreDonationInKind,
  ArchiveAndRestoreDonor,
  ArchiveAndRestoreFixedAssets,
  ArchiveAndRestoreFund,
  ArchiveAndRestoreFundTransfer,
  ArchiveAndRestoreInvoice,
  ArchiveAndRestoreJournals,
  ArchiveAndRestoreQuotation,
  ArchiveAndRestoreReceipt,
  ArchiveAndRestoreRecurring,
  ArchiveAndRestoreRecurringDonation,
  ArchiveAndRestoreSponsor,
  ArchiveAndRestoreUser,
  ArchiveAndRestoreVATCodes,
  CopyPreviousYearNote,
  DeleteAccount,
  DeleteActivity,
  DeleteBankAccount,
  DeleteBankImportTransactionHistory,
  DeleteBankStatementTransaction,
  DeleteBankTransfer,
  DeleteBill,
  DeleteCreditNote,
  DeleteCustomAccount,
  DeleteDebitNote,
  DeleteDonation,
  DeleteDonationInKind,
  DeleteDonor,
  DeleteFinancialYear,
  DeleteFixedAssets,
  DeleteFund,
  DeleteFundTransfer,
  DeleteGiftAidDetails,
  DeleteInvoice,
  DeleteJournals,
  DeleteMail,
  DeleteQuotation,
  DeleteReceipt,
  DeleteRecurringData,
  DeleteRecurringDonation,
  DeleteSavedFile,
  DeleteSelectedUser,
  DeleteSponsor,
  DeleteUserCompanyByUserId,
  DeleteVATCodes,
  PrepareGoogleOrOffice,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss'],
})
export class ConfirmationBoxComponent implements OnInit {
  confirmationList: ConfirmationList;
  confirmationType = ConfirmationType;
  id: Guid;
  noteData: any;
  modules = Modules;

  constructor(
    private store: Store,

    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    private notifier: NotificationService,
    public commonService: CommonService,
    public noteService: NoteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.confirmationList = this.data;
    this.id = this.data.id;
  }

  onYesClick(): void {
    this.spinner.show();
    if (this.confirmationList.type === ConfirmationType.Archive) {
      this.onArchiveClick();
    }

    if (this.confirmationList.type === ConfirmationType.Delete) {
      this.onDeleteClick();
    }

    if (this.confirmationList.type === ConfirmationType.Mail) {
      this.mailIntegration();
    }

    if (this.confirmationList.type === ConfirmationType.Activate) {
      this.onDisposedActivateClick();
    }

    if (this.confirmationList.type === ConfirmationType.MergeTrailBalance) {
      this.spinner.hide();
      this.dialogRef.close(true);
    }

    if (this.confirmationList.type === ConfirmationType.TrailBalanceErrorList) {
      this.spinner.hide();
      this.dialogRef.close(true);
    }

    if (this.confirmationList.type === ConfirmationType.Copy) {
      this.onCopyClick();
    }

    if (this.confirmationList.type === ConfirmationType.RefreshTrialBalance) {
      this.spinner.hide();
      this.dialogRef.close(true);
    }
  }

  onCopyClick(): void {
    this.noteData = {
      id: this.data.id,
      accountingPeriodId: this.confirmationList.accountingPeriod,
    };

    this.store
      .dispatch(new CopyPreviousYearNote(this.noteData))
      .subscribe((res) => {
        this.dialogRef.close(res.note.noteData);
      });
  }

  //#region  Delete

  onDeleteClick(): void {
    let actionName;

    if (
      this.confirmationList.multipleModulesIds !== undefined &&
      Object.keys(this.confirmationList.multipleModulesIds).length > 0
    ) {
      Object.keys(this.confirmationList.multipleModulesIds).forEach(
        (element) => {
          actionName = this.getActionNameForDelete(+element);
          this.callDeleteAPI(
            actionName,
            this.confirmationList.multipleModulesIds[+element],
            +element
          );
        }
      );
    } else if (this.confirmationList.moduleId === Modules.AnnualAccounts) {
      this.store
        .dispatch(
          new DeleteSavedFile(
            this.confirmationList.ids,
            this.confirmationList.accountingPeriod
          )
        )
        .subscribe(
          (data: any) => {
            this.onSuccessDelete();
          },
          (error) => {
            this.spinner.hide();
            this.dialogRef.close(true);
          }
        );
    } else {
      actionName = this.getActionNameForDelete(this.confirmationList.moduleId);
      this.callDeleteAPI(
        actionName,
        this.confirmationList.ids,
        this.confirmationList.moduleId
      );
    }
  }

  onDisposedActivateClick(): void {
    let actionName;
    actionName = this.getActionNameForDelete(this.confirmationList.moduleId);
    this.callDisposedActivateAPI(
      actionName,
      this.confirmationList.ids,
      this.confirmationList.moduleId
    );
  }

  getActionNameForDelete(moduleId): any {
    let actionName;

    switch (moduleId) {
      case Modules.Invoices:
        actionName = DeleteInvoice;
        break;

      case Modules.Customers:
      case Modules.Suppliers:
        actionName = DeleteAccount;
        break;

      case Modules.Donors:
        actionName = DeleteDonor;
        break;

      case Modules.BankTransfer:
        actionName = DeleteBankTransfer;
        break;

      case Modules.SponsorEvent:
        actionName = DeleteSponsor;
        break;

      case Modules.Activities:
        actionName = DeleteActivity;
        break;

      case Modules.Journals:
      case Modules.FixedAssetRollBack:
        actionName = DeleteJournals;
        break;

      case Modules.Quotation:
        actionName = DeleteQuotation;
        break;

      case Modules.Bills:
        actionName = DeleteBill;
        break;

      case Modules.AddCustomAccounts:
      case Modules.BankDashboard:
        actionName = DeleteCustomAccount;
        break;

      case Modules.CreditNote:
        actionName = DeleteCreditNote;
        break;

      case Modules.DebitNote:
        actionName = DeleteDebitNote;
        break;

      case Modules.Donations:
        actionName = DeleteDonation;
        break;

      case Modules.FundList:
        actionName = DeleteFund;
        break;

      case Modules.FundTransfers:
        actionName = DeleteFundTransfer;
        break;

      case Modules.FixedAssets:
        actionName = DeleteFixedAssets;
        break;

      case Modules.RecurringInvoice:
      case Modules.RecurringBill:
        actionName = DeleteRecurringData;
        break;

      case Modules.Receipt:
      case Modules.Payment:
      case Modules.CashEntry:
      case Modules.BankEntry:
        actionName = DeleteReceipt;
        break;

      case Modules.BankFeed:
        actionName = DeleteBankAccount;
        break;

      case Modules.RecurringDonations:
        actionName = DeleteRecurringDonation;
        break;

      case Modules.DonationsInKind:
        actionName = DeleteDonationInKind;
        break;

      case Modules.VatSettings:
        actionName = ArchiveAndRestoreVATCodes;
        break;

      case Modules.FinancialYear:
        actionName = DeleteFinancialYear;
        break;

      case Modules.BankImportTransactionHistory:
        actionName = DeleteBankImportTransactionHistory;
        break;

      case Modules.DonationGiftAid:
        actionName = DeleteGiftAidDetails;
        break;

      case Modules.FixedAssetsDispose:
        actionName = ActiveDisposeId;
        break;

      case Modules.BankFeedTrasnaction:
        actionName = DeleteBankStatementTransaction;
        break;

      case Modules.Users:
        actionName = DeleteSelectedUser;
        break;

      case Modules.UserClients:
        actionName = DeleteUserCompanyByUserId;
        break;
    }

    return actionName;
  }

  callDisposedActivateAPI(actionName, ids, moduleId): void {
    if (actionName !== undefined) {
      this.store.dispatch(new actionName(ids, moduleId)).subscribe(
        () => {
          this.onSuccessDisposed();
        },
        (error) => {
          this.spinner.hide();
          this.dialogRef.close(true);
        }
      );
    } else {
      this.spinner.hide();
      this.dialogRef.close(true);
      this.onFailure();
    }
  }

  callDeleteAPI(actionName, ids, moduleId): void {
    if (actionName !== undefined) {
      this.store.dispatch(new actionName(ids, moduleId)).subscribe(
        () => {
          this.onSuccessDelete();
        },
        (error) => {
          this.spinner.hide();
          this.dialogRef.close(false);
        }
      );
    } else if (Modules.Integration) {
      this.store.dispatch(new DeleteMail(this.id)).subscribe((data: any) => {
        this.dialogRef.close(data);
      });
    } else {
      this.spinner.hide();
      this.dialogRef.close(true);
      this.onFailure();
    }
  }

  //#endregion

  //#region  Archive

  onArchiveClick(): void {
    let actionName;

    if (
      this.confirmationList.multipleModulesIds !== undefined &&
      Object.keys(this.confirmationList.multipleModulesIds).length > 0
    ) {
      Object.keys(this.confirmationList.multipleModulesIds).forEach(
        (element) => {
          actionName = this.getActionNameForArchive(+element);
          this.callArchiveAPI(
            actionName,
            this.confirmationList.multipleModulesIds[+element]
          );
        }
      );
    } else {
      actionName = this.getActionNameForArchive(this.confirmationList.moduleId);
      this.callArchiveAPI(actionName, this.confirmationList.ids);
    }
  }

  getActionNameForArchive(moduleId): any {
    let actionName;

    switch (moduleId) {
      case Modules.Invoices:
        actionName = ArchiveAndRestoreInvoice;
        break;

      case Modules.Customers:
      case Modules.Suppliers:
        actionName = ArchiveAndRestoreAccount;
        break;

      case Modules.Donors:
        actionName = ArchiveAndRestoreDonor;
        break;

      case Modules.SponsorEvent:
        actionName = ArchiveAndRestoreSponsor;
        break;

      case Modules.Activities:
        actionName = ArchiveAndRestoreActivity;
        break;

      case Modules.Journals:
        actionName = ArchiveAndRestoreJournals;
        break;

      case Modules.Quotation:
        actionName = ArchiveAndRestoreQuotation;
        break;

      case Modules.Bills:
        actionName = ArchiveAndRestoreBill;
        break;

      case Modules.AddCustomAccounts:
        actionName = ArchiveAndRestoreCustomAccount;
        break;

      case Modules.CreditNote:
        actionName = ArchiveAndRestoreCreditNote;
        break;

      case Modules.DebitNote:
        actionName = ArchiveAndRestoreDebitNote;
        break;

      case Modules.Donations:
        actionName = ArchiveAndRestoreDonation;
        break;

      case Modules.FundList:
        actionName = ArchiveAndRestoreFund;
        break;

      case Modules.FundTransfers:
        actionName = ArchiveAndRestoreFundTransfer;
        break;

      case Modules.FixedAssets:
        actionName = ArchiveAndRestoreFixedAssets;
        break;

      case Modules.RecurringInvoice:
      case Modules.RecurringBill:
        actionName = ArchiveAndRestoreRecurring;
        break;

      case Modules.RecurringDonations:
        actionName = ArchiveAndRestoreRecurringDonation;
        break;

      case Modules.DonationsInKind:
        actionName = ArchiveAndRestoreDonationInKind;
        break;

      case Modules.CashEntry:
        actionName = ArchiveAndRestoreCashEntry;
        break;
      case Modules.BankEntry:
        actionName = ArchiveAndRestoreBankEntry;
        break;

      case Modules.Receipt:
      case Modules.Payment:
        actionName = ArchiveAndRestoreReceipt;
        break;

      case Modules.VatSettings:
        actionName = DeleteVATCodes;
        break;

      case Modules.BankTransfer:
        actionName = ArchiveAndRestoreBankTransfer;
        break;

      case Modules.Users:
        actionName = ArchiveAndRestoreUser;
        break;
    }

    return actionName;
  }

  callArchiveAPI(actionName, ids): void {
    if (actionName !== undefined) {
      this.store
        .dispatch(new actionName(ids, this.confirmationList.isArchive))
        .subscribe((data) => {
          data
            ? this.onArchiveAndRestoreSuccess()
            : this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
        });
    } else {
      this.onFailure();
      this.spinner.hide();
      this.dialogRef.close();
    }
  }

  //#endregion

  mailIntegration() {
    let redirectUrl, provider, configType;
    switch (this.confirmationList.moduleId) {
      case Modules.Integration:
        redirectUrl =
          window.location.origin.toString() + '/manage/integrations';
        provider = this.id[0] === AccountProvider.Google ? 'google' : 'office';
        configType =
          this.confirmationList.headerText ==
          NotificationHeader.reauthenticateYourAccount
            ? 'ReAuth'
            : '';
        this.redirectToMail(configType, redirectUrl, provider);
        break;

      default:
        break;
    }
  }

  redirectToMail(configType, redirectUrl, provider) {
    let payload = {
      ProviderId: AccountProvider.Google,
      configurationJson: configType,
      emailAddress: '',
      returnUrl: redirectUrl,
      ClientId: '1',
      userId: '1',
      ProviderType: provider,
    };

    this.store
      .dispatch(new PrepareGoogleOrOffice(payload))
      .subscribe((res: any) => {
        window.open(res.integration.integrationData, '_self');
      });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onFailure(): void {
    this.notifier.error(
      NotificationHeader.error,
      NotificationTextMessage.errorMessage
    );
  }

  onSuccessDelete(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.recordDeletedSuccessfully
    );
    this.dialogRef.close(true);
  }

  onSuccessDisposed(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.recordActivateSuccessfully
    );
    this.dialogRef.close(true);
  }

  onArchiveAndRestoreSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      this.confirmationList.isArchive
        ? NotificationTextMessage.recordArchivedSuccessfully
        : NotificationTextMessage.recordRestoredSuccessfully
    );
    this.dialogRef.close(true);
  }
}
