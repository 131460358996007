import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import {
  ActionType,
  ConfirmationType,
  HeaderTextForSideList,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  UserFilteredStatus,
} from '@app/core/Enum';
import { GridActionsText } from '@app/core/Enum/action-type';
import { ExportType, MainListParameters } from '@app/core/Models';
import { Pagination } from '@app/core/Models/common/pagination';
import {
  CommonService,
  HighlightRow,
  NotificationService,
} from '@app/core/Services';
import {
  Copy,
  Export,
  GetUserDetailList,
  InviteUser,
  MenuState,
  SendEmail,
  UserState,
} from '@app/core/Store';
import { ConfirmationBoxComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GrantPermissionPopupComponent } from '../grant-permission-popup/grant-permission-popup.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit, OnDestroy {
  listParameters: MainListParameters = new MainListParameters();
  moduleId = Modules.Users;
  moduleName = ModuleName.Users;
  moduleHeaderText = HeaderTextForSideList.Users;
  userList: any[];
  gridActions: any[];
  @ViewChild(MatSort) sort: MatSort;
  ids: any[] = [];
  exportType = ExportType;
  noRecordFound = 'Use the button below to add new';
  displayedColumns: string[] = ['userCode', 'fullName', 'email', 'status'];
  actionTypeEnum = ActionType;
  selectedRowIndex = -1;
  @Select(UserState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(UserState.reportActions)
  actions$: Observable<any>;

  @Select(UserState.isDataAvailable)
  isDataAvailable$: Observable<boolean>;

  // @Select(SettingsState.getSelectedMenuPermission)
  // isViewPermission$: Observable<any>;

  isAllSelected = false;
  isRowHighlighted = false;
  moreActionCount = 4;
  paginationParameters: Pagination = new Pagination();
  showGIF: boolean;
  showPagination = false;
  showPaginator = true;

  totalRecords: number;
  pageSize: number;
  pageNumber = 1;
  sortBy: string;
  prevPageSize: number;
  sortOrder: boolean;
  totalSelectedRecords = 0;

  triggerOnSearch: Subject<number> = new Subject<number>();
  defaultFilterStatus = 'All Status';
  highlightedRowIndex: Array<number> = [];
  searchText = '';
  statusFilter = UserFilteredStatus;
  commonNotificationText = NotificationTextMessage;
  gridActionsText = GridActionsText;

  @ViewChildren('button') buttons: QueryList<ElementRef>;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  constructor(
    private notifier: NotificationService,
    public dialog: MatDialog,
    private store: Store,
    private highlightRow: HighlightRow,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private notify: NotificationService
  ) {
    this.isAllSelected = false;
  }

  ngOnInit(): void {
    this.highlightRecord();
  }

  ngOnDestroy(): void {
    this.highlightRow.mainListHighlighted = {
      isHighlighted: false,
      sortBy: 'createdOn',
    };
  }

  onPopupOpen(): void {
    this.dialog
      .open(GrantPermissionPopupComponent, {})
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
        }
      });
  }

  highlightRecord(): void {
    if (this.highlightRow.mainListHighlighted.moduleId === this.moduleId) {
      this.listParameters.sortBy = this.highlightRow.mainListHighlighted.sortBy;
      this.listParameters.sortOrder = false;
      this.getList(true);
    } else {
      this.getList(false);
    }
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  onButtonClick(id: any, actionType: ActionType): void {
    this.ids = [];
    this.ids.push(id);

    switch (actionType) {
      case ActionType.Update:
        this.commonService.onEditRouting(true, this.moduleId, id);
        break;

      case ActionType.Delete:
        this.onDeleteClick(id);
        break;

      // case ActionType.Export:
      //   this.ids = [];
      //   this.ids.push(id.rowData);
      //   this.store
      //     .dispatch(new ExportReceipt(this.moduleId, this.ids, false))
      //     .subscribe();
      //   break;

      // case ActionType.View:
      //   const data: ViewParamModel = {
      //     moduleId: this.moduleId,
      //     id: id.rowData,
      //   };

      //   this.dialog
      //     .open(ViewReceiptComponent, {
      //       data,
      //       disableClose: true,
      //     })
      //     .afterClosed()
      //     .subscribe((result) => {});
      //   break;

      case ActionType.Email:
        this.store.dispatch(new SendEmail(this.moduleId, id)).subscribe((x) => {
          if (x.common.isMailSent) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.emailMessage
            );
          } else {
            this.notify.error(
              NotificationHeader.error,
              NotificationTextMessage.emailFailedMessage
            );
          }
        });
        break;
    }
  }

  getParameters(): any {
    if (this.listParameters.search === '') {
      this.spinner.show();
    }
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      filter: this.listParameters.filter,
    };

    return queryParams;
  }

  getList(isRowHighlighted: boolean): void {
    this.store
      .dispatch(new GetUserDetailList(this.getParameters()))
      .pipe(
        tap(() => {
          this.isRowHighlighted = isRowHighlighted;
        })
      )
      .subscribe((res) => {
        if (this.actions$ !== null || this.actions$ !== undefined) {
          this.actions$.subscribe((x) => {
            if (x.length > 4) {
              this.moreActionCount = 3;
            }
          });
        }
        this.userList = res.user.userList;
        this.gridActions = res.user.gridActions;

        this.commonService.setButtonPermissions(this.buttons, this.gridActions);
      });
  }

  onExpenseTypeEdit(id: any): void {}

  onExpenseTypeArchive(data: any, archive: boolean): void {}

  onExpenseTypeDelete(data: any): void {}

  onSearch(event: any): void {
    this.listParameters.search = event.target.value;
    this.listParameters.pageNumber = 1;
    this.getList(false);
  }

  onFilterChange(val: any): void {
    this.listParameters.filter = +val;
    this.listParameters.pageNumber = 1;
    this.cancelSelectionClick();
    this.getList(false);
  }

  deleteClick() {
    this.ids = [];

    this.userList.forEach((x) =>
      x.isSelected ? this.ids!.push(x.id!) : (Guid.EMPTY as unknown as Guid)
    );

    this.openDeletePopup();
  }

  onDeleteClick(id: any): void {
    this.ids = [];
    this.ids.push(id);
    this.openDeletePopup();
  }

  openDeletePopup(): void {
    if (this.checkCanDeleteArchive(false)) {
      if (this.ids.length > 0) {
        this.dialog
          .open(ConfirmationBoxComponent, {
            data: {
              ids: this.ids,
              type: ConfirmationType.Delete,
              moduleId: this.moduleId,
              totalNumberOfRecordSelected: this.ids.length,
              headerText: NotificationHeader.deleteConfirmation,
              detailText: NotificationDetails.deleteAllDetailText,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.getList(false);
            }
          });
      }
    }
  }

  getSelectedIds(data?: any): void {
    this.ids = [];

    if (data === undefined) {
      this.userList.forEach((x) =>
        x.isSelected ? this.ids!.push(x.id!) : (Guid.EMPTY as unknown as Guid)
      );
    } else {
      this.ids.push(data.id);
    }
  }

  copyClick(): void {
    this.getSelectedIds();

    this.store.dispatch(new Copy(this.moduleId, this.ids!)).subscribe(
      (res) => {
        if (res.common.isCopied) {
          this.notifier.success(
            NotificationHeader.success,
            NotificationTextMessage.recordCopySuccessfully
          );
          this.getList(false);
          this.cancelSelectionClick();
        }
      },
      (error) => {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.errorMessage
        );
      }
    );
  }

  export(format: number, isPrint: boolean = false, data?: any): void {
    this.spinner.show();
    this.getSelectedIds(data);

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId,
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.ids,
      startDate: null,
      endDate: null,
      format: format,
      isPrint: isPrint,
    };

    this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
    this.cancelSelectionClick();
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.userList.forEach((x) => (x.isSelected = false));
    this.pageSize = this.prevPageSize;
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
  }

  onArchiveClick(isArchive: boolean, data?: any): void {
    this.getSelectedIds(data);

    if (this.checkCanDeleteArchive(true)) {
      if (this.checkRecordsArchived(isArchive)) {
        let detailText = this.setDetailtext(isArchive, data);
        this.dialog
          .open(ConfirmationBoxComponent, {
            data: {
              ids: this.ids,
              isArchive: isArchive,
              type: ConfirmationType.Archive,
              moduleId: this.moduleId,
              moduleName: ModuleName.Users,
              headerText: isArchive
                ? NotificationHeader.archiveConfirmation
                : NotificationHeader.restoreConfirmation,
              totalNumberOfRecordSelected: this.ids!.length,
              detailText: detailText,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.deSelectAll();
              this.getList(false);
            }
          });
      }
    }
  }

  setDetailtext(isArchive: boolean, data?: any) {
    let detailText: string = '';

    if (data === undefined) {
      if (isArchive) {
        detailText = NotificationDetails.archiveDetailText;
      } else {
        detailText = NotificationDetails.restoreDetailText;
      }
    } else {
      if (isArchive) {
        detailText = `${NotificationDetails.archiveDetailText}`;
      } else {
        detailText = `${NotificationDetails.restoreDetailText}`;
      }
    }
    return detailText;
  }

  checkRecordsArchived(isArchive: boolean): boolean {
    let returnValue = true;

    this.userList?.every((data) => {
      if (this.ids?.includes(data.id!) && data.isArchived && isArchive) {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.alreadyInactive
        );
        returnValue = false;
      }
      if (this.ids?.includes(data.id!) && !data.isArchived && !isArchive) {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.alreadyRestore
        );
        returnValue = false;
      }
    });

    return returnValue;
  }

  checkCanDeleteArchive(isArchiveClicked: boolean): boolean {
    let returnValue = true;

    this.userList?.forEach((data) => {
      if (this.ids?.includes(data.id!) && !data.canDelete) {
        this.notifier.error(
          NotificationHeader.error,
          isArchiveClicked
            ? NotificationTextMessage.canNotInactive
            : NotificationTextMessage.canNotDelete
        );
        returnValue = false;
      }
    });

    return returnValue;
  }

  selectAll(event: any): void {
    this.userList.forEach((x) => (x.isSelected = event.checked));
    this.showFooter();
  }

  showFooter(): void {
    this.getSelectedIds();
    this.totalSelectedRecords = this.ids!.length;
  }

  onCheckBoxSelected(event: any): void {
    this.isAllSelected = this.userList.every(
      (item: any) => item.isSelected === true
    );
    this.showFooter();
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList(false);
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList(false);
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onAddClick(): void {
    this.commonService.onAddRouting(this.moduleId);
  }

  sorting(sortBy: string, sortOrder: string): void {
    if (sortOrder) {
      this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
      this.listParameters.sortBy = sortBy;
    } else {
      this.listParameters.sortOrder = true;
      this.listParameters.sortBy = '';
    }
    this.listParameters.pageNumber = 1;
    this.getList(false);
    this.deSelectAll();
  }

  getActionType(actionType: any, data: any): void {}

  onInviteClick(ele: any): void {
    this.store.dispatch(new InviteUser(ele.id)).subscribe(
      (result) => {
        if (result.user.isSuccess) {
          this.notifier.success(
            NotificationHeader.success,
            result.user.userResponse.statusMessage
          );
          this.getList(false);
        } else {
          this.notifier.error(
            NotificationHeader.error,
            result.user.userResponse.statusMessage
          );
        }
      },
      (error) => {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.errorMessage
        );
      }
    );
  }
  onUserOverviewClick(id): void {
    this.commonService.onEditRouting(true, this.moduleId, id);
  }
}
