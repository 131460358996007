import {
  Component,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProjectName, RoutingPath } from '@app/core/Enum';
import {
  Company,
  GlobalComponent,
  MainListParameters,
  MenuModel,
  ProfileInfoModel,
} from '@app/core/Models';
import { Select, Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as theme from 'src/assets/json/themes.json';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  CompanyState,
  GetAccountingMethod,
  GetAddMenu,
  GetAvailableCompanyCount,
  GetCompanyList,
  GetDefaultCurrency,
  GetFinancialPeriod,
  GetIsVatRegistered,
  GetMenu,
  GetProfileInfo,
  GetUserBusinessPermission,
  MenuState,
  RetrieveSubscription,
  SetAsDefaultCompany,
  UpdateThemeColor,
} from '@app/core/Store';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { CommonService, DashboardService } from '@app/core/Services';
import { NgxSpinnerService } from 'ngx-spinner';

declare const tinycolor: any;
export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userData: any;
  userName?: string;
  userPhoto = '';
  moduleName: string;
  userEmail?: string;
  companyName: string;
  appUrl = '';
  subscriptionModuleList: any = [];

  isSearchboxShow = false;

  moduleId: number;
  userId?: Guid;
  headerForm: FormGroup;
  companyList: Array<Company>;
  addMenuList: Array<MenuModel>;
  menuData: Array<MenuModel>;
  profileInfo: ProfileInfoModel;
  tempMenuData: Array<MenuModel>;
  myAdminUrl: string;

  // Multicolor theme
  colorSelected: any;
  singletheme: any;
  themes = JSON.parse(JSON.stringify(theme));
  colors: string[] = [
    '#6d41a1',
    '#6661b8',
    '#4461d7',
    '#a0144f',
    '#045b62',
    '#14539a',
    '#a76f05',
    '#000000',
  ];

  primaryColor = this.globalComponent.themecolor();
  primaryColorPalette: Color[] = [];
  commonService: CommonService;

  @Input()
  triggerDataFromComponent: Observable<any>;

  @Select(MenuState.hasAddPermission)
  hasAddPermission$: Observable<boolean>;

  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;

  @Select(MenuState.menu)
  menuList$: Observable<any>;

  @Select(CompanyState.companyCount)
  companyCount$: Observable<number>;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(MatSort) sort: MatSort;

  listParameters: MainListParameters = new MainListParameters();

  displayedColumns: string[] = [
    'default',
    'companyname',
    'charityRegulator',
    'vatnumber',
  ];
  defaultCurrency: any;
  accountingMethod: any;
  isVatRegistered: any;
  environment = environment;
  projectName = ProjectName;
  searchText = '';
  tenantName = '';
  helpDeskUrl = environment.helpDeskUrl;
  subscriptionPortalAppUrl = '';
  isSubscriptionPortalEnabled: boolean = false;
  profileUrl = '';

  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private cookieService: CookieService,
    private store: Store,
    private globalComponent: GlobalComponent,
    private activatedroute: ActivatedRoute,
    public highlightRow: HighlightRow,
    private injector: Injector,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService
  ) {
    //To reload companyList when new company is added
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let r = this.activatedroute;
        while (r.firstChild) {
          r = r.firstChild;
        }

        r.params.subscribe((params) => {
          if (Boolean(params.loadCompany)) {
            this.getCompanyList();
          }
        });
      }
    });

    this.commonService = this.injector.get<CommonService>(CommonService);

    this.userData = JSON.parse(
      sessionStorage.getItem(`${environment.clientId}_userData`) as any
    );

    if (this.userData.picture !== undefined && this.userData.picture !== null) {
      this.userData.picture = `${environment.cdnUrl}` + this.userData.picture;
    }
    setTimeout(() => {
      this.onSelectionChange(this.globalComponent.themecolor(), false);
    }, 1000);
  }

  getParamaters(): any {
    const queryParams = {
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
    };

    return queryParams;
  }

  ngOnInit(): void {
    this.tenantName = this.commonService.getTenantName();
    this.headerForm = new FormGroup({
      searchText: new FormControl(''),
      colorSelected: new FormControl(this.primaryColor),
    });

    this.getCompanyList();
    this.getUserProfileInfo();
    this.retrieveSubscription();
    this.store
      .dispatch(new GetAvailableCompanyCount())
      .subscribe((companyCount) => {
        if (companyCount.company.companyCount > 0) {
          this.getDefaultCurrency();
          this.getFinancialPeriod();
          this.getAccoutingMethod();
          this.getIsVatRegistered();
        }
      });

    this.userId = this.globalComponent.getLoggedInUserId();
    this.triggerDataFromComponent?.subscribe((data) => {
      this.moduleName = data.moduleName;
      this.moduleId = data.moduleId;
    });

    setTimeout(() => {
      this.bindMenu();
    }, 1000);

    this.store
      .dispatch(new GetAddMenu())
      .pipe(
        tap((res) => {
          this.addMenuList = res.Menu.addMenu;
        })
      )
      .subscribe();
  }

  bindMenu(): void {
    this.store.dispatch(new GetMenu()).subscribe((data) => {
      this.menuData = [];
      data.Menu.Menu.forEach((x) => {
        if (x.url !== undefined && x.url !== null) {
          this.menuData.push(x);
        } else {
          x.subMenu.map((y) => {
            if (y.url !== undefined && y.url !== null) {
              this.menuData.push(y);
            } else {
              y.subMenu.map((z) => {
                if (z.url !== undefined && z.url !== null) {
                  this.menuData.push(z);
                } else {
                  z.subMenu.map((z1) => {
                    if (z1.url !== undefined && z1.url !== null) {
                      this.menuData.push(z1);
                    }
                  });
                }
              });
            }
          });
        }
      });
      this.tempMenuData = this.menuData;
    });
  }

  onSearchName(event: any): void {
    const selectedArray: MenuModel[] = [];

    this.menuData.forEach((item) => {
      if (
        item.name
          .toLowerCase()
          .includes(event.currentTarget.value.toLowerCase())
      ) {
        selectedArray.push(item);
      }
    });

    if (selectedArray.length === 0) {
      this.headerForm.controls.searchText.setValue('');
      this.menuData = this.tempMenuData;
    }
  }

  onSearchModule(event: any): void {
    this.menuData = this.tempMenuData;

    if (
      event.currentTarget.value.length === 0 &&
      event.currentTarget.value === ''
    ) {
      this.menuData = this.tempMenuData;
    }
    const selectedArray: MenuModel[] = [];

    this.menuData.forEach((item) => {
      if (
        item.name
          .toLowerCase()
          .includes(event.currentTarget.value.toLowerCase())
      ) {
        selectedArray.push(item);
      }
    });

    if (event.currentTarget.value) {
      this.menuData = selectedArray;
    }
  }

  onSearchModuleSelection(event: any): void {
    this.headerForm.reset();
    this.bindMenu();
    this.router.navigate([event.option.value]);
  }

  goToProfile(): void {
    window.location.href = environment.stsServerUrl + '/Manage';
  }

  signOutUser(): void {
    this.cookieService.deleteAll();
    this.oidcSecurityService.logoff();
  }

  isActiveWidget(val: Array<string>): boolean {
    return val.includes(this.router.url);
  }

  onSearch(): void {
    this.listParameters.search = this.searchText;
    this.getCompanyList();
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.onSearch();
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getCompanyList();
  }

  getCompanyList(): void {
    this.store
      .dispatch(new GetCompanyList(this.getParamaters()))
      .pipe(
        tap((res) => {
          this.companyList = res.company.company;

          if (this.companyList.length) {
            this.companyName =
              this.companyList.find((item) => item.isDefault === true)
                ?.companyName ?? this.companyName;
          }

          this.setCompanyId(
            this.companyList.find((item) => item.isDefault === true)?.id
          );
        })
      )
      .subscribe();
  }

  setCompanyId(companyId): void {
    this.cookieService.set('companyid', companyId);
  }

  onChangeCompany(company): void {
    this.trigger.closeMenu();
    this.companyName = company.companyName;

    this.store.dispatch(new SetAsDefaultCompany(company.id)).subscribe((x) => {
      this.setCompanyId(company.id);
      this.ngOnInit();
      localStorage.setItem('defaultFinancialPeriod', '-1');
      this.dashboardService.setDashboardReload(true);
      this.spinner.show();
    });
    this.router.navigate([RoutingPath.Dashboard]);
  }

  onEditClick(companyId: any): void {
    this.router.navigate([RoutingPath.EditCharity, { id: btoa(companyId) }]);
    this.trigger.closeMenu();
  }

  onNewCompanyAdded(): void {
    this.router.navigate([RoutingPath.AddCharity]);
  }

  onCancel(): void {
    this.isSearchboxShow = false;
  }

  onSearchClick(): void {
    this.isSearchboxShow = true;
  }

  onCloseClick(): void {
    this.isSearchboxShow = false;
  }

  getUserProfileInfo(): void {
    this.store
      .dispatch(new GetProfileInfo())
      .pipe(
        tap((res) => {
          this.userId = JSON.parse(
            sessionStorage.getItem(`${environment.clientId}_userData`) as any
          ).CustomerId;
          this.userName = res.Menu.profileInfo.fullName;
          this.userEmail = res.Menu.profileInfo.email;
          this.userPhoto = res.Menu.profileInfo.photo;
        })
      )
      .subscribe();
  }

  getDefaultCurrency(): void {
    this.store
      .dispatch(new GetDefaultCurrency())
      .pipe(
        tap((res) => {
          this.defaultCurrency = res.common.defaultCurrency;
          this.cookieService.set(
            'defaultCurrency',
            JSON.stringify(this.defaultCurrency)
          );
        })
      )
      .subscribe();
  }

  getIsVatRegistered(): void {
    this.store
      .dispatch(new GetIsVatRegistered())
      .pipe(
        tap((res) => {
          this.isVatRegistered = res.common.isVatRegistered;
          this.cookieService.set(
            'isVatRegistered',
            JSON.stringify(res.common.isVatRegistered)
          );
        })
      )
      .subscribe();
  }

  getAccoutingMethod(): void {
    this.store
      .dispatch(new GetAccountingMethod())
      .pipe(
        tap((res) => {
          this.accountingMethod = res.annualReport.accountingMethod;
          this.cookieService.set(
            'accountingMethod',
            JSON.stringify(res.annualReport.accountingMethod)
          );
        })
      )
      .subscribe();
  }

  getFinancialPeriod(): void {
    this.highlightRow.financialData = [];
    this.store
      .dispatch(new GetFinancialPeriod())
      .pipe(
        tap((res) => {
          this.highlightRow.financialData = res.common.periodicDate;
          this.commonService.setLocalStorage(
            'financialPeriod',
            JSON.stringify(res.common.periodicDate)
          );
        })
      )
      .subscribe();
  }

  scrollIntoView() {
    this.commonService.autoScrollMatAutoComplete(this.renderer);
  }

  onSelectionChange(event: any, isThemeColor: boolean) {
    this.commonService.clearThemeMessages();
    this.colorSelected = event;
    this.primaryColor = event;

    this.computeColors(this.primaryColor);

    if (isThemeColor) {
      this.store
        .dispatch(new UpdateThemeColor(this.primaryColor))
        .subscribe(() => {
          this.cookieService.set('themecolor', this.primaryColor);
        });

      this.headerForm.controls.colorSelected.setValue(this.primaryColor);
      this.highlightRow.defaultColor.next(this.primaryColor);
    }
  }

  computeColors(hex: string): void {
    for (let index = 0; index < Object.keys(this.themes).length - 2; index++) {
      if (this.themes[index].id === hex) {
        const selectedThemeArray = this.themes[index];

        this.primaryColorPalette = [
          this.getColorObject(selectedThemeArray?.id, '500'),
          this.getColorObject(selectedThemeArray?.color50, '50'),
          this.getColorObject(selectedThemeArray?.color100, '100'),
          this.getColorObject(selectedThemeArray?.color200, '200'),
          this.getColorObject(selectedThemeArray?.color300, '300'),
        ];
      }
    }

    for (const color of this.primaryColorPalette) {
      const key = `--theme-primary-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  getColorObject(value, name): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }

  retrieveSubscription(): void {
    this.store.dispatch(new RetrieveSubscription()).subscribe(
      (res) => {
        this.subscriptionModuleList =
          res.subscription.retrieveSubscriptionList.listOfAddons ?? [];
        this.profileUrl = res.subscription.retrieveSubscriptionList.picture;
        this.isSubscriptionPortalEnabled =
          res.subscription.retrieveSubscriptionList.isSubscriptionPortalEnabled;
        this.subscriptionPortalAppUrl = this.isSubscriptionPortalEnabled
          ? res.subscription.retrieveSubscriptionList.subscriptionPortalAppUrl
          : '';
        this.subscriptionModuleList.forEach((element) => {
          if (element.externalName === 'My Admin') {
            this.myAdminUrl = element.appUrl;
          }
        });
      },
      (error) => {
        this.subscriptionModuleList = [];
        this.profileUrl = '';
      }
    );
  }

  redirectToModule(subscriptionModule, event: Event): void {
    if (subscriptionModule.isDisabled) {
      this.appUrl = '';
      event.preventDefault();
    } else {
      this.appUrl = subscriptionModule.appUrl;
    }
  }

  redirectToHome(): void {
    window.location.href = environment.stsServerUrl;
  }

  myAdminClick() {
    window.location.href = this.myAdminUrl;
  }

  onHelpClick(): void {
    window.open(this.helpDeskUrl, '_blank');
  }

  produdctSubscriptionClick(): void {
    if (this.isSubscriptionPortalEnabled)
      window.location.replace(this.subscriptionPortalAppUrl);
  }
}
