import { Component, OnInit, ViewChild } from '@angular/core';
import { OverviewModel } from '@app/core/Models';
import { OverviewState } from '@app/core/Store';
import { FlowChartOptions } from '@app/modules';
import { Select } from '@ngxs/store';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type LineChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-overview-line-chart',
  templateUrl: './overview-line-chart.component.html',
  styleUrls: ['./overview-line-chart.component.scss'],
})
export class OverviewLineChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  public lineChartOptions: Partial<FlowChartOptions>;
  reportList: OverviewModel;

  @Select(OverviewState.overviewData)
  overviewData$: Observable<OverviewModel>;

  ngOnInit(): void {
    this.overviewData$.subscribe((data) => {
      this.reportList = data;
      if (Object.keys(this.reportList).length > 0) {
        this.getLineChartData();
      }
    });
  }

  getLineChartData(): void {
    const tempSeries: any = [];
    const tempCategories: any = [];

    for (const barchartData of this.reportList.barChart['categories']) {
      tempCategories.push(barchartData);
    }

    for (const barchartData of this.reportList.barChart[`series`]) {
      tempSeries.push(barchartData.data);
    }

    this.lineChartOptions = {
      series: [
        {
          name: 'Net Profit',
          data: tempSeries,
        },
      ],
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      tooltip: {
        marker: {
          show: false,
        },
        enabled: true,
        fillSeriesColor: false,
      },
      xaxis: {
        categories: tempCategories,
      },
      fill: {
        opacity: 1,
        type: 'gradient',
        colors: ['#9966D4'],
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#F3ACC4'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
    };
  }
}
