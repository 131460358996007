import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[zeroNotAllowed]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ZeroNotAllowedDirective,
      multi: true,
    },
  ],
})
export class ZeroNotAllowedDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return /^(?=.*[1-9])\d+(?:\.*\d\d?)?$/.test(control.value)
      ? null
      : { invalid: true };
  }
}
