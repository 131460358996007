import { Guid } from 'guid-typescript';

export class BankReconciliationModel {
  id: Guid;
  transactionCategory: string;
  transactionType: string;
  description: string;
  amount: number;
  accountId: Guid;
  postingAccountId: Guid;
  reconciliationStatus: boolean;
  bankTransactionId: Guid;
  enableDropdown: boolean;
  isAdd: boolean;
  isSelected: boolean;
  receiptItems: Array<ReceiptItemModel>;
  accountValue: any;
}

export class ReceiptItemModel {
  id: Guid;
  companyId: Guid;
  invoiceId: Guid;
  invoiceTypeId: number;
  transactionId: Guid;
  transactionTypeId: number;
  transactionDate: Date;
  currencyId: number;
  accountId: Guid;
  postingAccountId: Guid;
  attachment: Array<string>;
  amount: number;
  note: string;
  isCredit: boolean;
  receiptId: Guid;
}
