<div class="mat-dialog-wrapper" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center header-text">
    <h2 mat-dialog-title class="m-0">Important Notice</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="content-body">
      <p>
        The current format and content of these notes are specifically tailored
        for the Charity Commission for England and Wales. If you are working
        with a different charity regulator, such as the Office of the Scottish
        Charity Regulator or the Charity Commission for Northern Ireland, please review and modify the notes as necessary to
        ensure compliance and relevance to the specific requirements and
        guidelines of the respective regulator. <br /><br />
        We appreciate your understanding and cooperation.
      </p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="m-0 d-flex gap-15 justify-content-end">
    <button
      class="mat-focus-indicator action-button primary-button mat-button mat-button-base"
      (click)="onCloseClick()"
    >
      Ok
    </button>
  </mat-dialog-actions>
</div>
