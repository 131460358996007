import { Injectable } from '@angular/core';
import { ReceiptModel, SideListModel } from '@app/core/Models';
import { ReceiptService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreReceipt,
  CreateReceipt,
  DeleteReceipt,
  GetDataByReceiptId,
  GetInvoiceBasedOnCustomer,
  GetInvoiceReceipt,
  GetBillBasedOnCustomer,
  GetBillBasedOnSupplier,
  GetBillDetail,
  GetBillReceipt,
  GetReceiptPayment,
  GetBankAccountListForBankEntryAndCashEntry,
  ArchiveAndRestoreCashEntry,
  ArchiveAndRestoreBankEntry,
  QuickReceiptAdd,
} from './receipt.action';

export class ReceiptStateInfo {
  receiptData?: ReceiptModel;
  invoiceByCustomer?: any[] = [];
  billByCustomer?: any[] = [];
  receipt: Array<ReceiptModel>;
  receiptId: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isReceiptAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  invoiceCustomerList?: any[] = [];
  invoiceReceiptData?: any;
  billReceiptData?: any;
  acctListForBankEntryAndCashEntry?: any;
  receiptPaymentList?: any[] = [];
  quickReceiptId: any;
  isQuickReceiptAdded?: boolean;
}

@State<ReceiptStateInfo>({
  name: 'receipt',
  defaults: {
    receipt: [],
    receiptId: Guid.EMPTY as unknown as Guid,
    quickReceiptId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isReceiptAdded: false,
    invoiceCustomerList: [],
  },
})
@Injectable()
export class ReceiptState {
  constructor(private receiptService: ReceiptService) {}

  @Selector()
  static isLastPage(state: ReceiptStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getReceiptId(state: ReceiptStateInfo) {
    return state.receiptId;
  }

  @Selector()
  static reciptPaymentList(state: ReceiptStateInfo) {
    return state.receiptPaymentList;
  }

  @Action(CreateReceipt)
  createInvoiceReceipt(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: CreateReceipt
  ) {
    return this.receiptService.createReceipt(action.receipt).pipe(
      tap((res) => {
        patchState({
          receiptId: res,
          isReceiptAdded: true,
        });
      })
    );
  }

  @Action(QuickReceiptAdd)
  quickReceiptAdd(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: QuickReceiptAdd
  ) {
    return this.receiptService.quickReceiptAdd(action.quickReceipt).pipe(
      tap((res) => {
        patchState({
          quickReceiptId: res,
          isQuickReceiptAdded: true,
        });
      })
    );
  }

  @Action(GetInvoiceReceipt)
  GetInvoiceReceipt(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: GetInvoiceReceipt
  ) {
    return this.receiptService.getInvoiceReceipt(action.invoiceId).pipe(
      tap((res) => {
        patchState({
          invoiceReceiptData: res,
        });
      })
    );
  }

  @Action(GetReceiptPayment)
  getReceiptPayment(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: GetReceiptPayment
  ) {
    return this.receiptService.getReceiptPayment(action.accountId).pipe(
      tap((res) => {
        patchState({
          receiptPaymentList: res,
        });
      })
    );
  }

  @Action(GetBillReceipt)
  getBillReceipt(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: GetBillReceipt
  ) {
    return this.receiptService.getBillReceipt(action.billId).pipe(
      tap((res) => {
        patchState({
          billReceiptData: res,
        });
      })
    );
  }

  @Action(GetBillBasedOnCustomer)
  GetBillBasedOnCustomer(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: GetBillBasedOnCustomer
  ) {
    return this.receiptService.getBillBasedOnCustomer(action.supplierId).pipe(
      tap((res) => {
        patchState({
          billByCustomer: res,
        });
      })
    );
  }

  @Action(GetInvoiceBasedOnCustomer)
  GetInvoiceBasedOnCustomer(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: GetInvoiceBasedOnCustomer
  ) {
    return this.receiptService
      .getInvoiceBasedOnCustomer(action.customerId)
      .pipe(
        tap((res) => {
          patchState({
            invoiceByCustomer: res,
          });
        })
      );
  }

  @Action(GetDataByReceiptId)
  getDataByReceiptId(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: GetDataByReceiptId
  ) {
    return this.receiptService
      .getDataByReceiptId(action.receiptId, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            receiptData: res,
          });
        })
      );
  }

  @Action(DeleteReceipt)
  deleteReceipt(
    { getState, patchState }: StateContext<ReceiptStateInfo>,
    action: DeleteReceipt
  ) {
    return this.receiptService
      .deleteReceipt(action.receiptIds, action.moduleId)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredQuatation = state.receipt.filter(
            (item) =>
              !action.receiptIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.receiptIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          patchState({
            ...state.receipt,
            receipt: filteredQuatation,
            sideListModel: filteredForSideList,
          });
        })
      );
  }

  @Action(ArchiveAndRestoreReceipt)
  archiveAndRestoreReceipt(
    { getState }: StateContext<ReceiptStateInfo>,
    action: ArchiveAndRestoreReceipt
  ) {
    return this.receiptService.archiveAndRestoreReceipt(
      action.receiptIds,
      action.isArchive
    );
  }

  @Action(ArchiveAndRestoreCashEntry)
  archiveAndRestoreCashEntry(
    { getState }: StateContext<ReceiptStateInfo>,
    action: ArchiveAndRestoreCashEntry
  ) {
    return this.receiptService.archiveAndRestoreCashEntry(
      action.cashEntryIds,
      action.isArchive
    );
  }

  @Action(ArchiveAndRestoreBankEntry)
  archiveAndRestoreBankEntry(
    { getState }: StateContext<ReceiptStateInfo>,
    action: ArchiveAndRestoreBankEntry
  ) {
    return this.receiptService.archiveAndRestoreBankEntry(
      action.bankEntryIds,
      action.isArchive
    );
  }

  @Action(GetBillBasedOnSupplier)
  GetBillBasedOnSupplier(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: GetBillBasedOnSupplier
  ) {
    return this.receiptService.getBillBasedOnSupplier(action.supplierId).pipe(
      tap((res) => {
        patchState({
          billByCustomer: res,
        });
      })
    );
  }

  @Action(GetBillDetail)
  getBillDetail(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: GetBillDetail
  ) {
    return this.receiptService.getBillDetail(action.billId).pipe(
      tap((res) => {
        patchState({
          billReceiptData: res,
        });
      })
    );
  }

  @Action(GetBankAccountListForBankEntryAndCashEntry)
  getBankAccountListForBankEntryAndCashEntry(
    { patchState }: StateContext<ReceiptStateInfo>,
    action: GetBankAccountListForBankEntryAndCashEntry
  ) {
    return this.receiptService
      .getBankAccountListForBankEntryAndCashEntry(action.entityId)
      .pipe(
        tap((res) => {
          patchState({
            acctListForBankEntryAndCashEntry: res,
          });
        })
      );
  }
}
