import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { MainListParameters } from '@app/core/Models';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class BankOverviewService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  trimObjectSpace(obj) {
    let data = JSON.parse(obj);

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    });

    return JSON.stringify(data);
  }

  getBankTransctions(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BankEntry/list`,
      this.commonService.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  deleteBankStatementTransaction(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.trimObjectSpace(JSON.stringify(ids)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}BankAccount/DeleteBankStatementTransaction`,
      options
    );
  }
}
