<div class="content client-list">
  <div class="content-body">
    <app-transactions-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-transactions-header>

    <div
      class="mat-table-wrapper"
      [ngClass]="[
        totalSelectedRecords > 0 ? 'mat-table-wrapper-full' : '',
        showPaginator ? '' : 'mat-table-wrapper-pagination'
      ]"
    >
      <table
        aria-describedby="main-list"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="dataSource"
      >
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
            <div class="d-flex align-items-center">
              <span mat-sort-header [disabled]="!col.name">
                {{ col.name }}
              </span>
            </div>
          </th>

          <td mat-cell *matCellDef="let element">
            <div
              class="d-flex align-items-center"
              [ngClass]="
                colIndex === (columns$ | async).length - 1
                  ? 'justify-space-between'
                  : ''
              "
            >
              <button
                mat-icon-button
                [style.visibility]="!element.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="element.level * 32"
                (click)="treeControl.toggle(element)"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    treeControl.isExpanded(element)
                      ? "expand_more"
                      : "chevron_right"
                  }}
                </mat-icon>
              </button>
              <div class="clamp">
                <span
                  *ngIf="
                    col.dataType === dataType.String ||
                    col.dataType === dataType.Bool
                  "
                  title="{{ element[colIndex] }}"
                >
                  {{ element[colIndex] }}</span
                >
                <span
                  *ngIf="col.dataType === dataType.DateTime"
                  title="{{ element[colIndex] }}"
                >
                  {{ element[colIndex] | date: "dd-LLL-yyyy" }}</span
                >

                <span
                  *ngIf="
                    col.dataType === dataType.Int32 ||
                    col.dataType === dataType.Currency
                  "
                  title="{{ element[colIndex] }}"
                >
                  £ {{ element[colIndex] | numberPipe }}</span
                >

                <span *ngIf="col.dataType === dataType.Blob">
                  <button
                    mat-button
                    (click)="onAddClick(element)"
                    class="fw-bold text-primary"
                    disableRipple
                  >
                    <mat-icon>add_circle_outline</mat-icon>
                  </button></span
                >
              </div>

              <div
                class="d-flex align-items-center grid-actions"
                *ngIf="colIndex === (columns$ | async).length - 1"
              >
                <div class="d-flex align-items-center">
                  <button
                    mat-button
                    disableRipple
                    *ngIf="hasEditPermission$ | async"
                    (click)="onEditClick(element[0])"
                  >
                    <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                  </button>
                  <button
                    mat-button
                    disableRipple
                    *ngIf="hasDeletePermission$ | async"
                    (click)="onDeleteClick(element[0])"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsNames$ | async"
          [ngClass]="isRowHighlighted ? 'highlightRow' : ''"
        ></tr>
      </table>
      <div *ngIf="!dataSource.data">No records found</div>
    </div>
  </div>
  <div
    class="paginator-wrapper"
    *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async) > 0"
  >
    <app-custom-paginator
      *ngIf="(totalRecord$ | async) > 10"
      [length]="totalRecord$ | async"
      [listParameters]="listParameters"
      (pageChanged)="pageChanged($event)"
      (pageSizeVal)="pageSizeVal($event)"
      (togglePaginator)="togglePaginator($event)"
    >
    </app-custom-paginator>
  </div>
</div>

<app-card-footer
  *ngIf="totalSelectedRecords > 0"
  [totalSelectedRecords]="totalSelectedRecords"
  (deleteClick)="deleteClick()"
  (exportClick)="export($event, false)"
  (copyClick)="copyClick()"
  (cancelSelectionClick)="cancelSelectionClick()"
  (printClick)="printClick()"
  (archiveAndRestoreClick)="archiveAndRestoreClick($event)"
  [ngClass]="this.listParameters.filter === 1 ? 'archive-records' : ''"
></app-card-footer>
<ngx-spinner></ngx-spinner>
