import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Modules } from '@app/core/Enum';
import { GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

export class YearDictionary {
  key: number;
  value: string;
}
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-custom-year-picker',
  templateUrl: './custom-year-picker.component.html',
  styleUrls: ['./custom-year-picker.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export default class CustomYearPickerComponent implements OnInit {
  selectedOption: any = '-1';
  selectedOptionId: any;
  currentYear: number = new Date().getFullYear();
  yearsListPair = this.globalComponent.getFinancialPeriod();
  rangeFormGroup: FormGroup;
  moduleEnum = Modules;
  isTrialBalanceImported = false;
  isAccountProductionTrialBalance = false;
  accountingPeriodId = Guid.EMPTY as unknown as Guid;
  @Input() moduleId: number;

  @Output()
  triggerDateChange = new EventEmitter<any>();
  @Input() triggerResetDetailList: Observable<any>;
  constructor(
    public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    public commonService: CommonService,
    private globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.triggerResetDetailList?.subscribe((data) => {
      if (data) {
        this.setForm();
        this.selectedOption = '1';
        this.onCustomDate();
      }
    });

    const defaultFinancialPeriod = localStorage.getItem(
      'defaultFinancialPeriod'
    );

    if (defaultFinancialPeriod) {
      const matchedYear = this.yearsListPair.find(
        (year) => year.name === defaultFinancialPeriod
      );

      if (matchedYear) {
        this.selectedOption = matchedYear;
        this.onDropdownChange({ value: matchedYear });
      } else if (this.isAllYearAllowed() && defaultFinancialPeriod === '-1') {
        const defaultDate = {
          event: {
            value: defaultFinancialPeriod,
          },
        };
        this.onDropdownChange(defaultDate);
      } else {
        this.setDefaultYearPickerValue();
      }
    } else {
      this.setDefaultYearPickerValue();
    }
    this.setForm();
  }

  isAllYearAllowed(): boolean {
    let isAllYearAllowed;

    switch (this.moduleId) {
      case Modules.TrialBalance:
      case Modules.EditTrialBalance:
      case Modules.EditAccountProductionTrialBalance:
      case Modules.StatementofFinancialActivities:
      case Modules.BalanceSheet:
      case Modules.IncomeAndExpenditure:
      case Modules.StatementOfAssetsAndLiabilities:
      case Modules.ReceiptAndPayment:
      case Modules.AccountProductionTrialBalance:
      case Modules.AccountProductionBalanceSheet:
      case Modules.AccountProductionStatementofFinancialActivities:
      case Modules.AccountProductionStatementOfAssetsAndLiabilities:
      case Modules.AccountProductionReceiptAndPayment:
      case Modules.VAT:
      case Modules.AccountDetails:
        isAllYearAllowed = false;
        break;
      default:
        isAllYearAllowed = true;
        break;
    }
    return isAllYearAllowed;
  }

  setDefaultYearPickerValue(): void {
    this.selectedOption = this.yearsListPair[0];
    const param: any = {
      value: this.yearsListPair[0],
    };
    this.onDropdownChange(param);
  }

  setForm(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  onCustomDate(): void {
    const sendingData = {
      startDate: this.rangeFormGroup.controls.start.value,
      endDate: this.rangeFormGroup.controls.end.value,
      isTrialBalanceImported: false,
      isAccountProductionTrialBalance: false,
    };

    if (sendingData.endDate !== null && sendingData.startDate !== null) {
      this.triggerDateChange.emit(sendingData);
    }
  }

  onDateChange(): void {
    if (this.selectedOption === '1') {
      return;
    }

    const sendingData = {
      accountingPeriodId: this.accountingPeriodId,
      startDate:
        this.selectedOption !== '-1'
          ? this.getStartDate(this.selectedOption)
          : '',

      endDate:
        this.selectedOption !== '-1'
          ? this.getEndDate(this.selectedOption)
          : '',
      isTrialBalanceImported: this.isTrialBalanceImported,
      isAccountProductionTrialBalance: this.isAccountProductionTrialBalance,
    };

    if (sendingData.endDate !== null && sendingData.startDate !== null) {
      this.triggerDateChange.emit(sendingData);
    }
    this.selectedOption = this.selectedOptionId;
  }

  onMinus(): void {
    this.selectedOption--;

    this.onDateChange();

    if (this.selectedOption === -1) {
      this.selectedOption = 1;
    }
  }

  getStartDate(selectedOption: any): any {
    if (selectedOption !== 1) {
      return this.datepipe.transform(
        new Date(this.selectedOption.split(' - ')[0]),
        'dd-MMM-yyyy'
      );
    } else {
      return this.rangeFormGroup.controls.start.value;
    }
  }

  getEndDate(selectedOption: any): any {
    if (selectedOption !== 1) {
      return this.datepipe.transform(
        new Date(this.selectedOption.split(' - ')[1]),
        'dd-MMM-yyyy'
      );
    } else {
      return this.rangeFormGroup.controls.end.value;
    }
  }

  onPlus(): void {
    this.selectedOption++;
    this.onDateChange();

    if (this.yearsListPair.length === this.selectedOption) {
      this.selectedOption = 0;
    }
  }

  onDropdownChange(event: any): void {
    if (event.value.id !== undefined && event.value.id !== null) {
      this.selectedOption = event.value.name;
      this.selectedOptionId = event.value;
      this.isTrialBalanceImported = event.value.isTrialBalanceImported;
      this.isAccountProductionTrialBalance =
        event.value.isAccountProductionTrialBalance;
      this.accountingPeriodId = event.value.id;
      localStorage.setItem('defaultFinancialPeriod', event.value.name);
    } else {
      this.selectedOption = event.value;
      this.selectedOptionId = event.value;
      this.isTrialBalanceImported = false;
      this.isAccountProductionTrialBalance = false;
      this.accountingPeriodId = Guid.EMPTY as unknown as Guid;
      localStorage.setItem('defaultFinancialPeriod', event.value);
    }

    this.onDateChange();
  }
}
