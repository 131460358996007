import { Injectable } from '@angular/core';
import { DonationGiftAidService, DonationService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  DeleteGIF,
  GetGiftAIDsettings,
  SaveGiftAIDsettings,
  SaveGiftAid,
  SubmitToHMRC,
} from './donation-gift-aid.action';
import { GiftAIDsettings, GiftAid } from '@app/core/Models';

export class DonationGiftAidStateInfo {
  giftAidData?: GiftAid;
  giftAIDSettingsData?: GiftAIDsettings;
  isSubmittedToHMRC?: boolean;
  isDeleted?: boolean;
}

@State<DonationGiftAidStateInfo>({
  name: 'giftAid',
  defaults: {},
})
@Injectable()
export class DonationGiftAidState {
  constructor(private donationGiftAidService: DonationGiftAidService) {}

  @Action(SaveGiftAid)
  saveGiftAid(
    { patchState }: StateContext<DonationGiftAidStateInfo>,
    action: SaveGiftAid
  ) {
    return this.donationGiftAidService.saveGiftAid(action.giftAidData).pipe(
      tap((res) => {
        patchState({
          giftAidData: res,
        });
      })
    );
  }

  @Action(SubmitToHMRC)
  submitToHMRC(
    { patchState }: StateContext<DonationGiftAidStateInfo>,
    action: SubmitToHMRC
  ) {
    return this.donationGiftAidService.submitToHMRC(action.giftAddId).pipe(
      tap((res) => {
        patchState({
          isSubmittedToHMRC: res,
        });
      })
    );
  }

  @Action(DeleteGIF)
  deleteGIF(
    { patchState }: StateContext<DonationGiftAidStateInfo>,
    action: DeleteGIF
  ) {
    return this.donationGiftAidService.deleteGIF(action.giftAddId).pipe(
      tap((res) => {
        patchState({
          isDeleted: res,
        });
      })
    );
  }

  @Action(SaveGiftAIDsettings)
  saveGiftAIDsettings(
    { patchState }: StateContext<DonationGiftAidStateInfo>,
    action: SaveGiftAIDsettings
  ) {
    return this.donationGiftAidService
      .saveGiftAIDsettings(action.giftAIDSettingsData)
      .pipe(
        tap((res) => {
          patchState({
            giftAIDSettingsData: res,
          });
        })
      );
  }

  @Action(GetGiftAIDsettings)
  getGiftAIDsettings(
    { patchState }: StateContext<DonationGiftAidStateInfo>,
    action: GetGiftAIDsettings
  ) {
    return this.donationGiftAidService.getGiftAIDsettings().pipe(
      tap((res) => {
        patchState({
          giftAIDSettingsData: res,
        });
      })
    );
  }
}
