import { Guid } from 'guid-typescript';

export class CompanyModel {
  logo: string;
  id?: Guid;
  name: string;
  charityRegulatorId: number;
  registrationNo: string;
  companyNo: string;
  principalPurpose: string;
  establishmentDate?: string;
  keepBillingDetailSeparate: boolean;
  billingDetail?: CompanyAddressDetailModel;
  addressDetail: CompanyAddressDetailModel;
  contactDetail: CompanyContactDetailModel;
  regionalDetail: CompanyRegionalDetailModel;
  accountingPeriods: Array<AccountingPeriodModel>;
  vatDetail?: CompanyVatDetailModel;
  accountingMethodId: number;
  tradingStatus: boolean;
  isCIO: boolean;
}

export class CompanyAddressDetailModel {
  address1: string;
  address2: string;
  address3?: string;
  city: string;
  state?: string;
  country: string;
  postalCode: string;
}

export class CompanyContactDetailModel {
  name: string;
  phone1: string;
  phone2: string;
  email: string;
  alternateEmail: string;
  website: string;
}

export class CompanyRegionalDetailModel {
  currencyId: number;
  languageId: number;
  timezoneId: number;
}

export class CompanyVatDetailModel {
  id?: number;
  vatRegistrationNo: string;
  vatRegistrationDate?: Date;
  vatSchemeId: number;
  vatReturnTypeId: number;
}

export class Company {
  id: Guid;
  companyName: string;
  isDefault: boolean;
  companyType: string;
  financialPeriod: string;
  vATNumber: string;
}

export class AccountingPeriodModel {
  id?: Guid;
  fromDate?: string;
  toDate?: string;
  isLocked?: boolean;
}
