import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modules, NotificationTextMessage } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { SaveUserCompanies, UserState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-assign-client',
  templateUrl: './assign-client.component.html',
  styleUrls: ['./assign-client.component.scss'],
})
export class AssignClientComponent {
  displayedUserClientColumns: string[] = [
    'no',
    'Name',
    'isAssigned',
    //'assignToAllClient',
  ];

  triggerAddClient: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerDataFromHeader: Subject<any> = new Subject<any>();
  showAddClient = false;
  triggerSaveClient: Subject<any> = new Subject<any>();
  triggerCancelClient: Subject<any> = new Subject<any>();
  moduleIdEnum = Modules;

  @Output()
  readonly triggerReloadList = new EventEmitter<any>();

  @Select(UserState.GetUserCompanyDetails)
  userClientsData$: Observable<Array<any>>;

  isAssignedToAllClient = false;

  @Input() taskId: Guid;
  @Input() userId: Guid;
  @Input() getModuleId: number;

  @Output()
  readonly toggleAssignClient = new EventEmitter<any>();
  id = Guid.EMPTY as unknown as Guid;
  constructor(
    private store: Store,
    public commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.id = this.userId;
  }

  onSaveClick() {
    this.spinner.show();
    let clientsDataList;
    let actionName;

    actionName = SaveUserCompanies;
    this.userClientsData$.subscribe((x) => {
      clientsDataList = x;
    });

    this.store
      .dispatch(new actionName(this.id, clientsDataList))
      .pipe()
      .subscribe(
        (res) => {
          if (res !== undefined) {
            this.commonService.onSucess(NotificationTextMessage.successMessage);
            this.onCloseClick();
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.spinner.hide();
          this.commonService.onFailure(err.message);
        }
      );
  }

  addClient() {
    const data = {
      isHideSideList: true,
    };
    this.triggerAddClient.next(data);
    this.showAddClient = true;
  }

  onBackLinkClick(): void {
    this.showAddClient = false;
    if (this.getModuleId === Modules.Users) {
      this.triggerReloadList.emit(this.userId);
    }
  }

  onSaveClient(): void {
    this.triggerSaveClient.next(false);
  }

  onCancelClient() {
    this.triggerCancelClient.next();
  }

  onCloseClick() {
    this.toggleAssignClient.emit();
  }

  onSearch(event: any): void {
    const data = {
      search: event.target.value,
      id: this.id,
      showAssignClients: true, //ViceVersa
    };

    this.triggerDataFromHeader.next(data);
  }
}
