import { PaymentDetailsModel, ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreditNoteModel {
  id: Guid;
  entryNumber: string;
  accountId: Guid;
  fundId: Guid;
  bookAccountId: Guid;
  entryDate?: string;
  note: string;
  voucherNumber: string;
  currencyId: number;
  attachment: Array<string>;
  baseAmount: number;
  totalAmount: number;
  creditNoteItems: Array<ProductDetailsModel>;
  invoiceAllocations: Array<PaymentDetailsModel>;
  receiptDetails: Array<any>;
  transactionLogId?: Guid;
  isManualBank?: boolean;
  isVatIncluded: boolean;
}
