<form [formGroup]="note2Form">
  <div class="notes-section">
    <table class="notes-table" aria-describedby="User Task Table">
      <tr>
        <th scope="col">Analysis</th>
        <th>
          <p>
            Unrestricted <br />
            funds
          </p>
        </th>
        <th scope="col">
          <p>
            Restricted <br />
            income funds
          </p>
        </th>
        <th scope="col">
          <p>
            Endowment <br />
            funds
          </p>
        </th>
        <th scope="col">
          <p>Total funds</p>
          <p>£</p>
        </th>
        <th scope="col">
          <p>Prior year</p>
          <p>£</p>
        </th>
      </tr>
      <ng-container *ngIf="calculativeData?.length === 0">
        <tr>
          <td colspan="6" class="text-align-center mt-1 fw-bold">
            <span>No records found</span>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="calculativeData?.length > 0">
        <tr *ngFor="let dl of calculativeData">
          <th class="text-align-right wp-20" scope="col">
            {{ dl.name }}
          </th>
          <td>{{ dl.unrestrictedFund | numberPipe }}</td>
          <td>{{ dl.restrictedIncomeFund | numberPipe }}</td>
          <td>{{ dl.endowmentFund | numberPipe }}</td>
          <td>{{ dl.totalFund | numberPipe }}</td>
          <td>{{ dl.priorYearFund | numberPipe }}</td>
        </tr>
      </ng-container>
    </table>
  </div>
  <div class="notes-wrapper">
    <div class="notes-section">
      <ejs-richtexteditor
        [toolbarSettings]="
          commonService.syncfusionRichTextEditorToolbarSettings
        "
        (actionBegin)="
          commonService.syncfusionRichTextEditorOnActionBegin($event)
        "
        [enableXhtml]="true"
        [toolbarSettings]="commonService.syncfusionRichTextEditorTools"
        formControlName="textbox1"
        [enableHtmlSanitizer]="true"
        [pasteCleanupSettings]="
          commonService.syncfusionRichTextEditorPasteCleanupSettings
        "
      ></ejs-richtexteditor>
    </div>
  </div>
</form>
