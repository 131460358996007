<div class="mb-4">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      {{ headerText }}
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
      <div class="d-flex gap-20 align-items-center mb-2">
        <div
          class="d-flex gap-20 align-items-center"
          [ngClass]="{
            hidden:
              this.moduleId !== moduleEnum.EditTrialBalance &&
              this.moduleId !== moduleEnum.EditAccountProductionTrialBalance
          }"
        >
          <p class="fw-bold m-0">
            Period: <span class="text-danger-300">*</span>
          </p>
          <app-custom-year-picker
            [moduleId]="moduleId"
            [triggerResetDetailList]="triggerResetDetailList"
            (triggerDateChange)="triggerDateChange($event)"
          ></app-custom-year-picker>
        </div>
        <div
          class="d-flex gap-20 align-items-center"
          [ngClass]="{
            hidden:
              this.moduleId === moduleEnum.EditTrialBalance ||
              this.moduleId === moduleEnum.EditAccountProductionTrialBalance
          }"
        >
          <p class="fw-bold m-0">
            Date: <span class="text-danger-300">*</span>
          </p>
          <mat-form-field appearance="standard" class="w-150">
            <input
              matInput
              [(ngModel)]="accountingDate"
              [matDatepicker]="accountingDatePicker"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="accountingDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #accountingDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="sidenav-table sidenav-table-input wp-100">
        <div class="mat-table-wrapper">
          <form [formGroup]="formOpeningBalance">
            <ng-container formArrayName="openingBalanceArray">
              <table
                aria-describedby="account-list"
                mat-table
                [dataSource]="tableDataSource"
              >
                <ng-container matColumnDef="srNo">
                  <th mat-header-cell *matHeaderCellDef class="w-50">Sr No.</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <div class="d-flex">
                      {{ i + 1 }}
                    </div>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="account">
                  <th mat-header-cell *matHeaderCellDef class="w-300">
                    Account Name
                    <span class="text-danger-300">*</span>
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                    class="widthemail"
                  >
                    <mat-form-field appearance="fill">
                      <input
                        type="text"
                        matInput
                        formControlName="account"
                        required
                        [matAutocomplete]="accountGroup"
                        (keyup)="onAccountSearch($event, i)"
                        (mousedown)="resetAccountList(element, i)"
                      />
                      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                      <mat-autocomplete
                        [displayWith]="getOptionText"
                        (opened)="scrollIntoView(element)"
                        #accountGroup="matAutocomplete"
                        (optionSelected)="accountSelect($event, element, i)"
                      >
                        <mat-optgroup
                          *ngFor="let group of accountGroupList[i]"
                          [label]="group.groupName"
                          title="{{ group.groupName }}"
                        >
                          <mat-option
                            *ngFor="let names of group.listModels"
                            [value]="names"
                            title="{{ names.name }}"
                            [class]="
                              element.value.account?.id === names.id
                                ? 'mat-selected'
                                : ''
                            "
                          >
                            {{ names.name }}
                          </mat-option>
                        </mat-optgroup>
                      </mat-autocomplete>
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="unrestrictedDebit">
                  <th mat-header-cell *matHeaderCellDef>Debit</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field floatLabel="never">
                      <input
                        allowDecimal
                        matInput
                        [value]="element.unrestrictedDebit"
                        autocomplete="off"
                        formControlName="unrestrictedDebit"
                        (keyup)="unrestrictedDebitChanges(i, $event)"
                        (keyup.backspace)="unrestrictedDebitChanges(i, $event)"
                        (change)="calAmount()"
                        maxlength="{{ maxLength.maxLength10 }}"
                      />
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    {{ totalUnrestrictedDebit | numberPipe }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="unrestrictedCredit">
                  <th mat-header-cell *matHeaderCellDef>Credit</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field>
                      <input
                        allowDecimal
                        matInput
                        [value]="element.unrestrictedCredit"
                        autocomplete="off"
                        formControlName="unrestrictedCredit"
                        (keyup)="unrestrictedCreditChanges(i, $event)"
                        (keyup.backspace)="unrestrictedCreditChanges(i, $event)"
                        (change)="calAmount()"
                        maxlength="{{ maxLength.maxLength10 }}"
                      />
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    {{ totalUnrestrictedCredit | numberPipe }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="restrictedDebit">
                  <th mat-header-cell *matHeaderCellDef>Debit</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field floatLabel="never">
                      <input
                        allowDecimal
                        matInput
                        [value]="element.restrictedDebit"
                        autocomplete="off"
                        formControlName="restrictedDebit"
                        (keyup)="restrictedDebitChanges(i, $event)"
                        (keyup.backspace)="restrictedDebitChanges(i, $event)"
                        (change)="calAmount()"
                        maxlength="{{ maxLength.maxLength10 }}"
                      />
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    {{ totalRestrictedDebit | numberPipe }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="restrictedCredit">
                  <th mat-header-cell *matHeaderCellDef>Credit</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field>
                      <input
                        allowDecimal
                        matInput
                        [value]="element.restrictedCredit"
                        autocomplete="off"
                        formControlName="restrictedCredit"
                        (keyup)="restrictedCreditChanges(i, $event)"
                        (keyup.backspace)="restrictedCreditChanges(i, $event)"
                        (change)="calAmount()"
                        maxlength="{{ maxLength.maxLength10 }}"
                      />
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    {{ totalRestrictedCredit | numberPipe }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="endowmentDebit">
                  <th mat-header-cell *matHeaderCellDef>Debit</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field floatLabel="never">
                      <input
                        allowDecimal
                        matInput
                        [value]="element.endowmentDebit"
                        autocomplete="off"
                        formControlName="endowmentDebit"
                        (keyup)="endowmentDebitChanges(i, $event)"
                        (keyup.backspace)="endowmentDebitChanges(i, $event)"
                        (change)="calAmount()"
                        maxlength="{{ maxLength.maxLength10 }}"
                      />
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    {{ totalEndowmentDebit | numberPipe }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="endowmentCredit">
                  <th mat-header-cell *matHeaderCellDef>Credit</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field>
                      <input
                        allowDecimal
                        matInput
                        [value]="element.endowmentCredit"
                        autocomplete="off"
                        formControlName="endowmentCredit"
                        (keyup)="endowmentCreditChanges(i, $event)"
                        (keyup.backspace)="endowmentCreditChanges(i, $event)"
                        (change)="calAmount()"
                        maxlength="{{ maxLength.maxLength10 }}"
                      />
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    {{ totalEndowmentCredit | numberPipe }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="closeButton">
                  <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                    class="text-align-center"
                  >
                    <div
                      class="d-flex align-items-center justify-content-end gap-10"
                    >
                      <button
                        *ngIf="
                          this.openingBalanceArray?.controls[i]?.get(
                            'isExplain'
                          )?.value ||
                          this.openingBalanceArray?.controls[i]?.get(
                            'isFundExplain'
                          )?.value
                        "
                        type="button"
                        mat-button
                        disableRipple
                        title="Explain"
                        (click)="onExplainContacts(i, element)"
                      >
                        <mat-icon>toc</mat-icon>
                      </button>

                      <button
                        class="close-btn"
                        type="button"
                        mat-button
                        disableRipple
                        title="Delete"
                        (click)="onDeleteOpeningBalance(i)"
                      >
                        <mat-icon>delete_outline</mat-icon>
                      </button>
                    </div>
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="header-row-first-group">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="2"></th>
                  <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="header-row-second-group">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [attr.colspan]="2"
                    class="text-align-center"
                  >
                    Unrestricted
                  </th>
                </ng-container>
                <ng-container matColumnDef="header-row-third-group">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [attr.colspan]="2"
                    class="text-align-center"
                  >
                    Restricted
                  </th>
                </ng-container>
                <ng-container matColumnDef="header-row-four-group">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [attr.colspan]="2"
                    class="text-align-center"
                  >
                    Endowment
                  </th>
                </ng-container>
                <ng-container matColumnDef="header-row-fifth-group">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"></th>
                </ng-container>
                <ng-container
                  *ngFor="let columnName of displayOpeningBalanceColumns2"
                  [matColumnDef]="columnName"
                >
                  <td
                    mat-footer-cell
                    *matFooterCellDef="let row; let i = index"
                  >
                    {{ countDifferenceOfDrCr(columnName) }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="[
                    'header-row-first-group',
                    'header-row-second-group',
                    'header-row-third-group',
                    'header-row-four-group',
                    'header-row-fifth-group'
                  ]"
                ></tr>
                <tr
                  mat-header-row
                  class="mat-header-second-row"
                  *matHeaderRowDef="displayOpeningBalanceColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayOpeningBalanceColumns"
                ></tr>
                <tr
                  mat-footer-row
                  *matFooterRowDef="displayOpeningBalanceColumns"
                ></tr>
                <tr
                  mat-footer-row
                  *matFooterRowDef="displayOpeningBalanceColumns2"
                ></tr>
              </table>
            </ng-container>
          </form>
        </div>
        <div class="mb-1 mt-1 d-flex justify-space-between">
          <div class="mt-1">
            <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
              >Add New Line</a
            >
            <a
              class="link mr-1"
              *ngIf="openingBalanceArray.length > 0"
              href="javascript:void(0)"
              (click)="clearForm()"
              >Clear All Lines</a
            >
          </div>
        </div>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="isUpdated($event.isExit)"
      (triggerOnCancelClick)="onCancel(true)"
    ></app-buttons>
  </div>
</div>
