<div class="content-body">
  <form [formGroup]="invoiceForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Invoice No. <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            maxlength="{{ maxLength.maxLength100 }}"
            formControlName="invoiceNo"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Invoice Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="invoiceDate"
            [matDatepicker]="invoiceDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            (dateChange)="dateChange()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="invoiceDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #invoiceDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(invoiceForm.get('invoiceDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(invoiceForm.get('invoiceDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p appAutoFocusDirective>
            Customer Name <span class="text-danger-300">*</span>
          </p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isCustomerChangePermission"
            >
              <mat-icon (click)="gotoAddCustomer()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>

            <button
              [disabled]="disabledEditButton || !isCustomerChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditCustomer()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #customerName
          [disableOptionCentering]="true"
          (focus)="customerName.open()"
          (valueChange)="customerName.close()"
          formControlName="customerName"
          panelClass="mat-select-position"
          required
          (change)="customerChange($event)"
        >
          <ng-option *ngFor="let option of customerList" [value]="option.id">
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div
        class="form-field datepicker"
        *ngIf="accountingMethodId !== accountingMethod.CashBasis"
      >
        <p>Due Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="dueDate"
            [matDatepicker]="dueDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dueDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dueDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(invoiceForm.get('dueDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(invoiceForm.get('dueDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field quick-add-buttons">
        <p>Account Name <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="invoiceType"
            required
            [matAutocomplete]="accountGroup"
            (keyup)="onAccountSearch($event)"
            (mousedown)="resetAccountList()"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            (opened)="scrollIntoView(invoiceForm.controls.invoiceType.value)"
            #accountGroup="matAutocomplete"
          >
            <mat-optgroup
              *ngFor="let group of accountGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.invoiceForm.controls.invoiceType.value.id === names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Fund Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isFundChangePermission"
            >
              <mat-icon (click)="gotoAddFund()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledFundEditButton || !isFundChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditFund()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #fundName
          (focus)="fundName.open()"
          (valueChange)="fundName.close()"
          [disableOptionCentering]="true"
          formControlName="fundName"
          panelClass="mat-select-position"
          (change)="fundChange($event)"
        >
          <ng-option *ngFor="let fn of fundNameList" [value]="fn.id">
            <div class="clamp" title="{{ fn.name }}">{{ fn.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Currency <span class="text-danger-300">*</span></p>
        <ng-select
          #selectCurrency
          [disableOptionCentering]="true"
          (focus)="selectCurrency.open()"
          (valueChange)="selectCurrency.close()"
          [disableOptionCentering]="true"
          formControlName="currency"
          panelClass="mat-select-position"
          required
        >
          <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
            <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
              {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
            </div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Activity</p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isActivityChangePermission"
            >
              <mat-icon (click)="gotoAddActivity()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>

            <button
              [disabled]="
                disabledActivityEditButton || !isActivityChangePermission
              "
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditActivity()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #activity
          (focus)="activity.open()"
          (valueChange)="activity.close()"
          [disableOptionCentering]="true"
          formControlName="activity"
          panelClass="mat-select-position"
          (change)="activityChange($event)"
        >
          <ng-option *ngFor="let cl of activityList" [value]="cl.id">
            <div class="clamp" title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Bank Details</p>
        </div>
        <ng-select
          #bank
          (focus)="bank.open()"
          (valueChange)="bank.close()"
          [disableOptionCentering]="true"
          formControlName="bankId"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let bl of bankList" [value]="bl.id">
            <div class="clamp" title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field m-0" *ngIf="isVatRegistered">
        <mat-checkbox
          formControlName="isVatInclude"
          (change)="onCheckBoxSelected($event)"
        >
          Amount Including VAT
        </mat-checkbox>
      </div>
    </div>
    <div class="form-field wp-100 textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          maxlength="{{ maxLength.maxLength2000 }}"
          formControlName="notes"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
