import { Guid } from 'guid-typescript';

export class ProductDetailsModel {
  id?: Guid;
  productId: Guid;
  productName: string;
  description: string;
  quantity: number;
  price: number;
  vatRateId?: Guid;
  vatRateName?: string;
  vatAmount?: number;
  totalAmount: number;
}
