import { DatePipe } from '@angular/common';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AccountingMethod,
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { FileImportRequestModel, ImportStep } from '@app/core/Enum/import';
import { GlobalComponent } from '@app/core/Models';
import {
  CommonService,
  HighlightRow,
  NotificationService,
} from '@app/core/Services';
import {
  GetFinancialPeriod,
  ImportModules,
  ImportState,
  MenuState,
  SaveImport,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { AddClosePopupComponent } from '../common';
import { ReportListComponent } from '../reports';
import { ImportFileDataComponent } from './import-file-data/import-file-data.component';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent {
  selectedModuleId: any = Modules.Invoices;
  moduleId = Modules.Import;
  panelHeaderTitle = ModuleName.Invoices;
  showImport: boolean = false;
  triggerImport: Subject<boolean> = new Subject<boolean>();
  triggerImportDetail: Subject<boolean> = new Subject<boolean>();
  triggerCancel: Subject<boolean> = new Subject<boolean>();
  selectCategory: any[] = [
    {
      moduleId: Modules.Invoices,
      isSelected: true,
      icon: 'sticky_note_2',
      name: ModuleName.Invoices,
    },
    {
      moduleId: Modules.Bills,
      isSelected: false,
      icon: 'receipt_long',
      name: ModuleName.Bills,
    },
    {
      moduleId: Modules.Donations,
      isSelected: false,
      icon: 'volunteer_activism',
      name: ModuleName.Donations,
    },
    {
      moduleId: Modules.CreditNote,
      isSelected: false,
      icon: 'post_add',
      name: ModuleName.CreditNote,
    },
    {
      moduleId: Modules.DebitNote,
      isSelected: false,
      icon: 'playlist_remove',
      name: ModuleName.DebitNote,
    },
    {
      moduleId: Modules.Customers,
      isSelected: false,
      icon: 'person_outline',
      name: ModuleName.Customers,
    },
    {
      moduleId: Modules.Suppliers,
      isSelected: false,
      icon: 'people',
      name: ModuleName.Suppliers,
    },
    {
      moduleId: Modules.Donors,
      isSelected: false,
      icon: 'people',
      name: ModuleName.Donors,
    },
    {
      moduleId: Modules.TrialBalance,
      isSelected: false,
      icon: 'balance',
      name: ModuleName.TrialBalance,
    },
    {
      moduleId: Modules.OpeningBalance,
      isSelected: false,
      icon: 'balance',
      name: ModuleName.OpeningBalance,
    },
    {
      moduleId: Modules.BankDashboard,
      isSelected: false,
      icon: 'account_balance',
      name: ModuleName.BankImport,
    },
  ];

  fileImportRequestModel: FileImportRequestModel;
  importDetailList: any[] = [];

  @ViewChild(ImportFileDataComponent, { static: true })
  importFileData;

  @ViewChild(ReportListComponent, { static: true })
  reportList;

  subscriptionRouting: Subscription;
  isDirectImport = false;
  isLocked = false;
  accountingMethodId: any;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  // isInvoiceChangePermission:boolean = true;
  isModuleChangePermission: boolean = true;
  constructor(
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private store: Store,
    private notifier: NotificationService,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
    private renderer: Renderer2,
    public datepipe: DatePipe,
    private router: Router,
    private globalComponent: GlobalComponent,
    private highlightRow: HighlightRow,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.accountingMethodId = this.globalComponent.getAccountingMethod();

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.spinner.hide();
          this.selectedModuleId = +atob(params.get('moduleId')!);
          this.radioChange(this.selectedModuleId);
          this.onNextClick();
        } else {
          this.isDirectImport = true;
        }
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isViewPermission$.subscribe((res) => {
        if (!res) {
          this.checkPermissionToImport(Modules.Income, Modules.Invoices);
        }
      });
    }, 500);
  }

  checkPermissionToImport(mainModule, subModule): void {
    this.isModuleChangePermission = this.commonService.checkPermission(
      mainModule,
      subModule
    );
  }

  radioChange(event: any): void {
    this.selectedModuleId = event;
    switch (this.selectedModuleId) {
      case Modules.Invoices:
        this.panelHeaderTitle = ModuleName.Invoices;
        this.checkPermissionToImport(Modules.Income, Modules.Invoices);
        break;
      case Modules.Bills:
        this.panelHeaderTitle = ModuleName.Bills;
        this.checkPermissionToImport(Modules.Expenditure, Modules.Bills);
        break;
      case Modules.Donations:
        this.panelHeaderTitle = ModuleName.Donations;
        this.checkPermissionToImport(Modules.Donation, Modules.Donations);
        break;
      case Modules.CreditNote:
        this.panelHeaderTitle = ModuleName.CreditNote;
        this.checkPermissionToImport(Modules.Income, Modules.CreditNote);
        break;
      case Modules.DebitNote:
        this.panelHeaderTitle = ModuleName.DebitNote;
        this.checkPermissionToImport(Modules.Expenditure, Modules.DebitNote);
        break;
      case Modules.Customers:
        this.panelHeaderTitle = ModuleName.Customers;
        this.checkPermissionToImport(Modules.Contacts, Modules.Customers);
        break;
      case Modules.Suppliers:
        this.panelHeaderTitle = ModuleName.Suppliers;
        this.checkPermissionToImport(Modules.Contacts, Modules.Suppliers);
        break;
      case Modules.TrialBalance:
      case Modules.AccountProductionTrialBalance:
        this.panelHeaderTitle = ModuleName.TrialBalance;
        this.checkPermissionToImport(
          Modules.AccountProduction,
          Modules.AccountProductionTrialBalance
        );
        break;
      case Modules.BankDashboard:
        this.panelHeaderTitle = ModuleName.Bank;
        this.checkPermissionToImport(Modules.Bank, Modules.BankDashboard);
        break;
      case Modules.OpeningBalance:
        this.panelHeaderTitle = ModuleName.OpeningBalance;
        this.checkPermissionToImport(Modules.Manage, Modules.OpeningBalance);
        break;
      case Modules.Donors:
        this.panelHeaderTitle = ModuleName.Donors;
        this.checkPermissionToImport(Modules.Contacts, Modules.Donors);
        break;
      default:
        break;
    }
  }

  onIgnoreClick(): void {
    this.dialog
      .open(AddClosePopupComponent, {
        data: {
          moduleId: this.moduleId,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.saveImport();
        }
      });
  }

  onCloseImportClick(): void {
    if (this.isDirectImport) {
      window.location.reload();
    } else {
      history.back();
    }
  }

  saveImport(): void {
    if (this.importFileData.importForm.invalid) {
      this.commonService.addValidation(
        this.importFileData.importForm,
        this.renderer
      );
    } else if (
      (this.selectedModuleId === Modules.OpeningBalance ||
        this.selectedModuleId === Modules.TrialBalance) &&
      this.importFileData.importSuccessList.data.length > 0 &&
      this.importFileData.importSuccessList.data.some(
        (x) => x.accountId === undefined || x.accountId === null
      )
    ) {
      this.commonService.onFailure(NotificationTextMessage.accountErrorMessage);
    } else {
      const succesList: any[] = [];
      this.importFileData.importSuccessList.data.forEach((element) => {
        if (
          this.selectedModuleId !== Modules.Customers &&
          this.selectedModuleId !== Modules.Suppliers &&
          this.selectedModuleId !== Modules.Donors &&
          this.selectedModuleId !== Modules.TrialBalance &&
          this.selectedModuleId !== Modules.OpeningBalance &&
          this.importFileData.importForm.controls.accountObj.value !== null &&
          this.importFileData.importForm.controls.accountObj.value !==
            undefined &&
          this.importFileData.importForm.controls.fundObj.value !== null &&
          this.importFileData.importForm.controls.fundObj.value !== undefined
        ) {
          element.bookAccountId =
            this.importFileData.importForm.controls.accountObj.value.id;
          element.fundId =
            this.importFileData.importForm.controls.fundObj.value.id;

          if (this.accountingMethodId === AccountingMethod.CashBasis) {
            if (
              this.importFileData.importForm.controls.depositAccountObj
                .value !== null &&
              this.importFileData.importForm.controls.depositAccountObj
                .value !== undefined
            ) {
              element.ReceiptPostingAccountId =
                this.importFileData.importForm.controls.depositAccountObj.value.id;
              succesList.push(element);
            }
          } else {
            succesList.push(element);
          }
        } else if (
          this.selectedModuleId !== Modules.Customers &&
          this.selectedModuleId !== Modules.Suppliers &&
          this.selectedModuleId !== Modules.Donors &&
          this.selectedModuleId !== Modules.TrialBalance &&
          this.selectedModuleId !== Modules.OpeningBalance &&
          this.selectedModuleId !== Modules.AccountProductionTrialBalance &&
          this.importFileData.importForm.controls.incomeAccountObj.value !==
            null &&
          this.importFileData.importForm.controls.incomeAccountObj.value !==
            undefined &&
          this.importFileData.importForm.controls.depositAccountObj.value !==
            null &&
          this.importFileData.importForm.controls.depositAccountObj.value !==
            undefined &&
          this.importFileData.importForm.controls.fundObj.value !== null &&
          this.importFileData.importForm.controls.fundObj.value !== undefined
        ) {
          element.incomeAccountId =
            this.importFileData.importForm.controls.incomeAccountObj.value.id;
          element.depositedToId =
            this.importFileData.importForm.controls.depositAccountObj.value.id;
          element.fundId =
            this.importFileData.importForm.controls.fundObj.value.id;
          succesList.push(element);
        } else if (
          this.selectedModuleId === Modules.Customers ||
          this.selectedModuleId === Modules.Suppliers ||
          this.selectedModuleId === Modules.Donors
        ) {
          succesList.push(element);
        } else if (
          (this.selectedModuleId === Modules.TrialBalance ||
            this.selectedModuleId === Modules.AccountProductionTrialBalance) &&
          element.accountId !== null &&
          element.accountId !== undefined
        ) {
          this.importFileData.periodicDateList.forEach((element) => {
            if (
              element.id ===
              this.importFileData.importForm.controls.accountingPeriod.value
            ) {
              this.isLocked = element.isLocked;
            }
          });
          if (!this.isLocked) {
            element.accountingPeriodId =
              this.importFileData.importForm.controls.accountingPeriod.value;
            element.accountId = element.accountId;
            succesList.push(element);
          }
        } else if (
          this.selectedModuleId === Modules.OpeningBalance &&
          element.accountId !== null &&
          element.accountId !== undefined
        ) {
          element.date = this.datepipe
            .transform(
              this.importFileData.importForm.controls.accountingDate.value,
              'yyyy-MM-dd'
            )
            ?.toString();
          element.accountId = element.accountId;
          succesList.push(element);
        }
      });
      if (succesList.length > 0) {
        const param = {
          successList: succesList,
          duplicateList:
            this.importFileData?.importDuplicateList.data.length > 0
              ? this.importFileData.importDuplicateList.data
              : [],
          errorList:
            this.importFileData.importErrorList.data.length > 0
              ? this.importFileData.importErrorList.data
              : [],
        };
        this.spinner.show();
        this.store
          .dispatch(new SaveImport(param, this.selectedModuleId))
          .subscribe((res) => {
            if (res) {
              this.spinner.hide();
              this.showImport = true;
              this.getFinancialPeriod();
              this.triggerImportDetail.next(res.import.saveImportData);
            }
          });
      } else if (this.isLocked) {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.lockedAccoutingPeriodDateMessage
        );
      } else {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.succesList
        );
      }
    }
  }

  getFinancialPeriod(): void {
    this.highlightRow.financialData = [];
    this.store
      .dispatch(new GetFinancialPeriod())
      .pipe(
        tap((res) => {
          this.highlightRow.financialData = res.common.periodicDate;
          this.commonService.setLocalStorage(
            'financialPeriod',
            JSON.stringify(res.common.periodicDate)
          );
        })
      )
      .subscribe();
  }

  onSaveNextClick(): void {
    this.saveImport();
  }

  onNextClick() {
    if (this.selectedModuleId === Modules.BankDashboard) {
      this.router.navigate([RoutingPath.BankImport]);
    } else {
      this.showImport = true;
      this.triggerImport.next(true);
    }
  }

  onCancelClick() {
    if (this.isDirectImport) {
      this.showImport = false;
      this.importDetailList = [];
      this.reportList.getList();
      this.importFileData.showAccountName = false;
      this.importFileData.showIncomeAccount = false;
      this.importFileData.showDepositAccount = false;
      this.importFileData.showFundName = false;
      this.importFileData.importForm.reset();
      this.importFileData.selectedFile = '';
      this.importFileData.url = '';
      this.importFileData.fileInfo = '';
      this.importFileData.selectedFileName = '';
      this.importFileData.step = ImportStep.Select;
      this.importFileData.myStepper.selectedIndex = 0;
      this.importFileData.importErrorList.data = new MatTableDataSource([]);
      this.importFileData.importDuplicateList.data = new MatTableDataSource([]);
      this.importFileData.importSuccessList.data = new MatTableDataSource([]);
    } else {
      history.back();
    }
  }

  onPrevClick() {
    this.importFileData.step = ImportStep.Upload;
    this.importFileData.myStepper.selectedIndex = 0;
    this.importFileData.totalUnrestrictedCredit = 0;
    this.importFileData.totalUnrestrictedDebit = 0;
    this.importFileData.totalRestrictedCredit = 0;
    this.importFileData.totalRestrictedDebit = 0;
    this.importFileData.totalEndowmentCredit = 0;
    this.importFileData.totalEndowmentDebit = 0;
    this.importFileData.sumOfTotalCrDrExplain = 0;
    this.importFileData.unrestrictedTotalDifference = 0;
    this.importFileData.restrictedTotalDifference = 0;
    this.importFileData.endowmenTotalDifference = 0;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onCancel(isCancelClick: boolean): void {
    location.reload();
  }

  onSave(isExit: boolean): void {
    this.fileImportRequestModel = {
      file: this.importFileData.selectedFile,
      step: this.importFileData.step,
    };

    this.onImport();
  }

  onImport(): void {
    this.spinner.show();
    this.store
      .dispatch(
        new ImportModules(this.fileImportRequestModel, this.selectedModuleId)
      )
      .subscribe(() => {
        const details = this.store.selectSnapshot(ImportState.getImportData);
        this.spinner.hide();
        if (details) {
          this.onSuccess();
          this.resetForm(details);
        } else {
          this.onError();
        }
      });
  }

  resetForm(details): void {
    this.importDetailList = details;
    this.reportList.getList();
    this.importFileData.fileInfo = '';
    this.importFileData.selectedFileName = '';
    this.importFileData.selectedFile = '';
    this.importFileData.url = '';
  }

  onError(): void {
    this.notifier.error(
      NotificationHeader.error,
      NotificationTextMessage.errorMessage
    );
  }

  onSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.fileUploaded
    );
  }
}
