export enum TransactionStatus {
  Receipt = 1,
  Payment = 0,
}

export enum TransactionType {
  Bank = 1,
  Cash = 2,
  JV = 3,
}
