import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { Currency, GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  GetCurrencyList,
  GetCustomBankAndCashAccount,
  GetDueAmountByBillId,
  GetDueAmountByInvoiceId,
  QuickReceiptAdd,
} from '@app/core/Store';
import { TransactionsAttachmentComponent } from '@app/modules/transactions';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-receipt-payment-popup',
  templateUrl: './add-receipt-payment-popup.html',
  styleUrls: ['./add-receipt-payment-popup.scss'],
})
export class AddReceiptPaymentPopupComponent implements OnInit {
  form: FormGroup;
  paymentAccountList: any[] = [];
  headerText = '';
  currencyList: Currency[];
  defaultCurrency: Guid;
  maxLength = MaxLength;
  quickReceiptData: any;
  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddReceiptPaymentPopupComponent>,
    public commonService: CommonService,
    private store: Store,
    private renderer: Renderer2,
    private globalComponent: GlobalComponent,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe
  ) {
    this.headerText = this.data.isReceipt ? 'Add Receipt' : 'Add Payment';
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
  }

  ngOnInit(): void {
    this.setForm();
    this.getAccountList();
  }

  getAccountList(): void {
    this.store.dispatch(new GetCustomBankAndCashAccount()).subscribe((res) => {
      this.paymentAccountList = res.common.customBankAndCashAccount;
    });
  }

  setForm(): void {
    this.form = new FormGroup({
      dueAmount: new FormControl({ value: 0, disabled: true }),
      receiptDate: new FormControl(new Date(), Validators.required),
      receiptNo: new FormControl(''),
      accountId: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      reference: new FormControl(''),
      currency: new FormControl({ value: '', disabled: true }),
    });

    this.getDueAmountById();
    this.getCurrency();
  }

  getCurrency(): void {
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.currencyList.length > 0) {
            this.form.controls.currency.setValue(this.defaultCurrency);
          }
        })
      )
      .subscribe();
  }

  getDueAmountById(): void {
    if (this.data.isReceipt) {
      this.store
        .dispatch(new GetDueAmountByInvoiceId(this.data.id))
        .subscribe((res) => {
          this.setDueAmount(res.invoice.dueAmount);
        });
    } else {
      this.store
        .dispatch(new GetDueAmountByBillId(this.data.id))
        .subscribe((res) => {
          this.setDueAmount(res.bill.dueAmount);
        });
    }
  }

  setDueAmount(data): void {
    this.form.controls.dueAmount.setValue(+data);
    this.form.controls.amount.clearValidators();
    this.form.controls.amount.setValidators([
      Validators.required,
      Validators.max(+data),
    ]);
    this.form.controls.amount.updateValueAndValidity();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const data = /^(?=.*[1-9])\d+(?:\.*\d\d?)?$/.test(control.value)
      ? null
      : { invalid: true };

    if (data?.invalid) {
      control.setValue(null);
      control.markAsTouched();
    }

    if (control.value > this.form.controls.dueAmount.value) {
      control.setErrors({ amountExceeds: true });
    }

    return data;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.quickReceiptData = {
        code: this.form.controls.receiptNo.value,
        receiptDate: this.datepipe
          .transform(this.form.controls.receiptDate.value, 'yyyy-MM-dd')
          ?.toString(),
        invoiceId: this.data.id,
        postingAccountId: this.form.controls.accountId.value,
        currencyId: this.form.controls.currency.value,
        totalAmount: +this.form.controls.amount.value,
        note: this.form.controls.reference.value,
        isCredit: this.data.isReceipt ? true : false,
        attachment: filedata,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onYesClick(): void {
    if (this.form.invalid) {
      this.commonService.addValidation(this.form, this.renderer);
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.quickReceiptData) {
          this.store
            .dispatch(new QuickReceiptAdd(this.quickReceiptData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.commonService.onSucess(
                    NotificationTextMessage.successMessage
                  );
                  this.dialogRef.close(true);
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
