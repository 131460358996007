import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPath } from '@app/core/Enum/routing-path';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  showPage: boolean;
  constructor(private spinner: NgxSpinnerService, private router: Router) {}

  ngOnInit(): void {
    if (this.router.url !== '/https:') {
      this.showPage = true;
    }

    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }

  onButtonClick(): void {
    this.router.navigate([RoutingPath.Dashboard]);
  }
}
