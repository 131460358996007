import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import {
  AccountEntity,
  AccountingMethod,
  ConfirmationType,
  GroupNames,
  ImportDateName,
  MaxLength,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { FileImportRequestModel, ImportStep } from '@app/core/Enum/import';
import { IncomeTypeName } from '@app/core/Enum/income-types-name';
import { PurchaseTypeName } from '@app/core/Enum/purchase-type-name';
import {
  ChartOfAccountListParam,
  Country,
  Currency,
  GlobalComponent,
  GroupListModel,
  SideListModel,
  VatRateScheme,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  AccountState,
  CheckCustomerSupplierDuplicate,
  CheckDonorDuplicate,
  CheckEntryNumber,
  CommonState,
  CountryState,
  CreateAccount,
  CreateContact,
  CreateCustomAccount,
  CreateDonor,
  CreateFund,
  DonationState,
  DonorState,
  GetActivityList,
  GetAllDonor,
  GetCountryList,
  GetCustomBankAndCashAccount,
  GetDataByFundId,
  GetFundNameList,
  GetGroupAccountsBasedOnGroupId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetImportTemplate,
  GetJournalAccounts,
  GetNonStandardAccountList,
  GetSponsoreList,
  GetVatRateList,
  ImportModules,
  ImportState,
} from '@app/core/Store';
import {
  ConfirmationBoxComponent,
  QuickAddComponent,
} from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-import-file-data',
  templateUrl: './import-file-data.component.html',
  styleUrls: ['./import-file-data.component.scss'],
})
export class ImportFileDataComponent implements OnInit, OnDestroy {
  selectedFileName: string;
  fileInfo: string = '';
  selectedFile: File;
  step: ImportStep;
  url: any;
  accountObj: any = null;
  incomeAccountObj: any = null;
  fundObj: any = null;
  depositAccountObj: any = null;
  showAccountName = false;
  showIncomeAccount = false;
  showDepositAccount = false;
  showFundName = false;
  startDate = new Date();
  endDate = new Date();
  totalUnrestrictedCredit = 0;
  totalUnrestrictedDebit = 0;
  totalRestrictedCredit = 0;
  totalRestrictedDebit = 0;
  totalEndowmentCredit = 0;
  totalEndowmentDebit = 0;
  sumOfTotalCrDrExplain = 0;
  unrestrictedTotalDifference: any = 0;
  restrictedTotalDifference: any = 0;
  endowmenTotalDifference: any = 0;
  selectedValue: Guid;
  @Input() importDetailList: any;
  @Input() panelHeaderTitle: string;
  @Input() selectedModuleId: number;
  @Input()
  triggerImport: Observable<any>;
  @Input()
  triggerImportDetail: Observable<any>;
  @Input()
  triggerCancel: Observable<any>;

  currencyList: Currency[];
  customerList: SideListModel[];
  supplierList: SideListModel[];
  supplierListImport: SideListModel[];
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];
  countryList: Country[];
  fundNameList: any;
  donorList: any;
  periodicDateList: any;
  sponsorEventList: SideListModel[];
  activityList: SideListModel[];
  depositToList: SideListModel[];
  maxLength = MaxLength;

  uploadFormGroup = this._formBuilder.group({
    isDuplicate: new FormControl(false),
  });

  importSubscription: Subscription;
  cancelSubscription: Subscription;
  fileImportRequestModel: FileImportRequestModel;

  @ViewChild('stepper') private myStepper: MatStepper;

  displayedColumns: any[] = [];
  displayedColumns2: string[] = [];

  importSuccessList: any = new MatTableDataSource();
  tempImportSuccessList: any = new MatTableDataSource();
  importErrorList: any = new MatTableDataSource();
  importDuplicateList: any = new MatTableDataSource();
  vatRateList: VatRateScheme[];
  finalResponse: any;
  importForm: FormGroup;
  modules = Modules;
  donationMaxDate = new Date();
  @Output()
  readonly triggerQuickAdd = new EventEmitter<any>();

  notificationMessage = NotificationTextMessage;
  importDateName = ImportDateName;
  periodicDate: any;
  accountingMethodId: any;

  constructor(
    private notifier: NotificationService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private _formBuilder: FormBuilder,
    public commonService: CommonService,
    private renderer: Renderer2,
    public dialog: MatDialog,
    private globalComponent: GlobalComponent,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setPeriodDate();
    this.accountingMethodId = this.globalComponent.getAccountingMethod();

    this.step = ImportStep.Select;

    this.triggerImportDetail.subscribe((res) => {
      this.finalResponse = res;
      this.myStepper.next();
    });

    this.importSubscription = this.triggerImport.subscribe(() => {
      if (this.step !== ImportStep.Select) {
        this.checkValidFile();
      }
    });
    this.cancelSubscription = this.triggerCancel?.subscribe(() => {
      this.step = ImportStep.Select;
    });
  }

  setForm(): void {
    this.importForm = new FormGroup({
      accountObj: new FormControl(),
      incomeAccountObj: new FormControl(),
      depositAccountObj: new FormControl(),
      fundObj: new FormControl(),
      accountingPeriod: new FormControl(),
      accountingDate: new FormControl(new Date()),
    });
    this.setPeriodDate();
  }

  checkCustomerValidElement(element, index: number): void {
    if (
      element.countryId !== this.commonService.defaultGuidValue &&
      element.countryId !== 0
    ) {
      element.countryError = false;
    }
    if (element.contactName !== null && element.contactName !== undefined) {
      element.contactNameError = false;
    }

    if (!element.emailError) {
      this.commonService.isEmailValid = true;
    }

    if (
      element.email !== null &&
      element.email !== undefined &&
      element.email !== '' &&
      !Validators.email(new FormControl(element.email))
    ) {
      if (this.commonService.isEmailValid) {
        element.emailError = false;
      } else {
        element.emailError = true;
      }
    }
    if (
      !element.countryError &&
      !element.contactNameError &&
      !element.emailError &&
      !element.phoneError
    ) {
      this.importSuccessList.data.push(element);
      this.importSuccessList.data = [...this.importSuccessList.data];
      this.importSuccessList = new MatTableDataSource(
        this.importSuccessList.data
      );

      this.importErrorList.data.splice(index, 1);
      this.importErrorList.data = [...this.importErrorList.data];
      this.importErrorList = new MatTableDataSource(this.importErrorList.data);

      this.notifier.success(
        NotificationHeader.success,
        NotificationTextMessage.moveToSuccessList
      );
    }
  }

  checkDonorValidElement(element, index: number): void {
    if (
      element.countryId !== this.commonService.defaultGuidValue &&
      element.countryId !== 0
    ) {
      element.countryError = false;
    }
    if (element.firstName !== null && element.firstName !== undefined) {
      element.donorNameError = false;
    }

    if (!element.emailError) {
      this.commonService.isEmailValid = true;
    }

    if (
      element.email !== null &&
      element.email !== undefined &&
      element.email !== '' &&
      !Validators.email(new FormControl(element.email))
    ) {
      if (this.commonService.isEmailValid) {
        element.emailError = false;
      } else {
        element.emailError = true;
      }
    }

    if (element.duplicateDonorError) {
      this.importDuplicateList.data.push(element);
      this.importDuplicateList.data = [...this.importDuplicateList.data];
      this.importDuplicateList = new MatTableDataSource(
        this.importDuplicateList.data
      );

      this.importErrorList.data.splice(index, 1);
      this.importErrorList.data = [...this.importErrorList.data];
      this.importErrorList = new MatTableDataSource(this.importErrorList.data);
    }
    if (
      !element.countryError &&
      !element.donorNameError &&
      !element.emailError &&
      !element.duplicateDonorError &&
      !element.phoneError
    ) {
      this.importSuccessList.data.push(element);
      this.importSuccessList.data = [...this.importSuccessList.data];
      this.importSuccessList = new MatTableDataSource(
        this.importSuccessList.data
      );

      this.importErrorList.data.splice(index, 1);
      this.importErrorList.data = [...this.importErrorList.data];
      this.importErrorList = new MatTableDataSource(this.importErrorList.data);

      this.notifier.success(
        NotificationHeader.success,
        NotificationTextMessage.moveToSuccessList
      );
    }
  }

  checkErrorProductName(element, index: number): void {
    if (
      element.productName !== '' &&
      element.productName !== null &&
      element.productName !== undefined
    ) {
      element.productNameError = false;
      this.checkValidElement(element, index);
    }
  }

  checkErrorProductPrice(element, index: number): void {
    if (
      element.price !== null &&
      element.price !== undefined &&
      element.price > 0
    ) {
      element.priceError = false;
      this.checkValidElement(element, index);
    }
  }

  checkErrorPhoneNumber(element, index: number): void {
    const pattern = /^\+?[0-9]*$/;
    if (
      element.phone !== null &&
      element.phone !== undefined &&
      element.phone.length > 0 &&
      element.phone.length <= 10 &&
      pattern.test(element.phone)
    ) {
      element.phoneError = false;
      this.checkValidElement(element, index);
    }
  }

  checkErrorEntryNumber(element, index: number): void {
    if (element.entryNumber !== '') {
      this.store
        .dispatch(
          new CheckEntryNumber(element.entryNumber, this.selectedModuleId)
        )
        .subscribe((res) => {
          if (!res.common.isValidEntryNo) {
            element.entryNumberError = false;
            this.checkValidElement(element, index);

            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.invoiceNoValidateSuccessfully
            );
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.invoiceNoIsExist
            );
          }
        });
    }
  }

  checkValidErrorDate(
    element: any,
    index: number,
    isFromErrorList: boolean,
    selectedDate: any,
    importDateId: number
  ): void {
    const date: any = this.datepipe
      .transform(selectedDate, 'yyyy-MM-dd')
      ?.toString();

    const isDateError = this.commonService.selectedDateValidator(
      this.periodicDate,
      date
    );

    if (isDateError === null) {
      switch (importDateId) {
        case ImportDateName.invoiceDate:
          element.entryDateError = false;
          break;
        case ImportDateName.dueDate:
          element.dueDateError = false;
          break;
        case ImportDateName.billDate:
          element.entryDateError = false;
          break;
        case ImportDateName.creditNoteDate:
          element.entryDateError = false;
          break;
        case ImportDateName.debitNoteDate:
          element.entryDateError = false;
          break;
        case ImportDateName.fixedAssetDate:
          element.entryDateError = false;
          break;
        case ImportDateName.donationDate:
          element.donationDateError = false;
          break;
      }

      if (isFromErrorList) {
        this.checkValidElement(element, index);
      } else {
        this.moveDuplicatetoSuccessList(element, index);
      }
    } else if (isDateError.invalidFinancialYear) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.validPeriodDateMessage
      );
    } else if (isDateError.lockedFinancialYear) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.lockedAccoutingPeriodDateMessage
      );
    }
  }

  checkValidElement(element, index: number, isFromSucessList?: boolean): void {
    if (element.accountId !== this.commonService.defaultGuidValue) {
      element.accountError = false;
    }

    if (element.donorId !== this.commonService.defaultGuidValue) {
      element.donorError = false;
    }

    if (
      element.vatRateId !== null &&
      element.vatRateId !== undefined &&
      element.vatRateId !== this.commonService.defaultGuidValue
    ) {
      element.vatRateError = false;
      element.vatAmount =
        (+element.price *
          (+element.quantity === 0 ? 1 : +element.quantity) *
          this.vatRateList.find((vatRate) => vatRate.id === element.vatRateId)
            ?.rate) /
        100;

      element.totalAmount =
        (+element.quantity === 0 ? 1 : +element.quantity) * +element.price +
        +element.vatAmount;
    }

    let amountError: boolean = false;
    if (element.allAmountError === true) {
      if (
        !element.endowmentError ||
        !element.restrictedError ||
        !element.unrestrictedError
      ) {
        amountError = false;
      } else {
        amountError = true;
      }
    } else if (element.allAmountError === false) {
      if (
        !element.endowmentError &&
        !element.restrictedError &&
        !element.unrestrictedError
      ) {
        amountError = false;
      } else {
        amountError = true;
      }
    }

    if (
      !element.accountError &&
      !element.entryNumberError &&
      !element.vatRateError &&
      !element.productNameError &&
      !element.donorError &&
      !element.entryDateError &&
      !element.dueDateError &&
      !element.donationDateError &&
      !element.donorNameError &&
      !amountError &&
      !element.priceError &&
      !element.phoneError &&
      !element.emailError &&
      !element.countryError
    ) {
      if (
        this.selectedModuleId === Modules.TrialBalance ||
        this.selectedModuleId === Modules.AccountProductionTrialBalance ||
        this.selectedModuleId === Modules.OpeningBalance
      ) {
        let successData = isFromSucessList
          ? this.tempImportSuccessList
          : this.importSuccessList;
        const foundItem = successData.data.find(
          (item) => item.accountId === element.accountId
        );

        let foundIndex = successData.data.findIndex(
          (item) => item.accountId === element.accountId
        );

        if (foundItem) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                type: ConfirmationType.MergeTrailBalance,
                moduleId: Modules.TrialBalance,
                headerText: NotificationHeader.confirmation,
                detailText: NotificationDetails.mergeTrailBalanceText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                element.unrestrictedDebit =
                  +element.unrestrictedDebit + +foundItem.unrestrictedDebit ??
                  0;
                element.unrestrictedCredit =
                  +element.unrestrictedCredit + +foundItem.unrestrictedCredit ??
                  0;
                element.restrictedDebit =
                  +element.restrictedDebit + +foundItem.restrictedDebit ?? 0;
                element.restrictedCredit =
                  +element.restrictedCredit + +foundItem.restrictedCredit ?? 0;
                element.endowmentDebit =
                  +element.endowmentDebit + +foundItem.endowmentDebit ?? 0;
                element.endowmentCredit =
                  +element.endowmentCredit + +foundItem.endowmentCredit ?? 0;

                const properties = ['unrestricted', 'restricted', 'endowment'];

                properties.forEach((property) => {
                  if (
                    element[`${property}Credit`] > +element[`${property}Debit`]
                  ) {
                    element[`${property}Credit`] =
                      +element[`${property}Credit`] -
                      +element[`${property}Debit`];
                    element[`${property}Debit`] = 0;
                  } else {
                    element[`${property}Debit`] =
                      +element[`${property}Debit`] -
                      +element[`${property}Credit`];
                    element[`${property}Credit`] = 0;
                  }
                });

                this.importSuccessList.data.splice(foundIndex, 1);

                if (
                  +element.unrestrictedDebit +
                    +element.unrestrictedCredit +
                    +element.restrictedDebit +
                    +element.restrictedCredit +
                    +element.endowmentDebit +
                    +element.endowmentCredit >
                  0
                ) {
                  if (!isFromSucessList) {
                    this.pushDataToSuccessList(element, index);
                  } else {
                    this.importSuccessList.data = [
                      ...this.importSuccessList.data,
                    ];
                  }
                } else {
                  this.notifier.error(
                    NotificationHeader.error,
                    NotificationTextMessage.trailBalanceZeroValueValidation
                  );
                }
              }
            });
        } else if (!isFromSucessList) {
          this.pushDataToSuccessList(element, index);
        }
      } else {
        this.pushDataToSuccessList(element, index);
      }
    }
  }

  pushDataToSuccessList(element: any, index: number): void {
    this.importSuccessList.data.push(element);
    this.importSuccessList.data = [...this.importSuccessList.data];
    this.importSuccessList = new MatTableDataSource(
      this.importSuccessList.data
    );

    this.tempImportSuccessList.data = JSON.parse(
      JSON.stringify(this.importSuccessList.data)
    );

    this.importErrorList.data.splice(index, 1);
    this.importErrorList.data = [...this.importErrorList.data];
    this.importErrorList = new MatTableDataSource(this.importErrorList.data);
    this.calAmount();
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.moveToSuccessList
    );
  }

  moveDuplicatetoSuccessList(element, index): void {
    if (
      !element.entryNumberError &&
      !element.productNameError &&
      !element.entryDateError &&
      !element.dueDateError &&
      !element.donationDateError
    ) {
      this.importSuccessList.data.push(element);
      this.importSuccessList.data = [...this.importSuccessList.data];
      this.importSuccessList = new MatTableDataSource(
        this.importSuccessList.data
      );

      this.importDuplicateList.data.splice(index, 1);
      this.importDuplicateList.data = [...this.importDuplicateList.data];
      this.importDuplicateList = new MatTableDataSource(
        this.importDuplicateList.data
      );

      this.notifier.success(
        NotificationHeader.success,
        NotificationTextMessage.moveToSuccessList
      );
    }
  }

  checkCustomerDuplicate(element, index: number): void {
    if (element.contactName !== '' && element.email !== '') {
      this.store
        .dispatch(
          new CheckCustomerSupplierDuplicate(
            element.contactName,
            element.email,
            this.selectedModuleId
          )
        )
        .subscribe((res) => {
          if (!res.common.isValidCustomerSupplier) {
            this.importSuccessList.data.push(element);
            this.importSuccessList.data = [...this.importSuccessList.data];
            this.importSuccessList = new MatTableDataSource(
              this.importSuccessList.data
            );

            this.importDuplicateList.data.splice(index, 1);
            this.importDuplicateList.data = [...this.importDuplicateList.data];
            this.importDuplicateList = new MatTableDataSource(
              this.importDuplicateList.data
            );

            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.moveToSuccessList
            );
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.invoiceNoIsExist
            );
          }
        });
    }
  }

  checkDonorDuplicate(element, index: number): void {
    if (element.firstName !== '' && element.email !== '') {
      this.store
        .dispatch(
          new CheckDonorDuplicate(
            element.firstName,
            element.lastName,
            element.email
          )
        )
        .subscribe((res) => {
          if (!res.common.isValidCustomerSupplier) {
            element.duplicateDonorError = false;
            this.importSuccessList.data.push(element);
            this.importSuccessList.data = [...this.importSuccessList.data];
            this.importSuccessList = new MatTableDataSource(
              this.importSuccessList.data
            );

            this.importDuplicateList.data.splice(index, 1);
            this.importDuplicateList.data = [...this.importDuplicateList.data];
            this.importDuplicateList = new MatTableDataSource(
              this.importDuplicateList.data
            );

            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.moveToSuccessList
            );
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.donorIsExist
            );
          }
        });
    }
  }

  checkProductName(element, index: number): void {
    if (
      element.productName !== '' &&
      element.productName !== null &&
      element.productName !== undefined
    ) {
      element.productNameError = false;
      this.moveDuplicatetoSuccessList(element, index);
    }
  }

  checkEntryNumber(element, index: number): void {
    if (element.entryNumber !== '') {
      this.store
        .dispatch(
          new CheckEntryNumber(element.entryNumber, this.selectedModuleId)
        )
        .subscribe((res) => {
          if (!res.common.isValidEntryNo) {
            element.entryNumberError = false;
            this.moveDuplicatetoSuccessList(element, index);
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.invoiceNoIsExist
            );
          }
        });
    }
  }

  checkValidFile(): void {
    this.fileImportRequestModel = {
      file: this.selectedFile,
      step: ImportStep.Upload,
    };

    this.spinner.show();
    this.store
      .dispatch(
        new ImportModules(this.fileImportRequestModel, +this.selectedModuleId)
      )
      .subscribe(() => {
        const details = this.store.selectSnapshot(ImportState.getImportData);
        this.spinner.hide();
        if (details.StatusCode === 0 || details.StatusCode !== undefined) {
          this.commonService.onFailure(details.Message);
        } else {
          if (details.errorValues.length === 0) {
            this.loadData(details);
          } else {
            const errorValuesList = details.errorValues;
            let errorValuesItem = '';

            for (let i = 0; i < errorValuesList.length; i++) {
              errorValuesItem += `<li>${errorValuesList[i]}</li>`;
            }
            this.dialog
              .open(ConfirmationBoxComponent, {
                data: {
                  type: ConfirmationType.TrailBalanceErrorList,
                  moduleId: Modules.TrialBalance,
                  headerText: NotificationHeader.confirmation,
                  detailText:
                    NotificationDetails.trialBalanceErrorText +
                    "<ul class='mt-1'>" +
                    errorValuesItem +
                    '</ul>' +
                    "<p class='fw-bold font-size-16'>Are you sure you want to continue?</p>",
                },
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                  this.loadData(details);
                }
              });
          }
        }
        (error) => {
          this.commonService.onFailure(error.message);
          this.spinner.hide();
        };
      });
  }

  endowmentDebitChanges(event: any, element: any): void {
    if (event.keyCode !== 9) {
      element.endowmentCredit = null;
    }
  }

  endowmentCreditChanges(event: any, element: any): void {
    if (event.keyCode !== 9) {
      element.endowmentDebit = null;
    }
  }

  checkEndowmentDebitCreditAmount(
    element: any,
    isDebit: boolean,
    index: number
  ): void {
    if (isDebit) {
      element.endowmentCredit = null;
      element.endowmentError = +element.endowmentDebit > 0 ? false : true;
    } else {
      element.endowmentDebit = null;
      element.endowmentError = +element.endowmentCredit > 0 ? false : true;
    }
    this.checkValidElement(element, index);
  }

  restrictedDebitChanges(event: any, element: any): void {
    if (event.keyCode !== 9) {
      element.restrictedCredit = null;
    }
  }

  restrictedCreditChanges(event: any, element: any): void {
    if (event.keyCode !== 9) {
      element.restrictedDebit = null;
    }
  }

  checkRestrictedDebitCreditAmount(
    element: any,
    isDebit: boolean,
    index: number
  ): void {
    if (isDebit) {
      element.restrictedCredit = null;
      element.restrictedError = +element.restrictedDebit > 0 ? false : true;
    } else {
      element.restrictedDebit = null;
      element.restrictedError = +element.restrictedCredit > 0 ? false : true;
    }
    this.checkValidElement(element, index);
  }

  unrestrictedDebitChanges(event: any, element: any): void {
    if (event.keyCode !== 9) {
      element.unrestrictedCredit = null;
    }
  }

  unrestrictedCreditChanges(event: any, element: any): void {
    if (event.keyCode !== 9) {
      element.unrestrictedDebit = null;
    }
  }

  checkUnRestrictedDebitCreditAmount(
    element: any,
    isDebit: boolean,
    index: number
  ): void {
    if (isDebit) {
      element.unrestrictedCredit = null;
      element.unrestrictedError = +element.unrestrictedDebit > 0 ? false : true;
    } else {
      element.unrestrictedDebit = null;
      element.unrestrictedError =
        +element.unrestrictedCredit > 0 ? false : true;
    }
    this.checkValidElement(element, index);
  }

  setData(details: any): void {
    this.displayedColumns = details.columns;
    this.displayedColumns2 = this.displayedColumns.map((x) => `f2_${x}`);

    this.importSuccessList.data = details.successList;

    if (
      this.selectedModuleId === Modules.TrialBalance ||
      this.selectedModuleId === Modules.OpeningBalance
    ) {
      if (this.importSuccessList.data.length > 0) {
        this.calAmount();
      }
    }

    this.showAccountName = this.displayedColumns.includes('accountName');
    this.showIncomeAccount = this.displayedColumns.includes('incomeAccount');
    this.showDepositAccount =
      this.displayedColumns.includes('depositAccount') ||
      (this.accountingMethodId === AccountingMethod.CashBasis &&
        (this.selectedModuleId === Modules.CreditNote ||
          this.selectedModuleId === Modules.DebitNote ||
          this.selectedModuleId === Modules.Invoices ||
          this.selectedModuleId === Modules.Bills ||
          this.selectedModuleId === Modules.FixedAssets));
    this.showFundName = this.displayedColumns.includes('fundName');

    this.importErrorList.data = details.errorList;
    this.importDuplicateList.data = details.duplicateList;

    this.myStepper.next();
    this.commonService.onSucess(NotificationTextMessage.fileUploaded);
  }

  loadInvoiceDropdownValues(detail: any): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Income);

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: this.commonService.invoiceTypeList,
    };

    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    const getInvoiceType = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
    );

    const getCustomer = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    const getFundNameList = this.store.dispatch(new GetFundNameList());

    let depositToList;
    if (this.accountingMethodId === AccountingMethod.CashBasis) {
      depositToList = this.store.dispatch(new GetCustomBankAndCashAccount());
    } else {
      depositToList = of(null); // Placeholder for the case when not using CashBasis
    }

    forkJoin([
      getInvoiceType,
      getCustomer,
      vatRateList,
      getFundNameList,
      depositToList,
    ]).subscribe((res) => {
      this.customerList = this.store.selectSnapshot(CommonState.accountList);
      this.fundNameList = this.store.selectSnapshot(
        AccountState.getFundNameList
      );

      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );

      this.vatRateList = this.store.selectSnapshot(CommonState.getVatRate);

      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }

      if (this.accountingMethodId === AccountingMethod.CashBasis) {
        this.depositToList = this.store.selectSnapshot(
          CommonState.getCustomBankAndCashAccount
        );
      }

      this.setData(detail);
    });
  }

  loadBillDropdownValues(detail: any): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Expense);

    const typeId = new Array<number>();
    typeId.push(
      PurchaseTypeName.CharitableActivities,
      PurchaseTypeName.RaisingFunds
    );

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: typeId,
    };

    const getInvoiceType = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
    );
    const getSupplierList = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );
    const getFundNameList = this.store.dispatch(new GetFundNameList());
    const vatRateList = this.store.dispatch(new GetVatRateList());

    let depositToList;
    if (this.accountingMethodId === AccountingMethod.CashBasis) {
      depositToList = this.store.dispatch(new GetCustomBankAndCashAccount());
    } else {
      depositToList = of(null); // Placeholder for the case when not using CashBasis
    }

    forkJoin([
      getSupplierList,
      getInvoiceType,
      getFundNameList,
      vatRateList,
      depositToList,
    ]).subscribe((res: any) => {
      this.supplierList = this.store.selectSnapshot(CommonState.accountList);
      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );
      this.fundNameList = this.store.selectSnapshot(
        AccountState.getFundNameList
      );
      this.vatRateList = this.store.selectSnapshot(CommonState.getVatRate);

      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }

      if (this.accountingMethodId === AccountingMethod.CashBasis) {
        this.depositToList = this.store.selectSnapshot(
          CommonState.getCustomBankAndCashAccount
        );
      }
      this.setData(detail);
    });
  }

  loadCreditNoteDropdownValues(detail: any): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Income);

    const getCustomer = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    const getFundNameList = this.store.dispatch(new GetFundNameList());

    const accountList = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupId(groupNames)
    );

    let depositToList;
    if (this.accountingMethodId === AccountingMethod.CashBasis) {
      depositToList = this.store.dispatch(new GetCustomBankAndCashAccount());
    } else {
      depositToList = of(null); // Placeholder for the case when not using CashBasis
    }

    forkJoin([
      getCustomer,
      vatRateList,
      getFundNameList,
      accountList,
      depositToList,
    ]).subscribe((res) => {
      this.customerList = this.store.selectSnapshot(CommonState.accountList);
      this.fundNameList = this.store.selectSnapshot(
        AccountState.getFundNameList
      );
      this.vatRateList = this.store.selectSnapshot(CommonState.getVatRate);
      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );

      if (this.accountingMethodId === AccountingMethod.CashBasis) {
        this.depositToList = this.store.selectSnapshot(
          CommonState.getCustomBankAndCashAccount
        );
      }

      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }
      this.setData(detail);
    });
  }

  loadDebitNoteDropdownValues(detail: any): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Expense);

    const getSupplier = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    const getFundNameList = this.store.dispatch(new GetFundNameList());

    const accountList = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupId(groupNames)
    );

    let depositToList;
    if (this.accountingMethodId === AccountingMethod.CashBasis) {
      depositToList = this.store.dispatch(new GetCustomBankAndCashAccount());
    } else {
      depositToList = of(null); // Placeholder for the case when not using CashBasis
    }

    forkJoin([
      getSupplier,
      vatRateList,
      getFundNameList,
      accountList,
      depositToList,
    ]).subscribe((res) => {
      this.supplierList = this.store.selectSnapshot(CommonState.accountList);
      this.fundNameList = this.store.selectSnapshot(
        AccountState.getFundNameList
      );
      this.vatRateList = this.store.selectSnapshot(CommonState.getVatRate);
      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );

      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }

      if (this.accountingMethodId === AccountingMethod.CashBasis) {
        this.depositToList = this.store.selectSnapshot(
          CommonState.getCustomBankAndCashAccount
        );
      }

      this.setData(detail);
    });
  }

  loadFixedAssetDropdownValues(detail: any): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    const groupNames = new Array<number>();
    groupNames.push(GroupNames.FixedAssets_Heritage);
    groupNames.push(GroupNames.FixedAssets_Investments);
    groupNames.push(GroupNames.FixedAssets_Tangibles);
    groupNames.push(GroupNames.FixedAssets_Intangibles);

    const getSupplier = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    const getFundNameList = this.store.dispatch(new GetFundNameList());

    const accountList = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupId(groupNames)
    );

    let depositToList;
    if (this.accountingMethodId === AccountingMethod.CashBasis) {
      depositToList = this.store.dispatch(new GetCustomBankAndCashAccount());
    } else {
      depositToList = of(null); // Placeholder for the case when not using CashBasis
    }

    forkJoin([
      getSupplier,
      vatRateList,
      getFundNameList,
      accountList,
      depositToList,
    ]).subscribe((res) => {
      this.supplierList = this.store.selectSnapshot(CommonState.accountList);
      this.fundNameList = this.store.selectSnapshot(
        AccountState.getFundNameList
      );
      this.vatRateList = this.store.selectSnapshot(CommonState.getVatRate);
      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );

      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }

      if (this.accountingMethodId === AccountingMethod.CashBasis) {
        this.depositToList = this.store.selectSnapshot(
          CommonState.getCustomBankAndCashAccount
        );
      }

      this.setData(detail);
    });
  }

  loadCustomerDropdownValues(detail: any): void {
    this.store
      .dispatch(new GetCountryList())
      .pipe(
        tap(() => {
          this.countryList = this.store.selectSnapshot(CountryState.getCountry);
          this.setData(detail);
        })
      )
      .subscribe();
  }

  loadDonationDropdownValues(detail: any): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Income);

    const getDonor = this.store.dispatch(new GetAllDonor());

    const getFundNameList = this.store.dispatch(new GetFundNameList());

    const accountList = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupId(groupNames)
    );

    const getCustomBankAndCashAccount = this.store.dispatch(
      new GetCustomBankAndCashAccount()
    );

    const getSponsoreList = this.store.dispatch(new GetSponsoreList());
    const getActivityList = this.store.dispatch(new GetActivityList());

    forkJoin([
      getDonor,
      getFundNameList,
      accountList,
      getCustomBankAndCashAccount,
      getSponsoreList,
      getActivityList,
    ]).subscribe((res) => {
      this.donorList = this.store.selectSnapshot(DonorState.getAllDonor);
      this.fundNameList = this.store.selectSnapshot(
        AccountState.getFundNameList
      );

      this.depositToList = this.store.selectSnapshot(
        CommonState.getCustomBankAndCashAccount
      );

      this.sponsorEventList = this.store.selectSnapshot(
        DonationState.getSponsore
      );
      this.activityList = this.store.selectSnapshot(DonationState.getActivity);

      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );

      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }
      this.setData(detail);
    });
  }

  loadTrailBalanceDropdownValues(detail: any, isOnLoad: boolean): void {
    this.store
      .dispatch(new GetJournalAccounts())
      .pipe(
        tap((res) => {
          if (res.account.accounts.length > 0) {
            this.accountGroupList = this.addGroupNameToListModels(
              res.account.accounts
            );
            this.tempAccountGroupList = this.accountGroupList;
          }
          if (isOnLoad) {
            this.setData(detail);
          }
        })
      )
      .subscribe();
  }

  loadData(detail: any): void {
    if (this.selectedModuleId === Modules.Invoices) {
      this.loadInvoiceDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.Bills) {
      this.loadBillDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.CreditNote) {
      this.loadCreditNoteDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.DebitNote) {
      this.loadDebitNoteDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.FixedAssets) {
      this.loadFixedAssetDropdownValues(detail);
    } else if (
      this.selectedModuleId === Modules.Customers ||
      this.selectedModuleId === Modules.Suppliers ||
      this.selectedModuleId === Modules.Donors
    ) {
      this.loadCustomerDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.Donations) {
      this.loadDonationDropdownValues(detail);
    } else if (
      this.selectedModuleId === Modules.TrialBalance ||
      this.selectedModuleId === Modules.OpeningBalance ||
      this.selectedModuleId === Modules.AccountProductionTrialBalance
    ) {
      this.loadTrailBalanceDropdownValues(detail, true);
      this.setPeriodDate();
    }
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Customers,
          headerText: `Add ${ModuleName.Customers}`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getCustomer();
        }
      });
  }

  gotoAddDonor(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Donors,
          headerText: `Add ${ModuleName.Donors}`,
          saveActionName: CreateDonor,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getDonor(id);
        }
      });
  }

  getDonor(id): void {
    this.store.dispatch(new GetAllDonor()).subscribe((res) => {
      this.donorList = res.donor.sideListModel;
    });
  }

  getCustomer(): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap((res) => {
          this.customerList = res.common.accountList;
        })
      )
      .subscribe();
  }

  gotoAddSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Suppliers,
          headerText: `Add ${ModuleName.Suppliers}`,
          saveActionName: CreateContact,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getSupplier();
        }
      });
  }

  getSupplier(): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap((res) => {
          this.supplierList = res.common.accountList;
        })
      )
      .subscribe();
  }

  gotoAddFund(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.FundList,
          headerText: `Add ${ModuleName.Fund}`,
          saveActionName: CreateFund,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getFund(id);
        }
      });
  }

  gotoEditFund(): void {
    if (this.fundNameList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.FundList,
            headerText: `Edit ${ModuleName.Fund}`,
            id: this.fundObj.id,
            saveActionName: CreateFund,
            getActionName: GetDataByFundId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getFund(id);
          }
        });
    }
  }

  getFund(id): void {
    this.store.dispatch(new GetFundNameList()).subscribe((res) => {
      this.fundNameList = res.account.fundNameList;
      if (id !== null && id !== undefined) {
        this.fundObj = this.fundNameList.find((x) => x.id === id);
      }
    });
  }

  checkSpecialChar(selectedFile: any): any {
    const format = /[!@#$%^&*():"<>?{}|]/;
    return format.test(selectedFile.name);
  }

  formatBytes(bytes: number): string {
    const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = 1024;
    let index = 0;
    while (bytes >= factor) {
      bytes /= factor;
      index++;
    }
    return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
  }

  downloadTemplateFile(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetImportTemplate(this.selectedModuleId))
      .subscribe((res) => {});
  }

  onDragOver(event: Event) {
    event.preventDefault();
    // Add styles to the drop area to indicate it's a valid drop target.
    // For example, you can change the background color or border.
  }

  onDragLeave(event: Event) {
    event.preventDefault();
    // Remove the styles applied in onDragOver.
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    // Prevent the default behavior of opening the dropped file in the browser.
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files !== null && files !== undefined && files.length > 0) {
      // Handle the dropped files (e.g., upload or process them).
      this.handleDroppedFiles(files);
    }
  }

  handleDroppedFiles(files: FileList) {
    let file: any;
    // Handle the dropped or selected files here, e.g., by uploading them to a server.
    for (let i = 0; i < files.length; i++) {
      file = files[i];
      // Process or upload the file as needed.
    }
    this.onProcessFileSelected(file);
  }

  onFileSelected(input: HTMLInputElement): void {
    if (!input.files || !input.files.length) {
      return;
    }
    const file = input.files![0];
    this.onProcessFileSelected(file);
  }

  onProcessFileSelected(files: any): void {
    const file = files;
    this.selectedFileName = file.name;
    if (file.size / 1024 / 1024 > 10) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSizeExceeds
      );
      return;
    }

    if (this.checkSpecialChar(file)) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.filenameWithSpecialCharacter
      );
      return;
    }

    this.fileInfo = `${file.name} (${this.formatBytes(file.size)})`;

    this.selectedFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.url = reader.result;
    };
    this.step = ImportStep.Finish;
  }

  onCloseClick(): void {
    this.fileInfo = '';
  }

  resetAccountList(): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(this.importForm.controls.accountObj.value);
  }

  resetElementAccountList(element): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(element.accountId);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  getOptionText(option) {
    return option?.name;
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  ngOnDestroy(): void {
    this.importSubscription?.unsubscribe();
    this.cancelSubscription?.unsubscribe();
  }

  onFileOpen(event): void {
    event.target.value = null;
  }

  calAmount(): void {
    this.totalUnrestrictedCredit = 0;
    this.totalUnrestrictedDebit = 0;
    this.totalRestrictedCredit = 0;
    this.totalRestrictedDebit = 0;
    this.totalEndowmentCredit = 0;
    this.totalEndowmentDebit = 0;
    this.importSuccessList.data.forEach((element) => {
      this.totalUnrestrictedDebit += +element.unrestrictedDebit;
      this.totalUnrestrictedCredit += +element.unrestrictedCredit;
      this.totalRestrictedDebit += +element.restrictedDebit;
      this.totalRestrictedCredit += +element.restrictedCredit;
      this.totalEndowmentDebit += +element.endowmentDebit;
      this.totalEndowmentCredit += +element.endowmentCredit;
    });
  }

  countDifferenceOfDrCr(columnName: string) {
    columnName = columnName.replace('f2_', '').replace('f3_', '');
    let returnValue;

    if (columnName === this.displayedColumns[0]) {
      returnValue = 'Difference';
    } else if (columnName === this.displayedColumns[1]) {
      if (this.totalUnrestrictedDebit < this.totalUnrestrictedCredit) {
        returnValue = this.calDifferenceUnrestrictedDebitAmount();
      } else {
        returnValue = '0';
      }
    } else if (columnName === this.displayedColumns[2]) {
      if (this.totalUnrestrictedDebit > this.totalUnrestrictedCredit) {
        returnValue = this.calDifferenceUnrestrictedDebitAmount();
      } else {
        returnValue = '0';
      }
    } else if (columnName === this.displayedColumns[3]) {
      if (this.totalRestrictedDebit < this.totalRestrictedCredit) {
        returnValue = this.calDifferenceRestrictedDebitAmount();
      } else {
        returnValue = '0';
      }
    } else if (columnName === this.displayedColumns[4]) {
      if (this.totalRestrictedDebit > this.totalRestrictedCredit) {
        returnValue = this.calDifferenceRestrictedDebitAmount();
      } else {
        returnValue = '0';
      }
    } else if (columnName === this.displayedColumns[5]) {
      if (this.totalEndowmentDebit < this.totalEndowmentCredit) {
        returnValue = this.calDifferenceEndowmentDebitAmount();
      } else {
        returnValue = '0';
      }
    } else if (columnName === this.displayedColumns[6]) {
      if (this.totalEndowmentDebit > this.totalEndowmentCredit) {
        returnValue = this.calDifferenceEndowmentDebitAmount();
      } else {
        returnValue = '0';
      }
    }
    return returnValue;
  }

  calDifferenceUnrestrictedDebitAmount(): any {
    this.unrestrictedTotalDifference =
      this.totalUnrestrictedDebit - this.totalUnrestrictedCredit;
    if (this.unrestrictedTotalDifference < 0) {
      this.unrestrictedTotalDifference = this.unrestrictedTotalDifference
        .toString()
        .replace('-', '');
    }
    return this.unrestrictedTotalDifference;
  }

  calDifferenceRestrictedDebitAmount(): any {
    this.restrictedTotalDifference =
      this.totalRestrictedDebit - this.totalRestrictedCredit;
    if (this.restrictedTotalDifference < 0) {
      this.restrictedTotalDifference = this.restrictedTotalDifference
        .toString()
        .replace('-', '');
    }
    return this.restrictedTotalDifference;
  }

  calDifferenceEndowmentDebitAmount(): any {
    this.endowmenTotalDifference =
      this.totalEndowmentDebit - this.totalEndowmentCredit;
    if (this.endowmenTotalDifference < 0) {
      this.endowmenTotalDifference = this.endowmenTotalDifference
        .toString()
        .replace('-', '');
    }
    return this.endowmenTotalDifference;
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    this.selectedValue = this.periodicDateList[0].id;
    if (
      localStorage.getItem('defaultFinancialPeriod') !== null &&
      localStorage.getItem('defaultFinancialPeriod') !== undefined &&
      localStorage.getItem('defaultFinancialPeriod') !== '-1'
    ) {
      this.selectedValue =
        this.periodicDateList.filter(
          (x) => x.name === localStorage.getItem('defaultFinancialPeriod')
        )[0].id ?? this.selectedValue;
    }
    this.onAccountPeriodChange();
  }

  onAccountPeriodChange(): void {
    let accountingId = this.importForm.get('accountingPeriod')?.value;
    this.periodicDateList.forEach((element) => {
      if (element.id === accountingId) {
        this.startDate = element.fromDate;
        this.endDate = element.toDate;
      }
    });
  }

  gotoAddCustomAccount(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.AddCustomAccounts,
          headerText: `Add ${ModuleName.AddCustomAccounts}`,
          saveActionName: CreateCustomAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.loadTrailBalanceDropdownValues('', false);
        }
      });
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }
}
