import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hmrc-gateway-details',
  templateUrl: './hmrc-gateway-details.component.html',
  styleUrls: ['./hmrc-gateway-details.component.scss'],
})
export class HMRCGatewayDetailsComponent implements OnInit {
  hmrcGatewayDetailsForm: FormGroup;
  isChecked = true;
  isAgent = false;

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.hmrcGatewayDetailsForm = new FormGroup({
      userName: new FormControl(),
      password: new FormControl(),
      isAgent: new FormControl(),
    });
  }

  onCheckBoxChanges(event: any): void {
    if (event.checked) {
      this.isChecked = false;
    } else {
      this.isChecked = true;
    }
  }
}
