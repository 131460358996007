<div id="chart" *ngIf="ReceivableSummaryChart">
  <apx-chart
    [series]="ReceivableSummaryChart?.series!"
    [chart]="ReceivableSummaryChart?.chart!"
    [dataLabels]="ReceivableSummaryChart?.dataLabels!"
    [plotOptions]="ReceivableSummaryChart?.plotOptions!"
    [yaxis]="ReceivableSummaryChart?.yaxis!"
    [legend]="ReceivableSummaryChart?.legend!"
    [fill]="ReceivableSummaryChart?.fill!"
    [stroke]="ReceivableSummaryChart?.stroke!"
    [tooltip]="ReceivableSummaryChart?.tooltip!"
    [xaxis]="ReceivableSummaryChart?.xaxis!"
    [noData]="ReceivableSummaryChart?.noData!"
    [colors]="ReceivableSummaryChart?.colors!"
  ></apx-chart>
</div>
