import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverviewModel } from '@app/core/Models';

import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexNoData,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type ChartOptionvBreckdown = {
  noData: ApexNoData;
  series: any;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  lines: ApexGrid;
};

@Component({
  selector: 'app-overview-incomes-breckdown',
  templateUrl: './overview-incomes-breckdown.component.html',
  styleUrls: ['./overview-incomes-breckdown.component.scss'],
})
export class OverviewIncomesBreckdownComponent {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptionsvBreckdown: Partial<ChartOptionvBreckdown>;

  constructor() {
    setTimeout(() => {
      this.chartOptionsvBreckdown = {
        series: [
          {
            name: 'distibuted',
            data: [60, 90, 25, 98],
          },
        ],
        chart: {
          height: 300,
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No Data to Display',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#6d41a1',
            fontSize: '18px',
          },
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          labels: {
            show: true,
            rotate: 0,
          },
          categories: [
            ['Sale of', 'Magazine'],
            ['Sale of', 'Services'],
            ['Sale of', 'Goods'],
            ['Let-out of Non-', 'Investment Proterty'],
          ],
        },
        legend: {
          show: false,
          offsetX: 0,
          offsetY: 8,
        },
        fill: {
          colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
        },
        tooltip: {
          marker: {
            show: true,
          },
          enabled: true,
          fillSeriesColor: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };
    }, 200);
  }
}
