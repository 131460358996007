import { FundModel, OverviewFilterModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateFund {
  static readonly type = '[FUND] Create fund';

  constructor(public fund: FundModel) {}
}

export class GetDataByFundId {
  static readonly type = '[FUND] Get fund Data By fund Id';

  constructor(public fundId: Guid) {}
}

export class ArchiveAndRestoreFund {
  static readonly type = '[FUND] Archive and Restore Selected fund';

  constructor(public fundIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteFund {
  static readonly type = '[FUND] Delete Selected fund';

  constructor(public fundIds: Array<Guid>) {}
}

export class GetFundBreakdownOverview {
  static readonly type = '[FUND] Get Fund Breakdown Overview';
  constructor(public filterData: any) {}
}

export class GetEndowmentCapitalOverview {
  static readonly type = '[FUND] Get Endowment Capital Overview';
  constructor(public filterData: any) {}
}

export class GetIncomeExpensesByFundTypeOverview {
  static readonly type = '[FUND] Get Income Expenses By Fund Type Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetIncomeVsExpensesOverview {
  static readonly type = '[FUND] Get Income Vs Expenses Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetFundCardData {
  static readonly type = '[FUND] Get Fund Card Data';
  constructor(public fundCardData: any) {}
}

export class GetFundListBasedOnFundAccountType {
  static readonly type = '[FUND] Get Fund List Based On Fund Account Type ';

  constructor(public accountId: Guid) {}
}
