<div class="content-body">
  <div class="alert-message mb-10" *ngIf="!isReceiptChangePermission">
    <mat-icon class="material-icons-outlined">error_outline</mat-icon>
    {{ commonNotificationText.ViewOnlyAccessMessage }}
  </div>
  <div class="sidenav-table sidenav-table-input wp-100">
    <div class="mat-table-wrapper payment-detail-table">
      <form [formGroup]="paymentForm">
        <ng-container formArrayName="paymentArray">
          <table
            aria-describedby="payment-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="srNo">
              <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex">{{ i + 1 }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="receiptdate">
              <th mat-header-cell *matHeaderCellDef>{{ headerText }} Date</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="standard">
                  <input
                    matInput
                    formControlName="receiptDate"
                    [matDatepicker]="picker"
                    autocomplete="off "
                    (change)="commonService.validateDate($event)"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      paymentArray.controls[i].get('receiptDate')
                    ).invalidFinancialYear
                  "
                >
                  {{ notificationMessage.validPeriodDateMessage }}
                </mat-error>

                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      paymentArray.controls[i].get('receiptDate')
                    ).lockedFinancialYear
                  "
                >
                  {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
                </mat-error>
              </td>
            </ng-container>
            <ng-container matColumnDef="account">
              <th mat-header-cell *matHeaderCellDef>Select Account</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="widthemail"
              >
                <ng-select
                  #accountId
                  [disableOptionCentering]="true"
                  (focus)="accountId.open()"
                  (valueChange)="accountId.close()"
                  formControlName="accountId"
                  panelClass="mat-select-position"
                  required
                >
                  <ng-option
                    *ngFor="let option of paymentAccountList[i]"
                    [value]="option.id"
                  >
                    <div title="{{ option.name }}">{{ option.name }}</div>
                  </ng-option>
                </ng-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef class="w-150">Amount</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    allow_14_2_Decimal
                    matInput
                    [value]="element.amount"
                    autocomplete="off"
                    formControlName="amount"
                    (blur)="amountChanges()"
                    required
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-50"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onDeletePayment(i)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
                <button
                  type="button"
                  mat-button
                  disableRipple
                  (click)="clickAttachment(i)"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayPaymentsColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayPaymentsColumns"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>

    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a
          class="link mr-1"
          href="javascript:void(0)"
          [ngClass]="isReceiptChangePermission ? '' : 'cursor-not-allowed'"
          (click)="isReceiptChangePermission ? addNewRow() : ''"
          >Add New Line</a
        >
        <a
          class="link mr-1"
          *ngIf="paymentArray.length > 0"
          href="javascript:void(0)"
          (click)="clearForm()"
          >Clear All Lines</a
        >
      </div>
    </div>
  </div>
</div>
