<div
  class="main-wrapper"
  [ngClass]="[
    this.commonService.toggleMenu ? 'show-menu' : 'hide-menu',
    activeRouteLink === routingPath.AddCharity || (companyCount$ | async) === 0
      ? 'add-company'
      : '',
    (moduleId$ | async) === moduleEnum.Users ? 'user-module' : ''
  ]"
  *ngIf="this.authResolverService.isLoggedUser"
>
  <div class="header-wrapper">
    <div class="navigation-container">
      <app-header></app-header>
    </div>
  </div>
  <ng-container *ngIf="(companyCount$ | async) === 0; else showMenu">
    <div class="content-wrapper">
      <app-new-company></app-new-company>
    </div>
  </ng-container>
  <ng-template #showMenu>
    <div class="content-wrapper">
      <app-menu-items [activeMenuLink]="activeRouteLink"></app-menu-items>
      <div class="container">
        <router-outlet (activate)="changeOfRoutes($event)"></router-outlet>
      </div>
    </div>
    <div class="menu-toggle" (click)="onIconClick()">
      <mat-icon>{{
        this.commonService.toggleMenu
          ? "keyboard_arrow_left"
          : "keyboard_arrow_right"
      }}</mat-icon>
    </div>
  </ng-template>
</div>
<ngx-spinner
  template="<img class='loader-image mr-1' src='assets/images/loader.svg' />
<span class='loader'></span>"
></ngx-spinner>
<app-custom-notifications></app-custom-notifications>
