import { Injectable, Injector } from '@angular/core';
import { AccountProductionService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetAccountProductionTrialBalance,
  RefreshAccountProductionTrialBalance,
  SaveAccountProductionTrialBalance,
} from './account-production.action';

export class AccountProductionStateInfo {
  isLoading?: boolean;
  refreshTrialBalance?: any;
  openingBalanceData: any;
  isTrialBalance: boolean;
}

@State<AccountProductionStateInfo>({
  name: 'accountProduction',
  defaults: {
    openingBalanceData: [],
    isTrialBalance: false,
  },
})
@Injectable()
export class AccountProductionState {
  accountProductionService: AccountProductionService;
  constructor(private injector: Injector) {
    this.accountProductionService = injector.get<AccountProductionService>(
      AccountProductionService
    );
  }
  @Selector()
  static getJournalAccounts(state: AccountProductionStateInfo) {
    return state.openingBalanceData;
  }
  @Selector()
  static isLoading(state: AccountProductionStateInfo): boolean {
    return state.isLoading ?? false;
  }
  @Action(RefreshAccountProductionTrialBalance, { cancelUncompleted: true })
  getMainList(
    { patchState }: StateContext<AccountProductionStateInfo>,
    action: RefreshAccountProductionTrialBalance
  ) {
    return this.accountProductionService
      .refreshAccountProductionTrialBalance(action.accountingPeriodId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              refreshTrialBalance: result,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetAccountProductionTrialBalance)
  getAccountProductionTrialBalance(
    { getState, patchState }: StateContext<AccountProductionStateInfo>,
    action: GetAccountProductionTrialBalance
  ) {
    return this.accountProductionService
      .getAccountProductionTrialBalance(action.accoutingPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            openingBalanceData: res,
          });
        })
      );
  }

  @Action(SaveAccountProductionTrialBalance)
  saveAccountProductionTrialBalance(
    { patchState }: StateContext<AccountProductionStateInfo>,
    action: SaveAccountProductionTrialBalance
  ) {
    return this.accountProductionService
      .saveTrialBalance(action.trialBalance)
      .pipe(
        tap((res) => {
          patchState({
            isTrialBalance: res,
          });
        })
      );
  }
}
