import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationHeader, NotificationTextMessage } from '@app/core/Enum';
import { FileImportRequestModel } from '@app/core/Enum/import';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
} from '@app/core/Models';
import {
  CommonService,
  HighlightRow,
  NotificationService,
} from '@app/core/Services';
import {
  BridgingVatState,
  GetImportTemplate,
  ImportVatBridging,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-vat-details',
  templateUrl: './vat-details.component.html',
  styleUrls: ['./vat-details.component.scss'],
})
export class VATDetailsComponent implements OnInit {
  bridgingVatForm: FormGroup;
  @ViewChild('fileInput')
  fileInput: ElementRef;

  @Input() triggerEditData: Observable<any>;

  defaultCurrency: Guid;
  periodicDate: any;
  notificationMessage = NotificationTextMessage;
  accept = ['.pdf', '.xls'];
  @Input() moduleId: number;
  fileName: string;
  fileList: any[] = [];
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: Array<FileUploadResponseModel>;
  isFileSelected = false;
  fileData: any;
  fileInfo: string = '';
  selectedFileName: string;
  selectedFile: File;
  url: any;
  fileImportRequestModel: FileImportRequestModel;
  isImportSuccess: boolean = false;

  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private store: Store,
    private spinner: NgxSpinnerService,
    private notifier: NotificationService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.bridgingVatForm = new FormGroup({
      file: new FormControl(''),
    });
  }

  downloadTemplateFile(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetImportTemplate(this.moduleId))
      .subscribe((res) => {});
  }

  onDragOver(event: Event) {
    event.preventDefault();
    // Add styles to the drop area to indicate it's a valid drop target.
    // For example, you can change the background color or border.
  }

  onDragLeave(event: Event) {
    event.preventDefault();
    // Remove the styles applied in onDragOver.
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    // Prevent the default behavior of opening the dropped file in the browser.
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files !== null && files !== undefined && files.length > 0) {
      // Handle the dropped files (e.g., upload or process them).
      this.handleDroppedFiles(files);
    }
  }

  handleDroppedFiles(files: FileList) {
    let file: any;
    // Handle the dropped or selected files here, e.g., by uploading them to a server.
    for (let i = 0; i < files.length; i++) {
      file = files[i];
      // Process or upload the file as needed.
    }
    this.onProcessFileSelected(file);
  }

  onFileSelected(input: HTMLInputElement): void {
    if (!input.files || !input.files.length) {
      return;
    }
    const file = input.files![0];
    this.onProcessFileSelected(file);
  }

  onProcessFileSelected(files: any): void {
    const file = files;
    this.selectedFileName = file.name;
    if (file.size / 1024 / 1024 > 10) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSizeExceeds
      );
      return;
    }

    if (this.checkSpecialChar(file)) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.filenameWithSpecialCharacter
      );
      return;
    }

    this.fileInfo = `${file.name} (${this.formatBytes(file.size)})`;

    this.selectedFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.url = reader.result;
    };
  }

  onCancel(): void {
    this.fileInput.nativeElement.value = '';
    this.fileInfo = '';
    this.isFileSelected = false;
  }

  formatBytes(bytes: number): string {
    const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = 1024;
    let index = 0;
    while (bytes >= factor) {
      bytes /= factor;
      index++;
    }
    return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
  }

  checkSpecialChar(selectedFile: any): any {
    const format = /[!@#$%^&*():"<>?{}|]/;
    return format.test(selectedFile.name);
  }

  onImport(): void {
    this.spinner.show();
    this.fileImportRequestModel = {
      file: this.selectedFile,
      step: 1,
    };

    this.store
      .dispatch(new ImportVatBridging(this.fileImportRequestModel))
      .subscribe(() => {
        const details = this.store.selectSnapshot(
          BridgingVatState.getImportData
        );
        this.spinner.hide();
        if (details) {
          this.isImportSuccess = true;
          this.onSuccess();
        } else {
          this.onError();
        }
      });
  }

  onError(): void {
    this.notifier.error(
      NotificationHeader.error,
      NotificationTextMessage.errorMessage
    );
  }

  onSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.fileUploaded
    );
  }
}
