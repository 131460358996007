import { Guid } from 'guid-typescript';

export class SubmitVATModel {
  id?: Guid;
  companyId?: Guid;
  periodKey?: string;
  fromDate?: string;
  toDate?: string;
  description?: string;
  isVat?: boolean;
  vatDueSales?: number;
  vatDueAcquisitions?: number;
  totalVatDue?: number;
  vatReclaimedCurrPeriod?: number;
  netVatDue?: number;
  totalValueSalesExVAT?: number;
  totalValuePurchasesExVAT?: number;
  totalValueGoodsSuppliedExVAT?: number;
  totalAcquisitionsExVAT?: number;
  submittedCapium?: boolean;
  submittedHMRC?: boolean;
  isBridgingVAT?: boolean;
  fileURL?: string;
}
export class VATReturnDateModel {
  fromDate: Date;
  toDate: Date;
}

export class IncluceExcludeTransactionModel {
  id?: Guid;
  typeId?: number;
  isVatReturnIncluded?: boolean;
  vatReturnId?: string;
}
