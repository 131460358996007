<div id="chart" *ngIf="!isNoRecord && isloadData">
  <apx-chart
    [series]="chartOptionsvBreckdownv1?.series!"
    [chart]="chartOptionsvBreckdownv1?.chart!"
    [labels]="chartOptionsvBreckdownv1?.labels!"
    [tooltip]="chartOptionsvBreckdownv1?.tooltip!"
    [responsive]="chartOptionsvBreckdownv1?.responsive!"
    [legend]="chartOptionsvBreckdownv1?.legend!"
    [plotOptions]="chartOptionsvBreckdownv1.plotOptions!"
    [fill]="chartOptionsvBreckdownv1?.fill!"
    [noData]="chartOptionsvBreckdownv1?.noData!"
    [dataLabels]="chartOptionsvBreckdownv1.dataLabels!"
    [xaxis]="chartOptionsvBreckdownv1.xaxis!"
    [yaxis]="chartOptionsvBreckdownv1.yaxis!"
    [grid]="chartOptionsvBreckdownv1.grid!"
    [lines]="chartOptionsvBreckdownv1.lines!"
  ></apx-chart>
</div>
<div class="norecord-chart" *ngIf="isNoRecord && isloadData">
  <img src="assets/images/column-icon.svg" alt="Bar Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
