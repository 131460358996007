import { VATCodesModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetVATCodes {
  static readonly type = '[VATCODES] Get vatCodes';
  constructor(public vatCodeId: Guid) {}
}

export class SaveVATCodes {
  static readonly type = '[VATCODES] Save vatCodes';

  constructor(public params: VATCodesModel) {}
}

export class DeleteVATCodes {
  static readonly type = '[VATCODES] Delete Selected vatCodes';

  constructor(public vatCodsIds: Array<Guid>) {}
}

export class ArchiveAndRestoreVATCodes {
  static readonly type =
    '[MinutesOfMeeting] Archive and Restore Selected vatCodes';

  constructor(public vatCodsIds: Array<Guid>, public isArchive: boolean) {}
}
