import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GroupNames,
  MaxLength,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { Currency, GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  CommonState,
  CustomAccountState,
  GetAccountTypeList,
  GetChartOfAccountGroupList,
  GetChartOfAccountTypeList,
  GetCurrencyList,
  ValidateAccountCodeExists,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-custom-accounts',
  templateUrl: './add-custom-accounts.component.html',
  styleUrls: ['./add-custom-accounts.component.scss'],
})
export class AddCustomAccountsComponent implements OnInit {
  isReadOnly = false;
  isFixedAssestsGroup = false;
  isCashInHandGroup = false;
  isAddMode = true;
  customAccountId = Guid.EMPTY as unknown as Guid;
  customAccountForm: FormGroup;
  maxLength = MaxLength;
  currencyList: Currency[];
  chartOfAccountGroupList: SideListModel[];
  chartOfAccountTypeList: SideListModel[];
  accountTypeList: SideListModel[];
  isStandandAccount = true;
  @Input() triggerEditCustomData: Observable<any>;
  @Input() triggereAddCustomAccount: Observable<any>;

  @Output()
  readonly accountGroupChange = new EventEmitter<any>();

  @Output()
  readonly accountTypeChange = new EventEmitter<any>();

  accountGroup: number;
  accountType: number;
  standardAccount: Guid;
  defaultCurrency: Guid;
  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  isAccountCodeNotExists: boolean = true;
  suggestedCodes: string[] = [];

  constructor(
    private store: Store,
    private commonService: CommonService,
    private globalComponent: GlobalComponent,
    public notifier: NotificationService
  ) {}

  ngOnInit(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.setForm();
    this.getAccountTypeList();
    this.getChartOfAccountGroupList();
    this.getCurrency();

    this.triggerEditCustomData?.subscribe((data) => {
      this.isStandandAccount = false;
      this.customAccountForm.patchValue({
        accountName: data.name,
        accountCode: data.code,
      });

      this.accountGroup = data.chartOfAccountGroupId;
      this.accountType = data.chartOfAccountTypeId;
      this.standardAccount = data.chartOfAccountId;
      this.isReadOnly = true;

      this.editCustomAccount(data);
    });

    this.triggereAddCustomAccount?.subscribe((data) => {
      this.isStandandAccount = true;
      this.accountGroup = data.groupId;
      this.accountType = data.typeId;
      this.standardAccount = data.id;

      this.editCustomAccount(data, false);
    });

    this.customAccountForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.customAccountForm.touched;
    });
  }

  getCurrency(): void {
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.isStandandAccount && this.currencyList.length > 0) {
            this.customAccountForm.controls.currency.setValue(
              this.defaultCurrency
            );
          }
        })
      )
      .subscribe();
  }

  getAccountTypeList(): void {
    this.store
      .dispatch(new GetAccountTypeList())
      .pipe(
        tap(() => {
          this.accountTypeList = this.store.selectSnapshot(
            CustomAccountState.getAccountType
          );
          this.accountTypeChange.emit(this.accountTypeList[0].id);
        })
      )
      .subscribe();
  }

  editCustomAccount(data, disableEditCode = true): void {
    this.isAddMode = false;
    if (disableEditCode) {
      this.customAccountForm.controls.accountCode.disable();
    }
    this.onChartOfAccountGroupChange(data.chartOfAccountGroupId);

    this.customAccountForm.patchValue({
      chartOfAccountGroup: this.accountGroup,
      chartOfAccountType: this.isFixedAssestsGroup
        ? data.name
        : this.accountType,
      bankAccountType: data.bankAccountTypeId,
      currency: data.currencyId,
      sortBranchCode: data.bankBranchCode,
      accountNo: data.bankAccountNumber,
    });
  }

  setForm(): void {
    this.customAccountForm = new FormGroup({
      accountName: new FormControl(''),
      accountCode: new FormControl(''),
      chartOfAccountType: new FormControl(''),
      chartOfAccountGroup: new FormControl(''),
      fundType: new FormControl(''),
      sortBranchCode: new FormControl(''),
      accountNo: new FormControl(''),
      currency: new FormControl({ value: '', disabled: true }),
      bankAccountType: new FormControl(''),
    });
  }

  onChartOfAccountGroupChange(accountGroupId?: number): void {
    this.accountGroupChange.emit(accountGroupId);
    this.customAccountForm.controls.chartOfAccountGroup.setValue(
      accountGroupId
    );
    if (accountGroupId === GroupNames.CurrentAssets_CashatBankAndHand) {
      this.isCashInHandGroup = true;
    } else {
      this.isCashInHandGroup = false;
    }
    if (
      accountGroupId === GroupNames.FixedAssets_Intangibles ||
      accountGroupId === GroupNames.FixedAssets_Tangibles ||
      accountGroupId === GroupNames.FixedAssets_Heritage ||
      accountGroupId === GroupNames.FixedAssets_Investments
    ) {
      this.setValidation(true);
    } else {
      this.getChartOfAccountTypeList(accountGroupId);
      this.setValidation(false);
    }
  }

  getChartOfAccountTypeList(accountGroupId?: number): void {
    if (accountGroupId !== undefined) {
      this.store
        .dispatch(new GetChartOfAccountTypeList(accountGroupId))
        .pipe(
          tap((res) => {
            this.chartOfAccountTypeList = res.account.chartOfAccountTypeList;
            if (accountGroupId === 10) {
              this.chartOfAccountTypeList.splice(0, 1);
            }
            this.customAccountForm.controls.chartOfAccountType.setValue(
              this.isReadOnly
                ? this.accountType
                : this.chartOfAccountTypeList[0].id
            );
          })
        )
        .subscribe();
    }
  }

  setValidation(isFixesAssest: boolean): void {
    if (isFixesAssest) {
      this.isFixedAssestsGroup = true;
      this.customAccountForm.clearValidators();
      this.customAccountForm.controls.chartOfAccountType.setValue(
        this.customAccountForm.controls.accountName.value
      );
      if (!this.isAddMode) {
        this.customAccountForm.controls.chartOfAccountGroup.disable();
      }
      this.customAccountForm.get('accountName')?.clearValidators();
      this.customAccountForm.get('accountCode')?.clearValidators();
      this.customAccountForm.get('accountName')?.updateValueAndValidity();
      this.customAccountForm.get('accountCode')?.updateValueAndValidity();
      this.customAccountForm.updateValueAndValidity();
    } else {
      this.isFixedAssestsGroup = false;
      this.customAccountForm.clearValidators();
      this.customAccountForm
        .get('accountName')
        ?.setValidators(Validators.required);
      this.customAccountForm
        .get('accountCode')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      this.customAccountForm.controls.chartOfAccountGroup.enable();
      this.customAccountForm.get('accountName')?.updateValueAndValidity();
      this.customAccountForm.get('accountCode')?.updateValueAndValidity();
      this.customAccountForm.updateValueAndValidity();
    }
  }

  getChartOfAccountGroupList(): void {
    this.store
      .dispatch(new GetChartOfAccountGroupList())
      .pipe(
        tap((res) => {
          const dataList = res.account.chartOfAccountGroupList;
          this.chartOfAccountGroupList = [];

          dataList.forEach((element) => {
            if (element.id !== GroupNames.CurrentAssets_Investments) {
              this.chartOfAccountGroupList.push(element);
            }
          });

          this.onChartOfAccountGroupChange(
            this.isReadOnly
              ? this.accountGroup
              : this.chartOfAccountGroupList[0].id
          );

          this.customAccountForm.controls.chartOfAccountGroup.setValue(
            this.isReadOnly
              ? this.accountGroup
              : this.chartOfAccountGroupList[0].id
          );
        })
      )
      .subscribe();
  }

  validateAccountCode(): void {
    if (this.customAccountForm.controls.accountCode.value !== '') {
      this.store
        .dispatch(
          new ValidateAccountCodeExists(
            this.customAccountForm.controls.accountCode.value
          )
        )
        .subscribe((res) => {
          this.isAccountCodeNotExists = res.custom.accountCodeExist.isSuccess;
          if (!this.isAccountCodeNotExists) {
            this.suggestedCodes = res.custom.accountCodeExist.suggestedCodes;
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.accountCodeExist
            );
            this.customAccountForm.controls.accountCode.setErrors({
              accountCodeExists: true,
            });
          } else {
            this.suggestedCodes = [];
            this.customAccountForm.controls.accountCode.setErrors(null);
          }
        });
    } else {
      this.isAccountCodeNotExists = true;
      this.suggestedCodes = [];
      this.customAccountForm.controls.accountCode.setErrors(null);
    }
  }
}
