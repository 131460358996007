import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface RequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export interface TshqRequestOptions extends RequestOptions {
  hideErrorAlert?: boolean;
  handledErrorCodes?: Array<string>;
}
export class StandardPermission {
  name: string;
  parentModuleId: number;
  id: number;
  isOwner: boolean;
  subMenu: Array<StandardPermission>;
  permissionId: number;
  isDisabled: boolean;
  moduleUrl?: string;
  moduleAddUrl?: string;
}

export class GlobalStandardPermission {
  name: string;
  ordinal: number;
  parentModuleId: number;
  id: number;
  isOwner: boolean;
  isAdministrator: number;
  isManager: number;
  isRegularUser: number;
  subMenu: Array<GlobalStandardPermission>;
  permissionId: number;
  isDisabled: boolean;
  moduleUrl?: string;
  moduleAddUrl?: string;
}

export class UserStandardPermission {
  name: string;
  parentModuleId: number;
  id: number;
  subMenu: Array<UserStandardPermission>;
  permissionId: number;
  isDisabled: boolean;
}
