<div id="chart" *ngIf="expensesChartOption">
  <apx-chart
    [series]="expensesChartOption?.series!"
    [chart]="expensesChartOption?.chart!"
    [labels]="expensesChartOption?.labels!"
    [tooltip]="expensesChartOption?.tooltip!"
    [responsive]="expensesChartOption?.responsive!"
    [legend]="expensesChartOption?.legend!"
    [plotOptions]="expensesChartOption.plotOptions!"
    [fill]="expensesChartOption?.fill!"
    [noData]="expensesChartOption?.noData!"
    [dataLabels]="expensesChartOption.dataLabels!"
    [xaxis]="expensesChartOption.xaxis!"
    [yaxis]="expensesChartOption.yaxis!"
    [grid]="expensesChartOption.grid!"
    [lines]="expensesChartOption.lines!"
  ></apx-chart>
</div>
