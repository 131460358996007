export class Currency {
  id: number;
  name: string;
  code: string;
  country: string;
}

export class DefaultCurrency {
  id: number;
}
