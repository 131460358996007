<div class="mat-dialog-wrapper ticket-popup">
  <h2 mat-dialog-title>New Support Ticket</h2>
  <button
    class="close-button"
    type="button"
    mat-button
    disableRipple
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <form [formGroup]="supportTicketForm">
      <div class="sidenav-body">
        <div class="d-flex gap-20">
          <div class="form-field w-230">
            <p class="fw-bold">Name <span class="text-red">*</span></p>
            <mat-form-field appearance="fill" class="light-bg">
              <input
                type="text"
                matInput
                maxlength="255"
                autocomplete="off"
                formControlName="name"
                required
                [readonly]="true"
              />
            </mat-form-field>
          </div>
          <div class="form-field w-230">
            <p class="fw-bold">Email <span class="text-red">*</span></p>
            <mat-form-field appearance="fill" class="light-bg">
              <input
                matInput
                required
                autocomplete="off"
                formControlName="email"
                maxlength="320"
                [readonly]="true"
              />
            </mat-form-field>
          </div>
          <div class="form-field w-230">
            <p class="fw-bold">Phone <span class="text-red">*</span></p>
            <mat-form-field appearance="fill">
              <input
                matInput
                required
                autocomplete="off"
                type="tel"
                minlength="0"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                maxlength="15"
                formControlName="phone"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-20">
          <div class="form-field w-230">
            <p class="fw-bold">Company</p>
            <ng-select
              #client
              (focus)="client.open()"
              (valueChange)="client.close()"
              formControlName="client"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              appearance="fill"
              required
            >
              <ng-option
                *ngFor="let company of companyList"
                [value]="company.companyName"
                >{{ company.companyName }}</ng-option
              >
            </ng-select>
          </div>
          <div class="form-field w-230">
            <p class="fw-bold">Help Topic</p>
            <ng-select
              #support
              (focus)="support.open()"
              (valueChange)="support.close()"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              formControlName="helpTopic"
              [readonly]="true"
            >
              <ng-option [value]="9000140263">Support Group</ng-option>
              <ng-option [value]="9000140262">SME Group</ng-option>
              <ng-option [value]="9000140264">Sales</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="hr-top pt-1 mt-5">
          <div class="form-field w-434">
            <p class="fw-bold">Subject <span class="text-red">*</span></p>
            <mat-form-field appearance="fill">
              <input type="text" matInput formControlName="subject" required />
            </mat-form-field>
          </div>
        </div>
        <div>
          <div class="form-field textarea">
            <p class="fw-bold">Message <span class="text-red">*</span></p>
            <mat-form-field class="wp-100" appearance="fill">
              <textarea
                matInput
                formControlName="message"
                autocomplete="off"
                required
              ></textarea>
            </mat-form-field>
          </div>
          <div class="form-field attached">
            <p class="fw-bold">Attachments</p>
            <div class="form-field attached mb-10">
              <div
                class="attach-file maxw-100"
                [ngClass]="isImageSelected ? 'hidden' : ''"
              >
                <mat-icon fontSet="material-icons-outlined"
                  >cloud_upload</mat-icon
                >
                <input
                  #fileupload
                  multiple
                  type="file"
                  [accept]="acceptedFileTypes"
                  formControlName="fileName"
                  autocomplete="off"
                  maxlength="100"
                  id="file"
                  (change)="selectFiles($event)"
                  class="cursor-pointer"
                />
                <label for="file" class="cursor-pointer">
                  <strong>Choose a file</strong> or drag it here to attach a
                  file with ticket.
                </label>
              </div>
            </div>
            <span class="font-size-12">
              (Max file size - 2 MB) | Supported formats : .doc, .docx, .pdf,
              .jpg, .png, .jpeg, .txt, .xls, .xlsx, .csv</span
            >

            <div class="note-table mt-10">
              <table
                aria-describedby="attachment-list"
                [ngClass]="fileList.length === 0 ? '' : 'table-border'"
              >
                <tr>
                  <th></th>
                </tr>
                <tr
                  class="clickable"
                  *ngFor="let file of fileList; let i = index"
                >
                  <td>
                    <span class="clamp" title="{{ file.name }}">{{
                      file.name
                    }}</span>
                  </td>
                  <td class="text-align-right">
                    <button
                      type="button"
                      mat-button
                      disableRipple
                      (click)="removeFile(i)"
                    >
                      <mat-icon class="text-gray-100">highlight_off</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex pb-5 pt-10 hr-top pt-1 mt-5">
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="onSave()"
        >
          Submit
        </button>
        <button
          class="action-button secondary-button"
          mat-dialog-close
          disableRipple
          type="button"
          mat-button
          disableRipple
        >
          Cancel
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
