import {
  AccountModel,
  CompanyModel,
  PaymentDetailsModel,
  ProductDetailsModel,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class InvoiceModel {
  id?: Guid;
  entryDate?: string;
  entryNumber: string;
  dueDate?: string;
  currencyId: number;
  accountId?: Guid;
  bookAccountId?: Guid;
  fundId?: Guid;
  activityId: Guid | null;
  bankAccountId: Guid | null;
  note: string;
  baseAmount?: number;
  totalAmount?: number;
  items: Array<ProductDetailsModel>;
  receiptDetails?: Array<PaymentDetailsModel>;
  attachment?: Array<string>;
  transactionLogId?: Guid;
  isManualBank?: boolean;
  advanceReceiptAmount?: number;
  isVatIncluded: boolean;
}

export class ChartOfAccountListParam {
  groupIds: Array<number>;
  typeIds: Array<number>;
}

export class InvoiceViewDetails {
  columns: Array<any>;
  account: AccountModel;
  company: CompanyModel;
  invoice: InvoiceModel;
}
