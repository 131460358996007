import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DonationCodingModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class DonationCodingService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createDonationCoding(donation: DonationCodingModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}donationCoding/insert`,
      this.commonService.trimObjectSpace(JSON.stringify(donation)),
      headers
    );
  }
}
