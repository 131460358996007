import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  AccountingMethod,
  MaxLength,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  FileUploadResponseModel,
  GlobalComponent,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import { GetCustomBankAndCashAccount } from '@app/core/Store';
import { AttachmentPopupComponent, CleanAllLinesComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-transactions-payment',
  templateUrl: './transactions-payment.component.html',
  styleUrls: ['./transactions-payment.component.scss'],
})
export class TransactionsPaymentComponent implements OnInit {
  @Input()
  headerText: string;

  @Input()
  totalReceiptAmount: number;

  paymentDetailForm: FormGroup;
  paymentArray: any;
  periodicDate: any;
  accountingMethod: any;
  transactionTypeList: SideListModel[];
  accountList: any[] = [];
  receiptData: any[];
  paymentId = Guid.EMPTY as unknown as Guid;
  paymentAccountList: any[] = [];
  tempPaymentAccountList: any[] = [];

  paymentForm: FormGroup;
  tableDataSource: MatTableDataSource<AbstractControl>;
  fileData: Array<FileUploadResponseModel>;

  displayPaymentsColumns: string[] = [
    'srNo',
    'receiptdate',
    'account',
    'amount',
    'closeButton',
  ];

  totalReciptAmount = 0;
  isAddMode = true;
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  @Input() triggereEditPaymentData: Observable<any>;
  @Input() moduleId: any;
  private destroy$ = new Subject<void>();
  isReceiptChangePermission: boolean = true;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.accountingMethod = this.globalComponent.getAccountingMethod();
    this.setPaymentForm(false);

    this.triggereEditPaymentData
      .pipe(
        switchMap((data) => {
          return this.getAccountList(this.paymentArray.length).pipe(
            map(() => data)
          );
        })
      )
      .subscribe((data) => {
        this.editPayment(data);
      });

    this.paymentForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.paymentForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (
        this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.CreditNote
      ) {
        this.isReceiptChangePermission = this.commonService.checkPermission(
          Modules.Income,
          Modules.Receipt
        );
      }

      if (
        this.moduleId === Modules.Bills ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.FixedAssets
      ) {
        this.isReceiptChangePermission = this.commonService.checkPermission(
          Modules.Expenditure,
          Modules.Payment
        );
      }
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  accountTypeChange(isAddMode: boolean): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Cash, AccountEntity.Bank);

    this.getAccountList(this.paymentArray.length - 1).subscribe();

    if (isAddMode) {
      this.paymentArray.controls[this.paymentArray.length - 1]
        .get('id')
        ?.setValue(Guid.EMPTY as unknown as Guid);
    }
  }

  getAccountList(i: number): Observable<any> {
    return this.store.dispatch(new GetCustomBankAndCashAccount()).pipe(
      tap((res) => {
        if (
          this.paymentAccountList.length > 0 &&
          this.paymentAccountList[i] !== null &&
          this.paymentAccountList[i] !== undefined
        ) {
          this.paymentAccountList[i] = res.common.customBankAndCashAccount;
          this.tempPaymentAccountList[i] = res.common.customBankAndCashAccount;
        } else {
          this.paymentAccountList.push(res.common.customBankAndCashAccount);
          this.tempPaymentAccountList.push(res.common.customBankAndCashAccount);
        }
      })
    );
  }

  getOptionText(option) {
    return option.name;
  }

  onAccountSearch(event: any, i: number): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempPaymentAccountList[i]
    );
    this.paymentAccountList[i] = list;
  }

  setPaymentForm(addNewRow: boolean): void {
    this.paymentForm = new FormGroup({
      paymentArray: new UntypedFormArray([]),
    });

    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.setDataSource(this.paymentArray);
    if (addNewRow) this.createRow();
  }

  resetForm(): void {
    this.paymentForm.reset();
  }

  addNewRow(): void {
    if (this.totalReceiptAmount === 0) {
      this.commonService.onFailure(
        NotificationTextMessage.checkAmountErrorMessage
      );
      return;
    }
    if (this.totalReceiptAmount <= this.totalReciptAmount) {
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    }
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  editPayment(paymentItems: any): void {
    this.receiptData = [];
    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.paymentArray.clear();

    paymentItems.forEach((element) => {
      this.receiptData?.push(element);
    });

    this.receiptData.forEach((item, i) => {
      this.paymentArray.push(this.buildPaymentItemsForm(item));
      this.paymentAccountList[i] = this.paymentAccountList[0];

      this.paymentArray.controls[i].get('receiptDate')?.clearValidators();

      this.paymentArray.controls[i]
        .get('receiptDate')
        ?.setValidators([datePickerValidator(this.periodicDate)]);

      this.paymentArray.controls[i].updateValueAndValidity();
    });
    this.setDataSource(this.paymentArray);
    this.amountChanges();
  }

  buildPaymentItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      receiptId: item.receiptId,
      accountId: item.postingAccountId,
      amount: item.amount,
      receiptDate: item.transactionDate,
      attachment:
        item.attachment !== null
          ? this.formBuilder.array(
              item.attachment.map((x) => this.formBuilder.control(x))
            )
          : [],
    });
  }

  createRow(): void {
    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.paymentArray.push(this.setForm());
    this.setDataSource(this.paymentArray);

    if (this.paymentArray.length > 0) {
      this.accountTypeChange(true);
    }
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      receiptId: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      receiptDate: new FormControl(
        new Date(),
        datePickerValidator(this.periodicDate)
      ),
      accountId: new FormControl(''),
      amount: new FormControl<string | null>(''),
      attachment: new FormControl(),
    });
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.paymentForm.get(
            'paymentArray'
          ) as UntypedFormArray;

          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i]
              .get('receiptId')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('receiptDate')?.setValue(new Date());
          }
        }
      });
  }

  onDeletePayment(index: number): void {
    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.paymentArray.removeAt(index);
    this.setDataSource(this.paymentArray);
    this.amountChanges();
  }

  checkValidation(event: any): boolean {
    const amount = String.fromCharCode(event.keyCode);
    return +amount < +this.totalReceiptAmount;
  }

  amountChanges(): void {
    this.totalReciptAmount = 0;

    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.paymentArray?.getRawValue().forEach((x) => {
      this.totalReciptAmount = +this.totalReciptAmount + +x.amount;
    });
  }

  clickAttachment(i: number): void {
    this.dialog
      .open(AttachmentPopupComponent, {
        data: this.paymentArray.controls[i]['controls'].attachment.value,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.fileData = result;

          const attachment: any = [];
          result.forEach((element) => {
            attachment.push(element.fileUrl);
          });

          this.paymentArray.controls[i].get('attachment').reset();
          this.paymentArray.controls[i].controls.attachment =
            this.formBuilder.control(attachment);
        }
      });
  }

  scrollIntoView() {
    this.commonService.autoScrollMatAutoComplete(this.renderer);
  }
}
