import { AccountModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateCustomAccount {
  static readonly type = '[CUSTOMACCOUNT] Create Invoice';

  constructor(public customAccount: AccountModel) {}
}

export class GetDataByCustomAccountId {
  static readonly type = '[CUSTOMACCOUNT] Get Data By Custom Account Id';

  constructor(public customAccountId: Guid) {}
}

export class GetDataByStandardAccountId {
  static readonly type = '[CUSTOMACCOUNT] Get Data By Standard Account Id';

  constructor(public standardAccountId: Guid) {}
}

export class GetAccountTypeList {
  static readonly type = '[CUSTOMACCOUNT] Get Account Type';
}

export class DeleteCustomAccount {
  static readonly type = '[CUSTOMACCOUNT] Delete Custom account';

  constructor(public customAccountIds: Array<Guid>) {}
}

export class ArchiveAndRestoreCustomAccount {
  static readonly type =
    '[CUSTOMACCOUNT] Archive and Restore Selected Custom account';

  constructor(
    public customAccountIds: Array<Guid>,
    public isArchive: boolean
  ) {}
}

export class ValidateAccountCodeExists {
  static readonly type = '[CUSTOMACCOUNT] Validate Account Code Exist';
  constructor(public code: any) {}
}
