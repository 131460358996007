<div class="content-body">
  <div class="sidenav-table sidenav-table-input">
    <div
      class="mat-table-wrapper contact-detail-table"
      [ngClass]="
        (totalRecordOfUserClient$ | async)! > 0
          ? 'mat-table-wrapper-pagination'
          : ''
      "
    >
      <table
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="userClientsDetails$ | async"
        aria-describedby="User Client Table"
      >
        <ng-container matColumnDef="no">
          <th mat-header-cell *matHeaderCellDef class="w-20"></th>
          <td mat-cell *matCellDef="let element; let i = index">
            #{{
              i +
                1 +
                this.listParameters.pageSize *
                  (this.listParameters.pageNumber - 1)
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Charity Name
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <span class="onelineclamp" title="{{ element.name }}">
                {{ element.name }}</span
              >
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="isAssigned">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            <mat-checkbox
              *ngIf="(userClientsDataLength$ | async) > 0"
              [(ngModel)]="isAssignedToAllClients"
              (change)="assignToAllClients($event); isClientUpdate = true"
            ></mat-checkbox>
            Assign to This User
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.isAssigned"
              (change)="checkAssignedToAllClient(); isClientUpdate = true"
              [disabled]="element.permissionId === authorizedRole.Owner"
            ></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="close">
          <th mat-header-cell *matHeaderCellDef class="w-50">
            <mat-icon
              class="cursor-pointer"
              *ngIf="(userClientsDataLength$ | async) > 0"
              (click)="onAllDelete()"
              >highlight_off</mat-icon
            >
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              class="close-btn"
              type="button"
              (click)="onDelete(element)"
              mat-button
              disableRipple
              [disabled]="element.permissionId === authorizedRole.Owner"
            >
              <mat-icon>highlight_off</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedUserClientColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedUserClientColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayedUserClientColumns.length"
          >
            <span *ngIf="listParameters && !listParameters.search">{{
              commonNotificationText.NoRecordsFound
            }}</span>
            <span *ngIf="listParameters && listParameters.search">{{
              commonNotificationText.NoRecordsFoundForFilter
            }}</span>
          </td>
        </tr>
      </table>
      <div
        class="paginator-wrapper sidenav-paginator"
        *ngIf="(totalRecordOfUserClient$ | async)! > 0"
      >
        <app-custom-paginator
          *ngIf="(totalRecordOfUserClient$ | async)! >= 10"
          [length]="totalRecordOfUserClient$ | async"
          [listParameters]="listParameters"
          (pageChanged)="pageChanged($event)"
          (pageSizeVal)="pageSizeVal($event)"
          [isClientAssign]="true"
        >
        </app-custom-paginator>
      </div>
    </div>
  </div>
</div>
